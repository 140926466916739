import { ModuleWithProviders, NgModule } from '@angular/core';

import { FormatDatePipe } from './format-date.pipe';
import { FormatFileSizePipe } from './format-file-size.pipe';
import { FormatNumberUnitPipe } from './format-number-unit.pipe';
import { FormatNumberPipe } from './format-number.pipe';
import { GetStackMsgPipe } from './get-stack-message.pipe';
import { OrderByPipe } from './order-by.pipe';
import { PdlFilterPipe } from './pdl-filter.pipe';
import { RemoveFileExtensionPipe } from './remove-file-extension.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { TranslatePipe } from './translate.pipe';
import { TruncatePipe } from './truncate.pipe';
import { UserFilterPipe } from './user-filter.pipe';

const pipes = [
    FormatNumberPipe,
    FormatFileSizePipe,
    RemoveFileExtensionPipe,
    FormatDatePipe,
    OrderByPipe,
    UserFilterPipe,
    TitleCasePipe,
    TranslatePipe,
    TruncatePipe,
    PdlFilterPipe,
    FormatNumberUnitPipe,
    GetStackMsgPipe,
];

@NgModule({
    imports: [],
    providers: pipes,
    declarations: pipes,
    exports: pipes,
})
export class PipesModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: PipesModule,
        };
    }
}
