import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BillsControlTab } from 'app/shared/components/bills/bills-control/bills-control-tabs/bills-control-tabs.interface';
import { UsersService } from 'app/shared/services/users/users.service';
@Component({
    selector: 'app-bills-control',
    templateUrl: './bills-control.component.html',
    styleUrls: ['./bills-control.component.scss'],
})
export class BillsControlComponent implements OnInit {
    selectedTab: BillsControlTab = null;
    tabs: BillsControlTab[] = [];

    isLoading = true;

    constructor(public route: ActivatedRoute, public router: Router, private usersService: UsersService) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.initData(params);
        });
    }

    initData(params) {
        if (params && params['tab']) {
            const selectedTabKey = params['tab'];

            this.isLoading = false;

            this.initTabs();

            this.selectTab(selectedTabKey);
        }
    }

    /**
     * Initialize the tabs with an array of AdminTabs.
     * Visibility and editing rights depend on the user's type and whether he belongs to the company or not.
     */
    initTabs() {
        this.tabs = [
            {
                key: 'factures',
                name: 'contrôle des factures',
                featureKey: 'bills-controls.controls',
                canUserView: true,
            },
            {
                key: 'completude-donnees',
                name: 'complétude des données',
                featureKey: 'bills-controls.completeness',
                canUserView: this.usersService.isSessionUserFromCitron(),
            },
        ];
    }

    /**
     * Set the correct tab based on URL
     * @param {string} tabKey - name in the url after the user's id, match a key in the tabs list
     */
    selectTab(tabKey: string) {
        this.selectedTab = this.tabs.find(t => t.key === tabKey);

        // If the url is wrong, redirect to homepage
        if (!this.selectedTab) {
            this.router.navigate(['/accueil']);
        }
    }
}
