import { AgmInfoWindow, AgmMarker, MapsAPILoader } from '@agm/core';
import { ControlPosition, ZoomControlOptions } from '@agm/core';
import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';

import { MarkerLegend } from 'app/shared/models/marker-legend.interface';
import { Marker } from 'app/shared/models/marker.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { MapService } from 'app/shared/services/map/map.service';

@Component({
    selector: 'ga-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    providers: [],
})
export class MapComponent implements OnInit, DoCheck {
    @Input() markers: Marker[] = [];
    markersDiffer: IterableDiffer<Marker>;

    latlngBounds: google.maps.LatLngBounds;
    markerSelected: Marker = null;

    // google maps inputs
    @Input() lat = 51.673858;
    @Input() lng = 7.815982;
    @Input() styles: object[] = this.mapService.getMapStyle();
    @Input() mapHeight = 400;
    @Input() scrollwheel = false;
    @Input() zoom = 8;
    @Input() zoomControl = true;
    @Input() zoomControlOptions: ZoomControlOptions = { position: ControlPosition.LEFT_TOP };
    @Input() defaultIcon = true;

    // data inputs
    @Input() legends: MarkerLegend[] = [];

    @Input() multipleInfoWindow = false;

    // outputs
    @Output() markerClick: EventEmitter<Marker> = new EventEmitter<Marker>();
    @ViewChildren(AgmMarker) agmMarkers: QueryList<AgmMarker>;

    constructor(
        private mapService: MapService,
        private mapsAPILoader: MapsAPILoader,
        private markersDiffers: IterableDiffers,
        private energyService: EnergyService
    ) {
        this.markersDiffer = markersDiffers.find([]).create(null);
    }

    ngOnInit() {}

    ngDoCheck(): void {
        const changes = this.markersDiffer.diff(this.markers);
        // if the markers list length has changed
        if (changes) {
            this.setBounds(); // when a marker is added or removed, resize the map

            const isDefault = this.defaultIcon;
            changes.forEachAddedItem(record => {
                isDefault ? this.setDefaultIcon(record.item) : this.setIconUrl(record.item); // set icon on the map for each new marker
            });
        }
    }

    onMarkerClick(marker: Marker, infoWindow: AgmInfoWindow) {
        if (!this.agmMarkers) {
            return;
        }
        const hostMaker = this.agmMarkers.find(x => x.latitude === marker.lat && x.longitude === marker.lng);
        if (hostMaker) {
            infoWindow.hostMarker = hostMaker;
            this.setMarkerWindow(marker);
            this.markerSelected = marker;
            this.latlngBounds = null;
            infoWindow.open();
            this.markerClick.emit(marker);
        }
    }

    public setIconUrl(marker: Marker): void {
        marker.icon = this.mapService.getIconUrl(marker.type);
    }

    private setDefaultIcon(marker: Marker): void {
        marker.icon = this.mapService.iconsUrl.defaultOne;
    }

    public getAgmCss() {
        return {
            height: this.mapHeight + 'px',
        };
    }

    private setBounds() {
        // needed to avoid center map on ocean on empty markers #itsnormalindevland
        if (!this.markers.length) {
            return;
        }

        this.mapsAPILoader.load().then(() => {
            const newLatLngBounds = new google.maps.LatLngBounds();

            this.markers.forEach(marker => {
                if (marker.lat && marker.lng) {
                    const latLng = new google.maps.LatLng(marker.lat, marker.lng);
                    newLatLngBounds.extend(latLng);
                }
            });

            // fix if bounds need more space
            if (newLatLngBounds.getNorthEast().equals(newLatLngBounds.getSouthWest())) {
                const extendPoint1 = new google.maps.LatLng(
                    newLatLngBounds.getNorthEast().lat() + 0.01,
                    newLatLngBounds.getNorthEast().lng() + 0.01
                );
                const extendPoint2 = new google.maps.LatLng(
                    newLatLngBounds.getNorthEast().lat() - 0.01,
                    newLatLngBounds.getNorthEast().lng() - 0.01
                );
                newLatLngBounds.extend(extendPoint1);
                newLatLngBounds.extend(extendPoint2);
            }

            this.latlngBounds = newLatLngBounds;
        });
    }

    /**
     * Get marker route
     * @param {Marker} marker
     * @returns {string[]}
     */
    getMarkerPlaceRoute(marker: Marker): string[] {
        let route: string[] = [];
        if (marker.infoWindow && marker.data._id) {
            switch (marker.config) {
                default:
                case 'carto':
                case 'site': {
                    let fluid = 'energie';
                    if (marker.type === 'water') {
                        fluid = 'eau';
                    } else if (marker.type === 'fuel') {
                        fluid = 'vehicules';
                    }
                    route = [`/${fluid}/profil/${marker.data._id}`];
                    break;
                }
            }
        }
        return route;
    }

    setMarkerWindow(marker: Marker) {
        switch (marker.config) {
            default:
            case 'site':
                marker.infoWindow = {
                    title: marker.data.complement || 'NC',
                    infoArray: [this.getMarkerAddress(marker.data)],
                };
                break;

            case 'carto':
                marker.infoWindow = {
                    title: marker.data.complement || 'NC',
                    infoArray: [this.getMarkerAddress(marker.data), this.getMarkerPdlCount(marker.data)],
                };
                break;

            case 'dju':
                marker.infoWindow = {
                    title: '', // not using default title as it is a link and for DJU, link is not needed (no redirection)
                    infoArray: [[[marker.data.title]]],
                };
                break;
        }
    }

    getMarkerAddress(site) {
        if (site.city) {
            const streetNumber = site.streetNumber || '';
            const zipcode = site.zipcode || '';

            return [[`${streetNumber} ${site.streetName}`], [`${zipcode} ${site.city}`]];
        } else {
            return [[site.name]];
        }
    }

    getMarkerPdlCount(site) {
        const infoArray = [];

        if (site.infoFluids) {
            Object.keys(site.infoFluids).forEach(fluid => {
                if (site.infoFluids[fluid]) {
                    let display = this.energyService.energyFullText(fluid) + ' : ' + site.infoFluids[fluid];
                    display += this.energyService.isFluidFromVehicle(fluid) ? ' véhicules' : ' PDL';
                    infoArray.push([display]);
                }
            });
        }

        if (!infoArray.length) {
            infoArray.push(['', 'Aucun PDL']);
        }

        return infoArray;
    }

    hasMarkerWindow() {
        return Boolean(
            this.markerSelected &&
                this.markerSelected.infoWindow &&
                (this.markerSelected.infoWindow.title || this.markerSelected.infoWindow.infoArray)
        );
    }
}
