import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { isEqual } from 'lodash';

import { Company } from 'app/shared/models/company.interface';
import { CONTRACTUAL_DATA_SOURCE } from 'app/shared/models/contractual-data.interface';
import { ENTITY_TYPE, SOURCE_TYPE } from 'app/shared/models/external-providers.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { ContractualDataService } from 'app/shared/services/contractual-data/contractual-data.service';
import {
    ExternalProvidersService,
    UpdateExternalProviders,
} from 'app/shared/services/external-providers/external-providers.service';
import { LoadCurveService } from 'app/shared/services/load-curve/load-curve.service';
import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';
import { ACTION, SchedulesService } from 'app/shared/services/schedules/schedules.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

import { RRefItem } from './rrefs-selection.interface';

@Injectable()
export class RRefSelectionService {
    /** List of routing references */
    private rRefList: RRefItem[] = null;

    private EnedisRegExp = new RegExp('^[0-9]{14}$');

    public get nbUpdated(): number {
        return this.rRefList ? this.rRefList.filter(x => x.isUpdated).length : 0;
    }

    /** Counts */
    public totalRrefsCount = 0;
    public assignedRrefsCount = 0;

    constructor(
        private contractualDataService: ContractualDataService,
        private routingReferencesService: RoutingReferencesService,
        private sitesService: SitesService,
        private externalProvidersService: ExternalProvidersService,
        private schedulesService: SchedulesService,
        private loadcurveService: LoadCurveService
    ) {}

    /** Returns the number of selected rrefs in the list */
    public getSelectedRrefCount(isAssigned: boolean | null): number {
        if (!this.rRefList) {
            return 0;
        }

        if (typeof isAssigned !== 'boolean') {
            return this.rRefList.filter(x => x.formGroup.controls.warrantAuthorization.value).length;
        }
        return this.rRefList.filter(x => x.formGroup.controls.warrantAuthorization.value && x.isAssigned === isAssigned)
            .length;
    }

    /**
     * Init rref list and returns that list.
     * @param {string} companyId company id to get rref from
     */
    public async initRRefList(companyId: string): Promise<RRefItem[]> {
        if (this.rRefList && Array.isArray(this.rRefList)) {
            return this.rRefList;
        }
        const routingReferences = await this.routingReferencesService.getRoutingReferences({
            companyId,
        });

        const assignedRrefsIds = await this.getAssignatedRrefsIdsList(companyId);

        const filteredRoutingReferences = routingReferences.filter(
            x => x.energyType === 'elec' && this.isEnedis(x.reference)
        );

        this.totalRrefsCount = filteredRoutingReferences.length;

        const rrefSyncDates = await this.getRRefSyncDates(filteredRoutingReferences);

        this.rRefList = filteredRoutingReferences.map(x => {
            const isAssigned = Boolean(assignedRrefsIds.find(rRefId => rRefId === x._id));
            this.assignedRrefsCount += isAssigned ? 1 : 0;

            const extProviders = (x.externalProviders &&
                x.externalProviders.find(e => e.dataProvider === SOURCE_TYPE.ENEDIS)) || {
                dataProvider: SOURCE_TYPE.ENEDIS,
                warrantAuthorization: false,
                loadCurveVisualization: false,
                contractualData: false,
                siret: '',
            };
            const lastSync: Date = rrefSyncDates[x._id];

            return {
                id: x._id,
                reference: x.reference,
                isUpdated: false,
                isAssigned,
                formGroup: new FormGroup({
                    warrantAuthorization: new FormControl(extProviders.warrantAuthorization),
                    loadCurveVisualization: new FormControl(extProviders.loadCurveVisualization),
                    contractualData: new FormControl(extProviders.contractualData),
                    siret: new FormControl(extProviders.siret),
                }),
                originalValue: {
                    warrantAuthorization: extProviders.warrantAuthorization,
                    loadCurveVisualization: extProviders.loadCurveVisualization,
                    contractualData: extProviders.contractualData,
                    siret: extProviders.siret,
                },
                lastSync,
                waitForSync: this.isWaitingForSync(lastSync),
            };
        });
        return this.rRefList;
    }

    /**
     * Retrieves the day on which contractual data will be updated for all routing references of a company
     */
    public async getDataUpdateDay(company: Company): Promise<number> {
        const schedules = await this.schedulesService.getSchedules({
            tag: this.getContractualDataScheduleTag(company),
        });
        if (schedules && schedules.length) {
            return schedules[0].recurrence.dayOfMonth;
        }
    }

    /**
     * Change the day on which we update the routing references contractual data of a company
     */
    public async saveDataUpdateDay(company: Company, day: FormControl) {
        await this.schedulesService.createUpdateSchedule(
            this.getContractualDataScheduleTag(company),
            ACTION.SYNC_CONTRACTUAL_DATA,
            { dayOfMonth: day.value },
            { source: 'enedis', companyId: company._id }
        );
    }

    /**
     * Save updated items
     */
    public async saveUpdatedItems() {
        const updated = this.rRefList.filter(x => x.isUpdated);
        const updates = updated.map<UpdateExternalProviders>(x => ({
            entityId: x.id,
            entityType: ENTITY_TYPE.ROUTING_REFERENCE,
            provider: {
                dataProvider: SOURCE_TYPE.ENEDIS,
                ...x.formGroup.getRawValue(),
            },
        }));
        await this.externalProvidersService.updateExternalProviders(updates);
        // Reset updated
        updated.forEach(x => {
            x.originalValue = x.formGroup.getRawValue();
            x.waitForSync = this.isWaitingForSync(x.lastSync);
            this.updateItem(x);
        });
    }

    /** Reset all updated items to their original value */
    public resetUpdatedItems(): void {
        const updatedItems = this.rRefList.filter(rrefItem => rrefItem.isUpdated);

        updatedItems.forEach(rrefItem => {
            rrefItem.formGroup.setValue(rrefItem.originalValue);
            rrefItem.isUpdated = false;
        });
    }

    /** Ensure external providers fields coherence */
    public ensureFieldsCoherence(formGroup: FormGroup) {
        const { warrantAuthorization, loadCurveVisualization, contractualData, siret } = formGroup.controls;
        // Update fields
        const opt = { emitEvent: false };
        if (warrantAuthorization.value === true) {
            loadCurveVisualization.enable(opt);
            contractualData.enable(opt);
            siret.enable(opt);
        } else {
            loadCurveVisualization.disable(opt);
            loadCurveVisualization.patchValue(false, opt);
            contractualData.disable(opt);
            contractualData.patchValue(false, opt);
            siret.disable(opt);
        }
    }

    /**
     * Perform action on updated items.
     * Currently set `isUpdated` to item
     */
    public updateItem(item: RRefItem) {
        item.isUpdated = this.hasItemBeenUpdated(item);
    }

    /**
     * Get filtered list of rref items
     * @param search - search string, can be a regex
     */
    public getRRefItems(search: string = ''): RRefItem[] {
        let regExp: RegExp = null;
        try {
            regExp = new RegExp(search, 'g');
        } catch (e) {
            return;
        }
        return this.rRefList.filter(x => regExp.test(x.reference));
    }

    public filterRrefItemsByAssignation(isAssigned: boolean | null): RRefItem[] {
        if (typeof isAssigned !== 'boolean') {
            return this.rRefList.concat();
        }
        return this.rRefList.filter(rRefItem => rRefItem.isAssigned === isAssigned);
    }

    /**
     * Returns only routing references not wating for sync and having contractual data retrieval activated
     */
    public filterRRefToSync(rRefs: RRefItem[]): RRefItem[] {
        return rRefs.filter(rRef => !rRef.waitForSync && rRef.originalValue.contractualData);
    }

    /**
     * Returns wether an item has been updated or not (from last save).
     */
    private hasItemBeenUpdated(item: RRefItem): boolean {
        return !isEqual(item.formGroup.getRawValue(), item.originalValue);
    }

    /**
     * Synchronize the contractual data of all routing references given
     */
    public async synchronizeContractualData(routingReferences: RRefItem[]) {
        const identifiers = [];
        for (let i = 0, len = routingReferences.length; i < len; i++) {
            const routingReference = routingReferences[i];
            identifiers.push({ routingReference: routingReference.reference, fluid: 'elec' });
            routingReference.lastSync = new Date();
            routingReference.waitForSync = this.isWaitingForSync(routingReference.lastSync);
        }
        await this.contractualDataService.synchronize(identifiers, CONTRACTUAL_DATA_SOURCE.ENEDIS);
    }

    /**
     * Returns if the last synchronization is in debounce time or not
     */
    private isWaitingForSync(date: Date): boolean {
        return date && new Date().getTime() - date.getTime() < 12 * 3600 * 1000;
    }

    /**
     * Returns the last synchronization date for a list of routing references
     */
    private async getRRefSyncDates(routingReferences: RoutingReference[]): Promise<Record<string, Date>> {
        const ids = routingReferences.map(rref => rref._id);
        const summaries = await this.loadcurveService.getRoutingReferencesCommunicatingSummaries(ids);

        const rrefSyncDates: Record<string, Date> = {};
        for (let i = 0, len = summaries.length; i < len; i++) {
            const summary = summaries[i];
            if (summary.lastSynchronization) {
                rrefSyncDates[summary.routingReference.id] = new Date(summary.lastSynchronization);
            }
        }

        return rrefSyncDates;
    }

    /**
     * Return the schedule tag for the contractual data update
     */
    private getContractualDataScheduleTag(company): string {
        return `contractual_data_${SOURCE_TYPE.ENEDIS}_${company._id}`;
    }

    private isEnedis(ref: string): boolean {
        return this.EnedisRegExp.test(ref);
    }

    /** Returns the assignated routing references ids for given company */
    private async getAssignatedRrefsIdsList(companyId: string): Promise<string[]> {
        const { data: sites } = await this.sitesService.getSites({ companyId });

        const assignedRrefsIds = [];

        if (sites && sites.length) {
            sites.forEach(site => {
                if (site.routingReferences && site.routingReferences.length) {
                    site.routingReferences.forEach(rRef => assignedRrefsIds.push(rRef._id));
                }
            });
        }

        return assignedRrefsIds;
    }
}
