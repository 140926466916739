import { Injectable } from '@angular/core';
import { CompanyManager } from 'app/shared/models/company.interface';
import { User } from 'app/shared/models/users.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

@Injectable()
export class CompanyService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    /**
     * Get populated users and filtered by types
     *
     * @param {string[]} companyUsers - list of user id
     * @param {User[]} users - users to filter
     * @param {string[]?} filterTypes - user type filter
     * @returns {User[]}
     */
    getCompanyUsers(companyUsers: string[], users: User[], userTypes?: string[]): User[] {
        if (!Array.isArray(companyUsers)) {
            return [];
        }
        return users.filter(
            u =>
                companyUsers.includes(u._id) &&
                (!Array.isArray(userTypes) || !userTypes.length || userTypes.includes(u.type))
        );
    }

    /**
     * Get company Citron manager by manager type
     *
     * @param {{user: string, type: string}} managers - company managers
     * @param {CompanyManager[]} managerType - manager type filter
     * @returns {string} - id of the manager
     */
    getCompanyManager(managers: CompanyManager[], managerType: string[]): string {
        if (!Array.isArray(managerType) || !Array.isArray(managers) || !managers.length) {
            return null;
        }
        const manager = managers.find(m => managerType.includes(m.type));
        return manager ? manager.user : null;
    }
}
