import { Injectable } from '@angular/core';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

import { RRefKeyData } from 'app/shared/models/key-data.interface';

@Injectable()
export class KeyDataService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    /**
     * Get routing references key data
     * @param {string[]} rRefIds - ids of routing references to get key data from
     * @returns {Promise<RRefKeyData>}
     */
    public async getRRefsKeyData(rRefIds: string[]): Promise<RRefKeyData> {
        const ids = rRefIds.join(',');
        const res = await this.post(`/api/key-data/routing-reference`, { routingReferences: ids });
        return res.data;
    }
}
