import { Injectable } from '@angular/core';

import _ = require('lodash');

import { SortService } from '../sort.service';

import { SortableProperty, SortFilters } from './sort-filter.interface';

@Injectable()
export class SortFilterService {
    /** Set default value for property if not found with its path */
    private propertyDefaultValue = null;

    /** Compare functions used by sortArray according to given property type */
    private compareFunctions: { [type: string]: (a: any, b: any) => number };

    constructor(private sortService: SortService) {
        this.compareFunctions = {
            string: this.sortService.compareStrings,
            number: this.sortService.compareNumbers,
            boolean: this.sortService.compareBooleans,
            date_string: this.sortService.compareDateStrings,
        };
    }

    /** Create and return new sort filters based on the given sortable property and previous filters state */
    public updateSortFilters(sortableProperty: SortableProperty, sortFilters: SortFilters): SortFilters {
        const newSortFilters: SortFilters = {
            order: 'asc',
            property: sortableProperty,
        };

        if (sortFilters.property.name === sortableProperty.name) {
            newSortFilters.order = sortFilters.order === 'desc' ? 'asc' : 'desc';
        }

        return newSortFilters;
    }

    /** Sort an array of objects with given sort filters and returns it */
    public sortArray(arr: any[], filters: SortFilters) {
        const arrayCopy = arr.concat([]); // copy given array to avoid side effects

        if (filters.property.path) {
            // If we are filtering only one property
            arrayCopy.sort((a: any, b: any) => this.compareByProperty(a, b, filters.order, filters.property));
        } else if (filters.property.paths && filters.property.paths.length) {
            // If we are filtering over multiple properties
            arrayCopy.sort((a: any, b: any) => this.compareByProperties(a, b, filters.order, filters.property));
        }

        return arrayCopy;
    }

    /** Compare two objects on one property (given by the path) */
    private compareByProperty(a: any, b: any, order: 'asc' | 'desc', property: SortableProperty): number {
        const firstValue = _.get(a, property.path, this.propertyDefaultValue);
        const secondValue = _.get(b, property.path, this.propertyDefaultValue);

        const result =
            order === 'asc'
                ? this.compareFunctions[property.type](firstValue, secondValue)
                : this.compareFunctions[property.type](secondValue, firstValue);

        return result;
    }

    /** Compare two objects over multiples properties (given by the paths) */
    private compareByProperties(a: any, b: any, order: 'asc' | 'desc', property: SortableProperty): number {
        /** Result of the comparaison between the last two examined fields */
        let result: number = null;

        const paths = property.paths.concat([]); // cloning to avoid side effects

        while (result === null && paths.length) {
            const path = paths.shift();
            const firstValue = _.get(a, path, this.propertyDefaultValue);
            const secondValue = _.get(b, path, this.propertyDefaultValue);

            if (firstValue !== secondValue) {
                result =
                    order === 'asc'
                        ? this.compareFunctions[property.type](firstValue, secondValue)
                        : this.compareFunctions[property.type](secondValue, firstValue);
            }
        }

        return result;
    }
}
