import { Injectable } from '@angular/core';
import { BehaviorSubject, ConnectableObservable, Observable, Subject } from 'rxjs';
import { filter, mergeMap, multicast, skip } from 'rxjs/operators';

function _window(): any {
    // return the global native browser window object
    return window;
}

export interface RegexRequest {
    /** Id to identify request */
    id: string;
    /** Text string for regex */
    text: string;
    /** Regex to execute */
    regex: string;
}

export interface RegexResponse {
    /** Id to identify request */
    id: string;
    /** Result */
    result: string[];
    /** Error if an error occured while computing regex */
    error?: any;
}

/**
 * Regex service.
 * How to use :
 * 1. Create `Subject` with `createRegexSubject`
 * 2. Create pipe `Observable` with `pipeRegexSubject`
 * 3. Subscribe to event and handle actions
 * 4. Call `Subject.next` on previously created `Subject` passing the `RegexRequest`
 *
 * Method testRegex is deprecated to avoid performances issues. Use of event must be prefered.
 */
@Injectable()
export class RegexService {
    /** Maximum number of concurrent requests for regex */
    private readonly MAX_CONCURRENT: number = 4;

    /** Subject to use global regex pool */
    private sub$: BehaviorSubject<RegexRequest>;
    /** Observable to subscribe for global regex pool */
    private obs$: ConnectableObservable<RegexResponse>;

    constructor() {
        // Create subject
        this.sub$ = this.createRegexSubject();
        // Create observable and use multicast (=> do pipe once whatever the number of subscribers)
        this.obs$ = this.pipeRegexSubject(this.sub$).pipe(multicast(() => new Subject())) as ConnectableObservable<
            RegexResponse
        >;
        // Connect observable
        this.obs$.connect();
    }

    /**
     * Compute regex using global pool.
     * Less performant than creating dedicated pool.
     * @param {string} text - text
     * @param {string} regex - regex
     * @returns {Promise<string[]>}
     */
    public async computeRegexInPool(text: string, regex: string): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            // Create unique id using timestamp and a random number between 0 and 2000.
            const id = `${Date.now().toString()}-${Math.floor(Math.random() * 2000)}`;
            // Subscribe to observable filtering on id (to only process correct event)
            const subscription = this.obs$.pipe(filter(x => x.id === id)).subscribe({
                next: v => {
                    // Unsubscribe
                    subscription.unsubscribe();
                    // Resolve result
                    resolve(v.result);
                },
                error: reject,
            });
            // Add task to queue (= ask for regex, will be caught by previous subscription)
            this.sub$.next({ text, regex, id });
        });
    }

    /**
     * Create regex subject
     * @returns {BehaviorSubject<RegexRequest>}
     */
    public createRegexSubject(): BehaviorSubject<RegexRequest> {
        const controller$ = new BehaviorSubject<RegexRequest>(null);
        return controller$;
    }

    /**
     * Create Observable from subject for regex computing
     * @param {BehaviorSubject<RegexRequest>} subject$ - subject that will be used to emit new requests
     * @returns {Observable<RegexResponse>}
     */
    public pipeRegexSubject(subject$: BehaviorSubject<RegexRequest>): Observable<RegexResponse> {
        return subject$.pipe(
            skip(1),
            mergeMap(arg => this.handleRegex(arg), this.MAX_CONCURRENT)
        );
    }

    /**
     * Compute regex in pipe
     * @param {RegexRequest} req - request
     * @returns {Promise<RegexResponse>}
     */
    private async handleRegex(req: RegexRequest): Promise<RegexResponse> {
        try {
            const res = await this.computeRegex(req.text, req.regex);
            return { result: res, id: req.id };
        } catch (e) {
            return { result: null, id: req.id, error: e };
        }
    }

    /**
     * Compute regex on a text content
     * Get only the value inside the string retrieved by the regex : erase the text before
     * @param {string} text - text to analyze
     * @param {string} regex - regex to apply
     * @returns {Promise<string[]>} values extracted
     */
    private computeRegex(text: string, regex: string): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            // Check if browser supports workers
            if (_window().Worker) {
                const myWorker = new Worker('/assets/workers/regex.js');
                const timeoutId = setTimeout(() => {
                    myWorker.terminate();
                    reject({ errorCode: 'regex_timeout' });
                }, 5000);
                myWorker.onmessage = res => {
                    if (res.data && res.data.matches) {
                        resolve(res.data.matches);
                    } else {
                        reject({ errorCode: 'regex_unknown_error' });
                    }
                    clearTimeout(timeoutId);
                };
                myWorker.onerror = err => {
                    reject({ errorCode: err.message });
                    clearTimeout(timeoutId);
                };
                myWorker.postMessage({
                    regex,
                    text,
                });
            } else {
                reject({ errorCode: 'browser_incompatible' });
            }
        });
    }

    /**
     * Test regex on a text content
     * Get only the value inside the string retrieved by the regex : erase the text before
     * @deprecated Use event management (create dedicated regex pool) or `computeRegexInPool`
     * @param {string} text - text to analyze
     * @param {string} regex - regex to apply
     * @returns {Promise<string[]>} values extracted
     */
    public testRegex(text: string, regex: string): Promise<string[]> {
        return this.computeRegex(text, regex);
    }

    /**
     * Copy text to clipboard
     * @param {string} text - text to copy to clipboard
     */
    public copyTextToClipboard(text: string) {
        // need to create a fake textArea, put the text inside it, and call the execCommand 'copy' on it
        const txtArea = document.createElement('textarea');
        txtArea.value = text;
        document.body.appendChild(txtArea);
        txtArea.select();
        try {
            const resultCopy = document.execCommand('copy');
            document.body.removeChild(txtArea);
            if (resultCopy) {
                return true;
            }

            return false;
        } catch (err) {
            return false;
        }
    }
}
