import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccessGuard } from '../../../../shared/services/guards/access-guard.service';
import { SharedModule } from '../../../../shared/shared.module';
import { ElecPdlViewComponent } from './elec.component';

const VIEW_ROUTE = [
    {
        path: '',
        component: ElecPdlViewComponent,
        canActivate: [AccessGuard],
        data: { page: 'energy' },
    },
];
@NgModule({
    declarations: [ElecPdlViewComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(VIEW_ROUTE)],
})
export class ElecPdlViewModule {}
