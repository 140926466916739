import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RuleSitesInterface } from 'app/shared/models/rule-sites-attribution.interface';
import { ColorService } from 'app/shared/services/color/color.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import * as clone from 'clone';
import { PaginationService } from '../../pagination/front/pagination-front.service';

@Component({
    selector: 'ga-sites-fluids-list',
    templateUrl: './sites-fluids-list.component.html',
    styleUrls: ['./sites-fluids-list.component.scss'],
    providers: [PaginationService],
})
export class SitesFluidsListComponent implements OnInit, OnChanges {
    private _sites: RuleSitesInterface;

    @Input()
    set sites(sites: RuleSitesInterface) {
        const val = clone(sites);
        this.updateSites(val);
    }
    get sites(): RuleSitesInterface {
        return this._sites;
    }

    @Input() fluids: string[];

    constructor(
        private colorService: ColorService,
        private siteService: SitesService,
        private paginationService: PaginationService
    ) {}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sites) {
            const val = clone(changes.sites.currentValue);
            this.updateSites(val);
        }
    }

    private updateSites(sites: RuleSitesInterface) {
        sites.sitesMatching.sort(this.sortListAlphabetically);
        this.setAddresses(sites.sitesMatching);
        this.setSiteRfs(sites.sitesMatching);
        this._sites = sites;
    }

    /**
     * Aggregate in one new array 'routingReferencesNum' the reference numbers found in the sites' routing references.
     * This list routingReferencesNum is displayed in the sites table
     * @param sites
     */
    private setSiteRfs(sites) {
        sites.forEach(site => {
            site.routingReferencesNum = site.routingReferences.map(rf => rf.reference);
        });
    }

    /**
     * Set in each site a new property 'googleAddress' a clear aggregation of the address properties in one String
     * @param sites
     */
    private setAddresses(sites) {
        sites.forEach(site => {
            site.googleAddress = this.getSiteAddress(site);
        });
    }

    /**
     * Returns a clear aggregation of the site's address properties in one String
     * @param site
     * @return {string}
     */
    private getSiteAddress(site) {
        return this.siteService.getAddressToDisplay(site);
    }

    /**
     * Returns the fluid's color if the site has at least one contract related to that fluid.
     * @param fluid
     * @param site
     * @return {string}
     */
    getFluidColor(fluid, site) {
        if (!site.routingReferences || !site.routingReferences.length) {
            return;
        }
        const hasFluid = site.routingReferences.some(rf => {
            return rf.energyType === fluid;
        });
        if (hasFluid) {
            return this.colorService.getSecondRgbColor(fluid);
        }
        return;
    }

    onPaginationChanged() {
        this.sites.pagination.start = this.paginationService.pagination.indexStart;
        this.sites.pagination.end = this.paginationService.pagination.indexEnd;
    }

    get sitesSorted() {
        return this.sites.sitesMatching.slice(
            this.paginationService.pagination.indexStart,
            this.paginationService.pagination.indexEnd + 1
        );
    }

    /** UTILS */
    private sortListAlphabetically(siteA, siteB) {
        const a = siteA.complement;
        const b = siteB.complement;
        return a > b ? 1 : a < b ? -1 : 0;
    }
}
