import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

// Components
import { ChartBarToggleComponent } from 'app/shared/components/charts/chart-bar-toggle/chart-bar-toggle.component';

// Models/interfaces
import { ElecPdlViewService } from 'app/pages/energy/pdl-view/elec/elec.service';
import { FluidViewToggleChartProperties } from 'app/pages/energy/pdl-view/pdl-view.interface';
import { SitesListService } from 'app/pages/energy/sites-list/sites-list.service';
import { DonutProperties } from 'app/shared/models/charts/charts.interface';
import { Dju } from 'app/shared/models/dju.interface';
import { CoolingMonthlyBill, CoolingYearlyBill, DatePeriod } from 'app/shared/models/fluid-bills.interface';
import { CoolingMonthlyCostTableBill, YearlyRatioTableBill } from 'app/shared/models/fluid-tables.interface';
import { TileInterface } from 'app/shared/models/tile.interface';
import { BillsService } from 'app/shared/services/bills/bills.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { ColorService } from 'app/shared/services/color/color.service';
import { DjuService } from 'app/shared/services/dju/dju.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

interface Loading {
    consoChart: boolean;
    costChart: boolean;
    repartitionChart: boolean;
    monthlyCostTable: boolean;
    ratioTable: boolean;
    site: boolean;
}

@Component({
    selector: 'ga-cooling-pdl-view',
    templateUrl: './cooling.component.html',
    styleUrls: ['./cooling.component.scss'],
    providers: [ElecPdlViewService, SitesListService],
})
export class CoolingPdlViewComponent implements OnInit {
    totalTTC = 0;
    totalHT = 0;

    loading: Loading = {
        consoChart: false,
        costChart: false,
        repartitionChart: false,
        monthlyCostTable: false,
        ratioTable: false,
        site: true,
    };

    // line chart
    consoChartProperties: FluidViewToggleChartProperties = {
        datasets: [],
        labels: [],
        colors: [],
        legend: [],
        width: 900,
        minHeight: null, // 'none'
        options: [],
        title: 'Consommation du PDL',
        downloadFileNames: [],
        toggleTitles: [],
    };
    @ViewChild('chartBarConso', { static: false }) chartBarConso: ChartBarToggleComponent;

    costsChartProperties: FluidViewToggleChartProperties = {
        datasets: [],
        labels: [],
        colors: [],
        legend: [],
        width: 900,
        minHeight: null, // 'none'
        options: [],
        title: 'Coût de la consommation du PDL',
        downloadFileNames: [],
        toggleTitles: [],
    };

    dju: any = { isDjuActivated: true, djusAvailable: false };

    costsDonutProperties: DonutProperties = {
        options: [],
        legend: [],
        title: 'Répartition des coûts',
    };

    monthlyCostTable: CoolingMonthlyCostTableBill[] = [];
    years: number[];
    contract = '';
    routingRef = '';
    site: any = null;
    data: any;
    selectedContract: any;
    consoData: any;
    costData: any;
    ratioTable: YearlyRatioTableBill[] = [];
    energyType = 'cooling';

    totalConsoTilesProperties: TileInterface = {
        mainColor: '',
        secondaryColor: '',
        label: 'Consommation totale',
        unit: 'kWh',
        type: this.energyType,
    };

    totalCostTilesProperties: TileInterface = {
        mainColor: '',
        secondaryColor: '',
        label: 'Dépense totale',
        unit: '',
        type: 'cost',
    };

    periodSelected: DatePeriod = { dateStart: null, dateEnd: null };

    constructor(
        private chartService: ChartService,
        private sessionService: SessionService,
        private energyPdlViewService: ElecPdlViewService,
        private siteService: SitesService,
        private route: ActivatedRoute,
        private colorService: ColorService,
        private billService: BillsService,
        private djuService: DjuService,
        private utilsService: UtilsService,
        private tilesService: TilesService,
        private pdlService: SitesListService
    ) {}

    ngOnInit() {
        this.totalConsoTilesProperties.mainColor = this.colorService.getHexColor('LIGHT_BLUE4');
        this.totalCostTilesProperties.mainColor = this.colorService.getHexColor('LIGHT_BLUE4');
        this.totalConsoTilesProperties.secondaryColor = this.colorService.getHexColor('LIGHT_BLUE3');
        this.totalCostTilesProperties.secondaryColor = this.colorService.getHexColor('LIGHT_BLUE3');
        this.initFromParams();
    }

    /**
     * Set all charts/tables loading
     * @param {boolean} loading - true to set as loading, false to set as not loading
     */
    private setAllLoadings(loading: boolean = true) {
        this.loading.consoChart = loading;
        this.loading.costChart = loading;
        this.loading.monthlyCostTable = loading;
        this.loading.ratioTable = loading;
        this.loading.repartitionChart = loading;
    }

    /**
     * Reset all data for new search (period)
     */
    resetAll() {
        this.loading.costChart = true;
        this.loading.repartitionChart = true;
        this.loading.monthlyCostTable = true;
        this.loading.consoChart = true;
        this.loading.ratioTable = true;

        this.consoChartProperties = {
            datasets: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: [],
            title: this.consoChartProperties.title,
            downloadFileNames: ['Consommation énergie/volume du PDL'],
            toggleTitles: [],
        };

        this.costsChartProperties = {
            datasets: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: [],
            title: this.costsChartProperties.title,
            downloadFileNames: ['Coût total du PDL', 'Cout énergie/volume du PDL'],
            toggleTitles: [],
        };

        this.costsDonutProperties = {
            options: [],
            legend: [],
            title: 'Répartition des coûts',
        };

        this.monthlyCostTable = null;
        this.ratioTable = [];
    }

    /**
     * Update values on period change
     * @param {DatePeriod} period - new period
     */
    async onPeriodChanged(period: DatePeriod): Promise<void> {
        if (
            (this.periodSelected && period && period.dateStart !== this.periodSelected.dateStart) ||
            period.dateEnd !== this.periodSelected.dateEnd
        ) {
            this.periodSelected = period;
            await this.grabDataAndDisplay();
        }
    }

    /**
     * Grab data, format it, and set it for every component.
     */
    async grabDataAndDisplay(): Promise<void> {
        if (!this.periodSelected || !this.periodSelected.dateStart || !this.periodSelected.dateEnd) {
            return;
        }

        this.resetAll();
        const [djusMonthDatas, yearlyConsumptionWithHisto, yearlyConsumption]: [
            { djus: Dju[]; monthDatas: CoolingMonthlyBill[] },
            CoolingYearlyBill[],
            CoolingYearlyBill[]
        ] = await Promise.all([
            this.getRfMonthlyConsumption(),
            this.getRfYearlyConsumptionWithHistoric(),
            this.getYearlyConsumptionForDonut(),
        ]);

        this.handleMonthData(djusMonthDatas.djus, djusMonthDatas.monthDatas);
        this.djuActivateChanged(this.dju.isDjuActivated);
        this.setTotals(djusMonthDatas.monthDatas);
        this.handleRatioTable(yearlyConsumptionWithHisto);
        this.handleCostsDonut(yearlyConsumption);
    }

    /**
     * On page init, get the routing reference, available dates, associated site
     * Then get data from the period (1 year, most recent one with data by default)
     */
    initFromParams(): void {
        if (this.route.params) {
            this.route.params.subscribe(async params => {
                try {
                    this.setAllLoadings(true);
                    this.contract = params.contractId;
                    this.routingRef = params.rfId;

                    // #1 Load year available
                    const years = await this.energyPdlViewService.getYears(
                        this.contract,
                        this.routingRef,
                        this.energyType
                    );

                    if (years && years.data && years.data.length) {
                        this.years = years.data;
                        this.years.sort((a, b) => b - a);
                    }
                    this.dju.isDjuActivated = true;

                    // #2 Load routing reference concerned
                    const routingReference = await this.energyPdlViewService.getRoutingReferenceById(this.routingRef);
                    this.data = routingReference.data;
                    this.selectedContract = this.data.contracts.find(contract => contract._id === this.contract);

                    // #3 Load site with routing reference
                    const site = await this.siteService.getSiteFromRoutingReference(this.data._id, this.data.company);

                    // if routing reference isn't linked to any site
                    if (!_.isEmpty(site)) {
                        this.site = site;
                    }
                    this.loading.site = false;
                } catch (error) {
                    this.years = this.years ? this.years : [];
                    this.periodSelected = this.periodSelected
                        ? this.periodSelected
                        : { dateStart: null, dateEnd: null };
                    this.data = this.data ? this.data : null;
                    this.selectedContract = this.selectedContract ? this.selectedContract : null;
                    this.site = null;
                    this.loading.site = false;
                    const msg = this.data ? 'du site' : 'de votre PDL';
                    Swal.fire('Toutes nos excuses', "Une erreur s'est produite lors du chargement " + msg, 'error');
                }
            });
        }
    }

    /**
     * Get month data grouped by month
     * @param {DatePeriod} period - period start and end dates
     * @return {Promise<CoolingMonthlyBill[]>}
     */
    async getMonthDatas(period: DatePeriod): Promise<CoolingMonthlyBill[]> {
        const result = await this.pdlService.getByMonthOrYear(
            this.energyType,
            this.contract,
            this.routingRef,
            period.dateStart,
            period.dateEnd
        );

        return result.data;
    }

    /**
     * Change DJU display for the consumption chart
     * @param {boolean} isDjuActivated
     */
    djuActivateChanged(isDjuActivated: boolean): void {
        isDjuActivated = isDjuActivated && this.dju.djusAvailable;
        this.dju.isDjuActivated = isDjuActivated;

        /**
         * First, we need to update data (so that it's saved depending on other actions)
         * Then, we need to update the chart display.
         */
        const djuLabel = 'Degrés jours unifiés (par mois)';
        const kwhDjuButton = 'kWh/dju';
        const labelsLegends = [['cooling_energy', 'cooling_volume'], ['cooling_energy']];
        if (isDjuActivated) {
            labelsLegends[0].unshift('djus');
        }
        const legends = labelsLegends.map(x => this.chartService.getChartLegends(x));

        this.djuService.toggleDjuInDatasetAndChart(
            isDjuActivated,
            this.consoChartProperties,
            djuLabel,
            legends,
            kwhDjuButton,
            this.chartBarConso,
            'y-axis-2'
        );
    }

    /**
     * Set ratio table data from bills data aggregated per year
     * @param {CoolingYearlyBill[]} data - yearly data
     */
    setRatioTable(dataPerYear: CoolingYearlyBill[]): void {
        this.ratioTable = [];
        dataPerYear.forEach(yd => {
            const quantity = yd.consumption.energy.quantity;

            this.ratioTable.push({
                periodLabel: yd.year,
                ratios: {
                    consumption: this.formatNumberDefaultToNC((yd.consumption.energy.amount / quantity) * 100, 'money'),
                    subscription: this.formatNumberDefaultToNC(
                        ((yd.subscription.r2.amount + yd.subscription.connection.amount) / quantity) * 100,
                        'money'
                    ),
                    totalHTVA: this.formatNumberDefaultToNC((yd.totalHT.amount / quantity) * 100, 'money'),
                    totalTTC: this.formatNumberDefaultToNC((yd.totalTTC.amount / quantity) * 100, 'money'),
                },
            });
        });
    }

    /**
     * Get total conso tile html value
     * @returns {string} HTML value string
     */
    getTotalConso(): string {
        const energy = this.tilesService.getNumberToDisplay(this.consoData.energy, 'kWh');
        const volume = this.tilesService.getNumberToDisplay(this.consoData.volume, 'm3');
        return `<b>${energy}</b> kWh - <b>${volume}</b> m3`;
    }

    /**
     * Get total cost conso value
     * @returns {string} HTML value string
     */
    getTotalCost(): string {
        const totalHT = this.tilesService.getNumberToDisplay(this.totalHT, '€');
        const totalTTC = this.tilesService.getNumberToDisplay(this.totalTTC, '€');
        return `<b>${totalHT}</b> € HT - <b>${totalTTC}</b> € TTC`;
    }

    /*
     * Set in storage the current site in last visited sites for dashboard display
     */
    setLastVisitedSites(site) {
        this.sessionService.setLastVisitedSites(site);
    }

    /**
     *  Grab month data and DJU if needed on selected period for charts.
     * @return {Promise<{djus: any[], monthDatas: CoolingMonthlyBill[]}>}
     */
    async getRfMonthlyConsumption(): Promise<{ djus: Dju[]; monthDatas: CoolingMonthlyBill[] }> {
        try {
            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');
            const djuPromise: Promise<Dju[]> =
                this.site && this.site._id
                    ? this.djuService.getSiteDJUs(dateStart, dateEnd, this.site).catch(() => null)
                    : (async () => null)();
            const promiseArray: [Promise<CoolingMonthlyBill[]>, Promise<Dju[]>] = [
                this.getMonthDatas({ dateStart, dateEnd }),
                djuPromise,
            ];
            const [monthDatas, djus] = await Promise.all(promiseArray);
            this.dju.djusAvailable = Boolean(djus && djus.length);

            return { djus, monthDatas };
        } catch (error) {
            this.loading.consoChart = false;
            this.loading.costChart = false;
            this.loading.monthlyCostTable = false;
            return { djus: null, monthDatas: [] };
        }
    }

    /**
     * Grab yearly consumption with 2 years historic for ratio table.
     * @return {Promise<CoolingYearlyBill[]>}
     */
    async getRfYearlyConsumptionWithHistoric(): Promise<CoolingYearlyBill[]> {
        try {
            this.loading.ratioTable = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('year')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('year')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                this.energyType,
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                true,
                2
            );
            return response.data;
        } catch (error) {
            this.loading.ratioTable = false;
            return [];
        }
    }

    /**
     *  Grab yearly consumption for donut.
     * @return {Promise<CoolingYearlyBill[]>}
     */
    async getYearlyConsumptionForDonut(): Promise<CoolingYearlyBill[]> {
        try {
            this.loading.repartitionChart = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                this.energyType,
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                true
            );

            return response.data;
        } catch (error) {
            this.loading.repartitionChart = false;
            return [];
        }
    }

    /**
     * Set totals values for tiles
     * @param {CoolingMonthlyBill[]} dataPerMonth - monthly data
     */
    setTotals(dataPerMonth: CoolingMonthlyBill[]): void {
        this.totalTTC = 0;
        this.totalHT = 0;

        if (dataPerMonth && dataPerMonth.length) {
            let consoEnergy = 0;
            let consoVolume = 0;
            dataPerMonth.forEach(monthData => {
                // Cost
                this.totalHT += monthData.totalHT.amount;
                this.totalTTC += monthData.totalTTC.amount;
                // Conso
                consoEnergy += monthData.consumption.energy.quantity;
                consoVolume += monthData.consumption.volume.quantity;
            });

            this.costData = {
                label: 'Total des coûts',
                data: this.totalHT,
            };

            this.consoData = {
                label: 'Consommation totale',
                energy: consoEnergy,
                volume: consoVolume,
            };
        } else {
            this.consoData = {
                label: 'Consommation totale',
                energy: 0,
                volume: 0,
            };
        }
    }

    /**
     * Set conso chart data from dju data and month data
     * @param {Array<any>} djuData - dju data per month
     * @param {CoolingMonthlyBill[]} monthData - bills' data aggregated per month
     */
    setConsoChartData(djuData: Dju[], monthData: CoolingMonthlyBill[]): void {
        const labels = ['cooling_energy', 'cooling_volume'];
        if (djuData) {
            labels.unshift('djus');
        }

        this.consoChartProperties.datasets[0] = this.billService.getCoolingMonthData(djuData, monthData);
        this.consoChartProperties.labels = this.chartService.getMonthsLabel(monthData);

        const configType = 'mixed';
        this.consoChartProperties.options = this.chartService.getDatasetsAxesOptions(
            this.consoChartProperties.datasets,
            this.chartService.getConfig(configType, {
                tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line'),
            })
        );

        this.consoChartProperties.toggleTitles = [{ name: 'kWh | m3', display: true }];
        this.consoChartProperties.legend[0] = this.chartService.getChartLegends(labels);
        this.consoChartProperties.colors[0] = this.chartService.getBarChartColors(labels);

        if (djuData) {
            this.consoChartProperties.toggleTitles.push({ name: 'kWh/dju', display: true });
            const djuDataset = this.billService.getCoolingMonthDataDjuRatio(djuData, monthData);
            this.consoChartProperties.datasets[1] = djuDataset;

            this.consoChartProperties.options[1] = this.chartService.getSeriesAxesOptions(
                djuDataset,
                this.chartService.getConfig('bar', {
                    tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line'),
                })
            );

            this.consoChartProperties.legend[1] = this.chartService.getChartLegends(['cooling_energy']);
            this.consoChartProperties.colors[1] = this.chartService.getBarChartColors(['cooling_energy']);
        }
    }

    /**
     * Populate conso line chart and costs line chart.
     * @param {Array<*>} djuData - dju data per month
     * @param {CoolingMonthlyBill[]} monthData - month data per month
     */
    handleMonthData(djuData: Dju[], monthData: CoolingMonthlyBill[]): void {
        if (monthData && monthData.length) {
            this.setConsoChartData(djuData, monthData);
            this.setCostsChartData(monthData);
            this.setMonthlyCostTable(monthData);
        }

        this.loading.consoChart = false;
        this.loading.costChart = false;
        this.loading.monthlyCostTable = false;
    }

    /**
     * Populate ratio table with yearly values over the last 3 years.
     * @param {CoolingYearlyBill[]} yearlyConsumptionWithHisto
     */
    handleRatioTable(yearlyConsumptionWithHisto: CoolingYearlyBill[]): void {
        if (yearlyConsumptionWithHisto && yearlyConsumptionWithHisto.length) {
            this.setRatioTable(yearlyConsumptionWithHisto);
        }

        this.loading.ratioTable = false;
    }

    /**
     * Populate costs repartition donut from data aggregated per year
     * @param {CoolingYearlyBill[]} yearlyConsumption - global consumption over the period
     */
    handleCostsDonut(yearlyConsumption: CoolingYearlyBill[]): void {
        if (yearlyConsumption && yearlyConsumption.length) {
            this.setCostsDonut(yearlyConsumption);
        }
        this.loading.repartitionChart = false;
    }

    /**
     * Set cost donut data
     * @param {CoolingYearlyBill[]} globalData - global data for the donut, per year
     */
    setCostsDonut(globalData: CoolingYearlyBill[]): void {
        const sum = {
            consumption: 0,
            subscription: 0,
        };

        globalData.forEach(dataPerYear => {
            sum.consumption += dataPerYear.consumption.energy.amount + dataPerYear.consumption.volume.amount;

            sum.subscription += dataPerYear.subscription.r2.amount + dataPerYear.subscription.connection.amount;
        });

        this.costsDonutProperties = {
            options: [],
            legend: [],
            title: 'Répartition des coûts',
        };
        this.costsDonutProperties.legend = this.chartService.getChartLegends([
            'cooling_consumption',
            'cooling_subscription',
        ]);
        const dataForChart = [Math.round(sum.consumption * 100) / 100, Math.round(sum.subscription * 100) / 100];
        this.costsDonutProperties.options = this.chartService.getHighchartDonutConfig(
            dataForChart,
            ['cooling_consumption', 'cooling_subscription'],
            '€'
        );
    }

    /**
     * @returns {boolean} true if has data for the cost donut, false otherwise
     */
    public hasDataForCostDonut(): boolean {
        try {
            const chartOptions = this.costsDonutProperties.options;
            return (
                chartOptions &&
                chartOptions.series &&
                chartOptions.series.some(serie => serie && serie.data && serie.data.some(d => d.y))
            );
        } catch (err) {
            return false;
        }
    }

    /**
     * Set costs chart data
     * @param {CoolingMonthlyBill[]} monthData - bills' data aggregated per month
     */
    setCostsChartData(monthData: CoolingMonthlyBill[]) {
        const arrayData = [];
        const monthLabels = [];

        monthData.forEach(x => {
            let cost = 0;
            cost += x.consumption.energy.amount || 0;
            cost += x.consumption.volume.amount || 0;

            arrayData.push(cost);
            monthLabels.push(
                moment()
                    .month(x.month)
                    .year(x.year)
                    .format('MM/YYYY')
            );
        });

        // [0] : total
        // [1] : energy/volume
        const totalIndex = 0;
        const enerVolIndex = 1;
        this.costsChartProperties.datasets[totalIndex] = this.billService.getCoolingMonthData(
            null,
            monthData,
            true,
            true
        );
        this.costsChartProperties.datasets[enerVolIndex] = this.billService.getCoolingMonthData(
            null,
            monthData,
            true,
            false
        );

        this.costsChartProperties.labels = this.chartService.getMonthsLabel(monthData);
        this.costsChartProperties.options = this.chartService.getDatasetsAxesOptions(
            this.costsChartProperties.datasets,
            this.chartService.getConfig('mixed', {
                tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line', '€'),
            })
        );

        // Hide all y axis not defined in datasets for the total
        this.costsChartProperties.options[totalIndex].scales.yAxes.forEach(y => {
            const ids = this.costsChartProperties.datasets[totalIndex].map(x => x.yAxisID);
            if (!ids.includes(y.id)) {
                y.display = false;
            }
        });
        this.costsChartProperties.toggleTitles = [
            { name: 'Total', display: true },
            { name: 'Energie|Volume', display: true },
        ];
        this.costsChartProperties.legend[totalIndex] = this.chartService.getChartLegends(['cooling_total_cost']);
        this.costsChartProperties.colors[totalIndex] = this.chartService.getBarChartColors(['cooling_total_cost']);
        this.costsChartProperties.legend[enerVolIndex] = this.chartService.getChartLegends([
            'cooling_energy',
            'cooling_volume',
        ]);
        this.costsChartProperties.colors[enerVolIndex] = this.chartService.getBarChartColors([
            'cooling_energy',
            'cooling_volume',
        ]);

        return arrayData;
    }

    /**
     * Get data per month formatted for the cost monthly table
     * @param {CoolingMonthlyBill[]} monthData - month data per month
     */
    setMonthlyCostTable(monthData: CoolingMonthlyBill[]): void {
        this.monthlyCostTable = [];

        monthData.forEach(md => {
            this.monthlyCostTable.push({
                periodLabel: moment()
                    .month(md.month)
                    .year(md.year)
                    .format('MM/YYYY'),
                consumption: {
                    energyAmount: this.formatNumberDefaultToNC(md.consumption.energy.amount, 'money'),
                    volumeAmount: this.formatNumberDefaultToNC(md.consumption.volume.amount, 'money'),
                    energyQty: this.formatNumberDefaultToNC(md.consumption.energy.quantity),
                    volumeQty: this.formatNumberDefaultToNC(md.consumption.volume.quantity),
                    totalAmount: this.formatNumberDefaultToNC(
                        md.consumption.energy.amount + md.consumption.volume.amount,
                        'money'
                    ),
                },
                subscription: {
                    r2: this.formatNumberDefaultToNC(md.subscription.r2.amount, 'money'),
                    power: this.formatNumberDefaultToNC(md.subscription.power.quantity, 'fluid'),
                },
                miscellaneous: {
                    deltaT: this.formatNumberDefaultToNC(md.miscellaneous.deltaT.quantity, 'percent'),
                },
                totalHT: { total: this.formatNumberDefaultToNC(md.totalHT.amount, 'money') },
                totalTTC: { total: this.formatNumberDefaultToNC(md.totalTTC.amount, 'money') },
            });
        });
    }

    /**
     * Format value for display
     * @param value - value to format
     * @param type - type of value ('money', 'percentage', 'fluid', ...)
     */
    formatNumberDefaultToNC(value: number | null, type = 'fluid'): string {
        return this.utilsService.formateNumberType(value, 'NC', type);
    }

    /**
     * @returns {boolean} true if routing reference and site loaded, false otherwise
     */
    isRoutingReferenceLoaded(): boolean {
        return Boolean(this.data && this.data._id && !this.loading.site);
    }

    canShowCostChart(): boolean {
        return Boolean(!this.loading.costChart && this.costsChartProperties.datasets.length);
    }

    canShowConsoChart(): boolean {
        return Boolean(!this.loading.consoChart && this.consoChartProperties.datasets.length);
    }

    canShowDataPerMonth(): boolean {
        return Boolean(!this.loading.monthlyCostTable && this.monthlyCostTable);
    }

    canShowRatioTable(): boolean {
        return Boolean(!this.loading.ratioTable && this.ratioTable.length);
    }

    getExportExcelUrl(type: string) {
        return this.pdlService.getExportExcelUrl(
            type,
            this.periodSelected,
            this.routingRef,
            this.contract,
            this.dju.isDjuActivated,
            this.energyType
        );
    }

    getExportName(txt: string) {
        return this.pdlService.getExportName(txt, this.periodSelected, this.data, this.selectedContract);
    }

    hasSite(): boolean {
        return Boolean(this.site);
    }
}
