import { CollectService } from 'app/pages/collect/collect.service';
import { PaginationService } from 'app/shared/components/pagination/front/pagination-front.service';
import { ForceType, UploadChunkRecap } from 'app/shared/models/upload-file.interface';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { UploadsMapping } from './recap-files.interface';

@Component({
    selector: 'ga-recap-files',
    templateUrl: './recap-files.component.html',
    styleUrls: ['./recap-files.component.scss'],
    providers: [PaginationService],
})
export class RecapFilesComponent implements OnInit {
    private _data: UploadChunkRecap[][];
    @Input() set data(data: UploadChunkRecap[][]) {
        this._data = data;
        // if collapseValue is 0, means that view is not init or empty
        if (this.collapseValue) {
            // Update file level checkbox if data changes (otherwise, after extract, still checked, while chunks aren't)
            this._data.forEach(chunks => {
                this.updateChunkChecked(chunks);
            });
        }
        this.refreshForceTypes();
    }
    get data(): UploadChunkRecap[][] {
        return this._data;
    }

    public filesChecked: { [filename: string]: boolean } = {};
    private filesCollapsed: { [filename: string]: boolean } = {};
    /**
     * Array of force type i18n key where each item index must match `_data` index
     */
    public dataForceTypeKeys: string[] = [];
    // Number of files in recap, used to check changes and trigger collapse reset
    private collapseValue = 0;
    @Input() disableActions = false;
    @Output() extractClicked: EventEmitter<UploadsMapping[]> = new EventEmitter<UploadsMapping[]>();
    @Output() forceExtractClicked: EventEmitter<UploadsMapping[]> = new EventEmitter<UploadsMapping[]>();
    @Output() updateExtractSelectionButton: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public collectService: CollectService, private paginationService: PaginationService) {}
    ngOnInit() {
        this.filesChecked = {};
        this.initCollapse();
    }

    private initCollapse() {
        this.data.forEach(file => {
            this.filesChecked[file[0].filename] = false;
            this.filesCollapsed[file[0].filename] = true;
        });
        this.collapseValue = this.data.length;
    }

    public resetCollapse() {
        if (this.data.length !== this.collapseValue) {
            this.collapseValue = Object.keys(this.filesChecked).length;
            this.initCollapse();
        }
        return true;
    }

    public collapse(file: UploadChunkRecap[]) {
        this.filesCollapsed[file[0].filename] = !this.filesCollapsed[file[0].filename];
    }

    public isCollapsedClass(file: UploadChunkRecap[]): string {
        return this.filesCollapsed[file[0].filename] ? 'uncollapsed-chevron' : 'collapsed-chevron';
    }

    public isCollapsed(file: UploadChunkRecap[]): boolean {
        return this.filesCollapsed[file[0].filename];
    }

    public getNbMissingProperties(element: UploadChunkRecap): number {
        if (!element.missingProperties) {
            return 0;
        }

        return element.missingProperties.length;
    }

    /**
     * Returns true if all chunks that can be checked are checked (=> checked or already extracted)
     * @param {Array<UploadChunkRecap>} chunk
     * @return {boolean}
     */
    private areAllChunksChecked(chunks: UploadChunkRecap[]): boolean {
        return chunks.every(chunk => this.isExtracted(chunk.state) || chunk.checked === true);
    }

    private someFilesOrChunkedChecked(): boolean {
        for (const file of this.data) {
            if (file.some(chunk => !this.isExtracted(chunk.state) && chunk.checked === true)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Check all file chunks
     */
    public checkAllFileChunks(chunks: UploadChunkRecap[]) {
        // A chunk is checked when it is already extracted or checked. We can not check a chunk which is already extracted.
        const allChunksChecked = this.areAllChunksChecked(chunks);

        const valueToPut = !allChunksChecked;
        chunks.forEach(chunk => {
            const isExtracted = this.isExtracted(chunk.state);
            chunk.checked = !isExtracted && valueToPut;
        });
    }

    /**
     * Update chunk checked
     */
    public updateChunkChecked(chunks: UploadChunkRecap[]) {
        const allChunksChecked = this.areAllChunksChecked(chunks);

        this.filesChecked[chunks[0].filename] = allChunksChecked;

        this.manageExtractAllButton();
    }

    /**
     * Enable extract selection button if some files/chunks are checked, disable if nothing is checked
     */
    public manageExtractAllButton() {
        const somethingChecked = this.someFilesOrChunkedChecked();
        this.updateExtractSelectionButton.emit(somethingChecked);
    }

    /**
     * Handle extract button clicked
     */
    public singleFileExtract(chunks: UploadChunkRecap[], action: 'extract' | 'forceExtract') {
        const checkedChunks = this.getListOfChunks(chunks, true);
        let chunkToExtract = checkedChunks;

        if (!checkedChunks || !checkedChunks[0] || !checkedChunks[0][chunks[0].uploadId].length) {
            const errorChunks = this.getListOfChunks(chunks, false);
            if (errorChunks.length === 0) {
                this.getSwal('', 'Aucun chunk à extraire', 'error');
                return;
            }
            chunkToExtract = errorChunks;
        }

        switch (action) {
            case 'extract':
                this.extractClicked.emit(chunkToExtract);
                break;
            case 'forceExtract':
                this.forceExtractClicked.emit(chunkToExtract);
                break;
        }
    }

    /**
     * Get list of checked or unchecked chunks
     * @param {boolean} checked - Specifies whether to get checked chunks (true) or unchecked chunks (false) in error states.
     */
    private getListOfChunks(chunks: UploadChunkRecap[], checked: boolean): UploadsMapping[] {
        // for the moment every bill belongs to the same upload -> all the same filename
        const formatedArrayCheckedFiles: UploadsMapping[] = [];
        const filesByUpload: UploadsMapping = {};

        if (checked) {
            filesByUpload[chunks[0].uploadId] = chunks.filter(c => c.checked);
        } else {
            filesByUpload[chunks[0].uploadId] = chunks.filter(c => !this.isExtracted(c.state));
        }

        if (filesByUpload[chunks[0].uploadId]) {
            formatedArrayCheckedFiles.push(filesByUpload);
        }

        return formatedArrayCheckedFiles;
    }

    /**
     * Display swal
     */
    private getSwal(title: string, subtitle: string, type: any) {
        return Swal.fire(title, subtitle, type);
    }

    /**
     * Get color related to state
     */
    public getColor(state: string): string {
        return this.collectService.infos[this.collectService.stateInfo[state].type].color;
    }

    /**
     * Get icon class related to state
     */
    public getIconClass(state: string): string {
        return this.collectService.infos[this.collectService.stateInfo[state].type].icon;
    }

    /**
     * Get message related to state
     */
    public getMessage(state: string): string {
        return this.collectService.stateInfo[state].message;
    }

    /** Pagination **/
    get filesSorted(): UploadChunkRecap[][] {
        return this.data.slice(
            this.paginationService.pagination.indexStart,
            this.paginationService.pagination.indexEnd + 1
        );
    }

    /**
     * used to disable the checkbox of the file chunk element if it is already extracted
     * @param {string} state - state of the chunk element
     * @returns {boolean}
     */
    public isExtracted(state: string): boolean {
        return [this.collectService.infoType.SUCCESS].includes(_.get(this.collectService.stateInfo[state], 'type'));
    }

    /**
     * used to disabled the checkbox of file which is already extracted
     * @param {UploadChunkRecap[]} file
     * @returns {boolean}
     */
    public isFileFullyExtracted(file: UploadChunkRecap[]): boolean {
        return file.every(chunk => this.isExtracted(chunk.state));
    }

    /**
     * Refresh dataForceTypeKey
     */
    private refreshForceTypes() {
        this.dataForceTypeKeys = this.data.map(data => {
            const forceType = this.collectService.getForceTypeFromStates(data, true);
            return this.getForceTypeKey(forceType);
        });
    }

    /**
     * Get i18n key related to force button
     * @param forceType
     */
    private getForceTypeKey(forceType: ForceType): string {
        if (!forceType) {
            return null;
        }
        if (forceType === ForceType.QUALITY) {
            return 'force_quality';
        }
        return null;
    }
}
