import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

// services
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { MapService } from 'app/shared/services/map/map.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { SitesListService } from './sites-list.service';

import { combineLatest, Subscription } from 'rxjs';
import Swal from 'sweetalert2';

// interfaces
import { Marker, MarkerType } from 'app/shared/models/marker.interface';
import { RoutingReferencePopulated } from 'app/shared/models/routing-reference.interface';
import { SitePopulated, SitePopulatedContracts } from 'app/shared/models/site.interface';
import { VehiclePopulated } from 'app/shared/models/vehicle.interface';
import { SortService } from 'app/shared/services/sort/sort.service';

@Component({
    selector: 'ga-sites-list',
    templateUrl: './sites-list.component.html',
    styleUrls: ['./sites-list.component.scss'],
    providers: [SitesListService],
})
export class SitesListComponent implements OnInit, OnDestroy {
    sites: SitePopulated[];
    pdls: RoutingReferencePopulated[];
    vehicles: VehiclePopulated[];
    markers: Marker[] = [];
    dataTiles: any[] = [];
    subTableTitle = '';
    subTableItems: Array<RoutingReferencePopulated | VehiclePopulated> = []; // elements from sites to display under the sites list (routing references / vehicles)
    currentSelection: SitePopulatedContracts = null;
    fluidCategory: string;
    fluidCategoryClass: string;
    fluidsAccepted: string[] = [];
    companies: Array<{ displayName: string; value: string }> = [];
    companyId: string = null;
    /**
     * Holds the Ids of the selected companies
     * @private
     */
    private companyIds: string[] = [];

    siteSubscription: Subscription = null;

    /**
     * Indicates if sites list is loading
     */
    public isLoading = false;

    constructor(
        private pdlService: SitesListService,
        private tilesService: TilesService,
        private route: ActivatedRoute,
        private router: Router,
        private mapService: MapService,
        private energyService: EnergyService,
        private filterService: FilterService,
        private sortService: SortService
    ) {}

    ngOnInit() {
        /**
         * combineLatest : When any observable emits a value, emit the latest value from each.
         * We don't want it to be triggered by a siteId param change
         */
        combineLatest([this.route.queryParams, this.route.data]).subscribe({
            next: ([params, data]) => {
                /**
                 * Update fluid category if it has changed
                 */
                const hasRouteDataChanged: boolean = this.handleRouteDataChanges(data);
                const hasCompaniesChanged: boolean = this.handleCompanyIdsChanges(params);
                /**
                 * If one of the data above changed, refresh sites list
                 */
                if (hasRouteDataChanged || hasCompaniesChanged) {
                    this.refreshSitesDisplayed(params);
                }
            },
        });
        this.initCompanies();
    }

    /**
     * Handles the changes in the Router data. Returns true if the data changed
     * @param {any} data
     * @returns {boolean} hasDataChanged
     */
    private handleRouteDataChanges(data: any): boolean {
        const hasDataChanged: boolean = Boolean(
            data && data.fluidCategory && data.fluidCategory !== this.fluidCategory
        );
        if (!hasDataChanged) {
            return false;
        }
        this.fluidCategory = 'energy'; // default value
        if (data && data.fluidCategory && this.energyService.getAllFluidCategories().includes(data.fluidCategory)) {
            this.fluidCategory = data.fluidCategory;
        }
        this.fluidsAccepted = this.energyService.getFluidsByCategory(this.fluidCategory);
        this.fluidCategoryClass = this.energyService.getFluidCategoryFrench(this.fluidCategory);
        this.setSubTableProperties();
        return true;
    }

    /**
     * Checks if company Ids' in parameters changed and updates the component internal state accordingly
     * @param {Params} params - the parameters containing the companyIds
     * @returns {boolean} hasCompanyIdsChanged - true if the companyIds have changed
     */
    private handleCompanyIdsChanges(params: Params): boolean {
        if (
            !params ||
            !this.filterService.getParamAliasValue(params, 'companies') ||
            this.companyIds.join(',') === this.filterService.getParamAliasValue(params, 'companies')
        ) {
            return false;
        }
        this.companyIds = this.filterService.getParamAliasValues(params, 'companies', []);
        return true;
    }

    /**
     * Checks if the companyIds in the url parameters changed and updates the internal companyIds if necessary
     * @param {Params} params - the parameters containing the companyIds
     * @returns {Promise<void>}
     */
    private async refreshSitesDisplayed(params: Params): Promise<void> {
        this.emptyRoutingReferencesTable();
        const loadDefaultSite: boolean = !Boolean(params && params.siteId);
        await this.refreshSites(loadDefaultSite);
    }

    /**
     * Refresh sites list with current company and fluid
     * @param {boolean} loadDefaultSite - if true, will load default site in list (unless it can't be loaded), if false doesn't load default site
     * Default site is the first in list.
     * @returns {Promise<void>}
     */
    private async refreshSites(loadDefaultSite: boolean = true): Promise<void> {
        try {
            const energies: string = this.fluidsAccepted.join(',');
            this.isLoading = true;
            this.sites = [];
            const response: SitePopulated[] = await this.pdlService.getSitesPopulatedFiltered({
                energies,
                companyId: this.companyId,
                companies: this.companyIds.join(','),
            });

            this.currentSelection = null;

            this.sites = response;

            if (this.sites && this.sites.length && (loadDefaultSite || !this.isCurrentSiteInSitesList)) {
                let currentSite = this.sites[0];

                for (let i = 0; i < this.sites.length - 1; i++) {
                    const siteToCompare = this.sites[i];
                    const result = this.sortService.compareStrings(currentSite.complement, siteToCompare.complement);
                    currentSite = result === 1 ? siteToCompare : currentSite;
                }

                this.selectSite(currentSite._id);
            }

            let type: MarkerType = 'energy';
            if (this.fluidCategory === 'water') {
                type = 'water';
            } else if (this.fluidCategory === 'vehicle') {
                type = 'fuel';
            }
            this.markers = this.mapService.getMarkersFromSites(this.sites, type);

            /**
             * Refresh subscription of queryParams for siteId on site selected
             */
            this.subscribeSiteParam();
            this.isLoading = false;
        } catch (error) {
            this.sites = [];
            this.pdls = [];
            this.vehicles = [];
            this.markers = [];
            this.subTableItems = [];
            this.isLoading = false;
            Swal.fire('Toutes nos excuses', "Une erreur s'est produite lors du chargement de vos sites", 'error');
        }
    }

    /**
     * Subscribe to site in queryParameters and change the selected site if necessary
     */
    private subscribeSiteParam() {
        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }
        this.siteSubscription = this.route.queryParams
            .pipe(
                filter(params => 'siteId' in params),
                map(params => params.siteId),
                distinctUntilChanged()
            )
            .subscribe({
                next: siteId => {
                    if (siteId) {
                        this.setSelectedSite(siteId);
                    }
                },
            });
    }

    /**
     * Init companies by getting the branches (for the select box in case of linked companies)
     */
    private async initCompanies() {
        this.companies = await this.filterService.getCompanyAndAllBranchesForFilter();
    }

    /**
     * Set the name of the sub table where are displayed the routing references or vehicles.
     * Set if the table's items must be filtered per fluid.
     * Depend on the fluid category.
     */
    private setSubTableProperties() {
        this.subTableTitle = this.isSourceRoutingReference() ? 'Points de livraison du site' : 'Véhicules du site';
    }

    /**
     * Empty the routing references table
     */
    private emptyRoutingReferencesTable() {
        this.subTableItems = [];
    }

    /**
     * Create data tiles for site information : number of active and inactive for every fluid accepted
     */
    private createTilesFromSite() {
        const total: {
            [key: string]: { enabled: number; disabled: number };
        } = {};
        this.fluidsAccepted.forEach(energy => (total[energy] = { enabled: 0, disabled: 0 }));

        this.pdls.forEach(pdl => {
            if (Array.isArray(pdl)) {
                pdl = pdl[0];
            }

            if (this.fluidsAccepted.includes(pdl.energyType)) {
                if (pdl.status && pdl.status.active) {
                    total[pdl.energyType].enabled++;
                } else {
                    total[pdl.energyType].disabled++;
                }
            }
        });

        if (this.fluidsAccepted.includes('fuel')) {
            total['fuel'] = { enabled: this.vehicles.length, disabled: 0 };
        }

        const messageSource = this.isSourceRoutingReference() ? 'PDL ACTIF' : 'VEHICULE';
        this.dataTiles = Object.keys(total)
            .filter(energy => total[energy].enabled > 0 || total[energy].disabled > 0)
            .map(energy => {
                const messagePlural = total[energy].enabled > 1 ? 'S' : '';
                const tile: any = this.tilesService.getTile(
                    energy,
                    total[energy].enabled,
                    messageSource + messagePlural
                );
                tile.modifier = 'full';
                return tile;
            });
    }

    /**
     * Returns true if fluid category is either energy or water, false if vehicles
     * @returns {boolean}
     */
    private isSourceRoutingReference() {
        return ['energy', 'water'].includes(this.fluidCategory);
    }

    /**
     * Set selected site
     * @param {string} siteId - site string id
     */
    private async setSelectedSite(siteId: string) {
        try {
            this.currentSelection = await this.pdlService.getSitePopulatedWithContractsDates(siteId);

            this.setItemsForSubtable(this.currentSelection.vehicles, this.currentSelection.routingReferences);

            this.createTilesFromSite();
        } catch (error) {
            this.pdls = [];
            this.vehicles = [];
            this.createTilesFromSite();
        }
    }

    /**
     * Set routing references & vehicles for sub-table. Selected the items to display.
     * @param {VehiclePopulated[]} vehicles
     * @param {RoutingReferencePopulated[]} routingReferences
     */
    private setItemsForSubtable(vehicles: VehiclePopulated[], routingReferences: RoutingReferencePopulated[]) {
        this.vehicles = vehicles;
        this.pdls = routingReferences;
        this.subTableItems = this.isSourceRoutingReference() ? this.pdls : this.vehicles;
    }

    /**
     * Synchonously get site by id in sites list
     * @param {string} siteId - site id to get
     * @returns {SitePopulated} site object
     */
    private getSiteById(siteId: string): SitePopulated {
        return this.sites.find(site => site._id === siteId);
    }

    /**
     * Is current site in sites list
     * @returns {boolean} true if currently selected site is in sites list or no selection, false otherwise.
     */
    private isCurrentSiteInSitesList(): boolean {
        if (!this.currentSelection) {
            return true;
        }
        return Boolean(this.getSiteById(this.currentSelection._id));
    }

    /**
     * Select site in table when a map marker is selected.
     * @param marker
     */
    onMarkerSelect(marker: Marker) {
        this.selectSite(marker.data._id);
    }

    /**
     * Action on site selected in list.
     * Update queryParams for siteId that will trigger queryParams subscription for site id
     * @param {string} siteId - site id selected
     */
    public selectSite(siteId: string) {
        // changes the route without moving from the current view or
        // triggering a navigation event
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { siteId },
            // preserve the existing query params in the route
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        });
    }

    ngOnDestroy() {
        if (this.siteSubscription) {
            this.siteSubscription.unsubscribe();
        }
    }
}
