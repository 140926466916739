import { HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { KeyFigures } from 'app/shared/models/key-figure.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { FileStorageService } from 'app/shared/services/files/file-storage.service';
import { PageService } from 'app/shared/services/page/page.service';

import { Observable } from 'rxjs';

@Injectable()
export class AdminCompanyService extends PageService {
    constructor(
        public apiService: ApiService,
        private sanitizer: DomSanitizer,
        private fileStorageService: FileStorageService
    ) {
        super(apiService);
        this.fake = false;
    }

    /**
     * Load the company from its id
     *
     * @param {string} id - id of the company to load
     * @returns {Promise<*>}
     */
    async getCompanyById(id: string): Promise<any> {
        return this.get('/api/companies/crud/' + id);
    }

    // list all the organizations: Entreprise/Santé/Commune/Collectivité - Région/Collectivité - Département...
    getCompanyProperties(): Promise<any> {
        return this.get('/api/sites/all/available/properties');
    }

    async updateCompany(company): Promise<any> {
        try {
            const route = '/api/companies/crud/' + company._id;
            const response = await this.put(route, company, null, false);
            return response;
        } catch (err) {
            if (!err.errorCode) {
                err.errorCode = 'error_updateCompany';
            }
            throw err;
        }
    }

    getUploadPatternMissingList(company): any {
        return this.get('/api/uploads/company/' + company._id + '/pattern-missing').then(
            response => {
                if (response.code === 200) {
                    return Promise.resolve(response.data);
                } else {
                    const err = { error_code: 'error_getListUploads' };
                    return Promise.reject(err);
                }
            },
            err => {
                err.error_code = 'error_getListUploads';
                return Promise.reject(err);
            }
        );
    }

    getUploadNoPatternsList(company): any {
        return this.get('/api/uploads/company/' + company._id + '/no-patterns').then(
            response => {
                if (response.code === 200) {
                    return Promise.resolve(response.data);
                } else {
                    const err = { error_code: 'error_getListUploads' };
                    return Promise.reject(err);
                }
            },
            err => {
                err.error_code = 'error_getListUploads';
                return Promise.reject(err);
            }
        );
    }

    /**
     * Get keyfigures for all companies
     * @returns {Promise<*[]>} list of keyfigures
     */
    async getAllKeyFigures(): Promise<KeyFigures[]> {
        try {
            const response = await this.get('/api/keyfigures/all');
            return response.data;
        } catch (err) {
            err.errorCode = err.errorCode || 'error_getKeyFigures';
            return Promise.reject(err);
        }
    }

    async getLogoPathById(id: string): Promise<any> {
        try {
            const url = await this.fileStorageService.getUrl(id);
            return this.sanitizer.bypassSecurityTrustUrl(url);
        } catch (e) {
            e.error_code = 'error_logo_url';
            throw e;
        }
    }

    /**
     * Create and save the company.
     * Set the session user as a company's user or viewer based on his type.
     * @param company
     * @return {Promise<Company>}
     */
    async createCompany(company): Promise<any> {
        try {
            const route = '/api/companies/create';
            const res = await this.post(route, { company });
            return res.data;
        } catch (err) {
            if (!err.errorCode) {
                err.errorCode = 'error_createCompany';
            }
            throw err;
        }
    }

    /**
     * Upload and save picture in db + link its id to the company's logo / user's picture
     * @param {{fileObject: any, filename: string}} file - file to upload
     * @param {string} id - id of company/user
     * @param {"company"|"user"} model - model to upload to
     */
    uploadPicture(
        file: { fileObject: any; filename: string },
        id: string,
        model: 'company' | 'user'
    ): Observable<HttpEvent<{}>> {
        const formData: FormData = new FormData();
        const fieldName = model === 'company' ? 'companyId' : 'id';
        const param = model === 'company' ? '' : `/${id}`;
        formData.append(fieldName, id);
        formData.append('file', file.fileObject, file.filename);
        const params: HttpParams = model === 'company' ? new HttpParams({ fromObject: { companyId: id } }) : null;
        const options = {
            reportProgress: true,
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: 'Basic ' + this.apiService.getToken(),
            }),
            params,
        };
        return this.apiService.upload(`/api/pictures/upload/${model}${param}`, formData, options);
    }

    /**
     * Delete company's logo
     * @param {string} companyId - company to delete the logo from
     * @returns {Promise<*>} company updated with logo removed
     */
    async deletePictureCompany(companyId: string): Promise<any> {
        try {
            const route = `/api/pictures/company/${companyId}`;
            const res = await this.delete(route);
            return res.data;
        } catch (err) {
            err.error_code = 'error_deletePicture';
            return Promise.reject(err);
        }
    }

    deleteContactPermanently(contacToDelete): Promise<any> {
        const route = '/api/contacts/crud/' + contacToDelete._id;
        return this.delete(route);
    }

    /**
     * Set the picture url inside a new field 'logoUrl' inside a User object or a Company Object
     * @param usersOrCompaniesList: list of companies or users
     * @param pictureField: 'logo' or 'img'
     */
    async setPictures(usersOrCompaniesList, pictureField) {
        // get an array with each user/company and its img url
        // Array<{_id: string, imgUrl: string|null}>
        const results = await this.getPictures(usersOrCompaniesList, pictureField);

        results.forEach(result => {
            const userOrComp = usersOrCompaniesList.find(u => u._id === result['_id']);
            userOrComp.logoUrl = result['imgUrl']; // userOrComp can't be null, if it isn't found, will be caught later but should never happen
        });
        return true;
    }

    /**
     * Returns an array with the picture's url of each company/user
     * @param usersOrCompaniesList
     * @param pictureField
     * @return {Promise<{_id: string, imgUrl: string|null}[]>}
     */
    async getPictures(usersOrCompaniesList, pictureField) {
        // set an array with each user/company and its img url (null if not found)
        // Array<{_id: string, imgUrl: string|null}>
        const userAndPictures = await Promise.all(
            usersOrCompaniesList.map(
                userOrComp => this.getImg(userOrComp, pictureField) // {Promise<{_id: string, imgUrl: string|null}>}
            )
        );
        return userAndPictures;
    }

    /**
     * Returns the picture's url with the id of the user/company
     * @param userOrComp
     * @param pictureField
     * @return {Promise<{_id: string, imgUrl: string|null}>}
     */
    getImg(userOrComp, pictureField): Promise<any> {
        if (userOrComp[pictureField]) {
            return this.getLogoPathById(userOrComp[pictureField]).then(
                url => {
                    return Promise.resolve({ _id: userOrComp._id, imgUrl: url });
                },
                err => {
                    return Promise.resolve({ _id: userOrComp._id, imgUrl: null });
                }
            );
        } else {
            return Promise.resolve({ _id: userOrComp._id, imgUrl: null });
        }
    }

    async updateSite(siteId, data): Promise<any> {
        return this.put('/api/sites/' + siteId + '/move-company', data);
    }

    async createContact(contact) {
        try {
            const route = '/api/contacts/crud';
            const res = await this.post(route, contact);
            if (res.code === 200) {
                return res.data;
            } else {
                const err = { errorCode: 'error_createNewContact' };
                throw err;
            }
        } catch (err) {
            err.errorCode = 'error_createNewCompany';
            throw err;
        }
    }
}
