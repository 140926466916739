import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccessGuard } from '../../shared/services/guards/access-guard.service';
import { SharedModule } from '../../shared/shared.module';

import { BillsControlComponent } from './bills-control.component';

const BILLS_CONTROL_ROUTE = [
    {
        path: '',
        component: BillsControlComponent,
        canActivate: [],
    },
];

@NgModule({
    imports: [CommonModule, SharedModule, RouterModule.forChild(BILLS_CONTROL_ROUTE)],
    declarations: [BillsControlComponent],
})
export class BillsControlModule {}
