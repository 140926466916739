import { Component, DoCheck, EventEmitter, Input, IterableDiffers, OnInit, Output } from '@angular/core';

import { Page, PaginationConfig, PaginationIndexes } from '../pagination.interface';
import { PaginationService } from './pagination-front.service';

@Component({
    selector: 'ga-pagination',
    templateUrl: './pagination-front.component.html',
    styleUrls: ['./../pagination.component.scss'],
})
export class PaginationComponent implements OnInit, DoCheck {
    /**
     * Maximum number of elements to display on a page
     */
    @Input() paginationRange = 5;

    /**
     * Boolean to detemine if the option "see all" is available
     */
    @Input() canDisplayAll = false;

    /**
     * Elements to display in the table
     */
    @Input() elements: any[] = [];

    /**
     * Event to be emitted when pagination has changed.
     */
    @Output() paginationChanged: EventEmitter<PaginationIndexes> = new EventEmitter<PaginationIndexes>(true);

    differ: any;

    constructor(differs: IterableDiffers, private paginationService: PaginationService) {
        this.differ = differs.find([]).create(null);
    }

    ngOnInit(): void {
        this.paginationService.pagination.range = this.paginationRange;
        this.paginationService.pagination.canDisplayAll = this.canDisplayAll;
        this.initPaginationArray();
    }

    ngDoCheck(): void {
        const change = this.differ.diff(this.elements);
        if (change) {
            this.initPaginationArray();
        }
    }

    /**
     * Create pagination array from selected page & range
     */
    private initPaginationArray(): void {
        this.paginationService.pagesArray = [];

        // if 'all' is selected
        if (this.paginationService.pagination.range === 0) {
            this.paginationService.pagination.indexEnd = this.elements.length - 1;
            this.paginationService.pagination.countPages = 1;
        } else {
            this.paginationService.pagination.indexEnd = this.paginationService.pagination.range - 1;
            this.paginationService.pagination.countPages = Math.ceil(
                this.elements.length / this.paginationService.pagination.range
            );
            this.paginationService.pagination.countPages = this.paginationService.pagination.countPages || 1;
        }

        // fill the pagination array
        for (let i = 0; i < this.paginationService.pagination.countPages; i++) {
            this.paginationService.pagesArray.push({
                page: i + 1,
                usage: false,
                class: 'unused-button',
                display: true,
                displaySeparator: false,
            });
        }

        this.updatePaginationArray();
    }

    /**
     * Update the pagination array and the current elements indexes to display
     */
    private updatePaginationArray(): void {
        // If the page exists and isn't already selected
        if (
            this.paginationService.pagesArray &&
            this.paginationService.pagesArray[this.paginationService.pagination.currentPage - 1] &&
            this.paginationService.pagesArray[this.paginationService.pagination.currentPage - 1].usage !== true
        ) {
            this.paginationService.pagesArray.forEach(x => {
                const { currentPage, countPages } = this.paginationService.pagination;
                const differenceWithCurrentIndex = Math.abs(x.page - currentPage);
                // We display the first and last item, plus the ones beside the active one
                x.display = x.display = x.page === 1 || x.page === countPages || differenceWithCurrentIndex <= 1;

                // We display "..." beside the visible buttons
                x.displaySeparator = differenceWithCurrentIndex <= 2;

                // Set all other pages to unused
                if (x.page !== currentPage) {
                    x.usage = false;
                    x.class = 'unused-button';
                } else {
                    // Calculate start and end indexes
                    // Set used page
                    x.usage = true;
                    this.paginationService.pagination.indexStart =
                        (x.page - 1) * this.paginationService.pagination.range;
                    // if all is selected, range is 0
                    this.paginationService.pagination.indexEnd = this.paginationService.pagination.range
                        ? this.paginationService.pagination.indexStart + this.paginationService.pagination.range - 1
                        : this.elements.length - 1;
                    x.class = 'used-button citron-background-50';

                    this.paginationChanged.emit({
                        indexStart: this.paginationService.pagination.indexStart,
                        indexEnd: this.paginationService.pagination.indexEnd,
                    });
                }
            });
        } else {
            // if files were removed the page might have been deleted
            this.paginationService.pagination.currentPage = 1;
            this.updatePaginationArray();
        }
    }

    /**
     * Set a new page index and update pagination accordingly
     * @param {number} pageNumber
     */
    public selectPage(pageNumber: number): void {
        if (this.paginationService.pagination.currentPage !== pageNumber) {
            this.paginationService.pagination.currentPage = pageNumber;
            this.updatePaginationArray();
        }
    }

    /**
     * Update pagination range and update pagination accordingly
     * @param {string} range
     */
    public updatePaginationRange(range: string): void {
        this.paginationService.pagination.range = Number(range);
        this.paginationService.pagination.currentPage = 1;
        this.initPaginationArray();
    }

    /**
     * Return the current pagination array
     */
    get pagesArray(): Page[] {
        return this.paginationService.pagesArray;
    }

    get pagination(): PaginationConfig {
        return this.paginationService.pagination;
    }
}
