import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-simple-tags',
    templateUrl: './simple-tags.component.html',
    styleUrls: ['./simple-tags.component.scss'],
    providers: [],
})
export class SimpleTagsComponent implements OnInit {
    @Input() elements: any[] = [];
    @Input() textProperty = 'displayName';
    @Input() subTextProperty: string = null;
    @Input() idProperty = 'value';
    // Define bootstrap col width (1-12 or auto)
    @Input() colSize = '4';
    @Output() removeTag: EventEmitter<any> = new EventEmitter();
    @Input() canDelete = true;

    constructor() {}
    ngOnInit() {}

    removeTagClicked(tag) {
        const id = this.elements.findIndex(element => element[this.idProperty] === tag[this.idProperty]);
        const elementToRemove = this.elements.find(element => element[this.idProperty] === tag[this.idProperty]);
        this.elements.splice(id, 1);
        this.removeTag.emit(elementToRemove);
    }

    getElementText(tag) {
        return tag[this.textProperty] ? tag[this.textProperty] : '';
    }

    hasElementSubText(tag) {
        return !tag[this.subTextProperty] ? false : true;
    }

    getElementSubText(tag) {
        return tag[this.subTextProperty] ? tag[this.subTextProperty] : '';
    }

    getClassCol() {
        return 'col-md-' + this.colSize + ' col-sm-' + this.colSize;
    }

    getClass(tag) {
        return tag[this.subTextProperty] ? 'text' : 'text text-alone';
    }

    getFluidType(tag: any) {
        return tag.value;
    }
}
