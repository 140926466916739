import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import Swal from 'sweetalert2';

// services
import { CampaignsService } from 'app/shared/services/campaigns/campaigns.service';

// interfaces
import {
    OptionValueGroup,
    QueryFilterRoutingRef,
} from 'app/shared/components/filters/scope-filter/scope-filter.interface';

// components
import { RoutingReferenceScopeFilterComponent } from 'app/shared/components/filters/scope-filter/routing-reference/routing-reference-scope-filter.component';
import { Campaign } from 'app/shared/models/campaign.interface';
import { CampaignInputs, CampaignPeriod, CampaignScope, CampaignValues } from './campaign.interface';

@Component({
    selector: 'ga-campaign-create',
    templateUrl: './campaign-create.component.html',
    styleUrls: ['./campaign-create.component.scss'],
    providers: [],
})
export class CampaignCreateComponent implements OnInit {
    @Output() campaignCreated: EventEmitter<Campaign> = new EventEmitter<Campaign>();

    /**
     * Filter elelements
     */
    private filter: QueryFilterRoutingRef = null;

    /**
     * Reference period of the campaign, computed from filter selection
     */
    private referencePeriod: { start: moment.Moment; end: moment.Moment } = { start: null, end: null };

    /**
     * Available periods for the campaign
     */
    public availableCampaignPeriods: CampaignPeriod[] = [];

    /**
     * Campaign values selected
     */
    private campaignValues: CampaignValues = {
        name: '',
        goal: 0,
        period: null,
    };

    /**
     * Indicate that the initialization of all elements linked to the filter is done and ready for display
     * Useful here for periods: need to display them once everything is computed to respect angular lifecycle
     */
    public isFilterReady = false;

    /**
     * Filter child component
     */
    @ViewChild(RoutingReferenceScopeFilterComponent, { static: true })
    scopeFilter: RoutingReferenceScopeFilterComponent;

    /**
     * Indicate that the form has been submitted. Used to set color in fields set incorreclty.
     */
    private formValidated = false;

    constructor(private campaignsService: CampaignsService) {}

    ngOnInit() {}

    /**
     * Update campaign's scope with the new filters selected
     * @param {QueryFilterRoutingRef} filter
     */
    public updateFilter(filter: QueryFilterRoutingRef): void {
        this.filter = filter;

        this.computeReferencePeriod();
        this.computeAvailablesCampaignPeriods();
    }

    /**
     * Update campaign's scope for the first time.
     * @param {QueryFilterRoutingRef} filter - filters from the data scope filter component.
     */
    public onAfterFilterInit(filter: QueryFilterRoutingRef): void {
        this.updateFilter(filter);
        this.isFilterReady = true;
    }

    /**
     * Compute possible periods for a campaign
     */
    private computeAvailablesCampaignPeriods(): void {
        this.availableCampaignPeriods = [];

        const periodRef = this.referencePeriod;

        if (!periodRef || !periodRef.start || !periodRef.end) {
            return;
        }

        /**
         * Goal : [{start: Date, end: Date}]
         * start = dateStart + x Years
         * end = dateEnd + x Years
         * xMin <= x <= xMax
         * xMin: Number of years between the reference period start and now.
         * xMin = |(dateStart - now()).years()|
         * xMax = xMin + 3
         * Cond :
         * - start > dateEnd
         * - end > now
         */
        const now = moment();
        const years = moment.duration(now.diff(periodRef.start)).asYears();
        const xMin = Math.floor(years);
        const xMax = xMin + 3; // For now 3 = up to 3 proposals

        for (let i = xMin; i < xMax; i++) {
            const end = periodRef.end.clone().add(i, 'year');
            const start = periodRef.start.clone().add(i, 'year');

            if (start.isAfter(periodRef.end) && end.isAfter(now)) {
                const displayName = `De ${start.format('MMMM YYYY')} à ${end.format('MMMM YYYY')}`;
                const period = {
                    start: start.toDate(),
                    end: end.toDate(),
                    displayName,
                };
                this.availableCampaignPeriods.push(period);
            }
        }
        /**
         * After calaculating, select the first period available.
         * Calculating happens when filter change, so when references changes.
         */
        this.updateCampaignPeriod(0);
    }

    /**
     * Get the period reference display text.
     */
    get referencePeriodDisplay(): string {
        if (this.referencePeriod && this.referencePeriod.start && this.referencePeriod.end) {
            return `De ${this.referencePeriod.start.format('MMMM YYYY')} à ${this.referencePeriod.end.format(
                'MMMM YYYY'
            )}`;
        } else {
            return 'Pas de période sélectionnée';
        }
    }

    /**
     * Compute the campaign's reference period. Should not exceed one year.
     */
    private computeReferencePeriod(): void {
        if (this.filter && this.filter.dateStart && this.filter.dateEnd) {
            const start = moment(this.filter.dateStart, 'YYYY-MM').startOf('month');
            let end = moment(this.filter.dateEnd, 'YYYY-MM').endOf('month');
            const ms = end.diff(start);
            const d = moment.duration(ms);
            if (d.asYears() > 1) {
                // Get end of year (not start of next year) (ie. 01/2018-01/2019 => 01/2018-12/2018)
                end = start
                    .clone()
                    .add(1, 'year')
                    .subtract(1, 'day')
                    .endOf('month');
            }
            this.referencePeriod = { start, end };
        } else {
            this.referencePeriod = { start: null, end: null };
        }
    }

    /**
     * Create a campaign
     */
    public async createCampaign(): Promise<void> {
        this.formValidated = true;
        const s = !this.isSearchDisabled();
        const n = Boolean(this.campaignValues.name);
        const g = Boolean(this.campaignValues.goal);
        const p = this.hasCampaignPeriod();
        const r = this.hasReferencePeriod();

        if (s && n && g && p && r) {
            const campaign: CampaignInputs = {
                scope: this.getScopeFromFilter(),
                reference: {
                    dateStart: this.referencePeriod.start.toISOString(),
                    dateEnd: this.referencePeriod.end.toISOString(),
                },
                goal: this.campaignValues.goal,
                dateStart: this.campaignValues.period.start.toISOString(),
                name: this.campaignValues.name,
                /** @TODO filter no longer has a unique company, but a string containing multiple companies separated by commas
                /* @TODO ==> for the time being, use the first company from the list
                /* @TODO ==> In the future : Modify campaign inputs to receive multiple companies */
                company: this.filter.companies.split(',')[0] || null,
            };
            await this.saveCampaign(campaign);
        }
    }

    /**
     * Returns the scope of the campaign to save in the campaign document
     * @returns {CampaignScope} scopeFilter
     */
    private getScopeFromFilter(): CampaignScope {
        return {
            customFilter: this.filter.customFilter ? this.filter.customFilter : null,
            energyTypes: this.filter.energies ? this.filter.energies.split(',') : [],
            sites: this.filter.sites ? this.filter.sites.split(',') : [],
            regions: this.filter.regions ? this.filter.regions.split(',') : [],
            routingReferences: this.filter.routingreferences ? this.filter.routingreferences : null,
            categories: this.filter.categories ? this.filter.categories.split(',') : [],
        };
    }

    /**
     * Save the given campaign.
     * @param {CampaignInputs} campaign - campaign obejct to save
     */
    private async saveCampaign(campaign: CampaignInputs): Promise<void> {
        try {
            const result = await this.campaignsService.saveCampaign(campaign);
            this.campaignCreated.emit(result.data);
        } catch (error) {
            this.handleErrorMessage(error);
        }
    }

    /**
     * Update campaign's name
     * @param {string} value
     */
    public updateName(value: string): void {
        this.campaignValues.name = value;
    }

    /**
     * Update campaign's goal value
     * @param {string|number} value
     */
    public updateGoal(value: string | number): void {
        if (typeof value === 'number') {
            this.campaignValues.goal = value;
        } else if (typeof value === 'string') {
            this.campaignValues.goal = Number.parseFloat(value);
        }
    }

    /**
     * Update campaign's action period.
     * @param {string|number} value - index of the period selected in the availableCampaignPeriods array.
     */
    public updateCampaignPeriod(value: string | number): void {
        let index = 0;
        if (typeof value === 'number') {
            index = value;
        } else if (typeof value === 'string') {
            index = Number.parseFloat(value);
        }

        if (this.availableCampaignPeriods && this.availableCampaignPeriods[index]) {
            this.campaignValues.period = {
                start: this.availableCampaignPeriods[index].start,
                end: this.availableCampaignPeriods[index].end,
            };
        }
    }

    /**
     * Get campaign's name input class
     */
    get campaignNameInputClass(): string {
        if (!this.formValidated) {
            return '';
        }
        return this.campaignValues.name ? '' : 'is-invalid';
    }

    /**
     * Get the campaign's goal input class
     */
    get campaignGoalInputClass(): string {
        if (!this.formValidated) {
            return '';
        }
        return this.campaignValues.goal ? '' : 'is-invalid';
    }

    /**
     * Is the search disabled or not. Depending on the reference period, campaign period and scope (filters).
     * @returns {boolean} true is search button need to be disabled, false otherwise.
     */
    public isSearchDisabled(): boolean {
        const hasReferencePeriod = this.hasReferencePeriod();

        const hasCampaignPeriod = this.hasCampaignPeriod();

        const hasDataForCurrentResearch = this.hasFiltersElementInFilter();
        const hasEnergies = this.hasFluidsInFilter();

        return !(hasDataForCurrentResearch && hasEnergies && hasReferencePeriod && hasCampaignPeriod);
    }

    /**
     * @returns true if reference period defined, false otherwise.
     */
    private hasReferencePeriod(): boolean {
        return Boolean(this.referencePeriod && this.referencePeriod.start && this.referencePeriod.end);
    }

    /**
     * @returns true is campaign's period defined, false otherwise.
     */
    private hasCampaignPeriod(): boolean {
        return Boolean(
            this.campaignValues.period && this.campaignValues.period.start && this.campaignValues.period.end
        );
    }

    /**
     * @returns true is scope has at leat one fluid selected, false otherwise.
     */
    private hasFluidsInFilter(): boolean {
        if (!this.filter || !this.filter.energies) {
            return false;
        }
        return this.filter.energies.split(',').length > 0;
    }

    /**
     * Returns true if tags are selected for the filter selected, unlessthe filter selected is "all the sites".
     * @return {boolean}
     */
    private hasFiltersElementInFilter(): boolean {
        const filterSelected = this.getMainFilterSelected();
        if (!filterSelected) {
            return true;
        }
        const value = filterSelected.value;
        if (value === 'custom-filters') {
            return Boolean(this.filter.customFilter);
        }

        if (value !== 'all') {
            if (['routingReferences'].includes(value)) {
                const valueLowerCase = value.toLocaleLowerCase();
                return Boolean(this.filter[valueLowerCase] && this.filter[valueLowerCase].length);
            } else {
                return Boolean(this.filter[value] && this.filter[value].length);
            }
        }

        return true;
    }

    /**
     * Returns the elements selected in the top filter part
     * @returns {OptionValueGroup} filter's options selected
     */
    private getMainFilterSelected(): OptionValueGroup {
        if (this.scopeFilter) {
            return this.scopeFilter.getMainFilterSelected();
        }
        return null;
    }

    /**
     * Fire a modal to explain the specfic error
     * @param {object} error
     * @param {string} error.errorCode used to select the message displayed
     */
    private handleErrorMessage(error): void {
        const messages = {
            error_campaign_already_exists: {
                title: 'La campagne existe déjà.',
                message: 'Impossiblde de créer la campagne car une campagne existe déjà avec ce nom.',
            },
        };
        if (error && error.errorCode && messages[error.errorCode]) {
            Swal.fire(messages[error.errorCode].title, messages[error.errorCode].message, 'error');
        } else {
            Swal.fire(
                'Impossible de créer la campagne',
                'Une erreur est survenue lors de la création de votre campagne. Veuillez réessayer ultérieurement.',
                'error'
            );
        }
    }
}
