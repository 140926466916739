import { EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import _ = require('lodash');
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import Swal from 'sweetalert2';

// interfaces
import { Company, CompanyLight } from 'app/shared/models/company.interface';
import { OptionValue } from 'app/shared/models/filter-config.interface';
import { SitePopulated } from 'app/shared/models/site.interface';
import { AvailableYears, FilterAlias } from 'app/shared/services/filter/filter.interface';
import {
    Entity,
    Filter,
    FiltersOptions,
    OptionValueGroup,
    QueryFilter,
    ScopeFilterConfig,
    TagOption,
} from './scope-filter.interface';

// services
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { FuelCardsService } from 'app/shared/services/vehicles/fuelCards.service';
import { VehiclesService } from 'app/shared/services/vehicles/vehicles.service';
import { ScopeFilterService } from './scope-filter.service';

@Injectable()
export abstract class ScopeFilterComponent<T extends Entity, Q extends QueryFilter, F extends Filter<Entity>>
    implements OnInit {
    /**
     * ------------
     * INPUTS
     * ------------
     */

    /** Date method can be either yearly or a custom period with a start and end.*/
    @Input() isDateCustomisable = true;

    /** Dates period can be reduced to a maximum range. Eg: maximum 1 year between start and end. */
    @Input() periodRange: {
        amount: number;
        unit: 'month' | 'year';
    } = null;

    /** Perimeter can be reduced to the active sites / inactive sites. */
    @Input() disablePerimeter = false;

    /** Used to filter the accepted fluids. Eg: 'energy' or 'water'. */
    @Input() fluidsCategory: string;

    /** Accepted entity fluid types that can be displayed */
    @Input() fluidTypesAccepted: string[] = [];

    /** Filters config */
    @Input() filtersConfig: ScopeFilterConfig = {};

    /**
     * ----------
     *  OUTPUTS
     * ----------
     */

    /**
     * Event emitted each time an element is changed inside the filter
     */
    @Output() filterChange: EventEmitter<Q> = new EventEmitter<Q>();

    /**
     * Event emitted to signal that the page's components need to be refreshed automatically with the new filter selection.
     * So far, called:
     *  - after the first initialization
     *  - when the companies are updated
     */
    @Output() filterSearch: EventEmitter<Q> = new EventEmitter<Q>();

    /**
     * Event emitted when we want to emit to the parent component
     */
    @Output() filterEmit: EventEmitter<Q> = new EventEmitter<Q>();

    /**
     * ---------
     * Filter Options
     * ---------
     */
    /** List of entities specific to the filter implementation (eg. routing references, vehicles, ...) */
    public entities: T[] = [];

    /** Object containing all the filter information for display */
    public filter: F;

    /** Dates / period */
    public dateFilterElements: OptionValueGroup[] = [
        {
            displayName: 'Par année',
            value: 'yearly',
        },
        {
            displayName: 'Personnalisée',
            value: 'custom',
        },
    ];

    /** Perimeter of activity / inactivity. Used for sites and routing references. */
    public perimeterFilterElements: OptionValueGroup[] = [
        {
            displayName: 'Tous',
            value: 'all',
            selected: true,
        },
        {
            displayName: 'Actifs',
            value: 'enabled',
            selected: false,
        },
        {
            displayName: 'Inactifs',
            value: 'disabled',
            selected: false,
        },
    ];

    /** Available years to display for selection. Computed from the bills. */
    public availableYears: AvailableYears = {
        dateStart: '',
        dateEnd: '',
        years: [],
    };

    /** List of companies avaible for selection, computed from the user's rights. */
    public companiesList: OptionValue[] = [];

    /** Custom filter id for initial filter setting */
    public customFilterId: string;
    /** Custom filter company */
    public customFilterCompany: Company;

    /**
     * Last used and valid params
     */
    private params: Params;

    /**
     * -----------
     * Value selection
     * -----------
     */
    /** Company ID from the user's session */
    private sessionCompany: Company;

    mainFilterSelected = 'all'; // Name of the top filter element which is currenlty selected.
    dateMethodSelected = 'yearly'; // Name of the selected date method selected, 'yearly' or 'custom'
    defaultSiteSelected: SitePopulated; // Preselected site from the url parameter
    defaultEntitySelected: string | T; // Preselected entity from the url parameter
    mainFilterSelectedValues: any[] = []; // Contains the options selected in the main filter
    nbMaxTags = 5; // Maximum number of options selected by the user. Eg: sites, routing references, etc.

    /**
     * -----------
     * Abstract variables & functions
     * -----------
     */

    /** Variables */
    /** Name of the entity expected inside the query. */
    abstract entityFilterName: string;
    /** Filter alias for URL of property */
    abstract entityPropertyAlias: FilterAlias;
    /** Elments available as filters in the top part. */
    abstract topFilterElements: OptionValueGroup[];
    /** Options available for each top filter element. */
    abstract filtersOptions: FiltersOptions<T>;
    abstract hasConversionOnMultipleFluids: boolean;
    abstract missingFluidTypeMessage: string;
    abstract multipleFluidTypesMessage: string;
    abstract fluidTypesName: string;

    /**
     * List of the permitted url params. The content depends on the page scope.
     */
    abstract permittedParams: string[];

    // fluid types
    abstract getFilterFluidTypes(): OptionValue[];
    abstract get fluidYearsParams(): Params;

    // filter
    abstract resetFilter(): void;
    abstract getPerimeterDisplayName(perimeter: OptionValueGroup): string;

    // init data
    abstract initSelectedItems(params: Params): Promise<void>;
    abstract initSites(): Promise<void>;
    abstract initEntitiesData(): Promise<void>;

    // component interactions
    abstract initComponentSetup(params: Params): void;
    abstract createQuery(): Q;

    constructor(
        public filterService: FilterService,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        public sitesService: SitesService,
        public energyService: EnergyService,
        public vehiclesService: VehiclesService,
        public fuelCardService: FuelCardsService,
        public scopeFilterService: ScopeFilterService,
        public companiesService: CompaniesService
    ) {}

    /**
     * Subscribe to any change coming from the url params.
     * Initialize the filter component.
     * Emit signal to update the parent and refresh the page's components with the new filter.
     */
    // tslint:disable-next-line: contextual-lifecycle
    ngOnInit() {
        this.resetFilter();
        if (this.route.queryParams && this.route.params) {
            this.initComponent(this.route.snapshot.queryParams).then(() => {
                /**
                 * combineLatest : When any observable emits a value, emit the latest value from each.
                 */
                combineLatest([this.route.queryParams, this.route.params]).subscribe({
                    next: async ([queryParams]) => {
                        if (!_.isEqual(this.params, queryParams)) {
                            await this.updateFiltersFromRouteParams(queryParams);
                        }
                    },
                });
            });
        }
    }

    /**
     * It will emit immediately if there any params in the url.
     * In the other case, It will analyze the params and update the filters
     * Called every time url query string params is changed.
     * @param {Params} params
     */
    private async updateFiltersFromRouteParams(params: Params): Promise<void> {
        // If the user tries to change/delete legal entity in query params, it will reset the entire filter instead
        const paramCompaniesIds: string[] = this.filterService.isAvailableAndSetPropertyInParams(params, 'companies')
            ? this.filterService.getParamAliasValues(params, 'companies')
            : this.filter.companies;
        if (this.filter.companies.join(',') !== paramCompaniesIds.join(',')) {
            this.resetFilter();
            this.updateCompaniesFilter(paramCompaniesIds);
            this.resetFilterWithNewCompanies();
        } else {
            const tmpParams = this.cleanParamsForFilters(params);
            await this.createAndEmitQuery(params, tmpParams);
        }
    }

    /**
     * It will verify and update the query string params if it need any modification.
     * If everything is correct, it will emit the call for the filter.
     * Called every time url query string params is changed.
     * @param {Params} params
     * @returns {Params} params with cleaned values
     */
    private cleanParamsForFilters(params: Params): Params {
        // We make a copy in order to compare the original params with the updated one
        const tmpParams: Params = { ...params };

        // Verify the params integrity, and deleting not valid params
        this.filterService.removeInvalidParams(tmpParams, this.permittedParams);

        // DATES PARSING
        if (this.availableYears.years.length) {
            // Verify the dates integrity by parsing them and removing not valid dates
            this.filterService.removeInvalidDatesInParams(tmpParams, this.availableYears);

            // Replace missings dates in params
            this.filterService.replaceMissingDatesInParams(tmpParams, this.availableYears);

            // Replace dates if they are out of limits
            this.filterService.fixDatesCoherence(tmpParams, this.availableYears);
        }

        // Verify fluids of fuels values
        this.removeUnacceptedSelectedItemsInParams(tmpParams);

        // Update the perimeter field
        this.scopeFilterService.removeUnacceptedPerimeterInParams(tmpParams, this.perimeterFilterElements);

        // Remove invalid companies id.
        this.scopeFilterService.removeInvalidCompaniesIdsInParams(tmpParams, this.companiesList);

        return tmpParams;
    }

    /**
     * This function will compare the query params and the valid params.
     * The filter will me updated with new values.
     * If there any difference, the page will be reloaded with new values (deactivated).
     * @param {Params} originalParams - original params
     * @param {Params} validParams - valid params with potential corrections from `originalParams`
     */
    private async createAndEmitQuery(originalParams: Params, validParams: Params): Promise<void> {
        this.params = validParams;
        this.initComponentSetup(this.params);
        const filter = this.createQuery();
        this.filterEmit.emit(filter);
        // Update the route only if the valid tmpParams is different from the most recent valid params
        // Disabled because of the following noticed behaviour :
        // Next query params event is the corrected version of query params (expected)
        // Then an another event fired with original version
        // Then an another event fired with corrected version
        // Consequence : call twice `this.filterEmit.emit(filter);` causing troubles
        // if (!_.isEqual(originalParams, validParams)) {
        //     await this.filterService.updateRouteParams(validParams, '');
        // }
    }

    /**
     * Remove from the param filter wrong energies or fuels filter entries.
     * @param {Params} params
     */
    private removeUnacceptedSelectedItemsInParams(params: Params) {
        // If the property is available for params and set in the query params, it will remove any false values
        if (this.filterService.isAvailableAndSetPropertyInParams(params, this.entityPropertyAlias)) {
            const correctedItemList = _.intersection(
                this.filterService.getParamAliasValue(params, this.entityPropertyAlias).split(','),
                this.fluidTypesAccepted
            ).join(',');

            this.filterService.setParamPropertyValue(params, this.entityPropertyAlias, correctedItemList);
        }

        return params;
    }

    /**
     * Update various filters. It made an intersect between the url params and the actual filter options in order to insert right values.
     * @param {Params} params - URL Query parametters
     * @param {FilterAlias} aliasKey - Denomination of parameters
     * @param {string} filterCat - Filter category, used for selecting graph types (ex : by sites, by categories, etc.)
     * @param {string} filterKey - Key of the filter selected objects.
     * @param {string} filterOptionsKey - Key of the filter options
     * @param {string} filterOptionsValueKey - Options value key for retrieving the corresponding id object. ("_id" or "value")
     */
    public updateFilterByParam(
        params: Params,
        aliasKey: FilterAlias,
        filterCat: string,
        filterKey: string,
        filterOptionsKey: string,
        filterOptionsValueKey: string
    ) {
        if (this.filterService.isAvailableAndSetPropertyInParams(params, aliasKey)) {
            const values = this.filterService.getParamAliasValue(params, aliasKey).split(',');
            const intersection = this.filtersOptions[filterOptionsKey].filter(filterObject =>
                values.includes(filterObject[filterOptionsValueKey].toString())
            );

            if (intersection.length) {
                this.mainFilterSelected = filterCat;
                this.filter[filterKey] = intersection;
            }
        }
    }

    /**
     * Update companies filter
     * @param {string[]} companiesIds
     */
    private updateCompaniesFilter(companiesIds: string[]) {
        if (this.filter.companies.join(',') !== companiesIds.join(',')) {
            /** Newly selected companies */
            const newCompanies: string[] = [];

            this.companiesList.forEach(company => {
                if (companiesIds.includes(company.value)) {
                    newCompanies.push(company.value);
                }
            });
            this.filter.companies = newCompanies.length ? newCompanies : [this.sessionCompany._id];
        }
    }

    /**
     * Update custom filters filter
     * @param {string} value
     */
    public updateCustomFilterByParams(params: Params) {
        if (this.filterService.isAvailableAndSetPropertyInParams(params, 'customFilter')) {
            this.mainFilterSelected = 'custom-filters';
            this.customFilterId = this.filterService.getParamAliasValue(params, 'customFilter');
        }
    }

    /**
     * Updates perimeter filter
     * @param {string} value
     */
    public updatePerimeterFilter(value: string) {
        this.perimeterFilterElements.forEach(x => {
            x.selected = x.value === value;
        });
    }

    /**
     * Verify existence of any dates property in the params and updates period filter
     * @param {Params} params
     */
    public updatePeriodFiltersByParams(params: Params) {
        this.dateMethodSelected = 'yearly';

        if (this.filterService.isAvailableAndSetPropertyInParams(params, 'year')) {
            this.filter.year = this.filterService.getParamAliasValue(params, 'year');
        } else if (
            this.filterService.isAvailableAndSetPropertyInParams(params, 'dateStart') &&
            this.filterService.isAvailableAndSetPropertyInParams(params, 'dateEnd')
        ) {
            this.dateMethodSelected = 'custom';
            this.filter.years = {
                dateStart: this.filterService.getMonthUrlIntoInputDateFormat(
                    this.filterService.getParamAliasValue(params, 'dateStart')
                ),
                dateEnd: this.filterService.getMonthUrlIntoInputDateFormat(
                    this.filterService.getParamAliasValue(params, 'dateEnd')
                ),
            };
        }
    }

    /**
     * Aggregate data to initialize each filter element.
     * Select filter element and values from the url params when present.
     * @param {Params} params - can contain several filters to preselect
     */
    async initComponent(params: Params): Promise<void> {
        try {
            this.sessionCompany = this.sessionService.getCompany();

            const branches: CompanyLight[] = await this.filterService.getAllVisibleBranches();

            // if the company logged in has branches, set the list of branches
            // set the query for companies
            if (branches && branches.length > 0) {
                branches.unshift(this.sessionCompany);
                // Turn companies objects into select options
                this.companiesList = this.formatCompaniesForSelect(branches);
            }
            // Set filter companies list based on params
            if (this.filterService.isAvailableAndSetPropertyInParams(params, 'companies')) {
                const companiesIds = this.filterService.getParamAliasValues(params, 'companies');
                this.updateCompaniesFilter(companiesIds);
            } else if (this.filterService.isAvailableAndSetPropertyInParams(params, 'company')) {
                const companyId = this.filterService.getParamAliasValue(params, 'company');
                this.updateCompaniesFilter([companyId]);
            } else {
                this.filter.companies = [this.sessionCompany._id];
            }
        } catch (err) {
            Swal.fire('Erreur', 'Une erreur est survenue lors de la récupération des filiales', 'error');
        }

        /**
         * Select the appropriate filter elements for the current page.
         * Init query and all the data to initialize each filter element.
         */
        await this.initComponentData(params);
    }

    /**
     * Init queries & load all data needed to initialize each element:
     * - sites, geographic areas, categories, segments, fuel cards, and custom filters
     * - fuel products & years
     *
     * @param {Params} params - can contain several filters to preselect
     */
    async initComponentData(params: Params = {}): Promise<void> {
        // Change the companies Ids if it's set in params
        const companiesIds: string[] = this.filterService.isAvailableAndSetPropertyInParams(params, 'companies')
            ? this.filterService.getParamAliasValues(params, 'companies')
            : this.filter.companies;
        this.updateCompaniesFilter(companiesIds);

        // Init the list of items
        const propertyList = this.filterService.isAvailableAndSetPropertyInParams(params, this.entityPropertyAlias)
            ? this.filterService.getParamAliasValues(params, this.entityPropertyAlias)
            : [];
        await this.initSelectedItems(propertyList);

        // Init sites
        try {
            await Promise.all([this.initSites(), this.initRegions()]);
            await this.initEntitiesData();
        } catch (error) {
            this.filtersOptions.sites = [];
            this.filtersOptions.entities = [];
        }

        // Init available years
        try {
            await this.initAvailableYearsAndPeriod();
        } catch (e) {
            this.availableYears = { dateStart: '', dateEnd: '', years: [] };
        }

        // Init custom filters
        try {
            await this.initCustomFilter();
        } catch (e) {
            this.customFilterCompany = this.sessionCompany;
        }
    }

    /**
     * Initialize the regions from the companies' sites.
     */
    async initRegions(): Promise<void> {
        try {
            const regions = await this.filterService.getRegionsForAutoCompletion(true, {
                companies: this.filter.companies,
            });
            this.filtersOptions.regions = regions.data;
        } catch (error) {
            this.filtersOptions.regions = [];
        }
    }

    /**
     * Init available period from bills found for the current companies (all energies included).
     * Starts in January of the first year found.
     * Ends in December of the last year found.
     */
    private async initAvailableYearsAndPeriod(): Promise<void> {
        // set available years
        const yearParams: Params = Object.assign({ companies: this.filter.companies.join(',') }, this.fluidYearsParams);
        this.availableYears.years = await this.filterService.getYearsWithData(true, yearParams);

        this.availableYears.years.sort((a, b) => {
            return parseInt(b.value, 10) - parseInt(a.value, 10);
        });

        // set datePicker default dates (YYYY-MM)
        const nbYears = this.availableYears.years.length;
        if (!nbYears) {
            throw new Error('no_year_available');
        }
        const maxYear = this.availableYears.years[0].value;
        const minYear = this.availableYears.years[nbYears - 1].value;
        this.availableYears.dateStart = minYear.concat('-01');
        this.availableYears.dateEnd = maxYear.concat('-12');

        // preselect the years in filter selection
        this.filter.year = maxYear;
        this.filter.years.dateStart = this.availableYears.dateStart;
        this.filter.years.dateEnd = this.availableYears.dateEnd;
    }

    /**
     * Init custom filter
     */
    private async initCustomFilter(): Promise<void> {
        // Get first company of selection for custom filters
        // Otherwise raise error
        // Custom filters currently handle one company
        if (!this.filter.companies || !this.filter.companies.length) {
            throw new Error('Custom filters : No company selected');
        }
        const companyId = this.filter.companies[0];
        const company = await this.companiesService.getCompanyById(companyId);
        this.customFilterCompany = company;
    }

    /**
     * Initialize commons filters such as dates/period, perimeter and custom filters
     * @param {Params} params
     */
    public initComponentCommonSetup(params: Params) {
        this.mainFilterSelected = 'all';

        // Dates settings
        this.updatePeriodFiltersByParams(params);

        // Perimeter setting.
        const perimeter = this.filterService.getParamAliasValue(params, 'sitesStatus');
        this.updatePerimeterFilter(perimeter ? perimeter : 'all');

        // Custom Filters
        this.updateCustomFilterByParams(params);

        return params;
    }

    /**
     * Refresh all filters and emit query when companies filter changes value.
     */
    async resetFilterWithNewCompanies() {
        this.mainFilterSelected = 'all'; // reset top main filter on the default position 'all'
        await this.initComponentData(); // init filter component with the new companies data and reset filter selection

        this.resetFiltersSearch(); // reset query for the new companies & update parent page to launch research
    }

    /**
     * Create query with the selected filter.
     * Emit signal to update the parent and refresh the page's components with the new filter.
     */
    resetFiltersSearch(): void {
        const filter = this.createQuery();
        this.filterSearch.emit(filter);
    }

    /**
     * ------
     * CALLED FROM ENTITY FILTER
     * ------
     */

    /**
     * Set period inside the query from the filter's selected dates.
     * Set format YYYY-MM.
     */
    setFilterDates(filter: Q): void {
        // Verify if there's any value. It's useful for the case when the company or branch has no data.
        if (!this.filter.year) {
            return;
        }

        if (this.dateMethodSelected === 'yearly') {
            const filterYear = parseInt(this.filter.year, 10);
            filter.dateStart = moment({ y: filterYear, M: 0 }).format(this.filterService.inputDateFormat);
            filter.dateEnd = moment({ y: filterYear, M: 11 }).format(this.filterService.inputDateFormat);
        } else {
            filter.dateStart = this.filter.years.dateStart;
            filter.dateEnd = this.filter.years.dateEnd;
        }
    }

    /**
     * -------
     * HTML FUNCTIONS
     * Update filter
     * -------
     */

    /**
     * Update the filter component & query for the new selected companies.
     * Emit event for the parent page to launch search automatically.
     * @param {string[]} companies - Comma separated companies IDs
     */
    async updateCompanies(companies: string[]): Promise<void> {
        this.resetFilter(); // reset selected values
        this.updateCompaniesFilter(companies);
        this.resetFilterWithNewCompanies();
    }

    /**
     * Update the query with the new top filter element selected.
     * @param {string}filterSelectedName - new filter name
     */
    updateSearchMethod(filterSelectedName: string): void {
        this.mainFilterSelected = filterSelectedName;
        this.createQuery();
    }

    /**
     * Set the custom filter id selected and update the query.
     * @param {OptionValue} value
     */
    updateCustomFilter(value: OptionValue): void {
        this.filter.customFilter = value ? value : null;
        this.createQuery();
    }

    /**
     * Update the date method 'yearly' or 'custom' and the query accordingly.
     * @param {string} method
     */
    updateDateSearchMethod(method: string): void {
        this.dateMethodSelected = method;
        this.createQuery();
    }

    /**
     * Update the selected year ('yearly' method), and the query accordingly.
     * @param {string} year
     */
    updateYear(year): void {
        this.filter.year = year;
        this.createQuery();
    }

    /**
     * Update the selected start of the period ('custom' method), and the query accordingly.
     * @param {string} date
     */
    updateDateStart(date): void {
        this.filter.years.dateStart = date;
        this.createQuery();
    }

    /**
     * Update the selected end of the period ('custom' method), and the query accordingly.
     * @param {string} date
     */
    updateDateEnd(date): void {
        this.filter.years.dateEnd = date;
        this.createQuery();
    }

    /**
     * Set the selected perimeter.
     * @param {OptionValueGroup} perimeter - perimeter to set as selected
     */
    updatePerimeter(perimeter: OptionValueGroup) {
        this.updatePerimeterFilter(perimeter.value);
        this.createQuery();
    }

    /**
     * Update filter with the given fluidTypes and update the query.
     * @param {OptionValue[]} fluidTypes
     */
    updateQueryFluidTypes(fluidTypes: OptionValue[]) {
        let filterFluidTypes = this.getFilterFluidTypes();
        filterFluidTypes = fluidTypes;
        this.createQuery();
    }

    /**
     * @returns {*} Return the selected values of the current filter.
     */
    getCurrentFilterSelected(): any {
        // exception for custom filter & entities
        let filterName = this.mainFilterSelected;

        if (this.mainFilterSelected === 'custom-filters') {
            filterName = 'customFilter';
        }
        if (this.isCurrentFilterEntities()) {
            filterName = 'entities';
        }
        return this.filter[filterName];
    }

    /**
     * Update the multiple items select
     * @param {string[]} energies
     */
    public updateItemsWithTypeFilter(type: string, items: string[]) {
        if (
            this.filtersConfig &&
            this.filtersConfig.toggleButtonSelection &&
            this.filtersConfig.toggleButtonSelection.enabled === true &&
            this.filtersConfig.toggleButtonSelection.selectionType === 'single'
        ) {
            this.filter[type].forEach(x => {
                x.selected = items.indexOf(x.value) === 0;
            });
        } else {
            this.filter[type].forEach(x => {
                x.selected = items.indexOf(x.value) > -1;
            });
        }
    }

    /**
     * -------
     * HTML FUNCTIONS
     * Display, disable & messages
     * -------
     */

    /**
     * @returns {boolean} true if the session's company has branches to display for selection.
     */
    hasBranches(): boolean {
        return Boolean(this.companiesList && this.companiesList.length);
    }

    /**
     * @returns {boolean} true if the maximum number of selected items is reached.
     */
    hasMaxElementsSelected(): boolean {
        const currentFilter = this.getCurrentFilterSelected();
        return currentFilter && currentFilter.length >= this.nbMaxTags;
    }

    /**
     * @returns {boolean} true when the selected filter has available options to display
     */
    hasOptionsAvailable(): boolean {
        const currentFilterOptions = this.isCurrentFilterEntities()
            ? this.filtersOptions.entities
            : this.filtersOptions[this.mainFilterSelected];
        return Boolean(currentFilterOptions && currentFilterOptions.length);
    }

    /**
     * @returns {string} message when zero element is selected in the current filter
     * */
    getEmptyFilterMessage(): string {
        return this.filterService.getDisabledErrorMessage(this.mainFilterSelected);
    }

    /**
     * @returns {string}  message when the maximum number of selected elements is reached
     * */
    getMaxElementsSelectedErrorMessage(): string {
        return `Vous ne pouvez pas sélectionner plus de ${this.nbMaxTags} `;
    }

    /**
     * @returns {string} the bootstrap class giving the percentage to give the energy component
     * */
    get energyWidePercentageClass(): string {
        return this.disablePerimeter ? 'col-lg-6' : 'col-lg-4';
    }

    /**
     * @returns {string} the bootstrap class giving the percentage to give the perimeter component
     * */
    get perimeterWidePercentageClass(): string {
        return this.disablePerimeter ? 'col-lg-6' : 'col-lg-3';
    }

    /**
     * Get class for a given perimeter, depending if it's selected or not
     * @param {OptionValueGroup} perimeter - perimeter to get the class of
     * @returns {string | string[]} class(es) of the perimeter
     */
    getPerimeterClass(perimeter: OptionValueGroup): string[] {
        if (perimeter.selected) {
            return ['citron-background', 'perimeter__button--active'];
        }
        return ['perimeter__button--inactive'];
    }

    /**
     * Get period bounds (min date & max date) for the filter.
     * If a period time range is defined (this.periodRange), set min and max depending on the range.
     * For example, if a period can be max 1 year (amout = 1, unit = "year"), will set min and max depending on values available AND selected.
     * @param {"start"|"end"} bound - period start date or period end date.
     * @returns {{min: string, max: string}} - min & max of the available period
     */
    getPeriodsMinMax(bound: 'start' | 'end'): { min: string; max: string } {
        if (bound === 'start') {
            if (this.periodRange) {
                const a = moment(this.filter.years.dateEnd, 'YYYY-MM').subtract(
                    this.periodRange.amount,
                    this.periodRange.unit
                );
                const b = moment(this.availableYears.dateStart, 'YYYY-MM');
                const min = moment.max(a, b);
                return {
                    min: min.format('YYYY-MM'),
                    max: this.filter.years.dateEnd,
                };
            }
            return {
                min: this.availableYears.dateStart,
                max: this.filter.years.dateEnd,
            };
        } else if (bound === 'end') {
            if (this.periodRange) {
                const a = moment(this.filter.years.dateStart, 'YYYY-MM').add(
                    this.periodRange.amount,
                    this.periodRange.unit
                );
                const b = moment(this.availableYears.dateEnd, 'YYYY-MM');
                const max = moment.min(a, b);
                return {
                    min: this.filter.years.dateStart,
                    max: max.format('YYYY-MM'),
                };
            }
            return {
                min: this.filter.years.dateStart,
                max: this.availableYears.dateEnd,
            };
        } else {
            return {
                min: null,
                max: null,
            };
        }
    }

    /**
     * @returns {boolean} true if the current filter selected is the filter on entities.
     */
    isCurrentFilterEntities(): boolean {
        return this.mainFilterSelected === this.entityFilterName;
    }

    /**
     * @returns {boolean} true if at least one fluid type is available for selection
     */
    hasFluidTypesAvailable(): boolean {
        const fluidTypes = this.getFilterFluidTypes();
        return Boolean(fluidTypes && fluidTypes.length);
    }

    /**
     * @returns {boolean} true if more than 1 fluid type is selected
     */
    hasMultipleFluidTypesSelected(): boolean {
        const fluidTypes = this.getFilterFluidTypes();
        return fluidTypes && fluidTypes.filter(x => x.selected).length > 1;
    }

    /**
     * @returns {boolean} true if at least one fuel type is selected
     */
    hasFluidTypesSelected(): boolean {
        const fluidTypes = this.getFilterFluidTypes();
        return fluidTypes && fluidTypes.some(x => x.selected);
    }

    /**
     * @returns {*} the options associated to the selected filter
     */
    getTagOptions(): TagOption {
        return this.getMainFilterSelected() ? this.getMainFilterSelected().tags : null;
    }

    /**
     * ---------
     * Used by hosts
     * ---------
     */

    /**
     * @returns {OptionValueGroup} the current selected element among the top filter elements.
     */
    getMainFilterSelected(): OptionValueGroup {
        return this.topFilterElements.find(x => x.value === this.mainFilterSelected);
    }

    /**
     * Returns true if at least one tag is selected on the current filter.
     * Returns true if a custom filter is selected
     * Returns true for the "all" item
     * @return {boolean}
     */
    hasOptionsSelected(): boolean {
        // exceptions for "All" - doesn't have any selection
        if (this.mainFilterSelected === 'all') {
            return true;
        }

        // exception for custom filters - only one selected, not an array
        if (this.mainFilterSelected === 'custom-filters') {
            return Boolean(this.filter.customFilter);
        }

        const currentFilter = this.getCurrentFilterSelected();
        return Boolean(currentFilter && currentFilter.length);
    }

    /**
     * Format an array of companies into an array of select options
     * @param companies - Companies list to turn into select options
     * @returns {OptionValue[]} an array of select options
     */
    private formatCompaniesForSelect(companies: CompanyLight[]): OptionValue[] {
        return companies.map(branch => {
            return {
                displayName: branch.name,
                value: branch._id,
            };
        });
    }
}
