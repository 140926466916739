import { Injectable } from '@angular/core';
import { QueryPaginationUser } from 'app/shared/models/pagination.interface';
import { User, UserPopulated } from 'app/shared/models/users.interface';
import { UserTypes } from '../../constants/user-types-list.constants';
import { PageService } from '../page/page.service';

@Injectable()
export class UsersService extends PageService {
    /**
     * Update the user in db.
     * @param user
     * @param {boolean} force
     * @return {Promise<any>}
     */
    async updateUser(user, options = null, force: boolean = false): Promise<any> {
        try {
            const route = '/api/users/crud/' + user._id;
            const res = await this.put(route, user, options, force);
            const updatedUser = res.data;
            const sessionUser = this.apiService.sessionService.getUser();
            if (updatedUser._id === sessionUser._id) {
                // TODO : set all other user properties
                sessionUser.favouriteCompany = updatedUser.favouriteCompany;
                this.apiService.sessionService.setUser(sessionUser);
            }
            return res.data;
        } catch (e) {
            e.error_code = 'error_updateUser';
            return Promise.reject(e);
        }
    }

    /**
     * Save user's sites rules
     * @param {*[]} rules - user sites rules
     * @param {string} userId - id of user to set rules to
     * @returns {Promise<APICitronResponse>}
     */
    async saveSitesRules(rules: object[], userId: string): Promise<APICitronResponse> {
        const route = '/api/users/' + userId + '/sites-rules';
        return this.post(route, { sitesRules: rules });
    }

    /**
     * Create user
     * @param {object} user - user values
     * @returns {Promise<*>} user created
     */
    async createUser(user: object): Promise<any> {
        try {
            const route = '/api/users/crud/';
            const res = await this.post(route, user);
            return res.data;
        } catch (err) {
            err.error_code = 'error_createNewUser';
            return Promise.reject(err);
        }
    }

    /**
     * Delete user.
     * @param {*} user - user filled with at least the _id property
     */
    async deleteUserById(user: { _id: string }): Promise<any> {
        try {
            const route = '/api/users/crud/' + user._id;
            const response = await this.delete(route);
            return response.data;
        } catch (err) {
            err.error_code = 'error_deleteUser';
            return Promise.reject(err);
        }
    }

    /**
     * Delete all the users found in the array of user ids
     * @param {Array<string>} userIds - array of user ids
     * @returns {Promise<any>}
     */
    deleteUsers(userIds: string[]): Promise<any> {
        const route = '/api/users/delete/multiple';
        return this.post(route, { users: userIds });
    }

    /**
     * Returns the given user populated with his relations and contacts
     * @param userId
     * @param {Array<string>} userTypesWanted: to filter the relations by user.type
     * @param {Array<string>} companyRolesWanted: the company's property inside which we look for the users (by default both users and viwers)
     * @return {Promise<any>}
     */
    getUserByIdWithRelationsAndContacts(userId, userTypesWanted = null, companyRolesWanted = null): Promise<any> {
        const route = '/api/users/' + userId + '/relations/contacts';

        const data = {};
        if (userTypesWanted) {
            Object.assign(data, { userTypes: userTypesWanted.toString() });
        }
        if (companyRolesWanted) {
            Object.assign(data, { userRoles: companyRolesWanted.toString() });
        }

        return this.get(route, null, data).then(
            response => {
                if (response.code === 200) {
                    return Promise.resolve(response.data);
                } else {
                    const err = { errorCode: 'error_getUserWithRelations' };
                    return Promise.reject(err);
                }
            },
            err => {
                err.errorCode = err.errorCode || 'error_getUserWithRelations';
                return Promise.reject(err);
            }
        );
    }

    /**
     * Returns a User object from its id
     * @param {string} id
     * @return {Promise<User>}
     */
    async getUserById(id: string): Promise<User> {
        try {
            const response = await this.get('/api/users/crud/' + id);
            if (response.code === 200) {
                return response.data;
            } else {
                throw { errorCode: 'error_loadUser' };
            }
        } catch (err) {
            err.errorCode = 'error_loadUser';
            return Promise.reject(err);
        }
    }

    /**
     * Returns the list of users that the current user can see.
     * @param {string} userId
     * @param {QueryPaginationUser} paginationQuery
     * @param {string} searchInput - filter to serach on user and company properties
     * @return {Promise<any>}
     */
    getVisibleUsers(
        userId: string,
        paginationQuery: QueryPaginationUser,
        searchInput: string
    ): Promise<UserPopulated[]> {
        const route = '/api/users/' + userId + '/list/visible?';
        let completeRoute =
            route +
            'search=' +
            searchInput +
            '&currentPageIndex=' +
            paginationQuery.currentPageIndex +
            '&selectedPageIndex=' +
            paginationQuery.selectedPageIndex +
            '&nbItemsPerPage=' +
            paginationQuery.nbItemsPerPage;
        completeRoute += paginationQuery.lastUserName ? '&lastUserName=' + paginationQuery.lastUserName : '';
        completeRoute += paginationQuery.lastUserIndex ? '&lastUserIndex=' + paginationQuery.lastUserIndex : '';

        return this.get(completeRoute).then(
            response => {
                if (response.code === 200) {
                    return Promise.resolve(response.data);
                } else {
                    const err = { errorCode: 'error_getListUsers' };
                    return Promise.reject(err);
                }
            },
            err => {
                return Promise.reject(err);
            }
        );
    }

    /**
     * Get user by a specified company and user roles
     *
     * @param {string?} companyId - filter by company id
     * @param {string[]?} roles - filter by roles
     * @returns {Promise<*[]>} list of users
     */
    async getAllUsersList(companyId?: string, userRoles?: string[]): Promise<any[]> {
        try {
            const data = {};

            if (companyId) {
                data['companyId'] = companyId;
            }

            if (Array.isArray(userRoles)) {
                data['roles'] = userRoles.join();
            }

            const response = await this.get('/api/users/crud', null, data);
            return response.data;
        } catch (e) {
            e.error_code = 'error_getListUsers';
            throw e;
        }
    }

    /**
     * Get all users filtered by [types]
     *
     * @param {{types?: string[]}} filter - filter to apply
     * @returns {Promise<any>}
     */
    async getAllUsersByFilter(filter: { types?: string[] }): Promise<any> {
        try {
            const data = {};
            if (Array.isArray(filter.types)) {
                data['types'] = filter.types.join();
            }
            const response = await this.post('/api/users/all', data);
            return response.data;
        } catch (e) {
            e.error_code = 'error_getAllUsers';
            throw e;
        }
    }

    /**
     * Returns an array with the companies (id + name) of each user
     * @param {string[]} userIds
     * @returns {Promise<{_id: string, companies: {_id: string, name:string}[]}[]>}
     */
    getUsersCompanies(
        userIds: string[]
    ): Promise<Array<{ _id: string; companies: Array<{ _id: string; name: string }> }>> {
        const route = '/api/users/companies?userIds=' + userIds.join();
        return this.get(route).then(
            response => {
                if (response.code === 200) {
                    const userAndCompanies = response.data.map(user => {
                        return { _id: user._id, companies: user.companies };
                    });
                    return Promise.resolve(userAndCompanies);
                } else {
                    const err = {
                        errorCode: 'error_getListCompanies',
                        data: userIds.map(id => {
                            return { _id: id, companies: [] };
                        }),
                    };
                    return Promise.reject(err);
                }
            },
            err => {
                err.errorCode = 'error_getListCompanies';
                err.data = userIds.map(id => {
                    return { _id: id, companies: [] };
                });
                return Promise.reject(err);
            }
        );
    }

    public async deletePicture(userId: string): Promise<User> {
        const response = await this.delete(`/api/pictures/user/${userId}`);
        return response.data;
    }

    /**
     * Returns a boolean corresponding if the user's type is the given one or higher
     * @returns {boolean}
     */
    isSessionUserAtLeast(minType: string): boolean {
        const connectedUser = this.apiService.sessionService.getUser();
        const orderedTypes = {
            [UserTypes.USER]: 0,
            [UserTypes.USER_ADMIN]: 1,
            [UserTypes.ENERGY_MANAGER]: 2,
            [UserTypes.ADMIN]: 3,
        };

        return orderedTypes[connectedUser.type] >= orderedTypes[minType];
    }

    /**
     * Returns true if user if from Citron either as an AMDIN or an EM
     * @param {object} user to compare
     * @return {boolean}
     */
    isFromCitron(user): boolean {
        return user ? [UserTypes.ADMIN, UserTypes.ENERGY_MANAGER].includes(user.type) : false;
    }

    /**
     * Returns true if connected user if from Citron either as an AMDIN or an EM
     * @return {boolean}
     */
    isSessionUserFromCitron(): boolean {
        const connectedUser = this.apiService.sessionService.getUser();
        return this.isFromCitron(connectedUser);
    }

    /**
     * Returns true if the user is ADMIN
     * @param {object} user to compare
     * @return {boolean}
     */
    isAdmin(user): boolean {
        return user ? user.type === UserTypes.ADMIN : false;
    }

    /**
     * Returns true if the user is ENERGY_MANAGER
     * @param {object} user to compare
     * @return {boolean}
     */
    isEM(user): boolean {
        return user ? user.type === UserTypes.ENERGY_MANAGER : false;
    }

    /**
     * Returns true if the user is USER_ADMIN
     * @param {object} user to compare
     * @return {boolean}
     */
    isUserAdmin(user): boolean {
        return user ? user.type === UserTypes.USER_ADMIN : false;
    }

    /**
     * Returns true if the user is USER
     * @param {object} user to compare
     * @return {boolean}
     */
    isUser(user): boolean {
        return user ? user.type === UserTypes.USER : false;
    }

    /**
     * Returns true if the user is either USER_ADMIN or USER
     * @param {object} user to compare
     * @return {boolean}
     */
    isClient(user): boolean {
        return user ? [UserTypes.USER_ADMIN, UserTypes.USER].includes(user.type) : false;
    }

    /**
     * Returns the right to create another user.
     * @param user
     * @return {boolean}
     */
    hasRightToCreate(user): boolean {
        return !this.isUser(user);
    }

    /**
     * Returns the right to create a company.
     * @param user
     * @return {boolean} - true if the user is either an EM or an ADMIN
     */
    hasRightToCreateCompany(user): boolean {
        return this.isFromCitron(user);
    }

    /**
     * Send mail to reset password
     * @param email
     * @return {Promise<any>}
     */
    async forgotPassword(email: string): Promise<any> {
        const form = {
            email,
        };
        const result = await this.post('/api/auth/forgot-password', form);
        return result.data;
    }

    /**
     * Send email activation to a user
     * @param {UserPopulated} user
     * @return {Promise<any>}
     */
    async sendEmailActivation(user: UserPopulated): Promise<any> {
        const result = await this.post('/api/auth/reset-activation', user);
        return result.data;
    }
}
