import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class GlobalState {
    private _data = new Subject<object>();
    private _dataStream = this._data.asObservable();
    private _subscriptions: Map<string, Array<() => void>> = new Map<string, Array<() => void>>();

    constructor() {
        this._dataStream.subscribe({ next: data => this._onEvent(data) });
    }

    notifyDataChanged(event, value) {
        const current = this._data[event];

        if (current !== value) {
            this._data[event] = value;

            this._data.next({
                event,
                data: this._data[event],
            });
        }
    }

    subscribe(event: string, callback: () => void) {
        const subscribers = this._subscriptions.get(event) || [];

        subscribers.push(callback);
        this._subscriptions.set(event, subscribers);
    }

    _onEvent(data: any) {
        const subscribers = this._subscriptions.get(data['event']) || [];

        subscribers.forEach(callback => {
            callback.call(null, data['data']);
        });
    }
}
