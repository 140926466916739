import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NavDropdownToggleDirective } from '../shared/directives/nav-dropdown-toggle.directive';
import { NavDropdownDirective } from '../shared/directives/nav-dropdown.directive';
import { ScrollbarDirective } from '../shared/directives/scrollbar.directive';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout.component';
import { LayoutRoutes } from './layout.routes';
import { LeftNavbarComponent } from './left-navbar/left-navbar.component';
import { NavbarTopComponent } from './navbar-top/navbar-top.component';

@NgModule({
    declarations: [
        LayoutComponent,
        LeftNavbarComponent,
        ScrollbarDirective,
        NavDropdownDirective,
        NavDropdownToggleDirective,
        NavbarTopComponent,
    ],
    imports: [LayoutRoutes, CommonModule, FormsModule, SharedModule.forRoot()],
})
export class LayoutModule {}
