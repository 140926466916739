import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { ModalComponent } from 'app/shared/components/common/modal/modal.component';
import { MonitoringCostsService } from 'app/shared/components/operating-monitoring/monitoring-edition/monitoring-edition-contract/monitoring-costs.service';
import { OperatingMonitoringService } from 'app/shared/components/operating-monitoring/operating-monitoring.service';
import { MonitoringUpdate } from 'app/shared/components/operating-monitoring/operating-monitoring.update.interface';

@Component({
    selector: 'ga-operating-monitoring',
    templateUrl: './operating-monitoring.component.html',
    styleUrls: ['./operating-monitoring.component.scss'],
    providers: [OperatingMonitoringService, MonitoringCostsService],
})
export class OperatingMonitoringComponent implements OnInit, OnDestroy {
    /**
     * Modals
     */
    @ViewChild('editionMonitoringModal', { static: false }) editionMonitoringModal: ModalComponent;
    @ViewChild('editReadingsModal', { static: false }) editReadingsModal: ModalComponent;

    public selectedMonitoring: MonitoringUpdate;
    public isEditingMonitoring: boolean;

    /**
     * Subscription to events
     */
    private subscription: Subscription;

    constructor(private operatingMonitoringService: OperatingMonitoringService) {}

    ngOnInit() {
        this.subscription = this.operatingMonitoringService.toggleEditionMonitoringModal$.subscribe({
            next: value => {
                this.toggleModal(value, this.editionMonitoringModal);
            },
        });
        this.subscription.add(
            this.operatingMonitoringService.toggleEditReadingsModal$.subscribe({
                next: value => {
                    this.toggleModal(value, this.editReadingsModal);
                },
            })
        );
        this.subscription.add(
            this.operatingMonitoringService.selectedMonitoring$.subscribe({
                next: value => {
                    this.selectedMonitoring = value;
                },
            })
        );
        this.subscription.add(
            this.operatingMonitoringService.editedMonitoring$.subscribe({
                next: value => {
                    this.isEditingMonitoring = Boolean(value);
                },
            })
        );
    }

    private toggleModal(value: boolean, modal: ModalComponent) {
        if (modal) {
            if (value) {
                modal.show();
            } else {
                modal.close();
            }
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public onCreate() {
        this.editionMonitoringModal.close();
    }
}
