import { Component, OnInit } from '@angular/core';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { MonitoringFilters } from '../operating-monitoring.interface';
import { OperatingMonitoringService } from '../operating-monitoring.service';

@Component({
    selector: 'ga-monitoring-companies-select',
    templateUrl: './monitoring-companies-select.component.html',
    styleUrls: ['./monitoring-companies-select.component.scss'],
})
export class MonitoringCompaniesSelectComponent implements OnInit {
    /**
     * Current filters selected
     */
    public filters: MonitoringFilters;
    /**
     * List of all the companies available
     */
    public companies: Array<{ displayName: string; value: string }> = [];

    constructor(private filterService: FilterService, private operatingMonitoringService: OperatingMonitoringService) {}

    ngOnInit() {
        this.operatingMonitoringService.filters$.subscribe({
            next: value => (this.filters = value),
        });

        this.initCompanies();
    }

    private async initCompanies() {
        this.companies = await this.filterService.getCompanyAndAllBranchesForFilter();
    }

    /**
     * Select new company from branches
     * @param {string[]} values - companyIds
     */
    public setSelectedCompanies(values: string[]) {
        // Set new filter if companies selected. At least one company must be selected.
        if (values && values.length) {
            const newFilter = { ...this.filters, companies: values };
            this.operatingMonitoringService.filters$.next(newFilter);
        }
    }
}
