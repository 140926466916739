import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-sites-bloc-adress',
    templateUrl: './bloc-adress.component.html',
    styleUrls: ['./bloc-adress.component.scss'],
})
export class SitesBlocAdressComponent implements OnInit {
    @Input() site: any = {};
    constructor() {}
    ngOnInit() {}
}
