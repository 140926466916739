import { Component, Input, OnInit } from '@angular/core';
import { Company } from 'app/shared/models/company.interface';
import {
    RuleBlockInterface,
    RuleBlockSitesInterface,
    RuleSitesAttributionInterface,
    RuleSitesInterface,
} from 'app/shared/models/rule-sites-attribution.interface';
import { UserPopulated } from 'app/shared/models/users.interface';
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { UsersService } from 'app/shared/services/users/users.service';
import * as clone from 'clone';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-user-sites-tab',
    templateUrl: './user-sites.component.html',
    styleUrls: ['./user-sites.component.scss'],
    providers: [],
})
export class UserSitesComponent implements OnInit {
    /** User with companies not populated */
    @Input() user: UserPopulated<string> = null;

    /** User with companies populated */
    public userPopulated: UserPopulated<Company> = null;

    /**
     * A ruleBlockSite is a block of rules, focused on one or multiple companies (rule: RuleSitesAttributionInterface)
     *  +
     *  the list of sites matching the rules and displayed in the table
     *  +
     *  the list filling the selectboxes
     *
     * @type {RuleBlockSitesInterface[]} array of ruleBloc
     */
    public rulesBlocksSites: RuleBlockSitesInterface[] = [];

    public allFluids = [];

    public infoMessage: string = null;
    private displayInfoMessage = false;
    public hasInfoMessage = true;
    public isReadyForAddBtn = false;

    constructor(
        private usersService: UsersService,
        private siteService: SitesService,
        private energyService: EnergyService,
        private companiesService: CompaniesService
    ) {}

    ngOnInit() {
        this.initData();
    }

    /** ------ Initialization --------- **/

    /**
     * Initialize a first block of rules targeting all the user's companies by default
     * Hence, the table aggregates all the sites of all the user's companies
     */
    async initData() {
        try {
            // get the list of companies where the user is
            const companies = await this.companiesService.getCompaniesListByUserId(this.user._id);
            if (companies && companies.length) {
                // store the list in the user object
                this.userPopulated = Object.assign(clone(this.user), {
                    companies: companies.sort(this.sortListAlphabetically('name')),
                });

                // values needed to init the first rule block :
                // regions:     all the regions + departments of France
                // natures & organizations: all the available natures & organizations
                // cities:      coming from the sites of the user's companies
                // fluids:      coming from the sites of the user's companies
                const promiseArray = [
                    this.energyService.getFluidsAvailableForCompanies(this.userPopulated.companies.map(c => c._id)),
                ];
                const resultsFromSites = await Promise.all(promiseArray);
                // format response for selectboxes
                this.allFluids = resultsFromSites[0];

                // if the user doesn't have rules saved in db, init the first ruleBlock with one empty rule
                if (!this.userPopulated.sitesRules.length) {
                    this.addNewRuleBlock();
                } else {
                    // init the ruleBlocks with the userSites values
                    this.initRuleblocksFromUserSiteRules();
                }
            }
        } catch (e) {
            // err.errorCode can be set to error_getCompaniesList, error_getRegions, error_getCategories, error_getCitiesList
            this.getSwalMsg(e.errorCode);
        }
    }

    /**
     * Add a ruleBlockSites with an empty rule
     */
    addNewRuleBlock() {
        const ruleBlockSites = this.createRuleBlockSites();

        this.initRuleBlockSites(ruleBlockSites)
            .then(() => {
                this.rulesBlocksSites.push(ruleBlockSites);
                this.isReadyForAddBtn = true;
            })
            .catch(err => {
                this.rulesBlocksSites.push(ruleBlockSites);
            });
    }

    /**
     * Init the ruleBlocks with the userSites values
     */
    initRuleblocksFromUserSiteRules() {
        this.userPopulated.sitesRules.forEach(siteRule => {
            const ruleBlockSites = this.createRuleBlockSites();
            this.initRuleBlockSites(ruleBlockSites)
                .then(() => {
                    ruleBlockSites.attributionRule = siteRule;
                    this.rulesBlocksSites.push(ruleBlockSites);
                    this.isReadyForAddBtn = true;
                })
                .catch(err => {
                    this.rulesBlocksSites.push(ruleBlockSites);
                });
        });
    }

    /**
     * Returns an object ruleblock which targets by default all the companies left to assign
     *
     * @returns {RuleBlockSitesInterface}
     */
    createRuleBlockSites(): RuleBlockSitesInterface {
        return {
            attributionRule: null,
            sites: {
                sitesMatching: [],
                pagination: {
                    start: 0,
                    end: 0,
                },
                isVisible: true,
                isReady: true,
            },
            isVisible: {
                attributionRule: true,
                sites: true,
            },
        };
    }

    /**
     * Initialize a ruleBlock with one empty rule targeting all the companies the user belongs to.
     * The sites displayed is an aggregation of all the sites from all these companies.
     * Fluids available depend on the companies as well
     * @param {RuleBlockSitesInterface} ruleBlockSites
     * @returns {Promise<void>}
     */
    async initRuleBlockSites(ruleBlockSites: RuleBlockSitesInterface): Promise<void> {
        if (!ruleBlockSites.attributionRule) {
            ruleBlockSites.sites.sitesMatching = [];
            ruleBlockSites.sites.isReady = true;
            return;
        }
        try {
            // in parallel we set the values for the selectboxes,
            // each one only needs to know which companies are preselected on this rulebloc
            const promiseArray = [
                this.getSitesFromRule(ruleBlockSites.attributionRule), // the rule contains the companies
            ];
            const results = await Promise.all(promiseArray);
            const [sites] = results;

            // sites: sites from the companies. Display all of them in the table + selectbox
            ruleBlockSites.sites.sitesMatching = sites;

            // update loaders for site
            ruleBlockSites.sites.isReady = true;
        } catch (e) {
            this.getSwalMsg(e.errorCode);
            throw e;
        }
    }

    /**
     * Return the list of companies that aren't in any ruleBlock:  they are available to apply a new ruleblock on
     * @returns {*}
     */
    getAvailableCompanies(): Company[] {
        // aggregate in 'companiesWithRule' all the companies present in the ruleblocks
        const companiesWithRule = this.rulesBlocksSites.reduce((memo, ruleBlockSite) => {
            if (!ruleBlockSite.attributionRule) {
                return memo;
            }
            memo = memo.concat(ruleBlockSite.attributionRule.companies);
            return memo;
        }, []);

        // keep only the one that aren't in this array
        return clone(this.userPopulated.companies).filter(c => !companiesWithRule.includes(c._id));
    }

    /** ------ Sites functions --------- **/

    /**
     * Update the list of sites displayed with the query params from the rule
     * @param {RuleBlockSitesInterface} ruleBlockSites
     * @returns {Promise<void>}
     */
    async updateSitesSelectedList(ruleBlockSites: RuleBlockSitesInterface): Promise<void> {
        ruleBlockSites.sites.isReady = false;
        this.isReadyForAddBtn = false;
        const sites = await this.getSitesFromRule(ruleBlockSites.attributionRule);
        // We don't simple assignation to trigger change detection hook
        ruleBlockSites.sites = Object.assign({}, ruleBlockSites.sites, { sitesMatching: sites, isReady: true });
        this.isReadyForAddBtn = true;
    }

    /**
     * Query the database with the filters in the rule in params
     * @param {*} rule
     * @returns {Promise<any>} the list of sites matching the query
     */
    getSitesFromRule(rule: any): Promise<any[]> {
        const ruleWithoutEmptyQuery = clone(rule);

        return this.siteService.getSitesFromRule(ruleWithoutEmptyQuery);
    }

    /** ------ Save functions --------- **/

    /**
     * Save ruleblocks
     */
    async saveRuleblocks() {
        const rules = this.rulesBlocksSites
            .map(ruleBlockSites => {
                const ruleWithoutEmptyQuery = clone(ruleBlockSites.attributionRule);

                // if there are more than one rule, don't save the empty ones
                // otherwise the rules with filters will be ignored
                ruleWithoutEmptyQuery.rules = this.deleteEmptyRules(ruleWithoutEmptyQuery.rules);

                return ruleWithoutEmptyQuery;
            })
            .filter(x => {
                return x.companies && x.companies.length && x.rules && x.rules.length;
            });

        try {
            await this.usersService.saveSitesRules(rules, this.userPopulated._id);
            this.userPopulated.sitesRules = rules;
            Swal.fire('Sauvegardé', 'Le périmètre a bien été sauvegardé', 'success');
        } catch (e) {
            this.getSwalMsg(e.errorCode);
        }
    }

    /** -------  Utils functions -------- **/

    /**
     * Helper to sort list alphabetically
     *
     * @param {string} field
     * @returns {*}
     */
    sortListAlphabetically(field: string = 'displayName'): any {
        return (itemA, itemB) => {
            const a = itemA[field];
            const b = itemB[field];
            return a > b ? 1 : a < b ? -1 : 0;
        };
    }

    /** ------ HTML functions to display companies & sites ------- **/

    /**
     * Collapse or uncollapse the filters or sites' table for a specific rule block
     *
     * @param {RuleBlockInterface | RuleSitesInterface} ruleBlock
     * @param {string} element
     */
    collapse(ruleBlock: RuleBlockInterface | RuleSitesInterface, element: string) {
        ruleBlock.isVisible[element] = !ruleBlock.isVisible[element];
    }

    /**
     * Update rule block
     *
     * @param {RuleBlockSitesInterface} ruleBlock
     * @param {RuleSitesAttributionInterface} attributionRule
     */
    updateRuleBlock(ruleBlock: RuleBlockSitesInterface, attributionRule: RuleSitesAttributionInterface) {
        ruleBlock.attributionRule = attributionRule;
        this.updateSitesSelectedList(ruleBlock);
    }

    /**
     * Get text for add rule button
     *
     * @param {string | number} index
     * @returns {string}
     */
    getTextForAddRuleBtn(index: string | number): string {
        return 'Règle n°' + index;
    }

    /**
     * On mouse over info
     */
    onMouseOverInfo() {
        this.displayInfoMessage = true;
    }

    /**
     * On mouse left info
     */
    onMouseLeaveInfo() {
        this.displayInfoMessage = false;
    }

    /**
     * Add border to rule
     *
     * @param {number} index
     * @returns {string}
     */
    addBorderToRule(index: number): string {
        return index > 0 ? 'border' : '';
    }

    /**
     * Returns false if all the companies are already used in a rule
     *
     * @returns {number}
     */
    hasAvailableCompanies(): number {
        return this.getAvailableCompanies().length;
    }

    /**
     * Returns true if we can add another ruleblock
     * 1. e check if some companies are still available for selection
     * 2. each ruleblock needs to have at least one rule with a filter
     *
     * @returns {boolean}
     */
    canAddRuleBlock(): boolean {
        this.isReadyForAddBtn = false;
        if (
            !this.rulesBlocksSites.length ||
            !this.rulesBlocksSites[this.rulesBlocksSites.length - 1].attributionRule ||
            !this.rulesBlocksSites[this.rulesBlocksSites.length - 1].attributionRule.companies
        ) {
            this.infoMessage = null;
            this.hasInfoMessage = true;
            this.isReadyForAddBtn = true;
            return false; // happens if the component hasn't been initialized yet
        } else {
            // we check if some companies are still available for selection
            const hasAvailableCompanies = this.hasAvailableCompanies();

            // returns false if we have one ruleblock with all its rules empty - doesn't mean anything
            const hasOneEmptyRuleblock = this.hasOneEmptyRuleblock();
            this.infoMessage = !hasAvailableCompanies
                ? 'Vous avez déjà des règles pour chacune de vos entreprises.'
                : hasOneEmptyRuleblock
                ? 'Une de vos règles ne contient aucun filtre.'
                : null;
            this.hasInfoMessage = hasOneEmptyRuleblock || !hasAvailableCompanies;
            this.isReadyForAddBtn = true;

            // to add, we need companies available and zero ruleblock with all its rules empty
            return hasAvailableCompanies && !hasOneEmptyRuleblock;
        }
    }

    /**
     * Get tooltip visibility
     *
     * @returns {string}
     */
    getTooltipVisibility(): string {
        return this.displayInfoMessage ? 'visible' : 'not-visible';
    }

    /**
     * Has one empty rule block
     *
     * @returns {boolean}
     */
    hasOneEmptyRuleblock(): boolean {
        return this.rulesBlocksSites.some(ruleBlockSites => {
            return this.isEveryRuleEmpty(ruleBlockSites.attributionRule);
        });
    }

    /**
     * Returns true if the first ruleblock hasn't been created yet or if the last one is empty
     *
     * @param {RuleSitesAttributionInterface} ruleBlock
     * @returns {boolean}
     */
    isEveryRuleEmpty(ruleBlock: RuleSitesAttributionInterface): boolean {
        // if the rule hasn't been created yet
        if (!ruleBlock || !ruleBlock.rules || !ruleBlock.rules.length) {
            return false;
        }

        const hasEveryRuleEmpty = ruleBlock.rules.every(r => {
            return this.isRuleEmpty(r);
        });
        return hasEveryRuleEmpty;
    }

    /**
     * Has one rule empty
     *
     * @param {RuleBlockInterface} ruleBlock
     * @returns {boolean}
     */
    hasOneRuleEmpty(ruleBlock: RuleBlockInterface): boolean {
        // if the rule hasn't been created yet
        if (!ruleBlock || !ruleBlock.rule || !ruleBlock.rule.rules.length) {
            return false;
        } else {
            const hasOneRuleEmpty = ruleBlock.rule.rules.some(r => {
                return this.isRuleEmpty(r);
            });
            return hasOneRuleEmpty;
        }
    }

    /**
     * Is rule empty
     *
     * @param {*} rule
     * @returns {boolean}
     */
    isRuleEmpty(rule: any): boolean {
        let isEmpty = true;
        if (
            !rule.regions.length &&
            !rule.sites.length &&
            !rule.departments.length &&
            !rule.cities.length &&
            !rule.natures.length &&
            !rule.energies.length &&
            !rule.subnatures.length &&
            !rule.routingReferences.length
        ) {
            if (!rule.categories.length) {
                return true;
            } else {
                rule.categories.forEach(catPerOrga => {
                    if (!catPerOrga.organization || !catPerOrga.items.length) {
                        return true;
                    } else {
                        isEmpty = false;
                    }
                });
            }
        } else {
            isEmpty = false;
        }
        return isEmpty;
    }

    /**
     * Delete empty rules
     *
     * @param {*} rules
     * @returns {*[]}
     */
    deleteEmptyRules(rules: any): any[] {
        return rules.reduce((rulesNotEmpty, currentRule) => {
            if (!this.isRuleEmpty(currentRule)) {
                rulesNotEmpty.push(currentRule);
            }
            return rulesNotEmpty;
        }, []);
    }

    /**
     * Sweet alerts when something went wrong
     *
     * @param {string} msgCode
     */
    getSwalMsg(msgCode: string) {
        const messages = {
            error_getSitesFromRule: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des sites.',
                'error',
            ],
            error_getSitesProperties: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des natures.',
                'error',
            ],
            error_getFluidsForCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des fluides.',
                'error',
            ],
            error_getRegionsFromCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des régions.',
                'error',
            ],
            error_getDepartmentsFromCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des départements.',
                'error',
            ],
            error_getCitiesList: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des villes.',
                'error',
            ],
            error_getCompaniesList: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des entreprises.',
                'error',
            ],
            error_default: [
                'Toutes nos excuses',
                'Une erreur est survenue. Veuillez réessayer ultérieurement.',
                'error',
            ],
        };

        const msg = messages[msgCode] ? messages[msgCode] : messages.error_default;

        Swal.fire(msg[0], msg[1], msg[2]);
    }
}
