import { Injectable } from '@angular/core';
import _ = require('lodash');

import { CompletenessRange } from './completeness-data-tab.interface';

@Injectable()
export class CompletenessRangesService {
    /** Returns the completeness ranges used to group routing references in completeness heatmap. */
    public get completenessRanges() {
        return [
            {
                min: Number.NEGATIVE_INFINITY,
                max: 0,
                label: '< 0%',
            },
            {
                min: 0,
                max: 0,
                label: '0%',
            },
            {
                min: 0,
                max: 25,
                label: '0 - 25%',
            },
            {
                min: 25,
                max: 50,
                label: '25 - 50%',
            },
            {
                min: 50,
                max: 75,
                label: '50 - 75%',
            },
            {
                min: 75,
                max: 100,
                label: '75 - 100%',
            },
            {
                min: 100,
                max: 100,
                label: '100%',
            },
            {
                min: 100,
                max: Number.POSITIVE_INFINITY,
                label: '> 100%',
            },
        ];
    }

    /** Returns the index of the completeness range containing given value. */
    public getCompletenessRangeIndex(value: number): number {
        return this.completenessRanges.findIndex(
            range => (range.min <= value && value < range.max) || (value === range.min && value === range.max)
        );
    }
}
