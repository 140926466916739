import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { AccessGuard } from '../../shared/services/guards/access-guard.service';
import { BillsComponent } from './bills.component';

const BILLS_ROUTE = [
    {
        path: '',
        component: BillsComponent,
        canActivate: [AccessGuard],
    },
];

@NgModule({
    declarations: [BillsComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(BILLS_ROUTE)],
})
export class BillsModule {}
