import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';

interface TreeConfig {
    name: string;
    children?: TreeConfig[];
    value?: string;
}

@Injectable()
export class MonitoringCostsService extends PageService {
    /** Default tree-view config */
    public static DEFAULT_CONFIG: Array<{ value: string; key: string }> = [];

    private static readonly DEFAULT_SELECTED_BY_FLUID = {
        elec: ['bill.billAmount.HTVA'],
        gaz: ['bill.billAmount.HTVA'],
        heating: ['totalHT.amount'],
        cooling: ['totalHT.amount'],
        water: ['totalHT.amount'],
    };

    /** The configs for each fluid */
    private costsConfigs = {};

    constructor(public apiService: ApiService, private translateService: TranslateService) {
        super(apiService);
    }

    /** Default selected costs */
    public static getDefaultSelected(fluid: string): string[] {
        if (this.DEFAULT_SELECTED_BY_FLUID[fluid]) {
            return [...this.DEFAULT_SELECTED_BY_FLUID[fluid]];
        }
        return [];
    }

    /** Transform config to a tree-view config */
    public formatConfig(config: TreeConfig, level: number = 0): Array<{ value: any; key: string }> {
        let options: Array<{ value: any; key: string }> = [];
        const namePrefix = '-'.repeat(level);
        options.push({ value: config.value || '', key: `${namePrefix} ${config.name}` });

        if (Array.isArray(config.children)) {
            const children = config.children;
            for (let i = 0, len = children.length; i < len; i++) {
                options = options.concat(this.formatConfig(children[i], level + 1));
            }
        }

        return options;
    }

    /** Get costs tree-view config for a given fluid */
    public async getCostsConfig(fluid: string): Promise<Array<{ value: any; key: string }>> {
        if (!fluid) {
            return MonitoringCostsService.DEFAULT_CONFIG;
        }

        if (!this.costsConfigs[fluid]) {
            await this.getCostsProperties(fluid);
        }

        return this.costsConfigs[fluid] || MonitoringCostsService.DEFAULT_CONFIG;
    }

    /** Retrieve costs config for a given fluid */
    private async getCostsProperties(fluid: string): Promise<void> {
        try {
            const costsResult = await this.get(`/api/common/cost-properties/${fluid}`);
            this.costsConfigs[fluid] = this.formatConfig({ name: 'Tous', children: costsResult.data }, 0);
        } catch (err) {
            Swal.fire({
                title: this.translateService._('error_we_are_sorry'),
                text: this.translateService._('error_monitoring_config'),
                icon: 'error',
            });
        }
    }
}
