import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { PageService } from '../../../shared/services/page/page.service';

import { CO2eEmission } from 'app/shared/models/co2emissions.interface';
import * as moment from 'moment';

@Injectable()
export class ParkMonitoringService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    /**
     * Format CO2eEmission data dates to be display in charts correctly instead of ISO Strings.
     * Also check emission units to set convert to ton (T)
     * Mutates original objects.
     * @param {CO2eEmission[]} emissions
     * @returns {CO2eEmission[]}
     */
    formatCo2eEmissions(emissions: CO2eEmission[]): CO2eEmission[] {
        emissions.forEach(emission => {
            emission.date = moment.utc(emission.date).format('MM-YYYY');
            this.convertEmissionToT(emission);
        });
        return emissions;
    }

    /**
     * Convert emission values co2e et co2eAvg to ton.
     * Mutates original object.
     * @param {CO2eEmission} emission - emission to convert values from.
     */
    private convertEmissionToT(emission: CO2eEmission) {
        switch (emission.co2Unit) {
            case 'kg':
                emission.co2e = emission.co2e / 1000;
                emission.co2eAvg = emission.co2eAvg / 1000;
                break;
            case 'g':
                emission.co2e = emission.co2e / 1000000;
                emission.co2eAvg = emission.co2eAvg / 1000000;
                break;
            default:
                break;
        }
    }
}
