import { Injectable } from '@angular/core';

import * as moment from 'moment';
// services
import { ApiService } from '../../shared/services/api/api.service';
import { PageService } from '../../shared/services/page/page.service';

// interfaces
import { QueryFilter } from 'app/shared/models/bills-contracts-filter.interface';
import {
    Contract,
    ContractLight,
    ContractPopulated,
    NoticeState,
    NoticeStateName,
} from 'app/shared/models/contract.interface';
import { QueryPagination, QuerySort } from 'app/shared/models/pagination.interface';
import * as clone from 'clone';
import { ContractDisplay, ContractPopulatedDisplayOld } from './contracts.interface';

@Injectable()
export class ContractsService extends PageService {
    /*
        6 states possible:
       - loading
        - before notice date
        - between notice and end date
        - NC
        - dates in error
        - completed
    * */
    public noticeStatesColors: NoticeState[] = [
        {
            name: 'inProgress',
            color: 'green',
            msg: 'En cours',
            priority: 2,
        },
        {
            name: 'incoming',
            color: 'green',
            msg: 'A venir',
            priority: 2,
        },
        {
            name: 'onNotice',
            color: 'orange',
            msg: 'En préavis',
            priority: 3,
        },
        {
            name: 'nc',
            color: 'red',
            msg: 'NC (date non renseignée)',
            priority: 4,
        },
        {
            name: 'error',
            color: 'red',
            msg: 'Erreur (date renseignée erronée)',
            priority: 4,
        },
        {
            name: 'completed',
            color: 'grey',
            msg: 'Terminé',
            priority: 1,
        },
        {
            name: 'loading',
            color: 'grey',
            msg: 'Chargement...',
            priority: 1,
        },
    ];

    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    /***** API *****/

    /**
     * Returns the contracts of the company matching filters, pagination & sort
     * Get the overall contracts number, and the specific contracts to display matching pagination
     * @param {string} companyId
     * @param {QueryFilter} filter
     * @param {QueryPagination} pagination
     * @param {QuerySort} sort
     * @returns {Promise<{total: number, results: ContractLight[]}>}
     */
    async getContractsFromPagination(
        companyId: string,
        filter: QueryFilter,
        pagination: QueryPagination,
        sort: QuerySort
    ): Promise<{ total: number; results: ContractLight[] }> {
        const res = await this.post('/api/contracts/list', {
            companyId,
            filters: filter,
            pagination,
            sort,
        });

        return { total: res.data.total, results: res.data.results };
    }

    async updateContract(contract: ContractPopulated): Promise<APICitronResponse> {
        try {
            const route = '/api/contracts/crud/' + contract._id;
            const response = await this.put(route, contract, null, true);
            if (response.data.matchedCount === 1) {
                return response;
            } else {
                const err = { error_code: 'error_updateContract' };
                return Promise.reject(err);
            }
        } catch (err) {
            err.error_code = 'error_updateContract';
            return Promise.reject(err);
        }
    }

    /**
     * Return the contract of the company with the aggregation of all the required properties existing in its bill
     * @param {string} contractId
     * @return {Promise<ContractPopulatedDisplayOld>}
     */
    async getContractWithProperties(contractId: string): Promise<ContractPopulatedDisplayOld> {
        try {
            const res = await this.get('/api/contracts/' + contractId + '/properties');
            return res.data;
        } catch (err) {
            err.error_code = 'error_getContract';
            return Promise.reject(err);
        }
    }

    /**
     * Return a contract with each routingReference populated with its sites
     * @param {string} contractId
     * @return {Promise<ContractPopulatedDisplayOld>}
     */
    async getContractWithSitesPerRf(contractId: string): Promise<ContractPopulatedDisplayOld> {
        try {
            const res = await this.get('/api/contracts/' + contractId + '/sites');
            return res.data;
        } catch (err) {
            err.error_code = 'error_getContract';
            return Promise.reject(err);
        }
    }

    /********** Functions shared **********/

    /** 5 states possible:
        - before notice date
        - between notice and end date
        - NC
        - dates in error
        - completed
    * */
    initNoticeState(contract: Contract): ContractDisplay {
        const c: ContractDisplay = clone(contract);
        c.noticeState = this.getNoticeStateByName('nc');

        if (contract.infos) {
            const start = contract.infos.dateStart;
            const end = contract.infos.dateEnd;
            const notice = contract.infos.dateNotice;

            // nc > start and end not defined
            if (contract.infos && start && end) {
                // error : if start > end
                // error : error : if notice is not set as start < notice < end
                const startBeforeEnd: boolean = moment(start).isBefore(moment(end));
                const noticeBetweenStartAndEnd: boolean =
                    moment(start).isBefore(moment(notice)) && moment(notice).isBefore(moment(end));

                if (!startBeforeEnd || (notice && !noticeBetweenStartAndEnd)) {
                    c.noticeState = this.getNoticeStateByName('error');
                } else {
                    // incoming: now < start
                    if (moment().isBefore(start)) {
                        c.noticeState = this.getNoticeStateByName('incoming');
                    }
                    //  inProgress :  start < now < end
                    if (moment().isAfter(start) && moment().isBefore(end)) {
                        c.noticeState = this.getNoticeStateByName('inProgress');
                    }
                    // onNotice: notice < now < end
                    if (notice && moment().isAfter(notice) && moment().isBefore(end)) {
                        c.noticeState = this.getNoticeStateByName('onNotice');
                    } else if (moment().isAfter(end)) {
                        // completed: contract is completed
                        c.noticeState = this.getNoticeStateByName('completed');
                    }
                }
            }
        }
        return c;
    }

    getNoticeStateByName(name: NoticeStateName): NoticeState {
        return this.noticeStatesColors.find(state => state.name === name);
    }

    /**
     * Get notice states to be displayed in filters
     * @returns {NoticeState[]}
     */
    public getNoticeStatesFilter(): NoticeState[] {
        const notInclude = ['error', 'loading'];
        return this.noticeStatesColors.filter(x => !notInclude.includes(x.name));
    }
}
