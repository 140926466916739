import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

interface CompanyProperties {
    categories: any[];
    natures: any[];
    organizations: any[];
}

@Injectable()
export class PropertiesService extends PageService {
    private allProperties: CompanyProperties;
    private availableProperties: CompanyProperties;

    /**
     * Company id of the properties.
     * If an other company is requested, will update the properties.
     */
    private companyId: string;

    /**
     * Processing promise. If not null, it means that properties are refreshing.
     */
    private processing: Promise<CompanyProperties[]> = null;

    constructor(public apiService: ApiService) {
        super(apiService);
        this.companyId = this.apiService.getCompanyId();
        this.refresh();
    }

    /**
     * Get the properties for given company.
     * @param {boolean} onlyValuesFromCompany - If true, get only properties used by the company (ie. categories assigned to at least one site).
     * @param {Params} params
     * @returns {Promise} Properties of the company
     */
    public async getProperties(onlyValuesFromCompany: boolean, params: Params = {}): Promise<CompanyProperties> {
        /**
         * If company asked is different from the current company, then get the properties for the given company.
         * If current company changed, refresh the properties
         */
        if (params && params.companyId && params.companyId !== this.companyId) {
            return this.getCompanyProperties(onlyValuesFromCompany, params);
        } else if (this.companyId !== this.apiService.getCompanyId()) {
            this.companyId = this.apiService.getCompanyId();
            await this.refresh();
        }
        if (this.processing) {
            await this.processing;
        }
        return onlyValuesFromCompany ? this.getAvailableProperties() : this.getAllProperties();
    }

    /**
     * Get the all properties.
     * @returns {CompanyProperties} all properties for a company
     */
    public getAllProperties(): CompanyProperties {
        return this.allProperties;
    }

    /**
     * Get the companie's used properties.
     * @returns {CompanyProperties} available properties for a company
     */
    public getAvailableProperties(): CompanyProperties {
        return this.availableProperties;
    }

    /**
     * Refresh notifications and send event to all notifications subscribers
     */
    public async refresh(): Promise<any> {
        try {
            const params = { companyId: this.companyId };
            this.processing = Promise.all([this.refreshAllProperties(params), this.refreshAvailableProperties(params)]);
            await this.processing;
            this.processing = null;
            return true;
        } catch (error) {
            this.processing = null;
            return false;
        }
    }

    /**
     * Refresh all properties and send event to all properties subscribers
     * @param {Params} params
     * @returns {Promise<*>}
     */
    private async refreshAllProperties(params: Params = {}): Promise<any> {
        try {
            const res = await this.getCompanyProperties(false, params);

            this.allProperties = res.data;
        } catch (err) {
            this.allProperties = this.getEmptyProperties();
            throw { errorCode: 'error_get_allProperties' };
        }
    }

    /**
     * Refresh available properties and send event to available properties subscribers
     * @param {Params} params
     * @returns {Promise<*>}
     */
    private async refreshAvailableProperties(params: Params = {}): Promise<void> {
        try {
            const res = await this.getCompanyProperties(true, params);

            this.availableProperties = res.data;
        } catch (err) {
            this.availableProperties = this.getEmptyProperties();
            throw { errorCode: 'error_get_availableProperties' };
        }
    }

    /**
     * Get company properties from API
     * @param {boolean} onlyValuesFromCompany
     * @param {Params} params
     * @returns {Promise<*>}
     */
    public async getCompanyProperties(onlyValuesFromCompany: boolean, params: Params = {}): Promise<any> {
        const url = onlyValuesFromCompany
            ? `/api/sites/company/available/properties`
            : `/api/sites/all/available/properties`;
        return this.get(url, null, params);
    }

    /**
     * Get empty properties values
     * @returns {CompanyProperties}
     */
    private getEmptyProperties(): CompanyProperties {
        return {
            categories: [],
            natures: [],
            organizations: [],
        };
    }
}
