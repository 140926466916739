import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Marker, MarkerType } from 'app/shared/models/marker.interface';
import { Site } from 'app/shared/models/site.interface';
/**
 * This service handles for the most the map (hugely used) style and icons.
 * It also handles the contact with Google Street View API to get sites front pictures
 */
@Injectable()
export class MapService {
    // list of icon url associated with an identifier (that back end will provide)
    public iconsUrl = {
        elec: 'assets/img/carto/geolocation_small.png',
        fuel: 'assets/img/carto/geolocation_small.png',
        water: 'assets/img/carto/geolocation_small.png',
        defaultOne: 'assets/img/carto/geolocation_small.png',
        beforeNotice: 'assets/img/carto/geolocation_small_green.png',
        onNotice: 'assets/img/carto/geolocation_small_orange.png',
        nc: 'assets/img/carto/geolocation_small_red.png',
        error: 'assets/img/carto/geolocation_small_red.png',
        completed: 'assets/img/carto/geolocation_small_grey.png',
        loading: 'assets/img/carto/geolocation_small_grey.png',
    };

    // google map style
    private mapStyle = [
        {
            featureType: 'all',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#444444',
                },
            ],
        },
        {
            featureType: 'administrative.country',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    weight: '1.2',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'administrative.province',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    weight: '1',
                },
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative.neighborhood',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
                {
                    color: '#f2f2f2',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.business',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'all',
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'simplified',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'transit.station',
            elementType: 'all',
            stylers: [
                {
                    visibility: 'on',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'all',
            stylers: [
                {
                    color: '#b4d2dc',
                },
            ],
        },
    ];

    constructor(private sanitizer: DomSanitizer) {}

    public getMapStyle(): any[] {
        return this.mapStyle;
    }

    public getIconUrl(type: MarkerType): string {
        return this.iconsUrl[type] || this.iconsUrl.defaultOne;
    }

    public getSitePicture(site): string {
        const siteNb = site.streetNumber || '';
        const zipCode = site.zipcode || '';

        const address = `${siteNb} ${site.streetName} ${zipCode} ${site.city}`;
        const key = 'AIzaSyBhzQGzbrFE_V0Lot8-DicgBnxhglT0Jko'; // specific for street view

        const picture = `https://maps.googleapis.com/maps/api/streetview?size=650x725&location=${address}&key=${key}`;

        return encodeURI(picture).replace(/'/g, '%27'); // sanitize URL replacing spaces and accents
    }

    /**
     * Get site picture as background for CSS at format 'url("...")'.
     * If site has no picture, get Google Streetview picture of address.
     * @param {Site} site - site to get picture from
     * @returns {string} CSS formatted url and sanitized
     */
    public getSitePictureAsBackground(site): any {
        const siteHasImg = Boolean(site && site.info && site.info.img && site.info.img.url);
        const pictureUrl = siteHasImg ? site.info.img.url.replace("'", '%27') : this.getSitePicture(site);
        return this.sanitizer.bypassSecurityTrustStyle(`url('${pictureUrl}')`);
    }

    /**
     * Return data needed to create marker.
     * @param {Site} site
     */
    getSiteDataForMarkersAddress(site: Site) {
        return {
            _id: site._id,
            complement: site.complement,
            city: site.city,
            streetNumber: site.streetNumber,
            streetName: site.streetName,
            zipcode: site.zipcode,
            name: site.name,
        };
    }

    getRoutingReferenceDataForMarkersAddress(routingReference) {
        return {
            _id: routingReference._id,
            reference: routingReference.reference,
            city: routingReference.address.city,
            streetNumber: routingReference.address.streetNumber,
            streetName: routingReference.address.streetName,
            zipcode: routingReference.address.zipcode,
        };
    }

    /**
     * Returns markers for each site
     * @param {Site[]} sites
     * @param {MarkerType} type
     * @returns {Marker[]}
     */
    getMarkersFromSites(sites: Site[], type: MarkerType = 'energy'): Marker[] {
        return sites.map(site => {
            const m: Marker = {
                lat: site.latitude,
                lng: site.longitude,
                config: 'site',
                type,
                data: this.getSiteDataForMarkersAddress(site),
            };
            return m;
        });
    }
}
