import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

// use french local
moment.locale('fr-fr');

const defaultFormat = 'DD/MM/YYYY';

@Pipe({
    name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
    transform(value: string, format: string = ''): string {
        return value ? moment(value).format(format || defaultFormat) : null;
    }
}
