import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

// services
import { ApiService } from '../../../shared/services/api/api.service';
import { EnergyService } from '../../../shared/services/energy/energy.service';
import { FilterService } from '../../../shared/services/filter/filter.service';
import { MapService } from '../../../shared/services/map/map.service';
import { PageService } from '../../../shared/services/page/page.service';
import { SitesService } from '../../../shared/services/sites/sites.service';

// interfaces
import { QueryFilterRoutingRef } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { Dju } from 'app/shared/models/dju.interface';
import { Marker } from 'app/shared/models/marker.interface';
import {
    CompareBarChartData,
    CompareBarChartSerie,
    MonthData,
    MonthDataItem,
    MonthLabel,
    YearData,
} from './compare.interface';

interface DataSumByEnergy {
    indexYearOverYear: number;
    items: MonthDataItem[];
}

@Injectable()
export class CompareService extends PageService {
    constructor(
        public apiService: ApiService,
        public energyService: EnergyService,
        public sitesService: SitesService,
        private filterService: FilterService,
        private mapService: MapService
    ) {
        super(apiService);
    }

    /**
     * Get sites' markers for the sites matching filters
     * @returns {Marker[]} markers
     */
    public async handleMarkers(filters: QueryFilterRoutingRef): Promise<Marker[]> {
        const sites = await this.getSitesFromFilters(filters);
        return sites.map(site => {
            const marker: Marker = {
                lat: site.latitude,
                lng: site.longitude,
                config: 'site',
                data: this.mapService.getSiteDataForMarkersAddress(site),
            };
            return marker;
        });
    }

    /**
     * Get sites from filters
     * @param {QueryFilterRoutingRef} filters
     * @returns {Promise<*[]>} list of sites
     */
    private async getSitesFromFilters(filters: QueryFilterRoutingRef): Promise<any[]> {
        const filteredSites = await this.sitesService.getSites(filters);
        return filteredSites.data;
    }

    /**
     * Process data to get the total of consumption
     * @param {YearData[]} data from the response : { year: 2018, items: [ { consumption : { quantity : 222 }}]}
     * @returns {number} Float total consumption
     */
    getTotalConso(data: YearData[], filters: QueryFilterRoutingRef): number {
        return data.reduce((memo, currentItem) => {
            currentItem.items.forEach(energy => {
                if (energy.consumption || energy.consumption.quantity) {
                    if (this.isPrimaryEnergy(filters.energies)) {
                        memo += this.energyService.convertEnergyKwhToKwhep(
                            energy.energyType,
                            energy.consumption.quantity
                        );
                    } else {
                        memo += energy.consumption.quantity;
                    }
                }
            });

            return memo;
        }, 0);
    }

    /**
     * Process data to get the total cost
     * @param {YearData[]} data from the response : { year: 2018, items: [ { consumption : { quantity : 222 }}]}
     * @returns {{ totalTTC: number, totalHTVA: number }
     */
    getTotalCost(responseData: YearData[]): { totalHTVA: number; totalTTC: number } {
        return responseData.reduce(
            (memo, currentItem) => {
                currentItem.items.forEach(energy => {
                    if (energy.totalHT && energy.totalHT.amount) {
                        memo.totalHTVA += energy.totalHT.amount;
                    }
                    if (energy.totalTTC && energy.totalTTC.amount) {
                        memo.totalTTC += energy.totalTTC.amount;
                    }
                });

                return memo;
            },
            {
                totalHTVA: 0,
                totalTTC: 0,
            }
        );
    }

    /**
     * Process data to return an array of all years found in the input data.
     * @param {MonthData[]} data from the API response : [ { year: 2018  , month: 0, items: [], ...}, { year: 2018  , month: 1, ...}]
     * @returns {number[]} Array of all years found in the response, in chronological order: [ 2016, 2017, 2018 ]
     */
    getYearsInMonthData(data: MonthData[]): number[] {
        return data.reduce((memo, currentItem) => {
            // If the year of the item already exists, push in the same array

            if (!memo.includes(currentItem.year)) {
                memo.unshift(currentItem.year);
            }

            return memo;
        }, []);
    }

    /**
     * Process data to create array of array
     * @param response from the response : [ { year: 2016  , month: 01, ...}, { year: 2016  , month: 02, ...}]
     * @returns {MonthData[][]} Array of month grouped by year and sorted by month
     */
    formateResponseInYearsArray(response: MonthData[]): MonthData[][] {
        return response.reduce((memo: any[], currentItem) => {
            // If the year of the item already exists, push in the same array

            const year = memo.find(yearArray => {
                return yearArray.find(item => {
                    return currentItem.year === item.year;
                });
            });

            if (year && year.length) {
                year.push(currentItem);
            } else {
                memo.unshift([currentItem]);
            }

            return memo;
        }, []);
    }

    /**
     * Process data to create all months of consumption, grouped by year.
     * @param {YearData[]} data : data grouped by year
     * @param {QueryFilterRoutingRef} filters - filter used to aggregate the data (includes other fields not used here)
     * @param {Dju}[] - dju data per year & month
     * @returns {CompareBarChartData}
     */
    formatYearBarChartData(data: YearData[], filters: QueryFilterRoutingRef, yearDjus: Dju[]): CompareBarChartData {
        const response: CompareBarChartData = {
            data: {
                chart: [],
            },
            labels: [],
        };

        const dataPerEnergy = {
            ttc: {},
            ht: {},
            kWhep: {},
        };

        const indexYears = this.getGraphYearsIndex(filters.dateStart, filters.dateEnd);
        const labels = this.getGraphYears(filters.dateStart, filters.dateEnd);
        const nbYears = labels.length;

        data.forEach(databyYear => {
            // databyYear.year = parseInt(databyYear.year.toString(), 10);
            databyYear.items.forEach(energy => {
                for (const priceTypeOrConso in dataPerEnergy) {
                    if (dataPerEnergy.hasOwnProperty(priceTypeOrConso)) {
                        // Init all months values to O if the energy is not set.
                        if (typeof dataPerEnergy[priceTypeOrConso][energy.energyType] === 'undefined') {
                            dataPerEnergy[priceTypeOrConso][energy.energyType] = Array.apply(null, Array(nbYears)).map(
                                Number.prototype.valueOf,
                                0
                            );
                        }

                        // We get the price as we want HT or TTC
                        let priceOrConso = null;

                        switch (priceTypeOrConso) {
                            case 'ht':
                                priceOrConso = energy.totalHT.amount;
                                break;

                            case 'ttc':
                                priceOrConso = energy.totalTTC.amount;
                                break;

                            case 'kWhep':
                                // if we compare multiple fluids -> compare in Primary Energy kWhep
                                if (this.isPrimaryEnergy(filters.energies)) {
                                    priceOrConso = this.energyService.convertEnergyKwhToKwhep(
                                        energy.energyType,
                                        energy.consumption.quantity
                                    );
                                } else {
                                    priceOrConso = energy.consumption.quantity;
                                }
                                break;
                        }

                        // We add the price to energy values
                        dataPerEnergy[priceTypeOrConso][energy.energyType][indexYears[databyYear.year]] += priceOrConso;
                    }
                }
            });
        });

        // reconstruct DJUs when all the required years are not found
        let djusForChart = Array.from(yearDjus);

        if (nbYears !== yearDjus.length) {
            // initiliase DJUs for each year with value null
            djusForChart = Array.apply(null, Array(nbYears)).map(() => getDefaultDataDju());
            data.forEach(databyYear => {
                // set DJU for that year
                djusForChart[indexYears[databyYear.year]] = yearDjus.find(item => item.year === databyYear.year);
            });
        }
        function getDefaultDataDju() {
            return { cold: null, hot: null, date: null, month: null, year: null, station: null, _id: null };
        }

        // We have all the values HT and TTC by energies. We create the chart data from it
        // There is only one stack so index is 0
        const chart = this.formatDataEnergyToChartBarSerie(dataPerEnergy, 0, djusForChart);

        response.data.chart = [chart.kWhep, chart.ht, chart.ttc];
        response.labels = labels.map(toString);

        return response;
    }

    /**
     * Process data to create all months of consumption, grouped by year.
     * @param {MonthData[]} data - data of one year sorted by month
     * @param {number} stackIndex of the year : will be the column index (ex : index 1 is for 2016 and will always be the 2nd column)
     * @param {QueryFilterRoutingRef} filters contains all filters, including dateStart and dateEnd ]
     * @returns {CompareBarChartData} Data for chart
     */
    formatOneYearData(data: MonthData[], stackIndex: number, filters: QueryFilterRoutingRef): CompareBarChartData {
        const response: CompareBarChartData = {
            data: {
                chart: [],
            },
            labels: [],
        };

        const dataPerEnergy = {
            ttc: {},
            ht: {},
            kWhep: {},
        };

        const indexMonths = this.getGraphAbscisses(filters.dateStart, filters.dateEnd);
        const labels = this.getGraphAbscisseLabels(filters.dateStart, filters.dateEnd);
        const nbMonths = labels.length;

        data.forEach(databyMonth => {
            databyMonth.items.forEach(energy => {
                for (const priceTypeOrConso in dataPerEnergy) {
                    if (!dataPerEnergy.hasOwnProperty(priceTypeOrConso)) {
                        continue;
                    }
                    // Init all months values to O if the energy is not set.
                    if (typeof dataPerEnergy[priceTypeOrConso][energy.energyType] === 'undefined') {
                        dataPerEnergy[priceTypeOrConso][energy.energyType] = Array.apply(null, Array(nbMonths)).map(
                            Number.prototype.valueOf,
                            0
                        );
                    }

                    // We get the price as we want HT or TTC
                    let priceOrConso = 0;

                    switch (priceTypeOrConso) {
                        case 'ht':
                            priceOrConso = energy.totalHT.amount;
                            break;

                        case 'ttc':
                            priceOrConso = energy.totalTTC.amount;
                            break;

                        case 'kWhep':
                            // if we compare multiple fluids -> compare in Primary Energy kWhep
                            if (this.isPrimaryEnergy(filters.energies)) {
                                priceOrConso = this.energyService.convertEnergyKwhToKwhep(
                                    energy.energyType,
                                    energy.consumption.quantity
                                );
                            } else {
                                priceOrConso = energy.consumption.quantity;
                            }
                            break;
                    }

                    // We add the price to energy values
                    dataPerEnergy[priceTypeOrConso][energy.energyType][indexMonths[databyMonth.month]] = priceOrConso;
                }
            });
        });

        // We have all the values HT and TTC by energies. We create the chart data from it
        const chart = this.formatDataEnergyToChartBarSerie(dataPerEnergy, stackIndex, null);

        response.data.chart = [chart.kWhep, chart.ht, chart.ttc];
        response.labels = labels;

        return response;
    }

    /**
     * Prepare twelve month group
     * @param {QueryFilterRoutingRef} filters
     * @returns {MonthLabel[][]} Array of all group of years possible since dateStart on twelve month
     * ex. [ [ { month: 2, year: 2018, label: "02-2018"}, ..., { month: 1, year: 2019, label: "01-2019" } ],
     * [ { month: 3, year: 2018, label: "03-2018"}, ..., { month: 2, year: 2019, label: "02-2019" } ],
     * [ { month: 4, year: 2018, label: "04-2018"}, ..., { month: 3, year: 2019, label: "03-2019" } ],
     * [ { month: 5, year: 2018, label: "05-2018"}, ..., { month: 4, year: 2019, label: "04-2019" } ], ... ]
     */
    prepareTwelveMonthGroup(filters: QueryFilterRoutingRef): MonthLabel[][] {
        const yearOverYearGroup: MonthLabel[][] = [];

        // Build twelve group with
        for (let i = 0; i < 12; i++) {
            yearOverYearGroup[i] = [];

            for (let j = 0; j < 12; j++) {
                const dateStart = moment(filters.dateStart);

                dateStart.add(i, 'months');
                dateStart.add(j, 'months');

                const monthIndex = dateStart.month();
                const yearLabel = dateStart
                    .year()
                    .toString()
                    .substring(2, 4);
                const year = dateStart.year();
                const month = monthIndex + 1;
                const displayMonth = month < 10 ? '0' + month : '' + month;
                const label = displayMonth + '/' + yearLabel;

                yearOverYearGroup[i][j] = {
                    month: monthIndex,
                    year,
                    label,
                };
            }
        }

        return yearOverYearGroup;
    }

    /**
     * Group data by by and month corresponding to yearOverYearGroup grouping
     * @param {MonthLabel[][]} yearOverYearGroup Array of all group of years possible since dateStart on twelve month
     * @param {any[]} data 24 month of data (consumption of DJU)
     * @param {number} monthStartAt Offset for data which months do not starts at 0
     * @returns {MonthData[][]} Array of all group of years possible since dateStart on twelve month WITH DATA (items)
     * ex. [ [ { month: 3, year: 2018, items: []}, ..., {month: 2, year: 2019, items: []} ],
     *    [ { month: 4, year: 2018, items: []}, ..., {month: 3, year: 2019, items: []} ],
     *    [ { month: 5, year: 2018, items: []}, ..., {month: 4, year: 2019, items: []} ],
     *    [ { month: 6, year: 2018, items: []}, ..., {month: 5, year: 2019, items: []} ],
     *    [ { month: 7, year: 2018, items: []}, ..., {month: 6, year: 2019, items: []} ], ... ]
     */
    formatDataToGroup(yearOverYearGroup: MonthLabel[][], data: Dju[], monthStartAt: number): Dju[][];
    formatDataToGroup(yearOverYearGroup: MonthLabel[][], data: MonthData[], monthStartAt: number): MonthData[][];
    formatDataToGroup(
        yearOverYearGroup: MonthLabel[][],
        data: Array<MonthData | Dju>,
        monthStartAt: number
    ): Array<Array<MonthData | Dju>> {
        if (!data || !data.length) {
            return [];
        }

        const yearOverYearGroupData = yearOverYearGroup.map(year => {
            const groupData = [];

            year.forEach(month => {
                const monthFound = data.find(item => {
                    return item.month - monthStartAt === month.month && item.year === month.year;
                });

                groupData.push(monthFound);
            });
            return groupData;
        });

        return yearOverYearGroupData;
    }

    /**
     * formatDataSumByEnergy get 12 months grouped by energy
     * @param dataFormated Array of all group of years possible since dateStart on twelve month WITH DATA (items)
     * @returns {MonthData[][]} dataFormatedByEnergy
     * Ex. [ { yearOverYearIndex : 0, items :[ elec, gaz]}, { yearOverYearIndex : 1, items :[ elec, gaz]}, { yearOverYearIndex : 2, items :[ elec, gaz]} ]
     */
    formatDataSumByEnergy(dataFormated: MonthData[][]): DataSumByEnergy[] {
        return dataFormated.map((year, indexYear) => {
            const init: DataSumByEnergy = {
                indexYearOverYear: indexYear,
                items: [],
            };
            return year.reduce((memo: DataSumByEnergy, currentMonth) => {
                if (currentMonth) {
                    memo.items = this.mergeItems(memo.items, currentMonth.items);
                }
                return memo;
            }, init);
        });
    }

    /**
     * Get DJU's sum by data grouping (used for year over year)
     * @param {Dju[][]} dataFormated - dju grouped
     * @returns {{indexYearOverYear: number, hot: number, cold: number}[]}
     */
    formatDjusSumByGroup(dataFormated: Dju[][]): Array<{ indexYearOverYear: number; hot: number; cold: number }> {
        return dataFormated.map((year, indexYear) => {
            const init = {
                indexYearOverYear: indexYear,
                hot: 0,
                cold: 0,
            };
            return year.reduce((memo, currentMonth) => {
                if (currentMonth) {
                    memo.hot += currentMonth.hot;
                    memo.cold += currentMonth.cold;
                }

                return memo;
            }, init);
        });
    }

    /**
     * Deep copy item
     * @param {<T>} item
     * @returns {T}
     */
    getItemCopy<T>(item: T): T {
        return _.cloneDeep(item);
    }

    /**
     * Merge month data items
     * @param {MonthDataItem[]} itemsBase
     * @param {MonthDataItem[]} itemsToMerge
     * @returns {MonthDataItem[]}
     */
    mergeItems(itemsBase: MonthDataItem[], itemsToMerge: MonthDataItem[]): MonthDataItem[] {
        if (!itemsBase || !itemsBase.length) {
            return itemsToMerge.map(itemToMerge => {
                return this.getItemCopy(itemToMerge);
            });
        }
        itemsToMerge.map(itemToMerge => {
            const energyItem = itemsBase.find(itemBase => {
                return itemBase.energyType === itemToMerge.energyType;
            });

            // If energy not existing yet, add it
            if (!energyItem) {
                itemsBase.push(this.getItemCopy(itemToMerge));
            } else {
                // Update all sumable
                energyItem.co2 += itemToMerge.co2;
                energyItem.consumption.quantity += itemToMerge.consumption.quantity;
                energyItem.totalHT.amount += itemToMerge.totalHT.amount;
                energyItem.totalTTC.amount += itemToMerge.totalTTC.amount;
            }
        });

        return itemsBase;
    }

    /**
     * Get labels for year over year charts
     * @param {MonthLabel[][]} twelveMonths
     * @returns {string[]} labels
     */
    getLabels(twelveMonths: MonthLabel[][]): string[] {
        return twelveMonths.reduce((memo: string[], currentYearOverYear) => {
            const firstItemOfYearOverYear = currentYearOverYear[0].label;
            const lastItemOfYearOverYear = currentYearOverYear[11].label;
            const label = firstItemOfYearOverYear + '-' + lastItemOfYearOverYear;
            memo.push(label);
            return memo;
        }, []);
    }

    /**
     * Process data to create sliping year charts.
     * @param {MonthData[]} data
     * @param {QueryFilterRoutingRef} filters
     * @param {Dju} yearOverYearDjus - dju grouped by year and month
     * @returns {CompareBarChartData} data formatted for chart
     */
    formatOneYearOverYearData(
        data: MonthData[],
        filters: QueryFilterRoutingRef,
        yearOverYearDjus: Dju[]
    ): CompareBarChartData {
        const response: CompareBarChartData = {
            data: {
                chart: [],
            },
            labels: [],
        };

        const dataPerEnergy = {
            ttc: {},
            ht: {},
            kWhep: {},
        };

        const twelveMonthGroup = this.prepareTwelveMonthGroup(filters);
        const labels = this.getLabels(twelveMonthGroup);
        const dataFormated = this.formatDataToGroup(twelveMonthGroup, data, 0);
        const djuFormated = this.formatDataToGroup(twelveMonthGroup, yearOverYearDjus, 1);
        const djuSumYearOverYear = this.formatDjusSumByGroup(djuFormated);
        const dataSumByEnergy = this.formatDataSumByEnergy(dataFormated);

        dataSumByEnergy.forEach((databyYearOverYear, indexYear) => {
            databyYearOverYear.items.forEach(energy => {
                for (const priceTypeOrConso in dataPerEnergy) {
                    if (!dataPerEnergy.hasOwnProperty(priceTypeOrConso)) {
                        continue;
                    }
                    // Init all months values to O if the energy is not set.
                    if (typeof dataPerEnergy[priceTypeOrConso][energy.energyType] === 'undefined') {
                        dataPerEnergy[priceTypeOrConso][energy.energyType] = Array.apply(null, Array(12)).map(
                            Number.prototype.valueOf,
                            0
                        );
                    }

                    // We get the price as we want HT or TTC
                    let priceOrConso = 0;

                    switch (priceTypeOrConso) {
                        case 'ht':
                            priceOrConso = energy.totalHT.amount;
                            break;

                        case 'ttc':
                            priceOrConso = energy.totalTTC.amount;
                            break;

                        case 'kWhep':
                            // if we compare multiple fluids -> compare in Primary Energy kWhep
                            if (this.isPrimaryEnergy(filters.energies)) {
                                priceOrConso = this.energyService.convertEnergyKwhToKwhep(
                                    energy.energyType,
                                    energy.consumption.quantity
                                );
                            } else {
                                priceOrConso = energy.consumption.quantity;
                            }
                            break;
                    }

                    // We add the price to energy values
                    dataPerEnergy[priceTypeOrConso][energy.energyType][indexYear] = priceOrConso;
                }
            });
        });

        // We have all the values HT and TTC by energies. We create the chart data from it
        const chart = this.formatDataEnergyToChartBarSerie(dataPerEnergy, 0, djuSumYearOverYear);

        response.data.chart = [chart.kWhep, chart.ht, chart.ttc];
        response.labels = labels;
        return response;
    }

    private isPrimaryEnergy(energiesSelected: string): boolean {
        return energiesSelected.split(',').length > 1 ? true : false;
    }

    /**
     * Get all index of each month
     * @param {string} dateStart - date of beginning of the chart
     * @param {string} dateEnd - date of end of chart
     * @param {boolean} sortMonth - sort month from oldest to most recent
     * @returns {{[month: string]: number}} Array of all month contain in chart
     *
     * For the bar chart, we need to know all concerned month indexs
     * months are sorted from first in year to last in year
     *
     * {
     *  9 : 0,
     *  10 : 1,
     *  11 : 2,
     *  12 : 3
     * }
     *
     */
    getGraphAbscisses(dateStart: string, dateEnd: string, sortMonth: boolean = true): { [month: string]: number } {
        const start = moment.utc(dateStart).startOf('month');
        const end = moment.utc(dateEnd).startOf('month');

        const months: number[] = [];

        // Get all months
        for (const startDate = start.clone(); startDate.isSameOrBefore(end); startDate.add(1, 'months')) {
            if (!months.includes(startDate.month())) {
                months.push(startDate.month());
            }
        }

        if (sortMonth) {
            // Sort month in croissant order
            months.sort((a, b) => {
                return a - b;
            });
        }

        // Return associated index
        return months.reduce((memo, currentItem, index) => {
            memo[currentItem] = index;
            return memo;
        }, {});
    }

    /**
     * Get all label for the abscisse
     * @param {string} dateStart - date of beginning of the chart
     * @param {string} dateEnd - date of end of chart
     * @returns {string[]} Array of all month contain in chart
     */
    getGraphAbscisseLabels(
        dateStart: string,
        dateEnd: string,
        sortMonth: boolean = true,
        format: string = 'MMMM'
    ): string[] {
        const monthsIndex = this.getGraphAbscisses(dateStart, dateEnd, sortMonth);
        const months = Object.keys(monthsIndex);

        // For each month return the name
        return months.map(month => {
            return moment()
                .month(month)
                .format(format);
        });
    }

    /**
     * Get 36 months bounds before dateEnd (3 years)
     * @param {QueryFilterRoutingRef} filters
     * @returns {Promise<{dateEnd: string, dateStart: string}>} Object with dateStart and dateEnd bounds of all years contained in chart
     */
    async getThreeYearsFiltersLimitDates(
        filters: QueryFilterRoutingRef
    ): Promise<{ dateEnd: string; dateStart: string }> {
        const dateStart = moment
            .utc(filters.dateEnd)
            .startOf('year')
            .subtract(2, 'years');
        const dateEnd = moment.utc(filters.dateEnd).startOf('month');

        return {
            dateEnd: dateEnd.format('YYYY-MM'),
            dateStart: dateStart.format('YYYY-MM'),
        };
    }

    /**
     * Get 12 months bounds before dateEnd
     * @param {QueryFilterRoutingRef} filters date of beginning of the chart
     * @returns {Promise<{dateEnd: string, dateStart: string}>} Object with dateStart and dateEnd bounds of all years contained in chart
     */
    async getTwentyFourMonthFiltersLimitDates(
        filters: QueryFilterRoutingRef
    ): Promise<{ dateEnd: string; dateStart: string }> {
        const dateStart = moment
            .utc(filters.dateEnd)
            .startOf('month')
            .subtract(23, 'months');
        const dateEnd = moment.utc(filters.dateEnd).startOf('month');

        return {
            dateEnd: dateEnd.format('YYYY-MM'),
            dateStart: dateStart.format('YYYY-MM'),
        };
    }

    /**
     * Get date filters limit dates based on available years. Dates at format YYYY-MM.
     * @returns {Promise<{dateStart: string, dateEnd: string}>}
     */
    async getAvailableYearsFiltersLimitDates(): Promise<{ dateStart: string; dateEnd: string }> {
        const availableYears = await this.filterService.getYearsWithData();
        availableYears.sort((a, b) => {
            return Number(a.value) - Number(b.value);
        });
        const earlyYear = availableYears && availableYears.length ? availableYears[0].value : moment.utc().year();
        const lastYear =
            availableYears && availableYears.length
                ? availableYears[availableYears.length - 1].value
                : moment.utc().year();
        const dateStart = moment
            .utc()
            .year(Number(earlyYear))
            .startOf('year');
        const dateEnd = moment
            .utc()
            .month(11)
            .year(Number(lastYear))
            .startOf('month');

        return {
            dateEnd: dateEnd.format('YYYY-MM'),
            dateStart: dateStart.format('YYYY-MM'),
        };
    }

    /**
     * Get all years within dateStart and dateEnd
     * @param {string} dateStart date of beginning of the chart
     * @param {string} dateEnd date of end of chart
     * @returns {number[]} Array of all years contain in chart
     */
    getGraphYears(dateStart: string, dateEnd: string): number[] {
        const start = moment.utc(dateStart).startOf('year');
        const end = moment.utc(dateEnd).startOf('year');
        const years = [];

        // Get all years
        for (const startDate = start.clone(); startDate.isSameOrBefore(end); startDate.add(1, 'years')) {
            if (!years.includes(startDate.year())) {
                years.push(startDate.year());
            }
        }

        return years;
    }

    /**
     * Get graph years index
     * @param {string} dateStart
     * @param {string} dateEnd
     * @returns {{[year: number]: number}}
     */
    getGraphYearsIndex(dateStart: string, dateEnd: string): { [year: number]: number } {
        const years = this.getGraphYears(dateStart, dateEnd);
        // Sort month in croissant order
        years.sort((a, b) => {
            return a - b;
        });

        return years.reduce((memo, currentItem, index) => {
            memo[currentItem] = index;
            return memo;
        }, {});
    }

    /**
     * Process formated data by energy to have chart datas
     * @param dataPerEnergy { elec : { ttc, ht, kwep }, water: { ttc, ht, kwep}}
     * @param stackIndex is the current year index, in the chart it is a column
     * @param {{hot: number, cold: number}[]} djus - list of djus sorted by year
     * @returns {kWhep: CompareChartBarData[], ht: CompareChartBarData[], ttc: CompareChartBarData[]} object with all dataset for the chart included in it
     */
    formatDataEnergyToChartBarSerie(
        dataPerEnergy: { [type: string]: any },
        stackIndex: number,
        djus: Array<{ hot: number; cold: number }>
    ): {
        kWhep: CompareBarChartSerie[];
        ht: CompareBarChartSerie[];
        ttc: CompareBarChartSerie[];
    } {
        const chart = {
            kWhep: [],
            ht: [],
            ttc: [],
        };

        // We have all the values HT and TTC by energies. We create the chart data from it
        for (const priceTypeOrConso in dataPerEnergy) {
            if (dataPerEnergy.hasOwnProperty(priceTypeOrConso)) {
                let idStackFilter = 0; // needed for the legend of the stack bar

                for (const energyName in dataPerEnergy[priceTypeOrConso]) {
                    if (dataPerEnergy[priceTypeOrConso].hasOwnProperty(energyName)) {
                        // Check if needed to had
                        chart[priceTypeOrConso].push({
                            data: dataPerEnergy[priceTypeOrConso][energyName],
                            label: energyName,
                            stack: stackIndex, // = site n° / categorie n° / place n°
                            idStackFilter,
                            yAxisID: 'y-axis-0',
                            type: 'bar',
                        });

                        // Check if need to had unit
                        idStackFilter++;
                    } else {
                        // TODO: Check Needed (Else to keep old behavior)
                        idStackFilter++;
                    }
                }

                if (djus) {
                    let hotDjus = [];

                    // Here add dju years
                    if (djus.length) {
                        hotDjus = djus
                            .filter(dju => dju !== undefined)
                            .map(year => {
                                return year.hot;
                            });
                    }
                    chart[priceTypeOrConso].unshift({
                        data: hotDjus,
                        label: 'djus',
                        stack: stackIndex, // = site n° / categorie n° / place n°
                        idStackFilter,
                        yAxisID: 'y-axis-1',
                        type: 'line',
                    });
                }
            }
        }

        return chart;
    }
}
