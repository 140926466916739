import { Inject, Injectable } from '@angular/core';
import { EMPTY, merge, ReplaySubject } from 'rxjs';
import { catchError, mergeMap, shareReplay, tap } from 'rxjs/operators';

import { ANALYTICS_CONFIGURATION, AnalyticsConfiguration } from '../analytics-configuration';

import { GoogleAnalyticsTag } from './google-analytics-tag';
import { loadGoogleAnalytics } from './load-google-analytics';

/**
 * Google Analytics service
 */
@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    private readonly tags = new ReplaySubject<GoogleAnalyticsTag>();
    private readonly variables = new ReplaySubject<Readonly<Record<string, unknown>>>();
    private readonly ga$ =
        this.configuration.gaId !== undefined
            ? loadGoogleAnalytics(document, this.configuration.gaId).pipe(
                  catchError(() => EMPTY),
                  shareReplay()
              )
            : EMPTY;

    /**
     * Class constructor
     *
     * @param configService Configuration service
     */
    constructor(@Inject(ANALYTICS_CONFIGURATION) private readonly configuration: AnalyticsConfiguration) {
        const { gaId } = configuration;
        this.ga$
            .pipe(
                mergeMap(ga =>
                    merge(
                        this.variables.asObservable().pipe(tap(variables => ga('set', variables))),
                        this.tags.asObservable().pipe(tap(tag => ga.apply(null, tag)))
                    )
                )
            )
            .subscribe();
    }

    /**
     * Sets variables
     *
     * @param variables Variables
     */
    setVariables(variables: Readonly<Record<string, unknown>>): void {
        this.variables.next(variables);
    }

    /**
     * Submits a tag
     *
     * @param tag Tag
     */
    tag(tag: GoogleAnalyticsTag): void {
        this.tags.next(tag);
    }
}
