import { Injectable } from '@angular/core';
import {
    PaginationItem,
    PaginationNavigation,
    PaginationUser,
    QueryPagination,
    QueryPaginationUser,
} from 'app/shared/models/pagination.interface';
import { QuerySort } from 'app/shared/models/pagination.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PaginationBackService {
    /**
     * Pagination element holding the last version, used by component pagination-back and the pages using it.
     */
    private _pagination: PaginationNavigation;
    private _defaultSortAsc = true;
    private _sort = {
        asc: false,
        field: 'dates.billDate',
    };

    /**
     * Getters and setters used by component pagination-back
     */
    get pagination() {
        return this._pagination;
    }
    set pagination(value: PaginationNavigation) {
        this._pagination = value;
    }

    get defaultSortAsc() {
        return this._defaultSortAsc;
    }
    set defaultSortAsc(value: boolean) {
        this._defaultSortAsc = value;
    }

    get sort() {
        return this._sort;
    }
    set sort(value: QuerySort) {
        this._sort = value;
    }

    /**
     * Behavior subject: emits an event when the number of input items is changed.
     */
    private paginationChanged: BehaviorSubject<PaginationNavigation> = new BehaviorSubject<PaginationNavigation>(
        this.pagination
    );
    public get pagination$(): Observable<PaginationNavigation> {
        return this.paginationChanged.asObservable();
    }

    /**
     * Update pagination when input items have changed.
     *  - Update the new number of items
     *  - Compute the number of pages.
     *  - Update the current page.
     * Emits an event in order to reload the pagination component.
     * @param {number} newItemsNb updated number of input elements (in the overall pages)
     */
    updateAfterSearch(newItemsNb: number): void {
        this.pagination.nbItems = newItemsNb;
        this.pagination.nbPages = this.pagination.nbItemsPerPage
            ? Math.ceil(this.pagination.nbItems / this.pagination.nbItemsPerPage)
            : 1;

        this.pagination.currentPageIndex = this.pagination.selectedPageIndex; // current page must be set before reloading

        this.paginationChanged.next(this.pagination); // emit an event to reload the pagination component
    }

    /**
     * Update the current last item.
     * @param {PaginationItem} lastItem to update
     * @param {any[]} elements elements displayed respecting the pagination
     */
    updateLastItem(lastItem: PaginationItem, elements: any[]) {
        lastItem._id = elements.length ? elements[elements.length - 1]._id : null;
        lastItem.index = elements.length ? elements.length - 1 : 0;
    }

    /**
     * Reset pagination for an empty array of items and set current page on first one.
     */
    emptyPagination(): void {
        this.updateAfterSearch(0);
    }

    /**
     * Create and return the pagination query from its values.
     * This specific format is expected by API routes using back pagination.
     * @param {PaginationItem} lastItem
     * @return {QueryPagination} query
     */
    getPaginationQuery(lastItem: PaginationItem): QueryPagination {
        return {
            limit: this.pagination.nbItemsPerPage,
            lastItemId: lastItem._id,
            lastItemIndex: lastItem.index,
            currentPageIndex: this.pagination.currentPageIndex,
            selectedPageIndex: this.pagination.selectedPageIndex,
        };
    }

    /**
     * Set pagination to first page. Reset last item.
     * @param {PaginationItem} lastItem item
     */
    setFirstPage(): void {
        this.pagination.currentPageIndex = 0;
        this.pagination.selectedPageIndex = 0;
    }

    /**
     * SORT
     */

    /**
     * Handle sort: selected column and order (asc/desc).
     * Clicking on the current column selected reverses the sort (asc/desc).
     * Clicking on another column sorts the table with the default direction
     * @param {string} header column header selected for sort
     * @param {QuerySort} sort sort element to update from user action
     */
    onSort(header: string): void {
        if (this.sort.field !== header) {
            this.sort.asc = this.defaultSortAsc; // if change of column, reset default sort direction
            this.sort.field = header;
        } else {
            this.sort.asc = !this.sort.asc;
        }
    }

    /**
     * Update the sort caret class depending on the user action.
     * @param {string} header
     * @returns {string[]} css classes
     */
    getClassesSort(header: string): string[] {
        const classes = ['fa', 'hover-color'];

        let icon = 'fa-sort';
        if (this.sort.field === header) {
            icon = this.sort.asc === this.defaultSortAsc ? 'fa-caret-down' : 'fa-caret-up';
        }

        classes.push(icon);
        return classes;
    }

    /**
     * <!> -------- SPECIFIC FOR USER -------- <!>
     * To refacto later with generic
     */

    /**
     * Create and return the pagination query from its values.
     * This specific format is expected by API routes using back pagination.
     * @param {PaginationUser} lastItem
     * @return {QueryPaginationUser} query
     */
    getPaginationQueryUser(lastItem: PaginationUser): QueryPaginationUser {
        return {
            lastUserName: lastItem.name,
            lastUserIndex: lastItem.index,
            currentPageIndex: this.pagination.currentPageIndex,
            selectedPageIndex: this.pagination.selectedPageIndex,
            nbItemsPerPage: this.pagination.nbItemsPerPage,
        };
    }

    /**
     * Update the current last user item
     * @param {any[]} elements elements having an _id
     */
    updateLastUser(lastItem: PaginationUser, elements: any[]) {
        if (elements.length === 0) {
            lastItem.name = null;
            lastItem.index = 0;
        } else {
            lastItem.name = elements[elements.length - 1].name;
            lastItem.index = elements.length - 1;
        }
    }

    /**
     * Set pagination to first page. Reset last item.
     * @param {PaginationUser} lastItem
     */
    setFirstPageUser(lastItem: PaginationUser): void {
        this.pagination.currentPageIndex = 0;
        this.pagination.selectedPageIndex = 0;
        lastItem = { name: null, index: 0 };
    }
}
