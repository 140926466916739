import { Injectable } from '@angular/core';

import * as legacySchema from 'app/shared/jsonschemas/legacy-config.json';
import * as jsonschema from 'jsonschema';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

@Injectable()
export class LegacyService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    getLegacyData(siteId, zoneId?, keys?: string[]) {
        return this.get(
            '/api/sites/legacy-data?site=' +
                siteId +
                (zoneId ? '&zone=' + zoneId : '') +
                (keys ? '&keys=' + keys.join(',') : '')
        );
    }

    async getConfigTemplate(nature) {
        const results = await Promise.all([
            this.get('/api/sites/legacy-data/configuration?nature=' + nature),
            this.getJSONSchema(),
        ]);

        const template = results[0];
        const schema = results[1];

        if (template.data && Object.keys(template.data).length === 0) {
            throw { errorCode: 'no_template' };
        }
        const val = jsonschema.validate(template.data, schema);
        if (val.valid) {
            return val.instance;
        } else {
            throw { errorCode: 'template_invalid' };
        }
    }

    resetLegacyData(siteId, zoneId?, fullDelete = false) {
        let route = '/api/sites/legacy-data?site=' + siteId;
        if (zoneId) {
            route += '&zone=' + zoneId;
        }
        if (fullDelete) {
            route += '&full=1';
        }
        return this.delete(route);
    }

    /**
     * Get the JSON schema to validate the structure of a config file.
     * @returns {Observable<any>}
     */
    public async getJSONSchema(): Promise<any> {
        return legacySchema;
    }
}
