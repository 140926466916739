import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CollectService } from 'app/pages/collect/collect.service';
import { UploadErrorList } from '../constants/upload-error-list.constants';
import { UploadChunkRecap } from '../models/upload-file.interface';

@Pipe({
    name: 'getStackMsg',
})
export class GetStackMsgPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    private sanitizeInput(input: string): SafeHtml {
        // Regular expression to remove <script> tags
        const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
        const sanitizedContent = input.replace(scriptRegex, '');
        return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    }

    transform(file: UploadChunkRecap, collectService: CollectService): SafeHtml | string {
        if (file.error) {
            if (!file.stack || file.stack === UploadErrorList.SAVING_UNKNOWN) {
                return 'Erreur inconnue';
            }
            return this.sanitizeInput(file.error);
        }
        if (file.state !== 'extracting') {
            return collectService.stateInfo[file.stack]
                ? this.sanitizeInput(collectService.stateInfo[file.stack].message)
                : '';
        }
    }
}
