// tslint:disable:no-access-missing-member
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { QuestionComponent } from '../question/question.component';

@Component({
    selector: 'ga-question-input-text',
    templateUrl: './input-text.component.html',
    styleUrls: ['./input-text.component.scss'],
})
/**
 * Component to create an input question (as text, number, email, ...) or text-area.
 * @Input data : User data at the format [{key: string, value: string}]
 * @Input config : Question from the config file with properties attribute at the format {key: string, type:string, min: number (opt.), max: number (opt.)}
 * Available types : text, number, text-area, color, date, datetime-local, email, month, tel, time, url, week.
 * @Ouput valueChange : event that gives the data each time it's changed by the user at the format {key: string, value: string}
 */
export class InputTextComponent extends QuestionComponent implements OnInit {
    // Will be used when dealing with real data for initialization
    textValue: string = null;

    allowedTypes = [
        'text',
        'number',
        'text-area',
        'color',
        'date',
        'datetime-local',
        'email',
        'month',
        'tel',
        'time',
        'url',
        'week',
    ];

    questionForm: FormGroup;

    constructor() {
        super();
    }

    ngOnInit() {
        const initVal = this.data.find(x => x.key === this.config.properties.key);

        this.textValue = initVal ? initVal.value : null;

        if (this.getType() === 'month' && this.textValue) {
            this.textValue = moment(this.textValue).format('YYYY-MM');
        } else if (this.getType() === 'month' && this.textValue) {
            this.textValue = moment(this.textValue).format('YYYY-MM-DD');
        }

        this.questionForm = new FormGroup({
            question: new FormControl(this.textValue),
        });

        this.onChanges();
        this.updateValue();
    }

    onChanges() {
        this.questionForm.valueChanges.subscribe(val => {
            if (val && val.question) {
                this.textValue = val.question;
                this.updateValue();
            }
        });
    }

    private updateValue() {
        this.valueChange.emit({
            key: this.config.properties.key,
            value: this.textValue,
        });
    }

    getType() {
        if (this.allowedTypes.find(x => x === this.config.properties.type)) {
            return this.config.properties.type;
        }
        return 'text';
    }

    getMin() {
        return this.config.properties.min || this.config.properties.min === 0 ? this.config.properties.min : null;
    }

    getMax() {
        return this.config.properties.max !== undefined ? this.config.properties.max : null;
    }

    isTextArea() {
        return this.config.properties.type === 'text-area';
    }
}
