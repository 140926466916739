import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

import { Legend } from 'app/shared/components/legends/legend.interface';
import { PaginationBackService } from 'app/shared/components/pagination/back/pagination-back.service';
import { FiltersAvailable, QueryFilter } from 'app/shared/models/bills-contracts-filter.interface';
import { Bill, BillsControlsRecap } from 'app/shared/models/bills.interface';
import { Pagination, QueryPagination } from 'app/shared/models/pagination.interface';
import { BillsListService } from 'app/shared/services/bills-list/bills-list.service';
import { BillsControlService } from 'app/shared/services/bills/bills-control.service';
import { ColorService } from 'app/shared/services/color/color.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';

@Component({
    selector: 'ga-bills-control-tab',
    templateUrl: './bills-control-tab.component.html',
    styleUrls: ['./bills-control-tab.component.scss'],
    providers: [PaginationBackService],
})
export class BillsControlTabComponent implements OnInit {
    bills: Bill[] = [];

    pagination: Pagination = {
        lastItem: {
            _id: null,
            index: 0,
        },
    };

    filtersAvailable: FiltersAvailable;
    isLoadingFilters = true;

    filters: QueryFilter;

    get filtersQueryString(): string {
        if (!this.filters) {
            return '';
        }

        let url = `?start=${this.filters.start}&end=${this.filters.end}`;
        url += this.filters.query ? `&query=${this.filters.query}` : '';
        url += this.filters.onlyError ? `&onlyError=${this.filters.onlyError}` : '';
        url += this.filters.includeBranches ? `&includeBranches=${this.filters.includeBranches}` : '';
        url += this.filters.fluids ? `&fluids=${this.filters.fluids}` : '';
        url += this.filters.status ? `&status=${this.filters.status}` : '';
        url += this.filters.providers ? `&providers=${this.filters.providers}` : '';
        url += this.filters.states ? `&states=${this.filters.states}` : '';
        url += this.filters.onlyValidContracts ? `&onlyValidContracts=${this.filters.onlyValidContracts}` : '';
        url += this.filters.routingReferences ? `&routingReferences=${this.filters.routingReferences}` : '';
        url += this.filters.contracts ? `&contracts=${this.filters.contracts}` : '';
        url += this.filters.failedVerifications ? `&failedVerifications=${this.filters.failedVerifications}` : '';

        return url;
    }

    /**
     * @type {boolean} - isBranchesLoaded is used to set filters available values from requested company and its branches
     */
    private isBranchesLoaded = false;

    legends: Legend[] = [
        {
            name: 'overcharged',
            icon: 'fa-arrow-up',
            color: this.colorService.getRgbColorValue('overcharged'),
            values: [3],
        },
        {
            name: 'undercharged',
            icon: 'fa-arrow-down',
            color: this.colorService.getRgbColorValue('undercharged'),
            values: [2],
        },
        {
            name: 'valid',
            icon: 'fa-check',
            color: this.colorService.getRgbColorValue('valid'),
            values: [1],
        },
        {
            name: 'not_controlled',
            color: this.colorService.getRgbColorValue('not_controlled'),
            values: [0],
        },
    ];

    public recap: BillsControlsRecap;

    /**
     * Set up loading
     *     bills : refers to the bills control list
     *     recap: refers to the recap banner
     */
    public loading = {
        bills: true,
        recap: true,
    };

    constructor(
        private sessionService: SessionService,
        private energyService: EnergyService,
        private filterService: FilterService,
        private billsListService: BillsListService,
        private paginationService: PaginationBackService,
        private colorService: ColorService,
        private billsControlService: BillsControlService,
        private translateService: TranslateService
    ) {}

    async ngOnInit() {
        await this.initData();
    }

    async initData() {
        try {
            await this.initFilters();
        } catch (err) {
            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des factures. Veuillez réessayer ultérieurement.',
                'warning'
            );
            throw JSON.stringify(err);
        }
    }

    /**
     * Set displayed list of bills from the given list and update pagination.
     * Default : none is expanded.
     */
    setBills(bills: { results: Bill[]; total: number }): void {
        // init collapse (default false) on each bill
        bills.results.forEach((bill: Bill) => {
            bill.isExpanded = false;
        });

        // set bills
        this.bills = bills.results;

        this.loading.bills = false;

        // Update pagination with the new total number of elements
        this.paginationService.updateLastItem(this.pagination.lastItem, this.bills);
        this.paginationService.updateAfterSearch(bills.total);
    }

    /**
     * Get bills uploads for filters and pagination
     * @param {QueryFilter} filters
     * @return {Promise<{results: Bill[], total: number}>}
     */
    async getBills(filters: QueryFilter): Promise<{ results: Bill[]; total: number }> {
        try {
            const queryPagination: QueryPagination = this.paginationService.getPaginationQuery(
                this.pagination.lastItem
            );
            return this.billsListService.getUploadsBills(filters, queryPagination, this.paginationService.sort);
        } catch (e) {
            this.bills = [];

            // reset pagination to first page for an empty array
            this.paginationService.emptyPagination();

            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des factures. Veuillez réessayer ultérieurement.',
                'warning'
            );
        }
    }

    /**
     * Called when pagination is updated (selected page or nb of items per pages).
     * Update the displayed list of bills.
     */
    async onPaginationChanged(event = { resetLastItem: false }): Promise<void> {
        if (event.resetLastItem) {
            this.paginationService.updateLastItem(this.pagination.lastItem, []);
        }
        // Display the loading message until bills list refreshed
        this.loading.bills = true;

        const bills = await this.getBills(this.filters);

        this.setBills(bills);

        // After bills list refreshed, hide the loading
        this.loading.bills = false;
    }

    /**
     * Called when sort has changed on the bills table
     * Reload the bills accordingly
     * @returns {Promise<void>}
     */
    async onSortChanged(): Promise<void> {
        // Display the loading message until bills list refreshed
        this.loading.bills = true;

        this.paginationService.setFirstPage();
        this.pagination.lastItem = { _id: null, index: 0 };
        const bills = await this.getBills(this.filters);

        const billsList = this.setBills(bills);

        // After bills list refreshed, hide the loading
        this.loading.bills = false;

        return billsList;
    }

    /**
     *  ************ FILTERS ************
     */

    /**
     * Initilise filters with default values.
     * company: session company
     * period: from min to max years where data is found
     * fluids: all of the company
     * providers: all of the company
     */
    async initFilters(): Promise<void> {
        await this.setFilters();
        this.isLoadingFilters = false;
    }

    /**
     * Called when filter has changed. Set the list and recap values for the new filters.
     * @param {QueryFilter} filters
     */
    async onFilterChanged(filters: QueryFilter): Promise<void> {
        // Display the loading message or the loading spinners (recap banner)
        // until bills list refreshed
        this.loading.bills = true;
        this.loading.recap = true;

        this.filters = filters;

        const loadBranches = _.get(this.filters, 'includeBranches', false);
        if ((loadBranches && !this.isBranchesLoaded) || (!loadBranches && this.isBranchesLoaded)) {
            this.isBranchesLoaded = !this.isBranchesLoaded;
            await this.setFilters({ loadBranches });
        }

        // reset pagination to first page
        this.paginationService.setFirstPage();
        // Reset last item
        this.pagination.lastItem = { _id: null, index: 0 };

        // set the bills list and the recap values for the required filter: the last one
        const res = await this.getBillsAndRecap(filters);
        if (res.filters === this.filters) {
            this.recap = res.recap;
            this.setBills(res.bills);

            // After bills list refreshed, hide the loading
            this.loading.bills = false;
            this.loading.recap = false;
        }
    }

    /**
     * Get the bills and recap values for the given filters.
     * @param { QueryFilter} filters
     * @returns { Promise<{ bills: { results: Bill[]; total: number }; recap: BillsControlsRecap; filters: QueryFilter }> }
     */
    async getBillsAndRecap(
        filters: QueryFilter
    ): Promise<{ bills: { results: Bill[]; total: number }; recap: BillsControlsRecap; filters: QueryFilter }> {
        filters.onlyValidContracts = true;
        const [bills, recap] = await Promise.all([
            this.getBills(filters),
            this.billsListService.getControlRecap(filters),
        ]);

        return { bills, recap, filters };
    }

    /**
     * set filters
     * @param {{loadBranches: boolean}} param
     */
    async setFilters(param: { loadBranches: boolean } = { loadBranches: false }) {
        const currentCompany = this.sessionService.getCompany();

        const [fluids, years, providers] = await Promise.all([
            this.energyService.getFluidsAvailable(currentCompany._id, param),
            this.filterService.getYearsWithData(true, param),
            this.filterService.getContractProviders(currentCompany._id, param),
        ]);

        // set selected fluids compared to available fluids
        if (this.filters && this.filters.fluids) {
            this.filters.fluids = _.intersection(this.filters.fluids, fluids);
        }

        // set selected providers compared to available providers
        if (this.filters && this.filters.providers) {
            this.filters.providers = _.intersection(this.filters.providers, providers.data);
        }

        let yearsMin: string = null;
        let yearsMax: string = null;

        const currentDate = moment();
        const currentYear = currentDate.year().toString();

        if (years && years.length) {
            yearsMin = years[0].value;
            yearsMax = years[years.length - 1].value;
        } else {
            yearsMin = currentYear;
            yearsMax = currentYear;
        }

        const setDefaultDate = Boolean(!_.get(this.filters, 'start') && !_.get(this.filters, 'end'));

        let selectedStart = null;
        let selectedEnd = null;

        if (setDefaultDate) {
            selectedStart = currentDate
                .clone()
                .subtract(1, 'year')
                .format('YYYY-MM');
            selectedEnd = currentDate.format('YYYY-MM');
        }

        this.filtersAvailable = {
            searchValue: {
                enabled: true,
                placeholder: 'N° de facture / n° de PDL / immatriculation',
            },
            years: {
                enabled: true,
                yearsMin,
                yearsMax,
                setDefault: setDefaultDate,
                selectedStart,
                selectedEnd,
                allowMissingBound: false,
            },
            fluids: {
                enabled: true,
                values: fluids,
            },
            providers: {
                enabled: true,
                values: providers.data,
            },
            status: {
                enabled: true,
                values: ['undercharged', 'overcharged', 'valid'],
            },
            failedVerifications: {
                label: 'bill_error_type_filter',
                placeholder: this.translateService._('bill_error_type_filter'),
                enabled: true,
                values: this.billsControlService.getControlsByFluid(fluids),
                tooltip:
                    "Ce filtre permet d'afficher uniquement les factures présentant une erreur sur l’une des familles d’erreurs sélectionnées",
            },
            branches: {
                enabled: true,
            },
        };
    }
}
