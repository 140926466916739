import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from 'app/shared/models/company.interface';
import { RuleBlockInterface, RuleSitesAttributionInterface } from 'app/shared/models/rule-sites-attribution.interface';
import { Site } from 'app/shared/models/site.interface';
import { UserPopulated } from 'app/shared/models/users.interface';
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import * as clone from 'clone';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-filter-sites-rules',
    templateUrl: './filter-sites-rules.component.html',
    styleUrls: ['./filter-sites-rules.component.scss'],
    providers: [],
})
export class FilterSitesRulesComponent implements OnInit {
    @Input() user: UserPopulated<Company> = null;

    /**
     * All companies
     */
    get allCompanies(): Company[] {
        return this.user ? this.user.companies : this.companiesAvailable;
    }

    @Input() sitesRule: RuleSitesAttributionInterface;

    /**
     * Companies not used in any rule
     */
    @Input() companiesAvailable: Company[];

    /**
     * Can delete a rule block.
     * To be set to true if multiple ruleBlocks.
     * Now used, to know if there is multiple ruleBlocks :
     * If only one company is selected, it can't be delete in case of multiple ruleBlocks
     * (a company can be in only one rule)
     */
    @Input() canDelete = false;

    /**
     * Is the component meant to display filters for custom filters (instead of user sites rules).
     * If true, there is no fluid selection, no company selection and for categories there is no organisation selection
     */
    @Input() isCustomFilter = false;

    /**
     * Event triggered when a rule changes.
     */
    @Output() ruleChanged: EventEmitter<RuleSitesAttributionInterface> = new EventEmitter<
        RuleSitesAttributionInterface
    >();

    /**
     * A ruleBlock is a block of rules, focused on one or multiple companies (rule: RuleSitesAttributionInterface)
     *  +
     *  the list filling the selectboxes
     *
     * ex:  block 1 -- "For the companies A & B"
     *         rule1:  I allow the user to see the sites of Category 1 & 2"
     *      block 2 -- "For the companies C"
     *         rule 1: I allow the user to see the sites of (Region A & Nature "Batiment tertiaire")
     *         or
     *         rule 2: I allow the user to see the sites of (Region B & Complement 101)"
     *
     * @type {RuleBlockInterface} array of ruleBloc
     */
    ruleBlock: RuleBlockInterface = null;

    private regionsJson = {};
    private allRegionsFormated = [];
    private allDepartmentsFormated = [];
    private allCitiesFormated = [];
    private allNaturesFormated = [];
    private allOrganizationsFormated = [];
    /**
     * Array of routing references we can display in the autocomplete selectBoxe
     */
    private allRoutingReferencesFormatted = [];

    // objects gathering the categories per organization type
    // key: organization number, value: [{id: .., name:.., subcategories: [...]}]
    highlightedCategoriesPerOrganization = {}; // only the categories of the companies selected
    allCategoriesPerOrganization = {}; // all existing categories

    allFluids = [];

    infoMessage: string = null;
    displayInfoMessage = false;
    hasInfoMessage = true;
    isReadyForAddBtn = false;

    localisationTypes: Array<{ value: string; name: string }> = [
        {
            value: 'regions',
            name: 'Régions',
        },
        {
            value: 'departments',
            name: 'Départements',
        },
        {
            value: 'cities',
            name: 'Villes',
        },
    ];
    localisationsSelected: any[] = [];

    constructor(
        private companiesService: CompaniesService,
        private filterService: FilterService,
        private energyService: EnergyService,
        private siteService: SitesService
    ) {}

    ngOnInit() {
        this.initData();
    }

    /** ------ Initialization --------- **/

    /**
     * Initialize a first block of rules targeting all the user's companies by default
     * Hence, the table aggregates all the sites of all the user's companies
     */
    async initData() {
        /**
         * Companies the connected user can access to
         */
        const allCompaniesIds = this.allCompanies.map(c => c._id);
        // values needed to init the first rule block :
        // regions:     all the regions + departments of France
        // natures & organizations: all the available natures & organizations
        // cities:      coming from the sites of the user's companies
        // fluids:      coming from the sites of the user's companies
        // routingReferences : coming from the sites of the user's companies

        try {
            const [regions, properties, cities, fluids, sites] = await Promise.all([
                this.getAllRegions(),
                this.getAllProperties(),
                this.getCitiesFromCompanies(allCompaniesIds),
                this.energyService.getFluidsAvailableForCompanies(allCompaniesIds),
                this.getSitesFromCompanies(allCompaniesIds),
            ]);
            // format response for selectboxes
            this.allRegionsFormated = this.filterService.flattenRegionsJson(regions);
            this.allDepartmentsFormated = this.filterService.flattenDepartmentsJson(regions);
            this.allNaturesFormated = properties.natures;
            this.allCitiesFormated = this.formatArrayForSelectbox(cities);
            this.allFluids = this.formatFluidSelectbox(fluids);
            this.allRoutingReferencesFormatted = this.getRoutingReferencesFromSites(sites);

            // highlight all the cities & fluids in the selectboxes
            this.initHighlightForSelectbox(this.allCitiesFormated);
            this.initHighlightForSelectbox(this.allFluids);
            if (!this.sitesRule) {
                await this.addNewRuleBlock(true);
            } else {
                // init the ruleBlocks with the userSites values
                await this.initRuleblockFromUserSiteRule();
            }

            this.cleanAndEmitSitesRule();
        } catch (error) {
            this.getSwalMsg(error.errorCode);
        }
    }

    /**
     * Add a ruleBlock with an empty rule + all the companies without any rule applied  to it
     * isInit: boolean if true, no need to set the selectboxes, already done
     */
    async addNewRuleBlock(isInit: boolean) {
        this.ruleBlock = this.createRuleBlock();

        try {
            await this.initRuleBlock(isInit);
        } catch (err) {
            throw err;
        }

        return;
    }

    /**
     * Init the ruleBlocks with the userSites values
     */
    async initRuleblockFromUserSiteRule() {
        this.ruleBlock = this.createRuleBlock();
        this.ruleBlock.rule.companies = this.sitesRule.companies;
        this.ruleBlock.companiesSelected = this.allCompanies
            .filter(x => this.sitesRule.companies.includes(x._id))
            .map(x => x._id);
        try {
            await this.initRuleBlock(true);
            this.ruleBlock.rule.rules = this.sitesRule.rules.map(rule => this.createGlobalRuleFromUserRuleSite(rule));
            this.ruleBlock.rule.rules.forEach((rule, i) => {
                const type = this.getRuleLocalisationType(rule);
                this.localisationsSelected[i] = type;
            });
            // this.onOrganizationSelected()
        } catch (err) {
            throw err;
        }

        return;
    }

    /**
     *
     * @param siteRule
     * @return {{regions: any, departments: any, sites: any, cities: any, natures: any, subnatures: any, energies: any, categories: (any | {organization: any; items: Array}[])}}
     */
    createGlobalRuleFromUserRuleSite(siteRule) {
        const rule = {
            regions: this.setRuleFromRuleSite(siteRule.regions, 'regions', 'value'),
            departments: this.setRuleFromRuleSite(siteRule.departments, 'departments', 'value'),
            sites: this.setRuleFromRuleSite(siteRule.sites, 'sites', '_id'),
            cities: this.setRuleFromRuleSite(siteRule.cities, 'cities', 'value'),
            natures: this.setNaturesFromRuleSite(siteRule.natures, siteRule.subnatures),
            energies: this.setRuleFromRuleSite(siteRule.energies, 'fluids', 'value'),
            categories: this.setCategoriesFromRuleSite(siteRule.categories),
            subnatures: [], // Subnatures empty because same input as natures. Computed for results in cleanAndEmitSitesRule
            routingReferences: this.setRuleFromRuleSite(siteRule.routingReferences, 'routingReferences', '_id'),
        };
        return rule;
    }

    setCategoriesFromRuleSite(categories) {
        if (categories.length) {
            return categories.map((c, index) => {
                this.ruleBlock.selectboxes.categories[index].items = this.createCategoriesItemsSelectbox(
                    c.organization
                );
                this.ruleBlock.isReady.complementsSelectBoxes = true;

                return {
                    organization: this.ruleBlock.selectboxes.categories[index].organizations.find(
                        option => option.id === c.organization
                    ),
                    items: c.items.length
                        ? c.items.map(i =>
                              this.ruleBlock.selectboxes.categories[index].items.find(option => option.id === i)
                          )
                        : [],
                };
            });
        } else {
            if (this.isCustomFilter && this.allCompanies && this.allCompanies.length) {
                this.ruleBlock.isReady.complementsSelectBoxes = true;
                return [
                    {
                        organization: this.allCompanies[0].organization,
                        items: [],
                    },
                ];
            }
            return [
                {
                    organization: null,
                    items: [],
                },
            ];
        }
    }

    /**
     * Set natures and subnatures as values selected.
     * These values are from the rule in db and function is used during the initialization.
     * They are in the same array because they are in the same selectbox.
     * @param natures - list of natures objects selected
     * @param subnatures - list of subnatures objects selected
     */
    setNaturesFromRuleSite(natures, subnatures) {
        const values = [].concat(
            this.setRuleFromRuleSite(natures, 'natures', 'id'),
            this.setRuleFromRuleSite(subnatures, 'natures', 'id')
        );
        return values;
    }

    setRuleFromRuleSite(modelValues, name, fieldToCompare) {
        if (!modelValues) {
            return [];
        }
        return modelValues
            .map(modelValue => {
                return this.ruleBlock.selectboxes[name].find(option => option[fieldToCompare] === modelValue);
            })
            .filter(x => x);
    }

    /**
     * Returns an object ruleblock which targets by default all the companies left to assign
     */
    createRuleBlock(): RuleBlockInterface {
        return {
            isReady: {
                sitesSelectbox: false,
                fluids: false,
                regionsSelectbox: false,
                departmentsSelectbox: false,
                citiesSelectbox: false,
                organizationsSelectBoxes: false,
                complementsSelectBoxes: false,
                naturesSelectBox: false,
                routingReferencesSelectbox: false,
            },
            isVisible: true,
            rule: this.initQuery(),
            selectboxes: {
                sites: [],
                regions: [],
                departments: [],
                cities: [],
                categories: [
                    {
                        organizations: [],
                        items: [],
                    },
                ],
                natures: [],
                fluids: [],
                routingReferences: [],
            },
            companiesSelected: clone(this.getAvailableCompanies()),
        };
    }

    /**
     * Initialize a ruleBlock with one empty rule targeting all the companies the user belongs to.
     * The sites displayed is an aggregation of all the sites from all these companies.
     * Fluids available depend on the companies as well
     * @param {boolean} isInit: boolean if true, no need to set the selectboxes, already done
     */
    initRuleBlock(isInit: boolean): Promise<any> {
        // in parallel we set the values for the selectboxes,
        // each one only needs to know which companies are preselected on this rulebloc
        const promiseArray = [
            this.getSitesFromRule(this.ruleBlock.rule), // the rule contains the companies

            // set the natures regions and departments selectboxes & highlight the options contained in the sites
            this.setNaturesOrgaCategoriesForSelectbox(),
            this.setRegionsForSelectbox(),
            this.setDepartmentsForSelectbox(),
            this.setRoutingReferencesForSelectbox(),
        ];

        // when it's the first rulelock, all the cities & fluids are already highlighted because they all belong to the sites
        // when it's not the first, some companies are not selected, so we highlight the cities and fluids related to the sites of the companies left
        if (!isInit) {
            promiseArray.push(this.setCitiesForSelectbox());
            promiseArray.push(this.setFluidsForSelectbox());
        }

        return Promise.all(promiseArray)
            .then(results => {
                const sites = results[0];

                // sites: sites from the companies. Display all of them in the selectbox
                this.ruleBlock.selectboxes.sites = sites;

                // update loaders for site
                this.ruleBlock.isReady.sitesSelectbox = true;

                // when it's the first ruleblock, put all the cities & fluids inside the selectboxes
                if (isInit) {
                    this.ruleBlock.selectboxes.cities = this.allCitiesFormated;
                    this.ruleBlock.selectboxes.fluids = this.allFluids;
                    this.ruleBlock.isReady.citiesSelectbox = true;
                    this.ruleBlock.isReady.fluids = true;
                }
                return Promise.resolve();
            })
            .catch(err => {
                this.getSwalMsg(err.errorCode);
                return Promise.reject(err);
            });
    }

    /**
     * Return the list of companies that aren't in any ruleBlock:  they are available to apply a new ruleblock on
     */
    getAvailableCompanies(): Company[] {
        // aggregate in 'companiesWithRule' all the companies present in the ruleblocks
        const companiesWithRule = this.ruleBlock ? this.ruleBlock.rule.companies : [];

        // keep only the one that aren't in this array
        return clone(this.companiesAvailable).filter(c => !companiesWithRule.includes(c._id));
    }

    /**
     * Initialize a ruleBlock with one empty rule targeting all the user's companies that aren't used yet in another ruleblock.
     * @return {{companies; rules: [{regions: Array; departments: Array; categories: Array; sites: Array; cities: Array; natures: Array; energies: Array}]}}
     */
    initQuery() {
        return {
            companies: this.getAvailableCompanies().map(c => c._id),
            rules: [this.initRule()],
        };
    }

    /**
     * One ruleblock can contain multiple rules which apply to the same companies.
     */
    initRule() {
        let organization = null;
        if (this.isCustomFilter && this.allCompanies && this.allCompanies.length) {
            organization = this.allCompanies[0].organization;
        }
        return {
            regions: [],
            departments: [],
            categories: [
                {
                    organization,
                    items: [],
                },
            ],
            sites: [],
            cities: [],
            natures: [],
            subnatures: [],
            energies: [],
            routingReferences: [],
        };
    }

    /**
     * Called on click on the btn "Rule n° .."
     */
    addNewRule() {
        this.ruleBlock.rule.rules.push(this.initRule());
    }

    removeRule(index) {
        this.ruleBlock.rule.rules.splice(index, 1);
        this.localisationsSelected[index] = null;
    }

    /**
     * Highlight every element of an array. Add or uodate a field called 'isHighlighted'
     * @param array: selectbox options
     */
    initHighlightForSelectbox(array) {
        array.forEach(elem => {
            elem.isHighlighted = true;
        });
    }

    /**
     * Store the json file of all the regions in France + the zipcode inside each
     * @return {Promise<Array<any>>}
     */
    getAllRegions(): Promise<any[]> {
        return this.filterService.getRegions(false);
    }

    /**
     * Returns json file of all the existing sites' properties(categories/natures/organizations)
     * @return {Promise<Array<any>>}
     */
    getAllProperties(): Promise<any> {
        return this.filterService.getCategories(false);
    }

    /**
     * Returns the names of the cities belonging to the companies selected
     * @param {string[]} companies
     * @return {Promise<string[]>}
     */
    getCitiesFromCompanies(companies: string[]): Promise<string[]> {
        return this.filterService.getCitiesFromCompanies(companies);
    }

    /**
     * Returns the natures of the sites belonging to the companies selected
     * @return {Promise<Array<any>>}
     */
    getNatureOrgaCategoriesFromCompanies(companies): Promise<any[]> {
        return this.companiesService.getNatureOrgaCategoriesFromCompanies(companies);
    }

    /** ------ Filters functions for companies --------- **/

    /**
     * Called when a new company is selected
     * rule.companies aggregates all the companies selected for the query.
     * companiesSelected aggregates all the companies displayed.
     * They are different when all the companies are selected - none are displayed
     *
     * @param {Company} company
     */
    onCompanyAdded(company: Company) {
        this.isReadyForAddBtn = false;

        // specific case: it's the first company selected in the filter
        // before, all companies were selected for the query, none was actually selected as a filter
        if (this.ruleBlock.rule.companies.length === this.allCompanies.length) {
            this.ruleBlock.rule.companies = [];
            this.ruleBlock.companiesSelected = [];

            // there might be sites selected coming from other companies
            // keep only the sites that belong to the company selected
            this.ruleBlock.rule.rules.forEach(rule => {
                rule.sites = rule.sites.filter(site => site.company === company._id);
            });
        }
        this.ruleBlock.rule.companies.push(company._id);
        this.ruleBlock.companiesSelected.push(company._id);

        // update the selectboxes with new colors depending on the companies selected
        this.updateSelectboxesFromCompanies()
            .then(() => {
                this.isReadyForAddBtn = true;
                this.cleanAndEmitSitesRule();
            })
            .catch(err => {
                this.isReadyForAddBtn = true;
                this.cleanAndEmitSitesRule();
            });
    }

    /**
     *
     * Update all the selectboxes needed when the companies selected have changed (added/removed)
     * Sites selectbox: add or remove the one from the companies added/removed
     * Other selectboxes: the list remains the same but we update which options are highlighted depending on the companies selected
     * @param ruleBlock
     * @return {Promise<any>}
     */
    async updateSelectboxesFromCompanies(): Promise<any> {
        try {
            await this.setNaturesOrgaCategoriesForSelectbox();
            const promiseArray = [
                this.updateSitesSelectBox(),
                this.setRegionsForSelectbox(),
                this.setDepartmentsForSelectbox(),
                this.setCitiesForSelectbox(),
                this.setFluidsForSelectbox(),
            ];

            return Promise.all(promiseArray);
        } catch (err) {
            this.getSwalMsg(err.errorCode);
            return Promise.reject(err);
        }
    }

    /**
     * Update the list of sites for the selectbox with the companies list in rule
     */
    updateSitesSelectBox(): Promise<any> {
        this.ruleBlock.isReady.sitesSelectbox = false;
        return this.getSitesFromCompanies(this.ruleBlock.rule.companies).then(
            sites => {
                this.ruleBlock.selectboxes.sites = sites;
                this.ruleBlock.isReady.sitesSelectbox = true;
                return Promise.resolve();
            },
            err => {
                return Promise.reject(err);
            }
        );
    }

    /**
     * Aggregate the sites belonging to the companies sent in param 'companies'
     * @param companies Array of ids as strings
     * @return {Promise<any>}
     */
    getSitesFromCompanies(companies): Promise<any> {
        const query: RuleSitesAttributionInterface = {
            companies,
            rules: [
                {
                    regions: [],
                    departments: [],
                    categories: [],
                    sites: [],
                    cities: [],
                    natures: [],
                    subnatures: [],
                    energies: [],
                    routingReferences: [],
                },
            ],
        };
        return this.getSitesFromRule(query);
    }

    /**
     * Query the database with the filters in the rule in params
     * @param rule
     * @return {Promise<any>} the list of sites matching the query
     */
    getSitesFromRule(rule: RuleSitesAttributionInterface): Promise<any[]> {
        const ruleWithoutEmptyQuery = clone(rule);

        // if there are more than one rule, delete the empty ones - they won't be saved anyway
        // otherwise all sites will be displayed and the rules with filters will be ignored
        if (rule.rules.length > 1) {
            ruleWithoutEmptyQuery.rules = this.deleteEmptyRules(ruleWithoutEmptyQuery.rules);
        }

        return this.siteService
            .getSitesFromRule(this.createRuleFromObjectsList(ruleWithoutEmptyQuery))
            .then(response => {
                let sitesFromRule = response;

                sitesFromRule = sitesFromRule.sort(sortListAlphabetically);

                // for the selectbox filter: add google address to display + aggregate list of reference number
                this.setAddresses(sitesFromRule);

                return Promise.resolve(sitesFromRule);

                function sortListAlphabetically(siteA, siteB) {
                    const a = siteA.complement;
                    const b = siteB.complement;
                    return a > b ? 1 : a < b ? -1 : 0;
                }
            });
    }

    canDeleteCompany() {
        if (this.canDelete && this.ruleBlock.companiesSelected.length === 1) {
            return false;
        }
        return true;
    }

    /**
     * Called when a company tag is removed.
     *
     * @param {Company} company
     */
    onCompanyDeleted(company: Company) {
        this.isReadyForAddBtn = false;

        // update the list of companies in the rule
        const companyId = company._id;
        const indexRule = this.ruleBlock.rule.companies.findIndex(e => e === companyId);
        if (indexRule > -1) {
            this.ruleBlock.rule.companies.splice(indexRule, 1);
        }
        // update the list of companies selected for display
        const indexRuleBlock = this.ruleBlock.companiesSelected.findIndex(e => e === companyId);
        if (indexRuleBlock > -1) {
            this.ruleBlock.companiesSelected.splice(indexRuleBlock, 1);
        }

        // specific case: when zero company is selected && there is only 1 ruleblock
        // it means thant all the rule applies to all of them - update rule.companies
        if (this.ruleBlock && this.ruleBlock.companiesSelected.length === 0) {
            this.ruleBlock.rule.companies = this.getAvailableCompanies().map(c => c._id);
            this.ruleBlock.rule.companies.unshift(companyId);
        }

        // update the sites in the filter rules
        // remove from the filter the sites belonging to the removed company
        this.ruleBlock.rule.rules.forEach(rule => {
            rule.sites = rule.sites.filter(site => site.company !== company._id);
        });

        // update the selectboxes with new colors depending on the companies selected
        this.updateSelectboxesFromCompanies().then(() => {
            this.isReadyForAddBtn = true;
            this.cleanAndEmitSitesRule();
        });
    }

    /**
     * Set in each site a new property 'googleAddress' a clear aggregation of the address properties in one String
     * @param sites
     */
    setAddresses(sites) {
        sites.forEach(site => {
            site.googleAddress = this.siteService.getAddressToDisplay(site);
        });
    }

    /** ------ Filters functions --------- **/

    /**
     * Called when a filter is changed (add/remove). ex: a site, a region, ..
     */
    onFilterChanged() {
        // Get clean rules and emit
        this.cleanAndEmitSitesRule();
    }

    /**
     * Clean sites rule (values instead of selectbox items) and emit change
     * @param ruleblock
     */
    cleanAndEmitSitesRule() {
        const ruleWithoutEmptyQuery = clone(this.ruleBlock.rule);

        // if there are more than one rule, don't save the empty ones
        // otherwise the rules with filters will be ignored
        if (ruleWithoutEmptyQuery.rules.length > 1) {
            ruleWithoutEmptyQuery.rules = this.deleteEmptyRules(ruleWithoutEmptyQuery.rules);
        }

        const cleanSitesRule = this.createRuleFromObjectsList(ruleWithoutEmptyQuery);

        this.ruleChanged.emit(cleanSitesRule);
    }

    /**
     * Create a mongo Query from the rule filters.
     * In the rule, filters are aggregation of Objects.
     * The query need the ids from each object instead.
     * @param rule
     * @return {{companies: (any | Array<string>); rules: Array}}
     */
    createRuleFromObjectsList(rule): RuleSitesAttributionInterface {
        const ruleWithIds = {
            companies: rule.companies,
            rules: [],
        };

        rule.rules.forEach(ruleWithObjects => {
            const natures = ruleWithObjects.natures.reduce((memo, n) => {
                if (n.level === 0) {
                    memo.push(n.id);
                }
                return memo;
            }, []);
            const subnatures = ruleWithObjects.natures.reduce((memo, n) => {
                if (n.level === 1) {
                    memo.push(n.id);
                }
                return memo;
            }, []);
            ruleWithIds.rules.push({
                regions: ruleWithObjects.regions.map(r => r.value),
                departments: ruleWithObjects.departments.map(d => d.value),
                categories: this.createQueryForOrganizationsCategories(ruleWithObjects.categories),
                sites: ruleWithObjects.sites.map(s => s._id),
                cities: ruleWithObjects.cities.map(c => c.value),
                natures,
                subnatures,
                energies: ruleWithObjects.energies.map(e => e.value),
                routingReferences: ruleWithObjects.routingReferences.map(r => r._id),
            });
        });
        return ruleWithIds;
    }

    createQueryForOrganizationsCategories(categories) {
        return categories.reduce((query, currentBlock) => {
            if (currentBlock.items && currentBlock.items.length) {
                query.push({
                    organization: currentBlock.organization.id,
                    items: currentBlock.items.map(category => category.id),
                });
            }

            return query;
        }, []);
    }

    /** -------  Fill the selectboxes -------- **/

    /**
     * Set the formated array expected by the regions selectbox.
     * The regions list aggregates all the regions in France.
     * The name is highlighted when one of the companies has a site inside it.
     */
    setRegionsForSelectbox(): Promise<any> {
        return this.setSelectbox('regions', 'displayName', 'displayName', 'regionsSelectbox');
    }

    /**
     * Set the formated array expected by the departments selectbox.
     * The departments list aggregates all the departments in France.
     * The name is highlighted when one of the companies has a site inside it.
     */
    setDepartmentsForSelectbox(): Promise<any> {
        return this.setSelectbox('departments', 'displayName', 'displayName', 'departmentsSelectbox');
    }

    /**
     * Set the formated array expected by the cities selectbox.
     * The cities list aggregates all the cities found in the user's companies' sites.
     * The name is highlighted when one of the companies has a site inside it.
     */
    setCitiesForSelectbox(): Promise<any> {
        return this.setSelectbox('cities', 'displayName', 'displayName', 'citiesSelectbox');
    }

    /**
     * We want to highlight some options in the selectbox: all that are linked to the selected values
     * Get the selectboxe values from the companies selected and highlight them among all the options of the selectbox.
     * Ex: the selectbox region includes all the regions in France, if Asnieres is selected, only 'Ile-De-France' will be highlighted in the selectbox options.
     * @param name: 'fluids', 'cities', 'regions' or 'deparments'
     * @param selectboxFieldToCompare: name of the field in the selectbox to do the comparison
     * @param optionFieldToSort: option field to sort the options in the selectbox
     * @param isReadyName: field from the global isReady
     */
    setSelectbox(name, selectboxFieldToCompare, optionFieldToSort, isReadyName): Promise<any> {
        this.ruleBlock.isReady[isReadyName] = false;
        const functions = {
            fluids: 'getFluidsFromCompanies',
            cities: 'getCitiesFromCompanies',
            regions: 'getRegionsFromCompanies',
            departments: 'getDepartmentsFromCompanies',
        };
        const allOptions = {
            fluids: this.allFluids,
            cities: this.allCitiesFormated,
            regions: this.allRegionsFormated,
            departments: this.allDepartmentsFormated,
        };
        return this[functions[name]](this.ruleBlock.rule.companies).then(valuesFromSites => {
            const optionsSelectbox = clone(allOptions[name]);

            optionsSelectbox.forEach(option => {
                if (!valuesFromSites.includes(option[selectboxFieldToCompare])) {
                    option.isHighlighted = false;
                } else {
                    option.isHighlighted = true;
                }
            });

            this.ruleBlock.selectboxes[name] = optionsSelectbox.sort(this.sortListAlphabetically(optionFieldToSort));
            this.ruleBlock.isReady[isReadyName] = true;
            return Promise.resolve();
        });
    }

    /**
     * Set the formated array expected by the fluids selectbox.
     * The fluids list aggregates all the fluids found in the user's companies' sites.
     * The name is highlighted when one of the companies' contracts has a site inside it.
     */
    setFluidsForSelectbox(): Promise<any> {
        return this.setSelectbox('fluids', 'value', 'displayName', 'fluids');
    }

    async setNaturesOrgaCategoriesForSelectbox(): Promise<any> {
        this.ruleBlock.isReady.naturesSelectBox = false;
        this.ruleBlock.isReady.organizationsSelectBoxes = false;
        this.ruleBlock.isReady.complementsSelectBoxes = false;
        this.ruleBlock.isReady.routingReferencesSelectbox = false;

        const propertiesFromCompanies = await this.getNatureOrgaCategoriesFromCompanies(this.ruleBlock.rule.companies);

        // 1. update the organizations list available
        this.allOrganizationsFormated = propertiesFromCompanies['organizations'];
        this.highlightedCategoriesPerOrganization = propertiesFromCompanies['categoriesPerOrgaFiltered'];
        this.allCategoriesPerOrganization = propertiesFromCompanies['categoriesPerOrgaAll'];
        this.allNaturesFormated = propertiesFromCompanies['naturesAll'];

        // 2.  update all blocks of the organization/categories subblocks in the filter rules
        // remove from the filter the organizations targeting an organization which isn't in the list anymore
        // keep all the organization/category block that match the new organization list
        this.ruleBlock.rule.rules.forEach(rule => {
            rule.categories = rule.categories.filter(orgablock => {
                return orgablock.organization
                    ? this.allOrganizationsFormated.some(orga => orga.id === orgablock.organization.id)
                    : true;
            });
            if (!rule.categories.length) {
                rule.categories.push({ organization: null, items: [] });
            }
        });

        // update the selectboxes
        this.ruleBlock.selectboxes.categories.forEach((catSelectbox, index) => {
            catSelectbox.organizations = this.allOrganizationsFormated;
        });

        // ------ update the natures selectbox highlighted elements ------
        const naturesFromCompanies = propertiesFromCompanies['natures'];
        const allNatures = clone(this.allNaturesFormated);
        const optionsSelectboxNature = allNatures.reduce((memo, nature) => {
            const natureInCompany = naturesFromCompanies.find(x => x.id === nature.id);
            // Add Nature
            const value = {
                id: nature.id,
                name: nature.name,
                isHighlighted: Boolean(natureInCompany),
                level: 0,
                items: [],
            };
            memo.push(value);
            // Add subnature
            if (nature.items && nature.items.length) {
                nature.items.forEach(subnature => {
                    value.items.push(subnature._id);
                    memo.push({
                        id: subnature._id,
                        name: `${nature.name} - ${subnature.name}`,
                        isHighlighted: natureInCompany
                            ? Boolean(natureInCompany.items.find(x => x._id === subnature._id))
                            : false,
                        level: 1,
                    });
                });
            }
            return memo;
        }, []);

        this.ruleBlock.selectboxes.natures = optionsSelectboxNature.sort(this.sortListAlphabetically('name'));
        this.ruleBlock.isReady.naturesSelectBox = true;
        this.ruleBlock.isReady.organizationsSelectBoxes = true;
        this.ruleBlock.isReady.routingReferencesSelectbox = true;

        return;
    }

    onOrganizationSelected(rule, index, elemSelected) {
        // remove the complements if the organization has changed
        if (rule.categories[index].organization && rule.categories[index].organization.id !== elemSelected.id) {
            rule.categories[index].items = [];
        }

        // update the rule
        rule.categories[index].organization = elemSelected;
        this.ruleBlock.selectboxes.categories[index].items = this.createCategoriesItemsSelectbox(elemSelected.id);

        this.ruleBlock.isReady.complementsSelectBoxes = true;
    }

    /**
     * Create an array with all the options of the selectbox
     * Group every category/subcategory/complement existing, highlights the options included in the companies selected.
     * @param organization
     * @return {{id: string, name: string, isHighlighted: boolean}[]} Array of options for categories selectboxes with categories, subcategories & complements
     */
    createCategoriesItemsSelectbox(organization) {
        /* get highlightedCategories & allCategories: [
        {
            id: "0"
            name: "Services généraux des administrations publiques locales",
            subcategories: [{
                    id: "02"
                    name: "Administration générale",
                    complements: [...]
                }]
        }, ...
        ]*/
        const highlightedCategories = this.highlightedCategoriesPerOrganization[organization];
        const allCategories = this.allCategoriesPerOrganization[organization];

        return allCategories.reduce((selectboxCategories, currentCategory) => {
            const optionsForCategory = [];
            const highlightedCat = highlightedCategories.find(c => c.id === currentCategory.id);

            optionsForCategory.push({
                id: currentCategory.id,
                name: currentCategory.id + ' - ' + currentCategory.name,
                sHighlighted: highlightedCat ? true : false,
            });
            currentCategory.subcategories.forEach(subcat => {
                const highlightedSubcat = highlightedCat
                    ? highlightedCat.subcategories.find(s => s.id === subcat.id)
                    : null;
                optionsForCategory.push({
                    id: subcat.id,
                    name: subcat.id + ' - ' + subcat.name,
                    isHighlighted: highlightedSubcat ? true : false,
                });
                subcat.complements.forEach(comp => {
                    const highlightedComp = highlightedSubcat
                        ? highlightedSubcat.complements.find(c => c.id === comp.id)
                        : null;

                    optionsForCategory.push({
                        id: comp.id,
                        name: comp.id + ' - ' + comp.name,
                        isHighlighted: highlightedComp ? true : false,
                    });
                });
            });
            selectboxCategories = selectboxCategories.concat(optionsForCategory);
            return selectboxCategories;
        }, []);
    }

    onOrganizationRemoved(rule, index) {
        // update the rule: remove the organization and the categories selected
        rule.categories[index].organization = null;
        rule.categories[index].items = [];
        this.onFilterChanged();
    }

    addNewOrgaCategoriesBlock(rule) {
        // can't create a new block if the previous one doesn't have any category selected
        rule.categories.push({
            organization: null,
            items: [],
        });
        this.ruleBlock.selectboxes.categories.push({
            organizations: this.allOrganizationsFormated,
            items: [],
        });
    }

    onRuleCatPerOrgaRemoved(index, rule) {
        rule.categories.splice(index, 1);
        this.ruleBlock.selectboxes.categories.splice(index, 1);
        this.onFilterChanged();
    }

    sortListAlphabetically(field = 'displayName') {
        return (itemA, itemB) => {
            const a = itemA[field];
            const b = itemB[field];
            return a > b ? 1 : a < b ? -1 : 0;
        };
    }

    canAddOrgaBlock(rule) {
        return rule.categories.length > 0 && rule.categories[rule.categories.length - 1].items.length;
    }

    /**
     * Returns the names of the regions belonging to the companies selected
     * @param companies
     * @return {Promise<Array<string>>}
     */
    getRegionsFromCompanies(companies): Promise<string[]> {
        return this.filterService.getRegionsFromCompanies(companies);
    }

    /**
     * Returns the names of the departments belonging to the companies selected
     * @param companies array of string id
     * @return {Promise<Array<string>>}
     */
    getDepartmentsFromCompanies(companies): Promise<string[]> {
        return this.filterService.getDepartmentsFromCompanies(companies);
    }

    /**
     * Return an array formated for selectboxes
     * [ {
     *      displayName: string,
     *      value: string
     *    }, ..
     * ]
     * @param array of string
     */
    formatArrayForSelectbox(array) {
        return array.map(elem => {
            return {
                displayName: elem,
                value: elem,
            };
        });
    }

    getFluidsFromCompanies(companies): Promise<any[]> {
        return this.energyService.getFluidsAvailableForCompanies(companies);
    }

    /**
     * Returns array for selectbox with name in French and value in short English term
     * @param fluidsArray: array of short English fluids names. ex: ["elec", "gaz"]
     */
    formatFluidSelectbox(fluidsArray) {
        return fluidsArray.map(fluid => {
            return {
                name: this.energyService.energyFullText(fluid),
                value: fluid,
            };
        });
    }

    /** ------ HTML functions to display companies & sites ------- **/

    /**
     * Returns the companies that are currently selected in the selectbox.
     * If the rule applies to all companies, it means there is no filter.
     * So the current selection is actually empty - fill the selectbox with all the user's companies
     * @param ruleBlock
     * @return {string[]}
     */
    getCompaniesSelectedForSelectBox(): string[] {
        if (this.ruleBlock.rule.companies.length === this.allCompanies.length) {
            return [];
        } else {
            return clone(this.ruleBlock.companiesSelected); // clone because the "onCompanyAdded" modifies this list adequatly
        }
    }

    /**
     * Return the list of available companies to display in the selectbox
     * Specif case: when the ruleblock targets all the companies, it means no filter is applied yet.
     * Hence, all companies must be available for selection in this specific case
     * @return {Company[]}
     */
    getAvailableCompaniesForSelectbox(): Company[] {
        if (this.ruleBlock.rule.companies.length === this.allCompanies.length) {
            return this.allCompanies;
        }
        return this.getAvailableCompanies();
    }

    /**
     * Returns the companies that need to be displayed in tags.
     * If the rule applies to all companies, it means there is no filter.
     * So the current selection is actually empty - display none in tags
     * @return {Company[]}
     */
    getCompaniesSelectedForTags(): Company[] {
        if (this.ruleBlock.rule.companies.length === this.allCompanies.length && this.allCompanies.length > 1) {
            return [];
        } else {
            return this.ruleBlock.companiesSelected.reduce((memo, companyId) => {
                const item = this.allCompanies.find(y => y._id === companyId);
                if (item) {
                    memo.push(item);
                }
                return memo;
            }, []);
        }
    }

    getOrganizationForTag(ruleCategoryPerOrga) {
        return ruleCategoryPerOrga.organization ? [ruleCategoryPerOrga.organization] : [];
    }

    getOrganizationSelectedForSelectbox(ruleCategoryPerOrga) {
        return ruleCategoryPerOrga.organization ? [ruleCategoryPerOrga.organization] : [];
    }

    getTextForAddRuleBtn() {
        return 'Combiner avec une règle supplémentaire';
    }

    addBorderToRule(index) {
        return index > 0 ? 'border' : '';
    }

    onLocalisationChange(value: string, rule, index) {
        const oldValue = this.localisationsSelected[index];
        if (oldValue && oldValue !== value && rule) {
            rule[oldValue] = [];
        }
        if (value) {
            this.localisationsSelected[index] = value;
        }
        this.onFilterChanged();
    }

    getRuleLocalisationType(rule) {
        const type = this.localisationTypes.find(localisation => {
            return rule[localisation.value] && rule[localisation.value].length;
        });
        if (type) {
            return type.value;
        }
        return '';
    }

    /** ----- Pagination for the rule block ----- */

    /**
     * Returns false if all the companies are already used in a rule
     * @return {boolean}
     */
    hasAvailableCompanies() {
        return this.getAvailableCompanies().length;
    }

    getTooltipVisibility() {
        return this.displayInfoMessage ? 'visible' : 'not-visible';
    }

    hasOneRuleEmpty() {
        // if the rule hasn't been created yet
        if (!this.ruleBlock || !this.ruleBlock.rule || !this.ruleBlock.rule.rules.length) {
            return false;
        } else {
            const hasOneRuleEmpty = this.ruleBlock.rule.rules.some(r => {
                return this.isRuleEmpty(r);
            });
            return hasOneRuleEmpty;
        }
    }

    isRuleEmpty(rule) {
        let isEmpty = true;
        if (
            !rule.regions.length &&
            !rule.sites.length &&
            !rule.departments.length &&
            !rule.cities.length &&
            !rule.natures.length &&
            !rule.energies.length &&
            !rule.subnatures.length &&
            !rule.routingReferences.length
        ) {
            if (!rule.categories.length) {
                return true;
            } else {
                rule.categories.forEach(catPerOrga => {
                    if (!catPerOrga.organization || !catPerOrga.items.length) {
                        return true;
                    } else {
                        isEmpty = false;
                    }
                });
            }
        } else {
            isEmpty = false;
        }
        return isEmpty;
    }

    deleteEmptyRules(rules) {
        return rules.reduce((rulesNotEmpty, currentRule) => {
            if (!this.isRuleEmpty(currentRule)) {
                rulesNotEmpty.push(currentRule);
            }
            return rulesNotEmpty;
        }, []);
    }

    /**
     * Sweet alerts when something went wrong
     * @param msg_code
     */
    private getSwalMsg(msgCode) {
        const messages = {
            error_getSitesFromRule: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des sites.',
                'error',
            ],
            error_getSitesProperties: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des natures.',
                'error',
            ],
            error_getFluidsForCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des fluides.',
                'error',
            ],
            error_getRegionsFromCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des régions.',
                'error',
            ],
            error_getDepartmentsFromCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des départements.',
                'error',
            ],
            error_getCitiesList: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des villes.',
                'error',
            ],
            error_getCompaniesList: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des entreprises.',
                'error',
            ],
        };

        if (messages[msgCode]) {
            Swal.fire(messages[msgCode][0], messages[msgCode][1], messages[msgCode][2]);
        } else {
            Swal.fire(
                'Toutes nos excuses',
                'Une erreur inconnue est survenue. Merci de réessayer ultérieurement.',
                'error'
            );
        }
    }

    /**
     * Get the list of PDLs that the user can access to
     * Formate PDLs in order to access to it and to display it
     */
    getRoutingReferencesFromSites(sites: any[]) {
        const routingReferences = [];
        sites.forEach(site => {
            site.routingReferences.forEach(routingReference => {
                const formattedRoutingReference = {
                    ...routingReference,
                    displayName: `${routingReference.reference} - ${site.complement}`,
                    idCompare: `${routingReference._id}-${site._id}`,
                };
                routingReferences.push(formattedRoutingReference);
            });
        });
        return routingReferences;
    }

    /**
     * Set routingReference for selectbox
     * Make selectbox ready
     */
    setRoutingReferencesForSelectbox() {
        this.ruleBlock.isReady.routingReferencesSelectbox = false;
        this.ruleBlock.selectboxes.routingReferences = this.allRoutingReferencesFormatted;
        this.ruleBlock.isReady.routingReferencesSelectbox = true;
    }
}
