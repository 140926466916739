import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from 'app/shared/services/api/api.service';
import { UploadService } from 'app/shared/services/files/upload.service';
import { RegexService } from 'app/shared/services/regex/regex.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-pattern-fix-table',
    templateUrl: './pattern-fix-table.component.html',
    styleUrls: ['./pattern-fix-table.component.scss'],
    providers: [ApiService],
})
export class PatternFixTableComponent {
    @Input() dataArray: any;
    @Output() updatePattern: EventEmitter<any> = new EventEmitter();
    @Output() createPattern: EventEmitter<any> = new EventEmitter();

    selectedValue: string;
    modification: any[] = [];
    resetState: any[] = [];
    otherRegexOptions: any[] = [];

    constructor(private regexService: RegexService, private uploadService: UploadService) {}

    updatePatternClicked(fileProperties) {
        if (!this.hasWrongPattern() && !this.hasLoadingPattern()) {
            this.updateClosestPatternRecursively(
                fileProperties.newRegex,
                fileProperties.propertyToWatch,
                fileProperties.closestPattern
            );
            this.updatePattern.emit(fileProperties);
            this.resetButtonsAndColumns();
        } else {
            Swal.fire('Impossible', 'Un regex est en erreur ou en cours de recherche.', 'error');
        }
    }

    createNewPattern(fileProperties) {
        if (!this.hasWrongPattern() && !this.hasLoadingPattern()) {
            this.updateClosestPatternRecursively(
                fileProperties.newRegex,
                fileProperties.propertyToWatch,
                fileProperties.closestPattern
            );
            this.createPattern.emit(fileProperties);
            this.resetButtonsAndColumns();
        } else {
            Swal.fire('Impossible', 'Un regex est en erreur ou en cours de recherche.', 'error');
        }
    }

    updateClosestPatternRecursively(regex, rightPartToExplore, currentPointer) {
        // need to go furher inside property
        // 1. rackingFee.HC.dateStart
        // 2. HC.dateStart
        // 3. dateStart
        if (rightPartToExplore.indexOf('.') !== -1) {
            // go 1 step further
            // 1. turpe -> turpe.rackingFee
            // 2. turpe.rackingFee -> turpe.rackingFee.HC
            const firstElement = rightPartToExplore.split('.')[0];
            if (!currentPointer[firstElement]) {
                currentPointer[firstElement] = {};
            }
            currentPointer = currentPointer[firstElement];

            // 1. rackingFee.HC.dateStart -> HC.dateStart
            // 2. HC.dateStart -> dateStart
            rightPartToExplore = rightPartToExplore.substring(rightPartToExplore.split('.')[0].length + 1);

            return this.updateClosestPatternRecursively(regex, rightPartToExplore, currentPointer);
        }

        // 3. closestPattern[turpe.rackingFee.HC.dateStart] is set to regex value
        currentPointer[rightPartToExplore] = regex;
    }

    onResetBtn(fileProperties) {
        Swal.fire({
            title: 'Etes-vous sûr ?',
            text: 'Voulez-vous réinitialiser le regex initial ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Réinitialiser',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
                this.resetLine(fileProperties);
            }
        });
    }

    resetLine(fileProperties) {
        // onResetBtn the colum 'potential values' for that pattern
        this.dataArray.fileProperties.forEach(file => {
            if (file.patternId.toString() === fileProperties.patternId.toString()) {
                file.potentialValueExtract = '';
            }
        });
        fileProperties.oldRegex = fileProperties.regex;
        fileProperties.option = 'Selectionnez un regex';
        fileProperties.potentialValueExtract = '';
        this.modification[fileProperties.number - 1] = false;
        this.resetState[fileProperties.number - 1] = false;
        fileProperties.valueExtract = '';
        this.applyRegexToText(fileProperties, fileProperties.regex).then(val => {
            fileProperties.valueExtract = val;
        });
    }

    async openFileInTab(fileId) {
        try {
            await this.uploadService.openFileInTab(fileId);
        } catch (e) {
            Swal.fire(
                'Toutes nos excuses',
                'Le fichier est inaccessible pour le moment. Veuillez réessayer ultérieurement.',
                'warning'
            );
        }
    }

    onRegexChanged(fileProperty, regex, target) {
        // If regex came from input
        if (target === 'input') {
            fileProperty.option = 'Selectionnez un regex';
            this.updateValuesFromRegex(fileProperty, regex);
        } else {
            // If regex came from selectbox
            if (regex === 'Selectionnez un regex') {
                this.resetLine(fileProperty);
            } else {
                if (regex.indexOf(' -->') !== -1) {
                    regex = regex.substr(regex.indexOf(' -->') + 5);
                }
                fileProperty.oldRegex = regex;
                this.updateValuesFromRegex(fileProperty, regex);
            }
        }
    }

    updateValuesFromRegex(fileProperty, regex) {
        fileProperty.loading = true;
        this.applyRegexToText(fileProperty, regex).then(val => {
            fileProperty.valueExtract = val;
            fileProperty.newRegex = regex;
            fileProperty.loading = false;
            this.resetState[fileProperty.number - 1] = true;
            this.modification[fileProperty.number - 1] = true;
            this.dataArray.fileProperties.forEach(file => {
                if (
                    file.patternId.toString() === fileProperty.patternId.toString() &&
                    file.number !== fileProperty.number
                ) {
                    file.loading = true;
                    this.applyRegexToText(file, regex).then(val2 => {
                        file.potentialValueExtract = val2;
                        file.loading = false;
                    });
                }
            });
        });
    }

    testRegex(text, regex) {
        return this.regexService.testRegex(text, regex);
    }

    applyRegexToText(file, regex) {
        const textContent = file.textContent;
        return this.testRegex(textContent, regex)
            .then(extractedValuesArray => {
                let listValues = '';
                extractedValuesArray.forEach(value => {
                    listValues = listValues.concat('[' + value + '] ');
                });
                file.error = false;
                return listValues;
            })
            .catch(err => {
                file.error = true;
                return 'Error : ' + err.errorCode;
            });
    }
    // Update on focus, can't calculate all live, as it take too much time with the workers
    updateDictOptions(fileProperty) {
        this.otherRegexOptions = [];
        this.dataArray.regexDictionaryPerIdentPattern[fileProperty.identificationPatternId].forEach(regex => {
            const option = { regex, value: 'Recherche...' };
            this.otherRegexOptions.push(option);
            this.getDictionaryOption(fileProperty, regex).then(valueToDisplay => {
                option.value = valueToDisplay;
            });
        });
    }

    getDictionaryOption(fileProperty, regex) {
        return this.applyRegexToText(fileProperty, regex).then(extractedValue => {
            // if the extracted valueis null -> shoudn't display 'null'
            const valueToDisplay = !extractedValue ? '' : extractedValue + ' --> ';

            // the first option is an empty regex, shouldn't display anything
            return valueToDisplay + regex;
        });
    }

    resetButtonsAndColumns() {
        this.modification = [];
        this.resetState = [];
        this.dataArray.fileProperties.forEach(file => {
            file.potentialValueExtract = '';
        });
    }

    getValueExtract(property) {
        if (property) {
            if (property.loading) {
                return 'Recherche...';
            }
            return property.valueExtract;
        }
        return '';
    }

    getPotentialValueExtract(property) {
        if (property) {
            if (property.loading && this.modification.some(x => x)) {
                return 'Recherche...';
            }
            return property.potentialValueExtract;
        }
        return '';
    }

    hasWrongPattern() {
        let isWrong = false;
        isWrong = isWrong || this.dataArray.fileProperties.some(x => x.error === true);
        return isWrong;
    }

    hasLoadingPattern() {
        let isLoading = false;
        isLoading = isLoading || this.dataArray.fileProperties.some(x => x.loading === true);
        return isLoading;
    }

    getPropertyLink(fileProperty) {
        if (fileProperty.chunk) {
            return ['/collecte/regex/upload', fileProperty._id, fileProperty.chunk];
        }
        return ['/collecte/regex/upload', fileProperty._id];
    }

    isPatternEnabled(fileProperty): boolean {
        if (fileProperty.closestPattern && typeof fileProperty.closestPattern.enabled === 'boolean') {
            return fileProperty.closestPattern.enabled;
        }

        return true;
    }
}
