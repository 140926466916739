import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { AccountComponent } from './account.component';

const ACCOUNT_ROUTE = [{ path: '', component: AccountComponent }];

@NgModule({
    declarations: [AccountComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(ACCOUNT_ROUTE)],
})
export class AccountModule {}
