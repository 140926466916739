import { Injectable } from '@angular/core';
import { PageService } from '../page/page.service';

@Injectable()
export class EngineerService extends PageService {
    async getEngineerByUserId(id): Promise<any> {
        try {
            const response = await this.get('/api/engineers/user/' + id);
            return response.data;
        } catch (err) {
            err.error_code = 'error_loadEngineer';
            return Promise.reject(err);
        }
    }

    async updateEngineer(engineer): Promise<any> {
        try {
            const route = '/api/engineers/crud/' + engineer._id;
            const response = await this.put(route, engineer, null, true);
            if (response.data.matchedCount === 1) {
                return;
            } else {
                const err = { error_code: 'error_updateEngineer' };
                return Promise.reject(err);
            }
        } catch (err) {
            err.error_code = 'error_updateEngineer';
            return Promise.reject(err);
        }
    }

    async createEngineer(engineer): Promise<any> {
        try {
            const route = '/api/engineers/crud/';
            const response = await this.post(route, engineer);
            return response.data;
        } catch (err) {
            err.error_code = 'error_createNewEngineer';
            return Promise.reject(err);
        }
    }
}
