import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { SigninComponent } from './signin.component';

const SIGNIN_ROUTE = [{ path: '', component: SigninComponent }];

@NgModule({
    declarations: [SigninComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(SIGNIN_ROUTE)],
})
export class SigninModule {}
