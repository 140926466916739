import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';

import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SpinnerService } from 'app/shared/services/spinner/spinner.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';

import {
    HeatingPeriod,
    Monitoring,
    MonitoringFilters,
    SeasonKeyfigures,
    Status,
} from './operating-monitoring.interface';
import { HeatingPeriodUpdate, MonitoringUpdate } from './operating-monitoring.update.interface';

@Injectable()
export class OperatingMonitoringService extends PageService {
    /**
     * Subject to subscribe to changes of filter
     */
    public filters$: BehaviorSubject<MonitoringFilters> = new BehaviorSubject<MonitoringFilters>({
        companies: [this.sessionService.getCompanyId()],
        itemsPerPage: 10,
        itemsToDisplay: 10,
    });

    /**
     * Subject to subscribe to changes of seasons keyfigures
     */
    public seasonsKeyfigures$: BehaviorSubject<SeasonKeyfigures[]> = new BehaviorSubject<SeasonKeyfigures[]>([]);

    /**
     * Subject to subscribe to changes of monitorings list
     */
    private monitorings$: BehaviorSubject<Monitoring[]> = new BehaviorSubject<Monitoring[]>([]);

    /**
     * Subject to subscribe to changes of monitorings list (only those filtered and displayed in pagination)
     */
    public monitoringsFiltered$: BehaviorSubject<Monitoring[]> = new BehaviorSubject<Monitoring[]>([]);

    /**
     * Subject to subscribe to changes of selected monitoring
     */
    public selectedMonitoring$: BehaviorSubject<Monitoring> = new BehaviorSubject<Monitoring>(null);

    /**
     * Subject to subscribe to the monitoring being edited
     */
    public editedMonitoring$: BehaviorSubject<Monitoring> = new BehaviorSubject<Monitoring>(null);

    /**
     * Event to toggle modal to create or update a monitoring.
     * True to open, false to close
     */
    public toggleEditionMonitoringModal$: Subject<boolean> = new Subject<boolean>();
    /**
     * Event to toggle modal to edit readings.
     * True to open, false to close
     */
    public toggleEditReadingsModal$: Subject<boolean> = new Subject<boolean>();

    /**
     * Subject to subscribe to changes of selected period of monitoring
     */
    public selectedPeriod$: BehaviorSubject<HeatingPeriod> = new BehaviorSubject<HeatingPeriod>(null);

    public isLastActionDelete = false;

    constructor(
        public apiService: ApiService,
        private sessionService: SessionService,
        private translateService: TranslateService,
        private _spinner: SpinnerService
    ) {
        super(apiService);

        this.filters$.subscribe({
            next: () => this.refreshMonitorings(),
        });
        this.monitorings$.subscribe({
            next: value => this.refreshFilteredMonitorings(this.filters$.getValue(), value),
        });
        this.monitoringsFiltered$.subscribe({
            next: value => this.setSelectedMonitoring(value),
        });
        this.selectedMonitoring$.subscribe({
            next: monitoring => {
                if (monitoring) {
                    // Populate empty site for new monitoring
                    if (!monitoring.site) {
                        monitoring.site = {
                            _id: null,
                            name: null,
                            complement: null,
                        };
                    }

                    // Select last selected monitoring period or the latest period of the new selected monitoring
                    let period = this.getLatestPeriod(monitoring);

                    const lastSelectedPeriod = this.selectedPeriod$.getValue();

                    if (lastSelectedPeriod && monitoring.periods && monitoring.periods.length) {
                        const periodInMonitoring = monitoring.periods.find(p => p._id === lastSelectedPeriod._id);
                        if (periodInMonitoring) {
                            period = periodInMonitoring;
                        }
                    }
                    this.setSelectedPeriod(period);
                } else if (this.isLastActionDelete) {
                    this.setSelectedPeriod(null);
                }
            },
        });
    }

    public setFilters(filters: MonitoringFilters): void {
        this.filters$.next(filters);
    }

    /**
     * Set the selected periode, null if none is selected
     */
    public setSelectedPeriod(period: HeatingPeriod): void {
        this.selectedPeriod$.next(period);
    }

    /**
     * Create the monitoring.
     * @param monitoring
     * @returns Newly created monitoring or null.
     */
    public async createMonitoring(monitoring: MonitoringUpdate): Promise<void> {
        try {
            const newMonitoring = (await this.post('/api/operating-monitoring', { data: monitoring })).data;

            this.selectedMonitoring$.next(newMonitoring);

            this.refreshMonitorings();
            this.isLastActionDelete = false;
        } catch (error) {
            Swal.fire({
                title: this.translateService._('error_we_are_sorry'),
                text: this.translateService._('error_monitoring_while_creating'),
                icon: 'error',
            });
        }
    }

    /**
     * Update the monitoring
     */
    public async updateMonitoring(monitoring: MonitoringUpdate): Promise<void> {
        try {
            const newMonitoring: Monitoring = (await this.patch(`/api/operating-monitoring/${monitoring._id}`, {
                data: monitoring,
            })).data;

            this.selectedMonitoring$.next(newMonitoring);

            // Update the monitoring in list
            this._spinner.show({ transparent: true });
            setTimeout(() => {
                this.refreshMonitorings();
                this._spinner.hide();
                this.isLastActionDelete = false;
            }, 2500);
        } catch (error) {
            this.refreshMonitorings();

            Swal.fire({
                title: this.translateService._('error_we_are_sorry'),
                text: this.translateService._('error_monitoring_while_updating'),
                icon: 'error',
            });
        }
    }

    /**
     * Delete the monitoring
     */
    public async deleteMonitoring(monitoring: Monitoring): Promise<void> {
        try {
            await this.delete(`/api/operating-monitoring/${monitoring._id}`);
            this.isLastActionDelete = true;
        } catch (error) {
            Swal.fire({
                title: this.translateService._('error_we_are_sorry'),
                text: this.translateService._('error_monitoring_while_updating'),
                icon: 'error',
            });
        } finally {
            this.refreshMonitorings();
        }
    }

    private async refreshMonitorings() {
        const filters = this.filters$.getValue();
        this.getMonitorings(filters);
        this.refreshSeasonsKeyfigures(filters);
    }

    /**
     * Retrieve full list of monitorings
     */
    private async getMonitorings(filters: MonitoringFilters): Promise<void> {
        let monitorings: Monitoring[] = [];
        const params: { companies: string; status?: Status } = { companies: filters.companies.join(',') };
        if (filters.status) {
            params.status = filters.status;
        }

        try {
            monitorings = (await this.get('/api/operating-monitoring', null, params)).data;
        } catch (error) {
            Swal.fire({
                title: this.translateService._('error_we_are_sorry'),
                text: this.translateService._('error_monitoring_while_loading'),
                icon: 'error',
            });
        }

        monitorings = monitorings
            .filter(m => Boolean(m.site))
            .sort((a: Monitoring, b: Monitoring) => {
                const siteNameA = a.site.complement && a.site.complement !== 'NC' ? a.site.complement : a.site.name;
                const siteNameB = b.site.complement && b.site.complement !== 'NC' ? b.site.complement : b.site.name;
                const compareSite = siteNameA.localeCompare(siteNameB);
                const compareName = a.name.localeCompare(b.name);

                return compareSite || compareName;
            });

        this.monitorings$.next(monitorings);
    }

    /**
     * Filter the currently retrieved monitorings with the current filters
     */
    private refreshFilteredMonitorings(filters: MonitoringFilters, monitorings: Monitoring[]): void {
        let monitoringsFiltered = monitorings;
        if (filters.status) {
            monitoringsFiltered = monitorings.filter(monitoring => monitoring.status === filters.status);
        }

        this.monitoringsFiltered$.next(monitoringsFiltered);
    }

    /**
     * Set the selected monitoring to the first of given filtered if no one is already set
     */
    private setSelectedMonitoring(monitoringsFiltered: Monitoring[]): void {
        const selectedMonitoring = this.selectedMonitoring$.getValue();

        if (selectedMonitoring) {
            const selected = monitoringsFiltered.find(m => m._id === selectedMonitoring._id);
            if (selected) {
                this.selectedMonitoring$.next(selected);
                return;
            } else if (!this.isLastActionDelete) {
                // No change in monitoring
                this.selectedMonitoring$.next(selectedMonitoring);
                return;
            }
        }
        // If no selected, select first one
        this.selectedMonitoring$.next(monitoringsFiltered[0]);
    }

    /**
     * Display or hide edition monitoring modal
     */
    public toggleEditionMonitoringModal(value: boolean) {
        this.toggleEditionMonitoringModal$.next(value);
    }
    /**
     * Display or hide edit readings modal
     */
    public toggleEditReadingsModal(value: boolean) {
        this.toggleEditReadingsModal$.next(value);
    }

    /**
     * Verify if the given period is inside monitoring contract boundaries
     */
    public isPeriodInContract(period: HeatingPeriodUpdate, monitoring: Monitoring): boolean {
        const periodStart = moment.utc(period.periodStart);
        const periodEnd = moment.utc(period.periodEnd);
        const contractStart = moment.utc(monitoring.contractStart);
        const contractEnd = moment.utc(monitoring.contractEnd);

        if (
            periodStart.isBefore(contractStart, 'day') ||
            periodStart.isAfter(contractEnd, 'day') ||
            (periodEnd.isValid() && periodEnd.isAfter(contractEnd, 'day'))
        ) {
            return false;
        }

        return true;
    }

    /**
     * Validate the new date of the selected period
     */
    public isPeriodValid(checkPeriod: HeatingPeriodUpdate): boolean {
        // If all dates are not in the same format (ISO string), compares should be false
        const periodStart = moment.utc(checkPeriod.periodStart).toISOString();
        const periodEnd = moment.utc(checkPeriod.periodEnd).toISOString();

        // If not a valid date unvalidate the period
        if (!periodStart || (checkPeriod.periodEnd && !periodEnd)) {
            return false;
        }

        if (checkPeriod) {
            // If start date after end date
            if (periodEnd && periodStart > periodEnd) {
                return false;
            }
            // If start/end date is in range of another periods (superposition)
            for (const period of this.selectedMonitoring$.getValue().periods as HeatingPeriod[]) {
                if (checkPeriod._id && period._id === checkPeriod._id) {
                    continue;
                }
                // End of selected is in middle of period
                if (periodEnd && periodEnd <= period.periodStart && periodStart >= period.periodEnd) {
                    return false;
                }
                // Period ends is in middle of new period, which doesn't have end
                if (!periodEnd && period.periodStart && periodStart <= period.periodStart) {
                    return false;
                }
                // Period ends in middle of the new period
                if (period.periodStart && periodStart <= period.periodStart && period.periodEnd <= periodEnd) {
                    return false;
                }
                // Period starts is in middle of new period, which doesn't have end
                if (!periodEnd && periodStart <= period.periodEnd) {
                    return false;
                }
                // Period is contained in the new period
                if (period.periodStart && periodStart <= period.periodEnd && periodEnd >= period.periodStart) {
                    return false;
                }
                // The new period starts after a period, which doesn't have end
                if (!period.periodStart && periodStart >= period.periodEnd) {
                    return false;
                }
                // Period starts in middle of the new period
                if (periodEnd >= period.periodEnd && periodStart <= period.periodEnd) {
                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Get latest period of monitoring
     * @param monitoring
     */
    private getLatestPeriod(monitoring: Monitoring): HeatingPeriod {
        if (!monitoring || !monitoring.periods || !monitoring.periods.length) {
            return null;
        }
        // Sort periods from latest to oldest
        const periods = monitoring.periods.concat().sort((a, b) => b.periodStart.localeCompare(a.periodStart));

        return periods[0];
    }

    public get hasMonitorings(): boolean {
        return this.monitorings$.getValue().length > 0;
    }

    /**
     * Get period date ISO string tranformed if needed.
     * Period start is start of day
     * Period end is end of day
     * @param date - date at format YYYY-MM-DD
     * @param type - period date type (start or end of period)
     */
    public getPeriodDateISOString(date: string, type: 'start' | 'end'): string {
        if (!date) {
            return null;
        }
        if (type === 'start') {
            return moment
                .utc(date)
                .startOf('day')
                .toISOString();
        }
        if (type === 'end') {
            return moment
                .utc(date)
                .endOf('day')
                .toISOString();
        }
        throw new Error('unknown type');
    }

    /**
     * Refresh seasons keyfigures and returns keyfigures
     */
    private async refreshSeasonsKeyfigures(filters: MonitoringFilters): Promise<void> {
        const params: { companies: string; status?: Status } = { companies: filters.companies.join(',') };
        if (filters.status) {
            params.status = filters.status;
        }

        try {
            const res = await this.get('/api/operating-monitoring/seasons', null, params);
            this.seasonsKeyfigures$.next(res.data);
        } catch (err) {
            Swal.fire({
                title: this.translateService._('error_we_are_sorry'),
                text: this.translateService._('error_monitoring_retrieve_seasons'),
                icon: 'error',
            });
        }
    }

    /** Sum consumption, valueRevalued, modulator and profits of all periods in a monitoring and calculate the deviation for it */
    public getSumAllPeriods(monitoring: Monitoring): HeatingPeriod {
        return monitoring.periods.reduce(
            (acc, p) => {
                if (Number.isFinite(p.consumptionHeating)) {
                    acc.consumptionHeating += p.consumptionHeating;
                }

                if (Number.isFinite(p.valueRevaluedOnBillsPeriod)) {
                    acc.valueRevaluedOnBillsPeriod += p.valueRevaluedOnBillsPeriod;
                }

                if (Number.isFinite(p.modulatorRealOnBillsPeriod)) {
                    acc.modulatorRealOnBillsPeriod += p.modulatorRealOnBillsPeriod;
                }

                if (acc.consumptionHeating !== null && acc.modulatorRealOnBillsPeriod) {
                    acc.consumptionHeatingPerModulatorReal = acc.consumptionHeating / acc.modulatorRealOnBillsPeriod;
                }

                if (Number.isFinite(p.profits)) {
                    acc.profits += p.profits;
                }

                if (acc.consumptionHeating !== null && acc.valueRevaluedOnBillsPeriod) {
                    acc.deviation = (1 - acc.consumptionHeating / acc.valueRevaluedOnBillsPeriod) * 100;
                }

                return acc;
            },
            {
                _id: null,
                periodStart: null,
                periodEnd: null,
                createdAt: null,
                updatedAt: null,
                consumptionHeating: null,
                valueRevaluedOnBillsPeriod: null,
                modulatorRealOnBillsPeriod: null,
                consumptionHeatingPerModulatorReal: null,
                profits: null,
                deviation: null,
            }
        );
    }
}
