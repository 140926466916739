import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

// Components
import { ChartBarToggleComponent } from 'app/shared/components/charts/chart-bar-toggle/chart-bar-toggle.component';

// Models/interfaces
import { ElecPdlViewService } from 'app/pages/energy/pdl-view/elec/elec.service';
import { FluidViewToggleChartProperties } from 'app/pages/energy/pdl-view/pdl-view.interface';
import { SitesListService } from 'app/pages/energy/sites-list/sites-list.service';
import { DonutProperties } from 'app/shared/models/charts/charts.interface';
import { Dju } from 'app/shared/models/dju.interface';
import { DatePeriod, HeatingMonthlyBill, HeatingYearlyBill } from 'app/shared/models/fluid-bills.interface';
import { HeatingMonthlyCostTableBill, YearlyRatioTableBill } from 'app/shared/models/fluid-tables.interface';
import { TileInterface } from 'app/shared/models/tile.interface';
import { BillsService } from 'app/shared/services/bills/bills.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { ColorService } from 'app/shared/services/color/color.service';
import { DjuService } from 'app/shared/services/dju/dju.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

interface Loading {
    chartLine: boolean;
    secondChartLine: boolean;
    chartDonut: boolean;
    monthlyCostTable: boolean;
    ratioTable: boolean;
    site: boolean;
}

@Component({
    selector: 'ga-heating-pdl-view',
    templateUrl: './heating.component.html',
    styleUrls: ['./heating.component.scss'],
    providers: [ElecPdlViewService, SitesListService],
})
export class HeatingPdlViewComponent implements OnInit {
    totalTTC = 0;
    totalHT = 0;
    promiseMonthData: Promise<HeatingMonthlyBill[]> = null;

    loading: Loading = {
        chartLine: false,
        secondChartLine: false,
        chartDonut: false,
        monthlyCostTable: false,
        ratioTable: false,
        site: true,
    };

    /**Default config for conso line chart */
    private get defaultConfigConsoLineChart(): FluidViewToggleChartProperties {
        return {
            datasets: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: [],
            title: 'Consommation du PDL',
            downloadFileNames: ['Consommation du PDL', 'Consommation Chauffage/ECS du PDL'],
            toggleTitles: [],
        };
    }
    /** Conso line chart properties */
    public consoLineChartProperties: FluidViewToggleChartProperties = this.defaultConfigConsoLineChart;

    @ViewChild('chartBarConso', { static: false }) chartBarConso: ChartBarToggleComponent;

    /** Default config for costs line chart */
    private get defaultConfigCostsLineChart(): FluidViewToggleChartProperties {
        return {
            datasets: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: [],
            title: 'Coût de la consommation du PDL',
            downloadFileNames: ['Coût de la consommation du PDL', 'Coût de la consommation Chauffage/ECS du PDL'],
            toggleTitles: [],
        };
    }
    /** Costs line chart properties */
    public costsLineChartProperties: FluidViewToggleChartProperties = this.defaultConfigCostsLineChart;

    dju: any = { isDjuActivated: true, djusAvailable: false };

    doughnutCostsProperties: DonutProperties = {
        options: [],
        legend: [],
        title: 'Répartition des coûts',
    };

    monthlyCostTable: HeatingMonthlyCostTableBill[];
    years: number[];
    contract = '';
    routingRef = '';
    site: any = null;
    data: any;
    selectedContract: any;
    consoData: any;
    costData: any;
    ratioTable: YearlyRatioTableBill[] = [];
    energyType = 'heating';

    totalConsoTilesProperties: TileInterface = {
        mainColor: '',
        secondaryColor: '',
        label: 'Consommation totale',
        unit: 'kWh PCI',
        type: 'heating',
    };

    totalCostTilesProperties: TileInterface = {
        mainColor: '',
        secondaryColor: '',
        label: 'Dépense totale',
        unit: '',
        type: 'cost',
    };

    periodSelected: DatePeriod = { dateStart: null, dateEnd: null };

    constructor(
        private chartService: ChartService,
        private sessionService: SessionService,
        private energyPdlViewService: ElecPdlViewService,
        private siteService: SitesService,
        private route: ActivatedRoute,
        private colorService: ColorService,
        private billService: BillsService,
        private djuService: DjuService,
        private utilsService: UtilsService,
        private tilesService: TilesService,
        private pdlService: SitesListService
    ) {}

    ngOnInit() {
        this.totalConsoTilesProperties.mainColor = this.colorService.getHexColor('SECOND_BURGUNDY');
        this.totalCostTilesProperties.mainColor = this.colorService.getHexColor('SECOND_BURGUNDY');
        this.totalConsoTilesProperties.secondaryColor = this.colorService.getHexColor('BURGUNDY');
        this.totalCostTilesProperties.secondaryColor = this.colorService.getHexColor('BURGUNDY');
        this.getSiteFromParams();
    }

    /**
     * Set all charts/tables loading
     * @param {boolean} loading - true to set as loading, false to set as not loading
     */
    private setAllLoadings(loading: boolean = true) {
        this.loading.chartLine = loading;
        this.loading.secondChartLine = loading;
        this.loading.chartDonut = loading;
        this.loading.monthlyCostTable = loading;
        this.loading.ratioTable = loading;
    }

    resetAll() {
        this.loading.secondChartLine = true;
        this.loading.chartDonut = true;
        this.loading.monthlyCostTable = true;
        this.loading.chartLine = true;
        this.loading.ratioTable = true;

        this.consoLineChartProperties = this.defaultConfigConsoLineChart;

        this.costsLineChartProperties = this.defaultConfigCostsLineChart;

        this.doughnutCostsProperties = {
            options: [],
            legend: [],
            title: 'Répartition des coûts',
        };

        this.monthlyCostTable = null;
        this.ratioTable = [];
    }

    /**
     * Update values on period change
     * @param {DatePeriod} period - new period
     */
    async onPeriodChanged(period: DatePeriod) {
        if (
            (this.periodSelected && period && period.dateStart !== this.periodSelected.dateStart) ||
            period.dateEnd !== this.periodSelected.dateEnd
        ) {
            this.periodSelected = period;
            await this.grabDataAndDisplay();
        }
    }

    /**
     * Grab data, format it, and set it for every component.
     */
    async grabDataAndDisplay(): Promise<void> {
        if (!this.periodSelected || !this.periodSelected.dateStart || !this.periodSelected.dateEnd) {
            return;
        }

        this.resetAll();
        const [djusMonthDatas, yearlyConsumptionWithHisto, yearlyConsumption]: [
            { djus: Dju[]; monthDatas: HeatingMonthlyBill[] },
            HeatingYearlyBill[],
            HeatingYearlyBill[]
        ] = await Promise.all([
            this.getRfMonthlyConsumption(),
            this.getRfYearlyConsumptionWithHistoric(),
            this.getYearlyConsumptionForDonut(),
        ]);

        this.handleMonthData(djusMonthDatas.djus, djusMonthDatas.monthDatas); // send dju data & month data
        this.djuActivateChanged(this.dju.isDjuActivated);
        this.setTotals(djusMonthDatas.monthDatas);
        this.handleRatioTable(yearlyConsumptionWithHisto);
        this.handleCostsDonut(yearlyConsumption);
    }

    getSiteFromParams(): void {
        if (this.route.params) {
            this.route.params.subscribe(async params => {
                try {
                    this.setAllLoadings(true);
                    this.contract = params.contractId;
                    this.routingRef = params.rfId;

                    // #1 Load year available
                    const years = await this.energyPdlViewService.getYears(
                        this.contract,
                        this.routingRef,
                        this.energyType
                    );

                    if (years && years.data && years.data.length) {
                        this.years = years.data;
                        this.years.sort((a, b) => b - a);
                    }
                    this.dju.isDjuActivated = true;

                    // #2 Load routing reference concerned
                    const routingReference = await this.energyPdlViewService.getRoutingReferenceById(this.routingRef);
                    this.data = routingReference.data;
                    this.selectedContract = this.data.contracts.find(contract => contract._id === this.contract);

                    // #3 Load site with routing reference
                    const site = await this.siteService.getSiteFromRoutingReference(this.data._id, this.data.company);

                    // if routing reference isn't linked to any site
                    if (!_.isEmpty(site)) {
                        this.site = site;
                    }
                    this.loading.site = false;
                } catch (error) {
                    this.years = this.years ? this.years : [];
                    this.periodSelected = this.periodSelected
                        ? this.periodSelected
                        : { dateStart: null, dateEnd: null };
                    this.data = this.data ? this.data : null;
                    this.selectedContract = this.selectedContract ? this.selectedContract : null;
                    this.site = null;
                    this.loading.site = false;
                    const msg = this.data ? 'du site' : 'de votre PDL';
                    Swal.fire('Toutes nos excuses', "Une erreur s'est produite lors du chargement " + msg, 'error');
                }
            });
        }
    }

    /**
     *
     * @param dateStart
     * @param dateEnd
     * @return {Promise<HeatingMonthlyBill[]>}
     */
    async getMonthDatas(dateStart, dateEnd): Promise<HeatingMonthlyBill[]> {
        if (!this.promiseMonthData) {
            const monthDatas = await this.pdlService.getByMonthOrYear(
                'heating',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd
            );
            this.promiseMonthData = null;
            return monthDatas.data;
        }

        return this.promiseMonthData;
    }

    djuActivateChanged(isDjuActivated) {
        isDjuActivated = isDjuActivated && this.dju.djusAvailable;
        this.dju.isDjuActivated = isDjuActivated;

        /**
         * First, we need to update data (so that it's saved depending on other actions)
         * Then, we need to update the chart display.
         */
        const djuLabel = 'Degrés jours unifiés (par mois)';
        const kwhDjuButton = 'kWh/dju';
        const labelsLegends = [
            ['total_conso_heating', 'total_conso_heating_calculated'],
            ['heating_heating', 'heating_ecs'],
            ['heating_heating', 'heating_ecs', 'total_conso_heating'],
        ];
        if (isDjuActivated) {
            labelsLegends[0].unshift('djus');
            labelsLegends[1].unshift('djus');
        }
        const legends = labelsLegends.map(x => this.chartService.getChartLegends(x));

        this.djuService.toggleDjuInDatasetAndChart(
            isDjuActivated,
            this.consoLineChartProperties,
            djuLabel,
            legends,
            kwhDjuButton,
            this.chartBarConso
        );
    }

    /**
     * Set ratio table data from bills data aggregated per year
     * @param {HeatingYearlyBill}[] dataPerYear
     */
    setRatio(dataPerYear: HeatingYearlyBill[]): void {
        this.ratioTable = [];

        dataPerYear.forEach(yd => {
            const hasTotalCons = yd.consumption.total.amount || yd.consumption.total.quantity;
            const consumption = hasTotalCons
                ? yd.consumption.total.amount
                : yd.consumption.heating.amount + yd.consumption.ecs.amount;
            const quantity = hasTotalCons
                ? yd.consumption.total.quantity
                : yd.consumption.heating.quantity + yd.consumption.ecs.quantity;

            const hasTotalSub = Boolean(yd.subscription.total.amount);
            const subscription = hasTotalSub
                ? yd.subscription.total.amount
                : yd.subscription.heating.amount + yd.subscription.ecs.amount;

            const miscellaneous = yd.miscellaneous.telegestion.amount + yd.miscellaneous.gestion.amount;
            const totalHTVA = yd.totalHT.amount;
            const totalTTC = yd.totalTTC.amount;

            this.ratioTable.push({
                periodLabel: yd.year,
                ratios: {
                    consumption: this.formatNumberDefaultToNC((consumption / quantity) * 100, 'money'),
                    subscription: this.formatNumberDefaultToNC((subscription / quantity) * 100, 'money'),
                    miscellaneous: this.formatNumberDefaultToNC((miscellaneous / quantity) * 100, 'money'),
                    totalHTVA: this.formatNumberDefaultToNC((totalHTVA / quantity) * 100, 'money'),
                    totalTTC: this.formatNumberDefaultToNC((totalTTC / quantity) * 100, 'money'),
                },
            });
        });
    }

    getTotalConso() {
        return Number(this.consoData.data);
    }

    getTotalCost() {
        const totalHT = this.tilesService.getNumberToDisplay(this.totalHT, '€');
        const totalTTC = this.tilesService.getNumberToDisplay(this.totalTTC, '€');
        return `<b>${totalHT}</b> € HT - <b>${totalTTC}</b> € TTC`;
    }

    /*
     * Set in storage the current site in last visited sites for dashboard display
     */
    setLastVisitedSites(site) {
        this.sessionService.setLastVisitedSites(site);
    }

    /**
     *  Grab month data and DJU if needed on selected period for charts.
     * @param {boolean} activateDjus
     * @return {Promise<{djus: Dju[], monthDatas: HeatingMonthlyBill[]}>}
     */
    async getRfMonthlyConsumption(): Promise<{ djus: Dju[]; monthDatas: HeatingMonthlyBill[] }> {
        try {
            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            const djuPromise: Promise<Dju[]> =
                this.site && this.site._id
                    ? this.djuService.getSiteDJUs(dateStart, dateEnd, this.site).catch(() => null)
                    : (async () => null)();

            const promiseArray: [Promise<HeatingMonthlyBill[]>, Promise<Dju[]>] = [
                this.getMonthDatas(dateStart, dateEnd),
                djuPromise,
            ];

            const [monthDatas, djus] = await Promise.all(promiseArray);

            this.dju.djusAvailable = Boolean(djus && djus.length);
            return { djus, monthDatas };
        } catch (error) {
            this.loading.chartLine = false;
            this.loading.secondChartLine = false;
            this.loading.monthlyCostTable = false;
            return { djus: null, monthDatas: [] };
        }
    }

    /**
     * Grab yearly consumption with 2 years historic for ratio table.
     * @return {Promise<HeatingYearlyBill[]>}
     */
    async getRfYearlyConsumptionWithHistoric(): Promise<HeatingYearlyBill[]> {
        try {
            this.loading.ratioTable = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('year')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('year')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                'heating',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                true,
                2
            );

            return response.data;
        } catch (error) {
            this.loading.ratioTable = false;
            return [];
        }
    }

    /**
     * Grab yearly consumption for donut.
     * @return {Promise<HeatingYearlyBill[]>}
     */
    async getYearlyConsumptionForDonut(): Promise<HeatingYearlyBill[]> {
        try {
            this.loading.chartDonut = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                'heating',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                true
            );

            return response.data;
        } catch (error) {
            this.loading.chartDonut = false;
            return [];
        }
    }

    /**
     * Set totals values for tiles
     * @param {HeatingMonthlyBill[]} dataPerMonth - monthly data
     */
    setTotals(dataPerMonth: HeatingMonthlyBill[]): void {
        this.totalTTC = 0;
        this.totalHT = 0;

        if (dataPerMonth && dataPerMonth.length) {
            let conso = 0;
            dataPerMonth.forEach(monthData => {
                // Cost
                this.totalHT += monthData.totalHT.amount;
                this.totalTTC += monthData.totalTTC.amount;
                // Conso
                let monthConso = monthData.consumption.total.quantity;
                if (!monthConso && (monthData.consumption.heating.quantity || monthData.consumption.ecs.quantity)) {
                    monthConso = monthData.consumption.heating.quantity + monthData.consumption.ecs.quantity;
                }
                conso += monthConso;
            });

            this.consoData = {
                label: 'Consommation totale du site',
                data: conso,
            };
        } else {
            this.consoData = {
                label: 'Consommation totale du site',
                data: 0,
            };
        }
    }

    /**
     *  Set conso chart data from dju data and month data
     * @param {Dju[]} djuData
     * @param {HeatingMonthlyBill[]} monthData - bills' data aggregated per month
     */
    setConsoLineChartData(djuData: Dju[], monthData: HeatingMonthlyBill[]): void {
        const labels = ['total_conso_heating', 'total_conso_heating_calculated'];
        const labels2 = ['heating_heating', 'heating_ecs'];
        if (djuData) {
            labels.unshift('djus');
            labels2.unshift('djus');
        }
        const configType = djuData ? 'mixed' : 'bar';

        this.consoLineChartProperties.datasets[0] = this.billService.getHeatingTotalMonthData(djuData, monthData);
        this.consoLineChartProperties.datasets[1] = this.billService.getHeatingMonthData(djuData, monthData);
        this.consoLineChartProperties.labels = this.chartService.getMonthsLabel(monthData);

        this.consoLineChartProperties.options = this.chartService.getDatasetsAxesOptions(
            this.consoLineChartProperties.datasets,
            this.chartService.getConfig(configType, {
                tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line'),
            })
        );

        this.consoLineChartProperties.toggleTitles = [
            { name: 'Total', display: true },
            { name: 'Chauffage/ECS', display: true },
        ];
        this.consoLineChartProperties.legend[0] = this.chartService.getChartLegends(labels);
        this.consoLineChartProperties.colors[0] = this.chartService.getBarChartColors(labels);
        this.consoLineChartProperties.legend[1] = this.chartService.getChartLegends(labels2);
        this.consoLineChartProperties.colors[1] = this.chartService.getBarChartColors(labels2);

        if (djuData) {
            this.consoLineChartProperties.toggleTitles.push({ name: 'kWh/dju', display: true });
            const djuDataset = this.billService.getHeatingMonthDataDjuRatio(djuData, monthData);
            this.consoLineChartProperties.datasets[2] = djuDataset;
            this.consoLineChartProperties.options[2] = this.chartService.getSeriesAxesOptions(
                djuDataset,
                this.chartService.getConfig('bar', {
                    tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line'),
                })
            );
            this.consoLineChartProperties.legend[2] = this.chartService.getChartLegends([
                'heating_heating',
                'heating_ecs',
                'total_conso_heating',
            ]);
            this.consoLineChartProperties.colors[2] = this.chartService.getBarChartColors([
                'heating_heating',
                'heating_ecs',
                'total_conso_heating',
            ]);
        }
    }

    /**
     * Populate conso line chart and costs line chart.
     *
     * @param {Dju[]} djuData
     * @param {HeatingMonthlyBill[]} monthData
     */
    handleMonthData(djuData: Dju[], monthData: HeatingMonthlyBill[]): void {
        if (monthData && monthData.length) {
            this.setConsoLineChartData(djuData, monthData);
            this.setCostsLineChartData(monthData);
            this.setMonthlyCostTable(monthData);
        }

        this.loading.chartLine = false;
        this.loading.secondChartLine = false;
        this.loading.monthlyCostTable = false;
    }

    /**
     * Populate ratio table with yearly values over the last 3 years.
     * @param {HeatingYearlyBill[]} yearlyConsumptionWithHisto
     */
    handleRatioTable(yearlyConsumptionWithHisto: HeatingYearlyBill[]): void {
        if (yearlyConsumptionWithHisto && yearlyConsumptionWithHisto.length) {
            this.setRatio(yearlyConsumptionWithHisto);
        }

        this.loading.ratioTable = false;
    }

    /**
     * Populate costs repartition donut
     * @param {HeatingYearlyBill[]} yearlyConsumption
     */
    handleCostsDonut(globalConsumption: HeatingYearlyBill[]): void {
        if (globalConsumption && globalConsumption.length) {
            this.setCostsDoughnut(globalConsumption);
        }
        this.loading.chartDonut = false;
    }

    /**
     * Set cost donut data
     * @param {HeatingYearlyBill[]} yearlyData
     */
    setCostsDoughnut(yearlyData: HeatingYearlyBill[]): void {
        const sum = {
            consumption: 0,
            subscription: 0,
            regulation: 0,
            miscellaneous: 0,
        };

        yearlyData.forEach(dataPerYear => {
            const hasTotalCons = dataPerYear.consumption.total.amount || dataPerYear.consumption.total.quantity;
            sum.consumption += hasTotalCons
                ? dataPerYear.consumption.total.amount
                : dataPerYear.consumption.heating.amount + dataPerYear.consumption.ecs.amount;

            const hasTotalSub = Boolean(dataPerYear.subscription.total.amount);
            sum.subscription += hasTotalSub
                ? dataPerYear.subscription.total.amount
                : dataPerYear.subscription.heating.amount + dataPerYear.subscription.ecs.amount;

            const hasTotalReg = dataPerYear.regulation.total.amount || dataPerYear.regulation.total.quantity;
            sum.regulation += hasTotalReg
                ? dataPerYear.regulation.total.amount
                : dataPerYear.regulation.heating.amount + dataPerYear.regulation.ecs.amount;
            sum.miscellaneous +=
                dataPerYear.miscellaneous.telegestion.amount + dataPerYear.miscellaneous.gestion.amount;
        });

        this.doughnutCostsProperties = {
            options: [],
            legend: [],
            title: 'Répartition des coûts',
        };
        this.doughnutCostsProperties.legend = this.chartService.getChartLegends([
            'heating_consumption',
            'heating_subscription',
            'heating_miscellaneous',
        ]);
        const dataForChart = [
            Math.round((sum.consumption + sum.regulation) * 100) / 100,
            Math.round(sum.subscription * 100) / 100,
            Math.round(sum.miscellaneous * 100) / 100,
        ];
        this.doughnutCostsProperties.options = this.chartService.getHighchartDonutConfig(
            dataForChart,
            ['heating_consumption', 'heating_subscription', 'heating_miscellaneous'],
            '€'
        );
    }

    /**
     * Return true if has data for the cost donut, false otherwise
     * @return {boolean}
     */
    public hasDataForCostDoughnut(): boolean {
        try {
            const chartOptions = this.doughnutCostsProperties.options;
            return Boolean(
                chartOptions &&
                    chartOptions.series[0].data &&
                    chartOptions.series[0].data[0] &&
                    chartOptions.series[0].data[1] &&
                    chartOptions.series[0].data[2] &&
                    (chartOptions.series[0].data[0].y > 0 ||
                        chartOptions.series[0].data[1].y > 0 ||
                        chartOptions.series[0].data[2].y > 0)
            );
        } catch (err) {
            return false;
        }
    }

    /**
     * Set costs chart data
     * @param monthDatas
     */
    setCostsLineChartData(monthDatas: HeatingMonthlyBill[]) {
        const arrayData = [];
        const monthLabels = [];

        monthDatas.forEach(x => {
            let cost = x.consumption.total.amount;
            if (!cost && (x.consumption.heating.amount || x.consumption.ecs.amount)) {
                cost = x.consumption.heating.amount + x.consumption.ecs.amount;
            }
            arrayData.push(cost);
            monthLabels.push(
                moment()
                    .month(x.month)
                    .year(x.year)
                    .format('MM/YYYY')
            );
        });

        this.costData = [
            {
                label: 'Total des coûts',
                data: arrayData,
            },
        ];
        // [0] : total
        // [1] : chauffage/ECS
        const totalDataset = this.billService.getHeatingTotalMonthData(null, monthDatas, true);
        const diffDataset = this.billService.getHeatingMonthData(null, monthDatas, true);
        this.costsLineChartProperties.datasets = [totalDataset, diffDataset];

        this.costsLineChartProperties.labels = this.chartService.getMonthsLabel(monthDatas);

        this.costsLineChartProperties.options = this.chartService.getDatasetsAxesOptions(
            this.costsLineChartProperties.datasets,
            this.chartService.getConfig('bar', {
                tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line', '€'),
            })
        );

        this.costsLineChartProperties.toggleTitles = [
            { name: 'Total', display: true },
            { name: 'Chauffage/ECS', display: true },
        ];
        this.costsLineChartProperties.legend[0] = this.chartService.getChartLegends([
            'total_cost_heating',
            'total_cost_heating_calculated',
        ]);
        this.costsLineChartProperties.colors[0] = this.chartService.getBarChartColors([
            'total_cost_heating',
            'total_cost_heating_calculated',
        ]);
        this.costsLineChartProperties.legend[1] = this.chartService.getChartLegends(['heating_heating', 'heating_ecs']);
        this.costsLineChartProperties.colors[1] = this.chartService.getBarChartColors([
            'heating_heating',
            'heating_ecs',
        ]);
    }

    /**
     * Create monthly cost table from bills' data aggregated per month
     * @param {HeatingMonthlyBill[]} monthData
     */
    setMonthlyCostTable(monthData: HeatingMonthlyBill[]): void {
        this.monthlyCostTable = [];

        monthData.forEach(md => {
            const consumptionTotalAmount = md.consumption.total.amount
                ? md.consumption.total.amount
                : md.consumption.heating.amount + md.consumption.ecs.amount;

            const consumptionTotalQty = md.consumption.total.quantity
                ? md.consumption.total.quantity
                : md.consumption.heating.quantity + md.consumption.ecs.quantity;

            const subscriptionTotalAmount = md.subscription.total.amount
                ? md.subscription.total.amount
                : md.subscription.heating.amount + md.subscription.ecs.amount;

            this.monthlyCostTable.push({
                periodLabel: moment()
                    .month(md.month)
                    .year(md.year)
                    .format('MM/YYYY'),
                consumption: {
                    heatingAmount: this.formatNumberDefaultToNC(md.consumption.heating.amount, 'money'),
                    ecsAmount: this.formatNumberDefaultToNC(md.consumption.ecs.amount, 'money'),
                    totalAmount: this.formatNumberDefaultToNC(consumptionTotalAmount, 'money'),
                    heatingQty: this.formatNumberDefaultToNC(md.consumption.heating.quantity),
                    ecsQuantity: this.formatNumberDefaultToNC(md.consumption.ecs.quantity),
                    totalQuantity: this.formatNumberDefaultToNC(consumptionTotalQty),
                },
                subscription: {
                    heating: this.formatNumberDefaultToNC(md.subscription.heating.amount, 'money'),
                    ecs: this.formatNumberDefaultToNC(md.subscription.ecs.amount, 'money'),
                    total: this.formatNumberDefaultToNC(subscriptionTotalAmount, 'money'),
                },
                miscellaneous: {
                    telegestion: this.formatNumberDefaultToNC(md.miscellaneous.telegestion.amount, 'money'),
                    gestion: this.formatNumberDefaultToNC(md.miscellaneous.gestion.amount, 'money'),
                    total: this.formatNumberDefaultToNC(
                        md.miscellaneous.telegestion.amount + md.miscellaneous.gestion.amount,
                        'money'
                    ),
                },
                totalHT: { total: this.formatNumberDefaultToNC(md.totalHT.amount, 'money') },
                totalTTC: { total: this.formatNumberDefaultToNC(md.totalTTC.amount, 'money') },
            });
        });
    }

    formatNumberDefaultToNC(value, type = 'fluid') {
        return this.utilsService.formateNumberType(value, 'NC', type);
    }

    siteLoaded() {
        return this.data && this.data._id && !this.loading.site;
    }

    canShowSecondLineChart(): boolean {
        return Boolean(!this.loading.secondChartLine && this.costsLineChartProperties.datasets.length);
    }

    canShowLineChart(): boolean {
        return Boolean(!this.loading.chartLine && this.consoLineChartProperties.datasets.length);
    }

    canShowDataPerMonth(): boolean {
        return Boolean(!this.loading.monthlyCostTable && this.monthlyCostTable);
    }

    canShowRatioTable(): boolean {
        return Boolean(!this.loading.ratioTable && this.ratioTable.length);
    }

    getExportExcelUrl(type) {
        return this.pdlService.getExportExcelUrl(
            type,
            this.periodSelected,
            this.routingRef,
            this.contract,
            this.dju.isDjuActivated,
            'heating'
        );
    }

    getExportName(txt) {
        return this.pdlService.getExportName(txt, this.periodSelected, this.data, this.selectedContract);
    }

    hasSite(): boolean {
        return Boolean(this.site);
    }
}
