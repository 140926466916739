import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginationService } from 'app/shared/components/pagination/front/pagination-front.service';
import { PaginationIndexes } from 'app/shared/components/pagination/pagination.interface';
import { ContractUploadsGroup, ForceType } from 'app/shared/models/upload-file.interface';

@Component({
    selector: 'ga-pattern-table',
    templateUrl: './pattern-table.component.html',
    styleUrls: ['./pattern-table.component.scss'],
    providers: [PaginationService],
})
export class PatternTableComponent implements OnInit {
    @Input() dataArray: ContractUploadsGroup[];
    @Output() selectElement = new EventEmitter();
    @Output() extractClick = new EventEmitter();
    /**
     * Triggered when force extract clicked.
     * Value is the index of contract group to force.
     */
    @Output() forceExtractClick: EventEmitter<number> = new EventEmitter<number>();
    @Input() currentLineSelected = 0;
    @Input() disableActions = false;

    /** List of contract types to display */
    public contractTypes: ContractUploadsGroup[] = [];

    constructor(private paginationService: PaginationService) {}
    ngOnInit() {}

    public selectContractType(index: number) {
        index = this.getIndexPaginated(index);
        this.currentLineSelected = index;
        this.selectElement.emit(index);
    }

    public extractContractType(index: number) {
        index = this.getIndexPaginated(index);
        this.extractClick.emit(index);
    }

    public forceExtractContractType(index: number) {
        index = this.getIndexPaginated(index);
        this.forceExtractClick.emit(index);
    }

    public trackByFn(index: any, item: any) {
        return item.id;
    }

    public getLineActiveClass(index: number): string {
        index = this.getIndexPaginated(index);
        return index === this.currentLineSelected ? 'line-active' : '';
    }

    /**
     * Get index considering pagination.
     * Eg. Index of 3rd item of 2nd page is 5 (not 2)
     * @param {number} index
     * @returns {number}
     */
    private getIndexPaginated(index: number): number {
        return this.paginationService.pagination.indexStart + index;
    }

    public getProviderName(contract: ContractUploadsGroup) {
        return contract.provider && contract.provider.name ? contract.provider.name.toUpperCase() : '';
    }

    public getContractTypeName(contract: ContractUploadsGroup) {
        return contract.contractType && contract.contractType.name ? contract.contractType.name : '';
    }

    public updateContractTypes(indexes: PaginationIndexes) {
        this.contractTypes = this.dataArray.slice(indexes.indexStart, indexes.indexEnd + 1);
    }

    public canForceQuality(forceType: string): boolean {
        return forceType === ForceType.QUALITY;
    }
}
