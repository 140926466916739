// tslint:disable:no-access-missing-member
import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    OnInit,
    QueryList,
    TemplateRef,
    Type,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Question } from '../../../../models/question.interface';
import { QuestionService } from '../../../../services/sites/question.service';
import { FileQuestionComponent } from '../file-upload/file-upload.component';
import { GroupQuestionComponent } from '../group-collapse/group-collapse.component';
import { InputTextMultipleComponent } from '../input-text-multiple/input-text-multiple.component';
import { InputTextComponent } from '../input-text/input-text.component';
import { QuestionComponent } from '../question/question.component';
import { SelectChoicesBlocComponent } from '../select-choices-bloc/select-choices-bloc.component';
import { SelectChoicesInputComponent } from '../select-choices-input/select-choices-input.component';
import { SelectChoicesComponent } from '../select-choices/select-choices.component';
import { SelectDropdownComponent } from '../select-dropdown/select-dropdown.component';

@Component({
    selector: 'ga-question-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
/**
 * Component to create a modal, that then creates questions for the modal.
 * @Input data : User data at the format [{key: string, value: string}]
 * @Input config : Question from the config file with properties attribute at the format {questions: [... any question objects]}
 * @Ouput valueChange : event that gives the data each time it's changed by the user at the format [{key: string, value: string}]
 */
export class ModalQuestionComponent extends QuestionComponent implements OnInit, AfterViewInit {
    // Container to set the questions component in the ng-template places
    rootViewContainer: ViewContainerRef;

    // Values of the questions components
    private values: Array<{ key: string; value: any }> = [];

    // Get all the questions components from the ng-template.s
    @ViewChildren('questionModal', { read: ViewContainerRef }) questionItems: QueryList<ViewContainerRef>;

    modalProgress = 0;

    constructor(
        private factoryResolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef,
        private questionService: QuestionService
    ) {
        super();
    }

    ngOnInit() {}

    /**
     * After view initialized, replace all the ng-template by the questions
     */
    ngAfterViewInit() {
        this.config.properties.questions.forEach((question, index) => {
            const vcr = this.questionItems.toArray()[index];
            this.addQuestion(question, vcr);
        });
        this.cdr.detectChanges();
    }

    /**
     * Create, add a question at the place, then subscribe to any changes of the question value
     * @param config: question config
     * @param vcr: DOM element where the question component will be placed (vcr = ViewContainerRef).
     */
    private addQuestion(config, vcr) {
        this.rootViewContainer = vcr;
        const obsAll = this.createQuestion(this.data, config);
        if (obsAll.change) {
            obsAll.change.subscribe(value => {
                if (Array.isArray(value)) {
                    value.forEach(item => {
                        this.addOrUpdate(item);
                    });
                } else {
                    this.addOrUpdate(value);
                }
                this.updateProgress();
            });
        }
        if (obsAll.fileUpload) {
            obsAll.fileUpload.subscribe(value => {
                this.uploadFile.emit(value);
            });
        }
    }

    /**
     * Create the question element and return the observable for modifications
     * @param data from db
     * @param config: question config
     * @returns {any}: object containing EventEmitters to subscribe for actions on question emit
     */
    createQuestion(data, config) {
        let typeComponent: Type<Question> = null;
        switch (config.type) {
            case 'simple-choices':
                typeComponent = SelectChoicesComponent;
                break;
            case 'simple-choices-bloc':
                typeComponent = SelectChoicesBlocComponent;
                break;
            case 'input-text':
                typeComponent = InputTextComponent;
                break;
            case 'select-dropdown':
                typeComponent = SelectDropdownComponent;
                break;
            case 'input-text-multiple':
                typeComponent = InputTextMultipleComponent;
                break;
            case 'select-choices-input':
                typeComponent = SelectChoicesInputComponent;
                break;
            case 'file':
                typeComponent = FileQuestionComponent;
                break;
            case 'group':
                typeComponent = GroupQuestionComponent;
                break;
        }
        if (typeComponent) {
            return this.questionService.addComponent(
                data,
                config,
                this.siteId,
                this.zoneId,
                typeComponent,
                this.rootViewContainer
            );
        } else {
            return null;
        }
    }

    /**
     * Update the values property by adding it to the list if not existing. Otherwise, update it.
     * @param {{key: string; value: any}} item: value from question
     */
    private addOrUpdate(item: { key: string; value: any }) {
        if (item) {
            const temp = this.values.find(x => x.key === item.key);
            if (!temp) {
                this.values.push(item);
            } else {
                temp.value = item.value;
            }
            this.valueChange.emit(this.values);
        }
    }

    close(modal) {
        modal.hide();
    }

    /**
     * Update modal progression
     */
    updateProgress() {
        const tmpProgress = {
            total: 0,
            filled: 0,
        };
        this.config.properties.questions.forEach(question => {
            this.groupProgress(tmpProgress, question);
        });
        this.modalProgress = (tmpProgress.filled / tmpProgress.total) * 100;
    }

    /**
     * Compute the progress for groups (or not)
     * @param tmpProgress: progress follow-up up object ({total: number, filled: number}).
     * @param info: question config
     */
    private groupProgress(tmpProgress, info) {
        if (info.type === 'group') {
            // For a group, only one question needs to be filled to be considered as completed.
            const grpProgress = { total: 0, filled: 0 };
            info.properties.questions.forEach(q => {
                this.manageProgress(grpProgress, q, true);
            });
            tmpProgress.total += 1;
            tmpProgress.filled += grpProgress.filled > 0 ? 1 : 0;
        } else {
            this.manageProgress(tmpProgress, info);
        }
    }

    /**
     * Compute progress for each "classic" question type.
     * @param tmpProgress: progress follow-up up object ({total: number, filled: number}).
     * @param info: question config
     * @param {boolean} isGroup: is the question part of a group.
     */
    private manageProgress(tmpProgress, info, isGroup = false) {
        // These two types have a key at a different place than others.
        const multipleTypes = ['input-text-multiple', 'select-choices-input'];

        if (multipleTypes.find(x => x === info.type)) {
            if (info.properties && info.properties.values) {
                // Compute the progress for the question that have multiple answers.
                const mtProgress = { total: 0, filled: 0 };
                mtProgress.total += info.properties.values.length;
                info.properties.values.forEach(item => {
                    if (item) {
                        const val = this.values.find(y => y.key === item.key);
                        const filled = val ? (isGroup ? val.value[0] : val.value) : false;
                        mtProgress.filled += filled ? 1 : 0;
                    }
                });
                // For select-choices-input, at least one needs to be filled
                // to be considered as completed.
                if (info.type === 'select-choices-input') {
                    tmpProgress.total++;
                    tmpProgress.filled += mtProgress.filled > 0 ? 1 : 0;
                } else {
                    tmpProgress.total += mtProgress.total;
                    tmpProgress.filled += mtProgress.filled;
                }
            }
        } else {
            const val = this.values.find(y => y.key === info.properties.key);
            const filled = val ? (isGroup ? val.value[0] : val.value) : false;
            tmpProgress.filled += filled ? 1 : 0;
            tmpProgress.total++;
        }
    }
}
