import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

// interfaces
import { FiltersAvailable, Option, QueryFilter } from 'app/shared/models/bills-contracts-filter.interface';
import { BillPopulated } from 'app/shared/models/bills.interface';
import { Pagination, QueryPagination } from 'app/shared/models/pagination.interface';

// services
import { PaginationBackService } from 'app/shared/components/pagination/back/pagination-back.service';
import { BillsListService } from 'app/shared/services/bills-list/bills-list.service';
import { BillsVerificationsService } from 'app/shared/services/bills/bills-verifications.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { SessionService } from 'app/shared/services/session/session.service';

@Component({
    selector: 'ga-bills',
    templateUrl: './bills.component.html',
    styleUrls: ['./bills.component.scss'],
    providers: [PaginationBackService],
})
export class BillsComponent implements OnInit {
    /**
     * Bills to display
     */
    public bills: BillPopulated[] = [];

    /**
     * Is currenlty loading bills
     */
    public isLoadingBills = true;

    /**
     * Pagination
     */
    private pagination: Pagination = {
        lastItem: {
            _id: null,
            index: 0,
        },
    };

    /**
     * Filters configuration
     */
    public filtersAvailable: FiltersAvailable;

    /**
     * Is currenlty loading filter values
     */
    public isLoadingFilters = true;

    /**
     * Filter query built from the selected filter values
     */
    public filters: QueryFilter;

    /**
     * @type {boolean} - isBranchesLoaded is used to set filters available values from requested company and its branches
     */
    private isBranchesLoaded = false;

    constructor(
        private energyService: EnergyService,
        private filterService: FilterService,
        private sessionService: SessionService,
        private paginationService: PaginationBackService,
        private billsListService: BillsListService,
        private billsVerificationsService: BillsVerificationsService
    ) {}

    ngOnInit(): void {
        this.initData();
    }

    async initData() {
        try {
            await this.initFilters();
        } catch (err) {
            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des factures. Veuillez réessayer ultérieurement.',
                'warning'
            );
            throw JSON.stringify(err);
        }
    }

    /**
     * Set the list of bills displayed, using filters & pagination.
     * @return {Promise<void>}
     */
    async setBills(): Promise<void> {
        try {
            // set loading and empty pagination during loading
            this.isLoadingBills = true;

            const result = await this.getBills();

            initCollapse(result.results);
            this.bills = result.results;

            // update pagination with the new total number of elements
            this.paginationService.updateLastItem(this.pagination.lastItem, this.bills);
            this.paginationService.updateAfterSearch(result.total);

            // stop loading
            this.isLoadingBills = false;
        } catch (e) {
            this.bills = [];

            // reset pagination to first page for an empty array
            this.paginationService.emptyPagination();
            this.isLoadingBills = false;

            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des factures. Veuillez réessayer ultérieurement.',
                'warning'
            );
            throw JSON.stringify(e);
        }

        function initCollapse(billsUploads: BillPopulated[]) {
            billsUploads.forEach((bill: BillPopulated) => {
                bill.isExpanded = false;
            });
        }
    }

    /**
     * Get data to bills uploads to display for filters and pagination.
     */
    async getBills(): Promise<{
        results: BillPopulated[];
        total: number;
    }> {
        const queryPagination: QueryPagination = this.paginationService.getPaginationQuery(this.pagination.lastItem);
        const result = await this.billsListService.getUploadsBillsPopulated(this.filters, queryPagination);
        this.billsListService.formatBillsUpload(result.results);
        return result;
    }

    /**
     * Called when pagination is updated (selected page or nb of items per pages).
     * Load the updated bills.
     */
    async onPaginationChanged(event = { resetLastItem: false }) {
        if (event.resetLastItem) {
            this.paginationService.updateLastItem(this.pagination.lastItem, []);
        }
        await this.setBills();
    }

    /**
     *  ************ FILTERS ************
     */

    async initFilters() {
        await this.setFilters();
        this.isLoadingFilters = false;
    }

    /**
     * Called when filter has changed. Fill the table with new values for the new filters.
     * @param {{start: string; end: string; query: string, fluids?: string[], providers: string[]}} filters
     * @return {Promise<void>}
     */
    async onFilterChanged(filters: QueryFilter): Promise<void> {
        this.filters = filters;

        const loadBranches = _.get(this.filters, 'includeBranches', false);
        if ((loadBranches && !this.isBranchesLoaded) || (!loadBranches && this.isBranchesLoaded)) {
            this.isBranchesLoaded = !this.isBranchesLoaded;
            await this.setFilters({ loadBranches });
        }

        // reset pagination to first page
        this.paginationService.setFirstPage();
        // Reset last item
        this.pagination.lastItem = { _id: null, index: 0 };

        // get data for new filter values
        await this.setBills();
    }

    /**
     * set filters
     * @param {{loadBranches: boolean}} param
     */
    async setFilters(param: { loadBranches: boolean } = { loadBranches: false }) {
        const currentCompany = this.sessionService.getCompany();

        const [fluids, years, providers] = await Promise.all([
            this.energyService.getFluidsAvailable(currentCompany._id, param),
            this.filterService.getYearsWithData(true, param),
            this.filterService.getContractProviders(currentCompany._id, param),
        ]);

        let yearsMin = null;
        let yearsMax = null;
        if (years && years.length) {
            yearsMin = years[0].value;
            yearsMax = years[years.length - 1].value;
        } else {
            const currentYear = new Date().getFullYear();
            yearsMin = currentYear.toFixed(0);
            yearsMax = currentYear.toFixed(0);
        }

        // set selected fluids compared to available fluids
        if (this.filters && this.filters.fluids) {
            this.filters.fluids = _.intersection(this.filters.fluids, fluids);
        }

        // set selected providers compared to available providers
        if (this.filters && this.filters.providers) {
            this.filters.providers = _.intersection(this.filters.providers, providers.data);
        }

        this.filtersAvailable = {
            searchValue: {
                enabled: true,
                placeholder: 'N° de facture / n° de PDL / immatriculation',
            },
            years: {
                enabled: true,
                yearsMin,
                yearsMax,
                setDefault: true,
                allowMissingBound: false,
            },
            fluids: {
                enabled: true,
                values: fluids,
            },
            providers: {
                enabled: true,
                values: providers.data,
            },
            branches: {
                enabled: true,
            },
            failedVerifications: {
                label: 'extraction_verifications',
                placeholder: 'Type de contrôles',
                enabled: true,
                values: this.getFormatedExtractionVerifications(),
                tooltip:
                    'Ce filtre permet d’afficher uniquement les factures présentant une erreur sur l’un des types de contrôles sélectionnés',
            },
        };
    }

    /**
     * get extraction verifications - grouped by category - and format them to display as select options groups in a filter
     * @returns {{[group: string]: Option[]}} An array of select options grouped by category
     */
    getFormatedExtractionVerifications(): { [group: string]: Option[] } {
        // Fetch verificationConfigs grouped by verification group
        const groupedConfigs = this.billsVerificationsService.getGroupedVerificationConfigs();
        const formattedVerifications: { [group: string]: Option[] } = {};

        // Iterating over each verification config to format it as a select Option
        Object.keys(groupedConfigs).forEach(groupName => {
            formattedVerifications[groupName] = groupedConfigs[groupName].map(config => {
                return {
                    value: config.key,
                    name: config.message,
                };
            });
        });

        return formattedVerifications;
    }
}
