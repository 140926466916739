import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

/* Exemple de data

const allData = {
    lineChartData: [
        {data: [36, 36, 36, 36], label: 'power_subscribe'},
        {data: [28, 35, 26, 33], label: 'power_max'},
    ],
    lineChartColors: [
        {
            backgroundColor: "rgba(223,124,58, 1)",
            borderColor: "rgba(223,124,58, 1)",
            fill: false,
            pointBackgroundColor: "rgba(223,124,58, 1)",
            pointBorderColor: "rgba(223,124,58, 1)",
            pointHoverBackgroundColor: "rgba(223,124,58, 1)",
            pointHoverBorderColor: "rgba(223,124,58, 0.8)",
        },
        {
            backgroundColor: "rgba(223,124,58, 1)",
            borderColor: "rgba(223,124,58, 1)",
            fill: false,
            pointBackgroundColor: "rgba(223,124,58, 1)",
            pointBorderColor: "rgba(223,124,58, 1)",
            pointHoverBackgroundColor: "rgba(223,124,58, 1)",
            pointHoverBorderColor: "rgba(223,124,58, 0.8)",
        },
    ],
    lineChartLabels: [
        "HPH",
        "HCH",
        "HPE",
        "HCE"
    ],
    lineChartOptions: {
        responsive: true,
        legend: {
            display: false
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 24,
                bottom: 24
            },
        },
        scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'kVA'
                },
                gridLines: {
                    display: true,
                    borderDashOffset: 2,
                    borderDash: [2],
                    lineHeight: 5,
                    drawBorder: false,
                    drawTicks: false,
                },
                ticks: {
                    min: 0,
                    max: 40
                }
            }],
            xAxes: [{
                ticks: {
                    display: false
                },
                gridLines: {
                    display: true,
                    borderDashOffset: 2,
                    borderDash: [2],
                    lineHeight: 5,
                    drawBorder: false,
                    drawTicks: false,
                }
            }]
        },
        tooltips: {
            yPadding: 10,
            xPadding: 10,
            caretPadding: 26,
            callbacks: {
                title(tooltipItem, data) {
                    const index = tooltipItem[0].index;
                    return [data.labels[index]]
                },
                footer(tooltipItem, data) {
                    return [tooltipItem[0].yLabel + ' kVA']
                },
                label(tooltipItem, data) {
                    return '';
                }
            }
        },
    },
    lineChartLegend: [
        {name: "Puissance souscrite", color: "rgb(223,124,58)"},
        {name: "Puissance maximale atteinte", color: "rgb(253,204,45)"}
    ],
    lineChartWidth: 900,
    lineChartHeight: 400,
};

 */

@Component({
    selector: 'ng2-charts-line',
    templateUrl: './ng2-charts-line.component.html',
})
export class Ng2LineChartComponent implements OnInit {
    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

    @Input() lineChartData: any[];
    @Input() lineChartDataset: any[];
    @Input() lineChartColors: any[];
    @Input() lineChartLabels: string[];
    @Input() lineChartWidth = 900;
    @Input() lineChartMinHeight: number;
    @Input() lineChartMaxHeight: number;
    @Input() lineChartLegend: any;
    @Input() lineChartOptions: any = {};

    @Output() chartInit: EventEmitter<object> = new EventEmitter<object>();
    @Output() emitChartClick = new EventEmitter();

    public lineChartType = 'line';

    public constructor() {}

    ngOnInit() {
        this.chartInit.emit(this.chart);
    }

    // events
    public chartClicked(e: any): void {
        this.emitChartClick.emit(e);
    }

    public chartHovered(e: any): void {}

    public setMinHeight() {
        return this.lineChartMinHeight ? this.lineChartMinHeight + 'px' : 'none';
    }
    public setMaxHeight() {
        return this.lineChartMaxHeight ? this.lineChartMaxHeight + 'px' : 'none';
    }
}
