import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';

// interfaces
import { OptionValue } from 'app/shared/models/filter-config.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { FilterAlias } from 'app/shared/services/filter/filter.interface';
import { Filter, FiltersOptions, OptionValueGroup, QueryFilterRoutingRef } from '../scope-filter.interface';

// components
import { ScopeFilterComponent } from '../scope-filter.component';

// services
import { ScopeFilterService } from '../scope-filter.service';

export interface RoutingReferenceOption extends RoutingReference {
    siteId: string;
    zoneId?: string;
    displayName: string;
    idCompare: string;
}

export interface FilterRoutingRef extends Filter<RoutingReferenceOption> {
    energies: OptionValue[];
    categories: OptionValue[];
    zones: string[];
}

interface FiltersOptionsRoutingRef extends FiltersOptions<RoutingReferenceOption> {
    categories: OptionValue[];
}

@Component({
    selector: 'ga-routing-reference-scope-filter',
    templateUrl: '../scope-filter.component.html',
    styleUrls: ['../scope-filter.component.scss'],
    providers: [ScopeFilterService],
})
export class RoutingReferenceScopeFilterComponent
    extends ScopeFilterComponent<RoutingReference, QueryFilterRoutingRef, FilterRoutingRef>
    implements OnInit {
    /**
     * @override
     * Name of the entity expected inside the query.
     */
    entityFilterName = 'routingReferences';

    /**
     * @override
     * Filter alias for URL of property
     */
    entityPropertyAlias: FilterAlias = 'routingReferencesItems';

    /**
     * ------
     * Fluid types
     * ------
     */

    /**
     * @override
     * True when a conversion is processed if multiple fuel types are selected.
     */
    hasConversionOnMultipleFluids = true;

    /**
     * @override
     * Error message to display when zero enerrgy is selected
     */
    missingFluidTypeMessage = 'Vous devez sélectionner au moins une énergie';

    /**
     * @override
     * Message explaning the necessity for a conversion when multiple energies are used
     * */
    multipleFluidTypesMessage =
        'Afin de comparer deux fluides ayant une unité usuelle différente, nous convertissons les mesures en kWhep (énergie primaire)';

    /**
     * @override
     * Name to translate and display above the filter of available fluids */
    fluidTypesName = 'energies';

    /**
     * -------
     * Top filter
     * -------
     */

    /**
     * @override
     * Elements for the top filter part. Name and value of each filter type. */
    topFilterElements: OptionValueGroup[] = [
        {
            displayName: 'Tous les sites',
            value: 'all',
        },
        {
            displayName: 'Par site ',
            totalGroupName: 'Total du site',
            value: 'sites',
            tags: {
                textProperty: 'complement',
                subTextProperty: 'googleAddress',
                idProperty: '_id',
            },
        },
        {
            displayName: 'Par région',
            totalGroupName: 'Total de la région',
            value: 'regions',
            tags: {
                textProperty: 'displayName',
                idProperty: '_id',
            },
        },
        {
            displayName: 'Par catégorie de sites',
            totalGroupName: 'Total de la catégorie',
            value: 'categories',
            tags: {
                textProperty: 'displayName',
            },
        },
        {
            displayName: 'Par numéro de PDL',
            totalGroupName: 'Total du PDL',
            value: this.entityFilterName,
            tags: {
                textProperty: 'displayName',
                idProperty: 'idCompare',
            },
        },
        {
            displayName: 'Personnalisé',
            totalGroupName: 'Personnalisé',
            value: 'custom-filters',
        },
    ];

    /** @override
     * Options available for each top filter element. */
    filtersOptions: FiltersOptionsRoutingRef = {
        sites: [],
        entities: [],
        categories: [],
        regions: [],
    };

    /**
     * ------
     * Global filter
     * ------
     */

    /** Filter holding the selected values */
    filter: FilterRoutingRef;

    /**
     * @override
     * List of permitted url params for RRef
     */
    permittedParams = ['le', 'year', 'ds', 'de', 's', 'a', 'c', 'p', 'ct', 'e', 'r', 'cf'];

    /**
     * @override
     * Fluids selected inside the filter. Property depends on the entity. */
    getFilterFluidTypes(): OptionValue[] {
        return this.filter.energies;
    }

    /**
     * @override
     * Fluid params to get availble years
     */
    get fluidYearsParams(): Params {
        return { fluids: this.fluidTypesAccepted };
    }

    /**
     * @override
     * Reset the filter
     * */
    resetFilter(): void {
        this.filter = {
            sites: [],
            energies: [],
            years: {
                dateStart: '',
                dateEnd: '',
            },
            year: '',
            regions: [],
            categories: [],
            companies: [],
            entities: [],
            zones: [],
            customFilter: null,
        };
    }

    /**
     * @override
     * Get perimeter displayed name, depending on the selected filter "sites" or "routing references"
     * @param {OptionValueGroup} perimeter - perimeter to get the display name of
     * @return {string} full display name of the perimeter
     */
    getPerimeterDisplayName(perimeter: OptionValueGroup): string {
        if (perimeter.value === 'all') {
            return perimeter.displayName;
        }

        if (this.mainFilterSelected !== 'routingReferences') {
            return `Sites ${perimeter.displayName}`;
        }
        return `PDLs ${perimeter.displayName}`;
    }

    /**
     * ------
     * INIT ROUTING REFERENCES DATA
     * ------
     */

    /**
     * @override
     * Init the fluids: must be in the selected companies' contracts + available for this page (energies VS water).
     * Select all fluids by default.
     * @param {Params} params
     */
    async initSelectedItems(selectedItems: string[] = []): Promise<void> {
        // Fluids category prevails on the given accepted fluids to set the accepted fluids
        if (this.fluidsCategory) {
            this.fluidTypesAccepted = this.energyService.getFluidsByCategory(this.fluidsCategory);
        }

        // Existing fluids in the selected companies' contracts
        const fluidsFromCompanies = await this.energyService.getFluidsAvailableForCompanies(this.filter.companies);

        // Display energies from given companies, included in the accepted energies for the current page.
        // Select all or select the ones on the url params
        const isSingleSelection =
            this.filtersConfig &&
            this.filtersConfig.toggleButtonSelection &&
            this.filtersConfig.toggleButtonSelection.enabled &&
            this.filtersConfig.toggleButtonSelection.selectionType === 'single';
        this.filter.energies = fluidsFromCompanies
            .filter(energy => this.fluidTypesAccepted.includes(energy))
            .map((energy, i) => {
                const energyOption = {
                    value: energy,
                    displayName: this.energyService.energyFullText(energy),
                    selected: false,
                };
                if (isSingleSelection) {
                    // If single selection, only select first item
                    const itemIndex = selectedItems.length ? selectedItems.indexOf(energyOption.value) : i;
                    energyOption.selected = itemIndex === 0;
                    return energyOption;
                }
                energyOption.selected = selectedItems.length ? selectedItems.indexOf(energyOption.value) >= 0 : true;
                return energyOption;
            });
    }

    /**
     * @override
     * Get sites from companies matching the selected energies
     * Init filter with these values, with clean google address displayed
     */
    async initSites(): Promise<void> {
        // get sites for companies
        const sites = await this.sitesService.getSites({
            companies: this.filter.companies.join(','),
        });
        this.filtersOptions.sites = sites.data;

        this.sitesService.createGoogleAddressToDisplay(this.filtersOptions.sites);
    }

    /**
     * @override
     * Initialize all filters elements : routing references, sites' categories
     */
    async initEntitiesData(): Promise<void> {
        await Promise.all([this.initRoutingReferences(), this.initCategories()]);
    }

    /**
     * Initialize the list of routing references from the populated sites:
     * for each site, add all its routing references and each association routing reference / zone
     */
    initRoutingReferences(): void {
        try {
            const routingReferences = [];
            this.filtersOptions.sites.forEach(site => {
                // Add all PDLs of the site
                site.routingReferences.forEach(routingReference => {
                    if (this.fluidTypesAccepted.includes(routingReference.energyType)) {
                        const formattedPdl = Object.assign({}, routingReference, {
                            siteId: site._id,
                            displayName: `${routingReference.reference} - ${site.complement}`,
                            idCompare: `${routingReference._id}-${site._id}`,
                        });
                        routingReferences.push(formattedPdl);
                    }
                });

                // Add associations PDL-Zone
                if (site.info.diviser) {
                    // For each zone
                    site.info.batiments.forEach(zone => {
                        // For each PDL of the zone
                        zone.routingReferences.forEach(routingReference => {
                            // Search the PDL object in the site, as zones only contains ID lists
                            const search = site.routingReferences.find(x => x._id === routingReference);
                            // Add the association if the PDL's energy is accepted
                            if (search && this.fluidTypesAccepted.includes(search.energyType)) {
                                const formattedPdl = Object.assign({}, search, {
                                    siteId: site._id,
                                    zoneId: zone._id,
                                    displayName: `${search.reference} - ${zone.name} > ${site.complement}`,
                                    idCompare: `${search._id}-${zone._id}-${site._id}`,
                                });
                                routingReferences.push(formattedPdl);
                            }
                        });
                    });
                }
            });

            // Sort by routing references alphabetical order
            routingReferences.sort((a, b) => {
                if (a.reference < b.reference) {
                    return -1;
                }
                if (a.reference > b.reference) {
                    return 1;
                }
                return 0;
            });

            this.filtersOptions.entities = routingReferences;
        } catch (error) {
            this.filtersOptions.entities = [];
        }
    }

    /**
     * Initialize the sites's categories, formated for autocompletion inputs.
     */
    async initCategories(): Promise<void> {
        try {
            const categories = await this.filterService.getCategoriesForAutoCompletionNewFilter(true, {
                companies: this.filter.companies,
            });
            this.filtersOptions.categories = categories.data;
        } catch (error) {
            this.filtersOptions.categories = [];
        }
    }

    /**
     * ------
     *  COMPONENT INTERACTIONS
     * ------
     */

    /**
     * @override
     * Handle params when present.
     * Preselect the site from params.
     * @param {Param} params - router params
     */
    initComponentSetup(params: Params): void {
        this.initComponentCommonSetup(params);

        // Energy settings.
        const energies = this.filterService.getParamAliasValues(params, 'energies', this.fluidTypesAccepted);
        this.updateItemsWithTypeFilter('energies', energies);

        // RRef
        this.updateFilterByParam(params, 'routingreferences', 'routingReferences', 'entities', 'entities', '_id');

        // Categories
        this.updateFilterByParam(params, 'categories', 'categories', 'categories', 'categories', 'value');

        // Regions
        this.updateFilterByParam(params, 'regions', 'regions', 'regions', 'regions', 'value');

        // Sites
        this.updateFilterByParam(params, 'sites', 'sites', 'sites', 'sites', '_id');
    }

    /**
     * @override
     * Create the query from the selected elements inside the filter
     * @returns {QueryFilterRoutingRef} query created
     */
    createQuery(): QueryFilterRoutingRef {
        // empty query filter
        const filter: QueryFilterRoutingRef = this.resetQueryFilter();

        // set dates
        this.setFilterDates(filter);

        // set energies
        const energiesValues = this.filter.energies.filter(energy => energy.selected).map(energy => energy.value);
        filter.energies = energiesValues.join(',');

        // set sites perimeter
        if (!this.disablePerimeter && this.mainFilterSelected !== 'routingreferences') {
            const selectedPerimeter = this.perimeterFilterElements.find(x => x.selected === true);
            if (selectedPerimeter) {
                filter.sitesStatus = selectedPerimeter.value;
            }
        }

        // set the query for the current top filter element selected
        switch (this.mainFilterSelected) {
            case 'regions':
                this.mainFilterSelectedValues = this.filter.regions;
                filter.regions = this.filter.regions.map(region => region.value).join(',');
                break;

            case 'sites':
                this.mainFilterSelectedValues = this.filter.sites;
                filter.sites = this.filter.sites.map(site => site._id).join(',');
                break;

            case 'categories':
                this.mainFilterSelectedValues = this.filter.categories;
                filter.categories = this.filter.categories.map(cat => cat.value).join(',');
                break;

            case 'routingReferences':
                const routingReferencesIds = this.filter.entities
                    .map(x => {
                        if (x.siteId && x.zoneId) {
                            return `${x._id}-${x.siteId}-${x.zoneId}`;
                        }
                        return x._id;
                    })
                    .join(',');

                this.mainFilterSelectedValues = this.filter.entities;
                filter.routingreferences = routingReferencesIds;

                if (!this.disablePerimeter) {
                    const selectedPerimeter = this.perimeterFilterElements.find(x => x.selected === true);
                    if (selectedPerimeter) {
                        filter.routingReferencesStatus = selectedPerimeter.value;
                    }
                }
                break;

            case 'custom-filters':
                if (this.filter.customFilter) {
                    this.mainFilterSelectedValues = [this.filter.customFilter];
                }

                filter.customFilter =
                    (this.filter.customFilter && this.filter.customFilter.value) || this.customFilterId || null;

                break;

            case 'all':
            default:
                this.mainFilterSelected = 'all';
                this.mainFilterSelectedValues = [this.topFilterElements.find(elem => elem.value === 'all')];

                break;
        }

        this.filterChange.emit(filter);
        return filter;
    }

    /**
     * Reset the query emited to the parent page
     * @returns {QueryFilterRoutingRef} empty query with only companies ids set
     */
    resetQueryFilter(): QueryFilterRoutingRef {
        return {
            dateStart: '',
            dateEnd: '',
            energies: '',
            sites: '',
            regions: '',
            companies: this.filter.companies ? this.filter.companies.join(',') : '',
            sitesStatus: 'all',
            customFilter: '',
            categories: '',
            routingreferences: '',
            routingReferencesStatus: 'all',
            entities: '',
        };
    }
}
