import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { DateRange, DateRangeLimit } from 'app/shared/components/common/date-range-picker/date-range-picker.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';

import { ExclusionTypes, Monitoring } from '../../operating-monitoring.interface';
import { OperatingMonitoringService } from '../../operating-monitoring.service';
import { ContractDataFieldKey, FieldConfig } from '../monitoring-edition.interface';
import { MonitoringEditionService } from '../monitoring-edition.service';
import { MonitoringCostsService } from './monitoring-costs.service';

/**
 * Monitoring creation contractual data management
 */
@Component({
    selector: 'ga-monitoring-edition-contract',
    templateUrl: './monitoring-edition-contract.component.html',
    styleUrls: ['../monitoring-edition.component.scss', './monitoring-edition-contract.component.scss'],
    providers: [MonitoringCostsService],
})
export class MonitoringEditionContractComponent implements OnInit, OnDestroy {
    /**
     * Fields config
     */
    private configs: Array<FieldConfig<ContractDataFieldKey>> = [
        {
            key: 'contractStart',
            displayKey: '',
            deps: [],
            defaultValue: null,
            required: true,
        },
        {
            key: 'contractEnd',
            displayKey: '',
            deps: [],
            defaultValue: null,
            required: true,
        },
        {
            key: 'perfFormulaGradient',
            displayKey: '',
            deps: [{ key: 'ecsUse', value: true }, { key: 'linearModeling', value: true }],
            defaultValue: null,
            // disabled: true,
            min: 0,
            required: true,
        },
        {
            key: 'perfFormulaYIntercept',
            displayKey: '',
            deps: [{ key: 'ecsUse', value: true }, { key: 'linearModeling', value: true }],
            defaultValue: null,
            // disabled: true,
            min: 0,
            required: true,
        },
        {
            key: 'monitoringDJUs',
            displayKey: 'djus_short',
            placeholder: 'djus_short',
            deps: [],
            defaultValue: null,
            min: 0,
            required: true,
        },
        {
            key: 'monitoringNB',
            displayKey: 'monitoring_nb',
            placeholder: 'kwh',
            deps: [],
            defaultValue: null,
            min: 0,
            required: true,
        },
        {
            key: 'qHighSeason',
            displayKey: 'monitoring_q_highSeason',
            placeholder: 'kwh_per_m3',
            deps: [
                { key: 'ecsUse', value: true },
                { key: 'linearModeling', value: false },
                { key: 'subtractECS', value: true },
            ],
            defaultValue: null,
            required: true,
        },
        {
            key: 'qLowSeason',
            displayKey: 'monitoring_q_lowSeason',
            placeholder: 'kwh_per_m3',
            deps: [
                { key: 'ecsUse', value: true },
                { key: 'linearModeling', value: false },
                { key: 'subtractECS', value: true },
            ],
            defaultValue: null,
        },
        {
            key: 'nHousing',
            displayKey: 'monitoring_n_housing',
            deps: [
                { key: 'ecsUse', value: true },
                { key: 'linearModeling', value: false },
                { key: 'subtractECS', value: true },
                { key: 'subtractMethod', value: ExclusionTypes.HOUSING_THEORETICAL },
            ],
            placeholder: '0',
            defaultValue: null,
            required: true,
        },
        {
            key: 'avgVolume',
            displayKey: 'monitoring_avg_volume',
            deps: [
                { key: 'ecsUse', value: true },
                { key: 'linearModeling', value: false },
                { key: 'subtractECS', value: true },
                { key: 'subtractMethod', value: ExclusionTypes.HOUSING_THEORETICAL },
            ],
            placeholder: 'm3_per_month',
            defaultValue: null,
            required: true,
        },
        {
            key: 'deltaTolerancePercentage',
            displayKey: 'monitoring_delta_tolerance_percentage',
            placeholder: '%',
            deps: [],
            defaultValue: null,
            min: 0,
            max: 100,
            step: 0.1,
            required: true,
        },
        {
            key: 'profitSharingPercentage',
            displayKey: 'monitoring_profit_sharing_percentage',
            placeholder: '%',
            deps: [],
            defaultValue: 50,
            min: 0,
            max: 100,
            step: 0.1,
            required: true,
        },
        {
            key: 'penaltiesPercentage',
            displayKey: 'monitoring_penalties_percentage',
            placeholder: '%',
            deps: [],
            defaultValue: 100,
            min: 0,
            max: 100,
            step: 0.1,
            required: true,
        },
        {
            key: 'includedCosts',
            displayKey: 'monitoring_included_costs',
            deps: [],
            defaultValue: [],
            options: MonitoringCostsService.DEFAULT_CONFIG,
            minLength: 1,
            required: true,
        },
    ];
    /** Fields form group. Initiated dynamically */
    get form() {
        return this.monitoringEditionService.form;
    }
    /** Subscription */
    private subscription: Subscription;
    /** date limit for the date range picker */
    public dateRangeLimit: DateRangeLimit = { minDate: null, maxDate: null };
    /** Data for the date range (start date and end date) */
    public dateRange: DateRange = {
        fromDate: {
            date: null,
            utcDate: null,
        },
        toDate: {
            date: null,
            utcDate: null,
        },
    };
    /** The energy type of the currently selected routing reference */
    private currentEnergyType: string;

    get contractStartField() {
        return this.configs.find(c => c.key === 'contractStart');
    }
    get contractEndField() {
        return this.configs.find(c => c.key === 'contractEnd');
    }
    get perfFormulaGradientField() {
        return this.configs.find(c => c.key === 'perfFormulaGradient');
    }
    get perfFormulaInterceptField() {
        return this.configs.find(c => c.key === 'perfFormulaYIntercept');
    }
    get monitoringDJUsField() {
        return this.configs.find(c => c.key === 'monitoringDJUs');
    }
    get monitoringNBField() {
        return this.configs.find(c => c.key === 'monitoringNB');
    }
    get qHighSeasonField() {
        return this.configs.find(c => c.key === 'qHighSeason');
    }
    get qLowSeasonField() {
        return this.configs.find(c => c.key === 'qLowSeason');
    }
    get nHousingField() {
        return this.configs.find(c => c.key === 'nHousing');
    }
    get avgVolumeField() {
        return this.configs.find(c => c.key === 'avgVolume');
    }
    get deltaTolerancePercentageField() {
        return this.configs.find(c => c.key === 'deltaTolerancePercentage');
    }
    get profitSharingPercentageField() {
        return this.configs.find(c => c.key === 'profitSharingPercentage');
    }
    /** Get value of profit sharing percentage for slider */
    get profitSharingPercentage(): number {
        return this.form.get(this.profitSharingPercentageField.key).value;
    }
    /** Set value of profit sharing percentage for slider */
    set profitSharingPercentage(value: number) {
        this.form.get(this.profitSharingPercentageField.key).setValue(value);
    }
    get penaltiesPercentageField() {
        return this.configs.find(c => c.key === 'penaltiesPercentage');
    }
    /** Get value of penalties percentage for slider */
    get penaltiesPercentage(): number {
        return this.form.get(this.penaltiesPercentageField.key).value;
    }
    /** Set value of penalties percentage for slider */
    set penaltiesPercentage(value: number) {
        this.form.get(this.penaltiesPercentageField.key).setValue(value);
    }
    get includedCostsField() {
        return this.configs.find(c => c.key === 'includedCosts');
    }
    get includedCosts(): string[] {
        return this.form.get(this.includedCostsField.key).value;
    }
    set includedCosts(value: string[]) {
        this.form.get(this.includedCostsField.key).setValue(value);
    }

    constructor(
        private operatingMonitoringService: OperatingMonitoringService,
        private monitoringEditionService: MonitoringEditionService,
        private monitoringCostsService: MonitoringCostsService
    ) {}

    ngOnInit() {
        this.monitoringEditionService.addToControlGroup(this.configs);

        this.subscription = this.form.get('monitoringRref').valueChanges.subscribe({
            next: value => this.onRRefChange(value),
        });
        this.subscription.add(
            this.operatingMonitoringService.editedMonitoring$.subscribe({
                next: value => this.prefillForm(value),
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Display or not field
     * @param item
     */
    public displayField(item: FieldConfig<ContractDataFieldKey>): boolean {
        if (!item.deps || !item.deps.length) {
            return true;
        }
        return item.deps.every(dep => this.form.get(dep.key).value === dep.value);
    }

    public onDateRangeChange(value: DateRange) {
        this.form.get('contractStart').setValue(value.fromDate.utcDate);
        this.form.get('contractEnd').setValue(value.toDate.utcDate);
    }

    public onIncludedCostsChange(costs: string[]): void {
        this.includedCosts = costs;
    }

    private async onRRefChange(newRRef: RoutingReference) {
        if (newRRef) {
            // If same energy type, change nothing
            if (
                (this.currentEnergyType && this.currentEnergyType !== newRRef.energyType) ||
                !this.includedCosts.length
            ) {
                // previously energy type is not the same
                this.includedCosts = MonitoringCostsService.getDefaultSelected(newRRef.energyType);
            }

            this.includedCostsField.options = await this.monitoringCostsService.getCostsConfig(newRRef.energyType);
            this.currentEnergyType = newRRef.energyType;
        }
    }

    private async prefillForm(monitoring: Monitoring) {
        if (!monitoring) {
            return;
        }
        const { uses, contractStart, contractEnd } = monitoring;

        const rangeDateStart = moment.utc(contractStart).toDate();
        // Tweak to display end of contract as real ending day of the contract
        const rangeDateEnd = moment
            .utc(contractEnd)
            .startOf('day')
            .toDate();

        this.form.get(this.contractStartField.key).setValue(contractStart);
        this.form.get(this.contractEndField.key).setValue(contractEnd);
        this.dateRange = {
            fromDate: { date: rangeDateStart, utcDate: rangeDateStart },
            toDate: { date: rangeDateEnd, utcDate: rangeDateEnd },
        };

        this.form.get(this.perfFormulaGradientField.key).setValue(_.get(uses, '0.perfFormula.gradient', null));
        this.form.get(this.perfFormulaInterceptField.key).setValue(_.get(uses, '0.perfFormula.yIntercept', null));
        this.form.get(this.monitoringDJUsField.key).setValue(monitoring.modulatorBase);
        this.form.get(this.monitoringNBField.key).setValue(monitoring.valueCommitted);
        this.form.get(this.qHighSeasonField.key).setValue(_.get(uses, '0.q.highSeason', null));
        this.form.get(this.qLowSeasonField.key).setValue(_.get(uses, '0.q.highSeason', null));
        this.form.get(this.nHousingField.key).setValue(_.get(uses, '0.nHousing', null));
        this.form.get(this.avgVolumeField.key).setValue(_.get(uses, '0.avgVolume', null));
        this.form.get(this.deltaTolerancePercentageField.key).setValue(monitoring.deltaTolerancePercentage);
        this.form.get(this.profitSharingPercentageField.key).setValue(monitoring.profitSharingPercentage);
        this.form.get(this.penaltiesPercentageField.key).setValue(monitoring.penaltiesPercentage);
        this.form.get(this.includedCostsField.key).setValue(monitoring.includedCosts);
    }
}
