import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { ManagersComponent } from './managers.component';

const MANAGERS_ROUTE = [{ path: '', component: ManagersComponent }];

@NgModule({
    declarations: [ManagersComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(MANAGERS_ROUTE)],
})
export class ManagersModule {}
