import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { PaginationBackService } from 'app/shared/components/pagination/back/pagination-back.service';
import { QueryFilter } from 'app/shared/models/bills-contracts-filter.interface';
import {
    CompletenessQueryFilter,
    Filter,
    PeriodFilter,
    TimelineData,
} from 'app/shared/models/bills-timeline.interface';
import { BillPopulated, BillsColumns } from 'app/shared/models/bills.interface';
import { Pagination, QueryPagination } from 'app/shared/models/pagination.interface';
import { RoutingReferenceLight } from 'app/shared/models/routing-reference.interface';
import { BillQuery } from 'app/shared/services/bills-list/bills-list.interface';
import { BillsListService } from 'app/shared/services/bills-list/bills-list.service';
import { BillsCompletenessService, FluidProperty } from 'app/shared/services/bills/bills-completeness.service';
import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { CompletenessPeriod, FluidCompleteness } from './bills-completeness-tab.interface';

@Component({
    selector: 'ga-bills-completeness-routing-reference',
    templateUrl: './bills-completeness-tab.component.html',
    styleUrls: ['./bills-completeness-tab.component.scss'],
    providers: [PaginationBackService],
})
export class BillsCompletenessRoutingReferenceComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * used for the bills query
     */
    @Input() routingReference: RoutingReferenceLight;

    /**
     * Contract to display completeness for.
     * If not provided, display completeness for all routing reference's contracts.
     */
    @Input() contract: {
        _id: string;
        reference: string;
        provider: string;
        startDate: string;
        endDate: string;
    } = null;

    /**
     * Bills displayed in the table
     */
    bills: BillPopulated[] = [];

    /**
     * Is currenlty loading bills
     */
    public isBillsLoading = true;

    /**
     * Pagination
     */
    pagination: Pagination = {
        lastItem: {
            _id: null,
            index: 0,
        },
    };

    /**
     * used for the bills service
     */
    filters: QueryFilter;

    /**
     * used to update the daterangepicker
     */
    @Input() dates: PeriodFilter;

    /**
     * used to set the period of the timeline
     */
    timelineFilters: Filter = {};

    /**
     * Datas of the timeline to display
     */
    public timelineDatas: TimelineData[] = [];

    /**
     * All timeline datas
     */
    public timelineDatasAll: TimelineData[] = [];

    /**
     * Do not display category column in the bills list
     */
    availableColumns: BillsColumns = {
        category: {
            enabled: false,
        },
    };

    // min and max bills dates of the routing reference
    private billsDatesLimit: {
        start: string; // date iso string
        end: string; // date iso string
    } = {
        start: null,
        end: null,
    };

    /** Control to handle detailled display checkbox */
    public detailledDisplayControl: FormControl = new FormControl(true);

    private subscription: Subscription;

    constructor(
        private paginationService: PaginationBackService,
        private billsListService: BillsListService,
        private billsCompletenessService: BillsCompletenessService,
        private translateService: TranslateService,
        private routingReferencesService: RoutingReferencesService
    ) {}

    ngOnInit() {
        this.initDates();
        this.subscription = this.detailledDisplayControl.valueChanges.subscribe({
            next: value => {
                this.refreshTimelinesToDisplay(value);
            },
        });
    }

    async ngAfterViewInit() {
        await this.setPeriod(this.dates);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * set the default beginDate and endDate
     * @returns void
     */
    private initDates() {
        const year: number = +moment().format('YYYY');
        this.dates = this.dates || {
            beginDate: {
                year,
                month: 1,
                day: 1,
            },
            endDate: {
                year,
                month: 12,
                day: 31,
            },
        };

        this.timelineFilters = {
            period: {
                beginDate: this.dates.beginDate,
                endDate: this.dates.endDate,
            },
        };
    }

    /**
     * Set the list of bills displayed, using filters & pagination.
     * @return {Promise<void>}
     */
    private async setBills(): Promise<void> {
        try {
            // set loading and empty pagination during loading
            this.isBillsLoading = true;

            const [, , billsResult] = await Promise.all([
                this.getBillsDatesLimit(),
                this.getBillsCompleteness(),
                this.getBills(),
            ]);

            initCollapse(billsResult.results);
            this.bills = billsResult.results;

            // Update pagination with the new total number of elements
            this.paginationService.updateLastItem(this.pagination.lastItem, this.bills);
            this.paginationService.updateAfterSearch(billsResult.total);

            this.isBillsLoading = false;
        } catch (e) {
            this.bills = [];
            this.isBillsLoading = false;

            // reset pagination to first page for an empty array
            this.paginationService.emptyPagination();

            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des factures. Veuillez réessayer ultérieurement.',
                'warning'
            );
        }

        function initCollapse(billsUploads: BillPopulated[]) {
            billsUploads.forEach(bill => {
                bill.isExpanded = false;
            });
        }
    }

    /**
     * Get data to bills uploads to display for filters and pagination.
     * @return {Promise<{ results: BillPopulated[]; total: number; }>}
     */
    private async getBills(): Promise<{
        results: BillPopulated[];
        total: number;
    }> {
        const queryPagination: QueryPagination = this.paginationService.getPaginationQuery(this.pagination.lastItem);
        const filters: BillQuery = Object.assign(
            { consumptionStart: this.filters.start, consumptionEnd: this.filters.end },
            this.filters,
            { start: null, end: null }
        );
        const result = await this.billsListService.getUploadsBillsPopulated(
            filters,
            queryPagination,
            this.routingReference.company
        );
        this.billsListService.formatBillsUpload(result.results);
        // Filter chunks with only those from routing reference
        this.filterBillsChunks(result.results);
        return result;
    }

    /**
     * Filter bills chunks to display to keep only chunks related to component's routing reference
     * @param {BillPopulated[]} bills - bills uploads formatted (with `subTotalsPerCategory`)
     */
    private filterBillsChunks(bills: BillPopulated[]) {
        bills.forEach(bill => {
            if (bill.multi) {
                /**
                 * subTotalsPerCategory is used to display chunks grouped
                 * So we need to filter chunks in these items
                 */
                bill.subTotalsPerCategory.forEach(category => {
                    category.chunks = category.chunks.filter(
                        x => x.routingReference && x.routingReference.id === this.routingReference._id
                    );
                });
                /**
                 * Filter to remove empty categories
                 */
                bill.subTotalsPerCategory = bill.subTotalsPerCategory.filter(x => x.chunks.length);
            }
        });
    }

    /**
     * get min and max bills dates of the routing reference
     */
    private async getBillsDatesLimit() {
        if (!this.billsDatesLimit.start && !this.billsDatesLimit.end) {
            const billsDates = await this.routingReferencesService.getRoutingReferenceBillsDates(
                this.routingReference._id,
                this.contract && this.contract._id ? { contract: this.contract._id } : {}
            );

            const billsStartDate = billsDates.start;
            if (billsStartDate && moment.utc(billsStartDate).isValid()) {
                this.billsDatesLimit.start = billsStartDate;
            }

            const billsEndDate = billsDates.end;
            if (billsEndDate && moment.utc(billsEndDate).isValid()) {
                this.billsDatesLimit.end = billsEndDate;
            }
        }
    }

    /**
     * get bills completeness periods for timeline displaying
     */
    private async getBillsCompleteness() {
        const detailled = this.detailledDisplayControl.value;
        try {
            const filters: CompletenessQueryFilter = {
                start: this.filters.start,
                end: this.filters.end,
                routingReference: this.routingReference._id,
            };
            if (this.contract && this.contract._id) {
                filters.contract = this.contract._id;
            }
            const result: FluidCompleteness = await this.billsCompletenessService.getBillsCompletenessPeriods(
                filters,
                this.routingReference.company
            );

            const properties = this.billsCompletenessService
                .getFluidProperties(this.routingReference.energyType)
                .reverse();
            const timelineDatas: TimelineData[] = properties.reduce((memo, property) => {
                const data = result[property.key];
                if (data) {
                    const timelineData = this.getTimelineBillsCompleteness(data, property);

                    this.setTimelineDatas(timelineData, property);
                    return memo.concat(timelineData);
                }
                return memo;
            }, []);
            this.timelineDatasAll = timelineDatas;
            this.refreshTimelinesToDisplay(detailled);
        } catch (err) {
            this.timelineDatasAll = [];
            this.refreshTimelinesToDisplay(detailled);
            throw err;
        }
    }

    /**
     * Create timeline data by using bill periods and filling with incomplete periods.
     * @param periods
     * @param property
     */
    private getTimelineBillsCompleteness(periods: CompletenessPeriod[], property: FluidProperty): TimelineData[] {
        const timelineDatas: TimelineData[] = [];
        const dateFormat = 'DD/MM/YYYY';

        const starts = [moment.utc(this.filters.start)];
        if (this.billsDatesLimit.start) {
            starts.push(moment.utc(this.billsDatesLimit.start));
        }
        const start: string = moment.max(starts).toISOString();
        const addIncompletePeriods = Boolean(property.isParent);

        let end: string = null;

        // End used to compute incomplete period at the end. Min calc must include bill dates end.
        if (this.billsDatesLimit.end) {
            end = moment
                .min([
                    moment.utc(this.billsDatesLimit.end),
                    moment
                        .utc(this.filters.end)
                        .add(1, 'days')
                        .startOf('day'),
                    moment.utc(),
                ])
                .toISOString();
        }

        periods.forEach(period => {
            /** Last period date to have the starting point and create incomplete period if needed */
            let lastDate: string;

            if (!timelineDatas.length) {
                lastDate = start;
            } else {
                lastDate = moment.utc(timelineDatas[timelineDatas.length - 1].endDate, dateFormat).toISOString();
            }

            // If period start date and last period end date don't follow, add incomplete period between
            if (
                addIncompletePeriods &&
                moment
                    .utc(lastDate)
                    .add(1, 'day')
                    .isBefore(moment.utc(period.startDate))
            ) {
                timelineDatas.push({
                    key: property.key,
                    category: property.label,
                    startDate: moment
                        .utc(lastDate)
                        .add(1, 'day')
                        .format(dateFormat),
                    endDate: moment
                        .utc(period.startDate)
                        .subtract(1, 'day')
                        .format(dateFormat),
                    type: 'incomplete_period',
                });
            }

            const type = this.billsCompletenessService.getPeriodWithDataType(property, period.bills.length);
            timelineDatas.push({
                key: property.key,
                category: property.label,
                startDate: moment.utc(period.startDate).format(dateFormat),
                endDate: moment.utc(period.endDate).format(dateFormat),
                type,
                bills: period.bills,
                provider: this.contract ? this.contract.provider : null,
                contractRef: this.contract ? this.contract.reference : null,
            });
        });
        // Add incomplete period
        if (
            addIncompletePeriods &&
            end &&
            timelineDatas.length &&
            moment
                .utc(timelineDatas[timelineDatas.length - 1].endDate, dateFormat)
                .add(1, 'days')
                .isBefore(moment.utc(end))
        ) {
            timelineDatas.push({
                key: property.key,
                category: property.label,
                startDate: moment
                    .utc(timelineDatas[timelineDatas.length - 1].endDate, dateFormat)
                    .add(1, 'day')
                    .format(dateFormat),
                endDate: moment
                    .utc(end)
                    .subtract(1, 'day')
                    .format(dateFormat),
                type: 'incomplete_period',
            });
        }
        return timelineDatas;
    }

    /**
     * set the QueryFilter with the start date and the end date
     * then get the filtered bills
     * @param {PeriodFilter}
     */
    private async setPeriod(period: PeriodFilter) {
        const isoDates = this.getIsoDates(period);

        this.filters = {
            start: isoDates.beginDate,
            end: isoDates.endDate,
            routingReferences: [this.routingReference._id],
            onlyError: false,
            includeBranches: false,
        };

        if (this.contract) {
            this.filters.contracts = [this.contract._id];
        }

        // reset pagination to first page
        this.paginationService.setFirstPage();
        // Reset last item
        this.pagination.lastItem = { _id: null, index: 0 };

        // get data for new filter values
        await this.setBills();
    }

    /**
     * on timeline period changed
     * @param {periodFilter} period
     */
    public async timelinePeriodOnChanged(period: PeriodFilter) {
        this.dates = period;
        await this.setPeriod(period);
    }

    /**
     * on dates rangepicker changed
     * @param {PeriodFilter} period
     */
    public async datesOnChanged(period: PeriodFilter) {
        this.timelineFilters = {
            period: {
                beginDate: period.beginDate,
                endDate: period.endDate,
            },
        };

        await this.setPeriod(period);
    }

    /**
     * Called when pagination is updated (selected page or nb of items per pages).
     * Load the updated bills.
     */
    public async onPaginationChanged(event = { resetLastItem: false }) {
        if (event.resetLastItem) {
            this.paginationService.updateLastItem(this.pagination.lastItem, []);
        }
        await this.setBills();
    }

    /**
     * get Iso String dates from PeriodFilter dates
     * @param {PeriodFilter} period
     * @returns {<{beginDate: string, endDate: string}>}
     */
    private getIsoDates(period: PeriodFilter): { beginDate: string; endDate: string } {
        const beginDate = moment
            .utc(`${period.beginDate.year}-${period.beginDate.month}-${period.beginDate.day}`, 'YYYY-MM-DD')
            .startOf('day')
            .toISOString();
        const endDate = moment
            .utc(`${period.endDate.year}-${period.endDate.month}-${period.endDate.day}`, 'YYYY-MM-DD')
            .endOf('day')
            .toISOString();
        return { beginDate, endDate };
    }

    /**
     * set the timeline datas by adding periods:
     * - today
     * - end of contract
     * - deactivation date of the routing reference
     * @param timelineDatas
     * @param property
     */
    private setTimelineDatas(timelineDatas: TimelineData[], property: FluidProperty) {
        const dateFormat = 'DD/MM/YYYY';

        timelineDatas.push({
            key: property.key,
            category: property.label,
            startDate: moment.utc().format(dateFormat),
            endDate: moment
                .utc()
                .add(1, 'day')
                .format(dateFormat),
            type: 'today',
        });

        if (this.contract && this.contract.endDate) {
            timelineDatas.push({
                key: property.key,
                category: property.label,
                startDate: moment.utc(this.contract.endDate).format(dateFormat),
                endDate: moment
                    .utc(this.contract.endDate)
                    .add(1, 'day')
                    .format(dateFormat),
                type: 'end_of_contract',
                provider: this.contract.provider,
                contractRef: this.contract.reference,
            });
        }

        // add routing reference deactivation date in the timeline
        const isActive = _.get(this.routingReference, 'status.active');
        const histories = _.get(this.routingReference, 'status.history', []);
        if (isActive === false && histories.length) {
            const history = _.last(histories);
            if (history && history['date']) {
                const deactivationDate = history['date'];
                timelineDatas.push({
                    key: property.key,
                    category: property.label,
                    startDate: moment.utc(deactivationDate).format(dateFormat),
                    endDate: moment
                        .utc(deactivationDate)
                        .add(1, 'day')
                        .format(dateFormat),
                    type: 'routing_reference_deactived',
                });
            }
        }
    }

    /**
     * Set a message in the table if it doesn't have the bills to match the timeline data
     * @returns {string | null} key of the message to display. Null if no message to display.
     */
    public get msgForTable(): string | null {
        // display a explanation msg if the timeline has data and the table doesn't
        const isMissingData = Boolean(!this.bills.length && this.timelineDatas.length);
        return isMissingData ? this.translateService._('bills_unavailable') : null;
    }

    /**
     * Refresh timelines data to display
     * @param displayDetailled
     */
    private refreshTimelinesToDisplay(displayDetailled: boolean) {
        const keys = this.billsCompletenessService.getFluidProperties(this.routingReference.energyType, {
            onlyParents: !displayDetailled,
        });
        this.timelineDatas = this.timelineDatasAll.filter(x => keys.some(k => k.key === x.key));
    }
}
