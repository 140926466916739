import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyService } from 'app/shared/components/admin/company/company.service';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { AdminTab } from 'app/shared/models/admin-tab.interface';
import { SessionService } from 'app/shared/services/session/session.service';
import { UsersService } from 'app/shared/services/users/users.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-company-table',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    providers: [CompanyService],
})
export class CompanyComponent implements OnInit {
    @Input() _company: any = null;
    set company(value) {
        this._company = value;
        this.handleBranchesTab();
    }
    get company() {
        return this._company;
    }

    @Input() selectedTab: AdminTab = null;
    @Input() tabs: AdminTab[] = [];

    isLoading = true;
    user: any = null;

    // for tabs "Utilisateurs" "Energy-Mangers" and "Energy-Viewers": rights available in the table
    tableProperties = {
        users: {
            companyRole: 'users',
            rightsAvailable: [{ name: 'Lecture', value: 1 }, { name: 'Ecriture', value: 2 }],
        },
        energyManagers: {
            companyRole: 'users',
            rightsAvailable: [{ name: 'Lecture', value: 1 }, { name: 'Ecriture', value: 2 }],
        },
        energyViewers: { companyRole: 'viewers', rightsAvailable: [{ name: 'Lecture', value: 1 }] },
    };

    get UserTypes() {
        return UserTypes;
    }

    isDeleting = false;
    @Output() deletingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private location: Location,
        private sessionService: SessionService,
        private usersService: UsersService,
        private router: Router
    ) {}

    ngOnInit() {
        this.initData();
    }

    async initData() {
        try {
            this.handleBranchesTab();
            this.selectTab(this.selectedTab);

            // load the session user info with his relations
            this.user = await this.usersService.getUserByIdWithRelationsAndContacts(this.sessionService.getUser()._id);
            this.isLoading = false;
        } catch (e) {
            // if contacts & users not loaded, go back to the list of companies
            Swal.fire(
                'Toutes nos excuses',
                "Une erreur est survenue pendant le chargement des contacts et utilisateurs de l'entreprise.",
                'error'
            );
            await this.router.navigateByUrl('/admin/entreprises');
        }
    }

    /**
     * Select the tab. Change URL displayed without relaoding the page.
     * @param {AdminTab} tab - tab to select
     */
    selectTab(tab: AdminTab) {
        const isTabSame = this.selectedTab && tab && this.selectedTab.key === tab.key;

        if (this.company && !this.isTabDisabled(tab.key) && !isTabSame) {
            // change the URL name as well but without reloading the page
            this.location.go('/admin/entreprise/' + this.company._id + '/' + tab.key);
            this.selectedTab = tab; // they might be different if selectTab is called by the page being refresh (Ctrl R)
        }
    }

    isTabActive(key: string) {
        return this.selectedTab.key === key;
    }

    getNameCapitalized(name) {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    getTabClasses(key: string): string {
        let classes = '';
        classes += this.isTabActive(key) ? 'tab-active ' : '';
        classes += this.isTabDisabled(key) ? 'cursor-not-allowed' : '';
        return classes;
    }

    isTabDisabled(key: string): boolean {
        // if the new company hasn't been saved, allow only the company's profile tab
        if (!this.company && key !== 'profil') {
            return true;
        }
        // prestations isn't implemented yet
        if (key === 'prestations') {
            return true;
        }

        // tabs are not clickable during deletion.
        return this.isDeleting;
    }

    onSave(newCompany) {
        this.company = newCompany;
    }

    /**
     * Handle if the branches tab is displayed or not (update visibility).
     */
    handleBranchesTab() {
        const visibility = this.hasBranches() && this.isTabVisible('filiales');
        this.setTabVisibility('filiales', visibility);
    }

    /**
     * Returns true if the company has branches
     * @return {boolean}
     */
    hasBranches(): boolean {
        return this.company && this.company.branches && Boolean(this.company.branches.length);
    }

    setTabVisibility(key: string, visibility: boolean) {
        const tab = this.tabs.find(t => t.key === key);
        if (tab) {
            tab.visible = Boolean(visibility);
        }
    }

    /**
     * Returns whether the tab is visible or not
     * @param {string} key - key of the tab
     * @return {boolean}
     */
    isTabVisible(key: string): boolean {
        const tab = this.tabs.find(t => t.key === key);
        return tab ? tab.visible : false;
    }

    /**
     * Returns whether the tab is editable or not
     * @param {string} key - key of the tab
     * @return {boolean}
     */
    canEditTab(key: string): boolean {
        const tab = this.tabs.find(t => t.key === key);
        return tab ? tab.canEdit : false;
    }

    /**
     * Returns true if the user is ADMIN
     * @return {boolean}
     */
    isAdmin(): boolean {
        return this.usersService.isAdmin(this.user);
    }

    /**
     * Set deleting param and disable tabs other than profile if deleting
     * @param {boolean} isDeleting - is company being deleted
     */
    setDeleting(isDeleting: boolean) {
        this.isDeleting = isDeleting;
        this.deletingChange.emit(this.isDeleting);

        const profileTab = this.tabs.find(x => x.key === 'profil');
        if (profileTab) {
            this.selectTab(profileTab);
        }
    }
}
