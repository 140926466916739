import { Component } from '@angular/core';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { SitePopulated } from 'app/shared/models/site.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { EntitiesAttributionComponent } from '../entity-attribution.component';

@Component({
    selector: 'ga-company-routing-references-tab',
    templateUrl: '../entity-attribution.component.html',
    styleUrls: ['../entity-attribution.component.scss'],
    providers: [],
})
export class CompanyRoutingReferencesComponent extends EntitiesAttributionComponent<RoutingReference> {
    constructor(
        public energyService: EnergyService,
        public sitesService: SitesService,
        private routingReferencesService: RoutingReferencesService
    ) {
        super(energyService, sitesService);
    }

    async getEntities() {
        return this.routingReferencesService.getRoutingReferencesPopulated({ companyId: this.company._id });
    }

    getSiteEntities(site: SitePopulated): RoutingReference[] {
        return site && site.routingReferences ? site.routingReferences : [];
    }

    setSiteEntities(site: SitePopulated, entities: RoutingReference[]): void {
        site.routingReferences = entities;
    }

    get searchProperties(): string[] {
        return ['reference', '_id', 'address.streetName', 'address.city', 'address.zipcode'];
    }

    isEntityFiltered(entity: RoutingReference, filters: { entities: string; fluid: string }) {
        if (this.searchFilter.fluid && entity.energyType !== this.searchFilter.fluid) {
            return false;
        }
        return true;
    }

    getEntityTitle(entity: RoutingReference): string {
        return entity.reference;
    }

    getEntityIcon(entity: RoutingReference): string {
        return `${entity.energyType}-icon`;
    }

    getEntitySubtitle1(entity: RoutingReference): string {
        const streetNumber = entity.address && entity.address.streetNumber ? entity.address.streetNumber : '';
        const streetName = entity.address && entity.address.streetName ? entity.address.streetName : '';
        return `${streetNumber} ${streetName}`;
    }

    getEntitySubtitle2(entity: RoutingReference): string {
        const zipcode = entity.address && entity.address.zipcode ? entity.address.zipcode : '';
        const city = entity.address && entity.address.city ? entity.address.city : '';
        return `${zipcode} ${city}`;
    }

    get entityKeyPlural(): string {
        return 'routingReferences';
    }

    get searchPlaceholder(): string {
        return 'Numéro de PDL, adresse, ...';
    }

    get entityType(): 'routingReferences' {
        return 'routingReferences';
    }

    async getFluids() {
        const routingReferencesFluids = this.energyService.getAllRoutingReferenceFluids();
        const fluids = await this.energyService.getFluidsAvailable(this.company._id);
        return fluids.filter(x => routingReferencesFluids.includes(x));
    }
}
