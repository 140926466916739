import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import moment = require('moment');

import { LoadCurveData, LoadCurveMeasure } from 'app/shared/models/load-curve.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { ExportService } from 'app/shared/services/export/export.service';
import { PageService } from 'app/shared/services/page/page.service';

import { LoadCurveDates, SearchFilter, TileSerie } from './load-curve.interface';

@Injectable()
export class LoadCurveService extends PageService {
    constructor(public apiService: ApiService, private readonly _exportService: ExportService) {
        super(apiService);
        this.fake = false;
    }

    /**
     * Get data for chart.
     * @param {SearchFilter} filters
     * @return {Promise<LoadCurveData[]>}
     */
    public async getLoadCurveData(filters: SearchFilter): Promise<LoadCurveData[]> {
        const data = {
            dateStart: filters.start,
            dateEnd: filters.end,
        };
        data[filters.entityType] = filters.entityId;
        const response = await this.get('/api/external-data/load-curve/' + filters.entityType, null, data);
        return response.data;
    }

    /**
     * Get the project's period.
     * @param {string} entityType
     * @param {string} entityId
     * @return {Promise<LoadCurveDates>}
     */
    public async getProjectPeriod(entityType: string, entityId: string): Promise<LoadCurveDates> {
        const data = {
            [entityType]: entityId,
        };
        const response = await this.get('/api/external-data/load-curve/period/' + entityType, null, data);
        return response.data;
    }

    /**
     * Find interval in miliseconds between the two first measures timestamp.
     * Default is 10 if serie doesn't have any measure.
     * @param {LoadCurveMeasure[]} mesaurues - serie's measures
     * @returns {number} interval in ms
     */
    public getDataInterval(measures: LoadCurveMeasure[]): number {
        const start = measures[0];
        const end = measures[1];
        if (!(start && end)) {
            return 10 * 60 * 1000; // default interval is 10 minutes for empty series
        }
        const dateStart = moment(new Date(start.timestamp).getTime());
        const dateEnd = moment(new Date(end.timestamp).getTime());
        return dateEnd.diff(dateStart, 'milliseconds');
    }

    /**
     * Compute missing data duration, the sum of :
     *  - duration of null values
     *  - duration of periods with missing indexes
     * Series' interval and expected duration must have the same unit
     * @param {TileSerie[]} series - series of data
     * @param {number[]} nullValuesPerSerie - count of null values in each serie
     * @param {number} expectedSerieDuration - expected duration of each serie (in minutes)
     * @returns {number} missing duration in minutes
     */
    public computeMissingDuration(
        series: TileSerie[],
        nullValuesPerSerie: number[],
        expectedSerieDuration: number
    ): number {
        /**
         * Duration of missing data in minutes : sum of null and missing values
         */
        let totalMissingDuration = 0;

        // Compute missing data on each serie (depends on the serie's interval)
        series.forEach((serie, index) => {
            // Handle the duration of null values (in min)
            const nullValuesCount = nullValuesPerSerie[index];
            const nullDuration = nullValuesCount * serie.interval;

            // Handle the duration of missing values (minssing  time indexes & the duration it represents)
            const expectedIndexesCount = expectedSerieDuration / serie.interval + 1; // expected number of indexes
            const missingValuesCount = expectedIndexesCount - serie.x.length; // compute nb of missing indexes
            const missingValuesDuration = missingValuesCount * serie.interval; // compute the missing duration it represents

            // Sum both duration of null and missing values
            const missingDuration = nullDuration + missingValuesDuration;
            totalMissingDuration = missingDuration ? totalMissingDuration + missingDuration : totalMissingDuration;
        });

        return totalMissingDuration;
    }

    /**
     * Compute the number of missing measures among all series, the sum of :
     *  - null values
     *  - missing measures
     * @param {TileSerie[]} series - series of data
     * @param {number[]} nbNullValuesPerSerie - count of null values in each serie
     * @returns {nbMissingMeasures: number, nbExpectedMeasures: number} count of missing and expected measures
     */
    public computeMissingMeasures(
        series: TileSerie[],
        nbNullValuesPerSerie: number[]
    ): { nbMissingMeasures: number; nbExpectedMeasures: number } {
        /** Count of missing measures */
        let nbMissingMeasures = 0;

        /** Count of null measures */
        let nbExpectedMeasures = 0;

        /** Expected number of measures expected per serie */
        const nbExpectedMeasuresPerSerie: number[] = this.computeExpectedMeasuresPerSerie(series);

        series.forEach((serie, index) => {
            /** The serie's count of null measures */
            const nullValuesCount = nbNullValuesPerSerie[index];

            /** The serie's expected number of measures */
            const expectedMeasuresCount = nbExpectedMeasuresPerSerie[index];
            const missingMeasuresCount = expectedMeasuresCount - serie.x.length;

            // Sum both null and missing measures
            nbMissingMeasures += nullValuesCount + missingMeasuresCount;
            nbExpectedMeasures += expectedMeasuresCount;
        });

        return { nbMissingMeasures, nbExpectedMeasures };
    }

    /**
     * Compute the expected number of measures for each serie
     * @param {TileSerie[]} series - series of data
     * @returns {number[]} expected number of measures per serie
     */
    public computeExpectedMeasuresPerSerie(series: TileSerie[]): number[] {
        /** Expected number of measures per serie */
        const expectedMeasuresPerSerie = [];
        let seriesMin = null;
        let seriesMax = null;
        /** The minimum interval from all series */
        let minInterval = null;
        series.forEach(serie => {
            if (!serie.x || !serie.x.length) {
                return;
            }
            if (seriesMin === null || serie.x[0] < seriesMin) {
                seriesMin = serie.x[0];
            }
            const last = _.last(serie.x);
            if (seriesMax === null || last > seriesMax) {
                seriesMax = last;
            }
            if (minInterval === null || (serie.interval && serie.interval < minInterval)) {
                minInterval = serie.interval;
            }
        });
        if (!seriesMin || !seriesMax || !minInterval) {
            return expectedMeasuresPerSerie;
        }
        /** First date with data displayed from all series */
        const overallMin = moment(seriesMin);

        /** Last date with data displayed from all series */
        const overallMax = moment(seriesMax);

        series.forEach(s => {
            // clone first and last date displayed in the chart to manipulate it without changing the original
            const min = overallMin.clone();
            const max = overallMax.clone();
            let remainingTry = 5;
            // Find the expected first and last data to display for the current serie, depending on its interval
            while (min.minutes() % s.interval && remainingTry > 0) {
                min.add(minInterval, 'minutes');
                remainingTry--;
            }
            remainingTry = 5;
            while (max.minutes() % s.interval && remainingTry > 0) {
                max.subtract(minInterval, 'minutes');
                remainingTry--;
            }
            /** Duration between the first and last date for the current serie */
            const serieDuration = max.diff(min, 'minutes');

            /** Compute the expeceted number of measures for the given serie's duration and interval */
            expectedMeasuresPerSerie.push(serieDuration / s.interval + 1);
        });

        return expectedMeasuresPerSerie;
    }
}
