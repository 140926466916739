import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { RankingService } from './ranking.service';

import { ChartService } from 'app/shared/services/chart/chart.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';

import { DjuService } from 'app/shared/services/dju/dju.service';

import { QueryFilterRoutingRef } from 'app/shared/components/filters/scope-filter/scope-filter.interface';

import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-ranking',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.scss'],
    providers: [RankingService],
})
export class RankingComponent implements OnInit {
    // Current fluid type page ('water' or 'energy')
    fluidCategory = 'energy';
    // Fluids accepted by the category type
    fluidsAccepted: string[] = [];
    // Set true if there is multiple fluids selected
    hasMultipleFluidsSelected = false;

    // Properties for top the chart
    topProperties: any = {
        isLoading: true,
        title: 'Top 10',
        datasets: [],
        labels: [],
        colors: [],
        options: {},
        legends: [],
        toggleTitles: [],
        downloadTitles: [],
    };

    // Properties for the flop chart
    flopProperties: any = {
        isLoading: true,
        title: 'Flop 10',
        datasets: [],
        labels: [],
        colors: [],
        options: {},
        legends: [],
        toggleTitles: [],
        downloadTitles: [],
    };

    queryExport: any = null;

    constructor(
        private route: ActivatedRoute,
        private energyService: EnergyService,
        private chartService: ChartService,
        private rankingService: RankingService,
        private filterService: FilterService,
        private djuService: DjuService
    ) {}

    ngOnInit() {
        if (this.route.data) {
            this.route.data.subscribe(params => {
                this.initComponent(params);
            });
        }
    }

    initComponent(params: Params) {
        // Set fluids available
        this.fluidCategory = 'energy';
        if (params && params['fluidCategory'] && params['fluidCategory'] === 'water') {
            this.fluidCategory = 'water';
        }
        this.fluidsAccepted = this.energyService.getFluidsByCategory(this.fluidCategory);
    }

    /**
     * Search for the top/flop sites mating criterias of the follow up filter
     * @param {any} filters - Object of filter coming from the follow up filter component
     */
    async search(filters: QueryFilterRoutingRef) {
        try {
            this.topProperties.isLoading = true;
            this.flopProperties.isLoading = true;
            let fluids = [];
            if (filters.energies) {
                fluids = filters.energies.split(',');
            }
            if (!filters.dateStart || !filters.dateEnd) {
                throw new Error('dates_missing');
            }
            this.hasMultipleFluidsSelected = Boolean(fluids.length > 1);
            this.queryExport = filters;

            const promiseArray = [this.rankingService.getConsumption(filters)];

            if (this.fluidCategory !== 'water') {
                promiseArray.push(this.djuService.getDJUs(filters, 'site'));
            }

            // Get consumption
            const response = await Promise.all(promiseArray);
            const sitesConsumptions = response[0].data;

            // Format consumption
            let sites = this.rankingService.formatConsumptionDataPerSite(sitesConsumptions, fluids);
            const hasDJU = Boolean(response[1]);
            // If DJUs grabbed, add quantity per DJUs to sites
            if (hasDJU) {
                const sitesDJUs = response[1];
                sites = this.rankingService.formatSitesConsumptionPerDJU(sites, sitesDJUs);
            }

            // Handle top sites chart
            this.handleTopSites(sites, hasDJU);
            // Handle flop sites chart
            this.handleFlopSites(sites, hasDJU);
        } catch (e) {
            // Handle top sites chart
            this.handleTopSites([]);
            // Handle flop sites chart
            this.handleFlopSites([]);

            if (e.message !== 'dates_missing') {
                Swal.fire(
                    'Erreur',
                    'Une erreur est survenue lors de la récupération de vos données. Merci de réessayer ultérieurement.',
                    'error'
                );
            }
        }
    }

    /**
     * Handle top sites chart
     * @param {Array<any>} sites - list of sites formatted with the consumption. Must be sort by consumption ascending.
     */
    handleTopSites(sites: any[], hasDJU: boolean = false) {
        this.handleProperties(this.topProperties, sites, 1, hasDJU);
    }

    /**
     * Handle flop sites chart
     * @param  {Array<any>} sites - list of sites formatted with the consumption. Must be sorted by consumption ascending.
     */
    handleFlopSites(sites: any[], hasDJU: boolean = false) {
        this.handleProperties(this.flopProperties, sites, -1, hasDJU);
    }

    /**
     * Handle the chart properties for top or flop.
     * @param {any} properties - property to set the values of (top of flop)
     * @param {Array<any>} sitesRaw - list of sites formatted with the consumption.
     * @param {number} sort - sort type. Set 1 to sort consumption/cost ascending, -1 for descending
     */
    handleProperties(properties: any, sitesRaw: any[], sort, hasDJU: boolean = false) {
        // Reset data
        properties.options = [];
        properties.datasets = [];
        properties.labels = [];
        properties.colors = [];
        properties.legends = [];
        properties.toggleTitles = [];
        properties.downloadTitles = [];

        const toggleProperties = ['quantity', 'totalHT', 'totalTTC'];

        if (hasDJU) {
            toggleProperties.splice(1, 0, 'quantityDju');
        }

        toggleProperties.forEach(toggleProperty => {
            // Sort sites list by consumption ascending
            const sites = this.sortSiteByProperty(sitesRaw, toggleProperty, sort);

            // Reset data
            //   Get config
            const options = this.chartService.getConfig('horizontalBar', {
                tooltips: this.chartService.getTooltipHTMLConfig('stack', null, { isHorizontal: true }),
            });
            properties.options.push(options);
            const datasets = [];
            const labels = [];
            const colors = [];
            const legends = [];
            // Object to create one property per fluid (faster to access than array with find)
            const fluidDatasets = {};
            // For each site, get the consumption.
            // Create a dataset if not already existing for the fluid.
            sites.forEach(site => {
                labels.push(site.name || 'NC');
                Object.keys(site.conso).forEach(fluid => {
                    if (fluid !== 'total') {
                        if (!fluidDatasets[fluid]) {
                            fluidDatasets[fluid] = { data: [] };
                        }
                        fluidDatasets[fluid].data.push(site.conso[fluid][toggleProperty]);
                    }
                });
            });
            const unit = this.getCurrentUnit(toggleProperty);
            // For each dataset ( = for each fluid)
            Object.keys(fluidDatasets).forEach(fluid => {
                // Get the energy full text
                fluidDatasets[fluid].label = fluid;
                fluidDatasets[fluid].unit = unit;
                // Add the dataset to the chart
                datasets.push(fluidDatasets[fluid]);
                // Get the fluid color
                colors.push(this.chartService.getColor(fluid));
                // Get the fluid legend
                legends.push(this.chartService.getLegend(fluid));
            });
            properties.datasets.push(datasets);
            properties.labels.push(labels);
            properties.colors.push(colors);
            properties.legends.push(legends);
            const toggleTitle = this.getPropertyToggleTitle(toggleProperty);
            properties.toggleTitles.push(`${toggleTitle} (${unit})`);
            properties.downloadTitles.push(`${toggleTitle} (${unit})`);
        });

        properties.isLoading = false;
    }

    /**
     * Sort sites by a given conso property. Create a copy of sites list (just the order, not the items).
     * @param {Object[]} sites - sites list
     * @param {string} property - property to compare to sort sites by consumption
     * @param {1|-1} sort - sort order. 1 to sort by consumption ascending, -1 descending.
     */
    private sortSiteByProperty(sites: any[], property: string, sort: 1 | -1) {
        return sites
            .concat()
            .sort((a, b) => {
                if (!a.conso.total[property] && a.conso.total[property] !== 0) {
                    return 1;
                }
                return (a.conso.total[property] - b.conso.total[property]) * sort;
            })
            .slice(0, 10);
    }

    getCurrentUnit(property) {
        if (property === 'totalTTC' || property === 'totalHT') {
            return '€';
        }
        if (this.fluidCategory === 'water') {
            return 'm³';
        }
        const baseUnit = this.hasMultipleFluidsSelected ? 'kWhep' : 'kWh';
        return baseUnit + (property === 'quantityDju' ? '/DJU' : '');
    }

    getPropertyToggleTitle(property) {
        switch (property) {
            default:
            case 'quantity':
                return 'Consommation';
            case 'totalTTC':
                return 'Total TTC';
            case 'totalHT':
                return 'Total HT';
            case 'quantityDju':
                return 'Consommation/DJU';
        }
    }

    getExportName(type) {
        const txt = 'Classement des sites';
        const date = moment()
            .format('YYYYMMDD')
            .toString();
        return `${txt}-${type}-${date}.xlsx`;
    }

    getExportUrl(type) {
        if (type && this.queryExport) {
            let url = `/api/export/excel/sites-consumptions/ranking`;
            url += this.filterService.formatQueryToUrl(this.queryExport);
            url += type === 'flop' ? `&ranking=flop` : `&ranking=top`;

            return url;
        }
        return null;
    }
}
