import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-loading-data',
    templateUrl: './loading-data.component.html',
    styleUrls: ['./loading-data.component.scss'],
    providers: [],
})
export class LoadingDataComponent implements OnInit {
    /** Boolean value : If component is loading, it's true. Else, it's false. */
    @Input() loading: boolean;
    /** String value setting loading text alignment. Possible values are "center", "left" or "right" */
    @Input() align: 'center' | 'left' | 'right' = 'center';

    constructor() {}
    ngOnInit() {}

    get textAlignClass() {
        return 'align-' + this.align;
    }
}
