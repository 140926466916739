import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-autocomplete-filter',
    templateUrl: './autocomplete-filter.component.html',
    styleUrls: ['./autocomplete-filter.component.scss'],
})
export class AutocompleteFilterComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
