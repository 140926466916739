import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { AccessGuard } from 'app/shared/services/guards/access-guard.service';
import { ParkMonitoringComponent } from './park-monitoring.component';

const PRK_ROUTE = [
    {
        path: '',
        component: ParkMonitoringComponent,
        canActivate: [AccessGuard],
        data: { page: 'vehicles' },
    },
];

@NgModule({
    declarations: [ParkMonitoringComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(PRK_ROUTE)],
})
export class ParkMonitoringModule {}
