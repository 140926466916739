import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { AccessGuard } from '../../../shared/services/guards/access-guard.service';
import { RankingComponent } from './ranking.component';

const RANKING_ROUTE = [
    {
        path: '',
        component: RankingComponent,
        canActivate: [AccessGuard],
    },
];

@NgModule({
    declarations: [RankingComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(RANKING_ROUTE)],
})
export class RankingModule {}
