import { Component, OnInit, ViewChild } from '@angular/core';
import { QuestionService } from 'app/shared/services/sites/question.service';
import { UploadFilesComponent } from '../../../upload/upload-files/upload-files.component';
import { QuestionComponent } from '../question/question.component';

@Component({
    selector: 'ga-question-file',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [],
})
/**
 * Component to create a question to upload file(s).
 * @Input data : User data at the format [{key: string, value: any}]
 * @Input config : Question from the config file with properties attribute at the format:
 * {key: string}
 */
export class FileQuestionComponent extends QuestionComponent implements OnInit {
    multiple = true;

    /**
     * Get the upload component
     */
    @ViewChild('upload', { static: false }) private uploadComponent: UploadFilesComponent;

    constructor(private questionService: QuestionService) {
        super();
    }

    ngOnInit(): void {
        super.setFileKey(this.config.properties.key);

        if (this.config.properties.multiple) {
            this.multiple = true;
        }

        const initVal = this.data.find(x => x.key === this.config.properties.key);

        if (initVal && initVal.value) {
            this.questionService
                .getUploadFile(initVal.value)
                .then(fileRes => {
                    this.fileLoaded = fileRes;
                })
                .catch(err => {
                    this.fileLoaded = {
                        _id: null,
                        originalname: 'Erreur lors de la récupération votre fichier',
                    };
                });
        }
    }

    clickOnInput() {
        if (this.uploadComponent) {
            this.uploadComponent.clickOnInput();
        }
    }

    deleteFile() {
        const key = this.config.properties.key;
        const valueToDelete = {
            site: this.siteId,
            zone: this.zoneId,
            keys: [key],
            fileId: this.fileLoaded._id,
        };

        this.questionService.deleteFiles(valueToDelete).then(res => {
            this.fileLoaded = null;
        });
    }
}
