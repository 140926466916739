import { Component, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';

import { Company } from 'app/shared/models/company.interface';
import { map } from 'rxjs/operators';
import { RrefMergeService } from './rref-merge.service';

@Component({
    selector: 'rref-merge-tab',
    templateUrl: './rref-merge.component.html',
    styleUrls: ['./rref-merge.component.scss'],
    providers: [RrefMergeService],
})
export class RrefMergeComponent implements OnInit {
    /** Company to administrate */
    @Input() company: Company;

    /**
     * Indicates current column with action.
     * 0 : no routing reference selected (left col)
     * 1 : destination routing reference selected (middle col)
     * 2 : preview routing reference displayed
     */
    public currentColumn = 0;

    constructor(private rrefMergeService: RrefMergeService) {}

    async ngOnInit() {
        // Init service
        await this.rrefMergeService.init(this.company);
        this.initCurrentColumnEvent();
    }

    /**
     * Init current column display event management
     */
    private initCurrentColumnEvent() {
        combineLatest([this.rrefMergeService.rRefDestination$, this.rrefMergeService.rRefPreview$])
            .pipe(
                map(([destination, preview]) => {
                    if (!destination) {
                        return 0;
                    }
                    if (!preview) {
                        return 1;
                    }
                    return 2;
                })
            )
            .subscribe({
                next: index => {
                    this.currentColumn = index;
                },
            });
    }
}
