import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-rse',
    templateUrl: './rse.component.html',
    styleUrls: ['./rse.component.scss'],
    providers: [],
})
export class RseComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
