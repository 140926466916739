import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as BlueBird from 'bluebird';
import Swal from 'sweetalert2';
import { ApiService } from '../../../services/api/api.service';
import { PaginationService } from '../../pagination/front/pagination-front.service';
import { UnknownFile } from './not-find-table.interface';

@Component({
    selector: 'ga-not-find-table',
    templateUrl: './not-find-table.component.html',
    styleUrls: ['./not-find-table.component.scss'],
    providers: [ApiService, PaginationService],
})
export class NotFindTableComponent implements OnInit {
    /**
     * List of unkwown files to display
     */
    @Input() files: UnknownFile[];

    /**
     * Event sent to remove one or multiple files
     */
    @Output() removeUnknownFiles: EventEmitter<UnknownFile[]> = new EventEmitter<UnknownFile[]>();

    /**
     * Event sent to reextract the file
     */
    @Output() reextractFile: EventEmitter<UnknownFile> = new EventEmitter<UnknownFile>();

    /**
     * Used to disable all the buttons and prevent any action during a temporary period
     */
    @Input() disableActions = false;

    constructor(private paginationService: PaginationService) {}

    ngOnInit() {}

    /**
     * Launch popup to confirm the removal of one or all files and alert parent component.
     * Do not remove file here.
     * @param {UnknownFile[]} files - files to remove
     */
    async remove(files: UnknownFile[]): Promise<void> {
        const result = await Swal.fire({
            title: 'Êtes-vous sur ?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: files.length > 1 ? 'Tout supprimer' : 'Supprimer',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        });

        if (result.value) {
            this.removeUnknownFiles.emit(files);
        }
    }

    /**
     * Launch popup to confirm the re-extraction of of a file and alert parent component.
     * Do not re-extract file here.
     * @param {UnknownFile} file - file to re-extract
     */
    async reextract(file: UnknownFile): Promise<void> {
        const result = await Swal.fire({
            title: 'Êtes-vous sur ?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ré-extraire',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        });

        if (result.value) {
            this.reextractFile.emit(file);
        }
    }

    /**
     * Returns the list of  the files to display, respecting the current pagination
     * @returns {UnknownFile[]} files to display
     */
    get filesSorted(): UnknownFile[] {
        return this.files.slice(
            this.paginationService.pagination.indexStart,
            this.paginationService.pagination.indexEnd + 1
        );
    }
}
