import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/api/api.service';
import { PageService } from '../../shared/services/page/page.service';

@Injectable()
export class ResetPasswordService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    resetOrActivate(data, token, isActivation, isSSOActivation) {
        const form = {
            password: data.password,
            passwordCheck: data.passwordCheck,
            token,
        };
        let route = '/api/auth/reset-password';

        if (isActivation) {
            route = isSSOActivation ? '/auth/activate-account' : '/api/auth/activate-account';
        }
        return this.post(route, form);
    }
    /**
     * Fills prerequisitesValidated and prerequisitesMissing according to the result of regex :
     * - contains a lower case
     * - contains a upper case
     * - contains a digit
     * - contains a special character
     * - contains at least 8 characters
     * @param {string} password
     * @return {{ prerequisitesMissing: boolean[]; prerequisitesValidated: boolean[] }}
     */
    public getPrerequisitesValuesOfPassword(
        password: string
    ): { prerequisitesMissing: boolean[]; prerequisitesValidated: boolean[] } {
        const result = {
            prerequisitesMissing: [],
            prerequisitesValidated: [],
        };

        const prerequisites = {
            charCount: password.length >= 8,
            lower: /[a-z]/.test(password),
            upper: /[A-Z]/.test(password),
            digit: /\d/.test(password),
            special: /\W/.test(password),
        };
        for (const check in prerequisites) {
            if (prerequisites[check]) {
                result.prerequisitesValidated.push(check);
            } else {
                result.prerequisitesMissing.push(check);
            }
        }
        return result;
    }
}
