import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { DateRange, DateRangeLimit, RangePickerConfig } from './date-range-picker.interface';

import * as _ from 'lodash';
import * as moment from 'moment';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { frLocale } from 'ngx-bootstrap/locale';
defineLocale('fr', frLocale);

@Component({
    selector: 'ga-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit {
    /** used to disable the date range picker input */
    @Input() isDisabled: boolean;

    /** date range picker configurations */
    @Input() dateRangePickerConfig: RangePickerConfig;

    /**date range limit (minDate & maxDate) */
    @Input() dateRangeLimit: DateRangeLimit;

    /** emit the selected period values */
    @Output() dateRangeChanged: EventEmitter<DateRange> = new EventEmitter<DateRange>();

    /** emit the number of days in the selected period */
    @Output() daysRangeChanged: EventEmitter<number> = new EventEmitter<number>();

    /** Placeholder of the date range input */
    public placeholder: string;

    /** date range value for the input element: [fromDate, toDate] */
    public dateRangeValue: Date[];

    /** date range data used to store fromDate and toDate values */
    _dateRange: DateRange;
    get dateRange(): DateRange {
        return this._dateRange;
    }
    /** allow date range to be set from parent component */
    @Input() set dateRange(value: DateRange) {
        if (this.isNewDate(value)) {
            this.dateRangeValue = [value.fromDate.date, value.toDate.date];
            this._dateRange = value;
        }
    }

    /** Number of days in the current selection */
    public nbDays: number;

    constructor(private localeService: BsLocaleService) {}

    ngOnInit() {
        /** set language */
        this.localeService.use('fr');

        /** init placeholder */
        this.placeholder = 'jj/mm/aaaa - jj/mm/aaaa';

        /** init dateRange values */
        const defaultDateRange = {
            fromDate: {
                date: null,
                utcDate: null,
            },
            toDate: {
                date: null,
                utcDate: null,
            },
        };
        this.dateRange = { ...defaultDateRange, ...this.dateRange };

        /* init the nb of days selected */
        this.nbDays = 0;

        /**
         * set the date range picker configurations
         */
        const defaultConfig = {
            containerClass: 'theme-default',
            dateInputFormat: 'DD/MM/YYYY',
            displayDays: true,
            placement: 'bottom',
        };
        this.dateRangePickerConfig = { ...defaultConfig, ...this.dateRangePickerConfig };
    }

    /**
     * Event triggered when the date range is selected
     * @param {Date[]} dateRange - date range selected
     */
    public onDateRangeChange(dateRange: Date[]) {
        const dateRangeKeys = ['fromDate', 'toDate'];
        if (dateRange && dateRange.length === 2) {
            dateRangeKeys.forEach((property: string, index: number) => {
                const date: Date = dateRange[index];
                /**
                 * Ngx-bootstrap has a bug : it doesn't care of the timezones.
                 * So we need to extract only year, month and day from date, not considering hours, minutes, ...
                 */
                const utcDate: Date = date
                    ? moment.utc({ y: date.getFullYear(), M: date.getMonth(), d: date.getDate() }).toDate()
                    : null;

                _.set(this.dateRange, property, { date, utcDate });
            });
        } else {
            // reset date range
            dateRangeKeys.forEach((property: string, index: number) => {
                _.set(this.dateRange, property, {
                    date: null,
                    utcDate: null,
                });
            });
        }

        // compute the new nb of days
        this.setNbDaysSelected();
        // emit event that range has changed
        this.emitDateRangeChanged();
        this.emitRangeDaysChanged();
    }

    /**
     * Compute the current number of days selected
     * @returns {number | null}
     */
    private setNbDaysSelected(): number {
        if (!this.isValidDateRange) {
            this.nbDays = 0;
            return null;
        }
        const fromDate: moment.Moment = moment.utc(this.dateRange.fromDate.utcDate);
        const toDate: moment.Moment = moment.utc(this.dateRange.toDate.utcDate).add(1, 'day');
        this.nbDays = moment.utc(toDate).diff(fromDate, 'days');
    }

    /**
     * Returns the correct key to display for the number of days (singular or plural)
     * @returns {string} key
     */
    get messageKey(): string {
        return this.nbDays > 1 ? 'days' : 'day';
    }

    /**
     * Return true if the dateRange has all data set
     * @returns {boolean}
     */
    get isCompleteDateRange(): boolean {
        const dates = ['fromDate.date', 'toDate.date', 'fromDate.utcDate', 'toDate.utcDate'];
        return dates.every(d => _.get(this.dateRange, d));
    }

    /**
     * return true if the dateRange has the fromDate before or same as toDate
     * @returns {boolean}
     */
    get isValidDateRange(): boolean {
        if (!this.isCompleteDateRange) {
            return false;
        }
        const fromDate: moment.Moment = moment.utc(this.dateRange.fromDate.utcDate);
        const toDate: moment.Moment = moment.utc(this.dateRange.toDate.utcDate);
        return fromDate.isValid() && toDate.isValid() && toDate.isSameOrAfter(fromDate);
    }

    /**
     * Emit the selected range period values
     * @returns {void}
     */
    private emitDateRangeChanged(): void {
        this.dateRangeChanged.emit(this.dateRange);
    }

    /**
     * Emit the number of selected days
     * @returns {void}
     */
    private emitRangeDaysChanged(): void {
        this.daysRangeChanged.emit(this.nbDays);
    }

    /**
     * Returns true if a new valid date is incoming
     * @param {DateRange} dateRange - specified date range
     * @returns {boolean}
     */
    private isNewDate(newRange: DateRange): boolean {
        const fields = ['date', 'utcDate'];
        const rangePickers = ['fromDate', 'toDate'];
        if (!this.dateRange || !newRange || !this.dateRangeValue) {
            return true;
        }

        const isNewOrInvalid = fields.some(dateField => {
            return rangePickers.some((range, i) => {
                // returns true as soon as it finds an invalid date or a different date
                const isInvalid =
                    !moment(this.dateRangeValue[i]).isValid() || !moment(newRange[range][dateField]).isValid();

                const isDifferent =
                    moment(this.dateRangeValue[i]).format('YYYY-MM-DD') !==
                    moment(newRange[range][dateField]).format('YYYY-MM-DD');
                return isInvalid || isDifferent;
            });
        });
        return isNewOrInvalid;
    }

    /**
     * @returns {string} grid properties to grant the correct nb of columns
     */
    get inputColClass(): string {
        // leave space to display the number of days if needed
        return this.dateRangePickerConfig.displayDays ? 'col-lg-12 col-xl-10 pb-lg-2' : 'col-sm-12';
    }
}
