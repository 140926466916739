import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { BillControlSection, ControlConfig, ControlSectionConfig } from 'app/shared/models/bills-controls.interface';
import { BillControlItem } from 'app/shared/models/bills-controls.interface';
import { BillVerification, ChunkControlsRecap } from 'app/shared/models/bills.interface';

@Injectable()
export class BillsControlService {
    private readonly config: ControlConfig = {
        elec: [
            {
                key: 'furniture_price',
                label: 'Prix de la fourniture',
                controls: [
                    {
                        key: 'elec_energy_pricing_base',
                        label: `Montant de l'énergie Base`,
                        items: [],
                    },
                    {
                        key: 'elec_energy_pricing_pointe',
                        label: `Montant de l'énergie Pointe`,
                        items: [],
                    },
                    {
                        key: 'elec_energy_pricing_hp',
                        label: `Montant de l'énergie HP`,
                        items: [],
                    },
                    {
                        key: 'elec_energy_pricing_hc',
                        label: `Montant de l'énergie HC`,
                        items: [],
                    },
                    {
                        key: 'elec_energy_pricing_hph',
                        label: `Montant de l'énergie HPH`,
                        items: [],
                    },
                    {
                        key: 'elec_energy_pricing_hch',
                        label: `Montant de l'énergie HCH`,
                        items: [],
                    },
                    {
                        key: 'elec_energy_pricing_hpe',
                        label: `Montant de l'énergie HPE`,
                        items: [],
                    },
                    {
                        key: 'elec_energy_pricing_hce',
                        label: `Montant de l'énergie HCE`,
                        items: [],
                    },
                ],
            },
            {
                key: 'turpe',
                label: 'Turpe',
                controls: [
                    {
                        key: 'elec_turpe_managementFee',
                        label: 'Composante de gestion',
                        items: [],
                    },
                    {
                        key: 'elec_turpe_countingFee',
                        label: 'Composante de comptage',
                        items: [],
                    },
                    {
                        key: 'elec_turpe_fixedRackingFee',
                        label: 'Composante de soutirage fixe',
                        items: [],
                    },
                    {
                        key: 'elec_turpe_rackingFee',
                        label: 'Composante de soutirage',
                        items: [],
                    },
                ],
            },
            {
                key: 'taxes',
                label: 'Taxes',
                controls: [
                    {
                        key: 'elec_amount_cspe',
                        label: 'Montant de la CSPE',
                        items: [],
                    },
                    {
                        key: 'elec_cta_rate',
                        label: 'Montant de la CTA',
                        items: [],
                    },
                ],
            },
        ],
        gaz: [
            {
                key: 'gaz_consumption',
                label: 'Consommation de gaz',
                controls: [
                    {
                        key: 'gaz_energy_pricing',
                        label: 'Montant Facturé',
                    },
                ],
            },
            {
                key: 'gaz_taxes',
                label: 'Taxes',
                controls: [
                    {
                        key: 'gaz_ticgn_amount',
                        label: 'TICGN',
                    },
                ],
            },
            {
                key: 'gaz_subscription',
                label: 'Abonnement',
                controls: [
                    {
                        key: 'gaz_subscription_amount',
                        label: 'Abonnement',
                    },
                ],
            },
        ],
    };

    constructor() {}

    /**
     * Return the config depending on the fluid
     *
     * @param {string} fluid
     * @returns {ControlSectionConfig[]}
     */
    public getControlSectionConfig(fluid: string): ControlSectionConfig[] {
        return this.config[fluid];
    }

    /**
     * Get controls by fluid
     * @returns {{[fluid: string]: Array<{ value: string; name: string }>}}
     */
    public getControlsByFluid(fluids?: string[]): { [fluid: string]: Array<{ value: string; name: string }> } {
        return Object.keys(this.config).reduce((fluidMemo, f) => {
            if (fluids && !fluids.includes(f)) {
                return fluidMemo;
            }
            fluidMemo[f] = this.config[f].reduce((c: Array<{ value: string; name: string }>, x) => {
                const values = x.controls.map(y => ({ value: y.key, name: y.label }));
                return c.concat(values);
            }, []);
            return fluidMemo;
        }, {});
    }

    /**
     * Return the controls with the label and the sub controls
     *
     * @param {ControlSectionConfig[]} config
     * @param {BillVerification[]} verifications
     * @returns {BillControlItem[]}
     */
    getSections(config: ControlSectionConfig[], verifications: BillVerification[]): BillControlSection[] {
        const controlSections: BillControlSection[] = [];
        /**
         * A config has many sections.
         * Each section can have many controls.
         * Each control can have many subcontrols
         * */
        config.forEach(conf => {
            const sectionControls: BillControlItem[] = [];
            const section = {
                label: conf.label,
                key: conf.key,
                items: sectionControls,
            };

            // add controls to section
            conf.controls.forEach(control => {
                // find values for this control
                const controlVerif = _.find(verifications, { key: control.key });
                if (controlVerif) {
                    // add control with its values ans label
                    const sectionSubControls: BillControlItem[] = [];
                    const sectionControl = Object.assign(controlVerif, {
                        label: control.label,
                        items: sectionSubControls,
                    });
                    sectionControls.push(sectionControl);

                    // add subcontrols to the current control
                    const subControls = control.items;
                    if (subControls && subControls.length > 0) {
                        subControls.forEach(subControl => {
                            // find values for this subControl
                            const subControlVerif = _.find(verifications, { key: subControl.key });

                            if (subControlVerif) {
                                // add subcontrol with its values and label
                                const sectionSubControl = Object.assign(subControlVerif, {
                                    label: subControl.label,
                                    items: [],
                                });
                                sectionSubControls.push(sectionSubControl);
                            }
                        });
                    }
                }
            });
            controlSections.push(section);
        });
        return controlSections;
    }

    /**
     * Get the priority from the given control's recap
     * @param {ChunkControlsRecap} recap
     * @returns {number} priority
     */
    getChunkPriorityFromRecap(recap: ChunkControlsRecap): number {
        if (recap.isValid && recap.balance !== null) {
            return 1;
        }
        // overcharged > underchared
        if (recap.balance > 0) {
            return 3;
        }
        // overcharged < underchared
        if (recap.balance < 0) {
            return 2;
        }
        return 0;
    }

    isNotAplicable(verification: BillVerification) {
        return !verification.isValid && !verification.ratio;
    }
    isUndercharged(verification: BillControlItem | BillVerification) {
        return verification.difference < 0; // don't check ratio cause can be null when expected value = 0 and notice < 0
    }
    isOvercharged(verification: BillControlItem | BillVerification) {
        return verification.difference > 0; // don't check ratio for same reason
    }
    isValid(verification: BillControlItem | BillVerification) {
        return verification.isValid;
    }

    /**
     * Returns the control's priority from its values and validity
     * @param {BillControlItem} control
     * @returns {number} priority
     */
    getControlPriority(control: BillControlItem) {
        // paid more than expected (noticed > expected)
        if (this.isOvercharged(control)) {
            return 3;
        }
        // paid less than expected (noticed < expected)
        if (this.isUndercharged(control)) {
            return 2;
        }
        // control correct (noticed === expected)
        if (this.isValid(control)) {
            return 1;
        }

        return 0;
    }

    /**
     * Filter the chunk verifications with the current config verifications.
     * Keep only the verifications found in the controls.
     * @param {string} fluid - bill or chunk fluid
     * @param {BillVerification[]} verifications - bill or chunk verifications
     * @returns {BillVerification[]}
     */
    filterBillVerifications(fluid: string, verifications: BillVerification[]): BillVerification[] {
        const config = this.getControlSectionConfig(fluid);
        if (config) {
            // flatten controls from each section in the config
            const configVerifications = _.flatten(config.map(section => section.controls));
            // filter the chunk verifications and keep only those in the config
            return _.intersectionBy(verifications, configVerifications, 'key');
        }
        return [];
    }

    /**
     * Return the icon based on the priority number
     *
     * @param {number} priority
     * @returns {string}
     */
    getPriorityIcon(priority: number): string {
        let icon = '';
        switch (priority) {
            case 3:
                icon = 'fa-arrow-up';
                break;

            case 2:
                icon = 'fa-arrow-down';
                break;

            case 1:
                icon = 'fa-check';
                break;

            case 0:
                icon = 'fa-circle';
                break;

            default:
                icon = 'fa-circle';
                break;
        }

        return icon;
    }
}
