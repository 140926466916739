import { Injectable } from '@angular/core';
import { ENTITY_TYPE, ExternalProvider } from 'app/shared/models/external-providers.interface';

import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

export interface UpdateExternalProviders {
    entityId: string;
    entityType: ENTITY_TYPE;
    provider: ExternalProvider;
}

@Injectable()
export class ExternalProvidersService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    public updateExternalProvider(
        entityType: ENTITY_TYPE,
        entityId: string,
        data: ExternalProvider
    ): Promise<ExternalProvider> {
        return this.post(`/api/external-provider/${entityType}/${entityId}`, data);
    }

    public updateExternalProviders(data: UpdateExternalProviders[]): Promise<ExternalProvider[]> {
        return this.post(`/api/external-provider/bulk`, data);
    }
}
