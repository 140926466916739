import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { PageService } from '../../../shared/services/page/page.service';

@Injectable()
export class AdminIdentificationPatternService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Create Identification Pattern
     *
     * @param {*} identificationPattern
     * @returns {Promise<*>}
     */
    async createIdentificationPattern(identificationPattern: any): Promise<any> {
        try {
            const route = '/api/identification-patterns/crud/';
            const res = await this.post(route, identificationPattern);
            return res.data;
        } catch (err) {
            err.error_code = 'error_createNewIdentificationPattern';
            throw err;
        }
    }

    /**
     * Get the text content of the upload
     *
     * @param {string} id - id of the upload
     * @returns {Promise<*>}
     */
    async getUploadTextContentById(id: string): Promise<any> {
        try {
            const res = await this.get('/api/uploads/text-content/' + id);
            return res.data;
        } catch (err) {
            err.error_code = 'error_loadUpload';
            throw err;
        }
    }

    /**
     * Get the text content of the specified chunk of the upload
     *
     * @param {string} id - id of the upload
     * @param {string} idChunk - id of the chunk
     * @returns {Promise<*>}
     */
    async getUploadChunkTextContentById(id: string, idChunk: string): Promise<any> {
        try {
            const res = await this.get('/api/uploads/text-content/' + id + '/chunk/' + idChunk);
            return res.data;
        } catch (err) {
            err.error_code = 'error_loadUpload';
            throw err;
        }
    }
}
