import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { SessionService } from '../session/session.service';

/**
 * This class helps you make all your API calls given environment URL and availables methods
 */
@Injectable()
export class ApiService {
    url = '';

    constructor(public http: HttpClient, public sessionService: SessionService) {
        this.url = environment.apiUrl;
    }

    public getToken() {
        return this.sessionService.getToken();
    }

    public getUserId(): string {
        const user = this.sessionService.getUser();

        if (user) {
            return user._id;
        } else {
            return '';
        }
    }

    /**
     * Get company id from session
     * @returns {string}
     */
    public getCompanyId(): string {
        const companyId = this.sessionService.getCompanyId();
        if (companyId) {
            return companyId;
        }
        return '';
    }

    public get(route: string, options = null, data = null): Observable<any> {
        let httpOptions = options;

        if (!httpOptions) {
            const headers = new HttpHeaders({
                Authorization: 'Basic ' + this.getToken(),
                'Content-Type': 'application/json',
            });

            httpOptions = { headers };
        }

        if (!httpOptions.params) {
            httpOptions.params = {};
        }
        if (data) {
            Object.assign(httpOptions.params, data);
        }
        // If URL doesn't contain companyId params, add it
        const urlParams = new URLSearchParams(route);
        if (!httpOptions.params.companyId && !urlParams.get('companyId')) {
            Object.assign(httpOptions.params, {
                companyId: this.sessionService.getCompanyId(),
            });
        }
        return this.http.get(this.url + route, httpOptions);
    }

    public post(route: string, data: any, options: any = null): Observable<any> {
        let httpOptions = options;

        if (!httpOptions) {
            const headers = new HttpHeaders({
                Authorization: 'Basic ' + this.getToken(),
                'Content-Type': 'application/json',
            });

            httpOptions = { headers };
        }
        if (!data || !data.companyId) {
            Object.assign(data, { companyId: this.sessionService.getCompanyId() });
        }

        return this.http.post(this.url + route, data, httpOptions).pipe(timeout(3600000));
    }

    public put(route: string, data: any, options: any = null, force: boolean = false): Observable<any> {
        let httpOptions = options;

        if (!httpOptions) {
            const headers = new HttpHeaders({
                Authorization: 'Basic ' + this.getToken(),
                'Content-Type': 'application/json',
            });

            httpOptions = { headers };
        }

        Object.assign(httpOptions, {
            params: {
                companyId: data && data.companyId ? data.companyId : this.sessionService.getCompanyId(),
                force,
            },
        });

        return this.http.put(this.url + route, data, httpOptions);
    }

    public delete(route: string, options: any = null): Observable<any> {
        let httpOptions = options;

        if (!httpOptions) {
            const headers = new HttpHeaders({
                Authorization: 'Basic ' + this.getToken(),
            });

            httpOptions = { headers };
        }

        return this.http.delete(this.url + route, httpOptions);
    }

    public upload(route: string, formData: any, options) {
        const req = new HttpRequest('POST', this.url + route, formData, options);
        return this.http.request(req);
    }

    public patch(route: string, data: any, options: any = null): Observable<any> {
        let httpOptions = options;

        if (!httpOptions) {
            const headers = new HttpHeaders({
                Authorization: 'Basic ' + this.getToken(),
                'Content-Type': 'application/json',
            });

            httpOptions = { headers };
        }

        Object.assign(httpOptions, {
            params: {
                companyId: data && data.companyId ? data.companyId : this.sessionService.getCompanyId(),
            },
        });

        return this.http.patch(this.url + route, data, httpOptions);
    }
}
