import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { ScatterPlotPoint } from 'app/shared/models/charts/chart-serie.interface';
import { ChartService } from 'app/shared/services/chart/chart.service';
import * as _ from 'lodash';

interface Statistics {
    mean: {
        [key: string]: number;
    };
    median: {
        [key: string]: number;
    };
}

export interface PropertyDescription {
    key: string; // key indicates the property used inside each Point to get the statistics inputs
    unit: string;
    unitType: string;
}

@Component({
    selector: 'ga-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss'],
    providers: [],
})
export class StatisticsComponent implements OnInit, OnChanges {
    @Input() points: ScatterPlotPoint[] = [];
    @Input() properties: PropertyDescription[] = [];

    statistics: Statistics = {
        mean: {},
        median: {},
    };

    constructor(private chartService: ChartService) {}

    ngOnInit(): void {
        this.computeStatistics();
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (const propName in changes) {
            if (propName === 'points' || propName === 'inputsGroup') {
                this.computeStatistics();
            }
        }
    }

    /**
     * Compute statistics (mean & median)
     */
    computeStatistics(): void {
        this.initStatistics();

        if (this.points && this.points.length) {
            this.computeMeans();
            this.computeMedians();
        }
    }

    /**
     * Init statistics to zero. Used when points on the chart change.
     */
    initStatistics(): void {
        this.properties.forEach(property => {
            this.statistics.mean[property.key] = 0;
            this.statistics.median[property.key] = 0;
        });
    }

    /**
     * Compute means for current points
     */
    computeMeans(): void {
        this.properties.forEach(property => {
            this.statistics.mean[property.key] = _.meanBy(this.points, property.key);
        });
    }

    /**
     * Compute medians for current points (x and y values)
     */
    computeMedians(): void {
        const nbSerieValues = this.points.length;

        this.properties.forEach(property => {
            // sort values
            const values = this.points.map(p => p[property.key]);
            const sortedValues = _.sortBy(values);

            // even arrays - take the mean of the two points in the middle
            if (nbSerieValues % 2 === 0) {
                const indexMiddle = nbSerieValues / 2;
                this.statistics.median[property.key] = _.mean([
                    sortedValues[indexMiddle - 1],
                    sortedValues[indexMiddle],
                ]);
            } else {
                // odd arrays - take the middle point
                const indexMedian = (nbSerieValues - 1) / 2;
                this.statistics.median[property.key] = sortedValues[indexMedian];
            }
        });
    }

    /**
     * Returns the statistic (value + unit) to display for the given axis and stat name
     * @param {PropertyDescription} property
     * @param {string} statisticName
     * @returns {string} statistic value and unit to display
     */
    getStatitic(property: PropertyDescription, statisticName: string): string {
        const value = this.statistics[statisticName][property.key];
        const unit = property.unit;
        const unitType = property.unitType;

        return this.chartService.getNumberToDisplay(value, unit, unitType);
    }
}
