import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { ToggleButtonConfig, ToggleButtonSelectionType, ToggleValue } from './toggle-buttons.interface';

@Component({
    selector: 'ga-toggle-buttons',
    templateUrl: './toggle-buttons.component.html',
    styleUrls: ['./toggle-buttons.component.scss'],
})
export class ToggleButtonsComponent implements OnInit {
    @Input() selectedDefaultValues: string[];
    @Input() provider: any;

    /**
     * Get the selection config for the buttons selection
     */
    @Input() config: ToggleButtonConfig = {
        selectionType: 'multiple',
    };

    /**
     * Property that store the selection type
     */
    public selectionType: ToggleButtonSelectionType;

    /**
     * Get the energies items and init the selection depending on the selection config
     */
    private _values: ToggleValue[];
    @Input() set values(values: ToggleValue[]) {
        this._values = values;
        this.initItemSelection(this.selectionType);
    }
    get values(): ToggleValue[] {
        return this._values;
    }

    /**
     * Emit the selected fluid types to the parent component to update the query
     */
    @Output() currentValuesSelectedChange = new EventEmitter<ToggleValue[]>();

    constructor(private filterService: FilterService) {}

    ngOnInit() {
        if (this.values) {
            this.values.forEach((item: ToggleValue) => {
                if (this.selectedDefaultValues && this.selectedDefaultValues.length) {
                    if (this.selectedDefaultValues.includes(item.value)) {
                        item.selected = true;
                    }
                } else {
                    item.selected = true;
                }
            });
            this.currentValuesSelectedChange.emit(this.values);
        } else {
            this.getProvidedValues().then(res => {
                this.values = res.data;
                this.values.forEach(item => {
                    item.selected = true;
                });
                this.currentValuesSelectedChange.emit(this.values);
            });
        }

        // Check if config exists and if it doesn't, set a selection type by default
        this.selectionType = this.config && this.config.selectionType ? this.config.selectionType : 'multiple';
    }

    /**
     * Activate or deactive buttons depending on the selection type :
     *    - single: possibility to select only one element
     *    - multiple: possibility to select many element
     *    - none: buttons are disabled but possibility to select it
     * @param {ToggleButtonSelectionType} selectionType
     * @returns {void}
     */
    private initItemSelection(selectionType: ToggleButtonSelectionType): void {
        switch (selectionType) {
            case 'single':
                // Deselect all the energy buttons, except the first
                this.values.forEach((value: ToggleValue, index: number) => {
                    index === 0 ? (value.selected = true) : (value.selected = false);
                });
                break;

            case 'none':
                // Deselect all the energy buttons
                this.values.forEach((value: ToggleValue) => {
                    value.selected = false;
                });
                break;

            case 'multiple':
            default:
                this.values.forEach((value: ToggleValue) => {
                    value.selected = true;
                });
                break;
        }
    }

    /**
     * Call a different method to activate or deactivate a button according to the selection type
     * @param {ToggleValue} item
     */
    selectItem(item: ToggleValue) {
        switch (this.selectionType) {
            case 'single':
                this.setSingleActive(item);
                break;

            case 'none':
            case 'multiple':
            default:
                this.toggle(item);
                break;
        }
    }

    /**
     * Toggle the selected property value of an energy item
     * @param {ToggleValue} item
     */
    public toggle(item: ToggleValue) {
        item.selected = !item.selected;
        this.currentValuesSelectedChange.emit(this.values);
    }

    getProvidedValues() {
        return this.filterService.getQueryResultByProvider(this.provider);
    }

    /**
     * Set a class depending on the selected property of an energy item
     * @param {ToggleValue} item
     * @returns {string}
     */
    getClass(item: ToggleValue): string {
        return item.selected ? 'activeToggle' : 'inactiveToggle';
    }

    /**
     * If filters config selection is single, disable the multi selection
     * It also prevents item button deselection
     * @param {ToggleValue} item
     */
    setSingleActive(item: ToggleValue) {
        // Deselect all items
        this.values.forEach(value => {
            value.selected = false;
        });

        // Set selected true to the energy selected
        item.selected = true;

        this.currentValuesSelectedChange.emit(this.values);
    }
}
