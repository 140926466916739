import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScenarioDisplay } from 'app/shared/models/scenario.model';
import { PaginationService } from '../../pagination/front/pagination-front.service';

@Component({
    selector: 'ga-alerts-mgmt-table',
    templateUrl: './alerts-mgmt-table.component.html',
    styleUrls: ['./alerts-mgmt-table.component.scss'],
    providers: [PaginationService],
})
export class AlertsManagementTableComponent implements OnInit {
    _scenarios: ScenarioDisplay[] = [];
    /**
     * All scenarios that can be displayed (not including pagination)
     */
    @Input()
    set scenarios(scenarios: ScenarioDisplay[]) {
        this._scenarios = scenarios;
        if (this.paginationService.pagination) {
            this.computeScenariosToDisplay();
        }
        this.cleanSelected(scenarios);
    }
    get scenarios(): ScenarioDisplay[] {
        return this._scenarios;
    }

    /**
     * Display table as loading or not.
     */
    @Input() isLoading = false;

    @Output() openView: EventEmitter<ScenarioDisplay> = new EventEmitter<ScenarioDisplay>();
    @Output() openEdit: EventEmitter<ScenarioDisplay> = new EventEmitter<ScenarioDisplay>();

    /**
     * Event triggered to archive a list of scenarios
     */
    @Output() archive: EventEmitter<string[]> = new EventEmitter<string[]>();

    /**
     * Scenarios to be displayed, including filters (pagination)
     */
    scenariosDisplay: ScenarioDisplay[] = [];

    /**
     * Scenarios selected by checkboxes.
     * Use of object instead of array for performances.
     */
    scenariosSelected: {
        [id: string]: ScenarioDisplay;
    } = {};

    constructor(private paginationService: PaginationService) {}

    ngOnInit() {
        // No need to call computeScenariosToDisplay as the pagination fires it automatically.
    }

    /**
     * Compute scenarios that must be displayed, regarding the pagination
     */
    computeScenariosToDisplay() {
        this.scenariosDisplay = [];
        if (!this.paginationService.pagination) {
            return;
        }

        for (
            let i = this.paginationService.pagination.indexStart;
            i <= this.paginationService.pagination.indexEnd;
            i++
        ) {
            if (this.scenarios[i]) {
                this.scenariosDisplay.push(this.scenarios[i]);
            }
        }
    }

    /**
     * Archive selected scenarios
     */
    archiveSelected() {
        const ids = this.getAllSelectedAsArray();
        if (ids.length > 0) {
            this.archive.emit(ids);
        }
    }

    /**
     * Triggers open view scenario
     * @param {ScenarioDisplay} scenario
     */
    onViewScenario(scenario: ScenarioDisplay) {
        this.openView.emit(scenario);
    }

    /**
     * Triggers open edit scenario
     * @param scenario
     */
    onEditScenario(scenario: ScenarioDisplay) {
        this.openEdit.emit(scenario);
    }

    /** SELECTBOXES */

    /**
     * Returns true if given scenario is selected
     * @param {ScenarioDisplay} scenario
     * @returns {boolean} true if scenario is selected, false otherwise
     */
    isAlreadySelected(scenario: ScenarioDisplay): boolean {
        return Boolean(this.scenariosSelected[scenario._id]);
    }

    /**
     * Is all scenarios from the current page selected
     * @returns {boolean} true if all scenarios selected, false otherwise
     */
    isAllSelected(): boolean {
        return this.scenariosDisplay.every(x => this.isAlreadySelected(x));
    }

    /**
     * Is at least one scenario from the current page selected
     * @returns {boolean} true if at least one scenario selected, false otherwise
     */
    isSomeSelected(): boolean {
        return this.scenariosDisplay.some(x => this.isAlreadySelected(x));
    }

    /**
     * Is some but not all scenarios, from the current page, selected
     * @returns {boolean} true if some scenarios selected but not all
     */
    isSomeButNotAllSelected(): boolean {
        const some = this.isSomeSelected();
        const every = this.isAllSelected();
        return Boolean(some && !every);
    }

    /**
     * Toggle select all scenarios from current page
     * @param {boolean} checked true if checked, false if not checked.
     */
    selectAll(checked: boolean) {
        this.scenariosDisplay.forEach(scenario => {
            if (checked) {
                this.scenariosSelected[scenario._id] = scenario;
            } else {
                delete this.scenariosSelected[scenario._id];
            }
        });
    }

    /**
     * Toggle given scenario selection
     * @param {ScenarioDisplay} scenario
     */
    toggleSelected(scenario: ScenarioDisplay) {
        if (this.scenariosSelected[scenario._id]) {
            delete this.scenariosSelected[scenario._id];
        } else {
            this.scenariosSelected[scenario._id] = scenario;
        }
    }

    /**
     * Get all selected scenarios ids as an array
     * @returns {string[]} list of scenarios selected via checkboxes
     */
    getAllSelectedAsArray(): string[] {
        return Object.keys(this.scenariosSelected).reduce((memo: string[], id) => {
            if (this.scenariosSelected[id]) {
                memo.push(this.scenariosSelected[id]._id);
            }
            return memo;
        }, []);
    }

    /**
     * Get the number of selected scenarios
     * @returns {number} number of scenarios selected
     */
    getCountAllSelected(): number {
        return Object.keys(this.scenariosSelected).reduce((memo, id) => {
            if (this.scenariosSelected[id]) {
                memo++;
            }
            return memo;
        }, 0);
    }

    /**
     * Clean scenarios selected by removing not existing ones.
     * @param {ScenarioDisplay} scenarios - reference scenarios list
     */
    cleanSelected(scenarios: ScenarioDisplay[]) {
        Object.keys(this.scenariosSelected).forEach(id => {
            if (this.scenariosSelected[id]) {
                const search = scenarios.find(x => x._id === this.scenariosSelected[id]._id && !x.archived);
                if (!search) {
                    delete this.scenariosSelected[id];
                }
            } else {
                // For the scenarios that have been archived
                delete this.scenariosSelected[id];
            }
        });
    }

    /** DISPLAY GETTERS */

    getCountSelectedKey(): string {
        return this.getCountAllSelected() > 1 ? 'selected_fp' : 'selected_f';
    }

    /**
     * Get scenario mute flag language key
     * @param {ScenarioDisplay} scenario
     */
    getFlagKey(scenario: ScenarioDisplay): string {
        if (scenario.flagMute === false) {
            return 'active_f';
        }
        return 'inactive_f';
    }

    /**
     * Get scenario mute flag class.
     * @param {ScenarioDisplay} scenario
     */
    getFlagClass(scenario: ScenarioDisplay): string[] {
        if (scenario.flagMute === false) {
            return ['table__col-status--active'];
        }
        return [];
    }

    /**
     * Get scenario privacy language key
     * @param {ScenarioDisplay} scenario
     */
    getPrivacyKey(scenario: ScenarioDisplay) {
        if (scenario.public === false) {
            return 'no';
        }
        return 'yes';
    }
    /**
     * Get scenario type language key
     * @param {ScenarioDisplay} scenario
     */
    getTypeKey(scenario: ScenarioDisplay) {
        return 'threshold';
    }

    /**
     * Get scenario's scope to display
     * @param {ScenarioDisplay} scenario
     */
    getPerimeter(scenario: ScenarioDisplay): string {
        /**
         * Used with translate.
         * There is no chance that routing reference name matches any key, so it will be displayed normally.
         */
        const scope = scenario.condition.scope;
        if (scope.allRoutingReferences) {
            return 'routingreferences_all';
        }
        if (!scope.display) {
            return 'unknown';
        }
        if (scope.display.site && scope.display.routingReference) {
            return `${scope.display.site} - ${scope.display.routingReference}`;
        }
        if (scope.display.routingReference) {
            return scope.display.routingReference;
        }
        return 'unknown';
    }

    /** PAGINATION */

    /**
     * Update pagination and compute scenarios to be displayed
     */
    updatePagination() {
        this.computeScenariosToDisplay();
    }
}
