import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { AccessGuard } from 'app/shared/services/guards/access-guard.service';
import { SitesListComponent } from './sites-list.component';

const SITES_LIST_ROUTE = [
    {
        path: '',
        component: SitesListComponent,
        canActivate: [AccessGuard],
    },
];

@NgModule({
    declarations: [SitesListComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(SITES_LIST_ROUTE)],
})
export class SitesListModule {}
