import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Warrant } from 'app/shared/models/warrant.interface';
import { ExternalDataService } from 'app/shared/services/external-data/external-data.service';
import { EditWarrantParams } from 'app/shared/services/warrants/interfaces';
import { WarrantService } from 'app/shared/services/warrants/warrant.service';

/**
 * This service is designed to load necessary data for Enedis assignation tab.
 */
@Injectable()
export class EnedisWarrantService {
    private readonly ENEDIS_PROVIDER = 'enedis';
    private readonly ENEDIS_FLUID = 'elec';

    private readonly loadedWarrant = new Subject<Warrant>();

    public get loadedWarrant$(): Observable<Warrant> {
        return this.loadedWarrant.asObservable();
    }

    constructor(
        private readonly externalDataService: ExternalDataService,
        private readonly warrantService: WarrantService
    ) {}

    /** Load in memory most recent warrant for given company */
    public async loadLatestWarrant(companyId: string): Promise<void> {
        // Load corporate name for current company
        const corporateName = await this.externalDataService.getSocialDenomination(companyId);

        if (corporateName) {
            const warrant = await this.warrantService.getLatestWarrant({
                corporateName,
                provider: this.ENEDIS_PROVIDER,
                providerFluid: this.ENEDIS_FLUID,
            });

            this.loadedWarrant.next(warrant);
        }
    }

    /** Update warrant if provided one is not null, or create a new one otherwise */
    public async editWarrant(
        warrant: Warrant | null,
        fields: { corporateName: string; validityStart: string; validityEnd: string }
    ): Promise<Warrant> {
        const params: EditWarrantParams = {
            ...fields,
            warrantProvider: this.ENEDIS_PROVIDER,
            warrantProviderFluid: this.ENEDIS_FLUID,
        };

        if (warrant) {
            params.id = warrant._id;
        }

        const editedWarrant = await this.warrantService.editWarrant(params);

        this.loadedWarrant.next(editedWarrant);

        return editedWarrant;
    }
}
