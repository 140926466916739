import { Component, Input, OnInit } from '@angular/core';
import { Campaign } from 'app/shared/models/campaign.interface';
import { ChartService } from 'app/shared/services/chart/chart.service';
import * as moment from 'moment';

interface CampaignMergedData {
    date: string; // Date at ISO format
    unit: string;
    fluids: any; // Object that must contain campaignGoal
}

@Component({
    selector: 'ga-campaign-visualization',
    templateUrl: './campaign-visualization.component.html',
    styleUrls: ['./campaign-visualization.component.scss'],
    providers: [],
})
export class CampaignVisualizationComponent implements OnInit {
    private _campaign: Campaign;

    @Input()
    set campaign(campaign: Campaign) {
        this._campaign = campaign;
        this.init();
    }
    get campaign(): Campaign {
        return this._campaign;
    }

    chartProperties: any = {
        datasets: [],
        colors: null,
        labels: [],
        options: null,
        legends: [],
        minHeight: 250,
    };

    constructor(private chartService: ChartService) {}

    ngOnInit() {}

    private init() {
        const data = this.mergeCampaignData();
        this.handleChartDatasets(data);
        this.handleChartDesign();
    }

    /**
     * Handle chart design : point style, options, tooltips, colors, legends and tooltips energies.
     */
    private handleChartDesign() {
        const goalDS = this.chartProperties.datasets.find(x => x.label === 'campaignGoal');
        if (goalDS) {
            goalDS.pointStyle = this.goalPointImage;
        }
        this.chartProperties.options = this.chartService.getConfig('stacked', {
            tooltips: this.chartService.getTooltipHTMLConfig('stack', null),
        });
        const energyLabels = this.chartProperties.datasets.map(x => x.label);
        this.chartProperties.colors = this.chartService.getBarChartColors(energyLabels);

        this.chartProperties.legends = [];
        // Strictly superior than 2, because there is the goal dataset
        const hasMultipleEnergies = this.chartProperties.datasets.length > 2;
        this.chartProperties.datasets.forEach(dataset => {
            if (hasMultipleEnergies) {
                dataset.unit = 'kWhep';
            } else {
                dataset.unit = 'kWh';
            }
            this.chartProperties.legends.push(this.chartService.getLegend(dataset.label));
        });
    }

    /**
     * Compute campaign data to get data that can be easily exploited to create charts datasets
     * @returns {CampaignMergedData[]} campaign data merged by date with fluids consumption and goal targeted.
     */
    private mergeCampaignData(): CampaignMergedData[] {
        const data = this.campaign.results;
        /**
         * Blocs is an array as following :
         * {
         *    date: Date,
         *    unit: string
         *    fluids: {
         *       campaignGoal: number
         *       elec: number,
         *       gaz: number,
         *       ...
         *    }
         * }[]
         */
        const blocs: CampaignMergedData[] = [];

        // Search or create a bloc by date
        // Maybe instead of strictcly comparing date, we should compare only year and month.
        const searchBlocOrCreate = result => {
            let date = result.dateStart;
            if (typeof date === 'number') {
                date = moment(date).toISOString();
            }
            let bloc = blocs.find(x => x.date === date);
            if (!bloc) {
                bloc = {
                    date,
                    unit: result.values[0] ? result.values[0].unit : 'NC',
                    fluids: {
                        campaignGoal: null,
                    },
                };
                blocs.push(bloc);
            }
            return bloc;
        };
        // First we handle the goal, so it will be always first in lists so the first dataset.
        // It's important to have the goal as the first dataset to have points over bars
        // We add values, so fluids goals already merged and fluids goals not merged are accepted.
        data.goal.forEach(result => {
            const bloc = searchBlocOrCreate(result);
            if (bloc.fluids.campaignGoal === null) {
                bloc.fluids.campaignGoal = 0;
            }
            bloc.fluids.campaignGoal += result.values.reduce((memo, current) => {
                return (memo += current.quantity);
            }, 0);
        });

        // Add fluids values that have the same date.
        const extractDataArray = dataConso => {
            dataConso.forEach(result => {
                const bloc = searchBlocOrCreate(result);
                result.values.forEach(value => {
                    if (!bloc.fluids[value.energyType]) {
                        bloc.fluids[value.energyType] = 0;
                    }
                    bloc.fluids[value.energyType] += value.quantity;
                });
            });
        };
        extractDataArray(data.reference);
        extractDataArray(data.reached);

        // Sort by date ascending
        blocs.sort((a, b) => {
            return moment(a.date)
                .utc()
                .diff(moment(b.date).utc());
        });

        return blocs;
    }

    /**
     * Handle chart datasets from merged data.
     * A merged data is all values for a given date (goal + fluids data)
     * @param {CampaignMergedData[]} data - array of merged data, sorted by date ascending.
     */
    private handleChartDatasets(data: CampaignMergedData[]) {
        const labels = [];
        const fluidsData: Array<{
            label: string;
            data: number[];
        }> = [];
        // Get all fluids available (including campaignGoal)
        const fluids = data.reduce((memo, d) => {
            Object.keys(d.fluids).forEach(fluid => {
                if (!memo.includes(fluid)) {
                    memo.push(fluid);
                }
            });
            return memo;
        }, []);
        // For each data (= each date), get the label and create/populate datasets data values
        data.forEach(bloc => {
            labels.push(moment(bloc.date).format('MM-YYYY'));
            fluids.forEach(fluid => {
                let fluidData = fluidsData.find(x => x.label === fluid);
                if (!fluidData) {
                    fluidData = {
                        label: fluid,
                        data: [],
                    };
                    fluidsData.push(fluidData);
                }
                if (bloc.fluids[fluid]) {
                    fluidData.data.push(bloc.fluids[fluid]);
                } else {
                    fluidData.data.push(null);
                }
            });
        });
        this.chartProperties.datasets.splice(0, this.chartProperties.datasets.length);
        // Create and add the datasets to the chart
        fluidsData.forEach(fluidData => {
            this.addChartDataset(fluidData);
        });
        this.chartProperties.labels = labels;
    }

    /**
     * Creates dataset for chart depending on the fluid data type.
     * Stacked bar datasets for fluids and line dataset for campaignGoal.
     */
    private addChartDataset(fluidData: { label: string; data: number[] }) {
        let dataset = null;
        if (fluidData && fluidData.label !== 'campaignGoal') {
            dataset = {
                type: 'bar',
                label: fluidData.label,
                data: fluidData.data,
                stack: 'stack-0',
                unit: 'kWh',
            };
        } else if (fluidData) {
            dataset = {
                type: 'line',
                label: fluidData.label,
                data: fluidData.data,
                fill: false,
                showLine: false,
                pointStyle: 'circle',
                unit: 'kWh',
            };
        }
        if (dataset) {
            this.chartProperties.datasets.push(dataset);
        }
    }

    /**
     * Get the point image. Invisible at first (size 0x0)
     * then set size preserving ratio.
     */
    get goalPointImage() {
        const img = new Image(0, 0);
        img.src = '/assets/img/icons/level-symbol--orange.png';

        img.onload = () => {
            const ratio = img.naturalWidth / img.naturalHeight;
            const w = 25;
            const h = Math.floor(w / ratio);

            img.width = w;
            img.height = h;
        };
        return img;
    }

    get hasData() {
        return Boolean(
            this.campaign &&
                this.chartProperties &&
                this.chartProperties.datasets &&
                this.chartProperties.datasets.length &&
                this.chartProperties.datasets.some(x => x.data && x.data.length)
        );
    }
}
