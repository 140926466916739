import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-highchart-doughnut-toggle',
    templateUrl: './highchart-doughnut-toggle.component.html',
    styleUrls: ['./highchart-doughnut-toggle.component.scss'],
})
export class HighchartDoughnutToggleComponent implements OnInit {
    @Input() options: any;
    @Input() legend: any;
    @Input() toggleNames: string[] = [];
    @Input() downloadFileNames: string[] = [];

    @Input() activeDataset = 0;

    constructor() {}

    ngOnInit() {
        this.selectToggleWithData();
    }

    public getRgbColor(color) {
        return `rgb(${color})`;
    }

    public setActivePieChart(index: number) {
        this.activeDataset = index;
    }

    /**
     * Select the toggle on which the doughnut has data (TTC instead of HTVA)
     */
    public selectToggleWithData() {
        const indexWithData = this.findToggleIndexWithData();
        const indexSelected = indexWithData !== -1 ? indexWithData : 0;
        this.setActivePieChart(indexSelected);
    }

    /**
     * Returns false if one of the toggles has data to display
     * @return {boolean}
     */
    public isCurrentChartEmpty(): boolean {
        return this.findToggleIndexWithData() === -1 ? true : false;
    }

    /**
     * Returns the first toggle index with data
     * @return {number}
     */
    private findToggleIndexWithData(): number {
        return this.options.findIndex(toggleDataset =>
            // series[0] because it's a simple donut
            // returns true as soon as it finds a fluid dataset with values
            toggleDataset.series[0].data.some(fluidData => fluidData.y > 0)
        );
    }
}
