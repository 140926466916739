import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BillsControlTab } from './bills-control-tabs.interface';

@Component({
    selector: 'ga-bills-control-tabs',
    templateUrl: './bills-control-tabs.component.html',
    styleUrls: ['./../../../../../shared/styles/navigation.scss', './bills-control-tabs.component.scss'],
})
export class BillsControlTabsComponent implements OnInit {
    @Input() tabs: BillsControlTab[];
    @Input() selectedTab: BillsControlTab;

    constructor(private location: Location) {}

    ngOnInit() {
        this.selectTab(this.selectedTab);
    }

    selectTab(tab: BillsControlTab) {
        const isTabSame = this.selectedTab && tab && this.selectedTab.key === tab.key;

        if (!isTabSame) {
            // change the URL name as well but without reloading the page
            this.location.go(`/controle/${tab.key}`);
            this.selectedTab = tab; // they might be different if selectTab is called by the page being refresh (Ctrl R)
        }
    }

    isTabActive(key: string): boolean {
        return this.selectedTab.key === key;
    }

    get isSelectedTabAllowed(): boolean {
        return this.selectedTab && this.selectedTab.canUserView;
    }

    getTabClasses(key: string): string {
        let classes = '';
        classes += this.isTabActive(key) ? 'citron-background tab-active ' : '';
        return classes;
    }

    getNameCapitalized(name: string): string {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    get hasMultipleTabsAllowed(): boolean {
        return this.tabs.filter(x => x.canUserView).length > 1;
    }
}
