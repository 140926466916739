import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { RseComponent } from './rse.component';

const RSE_ROUTE = [{ path: '', component: RseComponent }];

@NgModule({
    declarations: [RseComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(RSE_ROUTE)],
})
export class RseModule {}
