import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-em',
    templateUrl: './em.component.html',
    styleUrls: ['./em.component.scss'],
    providers: [],
})
export class EmComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
