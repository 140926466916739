import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterService } from '../../../services/filter/filter.service';

@Component({
    selector: 'ga-select-filter',
    templateUrl: './select-filter.component.html',
    styleUrls: ['./select-filter.component.scss'],
    providers: [],
})
export class SelectFilterComponent implements OnInit {
    @Output() updateElement: EventEmitter<any> = new EventEmitter();

    @Input() provider = 'energies';

    @Input() currentValue = '';

    @Input() values: any[] = [];

    constructor(private filterService: FilterService) {}

    ngOnInit() {
        this.checkProvider(() => {
            // If something to do after the values async initialization
        });
    }

    checkProvider(callback) {
        // If provider provided and values not provided by parent
        if (this.provider && !this.values.length) {
            this.getProvidedValues().then(response => {
                this.values = response.data;
                return callback();
            });
        } else {
            return callback();
        }
    }

    getProvidedValues() {
        return this.filterService.getQueryResultByProvider(this.provider);
    }

    updateCurrentValue(value) {
        this.updateElement.emit(value);
    }

    getNameFromProvider() {
        let name = ' ';

        switch (this.provider) {
            case 'energies':
                name = 'Énergies';
                break;

            case 'sites':
                name = 'Sites';
                break;

            case 'categories':
                name = 'Catégories';
                break;

            case 'regions':
                name = 'Régions';
                break;

            default:
                break;
        }

        return name;
    }
}
