import { Injectable } from '@angular/core';

import { Scenario, ScenarioDisplay } from 'app/shared/models/scenario.model';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

export interface ComparedPropertyItem {
    value: string;
    name: string;
    items: ThresholdItem[];
}

export interface ThresholdItem {
    name: string;
    value: string;
}

@Injectable()
export class AlertsService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get scenarios for connected user on connected company
     * @returns {Promise<ScenarioDisplay[]>}
     */
    async getScenarios(): Promise<ScenarioDisplay[]> {
        const result = await this.get('/api/scenarios');
        const scenarios = result.data;
        return scenarios;
    }

    /**
     * Insert a new scenario for the current user and company
     * @param {Scenario} scenario - scenario object to create.
     * @returns {Promise<Scenario>} scenario created
     */
    async insertScenario(scenario: Scenario): Promise<Scenario> {
        const result = await this.post(`/api/scenarios/`, scenario);
        return result.data;
    }

    /**
     * Update a scenario
     * @param {Scenario} scenario - scenarios with values to update
     * @returns {Promise<Scenario>} scenario updated
     */
    async updateScenario(scenario: Scenario): Promise<Scenario> {
        const result = await this.put(`/api/scenarios/${scenario._id}`, scenario);
        return result.data;
    }

    /**
     * Archive a scenario
     * @param {string} scenarioId - scenario's id to archive
     * @returns {Promise<Scenario>} scenario archived
     */
    async archiveScenario(scenarioId: string): Promise<Scenario> {
        const result = await this.delete(`/api/scenarios/${scenarioId}`);
        return result.data;
    }

    /**
     * Create an empty scenario object
     * @returns {Scenario} Empty scenario object with current company and connecter user
     */
    createScenarioObject(): Scenario {
        return {
            _id: null,
            name: '',
            description: '',
            msgTitle: '',
            msgBody: '',
            company: this.apiService.getCompanyId(),
            creator: this.apiService.getUserId(),
            public: false,
            flagMute: false,
            type: 'individual',
            archived: false,
            lastComputedAt: null,
            condition: {
                scope: {
                    energyType: null,
                    routingReference: null,
                    allRoutingReferences: false,
                    period: null,
                    isRetroactive: false,
                },
                comparator: null,
                comparedProperty: null,
                thresholdProperty: null,
                thresholdValue: null,
            },
        };
    }

    /**
     * Get condition config with items représenting threshold properties available for each compared property.
     * @returns {ComparedPropertyItem[]}
     */
    getConditionConfig(): ComparedPropertyItem[] {
        return [
            {
                name: 'Consommation (kWh)',
                value: 'conso',
                items: [],
            },
            {
                name: '- Consommation HP',
                value: 'consoHP',
                items: [],
            },
            {
                name: '- Consommation HC',
                value: 'consoHC',
                items: [],
            },
            {
                name: '- Consommation pointe',
                value: 'consoPointe',
                items: [],
            },
            {
                name: 'Puissance atteinte (kVA)',
                value: 'powerReached',
                items: [
                    {
                        name: 'Puissance souscrite',
                        value: 'subscriptionPower',
                    },
                ],
            },
            {
                name: '- Puissance atteinte HPH',
                value: 'powerReachedHPH',
                items: [
                    {
                        name: 'Puissance souscrite',
                        value: 'subscriptionPower',
                    },
                    {
                        name: 'Puissance souscrite HPH',
                        value: 'contractPowerHPH',
                    },
                ],
            },
            {
                name: '- Puissance atteinte HCH',
                value: 'powerReachedHCH',
                items: [
                    {
                        name: 'Puissance souscrite',
                        value: 'subscriptionPower',
                    },
                    {
                        name: 'Puissance souscrite HCH',
                        value: 'contractPowerHCH',
                    },
                ],
            },
            {
                name: '- Puissance atteinte HPE',
                value: 'powerReachedHPE',
                items: [
                    {
                        name: 'Puissance souscrite',
                        value: 'subscriptionPower',
                    },
                    {
                        name: 'Puissance souscrite HPE',
                        value: 'contractPowerHPE',
                    },
                ],
            },
            {
                name: '- Puissance atteinte HCE',
                value: 'powerReachedHCE',
                items: [
                    {
                        name: 'Puissance souscrite',
                        value: 'subscriptionPower',
                    },
                    {
                        name: 'Puissance souscrite HCE',
                        value: 'contractPowerHCE',
                    },
                ],
            },
            {
                name: '- Puissance atteinte pointe',
                value: 'powerReachedPointe',
                items: [
                    {
                        name: 'Puissance souscrite',
                        value: 'subscriptionPower',
                    },
                    {
                        name: 'Puissance souscrite pointe',
                        value: 'contractPowerPointe',
                    },
                ],
            },
            {
                name: 'Dépassement (€)',
                value: 'overtaking',
                items: [],
            },
            {
                name: 'TTC (€)',
                value: 'costTTC',
                items: [],
            },
            {
                name: 'HTVA (€)',
                value: 'costHT',
                items: [],
            },
        ];
    }
}
