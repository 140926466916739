import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-site-zone',
    templateUrl: './site-zone.component.html',
    styleUrls: ['./site-zone.component.scss'],
})
export class SiteZoneComponent implements OnInit {
    @Input() zone: any;
    @Input() colWidth: number | string = 12;

    constructor() {}
    ngOnInit() {}

    get getColClass() {
        return 'col-md-' + this.colWidth;
    }
}
