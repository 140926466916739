import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as clone from 'clone';
import { BehaviorSubject, Observable } from 'rxjs';

import { TranslateService } from 'app/shared/services/translate/translate.service';
import { ApiService } from '../../shared/services/api/api.service';
import { PageService } from '../../shared/services/page/page.service';
import { RegexRequest, RegexResponse, RegexService } from '../../shared/services/regex/regex.service';

export interface ExtractDataArray {
    dataTable: ExtractDataTableProperty[];
    closestPattern: {
        requiredProperties: string[];
        [key: string]: any;
        enabled?: boolean;
    };
    otherProperties: string[];
}

export interface ExtractDataTableProperty {
    name: string;
    number: number;
    regex: string;
    initialRegex: string;
    valueExtract: string;
    countExtract: number;
    energyType: string;
    selectBoxData: SelectBoxData;
    loading: boolean;
    editing?: boolean;
    hide: boolean;
}

interface SelectBoxData {
    listOptions: SelectBoxValue[];
    selectedValue: SelectBoxValue;
}

export interface SelectBoxValue {
    id: number;
    regex: string;
    valueExtracted: string;
}

/** Represents the config for one item of a treeview. */
interface TreeviewItemConfig {
    displayName: string;
    value: string;
}

/** Represents a property in a properties group. */
interface PropertyItem {
    name: string;
    ref: string;
}

/** Represents a property with all its children groupped. */
interface PropertyGroup {
    item: PropertyItem;
    children: PropertyGroup[];
}

@Injectable()
export class FixRegexUploadService extends PageService {
    /** Regex subject to handle concurrency */
    private regexSubject: BehaviorSubject<RegexRequest>;

    /** Observable with pool to subscribe */
    private regexObservable: Observable<RegexResponse>;

    constructor(
        public apiService: ApiService,
        public router: Router,
        private regexService: RegexService,
        private translateService: TranslateService
    ) {
        super(apiService);
        this.fake = false;
        // Create a subject for service
        this.regexSubject = this.regexService.createRegexSubject();
        // Create observable with pool
        this.regexObservable = this.regexService.pipeRegexSubject(this.regexSubject);
    }

    /**
     * Get upload file data
     * @param {string} id - upload id
     * @returns {Promise<APICitronResponse>} upload data
     */
    getUploadFiles(id: string): Promise<APICitronResponse> {
        return this.get('/api/uploads/crud/' + id);
    }

    getUploadChunk(id: string, chunkId: string): Promise<APICitronResponse> {
        return this.get(`/api/uploads/crud/${id}/${chunkId}`);
    }

    /**
     * Add fluid pattern to identification pattern
     * @param identificationPatternId - identificaiton pattern id
     * @param fluidPatternId - fluid pattern id
     */
    addPatternToIndentificationPattern(
        identificationPatternId: string,
        fluidPatternId: string
    ): Promise<APICitronResponse> {
        const route = '/api/identification-patterns/update-patterns';

        const body = {
            id: identificationPatternId,
            idPattern: fluidPatternId,
        };
        return this.post(route, body);
    }

    /**
     * Get dictionnary for property. Dictionnary is all regex found in all the patterns of the identification pattern.
     * @param {string} fluid - pattern fuid type
     * @param {string} identificationPatternId - identification pattern id
     * @param {string} propertyName - property name
     * @returns {Promise<string[]>} regexs found
     */
    getDictionaryForProperty(fluid: string, identificationPatternId: string, propertyName: string): Promise<string[]> {
        const route = `/api/patterns/${fluid}/search?identificationPatternId=${identificationPatternId}&property=${propertyName}`;
        return this.get(route);
    }

    /**
     * Get all properties available for a fluid pattern
     * @param {string} fluid - fluid of pattern to get all properties from
     */
    getPatternProperties(fluid: string): Promise<string[]> {
        return this.get('/api/properties/pattern-' + fluid);
    }

    /**
     * Get fluid pattern by id
     * @param {string} id - fluid pattern id
     * @param {string} energyType - pattern's fluid
     * @returns {Promise<APICitronResponse>} fluid pattern
     */
    getClosestPatternById(id: string, energyType: string): Promise<APICitronResponse> {
        const route = `/api/patterns/${energyType}/crud/${id}`;
        return this.get(route);
    }

    /**
     * Get property value from object. Go for deep search (recusively).
     * @param {Object} obj - Object to look into
     * @param {string} property - property value to get from object.
     * @returns {*} value accessed by property.
     */
    fetchFromObject(obj: object, prop: string): any {
        if (typeof obj === 'undefined') {
            return false;
        }

        const _index = prop.indexOf('.');
        if (_index > -1) {
            return this.fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
        }

        return obj[prop];
    }

    /**
     * Fill data table for display. Also launch regex computation in workers.
     * @param {string[]} allProperties - energy pattern all properties available
     * @param {ExtractDataArray} dataArray - upload/chunk data
     * @param {string} textContent - text content for regex computation
     * @param {string} energyType - energy type of pattern
     * @param {string} identificationPatternId - id of the identification pattern
     */
    fillDataTable(
        allProperties: string[],
        dataArray: ExtractDataArray,
        textContent: string,
        energyType: string,
        identificationPatternId: string
    ): void {
        let count = 0;

        // otherProperties is filled with the properties belonging to the identification not included in the closest pattern
        dataArray.otherProperties = allProperties.filter(property => {
            return !dataArray.closestPattern.requiredProperties.includes(property);
        });
        this.regexObservable.subscribe({
            next: res => {
                // fills the array given to the table component
                // order matters - fillSelectedBox needs the 'regex' to be pushed before
                const dataRow = dataArray.dataTable.find(x => x.name === res.id);
                if (!res.error) {
                    dataRow.valueExtract = this.displayValuesExtracted(res.result);
                    dataRow.countExtract = res.result.length;
                } else {
                    dataRow.valueExtract = res.error.errorCode || res.error.message;
                }
                dataRow.loading = false;
            },
            error: err => {
                const dataRow = dataArray.dataTable.find(x => x.name === err.id);
                dataRow.valueExtract = err.errorCode || err.message;
                dataRow.loading = false;
            },
        });
        // fills the data array for all the required properties
        dataArray.closestPattern.requiredProperties.forEach(propertyName => {
            count = count + 1;

            const regexSelected = this.fetchFromObject(dataArray.closestPattern, propertyName);
            const dataRow: ExtractDataTableProperty = {
                name: propertyName,
                number: count,
                regex: regexSelected,
                initialRegex: regexSelected,
                valueExtract: '',
                countExtract: 0,
                energyType,
                selectBoxData: {
                    listOptions: [],
                    selectedValue: null,
                },
                loading: true,
                hide: false,
            };
            dataArray.dataTable.push(dataRow);
            this.regexSubject.next({ text: textContent, regex: regexSelected, id: propertyName });
        });

        /**
         * Sort table display alphatically
         */
        dataArray.dataTable.sort(this.sortTablePropertyAlphabetically);
    }

    /**
     * Format properties into treeview items using carets for depth.
     * Example: ["obj.toto"] => [{displayName: "obj", value: ""},
     *                           {displayName: "- toto", value: "obj.toto"}]
     * @param properties Properties to format
     * @returns Formated properties for treeview
     */
    public formatPropertiesForTreeview(properties: string[]): TreeviewItemConfig[] {
        const groups: PropertyGroup[] = [];
        const propertiesCopy = properties.concat();

        while (propertiesCopy.length) {
            const property = propertiesCopy[0];
            this.groupProperties(property, propertiesCopy, groups);
        }

        const flatGroup: TreeviewItemConfig[] = this.flattenGroup(groups, []).map(item => {
            return { displayName: item.name, value: item.ref };
        });

        const allDisplayName = this.translateService._('all_fp');

        // Add base node to treeview
        flatGroup.unshift({ displayName: allDisplayName, value: '' });

        return flatGroup;
    }

    /**
     * Group properties recursively in a given list of properties string.
     * @param property Property to group by
     * @param list List of properties string (e.g. ["obj.toto", "obj.titi"])
     * @param groups List containing all groupped properties
     * @param previousRef Reference of the previous property (used for recursivity)
     * @returns Groupped properties for the given properties list
     */
    private groupProperties(property: string, list: string[], groups: PropertyGroup[], previousRef: string = ''): void {
        if (property.includes('.')) {
            // Find reference of property (e.g. "obj.toto" => "obj")
            const reference = property.split('.').shift();

            // Format and store previous reference
            previousRef = previousRef ? `${previousRef}.${reference}` : reference;

            // Find children in given list and remove reference from children
            const children = list
                .filter(item => item.startsWith(`${reference}.`))
                .map(child => child.replace(`${reference}.`, ''));

            // Remove elements from original array to avoid cycles
            children.forEach(child => list.splice(list.findIndex(element => element === `${reference}.${child}`), 1));

            // Find children of children recursively
            const childrenGroup: PropertyGroup[] = [];

            while (children.length) {
                const child = children[0];
                this.groupProperties(child, children, childrenGroup, previousRef);
            }

            groups.push({ item: { name: reference, ref: '' }, children: childrenGroup });
        } else {
            const reference = previousRef ? `${previousRef}.${property}` : property;
            list.splice(list.indexOf(property), 1);
            groups.push({ item: { name: property, ref: reference }, children: [] });
        }
    }

    /**
     * Recursively flatten a list of groupped properties using carrets to indicate each property depth.
     * @param group List of groupped properties to flatten
     * @param flatList Flatten list of groupped properties
     * @param index Depth index
     * @returns Flatten group of properties
     */
    private flattenGroup(group: PropertyGroup[], flatList: PropertyItem[] = [], index: number = 1): PropertyItem[] {
        group.forEach(property => {
            // Format property item name using carrets to indicate depth
            property.item.name = index ? `${'-'.repeat(index)} ${property.item.name}` : property.item.name;
            flatList.push(property.item);
            this.flattenGroup(property.children, flatList, index + 1);
        });
        return flatList;
    }

    /**
     * Get list of values extracted
     * @param {string} textContent - test content to extract
     * @param {string} regexToCheck - regex to apply
     * @returns {Promise<string>} values extracted formatted to be displayed
     */
    async getListValuesExtracted(textContent: string, regexToCheck: string): Promise<string> {
        try {
            const resultsFromMatch = await this.regexService.computeRegexInPool(textContent, regexToCheck);
            return this.displayValuesExtracted(resultsFromMatch);
        } catch (err) {
            if (err && err.errorCode) {
                return 'Error : ' + err.errorCode;
            }
            return 'Error : Unknown error';
        }
    }

    /**
     * Get display string for extracted values
     * @param {string[]} valuesArray - extracted values array
     * @return {string} formatted string to display extracted values
     */
    private displayValuesExtracted(valuesArray: string[]): string {
        let listValues = '';
        valuesArray.forEach(value => {
            listValues = listValues.concat('[' + value + '] ');
        });

        return listValues;
    }

    /**
     * Fill selectbox with regexs available from other patterns from the same identification pattern for a given property
     * @param {string} propertyName - property name to find other regex available for
     * @param {string} energyType - pattern energy type
     * @param {string} identificationPatternId - identification pattern id
     * @param {ExtractDataArray} dataArray - values from pattern
     * @param {string} textContent - text content of upload/chunk
     * @returns {SelectBoxData} values for select box
     */
    fillSelectBox(
        propertyName: string,
        energyType: string,
        identificationPatternId: string,
        dataArray: ExtractDataArray,
        textContent: string
    ): SelectBoxData {
        const defaultSelection: SelectBoxValue = {
            id: 0,
            regex: '',
            valueExtracted: null,
        };

        const selectBoxData: SelectBoxData = {
            listOptions: [],
            selectedValue: null,
        };

        // we need the default option for 2 cases :
        // 1) if there is no existing regex in the select box
        // 2) if the user selects an option, then modifies the regex -> needs the select box to set the default option
        // otherwise clicking on the same option again doesn't activate the onChange and the value in the input isn't reset

        selectBoxData.listOptions.push(defaultSelection);

        /**
         * Don't use async/await on this part as the function must remain synchronous.
         * Selectbox value will be returned and then filled with property over time.
         * It avoid a long loading time for the user
         */
        this.getDictionaryForProperty(energyType, identificationPatternId, propertyName).then(async listRegex => {
            if (listRegex && listRegex.length) {
                const val = await this.getValuesFromListRegex(listRegex, textContent);
                selectBoxData.listOptions = selectBoxData.listOptions.concat(val);
                // if the regex belongs to the pattern, set preselected value with that regex
                // otherwise if it's a new property the regex is empty and no value is selected
                const regex = dataArray.dataTable.find(prop => prop.name === propertyName).regex;
                selectBoxData.selectedValue = selectBoxData.listOptions.find(option => option.regex === regex);
            } else {
                selectBoxData.selectedValue = defaultSelection;
            }
        });

        return selectBoxData;
    }

    /**
     * Find values in text content for a list of regex.
     * @param {string[]} listRegex - list of regexs to execute
     * @param {string} textContent - text to test regexs on
     * @returns {Promise<SelectBoxValue>} values extracted formatted for a selectbox
     */
    async getValuesFromListRegex(listRegex: string[], textContent: string): Promise<SelectBoxValue[]> {
        const listRegexValue: SelectBoxValue[] = [];

        for (let i = 0; i < listRegex.length; i++) {
            const regex = listRegex[i];
            const val = await this.getListValuesExtracted(textContent, regex);
            listRegexValue.push({
                id: i + 1,
                regex,
                valueExtracted: val,
            });
        }
        return listRegexValue;
    }

    /**
     * Clone ExtractDataArray to store inital data
     * @param {ExtractDataArray} initialDataArray - data to clone to
     * @param {ExtractDataArray} dataArray - data to be cloned
     */
    setInitialDataArray(initialDataArray: ExtractDataArray, dataArray: ExtractDataArray): void {
        // TODO  find why this doesn't work : this.initialDataArray = clone(this.dataArray);

        initialDataArray.dataTable = dataArray.dataTable.map(x => Object.assign({}, x));
        initialDataArray.closestPattern = clone(dataArray.closestPattern);
        initialDataArray.otherProperties = clone(dataArray.otherProperties);
    }

    /**
     * Execute regex
     * @param {ExtractDataTableProperty} property - property to test
     * @param {string} textContent
     */
    async executeRegex(property: ExtractDataTableProperty, textContent: string): Promise<void> {
        property.loading = true;
        try {
            const extractedValuesArray = await this.regexService.testRegex(textContent, property.regex);
            property.valueExtract = this.displayValuesExtracted(extractedValuesArray);
            property.countExtract = extractedValuesArray.length;
            property.loading = false;
        } catch (err) {
            property.valueExtract = err.errorCode || err.message;
            property.countExtract = 0;
            property.loading = false;
        }
        return;
    }

    /**
     * Reset regex in property
     * @param {ExtractDataTableProperty} propertyToReset
     * @param {string} textContent
     */
    async resetRegex(propertyToReset: ExtractDataTableProperty, textContent: string): Promise<void> {
        propertyToReset.regex = propertyToReset.initialRegex;
        await this.executeRegex(propertyToReset, textContent);
        propertyToReset.selectBoxData.selectedValue = propertyToReset.selectBoxData.listOptions.find(
            option => option.regex === propertyToReset.regex
        );
        return;
    }

    /**
     * Sort properties by name alphabetically
     * @param {ExtractDataTableProperty} a
     * @param {ExtractDataTableProperty} b
     */
    sortTablePropertyAlphabetically(a: ExtractDataTableProperty, b: ExtractDataTableProperty) {
        if (!a && !b) {
            return 0;
        }
        if (!a || !a.name) {
            return -1;
        }
        if (!b || !b.name) {
            return 1;
        }
        return a.name.localeCompare(b.name);
    }

    /**
     * Add property to data table
     * @param {string} property - property to add to table
     * @param {ExtractDataArray} initialDataArray - inital data array
     * @param {ExtractDataArray} dataArray - modified data array
     * @param {string} energyType - pattern fluid
     * @param {string} identificationPatternId - identification pattern id
     * @param {string} textContent - text content of upload/chunk
     */
    addPropertyToTable(
        property: string,
        initialDataArray: ExtractDataArray,
        dataArray: ExtractDataArray,
        energyType: string,
        identificationPatternId: string,
        textContent: string
    ) {
        // if the user deleted a property from the initial closest pattern and put it back
        // we put back the initial data
        if (initialDataArray.closestPattern.requiredProperties.includes(property)) {
            const initialData = clone(initialDataArray.dataTable.find(prop => prop.name === property));
            dataArray.dataTable.push(initialData);
        } else {
            // order matters - fillSelectedBox needs the 'regex' to be pushed before
            dataArray.dataTable.push({
                name: property,
                number: this.setNumberToNewProperty(dataArray.dataTable),
                regex: '',
                valueExtract: '',
                countExtract: 0,
                energyType,
                selectBoxData: {
                    listOptions: [],
                    selectedValue: null,
                },
                editing: false,
                initialRegex: '',
                loading: false,
                hide: false,
            });
        }

        // delete this option from the "Add property" select box
        dataArray.otherProperties.splice(dataArray.otherProperties.indexOf(property), 1);
        dataArray.dataTable.sort(this.sortTablePropertyAlphabetically);
    }

    /**
     * Get next number for new property
     * @param {ExtractDataTableProperty[]} dataTable - data table to get the next property number
     */
    setNumberToNewProperty(dataTable: ExtractDataTableProperty[]): number {
        // if dataTable isn't empty check its length
        return dataTable.length ? dataTable[dataTable.length - 1].number + 1 : 1;
    }

    /**
     * Update fluid pattern, then reset select boxes of all properties in pattern.
     * @param {fluid} energyType - pattern fluid
     * @param {ExtractDataArray} dataArray - data array value
     * @param {string} identificationPatternId - identification pattern id
     * @param {string} textContent - text content
     * @param {ExtractDataArray} initialDataArray - initial data array value
     */
    async updatePattern(
        energyType: string,
        dataArray: ExtractDataArray,
        identificationPatternId: string,
        textContent: string,
        initialDataArray: ExtractDataArray
    ): Promise<void> {
        // update the closest pattern in db
        const route = '/api/patterns/' + energyType + '/crud/' + dataArray.closestPattern._id;

        try {
            const response: APICitronResponse = await this.put(route, dataArray.closestPattern, null, true);

            // now that the pattern has been successfully updated
            if (response.data.modifiedCount === 1) {
                // the select boxes might change if a new regex was saved
                dataArray.dataTable.forEach(property => {
                    property.selectBoxData = {
                        listOptions: [],
                        selectedValue: null,
                    };
                    // set the current regex as the initial one
                    property.initialRegex = property.regex;
                });

                // clone that dataArray as the initial one
                this.setInitialDataArray(initialDataArray, dataArray);

                return;
            } else {
                throw new Error('error_updatePattern');
            }
        } catch (error) {
            const err = { error_code: 'error_updatePattern' };
            return Promise.reject(err);
        }
    }

    /**
     * Create new fluid pattern and add it to the same identification pattern.
     * @param {string} energyType - pattern's fluid
     * @param {ExtractDataArray} dataArray - data containing pattern to save
     * @param {string} identificationPatternId - identification pattern id
     * @returns {Promise<string>} created fluid pattern's _id
     */
    async createNewPattern(
        energyType: string,
        dataArray: ExtractDataArray,
        identificationPatternId: string
    ): Promise<string> {
        // save the new closest pattern in the patterns db
        const route = '/api/patterns/' + energyType + '/crud';
        try {
            const res: APICitronResponse = await this.post(route, dataArray.closestPattern);

            // retrieve the id to add it in db to the identificationPattern
            const closestPatternSaved = res.data;

            // add it in db to the identificationPattern patterns list for the specific energy
            await this.addPatternToIndentificationPattern(identificationPatternId, closestPatternSaved._id);

            return closestPatternSaved._id;
        } catch (err) {
            err.error_code = 'error_createNewPattern';
            return Promise.reject(err);
        }
    }

    /**
     * Delete property from table (fluid pattern)
     * @param {ExtractDataTableProperty} property - property to delete
     * @param {ExtractDataArray} dataArray - data
     */
    deletePropertyFromTable(property: ExtractDataTableProperty, dataArray: ExtractDataArray): void {
        // -- delete from data array
        const indexToDelete = dataArray.dataTable.findIndex(x => x.name === property.name);

        if (indexToDelete > -1) {
            dataArray.dataTable.splice(indexToDelete, 1);
            dataArray.otherProperties.push(property.name);
            dataArray.otherProperties.sort((a, b) => a.localeCompare(b));
        }
    }

    /**
     * Find if there are differences between inital pattern and current state of pattern
     * @param {ExtractDataArray} dataArray - current data
     * @param {boolean} initialDataArray - initial data
     * @returns {boolean} true if pattern changed, false otherwise.
     */
    hasPatternChanged(dataArray: ExtractDataArray, initialDataArray: ExtractDataArray): boolean {
        let foundDifference = false;

        // difference can be : a new property was added / a property was deleted / the regex has changed
        if (dataArray.dataTable.length !== initialDataArray.dataTable.length) {
            // more properties were deleted than added, changes were obviously made
            foundDifference = true;
        } else {
            // if the change isn't obvious, check the regex
            // a new property has an "initalRegex" undefined
            foundDifference = dataArray.dataTable.some(element => {
                return element.initialRegex !== element.regex;
            });
        }

        return foundDifference;
    }

    /**
     * Find if there is a wrong regex in data (regex still loading or value extracted is an object)
     * @param {ExtractDataArray} dataArray - data
     * @returns {boolean} true if there is a wrong regex, false otherwise
     */
    hasWrongRegex(dataArray: ExtractDataArray): boolean {
        return dataArray.dataTable.some(element => {
            return element.loading || typeof element.valueExtract === 'object';
        });
    }

    /**
     * Get swal title on create/update action
     * @param {string} action - must be 'update' or 'new'
     * @return {string} swal title
     */
    getSwalTitleOnSave(action: string): string {
        if (action === 'update') {
            return 'Voulez-vous mettre à jour le pattern existant ?';
        } else if (action === 'new') {
            return 'Voulez-vous créer un nouveau pattern ?';
        }
    }

    /**
     * Get swal title on create/update action confirmation
     * @param {string} action - must be 'update' or 'new'
     * @return {string} swal title
     */
    getSwalConfirmButtonTextOnSave(action: string) {
        if (action === 'update') {
            return 'Mettre à jour';
        } else if (action === 'new') {
            return 'Ajouter';
        }
    }

    /**
     * Get swal title on create/update action successful
     * @param {string} action - must be 'update' or 'new'
     * @return {string} swal title
     */
    getSwalSuccessOnSave(action: string) {
        if (action === 'update') {
            return 'Le pattern a été mis à jour.';
        } else if (action === 'new') {
            return 'Le nouveau pattern a été enregistré';
        }
    }

    /**
     * Get swal title on create/update action failed
     * @param {string} action - must be 'update' or 'new'
     * @return {string} swal title
     */
    getSwalFailOnSave(action: string) {
        if (action === 'update') {
            return 'Le pattern n\'a pas pu être mis à jour.';
        } else if (action === 'new') {
            return 'Le nouveau pattern n\'a pas pu être enregistré.';
        }
    }

    /**
     * Find if there is an empty regex in data
     * @param {ExtractDataArray} dataArray - data with dataTable filled
     * @returns {boolean} true if there is an empty regex, false otherwise
     */
    hasEmptyRegex(dataArray: ExtractDataArray): boolean {
        return dataArray.dataTable.some(property => {
            return Boolean(!property.regex || property.regex === '');
        });
    }

    /**
     * Create closest pattern
     * @param {string} action
     * @param {ExtractDataArray} dataArray
     * @param {string} energyType
     * @param {string} identificationPatternId
     * @param {string} textContent
     */
    createClosestPattern(
        action: string,
        dataArray: ExtractDataArray,
        energyType: string,
        identificationPatternId: string,
        textContent: string
    ): void {
        const oldId = dataArray.closestPattern._id;
        // empty the current closest pattern
        // if it's an update, set the id the old one
        dataArray.closestPattern = {
            requiredProperties: [],
        };
        if (action === 'update') {
            dataArray.closestPattern['_id'] = oldId;
        }

        // fill it with the dataTable regexs
        dataArray.dataTable.forEach(property => {
            this.addPropertyToClosestPattern(property, dataArray);
            property.selectBoxData = {
                listOptions: [],
                selectedValue: null,
            };
        });
    }

    /**
     * Add property to closest pattern
     * @param {ExtractDataTableProperty} property - property to add
     * @param {ExtractDataArray} dataArray - data
     */
    addPropertyToClosestPattern(property: ExtractDataTableProperty, dataArray: ExtractDataArray): void {
        if (property.name.indexOf('.') !== -1) {
            // call recursive function to get further inside property
            // turpe
            const firstElement = property.name.split('.')[0];
            if (!dataArray.closestPattern[firstElement]) {
                dataArray.closestPattern[firstElement] = {};
            }
            const currentPointer = dataArray.closestPattern[firstElement];

            // rackingFee.HC.dateStart
            const rightPartToExplore = property.name.substring(firstElement.length + 1);

            this.setRegexRecursively(property.regex, rightPartToExplore, currentPointer);
        } else {
            dataArray.closestPattern[property.name] = property.regex;
        }

        // add it to the required properties if it's a new property
        dataArray.closestPattern.requiredProperties.push(property.name);
    }

    /**
     * Set regex recursively
     * @param regex
     * @param rightPartToExplore
     * @param currentPointer
     */
    setRegexRecursively(regex: string, rightPartToExplore: string, currentPointer: any) {
        // need to go furher inside property
        // 1. rackingFee.HC.dateStart
        // 2. HC.dateStart
        // 3. dateStart
        if (rightPartToExplore.indexOf('.') !== -1) {
            // go 1 step further
            // 1. turpe -> turpe.rackingFee
            // 2. turpe.rackingFee -> turpe.rackingFee.HC
            const firstElement = rightPartToExplore.split('.')[0];
            if (!currentPointer[firstElement]) {
                currentPointer[firstElement] = {};
            }
            currentPointer = currentPointer[firstElement];

            // 1. rackingFee.HC.dateStart -> HC.dateStart
            // 2. HC.dateStart -> dateStart
            rightPartToExplore = rightPartToExplore.substring(rightPartToExplore.split('.')[0].length + 1);

            return this.setRegexRecursively(regex, rightPartToExplore, currentPointer);
        }

        // 3. closestPattern[turpe.rackingFee.HC.dateStart] is set to regex value
        currentPointer[rightPartToExplore] = regex;
    }

    /**
     * Navigate back to collect
     * @param {string} group - upload's group id
     * @param {number} indexSelectedGroupType - index of contract type selected in group
     */
    backToCollect(group: string, indexSelectedGroupType: number = null) {
        if (group) {
            if (indexSelectedGroupType !== null) {
                this.router.navigate(['collecte/' + group], { queryParams: { index: indexSelectedGroupType } });
            } else {
                this.router.navigateByUrl('collecte/' + group);
            }
        } else {
            this.router.navigateByUrl('collecte');
        }
    }

    /**
     * Get back to collect link
     * @param {string} group - upload's group id
     * @param {number} indexSelectedGroupType - index of contract type selected in group
     * @returns {{url: string, queryParams: any}} link and potential query params
     */
    getBackToCollectLink(group: string, indexSelectedGroupType: number = null): { url: string; queryParams: any } {
        const link = {
            url: null,
            queryParams: null,
        };
        if (group) {
            if (indexSelectedGroupType !== null) {
                link.url = ['/collecte/' + group];
                link.queryParams = { index: indexSelectedGroupType };
            } else {
                link.url = ['/collecte/' + group];
            }
        } else {
            link.url = ['/collecte'];
        }
        return link;
    }

    /**
     * Copy text to clipboard
     * @param {string} text - text to copy yo clipboard
     * @returns {boolean} true if copy was a success, false otherwise
     */
    copyTextToClipboard(text: string): boolean {
        // need to create a fake textArea, put the text inside it, and call the execCommand 'copy' on it
        const txtArea = document.createElement('textarea');
        txtArea.value = text;
        document.body.appendChild(txtArea);
        txtArea.select();
        try {
            const resultCopy = document.execCommand('copy');
            document.body.removeChild(txtArea);

            if (resultCopy) {
                return true;
            }

            return false;
        } catch (err) {
            return false;
        }
    }
}
