import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

import { FluidCompleteness } from 'app/shared/components/bills/bills-control/completeness/bills-completeness-tab/bills-completeness-tab.interface';
import { CompletenessQueryFilter, TimelineDataType } from 'app/shared/models/bills-timeline.interface';
import { TranslateService } from '../translate/translate.service';

/**
 * Fluid property
 */
export interface FluidProperty {
    /** Completeness key */
    key: string;
    /** Display label */
    label?: string;
    /** Is a completeness parent property */
    isParent?: boolean;
    /** Key of property when data complete */
    completeKey: TimelineDataType;
}
@Injectable()
export class BillsCompletenessService extends PageService {
    /**
     * Config by fluid to display in completeness
     * [NRJ-324] cta temporarily disabled because not data yet
     */
    private readonly config: { [key: string]: FluidProperty[] } = {
        elec: [
            { key: 'furniture', isParent: true, completeKey: 'complete_period' },
            { key: 'furniture_metered', completeKey: 'complete_metered_period' },
            { key: 'furniture_estimated', completeKey: 'complete_estimated_period' },
            { key: 'furniture_cancelled', completeKey: 'complete_cancelled_period' },
            { key: 'furniture_adjusted', completeKey: 'complete_adjusted_period' },
            { key: 'cspe', isParent: true, completeKey: 'complete_period' },
            { key: 'cspe_metered', completeKey: 'complete_metered_period' },
            { key: 'cspe_estimated', completeKey: 'complete_estimated_period' },
            { key: 'cspe_cancelled', completeKey: 'complete_cancelled_period' },
            { key: 'cspe_adjusted', completeKey: 'complete_adjusted_period' },
        ],
        gaz: [
            { key: 'furniture', isParent: true, completeKey: 'complete_period' },
            { key: 'furniture_metered', completeKey: 'complete_metered_period' },
            { key: 'furniture_estimated', completeKey: 'complete_estimated_period' },
            { key: 'furniture_cancelled', completeKey: 'complete_cancelled_period' },
            { key: 'furniture_adjusted', completeKey: 'complete_adjusted_period' },
            { key: 'ticgn', isParent: true, completeKey: 'complete_period' },
            { key: 'ticgn_metered', completeKey: 'complete_metered_period' },
            { key: 'ticgn_estimated', completeKey: 'complete_estimated_period' },
            { key: 'ticgn_cancelled', completeKey: 'complete_cancelled_period' },
            { key: 'ticgn_adjusted', completeKey: 'complete_adjusted_period' },
        ],
        heating: [{ key: 'furniture', isParent: true, completeKey: 'complete_period' }],
    };

    constructor(public apiService: ApiService, private translateService: TranslateService) {
        super(apiService);
        Object.values(this.config).forEach(config => {
            config.forEach(property => {
                property.label = this.translateService._(property.key);
            });
        });
    }

    /**
     * get bills completeness periods
     * @param {CompletenessQueryFilter} filters
     * @param {string} companyId
     * @return {Promise<Array<FluidCompleteness>>}
     */
    async getBillsCompletenessPeriods(
        filters: CompletenessQueryFilter,
        companyId?: string
    ): Promise<FluidCompleteness> {
        try {
            const res = await this.post('/api/uploads/bills-completeness', { filters, companyId });
            return res.data;
        } catch (err) {
            err.error_code = 'error_getBillsCompleteness';
            return Promise.reject(err);
        }
    }

    /**
     * Get type for period with data from property and number of bills
     * @param property - completeness property
     * @param nbBills - number of bills
     * @returns timeline type
     */
    public getPeriodWithDataType(property: FluidProperty, nbBills: number): TimelineDataType {
        if (nbBills > 1) {
            return 'period_with_multiple_bills';
        }
        return property.completeKey;
    }

    /**
     * Get fluid properties for timeline
     * @param {string} fluid
     * @param options
     * @param options.onlyParents - only include parent properties
     */
    public getFluidProperties(fluid: string, options = { onlyParents: false }): FluidProperty[] {
        const config = this.config[fluid];
        if (!config) {
            return [];
        }
        const onlyParents = options && options.onlyParents;
        return config.filter(x => x.key && (!onlyParents || x.isParent));
    }
}
