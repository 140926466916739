import { UploadChunkRecap } from 'app/shared/models/upload-file.interface';

import { ExtractFileParams } from './collect.interface';

/**
 * @description Maps selected files to extract params for api call
 * @param selectedFiles an array of chunk/files arrays from selected files
 * @return params to call ExtractFile
 */
export function selectedFilesToExtractFileParams(selectedFiles: UploadChunkRecap[][]): ExtractFileParams[] {
    const extractFileParams = [];

    selectedFiles.forEach(file => {
        if (!file[0] || !file[0].uploadId) {
            return;
        }
        const params = {
            uploadId: file[0].uploadId,
            chunksIdSelected: [],
            fileName: file[0].filename,
            state: file[0].state,
        };
        // An upload without chunk was selected
        if (!file[0].chunkId && file[0].checked) {
            extractFileParams.push(params);
        } else {
            const selectedChunksIds = file.filter(chunk => chunk.checked).map(chunk => chunk.chunkId);
            if (selectedChunksIds.length > 0) {
                (params.chunksIdSelected = selectedChunksIds), extractFileParams.push(params);
            }
        }
    });

    return extractFileParams;
}
