import { Injectable } from '@angular/core';
import * as jsonschema from 'jsonschema';

import { FluidCompleteness } from 'app/shared/components/bills/bills-control/completeness/bills-completeness-tab/bills-completeness-tab.interface';
import { CompletenessQueryFilter, TimelineDataType } from 'app/shared/models/bills-timeline.interface';
import { Bill, BillPopulated, BillsControlsRecap, SubTotals } from 'app/shared/models/bills.interface';
import { QueryPagination, QuerySort } from 'app/shared/models/pagination.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';

import * as billsSchema from 'app/shared/jsonschemas/billsuploads-results.json';
import * as billsPopulatedSchema from 'app/shared/jsonschemas/billsuploadspopulated-results.json';
import { BillQuery } from './bills-list.interface';

@Injectable()
export class BillsListService extends PageService {
    constructor(public apiService: ApiService, private translateService: TranslateService) {
        super(apiService);
        this.fake = false;
    }

    /**************************
     * API FUNCTIONS
     **************************/

    /**
     * Get bills uploads filtered and paginated
     * @param {BillQuery} filters
     * @param {QueryPagination} pagination
     * @return {Promise<{results: Bill[], total: number}>}
     */
    async getUploadsBills(
        filters: BillQuery,
        pagination: QueryPagination,
        sort: QuerySort
    ): Promise<{ results: Bill[]; total: number }> {
        const data = {
            filters,
            pagination,
            sort,
        };

        const result = await this.post('/api/bills-uploads/list', data);

        const schema = this.getJSONSchema();

        const validatorResult = jsonschema.validate(result.data, schema);

        if (validatorResult.valid) {
            return validatorResult.instance;
        } else {
            throw { message: validatorResult.toString(), errorCode: 'result-not-valid-json' };
        }
    }

    /**
     * Get bills uploads populated filtered and paginated
     * @param {BillQuery} filters
     * @param {QueryPagination} pagination
     * @param {string} companyId
     * @return {Promise<{results: BillPopulated[], total: number}>}
     */
    async getUploadsBillsPopulated(
        filters: BillQuery,
        pagination: QueryPagination,
        companyId?: string
    ): Promise<{ results: BillPopulated[]; total: number }> {
        const data = {
            filters,
            pagination,
            companyId,
        };

        const result = await this.post('/api/bills-uploads/list-populated', data);

        const schema = this.getJSONSchema(true);

        const validatorResult = jsonschema.validate(result.data, schema);

        if (validatorResult.valid) {
            return validatorResult.instance;
        } else {
            throw { message: validatorResult.toString(), errorCode: 'result-not-valid-json' };
        }
    }

    /**
     * Get the control recap for the banner in the bills uploads control view
     * @param {BillQuery} filters
     * @returns {Promise<any>}
     */
    async getControlRecap(filters: BillQuery): Promise<BillsControlsRecap> {
        try {
            const res = await this.post('/api/bills-uploads/controls-recap', filters);
            return res.data;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    /**
     * Get the JSON schema to validate the structure of a config file.
     * @returns {any}
     */
    public getJSONSchema(populated: boolean = false): any {
        if (populated) {
            return billsPopulatedSchema;
        }
        return billsSchema;
    }

    /**************************
     * UTILS FUNCTIONS
     **************************/

    /**
     * Format null values + totals.
     * Specify when values are computed instead of extracted.
     *
     * @param billsUploadsArray. Multi bills have total in 'billValues', simple bill have the total in chunks[0].
     */
    formatBillsUpload(billsUploadsArray: BillPopulated[]) {
        billsUploadsArray.forEach(billupload => {
            if (billupload.multi) {
                this.computeSubtotalsPerCategory(billupload);
            }
        });
    }

    /**
     * Add on each billUpload an array subTotalsPerCategory :
     * {
     *   {string} category,
     *   {string} name,
     *   {*}[] chunks
     *   {
     *      {number} totalHTVA
     *      {number} totalTVA
     *      {number} totalTTC
     *   } subtotals
     *  }[]
     *  Used to display subtotals & group chunks per category
     *
     * @param billupload
     */
    computeSubtotalsPerCategory(billupload: BillPopulated) {
        const subTotalsPerCategory: SubTotals[] = [];
        billupload.chunks.forEach(chunk => {
            if (chunk.categories.length > 1) {
                let subTotalFound = subTotalsPerCategory.find(subTotal => subTotal.category === 'multiple');
                if (!subTotalFound) {
                    subTotalFound = {
                        category: 'multiple',
                        name: 'multiple',
                        chunks: [],
                        subtotals: {
                            totalHTVA: 0,
                            isTotalHTVAComputed: true,
                            totalTVA: 0,
                            isTotalTVAComputed: true,
                            totalTTC: 0,
                            isTotalTTCComputed: true,
                            quantity: 0,
                            quantityUnit: chunk.bill.values.quantityUnit,
                        },
                        subtotalsDisplay: null,
                        billIds: [],
                    };
                    subTotalsPerCategory.push(subTotalFound);
                }
                subTotalFound.chunks.push(chunk);
            } else {
                chunk.categories.forEach(category => {
                    let subTotalFound = subTotalsPerCategory.find(subTotal => subTotal.category === category.value);
                    if (!subTotalFound) {
                        subTotalFound = {
                            category: category.value,
                            name: category.name,
                            chunks: [],
                            subtotals: {
                                totalHTVA: 0,
                                isTotalHTVAComputed: true,
                                totalTVA: 0,
                                isTotalTVAComputed: true,
                                totalTTC: 0,
                                isTotalTTCComputed: true,
                                quantity: 0,
                                quantityUnit: chunk.bill.values.quantityUnit,
                            },
                            subtotalsDisplay: null,
                            billIds: [],
                        };
                        subTotalsPerCategory.push(subTotalFound);
                    }

                    subTotalFound.subtotals.totalHTVA += chunk.bill.values.totalHTVA
                        ? chunk.bill.values.totalHTVA * category.ratio
                        : 0;
                    subTotalFound.subtotals.totalTVA += chunk.bill.values.totalTVA
                        ? chunk.bill.values.totalTVA * category.ratio
                        : 0;
                    subTotalFound.subtotals.totalTTC += chunk.bill.values.totalTTC
                        ? chunk.bill.values.totalTTC * category.ratio
                        : 0;
                    subTotalFound.subtotals.quantity += chunk.bill.values.quantity
                        ? chunk.bill.values.quantity * category.ratio
                        : 0;
                    subTotalFound.chunks.push(chunk);
                });
            }
        });
        billupload.subTotalsPerCategory = subTotalsPerCategory;
    }

    /**
     * Get bill status full text from status value.
     * Default is an empty string if status not found.
     * @returns {string}
     */
    public getBillStatusFullText(status: string): string {
        return this.translateService._(status);
    }
}
