import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RuleSitesAttributionInterface, RuleSitesInterface } from 'app/shared/models/rule-sites-attribution.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

interface CustomFilterInterface {
    _id?: string;
    company: string;
    user?: string;
    name: string;
    rules: Array<{
        regions: string[];
        departments: string[];
        cities: string[];
        natures: string[];
        subnatures: string[];
        categories: { organization: string; items: string[] };
        sites: string[];
        routingReferences: string[];
    }>;
}

@Component({
    selector: 'ga-custom-filter',
    templateUrl: './custom-filter.component.html',
    styleUrls: ['./custom-filter.component.scss'],
    providers: [],
})
export class CustomFilterComponent implements OnInit {
    /**
     * Even triggered when a custom filter is selected, created or updated.
     */
    @Output() filterSelected: EventEmitter<{ value: string | number; displayName: string }> = new EventEmitter<{
        value: string | number;
        displayName: string;
    }>();

    /**
     * Company to use if it's needed to use a different one than the one in session
     */
    @Input() company: { _id: string; organization: string; name: string; [key: string]: any } = null;

    /**
     * Id of Default value
     */
    @Input() defaultValue: string = null;
    /**
     * Values to be displayed in the selectbox.
     */
    values: Array<{ value: string | number; displayName: string }> = [];

    /**
     * Custom filter selected
     */
    selectedFilter: string;

    /**
     * Custom filter management modal
     */
    @ViewChild('modalManageEl', { static: true }) modalManage: ModalDirective;
    /**
     * Custom filter results overview modal
     */
    @ViewChild('modalOverviewEl', { static: true }) modalOverview: ModalDirective;
    /**
     * Custom filter save modal
     */
    @ViewChild('modalSaveEl', { static: true }) modalSave: ModalDirective;

    /**
     * Filter to display in modal. Empty for filter creation.
     * This data is at the same format as user sites rules but the display is adapted for custom filters.
     */
    filterData: RuleSitesAttributionInterface;

    /**
     * Custom filter model data. Set when a new filter is created or existing one is updated.
     */
    customFilter: CustomFilterInterface;

    /**
     * Sites to be displayed in custom filter preview
     */
    sitesPreview: RuleSitesInterface;

    /**
     * List of fluids available for the company
     */
    fluids: string[];

    /**
     * Current company the user is connected to.
     */
    currentCompany: { _id: string; organization: string; [key: string]: any };

    /**
     * Name filter input
     */
    @ViewChild('filterNameInput', { static: true }) saveNameInput: ElementRef;

    constructor(
        private sessionService: SessionService,
        private energyService: EnergyService,
        private sitesService: SitesService,
        private filterService: FilterService
    ) {}

    ngOnInit() {
        // Get company to be associated to the custom filters.
        // Mandatory for the editing modal display (selectboxes)
        this.currentCompany = this.company ? this.company : this.sessionService.getCompany();
        // Get fluids for sites display list
        this.energyService.getFluidsAvailable(this.currentCompany._id).then(fluids => {
            this.fluids = fluids;
        });

        // Get all user custom filter for the company their's connected to
        this.setAllUserCustomFilter();

        /**
         * ModalDirective.onHidden : event fired when modal has finished being hidden from user
         */
        this.modalSave.onHidden.subscribe(() => {
            if (this.saveNameInput && this.saveNameInput.nativeElement) {
                this.saveNameInput.nativeElement.value = '';
            }
        });
    }

    /**
     * Actions to perform when a custom filter is selected
     * @param {string|number} val - value selected
     */
    selectCustomFilter(val: string | number) {
        if (val || val === '' || val === 0) {
            // Set the selected custom filter
            const value = val.toString();
            this.selectedFilter = value;
            const searchCF = this.values.find(x => x.value === value);
            if (searchCF) {
                this.filterSelected.emit(searchCF);
            } else {
                this.selectedFilter = null;
                this.filterSelected.emit(null);
            }
        } else {
            this.selectedFilter = null;
            this.filterSelected.emit(null);
        }
    }

    /**
     * Open custom filter creation/edition modal.
     * Creates the object needed by the component of filter creation/edition.
     */
    openCreateFilter() {
        this.filterData = {
            companies: [this.currentCompany._id],
            rules: [
                {
                    regions: [],
                    departments: [],
                    categories: [
                        {
                            organization: this.currentCompany.organization,
                            items: [],
                        },
                    ],
                    sites: [],
                    cities: [],
                    natures: [],
                    subnatures: [],
                    energies: [],
                    routingReferences: [],
                },
            ],
        };
        this.customFilter = null;
        this.modalManage.show();
    }

    /**
     * Open custom filter creation/edition modal
     * First, get the custom filter data
     */
    openEditFilter() {
        this.filterService
            .getCustomFilter(this.selectedFilter)
            .then(response => {
                const customFilter = response.data;

                this.customFilter = customFilter;

                this.filterData = {
                    companies: [customFilter.company],
                    rules: customFilter.rules.map(rule => {
                        return {
                            regions: rule.regions,
                            departments: rule.departments,
                            categories: [rule.categories],
                            sites: rule.sites,
                            cities: rule.cities,
                            natures: rule.natures,
                            subnatures: rule.subnatures,
                            energies: [],
                            routingReferences: rule.routingReferences,
                        };
                    }),
                };
                this.modalManage.show();
            })
            .catch(() => {
                Swal.fire(
                    'Impossible de récupérer le filtre personnalisé',
                    'Une erreur est survenue lors de la récupération du filtre, merci de réessayer ultérieurement',
                    'error'
                );
            });
    }

    /**
     * Update the custom filter value
     * @param {RuleSitesAttributionInterface} value - data from the edition component.
     */
    updateCustomFilter(value: RuleSitesAttributionInterface) {
        const customFilterClean = this.formatCustomFilter(value);
        if (this.customFilter && this.customFilter._id) {
            customFilterClean._id = this.customFilter._id;
        }
        this.customFilter = customFilterClean;
    }

    /**
     * Format custom filter data from RuleSitesAttributionInterface to CustomFilterInterface
     * @param value
     */
    private formatCustomFilter(value: RuleSitesAttributionInterface): CustomFilterInterface {
        return {
            company: this.currentCompany._id,
            name: this.customFilter ? this.customFilter.name : '',
            rules: value.rules.map(rule => {
                return {
                    regions: rule.regions,
                    departments: rule.departments,
                    cities: rule.cities,
                    natures: rule.natures,
                    subnatures: rule.subnatures,
                    categories:
                        rule.categories && rule.categories.length
                            ? rule.categories[0]
                            : { organization: this.currentCompany.organization, items: [] },
                    sites: rule.sites,
                    routingReferences: rule.routingReferences,
                };
            }),
        };
    }

    /**
     * Get the sites matching the custom filter and open sites list preview modal.
     */
    openOverviewModal() {
        if (this.modalOverview) {
            this.sitesPreview = {
                sitesMatching: [],
                pagination: {
                    start: 0,
                    end: 0,
                },
                isReady: false,
                isVisible: false,
            };
            // Get sites matching the filter
            this.sitesService
                .getSitesFromCustomFilter(this.customFilter)
                .then(response => {
                    this.sitesPreview.sitesMatching = response.data;

                    this.sitesPreview.isReady = true;
                    this.sitesPreview.isVisible = true;
                })
                .catch(() => {
                    this.sitesPreview.isReady = true;
                    this.sitesPreview.isVisible = true;

                    Swal.fire(
                        "Impossible d'afficher l'aperçu des sites",
                        'Une erreur est survenue lors de la récupération des sites, merci de réessayer ultérieurement.',
                        'error'
                    );
                });
            // Show modal
            this.modalOverview.show();
        }
    }

    /**
     * Save custom filter. Display save modal if no name
     * @param {string} value - custom filter name
     */
    saveFilter(value: string) {
        if (this.customFilter && (value || this.customFilter.name)) {
            if (value) {
                this.customFilter.name = value;
            }
            this.filterService
                .saveCustomFilter(this.customFilter, { companyId: this.currentCompany._id })
                .then(response => {
                    const updatedFilter = response.data;

                    const searchExisting = this.values.find(x => x.value === updatedFilter._id);
                    if (!searchExisting) {
                        this.values.push({
                            value: updatedFilter._id,
                            displayName: updatedFilter.name,
                        });
                    }
                    this.selectCustomFilter(updatedFilter._id);

                    if (this.modalOverview && this.modalOverview.isShown) {
                        this.modalOverview.hide();
                    }
                    if (this.modalManage) {
                        this.modalManage.hide();
                    }
                    if (this.modalSave) {
                        this.modalSave.hide();
                    }
                })
                .catch(() => {
                    Swal.fire(
                        "Impossible d'enregistrer le filtre",
                        "Une erreur est survenue lors de l'enregistrement du filtre, merci de réessayer ultérieurement",
                        'error'
                    );
                });
        } else if (this.modalSave && !this.modalSave.isShown && this.customFilter) {
            this.modalSave.show();
        }
    }

    async openDeleteFilter() {
        const response = await this.filterService.getCustomFilter(this.selectedFilter);
        this.customFilter = response.data;
        const result = await Swal.fire({
            title: 'Suppression du filtre personnalisé',
            text: 'Êtes-vous certain de vouloir supprimer le filtre : ' + this.customFilter.name + ' ?',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer',
            cancelButtonText: 'Annuler',
        });

        if (result.value) {
            try {
                await this.filterService.deleteCustomFilter(this.selectedFilter);
                // update the choice of custom filters
                this.setAllUserCustomFilter();
                Swal.fire('Supprimé !', 'Le filtre personnalisé a bien été supprimé.', 'success');
            } catch (error) {
                Swal.fire(
                    'Erreur',
                    'Impossible de supprimer le filtre personnalisé. Veuillez réessayer plus tard.',
                    'error'
                );
            }
        }
    }

    /**
     * Get all user custom filter for the company their's connected to
     */
    async setAllUserCustomFilter() {
        this.values = [];
        try {
            const response = await this.filterService.getUserCustomFilters({ companyId: this.currentCompany._id });
            this.values = response.data.map(item => {
                return {
                    value: item._id,
                    displayName: item.name,
                };
            });
            this.selectCustomFilter(this.defaultValue);
        } catch (error) {
            this.values = [];
            Swal.fire(
                'Impossible de récupérer les filtres personnalisés',
                'Une erreur est survenue lors de la récupération de vos filtres, merci de réessayer ultérieurement',
                'error'
            );
        }
    }

    get filterManagementModalTitle() {
        const action = this.selectedFilter && this.customFilter ? 'Modification' : 'Création';
        return action + " d'un filtre personnalisé - " + this.company.name;
    }
}
