// tslint:disable:no-access-missing-member
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { QuestionComponent } from '../question/question.component';

@Component({
    selector: 'ga-question-select-choices-input',
    templateUrl: './select-choices-input.component.html',
    styleUrls: ['./select-choices-input.component.scss'],
})
/**
 * Component to create a question with pre-determined answers (radios or checkboxes) and inputs answers. Can be one or multiple choices.
 * @Input data : User data at the format [{key: string, value: string}]
 * @Input config : Question from the config file with properties attribute at the format
 * {multiple: boolean, values: [{label: string, key: string, type: string, unit: string(opt.)}]}
 * Available types : text, number, color, date, datetime-local, email, month, tel, time, url, week.
 * @Ouput valueChange : event that gives the data each time it's changed by the user at the format {key: string, value: string}
 */
export class SelectChoicesInputComponent extends QuestionComponent implements OnInit {
    multiple = false;

    questionForm: FormGroup;

    // Answers possible
    values: any[] = [];

    allowedTypes = [
        'text',
        'number',
        'color',
        'date',
        'datetime-local',
        'email',
        'month',
        'tel',
        'time',
        'url',
        'week',
    ];

    constructor(private fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {
        if (this.config.properties.multiple) {
            this.multiple = true;
        }
        // Get different possibilities from the config file
        if (this.config.properties.values) {
            this.config.properties.values.forEach(item => {
                const dbValue = this.data.find(x => x.key === item.key);
                // Push all selections possible
                this.values.push({
                    key: item.key,
                    selected: dbValue && dbValue.value, // Is it selected as init or not, yes if value from db
                    value: dbValue && dbValue.value ? dbValue.value : null, // Values from db
                    label: item.label, // Label of the input/answer
                    unit: item.unit, // Unit to display on the right, or complementary information
                    type: item.type, // Type on the input text
                });
            });
        }

        // If not multiple (=> radio), get the default selected value;
        const existingValue = this.values.find(x => x.selected);

        this.questionForm = this.fb.group({
            questions: this.buildQuestionsCB(),
            singleAnswer: new FormControl(existingValue ? existingValue.key : null),
            inputs: this.buildInputs(),
        });

        this.onChanges();
        this.updateValues(this.questionForm.getRawValue());
    }

    get questions(): FormArray {
        return this.questionForm.get('questions') as FormArray;
    }

    get inputs(): FormArray {
        return this.questionForm.get('inputs') as FormArray;
    }

    onChanges() {
        this.questionForm.valueChanges.subscribe(val => {
            this.updateValues(val);
        });
    }

    private updateValues(val = null) {
        const res = [];
        if (val) {
            for (let i = 0; i < this.values.length; i++) {
                let itemValue = null;
                if (this.multiple) {
                    itemValue = val.questions[i] ? val.inputs[i] : null;
                } else {
                    itemValue = val.singleAnswer === this.values[i].key ? val.inputs[i] : null;
                }
                res.push({
                    key: this.values[i].key,
                    value: itemValue,
                });
            }
        }
        this.valueChange.emit(res);
    }

    buildQuestionsCB() {
        const arr = this.values.map(q => {
            return this.fb.control(q.selected);
        });
        return this.fb.array(arr);
    }

    buildInputs() {
        const arr = this.values.map(q => {
            return this.fb.control(q.value);
        });
        return this.fb.array(arr);
    }

    isMultiple() {
        return this.multiple;
    }

    getTextType(index: number) {
        if (this.values[index]) {
            if (this.allowedTypes.find(x => x === this.values[index].type)) {
                return this.values[index].type;
            }
        }
        return 'text';
    }
}
