import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { TranslateService } from 'app/shared/services/translate/translate.service';

import { HeatingPeriod, VolumeUnits } from '../../operating-monitoring.interface';
import { OperatingMonitoringService } from '../../operating-monitoring.service';
import {
    IndexReadingUpdate,
    MeasuringPointUpdate,
    MonitoringUpdate,
} from '../../operating-monitoring.update.interface';
import { OperatingReadingsService } from '../monitoring-readings.service';

@Component({
    selector: 'ga-monitoring-add-reading',
    templateUrl: './monitoring-add-reading.component.html',
    styleUrls: ['./monitoring-add-reading.component.scss'],
})
export class MonitoringAddReadingComponent implements OnInit, OnDestroy {
    /** Subscription */
    private subscription: Subscription;

    /** The monitoring actually selected */
    public monitoring: MonitoringUpdate;
    /** Copy of the original value of the currently edited reading */
    public originalReading: IndexReadingUpdate = null;
    /** The measuring point on which we should filter */
    public selectedMeasuringPoint = '';
    /** The period selected to filter index readings */
    public selectedPeriod: HeatingPeriod;
    /** Show the listPeriods quick modal */
    public displayListPeriods = false;

    get filteredIndexReadings(): IndexReadingUpdate[] {
        if (!this.monitoring) {
            return [];
        }
        return (
            this.monitoring.indexReadings
                .filter(i => {
                    // Format date to be usable by the input
                    i.date = i.date ? i.date.slice(0, 10) : null;

                    // If new index reading, always keep him
                    if (!i._id) {
                        return true;
                    }
                    // Filter on selected measuring point if set
                    if (this.selectedMeasuringPoint && i.measuringPointId !== this.selectedMeasuringPoint) {
                        return false;
                    }
                    // Filter on selected period if set
                    if (this.selectedPeriod && !this.dateIsInPeriod(i.date, this.selectedPeriod)) {
                        return false;
                    }

                    return true;
                })
                // Sort by date, keep new index first
                .sort((a, b) => (b._id ? b.date.localeCompare(a.date) : 1))
        );
    }

    /** Get the start of first period to end of last one */
    get globalDates(): { periodStart: string; periodEnd?: string } {
        const sorted = this.monitoring.periods
            .concat()
            .sort((a: HeatingPeriod, b: HeatingPeriod) => a.periodStart.localeCompare(b.periodStart));
        return {
            periodStart: sorted[0].periodStart,
            periodEnd: sorted[sorted.length - 1].periodEnd,
        };
    }

    constructor(
        private translateService: TranslateService,
        private operatingMonitoringService: OperatingMonitoringService,
        private operatingReadingService: OperatingReadingsService
    ) {}

    ngOnInit(): void {
        this.subscription = this.operatingMonitoringService.selectedMonitoring$.subscribe({
            next: value => (this.monitoring = value),
        });

        this.subscription.add(
            this.operatingMonitoringService.selectedPeriod$.subscribe({
                next: period => (this.selectedPeriod = period),
            })
        );
    }

    ngOnDestroy(): void {
        // ngOnDestroy is triggered if the user leaves the modal or switch tab
        this.subscription.unsubscribe();
        if (this.originalReading) {
            this.cancelEdit();
        }
    }

    /**
     * Returns true if given index reading is currently being edited
     */
    public isEditingIndexReading(indexReading: IndexReadingUpdate): boolean {
        return this.originalReading && this.originalReading._id === indexReading._id;
    }

    /**
     * Set given index reading as currently edited
     */
    public startEditingIndexReading(indexReading: IndexReadingUpdate): void {
        if (indexReading !== null) {
            this.originalReading = { ...indexReading };
        } else {
            this.originalReading = { date: null, measuringPointId: null, volume: null };
            this.monitoring.indexReadings.unshift({ ...this.originalReading });
        }
    }

    public cancelEdit() {
        if (!this.originalReading._id) {
            const itemIndex = this.monitoring.indexReadings.findIndex(reading => !reading._id);
            this.monitoring.indexReadings.splice(itemIndex, 1);
        } else {
            this.monitoring.indexReadings = this.monitoring.indexReadings.map(reading =>
                reading._id === this.originalReading._id ? this.originalReading : reading
            );
        }
        this.originalReading = null;
    }

    /**
     * Update currently edited index reading
     */
    public updateIndexReading(indexReading: IndexReadingUpdate): void {
        if (indexReading && this.getErrors(indexReading).length) {
            return;
        }

        // Partial update
        this.operatingMonitoringService.updateMonitoring({
            _id: this.monitoring._id,
            indexReadings: this.monitoring.indexReadings.map(reading => {
                // Put back date to ISO string
                reading.date = new Date(reading.date).toISOString();
                return reading;
            }),
        });

        this.originalReading = null;
    }

    public getVolumeUnitDisplayKey(measuringPointId: string): string {
        const measuringPoint: MeasuringPointUpdate = this.monitoring.measuringPoints.find(
            m => m._id === measuringPointId
        );

        if (!measuringPoint) {
            return '';
        }

        switch (measuringPoint.volumeUnit) {
            case VolumeUnits.L:
                return 'liters_long';
            case VolumeUnits.M3:
                return 'm3';
            default:
                return '';
        }
    }

    public setSelectedPeriod(period: HeatingPeriod) {
        this.selectedPeriod = period;
        this.displayListPeriods = false;
    }

    /**
     * Validate inputs of given reading or create error object otherwise
     */
    public getErrors(indexReading: IndexReadingUpdate): string[] {
        return this.operatingReadingService.validateReading(indexReading);
    }

    public async deleteIndexReading(indexReading: IndexReadingUpdate) {
        const confirm = await Swal.fire({
            title: this.translateService._('monitoring_delete_index_title'),
            text: this.translateService._('monitoring_delete_index_message'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translateService._('yes_delete'),
            cancelButtonText: this.translateService._('cancel'),
        });

        if (confirm.value) {
            this.monitoring.indexReadings = this.monitoring.indexReadings.filter(
                reading => reading._id !== indexReading._id
            );
            this.updateIndexReading(null);
        }
    }

    private dateIsInPeriod(date: string, period: HeatingPeriod) {
        if (date < period.periodStart) {
            return false;
        }
        if (period.periodEnd && date > period.periodEnd) {
            return false;
        }

        return true;
    }
}
