import { Injectable } from '@angular/core';
import { Company, CompanyLight } from 'app/shared/models/company.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CompaniesService extends PageService {
    private userCompanies: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    /**
     * Load the company from its id
     * @param {string} id - id of the company to load
     * @returns {Promise<Company>}
     */
    async getCompanyById(id: string): Promise<Company> {
        const response = await this.get('/api/companies/crud/' + id);
        return response.data;
    }

    /**
     * Get the user companies BehaviorSubject containing last companies for the connected user.
     * @returns {BehaviorSubject} behavior subject of the user companies to subscribe
     */
    public getUserCompanies(): BehaviorSubject<any[]> {
        return this.userCompanies;
    }

    getBranchesFromCompany(companiesList, holding) {
        return companiesList.filter(company => company.holding === holding._id);
    }

    /**
     * Return the list of branches of the company.
     * @param {string} companyId
     * @param {boolean} all - if true, return branches and all sub-branches
     * @return {Promise<{data: CompanyLight[]}>}
     */
    async getBranches(companyId: string, all: boolean = false): Promise<{ data: CompanyLight[] }> {
        const route = `/api/companies/${companyId}/branches` + (all ? '?all=1' : '');
        return this.get(route);
    }

    /**
     * Returns the list of companies the user belongs to (as a user or viewer by default).
     * @param userId
     * @param {string[]} roles: specify where to look into: users or viewers or both
     * @return {Promise<any>}
     */
    async getCompaniesListByUserId(userId, roles: string[] = null): Promise<any> {
        try {
            const query = roles ? '?userRoles=' + roles.toString() : '';
            const route = '/api/companies/user/' + userId + query;
            const response = await this.get(route);
            return response.data;
        } catch (e) {
            // error_code seems not to be used anymore, but we keep it in case of
            e.error_code = 'error_getListCompaniesByUser';
            throw e;
        }
    }

    /**
     * Refresh connected user companies list
     */
    async refreshUserCompaniesList() {
        const user = this.apiService.sessionService.getUser();
        const companies = await this.getCompaniesListByUserId(user._id);
        companies.sort((a, b) => a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' }));
        this.apiService.sessionService.setCompanies(companies);
        this.userCompanies.next(companies);
        return companies;
    }

    /**
     *
     * @param companies
     * @return {Promise<{
     *  categories: [],
     *  natures: [],
     *  organizations: []
     * }>}
     */
    getNatureOrgaCategoriesFromCompanies(companies): Promise<any> {
        const route = '/api/sites/companies/available/properties?companies=' + companies.toString();

        return this.get(route).then(response => {
            if (response.code === 200 && response.data) {
                return Promise.resolve(response.data);
            } else {
                const err = { errorCode: 'error_getSitesProperties' };
                return Promise.reject(err);
            }
        });
    }

    /**
     * Returns the list of companies th user has the right to see, depending on his type
     * @return {Promise<any>}
     */
    getVisibleCompaniesForUser(): Promise<Company[]> {
        return this.get('/api/companies/visible').then(
            response => {
                if (response.code === 200) {
                    return Promise.resolve(response.data);
                } else {
                    const err = { errorCode: 'error_getListCompanies' };
                    return Promise.reject(err);
                }
            },
            err => {
                if (!err.errorCode) {
                    err.errorCode = 'error_getListCompanies';
                }
                return Promise.reject(err);
            }
        );
    }

    /**
     * Return all the companies' list of user
     */
    getCompanyUsers(companyId, userTypesAllowed = null, companyRolesAllowed = null): Promise<any> {
        let route = '/api/companies/' + companyId + '/users';
        route += userTypesAllowed ? '?userTypes=' + userTypesAllowed : '';
        if (companyRolesAllowed) {
            route += userTypesAllowed ? '&' : '?';
            route += companyRolesAllowed ? 'userRoles=' + companyRolesAllowed : '';
        }

        return this.get(route)
            .then(response => {
                if (response.code === 200) {
                    return Promise.resolve(response.data);
                } else {
                    const err = { errorCode: 'error_getUsersBycompanyId' };
                    return Promise.reject(err);
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }
    /**
     * Delete company
     * @params {string} companyId - id of the company to delete.
     * @returns {Promise<*>}
     */
    async deleteCompany(companyId): Promise<any> {
        if (!companyId) {
            throw { errorCode: 'companyId_missing' };
        }
        const route = `/api/companies/${companyId}`;
        return this.delete(route);
    }
}
