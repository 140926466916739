import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ColorService } from 'app/shared/services/color/color.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

import { HeatingPeriod, Monitoring } from '../operating-monitoring.interface';
import { OperatingMonitoringService } from '../operating-monitoring.service';
import { KeyfigureTile } from './monitoring-keyfigures.interface';

@Component({
    selector: 'ga-monitoring-keyfigures',
    templateUrl: './monitoring-keyfigures.component.html',
    styleUrls: ['./monitoring-keyfigures.component.scss'],
})
export class MonitoringKeyfiguresComponent implements OnInit, OnDestroy {
    /** Subcription */
    private subscription: Subscription;
    /** Selected monitoring */
    private monitoring: Monitoring;

    /** Keyfigures title */
    public title = {
        displayKey: 'monitoring_title_short',
        color: 'inherit',
    };

    /** Tiles list */
    public tiles: Array<KeyfigureTile<HeatingPeriod>> = [
        {
            key: 'monitoring_profits',
            displayKey: 'monitoring_deviations_positive',
            getValue: (period: HeatingPeriod) => period.profits,
            unit: '€',
            displayValue: '-',
            isRecap: true,
            getRgbColorKey: (v: number) => {
                if (typeof v !== 'number' || v === 0) {
                    return null;
                }
                return v > 0 ? 'monitoring_deviation_positive' : 'monitoring_deviation_negative';
            },
            getDisplayKey: (v: number) => {
                if (typeof v !== 'number' || v === 0) {
                    return 'monitoring_deviations_positive';
                }
                return v > 0 ? 'monitoring_deviations_positive' : 'monitoring_deviations_negative';
            },
        },
        {
            key: 'monitoring_deviation',
            displayKey: 'monitoring_deviation',
            getValue: (period: HeatingPeriod) => period.deviation,
            unit: '%',
            displayValue: '-',
            isRecap: true,
            getRgbColorKey: (v: number) => {
                if (typeof v !== 'number' || v === 0) {
                    return null;
                }
                return v > 0 ? 'monitoring_deviation_positive' : 'monitoring_deviation_negative';
            },
        },
        {
            key: 'conso_noticed',
            displayKey: 'monitoring_conso_noticed',
            getValue: (period: HeatingPeriod) => period.consumptionHeating,
            unit: 'kWh',
            displayValue: '-',
        },
        {
            key: 'conso_expected',
            displayKey: 'monitoring_conso_expected',
            getValue: (period: HeatingPeriod) => period.valueRevaluedOnBillsPeriod,
            unit: 'kWh',
            displayValue: '-',
        },
        {
            key: 'djus',
            displayKey: 'djus_short',
            getValue: (period: HeatingPeriod) => period.modulatorRealOnBillsPeriod,
            unit: '',
            displayValue: '-',
        },
        {
            key: 'conso_noticed_per_dju',
            displayKey: 'monitoring_conso_noticed_per_dju',
            getValue: (period: HeatingPeriod) => period.consumptionHeatingPerModulatorReal,
            unit: 'kWh / DJU',
            displayValue: '-',
        },
        {
            key: 'conso_expected_per_dju',
            displayKey: 'monitoring_conso_expected_per_dju',
            getValue: (period: HeatingPeriod) => period.valueRevaluedOnBillsPeriod / period.modulatorRealOnBillsPeriod,
            unit: 'kWh / DJU',
            displayValue: '-',
        },
    ];

    constructor(
        private operatingMonitoringService: OperatingMonitoringService,
        private utilsService: UtilsService,
        private colorService: ColorService
    ) {}

    ngOnInit() {
        this.subscription = this.operatingMonitoringService.selectedPeriod$.subscribe({
            next: period => {
                this.monitoring = this.operatingMonitoringService.selectedMonitoring$.getValue();

                if (!period && this.monitoring) {
                    period = this.operatingMonitoringService.getSumAllPeriods(this.monitoring);
                }
                this.refreshTiles(period);
                this.refreshTitle(period);
            },
        });

        this.subscription.add(
            this.operatingMonitoringService.selectedMonitoring$.subscribe({
                next: monitoring => {
                    if (this.operatingMonitoringService.hasMonitorings) {
                        this.monitoring = monitoring;
                    } else if (this.operatingMonitoringService.isLastActionDelete) {
                        this.monitoring = null;
                    }
                },
            })
        );
    }

    public get keyfiguresTiles(): Array<KeyfigureTile<HeatingPeriod>> {
        return this.tiles.filter(x => !x.isRecap);
    }

    public get recapTiles(): Array<KeyfigureTile<HeatingPeriod>> {
        return this.tiles.filter(x => x.isRecap);
    }

    public hasSelectedMonitoring(): boolean {
        return !!this.monitoring;
    }

    private refreshTiles(period: HeatingPeriod) {
        const options = {
            defaultNotFinite: '-',
            defaultDisplayUnit: true,
        };
        this.tiles.forEach(tile => {
            const value = period ? tile.getValue(period) : null;
            tile.displayValue = this.utilsService.getNumberPretty(value, tile.unit, options);
            tile.color =
                tile.getRgbColorKey && period ? this.colorService.getRgbColor(tile.getRgbColorKey(value)) : null;
            if (tile.getDisplayKey) {
                tile.displayKey = tile.getDisplayKey(value);
            }
        });
    }

    private refreshTitle(period: HeatingPeriod) {
        const t = this.tiles.find(x => x.key === 'monitoring_profits');
        const titleValue = period ? t.getValue(period) : null;
        this.title.displayKey = 'monitoring_title_short';
        if (titleValue < 0) {
            this.title.displayKey = 'monitoring_drifting_long';
        } else if (titleValue > 0) {
            this.title.displayKey = 'monitoring_efficient_long';
        }
        this.title.color = t.color;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
