import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'user',
})
@Injectable()
export class UserFilterPipe implements PipeTransform {
    transform(users: any[], ...args: any[]): any {
        return users.filter(user => {
            let found = false;
            if (user.firstname) {
                found = found || user.firstname.toLowerCase().indexOf(args[0].toLowerCase()) !== -1;
            }
            if (user.name) {
                found = found || user.name.toLowerCase().indexOf(args[0].toLowerCase()) !== -1;
            }
            if (user.firstname && user.name) {
                const combo1 = user.name.toLowerCase() + ' ' + user.firstname.toLowerCase();
                const combo2 = user.firstname.toLowerCase() + ' ' + user.name.toLowerCase();
                found = found || combo1.indexOf(args[0].toLowerCase()) !== -1;
                found = found || combo2.indexOf(args[0].toLowerCase()) !== -1;
            }
            if (user.address) {
                found = found || user.address.toLowerCase().indexOf(args[0].toLowerCase()) !== -1;
            }
            if (user.active) {
                found = found || (user.active && args[0].toLowerCase() === 'actif');
            }
            if (!user.active) {
                found = found || (!user.active && args[0].toLowerCase() === 'inactif');
            }
            if (user.companies) {
                found =
                    found ||
                    user.companies.some(company => company.toLowerCase().indexOf(args[0].toLowerCase()) !== -1);
            }
            if (user.position) {
                found = found || user.position.toLowerCase().indexOf(args[0].toLowerCase()) !== -1;
            }
            return found;
        });
    }
}
