import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SwitchFilterElement } from './switch-filter-element.interface';

@Component({
    selector: 'ga-switch-filter',
    templateUrl: './switch-filter.component.html',
    styleUrls: ['./switch-filter.component.scss'],
})
export class SwitchFilterComponent implements OnInit {
    @Input() method = 'all';
    @Input() elements: SwitchFilterElement[] = [];
    @Input() columnsWidth = 0;
    @Input() direction = 'horizontal';
    @Input() fullLinear = false;
    @Output() updateFilter: EventEmitter<any> = new EventEmitter();

    constructor() {}
    ngOnInit() {}

    onRadioFilterChange(value) {
        this.updateFilter.emit(value);
    }

    public getClassFormCheck() {
        return this.direction === 'horizontal' ? (this.columnsWidth > 0 ? 'col-md-' + this.columnsWidth : 'col') : '';
    }

    public getFormClass() {
        return this.direction === 'vertical' ? 'vertical' : '';
    }

    public getColLabelClass() {
        return this.direction === 'vertical' || this.fullLinear ? 'col p-0' : 'col-md-12 p-0';
    }

    public getColRadioClass() {
        return this.direction === 'vertical' || this.fullLinear ? 'col-md-auto' : 'col-md-12 p-0';
    }
}
