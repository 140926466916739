import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { ChartDataSetsGA } from 'app/shared/models/charts/charts.interface';
import { ElecMonthlyBill } from 'app/shared/models/fluid-bills.interface';
import { ElecPower, ElecPowerPeriod } from './elec.interface';

@Injectable()
export class ElecPowerReachService {
    private readonly TIMESLOTS: string[] = ['pointe', 'HP', 'HC', 'HPH', 'HCH', 'HPE', 'HCE', 'base'];

    /**
     * Config to fill power reached according to a powerSubscription
     */
    private powerReachedConfig = [
        {
            powerSubscription: 'pointe',
            powerReached: [{ key: 'pointe' }],
        },
        {
            powerSubscription: 'HP',
            powerReached: [{ key: 'HP' }, { key: 'HPH', season: 1 }, { key: 'HPE', season: 0 }, { key: 'base' }],
        },
        {
            powerSubscription: 'HC',
            powerReached: [{ key: 'HC' }, { key: 'HCH', season: 1 }, { key: 'HCE', season: 0 }, { key: 'base' }],
        },
        {
            powerSubscription: 'HPH',
            powerReached: [{ key: 'HPH' }, { key: 'HP', season: 1 }, { key: 'base' }],
        },
        {
            powerSubscription: 'HCH',
            powerReached: [{ key: 'HCH' }, { key: 'HC', season: 1 }, { key: 'base' }],
        },
        {
            powerSubscription: 'HPE',
            powerReached: [{ key: 'HPE' }, { key: 'HP', season: 0 }, { key: 'base' }],
        },
        {
            powerSubscription: 'HCE',
            powerReached: [{ key: 'HCE' }, { key: 'HC', season: 0 }, { key: 'base' }],
        },
        {
            powerSubscription: 'base',
            powerReached: [{ key: 'base' }],
        },
    ];

    constructor() {}

    /**
     * Handle chart of power reached / subscribed.
     * Set datasets, labels, config, legend, colors
     */
    public getPowerChartDataSets(monthData: ElecMonthlyBill[]): ElecPowerPeriod[] {
        let powerPeriods: ElecPowerPeriod[] = [];
        let currentPeriod: ElecPowerPeriod;
        for (let i = 0, iLen = monthData.length; i < iLen; i++) {
            const elecPowerPeriod: ElecPowerPeriod = this.generateElecPowerPeriod(monthData[i]);

            // Ignore Period if powerReached and powerSubscription are all null
            if (this.isElecPowerPeriodValid(elecPowerPeriod)) {
                [powerPeriods, currentPeriod] = this.addPeriodToCurrentPeriodOrCreateNewPeriod(
                    powerPeriods,
                    currentPeriod,
                    elecPowerPeriod
                );
            }
        }

        // add dateInfo to each powerPeriod
        for (let i = 0, iLen = powerPeriods.length; i < iLen; i++) {
            const dateEnd = this.formatDate(powerPeriods[i].dateEnd);
            const dateStart = this.formatDate(powerPeriods[i].dateStart);
            powerPeriods[i].dateInfo = `${dateStart} à ${dateEnd}`;
        }
        return powerPeriods;
    }

    /**
     * Check if ElecPowerPeriod as at least a powerReached or a powerSubscription
     */
    private isElecPowerPeriodValid(elecPowerPeriod: ElecPowerPeriod): boolean {
        for (const timeSlot of this.TIMESLOTS) {
            if (elecPowerPeriod.powerReached[timeSlot]) {
                return true;
            }
            if (elecPowerPeriod.powerSubscription[timeSlot]) {
                return true;
            }
        }
        // all of powerReached and powerSubscription timesSlots are empty
        return false;
    }

    /**
     * Get the union of all requiredTimeSlots of all periods
     */
    public getRequiredTimeSlotsForAllPeriods(powerPeriods: ElecPowerPeriod[]): string[] {
        const allTimeSlots: ElecPower = {};
        for (let i = 0, iLen = powerPeriods.length; i < iLen; i++) {
            const powerPeriod = powerPeriods[i];
            // use powerReached when there is no powerSubscription
            const arr = powerPeriod.requiredTimeSlots.length
                ? powerPeriod.requiredTimeSlots
                : Object.keys(powerPeriod.powerReached).filter(x => powerPeriod.powerReached[x]);
            for (let j = 0, jLen = arr.length; j < jLen; j++) {
                allTimeSlots[arr[j]] = 1;
            }
        }
        // alway show timeslots in same order
        return Object.keys(allTimeSlots).sort(
            (firstTimeSlot, secondeTimeSlot) =>
                this.TIMESLOTS.indexOf(firstTimeSlot) - this.TIMESLOTS.indexOf(secondeTimeSlot)
        );
    }

    /**
     * Adds the perdiod to the current period if it has the same timeSlots
     * or this period is now the current period otherwise
     */
    private addPeriodToCurrentPeriodOrCreateNewPeriod(
        powerPeriods: ElecPowerPeriod[],
        currentPeriod: ElecPowerPeriod,
        powerPeriod: ElecPowerPeriod
    ): [ElecPowerPeriod[], ElecPowerPeriod] {
        // first iteration
        if (!currentPeriod) {
            currentPeriod = powerPeriod;
            powerPeriods.push(currentPeriod);
            return [powerPeriods, currentPeriod];
        }
        // Same powerSubscription => same period
        if (JSON.stringify(currentPeriod.powerSubscription) === JSON.stringify(powerPeriod.powerSubscription)) {
            currentPeriod.dateEnd = powerPeriod.dateEnd;
            currentPeriod.powerReached = this.getPowerReachMaxOfPeriods(currentPeriod, powerPeriod);
        } else {
            // new period
            currentPeriod = powerPeriod;
            powerPeriods.push(currentPeriod);
        }
        return [powerPeriods, currentPeriod];
    }

    /** Get the max by powerReached propertie of two ElecPower */
    private getPowerReachMaxOfPeriods(period1: ElecPowerPeriod, period2: ElecPowerPeriod): ElecPower {
        const powerReachedMax: ElecPower = {};
        // period2 and period1 have the same requiredTimeSlot
        const timeSlots = period1.requiredTimeSlots.length ? period1.requiredTimeSlots : this.TIMESLOTS;
        for (let i = 0, iLen = timeSlots.length; i < iLen; i++) {
            const timeSlot = timeSlots[i];
            const p1 = period1.powerReached[timeSlot] || 0;
            const p2 = period2.powerReached[timeSlot] || 0;
            powerReachedMax[timeSlot] = Math.max(p1, p2);
        }
        return powerReachedMax;
    }

    /** Generate an ElecPowerPeriod from a month data */
    private generateElecPowerPeriod(monthData: ElecMonthlyBill): ElecPowerPeriod {
        const result: ElecPowerPeriod = {
            dateStart: moment(monthData.dateStart).toDate(),
            dateEnd: moment(monthData.dateEnd).toDate(),
            powerSubscription: {},
            powerReached: {},
            requiredTimeSlots: [],
        };
        for (let i = 0, iLen = this.TIMESLOTS.length; i < iLen; i++) {
            const timeSlot = this.TIMESLOTS[i];
            if (monthData.contract.powerContract[timeSlot]) {
                result.requiredTimeSlots.push(timeSlot);
                result.powerSubscription[timeSlot] = monthData.contract.powerContract[timeSlot];
            }
        }

        result.powerReached = this.fillPowerReached(result, monthData);
        return result;
    }

    /**
     * Fill the all the power reached of a period
     * Makes the power reached coherent with power subscription
     */
    private fillPowerReached(elecPowerPeriod: ElecPowerPeriod, monthData: ElecMonthlyBill): ElecPower {
        const powerReached: ElecPower = {};
        for (let i = 0, iLen = this.TIMESLOTS.length; i < iLen; i++) {
            const timeSlot = this.TIMESLOTS[i];
            if (monthData.turpe.powerReached[timeSlot].quantity !== null) {
                powerReached[timeSlot] = monthData.turpe.powerReached[timeSlot].quantity;
            }
        }
        for (let i = 0, iLen = elecPowerPeriod.requiredTimeSlots.length; i < iLen; i++) {
            const timeSlot = elecPowerPeriod.requiredTimeSlots[i];
            let season = 0;
            const month = elecPowerPeriod.dateEnd.getMonth();
            // summer is from 01/04 to 31/10
            if (month > 2 && month < 10) {
                season = 0;
            } else {
                season = 1;
            }
            powerReached[timeSlot] = this.findOrCompletePowerReached(timeSlot, season, powerReached);
        }
        return powerReached;
    }

    /** format the data to show in graph */
    public formatPowerReachedGraphData(elecPowerPeriods: ElecPowerPeriod[], timeSlots: string[]): ChartDataSetsGA[] {
        const chartSeries: ChartDataSetsGA[] = [];
        // handle the stack on the graph so that a powerReach and a powerSubscription of same period are on the same stack
        let stack: number = elecPowerPeriods.length;
        // maxValue of all powerReached and powerSubscription to set the delta in power subscruption bars
        let maxValue = -1;
        // find maxValue of all data
        for (let i = 0, iLen = elecPowerPeriods.length; i < iLen; i++) {
            const elecPowerPeriod = elecPowerPeriods[i];
            for (const timeSlot in elecPowerPeriod.powerReached) {
                if (elecPowerPeriod.powerReached[timeSlot]) {
                    maxValue = Math.max(maxValue, elecPowerPeriod.powerReached[timeSlot]);
                }
            }
            for (const timeSlot in elecPowerPeriod.powerSubscription) {
                if (elecPowerPeriod.powerSubscription[timeSlot]) {
                    maxValue = Math.max(maxValue, elecPowerPeriod.powerSubscription[timeSlot]);
                }
            }
        }
        // goes throught i from the bottom to the top to have the most recent period on top
        for (let i = elecPowerPeriods.length; i > 0; i--) {
            const elecPowerPeriod: ElecPowerPeriod = elecPowerPeriods[i - 1];
            const dataPowerReached: number[][] = [];
            const dataPowerSubscription: number[][] = [];
            const dateEnd: string = this.formatDate(elecPowerPeriod.dateEnd);
            const dateStart: string = this.formatDate(elecPowerPeriod.dateStart);

            for (let j = 0, jLen = timeSlots.length; j < jLen; j++) {
                const timeSlot: string = timeSlots[j];

                dataPowerReached.push([0, elecPowerPeriod.powerReached[timeSlot]]);
                if (elecPowerPeriod.powerSubscription[timeSlot]) {
                    // to show powerSubscription as a floating bar
                    const delta: number = 0.005 * maxValue;
                    dataPowerSubscription.push([
                        elecPowerPeriod.powerSubscription[timeSlot] - delta,
                        elecPowerPeriod.powerSubscription[timeSlot] + delta,
                    ]);
                } else {
                    dataPowerSubscription.push([undefined, undefined]);
                }
            }
            // Power subscritpion data set
            const chartSeriePowerSubscription: ChartDataSetsGA = {
                label: 'Puissance souscrite',
                data: dataPowerSubscription,
                stack: `${stack}`,
                customData: [dateStart, dateEnd],
            };

            // Power reached data set
            const chartSeriePowerReached: ChartDataSetsGA = {
                label: `Puissance atteinte`,
                data: dataPowerReached,
                stack: `${stack}`,
                customData: [dateStart, dateEnd],
            };
            stack--;
            chartSeries.push(chartSeriePowerSubscription, chartSeriePowerReached);
        }
        return chartSeries;
    }

    /** format the date to MM/YYYY */
    private formatDate(date: Date): string {
        return moment(date).format('MMM YYYY');
    }

    /** find powerReached for timeSlot or complete it with closest timeSlot */
    private findOrCompletePowerReached(timeSlot: string, season: number, powersReached: ElecPower): number {
        const config = this.powerReachedConfig.find(x => x.powerSubscription === timeSlot);
        const powerReachedFilled = config.powerReached.find(
            x =>
                powersReached[x.key] !== null &&
                typeof powersReached[x.key] !== 'undefined' &&
                (typeof x.season === 'undefined' || season === x.season)
        );
        if (powerReachedFilled) {
            return powersReached[powerReachedFilled.key];
        }
        return 0;
    }
}
