import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AdminTab } from 'app/shared/models/admin-tab.interface';
import { User } from 'app/shared/models/users.interface';
import { SessionService } from 'app/shared/services/session/session.service';
import { UsersService } from 'app/shared/services/users/users.service';
import { TextService } from 'app/shared/services/utils/text.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    providers: [],
})
export class UserComponent implements OnInit {
    user: User = null;
    userSession: any = null;
    engineer: any = null;
    selectedTab: AdminTab = null;
    tabs: AdminTab[] = [];

    isLoading = true;

    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private sessionService: SessionService,
        private usersService: UsersService,
        private textService: TextService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.initData(params);
        });
    }

    /**
     * Params from url
     * @param {Params} params - can contain the userId and the tab key
     */
    async initData(params: Params) {
        try {
            let selectedTabKey = 'profil';
            this.userSession = this.sessionService.getUser();
            if (params && params['userId']) {
                // 1. Get user from params
                const userFromParams = await this.usersService.getUserById(params['userId']);
                if (!userFromParams) {
                    throw { errorCode: 'error_id_not_found' };
                }

                // 2. Check that the user session has the right to access the required user id
                // Load the session user info with his relations
                this.userSession = await this.usersService.getUserByIdWithRelationsAndContacts(
                    this.userSession._id,
                    null,
                    'users'
                );
                if (
                    !this.isUserInSession(userFromParams._id) &&
                    !this.userSession.relations.some(relation => relation._id.toString() === userFromParams._id) &&
                    !this.usersService.isAdmin(this.userSession)
                ) {
                    throw { errorCode: 'error_id_not_allowed' };
                }

                // 3. Params are correct, set the user
                this.user = userFromParams;

                selectedTabKey = params['tab'];
            } else if (!this.usersService.hasRightToCreate(this.userSession)) {
                // standard users can't access the creation page
                throw { errorCode: 'error_not_allowed' };
            }

            this.initTabs();
            this.selectTab(selectedTabKey);

            this.isLoading = false;
        } catch (err) {
            // err.errorCode can be set to error_getUserWithRelations, error_loadUser, error_id_not_found, error_id_not_allowed
            this.getSwalMsg(err.errorCode);
            this.router.navigateByUrl('/admin/utilisateurs');
        }
    }

    /**
     * Initialize the tabs with an array of AdminTabs.
     * Visibility and editing rights depend on the user's type and whether he belongs to the company or not.
     */
    initTabs() {
        const canEditProfileEngineerTab = this.canEditProfileTab();
        const canSeeAttributionSites = this.canSeeAttributionSites();
        const canEditAttributionSites = this.canEditAttributionSites();

        this.tabs = [
            { key: 'profil', name: 'profil', visible: true, canEdit: canEditProfileEngineerTab },
            {
                key: 'ingenieur',
                name: 'ingénieur',
                visible: this.usersService.isEM(this.user),
                canEdit: canEditProfileEngineerTab,
            },
            {
                key: 'attribution-des-sites',
                name: 'attribution des sites',
                visible: canSeeAttributionSites && canEditAttributionSites,
                canEdit: canEditAttributionSites,
            },
        ];
    }

    isCreating(): boolean {
        return !Boolean(this.user);
    }

    isUserInSession(userIdToCompare: string): boolean {
        return userIdToCompare === this.userSession._id.toString();
    }

    /**
     * Returns the right to edit the profile or engineer tab
     * @return {boolean}
     */
    canEditProfileTab(): boolean {
        // if it is a new user profile --> admin EM and superuser have access to it and can edit
        // if it's the profile of the user logged in -> can edit
        // if user = admin -> can edit
        // if user = energy manager -> can edit if he is one of his clients
        // if user = useradmin -> can edit if he is one of his standard users
        // if user = standard user -> can not edit

        return (
            this.isCreating() ||
            this.isUserInSession(this.user._id.toString()) ||
            this.usersService.isAdmin(this.userSession) ||
            (this.usersService.isEM(this.userSession) && this.usersService.isClient(this.user)) ||
            (this.usersService.isUserAdmin(this.userSession) && this.usersService.isUser(this.user))
        );
    }

    /**
     * Returns the right to edit the tab of sites attribution.
     * Returns false if it's during a creation.
     * Returns true if its an ADMIN or ENERGY_MANAGER
     * Returns true if it's a USER_ADMIN watching a standard user
     * @return {boolean}
     */
    canSeeAttributionSites(): boolean {
        return (
            !this.isCreating() &&
            (this.usersService.isFromCitron(this.userSession) ||
                (this.usersService.isUserAdmin(this.userSession) && this.usersService.isUser(this.user)))
        );
    }

    /**
     * Returns editing rights on the tab of sites attribution.
     * Specific rules are set for USER_ADMINS and EMs.
     * Standard users don't have access to that tab.
     * Admins can edit anytime.
     * @return {boolean}
     */
    canEditAttributionSites(): boolean {
        // if user = userAdmin -> can edit USERs
        // if user = energy manager -> can edit if he is one of his clients
        return (
            (this.usersService.isUserAdmin(this.userSession) && this.usersService.isUser(this.user)) ||
            (this.usersService.isEM(this.userSession) && this.usersService.isClient(this.user)) ||
            this.usersService.isAdmin(this.userSession)
        );
    }

    /**
     * Set the correct tab based on URL
     * @param {string} tabKey - name in the url after the user's id, match a key in the tabs list
     */
    selectTab(key: string) {
        this.selectedTab = this.tabs.find(t => t.key === key);

        // if the url is wrong, display profile + update url
        if ((!this.selectedTab || !this.selectedTab.visible) && this.user) {
            this.selectedTab = this.tabs[0];
            this.location.go('/admin/utilisateur/' + this.user._id + '/' + this.selectedTab.key);
        }
    }

    /**
     * Sweet alert based on the code sent
     * @param msg_code
     */
    getSwalMsg(msg_code) {
        const messages = {
            error_loadUser: ['Attention', "L'utilisateur demandé est indisponible.", 'warning'],
            error_getUserWithRelations: [
                'Toutes nos exuses',
                "Une erreur s'est produite lors du chargement du profil.",
                'error',
            ],
            error_not_allowed: [
                'Toutes nos exuses',
                "Vous n'avez pas les droits suffisants. Rapprochez-vous de votre Energy-Manager.",
                'warning',
            ],
        };

        // no default swal
        if (messages[msg_code]) {
            Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
        }
    }

    getUserName() {
        return !this.isLoading ? this.userDisplayName : '';
    }

    get userDisplayName() {
        if (this.user && this.user.firstname && this.user.name) {
            return this.textService.toTitleCase(this.user.firstname + ' ' + this.user.name);
        }
        return '';
    }
}
