import { Injectable } from '@angular/core';
import * as moment from 'moment';

// interfaces
import { QueryFilterVeh } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { CommonEnergyRepartitionMonthly } from 'app/shared/models/energy-data-bills.interface';
import { ChartSerieFollowup, GroupChartsDataFormated } from './followup.interface';

// services
import { ChartService } from 'app/shared/services/chart/chart.service';
import { ApiService } from '../../../shared/services/api/api.service';
import { PageService } from '../../../shared/services/page/page.service';

@Injectable()
export class FollowupService extends PageService {
    constructor(public apiService: ApiService, private chartService: ChartService) {
        super(apiService);
        this.fake = false;
    }

    /**
     * @todo: refacto with followup from energy page.
     * This function is a copy paste from the energy followup service.
     * The only difference is the DJU part (removed here).
     * Format data from monthly consumptions for charts datasets
     * @todo: typng & improve visibility.
     * @param {CommonEnergyRepartitionMonthly[]} data
     * @param {number} filterGroupIndex id of the current filter group being processed (tag)
     * @param {QueryFilterVeh} filters
     */
    formatToMonthlyConsumption(
        data: CommonEnergyRepartitionMonthly[],
        filterGroupIndex: number,
        filters: QueryFilterVeh
    ): GroupChartsDataFormated {
        const chart: { [consoType: string]: ChartSerieFollowup[] } = {
            L: [],
            ht: [],
            ttc: [],
        };

        const total: { [consoType: string]: { [monthLabel: string]: number } } = {
            L: {},
            ht: {},
            ttc: {},
        };

        const consoCostPerFuelType: { [consoType: string]: { [fuelType: string]: number[] } } = {
            ttc: {}, // {diesel: [100, 2000, 126, ...]}
            ht: {},
            L: {},
        };

        const start = moment.utc(filters.dateStart).startOf('month');
        const end = moment.utc(filters.dateEnd).startOf('month');
        const indexMonthYears = this.chartService.getIndexMonthYears(start, end);
        const nbMonths = indexMonthYears[end.year()][end.month()] + 1; // +1 cause index starts with 0

        data.forEach(databyMonth => {
            const labelMonth: string =
                this.chartService.getMonthNumber(databyMonth.month + 1) + '/' + databyMonth.items[0].year.toString();

            // if first item found on this month, init ttc htva and conso with zero
            if (typeof total.ttc[labelMonth] === 'undefined') {
                total.ttc[labelMonth] = 0;
            }
            if (typeof total.ht[labelMonth] === 'undefined') {
                total.ht[labelMonth] = 0;
            }
            if (typeof total.L[labelMonth] === 'undefined') {
                total.L[labelMonth] = 0;
            }

            /**
             * 1. compute total per conso type (ht, ttc, L)
             * 2. fill array of values per month, per fuel type (diesel/gasoline), per conso type (ht, ttc, L)
             */
            databyMonth.items.forEach(fuelData => {
                for (const consoType in consoCostPerFuelType) {
                    if (!fuelData.fuelType) {
                        continue;
                    }

                    // if first data on this month, init an array of n zeros, n being the number of months
                    if (typeof consoCostPerFuelType[consoType][fuelData.fuelType] === 'undefined') {
                        consoCostPerFuelType[consoType][fuelData.fuelType] = Array.apply(null, Array(nbMonths)).map(
                            Number.prototype.valueOf,
                            0
                        );
                    }

                    let currentValue = 0;

                    // get the current value being processed
                    switch (consoType) {
                        case 'ht':
                            currentValue = fuelData.totalHT.amount;
                            break;

                        case 'ttc':
                            currentValue = fuelData.totalTTC.amount;
                            break;

                        case 'L':
                            currentValue = fuelData.consumption.quantity;
                            break;
                    }

                    // add the value (conso qty or cost amount) to its total
                    total[consoType][labelMonth] += currentValue; // add the price to total price

                    // add the value (conso qty or cost amount) inside the global formated monthly data object
                    consoCostPerFuelType[consoType][fuelData.fuelType][
                        indexMonthYears[databyMonth.year][databyMonth.month]
                    ] = currentValue;
                }
            });
        });

        for (const consoOrCost in consoCostPerFuelType) {
            if (consoCostPerFuelType.hasOwnProperty(consoOrCost)) {
                let idStackFilter = 0; // order of each stack

                for (const fuelType in consoCostPerFuelType[consoOrCost]) {
                    if (consoCostPerFuelType[consoOrCost].hasOwnProperty(fuelType)) {
                        const chartSerie: ChartSerieFollowup = {
                            data: consoCostPerFuelType[consoOrCost][fuelType],
                            label: fuelType,
                            stack: filterGroupIndex, // = site n° / categorie n° / region n°
                            idStackFilter,
                            yAxisID: 'y-axis-0',
                            type: 'bar',
                        };
                        chart[consoOrCost].push(chartSerie);
                        idStackFilter++;
                    } else {
                        // TODO: Check Needed (Else to keep old behavior)
                        idStackFilter++;
                    }
                }
            }
        }

        const formatedData: GroupChartsDataFormated = {
            data: {
                chart: { L: chart.L, ht: chart.ht, ttc: chart.ttc },
                totalPrices: [total.ht, total.ttc],
                totalConsumptions: [total.L],
            },
            labels: this.chartService.getPeriodMonthlyLabels(start, end),
            stack: filterGroupIndex,
        };

        return formatedData;
    }
}
