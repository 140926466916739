import { defer, fromEvent, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { buildHotjarJsUrl } from './build-hotjar-js-url';
import { Hotjar } from './hotjar';

/**
 * Loads Hotjar
 *
 * @param document Document
 * @param hotjarId Hotjar identifier
 */
export function loadHotjar(document: Document, hotjarId: number): Observable<Hotjar> {
    return defer(() => {
        const script = document.createElement('script');
        const src = buildHotjarJsUrl(hotjarId, hotjarJsVersion);

        script.async = true;
        script.src = src;

        window._hjSettings = {
            hjid: hotjarId,
            hjsv: hotjarJsVersion,
        };

        function hj() {
            const windowHj = (hj as unknown) as NonNullable<Window['hj']>;
            const q = (windowHj.q = windowHj.q ? windowHj.q : []);
            q.push(arguments);
        }

        window.hj = hj;

        const loaded$ = fromEvent(script, 'load').pipe(map(() => void 0));

        document.head.appendChild(script);

        return loaded$.pipe(
            map(() => window.hj),
            filter((hotjar): hotjar is Hotjar => hotjar !== undefined)
        );
    });
}

/**
 * hotjar.js version
 */
const hotjarJsVersion = 6;

declare global {
    interface Window {
        _hjSettings?: { hjid: number; hjsv: number };
        hj?: {
            (...args: ReadonlyArray<unknown>): void;
            q?: unknown[];
        };
    }
}
