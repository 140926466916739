import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { AccessGuard } from '../../shared/services/guards/access-guard.service';
import { ContractsComponent } from './contracts.component';

const CONTRACTS_ROUTE = [
    {
        path: '',
        component: ContractsComponent,
        canActivate: [AccessGuard],
    },
];

@NgModule({
    declarations: [ContractsComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(CONTRACTS_ROUTE)],
})
export class ContractsModule {}
