import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { AccessGuard } from '../../../shared/services/guards/access-guard.service';
import { LoadCurveComponent } from './load-curve.component';

const LOAD_CURVE_ROUTE = [
    {
        path: '',
        component: LoadCurveComponent,
        canActivate: [AccessGuard],
        data: { page: 'energy' },
    },
];

@NgModule({
    declarations: [LoadCurveComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(LOAD_CURVE_ROUTE)],
})
export class LoadCurveModule {}
