import { Component, OnInit } from '@angular/core';
import { Params } from '@angular/router';

// components
import { ScopeFilterComponent } from '../scope-filter.component';

// interfaces
import { OptionValue } from 'app/shared/models/filter-config.interface';
import { FuelCard, Vehicle } from 'app/shared/models/vehicle.interface';
import { FilterAlias } from 'app/shared/services/filter/filter.interface';
import { Filter, FiltersOptions, OptionValueGroup, QueryFilterVeh } from '../scope-filter.interface';

// services
import { ScopeFilterService } from '../scope-filter.service';

export interface FilterVeh extends Filter<Vehicle> {
    fuelTypes: OptionValue[];
    vehicleCategories: Array<{ value: string }>;
    fuelCards: Array<{ _id: string; contractId: string }>;
}

export interface FiltersOptionsVeh extends FiltersOptions<Vehicle> {
    vehicleCategories: OptionValue[];
    fuelCards: FuelCard[];
}

@Component({
    selector: 'ga-vehicle-scope-filter',
    templateUrl: '../scope-filter.component.html',
    styleUrls: ['../scope-filter.component.scss'],
    providers: [ScopeFilterService],
})
export class VehicleScopeFilterComponent extends ScopeFilterComponent<Vehicle, QueryFilterVeh, FilterVeh>
    implements OnInit {
    /**
     * @override
     * Name of the entity expected inside the query. */
    entityFilterName = 'vehicles';

    /**
     * @override
     * Filter alias for URL of property
     */
    entityPropertyAlias: FilterAlias = 'vehiclesItems';

    /**
     * ------
     * Fluid types
     * ------
     */

    /**
     * @override
     * True when a conversion is processed if multiple fuel types are selected.
     */
    hasConversionOnMultipleFluids = false;

    /**
     * @override
     * Error message to display when zero fuel type is selected
     */
    missingFluidTypeMessage = 'Vous devez sélectionner au moins un carburant';

    /**
     * @override
     * Returns no message when multiple fuel tyeps are selected as there is no conversion needed.
     * */
    multipleFluidTypesMessage = '';

    /**
     * @override
     * Name to translate and display above the filter of available fluids */
    fluidTypesName = 'fuel_p';

    /**
     * -------
     * Top filter
     * -------
     */

    /**
     * @override
     * Elements for the top filter part. Name and value of each filter type. */
    topFilterElements: OptionValueGroup[] = [
        {
            displayName: 'Tous les véhicules',
            value: 'all',
        },
        {
            displayName: 'Par véhicule ',
            totalGroupName: 'Total du véhicule',
            value: 'vehicles',
            tags: {
                textProperty: 'registrationNumber',
                idProperty: '_id',
            },
        },
        {
            displayName: 'Par site ',
            totalGroupName: 'Total du site',
            value: 'sites',
            tags: {
                textProperty: 'complement',
                subTextProperty: 'googleAddress',
                idProperty: '_id',
            },
        },
        {
            displayName: 'Par région',
            totalGroupName: 'Total de la région',
            value: 'regions',
            tags: {
                textProperty: 'displayName',
                idProperty: '_id',
            },
        },
        {
            displayName: 'Par segment',
            totalGroupName: 'Total du segment',
            value: 'vehicleCategories',
            tags: {
                textProperty: 'displayName',
            },
        },
        {
            displayName: 'Par carte de carburant',
            totalGroupName: 'Total de la carte',
            value: 'fuelCards',
            tags: {
                textProperty: 'reference',
                subTextProperty: 'provider',
                idProperty: '_id',
            },
        },
        {
            displayName: 'Personnalisé',
            totalGroupName: 'Personnalisé',
            value: 'custom-filters',
        },
    ];

    /**
     * @override
     * Options available for each top filter element. */
    filtersOptions: FiltersOptionsVeh = {
        sites: [],
        entities: [],
        regions: [],
        vehicleCategories: [],
        fuelCards: [],
    };

    /**
     * ------
     * Global filter
     * ------
     */

    /** Filter holding the selected values */
    filter: FilterVeh;

    /**
     * @override
     * List of permitted url params for RRef
     */
    permittedParams = ['le', 'year', 'ds', 'de', 's', 'a', 'c', 'p', 'ct', 'e', 'v', 'fc', 'f', 'cf'];

    /**
     * @override
     * Fluids selected inside the filter. Property depends on the entity. */
    getFilterFluidTypes(): OptionValue[] {
        return this.filter.fuelTypes;
    }

    /**
     * @override
     * Fluid params to get availble years
     */
    get fluidYearsParams(): Params {
        return { fluids: ['fuel'] };
    }

    /**
     * @override
     * Reset the filter: unselect values */
    resetFilter(): void {
        this.filter = {
            sites: [],
            fuelTypes: [],
            years: {
                dateStart: '',
                dateEnd: '',
            },
            year: '',
            regions: [],
            companies: [],
            customFilter: null,
            entities: [],
            vehicleCategories: [],
            fuelCards: [],
        };
    }

    /**
     * @override
     * Get perimeter displayed name
     * @param {OptionValueGroup} perimeter - perimeter to get the display name of
     * @return {string} full display name of the perimeter
     */
    getPerimeterDisplayName(perimeter: OptionValueGroup): string {
        if (perimeter.value === 'all') {
            return perimeter.displayName;
        }

        return `Sites ${perimeter.displayName}`;
    }

    /**
     * ------
     * INIT VEHICLES DATA
     * ------
     */

    /**
     * @override
     * Init the fuel types: must be in the given companies' vehicles + available for this page (no restriction so far)
     * Select all fuel types by default
     */
    async initSelectedItems(selectedItems: string[]): Promise<void> {
        if (!this.fluidTypesAccepted.length) {
            this.fluidTypesAccepted = this.energyService.getAllFuelTypes(); // all existing fuel types
        }
        /** existing products in the given companies' vehicles */
        const fuelTypesFromCompanies = await this.filterService.getFuelTypesFromCompanies(false, this.filter.companies);

        // intersection from available products & selected companies products + select all by default
        const isSingleSelection =
            this.filtersConfig &&
            this.filtersConfig.toggleButtonSelection &&
            this.filtersConfig.toggleButtonSelection.enabled &&
            this.filtersConfig.toggleButtonSelection.selectionType === 'single';
        this.filter.fuelTypes = fuelTypesFromCompanies
            .filter(fuelType => this.fluidTypesAccepted.includes(fuelType.value))
            .map((fuelType, i) => {
                let isSelected = false;
                if (isSingleSelection) {
                    // If single selection, only select first item
                    const itemIndex = selectedItems.length ? selectedItems.indexOf(fuelType.value) : i;
                    isSelected = itemIndex === 0;
                } else {
                    isSelected = selectedItems.length ? selectedItems.indexOf(fuelType.value) >= 0 : true;
                }
                return Object.assign(fuelType, {
                    selected: isSelected,
                });
            });
    }

    /**
     * @override
     * Get sites from selected companies matching the selected fuel types
     * Init filter with these values, with clean google address displayed
     */
    async initSites(): Promise<void> {
        // create query to get sites having fuel
        const sites = await this.sitesService.getSites({
            companies: this.filter.companies.join(','),
            energies: 'fuel',
        });
        this.filtersOptions.sites = sites.data;

        this.sitesService.createGoogleAddressToDisplay(this.filtersOptions.sites);
    }

    /**
     * @override
     * Initilaze all filters elements: vehicles, vehicles' categories, fuel cards.
     */
    async initEntitiesData(): Promise<void> {
        await Promise.all([this.initVehicles(), this.initCategories(), this.initFuelCards()]);
    }

    /**
     * Initialize the list of vehicles from the sites's vehicles populated.
     */
    initVehicles(): void {
        this.filtersOptions.sites.forEach(site => {
            this.filtersOptions.entities.push(...site.vehicles);
        });
    }

    /**
     * Initialize the vehicles' categories, formated for autocompletion inputs.
     */
    async initCategories(): Promise<void> {
        try {
            const categories = await this.vehiclesService.getAttributesFromCompanies(
                this.filter.companies,
                'categories'
            );

            this.filtersOptions.vehicleCategories = categories.map(category => {
                return {
                    displayName: category.name,
                    value: category._id,
                };
            });
        } catch (e) {
            this.filtersOptions.vehicleCategories = [];
        }
    }

    /**
     * Initialize the fuel cards.
     */
    async initFuelCards() {
        try {
            this.filtersOptions.fuelCards = await this.fuelCardService.getFuelCardsFromCompanies({
                companies: this.filter.companies,
            });
        } catch (e) {
            this.filtersOptions.fuelCards = [];
        }
    }

    /**
     * ------
     *  COMPONENT INTERACTIONS
     * ------
     */

    /**
     * @override
     * Handle params when present.
     * Preselect the every available filters from params.
     * @param {Param} params - router params
     */
    initComponentSetup(params: Params): void {
        this.initComponentCommonSetup(params);

        // Energy settings.
        const fuelTypes = this.filterService.getParamAliasValues(params, 'fuelTypes', this.fluidTypesAccepted);
        this.updateItemsWithTypeFilter('fuelTypes', fuelTypes);

        // Fuel cards
        this.updateFilterByParam(params, 'fuelCards', 'fuelCards', 'fuelCards', 'fuelCards', '_id');

        // Categories
        this.updateFilterByParam(
            params,
            'vehicleCategories',
            'vehicleCategories',
            'vehicleCategories',
            'vehicleCategories',
            'value'
        );

        // Regions
        this.updateFilterByParam(params, 'regions', 'regions', 'regions', 'regions', 'value');

        // Sites
        this.updateFilterByParam(params, 'sites', 'sites', 'sites', 'sites', '_id');

        // Vehicles
        this.updateFilterByParam(params, 'vehicles', 'vehicles', 'sites', 'entities', '_id');
    }

    /**
     * @override
     * Create the query from the selected elements inside the filter
     * @returns {QueryFilterVeh} query created
     */
    createQuery(): QueryFilterVeh {
        // empty query filter
        const filter: QueryFilterVeh = this.resetQueryFilter();

        // set dates
        this.setFilterDates(filter);

        // set fuel types
        const fuelTypesValues = this.filter.fuelTypes
            .filter(fuelType => fuelType.selected)
            .map(fuelType => fuelType.value);
        filter.fuelTypes = fuelTypesValues.join(',');

        // set sites perimeter
        if (!this.disablePerimeter) {
            const selectedPerimeter = this.perimeterFilterElements.find(x => x.selected === true);
            if (selectedPerimeter) {
                filter.sitesStatus = selectedPerimeter.value;
            }
        }

        // set the query for the current top filter element selected
        switch (this.mainFilterSelected) {
            case 'regions':
                this.mainFilterSelectedValues = this.filter.regions;
                filter.regions = this.filter.regions.map(region => region.value).join(',');
                break;

            case 'sites':
                this.mainFilterSelectedValues = this.filter.sites;
                filter.sites = this.filter.sites.map(site => site._id).join(',');
                break;

            case 'vehicles':
                this.mainFilterSelectedValues = this.filter.entities;
                filter.vehicles = this.filter.entities.map(veh => veh._id).join(',');
                break;

            case 'vehicleCategories':
                this.mainFilterSelectedValues = this.filter.vehicleCategories;
                filter.vehicleCategories = this.filter.vehicleCategories.map(cat => cat.value).join(',');
                break;

            case 'fuelCards':
                this.mainFilterSelectedValues = this.filter.fuelCards;
                filter.fuelCards = this.filter.fuelCards.map(card => card._id).join(',');
                break;

            case 'custom-filters':
                this.mainFilterSelectedValues = [this.filter.customFilter];
                if (this.customFilterId) {
                    filter.customFilter = this.customFilterId;
                } else {
                    filter.customFilter =
                        this.filter.customFilter && this.filter.customFilter.value
                            ? this.filter.customFilter.value.toString()
                            : null;
                }
                break;

            case 'all':
            default:
                this.mainFilterSelected = 'all';
                this.mainFilterSelectedValues = [
                    {
                        value: 'all',
                        displayName: 'Tous les véhicules',
                    },
                ];
                break;
        }

        this.filterChange.emit(filter);
        return filter;
    }

    /**
     * Reset the query emited to the parent page
     * @returns {QueryFilterVeh} empty query with only companies ids set
     */
    resetQueryFilter(): QueryFilterVeh {
        return {
            dateStart: '',
            dateEnd: '',
            fuelTypes: '',
            sites: '',
            vehicles: '',
            regions: '',
            vehicleCategories: '',
            companies: this.filter.companies ? this.filter.companies.join(',') : '',
            fuelCards: '',
            sitesStatus: 'all',
            customFilter: '',
            entities: '',
            energies: 'fuel',
        };
    }
}
