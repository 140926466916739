import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

@Injectable()
export class ExportService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    getFile(route: string, params?: Params): Observable<Blob> {
        const headers = new HttpHeaders({
            Authorization: 'Basic ' + this.apiService.getToken(),
            'Content-Type': 'application/json',
        });

        const options = {
            headers,
            responseType: 'blob',
        };

        return this.apiService.get(route, options, params);
    }
}
