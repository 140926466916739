import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

@Injectable()
export class PatternService extends PageService {
    properties = {};

    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get dictionnary for property. Dictionnary is all regex found in all the patterns of the identification pattern.
     */
    public async getDictionaryForProperty(
        energyType: string,
        identificationPatternId: string,
        propertyName: string
    ): Promise<string[]> {
        const route = `/api/patterns/${energyType}/search?identificationPatternId=${identificationPatternId}&property=${propertyName}`;

        return this.get(route);
    }

    getPropertyNameFromSlug(property: string, type: string = 'elec') {
        /* if (this.properties[type][property]) {
    		return this.properties[type][property];
    	} */

        return property;
    }
}
