import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { TranslateService } from 'app/shared/services/translate/translate.service';

import { Monitoring } from '../operating-monitoring.interface';
import { OperatingMonitoringService } from '../operating-monitoring.service';
import { MonitoringUpdate } from '../operating-monitoring.update.interface';
import { MonitoringEditionService } from './monitoring-edition.service';

@Component({
    selector: 'ga-monitoring-edition',
    templateUrl: './monitoring-edition.component.html',
    styleUrls: ['./monitoring-edition.component.scss'],
    providers: [MonitoringEditionService],
})
export class MonitoringEditionComponent implements OnInit, OnDestroy {
    /** The created or updated monitoring */
    private monitoring: MonitoringUpdate;
    /** Subscription */
    private subscription: Subscription;
    /** The new monitoring form */
    get form() {
        return this.monitoringEditionService.form;
    }
    get isEditing(): boolean {
        return Boolean(this.monitoring._id);
    }

    @Output() monitoringSaved: EventEmitter<Monitoring> = new EventEmitter<Monitoring>();

    constructor(
        private translateService: TranslateService,
        private operatingMonitoringService: OperatingMonitoringService,
        private monitoringEditionService: MonitoringEditionService
    ) {}

    ngOnInit() {
        this.subscription = this.monitoringEditionService.subscription;
        this.subscription.add(
            this.monitoringEditionService.monitoring$.subscribe({
                next: value => (this.monitoring = value),
            })
        );
        this.subscription.add(
            this.operatingMonitoringService.editedMonitoring$.subscribe({
                next: value => (this.monitoring._id = value && value._id),
            })
        );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public async actionButton(): Promise<void> {
        if (this.isEditing) {
            const confirm = await Swal.fire({
                title: this.translateService._('monitoring_edit'),
                text: this.translateService._('monitoring_edit_warning_msg'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.translateService._('yes_edit'),
                cancelButtonText: this.translateService._('cancel'),
            });

            if (confirm.value) {
                await this.operatingMonitoringService.updateMonitoring(this.monitoring);
                this.monitoringSaved.next(null);
            }
        } else {
            await this.operatingMonitoringService.createMonitoring(this.monitoring);
            this.monitoringSaved.next(null);
        }
    }
}
