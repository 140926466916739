import { Injectable } from '@angular/core';
import { QuerySort } from 'app/shared/models/pagination.interface';
import { Page, PaginationConfig, PaginationIndexes } from '../pagination.interface';

@Injectable()
export class PaginationService {
    /** Pages array */
    public pagesArray: Page[] = [];

    /** Pagination configuration */
    public pagination: PaginationConfig = {
        indexStart: 0,
        indexEnd: 9,
        range: 10,
        currentPage: 1,
        countPages: 0,
        defaultSortAsc: true,
        canDisplayAll: false,
    };

    /** Sort fields elements */
    public sort: QuerySort = {
        asc: true,
        field: 'routingReference',
    };

    /**
     * Update pagination with new selected values
     */
    public updatePagination(newPagination: PaginationIndexes) {
        this.pagination.indexStart = newPagination.indexStart;
        this.pagination.indexEnd = newPagination.indexEnd;
    }

    /**
     * Update the sort caret class depending on the user action.
     */
    public getClassesSort(header: string): string[] {
        const classes = ['fa', 'hover-color'];

        let icon = 'fa-sort';
        if (this.sort.field === header) {
            icon = this.sort.asc === this.pagination.defaultSortAsc ? 'fa-caret-down' : 'fa-caret-up';
        }

        classes.push(icon);
        return classes;
    }

    /**
     * Update sort header and direction depending on selection
     */
    public updateSort(newHeader: string): void {
        if (this.sort.field === newHeader) {
            this.sort.asc = !this.sort.asc;
        } else {
            this.sort.field = newHeader;
        }
    }

    /**
     * Set pagination indexes on first page
     */
    public setFirstPage(): void {
        this.pagination.indexStart = 0;
        this.pagination.indexEnd = this.pagination.range - 1;
        this.pagination.currentPage = 1;
    }
}
