import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { ContractPricing, ContractPricingItem } from 'app/shared/models/contract-pricing.interface';

import {
    ContractPopulated,
    InfoContract,
    PricingSelected,
    PricingSelectedValue,
    RoutingRefContract,
} from 'app/shared/models/contract.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { ContractUpdate, PricingOption, PricingSection } from './contract-edition.interface';

@Injectable()
export class ContractEditionService {
    /**
     * Contract property with latest values
     */
    private _contract: ContractPopulated;

    /**
     * All available contract pricings for the contract's fluid
     */
    private _pricings: ContractPricing[] = [];

    /**
     * All the contract pricing items that can be found in `pricings`
     */
    private _pricingItems: ContractPricingItem[] = [];

    /**
     * Selected pricings options
     */
    private _selectedPricingOptions: PricingOption[] = [];

    /**
     * Subject to subscribe changes to pricing options selected.
     */
    public pricingOptionsSubject: BehaviorSubject<PricingOption[]> = new BehaviorSubject<PricingOption[]>([]);

    /**
     * Object of all updates to set to contract.
     */
    public updates: ContractUpdate = {
        _id: '',
        validity: {
            infos: true,
            pricings: true,
        },
        isValid() {
            return Boolean(this.validity.infos && this.validity.pricings);
        },
    };

    constructor(private utilsService: UtilsService) {}

    /**
     * Update selected pricing options and emit next subject value
     * @param {PricingOption[]} pricings
     */
    public updateSelectedPricingOptions(pricings: PricingOption[]) {
        this._selectedPricingOptions = _.uniqBy(pricings, 'label');
        this.pricingOptionsSubject.next(this.selectedPricingOptions);
    }

    /**
     * Set infos updates
     */
    public updateInfos(infos: { data: InfoContract; isValid: boolean }) {
        const unitRate = _.get(this.updates, 'infos.unitRate') || _.get(this.contract, 'infos.unitRate');
        this.updates.infos = unitRate ? Object.assign({}, infos.data, { unitRate }) : infos.data;
        this.updates.validity.infos = infos.isValid;
    }

    /**
     * Set pricings updates and emit next subject value
     */
    public updateCommonPricing(pricings: PricingSelected[]) {
        this.updates.pricings = pricings;

        // Reset the rRefs pricing which are not in the updated common contract pricing
        this.resetUnselectedRrefsPricing();
    }

    /**
     * Set routing references updates
     */
    public updateRoutingReferences(routingReferences: Array<RoutingRefContract<RoutingReference>>) {
        this.updates.routingReferences = routingReferences;
    }

    /**
     * Reset the Rrefs pricings related to the updated (unselected) common contract pricing
     */
    public resetUnselectedRrefsPricing() {
        // check if updated contract pricings
        if (!Array.isArray(this.updates.pricings)) {
            return;
        }

        // Update (reset) the rRefs pricings which are not in the updated contract.pricings
        let update = false;
        for (const rRef of this.contract.routingReferences) {
            const rRefPath = _.get(rRef, 'pricing.pathContractPricing');

            if (Array.isArray(rRefPath) && rRefPath.length) {
                const foundPath = this.updates.pricings.some(p =>
                    _.isEqual(p.pathContractPricing, rRef.pricing.pathContractPricing)
                );
                if (!foundPath) {
                    rRef.pricing = null;
                    update = true;
                }
            }
        }

        if (update) {
            this.updateRoutingReferences(this.contract.routingReferences);
        }
    }

    /**
     * Set prices unitRate
     */
    public updateUnitRate(unitRate: number) {
        const currentInfos = _.get(this.updates, 'infos') || _.get(this.contract, 'infos');
        const routingRefs = _.get(this.updates, 'routingReferences') || _.get(this.contract, 'routingReferences');
        if (routingRefs) {
            this.updates.routingReferences = routingRefs;
        }
        this.updates.infos = Object.assign({}, currentInfos, { unitRate });
    }

    /**
     * Is contract pricing item a unit pricing
     * @param {string} id - id of the ContractPricingItem
     * @returns {boolan} true if item is a unit pricing, false is not or not found.
     */
    public isPricingItemUnitPricing(id: string): boolean {
        const item = this._pricingItems.find(x => x._id === id);
        return item ? item.isUnitPricing : false;
    }

    /**
     * Get pricing item value. Apply conversion on pricing item unit if needed.
     * Doesn't mutate object apssed in arguments.
     * @param {PricingSelectedValue} item - item to get value from.
     * @param {number} rate - rate to apply to value. A multiplication is done between item value and rate.
     * @returns {string} value
     */
    public getPricingItemValue(item: PricingSelectedValue, rate: number): string {
        if (!this.isPricingItemUnitPricing(item.id)) {
            return item.value;
        }
        const parsed = parseFloat(item.value);
        let val = null;
        // Pricing values must be >= 0
        if (!Number.isNaN(parsed) && parsed >= 0) {
            val = this.utilsService.roundNumber(parsed * rate).toString();
        }
        return val;
    }

    /**
     * Compute all prices for routing references with the contract unit rate
     */
    public computeUpdateRoutingReferencesPrices() {
        const unitRate: number = _.get(this.updates, 'infos.unitRate') || _.get(this.contract, 'infos.unitRate');
        const routingReferences = _.get(this.updates, 'routingReferences') || _.get(this.contract, 'routingReferences');
        if (typeof unitRate === 'number' && routingReferences) {
            routingReferences.forEach(rf => {
                const pricingValues: PricingSelectedValue[] = _.get(rf, 'pricing.values');
                if (pricingValues) {
                    pricingValues.forEach(pv => {
                        if (pv !== null) {
                            pv.value = this.getPricingItemValue(pv, unitRate);
                        }
                    });
                }
            });
        }
    }

    /**
     * Reset updates object
     */
    public resetUpdates() {
        this.updates = {
            _id: this._contract._id,
            validity: {
                infos: true,
                pricings: true,
            },
            isValid: this.updates.isValid,
        };
    }

    /**
     * Set the selected pricing inside the contract's selected routing reference
     * @param {RoutingRefContract<RoutingReference>} routingReference
     * @param {PricingSection} pricingSection
     */
    setRoutingReferencePricing(routingReference: RoutingRefContract<RoutingReference>, pricingSection: PricingSection) {
        // Find the current section to get the path IDS
        const currentSection = _.find(this.selectedPricingOptions, { label: pricingSection.label });
        const pathContractPricings: string[] = currentSection.id.split('-');

        // Create pricing object to associate to the routing reference
        const obj: PricingSelected = {
            pathContractPricing: pathContractPricings,
            values: this.getInitialPricingValues(pricingSection.pricingItems),
        };

        // Find the routing reference inside the contract
        const contractRoutingReferencesSelected = this.contract.routingReferences.find(
            x => x.routingReference._id === routingReference.routingReference._id
        );

        // Set its new pricing value
        contractRoutingReferencesSelected.pricing = obj;

        this.updateRoutingReferences(this.contract.routingReferences);
    }

    /**
     * Set initial array for routingReferences pricingItems
     * @param {ContractPricingItem[]} pricingItems
     */
    private getInitialPricingValues(pricingItems: ContractPricingItem[]): PricingSelectedValue[] {
        const values = pricingItems.map(x => ({ id: x._id, value: null }));

        return values;
    }

    /**
     * Getters and setters
     */
    public get contract(): ContractPopulated {
        return this._contract;
    }
    public set contract(contract: ContractPopulated) {
        this._contract = contract;
        this.updates._id = this._contract._id;
    }
    public get pricings(): ContractPricing[] {
        return this._pricings;
    }
    public set pricings(pricings: ContractPricing[]) {
        this._pricings = pricings;
    }
    public get pricingItems(): ContractPricingItem[] {
        return this._pricingItems;
    }
    public set pricingItems(pricingItems: ContractPricingItem[]) {
        this._pricingItems = pricingItems;
    }
    public get selectedPricingOptions(): PricingOption[] {
        return this._selectedPricingOptions;
    }
    public set selectedPricingOptions(pricingOptions: PricingOption[]) {
        this.updateSelectedPricingOptions(pricingOptions);
    }
}
