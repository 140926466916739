import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-sites-select-pdls',
    templateUrl: './select-pdls.component.html',
    styleUrls: ['./select-pdls.component.scss'],
})
export class SitesSelectPdlsComponent implements OnInit {
    @Input() pdls: any[] = [];
    @Input() routingReferencesInZone: any = [];

    @Output() select: EventEmitter<any> = new EventEmitter();
    @Output() unselect: EventEmitter<any> = new EventEmitter();

    constructor() {}
    ngOnInit() {}

    // returns true if routingReferencesInZone contains the PDL
    isPDLActive(pdl): boolean {
        return this.routingReferencesInZone.some(routingReference => {
            return routingReference._id === pdl._id;
        });
    }

    getCheckBoxClass(pdl): string {
        if (this.isPDLActive(pdl)) {
            return 'fa-check-square';
        }

        return 'fa-square-o';
    }

    onCheckBoxClick(pdl) {
        const isPDLActive = this.isPDLActive(pdl);

        if (isPDLActive) {
            this.unselect.emit(pdl);
        } else {
            this.select.emit(pdl);
        }
    }

    getPdlContractDates(pdl, contract) {
        const search = contract.routingReferences.find(x => x.routingReference === pdl._id);
        return {
            dateStart: search ? search.dateStart : '',
            dateEnd: search ? search.dateEnd : '',
        };
    }
}
