import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ColorService } from 'app/shared/services/color/color.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { SeasonKeyfigures } from '../operating-monitoring.interface';
import { OperatingMonitoringService } from '../operating-monitoring.service';

@Component({
    selector: 'ga-monitoring-resume',
    templateUrl: './monitoring-resume.component.html',
    styleUrls: ['./monitoring-resume.component.scss'],
})
export class MonitoringResumeComponent implements OnInit, OnDestroy {
    /** Subscription */
    private subscription: Subscription;

    /** Seasons keyfigures */
    public keyfigures: SeasonKeyfigures[] = [];

    constructor(
        private colorService: ColorService,
        private operatingMonitoringService: OperatingMonitoringService,
        private utilsService: UtilsService
    ) {}

    ngOnInit() {
        this.subscription = this.operatingMonitoringService.seasonsKeyfigures$.subscribe({
            next: values => {
                this.keyfigures = values;
            },
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public getSeasonDate(date: string): string {
        const year = new Date(date).getUTCFullYear();
        return `${year} - ${year + 1}`;
    }

    public formatDevation(value: number): string {
        return this.utilsService.getNumberPretty(value, '%', { defaultNotFinite: '-' });
    }

    public formatProfit(value: number): string {
        return this.utilsService.getNumberPretty(value, '€', { defaultNotFinite: '-' });
    }

    public formatConsumption(value: number): string {
        return this.utilsService.getNumberPretty(value, 'kWh', { defaultNotFinite: '-' });
    }

    public getColor(key: string): string {
        return this.colorService.getRgbColor(key);
    }

    public getKeyFigureColor(keyfigure: SeasonKeyfigures) {
        if (keyfigure.deviation < 0) {
            return this.colorService.getRgbColor('monitoring_deviation_negative');
        }
        return this.colorService.getRgbColor('monitoring_deviation_positive');
    }

    public getEfficientPeriodsText(count) {
        if (!count || count < 2) {
            return 'monitoring_efficient_period';
        }
        return 'monitoring_efficient_periods';
    }

    public getDriftingPeriodsText(count) {
        if (!count || count < 2) {
            return 'monitoring_drifting_period';
        }
        return 'monitoring_drifting_periods';
    }
}
