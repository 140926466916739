import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UtilsService } from 'app/shared/services/utils/utils.service';

@Component({
    selector: 'ga-simple-autocomplete',
    templateUrl: './simple-autocomplete.component.html',
    styleUrls: ['./simple-autocomplete.component.scss'],
    providers: [],
})
export class SimpleAutocompleteComponent<T extends object, U> implements OnInit {
    private _list: T[] = [];
    private _selectedItem: T;

    searchValue = '';
    canShowList = false;
    filteredList: T[] = [];
    autocompleteClass = '';

    // optional inputs
    @Input() name = 'simple-autocomplete';
    @Input() placeholder = '';
    @Input() textAttribute = 'text';
    @Input() defaultNoText = 'Aucune valeur associée à cet item';
    @Input() defaultNoList = 'Pas de valeurs';
    @Input() valueAttribute = 'value';
    @Input() filterAttributes: string[] = [];

    // important inputs
    @Input() value: U;
    @Input()
    get list() {
        return this._list;
    }
    set list(list) {
        this._list = list;
        this.setFilteredList();
    }

    @Input()
    get selectedItem() {
        return this._selectedItem;
    }

    set selectedItem(selectedItem) {
        this._selectedItem = selectedItem;

        if (selectedItem) {
            this.searchValue = selectedItem[this.textAttribute];
            this.setFilteredList();
        } else {
            this.searchValue = '';
            this.setFilteredList();
        }
    }
    @Input() disableActions = false;

    // event
    @Output() valueChange: EventEmitter<any> = new EventEmitter(true);

    // list template used to allow html in list
    @ContentChild(TemplateRef, { static: true }) listItemTemplate: TemplateRef<any>;

    constructor(private utils: UtilsService) {}

    ngOnInit() {}

    showList() {
        this.canShowList = true;
        this.autocompleteClass = 'open';
    }

    hideList() {
        this.canShowList = false;
        this.autocompleteClass = '';

        if (this.selectedItem) {
            this.searchValue = this.selectedItem[this.textAttribute];
        }
    }

    toggleList() {
        if (!this.disableActions) {
            if (this.canShowList) {
                this.hideList();
            } else {
                this.showList();
            }
        }
    }

    selectValue(item: T) {
        this.setValue(item);
        this.hideList();
    }

    getItemText(item: T) {
        return item[this.textAttribute] || this.defaultNoText;
    }

    setFilteredList() {
        if (!this.searchValue) {
            this.filteredList = this.list;
        }

        this.filteredList = this.list.sort().filter(item => {
            const isInTextAttribute = this.valueIncludeSearch(item[this.textAttribute]);

            if (this.filterAttributes.length) {
                return (
                    isInTextAttribute ||
                    this.filterAttributes.some(key => item[key] && this.valueIncludeSearch(item[key]))
                );
            } else {
                return isInTextAttribute;
            }
        });
    }

    private setValue(item: T) {
        this.value = item[this.valueAttribute];
        this.searchValue = item[this.textAttribute];
        this.selectedItem = item;
        this.valueChange.emit(this.value);
    }

    private valueIncludeSearch(value: U): boolean {
        if (value !== null && typeof value !== 'undefined') {
            return this.utils
                .removeAccentFromString(value.toString())
                .toLowerCase()
                .includes(this.searchValue.toLowerCase());
        }

        return false;
    }
}
