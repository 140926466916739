import { Injectable } from '@angular/core';
import { ContractPricing, ContractPricingItem } from '../../models/contract-pricing.interface';
import { ContractPopulated } from '../../models/contract.interface';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

@Injectable()
export class ContractsService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get contract populated with pricings and routing references
     * @param {string} id - contract id
     * @returns {Promise<ContractPopulated>}
     */
    public async getContractPopulated(id: string): Promise<ContractPopulated> {
        const route = `/api/contracts/${id}/sites`;
        const res = await this.get(route);
        return res.data;
    }

    /**
     * Update contract
     * @param {object} contract - contract with properties to update
     * @returns {Promise<ContractPopulated>}
     */
    public async updateContract(contract: { _id: string; [prop: string]: any }): Promise<ContractPopulated> {
        const route = `/api/contracts/crud/${contract._id}`;
        const res = await this.put(route, contract);
        return res.data;
    }

    /**
     * Get all pricings available for a fluid
     * @param {string} fluid - fluid to get pricings for
     * @returns {Promise<{pricings: ContractPricing[], pricingsItems: ContractPricingItem[]}>}
     */
    public async getPricings(
        fluid: string
    ): Promise<{ pricings: ContractPricing[]; pricingsItems: ContractPricingItem[] }> {
        const route = `/api/contracts/pricings`;
        const res = await this.get(route, null, { fluid });
        return res.data;
    }
}
