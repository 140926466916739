import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4lang_fr_FR from '@amcharts/amcharts4/lang/fr_FR';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';

import { IMyDateRangeModel } from 'mydaterangepicker';

import { DateFilter, Filter, Legend, PeriodFilter, TimelineData } from 'app/shared/models/bills-timeline.interface';
import { ColorService } from 'app/shared/services/color/color.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';

am4core.useTheme(am4themes_animated);

@Component({
    selector: 'ga-bills-timeline',
    templateUrl: './bills-timeline.component.html',
    styleUrls: ['./bills-timeline.component.scss'],
    providers: [],
})
export class BillsTimelineComponent implements OnInit, OnChanges, OnDestroy {
    @Input() legends: Legend[];

    @Input() filters: Filter = {};

    @Input() timelineDatas: TimelineData[] = [];

    @Output() periodOnChange: EventEmitter<PeriodFilter> = new EventEmitter<PeriodFilter>();

    /**
     * Get legend filtered based on timeline data displayed
     */
    public get legendsFiltered(): Legend[] {
        if (!this.legends) {
            return [];
        }
        return this.legends.filter(x => this.timelineDatas.some(y => y.type === x.type));
    }

    private timeline: am4charts.XYChart;
    private xAxis: am4charts.DateAxis<am4charts.AxisRenderer>;

    private dateFormat = 'dd/MM/yyyy';

    @ViewChild('timelineEl', { static: true }) timelineEl: ElementRef;

    constructor(
        private translateService: TranslateService,
        private router: Router,
        private colorService: ColorService
    ) {}

    ngOnInit() {
        this.initLegends();
        setTimeout(() => {
            this.initTimeline();
        });
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (const propName in changes) {
            if (propName === 'timelineDatas') {
                this.setTimelineData(changes.timelineDatas.currentValue);
            } else if (propName === 'filters') {
                this.setTimelinePeriod(changes.filters.currentValue.period);
            }
        }
    }

    ngOnDestroy() {
        this.disposeTimeline();
    }

    /**
     * Initialize legends
     */
    private initLegends() {
        this.legends = this.legends || this.defaultLegends;
    }

    /**
     * Initialize timeline filters
     * The default period is the current year
     */
    private initFilters() {
        const beginDate = _.get(this.filters, 'period.beginDate');
        const endDate = _.get(this.filters, 'period.endDate');
        let period: PeriodFilter;

        if (!beginDate || !endDate) {
            const year: number = +moment().format('YYYY');
            period = {
                beginDate: {
                    year,
                    month: 1,
                    day: 1,
                },
                endDate: {
                    year,
                    month: 12,
                    day: 31,
                },
            };
        } else {
            period = {
                beginDate,
                endDate,
            };
        }

        this.setFiltersPeriod(period);
    }

    /**
     * Initialize the timeline
     */
    private initTimeline() {
        this.initFilters();
        this.setTimeline();
    }

    private setTimeline() {
        this.disposeTimeline();

        const FONT_SIZE = '0.875rem';
        const FONT_FAMILY = 'Montserrat';

        const timeline: am4charts.XYChart = am4core.create(this.timelineEl.nativeElement, am4charts.XYChart);
        timeline.language.locale = am4lang_fr_FR;
        timeline.language.locale['_date_day'] = 'dd/MM/yy';
        timeline.language.locale['_date_month'] = 'MMM';
        timeline.language.locale['_date_year'] = 'yyyy';
        timeline.dateFormatter.dateFormat = this.dateFormat;
        timeline.dateFormatter.inputDateFormat = this.dateFormat;

        const yAxis = timeline.yAxes.push(new am4charts.CategoryAxis());
        yAxis.dataFields.category = 'category';
        yAxis.fontSize = FONT_SIZE;
        yAxis.renderer.labels.template.fill = am4core.color(this.colorService.getHexPageColor('TEXT_COLOR'));
        yAxis.fontFamily = FONT_FAMILY;

        const xAxis = timeline.xAxes.push(new am4charts.DateAxis());
        xAxis.renderer.minGridDistance = 70;
        xAxis.renderer.tooltipLocation = 0;
        xAxis.baseInterval = {
            count: 1,
            timeUnit: 'day',
        };
        xAxis.fontSize = FONT_SIZE;
        xAxis.renderer.labels.template.fill = am4core.color(this.colorService.getHexPageColor('TEXT_COLOR'));
        xAxis.fontFamily = FONT_FAMILY;

        const serie = timeline.series.push(new am4charts.ColumnSeries());

        serie.columns.template.height = am4core.percent(70);
        serie.columns.template.tooltipHTML = '<div></div>';
        serie.columns.template.propertyFields.fill = 'color';
        serie.columns.template.propertyFields.stroke = 'color';
        serie.columns.template.strokeOpacity = 1;
        // column on click
        serie.columns.template.events.on(
            'hit',
            function(ev) {
                const timelineData: any = ev.target.dataItem.dataContext;
                this.timelineColumnCallback(timelineData);
            },
            this
        );

        serie.columns.template.adapter.add('tooltipHTML', (text: string, target: am4charts.Column) => {
            return this.setTooltipTemplate(target);
        });

        serie.tooltip.pointerOrientation = 'up';
        serie.tooltip.label.interactionsEnabled = true;
        serie.tooltip.keepTargetHover = true;
        serie.tooltip.tooltipPosition = 'pointer';
        serie.tooltip.fitPointerToBounds = true;
        serie.tooltip.dy = 10;
        serie.tooltip.fontSize = FONT_SIZE;
        serie.tooltip.fontFamily = FONT_FAMILY;

        // tooltip on click
        serie.tooltip.events.on('hit', ev => {
            this.timelineTooltipCallback(ev.target.dataItem.dataContext as TimelineData);
        });

        serie.dataFields.openDateX = 'startDate';
        serie.dataFields.dateX = 'endDate';
        serie.dataFields.categoryY = 'category';

        this.timeline = timeline;
        this.xAxis = xAxis;

        this.setTimelineData(this.timelineDatas);
        this.setTimelinePeriod(this.filters.period);
    }

    /**
     * set the tooltip template by timeline data type
     *
     * @param {am4charts.Column} target
     * @return {string}
     */
    private setTooltipTemplate(target: am4charts.Column): string {
        let text = '';
        const timelineDataType = _.get(target.dataItem.dataContext, 'type');
        switch (timelineDataType) {
            case 'routing_reference_deactived':
                text = `<b>{startDate}</b>`;
                text += `<div>${this.translateService._(timelineDataType)}</div>`;
                break;
            case 'end_of_contract':
                text = `<b>{startDate}</b></div>`;
                text += `<div>${this.translateService._(timelineDataType)} <b>${_.get(
                    target.dataItem.dataContext,
                    'provider'
                )}</b>`;
                break;
            case 'today':
                text = `<div><b>${this.translateService._(timelineDataType)}</b></div>`;
                break;
            default:
            case 'complete_period':
                text = `<b>{startDate}</b> - <b>{endDate}</b></div>`;

                // bill numbers
                const billNumber = _.get(target.dataItem.dataContext, 'bills.0.billNumber', 'NC');
                text += `<div>${this.translateService._('bill')} <b>${billNumber}</b></div>`;
                break;
            case 'period_with_multiple_bills':
                text = `<b>{startDate}</b> - <b>{endDate}</b></div>`;

                // contract provider and ref
                const provider = _.get(target.dataItem.dataContext, 'provider');
                const contractRef = _.get(target.dataItem.dataContext, 'contractRef');
                if (provider && contractRef) {
                    text += `<div>${this.translateService._(
                        'contract_number'
                    )} : <b>${provider} ${contractRef}</b></div>`;
                } else if (provider && !contractRef) {
                    text += `<div>${this.translateService._('provider')} : <b>${provider}</b></div>`;
                } else if (!provider && contractRef) {
                    text += `<div>${this.translateService._('contract_number')} : <b>${contractRef}</b></div>`;
                }

                // bill numbers
                text += _.get(target.dataItem.dataContext, 'bills', []).reduce((memo, bill) => {
                    return `${memo}<div><b>- ${bill.billNumber}</b></div>`;
                }, `<div>${this.translateService._('bill_number')}:</div>`);
                break;
            case 'incomplete_period':
                text = `<b>{startDate}</b> - <b>{endDate}</b></div>`;
                text += `<div>${this.translateService._(timelineDataType)}`;
                break;
        }
        return text;
    }

    /**
     * callback when a timeline tooltip is clicked
     *
     * @param {TimelineData} timelineData
     */
    private timelineTooltipCallback(timelineData: TimelineData) {
        if (timelineData.type === 'end_of_contract' && timelineData.contractRef) {
            this.router.navigateByUrl(`/contrats?q=${timelineData.contractRef}`);
        }
    }

    /**
     * callback when a timeline column is clicked:
     * - set the period with the min startDate and the max endDate of bills in the given timelineData
     *
     * @param {TimelineData} timeline
     */
    private timelineColumnCallback(timelineData: TimelineData) {
        // Click enabled if match period with data
        const match =
            timelineData.type && timelineData.type.match(/complete_(?:\w+_)?period|period_with_multiple_bills/);
        // Perform action only if period is from one of previous types
        if (match && match.length && timelineData.bills) {
            const { startDate, endDate } = this.findStartAndEndDates(timelineData);
            if (startDate.isValid() && endDate.isValid()) {
                const newPeriod: PeriodFilter = {
                    beginDate: {
                        year: startDate.year(),
                        month: startDate.month() + 1,
                        day: startDate.date(),
                    },
                    endDate: {
                        year: endDate.year(),
                        month: endDate.month() + 1,
                        day: endDate.date(),
                    },
                };

                this.setFiltersPeriod(newPeriod);
                this.setTimelinePeriod(this.filters.period);
                this.periodOnChange.emit(this.filters.period);
            }
        }
    }

    /**
     * find start and end dates of bills in the graph.
     */
    private findStartAndEndDates(timelineData: TimelineData): { startDate: moment.Moment; endDate: moment.Moment } {
        let matchingTimelineDatas: TimelineData[] = [];
        // find timelineDatas with same bills as timelineData
        timelineData.bills.forEach(bill => {
            matchingTimelineDatas = matchingTimelineDatas.concat(
                this.timelineDatas.filter(tlData => tlData.bills && tlData.bills.some(b => b.billId === bill.billId))
            );
        });
        // Get matchingTimelineDatas min dateStart
        const startDate = moment.min(matchingTimelineDatas.map(x => moment(x.startDate, 'DD/MM/YYYY')));
        // Get matchingTimelineDatas max dateEnd
        const endDate = moment.max(matchingTimelineDatas.map(x => moment(x.endDate, 'DD/MM/YYYY')));

        return { startDate, endDate };
    }

    /**
     * update minDate and maxDate of the timeline
     *
     * @param {periodFilter} period
     */
    private setTimelinePeriod(period: PeriodFilter) {
        if (this.xAxis && _.get(period, 'beginDate') && _.get(period, 'endDate')) {
            const minDate: DateFilter = period.beginDate;
            const maxDate: DateFilter = period.endDate;
            this.xAxis.min = new Date(minDate.year, minDate.month - 1, minDate.day).getTime();
            this.xAxis.max = new Date(maxDate.year, maxDate.month - 1, maxDate.day).getTime();
        }
    }

    /**
     * set filters.period
     * @param {PeriodFilter} period
     */
    private setFiltersPeriod(period: PeriodFilter) {
        this.filters.period = {
            beginDate: period.beginDate,
            endDate: period.endDate,
        };
    }

    /**
     * timeline data setter
     * @param {TimelineData[]} timelineDatas
     */
    private setTimelineData(timelineDatas: TimelineData[]) {
        if (this.timeline) {
            this.timelineDatas = this.setTimelineDataColor(timelineDatas);
            // Slow assignation
            this.timeline.data = this.timelineDatas;
        }
    }

    /**
     * Destroy current timeline
     */
    private disposeTimeline() {
        if (this.timeline) {
            this.timeline.dispose();
        }
    }

    /**
     * set all timeline data colors by the type
     *
     * @param {TimelinaData[]} timelineDatas
     * @returns {TimelineData[]}
     */
    private setTimelineDataColor(timelineDatas: TimelineData[]): TimelineData[] {
        if (!Array.isArray(timelineDatas)) {
            return [];
        }
        return timelineDatas.map((timelineData: TimelineData) => {
            timelineData.color = timelineData.color || this.legends.find(x => x.type === timelineData.type).color;
            return timelineData;
        });
    }

    /**
     * Compute height: around 40px height for each category's timeline
     */
    get timelineHeight(): string {
        if (this.timeline) {
            const axisXHeight = 80;
            const categoryTimelineHeight = 45;
            const nbCategories = _.uniqBy(this.timeline.data, 'category').length;
            const containerHeight = axisXHeight + categoryTimelineHeight * nbCategories;
            return `${containerHeight}px`;
        }
        return 'Opx';
    }

    /**
     * Get default legend
     */
    private get defaultLegends(): Legend[] {
        return [
            {
                type: 'complete_period',
                name: this.translateService._('complete_period'),
                color: '#02731E',
            },
            {
                type: 'complete_metered_period',
                name: this.translateService._('complete_metered_period'),
                color: '#43A047',
            },
            {
                type: 'complete_estimated_period',
                name: this.translateService._('complete_estimated_period'),
                color: '#C5E1A5',
            },
            {
                type: 'complete_cancelled_period',
                name: this.translateService._('complete_cancelled_period'),
                color: '#D96920',
            },
            {
                type: 'complete_adjusted_period',
                name: this.translateService._('complete_adjusted_period'),
                color: '#ECB490',
            },
            {
                type: 'period_with_multiple_bills',
                name: this.translateService._('period_with_multiple_bills'),
                color: '#7461c2',
            },
            {
                type: 'incomplete_period',
                name: this.translateService._('incomplete_period'),
                color: '#fca828',
            },
            {
                type: 'end_of_contract',
                name: this.translateService._('end_of_contract'),
                color: '#ff0000',
            },
            {
                type: 'routing_reference_deactived',
                name: this.translateService._('routing_reference_deactived'),
                color: '#d3bf96',
            },
            {
                type: 'today',
                name: this.translateService._('today'),
                color: '#4281b8',
            },
        ];
    }
}
