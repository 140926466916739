import { Component, Input, OnInit } from '@angular/core';
import { TilesService } from '../../../services/tiles/tiles.service';

@Component({
    selector: 'data-tile-4',
    templateUrl: './data-tile-4.component.html',
    styleUrls: ['./data-tile-4.component.scss'],
})
export class DataTile4Component implements OnInit {
    @Input() color: string;
    @Input() type: string;
    @Input() label: string;
    @Input() value: number;
    @Input() valueHTML: string;
    @Input() unit: string;

    constructor(private tilesService: TilesService) {}

    ngOnInit() {}

    getFormatedValue(value) {
        return this.tilesService.getNumberToDisplay(value, this.unit);
    }
}
