import { Component, Input, OnInit } from '@angular/core';
import { materialTypes } from '../../../constants/material-types.constants';

@Component({
    selector: 'ga-sites-general-informations',
    templateUrl: './general-informations.component.html',
    styleUrls: ['./general-informations.component.scss'],
})
export class SitesGeneralInformationsComponent implements OnInit {
    @Input() element: any = {};

    constructor() {}
    ngOnInit() {}
}
