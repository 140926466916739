import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: ['./text-filter.component.scss'],
})
export class TextFilterComponent implements OnInit {
    @Output() updateElement: EventEmitter<any> = new EventEmitter();
    @Input() currentValue = '';

    constructor() {}

    ngOnInit() {}

    updateCurrentValue(value) {
        this.updateElement.emit(value);
    }
}
