import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { AccessGuard } from '../../../shared/services/guards/access-guard.service';
import { UserComponent } from './user.component';

const COMPANY_ROUTE = [
    {
        path: '',
        component: UserComponent,
        canActivate: [AccessGuard],
        data: { page: 'admin' },
    },
];

@NgModule({
    declarations: [UserComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(COMPANY_ROUTE)],
})
export class UserModule {}
