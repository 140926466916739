import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ModalComponent } from 'app/shared/components/common/modal/modal.component';
import { SortFilters } from 'app/shared/services/sort/sort-filter/sort-filter.interface';
import { RoutingReferenceKeyfigures } from '../rref-merge.interface';
import { RRefRename } from '../rref-rename/rref-rename.interface';

@Component({
    selector: 'rref-keyfigures-card',
    templateUrl: './rref-keyfigures-card.component.html',
    styleUrls: ['./rref-keyfigures-card.component.scss'],
})
export class RrefKeyFiguresCardComponent implements OnInit {
    /** Routing refererence keyfigures */
    @Input() keyFigures: RoutingReferenceKeyfigures;

    /** Can card be closed */
    @Input() canClose = true;

    /** Can routing reference's reference be edited */
    @Input() canRename = false;

    /** Event fired on card closed */
    @Output() closeCard: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Event fired when rename modal registered a change, so card needs to be refreshed
     */
    @Output() updatedCard: EventEmitter<void> = new EventEmitter<void>();

    /** Rename modal component */
    @ViewChild(ModalComponent, { static: true }) public renameModal: ModalComponent;

    public modalProperties: RRefRename = {
        _id: null,
        reference: null,
        alias: [],
    };

    // Used by orderby pipe to sort contracts by starting date
    public dateStartSortFilters: SortFilters = {
        order: 'asc',
        property: {
            name: 'dateStart',
            type: 'date_string',
            path: 'dateStart',
        },
    };

    constructor() {}

    ngOnInit() {
        this.modalProperties = {
            _id: this.keyFigures._id,
            reference: this.keyFigures.reference,
            alias: this.keyFigures.alias.map(x => x.reference),
        };
    }

    /**
     * Close current card
     */
    public close() {
        if (this.canClose) {
            this.closeCard.emit();
        }
    }

    /**
     * Open rename modal
     */
    public openRename() {
        this.modalProperties = {
            _id: this.keyFigures._id,
            reference: this.keyFigures.reference,
            alias: this.keyFigures.alias.map(x => x.reference),
        };
        if (this.canRename) {
            this.renameModal.show();
        }
    }

    /**
     * After rename, close modal and triggers changes happened
     * @param {boolean} hasChanged - true if changes made, false otherwise.
     */
    public async afterRename(hasChanged: boolean) {
        this.renameModal.close();
        if (hasChanged) {
            this.updatedCard.emit();
        }
    }

    /**
     * @returns {boolean} true is the modal shown, false otherwise
     */
    public get isModalShown(): boolean {
        return this.renameModal.isShown;
    }
}
