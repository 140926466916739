import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportService } from 'app/shared/services/export/export.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-bloc',
    templateUrl: './component-bloc.component.html',
    styleUrls: ['./component-bloc.component.scss'],
    providers: [],
})
export class ComponentBlocComponent implements OnInit {
    @Input() title: string;
    @Input() subtitle: string = null;
    @Input() unit: string = null;
    @Input() sup: string;
    @Input() customCss: object = {};
    @Input() exportExcelUrls: string[];
    @Input() exportExcelNames: string[];
    @Input() exportExcelUrl: string;
    @Input() exportExcelName: string;
    @Input() isDjuActivated: boolean;
    @Input() hasDju: boolean;
    @Input() isPopover = false;

    @Input() componentIsOpen: boolean = null; // to open/close component contained by bloc
    @Output() componentIsOpenChange = new EventEmitter<boolean>();
    @Output() isDjuActivatedChange = new EventEmitter<boolean>();

    titleColor: string;
    isExporting = false;

    /** Excel file extension */
    private fileExtension = 'xlsx';

    constructor(private router: Router, private exportService: ExportService, private route: ActivatedRoute) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            let url = this.router.url;
            if (url && url.split('?').length) {
                // Keep only part before query params
                url = url.split('?')[0];
                if (url.split('/').length - 1 > 1) {
                    this.titleColor = url.split('/')[1];
                } else {
                    this.titleColor = url.replace('/', '');
                }
            }
        });
    }

    onDjuActivateSwitch(event) {
        this.isDjuActivatedChange.emit(event.checked);
    }

    getButtonTextFromState() {
        return this.componentIsOpen ? 'Fermer' : 'Ouvrir';
    }

    updateComponentIsOpen() {
        this.componentIsOpen = !this.componentIsOpen;
        this.componentIsOpenChange.emit(this.componentIsOpen);
    }

    getTitle() {
        return this.unit ? this.title.toUpperCase() + ' (' + this.unit + ')' : this.title.toUpperCase();
    }

    exportToExcel(index?) {
        if (index === 0 || index) {
            if (this.exportExcelUrls[index] && this.exportExcelNames[index] && !this.isExporting) {
                this.isExporting = true;
                this.getExportFile(this.exportExcelUrls[index], this.exportExcelNames[index]);
            }
        } else {
            if (this.exportExcelUrl && this.exportExcelName && !this.isExporting) {
                this.isExporting = true;
                this.getExportFile(this.exportExcelUrl, this.exportExcelName);
            }
        }
    }

    /**
     * Get export file by specified url and excel name
     *
     * @param {string} excelUrl - url to get the exported file
     * @param {string} excelName - exported file name
     */
    getExportFile(excelUrl: string, excelName: string): void {
        this.isExporting = true;

        // Rename given file name if missing extension
        if (excelName && !excelName.match(new RegExp(`\\.${this.fileExtension}$`))) {
            excelName += `.${this.fileExtension}`;
        }

        this.exportService.getFile(excelUrl).subscribe(
            file => {
                saveAs(file, excelName);
                this.isExporting = false;
            },
            err => {
                this.isExporting = false;
                Swal.fire(
                    'Toutes nos excuses',
                    "Une erreur s'est produite lors de l'export de données. Merci de réessayer.",
                    'error'
                );
            }
        );
    }

    get canExport() {
        return (
            (this.exportExcelName && this.exportExcelUrl) ||
            (this.exportExcelNames &&
                this.exportExcelNames.length &&
                this.exportExcelUrls &&
                this.exportExcelUrls.length)
        );
    }

    get exportClass() {
        return this.isExporting ? 'citron-icon rotating' : 'excel-icon';
    }
}
