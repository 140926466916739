import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss'],
    providers: [],
})
export class AccountComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
