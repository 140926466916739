import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PeriodFilter } from 'app/shared/models/bills-timeline.interface';
import * as moment from 'moment';

@Component({
    selector: 'ga-bills-datepicker',
    templateUrl: './bills-datepicker.component.html',
    styleUrls: ['./bills-datepicker.component.scss'],
})
export class BillsDatepickerComponent implements OnInit {
    private _dates: PeriodFilter;
    @Input() set dates(dates: PeriodFilter) {
        // Init selected dates
        this._dates = dates;
        if (dates && dates.beginDate && dates.endDate) {
            const start = dates.beginDate;
            const end = dates.endDate;
            this.beginDate = moment([start.year, start.month - 1, start.day]).format('YYYY-MM-DD');
            this.endDate = moment([end.year, end.month - 1, end.day]).format('YYYY-MM-DD');
        }
    }
    get dates(): PeriodFilter {
        return this._dates;
    }

    @Output() datesOnChange: EventEmitter<PeriodFilter> = new EventEmitter<PeriodFilter>();

    beginDate: string;
    endDate: string;
    errorMessage: string;

    constructor() {}

    ngOnInit() {}

    /**
     * on daterangepicker changed it will set the period of the timeline
     * and apply the filters
     */
    onDatesChanged() {
        this.errorMessage = null;
        if (this.beginDate && this.endDate) {
            if (this.beginDate.length && this.endDate.length) {
                const beginDate = moment.utc(this.beginDate);
                const endDate = moment.utc(this.endDate);
                if (beginDate.isAfter(endDate)) {
                    this.errorMessage = 'La date de début doit être inférieure à la date de fin';
                } else {
                    this.setDates({
                        beginDate: {
                            day: beginDate.date(),
                            month: beginDate.month() + 1,
                            year: beginDate.year(),
                        },
                        endDate: {
                            day: endDate.date(),
                            month: endDate.month() + 1,
                            year: endDate.year(),
                        },
                    });
                    this.datesOnChange.emit(this.dates);
                }
            }
        }
    }

    /**
     * Set dates
     * @param {PeriodFilter} dates
     */
    setDates(dates: PeriodFilter) {
        this.dates = dates;
    }
}
