import { Injectable, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

// Services
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

// Interfaces
import { Company } from 'app/shared/models/company.interface';
import { SitePopulated } from 'app/shared/models/site.interface';

/**
 * Minimum requirements for an entity
 */
interface Entity {
    _id: string;
}

/**
 * Component to handle entities attributions to sites such as routing references or vehicles.
 */
@Injectable()
export abstract class EntitiesAttributionComponent<T extends Entity> implements OnInit {
    @Input() company: Company;

    // List of fluids availale
    fluidList: Array<{
        name: string;
        value: string;
    }> = [];

    // Entities not assigned to any site
    entities: T[] = [];

    // Entities assigned to a site
    entitiesAssigned: T[] = [];

    // Site list
    sites: SitePopulated[] = [];

    /**
     * Site information properties
     * Selected site by the user by click or drop of an entity
     */
    selectedSite: SitePopulated;

    // Limits for the display of sites and entities
    limits = {
        entities: 10,
    };

    // List of entities to display in first column
    entitiesToDisplay: T[] = [];

    // Search filters on entities and sites columns
    searchFilter = {
        entities: '',
        fluid: '',
    };

    // Loading informations for display.
    loading = {
        entities: true,
        sites: true,
        fluids: true,
    };

    constructor(public energyService: EnergyService, public sitesService: SitesService) {}

    /**
     * Get entities list
     */
    abstract async getEntities(): Promise<T[]>;

    /**
     * Get site's entities list
     * @param {SitePopulated} site - site to get entities from
     * @returns {T[]} list of entities of the site
     */
    abstract getSiteEntities(site: SitePopulated): T[];

    /**
     * Set site's entities
     * @param {SitePopulated} site - site to set entities to
     * @param {T[]} entities - entities to set
     */
    abstract setSiteEntities(site: SitePopulated, entities: T[]): void;

    /**
     * Get properties to searched for an entity
     * @returns {string[]} list of properties
     */
    abstract get searchProperties(): string[];

    /**
     * Get entity title
     * @param {T} entity
     * @returns {string} entity title
     */
    abstract getEntityTitle(entity: T): string;

    /**
     * Get entity energy icon
     * @param {T} entity
     * @returns {string} entity icon
     */
    abstract getEntityIcon(entity: T): string;

    /**
     * Get entity first subtitle
     * @param {T} entity
     * @returns {string} entity first subtitle
     */
    abstract getEntitySubtitle1(entity: T): string;

    /**
     * Get entity second subtitle
     * @param {T} entity
     * @returns {string} entity second subtitle
     */
    abstract getEntitySubtitle2(entity: T): string;

    /**
     * Get fluids related to the attribution
     * @returns {Promise<string>}
     */
    abstract async getFluids(): Promise<string[]>;

    /**
     * Filter entity based on filters
     * Executed before search properties analyze
     * @param {T} entity - entity to filter
     * @param {{entities: string, fluid: string}} filters - filter to test.
     * @returns {boolean} true to continue entity analyze, false to reject entity (not match custom filtering)
     */
    abstract isEntityFiltered(entity: T, filters: { entities: string; fluid: string }): boolean;

    /**
     * Get entity name plural key
     * @returns {string} entity name key
     */
    abstract get entityKeyPlural(): string;

    /**
     * Entity search input placeholder text
     * @returns {string} search input text
     */
    abstract get searchPlaceholder(): string;

    /**
     * Get entity type.
     * Currently supporting 'vehicles' and 'routingReferences'
     * @returns {'vehicles'|'routingReferences'}
     */
    abstract get entityType(): 'vehicles' | 'routingReferences';

    // tslint:disable-next-line: contextual-lifecycle
    ngOnInit() {
        this.init();
    }

    /**
     * Init component.
     * 1. Get sites
     * 1. Get entities
     * 1. Get and set fluids
     * 2. Split assigned entities and non assigned entities
     */
    private async init() {
        try {
            const [resSites, resEntities] = await Promise.all([
                this.sitesService.getSites({ companyId: this.company._id }),
                this.getEntities(),
                this.getSetFluids(),
            ]);
            if (resSites && resSites.data && resSites.data.length) {
                this.sites = resSites.data;
            }

            if (resEntities && resEntities.length) {
                // Split entities associated and entities not associated
                this.entities = resEntities;
                const assigned: { [id: string]: boolean } = {};
                this.sites.forEach(site => {
                    this.getSiteEntities(site).forEach(entity => {
                        const entityFound = this.entities.find(x => x._id === entity._id);
                        if (entityFound) {
                            // Add it to the list of entities assigned
                            if (!assigned[entityFound._id]) {
                                this.entitiesAssigned.push(entityFound);
                                assigned[entityFound._id] = true;
                            }
                        }
                    });
                });
                // Just keep entities not assigned
                this.entities = this.entities.filter(entity => {
                    return !assigned[entity._id];
                });

                // Init display entities
                this.filterEntities();
            }
            this.loading.sites = false;
            this.loading.entities = false;
        } catch (e) {
            this.sites = [];
            this.entities = [];
            this.loading.sites = false;
            this.loading.entities = false;
            this.loading.fluids = false;
        }
    }

    /**
     * True if site's needed info are loading, false otherwise
     * @returns {boolean}
     */
    get isSiteLoading(): boolean {
        return Boolean(this.loading.sites || this.loading.fluids);
    }

    /*****************
     * FILTERS COLUMN (ENTITIES)
     *****************/

    /**
     * Get and set fluids available for a customer
     * @returns {Promise<string[]>} fluids available for the company
     */
    private async getSetFluids(): Promise<string[]> {
        try {
            const fluids = await this.getFluids();
            if (fluids && fluids.length) {
                this.fluidList = fluids.map(fluid => {
                    return {
                        name: this.energyService.energyFullText(fluid),
                        value: fluid,
                    };
                });
            }
            this.loading.fluids = false;
            return fluids;
        } catch (e) {
            this.fluidList = [];
            this.loading.fluids = false;
            return [];
        }
    }

    /**
     * Filter entities per value
     * @param {string} value search criteria
     */
    searchEntity(value: string) {
        this.searchFilter.entities = value;
        this.filterEntities();
    }

    /**
     * Filter entities per fluid type
     * @param {string} value fluid type (eg. 'elec')
     */
    onFluidChange(value: string) {
        this.searchFilter.fluid = value;
        this.filterEntities();
    }

    /**
     * Apply all filters to the entities list
     * @param {boolean} isReset reset the entities list (for example in research true, display more false)
     */
    private filterEntities(isReset: boolean = true) {
        // Reset the entity list
        if (isReset) {
            this.entitiesToDisplay = [];
        }
        // Split the search in multiple words to check each one instead of all of them
        const searchWords = this.searchFilter.entities.toLocaleLowerCase().split(' ');

        // Add the right number of entities to the display list
        // Each one is the next one matching filters
        for (let i = this.entitiesToDisplay.length; i < this.limits.entities; i++) {
            // Find unassociated entities matching filters
            const entity = this.entities.find(x => {
                // Check if not already in the list
                if (this.entitiesToDisplay.some(y => x._id === y._id)) {
                    return false;
                }

                const isEntityMatchingSearch = this.isEntityFiltered(x, this.searchFilter);
                if (isEntityMatchingSearch === false) {
                    return false;
                }

                // Check from search input
                return this.searchProperties.some(property => {
                    const val = _.get(x, property);
                    return val && this.checkMatch(val, searchWords);
                });
            });
            if (entity) {
                // If found, add it to the display list
                this.entitiesToDisplay.push(entity);
            }
        }
    }

    /**
     * Check if each one of the values is included in property string value
     * @param {string} property to check check the values from
     * @param {string[]} values list of values to test
     * @returns {boolean} true if all values matches, false otherwise
     */
    private checkMatch(property: string = '', values: string[] = []): boolean {
        return values.every(value => {
            return property.toLocaleLowerCase().includes(value);
        });
    }

    /**
     * Display more entities in the list.
     * @param {number} number of entities to display more. Default : 10
     */
    displayMoreEntities(number: number = 10) {
        this.limits.entities += number;
        this.filterEntities(false);
    }

    /*****************
     * ENTITY ASSOCIATION TO SITE (DRAG & DROP)
     *****************/

    /**
     * Associate an entity to a site (front operation, back end action already done)
     * @param {T} entity - entity to associate
     * @param {SitePopulated} site - to be associated to
     * @param {boolean} isNew - is the site new or not.
     */
    associateEntityToSite({ entity, site, isNew }: { entity: T; site: SitePopulated; isNew: true }) {
        /**
         * If the association triggered a site creation, display the site in first position
         */
        if (isNew) {
            this.sites.unshift(site);
        }

        // Associate the entity
        if (!this.getSiteEntities(site).some(x => x._id === entity._id)) {
            // Not using push to trigger change detection
            const entities = this.getSiteEntities(site).concat(entity);
            this.setSiteEntities(site, entities);
        }
        // Add to assigned list
        if (!this.entitiesAssigned.some(x => x._id === entity._id)) {
            this.entitiesAssigned.push(entity);
        }
        // Remove from free list
        this.entities = this.entities.filter(x => x._id !== entity._id);

        // Remove from display list
        this.entitiesToDisplay = this.entitiesToDisplay.filter(x => x._id !== entity._id);
        // Add next pdl to display list
        this.filterEntities(false);
    }

    /**
     * When a user starts dragging an entity, store it.
     * @param {DragEvent} event
     * @param {T} entity
     */
    onDragStart(event: DragEvent, entity: T) {
        const data = entity;
        const toTransfer = JSON.stringify(data);
        event.dataTransfer.setData('text/plain', toTransfer);
        event.dataTransfer.effectAllowed = 'copy';
    }

    /*****************
     * SITE INFORMATION MANAGEMENT
     *****************/

    /**
     * Remove a site from sites list
     * @param {SitePopulated} site - site to remove from sites list
     */
    removeSiteFromList(site: SitePopulated) {
        this.selectedSite = null;
        this.sites = this.sites.filter(x => x._id !== site._id);
    }

    /**
     * Add entity to entities list and in first position of entity display list.
     * @param {T} entity - entity to add to lists
     */
    addEntityToList(entity: T) {
        // Add the entity at the top of the unassociated list
        if (!this.entities.some(x => x._id === entity._id)) {
            this.entities.unshift(entity);
        }
        if (!this.entitiesToDisplay.some(x => x._id === entity._id)) {
            this.entitiesToDisplay.unshift(entity);
            this.limits.entities++;
        }
        // Remove the entity from the assigned list
        this.entitiesAssigned = this.entitiesAssigned.filter(x => x._id !== entity._id);
    }
}
