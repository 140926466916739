import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { AutoCompleteItem } from './ultra-simple-autocomplete.interface';

@Component({
    selector: 'ga-ultra-simple-autocomplete',
    templateUrl: './ultra-simple-autocomplete.component.html',
    styleUrls: ['./ultra-simple-autocomplete.component.scss'],
    providers: [],
})
export class UltraSimpleAutocompleteComponent implements OnInit {
    /**
     * Array containing list with all items
     */
    private _list: AutoCompleteItem[] = [];

    /**
     * Array containing items to display, sorted and matching search
     */
    public filteredList: AutoCompleteItem[] = [];

    /**
     * Boolean that indaicates if list must be displayed of not
     */
    public canShowList = false;

    /**
     * Input class
     */
    public autocompleteClass = '';

    /**
     * Search input control
     */
    public inputControl = new FormControl('');

    /**
     * Input value
     */
    @Input() set inputValue(value: string) {
        if (this.inputControl) {
            this.inputControl.setValue(value);
        }
    }
    get inputValue(): string {
        if (this.inputControl) {
            return this.inputControl.value;
        }
        return null;
    }

    /**
     * Debounce time for input in ms
     */
    @Input() debounceTime = 1;

    /**
     * Item in list to display/search
     * Items with `value` set to null will always be displayed and can't be selected (usefull to display error or loading).
     */
    @Input()
    get list() {
        return this._list;
    }
    set list(list: AutoCompleteItem[]) {
        this._list = list;
        this.setList();
    }

    /**
     * Placeholder value
     */
    @Input() placeholder = '';
    /**
     * Default value when no match or no value
     */
    @Input() defaultNoList = 'Pas de valeurs';
    /**
     * Emit value change/search on item selected
     */
    @Input() emitInputChangedOnItemSelected = false;

    /**
     * Event triggered when input changed
     */
    @Output() inputChanged: EventEmitter<string> = new EventEmitter(true);
    /**
     * Event triggered when an item is selected
     */
    @Output() itemSelected: EventEmitter<AutoCompleteItem> = new EventEmitter(true);

    /**
     * Click on document
     */
    @HostListener('document:click', ['$event']) IsClickOutsideList() {
        // If click outside component, hide list
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.hideList();
        }
    }

    constructor(private utils: UtilsService, private elementRef: ElementRef) {}

    ngOnInit() {
        this.inputControl.valueChanges.pipe(debounceTime(this.debounceTime)).subscribe({
            next: value => {
                this.emitSearchChange(value);
            },
        });
    }

    /**
     * Show list
     */
    public showList() {
        this.canShowList = true;
        this.autocompleteClass = 'open';
    }

    /**
     * Hide list
     */
    public hideList() {
        this.canShowList = false;
        this.autocompleteClass = '';
    }

    /**
     * Toggle list display
     */
    public toggleList() {
        if (this.canShowList) {
            this.hideList();
        } else {
            this.showList();
        }
    }

    /**
     * Set selected item in list
     * @param {AutoCompleteItem} item - item selected in list
     */
    public selectItem(item: AutoCompleteItem) {
        if (item.value !== null) {
            this.setValue(item);
            this.hideList();
        }
    }

    /**
     * Refresh filtered list, sorted by value and filtered with items matching search
     */
    public setList() {
        this.filteredList = _.sortBy(this._list, ['value']).filter(item => {
            return this.valueIncludeSearch(item);
        });
    }

    /**
     * Emit serch value change
     */
    public emitSearchChange(value: string) {
        this.inputChanged.emit(value);
    }

    /**
     * Set selected item and emit
     * @param {AutoCompleteItem} item - item selected
     */
    private setValue(item: AutoCompleteItem) {
        this.itemSelected.emit(item);
        this.inputControl.setValue(item.label, { emitEvent: this.emitInputChangedOnItemSelected });
    }

    /**
     * @returns {boolean} true if item matches search, false otherwise
     */
    private valueIncludeSearch(item: AutoCompleteItem): boolean {
        if (!this.inputValue) {
            return false;
        }
        if (item.value === null) {
            return true;
        }
        return this.utils
            .removeAccentFromString(item.label.toString())
            .toLowerCase()
            .includes(this.inputValue.toLowerCase());
    }
}
