import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AdminCompanyService } from '../../../../../pages/admin/company/company.service';
import { CartographyService } from '../../../../../pages/cartography/cartography.service';
import { ColorService } from '../../../../services/color/color.service';
import { CompaniesService } from '../../../../services/companies/companies.service';
import { SitesService } from '../../../../services/sites/sites.service';

@Component({
    selector: 'ga-company-sites-tab',
    templateUrl: './company-sites.component.html',
    styleUrls: ['./company-sites.component.scss'],
    providers: [CartographyService, ColorService],
})
export class CompanySitesComponent implements OnInit {
    @Input() company: any = null;

    branchesList = [];
    companyClone = null;
    isLoading = true;

    siteDragged = null; // needed to add a darker background-color on the dragged site div
    companyOrigin = null; // needed to know which company we need to remove the site from

    constructor(
        private cartographyService: CartographyService,
        private companyService: AdminCompanyService,
        private colorService: ColorService,
        private companiesService: CompaniesService,
        private sitesService: SitesService
    ) {}

    ngOnInit(): void {
        // need to clone the company object because it is the one that travels between tabs
        // we modify it by addind the branches --> not part of the model
        // --> if saved on another tab, it would try to save the branches as well
        this.companyClone = Object.assign({}, this.company);

        this.initBranches();
    }

    async initBranches() {
        // get all the branches
        const res = await this.companiesService.getBranches(this.companyClone._id, true);

        this.branchesList = res.data;

        this.setAffiliationDegrees();

        //  asynchronously get all the sites of both the company and its branches
        const PromiseArray = this.branchesList.concat(this.companyClone).map(company => {
            return this.getSitesPopulated(company._id);
        });
        const results = await Promise.all(PromiseArray);

        // set a field sites in both the main company and its branches
        this.branchesList.forEach(branch => {
            // creates the sites in the branchList & in the company.branches (same reference)
            const branchSites = results.find(company => company.companyId === branch._id).sites;
            branch.sites = this.sortAscByProperty(branchSites, 'complement');
        });

        const companySites = results.find(company => company.companyId === this.companyClone._id).sites;
        this.companyClone.sites = this.sortAscByProperty(companySites, 'complement');

        this.companyClone.branches = this.sortAscByProperty(this.companyClone.branches, 'name');

        this.isLoading = false;
    }

    setAffiliationDegrees() {
        this.companyClone.branches.forEach(branch => {
            const result = this.findMaxAffiliationDegreePerFirstBranch(branch);
            if (!branch.maxDegree || branch.maxDegree < result) {
                branch.maxAffiliationDegree = result;
            }
        });
    }

    findMaxAffiliationDegreePerFirstBranch(branch) {
        if (branch.branches && branch.branches.length) {
            let max = 0;
            branch.branches.forEach(b => {
                const res = this.findMaxAffiliationDegreePerFirstBranch(b);
                max = res > max ? res : max;
            });
            return max;
        } else {
            return branch.affiliationDegree;
        }
    }

    // when dragged, background-color is red opacity 0.3,
    // when not dragged, the company's istes are red opacity 0.1 (lighter), and the branches are white
    getLightRedBackgroundColor(site) {
        let color = 'ADMIN';
        let opacity = null;

        if (this.siteDragged && site._id === this.siteDragged._id) {
            color = 'ADMIN';
            opacity = 0.3;
        } else if (this.companyClone.sites.some(s => s._id === site._id)) {
            color = 'ADMIN';
            opacity = 0.1;
        } else {
            color = 'WHITE';
            opacity = 1;
        }

        const rgba = this.colorService.hexToRgba(this.colorService.getHexColor(color), opacity);
        return `rgba(${rgba})`;
    }

    // return the sites of the company (populated with contracts and rfc)
    getSitesPopulated(companyId): Promise<any> {
        return new Promise((resolve, reject) => {
            return this.cartographyService.getSites({ companies: companyId }).then(
                sites => {
                    this.sitesService.createGoogleAddressToDisplay(sites);
                    return resolve({
                        companyId,
                        sites: this.sortAscByProperty(sites, 'complement'),
                    });
                },
                err => {
                    // err.error_code is set to 'error_load_sites'
                    this.sweetAlert(err.error_code);
                }
            );
        });
    }

    async moveSite(site, destinationCompany) {
        try {
            site.company = destinationCompany._id;
            await this.companyService.updateSite(site._id, site);

            destinationCompany.sites.push(site);
            this.companyOrigin.sites = this.companyOrigin.sites.filter(s => s._id !== site._id);

            this.companyOrigin = null;
            this.siteDragged = null;
            destinationCompany.draggedOver = false;
        } catch (error) {
            this.sweetAlert(error.errorCode);
            destinationCompany.draggedOver = false;
        }
    }

    getSiteClass(site) {
        let classes = 'site ';
        classes += this.siteDragged && site._id === this.siteDragged._id ? 'dragged' : '';
        return classes;
    }

    getBranchColClass(branch) {
        return branch.draggedOver ? 'dragged-over' : '';
    }

    // when an item is grabbed
    drag(site, company) {
        this.siteDragged = site;
        this.companyOrigin = company;
    }

    // when drop the item to move the company to that destination company
    async drop(destinationCompany) {
        // do no move the site if it is dropped in its own initial company
        if (!destinationCompany.sites.some(s => s._id.toString() === this.siteDragged._id.toString())) {
            await this.moveSite(this.siteDragged, destinationCompany);
        } else {
            this.companyOrigin = null;
            this.siteDragged = null;
            destinationCompany.draggedOver = false;
        }
    }

    // when dragged item is over the drop zone
    onDragOver(event, branch) {
        event.preventDefault(); // do not delete, fires (drop)
        branch.draggedOver = true;
    }

    // when dragged item leaves the drop zone
    onDragLeave(branch) {
        branch.draggedOver = false;
    }

    // if item is dropped, happens after the drop action
    // if drag ends but not over a drop zone, onDrageEnd si fired without the drop
    onDragEnd() {
        this.siteDragged = null;
    }

    sweetAlert(code) {
        const messages = {
            error_load_sites: [
                'Toutes nos excuses',
                'Une erreur est surveneue pendant le chargement de vos sites.',
                'error',
            ],
            error_move_site: [
                'Toutes nos excuses',
                "Une erreur est surveneue lors du changement d'entreprise. Veuillez contacter votre Energy-Manager.",
                'error',
            ],
            error_default: [
                'Toutes nos excuses',
                'Une erreur est surveneue, veuillez réessayer ultérieurement.',
                'error',
            ],
        };

        if (!messages[code]) {
            code = 'error_default';
        }

        Swal.fire(messages[code][0], messages[code][1], messages[code][2]);
    }

    hasNoBranches() {
        return !this.companyClone.branches || !this.companyClone.branches.length;
    }

    private sortAscByProperty(array, propertyToSortBy) {
        return array.sort(sortAsc);
        function sortAsc(optionA, optionB) {
            if (optionA[propertyToSortBy] < optionB[propertyToSortBy]) {
                return -1;
            } else if (optionA[propertyToSortBy] > optionB[propertyToSortBy]) {
                return 1;
            } else {
                return 0;
            }
        }
    }
}
