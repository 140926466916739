import { Injectable } from '@angular/core';
import { FuelCard } from 'app/shared/models/vehicle.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

@Injectable()
export class FuelCardsService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get fuel cards for a given companies.
     * If no property `companies` in filters, will use session company.
     * @param {Object} filters - filters to apply to match fuel cards
     * @param {string[]} filters.companies - list of company ids
     */
    async getFuelCardsFromCompanies(filters: { companies: string[] }): Promise<FuelCard[]> {
        if (!filters.companies) {
            filters.companies = [this.apiService.sessionService.getCompanyId()];
        }
        const res = await this.get('/api/fuelcards', null, {
            companies: filters.companies.join(','),
        });
        return res.data;
    }
}
