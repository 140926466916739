import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { FilterService } from 'app/shared/services/filter/filter.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { SortFilterService } from 'app/shared/services/sort/sort-filter/sort-filter.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

import { SitePopulated } from 'app/shared/models/site.interface';
import {
    SortableProperties,
    SortableProperty,
    SortFilters,
} from 'app/shared/services/sort/sort-filter/sort-filter.interface';

@Component({
    selector: 'ga-pdl-data-table',
    templateUrl: './pdl-data-table.component.html',
    styleUrls: ['./pdl-data-table.component.scss'],
    providers: [],
})
export class PdlDataTableComponent implements OnInit {
    @Input() sites: any[];
    @Output() selectedSite: EventEmitter<string> = new EventEmitter();
    @Input() currentSelection: string;
    @Input() companies: Array<{ displayName: string; value: string }>;

    /**
     * Indicates if sites list is loading
     */
    @Input() isLoading: boolean;

    public filterArgs = '';

    public sitesSortableProperties: SortableProperties = {
        status: {
            name: 'status',
            type: 'boolean',
            path: 'status.active',
        },
        code: {
            name: 'code',
            type: 'string',
            path: 'code',
        },
        complement: {
            name: 'complement',
            type: 'string',
            path: 'complement',
        },
        zipcode: {
            name: 'zipcode',
            type: 'string',
            path: 'zipcode',
        },
        completion: {
            name: 'completion',
            type: 'number',
            path: 'info.profilCompletion.general',
        },
    };

    // Store the filters for the orderby pipe
    public sortFilters: SortFilters;

    /**
     * Tracks the companies selected by the user among the ones he is allowed to see
     * selectedCompanies = ['companyId1', 'companyId2']
     */
    public selectedCompanies: string[] = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private utils: UtilsService,
        private sitesService: SitesService,
        private filterService: FilterService,
        private sortFilterService: SortFilterService
    ) {
        // Set sort filters by default on field complement when loading
        this.sortFilters = {
            order: 'asc',
            property: this.sitesSortableProperties.complement,
        };
    }

    ngOnInit() {
        if (Array.isArray(this.selectedCompanies) && !this.selectedCompanies.length) {
            this.selectedCompanies =
                this.companies && this.companies.length
                    ? [this.companies[0].value]
                    : [this.sessionService.getCompanyId()];
        }
        this.route.queryParams
            .pipe(filter(params => Boolean(this.filterService.getParamAliasValue(params, 'companies'))))
            .subscribe({
                next: params => {
                    this.selectedCompanies = this.filterService.getParamAliasValues(params, 'companies', []);
                },
            });
    }

    public filterSearchSite(site: SitePopulated): boolean {
        return this.sitesService.filterSiteOnSearch(site, this.filterArgs);
    }

    public selectionSite(element: string) {
        this.currentSelection = element;
        this.selectedSite.emit(element);
    }

    // Update the sort filters which refreshes the orderby pipe
    public sortTable(sortableProperty: SortableProperty): void {
        this.sortFilters = this.sortFilterService.updateSortFilters(sortableProperty, this.sortFilters);
    }

    public getAddressToDisplay(site) {
        return this.sitesService.getAddressToDisplay(site);
    }

    /**
     * Get companyName object from a company ID
     * @param {Array<{ displayName: string; value: string }>} array
     * @returns {{ displayName: string; value: string }} object
     */

    public getCompanyNameObject(
        companies: Array<{ displayName: string; value: string }>,
        companyId: string
    ): { displayName: string; value: string } {
        return companies.find(company => company.value === companyId);
    }

    /**
     * For a given site, get all the information of the site, to be able to get the company of the site
     * Then format the company name of the site to display it
     * @param {any} site
     * @returns {string} company name to display
     */
    public getCompanyToDisplay(site: any): string {
        // Get companyName for site companyId
        const companyNameObject = this.getCompanyNameObject(this.companies, site.company);
        if (!companyNameObject) {
            return 'Inconnue';
        }
        // Format the site company display name
        const displayName = companyNameObject.displayName.replace(/^-+\s/, '');
        return displayName;
    }

    public getSiteStatusClass(site) {
        if (site && site.status && site.status.active) {
            return 'status__icon-enabled';
        }
        return 'status__icon-disabled';
    }

    public getSiteProfileCompletion(site) {
        if (site && site.info && site.info.profilCompletion && site.info.profilCompletion.general) {
            return site.info.profilCompletion.general;
        }
        return 0;
    }

    get hasBranches() {
        return this.companies && this.companies.length > 1;
    }

    /**
     * Select new company from branches
     * @param {string[]} values - companyIds
     */
    public setSelectedCompanies(values: string[]) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [this.filterService.getParamAlias('companies')]: values.join(',') },
            // reset the existing query params in the route
            queryParamsHandling: '',
            skipLocationChange: false,
        });
    }
}
