import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';

export interface CanComponentDeactivate {
    canDeactivate: () => boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (component.canDeactivate && !component.canDeactivate()) {
            if (
                confirm(
                    'Vous avez des actions en cours ou des changements non enregistrés! Si vous quittez cette page, vos changements ne seront pas pris en compte.'
                )
            ) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
