import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../../environments/environment';
import Rollbar, { RollbarService } from '../../../rollbar';
import { SessionService } from '../session/session.service';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        public sessionService: SessionService,
        @Inject(RollbarService) private rollbar: Rollbar
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.sessionService.checkVersion(environment.VERSION, true);

        if (!this.sessionService.getToken()) {
            this.router.navigateByUrl('/authentification');
            return;
        }
        const user = this.sessionService.getUser();
        // Check if rollbar user and company is already set
        if (
            user &&
            !(
                this.rollbar.options.payload &&
                (this.rollbar.options.payload as any).person &&
                (this.rollbar.options.payload as any).custom &&
                (this.rollbar.options.payload as any).custom.company_id
            )
        ) {
            const company = this.sessionService.getCompany();
            this.rollbar.configure({
                payload: {
                    context: this.router.url,
                    person: {
                        email: user.contact.mail,
                        id: user._id,
                    },
                    custom: {
                        company_id: company ? company._id : null,
                        company_name: company ? company.name : null,
                        version: environment.VERSION,
                    },
                },
            });
        }
        return this.sessionService.getToken() ? true : false;
    }
}
