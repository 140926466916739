import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router';
import { PageList, PagesList } from '../rights/rights.interface';
import { RightsService } from '../rights/rights.service';
import { SessionService } from '../session/session.service';

@Injectable()
export class AccessGuard implements CanActivate {
    company: any;
    user: any;
    pagesList: PagesList;

    loading: Promise<boolean>;

    constructor(private sessionService: SessionService, private rightsService: RightsService, private router: Router) {}

    /**
     * Load access guard values. Must be called at app initialization.
     * Can't be an APP_INITIALIZER as it uses injected services uses HttpClient (creating a circular dependancy).
     * The perfect solution would be to wait for this at init as Provider.
     * @todo take more time to find the cleanest solution
     * @returns {Promise.<boolean>} return loading promise (ex. waiting for pages to be loaded at login)
     */
    public async load(): Promise<boolean> {
        this.loading = this._load();
        return this.loading;
    }

    private async _load(): Promise<boolean> {
        try {
            this.loading = new Promise<boolean>(null);
            this.user = this.sessionService.getUser();
            this.company = this.sessionService.getCompany();
            if (this.sessionService.hasToken()) {
                const pagesList = await this.rightsService.getPagesListURLs();
                this.pagesList = pagesList;
                return true;
            }
            this.pagesList = null;
            return false;
        } catch (err) {
            this.user = null;
            this.company = null;
            this.pagesList = null;
            return false;
        }
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this.user = this.sessionService.getUser();
        this.company = this.sessionService.getCompany();
        /**
         * Wait for pages list to be loaded.
         */
        if (this.loading) {
            await this.loading;
        }
        try {
            const rights = await this.rightsService.getRightsListByCompanyAndUser(this.company._id, this.user._id);
            const access = this.getRightForUrl(state.url, rights.rights, route.data['page'], route.params);

            return this.redirection(
                access,
                route.data['redirectionForbiddenUrl'],
                route.data['hasEditingMode'],
                route.data['needEditingRight'],
                route.data['hasReadOnlyMode'],
                state.url
            );
        } catch (e) {
            return false;
        }
    }

    redirection(
        access: number = 0,
        redirectionForbiddenUrl,
        hasEditingMode = false,
        needEditingRight = false,
        hasReadOnlyMode = false,
        url
    ) {
        switch (access) {
            case 0:
                if (redirectionForbiddenUrl) {
                    this.router.navigate([redirectionForbiddenUrl]);
                }
                // if no redirection route was specified, stay on the same page
                break;
            case 1:
                if (needEditingRight) {
                    if (hasReadOnlyMode) {
                        this.router.navigate([url.replace('/edition', '')]);
                    } else if (redirectionForbiddenUrl) {
                        this.router.navigate([redirectionForbiddenUrl]);
                    } else {
                        this.router.navigate(['/accueil']);
                    }
                }
                break;
            case 2:
                if (hasEditingMode) {
                    this.router.navigate([url + '/edition']);
                }
                break;
        }
        return access ? true : false;
    }

    private getRightForUrl(urlSegment: string, userRights: any[], page: string, params: Params): number {
        if (!this.pagesList) {
            return 0;
        }

        // page exists only if there is subPages
        if (page) {
            // look into subPages for the subpage name
            const subPages: PageList = this.pagesList[page].subPages;
            const subPageName = Object.keys(subPages).find(subPage => {
                const formatedUrl = this.removeParamsFromUrl(params, subPages[subPage].urls, urlSegment);
                return subPages[subPage].urls && subPages[subPage].urls.includes(formatedUrl);
            });
            // Check rights for the subpage
            return this.checkRightForUrl(userRights, page, subPageName);
        }

        const urlNoQuery = urlSegment.split('?')[0];
        const pageName = Object.keys(this.pagesList).find(
            p => this.pagesList[p].urls && this.pagesList[p].urls.includes(urlNoQuery)
        );
        const rightObject = userRights.find(elem => elem.page === pageName);
        return rightObject ? rightObject.right : 0;
    }

    private checkRightForUrl(userRights: any[], page: string, subPageName: string) {
        const rightsForPage = userRights.find(elem => elem.page === page);
        if (!rightsForPage) {
            throw new Error('User has no rights for page ' + page + ', please set it in admin');
        } else {
            const rightForUrl = rightsForPage.subPages.find(subPage => subPage.name === subPageName);
            return rightForUrl ? rightForUrl.right : 0;
        }
    }

    // in the URL:
    // 1. remove otpional query params ("?index=0&page=2")
    // 2. replace the params value by their name to create a template URL
    removeParamsFromUrl(params, urlsTemplate, url) {
        // divide the url between url & required params & useless option params
        const urlDivided = url.replace('?', ' ? ').split(' '); // looks for the first "?" found --> [collecte/5aba35bb60dd33f146be7879][?][index=0&page=2]
        const urlSegment = urlDivided[0].split('/'); // [collecte/5aba35bb60dd33f146be7879] -> [collecte][5aba35bb60dd33f146be7879]

        let urlFound = null;

        // compare our URL with the URLs belonging to the page : "/collecte/groupId"
        urlsTemplate.forEach(urlTemplate => {
            // [collecte][groupId]
            const splitUrlTemplate = urlTemplate.split('/');
            // doesn't bother comparing URL that don't look alike - only the templates that have the same length as ours
            if (splitUrlTemplate.length === urlSegment.length) {
                // compare 'collecte' puis 'groupId'
                splitUrlTemplate.forEach((segment, index) => {
                    // if segment isn't the same, maybe it's a parameter -> 'groupeId' !== '12eioghmg4542ezg'
                    if (segment !== urlSegment[index]) {
                        // groupeId is a parameter
                        if (params.hasOwnProperty(segment)) {
                            urlSegment[index] = segment; // replace '12eioghmg4542ezg' by 'groupId'
                        } else {
                            return null;
                        }
                    }
                });
                urlFound = urlSegment.join('/'); // -> "/collecte/groupId"
            }
        });

        return urlFound;
    }
}
