import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { PageService } from '../../../shared/services/page/page.service';

@Injectable()
export class RepartitionService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    getSitesWithContracts(filters) {
        filters.companyIdTargeted = filters.companyId;
        return this.post('/api/common/consumption', filters);
    }

    getSitesProperties() {
        return this.get('/api/sites/company/available/properties');
    }
}
