import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'ga-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
    @Input() sites: any[];

    constructor(private router: Router) {}
    ngOnInit() {}

    getBorderClass(last) {
        return last ? 'last' : '';
    }

    getColorClass() {
        let className = '';

        if (this.router.url.split('/').length - 1 > 1) {
            className = this.router.url.split('/')[1];
        } else {
            className = this.router.url.replace('/', '');
        }

        return className;
    }
}
