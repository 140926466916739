import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { ScatterPlotSerie } from 'app/shared/models/charts/chart-serie.interface';
import { ChartLegendGA } from 'app/shared/models/charts/charts.interface';
import * as _ from 'lodash';

@Component({
    selector: 'ga-highchart-scatter-plot',
    templateUrl: './highchart-scatter-plot.component.html',
    styleUrls: ['./highchart-scatter-plot.component.scss'],
    providers: [],
})
export class HighchartScatterPlotComponent implements OnInit, OnChanges {
    @Input() options: any;
    @Input() legend: ChartLegendGA[];
    @Input() data: ScatterPlotSerie[];

    @Input() downloadFileName: string;
    chart: any;
    isLoading = true;

    @Output() valuesSelected: EventEmitter<ScatterPlotSerie[]> = new EventEmitter();

    constructor() {}

    ngOnInit() {
        this.options.series = this.data;
        this.isLoading = false;
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        // if the chart data has changed, update the values or redraw the chart if the number of series has changed
        for (const propName in changes) {
            if (propName === 'data' && this.chart && this.chart.series && this.chart.series[0]) {
                if (this.chart.series.length !== this.data.length) {
                    this.redrawSeries();
                } else {
                    this.udpateSeries();
                }

                this.chart.update({ series: this.data });
                this.chart.update({ yAxis: this.options.yAxis });
                this.chart.update({ xAxis: this.options.xAxis });
            } else if (propName === 'options' && this.chart) {
                this.chart.update({ containerWidth: this.options.containerWidth });
            }
        }
    }

    redrawSeries() {
        const nbSeriesToDelete = this.chart.series.length;
        for (let i = 0; i < nbSeriesToDelete; i++) {
            this.chart.series[0].remove();
        }

        this.data.forEach((serie, index) => {
            this.chart.addSeries(this.data[index]);
        });
    }

    redrawChart() {
        if (this.chart) {
            // DONT REMOVE IT
            setTimeout(() => {
                this.chart.setSize(null);
            });
        }
    }

    udpateSeries() {
        this.data.forEach((serie, index) => {
            this.chart.series[index].setData(this.data[index].data);
        });
    }

    saveInstance(chartInstance) {
        // DONT REMOVE IT: fix to avoid highcharts' callback with empty chartInstance after export
        if (!this.chart) {
            // DONT REMOVE IT: fix to avoid "Expression has changed after it was checked" error
            setTimeout(() => {
                this.chart = chartInstance;
                this.isLoading = false;
            });
        }
    }

    /**
     * Filter the series with only the points selected by zooming and return them to parent component.
     * /!\ Event doesn't fire when the user scrolls horizontally.
     * @param event
     */
    getSelectedValues(event): void {
        const e = event.originalEvent;

        if (e.resetSelection) {
            this.valuesSelected.emit(this.data);
            return;
        }

        const xMin = e.xAxis[0].min;
        const xMax = e.xAxis[0].max;
        const yMin = e.yAxis[0].min;
        const yMax = e.yAxis[0].max;

        const dataSelected = _.cloneDeep(this.data);
        dataSelected.forEach(serie => {
            if (serie.data) {
                serie.data = serie.data.filter(point => {
                    return point.x >= xMin && point.x <= xMax && point.y >= yMin && point.y <= yMax;
                });
            }
        });

        this.valuesSelected.emit(dataSelected);
    }
}
