import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/services/api/api.service';
import { PageService } from '../../shared/services/page/page.service';
import { SessionService } from '../../shared/services/session/session.service';

@Injectable()
export class FixRegexPropertyService extends PageService {
    constructor(public apiService: ApiService, public sessionService: SessionService) {
        super(apiService);
        this.fake = false;
    }

    getDictionaryForProperty(energyType, identificationPatternId, property): Promise<any> {
        const route = `/api/patterns/${energyType}/search?identificationPatternId=${identificationPatternId}&property=${property}`;

        return this.get(route);
    }

    searchGroupFilesCount(groupId, energyType, provider, contractType): Promise<any> {
        const url = `/api/uploads/group-count/${groupId}`;

        const body = {
            companyId: this.sessionService.getCompanyId(),
            energyType,
            provider,
            contractType,
        };

        return this.post(url, body);
    }

    getUploadTextContentById(id) {
        // load the company from its id
        return this.get('/api/uploads/text-content/' + id).then(
            response => {
                if (response.code === 200) {
                    return Promise.resolve(response.data);
                } else {
                    const err = { error_code: 'error_loadUpload' };
                    return Promise.reject(err);
                }
            },
            err => {
                err.error_code = 'error_loadUpload';
                return Promise.reject(err);
            }
        );
    }

    getUploadChunkTextContentById(id, idChunk) {
        // load the company from its id
        return this.get('/api/uploads/text-content/' + id + '/chunk/' + idChunk).then(
            response => {
                if (response.code === 200) {
                    return Promise.resolve(response.data);
                } else {
                    const err = { error_code: 'error_loadUpload' };
                    return Promise.reject(err);
                }
            },
            err => {
                err.error_code = 'error_loadUpload';
                return Promise.reject(err);
            }
        );
    }

    searchGroupFiles(groupId, energyType, provider, contractType, indexStart, indexEnd): Promise<any> {
        const url = `/api/uploads/group-content/${groupId}`;

        const body = {
            companyId: this.sessionService.getCompanyId(),
            energyType,
            provider,
            contractType,
            startIndex: indexStart,
            stopIndex: indexEnd,
        };

        return this.post(url, body);
    }

    getPatternProperties(energyType): Promise<any> {
        return this.get('/api/properties/pattern-' + energyType);
    }
}
