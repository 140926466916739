import { Injectable } from '@angular/core';
import { FilterQuery } from 'app/shared/components/common/filter/filter.component';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { Site } from './cartography.interface';

@Injectable()
export class CartographyService extends PageService {
    constructor(public apiService: ApiService, private sessionService: SessionService) {
        super(apiService);
        this.fake = false;
    }

    /**
     * Return sites filtered from filters.
     * @param {FilterQuery} filters
     * @param {string} filters.regions - area to filter the sites on
     * @param {string} filters.energies - fluid to filter the sites on
     * @param {string} filters.companies - companies to filter the sites on (in the case of holding vs branches)
     * @param {string} filters.companyId - company to filter sites on if companies not set
     */
    async getSites(filters: FilterQuery = {}): Promise<Site[]> {
        try {
            const companyId: string = filters['companyId'] || this.sessionService.getCompany()._id;
            const sites = await this.post(`/api/companies/${companyId}/sites`, filters);
            return sites.data;
        } catch (e) {
            e.error_code = 'error_load_sites';
            throw e;
        }
    }
}
