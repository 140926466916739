import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractLight, ContractPopulated } from 'app/shared/models/contract.interface';
import { ContractsService } from 'app/shared/services/contracts/contracts.service';
import { ContractEditionService } from './contract-edition.service';

import Swal from 'sweetalert2';

@Component({
    selector: 'contract-edition',
    templateUrl: './contract-edition.component.html',
    styleUrls: ['./contract-edition.component.scss'],
    providers: [ContractEditionService],
})
export class ContractEditionComponent implements OnInit {
    /**
     * Unpopulated contract to be editted
     */
    @Input() private _contract: ContractLight;

    /**
     * Triggered when save button pressed and save done
     */
    @Output() public saved: EventEmitter<void> = new EventEmitter<void>();
    /**
     * Triggered when cancel button pressed
     */
    @Output() public canceled: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Contract populated to display and use in component
     */
    public get contract(): ContractPopulated {
        return this.contractEditionService.contract;
    }

    /**
     * Is the contract loading
     */
    public loading = true;

    /**
     * Is contract being saved
     */
    public saving = false;

    constructor(private contractsService: ContractsService, private contractEditionService: ContractEditionService) {}

    ngOnInit(): void {
        this.getData();
    }

    public get enableSave(): boolean {
        return this.contractEditionService.updates.isValid();
    }

    /**
     * Send save request of updates to api
     */
    public async save() {
        try {
            /** If already saving, don't send other request */
            if (this.saving) {
                return;
            }
            /**
             * Convert routing references pricing values using unit rate
             * @todo only convert pricing values that need to be converted, not all
             */
            this.contractEditionService.computeUpdateRoutingReferencesPrices();
            /**
             * Get contract updates
             */
            const updates = this.contractEditionService.updates;
            /**
             * If updates invalid, don't send request
             */
            if (!updates.isValid()) {
                return;
            }
            /**
             * Check if there is some updates to do
             */
            const properties = ['infos', 'pricings', 'routingReferences'];
            const hasUpdate = properties.some(prop => updates[prop]);
            /**
             * If there is updates to do, then save contract with new values
             */
            if (updates && updates._id && hasUpdate) {
                this.saving = true;
                await this.contractsService.updateContract(updates);
            }
            this.saved.emit();
        } catch (e) {
            if (!e.errorCode) {
                e.errorCode = 'save';
            }
            this.displayError(e.errorCode);
        }
        this.saving = false;
    }

    /**
     * Triggers cancel : reset updates and emit cancel event
     */
    public cancel() {
        this.contractEditionService.resetUpdates();
        this.canceled.emit();
    }

    /**
     * Get data :
     * - contract populated
     * - available contract pricings for contract's fluid
     * - available contract pricings items for the available contract pricings
     */
    private async getData() {
        try {
            /** Get populated contract */
            this.contractEditionService.contract = await this.contractsService.getContractPopulated(this._contract._id);
            /** Get tarifications for the contract type */
            const { pricings, pricingsItems } = await this.contractsService.getPricings(this.contract.energyType);
            this.contractEditionService.pricings = pricings;
            this.contractEditionService.pricingItems = pricingsItems;
        } catch (e) {
            if (!e.errorCode) {
                e.errorCode = 'get_data';
            }
            this.displayError(e.errorCode);
        }
        this.loading = false;
    }

    /**
     * Display error
     * @param {string} errorCode
     */
    private displayError(errorCode: string) {
        const map = {
            default: ['Une erreur est survenue', 'Merci de réessayer ultérieurement.', 'error'],
            get_data: [
                'Une erreur est survenue',
                'Une erreur est survenue lors de la récupération des données.',
                'error',
            ],
            save: ['Une erreur est survenue', 'Une erreur est survenue lors de la sauvegarde du contrat.', 'error'],
            invalid_contract: ['Contrat invalide', 'Veuillez vérifier la cohérence des données du contrat.', 'error'],
            contract_not_found: ['Contrat non trouvé', "Ce contrat n'existe pas ou vous n'y avez pas accès.", 'error'],
        };
        let error = map[errorCode];
        if (!error) {
            error = map['default'];
        }
        Swal.fire({
            title: error[0],
            text: error[1],
            icon: error[2],
        });
    }

    /**
     * Return the key of the message to display while the contract is saved
     */
    get saveMessage(): string {
        return this.saving ? 'bills_control_delay' : 'bills_control_saving_message';
    }
}
