import { Injectable } from '@angular/core';
import { Bill } from 'app/shared/models/bills.interface';
import * as BlueBird from 'bluebird';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

export interface DownloadResult {
    upload: string; // id as as string
    success: boolean;
    filename: string;
}

@Injectable()
export class UploadService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    /** Get a public url for the file */
    async getUploadFileUrl(uploadId: string): Promise<string> {
        const res = await this.get(`/api/uploads/file-url/${uploadId}`);
        return res.data;
    }

    /** Open the file in a new tab */
    async openFileInTab(uploadId: string): Promise<void> {
        const url = await this.getUploadFileUrl(uploadId);
        window.open(url);
    }

    /**
     * Download the given bills.
     * For those which failed, create a report in a modal with their filenames formated.
     * @param {Bill[]} bills
     */
    async downloadAndReportMulti(bills: Bill[]): Promise<void> {
        const resultsDownload: DownloadResult[] = await this.downloadMultiBills(bills);

        // if somme downloads aren't successful, create a report as a modal
        if (resultsDownload && resultsDownload.length && resultsDownload.some(result => !result.success)) {
            this.generateHtmlReport(resultsDownload);
        }
    }

    /**
     * Download multiple bills in series.
     * @todo: remove bluebird and use simple for loop
     * @param {Bill[]} bills
     */
    async downloadMultiBills(bills: Bill[]): Promise<DownloadResult[]> {
        const downloadSeries = BlueBird.resolve(bills.map(bill => createDownloadBill(bill).bind(this)));
        return downloadSeries.mapSeries(f => f());

        function createDownloadBill(bill) {
            return async function() {
                try {
                    const filename = await this.downloadBill(bill);
                    return { upload: bill.upload, filename, success: true };
                } catch (e) {
                    const filename = this.getFilenameFromBill(bill);
                    return { upload: bill.upload, filename, success: false };
                }
            };
        }
    }

    /**
     * Download the bill
     * @param {Bill} bill
     * @returns {Promise<string>} filename of the bill's upload
     */
    async downloadBill(bill: Bill): Promise<string> {
        const filename = this.getFilenameFromBill(bill);
        const url = await this.getUploadFileUrl(bill.upload);
        saveAs(url, filename);

        return filename;
    }

    /**
     * Generate a report inside a report if something went wrong during the multi-download
     * @param {DownloadResult[]} resultsDownload
     */
    generateHtmlReport(downloadResults: DownloadResult[]) {
        let html = '';
        downloadResults.forEach(result => {
            if (!result.success) {
                html += '<li>' + result.filename + '</li>';
            }
        });

        // Display error report
        Swal.fire({
            html:
                '<h1>Fichiers temporairement inaccessibles: </h1><br>' +
                '<div style="text-align: left">' +
                html +
                '</div>',
            icon: 'error',
        });
    }

    /**
     * Create a filename from the bill properties (reference - DD/MM/YYYY - provider)
     * @param {Bill} bill
     * @returns {string} filename
     */
    getFilenameFromBill(bill: Bill): string {
        return `${bill.chunks[0].bill.reference} - ${moment(bill.dates.billDate).format('DD/MM/YYYY')} - ${
            bill.provider
        }`;
    }
}
