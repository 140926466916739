import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollectService } from 'app/pages/collect/collect.service';
import { QueryFilter } from 'app/shared/models/bills-contracts-filter.interface';
import {
    BillChunkPopulated,
    BillPopulated,
    BillsColumns,
    BillValues,
    BillValuesDisplay,
    SubTotals,
} from 'app/shared/models/bills.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FeatureToggleService } from 'app/shared/services/feature-toggle/feature-toggle.service';
import { UploadService } from 'app/shared/services/files/upload.service';
import { NotificationsService } from 'app/shared/services/notifications/notifications.service';
import { UserSession } from 'app/shared/services/session/session.interface';
import { SessionService } from 'app/shared/services/session/session.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { UsersService } from 'app/shared/services/users/users.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-bills-list',
    templateUrl: './bills-list.component.html',
    styleUrls: ['./bills-list.component.scss'],
    providers: [CollectService],
})
export class BillsListComponent implements OnInit {
    public billsSelectedList: BillPopulated[] = [];
    public readonly exportUrl = '/api/export/excel/bills-listing';
    public isTableDisabled = false;
    private user: UserSession;
    public isCurrentUserAdmin = false;

    /**
     * List of bills to display
     */
    private _billsList: BillPopulated[] = [];
    @Input()
    set billsList(list) {
        this.initCollapseMulti(list);
        this._billsList = list;
        this.computeBillsValuesDisplay();
    }
    get billsList() {
        return this._billsList;
    }

    /**
     * Enable or not the access to navigate to a page by clicking on a bill in the table
     */
    private _enabledLinkBill = false;
    @Input()
    set enabledLinkBill(enabled) {
        this._enabledLinkBill = enabled;
    }
    get enabledLinkBill() {
        return this._enabledLinkBill;
    }

    /**
     * To set category column displaying
     */
    @Input() availableColumns: BillsColumns = {
        category: {
            enabled: true,
        },
    };

    /**
     * Message to display when the table is empty. Don't display anything if there is no message.
     */
    @Input() emptyTableMsg: string = null;

    /**
     * Used to display loading message
     */
    @Input() isLoading: boolean;
    @Input() filters: QueryFilter;

    @Output() billsListChanged = new EventEmitter<void>();

    constructor(
        private _utilsService: UtilsService,
        private _uploadService: UploadService,
        private _energyService: EnergyService,
        private _translateService: TranslateService,
        private _sessionService: SessionService,
        private _collectService: CollectService,
        private _notificationsService: NotificationsService,
        private _usersService: UsersService,
        private readonly _featureToggleService: FeatureToggleService
    ) {}

    ngOnInit(): void {
        this.user = this._sessionService.getUser();
        if (this._usersService.isAdmin(this.user)) {
            this.isCurrentUserAdmin = true;
        }
    }

    public get filtersQueryString(): string {
        if (!this.filters) {
            return '';
        }

        let url = `?start=${this.filters.start}&end=${this.filters.end}`;
        url += this.filters.query ? `&query=${this.filters.query}` : '';
        url += this.filters.onlyError ? `&onlyError=${this.filters.onlyError}` : '';
        url += this.filters.includeBranches ? `&includeBranches=${this.filters.includeBranches}` : '';
        url += this.filters.fluids ? `&fluids=${this.filters.fluids}` : '';
        url += this.filters.status ? `&status=${this.filters.status}` : '';
        url += this.filters.providers ? `&providers=${this.filters.providers}` : '';
        url += this.filters.states ? `&states=${this.filters.states}` : '';
        url += this.filters.onlyValidContracts ? `&onlyValidContracts=${this.filters.onlyValidContracts}` : '';
        url += this.filters.routingReferences ? `&routingReferences=${this.filters.routingReferences}` : '';
        url += this.filters.contracts ? `&contracts=${this.filters.contracts}` : '';
        url += this.filters.failedVerifications ? `&failedVerifications=${this.filters.failedVerifications}` : '';

        return url;
    }

    public get fileName(): string {
        if (!this.filters) {
            return '';
        }

        const { name } = this._sessionService.getCompany();
        const fileNameParts = [name];

        const { start, end, includeBranches, query, fluids, providers, failedVerifications } = this.filters;

        if (start && end) {
            const startDate = moment(start).format('DD-MM-YYYY');
            const endDate = moment(end).format('DD-MM-YYYY');
            fileNameParts.push(`Factures du ${startDate} au ${endDate}`);
        }

        if (includeBranches) {
            fileNameParts.push('filiales incluses');
        }

        // search - without special characters to avoid issues with file name
        if (query) {
            const cleanedQuery = query.replace(/[^a-zA-Z \d]/g, '');
            fileNameParts.push(cleanedQuery);
        }

        if (fluids && fluids.length) {
            fileNameParts.push(fluids.map(fluid => this._energyService.energyFullText(fluid)).join(' '));
        }

        if (providers && providers.length) {
            fileNameParts.push(providers.join(' '));
        }

        // Selected controls
        if (failedVerifications && failedVerifications.length > 0) {
            const controls = [
                [
                    { keys: ['elec_quantity_cspe', 'elec_amount_htva'], text: "factures d'électricité en erreur" },
                    {
                        keys: ['elec_quantity_cspe'],
                        text: "factures d'électricité en erreur d'extraction des consommations",
                    },
                    {
                        keys: ['elec_amount_htva'],
                        text: "factures d'électricité en erreur sur la complétude d'extraction des coûts",
                    },
                ],
                [
                    { keys: ['gaz_ticgn_quantity', 'gaz_htva_amount'], text: 'factures de gaz en erreur' },
                    {
                        keys: ['gaz_ticgn_quantity'],
                        text: "factures de gaz en erreur d'extraction des consommations",
                    },
                    {
                        keys: ['gaz_htva_amount'],
                        text: "factures de gaz en erreur sur la complétude d'extraction des coûts",
                    },
                ],
            ];

            controls.forEach(control => {
                const controlSelected = control.find(controlMessage =>
                    controlMessage.keys.every(key => failedVerifications.includes(key))
                );
                if (controlSelected) {
                    fileNameParts.push(controlSelected.text);
                }
            });
        }

        return `${fileNameParts.join(' - ')}.xlsx`;
    }

    /**
     * ************ Init data after search ************
     */

    initCollapseMulti(newList: BillPopulated[]) {
        newList.forEach(bill => {
            if (bill.multi) {
                const billAlreadyPresent = this.billsList.find(b => b.upload === bill.upload);
                bill.isExpanded = billAlreadyPresent ? billAlreadyPresent.isExpanded : false;
            } else {
                bill.isExpanded = false;
            }
        });
    }

    /**
     * Compute bill list with bills values to be displayed
     */
    computeBillsValuesDisplay() {
        this.billsList.forEach(bill => {
            if (bill.multiBillValues) {
                bill.multiBillValuesDisplay = this.getBillValuesDisplay(bill.multiBillValues, bill);
            }
            bill.chunks.forEach(chunk => {
                chunk.valuesDisplay = this.getBillValuesDisplay(chunk.bill.values);
            });
            if (bill.isExpanded) {
                this.computeSubtotalsValuesDisplay(bill);
            }
        });
    }

    /**
     * Compute bill subTotalsPerCategory values for display
     * @param {Bill} bill - bill to compute subtotal display from
     */
    private computeSubtotalsValuesDisplay(bill: BillPopulated) {
        bill.subTotalsPerCategory.forEach(subtotal => {
            if (!subtotal.subtotalsDisplay) {
                subtotal.subtotalsDisplay = this.getBillValuesDisplay(subtotal.subtotals);
            }
        });
    }

    /**
     * ************ Actions from header row, multi selection --> action ************
     */

    /** Called when the selectbox "all" is checked or not */
    onClickSelectAll() {
        if (!this.isEveryBillSelected()) {
            this.selectAllBills();
        } else {
            this.deselectAllBills();
        }
    }

    /** Add to the selected bills list all the bills currently displayed in the table */
    selectAllBills() {
        this.billsList.forEach(bill => {
            if (!this.isAlreadySelected(bill.upload)) {
                this.billsSelectedList.push(bill);
            }
        });
    }

    /** Remove from the selected bills all the bills currently displayed in the list */
    deselectAllBills() {
        this.billsList.forEach(bill => {
            if (this.isAlreadySelected(bill.upload)) {
                const indexAlreadySelected = this.billsSelectedList.findIndex(
                    b => b.upload.toString() === bill.upload.toString()
                );
                this.billsSelectedList.splice(indexAlreadySelected, 1);
            }
        });
    }

    /** Cursor pointer of some bills are selected */
    getIconCursorClass() {
        return this.hasBillSelected() ? 'cursor-pointer' : '';
    }

    /** Returns true if at least one bill is selected */
    hasBillSelected() {
        return this.billsSelectedList && this.billsSelectedList.length;
    }

    /** Returns the number of bills selected */
    displayNbBillsSelected() {
        if (this.hasBillSelected()) {
            const count = this.billsSelectedList.length;
            return count.toString() + (count > 1 ? ' factures sélectionnées' : ' facture sélectionnée');
        }
    }

    /**
     * ********************** Download **********************
     */

    /**
     * Download the selected bills.
     * For those which failed, create a report in a modal with their filenames formated.
     */
    async onDownloadMultiSelection(): Promise<void> {
        this._uploadService.downloadAndReportMulti(this.billsSelectedList);
    }

    /**
     * Download the given bill.
     * @param {BillPopulated} bill
     */
    async onSingleDownload(bill: BillPopulated) {
        try {
            this._uploadService.downloadBill(bill);
        } catch (e) {
            const filename = this._uploadService.getFilenameFromBill(bill);
            Swal.fire(
                'Toutes nos excuses',
                `Le fichier ${filename} est inaccessible pour le moment. Veuillez réessayer ultérieurement.`,
                'warning'
            );
        }
    }

    /**
     * Deletes the given bill and warns user
     * @param {BillPopulated} bill - bill to remove
     */
    async onBillDelete(bill: BillPopulated): Promise<void> {
        const result = await Swal.fire({
            title: 'Êtes-vous sur ?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        });
        if (result.value) {
            this.removeBill(bill);
        }
    }

    async onBillReUplaod(bill: BillPopulated): Promise<void> {
        const result = await Swal.fire({
            title: 'Êtes-vous sur ?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Re-extraire',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        });
        if (result.value) {
            this.reUploadBill(bill);
        }
    }

    /**
     * Remove a bill
     * @param {BillPopulated} bill - bill to remove
     */
    async removeBill(bill: BillPopulated): Promise<void> {
        this.isTableDisabled = true;
        try {
            const res = await this._collectService.removeFile(bill);
            if (res.code !== 200) {
                throw new Error('remove-file-failed');
            }
            this.billsList = this.billsList.filter(item => item._id !== bill._id);
            this.billsListChanged.emit();
            Swal.fire('Supprimé', 'Le fichier a bien été supprimé.', 'success');
        } catch (err) {
            Swal.fire('Toutes nos excuses', 'Une erreur est survenue lors de la suppression du fichier', 'error');
        }
        this.isTableDisabled = false;
        this._notificationsService.refresh();
    }

    /**
     * Re uplaod a bill
     * @param {BillPopulated} bill - bill to re upload
     */
    async reUploadBill(bill: BillPopulated): Promise<void> {
        this.isTableDisabled = true;
        try {
            const res = await this._collectService.reextractFile(bill.upload);
            if (res.code !== 200) {
                throw new Error('re-upload-file-failed');
            }
            this.billsList = this.billsList.filter(item => item._id !== bill._id);
            this.billsListChanged.emit();
            Swal.fire('Re-extrait', 'Le fichier a bien été re-extrait.', 'success');
        } catch (err) {
            Swal.fire('Toutes nos excuses', 'Une erreur est survenue lors de la suppression du fichier', 'error');
        }
        this.isTableDisabled = false;
        this._notificationsService.refresh();
    }

    /**
     * ************ Actions on table ************
     */

    /**
     * Returns true if the upload id sent is already in the selected bills list. */
    isAlreadySelected(billIdToCompare) {
        return this.billsSelectedList.some(b => b.upload.toString() === billIdToCompare.toString());
    }

    /**
     * Returns true if all the bills displayed are selected.
     * @return {boolean}
     */
    isEveryBillSelected() {
        return Boolean(
            this.billsSelectedList.length && this.billsList.every(bill => this.isAlreadySelected(bill.upload))
        );
    }

    /**
     * Add the bill to the selected bills list if it's not there remove the bill from the list otherwise
     *
     * @param {BillPopulated} bill
     */
    switchBillSelection(bill: BillPopulated) {
        const indexAlreadySelected = this.billsSelectedList.findIndex(
            b => b.upload.toString() === bill.upload.toString()
        );
        // If the bill was selected --> unselected it
        if (indexAlreadySelected !== -1) {
            this.billsSelectedList.splice(indexAlreadySelected, 1);
        } else {
            this.billsSelectedList.push(bill);
        }
    }

    toggleCollapse(bill: BillPopulated) {
        bill.isExpanded = !bill.isExpanded;
        /**
         * On every opening : check is all subTotalsPerCategory are computed for string display
         * If not, compute it. So it only do it once on first opening.
         * It prevents from computing all at init
         */
        if (bill.subTotalsPerCategory && bill.isExpanded) {
            this.computeSubtotalsValuesDisplay(bill);
        }
    }

    getCollapseClass(bill: BillPopulated) {
        return bill.isExpanded ? 'fa-rotate-90' : '';
    }

    /** Returns MULTI or SIMPLE depending on the bill's type. */
    getType(bill: BillPopulated) {
        return bill.multi ? 'multi' : 'simple';
    }

    getTypeClass(bill: BillPopulated) {
        return bill.multi ? 'table__col-type-multi cursor-pointer' : 'table__col-type-simple';
    }

    isDisplayingChunks(bill: BillPopulated) {
        return bill.multi && bill.isExpanded;
    }

    /**
     * Returns the bill number of the bill / the chunk.
     * The bill must have passed validation. Required fields include: "chunks.bill"
     * */
    getBillNumber(bill: BillPopulated, chunk: BillChunkPopulated = null) {
        return !bill.multi
            ? bill.chunks[0] && bill.chunks[0].bill.reference
            : bill.isExpanded && chunk
            ? chunk.bill.reference
            : bill.chunks[0].bill.reference;
    }

    /**
     * Returns the routing reference number / vehicle immat of the bill / the chunk / nothing
     * The bill must have passed validation. Required fields include: "chunks.routingReference"|"chunks.vehicle"
     * @param {Bill} bill
     * @param {BillChunkPopulated|null} chunks
     * @returns {string} routing reference number / vehicle immat of the bill/the chunk / nothing
     * */
    getSourceReference(bill: BillPopulated, chunk: BillChunkPopulated = null): string {
        if (!chunk) {
            if (!bill.multi && bill.chunks[0]) {
                chunk = bill.chunks[0];
            } else {
                return '';
            }
        }

        const field = this._energyService.getFluidSource(chunk.fluid);

        if (!field) {
            return '';
        }

        return !bill.multi
            ? bill.chunks[0] && bill.chunks[0][field].reference
            : bill.isExpanded && chunk
            ? chunk[field].reference
            : '';
    }

    /**
     * Returns the property's value for the bill or the chunk
     * @param bill
     * @param property
     */
    getValue(bill: BillPopulated, property: string, chunk: BillChunkPopulated = null) {
        const value = !bill.multi
            ? bill.chunks[0].valuesDisplay[property]
            : bill.isExpanded && chunk
            ? chunk.valuesDisplay[property]
            : bill.multiBillValuesDisplay[property];
        return value;
    }

    private getNumberAndUnitToDisplay(value: number, unit: string = '€', type: string = 'money') {
        return typeof value !== 'undefined' && value !== null
            ? this._utilsService.getNumberAndUnitToDisplay(value, unit, type)
            : 'NC';
    }

    isValueComputed(bill: BillPopulated, property: string, chunk: BillChunkPopulated = null) {
        const values = !bill.multi
            ? bill.chunks[0].bill.values
            : bill.isExpanded && chunk
            ? chunk.bill.values
            : bill.multiBillValues;
        switch (property) {
            case 'totalHTVA':
                return values.isTotalHTVAComputed;
            case 'totalTVA':
                return values.isTotalTVAComputed;
            case 'totalTTC':
                return values.isTotalTTCComputed;
            default:
                return false;
        }
    }

    /** Returns the category for the bill / chunk or "Multiple" if there are more than 1. */
    getCategory(bill: BillPopulated, chunk: BillChunkPopulated = null): string {
        if (!bill.multi) {
            return getTextToDisplay(bill.chunks[0].categories);
        } else if (bill.isExpanded && chunk) {
            return getTextToDisplay(chunk.categories);
        }

        return '';

        function getTextToDisplay(categoriesArray): string {
            return categoriesArray.length > 1 ? 'Multiple' : categoriesArray[0].name;
        }
    }

    /**
     * Disable popover display if bill is multi and expanded
     */
    isPopoverVisible(bill: BillPopulated): boolean {
        return bill.multi ? !bill.isExpanded : true;
    }

    getVerifications(bill: BillPopulated, chunk: BillChunkPopulated = null) {
        if (bill.multi) {
            return bill.isExpanded && chunk && chunk.verifications ? chunk.verifications : [];
        }
        return bill.chunks[0] && bill.chunks[0].verifications ? bill.chunks[0].verifications : [];
    }

    getChunksByIds(billIds: string[], chunksList: BillChunkPopulated[]) {
        return chunksList.filter(chunk => billIds.includes(chunk.bill.id));
    }

    getSubtotalValue(subTotal: SubTotals, property: string) {
        return subTotal.subtotalsDisplay ? subTotal.subtotalsDisplay[property] : '-';
    }

    /**
     * Get bills values display from bill values
     * @param {BillValues} billValues - bill values to get the data from
     * @param {Bill} bill - bill related to the values. Only used for bill (not chunk computation).
     * @returns {BillValuesDisplay} item with all properties to be displayed
     */
    private getBillValuesDisplay(billValues: BillValues, bill: BillPopulated = null): BillValuesDisplay {
        let quantity: string = null;
        let isMultiFluid = false;
        /**
         * If multi fluid, API set quantity to null,
         * but a null can because no data so we need to distinct both cases
         */
        if (billValues.quantity === null && bill) {
            isMultiFluid = this.isBillMultiFluid(bill);
        }
        if (quantity === null && isMultiFluid) {
            quantity = this._translateService._('multiple');
        } else {
            quantity = this.getNumberAndUnitToDisplay(billValues.quantity, billValues.quantityUnit, 'fluid');
        }
        return {
            totalHTVA: this.getNumberAndUnitToDisplay(billValues.totalHTVA),
            totalTTC: this.getNumberAndUnitToDisplay(billValues.totalTTC),
            totalTVA: this.getNumberAndUnitToDisplay(billValues.totalTVA),
            quantity,
            isMultiFluid,
        };
    }

    /**
     * @param {Bill} bill - bill to determine if multi fluide or not
     * @returns {boolean} true if bill is multi fluid, false otherwise
     */
    private isBillMultiFluid(bill: BillPopulated): boolean {
        if (bill) {
            const fluids = {};
            bill.chunks.forEach(chunk => {
                fluids[chunk.fluid] = 1;
            });
            if (Object.keys(fluids).length > 1) {
                return true;
            }
        }
        return false;
    }

    /**
     * returns the link to the bills page
     * @returns {string[]}
     */
    getLinkBill(): string[] {
        if (this.enabledLinkBill) {
            return [`/factures`];
        }
        return [];
    }

    /**
     * returns the query param of the link to the bills page
     * @param {string} queryValue
     * @returns {<{q?: string}>}
     */
    getQueryParamLinkBill(queryValue: string): { q?: string } {
        if (this.enabledLinkBill) {
            return { q: queryValue };
        }
        return {};
    }

    /**
     * @returns {boolean} true if table isn't empty
     */
    public hasBills(): boolean {
        return Boolean(this.billsList && this.billsList.length);
    }

    public isReExtractActivated(): boolean {
        return this._featureToggleService.isEnabled('re-extraction-on-history.newButton');
    }
}
