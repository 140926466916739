import { Pipe, PipeTransform } from '@angular/core';

import { SortFilters } from '../services/sort/sort-filter/sort-filter.interface';
import { SortFilterService } from '../services/sort/sort-filter/sort-filter.service';
@Pipe({
    name: 'orderby',
})
export class OrderByPipe implements PipeTransform {
    constructor(private sortFilterService: SortFilterService) {}

    transform(array: any[], filters: SortFilters): any[] {
        return this.sortFilterService.sortArray(array, filters);
    }
}
