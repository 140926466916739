import { Component, Input, OnInit } from '@angular/core';
import { ExportService } from 'app/shared/services/export/export.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-export-button',
    templateUrl: './export-button.component.html',
    styleUrls: ['./export-button.component.scss'],
})
export class ExportButtonComponent implements OnInit {
    /**
     * Is currently exporting
     */
    public isExporting = false;
    /**
     * If null a default one is used
     */
    @Input() fileName?: string;
    @Input() url: string;
    @Input() queryString: string;

    /**
     * used to display the right icon when exporting excel file or not
     * @returns {string} - classname
     */
    get exportClass(): string {
        return this.isExporting ? 'citron-icon rotating' : 'excel-icon';
    }

    constructor(private sessionService: SessionService, private exportService: ExportService) {}

    ngOnInit() {}

    public async exportToExcel(): Promise<void> {
        this.isExporting = true;
        this.exportService.getFile(`${this.url}${this.queryString}`).subscribe(
            file => {
                saveAs(file, this.fileName || this.getDefaultName());
                this.isExporting = false;
            },
            err => {
                this.isExporting = false;
                Swal.fire(
                    'Toutes nos excuses',
                    "Une erreur s'est produite lors de l'export de données. Merci de réessayer.",
                    'error'
                );
            }
        );
    }

    private getDefaultName(): string {
        const currentCompany = this.sessionService.getCompany();
        const extractStart =
            this.queryString.match(/[?|&]start=([^&]*)/) || this.queryString.match(/[?|&]dateStart=([^&]*)/);
        const extractEnd = this.queryString.match(/[?|&]end=([^&]*)/) || this.queryString.match(/[?|&]dateEnd=([^&]*)/);
        const dateStart = moment(extractStart ? extractStart[1] : new Date())
            .format('MMMM YYYY')
            .toString();
        const dateEnd = moment(extractEnd ? extractEnd[1] : new Date())
            .format('MMMM YYYY')
            .toString();
        return `${currentCompany.name} - ${dateStart} à ${dateEnd}.xlsx`;
    }
}
