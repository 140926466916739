import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

// interfaces
import { ContractPricing, ContractPricingItem } from 'app/shared/models/contract-pricing.interface';
import { ContractPopulated, PricingSelected, PricingSelectedValue } from 'app/shared/models/contract.interface';
import { PricingOption } from '../contract-edition.interface';
import { PricingSection } from './pricings-common.interface';

// services
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { ContractEditionService } from '../contract-edition.service';

@Component({
    selector: 'contract-edition-pricings-common',
    templateUrl: './pricings-common.component.html',
    styleUrls: ['./pricings-common.component.scss'],
})
export class PricingsCommonComponent implements OnInit {
    /**
     * Pricing available options with values set and updated.
     * Used to set new pricings wihtout updating contract object
     */
    public pricingsAvailable: {
        default: string;
        options: PricingOption[];
    } = {
        default: '0',
        options: [],
    };

    public priceRates: {
        rate: number;
        items: Array<{
            label: string;
            rate: number;
        }>;
    } = {
        rate: 1,
        items: [],
    };

    public isUnselectedPricingsOpened: boolean;

    public selected = '';

    /**
     * Contract to edit common pricing from
     */
    public get contract(): ContractPopulated {
        return this.contractEditionService.contract;
    }

    /**
     * Available pricings description for the contract
     */
    public get pricings(): ContractPricing[] {
        return this.contractEditionService.pricings;
    }

    /**
     * Pricing items related to the pricings
     */
    public pricingItems: ContractPricingItem[] = [];

    /**
     * Property to store the available contract pricing items by section
     */
    public availablePricingsSections: PricingSection[] = [];

    constructor(private energyService: EnergyService, private contractEditionService: ContractEditionService) {}

    ngOnInit() {
        this.pricingItems = this.contractEditionService.pricingItems;
        // Find pricing items with base group only
        this.pricingItems = _.filter(this.pricingItems, { group: 'base' });
        this.pricingItems = _.sortBy(this.pricingItems, ['label']);

        this.initPricingsAvailable();
        this.initPriceRates();

        // If a pricing is already selected and saved, push it in an array of selected pricings
        if (this.selectedPricings.length !== 0) {
            // Update pricing options selected in service
            this.contractEditionService.updateSelectedPricingOptions(this.selectedPricings);
        }

        this.isUnselectedPricingsOpened = false;

        this.refreshAvailablePricingsSections();
    }

    /**
     * Get selected pricing options
     * @returns {PricingOption[]}
     */
    public get selectedPricings(): PricingOption[] {
        return this.pricingsAvailable.options.filter(x => x.selected);
    }

    /**
     * Get unselected pricing options
     * @returns {PricingOption[]}
     */
    public get unselectedPricings(): PricingOption[] {
        return this.pricingsAvailable.options.filter(x => !x.selected);
    }

    /**
     * Group available contract pricings by section
     */
    refreshAvailablePricingsSections() {
        this.availablePricingsSections = [];

        this.pricings.forEach((pricing: ContractPricing) => {
            const availablePricings = this.unselectedPricings.filter(p => p.path[0] === pricing._id);

            this.availablePricingsSections.push({
                pricingSectionName: pricing.value,
                pricings: availablePricings,
            });
        });
    }

    /**
     * Set pricing option as selected
     * @param {string} value - id of pricing option item
     */
    public setPricingSelected(section: PricingSection, pricing: PricingOption) {
        const toSelect = this.pricingsAvailable.options.find(x => x.id === pricing.id);
        const index = section.pricings.findIndex(s => s.label === pricing.label);

        if (toSelect) {
            toSelect.selected = true;

            // Update pricing options selected in service
            this.contractEditionService.updateSelectedPricingOptions(this.selectedPricings);

            this.onContractPricingsChange();

            this.isUnselectedPricingsOpened = false;

            section.pricings.splice(index, 1);
        }
    }

    /**
     * Unselect pricing
     * @param {PricingOption} pricing - pricing to set as unselected
     */
    public unselectPricing(pricing: PricingOption) {
        pricing.selected = false;

        // Remove the pricing in the right section of the available pricings list
        this.refreshAvailablePricingsSections();

        // Update pricing options selected in service
        this.contractEditionService.updateSelectedPricingOptions(this.selectedPricings);
        this.onContractPricingsChange();
    }

    /**
     * @param {PricingOption} pricing
     * @param {ContractPricingItem} item
     * @returns {boolean} true if pricing option is concerned by the pricing item.
     */
    public hasContractPricingItem(pricing: PricingOption, item: ContractPricingItem): boolean {
        return pricing.items.some(x => x.id === item._id);
    }

    /**
     * @param {ContractPricingItem} item
     * @returns {boolean} true if pricing item is in a selected pricing item.
     */
    public isPricingItemInSelected(item: ContractPricingItem): boolean {
        return this.selectedPricings.some(pricing => this.hasContractPricingItem(pricing, item));
    }

    /**
     * Get the value of a pricing item of a contract pricing.
     * @param {PricingOption} pricing
     * @param {ContractPricingItem} item
     * @returns {string|number}
     */
    public getContractPricingValue(pricing: PricingOption, item: ContractPricingItem): string | number {
        const search = pricing.items.find(x => x.id === item._id);
        if (search && typeof search.value !== 'undefined') {
            return search.value;
        }
        return null;
    }

    /**
     * Set the value of a pricing item of a contract pricing.
     * @param {PricingOption} pricing
     * @param {ContractPricingItem} item
     * @param {string} value
     */
    public setContractPricingValue(pricing: PricingOption, item: ContractPricingItem, value: string) {
        const search = pricing.items.find(x => x.id === item._id);
        if (search) {
            if (value === '') {
                value = null;
            }
            search.value = value;
            this.onContractPricingsChange();
        }
    }

    /**
     * Set rate unit.
     *
     * @param value - value comes from HTML select-option which is a string
     */
    public setRateUnit(value: any) {
        const rate = parseFloat(value.value);
        if (!Number.isNaN(rate)) {
            this.priceRates.rate = rate;
            this.contractEditionService.updateUnitRate(rate);
            this.onContractPricingsChange();
        }
    }

    /**
     * Init pricing options by flattening contract pricings
     */
    private initPricingsAvailable() {
        this.pricings.forEach(pricing => {
            const option: PricingOption = {
                label: '',
                path: [],
                items: [],
                id: '',
                selected: false,
            };
            this.flattenDeep(pricing, option);
        });
        this.fillPricingsAvailable();
    }

    /**
     * Recursively flatten contract pricing to create pricing option.
     * Objective is to have all possible options.
     * @param {ContractPricing} pricing - contract pricing at any depth
     * @param {PricingOption} option - pricing option item to increment and clone for child items.
     */
    private flattenDeep(pricing: ContractPricing, option: PricingOption) {
        option.label += ` ${pricing.value}`;
        option.path.push(pricing._id);
        if (pricing.contractData && pricing.contractData.common && pricing.contractData.common.length) {
            pricing.contractData.common.forEach(commonId => {
                const item = this.pricingItems.find(x => x._id === commonId);
                if (item) {
                    option.items.push({
                        id: item._id,
                        value: null,
                    });
                }
            });
        }
        if (pricing.items && pricing.items.length) {
            pricing.items.forEach(item => {
                this.flattenDeep(item, _.cloneDeep(option));
            });
        } else {
            option.id = option.path.join('-');
            this.pricingsAvailable.options.push(option);
        }
    }

    /**
     * Fill pricings available with currently loaded contract values.
     * For each available pricing, find if already defined in contract.
     * If so, set pricing as selected and fill values from contract if exists.
     */
    private fillPricingsAvailable() {
        const unitRate = _.get(this.contract, 'infos.unitRate');
        this.pricingsAvailable.options.forEach(option => {
            if (!Array.isArray(this.contract.pricings)) {
                this.contract.pricings = [];
            }
            const contractValues = this.contract.pricings.find(
                x => x.pathContractPricing.join('-') === option.path.join('-')
            );
            if (contractValues) {
                option.selected = true;
                option.items.forEach(item => {
                    const contractValue = contractValues.values.find(x => x.id === item.id);
                    if (contractValue) {
                        if (unitRate) {
                            // As we reverse conversion (displaying non-converted value), rate to apply is inverse of stored rate.
                            item.value = this.contractEditionService.getPricingItemValue(contractValue, 1 / unitRate);
                        } else {
                            item.value = contractValue.value;
                        }
                    }
                });
            }
        });
    }

    /**
     * Init price rates for the fluid contract.
     * @todo When rate (or similar name) property is stored in contract, set it at init
     */
    private initPriceRates() {
        const unitRate = _.get(this.contract, 'infos.unitRate');
        if (unitRate) {
            this.priceRates.rate = unitRate;
        }
        this.priceRates.items = this.energyService.getFluidPricingRates(this.contract.energyType);
    }

    /**
     * Create most recent version of contract pricings selected and emit to parent component.
     * No save done in this component.
     */
    private onContractPricingsChange() {
        const pricings: PricingSelected[] = this.selectedPricings.map(pricing => {
            /** Get values converted with rate choosen to set base unit for fluids (eg. for elec convert all to c€/kWh) */
            const values: PricingSelectedValue[] = pricing.items.map(item => {
                return {
                    id: item.id,
                    value: this.contractEditionService.getPricingItemValue(item, this.priceRates.rate),
                };
            });
            const obj: PricingSelected = {
                pathContractPricing: pricing.path,
                values,
            };
            return obj;
        });
        this.contractEditionService.updateCommonPricing(pricings);
    }

    toggleUnselectedPricingsList() {
        this.isUnselectedPricingsOpened = !this.isUnselectedPricingsOpened;
    }

    /**
     * Close the available pricings list when click on button
     */
    closeUnselectedPricingsList() {
        this.isUnselectedPricingsOpened = false;
    }

    /**
     * Action to close the available pricings list if click outside the content
     *
     * @param {boolean} event
     */
    offClickAction(event: boolean) {
        if (this.isUnselectedPricingsOpened === true && event === true) {
            this.isUnselectedPricingsOpened = false;
        }
    }
}
