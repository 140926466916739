import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { RrefMergeService } from '../rref-merge.service';

import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { SitePopulated } from 'app/shared/models/site.interface';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { SortFilters } from 'app/shared/services/sort/sort-filter/sort-filter.interface';

@Component({
    selector: 'rref-merge-list',
    templateUrl: './rref-list.component.html',
    styleUrls: ['./rref-list.component.scss'],
})
export class RrefListComponent implements OnInit {
    /** Number of sites to display by batch (at start, then when displaying more, ...) */
    private readonly nbSitesBatch = 10;

    /** Sites to display with routing references. List is filtered with search and paginated */
    public sites: SitePopulated[] = [];

    /** Number of sites to display */
    public nbSitesToDisplay = 10;

    /** Search text of input */
    private searchText = '';

    /** Form control to handle input change */
    public searchFormControl: FormControl;

    /** Boolean that indicates if there is more sites that can be displayed */
    public hasMoreSiteToDisplay = false;

    /** Used by orderby pipe to sort RRefs by energy type */
    public sortFilters: SortFilters = {
        order: 'asc',
        property: {
            name: 'energy_and_reference',
            type: 'string',
            paths: ['energyType', 'reference'],
        },
    };

    constructor(private rrefMergeService: RrefMergeService, private sitesService: SitesService) {}

    ngOnInit() {
        // Subscribe to sites list change
        this.rrefMergeService.sites$.subscribe({
            next: sites => {
                if (!sites) {
                    return;
                }
                // Reset nb sites to display
                this.nbSitesToDisplay = this.nbSitesBatch;
                // Refresh sites list
                this.refreshSitesDisplay(sites);
            },
        });
        // Init form control
        this.searchFormControl = new FormControl('');
        // Subscribe to form control change. Use debounce time
        this.searchFormControl.valueChanges
            .pipe(
                debounceTime(300),
                // allow the users to correct themselves before the 300ms if necessary to avoid a search
                distinctUntilChanged()
            )
            .subscribe({
                next: newSearch => {
                    // Reset nb sites to display
                    this.nbSitesToDisplay = this.nbSitesBatch;
                    // Refresh sites list with search
                    this.searchText = newSearch;
                    this.refreshSitesDisplay(this.rrefMergeService.sites$.value);
                },
            });
    }

    /**
     * Display more site based on site increment value
     */
    public displayMoreSites() {
        this.nbSitesToDisplay += this.nbSitesBatch;
        this.refreshSitesDisplay(this.rrefMergeService.sites$.value);
    }

    /**
     * Refresh sites to display
     * @param {SitePopulated[]} sites - full sites list to use
     */
    private refreshSitesDisplay(sites: SitePopulated[]) {
        const sitesList: SitePopulated[] = [];
        const searchText = this.searchText;
        this.hasMoreSiteToDisplay = true;
        for (let i = 0, len = sites.length; sitesList.length < this.nbSitesToDisplay && i < len; i++) {
            if (this.sitesService.filterSiteOnSearch(sites[i], searchText)) {
                sitesList.push(sites[i]);
            }
            // If we reach the end of all sites and list is still incomplete,
            // then it means there no more site to be displayed
            if (i === len - 1 && sitesList.length < this.nbSitesToDisplay) {
                this.hasMoreSiteToDisplay = false;
            }
        }
        this.sites = sitesList;
    }

    /**
     * When start dragging a routing reference, store routing reference data in event as plain.
     * (events can't store json)
     * @param {DragEvent} event
     * @param {RoutingReference} routingReference
     */
    public onDragStart(event: DragEvent, routingReference: RoutingReference) {
        const data = routingReference;
        const toTransfer = JSON.stringify(data);
        event.dataTransfer.setData('text/plain', toTransfer);
        event.dataTransfer.effectAllowed = 'copy';
    }
}
