import { Component, OnInit, ViewChild } from '@angular/core';
import * as clone from 'clone';
import * as moment from 'moment';

// services
import { BillsService } from 'app/shared/services/bills/bills.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { DjuService } from 'app/shared/services/dju/dju.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { HomeService } from '../../home/home.service';
import { CompareService } from './compare.service';

// interfaces
import { QueryFilterRoutingRef } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { Dju } from 'app/shared/models/dju.interface';
import { Marker } from 'app/shared/models/marker.interface';
import { CompareBarChartData, CompareBarChartProperties, MonthData, YearData } from './compare.interface';

// components
import { ChartBarToggleComponent } from 'app/shared/components/charts/chart-bar-toggle/chart-bar-toggle.component';

@Component({
    selector: 'ga-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.scss'],
    providers: [HomeService, CompareService],
})
export class CompareComponent implements OnInit {
    // Map options
    mapOptions = {
        zoom: 8,
        lat: 49.894067,
        lng: 2.2957529999999906,
        height: 250,
    };

    markers: Marker[] = [];

    isLoading = false;
    filters: QueryFilterRoutingRef = null;

    // Filters
    markersFilter: QueryFilterRoutingRef = null;
    tilesFilter: QueryFilterRoutingRef = null;
    monthChartFilter: QueryFilterRoutingRef = null;
    yearChartFilter: QueryFilterRoutingRef = null;
    yearOverYearFilter: QueryFilterRoutingRef = null;

    totalConso = 0;
    totalCosts: {
        totalHTVA: number;
        totalTTC: number;
    } = {
        totalHTVA: 0,
        totalTTC: 0,
    };

    // bar chart year
    barProperties: CompareBarChartProperties = {
        isLoading: false,
        datasets: [],
        labels: [],
        colors: [],
        width: 1900,
        minHeight: 340,
        maxHeight: 340,
        options: [],
        legend: [],
        toggleTitles: [],
        downloadFileNames: ['', 'Coût mensuel HTVA sur la période (€)', 'Coût mensuel TTC sur la période (€)'],
        units: { bar: [] },
    };

    // bar chart year
    barYearProperties: CompareBarChartProperties = {
        isDjuActivated: true,
        isLoading: false,
        datasets: [],
        labels: [],
        colors: [],
        width: 810,
        minHeight: 340,
        maxHeight: 340,
        options: [],
        legend: [],
        toggleTitles: [],
        downloadFileNames: ['', 'Coût mensuel HTVA sur la période (€)', 'Coût mensuel TTC sur la période (€)'],
        units: { bar: [], line: [] },
    };

    barYearOverYearProperties: CompareBarChartProperties = {
        isDjuActivated: true,
        isLoading: false,
        datasets: [],
        labels: [],
        colors: [],
        width: 810,
        minHeight: 340,
        maxHeight: 340,
        options: [],
        legend: [],
        toggleTitles: [],
        downloadFileNames: ['', 'Coût mensuel HTVA sur la période (€)', 'Coût mensuel TTC sur la période (€)'],
        units: { bar: [], line: [] },
    };

    yearDatas: YearData[] = null;
    monthDatas: MonthData[] = null;
    yearDjus: Dju[] = null;
    monthDjus: Dju[] = null;
    yearOverYearDjus: Dju[] = null;

    @ViewChild('yearChart', { static: false }) yearChart: ChartBarToggleComponent;
    @ViewChild('yearOverYearChart', { static: false }) yearOverYearChart: ChartBarToggleComponent;

    constructor(
        private chartService: ChartService,
        private homeService: HomeService,
        private compareService: CompareService,
        private tilesService: TilesService,
        private djuService: DjuService,
        private billService: BillsService,
        private filterService: FilterService
    ) {}

    ngOnInit() {}

    public initDatas() {
        // Datas
        this.yearDatas = null;
        this.monthDatas = null;

        // Graph month
        this.barProperties.datasets = [[], [], []];
        this.barProperties.units = { bar: ['Kwhep', '€', '€'] };

        this.initYearGraph();
        this.initYearOverYearGraph();
    }

    public initYearGraph() {
        // Graph Year
        this.barYearProperties.datasets = [[], [], []];
        this.barYearProperties.units = { bar: ['Kwhep', '€', '€'], line: ['DJU', 'DJU', 'DJU'] };
    }

    public initYearOverYearGraph() {
        // Graph Year over Year
        this.barYearOverYearProperties.datasets = [[], [], []];
        this.barYearOverYearProperties.units = { bar: ['Kwhep', '€', '€'], line: ['DJU', 'DJU', 'DJU'] };
    }

    public handleMarkers(filters: QueryFilterRoutingRef) {
        this.compareService
            .handleMarkers(this.markersFilter)
            .then(markers => {
                if (this.filters === filters) {
                    this.markers = markers;
                }
            })
            .catch(error => {
                this.markers = [];
            });
    }

    public isLoadingData(property): boolean {
        return Boolean(this[property].isLoading);
    }

    public hasData(property): boolean {
        return Boolean(this[property].datasets && this[property].datasets.length && this[property].datasets[0].length);
    }

    /**
     * Start the research with new criterias
     * @param {QueryFilter} filters from the filter component
     */
    public search(filters: QueryFilterRoutingRef) {
        this.barYearProperties.isLoading = true;
        this.barProperties.isLoading = true;
        this.barYearOverYearProperties.isLoading = true;
        this.filters = filters;

        this.initDatas();
        this.initFilters(filters).then(
            () => {
                // If the last request is the current request
                if (this.filters === filters) {
                    // Get markers for the map
                    this.handleMarkers(filters);

                    return this.grabData().then(() => {
                        // If the last request is the current one
                        if (this.filters === filters) {
                            // Grab datas & generate graphs
                            this.handleTiles();
                            this.handleBarChart();
                            this.handleYearBarChart(this.barYearProperties.isDjuActivated);
                            this.handleYearOverYearBarChart(this.barYearOverYearProperties.isDjuActivated);
                        }
                    });
                }
            },
            err => {
                this.barYearProperties.isLoading = false;
                this.barProperties.isLoading = false;
                this.barYearOverYearProperties.isLoading = false;
            }
        );
    }

    initFilters(filters: QueryFilterRoutingRef) {
        // init all filters
        this.markersFilter = Object.assign({}, filters);
        this.tilesFilter = Object.assign({}, filters);
        this.monthChartFilter = Object.assign({}, filters);
        this.yearChartFilter = Object.assign({}, filters);
        this.yearOverYearFilter = Object.assign({}, filters);

        // Get limits for each filters

        return Promise.all([
            this.compareService
                .getThreeYearsFiltersLimitDates(this.markersFilter)
                .then(dateLimits => {
                    this.markersFilter.dateStart = dateLimits.dateStart;
                    this.markersFilter.dateEnd = dateLimits.dateEnd;
                })
                .catch(error => {
                    this.markersFilter.dateStart = null;
                    this.markersFilter.dateEnd = null;
                }),
            this.compareService
                .getThreeYearsFiltersLimitDates(this.monthChartFilter)
                .then(dateLimits => {
                    this.monthChartFilter.dateStart = dateLimits.dateStart;
                    this.monthChartFilter.dateEnd = dateLimits.dateEnd;
                })
                .catch(error => {
                    this.monthChartFilter.dateStart = null;
                    this.monthChartFilter.dateEnd = null;
                }),
            this.compareService
                .getAvailableYearsFiltersLimitDates()
                .then(dateLimits => {
                    this.yearChartFilter.dateStart = dateLimits.dateStart;
                    this.yearChartFilter.dateEnd = dateLimits.dateEnd;
                })
                .catch(error => {
                    this.yearChartFilter.dateStart = null;
                    this.yearChartFilter.dateEnd = null;
                }),
            this.compareService
                .getTwentyFourMonthFiltersLimitDates(this.yearOverYearFilter)
                .then(dateLimits => {
                    this.yearOverYearFilter.dateStart = dateLimits.dateStart;
                    this.yearOverYearFilter.dateEnd = dateLimits.dateEnd;
                })
                .catch(error => {
                    this.yearOverYearFilter.dateStart = null;
                    this.yearOverYearFilter.dateEnd = null;
                }),
        ]);
    }

    grabData() {
        return Promise.all([
            this.homeService
                .getYearlyGlobalEnergyRepartition(this.yearChartFilter)
                .then(response => {
                    this.yearDatas = response.data;
                })
                .catch(error => {
                    this.yearDatas = [];
                }),
            this.homeService
                .getMonthlyGlobalEnergyRepartition(this.monthChartFilter)
                .then(response => {
                    this.monthDatas = response.data;
                })
                .catch(error => {
                    this.monthDatas = [];
                }),
            this.djuService
                .getDJUs(this.yearChartFilter)
                .then(response => {
                    this.monthDjus = response;
                    this.yearDjus = this.djuService.getYearDju(this.monthDjus);
                    this.yearOverYearDjus = this.monthDjus;
                })
                .catch(error => {
                    this.monthDjus = [];
                }),
        ]);
    }

    /**
     * Filter month data array with data between filter date start and date end
     * @param {MonthData[]} data
     * @param {QueryFilter} filters
     * @returns {MonthData[]} data filtered
     */
    filterMonthData(data: MonthData[], filters: QueryFilterRoutingRef): MonthData[] {
        return data.filter(item => {
            const currentDate = moment.utc();
            currentDate.month(item.month);
            currentDate.year(item.year);
            currentDate.startOf('month');

            const filterDateStart = moment.utc(filters.dateStart);
            const filterDateEnd = moment.utc(filters.dateEnd);
            return currentDate.isSameOrBefore(filterDateEnd) && currentDate.isSameOrAfter(filterDateStart);
        });
    }

    /**
     * Filter year data array with data between filter date start and date end
     * @param {YearData[]} data
     * @param {QueryFilter} filters
     * @returns {YearData[]} data filtered
     */
    filterYearData(data: YearData[], filters: QueryFilterRoutingRef): YearData[] {
        return data.filter(item => {
            const currentDate = moment.utc();
            currentDate.year(item.year);
            currentDate.startOf('year');

            const filterDateStart = moment.utc(filters.dateStart);
            const filterDateEnd = moment.utc(filters.dateEnd);
            return currentDate.isSameOrBefore(filterDateEnd) && currentDate.isSameOrAfter(filterDateStart);
        });
    }

    /**
     * Create the bar chart with year over year with data loading.
     * @param {boolean} isDjuActivated - true to activate the display of DJU, false otherwise
     */
    handleYearOverYearBarChart(isDjuActivated: boolean) {
        const data = this.filterMonthData(this.monthDatas, this.yearOverYearFilter);
        const yearOverYearFormatedConsumption = this.compareService.formatOneYearOverYearData(
            data,
            this.yearOverYearFilter,
            this.yearOverYearDjus
        );
        this.populateBarDataset(this.barYearOverYearProperties, yearOverYearFormatedConsumption, true);

        // Reload config for legend and labels
        this.getYearOverYearBarChartConfig(true);

        // Handle chart display
        this.onBarYearOverYearDjuChange(isDjuActivated);

        // End of the barChart loading
        this.barYearOverYearProperties.isLoading = false;
    }

    /**
     * Handle year over year chart bar dju change
     * @param {boolean} isDjuActivated - true to enable DJU, false otherwise
     */
    onBarYearOverYearDjuChange(isDjuActivated: boolean) {
        isDjuActivated = Boolean(isDjuActivated && this.monthDjus && this.monthDjus.length);
        this.barYearOverYearProperties.isDjuActivated = isDjuActivated;

        const djuLabel = 'djus';
        const kwhDjuButton = 'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )';
        const energies = this.yearOverYearFilter.energies ? this.yearOverYearFilter.energies.split(',') : [];
        const legend = this.getChartLegends(energies);
        const legends = [legend, legend, legend];
        if (isDjuActivated) {
            legends.push(legend);
        }

        this.djuService.toggleDjuInDatasetAndChart(
            isDjuActivated,
            this.barYearOverYearProperties,
            djuLabel,
            legends,
            kwhDjuButton,
            this.yearOverYearChart
        );
    }

    /**
     * Handle year chart bar dju change
     * @param {boolean} isDjuActivated - true to enable DJU, false otherwise
     */
    onBarYearDjuChange(isDjuActivated: boolean) {
        isDjuActivated = Boolean(isDjuActivated && this.yearDjus && this.yearDjus.length);
        this.barYearProperties.isDjuActivated = isDjuActivated;

        const djuLabel = 'djus';
        const kwhDjuButton = 'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )';
        const energies = this.yearChartFilter.energies ? this.yearChartFilter.energies.split(',') : [];
        const legend = this.getChartLegends(energies);
        const legends = [legend, legend, legend];
        if (isDjuActivated) {
            legends.push(legend);
        }

        this.djuService.toggleDjuInDatasetAndChart(
            isDjuActivated,
            this.barYearProperties,
            djuLabel,
            legends,
            kwhDjuButton,
            this.yearChart
        );
    }

    /**
     * Create the bar chart with year with data loading.
     * @param filters from the filter component for the search query.
     */
    handleYearBarChart(isDjuActivated: boolean) {
        const data = this.filterYearData(this.yearDatas, this.yearChartFilter);

        const yearlyFormatedConsumption = this.compareService.formatYearBarChartData(
            data,
            this.yearChartFilter,
            this.yearDjus
        );

        this.populateBarDataset(this.barYearProperties, yearlyFormatedConsumption, true);
        // Reload config for legend and labels
        this.getYearlyBarChartConfig(true);

        // Handle chart display
        this.onBarYearDjuChange(isDjuActivated);

        // End of the barChart loading
        this.barYearProperties.isLoading = false;
    }

    /**
     * Create the bar chart with data loading.
     * @param filters from the filter component for the search query.
     */
    handleBarChart() {
        const data = this.filterMonthData(this.monthDatas, this.monthChartFilter);
        const years = this.compareService.getYearsInMonthData(data); // chronological order [2016, 2017, 2018]

        // Response goes from dateEnd to dateStart, cut it into years array [ [2016], [2017], [2018] ]
        const yearlyFormatedConsumption = this.compareService.formateResponseInYearsArray(data);

        // For each year, format to monthly consumption. Stack = 0 on dateStart data, stack = n on dateEnd data
        yearlyFormatedConsumption.forEach((year, index) => {
            const monthlyFormatedConsumption = this.compareService.formatOneYearData(
                year,
                index,
                this.monthChartFilter
            );
            this.populateBarDataset(this.barProperties, monthlyFormatedConsumption);
        });

        // Reload config for legend and labels
        this.getMonthlyBarChartConfig(years);

        // End of the barChart loading
        this.barProperties.isLoading = false;
    }

    /**
     * Create the tiles.
     * @param filters from the filter component for the search query.
     */
    handleTiles() {
        const data = this.filterYearData(this.yearDatas, this.tilesFilter);
        this.totalConso = this.compareService.getTotalConso(data, this.tilesFilter);
        this.totalCosts = this.compareService.getTotalCost(data);
    }

    /**
     * Populate the barProperties data property
     * @param {CompareBarChartProperties} properties
     * @param {CompareBarChartData} dataOfOneYear all the datas concerned by one year.
     * @param {boolean} isDjuActivated
     */
    populateBarDataset(
        properties: CompareBarChartProperties,
        dataOfOneYear: CompareBarChartData,
        isDjuActivated?: boolean
    ) {
        // push the data object of each fluid
        for (let indexEnergy = 0; indexEnergy < dataOfOneYear.data.chart[0].length; indexEnergy++) {
            properties.datasets[0].push(dataOfOneYear.data.chart[0][indexEnergy]); // Consommation kWhep
            properties.datasets[1].push(dataOfOneYear.data.chart[1][indexEnergy]); // HTVA
            properties.datasets[2].push(dataOfOneYear.data.chart[2][indexEnergy]); // TTC
        }

        if (dataOfOneYear.labels && dataOfOneYear.labels.length) {
            properties.labels = dataOfOneYear.labels;
        }

        if (isDjuActivated) {
            // Dju data are always first in datasets
            const dataDjuPonderation = this.billService.getDjuPonderationByEnergy(properties.datasets[0]);
            properties.datasets.splice(1, 0, dataDjuPonderation);
        }
    }

    /**
     * Get and set config for the monthly comparison chart
     * @param {number[]} years - years to display
     */
    getMonthlyBarChartConfig(years: number[]) {
        const energies = this.monthChartFilter.energies ? this.monthChartFilter.energies.split(',') : [];
        const legend = this.getChartLegends(energies);

        // because of the toggle, we need to put three time the same legend and colors here
        this.barProperties.legend = [legend, legend, legend];
        this.barProperties.colors = this.chartService.getStackColors(this.barProperties);

        // Set unit for each tab of toggles
        this.barProperties.units.bar[0] = this.getCurrentUnit();
        this.barProperties.toggleTitles = [
            { name: 'CONSOMMATION (' + this.getCurrentUnit() + ')', display: true },
            { name: 'HTVA (€)', display: true },
            { name: 'TTC (€)', display: true },
        ];
        this.chartService.populateBarLineDatasetsWithUnits(this.barProperties.datasets, this.barProperties.units);

        this.barProperties.labels = this.compareService.getGraphAbscisseLabels(
            this.monthChartFilter.dateStart,
            this.monthChartFilter.dateEnd
        );

        const options = this.chartService.getConfig('groupable-stacked-bar', {
            tooltips: this.chartService.getTooltipHTMLConfig('monthly', null, years, this.barProperties.datasets),
        });
        this.barProperties.options = [options, options, options];
    }

    /**
     * Get and set yearly bar chart config
     * @param {boolean} isDjuActivated - true if DJU activated, false otherwise
     */
    getYearlyBarChartConfig(isDjuActivated: boolean) {
        const energies = this.yearChartFilter.energies ? this.yearChartFilter.energies.split(',') : [];
        const legend = this.getChartLegends(energies);
        const years = this.compareService.getGraphYears(this.yearChartFilter.dateStart, this.yearChartFilter.dateEnd);

        // because of the toggle, we need to put three time the same legend and colors here
        this.barYearProperties.legend = [legend, legend, legend];
        this.barYearProperties.colors = this.chartService.getStackColors(this.barYearProperties);

        // Set unit for each tab of toggles
        this.barYearProperties.units.bar[0] = this.getCurrentUnit();
        this.barYearProperties.toggleTitles = [
            { name: 'CONSOMMATION (' + this.getCurrentUnit() + ')', display: true },
            { name: 'HTVA (€)', display: true },
            { name: 'TTC (€)', display: true },
        ];

        this.barYearProperties.labels = this.compareService.getGraphYears(
            this.yearChartFilter.dateStart,
            this.yearChartFilter.dateEnd
        );

        // const options = this.chartService.getConfig('groupable-stacked-bar',
        const options = this.chartService.getConfig(
            isDjuActivated ? 'mixed-groupable-stacked-bar' : 'groupable-stacked-bar',
            { tooltips: this.chartService.getTooltipHTMLConfig('yearly', null, years, this.barYearProperties.datasets) }
        );
        this.barYearProperties.options = [options, options, options];

        if (isDjuActivated && (this.getCurrentUnit() === 'kWhep' || this.getCurrentUnit() === 'kWh')) {
            this.barYearProperties.legend.splice(1, 0, this.getChartLegends(energies));
            // this.barYearProperties.colors.splice(1, 0, colors.slice(1));
            this.barYearProperties.units.bar.splice(1, 0, this.getCurrentUnit() + '/DJU');
            this.barYearProperties.units.line.splice(1, 0, 'DJU');
            this.barYearProperties.options.splice(
                1,
                0,
                this.chartService.getConfig('bar', {
                    tooltips: this.chartService.getTooltipHTMLConfig(
                        'ng-chart-bar-line',
                        this.getCurrentUnit() + '/DJU'
                    ),
                })
            );
            this.barYearProperties.toggleTitles.splice(1, 0, {
                name: 'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )',
                display: true,
            });
            this.barYearProperties.downloadFileNames.splice(
                1,
                0,
                'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )'
            );
        }

        this.chartService.populateBarLineDatasetsWithUnits(
            this.barYearProperties.datasets,
            this.barYearProperties.units
        );
    }

    /**
     * Get and set year over year bar chart config
     * @param {boolean} isDjuActivated - true if DJU activated, false otherwise
     */
    getYearOverYearBarChartConfig(isDjuActivated: boolean) {
        // Get exactly one year before dateEnd

        const energies = this.yearOverYearFilter.energies ? this.yearOverYearFilter.energies.split(',') : [];
        const legend = this.getChartLegends(energies);
        const years = this.compareService.getGraphYears(
            this.yearOverYearFilter.dateStart,
            this.yearOverYearFilter.dateEnd
        );

        // because of the toggle, we need to put three time the same legend and colors here
        this.barYearOverYearProperties.legend = [legend, legend, legend];
        this.barYearOverYearProperties.colors = this.chartService.getStackColors(this.barYearOverYearProperties);

        // Set unit for each tab of toggles
        this.barYearOverYearProperties.units.bar[0] = this.getCurrentUnit();
        this.barYearOverYearProperties.toggleTitles = [
            { name: 'CONSOMMATION (' + this.getCurrentUnit() + ')', display: true },
            { name: 'HTVA (€)', display: true },
            { name: 'TTC (€)', display: true },
        ];

        const options = this.chartService.getConfig(
            isDjuActivated ? 'mixed-groupable-stacked-bar' : 'groupable-stacked-bar',
            {
                tooltips: this.chartService.getTooltipHTMLConfig(
                    'yearOverYear',
                    null,
                    years,
                    this.barYearOverYearProperties.datasets
                ),
            }
        );
        this.barYearOverYearProperties.options = [options, options, options];

        if (isDjuActivated && (this.getCurrentUnit() === 'kWhep' || this.getCurrentUnit() === 'kWh')) {
            this.barYearOverYearProperties.legend.splice(1, 0, this.getChartLegends(energies));
            // this.barYearOverYearProperties.colors.splice(1, 0, colors.slice(1));
            this.barYearOverYearProperties.units.bar.splice(1, 0, this.getCurrentUnit() + '/DJU');
            this.barYearOverYearProperties.units.line.splice(1, 0, 'DJU');
            this.barYearOverYearProperties.options.splice(
                1,
                0,
                this.chartService.getConfig('bar', {
                    tooltips: this.chartService.getTooltipHTMLConfig(
                        'ng-chart-bar-line',
                        this.getCurrentUnit() + '/DJU'
                    ),
                })
            );
            this.barYearOverYearProperties.toggleTitles.splice(1, 0, {
                name: 'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )',
                display: true,
            });
            this.barYearOverYearProperties.downloadFileNames.splice(
                1,
                0,
                'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )'
            );
        }

        this.chartService.populateBarLineDatasetsWithUnits(
            this.barYearOverYearProperties.datasets,
            this.barYearOverYearProperties.units
        );
    }

    public getCurrentUnit(): string {
        return this.filters && this.filters.energies && this.filters.energies.split(',').length > 1 ? 'kWhep' : 'kWh';
    }

    getTotalConso() {
        return this.totalConso;
    }

    /**
     * Get total cost HTML display inline
     */
    getTotalCost(): string {
        const totalHT = this.tilesService.getNumberToDisplay(this.totalCosts.totalHTVA, '€');
        const totalTTC = this.tilesService.getNumberToDisplay(this.totalCosts.totalTTC, '€');
        return `<b>${totalHT}</b> € HTVA - <b>${totalTTC}</b> € TTC`;
    }

    getChartLegends(labels: string[]) {
        if (!labels) {
            return [];
        }

        return labels.map(label => this.chartService.getLegend(label));
    }

    getExportExcelUrl(type: string) {
        if (
            type &&
            this.monthChartFilter &&
            this.monthChartFilter.energies &&
            this.yearOverYearFilter &&
            this.yearChartFilter
        ) {
            const filters = clone(this.monthChartFilter);

            switch (type) {
                case 'month':
                    filters.dateStart = this.monthChartFilter.dateStart;
                    filters.dateEnd = this.monthChartFilter.dateEnd;
                    break;
                case 'yearonyear':
                    filters.dateStart = this.yearOverYearFilter.dateStart;
                    filters.dateEnd = this.yearOverYearFilter.dateEnd;
                    filters.dju = this.barYearOverYearProperties.isDjuActivated;
                    break;
                case 'year':
                    filters.dateStart = this.yearChartFilter.dateStart;
                    filters.dateEnd = this.yearChartFilter.dateEnd;
                    filters.dju = this.barYearProperties.isDjuActivated;
                    break;
                default:
                    return null;
            }

            const query = this.filterService.formatQueryToUrl(filters);
            const url = `/api/export/excel/compare-years/${type}${query}`;

            return url;
        }
        return null;
    }

    getExportName(txt: string) {
        const date = moment()
            .format('YYYYMMDD')
            .toString();
        return `${txt}-${date}.xlsx`;
    }
}
