import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';
import { SessionService } from './session.service';

@Injectable()
export class UserSessionService extends PageService {
    private userSession: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private sessionService: SessionService, public apiService: ApiService) {
        super(apiService);
        const user = this.sessionService.getUser();
        this.userSession.next(user);
    }

    public getUserSession(): BehaviorSubject<any> {
        return this.userSession;
    }

    /**
     * Refresh user in session with most recent values.
     * Only update some fields
     */
    async refreshUserSession(): Promise<void> {
        const userSession = this.sessionService.getUser();
        const res = await this.get(`/api/users/crud/${userSession._id}`);

        const user = res.data;
        /**
         * Don't update all fields as this route return more fields than the auth route
         */
        userSession.name = user.name;
        userSession.firstname = user.firstname;
        userSession.img = user.img ? user.img : null;
        userSession.type = user.type;

        this.sessionService.setUser(userSession);
        this.userSession.next(userSession);
    }

    /**
     * Logout current user
     * @returns {Promise<{ logoutDone: boolean }>}
     */
    async logout(): Promise<{ logoutDone: boolean }> {
        const response = await this.post('/api/auth/logout', {});
        return response.data;
    }
}
