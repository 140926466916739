import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AdminCompanyService } from '../../pages/admin/company/company.service';
import { AdminIdentificationPatternService } from '../../pages/admin/identification-pattern/identification-pattern.service';
import { FixRegexPropertyService } from '../../pages/fix-regex-property/fix-regex-property.service';
import { AlertsService } from './alerts/alerts.service';
import { BillsListService } from './bills-list/bills-list.service';
import { BillsCompletenessService } from './bills/bills-completeness.service';
import { BillsControlService } from './bills/bills-control.service';
import { BillsVerificationsService } from './bills/bills-verifications.service';
import { BillsService } from './bills/bills.service';
import { CampaignsService } from './campaigns/campaigns.service';
import { ChartService } from './chart/chart.service';
import { ColorService } from './color/color.service';
import { CompaniesService } from './companies/companies.service';
import { CompletenessService } from './completeness/completeness.service';
import { ConfigService } from './config/config.service';
import { ContractsService } from './contracts/contracts.service';
import { ContractualDataService } from './contractual-data/contractual-data.service';
import { DjuService } from './dju/dju.service';
import { EnergyDataService } from './energy/energy-data.service';
import { EnergyService } from './energy/energy.service';
import { ExportService } from './export/export.service';
import { ExternalProvidersService } from './external-providers/external-providers.service';
import { FeatureToggleService } from './feature-toggle/feature-toggle.service';
import { FileStorageService } from './files/file-storage.service';
import { FilesTypeService } from './files/files-type.service';
import { UploadService } from './files/upload.service';
import { FilterService } from './filter/filter.service';
import { IdentificationPatternService } from './identification-pattern/identification-pattern.service';
import { KeyDataService } from './key-data/key-data.service';
import { LoadCurveService } from './load-curve/load-curve.service';
import { MapService } from './map/map.service';
import { MeteoService } from './meteo/meteo.service';
import { NotificationsService } from './notifications/notifications.service';
import { PatternService } from './pattern/pattern.service';
import { PreloaderService } from './preloader/preloader.service';
import { ProfilesService } from './profiles/profiles.service';
import { RegexService } from './regex/regex.service';
import { RightsService } from './rights/rights.service';
import { RoutingReferencesService } from './routing-references/routing-references.service';
import { SchedulesService } from './schedules/schedules.service';
import { SessionService } from './session/session.service';
import { UserSessionService } from './session/user-session.service';
import { LegacyService } from './sites/legacy.service';
import { PropertiesService } from './sites/properties.service';
import { QuestionService } from './sites/question.service';
import { SitesService } from './sites/sites.service';
import { SortFilterService } from './sort/sort-filter/sort-filter.service';
import { SortService } from './sort/sort.service';
import { SpinnerService } from './spinner/spinner.service';
import { ThemesService } from './themes/themes.service';
import { TilesService } from './tiles/tiles.service';
import { TitleService } from './title/title.service';
import { TranslateService } from './translate/translate.service';
import { EngineerService } from './users/engineer.service';
import { UsersService } from './users/users.service';
import { TextService } from './utils/text.service';
import { UtilsService } from './utils/utils.service';
import { FuelCardsService } from './vehicles/fuelCards.service';
import { VehiclesService } from './vehicles/vehicles.service';
import { WarrantService } from './warrants/warrant.service';

const services = [
    ConfigService,
    ThemesService,
    PreloaderService,
    SpinnerService,
    TitleService,
    ChartService,
    MapService,
    SessionService,
    BillsService,
    ColorService,
    TilesService,
    ContractsService,
    EnergyService,
    RightsService,
    CompaniesService,
    FixRegexPropertyService,
    UtilsService,
    PatternService,
    RegexService,
    AdminCompanyService,
    AdminIdentificationPatternService,
    IdentificationPatternService,
    QuestionService,
    SitesService,
    FilesTypeService,
    RoutingReferencesService,
    NotificationsService,
    TranslateService,
    PropertiesService,
    UserSessionService,
    AlertsService,
    VehiclesService,
    FuelCardsService,
    EnergyDataService,
    DjuService,
    UsersService,
    FilterService,
    ExportService,
    LegacyService,
    UploadService,
    FileStorageService,
    CampaignsService,
    EngineerService,
    BillsListService,
    CompletenessService,
    BillsControlService,
    LoadCurveService,
    BillsVerificationsService,
    KeyDataService,
    FeatureToggleService,
    MeteoService,
    SortService,
    SortFilterService,
    TextService,
    BillsCompletenessService,
    ExternalProvidersService,
    SchedulesService,
    ContractualDataService,
    WarrantService,
    FileStorageService,
    ProfilesService,
];

@NgModule({
    imports: [],
    providers: services,
    declarations: [],
    exports: [],
})
export class ServicesModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: ServicesModule
    ) {}
}
