// tslint:disable:no-access-missing-member
import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuestionComponent } from '../question/question.component';

@Component({
    selector: 'ga-question-select-choices-bloc',
    templateUrl: './select-choices-bloc.component.html',
    styleUrls: ['./select-choices-bloc.component.scss'],
})
/**
 * Component to create a question with pre-determined answers with images. Can be one or multiple choices.
 * @Input data : User data at the format [{key: string, value: string}]
 * @Input config : Question from the config file with properties attribute at the format {key: string, multiple: boolean, values: [{label: string, value: string, img: string}]}
 * @Ouput valueChange : event that gives the data each time it's changed by the user at the format {key: string, value: string}
 */
export class SelectChoicesBlocComponent extends QuestionComponent implements OnInit {
    // If multiple answers are allowed by the config file
    multiple = false;

    // Values entered by the user.
    values: string[] = [];

    // Choices bloc where there is no img or the image loading failed
    imgErrors: string[] = [];

    // Bloc mouse over
    mouseover = '';

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.config.properties.multiple) {
            this.multiple = true;
        }

        // Get then Set values from db
        const dbValues = this.data.find(x => x.key === this.config.properties.key);
        this.values = dbValues && dbValues.value ? dbValues.value.split(',') : [];
        this.updateValues();
    }

    getClass(elem) {
        return this.values.find(x => x === elem.value) ? ' active' : ' inactive';
    }

    onChanges(val): void {
        if (!this.multiple) {
            this.values[0] = val;
        } else {
            const search = this.values.find(x => x === val);
            if (search) {
                this.values = this.values.filter(i => i !== search);
            } else {
                this.values.push(val);
            }
        }
        this.updateValues();
    }

    private updateValues() {
        const empty = this.values.length === 0;
        this.valueChange.emit({
            key: this.config.properties.key,
            value: !empty ? this.values.join(',') : null,
        });
    }

    onMouseOver(value) {
        this.mouseover = value;
    }

    onMouseOut(value) {
        if (this.mouseover === value) {
            this.mouseover = '';
        }
    }

    imgError(img) {
        this.imgErrors.push(img);
    }

    hasImgError(img) {
        return !this.imgErrors.find(x => x === img);
    }
}
