import { Injectable } from '@angular/core';
import { environment } from 'app/../environments/environment';
import * as _ from 'lodash';

/**
 * Service to handle activation of a feature on the plateform.
 * Currently only depend on the environment.
 */
@Injectable()
export class FeatureToggleService {
    /**
     * Is feature enable for current instance
     * @param feature - feature key
     */
    isEnabled(feature: string): boolean {
        const features = environment.features;

        if (features['*']) {
            return true;
        }

        if (!feature) {
            return true;
        }

        const enabled = _.get(features, feature, false);

        return Boolean(enabled);
    }
}
