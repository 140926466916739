import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

// services
import { ElecPdlViewService } from 'app/pages/energy/pdl-view/elec/elec.service';
import { SitesListService } from 'app/pages/energy/sites-list/sites-list.service';
import { BillsService } from 'app/shared/services/bills/bills.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { DjuService } from 'app/shared/services/dju/dju.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { ElecPowerReachService } from './elec-power-reach.service';

// interfaces
import { FluidViewToggleChartProperties } from 'app/pages/energy/pdl-view/pdl-view.interface';
import { ChartSerieLight } from 'app/shared/models/charts/chart-serie.interface';
import { Dju } from 'app/shared/models/dju.interface';
import { DatePeriod, ElecMonthlyBill, ElecYearlyBill } from 'app/shared/models/fluid-bills.interface';
import { ElecMonthlyCostTableBill, ElecYearlyCostTableBill } from 'app/shared/models/fluid-tables.interface';
import { TileInterface } from 'app/shared/models/tile.interface';

// components
import { ChartBarToggleComponent } from 'app/shared/components/charts/chart-bar-toggle/chart-bar-toggle.component';
import { BarChartProperties, ChartDataSetsGA, ChartLegendGA } from 'app/shared/models/charts/charts.interface';
import { ChartColor } from 'app/shared/models/colors.interface';
import { ElecPowerPeriod } from './elec.interface';

interface Loading {
    consoChart: boolean;
    costChart: boolean;
    powerChart: boolean;
    yearTable: boolean;
    monthTable: boolean;
    site: boolean;
}

@Component({
    selector: 'ga-elec-pdl-view',
    templateUrl: './elec.component.html',
    styleUrls: ['./elec.component.scss'],
    providers: [ElecPdlViewService, SitesListService, ElecPowerReachService],
})
export class ElecPdlViewComponent implements OnInit {
    powerChartLabelTop: any[];
    powerChartLabelBottom: any[];

    totalTTC = 0;
    totalHT = 0;

    consoGraph: any = {
        isDjuActivated: true,
    };

    monthData: ElecMonthlyBill[] = [];
    djus: Dju[] = [];

    /** Default config for power reached/subscribed chart */
    private get defaultPowerChartConfig(): BarChartProperties {
        return {
            title: 'Puissances atteintes',
            datasets: [],
            labels: [],
            colors: [],
            options: {},
            legends: [],
        };
    }
    /** Properties for power reached/subscribed chart */
    public powerChartProperties: BarChartProperties = this.defaultPowerChartConfig;

    /** Default config for cost chart */
    private get defaultCostChartConfig(): FluidViewToggleChartProperties {
        return {
            title: 'Coût de la consommation du PDL',
            downloadFileNames: ['Coût de la consommation du PDL', 'Coût de la consommation HP / HC / Pointe du PDL'],
            datasets: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: [],
            toggleTitles: [{ name: 'TOTAL', display: true }, { name: 'Pointe / HP / HC', display: true }],
        };
    }
    /** Properties for cost chart */
    public costChartProperties: FluidViewToggleChartProperties = this.defaultCostChartConfig;

    /** Default config for conso chart */
    private get defaultConsoChartConfig(): FluidViewToggleChartProperties {
        return {
            title: 'Consommation du PDL',
            datasets: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: [],
            toggleTitles: [],
            downloadFileNames: ['Consommation du PDL', 'Consommation HP HC du PDL'],
        };
    }
    /** Properties for conso chart */
    public consoChartProperties: FluidViewToggleChartProperties = this.defaultConsoChartConfig;

    @ViewChild('chartBarConso', { static: false }) chartBarConso: ChartBarToggleComponent;

    loading: Loading = {
        consoChart: false,
        costChart: false,
        powerChart: false,
        yearTable: false,
        monthTable: false,
        site: true,
    };

    // bills' cost table per year
    yearlyCostTable: ElecYearlyCostTableBill[] = [];
    // bills' cost table per month
    monthlyCostTable: ElecMonthlyCostTableBill[] = [];

    contract = '';
    years: number[] = [];
    routingRef = '';
    data: any;
    selectedContract: any;
    site: any = null;
    totalConso = 0;
    energyType = 'elec';
    periodSelected: DatePeriod = { dateStart: null, dateEnd: null };

    totalConsoTile: TileInterface = {
        mainColor: '#f3cc2e',
        secondaryColor: '#e4be26',
        label: 'Consommation totale',
        unit: 'kWh ef',
        type: 'elec',
    };
    totalCostTile: TileInterface = {
        mainColor: '#f3cc2e',
        secondaryColor: '#e4be26',
        label: 'Dépense totale',
        unit: '',
        type: 'cost',
    };

    constructor(
        private chartService: ChartService,
        private elecPdlViewService: ElecPdlViewService,
        private siteService: SitesService,
        private billService: BillsService,
        private sessionService: SessionService,
        private utilsService: UtilsService,
        private route: ActivatedRoute,
        private tilesService: TilesService,
        private djuService: DjuService,
        private pdlService: SitesListService,
        private translateService: TranslateService,
        private elecPowerReachService: ElecPowerReachService
    ) {}

    ngOnInit() {
        this.getSiteFromParams();
    }

    /**
     * Set all charts/tables loading
     * @param {boolean} loading - true to set as loading, false to set as not loading
     */
    private setAllLoadings(loading: boolean = true) {
        this.loading.consoChart = loading;
        this.loading.costChart = loading;
        this.loading.powerChart = loading;
        this.loading.yearTable = loading;
        this.loading.monthTable = loading;
    }

    resetAll() {
        this.totalConso = 0;
        this.totalHT = 0;
        this.totalTTC = 0;

        this.setAllLoadings(true);

        // power chart
        this.resetPowerChartProperties();

        // cost chart
        this.resetCostChartProperties();

        // bill table per year
        this.yearlyCostTable = [];

        // bill table per month
        this.monthlyCostTable = [];

        this.resetConsoChartProperties();
    }

    /**
     * Called when period is changed from the site's profile top component.
     * @param {DatePeriod} period
     */
    async onPeriodChanged(period: DatePeriod): Promise<void> {
        if (
            (this.periodSelected && period && period.dateStart !== this.periodSelected.dateStart) ||
            period.dateEnd !== this.periodSelected.dateEnd
        ) {
            this.periodSelected = period;
            await this.grabDataAndDisplay();
        }
    }

    /**
     * Get data aggregated per year and per month and populate bar charts and tables
     * @return {Promise<void>}
     */
    async grabDataAndDisplay(): Promise<void> {
        if (!this.periodSelected || !this.periodSelected.dateStart || !this.periodSelected.dateEnd) {
            return;
        }

        this.resetAll();

        Promise.all([
            this.getRoutingReferenceMonthlyConsumption(),
            this.getDataAndCreateYearlyCostTable(),
            this.getDataAndCreateMonthlyCostTable(),
        ]);
    }

    getSiteFromParams() {
        this.route.params.subscribe(async params => {
            try {
                this.setAllLoadings();
                this.contract = params.contractId;
                this.routingRef = params.rfId;

                const years = await this.elecPdlViewService.getYears(this.contract, this.routingRef, this.energyType);

                if (years && years.data && years.data.length) {
                    this.years = years.data;
                    this.years.sort((a, b) => b - a);
                }

                const routingReference = await this.elecPdlViewService.getRoutingReferenceById(this.routingRef);

                this.data = routingReference.data;
                this.selectedContract = this.data.contracts.find(contract => contract._id === this.contract);

                const site = await this.siteService.getSiteFromRoutingReference(this.data._id, this.data.company);

                if (!_.isEmpty(site)) {
                    this.site = site;
                }
                this.loading.site = false;
            } catch (error) {
                this.years = this.years ? this.years : [];
                this.periodSelected = this.periodSelected ? this.periodSelected : { dateStart: null, dateEnd: null };
                this.data = this.data ? this.data : null;
                this.selectedContract = this.selectedContract ? this.selectedContract : null;
                this.site = null;
                this.loading.site = false;
                const msg = this.data ? 'du site' : 'de votre PDL';
                Swal.fire('Toutes nos excuses', "Une erreur s'est produite lors du chargement " + msg, 'error');
            }
        });
    }

    /**
     * Compute the sum of the monthly conso values.
     * Set value for the tile displaying this total.
     */
    setTotalConsoTileValue() {
        const chartDataset = this.consoChartProperties.datasets;

        if (chartDataset && chartDataset.length && chartDataset[0].length) {
            const totalConsoDataset = chartDataset[0].find(item => item.label === 'Total des consommations');
            const total = totalConsoDataset.data.reduce((a, b) => a + b);
            this.totalConso = total;
        }
    }

    getTotalCost() {
        const totalHT = this.tilesService.getNumberToDisplay(this.totalHT, '€');
        const totalTTC = this.tilesService.getNumberToDisplay(this.totalTTC, '€');
        return `<b>${totalHT}</b> € HT - <b>${totalTTC}</b> € TTC`;
    }

    /*
     * Set in storage the current site in last visited sites for dashboard display
     */
    setLastVisitedSites(site) {
        this.sessionService.setLastVisitedSites(site);
    }

    /**
     * Get consumptions aggregated per month and set charts data
     * @return {Promise<void>}
     */
    async getRoutingReferenceMonthlyConsumption(): Promise<void> {
        try {
            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');
            this.monthData = [];

            // Get monthData
            const monthDataResult = await this.pdlService.getByMonthOrYear(
                'elec',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd
            );

            this.monthData = monthDataResult.data;

            await this.setChartsDataFromMonthlyConso(dateStart, dateEnd);
            this.setTotals();
        } catch (error) {
            this.loading.consoChart = false;
            this.loading.costChart = false;
            this.loading.powerChart = false;
        }
    }

    /**
     * Load dju on period and set both bar charts from month data
     * @param {string} dateStart
     * @param {string} dateEnd
     * @return {Promise<void>}
     */
    async setChartsDataFromMonthlyConso(dateStart: string, dateEnd: string): Promise<void> {
        try {
            if (this.site && this.site._id) {
                this.djus = await this.djuService.getSiteDJUs(dateStart, dateEnd, this.site).catch(e => {
                    if (e && e.errorCode && e.errorCode === 'not_geolocalised') {
                        this.consoGraph.isDjuActivated = false;
                        return [];
                    }
                    throw e;
                });
            } else {
                this.djus = [];
            }

            this.setConsoChartProperties();
            this.djuActivateChanged(this.consoGraph.isDjuActivated); // activate or deactivate dju display

            this.setCostChartProperties();
            this.setPowerChartProperties();

            this.loading.consoChart = false;
            this.loading.costChart = false;
            this.loading.powerChart = false;
        } catch (error) {
            this.loading.consoChart = false;
            this.loading.costChart = false;
            this.loading.powerChart = false;
        }
    }

    /**
     * Set totals HT and TTC from month data
     */
    setTotals() {
        this.totalHT = 0;
        this.totalTTC = 0;
        this.monthData.forEach(data => {
            this.totalHT += data.bill.billAmount.HTVA;
            this.totalTTC += data.bill.billAmount.TTC;
        });
    }

    /**
     * Handle cost bar chart properties.
     * Set datasets, labels, options, legend and colors
     */
    setCostChartProperties() {
        // handle datasets & configs
        const dataSetTotal = this.billService.getTotalCostMonthData(this.monthData);
        const dataSetHPHC = this.billService.getHPHCCostMonthData(this.monthData);
        this.costChartProperties.datasets = [dataSetTotal, dataSetHPHC];

        this.costChartProperties.labels = this.chartService.getMonthsLabel(this.monthData);

        this.costChartProperties.options = this.chartService.getDatasetsAxesOptions(
            this.costChartProperties.datasets,
            this.chartService.getConfig('bar', {
                tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line', '€'),
            })
        );

        // handle labels for legend & color
        const hasPointe = dataSetHPHC.some(serie => serie.label === this.translateService._('pointe'));
        const labelsTotal = ['total_cost'];
        const labelsHPHC = hasPointe ? ['pointe', 'HP', 'HC'] : ['HP', 'HC'];

        const legendTotal = this.chartService.getChartLegends(labelsTotal);
        const legendHPHC = this.chartService.getChartLegends(labelsHPHC);
        this.costChartProperties.legend = [legendTotal, legendHPHC];

        const colorsTotal = this.chartService.getBarChartColors(labelsTotal);
        const colorsHPHC = this.chartService.getBarChartColors(labelsHPHC);
        this.costChartProperties.colors = [colorsTotal, colorsHPHC];
    }

    /**
     * Handle chart of power reached / subscribed.
     * Set datasets, labels, config, legend, colors
     */
    private setPowerChartProperties() {
        const data: ElecPowerPeriod[] = this.elecPowerReachService.getPowerChartDataSets(this.monthData);
        const labels: string[] = this.elecPowerReachService.getRequiredTimeSlotsForAllPeriods(data);

        const datasets: ChartDataSetsGA[] = this.elecPowerReachService.formatPowerReachedGraphData(data, labels);
        const options = this.chartService.getConfig('horizontalBar', {
            tooltips: this.chartService.getTooltipHTMLConfig('horizontal-bar', 'kVA'),
        });
        options.scales.xAxes[0].stacked = false;

        let colors: ChartColor[] = [];
        let opacity = 1;
        let legends: ChartLegendGA[] = [];
        let secondLegends: ChartLegendGA[] = [];

        for (let i = 0, iLen = data.length; i < iLen; i++) {
            colors = colors.concat(
                this.chartService.getBarChartColorsWithOpacity(['powers_subscribe', 'powers_reached'], opacity)
            );
            opacity -= 1 / data.length;
        }

        ({ legends, secondLegends } = this.getLegendsFormated(data, legends, secondLegends));
        const title = this.translateService._('powers_reached');
        this.powerChartProperties = {
            title,
            datasets,
            labels,
            legends,
            secondLegends,
            colors,
            options,
        };
    }

    /**
     * generate the legends of power reached graph
     */
    private getLegendsFormated(
        data: ElecPowerPeriod[],
        legends: ChartLegendGA[],
        secondLegends: ChartLegendGA[]
    ): { legends: ChartLegendGA[]; secondLegends: ChartLegendGA[] } {
        // generate colors and legends for power subscirption
        let opacity = 1;
        for (let i = data.length - 1, iLen = 0; i >= iLen; i--) {
            legends = this.getLegendsOfLabel(legends, opacity, 'powers_subscribe', data[i].dateInfo);
            secondLegends = this.getLegendsOfLabel(secondLegends, opacity, 'powers_reached', data[i].dateInfo);
            opacity -= 1 / data.length;
        }

        return { legends, secondLegends };
    }

    /**
     * generate legend of a label for power reached graph
     */
    private getLegendsOfLabel(
        legends: ChartLegendGA[],
        opacity: number,
        label: string,
        suffix: string
    ): ChartLegendGA[] {
        const powerLegend: ChartLegendGA[] = this.chartService.getChartLegends([label]);
        for (let j = 0, jLen = powerLegend.length; j < jLen; j++) {
            powerLegend[j].color = powerLegend[j].color + `, ${opacity}`;
            powerLegend[j].name = powerLegend[j].name + ` ${suffix}`;
        }
        legends = legends.concat(powerLegend);
        return legends;
    }

    /**
     * On DJU activation change, hide/show djus datasets and y-axe
     */
    djuActivateChanged(isDjuActivated: boolean): void {
        isDjuActivated = Boolean(isDjuActivated && this.djus && this.djus.length);
        this.consoGraph.isDjuActivated = isDjuActivated;

        /**
         * First, we need to update data
         * Then, we need to update the chart display.
         */
        const djuLabel = 'Degrés jours unifiés (par mois)';
        const kwhDjuButton = 'kWh/dju';
        const hasPointe = this.consoChartProperties.datasets[1].some(
            serie => serie.label === this.translateService._('pointe')
        );
        const consoHPHCLabels = hasPointe ? ['pointe', 'HP', 'HC'] : ['HP', 'HC'];
        const labelsLegends = [['total_conso'], consoHPHCLabels, ['total_conso_elec_dju_ratio']];

        if (isDjuActivated) {
            labelsLegends[0].unshift('djus');
            labelsLegends[1].unshift('djus');
        }

        const legends = labelsLegends.map(x => this.chartService.getChartLegends(x));

        this.djuService.toggleDjuInDatasetAndChart(
            isDjuActivated,
            this.consoChartProperties,
            djuLabel,
            legends,
            kwhDjuButton,
            this.chartBarConso
        );
    }

    setConsoChartProperties() {
        this.loading.consoChart = true;

        this.resetConsoChartProperties();

        const isDjuActivated: boolean = Boolean(this.djus && this.djus.length);

        // handle datasets
        const dataSetConsoTotal: ChartSerieLight[] = this.billService.getTotalConsoMonthData(this.monthData, this.djus);
        const dataSetHPHC: ChartSerieLight[] = this.billService.getHPHCConsoMonthData(this.monthData, this.djus);
        this.consoChartProperties.datasets = [dataSetConsoTotal, dataSetHPHC];

        // handle labels
        this.consoChartProperties.labels = this.chartService.getMonthsLabel(this.monthData);

        // handle configs
        const configType = isDjuActivated ? 'mixed' : 'bar';
        this.consoChartProperties.options = this.chartService.getDatasetsAxesOptions(
            this.consoChartProperties.datasets,
            this.chartService.getConfig(configType, {
                tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line'),
            })
        );

        // handle labels legends & colors & buttons
        const hasPointe = dataSetHPHC.some(serie => serie.label === this.translateService._('pointe'));

        const consoTotalLabels = ['total_conso'];
        const consoHPHCLabels = hasPointe ? ['pointe', 'HP', 'HC'] : ['HP', 'HC'];
        let labelsDjuPonderation = [];

        this.consoChartProperties.toggleTitles = [
            { name: 'Total', display: true },
            { name: 'Pointe / HP / HC', display: true },
        ];

        // handle djus, labels & dju ponderation data (kwh / dju button, dataset, config)
        if (isDjuActivated) {
            labelsDjuPonderation = ['total_conso_elec_dju_ratio'];
            consoTotalLabels.unshift('djus');
            consoHPHCLabels.unshift('djus');

            const djuPonderationButtons = { name: 'kWh/dju', display: true };
            const djuPonderationDataset = this.billService.getMonthDataDjuRatio(
                this.djus,
                this.monthData,
                [
                    {
                        name: 'Elec conso',
                        path: 'consumption.global.quantity',
                    },
                ],
                consoHPHCLabels
            );

            const djuPonderationConfig = this.chartService.getSeriesAxesOptions(
                djuPonderationDataset,
                this.chartService.getConfig('bar', {
                    tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line'),
                })
            );

            this.consoChartProperties.toggleTitles.push(djuPonderationButtons);
            this.consoChartProperties.datasets.push(djuPonderationDataset);
            this.consoChartProperties.options.push(djuPonderationConfig);
        }

        // legend (need last versions of labels)
        const consoTotalLegend = this.chartService.getChartLegends(consoTotalLabels);
        const consoHPHCLegend = this.chartService.getChartLegends(consoHPHCLabels);
        const djuPonderationLegend = this.chartService.getChartLegends(labelsDjuPonderation);

        this.consoChartProperties.legend = [consoTotalLegend, consoHPHCLegend].concat([djuPonderationLegend]);

        // colors (need last versions of labels)
        const consoTotalColors = this.chartService.getBarChartColors(consoTotalLabels);
        const consoHPHCColors = this.chartService.getBarChartColors(consoHPHCLabels);
        const djuPonderationColors = this.chartService.getBarChartColors(labelsDjuPonderation);
        this.consoChartProperties.colors = [consoTotalColors, consoHPHCColors].concat([djuPonderationColors]);

        this.setTotalConsoTileValue();
        this.loading.consoChart = false;
    }

    /**
     * Get labels & values to display on top of the power reached / subscription power chart
     * @param {ChartSerieLight[]} data
     * @return {{label: string; value: string}[]}
     */
    getLineChartLabelTop(data: ChartSerieLight[]): Array<{ label: string; value: string }> {
        const [contractPower, subscriptionPower] = data.map(serie => {
            const dataNotNull = serie.data.filter((x: number) => typeof x === 'number');
            if (dataNotNull.length) {
                const max = Math.max(...dataNotNull);
                return `${max} kVA`;
            }
            return 'NC';
        });
        return [
            {
                label: 'Puissance souscrite',
                value: `${contractPower}`,
            },
            {
                label: 'Maximum atteint',
                value: `${subscriptionPower}`,
            },
        ];
    }

    /**
     * Get labels & values to display at the bottom of the power reached / subscription power chart
     * @param {string[]} labels
     * @param {number[]} values
     * @return {{label: string; value: string}[]}
     */
    getLineChartLabelBottom(labels: string[], values: number[]): Array<{ label: string; value: string }> {
        return labels.map((label, i) => {
            return {
                label,
                value: typeof values[i] === 'number' ? `${values[i]} kVA` : 'NC',
            };
        });
    }

    /**
     * Returns cost bills values aggregated per month on the selected period.
     */
    async getMonthlyCosts(): Promise<ElecMonthlyBill[]> {
        const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
            .startOf('month')
            .format('DD/MM/YYYY');
        const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
            .subtract(1, 'months')
            .endOf('month')
            .format('DD/MM/YYYY');

        const result = await this.pdlService.getByMonthOrYear(
            'elec',
            this.contract,
            this.routingRef,
            dateStart,
            dateEnd
        );
        return result.data;
    }

    /**
     * Get costs values per month from bills on the selected period and create the table data.
     */
    async getDataAndCreateMonthlyCostTable(): Promise<void> {
        try {
            this.monthlyCostTable = [];
            const dataPerMonth: ElecMonthlyBill[] = await this.getMonthlyCosts();

            // Get first month of the table and use it to start the creation of the table data
            const monthNumber = Number(this.periodSelected.dateStart.substring(5, 7)) - 1;
            const yearNumber = Number(this.periodSelected.dateStart.substring(0, 4));
            const currentMonth = moment.utc({ year: yearNumber, month: monthNumber, day: 0 });

            const nbMonthInQuery = moment(this.periodSelected.dateEnd).diff(
                moment(this.periodSelected.dateStart),
                'months'
            );

            for (let i = 0; i < nbMonthInQuery; i++) {
                let bill: ElecMonthlyCostTableBill;
                const monthLabel = currentMonth.format('MM/YYYY');
                const monthData = dataPerMonth.find(
                    data => data._id.month === currentMonth.month() && data._id.year === currentMonth.year()
                );

                // Format the current month data of set an empty one if no data found for this month & years
                if (monthData) {
                    bill = this.createMonthlyBill(monthData, monthLabel);
                } else {
                    bill = {
                        periodLabel: monthLabel,
                        consumption: { total: '' },
                        networkService: { total: '' },
                        taxes: { total: '' },
                        subscription: { total: '' },
                        totalHTVA: { total: '' },
                        totalTTC: { total: '' },
                    };
                }

                this.monthlyCostTable.push(bill);
                currentMonth.add(1, 'month');
            }

            this.loading.monthTable = false;
        } catch (e) {
            this.monthlyCostTable = [];
            this.loading.monthTable = false;
        }
    }

    /**
     * Create a month elec cost bill for the table
     * @param {ElecMonthlyBill} data
     * @param {string} monthLabel
     * @returns {ElecMonthlyCostTableBill}
     */
    createMonthlyBill(data: ElecMonthlyBill, monthLabel: string): ElecMonthlyCostTableBill {
        const { bill, consumption, networkService, tax, turpe } = data;

        const billTotals = this.getElecBillTotals(data);

        return {
            periodLabel: monthLabel,
            consumption: {
                globalQuantity: this.formatNumberDefaultToNC(consumption.global.quantity, 'money'),
                globalCost: this.formatNumberDefaultToNC(consumption.global.amount, 'money'),
                renewable: this.formatNumberDefaultToNC(consumption.renewable.global.amount, 'money'),
                capacity: this.formatNumberDefaultToNC(consumption.capacity.global.amount, 'money'),
                cee: this.formatNumberDefaultToNC(consumption.globalCee.amount, 'money'),
                total: this.formatNumberDefaultToNC(billTotals.consumptionAmount, 'money'),
            },
            subscription: {
                subscriptionFixed: this.formatNumberDefaultToNC(networkService.subscriptionFixed.amount, 'money'),
                fixedRacking: this.formatNumberDefaultToNC(turpe.racking.fixed.amount, 'money'),
                counting: this.formatNumberDefaultToNC(turpe.counting.amount, 'money'),
                management: this.formatNumberDefaultToNC(turpe.management.amount, 'money'),
                total: this.formatNumberDefaultToNC(billTotals.subscriptionAmount, 'money'),
            },
            networkService: {
                meterRent: this.formatNumberDefaultToNC(networkService.meterRent.amount, 'money'),
                miscServices: this.formatNumberDefaultToNC(networkService.miscServices.amount, 'money'),
                penalities: this.formatNumberDefaultToNC(networkService.penality.amount, 'money'),
                racking: this.formatNumberDefaultToNC(turpe.racking.global.amount, 'money'),
                overtaking: this.formatNumberDefaultToNC(turpe.overtaking.global.amount, 'money'),
                reactive: this.formatNumberDefaultToNC(turpe.reactive.amount, 'money'),
                total: this.formatNumberDefaultToNC(billTotals.networkServicesAmount, 'money'),
            },
            taxes: {
                cspeQuantity: this.formatNumberDefaultToNC(tax.CSPE.quantity, 'money'),
                cspeCost: this.formatNumberDefaultToNC(tax.CSPE.amount, 'money'),
                ctaCost: this.formatNumberDefaultToNC(tax.CTA.amount, 'money'),
                tccfeQuantity: this.formatNumberDefaultToNC(tax.TCCFE.quantity, 'money'),
                tccfeCost: this.formatNumberDefaultToNC(tax.TCCFE.amount, 'money'),
                tdcfeQuantity: this.formatNumberDefaultToNC(tax.TDCFE.quantity, 'money'),
                tdcfeCost: this.formatNumberDefaultToNC(tax.TDCFE.amount, 'money'),
                tcfeQuantity: this.formatNumberDefaultToNC(tax.TCFE.quantity, 'money'),
                tcfeCost: this.formatNumberDefaultToNC(tax.TCFE.amount, 'money'),
                total: this.formatNumberDefaultToNC(billTotals.taxesAmount, 'money'),
            },
            totalHTVA: {
                total: this.formatNumberDefaultToBlank(
                    billTotals.consumptionAmount +
                        billTotals.networkServicesAmount +
                        billTotals.taxesAmount +
                        billTotals.subscriptionAmount,
                    'money'
                ),
            },
            totalTTC: { total: this.formatNumberDefaultToBlank(bill.billAmount.TTC, 'money') },
        };
    }

    /**
     * Get yearly costs from bills on the selected period
     * @return {ElecYearlyBill[]} array of costs bills values aggregated per year
     */
    async getYearlyCosts(): Promise<ElecYearlyBill[]> {
        const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
            .startOf('year')
            .format('DD/MM/YYYY');
        const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
            .subtract(1, 'months')
            .endOf('year')
            .format('DD/MM/YYYY');
        const response = await this.pdlService.getByMonthOrYear(
            'elec',
            this.contract,
            this.routingRef,
            dateStart,
            dateEnd,
            true,
            2
        );

        return response.data;
    }

    /**
     * Get costs values per year from bills on the selected period and create the table data.
     */
    async getDataAndCreateYearlyCostTable(): Promise<void> {
        try {
            this.yearlyCostTable = [];
            const dataPerYear: ElecYearlyBill[] = await this.getYearlyCosts();

            if (!dataPerYear || !dataPerYear.length) {
                this.loading.yearTable = false;
                return;
            }

            dataPerYear.forEach(yearData => {
                const { bill, consumption, networkService, tax, turpe } = yearData;

                const globalQty = consumption.global.quantity;

                const billTotals = this.getElecBillTotals(yearData);

                const yearFormatedTableData = {
                    periodLabel: 'Facture ' + yearData.year,
                    amount: {
                        total: this.formatNumberDefaultToNC(bill.billAmount.TTC, 'money'),
                    },
                    consumption: {
                        global: this.formatNumberDefaultToNC(consumption.global.amount, 'money'),
                        globalRatio: this.formatNumberDefaultToNC(
                            (consumption.global.amount / globalQty) * 100,
                            'money'
                        ),
                        renewable: this.formatNumberDefaultToNC(consumption.renewable.global.amount, 'money'),
                        renewableRatio: this.formatNumberDefaultToNC(
                            (consumption.renewable.global.amount / globalQty) * 100,
                            'money'
                        ),
                        capacity: this.formatNumberDefaultToNC(consumption.capacity.global.amount, 'money'),
                        capacityRatio: this.formatNumberDefaultToNC(
                            (consumption.capacity.global.amount / globalQty) * 100,
                            'money'
                        ),
                        cee: this.formatNumberDefaultToNC(consumption.globalCee.amount, 'money'),
                        ceeRatio: this.formatNumberDefaultToNC(
                            (consumption.globalCee.amount / globalQty) * 100,
                            'money'
                        ),
                        total: this.formatNumberDefaultToNC(billTotals.consumptionAmount, 'money'),
                        totalRatio: this.formatNumberDefaultToNC(
                            (billTotals.consumptionAmount / globalQty) * 100,
                            'money'
                        ),
                    },
                    subscription: {
                        subscriptionFixed: this.formatNumberDefaultToNC(
                            (networkService.subscriptionFixed.amount / globalQty) * 100,
                            'money'
                        ),
                        fixedRacking: this.formatNumberDefaultToNC(
                            (turpe.racking.fixed.amount / globalQty) * 100,
                            'money'
                        ),
                        counting: this.formatNumberDefaultToNC((turpe.counting.amount / globalQty) * 100, 'money'),
                        management: this.formatNumberDefaultToNC((turpe.management.amount / globalQty) * 100, 'money'),
                        total: this.formatNumberDefaultToNC((billTotals.subscriptionAmount / globalQty) * 100, 'money'),
                    },
                    networkService: {
                        meterRent: this.formatNumberDefaultToNC(
                            (networkService.meterRent.amount / globalQty) * 100,
                            'money'
                        ),
                        miscServices: this.formatNumberDefaultToNC(
                            (networkService.miscServices.amount / globalQty) * 100,
                            'money'
                        ),
                        penalities: this.formatNumberDefaultToNC(
                            (networkService.penality.amount / globalQty) * 100,
                            'money'
                        ),
                        racking: this.formatNumberDefaultToNC((turpe.racking.global.amount / globalQty) * 100, 'money'),
                        overtaking: this.formatNumberDefaultToNC(
                            (turpe.overtaking.global.amount / globalQty) * 100,
                            'money'
                        ),
                        reactive: this.formatNumberDefaultToNC((turpe.reactive.amount / globalQty) * 100, 'money'),
                        total: this.formatNumberDefaultToNC(
                            (billTotals.networkServicesAmount / globalQty) * 100,
                            'money'
                        ),
                    },
                    taxes: {
                        cta: this.formatNumberDefaultToNC((tax.CTA.amount / globalQty) * 100, 'money'),
                        cspe: this.formatNumberDefaultToNC((tax.CSPE.amount / globalQty) * 100, 'money'),
                        tdcfe: this.formatNumberDefaultToNC((tax.TDCFE.amount / globalQty) * 100, 'money'),
                        tccfe: this.formatNumberDefaultToNC((tax.TCCFE.amount / globalQty) * 100, 'money'),
                        tcfe: this.formatNumberDefaultToNC((tax.TCFE.amount / globalQty) * 100, 'money'),
                        total: this.formatNumberDefaultToNC((billTotals.taxesAmount / globalQty) * 100, 'money'),
                    },
                    totalHTVA: {
                        total: this.formatNumberDefaultToBlank(
                            ((billTotals.consumptionAmount +
                                billTotals.networkServicesAmount +
                                billTotals.taxesAmount +
                                billTotals.subscriptionAmount) /
                                globalQty) *
                                100,
                            'money'
                        ),
                    },
                    totalTTC: {
                        total: this.formatNumberDefaultToBlank((bill.billAmount.TTC / globalQty) * 100, 'money'),
                    },
                };

                this.yearlyCostTable.push(yearFormatedTableData);
            });
            this.loading.yearTable = false;
        } catch (e) {
            this.yearlyCostTable = [];
            this.loading.yearTable = false;
            return;
        }
    }

    siteLoaded() {
        return this.data && this.data._id && !this.loading.site;
    }

    formatNumberDefaultToNC(value, type = 'fluid') {
        return this.utilsService.formateNumberType(value, 'NC', type);
    }

    formatNumberDefaultToBlank(value, type = 'fluid') {
        return this.utilsService.formateNumberType(value, '', type);
    }

    canShowConsoGraph(): boolean {
        return Boolean(
            !this.loading.consoChart &&
                this.consoChartProperties.datasets.length &&
                this.consoChartProperties.datasets[0].length
        );
    }

    canShowCostGraph(): boolean {
        return Boolean(
            !this.loading.costChart &&
                this.costChartProperties.datasets.length &&
                this.costChartProperties.datasets[0].length
        );
    }

    canShowPowerChart(): boolean {
        if (
            !this.powerChartProperties ||
            !this.powerChartProperties.datasets ||
            !this.powerChartProperties.datasets.length
        ) {
            return false;
        }
        return Boolean(this.powerChartProperties.datasets.reduce((a, b) => a.concat(b.data), []).length);
    }

    canShowYearTable(): boolean {
        return Boolean(!this.loading.yearTable && this.yearlyCostTable.length);
    }

    canShowMonthTable(): boolean {
        return Boolean(!this.loading.monthTable && this.monthlyCostTable.length);
    }

    getExportExcelUrl(type) {
        return this.pdlService.getExportExcelUrl(
            type,
            this.periodSelected,
            this.routingRef,
            this.contract,
            this.consoGraph.isDjuActivated,
            'elec'
        );
    }

    getExportName(txt) {
        return this.pdlService.getExportName(txt, this.periodSelected, this.data, this.selectedContract);
    }

    private resetConsoChartProperties(): void {
        this.consoChartProperties = this.defaultConsoChartConfig;
    }

    private resetPowerChartProperties(): void {
        this.powerChartProperties = this.defaultPowerChartConfig;
    }

    private resetCostChartProperties(): void {
        this.costChartProperties = this.defaultCostChartConfig;
    }

    /** Returns totals for each expense category on the elec bill. */
    private getElecBillTotals(bill: ElecMonthlyBill | ElecYearlyBill) {
        const { consumption, networkService, turpe, tax } = bill;

        // Consumption totals
        const consumptionAmount =
            consumption.global.amount +
            consumption.renewable.global.amount +
            consumption.capacity.global.amount +
            consumption.globalCee.amount;

        // Network services totals
        const networkServicesAmount =
            networkService.meterRent.amount +
            networkService.miscServices.amount +
            networkService.penality.amount +
            turpe.racking.global.amount +
            turpe.overtaking.global.amount +
            turpe.reactive.amount;

        // Taxes totals
        const taxesAmount = tax.global.amount;

        // Subscription totals
        const subscriptionAmount =
            networkService.subscriptionFixed.amount +
            turpe.racking.fixed.amount +
            turpe.counting.amount +
            turpe.management.amount;

        return {
            consumptionAmount,
            networkServicesAmount,
            subscriptionAmount,
            taxesAmount,
        };
    }
}
