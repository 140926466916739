import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';

export interface Option {
    key: string;
    legend: string;
    selected: boolean;
    class?: string;
    isDisabled?: boolean;
}

export interface Column<T> {
    key: string;
    name: string;
    options: T[];
}

@Component({
    selector: 'ga-scroll-column-selection',
    templateUrl: './axis-selection.component.html',
    styleUrls: ['./axis-selection.component.scss'],
    providers: [],
})
export class ColumnSelectorComponent<T extends Option> implements OnInit, OnChanges {
    @Input() columnsOptions: Array<Column<T>> = [];
    @Input() disableSameKeys = true;
    @Output() optionChanged: EventEmitter<void> = new EventEmitter<void>();

    ngOnInit(): void {
        this.setClasses();
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
        for (const propName in changes) {
            if (propName === 'columnsOptions' || propName === 'disableSameKeys') {
                this.setClasses();
            }
        }
    }

    /**
     * Handle a change in the column selection.
     * Emit the change to parent component.
     * @param {T} optionSelected
     * @param {string} oppositeColumnKey
     */
    changeAxeOption(column: Column<T>, optionSelected: T) {
        const oldSelection = column.options.find(option => option.selected);

        if (oldSelection) {
            oldSelection.selected = false;
            oldSelection.class = 'inactiveToggle';
        }

        optionSelected.selected = true;
        optionSelected.class = 'activeToggle';

        if (this.disableSameKeys) {
            this.disableOptions(oldSelection.key);
        }

        this.optionChanged.emit();
    }

    /**
     * Get the column object from its key
     * @param {string} columnKey
     * @returns {Column<T>} column
     */
    getColumn(columnKey: string): Column<T> {
        return this.columnsOptions.find(column => column.key === columnKey);
    }

    /**
     * Return the selected option in the given column from key
     * @param {string}columnKey
     */
    getColumnSelection(columnKey: string): T {
        const column = this.columnsOptions.find(axe => axe.key === columnKey);
        return column.options.find(opt => opt.selected);
    }

    /**
     * Set classes for active and inactive toggles.
     * Disable options when required.
     */
    setClasses(): void {
        this.columnsOptions.forEach(column => {
            column.options.forEach(option => {
                option.class = option.selected ? 'activeToggle' : 'inactiveToggle';
            });
        });

        if (this.disableSameKeys) {
            this.disableOptions();
        }
    }

    /**
     * Disable options having a key which is selected somewhere else
     * @param {string} oldKey - re-enable the previous selection
     */
    disableOptions(oldKey = null) {
        this.columnsOptions.forEach(column => {
            const selection = this.getColumnSelection(column.key);

            this.columnsOptions
                .filter(col => col.key !== column.key)
                .forEach(otherColumn => {
                    // remove previous disable
                    if (oldKey) {
                        const oldOption = otherColumn.options.find(o => o.key === oldKey);
                        oldOption.isDisabled = false;
                    }

                    // set new disabled option
                    const option = otherColumn.options.find(o => o.key === selection.key);
                    if (option) {
                        option.isDisabled = true;
                    }
                });
        });
    }
}
