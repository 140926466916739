import { Injectable } from '@angular/core';

import { CONTRACTUAL_DATA_SOURCE, ContractualData, Identifiers } from 'app/shared/models/contractual-data.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

import { GetContractualDataFilters } from './interfaces';

@Injectable()
export class ContractualDataService extends PageService {
    private readonly baseUrl = '/api/contractual-data';

    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /** Launch the synchronization for a bunch of identifiers */
    public async synchronize(identifiers: object[], source: CONTRACTUAL_DATA_SOURCE) {
        const route = `${this.baseUrl}/synchronization`;
        const res = await this.put(route, { identifiers, source });
        return res.data;
    }

    /** Get the values of contractual data items matching identifiers. */
    public async getContractualDatas(
        identifiers: Identifiers[],
        filters: GetContractualDataFilters = {}
    ): Promise<ContractualData[]> {
        const url = `${this.baseUrl}`;
        const body = { identifiers, filters };

        const response = await this.post(url, body);

        return response.data;
    }
}
