import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { saveAs } from 'file-saver';
import _ = require('lodash');
import Swal from 'sweetalert2';

// interfaces
import { ScopeFilterConfig } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import {
    OptionValueGroup,
    QueryFilter,
    QueryFilterRoutingRef,
    QueryFilterVeh,
} from '../scope-filter/scope-filter.interface';

// services
import { ExportService } from 'app/shared/services/export/export.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { FollowupFilterService } from './followup-filter.service';

// components
import { RoutingReferenceScopeFilterComponent } from '../scope-filter/routing-reference/routing-reference-scope-filter.component';
import { VehicleScopeFilterComponent } from '../scope-filter/vehicle/vehicle-scope-filter.component';

@Component({
    selector: 'ga-followup-filter',
    templateUrl: './followup-filter.component.html',
    styleUrls: ['./followup-filter.component.scss'],
    providers: [FollowupFilterService],
})
export class FollowupFilterComponent implements OnInit {
    public shownButtons = {
        search: true,
        exportDT: false,
    };
    public isExportingDT = false;

    /**
     * ------------
     * INPUTS
     * ------------
     */

    /**
     * Accepted fluids that can be displayed.
     */
    private _fluidsAccepted: string[] = [];
    @Input()
    set fluidsAccepted(values: string[]) {
        this._fluidsAccepted = Array.from(values);
    }
    get fluidsAccepted() {
        return this._fluidsAccepted;
    }

    /**
     * Accepted fuel products that can be displayed.
     */
    private _fuelTypesAvailable: string[] = [];
    @Input()
    set fuelTypesAvailable(values: string[]) {
        this._fuelTypesAvailable = Array.from(values);
    }
    get fuelTypesAvailable() {
        return this._fuelTypesAvailable;
    }

    /**
     * Last used and valid params
     */
    private params: Params;

    /** Date can be either yearly or a custom period with a start and end.*/
    @Input() isDateCustomisable = true;

    /** Used to filter the accepted fluids. Eg: 'energy' or 'water'. */
    @Input() fluidsCategory: string;

    /** Used to diabled reasearch button */
    @Input() isLoading: boolean;

    /**
     * Boolean to indicate which set of elements must be displayed in the filter.
     * 2 different sets for now: routing references VS vehicles.
     */
    @Input() isRoutingReferenceScope = true;

    /**
     * Get the filters configuration obtained in the child component
     */
    @Input() filtersConfig: ScopeFilterConfig;

    @Input()
    set showSearchButton(value: boolean) {
        this.shownButtons.search = value;
    }
    @Input()
    set showExportDTButton(value: boolean) {
        this.shownButtons.exportDT = value;
    }

    /**
     * ------------
     * OUTPUTS
     * ------------
     */

    /**
     * Event emitted each time an element is changed inside the filter
     */
    @Output() filterChange: EventEmitter<QueryFilter> = new EventEmitter<QueryFilter>();

    /**
     * Holds the options values selected in the filter
     */
    filter: QueryFilterVeh | QueryFilterRoutingRef = null;
    filterComponent: VehicleScopeFilterComponent | RoutingReferenceScopeFilterComponent;

    @ViewChild(VehicleScopeFilterComponent, { static: false }) scopeFilterVeh: VehicleScopeFilterComponent;
    @ViewChild(RoutingReferenceScopeFilterComponent, { static: false })
    scopeFilterRoutingRef: RoutingReferenceScopeFilterComponent;

    constructor(
        private filterService: FilterService,
        private followupFilterService: FollowupFilterService,
        private exportService: ExportService
    ) {}

    ngOnInit() {}

    /**
     * Update the filter selected value.
     * @param {QueryFilterVeh | QueryFilterRoutingRe} filter
     */
    updateFilter(filter: QueryFilterVeh | QueryFilterRoutingRef): void {
        this.filter = filter;
    }

    /**
     * After filter initialized, update filter member and emit search.
     * @param {QueryFilterVeh | QueryFilterRoutingRef} filter
     */
    updateFilterAndSearch(filter: QueryFilterVeh | QueryFilterRoutingRef): void {
        this.filterComponent = this.isRoutingReferenceScope ? this.scopeFilterRoutingRef : this.scopeFilterVeh;
        this.updateFilter(filter);
        this.search();
    }

    /**
     * Launch the filter search by updating the url query params.
     */
    public search(): void {
        const params = this.followupFilterService.convertFilterIntoQueryParams(this.filter);
        if (!_.isEqual(params, this.params)) {
            this.params = params;

            this.filterService.updateRouteParams(this.params, '');
        }
    }

    /**
     * Launch the filter search in the parent component.
     * @param {QueryFilterVeh | QueryFilterRoutingRef} filter
     */
    public emitFilter(filter: QueryFilterVeh | QueryFilterRoutingRef) {
        this.filterComponent = this.isRoutingReferenceScope ? this.scopeFilterRoutingRef : this.scopeFilterVeh;
        this.updateFilter(filter);
        this.filterChange.emit(this.filter);
    }

    /**
     * @returns {boolean} true if at least one fluid in research, false otherwise
     */
    public hasFluidsSelected(): boolean {
        return this.getFluidsCount() > 0;
    }

    /**
     * @returns {boolean} true is search button need to be disabled, false otherwise.
     */
    public areButtonsDisabled(): boolean {
        const isLoading = this.isLoading || this.isExportingDT;

        const hasDataForCurrentFilter = this.filterComponent && this.filterComponent.hasOptionsSelected();
        const hasEnergies = this.hasFluidsSelected();

        return !hasDataForCurrentFilter || !hasEnergies || isLoading;
    }

    /************************
     * Used by hosts
     ************************/

    /**
     * Returns the current selected element among the top filter elements.
     */
    public getMainFilterSelected(): OptionValueGroup {
        if (this.filterComponent) {
            return this.filterComponent.getMainFilterSelected();
        }
        return null;
    }

    /**
     * Returns the selected values inside the current top filter element selected.
     */
    public getFilters() {
        if (this.filterComponent) {
            return this.filterComponent.mainFilterSelectedValues;
        }
        return [];
    }

    /**
     * @returns {number} number of energies / fuelTypes selected
     */
    public getFluidsCount(): number {
        const queryFilter = this.isRoutingReferenceScope ? 'energies' : 'fuelTypes';

        if (!this.filter || !this.filter[queryFilter]) {
            return 0;
        }
        return this.filter[queryFilter].split(',').length;
    }

    public getExportDTFile(): void {
        const excelName = 'Déclaration DT.xlsx';
        const queryStringParams = this.filterService.formatQueryToUrl(this.filter);
        const excelUrl = `/api/export/excel/consumptions/sites-zones${queryStringParams}`;

        this.isExportingDT = true;
        this.exportService.getFile(excelUrl).subscribe(
            file => {
                saveAs(file, excelName);
                this.isExportingDT = false;
            },
            err => {
                this.isExportingDT = false;
                Swal.fire(
                    'Toutes nos excuses',
                    "Une erreur s'est produite lors de l'export de données. Merci de réessayer.",
                    'error'
                );
            }
        );
    }
}
