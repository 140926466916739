// tslint:disable:no-access-missing-member
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuestionComponent } from '../question/question.component';

@Component({
    selector: 'ga-question-input-text-multiple',
    templateUrl: './input-text-multiple.component.html',
    styleUrls: ['./input-text-multiple.component.scss'],
})
/**
 * Component to create an input question (as text, number, email, ...) or text-area with multiple inputs.
 * @Input data : User data at the format [{key: string, value: string}]
 * @Input config : Question from the config file with properties attribute at the format {values: [{label: string, key: string, type: string}]}
 * Available types : text, number, text-area, color, date, datetime-local, email, month, tel, time, url, week.
 * @Ouput valueChange : event that gives the data each time it's changed by the user at the format {key: string, value: string}
 */
export class InputTextMultipleComponent extends QuestionComponent implements OnInit {
    allowedTypes = [
        'text',
        'number',
        'text-area',
        'color',
        'date',
        'datetime-local',
        'email',
        'month',
        'tel',
        'time',
        'url',
        'week',
    ];

    questionForm: FormGroup;

    constructor() {
        super();
    }

    ngOnInit() {
        const form = {};
        if (this.config.properties.values) {
            this.config.properties.values.forEach(item => {
                // Set data from db
                const itemData = this.data.find(x => x.key === item.key);
                form[item.key] = new FormControl(itemData ? itemData.value : null);
            });
            this.questionForm = new FormGroup(form);

            this.onChanges();
            this.updateValues(this.questionForm.getRawValue());
        }
    }

    onChanges() {
        this.questionForm.valueChanges.subscribe(val => {
            if (val) {
                this.updateValues(val);
            }
        });
    }

    private updateValues(val = null) {
        const res = [];
        this.config.properties.values.forEach(item => {
            res.push({
                key: item.key,
                value: val ? val[item.key] : null,
            });
        });
        this.valueChange.emit(res);
    }

    getType(item) {
        if (this.allowedTypes.find(x => x === item.type)) {
            return item.type;
        }
        return 'text';
    }

    getMin(item) {
        return item.min !== undefined ? item.min : null;
    }

    getMax(item) {
        return item.max !== undefined ? item.max : null;
    }

    isTextArea(item) {
        return item.type === 'text-area';
    }
}
