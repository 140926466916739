import { Component, OnInit } from '@angular/core';
import { PaginationBackService } from 'app/shared/components/pagination/back/pagination-back.service';
import { FiltersAvailable, QueryFilter } from 'app/shared/models/bills-contracts-filter.interface';
import { Pagination, QueryPagination, QuerySort } from 'app/shared/models/pagination.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { SessionService } from 'app/shared/services/session/session.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { ContractDisplay } from './contracts.interface';
import { ContractsService } from './contracts.service';

interface Loading {
    filters: boolean;
    contracts: boolean;
}

@Component({
    selector: 'ga-contracts',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.scss'],
    providers: [ContractsService, PaginationBackService],
})
export class ContractsComponent implements OnInit {
    companyId: string;

    contracts: ContractDisplay[] = [];

    loading: Loading = {
        filters: true,
        contracts: true,
    };

    /**
     * Options available inside the filter elements
     * Not to be confused with the selected elements from the filter, these are hold by 'QueryFilter'
     */
    filtersAvailable: FiltersAvailable;

    queryFilter: QueryFilter;

    /**
     * @type {boolean} - isBranchesLoaded is used to set filters available values from requested company and its branches
     */
    private isBranchesLoaded = false;

    pagination: Pagination = {
        lastItem: {
            _id: null,
            index: 0,
        },
    };

    constructor(
        private contractsService: ContractsService,
        private sessionService: SessionService,
        private paginationService: PaginationBackService,
        private energyService: EnergyService,
        private filterService: FilterService
    ) {}

    async ngOnInit(): Promise<void> {
        this.paginationService.sort = {
            asc: true,
            field: 'reference',
        };
        await this.loadData();
    }

    /**
     * Load filter
     */
    async loadData(): Promise<any> {
        try {
            await this.initFilter();
        } catch (err) {
            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des factures. Veuillez réessayer ultérieurement.',
                'warning'
            );
            throw JSON.stringify(err);
        }
    }

    /**
     * ------
     * Initialization of the filter
     * ------
     */
    async initFilter(): Promise<void> {
        await this.setFilters();
        this.loading.filters = false;
        return;
    }

    /**
     * ------
     * Initialization of the contracts
     * ------
     */

    /**
     * Load the company's contracts sorted and respecting the current pagination
     * Set their notice state from their dates
     */
    async loadContracts(): Promise<void> {
        try {
            // 1. create the pagination query from current pagination elements
            const queryPagination: QueryPagination = this.paginationService.getPaginationQuery(
                this.pagination.lastItem
            );

            // 2. get contracts
            const res = await this.contractsService.getContractsFromPagination(
                this.companyId,
                this.queryFilter,
                queryPagination,
                this.paginationService.sort
            );

            // 3. set the notice state on each contract
            this.contracts = res.results.map(contract => {
                const c: ContractDisplay = this.contractsService.initNoticeState(contract);
                return c;
            });

            // 4. reload pagination elements from the new set of contracts
            this.paginationService.updateLastItem(this.pagination.lastItem, this.contracts);
            this.paginationService.updateAfterSearch(res.total);

            this.loading.contracts = false;

            return;
        } catch (e) {
            this.contracts = [];

            // reset pagination to first page for an empty array
            this.paginationService.emptyPagination();
            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des contrats. Veuillez réessayer ultérieurement.',
                'warning'
            );

            this.loading.contracts = false;
            return;
        }
    }

    /**
     * ------
     * Filters interactions
     * ------
     */

    /**
     * Handle a change inside the filter.
     * Reload contracts with new query.
     * @param {QueryFilter} queryFilter
     */
    async onFilterChanged(queryFilter: QueryFilter) {
        this.queryFilter = queryFilter;

        const loadBranches = _.get(this.queryFilter, 'includeBranches', false);
        if ((loadBranches && !this.isBranchesLoaded) || (!loadBranches && this.isBranchesLoaded)) {
            this.isBranchesLoaded = !this.isBranchesLoaded;
            await this.setFilters({ loadBranches });
        }

        this.paginationService.setFirstPage();
        // Reset last item
        this.pagination.lastItem = { _id: null, index: 0 };

        await this.loadContracts();
    }

    /**
     * -----
     * Table interactions
     * -----
     */

    /**
     * @returns {boolean} true when contracts are loaded and at least one contract is found
     */
    hasContracts(): boolean {
        return Boolean(!this.loading.contracts && this.contracts && this.contracts.length);
    }

    /**
     * Returns true when contracts are done loading.
     * @return {boolean}
     */
    canShowContractsTable(): boolean {
        return this.contracts && !this.loading.contracts;
    }

    /**
     * Called when pagination is updated (selected page or nb of items per pages)
     * or when a contract has been edited
     * Reload the contracts accordingly
     */
    reloadContractsList($event = { resetLastItem: false }) {
        if ($event.resetLastItem) {
            this.paginationService.updateLastItem(this.pagination.lastItem, []);
        }
        this.loadContracts();
    }

    /**
     * Called when sort has changed on contracts table
     * Reload the contracts accordingly
     * @returns {Promise<void>}
     */
    async onSortChanged(): Promise<void> {
        this.paginationService.setFirstPage();
        this.pagination.lastItem = { _id: null, index: 0 };
        return this.loadContracts();
    }

    /**
     * set filters
     * @param {{loadBranches: boolean}} param
     */
    async setFilters(param: { loadBranches: boolean } = { loadBranches: false }) {
        this.companyId = this.sessionService.getCompanyId();

        const [fluids, years, providers] = await Promise.all([
            this.energyService.getFluidsAvailable(this.companyId, param),
            this.filterService.getYearsWithData(true, param),
            this.filterService.getContractProviders(this.companyId, param),
        ]);

        // set selected fluids compared to available fluids
        if (this.queryFilter && this.queryFilter.fluids) {
            this.queryFilter.fluids = _.intersection(this.queryFilter.fluids, fluids);
        }

        // set selected providers compared to available providers
        if (this.queryFilter && this.queryFilter.providers) {
            this.queryFilter.providers = _.intersection(this.queryFilter.providers, providers.data);
        }

        let yearsMin = null;
        let yearsMax = null;
        if (years && years.length) {
            yearsMin = years[0].value;
            yearsMax = years[years.length - 1].value;
        } else {
            const currentYear = new Date().getFullYear();
            yearsMin = currentYear.toFixed(0);
            yearsMax = currentYear.toFixed(0);
        }

        const contractStates = this.contractsService.getNoticeStatesFilter();

        this.filtersAvailable = {
            searchValue: {
                enabled: true,
                placeholder: 'N° de contrat',
            },
            years: {
                enabled: true,
                yearsMin,
                yearsMax,
                setDefault: false,
                allowMissingBound: true,
            },
            fluids: {
                enabled: true,
                values: fluids,
            },
            providers: {
                enabled: true,
                values: providers.data,
            },
            contractStates: {
                enabled: true,
                values: contractStates,
            },
            branches: {
                enabled: true,
            },
        };
    }
}
