import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterQuery } from 'app/shared/components/common/filter/filter.component';
import Swal from 'sweetalert2';
import { KeyFigures } from '../../shared/models/key-figure.interface';
import { LastVisitedSites } from '../../shared/models/last-visited-sites.interface';
import { Marker } from '../../shared/models/marker.interface';
import { ChartService } from '../../shared/services/chart/chart.service';
import { MapService } from '../../shared/services/map/map.service';
import { SessionService } from '../../shared/services/session/session.service';
import { HomeChartData, HomeDonutData, HomeMonthlyConsumptionData, HomeService } from './home.service';

export interface LoadingHome {
    chartBar: boolean;
    chartDonut: boolean;
    keyFigures: boolean;
    lastVisited: boolean;
}

@Component({
    selector: 'ga-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [HomeService],
})
export class HomeComponent implements OnInit {
    keyFigures: KeyFigures;
    filterName = 'main';
    lastVisitedSites: LastVisitedSites[] = [];

    loading: LoadingHome = {
        chartBar: false,
        chartDonut: false,
        keyFigures: false,
        lastVisited: false,
    };

    dataTiles: any[] = [];
    markers: Marker[] = [];
    sites = [];

    currentBarDataset = 0;

    // bar chart
    monthlyChartProperties: any = {
        data: [],
        labels: [],
        colors: [],
        width: 1250,
        height: 340,
        options: [],
        legend: [],
        toggleTitles: ['HTVA (€)', 'TTC (€)'],
        downloadTitles: [
            'Répartition des coûts énergétiques HTVA sur l’année (€)',
            'Répartition des coûts énergétiques TTC sur l’année (€)',
        ],
        units: ['€', '€'],
    };

    // doughnut chart
    fluidsChartProperties = {
        data: [],
        legend: [],
        toggleTitles: ['HTVA (€)', 'TTC (€)'],
        downloadFileNames: [
            'Répartition de la facture énergétique HTVA sur la période',
            'Répartition de la facture énergétique TTC sur la période',
        ],
    };

    monthToTheEnd = [];
    refreshFilters = false;

    constructor(
        private chartService: ChartService,
        private homeService: HomeService,
        private mapService: MapService,
        private sessionService: SessionService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.getKeyFigures();
        this.route.queryParams.subscribe(queryParams => {
            // the home url has '?refresh=true' when the user has changed the company in the navbartop while he was already on the Home page
            if (queryParams.hasOwnProperty('refresh') && queryParams.refresh === 'true') {
                this.refreshFilters = true; // need to reload the filters
                this.router.navigateByUrl('accueil'); // reload the page to take into account the new company looged in

                this.getKeyFigures();
            }
        });
    }

    initializeLeftComponents() {
        // These queries do not need to be reloaded with filters, so we do them only once
        this.updateLastVisitedSites();
    }

    canShowKeyFigures() {
        return Boolean(!this.loading.keyFigures && this.keyFigures && this.keyFigures.sites);
    }

    setLoading() {
        this.loading.chartBar = true;
        this.loading.chartDonut = true;
    }

    /**
     * Launch search to update sites list and consumption data.
     * Fired by filters
     * @param {FilterQuery} query - query from filter
     */
    search(query: FilterQuery): void {
        this.setLoading();

        this.updateGlobalMonthlyConsumption(query);
        if (!this.lastVisitedSites.length || this.refreshFilters) {
            this.loading.lastVisited = true;
            this.lastVisitedSites = [];
        }
        // Better not using async/await here because callback is only linked to the function
        // Search function has multiple async function that doesn't require to wait for each other
        this.setMarkers(query).then(sites => {
            this.sites = sites;
            if (!this.lastVisitedSites.length) {
                this.initializeLeftComponents();
            }
        });
    }

    /**
     * Update last visited sites list
     */
    updateLastVisitedSites(): void {
        const lastVisitedSites = this.sessionService.getLastVisitedSites(); // contains only userId and siteId
        const lastVisitedSitesIds = lastVisitedSites.map(site => site.id);

        this.lastVisitedSites = this.sites
            .reduce((memo, site) => {
                if (lastVisitedSitesIds.includes(site._id)) {
                    const siteName = site.complement || site.name;
                    let address = site.streetName;

                    if (site.streetNumber) {
                        address = site.streetNumber + ' ' + site.streetName;
                    }
                    const siteDate = lastVisitedSites.find(element => element.id === site._id).date;

                    memo.push({
                        id: site._id,
                        complement: siteName,
                        address,
                        zipcode: site.zipcode,
                        city: site.city,
                        date: siteDate,
                        image: this.mapService.getSitePicture(site),
                    });
                }
                return memo;
            }, [])
            .sort((a: any, b: any) => {
                return a.date < b.date ? 1 : -1;
            });
        this.loading.lastVisited = false;
    }

    /**
     * Main query handling charts and tiles from same data
     * @param {FilterQuery} filters
     */
    async updateGlobalMonthlyConsumption(filters: FilterQuery): Promise<void> {
        // TODO: push into filters le nom de la catégorie dans l'array de la catégorie
        try {
            const response = await this.homeService.getMonthlyGlobalEnergyRepartition(filters);
            const data = response.data;

            // create array with all months between dateStart and dateEnd from filters
            this.monthToTheEnd = this.arrayWithMonthFilters(filters);

            const monthlyFormatedConsumption = this.homeService.formateToMonthlyConsumption(data, this.monthToTheEnd);

            const dataForDonut = this.homeService.refactoDataForDonut(monthlyFormatedConsumption);
            this.handleFluidRepartitionChartData(dataForDonut);

            this.handleMonthlyRepartitionChart(monthlyFormatedConsumption);
            this.handleTiles(data);
            this.refreshFilters = false;
        } catch (error) {
            this.loading.chartBar = false;
            this.loading.chartDonut = false;
        }
    }

    /**
     * Handle fluid repartition chart data
     * @param {HomeDonutData} data
     */
    handleFluidRepartitionChartData(data: HomeDonutData): void {
        const optionsDonutHT = this.chartService.getHighchartDonutConfig(data.data.ht, data.labels, '€');
        const optionsDonutTTC = this.chartService.getHighchartDonutConfig(data.data.ttc, data.labels, '€');
        const legend = this.chartService.getChartLegends(data.labels);

        this.fluidsChartProperties.data = [optionsDonutHT, optionsDonutTTC];
        this.fluidsChartProperties.legend = [legend, legend];
        this.loading.chartDonut = false;
    }

    /**
     * Get keyfigures for company and set it to component property keyfigures.
     */
    async getKeyFigures(): Promise<void> {
        this.loading.keyFigures = true;
        try {
            this.keyFigures = await this.homeService.getKeyFigures();
            this.loading.keyFigures = false;
        } catch (err) {
            this.loading.keyFigures = false;
            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des chiffres clefs',
                'error'
            );
        }
    }

    /**
     * Get sites then set markers
     * @param {FilterQuery} query
     * @returns {Promise<Array<*>>} sites list
     */
    async setMarkers(query: FilterQuery): Promise<any[]> {
        try {
            const response = await this.homeService.getSites(query);
            const sites: any[] = response.data;
            this.markers = sites.map(site => {
                const m: Marker = {
                    lat: site.latitude,
                    lng: site.longitude,
                    config: 'site',
                    data: this.mapService.getSiteDataForMarkersAddress(site),
                };
                return m;
            });
            return sites;
        } catch (err) {
            this.markers = [];
            return [];
        }
    }

    /**
     * Handle monthly repartition chart data
     */
    handleMonthlyRepartitionChart(monthlyFormatedConsumption: HomeMonthlyConsumptionData) {
        this.loading.chartBar = false;

        // TODO find better fix
        if (!monthlyFormatedConsumption.data.chart[0].length) {
            this.monthlyChartProperties = {
                data: [],
                labels: [],
                colors: [],
                width: 900,
                height: 340,
                options: [],
                legend: [],
                toggleTitles: ['€ HTVA', '€ TTC'],
                downloadTitles: [
                    'Répartition des coûts énergétiques HTVA sur l’année (€)',
                    'Répartition des coûts énergétiques TTC sur l’année (€)',
                ],
                units: ['€', '€'],
            };

            return;
        }

        const labels = this.getLabelsFromData(monthlyFormatedConsumption.data.chart[0]);
        const legend = this.chartService.getChartLegends(labels);
        const colors = this.chartService.getBarChartColors(labels);

        this.monthlyChartProperties.labels = this.monthToTheEnd;

        // We use it as toggled chart, so array of two times the same legend and colors here
        this.monthlyChartProperties.legend = [legend, legend];
        this.monthlyChartProperties.colors = [colors, colors];

        this.monthlyChartProperties.data = monthlyFormatedConsumption.data.chart;

        this.monthlyChartProperties.options[0] = this.chartService.getConfig('groupable-stacked-bar', {
            tooltips: this.chartService.getTooltipHTMLConfig('stack', null),
        });

        this.monthlyChartProperties.options[1] = this.chartService.getConfig('groupable-stacked-bar', {
            tooltips: this.chartService.getTooltipHTMLConfig('stack', null),
        });

        this.setUnit(this.monthlyChartProperties.data, this.monthlyChartProperties.units);
    }

    /**
     * Extract months from filters dateStart and dateEnd
     * @param {FilterQuery} filters
     * @returns {string[]} list of month at format MM/YYYY
     */
    arrayWithMonthFilters(filters: FilterQuery): string[] {
        const filterDateStart = filters.dateStart;
        const filterDateEnd = filters.dateEnd;
        let maxYear: number;

        const monthToTheEnd: string[] = [];

        for (maxYear = Number(filterDateStart.substr(0, 4)); maxYear <= Number(filterDateEnd.substr(0, 4)); maxYear++) {
            for (let currentMonth = 1; currentMonth <= 12; currentMonth++) {
                if (
                    maxYear === Number(filterDateEnd.substr(0, 4)) &&
                    currentMonth > Number(filterDateEnd.substr(5, 2))
                ) {
                    break;
                }
                if (
                    maxYear === Number(filterDateStart.substr(0, 4)) &&
                    currentMonth < Number(filterDateStart.substr(5, 2))
                ) {
                    continue;
                }
                if (currentMonth >= 10) {
                    monthToTheEnd.push(currentMonth + '/' + maxYear);
                } else {
                    monthToTheEnd.push('0' + currentMonth + '/' + maxYear);
                }
            }
        }

        return monthToTheEnd;
    }

    /**
     * Create data tiles
     * @param {*} data
     */
    handleTiles(data: any) {
        this.dataTiles = this.homeService.refactoDataForTiles(data);
    }

    /**
     * Change bar dataset
     * @param dataset
     */
    changeBarDataset(dataset: any) {
        this.currentBarDataset = dataset;
    }

    /**
     * Get labels from data
     * @param {Array<HomeChartData>} data
     * @returns {Array<string>}
     */
    getLabelsFromData(data: HomeChartData[]): string[] {
        return data.map(d => d.label);
    }

    /**
     * Populate the unit property of each fluid dataset of each toggle data array
     * @param dataTabs all tab in the view [ tab htva, tab ttc]
     *        each tab contains an array of dataset for each fluid
     *        tab htva =[ {data: [...], label: 'elec'}, {data: [...], label: 'gaz'} ]
     *        set tab htva =[ {data: [...], label: 'elec', unit :'€'}, {data: [...], label: 'gaz', unit :'€'} ]
     */
    private setUnit(dataTabs, units) {
        return dataTabs.map((dataTab, index) => {
            return dataTab.map(dataset => {
                dataset.unit = units[index];
                return dataset;
            });
        });
    }
}
