import { Component, Input, OnInit } from '@angular/core';
import { BillsControlsRecap } from 'app/shared/models/bills.interface';
import { ColorService } from 'app/shared/services/color/color.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

interface RecapPropertyDisplay {
    key: string;
    value: string;
    color: string;
    colorKey: string;
    tooltip?: string;
    icon?: string;
}

@Component({
    selector: 'ga-bills-control-recap-banner',
    templateUrl: './bills-control-recap-banner.component.html',
    styleUrls: ['./bills-control-recap-banner.component.scss'],
})
export class BillsControlRecapBannerComponent implements OnInit {
    @Input()
    set recapValues(val: BillsControlsRecap) {
        // For each recap values, set a format (two numbers after digits and space for thousand numbers)
        this.recapValuesDisplay.forEach(item => {
            const unit = item.key !== 'ratio' ? '€' : '%';
            const type = item.key !== 'ratio' ? 'money' : 'ratio';
            const value = val ? val[item.key] : null;
            const formatted = this.utilsService.formateNumberType(value, '-', type);
            item.value = `${formatted} ${unit}`;
            item.color = this.colorService.getRgbColor(item.colorKey);
        });
    }

    /**
     * Get the loading status in order to display a loading spinner in the recap banner
     */
    @Input() loading: boolean;

    public recapValuesDisplay: RecapPropertyDisplay[] = [
        {
            key: 'undercharged',
            value: '- €',
            color: this.colorService.getRgbColor('undercharged'),
            colorKey: 'undercharged',
            icon: 'fa-arrow-down',
        },
        {
            key: 'overcharged',
            value: '- €',
            color: this.colorService.getRgbColor('overcharged'),
            colorKey: 'overcharged',
            icon: 'fa-arrow-up',
        },
        {
            key: 'balance',
            value: '- €',
            color: this.colorService.getRgbColor('not_controlled'),
            colorKey: 'not_controlled',
        },
        {
            key: 'ratio',
            value: '- %',
            color: this.colorService.getRgbColor('not_controlled'),
            colorKey: 'not_controlled',
            tooltip: 'Ratio entre les écarts constatés et le total facturé pour les filtres sélectionnés',
        },
    ];

    constructor(private utilsService: UtilsService, private colorService: ColorService) {}

    ngOnInit() {}
}
