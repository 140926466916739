import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminCompanyService } from 'app/pages/admin/company/company.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-company-uploads-tab',
    templateUrl: './company-uploads.component.html',
    styleUrls: ['./company-uploads.component.scss'],
    providers: [],
})
export class CompanyUploadsComponent implements OnInit {
    @Input() company: any = null;
    @Input() canEdit = false;

    companyUploadsPatternMissing: any = [];
    companyUploadsNoPatterns: any = [];

    constructor(private companyService: AdminCompanyService, private router: Router) {}

    ngOnInit() {
        Promise.all([this.setUploadsPatternMissingList(), this.setUploadsNoPatternsList()]).then(
            results => {},
            error => {
                this.getSwalMsg(error.error_code);
            }
        );
    }

    getUploadRegexUrl(upload): string[] {
        return ['/collecte/regex/upload', upload._id];
    }

    getChunkRegexUrl(upload, chunk): string[] {
        return ['/collecte/regex/upload', upload._id, chunk._id];
    }

    getUploadIdentificationPatternUrl(upload): string[] {
        return ['/admin/entreprise', this.company._id, 'uploads', upload._id];
    }

    getChunkIdentificationPatternUrl(upload, chunk): string[] {
        return ['/admin/entreprise', this.company._id, 'uploads', upload._id, 'chunks', chunk._id];
    }

    getSwalMsg(msg_code) {
        const messages = {
            error_getListUploads: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des uploads.',
                'error',
            ],
        };

        if (messages[msg_code]) {
            Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
        }
    }

    setUploadsPatternMissingList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.companyService.getUploadPatternMissingList(this.company).then(
                allUploads => {
                    if (allUploads.length) {
                        // companyUsers: push all the users belonging to the company + being of type 'user' or 'energy manager'
                        this.companyUploadsPatternMissing = allUploads;
                    }
                },
                err => {
                    // err.code is set to 'error_getListUsers';
                    reject(err);
                }
            );
        });
    }

    setUploadsNoPatternsList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.companyService.getUploadNoPatternsList(this.company).then(
                allUploads => {
                    if (allUploads.length) {
                        // companyUsers: push all the users belonging to the company + being of type 'user' or 'energy manager'
                        this.companyUploadsNoPatterns = allUploads;
                    }
                },
                err => {
                    // err.code is set to 'error_getListUsers';
                    reject(err);
                }
            );
        });
    }
}
