import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'ng2-charts-doughnut',
    templateUrl: './ng2-charts-doughnut.component.html',
})
export class Ng2DoughnutChartComponent implements OnInit {
    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

    @Input() doughnutChartWidth = 900;
    @Input() doughnutChartHeight = 340;
    @Input() doughnutChartData: number[];
    @Input() doughnutChartDataset: any[];
    @Input() doughnutChartLabels: string[];
    @Input() doughnutChartOptions: any = {};
    @Input() doughnutChartColors: any[];
    @Input() doughnutChartLegend: any;

    @Output() chartInit: EventEmitter<object> = new EventEmitter<object>();
    @Output() emitChartClick = new EventEmitter();

    public doughnutChartType = 'doughnut';

    public constructor() {}

    ngOnInit() {
        this.chartInit.emit(this.chart);
    }

    public chartClicked(e: any): void {
        this.emitChartClick.emit(e);
    }

    // events
    public chartHovered(e: any): void {}
}
