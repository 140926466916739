import { defer, fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { buildGtmJsUrl } from './build-gtm-js-url';
import { GoogleAnalytics } from './google-analytics';

/**
 * Loads the Google Analytics function
 *
 * @param document Document
 * @param gaId Google Analytics identifier
 * @returns Google Analytics function
 */
export function loadGoogleAnalytics(document: Document, gaId: string): Observable<GoogleAnalytics> {
    return defer(() => {
        const script = document.createElement('script');
        const src = buildGtmJsUrl(gaId);

        script.async = true;
        script.src = src;

        const dataLayer = (window.dataLayer = window.dataLayer === undefined ? [] : window.dataLayer);

        function gtag(..._args: ReadonlyArray<unknown>) {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());

        const loaded$ = fromEvent(script, 'load');

        document.head.appendChild(script);

        return loaded$.pipe(
            map(() => {
                gtag('config', gaId);
                return gtag;
            })
        );
    });
}

declare global {
    interface Window {
        dataLayer?: unknown[];
    }
}
