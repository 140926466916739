import { Component, OnInit } from '@angular/core';

import { ChartService } from 'app/shared/services/chart/chart.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import Swal from 'sweetalert2';
import { RepartitionService } from './repartition.service';

@Component({
    selector: 'ga-repartition',
    templateUrl: './repartition.component.html',
    styleUrls: ['./repartition.component.scss'],
    providers: [RepartitionService],
})
export class RepartitionComponent implements OnInit {
    sankeyProperties: any = {
        options: {},
        legend: [],
        title: 'Répartition des flux de consommation',
    };

    sankeyToggle = [
        {
            name: 'Consommations',
            display: true,
            isCurrency: false,
        },
        {
            name: 'Coût (HTVA)',
            display: false,
            isCurrency: true,
        },
        {
            name: 'Coûts (TTC)',
            display: false,
            isCurrency: true,
        },
    ];

    sankeyData: any[] = [];

    // For creating sides labels as Highcharts doesn't includes it
    sankeyHighchartsData: any[] = [];

    isLoadingData = false;

    hasConsumptionData = false;

    filters: any = {};

    // The display is, for now, always true
    columnsUser: Array<{
        name: string;
        display: boolean;
    }> = [
        {
            name: 'energyType',
            display: true,
        },
        {
            name: 'nature',
            display: true,
        },
    ];

    unit = '';

    constructor(
        private chartService: ChartService,
        private repartitionService: RepartitionService,
        private energieService: EnergyService,
        private sitesService: SitesService
    ) {}

    ngOnInit() {
        this.isLoadingData = true;
    }

    /**
     * Create the sankey chart with data loading.
     * @param filters from the filter component for the search query.
     */
    handleSankeyChart(filters) {
        this.repartitionService
            .getSitesWithContracts(filters)
            .then(sites => {
                return this.repartitionService.getSitesProperties().then(properties => {
                    sites.data.forEach(site => {
                        this.sitesService.setSiteProperties(site, properties.data);
                    });
                    this.sankeyData = sites.data;
                    this.updateUnit();
                    return this.treeRunSankey(this.sankeyData).then((data: any) => {
                        if (data.data.length > 0) {
                            this.sankeyProperties.options = this.chartService.getHightchartSankeyConfig(data);
                            this.hasConsumptionData = true;
                        } else {
                            this.hasConsumptionData = false;
                        }
                        this.isLoadingData = false;
                    });
                });
            })
            .catch(error => {
                this.hasConsumptionData = false;
                this.isLoadingData = false;
                Swal.fire(
                    'Toutes nos excuses',
                    "Une erreur s'est produite lors du chargement des données de vos sites",
                    'error'
                );
            });
    }

    /**
     * Refresh the diagram without loading new data. For example, when adding/removing/changing a column or toggling from cost to consumption
     */
    public refreshSankey() {
        this.isLoadingData = true;
        this.updateUnit();
        this.treeRunSankey(this.sankeyData).then((data: any) => {
            if (data.data.length > 0) {
                this.sankeyProperties.options = this.chartService.getHightchartSankeyConfig(data);
                this.hasConsumptionData = true;
            } else {
                this.hasConsumptionData = false;
            }
            this.isLoadingData = false;
        });
    }

    /**
     * Start the research with new criterias
     * @param filters from the filter component
     */
    public search(filters) {
        this.isLoadingData = true;
        this.filters = filters;
        this.handleSankeyChart(filters);
    }

    /**
     * Process data to create Highchart sankey formatted series
     * @param data from the request
     * @returns {Promise<any>} Promise with the series at the Highcharts sankey format : [[from, to, weight], ...]
     */
    private treeRunSankey(data: any) {
        return new Promise(resolve => {
            const registre = [];
            const sites = data;

            for (let i = 0; i < this.columnsUser.length - 1; i++) {
                registre[i] = [];
            }

            sites.forEach(site => {
                if (site) {
                    if (!site.info.diviser && site.category && site.nature) {
                        if (site.conso) {
                            for (const key in site.conso) {
                                if (!site.conso.hasOwnProperty(key)) {
                                    continue;
                                }
                                const weight = this.calculateWeight(site.conso[key], key);
                                if (weight > 0) {
                                    for (let i = 0; i < this.columnsUser.length - 1; i++) {
                                        if (this.columnsUser[i].display) {
                                            this.sankeyDuplicatesHighcharts(
                                                registre[i],
                                                this.createSegment(
                                                    this.columnsUser[i].name,
                                                    this.columnsUser[i + 1].name,
                                                    site,
                                                    weight,
                                                    key
                                                )
                                            );
                                        }
                                    }
                                }
                            }
                        }
                    } else if (site.info.diviser && site.conso) {
                        for (const key in site.conso) {
                            if (!site.conso.hasOwnProperty(key)) {
                                continue;
                            }
                            site.category = 'PDL Non Associé';
                            site.nature = 'PDL Non Associé ';
                            site.name = site.name ? site.name : 'PDL Non Associé  ';
                            const weight = this.calculateWeight(site.conso[key], key);
                            if (weight > 0) {
                                for (let i = 0; i < this.columnsUser.length - 1; i++) {
                                    if (this.columnsUser[i].display) {
                                        this.sankeyDuplicatesHighcharts(
                                            registre[i],
                                            this.createSegment(
                                                this.columnsUser[i].name,
                                                this.columnsUser[i + 1].name,
                                                site,
                                                weight,
                                                key
                                            )
                                        );
                                    }
                                }
                            }
                        }
                    } else if (site.info.diviser && site.batiments.length > 0) {
                        site.batiments.forEach(zone => {
                            if (zone.category && zone.nature && zone.name) {
                                if (zone.routingReferencesConso && zone.routingReferencesConso.length > 0) {
                                    zone.routingReferencesConso.forEach(rfConso => {
                                        if (rfConso.conso && Object.keys(rfConso.conso).length !== 0) {
                                            for (const key in rfConso.conso) {
                                                if (!rfConso.conso.hasOwnProperty(key)) {
                                                    continue;
                                                }
                                                const weight = this.calculateWeight(rfConso.conso[key], key);
                                                if (weight > 0) {
                                                    for (let i = 0; i < this.columnsUser.length - 1; i++) {
                                                        if (this.columnsUser[i].display) {
                                                            zone.parent = site.name;
                                                            zone.zipcode = site.zipcode;
                                                            this.sankeyDuplicatesHighcharts(
                                                                registre[i],
                                                                this.createSegment(
                                                                    this.columnsUser[i].name,
                                                                    this.columnsUser[i + 1].name,
                                                                    zone,
                                                                    weight,
                                                                    key
                                                                )
                                                            );
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            });

            const series = [];
            // Get the total weight for percentages
            const total = registre[0].reduce((a, b) => a + b[2], 0);
            for (let i = 0; i < this.columnsUser.length - 1; i++) {
                if (this.columnsUser[i].display) {
                    series[i] = registre[i];
                }
            }

            // Here we sort the series, to have it displayed clearer (avoid unecessary segements crossing)
            series.forEach(serie => {
                serie.sort((a, b) => {
                    if (!a[0]) {
                        return 1;
                    }
                    if (!b[0]) {
                        return -1;
                    }
                    const sort1 = a[0].localeCompare(b[0]);
                    if (sort1 !== 0) {
                        return sort1;
                    }
                    if (!a[1]) {
                        return 1;
                    }
                    if (!b[1]) {
                        return -1;
                    }
                    return a[1].localeCompare(b[1]);
                });
            });

            this.sankeyHighchartsData = series;
            resolve({ data: series, total, unit: this.unit });
        });
    }

    /**
     * Create one segment for highcharts sankey diagram
     * @param {string} colFrom name of the origin column
     * @param {string} colTo name of the destination column
     * @param site object of the site
     * @param {number} weight of the segment
     * @param {string} key energy type
     * @returns {Array<any>} the segment [from, to, weight]
     */
    private createSegment(colFrom: string, colTo: string, site, weight: number, key: string): any[] {
        let from;
        let to;
        const fillCol = (col, ts) => {
            let res;
            switch (col) {
                case 'energyType':
                    res = ts.energyFullText(key);
                    break;
                case 'zone':
                    res = site.name + ' ';
                    break;
                case 'name':
                    res = site.parent ? site.parent : site[col];
                    break;
                case 'zipcode':
                    if (site.region) {
                        res = site.region;
                    } else {
                        res = site.zipcode ? site.zipcode : 'Code postal inconnu';
                    }
                    break;
                case 'category':
                    res = site[col] ? site[col] : 'Catégorie inconnue';
                    break;
                case 'nature':
                    res = site[col] ? site[col] : 'Nature inconnue';
                    break;
                case 'subcategory':
                    res = site[col] ? site[col] : 'Sous-catégorie inconnue';
                    break;
                case 'complement':
                    res = site[col] ? site[col] : 'Complément inconnu';
                    break;
                default:
                    res = site[col] ? site[col] : 'Erreur inconnue';
                    break;
            }
            return res;
        };
        from = fillCol(colFrom, this.energieService);
        to = fillCol(colTo, this.energieService);
        return [from, to, weight];
    }

    /**
     * Add value to the registre if not a duplicate. Increment otherwise.
     * Applicable for Highcharts only
     **/
    private sankeyDuplicatesHighcharts(registre: any[], value: any[]) {
        let found = false;
        for (const r of registre) {
            if (r[0] === value[0] && r[1] === value[1]) {
                r[2] += value[2];
                found = true;
            }
        }
        if (!found) {
            registre.push(value);
        }
    }

    /**
     * Calculate the weight. Convert it if multiple energies
     * @param conso consumption object
     * @param key energy type of the consumption
     * @returns {number} Weight
     */
    private calculateWeight(conso, key) {
        let weight = 0;
        if (this.sankeyToggle[0].display) {
            if (this.filters.energies && this.filters.energies.split(',').length > 1) {
                weight = this.energieService.convertEnergyKwhToKwhep(key, conso.quantity);
            } else {
                weight = conso.quantity;
            }
        } else if (this.sankeyToggle[1].display) {
            weight = conso.totalHT;
        } else if (this.sankeyToggle[2].display) {
            weight = conso.totalTTC;
        }
        return weight;
    }

    public hasDataForSankey() {
        return !this.isLoadingData && this.hasConsumptionData;
    }

    public updateUnit() {
        const selected = this.sankeyToggle.filter(x => x.display);
        if (selected.length > 0) {
            if (selected[0].isCurrency) {
                this.unit = '€';
            } else {
                if (this.filters.energies) {
                    if (this.filters.energies.split(',').length > 1) {
                        this.unit = 'kWhep';
                    } else {
                        this.unit = 'kWh';
                    }
                }
            }
        }
    }
}
