import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { RRefRename } from './rref-rename.interface';

@Component({
    selector: 'rref-rename',
    templateUrl: './rref-rename.component.html',
    styleUrls: ['./rref-rename.component.scss'],
})
export class RrefRenameComponent implements OnInit {
    /** Routing reference with minimal info to display */
    @Input() public rRef: RRefRename;

    /**
     * Event fired when routing reference renamed or alias added.
     * True when changes made, false if no changes.
     */
    @Output() private rRefRenamed: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Form group to handle inputs
     */
    public form: FormGroup;

    /**
     * Message to display
     */
    public message: string = null;

    /** Is message to display an error message */
    public isMessageError = false;

    /** Initial values to detect if changes have been made */
    private iniValues: {
        reference: string;
        alias: string[];
    } = {
        reference: null,
        alias: [],
    };

    constructor(
        private routingReferencesService: RoutingReferencesService,
        private fb: FormBuilder,
        private translateService: TranslateService
    ) {
        this.form = fb.group({
            reference: ['', Validators.required],
            newAlias: [''],
        });
    }

    ngOnInit() {
        this.form.setValue({
            reference: this.rRef.reference,
            newAlias: '',
        });
        this.iniValues = {
            reference: this.rRef.reference,
            alias: [].concat(this.rRef.alias),
        };
    }

    /**
     * Add alias
     */
    public async addAlias() {
        const value = this.form.get('newAlias').value;
        if (!value) {
            return;
        }
        // API Call to add alias to routing reference
        // Add alias to list
        this.rRef.alias.push(value);
        this.form.get('newAlias').setValue('');
        this.message = this.translateService._('alias_added') + ` : ${value}`;
        this.isMessageError = false;
    }

    /**
     * Save routing reference's new reference and alias list
     */
    public async renameRRef() {
        try {
            const reference = this.form.get('reference').value;
            if (!reference) {
                return;
            }
            const alias = this.rRef.alias;
            const hasChanged = this.hasValuesChanged(reference, alias);
            if (hasChanged) {
                // Add alias to list
                await this.routingReferencesService.updateRRefAlias({
                    routingReferenceId: this.rRef._id,
                    reference,
                    alias,
                });
            }

            this.rRefRenamed.emit(hasChanged);
        } catch (e) {
            if (e.errorCode === 'reference_already_existing') {
                this.message = `L'alias existe déjà`;
                this.isMessageError = true;
            } else {
                this.message = `Une erreur s'est produite pendant l'enregistrement (${e.message})`;
                this.isMessageError = true;
                throw e;
            }
        }
    }

    /**
     * Returns true if reference or alias list has changed
     * @param {string} reference - last reference value
     * @param {string[]} alias - last alias list
     */
    private hasValuesChanged(reference: string, alias: string[]): boolean {
        if (reference !== this.iniValues.reference) {
            return true;
        }
        if (alias.length !== this.iniValues.alias.length) {
            return true;
        }
        if (alias.some(x => !this.iniValues.alias.includes(x))) {
            return true;
        }
        return false;
    }

    /**
     * Clear message
     */
    public clearMessage() {
        this.message = null;
    }

    /**
     * Get alert message classes
     */
    public get alertClasses(): string[] {
        return [this.isMessageError ? 'alert-danger' : 'alert-success'];
    }
}
