import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'ga-chart-line',
    templateUrl: './chart-line.component.html',
    styleUrls: ['./chart-line.component.scss'],
})
export class ChartLineComponent {
    private _colors: any[];
    public chartInstance: any = {};

    @Input() downloadFileName = 'chart-line.png';

    @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;

    @Input() data: any[];
    @Input() dataset: any[];
    @Input() labels: object[];
    @Input() width = 900;
    @Input() minHeight: number = null;
    @Input() options: object = {};
    @Input() legend: object = {};
    @Input() fill = false;
    @Input()
    set colors(colors: any[]) {
        this._colors = colors.map(color => {
            color.fill = this.fill;
            return color;
        });
    }
    get colors(): any[] {
        return this._colors;
    }

    @Output() emitChartClick = new EventEmitter();

    setChartInstance(chart) {
        this.chartInstance = chart;
    }

    chartClicked(event) {
        this.emitChartClick.emit(event);
    }

    updateChart() {
        // Reinit datasets as when refreshing, expects old and new one to have both the same size.
        this.chartInstance.chart.data.datasets = [];
        setTimeout(() => {
            this.chartInstance.refresh();
        });
    }
}
