import { Injectable } from '@angular/core';
import { MeteoStation } from 'app/shared/models/meteo-station.interface';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

@Injectable()
export class MeteoService extends PageService {
    /** List of all meteo stations */
    private meteoStations: MeteoStation[] = null;

    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get available dju temperatures
     */
    async getMeteoStations(): Promise<MeteoStation[]> {
        if (!this.meteoStations) {
            const resp = await this.get(`/api/meteo-stations`);
            this.meteoStations = resp.data;
            this.meteoStations.sort((a, b) => a.name.localeCompare(b.name));
        }
        return this.meteoStations;
    }

    /**
     * Retrieve info of a given meteo station
     */
    public async getMeteoStation(stationId: string): Promise<MeteoStation> {
        const stations = await this.getMeteoStations();
        return stations.find(x => x._id === stationId);
    }
}
