import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SessionService } from 'app/shared/services/session/session.service';
import { ConfigService } from '../../shared/services/config/config.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    providers: [],
})
export class FooterComponent implements OnInit {
    versionString: string = null;

    constructor(private sessionService: SessionService) {}

    ngOnInit() {
        const version = this.sessionService.getVersion();
        if (version) {
            this.versionString = `- ${version}`;
        }
    }
}
