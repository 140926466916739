import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';

import { AccessGuard } from 'app/shared/services/guards/access-guard.service';
import { OperatingMonitoringComponent } from './operating-monitoring.component';

const OPERATING_MONITORING_ROUTE = [
    {
        path: '',
        component: OperatingMonitoringComponent,
        canActivate: [AccessGuard],
        data: { redirectionForbiddenUrl: '/accueil', page: 'targets' },
    },
];

@NgModule({
    declarations: [OperatingMonitoringComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(OPERATING_MONITORING_ROUTE)],
})
export class OperatingMonitoringModule {}
