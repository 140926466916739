import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';

import { HttpHeaders } from '@angular/common/http';
import { LegacyData } from 'app/shared/legacy-data.interface';
import { FileStorage } from 'app/shared/models/file-storage.interface';
import { Observable } from 'rxjs';
import { Question } from '../../models/question.interface';
import { ApiService } from '../api/api.service';
import { FileStorageService } from '../files/file-storage.service';
import { PageService } from '../page/page.service';

interface SaveOptions {
    values: Array<{
        key: string;
        value: any;
    }>;
    site: string;
    zone: string;
}

interface FileDeleteOptions {
    site: string;
    zone: string;
    keys: any[];
    fileId?: string;
    index?: number;
    groupDelete?: boolean;
}

@Injectable()
export class QuestionService extends PageService {
    constructor(
        private factoryResolver: ComponentFactoryResolver,
        public apiService: ApiService,
        private fileStorageService: FileStorageService
    ) {
        super(apiService);
    }

    /**
     * Replace the ng-template by the a newly created question component in the DOM
     * @param data: from db
     * @param config: question config
     * @param site: siteID
     * @param zone: zoneID
     * @param {Type<Question>} type: Type of the Question to create
     * @param {ViewContainerRef} vcr: Reference to the DOM element
     * @returns {{change: EventEmitter<any>; fileUpload: EventEmitter<any>}} Events returned by the component.
     * Change : triggered each time a value is changed. fileUpload : triggered when a file is selected for file upload.
     */
    public addComponent(data, config, site, zone, type: Type<Question>, vcr: ViewContainerRef) {
        const factory = this.factoryResolver.resolveComponentFactory(type);
        const component = factory.create(vcr.injector);
        component.instance.data = data;
        component.instance.config = config;
        component.instance.siteId = site;
        component.instance.zoneId = zone;
        const change = component.instance.valueChange;
        const fileUploadEvent = component.instance.uploadFile;
        vcr.insert(component.hostView);
        return { change, fileUpload: fileUploadEvent };
    }

    /**
     * save file in db + link its id to the company's logo / user's picture
     * @param file: file object from the input (DOM)
     * @param values: contains information about the legacy data associated to the
     * @returns {Observable<any>}
     */
    uploadFile(file, values): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('siteId', values.siteId);
        formData.append('key', values.key);
        if (Number.isInteger(values.index)) {
            formData.append('index', values.index);
        }

        const options = {
            reportProgress: true,
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: 'Basic ' + this.apiService.getToken(),
            }),
        };

        return this.apiService.upload('/api/pictures/upload/file/legacy-data', formData, options);
    }

    /**
     * Get information about a file
     * @param fileId: ID of the file
     */
    async getUploadFile(fileId: string): Promise<FileStorage> {
        return this.fileStorageService.getInfo(fileId);
    }

    /**
     * Save legacy data.
     * @param options to send that need to contain { values: [{key: string, value}], site: string, zone: string (opt.) }
     */
    async saveLegacyData(options: SaveOptions): Promise<APICitronResponse<LegacyData[]>> {
        return this.post('/api/sites/legacy-data', options);
    }

    /**
     * Delete files
     * @param options to send of format {site: string, zone: string, key: [string]}
     * Can also contain : fileId: string || index: string|number, groupDelete: boolean
     * @returns true if successfull
     */
    async deleteFiles(options: FileDeleteOptions): Promise<boolean> {
        const response = await this.post('/api/sites/legacy-data/files/delete', options);
        return response.data;
    }
}
