import { Injectable } from '@angular/core';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

@Injectable()
export class ElecPdlViewService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    getRoutingReferenceById(routingReferenceId): Promise<any> {
        return this.get('/api/routing-references/' + routingReferenceId);
    }

    getYears(contractId: string = '', routingReference: string, energyType: string = '') {
        let route = '/api/common/available-years?routingReference=' + routingReference;

        if (contractId) {
            route += `&contract=${contractId}`;
        }

        if (energyType) {
            route += `&energyType=${energyType}`;
        }

        return this.get(route);
    }
}
