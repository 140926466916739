import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import Swal, { SweetAlertIcon } from 'sweetalert2';

import { Condition } from 'app/shared/models/scenario.model';
import { AlertsService, ComparedPropertyItem, ThresholdItem } from 'app/shared/services/alerts/alerts.service';
import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';

@Component({
    selector: 'ga-alerts-condition-builder',
    templateUrl: './alerts-condition-builder.component.html',
    styleUrls: ['./alerts-condition-builder.component.scss'],
    providers: [],
})
export class AlertsConditionBuilderComponent implements OnInit {
    /**
     * Condition to display
     */
    @Input() condition: Condition;

    /**
     * Set read only view for the component
     */
    @Input() readonly = false;

    /**
     * Set if the condition is from a scenario that already exists or not
     */
    @Input() isCreating = false;

    /**
     * Triggered when a change occurs (value changed)
     * Emit last version of Condition
     * true for asynchronous event delivery. => Avoid update outside angular cycle
     */
    @Output() changes: EventEmitter<Condition> = new EventEmitter<Condition>(true);

    /**
     * Triggered when a change occurs (value changed)
     * Emit true if condition is correct, false otherwise
     */
    @Output() conditionValidate: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    /**
     * List of all routing references available
     */
    public routingReferences: any[] = [];

    /**
     * Selected routing reference
     */
    public selectedRoutingRef: { [key: string]: any } = null;

    /**
     * Sources properties available
     */
    public comparedProperties: ComparedPropertyItem[];

    public thresholds: ThresholdItem[] = [];

    public selectedComparedProperty: string = null;
    public selectedOperator: string = null;
    public selectedValue: number = null;
    public selectedThreshold: string = null;

    public queryValidation = {
        invalidSource: false,
        invalidOperator: false,
        invalidValue: false,
        invalidRoutingReference: false,
    };

    public errorMessage = '';
    public successMessage = '';
    public isLoading = false;
    public checkAll = false;

    public operators: string[] = ['>', '<', '='];

    constructor(private routingReferencesService: RoutingReferencesService, private alertsService: AlertsService) {}

    ngOnInit() {
        this.init();
    }

    private async init() {
        try {
            this.isLoading = true;
            this.comparedProperties = this.alertsService.getConditionConfig();
            const res = await this.routingReferencesService
                .getRoutingReferencesAssociated({ energies: 'elec' })
                .catch(e => {
                    if (!e.errorCode) {
                        e.errorCode = 'failed_get_routing_references';
                    }
                    return Promise.reject(e);
                });

            this.routingReferences = res;
            this.setExistingCondition();
            this.send();
        } catch (e) {
            this.handleErrorMessage(e.errorCode);
        }
        this.isLoading = false;
    }

    /**
     * On edit, select routing reference with their source property, operator and value from conditions
     */
    setExistingCondition() {
        if (this.condition) {
            this.selectedValue = this.condition.thresholdValue;
            this.selectedOperator = this.condition.comparator;
            this.selectedComparedProperty = this.condition.comparedProperty;
            this.updateComparisonProperty(this.selectedComparedProperty);
            this.selectedThreshold = this.condition.thresholdProperty;
            const pdl = this.routingReferences.find(x => x._id === this.condition.scope.routingReference);
            if (pdl) {
                this.selectRoutingReference(pdl);
            }
            if (!pdl && !this.condition.scope.routingReference && this.condition.scope.allRoutingReferences) {
                this.checkAll = true;
            }
            this.send();
        }
    }

    /**
     * Set given routing reference as selected.
     * @param {RoutingReference} routingReference - routing reference to selected
     */
    selectRoutingReference(routingReference: any) {
        this.selectedRoutingRef = routingReference;
        this.checkAll = false;
        this.send();
    }

    /**
     * Get if the given routing reference is currently selected
     * @param {RoutingReference} routingReference - routingReference
     * @returns {boolean} true if routing reference is selected, returns false otherwise
     */
    isSelected(routingReference: any): boolean {
        return this.selectedRoutingRef ? this.selectedRoutingRef._id === routingReference._id : false;
    }

    /**
     * Select all routing references
     */
    selectAll() {
        this.selectedRoutingRef = null;
        this.checkAll = true;
        this.send();
    }

    /**
     * Get routing reference's fluid icon
     * @param {RoutingReference} routingReference - item to get the icon
     * @returns {string[]} class(es) of fluid icon
     */
    getRoutingReferenceIcon(routingReference: any): string[] {
        if (!routingReference || !routingReference.energyType) {
            return ['unknown-icon'];
        }
        return [`${routingReference.energyType}-icon`];
    }

    /**
     * Update compared property value
     * @param {string} value - value (key) of compared property selected.
     */
    onComparedPropertyChanged(value: string) {
        this.updateComparisonProperty(value);
        this.send();
    }

    onRetroactivityChanged(checked: boolean) {
        this.condition.scope.isRetroactive = checked;
        this.send();
    }

    /**
     * Update thresholds properties selectbox choices
     * @param {string} comparedPropertyValue - compared property selected value
     */
    private updateComparisonProperty(comparedPropertyValue: string) {
        const prop = this.comparedProperties.find(x => x.value === comparedPropertyValue);
        if (prop) {
            this.thresholds = prop.items;
            if (this.thresholds.length) {
                this.selectedThreshold = this.thresholds[this.thresholds.length - 1].value;
            } else {
                this.selectedThreshold = null;
            }
        } else {
            this.thresholds = [];
            this.selectedThreshold = null;
        }
    }

    /**
     * Check condition values and send them to parent component via event emitters.
     * One event for the condition validity.
     * One event with the condition.
     */
    send() {
        this.checkSelectesValues();

        const isValid = Boolean(
            !this.queryValidation.invalidSource &&
                !this.queryValidation.invalidOperator &&
                !this.queryValidation.invalidValue &&
                !this.queryValidation.invalidRoutingReference
        );

        this.conditionValidate.emit(isValid);

        const newCondition = this.createCondition();
        this.changes.emit(newCondition);
    }

    /**
     * Checks if condition fields are filled.
     * Updates queryValidation object.
     */
    private checkSelectesValues() {
        // If the routing reference is selected XOR all selected, display operator and value errors
        // to encourage the user to fill the fields
        const selectedOneXorAll = Boolean(
            (this.selectedRoutingRef || this.checkAll) && !(this.selectedRoutingRef && this.checkAll)
        );
        if (selectedOneXorAll) {
            this.queryValidation.invalidRoutingReference = false;
            this.queryValidation.invalidSource = Boolean(!this.selectedComparedProperty);
            this.queryValidation.invalidOperator = Boolean(!this.selectedOperator);
            this.queryValidation.invalidValue = Boolean(
                typeof this.selectedValue !== 'number' && !this.selectedThreshold
            );
        } else {
            this.queryValidation.invalidRoutingReference = true;
            this.queryValidation.invalidSource = false;
            this.queryValidation.invalidOperator = false;
            this.queryValidation.invalidValue = false;
        }
    }

    /**
     * Create condition object based on user's choices.
     * Uses queryValidation to verify choices are correct.
     * @returns {Condition} return condition object or null
     */
    private createCondition(): Condition {
        if (
            this.queryValidation.invalidRoutingReference ||
            this.queryValidation.invalidOperator ||
            this.queryValidation.invalidSource ||
            this.queryValidation.invalidValue
        ) {
            return null;
        }

        const condition: Condition = {
            scope: {
                energyType: this.selectedRoutingRef ? this.selectedRoutingRef.energyType : 'elec',
                routingReference: this.selectedRoutingRef && !this.checkAll ? this.selectedRoutingRef._id : null,
                allRoutingReferences: this.checkAll,
                period: null,
                isRetroactive: this.condition.scope.isRetroactive,
            },
            comparator: this.selectedOperator,
            comparedProperty: this.selectedComparedProperty,
            thresholdValue: this.selectedThreshold ? null : this.selectedValue,
            thresholdProperty: this.selectedThreshold,
        };

        return condition;
    }

    getRoutingReferenceDisplay(routingReference: any): string {
        let display = `${routingReference.reference} - `;
        if (routingReference && routingReference.site) {
            display += routingReference.site.complement ? routingReference.site.complement : routingReference.site.name;
        } else {
            display += 'Site NC';
        }
        return display;
    }

    /**
     * Open a swal with the error explained
     * @param {string} errorCode - error code
     */
    handleErrorMessage(errorCode: string) {
        let msg: [string, string, SweetAlertIcon];
        const errorGetRoutingRef: [string, string, SweetAlertIcon] = [
            'Impossible de récupérer les PDLs',
            'Merci de réessayer ultérieurement',
            'error',
        ];
        const mapping: { [key: string]: [string, string, SweetAlertIcon] } = {
            failed_get_routing_references: errorGetRoutingRef,
            user_routing_references_site_is_missing: errorGetRoutingRef,
            company_routing_references_site_is_missing: errorGetRoutingRef,
            filters_routing_references_site_is_missing: errorGetRoutingRef,
        };
        msg = mapping[errorCode];
        if (!msg) {
            msg = ['Une erreur inconnue est survenue', 'Merci de réessayer ultérieurement', 'error'];
        }
        Swal.fire(msg[0], msg[1], msg[2]);
    }
}
