import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CotisationService } from './cotisation.service';

@Component({
    selector: 'ga-cotisation',
    templateUrl: './cotisation.component.html',
    styleUrls: ['./cotisation.component.scss'],
    providers: [CotisationService],
})
export class CotisationComponent implements OnInit {
    cotisationData: any = [];
    siteConsumption: any = [];
    dates: any = {};
    // Site selection
    sites: any = []; // For the list
    site: any = []; // Site selected from the list, to hide it.
    selectedSite: any; // The selected site to display

    isLoadingSite = false;
    isLoadingProcedures = false;

    constructor(private cotisationService: CotisationService, private router: Router) {}

    ngOnInit(): void {
        this.isLoadingProcedures = true;
        this.cotisationService.getCotisationData().then(data => {
            this.cotisationData = data.data;
            this.isLoadingProcedures = false;
            this.cotisationService.getAvailableYears().then(dates => {
                this.dates = dates.data;
            });
        });
    }

    onUpdateSite(site) {
        this.selectedSite = site;
        this.isLoadingSite = true;
        this.cotisationService.getSiteConsumption(site._id, this.dates).then(conso => {
            this.siteConsumption = [];
            conso.data.sort((a, b) => b.year - a.year);
            this.siteConsumption.push(...conso.data);
            this.isLoadingSite = false;
        });
    }

    getConsommationText(procedure) {
        if (procedure.min && procedure.max) {
            return `Comprise entre ${procedure.min}${procedure.unit} et ${procedure.max}${procedure.unit}`;
        } else if (procedure.min) {
            return `Supérieure à ${procedure.min}${procedure.unit}`;
        } else if (procedure.max) {
            return `Inférieure à ${procedure.max}${procedure.unit}`;
        }
    }

    getElectronLibrePrice(consoYearFluid) {
        if (consoYearFluid.priceLibre && consoYearFluid.priceLibre !== 'NC') {
            return consoYearFluid.priceLibre.toLocaleString() + ' €';
        } else {
            return 'NC';
        }
    }

    getElectronPlusPrice(consoYearFluid) {
        if (consoYearFluid.pricePlus && consoYearFluid.pricePlus !== 'NC') {
            return consoYearFluid.pricePlus.toLocaleString() + ' €';
        } else {
            return 'NC';
        }
    }

    getSiteName(site) {
        if (site.complement) {
            return site.complement;
        }
        if (site.name) {
            return site.name;
        }
        return 'NC';
    }

    getSiteAddress(site) {
        let address = '';
        if (site.streetNumber) {
            address += site.streetNumber + ', ';
        }
        if (site.streetName) {
            address += site.streetName;
        }
        return address;
    }

    getSiteCity(site) {
        let city = '';
        if (site.zipcode) {
            city += site.zipcode + ' ';
        }
        if (site.city) {
            city += site.city;
        }
        return city;
    }

    getFluidDisplayName(fluidType) {
        if (fluidType === 'elec') {
            return 'Électricité';
        }
        if (fluidType === 'gaz') {
            return 'Gaz';
        }
        if (fluidType === 'water') {
            return 'Eau';
        }
        return fluidType;
    }

    formatConsumption(consoYearFluid) {
        const rounded = this.roundToDecimal(consoYearFluid.consumption);
        return rounded + ' ' + consoYearFluid.unit;
    }

    roundToDecimal(x, decimal = 3) {
        const pow = Math.pow(10, decimal);
        const rounded = Math.round(x * pow) / pow;
        if (rounded === 0 && x !== 0) {
            return '< ' + (rounded + 1 / pow);
        }
        return rounded;
    }

    hasSiteAnAddress(site) {
        return this.getSiteAddress(site) && this.getSiteCity(site);
    }

    goToSite(site) {
        this.router.navigateByUrl('/energie/profil/' + site._id);
    }

    getExportExcelUrl() {
        if (this.selectedSite && this.dates) {
            const start = moment(this.dates.startDate, 'YYYY-MM')
                .startOf('month')
                .format('YYYY-MM');
            const end = moment(this.dates.endDate, 'YYYY-MM')
                .endOf('month')
                .format('YYYY-MM');
            return `/api/export/excel/specific/resah?site=${this.selectedSite._id}&start=${start}&end=${end}&energy=elec`;
        }
        return null;
    }

    getExportName(txt) {
        if (this.selectedSite) {
            const name = this.selectedSite.complement ? this.selectedSite.complement : this.selectedSite.name;
            return `${txt}-${name}.xlsx`;
        } else {
            return `${txt}-Site NC.xlsx`;
        }
    }
}
