export interface FilterConfig {
    type: TypeFilter;
    displayName: string;
    propertyName: string;
    selectedValue: any;
    placeHolder: string;
    data: Array<{
        displayName: string;
        value: string;
    }>;
    multiSelect: boolean;
    multiSelectedValues: any;
    defaultValue: any;
}

export enum TypeFilter {
    date,
    select,
    selectAutoComplete,
    treeSelect,
}

export interface OptionValue<T = string> {
    displayName: string;
    value: T;
    selected?: boolean;
}
