import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';

import { RoutingReferenceKeyfigures } from '../rref-merge.interface';
import { RrefMergeService } from '../rref-merge.service';

import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { KeyDataService } from 'app/shared/services/key-data/key-data.service';

@Component({
    selector: 'rref-merge-selected',
    templateUrl: './rref-selected.component.html',
    styleUrls: ['./rref-selected.component.scss'],
})
export class RrefSelectedComponent implements OnInit {
    /** Destination routing reference keyfigures */
    public rRefDestination: RoutingReferenceKeyfigures = null;
    /** Origin routing reference keyfigures */
    public rRefOrigin: RoutingReferenceKeyfigures = null;

    constructor(private rrefMergeService: RrefMergeService, private keyDataService: KeyDataService) {}

    ngOnInit() {
        this.rrefMergeService.rRefDestination$.subscribe({
            next: value => {
                this.rRefDestination = value;
            },
        });
        this.rrefMergeService.rRefOrigin$.subscribe({
            next: value => {
                this.rRefOrigin = value;
            },
        });
    }

    /**
     * Handle drop event for target routing reference
     * @param {DragEvent} event
     * @param {'destination' | 'origin'} target - routing reference target
     */
    async onDrop(event: DragEvent, target: 'destination' | 'origin') {
        event.preventDefault();
        // Prevent dropping origin if destination not dropped
        if (target === 'origin' && !this.rRefDestination) {
            return;
        }
        const dataString = event.dataTransfer.getData('text/plain');
        const rRef: RoutingReference = JSON.parse(dataString);
        // Prevent dropping same routing reference
        if (
            target === 'origin' &&
            (rRef._id === this.rRefDestination._id || rRef.energyType !== this.rRefDestination.energyType)
        ) {
            return;
        }
        await this.grabKeyFigures(rRef, target);
    }

    /**
     * Unselect routing reference
     * @param {'destination' | 'origin'} target - routing refrence target unselected
     */
    public unselectRoutingReference(target: 'destination' | 'origin') {
        if (target === 'destination') {
            this.rRefDestination = this.rrefMergeService.clearDestination();
        } else if (target === 'origin') {
            this.rRefOrigin = this.rrefMergeService.clearOrigin();
        }
    }

    /**
     * DO NOT REMOVE. ENABLE THE DROP.
     * @param {DragEvent} event
     */
    public onDragOver(event: DragEvent, target: 'destination' | 'origin') {
        if (target === 'origin') {
            if (this.rRefDestination) {
                event.preventDefault();
            }
        } else {
            event.preventDefault();
        }
    }

    /**
     * Get routing reference keyfigures
     * @param {RoutingReference} rRef - routing reference to get keyfigures from
     * @returns {Promise<RoutingReferenceKeyfigures>}
     */
    private async grabKeyFigures(rRef: RoutingReference, target: 'destination' | 'origin'): Promise<void> {
        try {
            // Get keyfigures but don't assign them directly, they are handled by event management
            if (target === 'destination') {
                await this.rrefMergeService.grabDestinationKeyFigures(rRef);
            } else if (target === 'origin') {
                await this.rrefMergeService.grabOriginKeyFigures(rRef);
            }
        } catch (e) {
            this.rrefMergeService.displayError(e.errorCode);
        }
    }

    /**
     * Refresh keyfigures data.
     * Refreshing sites triggers keyfigure refresh
     * @param {'destination' | 'origin'} target
     */
    public async refreshKeyfigure(target: 'destination' | 'origin') {
        // Currently only needs to handle destination refresh as no update can be done in origin
        if (target === 'destination') {
            await this.rrefMergeService.refreshSites();
        }
    }

    /**
     * Can unselect destination
     */
    public get canUnselectDestination(): boolean {
        return this.rRefDestination && !this.rRefOrigin;
    }

    /**
     * Can rename destination
     * @returns {boolean}
     */
    public get canRenameDestination(): boolean {
        return this.rRefDestination && !this.rRefOrigin;
    }
}
