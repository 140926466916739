import { Injectable } from '@angular/core';

// Models
import { ContractualData } from 'app/shared/models/contractual-data.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';

// Services
import { ContractualDataService, GetContractualDataFilters } from 'app/shared/services/contractual-data';

/**
 * This service is designed to retrieve routing reference's required
 * contractual data when loading its view page.
 */
@Injectable()
export class RRefContractualDataService {
    constructor(private readonly contractualDataService: ContractualDataService) {}

    /** Get required contractual data for a routing reference  */
    public async getRRefContractualData(
        routingReference: RoutingReference,
        filters: GetContractualDataFilters
    ): Promise<ContractualData | null> {
        const identifiers = [
            {
                routingReference: routingReference.reference,
                fluid: routingReference.energyType,
            },
        ];

        const data = await this.contractualDataService.getContractualDatas(identifiers, filters);

        // Contractual data is the first value from data array
        if (data && data.length) {
            return data[0];
        }

        return null;
    }
}
