import { Injectable } from '@angular/core';
import { MomentInput } from 'moment';
import * as moment from 'moment';

import { CommunicatingSummary } from 'app/shared/models/routing-reference.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

@Injectable()
export class LoadCurveService extends PageService {
    /**
     *  Load curve compatible fluids
     */
    private readonly fluids: string[] = ['elec'];
    /**
     * Load curve compatible fluid categories
     */
    private readonly fluidCategories: string[] = ['energy'];

    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    // Handle versions with and wihtout data stored (max than 1 week available or not)
    private readonly displayMaxOneWeek = true;

    /**
     * Returns the link of the load curve page
     */
    public get loadCurveLink(): string {
        return `/energie/courbes-de-charge`;
    }
    /**
     * Checks if the provided category is load curve compatible
     * @returns {boolean}
     */
    public isFluidCategoryCompatible(category: string): boolean {
        return this.fluidCategories.includes(category);
    }
    /**
     * Returns an array of load curve compatible fluids.
     * @returns {string[]}
     */
    public get compatibleFluids(): string[] {
        return [].concat(this.fluids);
    }
    /**
     * Format input dates to dates for params.
     * @param {MomentInput} start - start date
     * @param {MomentInput} end - end date
     * @returns {Array<{ds: string, de: string}>} dates formatted for params
     */
    public formatDatesParams(start: MomentInput, end: MomentInput): { ds: string; de: string } {
        let startMoment = moment(start);
        const endMoment = moment(end);
        /**
         * If the current version can't retrieve more than one week of data:
         * display the last week of the given period
         * @todo Remove this part when load curve v2 service implemented
         */
        if (this.displayMaxOneWeek && endMoment.isValid() && endMoment.diff(startMoment, 'days') > 7) {
            startMoment = endMoment.clone().subtract(6, 'days');
        }
        return {
            ds: startMoment.format('MM-DD-YYYY'),
            de: endMoment.format('MM-DD-YYYY'),
        };
    }

    /**
     * Retrieve the communicating summary for a routing reference
     */
    public async getRoutingReferenceCommunicatingSummary(routingReferenceId: string): Promise<CommunicatingSummary> {
        const res = await this.get(`/api/loadcurve/routingReference/${routingReferenceId}`);
        return res.data;
    }

    /**
     * Retrieve the communicating summaries for a list of routing references
     */
    public async getRoutingReferencesCommunicatingSummaries(
        routingReferenceIds: string[]
    ): Promise<CommunicatingSummary[]> {
        const res = await this.post('/api/loadcurve/routingReference', {
            routingReferenceIds,
        });
        return res.data;
    }
}
