import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ErrorDisplay } from './message-display.interface';
import { MessageDisplayService } from './message-display.service';

@Component({
    selector: 'ga-lc-message-display',
    templateUrl: './message-display.component.html',
    styleUrls: ['./message-display.component.scss'],
    providers: [],
})
export class MessageDisplayComponent implements OnInit, OnDestroy {
    /** String value setting loading text alignment. Possible values are "center", "left" or "right" */
    @Input() align: 'center' | 'left' | 'right' = 'center';

    /** Subscriptions */
    private subscriptions: Subscription;

    public errors: ErrorDisplay[] = [];
    public get textAlignClass(): string {
        return 'align-' + this.align;
    }

    constructor(private service: MessageDisplayService) {}

    ngOnInit() {
        this.subscriptions = this.service.$errors.subscribe({
            next: value => {
                this.errors = value;
            },
        });
    }

    ngOnDestroy() {
        this.subscriptions.remove(this.subscriptions);
    }
}
