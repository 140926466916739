import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Site } from './cartography.interface';
import { CartographyService } from './cartography.service';

import { DataSearchListComponent } from 'app/shared/components/cartography/data-search-list/data-search-list.component';
import { SiteDisplay } from 'app/shared/components/cartography/data-search-list/data-search-list.interface';
import { Marker } from 'app/shared/models/marker.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { MapService } from 'app/shared/services/map/map.service';
import { CartographyTile, TilesService } from 'app/shared/services/tiles/tiles.service';
import { FilterQuery } from '../../shared/components/common/filter/filter.component';

import { SessionService } from 'app/shared/services/session/session.service';
import * as moment from 'moment';

@Component({
    selector: 'ga-cartography',
    templateUrl: './cartography.component.html',
    styleUrls: ['./cartography.component.scss'],
    providers: [CartographyService],
})
export class CartographyComponent implements OnInit {
    public sites: SiteDisplay[] = [];
    public markers: Marker[] = [];
    public dataTiles: CartographyTile[] = [];

    public filterName = 'cartographie';
    public filterSubtitle = "Personnalisez l'affichage de vos contrats";

    /** Energy Tile that is currently selected */
    private selectedTile: CartographyTile = null;
    /** Fluid type that is currently being filtered */
    private selectedFluid: string = null;

    /**
     * Options object for map component
     * Params: zoom, lat, lng, height
     */
    public mapOptions = {
        zoom: 8,
        lat: 49.894067,
        lng: 2.2957529999999906,
        height: 600,
    };

    private queryExport: FilterQuery;
    private loading = true;
    @ViewChild(DataSearchListComponent, { static: false })
    private listComponent: DataSearchListComponent;

    /**
     * Current company selected
     */
    public company: string = null;

    constructor(
        private sessionService: SessionService,
        private cartographyService: CartographyService,
        private tilesService: TilesService,
        private mapService: MapService,
        private energyService: EnergyService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    // At init, the filter already emit a search event, so no need to do it twice
    ngOnInit() {}

    /**
     * Initialise a search of the sites matching current filters,
     * accessible from the filter component
     * @param {FilterQuery} filters
     */
    public search(filters: FilterQuery) {
        this.getSites(filters);
    }

    /**
     * Process a new search of the sites matching current filters. Update sites.
     * @param {FilterQuery} filters
     * @param {string} filters.regions - area to filter the sites on
     * @param {string} filters.energies - fluid to filter the sites on
     * @param {string} filters.companies - company to filter the sites on (in the case of holding vs branches)
     */
    private async getSites(filters: FilterQuery): Promise<void> {
        // update export query filters from current ones
        this.queryExport = filters;

        // Remove'energies' param from filters to get all sites,
        // in order to count PDLs, without modifying original filters.
        const newFilters: FilterQuery = { ...filters };

        if (newFilters.energies) {
            this.selectedFluid = newFilters.energies.toString();
            delete newFilters.energies;
        } else if (this.selectedFluid) {
            // No fluid in query params but a fluid was previously selected : deselect it
            this.selectedFluid = null;
        }

        if (newFilters && newFilters.companies) {
            this.company = newFilters.companies.toString();
        }

        // Perform the sites research with filters
        const sites: Site[] = await this.cartographyService.getSites(newFilters);

        this.processSites(sites);

        if (this.listComponent) {
            this.listComponent.refreshList(this.sites);
        }
        this.loading = false;
    }

    /**
     * Update the counts of routing references of the given fluid.
     * Update total count and the site's count.
     * @param {string} energy
     * @param {{[fluid:string] : number}} totalCount
     * @param {{[fluid:string] : number}} siteCount
     */
    updateCountsForEnergy(
        energy: string,
        totalCount: { [fluid: string]: number },
        siteCount: { [fluid: string]: number }
    ) {
        if (siteCount.hasOwnProperty(energy) && totalCount.hasOwnProperty(energy)) {
            siteCount[energy]++;
            totalCount[energy]++;
        }
    }

    /**
     * 1. Count PDLs for all sites and filter sites if a filter is active.
     * 2. Process data to display for each site: address, basic info
     * 3. Update markers.
     * 4. Sort alphabtelically on sites' complement or name.
     * 5. Update fluid tiles with the number of routing references / vehicles.
     * @param {Site[]} sites
     */
    processSites(sites: Site[]) {
        this.markers = [];

        /**
         * 1. Count PDLs for all sites and filter sites array if a filter is active
         */
        const total: { [fluid: string]: number } = {};
        const active: { [fluid: string]: number } = {};
        this.energyService.getAllFluids().forEach(fluid => (total[fluid] = active[fluid] = 0));

        // Use array.reduce to filter out unwanted sites
        this.sites = sites.reduce((memo: SiteDisplay[], site: Site) => {
            const countSiteFluids: { [fluid: string]: number } = {};

            this.energyService.getAllFluids().forEach(fluid => (countSiteFluids[fluid] = 0));

            /** Boolean checking if site has entity (RRef/Vehicle) of the fluid type that is being filtered */
            let hasFilteredEntity = false;

            site.routingReferences.forEach(rf => {
                if (rf) {
                    // If an energy filter is active, check if site has PDLs of that energy type
                    if (this.selectedFluid && this.selectedFluid === rf.energyType) {
                        hasFilteredEntity = true;
                    }

                    // Update energy count
                    this.updateCountsForEnergy(rf.energyType, total, countSiteFluids);
                    if (rf.status && rf.status.active) {
                        active[rf.energyType]++;
                    }
                }
            });
            site.vehicles.forEach(veh => {
                if (veh) {
                    // If an fuel filter is active, check if site has vehicle
                    if (this.selectedFluid && this.selectedFluid === 'fuel') {
                        hasFilteredEntity = true;
                    }

                    // Update fuel count
                    this.updateCountsForEnergy('fuel', total, countSiteFluids);
                    // Vehicle with date out in the futur and date in in the past are considered as active
                    const now = moment();
                    const dateInCheck = Boolean(!veh.dateIn || moment(veh.dateIn).isSameOrBefore(now));
                    const dateOutCheck = Boolean(!veh.dateOut || moment(veh.dateOut).isSameOrAfter(now));
                    if (dateInCheck && dateOutCheck) {
                        active.fuel++;
                    }
                }
            });

            // If no fluid filter is active or if the site has entities that passed the filter
            if (!this.selectedFluid || (this.selectedFluid && hasFilteredEntity)) {
                /**
                 * 2. Process address, info on each site.
                 */
                // address & name
                const streetNumber = site.streetNumber || '';
                const zipcode = site.zipcode || '';
                const address = site.city ? `${streetNumber} ${site.streetName} ${zipcode} ${site.city}` : '';
                const name = site.name;

                // create info to display for the site.
                const info: SiteDisplay = {
                    complement: site.complement || 'NC',
                    address,
                    name,
                    vehicles: site.vehicles,
                    routingReferences: site.routingReferences,
                    id: site._id,
                    infoFluids: countSiteFluids,
                    code: site.code,
                };

                /**
                 * 3. Update markers
                 */
                this.updateMarkers(site, info);
                // Add site to filtered site array
                memo.push(info);
            }

            return memo;
        }, []);

        /**
         * 4. Sort alphabtelically on sites' complement or name
         */
        this.sites.sort((a, b) => {
            const valA = a.complement || a.name ? a.complement || a.name : '';
            const valB = b.complement || b.name ? b.complement || b.name : '';
            return valA.localeCompare(valB);
        });

        /**
         * 5. Update tiles
         */
        this.processDataTiles(total, active);
    }

    /**
     * Create tiles from total
     * @param {{[fluid: string]: number}} total - number of routing references / vehicles found per fluid
     * @param {{[fluid: string]: number}} active - number of active routing references / vehicles found per fluid
     * @returns {CartographyTile[]} tiles for the fluids having data
     */
    processDataTiles(total: { [fluid: string]: number }, active: { [fluid: string]: number }) {
        this.dataTiles = Object.keys(total)
            .filter(fluid => total[fluid] !== 0) // create tile when count is > 0
            .map(fluid => this.tilesService.generateCartoTile(fluid, total[fluid], active[fluid], this.selectedFluid));

        // Check if a tile is selected and store its reference
        this.selectedTile = this.dataTiles.find(tile => tile.selected);
    }

    /**
     * Update markers info from sites
     * @param {Site} site
     * @param {any} info
     */
    updateMarkers(site: Site, info: any) {
        const siteForMarker = this.mapService.getSiteDataForMarkersAddress(site);
        siteForMarker['infoFluids'] = info.infoFluids;

        const energies = this.energyService.getAllEnergies();
        const liquids = this.energyService.getAllLiquids();

        const hasOnlyWater =
            !energies.some(x => Boolean(info.infoFluids[x])) && liquids.some(x => Boolean(info.infoFluids[x]));
        this.markers.push({
            lat: site.latitude,
            lng: site.longitude,
            config: 'carto',
            data: siteForMarker,
            type: hasOnlyWater ? 'water' : 'elec',
        });
    }

    /**
     * Get url for export, null if query is undefined.
     */
    getExportRRefBySiteUrl(): string | null {
        if (this.queryExport) {
            let url = `/api/export/excel/sites?energies=`;
            url += this.queryExport.energies ? `${this.queryExport.energies}` : '';
            url += this.queryExport.regions ? `&regions=${this.queryExport.regions}` : '';
            url += `&companies=${this.queryExport.companies || this.sessionService.getCompany()._id}`;
            return url;
        }
        return null;
    }

    /**
     * Get url for export, null if query is undefined.
     */
    getExportLegacyDataBySiteUrl(): string | null {
        if (this.queryExport) {
            let url = `/api/export/excel/sites/legacy-data?energies=`;
            url += this.queryExport.energies ? `${this.queryExport.energies}` : '';
            url += this.queryExport.regions ? `&regions=${this.queryExport.regions}` : '';
            url += `&companies=${this.queryExport.companies || this.sessionService.getCompany()._id}`;
            return url;
        }
        return null;
    }

    /**
     * Get clean name for export with current date at the end '-YYYYMMDD'
     * @param {string} txt
     */
    getExportName(txt: string): string {
        const date = moment()
            .format('YYYYMMDD')
            .toString();
        return `${txt}-${date}.xlsx`;
    }

    /**
     * Return the right to display table: if nothing loading and sites found.
     */
    canShowTable(): boolean {
        return Boolean(!this.loading && this.sites && this.sites.length);
    }

    /**
     * Handles the selection and deselection of an energy Tile,
     * and restarts the cartography filtering process.
     * @param {CartographyTile} clickedTile Fluid Tile that is being clicked
     */
    async handleTileSelection(clickedTile: CartographyTile): Promise<void> {
        clickedTile.selected = !clickedTile.selected;

        // If clicked Tile is different from previously selected tile, deselect the previous one
        if (this.selectedTile && this.selectedTile.type !== clickedTile.type) {
            this.selectedTile.selected = false;
        }

        if (clickedTile.selected) {
            this.selectedTile = clickedTile;
        } else {
            this.selectedTile = null;
            this.selectedFluid = null;
        }

        // changes the route without moving from the current view or triggering a navigation event
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { en: this.selectedTile ? this.selectedTile.type : null },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        });
    }
}
