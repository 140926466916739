import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

// interfaces
import { Legend } from 'app/shared/components/legends/legend.interface';
import { LegendConfig } from 'app/shared/components/legends/legend.interface';
import { BillControlItem, BillControlSection } from 'app/shared/models/bills-controls.interface';
import { Bill, BillChunk } from 'app/shared/models/bills.interface';
import { Site } from 'app/shared/models/site.interface';

// services
import { SitesService } from 'app/shared//services/sites/sites.service';
import { BillsControlService } from 'app/shared/services/bills/bills-control.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

@Component({
    selector: 'ga-bill-verifications',
    templateUrl: './bill-verifications.component.html',
    styleUrls: ['./bill-verifications.component.scss', './../../../bill-verification.scss'],
    providers: [],
})
export class BillVerificationsComponent implements OnInit {
    @Input() bill: Bill;
    @Input() chunk: BillChunk;
    @Input() legends: Legend[];

    title: string;
    sections: BillControlSection[] = [];
    site: Site;
    sourceReferenceTitle: string;
    sourceReferenceValue: string;

    /**
     * Set config attributes for the legend component
     */
    public legendConfig: LegendConfig = {
        alignement: {
            enabled: true,
            value: 'right',
        },
    };

    constructor(
        private billsControlService: BillsControlService,
        private utilService: UtilsService,
        private siteService: SitesService
    ) {}

    ngOnInit() {
        this.getConfig();

        this.getSite();

        this.getSourceReference();
    }

    get verifications() {
        if (!this.bill.multi) {
            return this.bill.chunks[0].verifications;
        } else {
            if (this.chunk && this.chunk.verifications.length > 0) {
                return this.chunk.verifications;
            }
        }
    }

    /**
     * Get the value for the PDL or the vehicle
     * @returns {string}
     */
    getSourceReference() {
        if (this.chunk && this.chunk.routingReference.reference) {
            this.sourceReferenceTitle = 'routingReference';
            this.sourceReferenceValue = this.chunk.routingReference.reference;
        } else if (this.chunk && this.chunk.vehicle.reference) {
            this.sourceReferenceTitle = 'vehicle';
            this.sourceReferenceValue = this.chunk.vehicle.reference;
        }
    }

    async getSite() {
        if (this.chunk) {
            this.site = await this.siteService.getSiteFromRoutingReference(
                this.chunk.routingReference.id,
                this.chunk.company
            );
        }
    }

    hasVerifications() {
        return this.verifications && this.verifications.length > 0 ? true : false;
    }

    get sectionControls() {
        for (const section of this.sections) {
            return section.items;
        }
    }

    /**
     * Get the config defined in the service
     * @returns {Promise<void>}
     */
    getConfig() {
        const fluid = this.getEnergyType();
        const config = this.billsControlService.getControlSectionConfig(fluid);

        if (config) {
            this.sections = this.billsControlService.getSections(config, this.verifications);
            this.setPillPriority();
        }
    }

    /**
     * Get the fluid type
     *
     * @returns {string}
     */
    getEnergyType() {
        return this.bill.chunks[0].fluid;
    }

    /**
     * Collapse sub controls section
     * @param {number} index
     */
    collapse(control: BillControlItem): void {
        control.isExpanded = !control.isExpanded;
    }

    /**
     * Returns the class for the collapse carret depending on the control status
     * @param {boolean} isExpanded
     */
    getClassCollapse(isExpanded: boolean): string {
        return isExpanded ? 'collapse-up' : 'collapse-down';
    }

    /**
     * Get the required total (from key) to display. Sum the section's controls specfic value.
     * @param {string} key - key of the controls' value to sum
     * @param {BillControlSection} section
     * @param {boolean} replaceZero - return default string '-' when value is null
     * @returns {string} - sum
     */
    getTotalValue(section: BillControlSection, key: string, replaceZero: boolean = false): string {
        // if all controls have null value -> display '- €' instead of 0.00€
        const allNaan = section.items.every(item => typeof item[key] !== 'number');
        if (allNaan) {
            return '-';
        }

        const total = _.sumBy(section.items, key);
        if (replaceZero && typeof total === 'number' && total === 0) {
            return '-';
        }

        return this.utilService.getNumberAndUnitToDisplay(total, '€');
    }

    /**
     * Get the global ratio value from controls to display: sum of difference / sum of noticed values
     * @returns {string}
     */
    getRatioTotal(section: BillControlSection): string {
        const totalDiff = _.sumBy(section.items, 'difference');
        const totalNoticed = _.sumBy(section.items, 'noticedValue');

        // return not applicable if noticed total is 0 (can't divide by 0) or if diff is zero (for visiblity, when total is valid)
        const isNotApplicable: boolean = !totalNoticed || !totalDiff;
        if (isNotApplicable) {
            return '-';
        }

        /**
         * Compute ratio based on difference over value that should have been billed.
         * Using noticedValue - difference and not expectedValue because expectedValue is less likely to exist.
         * Ex. difference -50 noticedValue 100 => -33.67% (supposed to pay 150 but paid 100)
         * difference +50 noticedValue 100 => +100% (supposed to pay 50 but paid 100)
         */
        const totalRatio = (100 * totalDiff) / (totalNoticed - totalDiff);
        return this.utilService.getNumberAndUnitToDisplay(totalRatio, '%');
    }

    /**
     * Returns true if the value is not a number and is undefined
     * @param {*} value - to test
     * @returns {boolean}
     */
    isUndefined(value: any): boolean {
        return typeof value !== 'number' && !value;
    }

    /**
     * Set the pill priority on each control and subControl
     */
    setPillPriority(): void {
        this.sections.forEach(section => {
            if (section.items) {
                section.items.forEach(control => {
                    // set the control result's criticity
                    control.priority = this.billsControlService.getControlPriority(control);

                    const subControls = control.items;
                    if (subControls) {
                        subControls.forEach(subControl => {
                            // set the subcontrol result's criticity
                            subControl.priority = this.billsControlService.getControlPriority(subControl);
                        });
                    }
                });
            }
        });
    }

    /**
     * get the rgb color of the legend by given value
     * @param {*} val
     * @returns {string}
     */
    getRgbColorByValue(val: any): string {
        const legend = this.legends.find(item => item.values && item.values.includes(val));
        return legend ? `rgb(${legend.color})` : null;
    }

    /**
     * Call the service to return the icon based on the priority number
     *
     * @param {number} priority
     * @returns {string}
     */
    getPriorityIcon(priority: number): string {
        return this.billsControlService.getPriorityIcon(priority);
    }
}
