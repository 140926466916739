import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Component({
    selector: 'upload-snackbar',
    template: `
        <div class="d-flex justify-content-between mat-simple-snackbar">
            <div>Upload en cours</div>
            <div>{{ data | async }} %</div>
        </div>
    `,
})
export class UploadSnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Observable<number>) {}
}
