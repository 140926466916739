import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdminCompanyService } from 'app/pages/admin/company/company.service';
import { UploadFilesTileComponent } from 'app/shared/components/upload/upload-files-tile/upload-files-tile.component';

import { HttpEventType } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { File } from 'app/shared/models/upload-file.interface';
import { RightsService } from 'app/shared/services/rights/rights.service';
import { UsersService } from 'app/shared/services/users/users.service';
import Swal, { SweetAlertResult } from 'sweetalert2';

const globalStates = {
    NO_LOGO: 'noLogo',
    HAS_LOGO: 'hasLogo',
};

@Component({
    selector: 'ga-user-profile-tab',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    providers: [],
})
export class UserProfileComponent implements OnInit {
    @Input() user: any = null;
    @Input() userSession: any = null;
    @Output() userChange: EventEmitter<any> = new EventEmitter();
    @Output() keepCompanyOrigin: EventEmitter<any> = new EventEmitter();
    @Input() canEdit = false;

    isCreating = true;
    @Input() createdFromCompanyId = null;
    createdFromList: string = null;
    currentState: string = globalStates.NO_LOGO;
    isSaving = false;

    uploadTilesData: any = {
        [globalStates.NO_LOGO]: {
            label: 'Glissez et déposez votre logo',
            subLabel: "On s'occupe de tout !",
            imgUrl: 'assets/img/backgrounds/default-avatar.jpg',
        },
        [globalStates.HAS_LOGO]: {
            label: '',
            subLabel: '',
            imgUrl: 'assets/img/backgrounds/default-avatar.jpg',
        },
    };

    userTypes = [];
    userSaved = null;

    @ViewChild('upload', { static: false }) private uploadComponent: UploadFilesTileComponent;

    constructor(
        private router: Router,
        private companyService: AdminCompanyService,
        private route: ActivatedRoute,
        private rightsService: RightsService,
        private usersService: UsersService
    ) {}

    ngOnInit() {
        this.initData();
    }

    initData() {
        this.setAllowedTypes();

        // is reading or updating the user's profile
        if (this.user) {
            this.isCreating = false;
        } else {
            // creating a new user
            this.isCreating = true;
            this.route.params.subscribe(params => {
                this.createdFromCompanyId = params['companyId'];
            });
            this.route.queryParams.subscribe(queryParams => {
                /**
                 * Query param 'l' is for list to add the user after creation.
                 * For now, if l=viewers will add to viewers list, otherwise add to user list.
                 */
                if (queryParams['l']) {
                    this.createdFromList = queryParams['l'];
                }
            });
            this.user = this.initiliazeNewUser();
        }
        this.setUserLogo();
    }

    initiliazeNewUser() {
        return {
            firstname: '',
            name: '',
            type: this.userTypes[0].id,
            contact: {
                phone: '',
                mail: '',
            },
        };
    }

    setAllowedTypes() {
        // useradmin and admin can add users & useradmins
        this.userTypes = [
            {
                id: UserTypes.USER,
                name: 'Client',
            },
            {
                id: UserTypes.USER_ADMIN,
                name: 'Administrateur',
            },
        ];

        // EM and Admin can add EM
        if (
            UserTypes.ADMIN === this.userSession.type ||
            UserTypes.ENERGY_MANAGER === this.userSession.type ||
            !this.canEdit
        ) {
            this.userTypes.push({
                id: UserTypes.ENERGY_MANAGER,
                name: 'Energy-manager',
            });
        }

        // Admin can add admin
        if (UserTypes.ADMIN === this.userSession.type || !this.canEdit) {
            this.userTypes.push({
                id: UserTypes.ADMIN,
                name: 'Super Citron',
            });
        }
    }

    // set the logo info
    setUserLogo() {
        // if we create a new company, set the default empty logo in background
        if (this.isCreating || !this.user.img) {
            this.currentState = globalStates.NO_LOGO;
        } else {
            this.setLogoState(this.user.img);
        }
    }

    //  ******* SAVE USER *****

    // on click on 'Create' or 'Update'
    saveUserProfile() {
        if (this.isUserValid() && !this.isSaving) {
            this.isSaving = true;
            this.saveUser().then(
                user => {
                    this.user = user;
                    this.userSaved = user;
                    const swalMsgCode = !this.isCreating ? 'user_saved' : 'user_created';
                    this.getSwalMsg(swalMsgCode).then(() => {
                        // add the user to the company it was created from
                        if (this.createdFromCompanyId) {
                            this.saveRights().then(
                                rights => {
                                    this.isSaving = false;
                                    this.isCreating = false;

                                    // if type is E-M, leave time to fill engineer tab + display btn at the bottom
                                    if (this.userSaved.type !== UserTypes.ENERGY_MANAGER) {
                                        this.navigateToCompanyTabUsers();
                                    }
                                    this.userChange.emit(this.userSaved);
                                    this.keepCompanyOrigin.emit(this.createdFromCompanyId);
                                },
                                err => {
                                    // err.error_code is set to error_saveRights
                                    this.getSwalMsg(err.error_code);
                                }
                            );
                        } else {
                            this.userChange.emit(user);
                            this.router.navigateByUrl('/admin/utilisateur/' + this.user._id);
                            this.isSaving = false;
                        }
                    });
                },
                err => {
                    this.isSaving = false;
                    // err.error_code is set to error_updateUser
                    this.getSwalMsg(err.errorCode || err.error_code);

                    this.router.navigateByUrl('/admin/utilisateurs/creer');
                }
            );
        }
    }

    // check if the form info are valid before saving
    isUserValid(): boolean {
        // the fields can't be empty
        if (this.hasEmptyFields()) {
            this.getSwalMsg('empty_field');
            return false;
        }

        return true;
    }

    // check if some fields are empty before saving
    hasEmptyFields(): boolean {
        return (
            this.user.firstName === '' ||
            this.user.name === '' ||
            this.user.contact.phone === '' ||
            this.user.contact.mail === '' ||
            !this.user.type
        );
    }

    // create or update the new user in db
    saveUser(): Promise<any> {
        if (!this.isCreating) {
            return this.usersService.updateUser(this.user);
        } else {
            return this.usersService.createUser(this.user);
        }
    }

    saveRights(): Promise<any> {
        return this.rightsService.saveRights(this.createdFromCompanyId, this.user._id, null, this.createdFromList);
    }

    /**
     * Get swal with message
     * @param {string} msg_code - error code
     * @returns {Promise<SweetAlertResult>}
     */
    getSwalMsg(msg_code: string): Promise<SweetAlertResult> {
        const messages = {
            empty_field: ['Attention', "L'un des champs n'est pas renseigné.", 'warning'],
            error_updateUser: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement du profil.",
                'error',
            ],
            error_createNewUser: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement du profil.",
                'error',
            ],
            user_already_exists: ['Attention', 'Un autre utilisateur a déjà ce nom ou cette adresse mail.', 'warning'],
            user_saved: ['Sauvegardé', "L'utilisateur a bien été sauvegardé", 'success'],
            user_created: [
                'Sauvegardé',
                "L'utilisateur a bien été créé. Un email d'activation lui a été envoyé.",
                'success',
            ],
            need_user_saved_before_picture: [
                'Attention',
                "L'utilisateur doit être enregistré avant d'ajouter une photo",
                'warning',
            ],
            picture_need_png_extension: ['Attention', "Votre photo doit avoir l'extension .png", 'warning'],
            picture_saved: ['Sauvegardé', 'La photo a bien été enregistrée', 'success'],
            user_deleted: ['Supprimé', "L'utilisateur a bien été supprimé", 'success'],
            error_deleteUser: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant la suppression de l'utilisateur.",
                'error',
            ],
            error_savePicture: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement de la photo.",
                'error',
            ],
            error_deletePicture: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la suppression de la photo.',
                'error',
            ],
            error_loadCompany: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement des droits du nouvel utilisateur ",
                'error',
            ],
            error_updateCompany: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement des droits du nouvel utilisateur",
                'error',
            ],
            error_getListPages: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement des droits du nouvel utilisateur",
                'error',
            ],
            error_saveRights: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement des droits du nouvel utilisateur",
                'error',
            ],
            forbidden: ['Interdit', 'Vous ne pouvez pas créer cet utilisateur', 'error'],
            internal_error: ['Toutes nos excuses', 'Une erreur inconnue est survenue', 'error'],
            default_error: ['Toutes nos excuses', 'Une erreur inconnue est survenue', 'error'],
        };
        // If error not found, set default one
        const msgArray = messages[msg_code] ? messages[msg_code] : messages.default_error;

        return Swal.fire(msgArray[0], msgArray[1], msgArray[2]);
    }

    // Save profile button label : Update or Create
    getSaveBtnLabel() {
        if (!this.isCreating) {
            if (this.isSaving) {
                return 'Mise à jour...';
            } else {
                return 'Mettre à jour';
            }
        } else {
            if (this.isSaving) {
                return 'Création en cours...';
            } else {
                return 'Créer';
            }
        }
    }

    // **** UPLOAD ***

    hasCurrentState() {
        return this.currentState;
    }

    // get upload logo path and labels to display
    getUploadLogoLabels() {
        return this.uploadTilesData[this.currentState];
    }

    hasLogo() {
        return this.currentState === globalStates.HAS_LOGO;
    }

    // files coming from drag & drop - upload them in db + link their upload id to the company's logo property
    addFiles(files: File[]) {
        if (!this.canEdit) {
            return;
        }
        if (files) {
            // the company must be saved first before uploading a file - need to link the upload to the company
            if (!this.isCreating) {
                // if multiple files were uploaded - take the first one
                const file = files[0];

                const formatedFile = {
                    filename: file.name,
                    fileObject: file, // file Object containing raw data
                };

                // save logo in db + link its id to the company's logo property
                return this.companyService.uploadPicture(formatedFile, this.user._id, 'user').subscribe(
                    event => {
                        if (event.type === HttpEventType.Response) {
                            this.user.img = event.body['data'].img;
                            this.userChange.emit(this.user);

                            // set the new logo path
                            this.setUserLogo();
                            this.getSwalMsg('picture_saved');
                        }
                    },
                    err => {
                        this.getSwalMsg('error_savePicture');
                    }
                );
            } else {
                this.getSwalMsg('need_user_saved_before_picture');
            }
        } else {
            this.getSwalMsg('picture_need_png_extension');
        }
    }

    // set the new logo path
    setLogoState(logoId: string) {
        if (logoId) {
            this.companyService.getLogoPathById(logoId).then(
                url => {
                    if (url) {
                        this.uploadTilesData[globalStates.HAS_LOGO].imgUrl = url;
                        this.currentState = globalStates.HAS_LOGO;
                    }
                },
                err => {
                    this.currentState = globalStates.NO_LOGO;
                }
            );
        } else {
            this.currentState = globalStates.NO_LOGO;
        }
    }

    // click on add a profile picture
    clickOnFileInput() {
        this.uploadComponent.clickOnInput();
    }

    deleteUser() {
        Swal.fire({
            title: 'Êtes-vous sûr(e) ?',
            text: `Etes-vous sûr(e) de vouloir supprimer l'utilisateur ${this.user.firstname} ${this.user.name} ? Cette action est irréversible.`,
            icon: 'warning',
            showCancelButton: true,
        }).then(result => {
            if (result.value) {
                this.usersService.deleteUserById(this.user).then(
                    () => {
                        this.getSwalMsg('user_deleted');
                        this.router.navigate(['/admin/utilisateurs']);
                    },
                    err => {
                        // err.error_code is set to error_deleteUser
                        this.getSwalMsg(err.error_code);
                    }
                );
            }
        });
    }

    async deleteProfilePicture() {
        try {
            const user = await this.usersService.deletePicture(this.user._id);
            this.user = user;
            this.userChange.emit(this.user);

            this.setUserLogo();
        } catch (e) {
            // e.error_code is set to error_deletePicture
            this.getSwalMsg(e.error_code);
        }
    }

    /**
     * Returns the right to edit the user's type.
     * Only admin users can edit the user's type, except during the creation.
     * @return {boolean}
     */
    canEditType(): boolean {
        if (this.isCreating) {
            return true;
        }
        // Is user an user admin or from citron (EM or admin) and can edit this profil
        return (
            (this.usersService.isFromCitron(this.userSession) || this.usersService.isUserAdmin(this.userSession)) &&
            this.canEdit
        );
    }

    /**
     * Returns the right to delete.
     * The user logged in can't delete his own profile. Only admin can delete a profile.
     * @return {boolean}
     */
    canDeleteProfile(): boolean {
        return (
            this.userSession._id !== this.user._id && !this.isCreating && this.usersService.isAdmin(this.userSession)
        );
    }

    navigateToCompanyTabUsers() {
        let route = '/admin/entreprise/' + this.createdFromCompanyId;
        route += [UserTypes.USER, UserTypes.USER_ADMIN].includes(this.userSaved.type)
            ? '/utilisateurs'
            : '/energy-managers';
        this.router.navigateByUrl(route);
    }

    displayBackToCompany() {
        return this.createdFromCompanyId && !this.isCreating;
    }
}
