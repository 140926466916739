import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { ResetPasswordComponent } from './reset-password.component';

const RESETPASSWORD_ROUTE = [{ path: '', component: ResetPasswordComponent }];

@NgModule({
    declarations: [ResetPasswordComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(RESETPASSWORD_ROUTE)],
})
export class ResetPasswordModule {}
