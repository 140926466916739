import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

import { Company } from 'app/shared/models/company.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { SitePopulated } from 'app/shared/models/site.interface';
import { Vehicle } from 'app/shared/models/vehicle.interface';
import { ColorService } from 'app/shared/services/color/color.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { VehiclesService } from 'app/shared/services/vehicles/vehicles.service';

type Entity = RoutingReference | Vehicle;

@Component({
    selector: 'ga-site-basic-info-form',
    templateUrl: './basic-information-form.component.html',
    styleUrls: ['./basic-information-form.component.scss'],
    providers: [],
})
export class SiteBasicInformationFormComponent implements OnInit {
    /**
     * Site's company
     */
    @Input() company: Company;

    /**
     * Entity type authorized to be unlinked
     */
    @Input() entityType: 'vehicules' | 'routingReferences';

    /**
     * Event triggered when the site has been deleted.
     * Value is site deleted.
     */
    @Output() siteDeleted: EventEmitter<SitePopulated> = new EventEmitter<SitePopulated>(true);

    /**
     * Event triggered when an entity has been unlinked.
     * Value is entity unlinked.
     */
    @Output() entityUnlinked: EventEmitter<Entity> = new EventEmitter<Entity>(true);

    /**
     * Site's form group for name and search query (for address)
     */
    siteForm: FormGroup;

    /**
     * Site displayed
     */
    _site: SitePopulated;
    @Input()
    set site(site: SitePopulated) {
        this._site = site;
        this.initForm();
        if (this._site) {
            // Set form values with the sites infos
            const siteComplement = typeof site.complement !== 'undefined' ? site.complement : null;
            const siteName = typeof site.name !== 'undefined' ? site.name : null;
            this.siteForm.setValue({
                name: siteComplement,
                search: siteName,
            });
            // Set the address.
            // It's a copy so that we don't change the site object.
            this.addressSite = {
                changed: false,
                error: null,
                name: siteName,
                streetNumber: site.streetNumber,
                streetName: site.streetName,
                zipcode: site.zipcode,
                city: site.city,
                longitude: site.longitude,
                latitude: site.latitude,
            };
        } else {
            this.siteForm.setValue({
                name: '',
                search: '',
            });
            this.addressSite = {
                changed: false,
                error: null,
                name: null,
                streetNumber: null,
                streetName: null,
                zipcode: null,
                city: null,
                longitude: null,
                latitude: null,
            };
        }
    }
    get site(): SitePopulated {
        return this._site;
    }

    /**
     * Address fields to display under the site form
     */
    addressSite: {
        changed: boolean;
        error: ApiCitronError;
        name: string;
        streetNumber: string;
        streetName: string;
        zipcode: string;
        city: string;
        longitude: number;
        latitude: number;
    } = {
        changed: false,
        error: null,
        name: null,
        streetNumber: null,
        streetName: null,
        zipcode: null,
        city: null,
        longitude: null,
        latitude: null,
    };

    constructor(
        private sitesService: SitesService,
        private colorService: ColorService,
        private vehiclesService: VehiclesService
    ) {}

    ngOnInit() {
        this.initForm();
    }

    /**
     * Check if input name value has changed in comparison to current text value
     * @returns {boolean}
     */
    get isNameChanged(): boolean {
        if (!this.siteForm) {
            return false;
        }

        let name = this.siteForm.get('name').value;
        if (typeof name === 'string') {
            name = name.trim();
        }

        let complement = this.site.complement;
        if (typeof complement === 'string') {
            complement = complement.trim();
        }

        return name && name !== complement ? true : false;
    }

    /**
     * Check if input search value has changed in comparison to current text value
     * @returns {boolean}
     */
    get isSearchChanged(): boolean {
        if (!this.siteForm) {
            return false;
        }

        let search = this.siteForm.get('search').value;
        if (typeof search === 'string') {
            search = search.trim();
        }

        let name = this.site.name;
        if (typeof name === 'string') {
            name = name.trim();
        }

        return search !== name ? true : false;
    }

    /**
     * Init site form for name and search if not already initialized
     */
    private initForm() {
        if (!this.siteForm) {
            this.siteForm = new FormGroup({
                name: new FormControl(),
                search: new FormControl(),
            });
        }
    }

    /*****************
     * SITE INFORMATION FORM
     *****************/

    /**
     * Get a property value of the site selected's address
     * @param {string} property - property name
     * @returns {string} address property value
     */
    getSiteSelectedAddressProperty(property: string): string {
        if (!this.site || !this.addressSite) {
            return null;
        }

        return this.addressSite[property];
    }

    /**
     * Save site information
     * @param {{name: string, search: string}} values of the form
     */
    async submitSite(values: { name: string; search: string }) {
        const site = this.site;

        if (site && values) {
            const isNameChanged = values.name !== site.complement;
            const isSearchChanged = values.search !== site.name;

            // If name has been changed => Save name
            if (Boolean(isNameChanged && values.name)) {
                const oldValue = site.complement;
                site.complement = values.name;
                try {
                    const res = await this.sitesService.updateSite(site._id, site);
                    // Check if name changed
                    if (res.data.complement !== site.complement) {
                        // Should not happen
                        site.complement = res.data.complement;
                    }
                } catch (e) {
                    site.complement = oldValue;
                }
            }
            // If search address has been changed, but not checked => Check location
            if (isSearchChanged && values.search && !this.addressSite.changed) {
                try {
                    const res = await this.sitesService.searchAddress(values.search, false, site._id, this.company._id);
                    if (res.data) {
                        const addressFound = res.data;
                        Object.assign(this.addressSite, addressFound);
                        this.addressSite.streetNumber = addressFound.streetNumber ? addressFound.streetNumber : null;
                        this.addressSite.changed = true;
                        this.addressSite.error = null;
                    }
                } catch (e) {
                    this.addressSite.error = e;
                }
                // If search address has been changed and checked => Save location
            } else if (isSearchChanged && values.search && this.addressSite.changed && !this.addressSite.error) {
                const location = Object.assign({}, this.addressSite);
                delete location.changed;
                try {
                    const res = await this.sitesService.saveLocation(site._id, location, this.company._id);
                    if (res.data) {
                        const siteNew = res.data;
                        // If site address is unchanged (same coordinates)
                        if (siteNew.latitude === location.latitude && siteNew.longitude === location.longitude) {
                            Object.assign(site, location);
                            site.streetNumber = location.streetNumber ? location.streetNumber : null;
                            this.addressSite.changed = false;
                        }
                        this.addressSite.error = null;
                    }
                } catch (e) {
                    this.addressSite.error = e;
                }
            }
        }
    }

    /**
     * Get the save button text
     * @returns {string} save btn text
     */
    get saveButtonText(): string {
        if (this.site && this.siteForm) {
            /** Check if search and name values have changed */
            if (this.isNameChanged && this.isSearchChanged) {
                if (this.addressSite.changed) {
                    return 'Enregistrer';
                }
                return 'Renommer et rechercher';
            }

            /** Check if name value has changed */
            if (this.isNameChanged && !this.isSearchChanged) {
                return 'Enregistrer le nom';
            }

            /** Check if search and addressSite values have changed */
            if (this.isSearchChanged && this.addressSite.changed) {
                return 'Enregistrer l\'adresse';
            }

            /** Check if search value has changed */
            if (this.isSearchChanged && !this.addressSite.changed) {
                return 'Rechercher une adresse';
            }
        }
    }

    /**
     * Add a save class on the button
     * @returns {string} save btn class
     */
    get buttonSaveClass(): string {
        if (this.site && this.siteForm) {
            if (this.isNameChanged) {
                return 'save-btn';
            }

            if (this.isSearchChanged && this.addressSite.changed) {
                return 'save-btn';
            }
        }
        return '';
    }

    /**
     * Reset the selected site information and form
     */
    cancelSiteInfo() {
        this.site = this._site;
    }
    /**
     * @returns {string} if the address changed, returns a specific class to highlight it.
     */
    get addressClass(): string {
        return this.addressSite.changed ? 'address-changed' : '';
    }

    /**
     * Get the error message for the address.
     */
    get errorMessage(): string {
        if (!this.addressSite || !this.addressSite.error || !this.addressSite.error.errorCode) {
            return null;
        }
        const errorCode = this.addressSite.error.errorCode;
        if (errorCode === 'address_not_found') {
            return 'Adresse introuvable';
        }
        return 'Une erreur est survenue';
    }

    /**
     * Delete site and emit onSiteDeleted event with site deleted
     */
    async deleteSite() {
        try {
            const site = this.site;
            const result = await Swal.fire({
                title: 'Êtes-vous sûr ?',
                text:
                    'Voulez-vous vraiment supprimer le site ' + (site.complement ? site.complement : site.name) + ' ?',
                icon: 'warning',
                showCancelButton: true,
            });
            if (result.value) {
                await this.sitesService.deleteSite(site._id);
                this.site = null;
                this.siteDeleted.emit(site);
            }
        } catch (e) {
            Swal.fire(
                'Erreur',
                'Une erreur est survenue pendant la suppression du site. Merci de réessayer ultérieurement.',
                'error'
            );
        }
    }

    /*****************
     * SITE INFORMATION ENTITY LIST
     *****************/

    /**
     * Get the fluid color for a given pdl
     * @param {RoutingReference} pdl populated with contracts
     * @returns {string} formatted CSS rbg or null if the routing reference has no fluid (no contracts)
     */
    getRoutingReferenceFluidColor(pdl: RoutingReference): string {
        if (!pdl || !pdl.energyType) {
            return null;
        }
        const fluid = pdl.energyType;
        return this.colorService.getSecondRgbColor(fluid);
    }

    /**
     * Get color for vehicle's fluid color (fuel)
     * @returns {string} formatted CSS rbg or null if the vehicle has no fluid
     */
    get vehicleFluidColor(): string {
        return this.colorService.getSecondRgbColor('fuel');
    }

    /**
     * Get the fluid value for a given pdl
     * @param {RoutingReference} pdl populated with contracts
     * @returns {string} fluid value (eg. 'elec') or null if the pdl has no fluid (no contracts)
     */
    getRoutingReferenceFluidValue(pdl: RoutingReference): string {
        if (!pdl || !pdl.energyType) {
            return null;
        }
        return pdl.energyType;
    }

    /**
     * Get entity order. For example, in routing references attribution, routing references will be displayed first.
     * @returns {string} Boostrap's flex order class
     */
    getEntityOrderClass(entityType: 'vehicles' | 'routingReferences'): string {
        if (entityType === this.entityType) {
            return 'order-0';
        }
        return 'order-1';
    }

    /**
     * @returns {boolean} true to display remove icon, false otherwise
     */
    canUnlink(entityType: 'vehicles' | 'routingReferences'): boolean {
        return Boolean(entityType === this.entityType);
    }

    /**
     * Remove a PDL from it's site.
     * Triggers API call then remove it from local site object
     * @param {RoutingReference} routingReference
     */
    async unlinkRoutingReference(routingReference: RoutingReference) {
        try {
            const site = this.site;
            await this.sitesService.removeRoutingReferenceFromSite(routingReference._id, site._id);
            // Remove from the front object
            site.routingReferences = site.routingReferences.filter(x => x._id !== routingReference._id);
            this.entityUnlinked.emit(routingReference);
        } catch (e) {
            Swal.fire(
                'Erreur',
                'Une erreur est survenue pendant la dissociation du PDL. Merci de réessayer ultérieurement.',
                'error'
            );
        }
    }

    /**
     * Remove a vehicle from it's site.
     * Triggers API call then remove it from local site object
     * @param {Vehicle} vehicle
     */
    async unlinkVehicle(vehicle: Vehicle) {
        try {
            const site = this.site;
            await this.vehiclesService.unlinkVehicleFromSite(vehicle._id);
            // Remove from the front object
            site.vehicles = site.vehicles.filter(x => x._id !== vehicle._id);
            this.entityUnlinked.emit(vehicle);
        } catch (e) {
            Swal.fire(
                'Erreur',
                'Une erreur est survenue pendant la dissociation du véhicule. Merci de réessayer ultérieurement.',
                'error'
            );
        }
    }
}
