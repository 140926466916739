import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

@Injectable()
export class PageService {
    public fake = false;

    constructor(public apiService: ApiService) {}

    get(route: string, options = null, data = null): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.fake) {
                return this.apiService.get(route, options, data).subscribe({
                    next: response => {
                        return resolve(response);
                    },
                    error: (error: HttpErrorResponse) => {
                        return reject(error.error);
                    },
                });
            } else {
                return resolve(this.fakeData(route));
            }
        });
    }

    post(route: string, data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.fake) {
                return this.apiService.post(route, data).subscribe({
                    next: response => {
                        return resolve(response);
                    },
                    error: (error: HttpErrorResponse) => {
                        return reject(error.error);
                    },
                });
            } else {
                return resolve(this.fakeData(route));
            }
        });
    }

    put(route: string, data: any, options = null, force = false): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.fake) {
                return this.apiService.put(route, data, options, force).subscribe({
                    next: response => {
                        return resolve(response);
                    },
                    error: (error: HttpErrorResponse) => {
                        return reject(error.error);
                    },
                });
            } else {
                return resolve(this.fakeData(route));
            }
        });
    }

    delete(route: string, option: any = null): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.fake) {
                return this.apiService.delete(route, option).subscribe({
                    next: response => {
                        return resolve(response);
                    },
                    error: (error: HttpErrorResponse) => {
                        return reject(error.error);
                    },
                });
            } else {
                return resolve(this.fakeData(route));
            }
        });
    }

    patch(route: string, data: any, options = null): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.fake) {
                return this.apiService.patch(route, data, options).subscribe({
                    next: response => {
                        return resolve(response);
                    },
                    error: (error: HttpErrorResponse) => {
                        return reject(error.error);
                    },
                });
            } else {
                return resolve(this.fakeData(route));
            }
        });
    }

    fakeData(route): object {
        return {};
    }
}
