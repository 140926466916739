import { Component, Input, OnInit } from '@angular/core';
import { sortBy } from 'lodash';
import Swal from 'sweetalert2';

import { ContractualData } from 'app/shared/models/contractual-data.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { GetContractualDataFilters } from 'app/shared/services/contractual-data/interfaces';

import { RRefContractualDataService } from './pdl-contractual-data.service';

interface ContractualDataConfig {
    fluid: string;
    retrievalFilters: GetContractualDataFilters;
    propertiesDisplay: Array<{
        key: string;
        translateKey: string;
        unit: string;
    }>;
}

@Component({
    selector: 'pdl-contractual-data',
    templateUrl: './pdl-contractual-data.component.html',
    styleUrls: ['./pdl-contractual-data.component.scss'],
    providers: [RRefContractualDataService],
})
export class RRefContractualDataComponent implements OnInit {
    @Input() routingReference: RoutingReference;

    /** Routing reference's contractual data */
    public rrefContractualData: ContractualData;

    /** Config for retrieval and display contractual data */
    public contractualDataConfig: ContractualDataConfig;

    /** Config indicating how to retrieve and display contractual data depending on routing reference fluid */
    public readonly CONFIG_CONTRACTUAL_DATA: ContractualDataConfig[] = [
        {
            fluid: 'elec',
            retrievalFilters: {
                keys: ['segment', 'connectionPowerRacking'],
                onlyLast: true,
                excludeData: false,
                excludeSynchronizations: true,
            },
            propertiesDisplay: [
                {
                    key: 'segment',
                    translateKey: 'segment',
                    unit: '',
                },
                {
                    key: 'connectionPowerRacking',
                    translateKey: 'connection_power_racking',
                    unit: 'kVA',
                },
            ],
        },
    ];

    constructor(private readonly rRefContractualDataService: RRefContractualDataService) {}

    async ngOnInit() {
        try {
            this.contractualDataConfig = this.CONFIG_CONTRACTUAL_DATA.find(
                config => config.fluid === this.routingReference.energyType
            );

            if (this.contractualDataConfig) {
                this.rrefContractualData = await this.rRefContractualDataService.getRRefContractualData(
                    this.routingReference,
                    this.contractualDataConfig.retrievalFilters
                );
            }
        } catch (error) {
            await Swal.fire({
                title: 'Une erreur est survenue',
                text: 'Impossible de récupérer les données contractuelles. Veuillez réessayer ultérieurement.',
                icon: 'error',
            });
        }
    }

    public showContractualData(): boolean {
        return Boolean(this.hasContractualData() && this.contractualDataConfig);
    }

    public getDataPropertyLastValue(key: string): unknown | null {
        if (!this.hasContractualData()) {
            return null;
        }

        const dataProperty = this.rrefContractualData.data.find(dataProp => dataProp.key === key);

        if (dataProperty) {
            const orderedValues = sortBy(dataProperty.values, 'dateStart');
            const lastValue = orderedValues.slice(-1)[0];

            return lastValue ? lastValue.value : null;
        }
        return null;
    }

    private hasContractualData(): boolean {
        return Boolean(
            this.rrefContractualData && this.rrefContractualData.data && this.rrefContractualData.data.length
        );
    }
}
