import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import { RoutingReferenceKeyfigures } from '../rref-merge.interface';
import { RrefMergeService } from '../rref-merge.service';

@Component({
    selector: 'rref-merge-preview',
    templateUrl: './rref-merge-preview.component.html',
    styleUrls: ['./rref-merge-preview.component.scss'],
})
export class RrefMergePreviewComponent implements OnInit {
    /** Merge routing reference preview */
    public rRefPreview: RoutingReferenceKeyfigures = null;

    constructor(private rrefMergeService: RrefMergeService) {}

    ngOnInit() {
        this.rrefMergeService.rRefPreview$.subscribe({
            next: value => {
                this.rRefPreview = value;
            },
        });
    }

    /**
     * Handle merge. Fire confirmation modal then, if accepted, merge routing references.
     */
    public async merge() {
        const destination = this.rrefMergeService.rRefDestination$.value;
        const origin = this.rrefMergeService.rRefOrigin$.value;

        const result = await Swal.fire({
            title: `Fusionner les données du PDL ${origin.reference} vers ${destination.reference}`,
            text: `La fusion des données de ces deux PDLs est irréversible. Elle impacte les données de contrats, factures, sites, etc.\n\nSouhaitez-vous confirmer cette fusion ?`,
            confirmButtonText: `Confirmer`,
            showCancelButton: true,
            cancelButtonText: `Annuler`,
        });

        if (result.value && !result.dismiss) {
            try {
                await this.rrefMergeService.mergeRoutingReferences(destination._id, origin._id);
            } catch (e) {
                this.rrefMergeService.displayError(e.errorCode);
            }
            await this.rrefMergeService.afterMerge();
        }
    }
}
