import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { OptimizationComponent } from './optimization.component';

const OPTIMIZATION_ROUTE = [{ path: '', component: OptimizationComponent }];

@NgModule({
    declarations: [OptimizationComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(OPTIMIZATION_ROUTE)],
})
export class OptimizationModule {}
