import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HeatmapProperties } from 'app/shared/models/charts/chart-properties.interface';
import { HeatmapPoint } from 'app/shared/models/charts/chart-serie.interface';
import * as _ from 'lodash';

@Component({
    selector: 'chart-heatmap',
    templateUrl: './chart-heatmap.component.html',
    styleUrls: ['./chart-heatmap.component.scss'],
    providers: [],
})
export class ChartHeatmapComponent implements OnInit {
    @Input() options: HeatmapProperties;
    @Output() cellSelected: EventEmitter<HeatmapPoint> = new EventEmitter<HeatmapPoint>();

    ngOnInit() {}

    /**
     * Emit the selected point with its data to the parent page.
     * @param {*} event
     */
    getSelectedValues(event): void {
        if (event.point) {
            this.cellSelected.emit(event.point.options);
        }
    }
}
