import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-key-figure',
    templateUrl: './key-figure.component.html',
    styleUrls: ['./key-figure.component.scss'],
})
export class KeyFigureComponent implements OnInit {
    @Input() valueKey: number;
    @Input() textSingular: string;
    @Input() textPlural: string;
    @Input() iconePath: string;
    @Input() valueKeyInactive: number = null;

    text: string;

    constructor() {}

    ngOnInit(): void {
        this.setText();
    }

    setText() {
        this.text = this.valueKey < 2 ? this.textSingular : this.textPlural;
    }

    get valueActiveText() {
        return this.valueKey > 1 ? 'actifs' : 'actif';
    }

    get valueInactiveText() {
        return !this.isValueInactiveDefined ? '' : this.valueKeyInactive > 1 ? 'inactifs' : 'inactif';
    }

    get isValueInactiveDefined() {
        return typeof this.valueKeyInactive === 'number';
    }
}
