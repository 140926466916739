import { Component, HostBinding, HostListener, OnInit, ViewContainerRef } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';

import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

declare var $: any;

import { GlobalState } from './app.state';
import { ChartService } from './shared/services/chart/chart.service';
import { ConfigService } from './shared/services/config/config.service';
import { PreloaderService } from './shared/services/preloader/preloader.service';
import { SpinnerService } from './shared/services/spinner/spinner.service';

import * as Highcharts from 'highcharts/highstock';
import { ApiService } from './shared/services/api/api.service';
import { TitleService } from './shared/services/title/title.service';

import { environment } from '../environments/environment';
import { AccessGuard } from './shared/services/guards/access-guard.service';
import { SessionService } from './shared/services/session/session.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html', // we directly go to layout.component.html
    styleUrls: ['./app.component.scss'],
    providers: [],
})
export class AppComponent implements OnInit {
    // App Left Sidebar Menu Open/Close Desktop
    @HostBinding('class.app_sidebar-menu-collapsed')
    get isApp_SidebarLeftCollapsed() {
        return this.config.appLayout.isApp_SidebarLeftCollapsed;
    }

    // Left Menu Sidebar Open/Close Tablet & Mobile
    @HostBinding('class.app_sidebar-left-open')
    get isApp_MobileSidebarLeftOpen() {
        return this.config.appLayout.isApp_MobileSidebarLeftOpen;
    }

    // App Right Sidebar Open/Close
    @HostBinding('class.sidebar-overlay-open')
    get isApp_SidebarRightOpen() {
        return this.config.appLayout.isApp_SidebarRightOpen;
    }

    // The constructor is called first time before the ngOnInit()
    // The constructor should only be used to initialize class members but shouldn't do actual "work".
    // So you should use constructor() to setup Dependency Injection and not much else.
    constructor(
        private _state: GlobalState,
        public config: ConfigService,
        private viewContainerRef: ViewContainerRef,
        private _spinner: SpinnerService,
        private apiService: ApiService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: TitleService,
        private chartService: ChartService,
        public sessionService: SessionService,
        private accessGuard: AccessGuard
    ) {}

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    // called after the constructor and called  after the first ngOnChanges()
    // ngOnInit() is better place to "start" - it's where/when components' bindings are resolved.
    ngOnInit() {
        $(document).on('click', '[href="#"]', e => e.preventDefault());
        // Listen for every router page change to update the title.
        // If event['title] is not defined, it will take the title already set for the page (displayed on the top of a page)
        // Or the subtitle if exists.
        // To define a specific title, change the route in the wanted module and add data: {title: "MyTitle"}.
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe({ next: event => this.setTitle(event['title']) });

        if (environment.isProduction) {
            this.router.events.subscribe({
                next: event => {
                    if (event instanceof NavigationEnd) {
                        (window as any).ga('set', 'page', event.urlAfterRedirects);
                        (window as any).ga('send', 'pageview');
                    }
                },
            });
        }

        // Set highcharts lang
        Highcharts.setOptions({
            lang: this.chartService.getHighchartLang(),
        });
        this.accessGuard.load();
    }

    checkAuth() {
        this.router.events.forEach(event => {
            if (event instanceof NavigationStart) {
                this.sessionService.checkVersion(environment.VERSION, true);

                const token = this.apiService.getToken();

                if (event.url === '/authentification' && token) {
                    this.router.navigateByUrl('/accueil');
                } else if (event.url !== '/authentification' && !token) {
                    this.router.navigateByUrl('/authentification');
                }
            }
        });
    }

    // check if menu should reset on resize
    @HostListener('window:resize')
    public onWindowResize(): void {
        if (this._shouldMenuReset()) {
            this.config.appLayout.isApp_SidebarLeftCollapsed = false;
        }
    }

    private _shouldMenuReset(): boolean {
        return window.innerWidth <= this.config.breakpoint.desktopLG;
    }

    // tslint:disable-next-line
    public ngAfterViewInit(): void {
        PreloaderService.load().then(values => {
            this._spinner.hide();
        });
    }
}
