import { Injectable } from '@angular/core';
import { PageService } from 'app/shared/services/page/page.service';

import { QueryFilter } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { CommonEnergyRepartition, CommonEnergyRepartitionMonthly } from 'app/shared/models/energy-data-bills.interface';
import { SiteRoutingRefContractConso } from 'app/shared/models/site.interface';

@Injectable()
export class EnergyDataService extends PageService {
    /**
     * Returns consumptions per energy type, per year and month
     * @param {QueryFilter} filters - filters to apply to get consumption
     * @param {string} groupType - filters to apply to get consumption
     * @returns {Promise<CommonEnergyRepartitionMonthly[]>}
     */
    async getMonthlyGlobalEnergyRepartition(
        filters: QueryFilter,
        groupType = 'monthly'
    ): Promise<CommonEnergyRepartitionMonthly[]> {
        const res = await this.post(`/api/common/global/${groupType}`, filters);
        return res.data;
    }

    /**
     * Returns consumptions per energy type, per year
     * @param {*} filters - filters to apply to get consumption
     * @returns {Promise<CommonEnergyRepartition[]>}
     */
    async getYearlyGlobalEnergyRepartition(filters): Promise<CommonEnergyRepartition[]> {
        const res = await this.post(`/api/common/global/yearly`, filters);
        return res.data;
    }

    /**
     * Get data consumption for a triple key: site / contract / n°PDL
     * @param {QueryFilter} filters
     * @returns {Promise<SiteRoutingRefContractConso[]>} consumptions for triple key
     */
    async getCommonConsumptionPerSite(filters: QueryFilter): Promise<SiteRoutingRefContractConso[]> {
        const res = await this.post('/api/common/consumption', filters);
        return res.data;
    }
}
