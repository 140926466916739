/**
 * Monitoring interface used to retrieve a monitoring from back
 */
export interface Monitoring {
    _id: string;
    companyId: string;
    monitoringType: Types;
    status: Status;
    name: string;
    scope: Scope;
    modulatorBase: number;
    valueCommitted: number;
    valuePerModulatorBase: number;
    contractStart: Date;
    contractEnd: Date;
    deltaTolerancePercentage: number;
    profitSharingPercentage: number;
    penaltiesPercentage: number;
    dataSourceType: DataSource;
    includedCosts: string[];
    uses: Uses[];
    periods: HeatingPeriod[];
    monthlyAggregated: MonthlyAggregated[];
    indexReadings: IndexReading[];
    measuringPoints: MeasuringPoint[];
    djuType: DjuType;
    site: {
        _id: string;
        name: string;
        complement: string;
    };
    createdAt: string;
    updatedAt: string;
}

export interface Scope {
    rRefId: string;
}

export interface Uses {
    use: BRExclusibleUses;
    exclusionType?: ExclusionTypes;
    perfFormula?: {
        gradient: number;
        yIntercept: number;
    };
    q?: {
        highSeason: number;
        lowSeason: number;
    };
    nHousing?: number;
    avgVolume?: number;
    createdAt: string;
    updatedAt: string;
}

export interface IndexReading {
    _id: string;
    volume: number;
    date: string;
    consumptionDelta?: number;
    measuringPointId: string;
    createdAt: string;
    updatedAt: string;
}

export interface MeasuringPoint {
    _id: string;
    name: string;
    description?: string;
    uses: BRExclusibleUses;
    volumeUnit: VolumeUnits;
    createdAt: string;
    updatedAt: string;
}

export interface MonitoringFilters {
    companies: string[];
    readonly itemsPerPage: number;
    itemsToDisplay: number;
    status?: Status;
}

export interface HeatingPeriod {
    _id: string;
    periodStart: string;
    periodEnd: string;
    consumption?: number;
    consumptionHeating?: number;
    costs?: number;
    modulatorReal?: number;
    modulatorRealOnBillsPeriod?: number;
    valueRevalued?: number;
    valueRevaluedOnBillsPeriod?: number;
    consumptionHeatingPerValueRevalued?: number;
    valuePerModulatorBase?: number;
    consumptionHeatingPerModulatorReal?: number;
    deviation?: number;
    profits?: number;
    createdAt: string;
    updatedAt: string;
}

export interface MonthlyAggregated {
    _id: string;
    monthStart: string;
    monthEnd: string;
    consumption: number;
    consumptionHeating: number;
    costs: number;
    modulatorReal: number;
    modulatorRealOnBillsPeriod: number;
    valueRevalued: number;
    valueRevaluedOnBillsPeriod: number;
    consumptionHeatingPerValueRevalued: number;
    deviation: number;
    createdAt: string;
    updatedAt: string;
}

/**
 * The current status of the monitoring:
 * - efficient: lower than expectations
 * - drifting: higher than expectations
 * - idle: not in an active period currently
 */
export enum Status {
    EFFICIENT = 'efficient',
    DRIFTING = 'drifting',
    IDLE = 'idle',
}

/**
 * Type of the monitoring:
 * - boilerRoom: A boiler room for heating
 */
export enum Types {
    BOILER_ROOM = 'boilerRoom',
}

export enum DataSource {
    BILLING = 'billing',
}

export enum ExclusionTypes {
    INDEX_READING = 'indexReading',
    PERF_FORMULA = 'perfFormula',
    HOUSING_THEORETICAL = 'housingTheoretical',
}

export enum BRExclusibleUses {
    HEATING = 'heating',
    HOT_WATER = 'hotWater',
}

export enum VolumeUnits {
    M3 = 'm3',
    L = 'L',
}

export enum DjuType {
    HOT = 'hot',
    COLD = 'cold',
}

/**
 * Monitoring periods season keyfigures
 */
export interface SeasonKeyfigures {
    /** Start date of season at ISO string format */
    startDate: string;
    /** Number of efficient heating periods */
    countEfficient: number;
    /** Number of drifting heating periods */
    countDrifting: number;
    /** Profit of efficient periods */
    profitsEfficient: number;
    /** Profit of drifting periods */
    profitsDrifting: number;
    /** Sum of consumption heating of periods (NC) */
    consumptionHeating: number;
    /** Sum of consumption heating of efficient periods (NC) */
    consumptionHeatingEfficient: number;
    /** Sum of consumption heating of drifting periods (NC) */
    consumptionHeatingDrifting: number;
    /** Sum of values revalued on bills period of periods (N'B) */
    valueRevaluedOnBillsPeriod: number;
    /** Deviation of all periods */
    deviation: number;
}
