import { Component, Input, OnInit } from '@angular/core';
import { FilterService } from 'app/shared/services/filter/filter.service';
import * as moment from 'moment';

interface ScopeFilter {
    dateStart?: string;
    dateEnd?: string;
    energyTypes?: string | string[];
    sites?: string | string[];
    regions?: string | string[];
    categories?: string | string[];
    routingreferences?: string | string[];
    sitesStatus?: string;
    routingreferencesStatus?: string;
    customFilter?: string;
}

@Component({
    selector: 'ga-data-scope-filter-readonly',
    templateUrl: './data-scope-filter-readonly.component.html',
    styleUrls: ['./data-scope-filter-readonly.component.scss'],
    providers: [],
})
export class DataScopeFilterReadonlyComponent implements OnInit {
    private _scope: ScopeFilter = {};
    @Input()
    set scope(scope: ScopeFilter) {
        this._scope = scope;
        this.getScopeDisplayValues();
    }
    get scope(): ScopeFilter {
        return this._scope;
    }

    scopeDisplay: Array<{
        title: string;
        value: string;
    }> = [];

    constructor(private filterService: FilterService) {}

    ngOnInit() {}

    async getScopeDisplayValues() {
        try {
            const scope = Object.assign({}, this.scope, { energies: this.scope.energyTypes });
            const result = await this.filterService.getScopeFilterDisplayValues(scope);
            const scopeValues = result.data;

            this.scopeDisplay = [];
            this.handleDates(scopeValues);
            this.handleValueList(scopeValues.energies, 'Énergies');
            this.handleValueList(scopeValues.sites, 'Sites');
            this.handleValueList(scopeValues.regions, 'Régions');
            this.handleValueList(scopeValues.categories, 'Catégories');
            this.handleValueList(scopeValues.routingreferences, 'PDLs');
            this.handleValue(scopeValues.sitesStatus, 'Status des sites');
            this.handleValue(scopeValues.routingreferencesStatus, 'Status des PDLs');
            this.handleValue(scopeValues.customFilter, 'Filtre personnalisé');
        } catch (err) {
            this.scopeDisplay = [];
        }
    }

    private handleDates(scope) {
        let start = null;
        let end = null;
        if (scope.dateStart) {
            start = moment(scope.dateStart).format('MMMM YYYY');
        }
        if (scope.dateEnd) {
            end = moment(scope.dateEnd).format('MMMM YYYY');
        }
        if (start || end) {
            if (start && end) {
                this.scopeDisplay.push({
                    title: 'Période',
                    value: `De ${start} à ${end}`,
                });
            } else if (start) {
                this.scopeDisplay.push({
                    title: 'Date de début',
                    value: `${start}`,
                });
            } else if (end) {
                this.scopeDisplay.push({
                    title: 'Date de fin',
                    value: `${end}`,
                });
            }
        }
    }

    private handleValue(value, title) {
        if (value) {
            this.scopeDisplay.push({
                title,
                value,
            });
        }
    }

    private handleValueList(values, title) {
        if (values && Array.isArray(values) && values.length) {
            this.scopeDisplay.push({
                title,
                value: values.join('<br>'),
            });
        }
    }
}
