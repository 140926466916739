import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { NgSelectComponent } from '@ng-select/ng-select';
import { DropdownSettings } from './dropdown-multilevel.interface';

@Component({
    selector: 'ga-dropdown-multilevel',
    templateUrl: './dropdown-multilevel.component.html',
    styleUrls: ['./dropdown-multilevel.component.scss'],
})
export class DropdownMultilevelComponent<T> implements OnInit {
    /**
     * Dropdown list to display
     */
    @Input() list: T[];

    /**
     * Dropdown multilevel configuration
     */
    @Input() settings: DropdownSettings;

    /**
     * Emit the selected items to the parent component
     */
    @Output() itemSelected: EventEmitter<T> = new EventEmitter<T>();

    /**
     * The selected items in the dropdown
     */
    @Input()
    public selectedItem: T;

    /**
     * Placeholder text to display
     */
    @Input()
    public placeholder: string;

    /**
     * Indicates if currently selecting all items.
     */
    private isSelectingAll = false;

    /**
     * NgSelectComponent
     */
    @ViewChild(NgSelectComponent, { static: false }) component: NgSelectComponent;

    constructor() {}

    ngOnInit() {}

    /**
     * Emit the selected item to the parent component
     */
    onChange() {
        // Don't emit selection if selecting all items.
        if (!this.isSelectingAll) {
            this.itemSelected.emit(this.selectedItem);
        }
    }

    /**
     * Handle select all action
     */
    public onSelectAll() {
        /**
         * Select method on component triggers selection event.
         * So we need to make sure itemSelected not emitted while selecting all, but once at the end.
         */
        this.isSelectingAll = true;
        this.component.itemsList.items.forEach(item => {
            this.component.select(item);
        });
        this.isSelectingAll = false;
        this.onChange();
    }
}
