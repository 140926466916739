import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'ga-popover-history',
    templateUrl: './history-popover.component.html',
    styleUrls: ['./history-popover.component.scss'],
})
export class HistoryPopoverComponent implements OnInit {
    @Input() entity: any = [];

    historyDisplay: any = [];
    fullDisplay = false;

    constructor() {}
    ngOnInit() {
        this.computeHistory();
    }

    public computeHistory() {
        this.historyDisplay = [];
        if (this.entity && this.entity.createdAt) {
            this.historyDisplay.push({
                actionText: 'Ajouté',
                actionClass: 'action-add',
                user: 'Citron',
                date: moment(this.entity.createdAt).format('DD/MM/YYYY'),
            });
        }
        if (this.entity && this.entity.status && this.entity.status.history) {
            const history = this.entity.status.history.concat();
            history.forEach((action, index) => {
                const line: any = {};
                if (index === 0 && action.activation && !this.entity.createdAt) {
                    line.actionText = 'Ajouté';
                    line.actionClass = 'action-add';
                } else {
                    line.actionText = action.activation ? 'Activé' : 'Désactivé';
                    line.actionClass = action.activation ? 'action-enable' : 'action-disable';
                }
                if (typeof action.user === 'object') {
                    line.user = action.user.firstname + ' ' + action.user.name;
                } else {
                    line.user = 'NC';
                }
                line.date = moment(action.date).format('DD/MM/YYYY H:mm:ss');
                this.historyDisplay.push(line);
            });
        }
    }
    /**
     * Return true if the history matching the index needs to be displayed (by default display the first and last two)
     */
    isHistoryToDisplay(index): boolean {
        return (
            this.fullDisplay ||
            (index === 0 || index === this.historyDisplay.length - 1 || index === this.historyDisplay.length - 2)
        );
    }

    hideDisplayMore(index) {
        return index !== 0 || this.fullDisplay || this.historyDisplay.length <= 3;
    }
}
