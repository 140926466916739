import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable()
export class TitleService {
    titles: any = {
        exemple: {
            index: "Page d'exemple",
        },
        accueil: {
            index: 'Accueil',
        },
        cartographie: {
            index: 'Cartographie des sites',
        },
        collecte: {
            index: 'Collecte automatique',
            regex: 'Amélioration des regex',
        },
        analyse: {
            index: 'Analyse des données',
            croisement: 'Croisement des données',
            classement: 'Classement des sites',
            comparaison: 'Comparaison temporelle',
        },
        energie: {
            index: "Gestion de l'énergie",
            pdl: 'Profil du PDL',
            suivi: 'Suivi temporel',
            profil: 'Profil du site',
            merge: 'Localisation',
            repartition: 'Répartition des flux',
            classement: 'Classement des sites',
            'courbes-de-charge': 'Courbes de charge',
        },
        eau: {
            index: "Gestion de l'eau",
            pdl: 'Profil du PDL',
            suivi: 'Suivi temporel',
            profil: 'Profil du site',
            merge: 'Localisation',
            classement: 'Classement des sites',
        },
        vehicules: {
            index: 'Gestion des véhicules',
            flotte: 'Données de la flotte',
            profil: 'Profil du site',
            suivi: 'Suivi des consommations',
            parc: 'Suivi du parc',
        },
        documents: {
            index: 'Gestion des documents',
        },
        contrats: {
            index: 'Gestion des contrats',
        },
        rse: {
            index: 'RSE',
        },
        veille: {
            index: 'Veille règlementaire',
        },
        '404': {
            index: 'Page 404',
        },
        admin: {
            index: 'Admin',
            entreprise: "Fiche de l'entreprise",
            utilisateur: "Fiche de l'utilisateur",
            entreprises: 'Entreprises',
            utilisateurs: 'Utilisateurs',
        },
        specific: {
            index: 'Fonctionnalités Spécifiques',
            resah: 'RESAH',
            cotisation: 'Cotisation',
        },
        cibles: {
            index: 'Cibles',
            campagnes: 'Campagnes',
            'suivis-exploitation': "Suivis des performances d'exploitation",
        },
        factures: {
            index: 'Gestion des factures',
        },
        controle: {
            index: 'Contrôle des factures',
        },
        dju: {
            index: 'Export des DJU',
        },
        'parametrage-alertes': {
            index: 'Paramétrage des alertes',
        },
    };

    constructor(private titleService: Title, private router: Router, private analyticsService: AnalyticsService) {}

    getTitle(url: string): string {
        const routes = url.split('/').slice(1);
        const title = this.titles[routes[0].split('?')[0]];
        return title ? title.index : ''; // accueil?refresh=true
    }

    getSubtitle(url: string): string {
        // url: "/eau/suivi?siteId=59bba31d5099a902a3b1225b" --> segments: ["eau", "suivi?siteId=59bba31d5099a902a3b1225b"]
        // url : ["accueil"] --> segments: ["eau"]
        const segments = url.split('/').slice(1);

        // segments: ["eau", "suivi?siteId=59bba31d5099a902a3b1225b"]
        // --> mainRoute: [ "eau"] & segments: ["suivi?siteId=59bba31d5099a902a3b1225b"]
        // segments: ["eau"]
        // mainRoute: ["eau"] & segments: []
        const mainRoute = segments.shift();

        // segments: ["suivi?siteId=59bba31d5099a902a3b1225b"]  --> secondSegment: ["suivi"]["siteId=59bba31d5099a902a3b1225b"]
        // segments: [] --> secondSegment: []
        let secondSegment = segments[0] ? segments[0].split('?') : [];

        let subtitle = '';

        if (secondSegment[0]) {
            // delete from the URL the parts that don't match any subtitle
            secondSegment = secondSegment.map(r => this.titles[mainRoute][r]).filter(urlSegment => urlSegment);
            subtitle = secondSegment.join(' - '); // join with '/' isn't working
        }
        return subtitle;
    }

    getTitleColor(url) {
        let titleColor = null;
        const urlPartsQuery = url.split('?');
        titleColor = urlPartsQuery && urlPartsQuery.length ? urlPartsQuery[0] : '';
        if (url.split('/').length - 1 > 1) {
            titleColor = titleColor.split('/')[1];
        } else {
            titleColor = titleColor.replace('/', '');
        }

        if (titleColor === '404') {
            titleColor = 'error';
        }
        return titleColor;
    }
    /**
     * Set page title (tab or page in browser). If a title is provided, will set with this title.
     * If no title provided, will look for the default title for the page or the subtitle if exists.
     * Suffix all title with " - Citron".
     * If no title provided, and no title found for the page, the title will just be "Citron"
     * @param {string} newTitle
     */
    setTitle(newTitle: string = '') {
        const baseTitle = 'Citron';
        let title = baseTitle;
        if (newTitle) {
            title = newTitle;
        } else {
            const tmpTitle = this.getTitle(this.router.url);
            const tmpSubTitle = this.getSubtitle(this.router.url);
            if (tmpSubTitle) {
                title = tmpTitle ? tmpTitle + ' - ' + tmpSubTitle : tmpSubTitle;
            } else if (tmpTitle) {
                title = tmpTitle;
            }
        }
        this.titleService.setTitle(title);
        this.analyticsService.recordTag({
            type: 'navigation',
            properties: {
                citron_page_title: title,
            },
        });
    }
}
