import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [],
})
export class UsersComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
