import { Component, OnInit } from '@angular/core';

import { Marker } from 'app/shared/models/marker.interface';
import { MeteoStation } from 'app/shared/models/meteo-station.interface';
import { ExportService } from 'app/shared/services/export/export.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { DjuService, Location } from './dju.service';

import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { UsersService } from 'app/shared/services/users/users.service';
import { saveAs } from 'file-saver';
import Swal, { SweetAlertIcon } from 'sweetalert2';
@Component({
    selector: 'ga-dju',
    templateUrl: './dju.component.html',
    styleUrls: ['./dju.component.scss'],
    providers: [DjuService, DatePipe],
})
export class DjuComponent implements OnInit {
    /**
     * Map options
     */
    mapOptions = {
        zoom: 5,
        lat: 47,
        lng: 2.29,
        height: 250,
    };

    /**
     * Markers for the map
     */
    markers: Marker[] = [];

    /**
     * Value in search field
     */
    searchValue = '';

    /**
     * Location found for the user search
     */
    location: Location;

    /**
     * Closest meteo station from the location
     */
    station: MeteoStation;

    /**
     * DJU dates available. We mainly use datesSelected but keep bounds in memory.
     */
    datesAvailable: {
        start: Date;
        end: Date;
    };

    /**
     * DJU dates selected for export
     */
    datesSelected: {
        start: string;
        end: string;
    } = {
        start: null,
        end: null,
    };

    /**
     * Are location, station and dates loading
     */
    isLoading = false;

    /**
     * Is currently exporting
     */
    isExporting = false;

    /**
     * DJU possible grouping values for export
     */
    djuGroupValues: Array<{
        value: 'daily' | 'monthly' | 'yearly';
        name: string;
    }> = [
        {
            value: 'daily',
            name: 'daily',
        },
        {
            value: 'monthly',
            name: 'monthly',
        },
        {
            value: 'yearly',
            name: 'yearly',
        },
    ];

    /**
     * DJU selected grouping value
     */
    groupSelected = 'daily';

    constructor(
        private djuService: DjuService,
        private exportService: ExportService,
        private usersService: UsersService,
        private router: Router,
        private datePipe: DatePipe,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        if (!this.usersService.isSessionUserFromCitron()) {
            this.router.navigate(['/404']);
        }
    }

    /**
     * Launch new address search and set location, station and markers.
     */
    async searchAddress() {
        this.isLoading = true;
        const query = this.searchValue;
        try {
            const data = await this.djuService.searchStation(query);

            this.location = data.location;
            this.station = data.station;
            this.datesAvailable = {
                start: new Date(data.dates.start),
                end: new Date(data.dates.end),
            };
            // Set as start date the beginning of date end's year
            const dateYearStart = new Date(this.datesAvailable.end.getFullYear(), 0, 1);
            this.datesSelected = {
                start: this.formatDate(dateYearStart),
                end: this.formatDate(this.datesAvailable.end),
            };

            this.updateMarkers();
        } catch (err) {
            this.handleErrorSwal(err.errorCode);
        }
        this.isLoading = false;
    }

    /**
     * Update location and station markers
     */
    private updateMarkers() {
        const locationMarker: Marker = {
            lat: this.location.latitude,
            lng: this.location.longitude,
            config: 'dju',
            data: {
                title: this.locationAddressDisplay,
            },
            type: 'defaultOne',
        };
        const stationMarker: Marker = {
            lat: this.station.location.coordinates[1],
            lng: this.station.location.coordinates[0],
            config: 'dju',
            data: {
                title: `Station météo ${this.station.name}`,
            },
            type: 'onNotice',
        };
        this.markers = [locationMarker, stationMarker];
    }

    /**
     * Export DJU action
     */
    exportDju() {
        if (
            !this.isExporting &&
            this.station &&
            this.datesSelected &&
            this.datesSelected.start &&
            this.datesSelected.end &&
            this.groupSelected
        ) {
            this.getExportFileAndSave(
                this.station,
                this.datesSelected.start,
                this.datesSelected.end,
                this.groupSelected
            );
        }
    }

    /**
     * Get and download requested DJU export file
     */
    getExportFileAndSave(station: MeteoStation, start: string, end: string, grouping: string) {
        this.isExporting = true;
        const route = '/api/export/excel/djus';
        const params = {
            station: station._id,
            start,
            end,
            group: grouping,
        };

        const name = `DJU_${station.name}_${start}_${end}_${this.translate._(grouping)}.xlsx`;
        this.exportService.getFile(route, params).subscribe(
            file => {
                saveAs(file, name);
                this.isExporting = false;
            },
            err => {
                this.isExporting = false;
                this.handleErrorSwal('export_error');
            }
        );
    }

    /**
     * Update date selected
     * @param {string} value - A string that represents a valid date (format YYYY-MM-DD), otherwise it is empty.
     * @param {"start"|"end"} date - selected date to set
     */
    onDateChange(value: string, date: 'start' | 'end') {
        if (value) {
            this.datesSelected[date] = value;
        }
    }

    /**
     * @returns {boolean} true if location, station and dates found and ready for display
     */
    get isLocationStationDatesFound(): boolean {
        return Boolean(this.location && this.station && this.datesAvailable);
    }

    /**
     * @return {boolean} true if date selected ready for use (= defined), false otherwise
     */
    get isDateSelectedDefined(): boolean {
        return Boolean(this.datesSelected && this.datesSelected.start && this.datesSelected.end);
    }

    /**
     * Get location address for display
     */
    get locationAddressDisplay(): string {
        if (!this.location) {
            return '';
        }
        const streetNumber = this.location.streetNumber ? `${this.location.streetNumber}, ` : '';
        const zipcode = this.location.zipcode ? this.location.zipcode : '';
        return `${streetNumber}${this.location.streetName}, ${zipcode} ${this.location.city}`;
    }

    /**
     * Get location img source
     */
    get locationImgSrc(): string {
        return 'assets/img/carto/geolocation_small.png';
    }

    /**
     * Get station img source
     */
    get stationImgSrc(): string {
        return 'assets/img/carto/geolocation_small_orange.png';
    }

    /**
     * Get date img source
     */
    get datesImgSrc(): string {
        return 'assets/img/icons/calendar.png';
    }

    /**
     * Format date to string at format YYYY-MM-DD
     * @param {Date} date - date to format
     * @returns {string} date formatted
     */
    private formatDate(date: Date): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }

    /**
     * Handle error display by opening an error/warning swal
     * @param {string} errorCode - error code
     */
    private handleErrorSwal(errorCode: string) {
        const mapping: { [key: string]: [string, string, SweetAlertIcon] } = {
            address_not_found: ['Adresse introuvable', 'Veuillez essayer une autre adresse', 'warning'],
            station_not_found: ['Station météo introuvable', 'Veuillez essayer une autre adresse', 'error'],
            djus_not_found: ['Aucun DJU trouvé pour la station météo', 'Veuillez essayer ultérieurement', 'error'],
            error_search_station: ['Impossible de trouver la station', 'Une erreur inconnue est survenue', 'error'],
            export_error: [
                'Toutes nos excuses',
                "Une erreur s'est produite lors de l'export de données. Merci de réessayer.",
                'error',
            ],
        };
        const defaultErr: [string, string, SweetAlertIcon] = [
            'Toutes nos excuses',
            "Une erreur inconnue s'est produite. Merci de réessayer ultérieurement.",
            'error',
        ];
        const item = mapping[errorCode] ? mapping[errorCode] : defaultErr;
        Swal.fire(item[0], item[1], item[2]);
    }
}
