// Angular
// https://angular.io/
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Angular Material
// https://material.angular.io/
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule, NativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// ngx-bootstrap4
// http://valor-software.com/ngx-bootstrap/index-bs4.html#/
import { MyDateRangePickerModule } from 'mydaterangepicker';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { ChartsModule, ThemeService } from 'ng2-charts';

import { ChartModule } from 'angular2-highcharts';
import { HighchartsStatic } from 'angular2-highcharts/dist/HighchartsService';
declare var require: any;
export function highchartsFactory() {
    const hc = require('highcharts');
    const dd = require('highcharts/modules/drilldown');
    const sk = require('highcharts/modules/sankey');
    const hm = require('highcharts/modules/heatmap');
    const hcm = require('highcharts/highcharts-more');
    const exp = require('highcharts/modules/exporting');
    const st = require('highcharts/modules/stock');
    const boost = require('highcharts/modules/boost');

    dd(hc);
    sk(hc);
    hm(hc);
    hcm(hc);
    exp(hc);
    st(hc);
    // Boost should be the last module included.
    boost(hc);
    return hc;
}

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PipesModule } from './pipes/pipes.module';

// UI Shared Components

// NG2 Charts
import { Ng2BarChartDemoComponent } from './components/ng2-charts/ng2-charts-bar.component';
import { Ng2DoughnutChartComponent } from './components/ng2-charts/ng2-charts-doughnut.component';
import { Ng2LineChartComponent } from './components/ng2-charts/ng2-charts-line.component';

// Charts
import { StatisticsComponent } from './components/analyse/statistics/statistics.component';
import { ChartBarToggleComponent } from './components/charts/chart-bar-toggle/chart-bar-toggle.component';
import { ChartBarComponent } from './components/charts/chart-bar/chart-bar.component';
import { ChartDoughnutToggleComponent } from './components/charts/chart-doughnut-toggle/chart-doughnut-toggle.component';
import { ChartDoughnutComponent } from './components/charts/chart-doughnut/chart-doughnut.component';
import { ChartHeatmapComponent } from './components/charts/chart-heatmap/chart-heatmap.component';
import { ChartLineToggleComponent } from './components/charts/chart-line-toggle/chart-line-toggle.component';
import { ChartLineComponent } from './components/charts/chart-line/chart-line.component';
import { GraphBottomComponent } from './components/charts/graph-bottom/graph-bottom.component';
import { HighchartDoughnutToggleComponent } from './components/charts/highchart-doughnut-toggle/highchart-doughnut-toggle.component';
import { HighchartDoughnutComponent } from './components/charts/highchart-doughnut/highchart-doughnut.component';
import { HighchartMultilevelPieToggleComponent } from './components/charts/highchart-multilevel-pie-toggle/highchart-multilevel-pie-toggle.component';
import { HighchartMultilevelPieComponent } from './components/charts/highchart-multilevel-pie/highchart-multilevel-pie.component';
import { HighchartSankeyEditableComponent } from './components/charts/highchart-sankey-editable/highchart-sankey-editable.component';
import { HighchartSankeyComponent } from './components/charts/highchart-sankey/highchart-sankey.component';
import { HighchartScatterPlotComponent } from './components/charts/highchart-scatter-plot/highchart-scatter-plot.component';

// Data Tiles
import { DataTile1Component } from './components/data-tiles/tile-1/data-tile-1.component';
import { DataTile2Component } from './components/data-tiles/tile-2/data-tile-2.component';
import { DataTile3Component } from './components/data-tiles/tile-3/data-tile-3.component';
import { DataTile4Component } from './components/data-tiles/tile-4/data-tile-4.component';
import { DataTile5Component } from './components/data-tiles/tile-5/data-tile-5.component';

// Site related components
import { SitesAddMaterialComponent } from './components/sites/add-material/add-material.component';
import { SiteBasicInformationFormComponent } from './components/sites/basic-information-form/basic-information-form.component';
import { SitesBlocAdressComponent } from './components/sites/bloc-adress/bloc-adress.component';
import { SiteDistributionKeyComponent } from './components/sites/distribution-key/distribution-key.component';
import { SitesGeneralInformationsComponent } from './components/sites/general-informations/general-informations.component';
import { SiteInformationsComponent } from './components/sites/informations/site-informations.component';
import { LegacyDataComponent } from './components/sites/legacy-data/legacy-data.component';
import { SitesListDragDropComponent } from './components/sites/list-drag-n-drop/list-drag-n-drop.component';
import { SitesPdlListComponent } from './components/sites/pdl-list/pdl-list.component';
import { SitePropertiesComponent } from './components/sites/properties/site-properties.component';
import { FileQuestionComponent } from './components/sites/questions/file-upload/file-upload.component';
import { GroupQuestionComponent } from './components/sites/questions/group-collapse/group-collapse.component';
import { InputTextMultipleComponent } from './components/sites/questions/input-text-multiple/input-text-multiple.component';
import { InputTextComponent } from './components/sites/questions/input-text/input-text.component';
import { ModalQuestionComponent } from './components/sites/questions/modal/modal.component';
import { QuestionComponent } from './components/sites/questions/question/question.component';
import { SelectChoicesBlocComponent } from './components/sites/questions/select-choices-bloc/select-choices-bloc.component';
import { SelectChoicesInputComponent } from './components/sites/questions/select-choices-input/select-choices-input.component';
import { SelectChoicesComponent } from './components/sites/questions/select-choices/select-choices.component';
import { SelectDropdownComponent } from './components/sites/questions/select-dropdown/select-dropdown.component';
import { SitesSelectPdlsComponent } from './components/sites/select-pdls/select-pdls.component';
import { SitesFluidsListComponent } from './components/sites/sites-pdls-list/sites-fluids-list.component';
import { SiteZoneComponent } from './components/sites/zone/site-zone.component';

// Pdl related components
import { PdlBlocContractComponent } from './components/pdl/bloc-contract/bloc-contract.component';
import { PDLCommunicatingIconComponent } from './components/pdl/pdl-data-site-top/pdl-communicating-icon/pdl-communicating-icon.component';
import { RRefContractualDataComponent } from './components/pdl/pdl-data-site-top/pdl-contractual-data/pdl-contractual-data.component';
import { PdlDataSiteTopComponent } from './components/pdl/pdl-data-site-top/pdl-data-site-top.component';
import { PdlDataTableSiteComponent } from './components/pdl/pdl-data-table-site/pdl-data-table-site.component';
import { PdlDataTableComponent } from './components/pdl/pdl-data-table/pdl-data-table.component';
import { PdlTableComponent } from './components/pdl/pdl-table/pdl-table.component';

// Upload related components
import { UploadFilesTileComponent } from './components/upload/upload-files-tile/upload-files-tile.component';
import { UploadFilesComponent } from './components/upload/upload-files/upload-files.component';
import { UploadListComponent } from './components/upload/upload-list/upload-list.component';
import { UploadReportComponent } from './components/upload/upload-report/upload-report.component';

// Common components
import { FooterComponent } from '../layout/footer/footer.component';
import { ComponentBlocComponent } from './components/common/component-bloc/component-bloc.component';
import { DateRangePickerComponent } from './components/common/date-range-picker/date-range-picker.component';
import { DropdownMultilevelComponent } from './components/common/dropdown-multilevel/dropdown-multilevel.component';
import { FilterComponent } from './components/common/filter/filter.component';
import { FullFilterComponent } from './components/common/full-filter/full-filter.component';
import { LoadingDataComponent } from './components/common/loading-data/loading-data.component';
import { MapComponent } from './components/common/map/map.component';
import { ModalComponent } from './components/common/modal/modal.component';
import { NoDataComponent } from './components/common/nodata/no-data.component';
import { HistoryPopoverComponent } from './components/common/popover/history/history-popover.component';
import { ProgressBarComponent } from './components/common/progress-bar/progress-bar.component';
import { SimpleAutocompleteComponent } from './components/common/simple-autocomplete/simple-autocomplete.component';
import { SimpleTableComponent } from './components/common/simple-table/simple-table.component';
import { ToggleButtonsComponent } from './components/common/toggle-buttons/toggle-buttons.component';
import { TreeViewSelectComponent } from './components/common/tree-view-select/tree-view-select.component';
import { UltraSimpleAutocompleteComponent } from './components/common/ultra-simple-autocomplete/ultra-simple-autocomplete.component';

// Other components
import { DataSearchListComponent } from './components/cartography/data-search-list/data-search-list.component';
import { LegendComponent } from './components/legends/legend.component';
import { GridKeyFiguresComponent } from './components/other/card-key-figures/grid-key-figures.component';
import { KeyFigureComponent } from './components/other/card-key-figures/key-figure.component';
import { TimelineComponent } from './components/other/timeline/timeline.component';
import { UserTileComponent } from './components/other/user-tile/user-tile.component';
import { PaginationBackComponent } from './components/pagination/back/pagination-back.component';
import { PaginationComponent } from './components/pagination/front/pagination-front.component';

// Regex components
import { NotFindTableComponent } from './components/regex/not-find-table/not-find-table.component';
import { PatternTableComponent } from './components/regex/pattern-table/pattern-table.component';
import { PatternsPropertiesTableComponent } from './components/regex/patterns-properties-table/patterns-properties-table.component';
import { RecapFilesComponent } from './components/regex/recap-files/recap-files.component';

// Filters related components
import { AutocompleteFilterComponent } from './components/filters/autocomplete-filter/autocomplete-filter.component';
import { CustomFilterComponent } from './components/filters/custom-filter/custom-filter.component';
import { FilterSitesRulesComponent } from './components/filters/custom-filter/filter-sites-rules/filter-sites-rules.component';
import { DataScopeFilterReadonlyComponent } from './components/filters/data-scope-filter-readonly/data-scope-filter-readonly.component';
import { DateFilterComponent } from './components/filters/date-filter/date-filter.component';
import { FollowupFilterComponent } from './components/filters/followup-filter/followup-filter.component';
import { MultipleAutocompleteFilterComponent } from './components/filters/multiple-autocomplete-filter/multiple-autocomplete-filter.component';
import { NumberFilterComponent } from './components/filters/number-filter/number-filter.component';
import { RoutingReferenceScopeFilterComponent } from './components/filters/scope-filter/routing-reference/routing-reference-scope-filter.component';
import { VehicleScopeFilterComponent } from './components/filters/scope-filter/vehicle/vehicle-scope-filter.component';
import { SelectFilterComponent } from './components/filters/select-filter/select-filter.component';
import { SimpleTagsComponent } from './components/filters/simple-tags/simple-tags.component';
import { SwitchBlocFilterComponent } from './components/filters/switch-bloc-filter/switch-bloc-filter.component';
import { SwitchFilterComponent } from './components/filters/switch-filter/switch-filter.component';
import { TextFilterComponent } from './components/filters/text-filter/text-filter.component';
import { PatternFixTableComponent } from './components/pattern-fix/pattern-fix-table/pattern-fix-table.component';
import { UploadFixTableComponent } from './components/pattern-fix/upload-fix-table/upload-fix-table.component';

// Admin Company Components
import { AdminSelectionComponent } from 'app/shared/components/admin/admin-selection/admin-selection.component';
import { CompanyComponent } from './components/admin/company/company.component';
import { CompanyContactsComponent } from './components/admin/company/contacts/company-contacts.component';
import { CompanyLoadingCurvesComponent } from './components/admin/company/loading-curves/company-loading-curves.component';
import { CompanyProfileComponent } from './components/admin/company/profile/company-profile.component';
import { RrefKeyFiguresCardComponent } from './components/admin/company/rref-merge/rref-keyfigures-card/rref-keyfigures-card.component';
import { RrefListComponent } from './components/admin/company/rref-merge/rref-list/rref-list.component';
import { RrefMergePreviewComponent } from './components/admin/company/rref-merge/rref-merge-preview/rref-merge-preview.component';
import { RrefMergeComponent } from './components/admin/company/rref-merge/rref-merge.component';
import { RrefRenameComponent } from './components/admin/company/rref-merge/rref-rename/rref-rename.component';
import { RrefSelectedComponent } from './components/admin/company/rref-merge/rref-selected/rref-selected.component';
import { CompanyRoutingReferencesComponent } from './components/admin/company/sites-entities-attribution/routing-references/company-routing-references.component';
import { CompanyVehiclesComponent } from './components/admin/company/sites-entities-attribution/vehicles/company-vehicles.component';
import { CompanySitesComponent } from './components/admin/company/sites/company-sites.components';
import { CompanyUploadsComponent } from './components/admin/company/uploads/company-uploads.component';
import { CompanyUsersComponent } from './components/admin/company/users/company-users.component';
import { IdentificationPatternSearchComponent } from './components/admin/identification-pattern-search/identification-pattern-search.component';
import { IdentificationPatternComponent } from './components/admin/identification-pattern/identification-pattern.component';

import { LoadCurveAssignationEnedisComponent } from './components/admin/company/loading-curves/providers/enedis/assignation-enedis.component';
import { EnedisRRefsSelectionComponent } from './components/admin/company/loading-curves/providers/enedis/rrefs-selection/rrefs-selection.component';
import { EnedisWarrantFormComponent } from './components/admin/company/loading-curves/providers/enedis/warrant-form/warrant-form.component';
import { LoadCurveAssignationGAComponent } from './components/admin/company/loading-curves/providers/greenanalyzer/assignation-greenanalyzer.component';

// Admin User Components
import { AdminUsersListComponent } from './components/admin/admin-users-list/admin-users-list.component';
import { UserEngineerComponent } from './components/admin/user/engineer/user-engineer.component';
import { UserProfileComponent } from './components/admin/user/profile/user-profile.component';
import { UserSitesComponent } from './components/admin/user/sites/user-sites.component';
import { UserComponent } from './components/admin/user/user.component';

// Modals related components
import { InputModalComponent } from './components/modals/input-modal/input-modal.component';
import { YesNoModalComponent } from './components/modals/yes-no-modal/yes-no-modal.component';

// Contracts related components
import { CapacityFactorComponent } from './components/contracts/contract-edition/capacity-factor/capacity-factor.component';
import { ContractEditionComponent } from './components/contracts/contract-edition/contract-edition.component';
import { ContractDatesPeriodicityComponent } from './components/contracts/contract-edition/dates-periodicity/dates-periodicity.component';
import { PdlPricingsComponent } from './components/contracts/contract-edition/pdl-pricings/pdl-pricings.component';
import { PricingsCommonComponent } from './components/contracts/contract-edition/pricings-common/pricings-common.component';
import { ContractsTableComponent } from './components/contracts/contracts-table/contracts-table.component';

// Campains related components
import { CampaignCreateComponent } from './components/campaigns/campaign-create/campaign-create.component';
import { CampaignGoalRecapComponent } from './components/campaigns/campaign-goal-recap/campaign-goal-recap.component';
import { CampaignOverviewComponent } from './components/campaigns/campaign-overview/campaign-overview.component';
import { CampaignVisualizationComponent } from './components/campaigns/campaign-visualization/campaign-visualization.component';
import { CampaignsNavComponent } from './components/campaigns/campaigns-nav/campaigns-nav.component';

// Bills related components
import { BillsControlTabsComponent } from './components/bills/bills-control/bills-control-tabs/bills-control-tabs.component';
import { BillsCompletenessRoutingReferenceComponent } from './components/bills/bills-control/completeness/bills-completeness-tab/bills-completeness-tab.component';
import { BillsDatepickerComponent } from './components/bills/bills-control/completeness/bills-datepicker/bills-datepicker.component';
import { BillsTableCompletenessComponent } from './components/bills/bills-control/completeness/bills-table-completeness/bills-table-completeness.component';
import { BillsTimelineComponent } from './components/bills/bills-control/completeness/bills-timeline/bills-timeline.component';
import { CompletenessDataTabComponent } from './components/bills/bills-control/completeness/completeness-data-tab/completeness-data-tab.component';
import { BillVerificationsComponent } from './components/bills/bills-control/control/bill-verifications/bill-verifications.component';
import { BillsControlTabComponent } from './components/bills/bills-control/control/bills-control-tab/bills-control-tab.component';
import { BillsTableControlComponent } from './components/bills/bills-control/control/bills-table-control/bills-table-control.component';
import { BillsControlRecapBannerComponent } from './components/bills/bills-control/control/recap-banner/bills-control-recap-banner.component';
import { BillVerificationPillComponent } from './components/bills/bills-management/bill-verification-pill/bill-verification-pill.component';
import { BillVerificationPopoverComponent } from './components/bills/bills-management/bill-verification-popover/bill-verification-popover.component';
import { BillsListComponent } from './components/bills/bills-management/bills-list/bills-list.component';
import { BillsContractsFilterComponent } from './components/filters/bills-contracts-filter/bills-contracts-filter.component';

// Alerts related components
import { AlertsEditionComponent } from './components/alerts/edition/alerts-edition.component';
import { AlertsConditionBuilderComponent } from './components/alerts/edition/condition-builder/alerts-condition-builder.component';
import { AlertsManagementFilterComponent } from './components/alerts/management-filter/alerts-mgmt-filter.component';
import { AlertsManagementTableComponent } from './components/alerts/management-table/alerts-mgmt-table.component';

// Vehicle related components
import { VehicleEditComponent } from './components/vehicles/edit/vehicle-edit.component';

// Cros data related components
import { ColumnSelectorComponent } from './components/analyse/axis-selection/axis-selection.component';

// Directives
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { OffClickDirective } from './directives/off-click.directive';

// Multi-select levels
import { NgSelectModule } from '@ng-select/ng-select';

// Tree View Select
import { TreeviewModule } from 'ngx-treeview';
import { ExportButtonComponent } from './components/export-button/export-button.component';

// Operating monitoring components
import { MonitoringCompaniesSelectComponent } from './components/operating-monitoring/monitoring-companies-select/monitoring-companies-select.component';
import { MonitoringEditionContractComponent } from './components/operating-monitoring/monitoring-edition/monitoring-edition-contract/monitoring-edition-contract.component';
import { MonitoringEditionScopeComponent } from './components/operating-monitoring/monitoring-edition/monitoring-edition-scope/monitoring-edition-scope.component';
import { MonitoringEditionSourceComponent } from './components/operating-monitoring/monitoring-edition/monitoring-edition-source/monitoring-edition-source.component';
import { MonitoringEditionComponent } from './components/operating-monitoring/monitoring-edition/monitoring-edition.component';
import { MonitoringGraphsComponent } from './components/operating-monitoring/monitoring-graphs/monitoring-graphs.component';
import { MonitoringHeaderComponent } from './components/operating-monitoring/monitoring-header/monitoring-header.component';
import { MonitoringKeyfiguresComponent } from './components/operating-monitoring/monitoring-keyfigures/monitoring-keyfigures.component';
import { MonitoringListComponent } from './components/operating-monitoring/monitoring-list/monitoring-list.component';
import { MonitoringAddMeterComponent } from './components/operating-monitoring/monitoring-readings/monitoring-add-meter/monitoring-add-meter.component';
import { MonitoringAddReadingComponent } from './components/operating-monitoring/monitoring-readings/monitoring-add-reading/monitoring-add-reading.component';
import { MonitoringReadingsComponent } from './components/operating-monitoring/monitoring-readings/monitoring-readings.component';
import { MonitoringResumeComponent } from './components/operating-monitoring/monitoring-resume/monitoring-resume.component';

// List panel directive
import { ListPanelDirective } from './directives/list-panel.directive';

// Load curve display components
import { MessageDisplayComponent } from './components/load-curve/message-display/message-display.component';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        NativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        // NguUtilityModule,
        ChartsModule,
        PipesModule,
        BsDropdownModule.forRoot(),
        AlertModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        SweetAlert2Module.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDAPQEDx82drT0_5_83lFt1uIuae92a1jA',
        }),
        NgxDatatableModule,
        ChartModule,
        MyDateRangePickerModule,
        TooltipModule.forRoot(),
        NgSelectModule,
        TreeviewModule.forRoot(),
    ],
    declarations: [
        FooterComponent,
        DataTile1Component,
        DataTile2Component,
        DataTile3Component,
        DataTile4Component,
        DataTile5Component,
        Ng2BarChartDemoComponent,
        GridKeyFiguresComponent,
        KeyFigureComponent,
        ChartBarComponent,
        ChartLineComponent,
        ChartBarToggleComponent,
        ChartLineToggleComponent,
        MapComponent,
        TimelineComponent,
        DataSearchListComponent,
        ComponentBlocComponent,
        ChartDoughnutComponent,
        Ng2DoughnutChartComponent,
        UploadFilesComponent,
        UploadListComponent,
        FullFilterComponent,
        ProgressBarComponent,
        FilterComponent,
        PdlDataTableComponent,
        SiteInformationsComponent,
        PdlDataTableSiteComponent,
        Ng2LineChartComponent,
        PdlTableComponent,
        PDLCommunicatingIconComponent,
        RRefContractualDataComponent,
        Ng2LineChartComponent,
        HighchartMultilevelPieComponent,
        NoDataComponent,
        LoadingDataComponent,
        DropdownMultilevelComponent,
        HighchartMultilevelPieToggleComponent,
        SimpleTableComponent,
        ChartDoughnutToggleComponent,
        PdlDataSiteTopComponent,
        UploadReportComponent,
        UploadFilesTileComponent,
        SitePropertiesComponent,
        SiteZoneComponent,
        SitesAddMaterialComponent,
        SitesSelectPdlsComponent,
        SitesPdlListComponent,
        SitesFluidsListComponent,
        SitesGeneralInformationsComponent,
        SiteDistributionKeyComponent,
        SitesListDragDropComponent,
        SiteBasicInformationFormComponent,
        SitesBlocAdressComponent,
        PdlBlocContractComponent,
        NotFindTableComponent,
        PatternTableComponent,
        TextFilterComponent,
        NumberFilterComponent,
        SimpleTagsComponent,
        MultipleAutocompleteFilterComponent,
        AutocompleteFilterComponent,
        DateFilterComponent,
        SwitchFilterComponent,
        SelectFilterComponent,
        CustomFilterComponent,
        FilterSitesRulesComponent,
        HighchartDoughnutComponent,
        HighchartDoughnutToggleComponent,
        HighchartSankeyComponent,
        HighchartSankeyEditableComponent,
        HighchartScatterPlotComponent,
        ChartHeatmapComponent,
        BillsTableCompletenessComponent,
        GraphBottomComponent,
        PatternFixTableComponent,
        UploadFixTableComponent,
        InputModalComponent,
        YesNoModalComponent,
        SimpleAutocompleteComponent,
        UltraSimpleAutocompleteComponent,
        PatternsPropertiesTableComponent,
        RecapFilesComponent,
        CompanyComponent,
        IdentificationPatternComponent,
        IdentificationPatternSearchComponent,
        CompanyProfileComponent,
        CompanyContactsComponent,
        CompanyUsersComponent,
        CompanyUploadsComponent,
        CompanySitesComponent,
        CompanyRoutingReferencesComponent,
        CompanyVehiclesComponent,
        CompanyLoadingCurvesComponent,
        LoadCurveAssignationGAComponent,
        LoadCurveAssignationEnedisComponent,
        EnedisWarrantFormComponent,
        EnedisRRefsSelectionComponent,
        AdminSelectionComponent,
        AdminUsersListComponent,
        PaginationComponent,
        PaginationBackComponent,
        UserComponent,
        UserProfileComponent,
        UserEngineerComponent,
        UserSitesComponent,
        FollowupFilterComponent,
        LegacyDataComponent,
        SelectChoicesComponent,
        QuestionComponent,
        SelectChoicesBlocComponent,
        ToggleButtonsComponent,
        SwitchBlocFilterComponent,
        InputTextComponent,
        SelectDropdownComponent,
        InputTextMultipleComponent,
        SelectChoicesInputComponent,
        ModalQuestionComponent,
        GroupQuestionComponent,
        FileQuestionComponent,
        ContractsTableComponent,
        HistoryPopoverComponent,
        ModalComponent,
        UserTileComponent,
        CampaignsNavComponent,
        RoutingReferenceScopeFilterComponent,
        VehicleScopeFilterComponent,
        CampaignCreateComponent,
        CampaignVisualizationComponent,
        CampaignOverviewComponent,
        CampaignGoalRecapComponent,
        DataScopeFilterReadonlyComponent,
        BillsListComponent,
        BillsContractsFilterComponent,
        BillVerificationPillComponent,
        BillVerificationPopoverComponent,
        BillsCompletenessRoutingReferenceComponent,
        BillsDatepickerComponent,
        BillsTimelineComponent,
        BillsControlTabComponent,
        BillsControlRecapBannerComponent,
        BillsTableControlComponent,
        AlertsManagementFilterComponent,
        AlertsManagementTableComponent,
        AlertsEditionComponent,
        AlertsConditionBuilderComponent,
        VehicleEditComponent,
        StatisticsComponent,
        ContractEditionComponent,
        ContractDatesPeriodicityComponent,
        PricingsCommonComponent,
        PdlPricingsComponent,
        CapacityFactorComponent,
        ColumnSelectorComponent,
        BillVerificationsComponent,
        BillsControlTabsComponent,
        OffClickDirective,
        LegendComponent,
        FeatureToggleDirective,
        ListPanelDirective,
        CompletenessDataTabComponent,
        DateRangePickerComponent,
        TreeViewSelectComponent,
        RrefMergeComponent,
        RrefListComponent,
        RrefKeyFiguresCardComponent,
        RrefSelectedComponent,
        RrefMergePreviewComponent,
        RrefRenameComponent,
        ExportButtonComponent,
        MonitoringEditionComponent,
        MonitoringAddMeterComponent,
        MonitoringAddReadingComponent,
        MonitoringGraphsComponent,
        MonitoringHeaderComponent,
        MonitoringListComponent,
        MonitoringResumeComponent,
        MonitoringCompaniesSelectComponent,
        MonitoringEditionScopeComponent,
        MonitoringEditionContractComponent,
        MonitoringEditionSourceComponent,
        MonitoringReadingsComponent,
        MonitoringKeyfiguresComponent,
        MessageDisplayComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        NativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        // NguUtilityModule,
        FooterComponent,
        ReactiveFormsModule,
        TabsModule,
        BsDropdownModule,
        AlertModule,
        ModalModule,
        PopoverModule,
        ChartsModule,
        AgmCoreModule,
        SweetAlert2Module,
        DataTile1Component,
        DataTile2Component,
        DataTile3Component,
        DataTile4Component,
        DataTile5Component,
        Ng2BarChartDemoComponent,
        GridKeyFiguresComponent,
        KeyFigureComponent,
        ChartLineComponent,
        ChartBarComponent,
        ChartBarToggleComponent,
        ChartLineToggleComponent,
        MapComponent,
        TimelineComponent,
        DataSearchListComponent,
        ComponentBlocComponent,
        PipesModule,
        ChartDoughnutComponent,
        ChartLineComponent,
        FilterComponent,
        Ng2DoughnutChartComponent,
        FullFilterComponent,
        UploadFilesComponent,
        ProgressBarComponent,
        PdlDataTableComponent,
        SiteInformationsComponent,
        PdlDataTableSiteComponent,
        UploadListComponent,
        NgxDatatableModule,
        Ng2LineChartComponent,
        PdlTableComponent,
        Ng2LineChartComponent,
        HighchartMultilevelPieComponent,
        ChartModule,
        NoDataComponent,
        LoadingDataComponent,
        DropdownMultilevelComponent,
        HighchartMultilevelPieToggleComponent,
        SimpleTableComponent,
        ChartDoughnutToggleComponent,
        PdlDataSiteTopComponent,
        UploadReportComponent,
        UploadFilesTileComponent,
        SitePropertiesComponent,
        SiteZoneComponent,
        SitesAddMaterialComponent,
        SitesSelectPdlsComponent,
        SitesPdlListComponent,
        SitesFluidsListComponent,
        SitesListDragDropComponent,
        SiteBasicInformationFormComponent,
        SitesGeneralInformationsComponent,
        SiteDistributionKeyComponent,
        SitesBlocAdressComponent,
        PdlBlocContractComponent,
        NotFindTableComponent,
        PatternTableComponent,
        TextFilterComponent,
        NumberFilterComponent,
        SimpleTagsComponent,
        MultipleAutocompleteFilterComponent,
        AutocompleteFilterComponent,
        DateFilterComponent,
        SwitchFilterComponent,
        SelectFilterComponent,
        CustomFilterComponent,
        FilterSitesRulesComponent,
        HighchartDoughnutComponent,
        HighchartDoughnutToggleComponent,
        HighchartSankeyComponent,
        HighchartSankeyEditableComponent,
        HighchartScatterPlotComponent,
        ChartHeatmapComponent,
        BillsTableCompletenessComponent,
        GraphBottomComponent,
        PatternFixTableComponent,
        UploadFixTableComponent,
        InputModalComponent,
        YesNoModalComponent,
        SimpleAutocompleteComponent,
        UltraSimpleAutocompleteComponent,
        PatternsPropertiesTableComponent,
        RecapFilesComponent,
        CompanyComponent,
        IdentificationPatternComponent,
        IdentificationPatternSearchComponent,
        CompanyProfileComponent,
        CompanyContactsComponent,
        CompanyUsersComponent,
        CompanyUploadsComponent,
        CompanySitesComponent,
        CompanyRoutingReferencesComponent,
        CompanyVehiclesComponent,
        CompanyLoadingCurvesComponent,
        LoadCurveAssignationGAComponent,
        LoadCurveAssignationEnedisComponent,
        EnedisWarrantFormComponent,
        EnedisRRefsSelectionComponent,
        AdminSelectionComponent,
        AdminUsersListComponent,
        PaginationComponent,
        PaginationBackComponent,
        UserComponent,
        UserProfileComponent,
        UserSitesComponent,
        UserEngineerComponent,
        FollowupFilterComponent,
        LegacyDataComponent,
        SelectChoicesComponent,
        QuestionComponent,
        SelectChoicesBlocComponent,
        ToggleButtonsComponent,
        TreeViewSelectComponent,
        SwitchBlocFilterComponent,
        InputTextComponent,
        SelectDropdownComponent,
        InputTextMultipleComponent,
        SelectChoicesInputComponent,
        ModalQuestionComponent,
        GroupQuestionComponent,
        FileQuestionComponent,
        ContractsTableComponent,
        HistoryPopoverComponent,
        ModalComponent,
        UserTileComponent,
        BsDatepickerModule,
        CampaignsNavComponent,
        RoutingReferenceScopeFilterComponent,
        VehicleScopeFilterComponent,
        CampaignCreateComponent,
        CampaignVisualizationComponent,
        CampaignOverviewComponent,
        CampaignGoalRecapComponent,
        DataScopeFilterReadonlyComponent,
        BillsListComponent,
        BillsContractsFilterComponent,
        BillVerificationPillComponent,
        BillVerificationPopoverComponent,
        BillsCompletenessRoutingReferenceComponent,
        BillsTimelineComponent,
        BillsDatepickerComponent,
        BillsControlTabComponent,
        BillsControlRecapBannerComponent,
        BillsTableControlComponent,
        AlertsManagementFilterComponent,
        AlertsManagementTableComponent,
        AlertsEditionComponent,
        AlertsConditionBuilderComponent,
        VehicleEditComponent,
        StatisticsComponent,
        ContractEditionComponent,
        ContractDatesPeriodicityComponent,
        MyDateRangePickerModule,
        PricingsCommonComponent,
        PdlPricingsComponent,
        ColumnSelectorComponent,
        BillVerificationsComponent,
        BillsControlTabsComponent,
        LegendComponent,
        NgSelectModule,
        DateRangePickerComponent,
        RrefMergeComponent,
        RrefListComponent,
        RrefKeyFiguresCardComponent,
        RrefSelectedComponent,
        RrefMergePreviewComponent,
        RrefRenameComponent,
        MonitoringEditionComponent,
        MonitoringAddMeterComponent,
        MonitoringAddReadingComponent,
        MonitoringGraphsComponent,
        MonitoringHeaderComponent,
        MonitoringListComponent,
        MonitoringResumeComponent,
        MonitoringCompaniesSelectComponent,
        MonitoringEditionScopeComponent,
        MonitoringEditionContractComponent,
        MonitoringEditionSourceComponent,
        MonitoringReadingsComponent,
        MonitoringKeyfiguresComponent,
        MessageDisplayComponent,
    ],
    providers: [
        {
            provide: HighchartsStatic,
            useFactory: highchartsFactory,
        },
        ThemeService,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
        };
    }
}
