import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    KeyValueDiffer,
    KeyValueDiffers,
    OnInit,
    Output,
} from '@angular/core';
import { Company } from 'app/shared/models/company.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { SitePopulated } from 'app/shared/models/site.interface';
import { Vehicle } from 'app/shared/models/vehicle.interface';
import { ColorService } from 'app/shared/services/color/color.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { VehiclesService } from 'app/shared/services/vehicles/vehicles.service';

type Entity = RoutingReference | Vehicle;

@Component({
    selector: 'ga-sites-list-drag-n-drop',
    templateUrl: './list-drag-n-drop.component.html',
    styleUrls: ['./list-drag-n-drop.component.scss'],
    providers: [],
})
export class SitesListDragDropComponent implements OnInit, DoCheck {
    /**
     * Company of sites
     */
    @Input() company: Company;

    /**
     * Fluids available
     */
    @Input() fluids: Array<{
        name: string;
        value: string;
    }> = [];

    /**
     * All sites from company, populated with routing references and vehicles
     */
    @Input() sites: SitePopulated[];

    /**
     * List's entity type to display and assign : routing references or vehicles
     */
    @Input() entityType: 'vehicles' | 'routingReferences';

    /**
     * Event emitted when an entity have been associated to a site
     */
    @Output() entityAssigned: EventEmitter<{
        entity: Entity;
        site: SitePopulated;
        isNew: boolean;
    }> = new EventEmitter(true);

    /**
     * Event emitted when a site is selected
     */
    @Output() siteSelected: EventEmitter<SitePopulated> = new EventEmitter(true);

    /**
     * List of sites to be displayed (after filtering and limit applied)
     */
    sitesToDisplay: SitePopulated[] = [];

    /**
     * Site hovered while dragging
     */
    dragOverSite: string = null;

    /**
     * Search value
     */
    search = '';

    /**
     * Site selected
     */
    selectedSite: any;

    // Sites's entities informations to display (colors, count).
    // Each site _id is a key
    siteEntityDisplayInfo: {
        [siteId: string]: {
            [fluid: string]: {
                color: string;
                count: number;
            };
        };
    } = {};

    // Limits for the display of sites
    limits = {
        sites: 10,
    };

    /**
     * Differs to monitor changes in sites and perform action when changed.
     * _sitesObjDiffer is to detect changes inside sites (ex. changes on name, entities, ...)
     * _sitesDiffer is to detech changes on the site's array (add or remove one)
     */
    private _sitesObjDiffer: { [id: string]: KeyValueDiffer<string, any> };
    private _sitesDiffer: IterableDiffer<SitePopulated>;

    constructor(
        private routingReferencesService: RoutingReferencesService,
        private sitesService: SitesService,
        private vehiclesService: VehiclesService,
        private colorService: ColorService,
        private fluidService: EnergyService,
        private keyValueDiffers: KeyValueDiffers,
        private iterableDiffers: IterableDiffers
    ) {}

    ngOnInit() {
        this._sitesObjDiffer = {};
        this.sites.forEach(site => {
            this.createSiteDiffer(site);
        });
        this._sitesDiffer = this.iterableDiffers.find(this.sites).create(null);
    }

    /**
     * Create a site differ entry in sites differs
     */
    private createSiteDiffer(site: SitePopulated) {
        this._sitesObjDiffer[site._id] = this.keyValueDiffers.find(site).create();
    }

    /**
     * Lifecycle hook that invokes a custom change-detection function for a directive,
     * in addition to the check performed by the default change-detector.
     *
     * Here, we check if sites changed and if sites list changed
     */
    ngDoCheck() {
        let hasChanged = false;
        this.sites.forEach(site => {
            const objDiffer = this._sitesObjDiffer[site._id];
            if (objDiffer) {
                const objChanges = objDiffer.diff(site);
                if (objChanges) {
                    hasChanged = true;
                    this.computeSiteEntityInfo(site);
                }
            }
        });
        const changes = this._sitesDiffer.diff(this.sites);
        if (changes) {
            hasChanged = true;
        }
        if (hasChanged) {
            this.filterSites(true);
        }
    }

    /*****************
     * COMPUTE SITE & ENTITY DISPLAY INFO
     *****************/

    /**
     * Get the rgb color of a fluid. Return the color if the site has the fluid, default otherwise.
     * @param {string} fluid to look for
     * @param {number} count - number of entity of the fluid
     * @returns {string} color at format 'rgb(r,g,b)' or null if element not found
     */
    private getFluidColor(fluid: string, count: number): string {
        const hasFluid = Boolean(count && count > 0);
        if (hasFluid) {
            return this.colorService.getSecondRgbColor(fluid);
        }
        return null;
    }
    /**
     * Get the number of entities of a site for a given fluid.
     * @param {string} fluid to look for
     * @param {SitePopulated} site to get the count of pdls for the fluid
     * @returns {number} number of PDLs for the given site and fluid
     */
    private getSiteEntityCount(fluid: string, site: SitePopulated): number {
        if (this.fluidService.getAllVehicleFluids().includes(fluid)) {
            return site && site.vehicles ? site.vehicles.length : 0;
        }
        return this.sitesService.countRoutingReferencesOfFluid(site, fluid);
    }

    /**
     * Compute the entities information for a given site.
     * Example : for routing references, will compute number of each fluid routing references
     * As there is lot of sites displayed, it's calculated when user takes action
     * and not on the angular cycles
     * Objects of type: {
     *   color: string,
     *   count: number
     * }
     * @param {SitePopulated} site - site to compute the information of
     */
    private computeSiteEntityInfo(site: SitePopulated) {
        this.siteEntityDisplayInfo[site._id] = {};
        this.fluids.forEach(fluid => {
            const count = this.getSiteEntityCount(fluid.value, site);
            this.siteEntityDisplayInfo[site._id][fluid.value] = {
                color: this.getFluidColor(fluid.value, count),
                count,
            };
        });
    }

    /**
     * Filter site per value
     * @param {string} value - search criteria
     */
    searchSite(value: string) {
        this.search = value;
        this.filterSites();
    }

    /**
     * Apply all filters to the sites list
     * @param {boolean} isReset - reset the sites list (for example in research true, display more false)
     */
    private filterSites(isReset: boolean = true) {
        // Reset the sites list
        if (isReset) {
            this.sitesToDisplay = [];
        }
        // Split the search in multiple words to check each one instead of all of them
        const values = this.search.toLocaleLowerCase().split(' ');
        // Properties of the sites to check in research
        const propertiesToCheck = ['complement', '_id', 'streetName', 'city', 'zipcode'];
        // Function to access embed properties
        const getProp = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);
        // Add the right number of sites to the display list
        // Each one is the next one matching filters
        for (let i = this.sitesToDisplay.length; i < this.limits.sites; i++) {
            const site = this.sites.find((x: SitePopulated) => {
                // Check if not already in the list
                if (this.sitesToDisplay.some(y => x._id === y._id)) {
                    return false;
                }

                // Search in associated routingreferences
                const hasRoutingReferenceMatch =
                    x.routingReferences &&
                    x.routingReferences.some(
                        pdl => this.checkMatch(pdl._id, values) || this.checkMatch(pdl.reference, values)
                    );
                if (hasRoutingReferenceMatch) {
                    return true;
                }

                // Search in associated vehicles
                const hasVehicleMatch =
                    x.vehicles &&
                    x.vehicles.some(
                        v =>
                            this.checkMatch(v._id, values) ||
                            this.checkMatch(v.name, values) ||
                            this.checkMatch(v.registrationNumber, values)
                    );
                if (hasVehicleMatch) {
                    return true;
                }

                // Check from search input
                return propertiesToCheck.some(property => {
                    const val = getProp(x, property);
                    return val && this.checkMatch(val, values);
                });
            });
            if (site) {
                this.sitesToDisplay.push(site);
            }
        }
    }

    /**
     * Check is a list of values matches a string
     * @param {string} property to check check the values from
     * @param {string[]} values list of values to test
     * @returns {boolean} true if all values matches, false otherwise
     */
    private checkMatch(property: string = '', values: string[] = []): boolean {
        return values.every(value => {
            return property.toLocaleLowerCase().includes(value);
        });
    }

    /**
     * Get site bloc class.
     * @param {SitePopulated} site - site to get class from
     * @returns {string} 'drag-over' when hover while dragging, 'selected-site' when selected, empty otherwise
     */
    getSiteClass(site: SitePopulated): string {
        if (this.dragOverSite === site._id) {
            return 'drag-over';
        }
        if (this.selectedSite && this.selectedSite._id === site._id) {
            return 'selected-site';
        }
        return '';
    }

    /**
     * Set selected site if not the same site already selected
     * @param {SitePopulated} site - site selected
     */
    selectSite(site: SitePopulated) {
        if (!this.selectedSite || (this.selectedSite && this.selectedSite._id !== site._id)) {
            this.selectedSite = site;
            this.siteSelected.emit(site);
        }
    }

    /**
     * Display more site in the list.
     * @param {number} number of sites to display more. Default : 10
     */
    displayMoreSites(number: number = 10) {
        this.limits.sites += number;
        this.filterSites(false);
    }

    /**
     * DRAG N DROP MANAGEMENT
     */
    /**
     * On drop of an entity on a site, associate the entity to the existing site or create a site if new one
     * @param {DragEvent} event
     * @param {SitePopulated} site - id of site dropped
     */
    async onDrop(event: DragEvent, site: SitePopulated) {
        event.preventDefault();
        const dataString = event.dataTransfer.getData('text/plain');
        const entity = JSON.parse(dataString);

        switch (this.entityType) {
            case 'routingReferences': {
                await this.associateRoutingReference(entity, site);
                break;
            }
            case 'vehicles': {
                await this.associateVehicle(entity, site);
                break;
            }
            default:
                break;
        }

        this.dragOverSite = null;
    }

    private async associateRoutingReference(entity: RoutingReference, site: SitePopulated) {
        // If the area dropped is an existing site
        if (site) {
            // Set the selected site
            this.selectSite(site);

            // Associate the pdl to site, if not already in
            const siteHasRoutingReference = site.routingReferences.some(x => Boolean(x._id === entity._id));
            if (!siteHasRoutingReference) {
                try {
                    await this.routingReferencesService.associateRoutingReferenceToSite(entity._id, site._id);
                    this.entityAssigned.emit({ entity, site, isNew: false });
                } catch (e) {}
            }
        } else {
            // If the area dropped to is the area to create a site, create a new site
            // Then associate the routing reference to the site.
            // Create site from routing reference in back
            try {
                const newSite = await this.routingReferencesService.createSiteFromRoutingReference(
                    entity._id,
                    this.company._id
                );
                if (newSite) {
                    newSite.routingReferences = [entity];
                    this.createSiteDiffer(newSite);
                    this.entityAssigned.emit({ entity, site: newSite, isNew: true });
                    this.selectSite(newSite);
                    this.limits.sites++;
                }
            } catch (e) {}
        }
    }

    private async associateVehicle(entity: Vehicle, site: SitePopulated) {
        // If the area dropped is an existing site
        if (site) {
            // Set the selected site
            this.selectSite(site);

            // Associate the vehicle to site, if not already in
            const siteHasVehicle = site.vehicles.some(x => Boolean(x._id === entity._id));
            if (!siteHasVehicle) {
                try {
                    await this.vehiclesService.linkVehicleToSite(entity._id, site._id);
                    this.entityAssigned.emit({ entity, site, isNew: false });
                } catch (e) {}
            }
        } else {
            // If the area dropped to is the area to create a site, create a new site
            // Then associate the vehicle to the site.
            // Create site from vehicle in back
            try {
                const newSite = await this.vehiclesService.createSiteFromVehicle(entity._id);
                if (newSite) {
                    newSite.vehicles = [entity];
                    this.createSiteDiffer(newSite);
                    this.entityAssigned.emit({ entity, site: newSite, isNew: true });
                    this.selectSite(newSite);
                    this.limits.sites++;
                }
            } catch (e) {}
        }
    }

    /**
     * Set the site when drag enters DOM element hover.
     * If it hovers the site creation zone, instead of setting the _id, set 'new'.
     * @param {DragEvent} event
     * @param {SitePopulated} site object of the site dragged over
     */
    onDragEnter(event: DragEvent, site: SitePopulated) {
        event.preventDefault();
        const isNewSite = !Boolean(site);

        this.dragOverSite = isNewSite ? 'new' : site._id;
    }

    /**
     * Unset the site when drag leaves DOM element hover.
     * @param {DragEvent} event
     * @param {SitePopulated} site - site left. Unset only if the site is the one stored.
     */
    onDrageLeave(event: DragEvent, site: SitePopulated) {
        event.preventDefault();
        const isNewSite = !Boolean(site);

        const id = isNewSite ? 'new' : site._id;

        if (this.dragOverSite === id) {
            this.dragOverSite = null;
        }
    }

    /**
     * DO NOT REMOVE. ENABLE THE DROP.
     * @param event
     */
    onDragOver(event: DragEvent) {
        event.preventDefault();
    }
}
