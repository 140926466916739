import { Injectable } from '@angular/core';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

import { SitePopulated, SitePopulatedContracts } from 'app/shared/models/site.interface';

import * as moment from 'moment';
@Injectable()
export class SitesListService extends PageService {
    constructor(
        public apiService: ApiService,
        private sessionService: SessionService,
        private sitesService: SitesService
    ) {
        super(apiService);
    }

    /**
     * Get sites matching the filters.
     * Each site is populated with its routing references vehicles.
     * Routing references are populate with their contracts.
     * Vehicles are populated with their fuel cards.
     * @param {{[key: string]: string}} filters
     * @returns {Promise<{SitePopulated[]>}
     */
    async getSitesPopulatedFiltered(filters: { [key: string]: string }): Promise<SitePopulated[]> {
        try {
            const sites = await this.sitesService.getSites(filters);

            return sites.data;
        } catch (e) {
            throw e;
        }
    }

    /**
     * Get site populated with its routing references and vehicles.
     * Routing references and vehicles are populated with their contracts.
     * Inside routing references, contracts are populated with only their own routing references. Each routing ref contains its dates coming from bills (routing ref/contract).
     * Inside vehicles, contracts are populated with their own fuel cards. Each fuel card contains its dates coming from bills (vehicle/contract/fuelcard).
     * @param {string} siteId
     * @returns {Promise<SitePopulatedContracts>} site populated with vehicles/routing references & contracts' dates per routing ref/fuel card
     */
    async getSitePopulatedWithContractsDates(siteId: string): Promise<SitePopulatedContracts> {
        try {
            const site = await this.get('/api/sites/profile/' + siteId);
            return site.data;
        } catch (e) {
            throw e;
        }
    }

    /**
     * Returns monthsdata aggregated by month or year between dateStart and dateEnd.
     * If a number of years is specified for historic, we extend the dateStart.
     * @param {string} contractId
     * @param {string} routingReference
     * @param {string} start
     * @param {string} end
     * @param {boolean} isByYear
     * @param {number} nbYearsHistoric
     * @return {Promise<any>} Array of month data aggregated.
     * The only difference between monthly and yearly is on the property "month": nb of month for which we have data/ index of the month between 0 and 11.
     *  aggregated per year:
     * [{   consumption: {heating: {quantity: 0, amount: 0}, ecs: {quantity: 0, amount: 0}, total: {quantity: 0, amount: 0}}
            dateEnd: "2016-08-31T23:59:59.999Z"
            dateStart: "2016-08-01T00:00:00.000Z"
            days: 31
            energyType: "heating"
            miscellaneous: {telegestion: {amount: 0}, gestion: {amount: 0}}
            month: 7
            regulation: {heating: {quantity: 0, amount: 0}, ecs: {quantity: 0, amount: 0}, total: {quantity: 0, amount: 0}}
            subscription: {heating: {amount: 680.34}, ecs: {amount: 425.21}, total: {amount: 0}}
            totalHT: {amount: 1105.55}
            totalTTC: {amount: 1166.36}
            totalTVA: {amount: 0}
            unit: "kwh"
            year: 2016
        }, ...]
     */
    getByMonthOrYear(
        energy: string = '',
        contractId: string = '',
        routingReference: string,
        start: string,
        end: string,
        isByYear: boolean = false,
        nbYearsHistoric: number = 0
    ) {
        let modifiedStart = start;
        if (isByYear) {
            modifiedStart = moment(start, 'DD/MM/YYYY')
                .subtract(nbYearsHistoric, 'y')
                .format('DD/MM/YYYY')
                .replace(/\//g, '%2F');
        } else {
            modifiedStart = start.replace(/\//g, '%2F');
        }
        const modifiedEnd = end.replace(/\//g, '%2F');
        const monthOrYear = isByYear ? 'yearly' : 'monthly';
        let route = `/api/common/bills/${monthOrYear}?routingReference=${routingReference}&start=${modifiedStart}&end=${modifiedEnd}&energy=${energy}`;

        if (contractId) {
            route += `&contract=${contractId}`;
        }

        return this.get(route);
    }

    /**
     * Export Page, graph or table in an Excel document.
     * @param type
     * @return {any}
     */
    getExportExcelUrl(type, periodSelected, routingRef, contract, isDjuActivated, energy) {
        if (type && periodSelected && periodSelected.dateStart && periodSelected.dateEnd && routingRef) {
            const start = moment(periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const end = moment(periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            let url = `/api/export/excel/pdl/${type}?routingReferenceId=${routingRef}&start=${start}&end=${end}&energy=${energy}`;
            url += contract ? `&contract=${contract}` : '';

            url += isDjuActivated && type === 'consumption' ? `&dju=true` : '';
            return url;
        }
        return null;
    }

    /**
     * Return the Excel document name.
     * @param txt
     * @return {any}
     */
    getExportName(txt, periodSelected, routingRef, contract) {
        if (
            routingRef &&
            routingRef.reference &&
            periodSelected &&
            periodSelected.dateStart &&
            periodSelected.dateEnd
        ) {
            const dateForTitle = periodSelected.dateStart + ' - ' + periodSelected.dateEnd;

            if (contract && contract.reference) {
                return `${txt}-${routingRef.reference}-${contract.reference}-${dateForTitle}.xlsx`;
            }
            return `${txt}-${routingRef.reference}-${dateForTitle}.xlsx`;
        }
        return null;
    }
}
