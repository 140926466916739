import { Pipe, PipeTransform } from '@angular/core';

const sizes = ['bit', 'Ko', 'Mo', 'Go'];
const k = 1000;

@Pipe({
    name: 'formatFileSize',
})
export class FormatFileSizePipe implements PipeTransform {
    transform(value: number): string {
        if (value === 0) {
            return '0 Ko';
        }

        const i = Math.floor(Math.log(value) / Math.log(k));
        const result = Math.round(value / Math.pow(k, i));

        return result + ' ' + sizes[i];
    }
}
