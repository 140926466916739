import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Params } from '@angular/router';

import { MessageDisplayService } from 'app/shared/components/load-curve/message-display/message-display.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { LoadCurveService } from './load-curve.service';

import { DropdownSettings } from 'app/shared/components/common/dropdown-multilevel/dropdown-multilevel.interface';
import {
    AreasplineAxis,
    AreasplineOptions,
    AreasplineYPlotLines,
} from 'app/shared/models/charts/chart-properties.interface';
import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';

import { LoadCurveData, LoadCurveMeasure } from 'app/shared/models/load-curve.interface';
import { SitePopulated } from 'app/shared/models/site.interface';
import {
    Entity,
    LoadCurveSerie,
    RoutingReferenceDropdownItem,
    SearchFilter,
    TileProperties,
    TileSerie,
} from './load-curve.interface';

import {
    DatePicker,
    DateRange,
    DateRangeLimit,
} from 'app/shared/components/common/date-range-picker/date-range-picker.interface';

import * as Highcharts from 'highcharts/highstock';

import * as _ from 'lodash';
import * as moment from 'moment';
import * as mTZ from 'moment-timezone';

@Component({
    selector: 'ga-load-curve',
    templateUrl: './load-curve.component.html',
    styleUrls: ['./load-curve.component.scss'],
    providers: [LoadCurveService, MessageDisplayService],
})
export class LoadCurveComponent implements OnInit, OnDestroy {
    /*********************
     * DATE PICKER
     *********************/

    /** date limit for the date range picker */
    public dateRangeLimit: DateRangeLimit = {
        minDate: null,
        maxDate: null,
    };

    // Data for the date range (start date and end date)
    public dateRange: DateRange = {
        fromDate: {
            date: null,
            utcDate: null,
        },
        toDate: {
            date: null,
            utcDate: null,
        },
    };

    // Number of days counted in the current selected period
    public dateRangeDaysCount = 0;

    /**
     * Tiles properties
     * - Consumption
     * - Missing measures
     * - Minimum electric power
     * - Maximum electric power
     */

    public tiles: TileProperties[] = [];

    /*********************
     * CHART
     *********************/

    /** Properties for the load curve chart */
    private loadCurveProperties: AreasplineOptions;

    /** Chart data series */
    private loadCurveSeries: LoadCurveSerie[] = [];

    /** Chart object */
    private chart: Highcharts.Chart = null;

    /** Is the option to stack chart series enabled */
    public seriesStacked = true;

    /**
     * Is the chart currently loading. Used to display loading message and hide chart.
     * Only indicated if loading for the first time a new user search.
     * Not set to true if loading for complete data.
     */
    public isChartLoading = false;

    /** Is the chart currently loading complete data */
    public isCompleteChartLoading = false;

    /** Id of the load curve complete search request timer */
    private setTimeoutSearchId: ReturnType<typeof setTimeout>;

    /** Timeout interval (in milliseconds) used for data complete search process  */
    private readonly timeoutInterval: number = 3000;

    /** Url date format for parsing and format */
    private readonly urlDateFormat: string = 'MM-DD-YYYY';

    /** Filters hash to detect changes in values */
    private filtersHash: string = null;

    /** Is true when the load curve data have not the same interval */
    public isAdjustedData = false;

    /** Stored load curve data from API and adjusted data */
    public loadCurveData: {
        default: LoadCurveData[];
        adjusted: LoadCurveData[];
    } = {
        default: null,
        adjusted: null,
    };

    /** Selected chart type */
    private selectedChartType: 'spline' | 'areaspline';

    /** Default measure interval (in milliseconds) */
    private readonly defaultInterval: number = 600000;

    /*********************
     * ENTITY SELECTION
     ********************/

    /** Current company selected to display sites. Currently onyl supported for URL use */
    public companyId: string = null;

    /** Wheter the selected entity is loading or not */
    public isEntityLoading = false;

    /** List of routing references and sites to display */
    public routingReferencesList: RoutingReferenceDropdownItem[] = [];

    /** Selected element from the dropdown list */
    public selectedItem: Entity = null;

    /** Configuration for the dropdown list */
    public dropdownSettings: DropdownSettings = {
        bindLabel: 'reference', // field displayed for dropdown options (site or routing ref)
        groupBy: (routingRef: RoutingReferenceDropdownItem) => this.getDisplayCodeNameFromSite(routingRef.site), // group routing references by their site name
        groupValue: (s: string, children: any[]) => ({
            // give to the site options the same fields as the routing references (_id & reference)
            _id: children[0].site._id,
            reference: children[0].site.complement,
            type: 'site',
        }),
        selectableGroup: true,
        selectableGroupAsModel: true,
        hideSelected: true,
        notFoundText: 'Aucun PDL trouvé',
        dropdownPosition: 'bottom',
        customSearch: (search: string, routingRef: RoutingReferenceDropdownItem) => {
            // search inside each routing reference's number and site name
            search = search.toLowerCase();
            return (
                routingRef.reference.toLowerCase().includes(search) ||
                this.getDisplayCodeNameFromSite(routingRef.site)
                    .toLowerCase()
                    .includes(search)
            );
        },
    };

    /** used to store the site name and display it above the current chart */
    private _displayedSiteName: string;

    /** Constructor */
    constructor(
        private loadCurveService: LoadCurveService,
        private messageDisplayService: MessageDisplayService,
        private routingReferencesService: RoutingReferencesService,
        private chartService: ChartService,
        private tilesService: TilesService,
        private translateService: TranslateService,
        private utilsService: UtilsService,
        private filterService: FilterService,
        private route: ActivatedRoute,
        private sessionService: SessionService
    ) {}

    ngOnInit() {
        /**
         * Set moment at window level
         * `moment` is not saved on `window`, so Highcharts fail when trying to access `moment`
         */
        window['moment'] = moment;

        /**
         * Set the chart options for display
         */
        this.initChartOptions();

        /**
         * Initialise tiles (consumption and missing measures)
         */
        this.initTiles();

        /**
         * Initialise the dropdown data to display in the dropdown list
         */
        this.initData();
    }

    ngOnDestroy() {
        this.destroyTimeout();
    }

    //#region chart options
    /***********************
     * CHART OPTIONS
    /***********************

    /**
     * Set time zone and handle zoom selection
     */
    private initChartOptions() {
        /**
         * Load timezones and set highchart timezone in Paris
         */
        mTZ();

        /**
         * Set properties for load curve chart
         */
        const that = this;
        this.loadCurveProperties = this.chartService.getConfig('areaspline');

        /**
         * Update external elements each time the chart is rendered (on redraw)
         *  - compute total consumption
         *  - compute min and max for lines
         *  - compute missing measures
         */
        this.loadCurveProperties.chart.events = {
            redraw(e) {
                that.handleChartRedraw(this);
                that.computeConsumption();
                that.computeMinMaxMissing();
                return true;
            },
        };

        // Display a line for subscribed power
        this.setChartPlotLines();

        /**
         * Set French for axis labels and tooltips
         */
        this.chartService.setFrenchOptions();

        this.chart = Highcharts.stockChart('loadCurveContainer', this.loadCurveProperties);
    }

    /**
     * Set a plotline for the subscribed power
     * @todo later, use real value. Null value is not displayed.
     */
    private setChartPlotLines(): void {
        /**
         * Set plotLines for min, max and power subscribed
         */
        const plotLines: AreasplineYPlotLines[] = [
            {
                id: 'subscription',
                label: {
                    text: 'Puissance souscrite : NC',
                },
                color: 'grey',
                width: 2,
                dashStyle: 'ShortDash',
                value: null,
                zIndex: 5,
            },
        ];
        this.loadCurveProperties.yAxis[0].plotLines = plotLines;
    }

    private getDisplayCodeNameFromSite(site: SitePopulated): string {
        return site.code ? `${site.code} - ${site.complement}` : site.complement;
    }

    /**
     * Compute selected series each time the chart redraw
     *
     * @param {	Highcharts.Chart} chart - chart object which redraw
     */
    private handleChartRedraw(chart: Highcharts.Chart) {
        if (this.updateChartData()) {
            return true;
        }

        const consoTile = this.tiles.find(t => t.key === 'elec');
        // Don't consider last serie as it's the zoom serie displayed under the chart
        consoTile.series = (chart['series'] as any[]).slice(0, -1).map(serie => {
            // As when there is many values Highchart group them (best for visual and perfs)
            // Then we need to manually get values displayed
            const extremes = serie.xAxis.getExtremes();
            const minX = extremes.min;
            const maxX = extremes.max;

            /** Index of the first data displayed, matching the min bound of the chart */
            let minDataIndex: number = serie.xData
                .concat([])
                .reverse()
                .findIndex((v: number) => v < minX);

            // Take the next index in the chronological order (original array order before reverse)
            minDataIndex = minDataIndex === -1 ? 0 : serie.xData.length - minDataIndex;

            /** Index of the last data displayed, matching the max included bound of the chart */
            let maxDataIndex: number = serie.xData.findIndex((v: number) => v > maxX);
            if (maxDataIndex === -1) {
                maxDataIndex = serie.xData.length; // last measure if not found
            }

            // Get x and y values between indexes
            const x = serie.xData.slice(minDataIndex, maxDataIndex);
            const y = serie.yData.slice(minDataIndex, maxDataIndex);

            const serieUpdated: TileSerie = {
                index: serie.index,
                visible: serie.visible,
                // xDisplay: serie.processedXData.map(value => Highcharts.dateFormat('%a %d/%m %H:%M', value)), // useful for debug
                x: [].concat(x),
                y: [].concat(y),
                interval: serie.userOptions.pointInterval / 1000 / 60, // interval in minutes
            };
            return serieUpdated;
        });

        this.tiles
            .filter(t => t.key !== 'elec')
            .forEach(tile => {
                tile.series = [...consoTile.series];
            });
    }

    // #endregion

    //#region tiles

    /***********************
     * TILES:
     * - CONSUMTION
     * - MISSING MEASURES
     * - MIN POWER
     * - MAX POXER
    /***********************

    /**
     * Initialise tiles
     */
    private initTiles(): void {
        const keys = ['elec', 'min_elec_power', 'max_elec_power', 'missing_measures'];
        keys.forEach(key => {
            const tile: TileProperties = {
                ...this.tilesService.generateTile(key),
                key,
                value: null,
                series: [],
                size: 'medium',
            };
            this.tiles.push(tile);
        });
        this.initMissingMeasuresTile();
    }

    /**
     * Initialize the tile properties of the missing measures tile
     */
    private initMissingMeasuresTile(): void {
        const tile = this.tiles.find(t => t.key === 'missing_measures');
        tile.valueHTML = this.setMissingMeasuresCountHTML(null, null);
        tile.unit = null;
    }

    /**
     * Compute the total consumption of the series currenlty being displayed in the chart
     * Set it inside the tile value
     * @returns {void}
     */
    private computeConsumption(): void {
        const consoTile = this.tiles.find(t => t.key === 'elec');

        const seriesConsumption: number[] = consoTile.series
            .filter(x => x.visible === true)
            .map(serie => {
                const sumPower = _.sum(serie.y);
                const n = 60 / serie.interval;
                const averagePower = sumPower / n;
                return averagePower;
            });
        if (seriesConsumption.length) {
            // _.sum([]) === 0
            consoTile.value = _.sum(seriesConsumption) / 1000; // sum of Wh
        } else {
            // If no visible serie, set null (≠ 0)
            consoTile.value = null;
        }
    }

    /**
     * Return the formatted missing measures count and percentage to be displayed
     * @param {number | null} missingCount - missing measures count
     * @param {number | null} total - total measures expected
     * @returns {string}
     */
    private setMissingMeasuresCountHTML(missingCount: number | null, total: number | null): string {
        let missingPercentage = 0;

        if (missingCount === null || missingCount === 0 || total === null) {
            return this.translateService._('none_f');
        }

        if (missingCount > 0 && total > 0) {
            missingPercentage = this.utilsService.roundNumber((missingCount / total) * 100);
        }

        const label = missingCount > 1 ? this.translateService._('mesure_p') : this.translateService._('mesure');
        return `${missingCount} ${label} <span class="no-bold">(${missingPercentage}%)</span>`;
    }

    /**
     * Compute min and max values, and the number of missing measures
     * Update values in appropriate tiles
     */
    private computeMinMaxMissing(): void {
        /**
         * Update min/max with value if less than min or greater than max
         * @param {number} value - value to compare
         * @param {number} timestamp - date when value was recorded
         */
        const updateMinMax = (value: number, timestamp: number = null) => {
            if (value >= max) {
                max = value;
                // For now, don't display hour and minutes, as it doesn't fit in tile
                maxDate = moment(timestamp).format('DD/MM/YYYY');
            } else if (value <= min || min === null) {
                min = value;
                // For now, don't display hour and minutes, as it doesn't fit in tile
                minDate = moment(timestamp).format('DD/MM/YYYY');
            }
        };

        // Tiles updated
        const consumptionTile = this.tiles.find(t => t.key === 'elec');
        const minPowerTile = this.tiles.find(t => t.key === 'min_elec_power');
        const maxPowerTile = this.tiles.find(t => t.key === 'max_elec_power');
        const missingMeasuresTile = this.tiles.find(t => t.key === 'missing_measures');

        /** Data series being displayed */
        const visibleSeries: TileSerie[] = consumptionTile.series.concat([]).filter(x => x.visible === true);

        // Minimum and maximum values found (in all series)
        let min: number = null;
        let max: number = null;
        /** Date when minimum value was recorded */
        let minDate: string = null;
        /** Date when maximum value was recorded */
        let maxDate: string = null;

        /** Define if the chart is in areaspline type (stacked chart type) */
        const isAreaspline = Boolean(this.loadCurveProperties.chart.type === 'areaspline');

        /** Number of null values found per serie */
        const nbNullValuesPerSerie: number[] = [];

        /**
         * For stacked chart type, sum all value per date and get min/max sum.
         * As every serie may have different dates, we can't rely on indexes to sum values.
         * Chart stacked values can't be used either as they're grouped (averages made).
         */
        if (isAreaspline) {
            /** Map where key is a timestamp and value the sumed value */
            const map = new Map<number, number>();

            // Sum values by timestamp
            visibleSeries.forEach(serie => {
                let serieNullValuesCount = 0;
                for (let i = 0, len = serie.y.length; i < len; i++) {
                    if (serie.y[i] !== null) {
                        map.set(serie.x[i], (map.get(serie.x[i]) || 0) + serie.y[i]);
                    } else {
                        serieNullValuesCount++; // count null values on each serie
                    }
                }
                nbNullValuesPerSerie.push(serieNullValuesCount);
            });

            // Get min/max
            map.forEach((value, timestamp) => {
                updateMinMax(value, timestamp);
            });
        } else {
            // Scan all visible series values to get min/max
            visibleSeries.forEach(serie => {
                let serieNullValuesCount = 0;
                for (let i = 0, len = serie.y.length; i < len; i++) {
                    if (serie.y[i] !== null) {
                        updateMinMax(serie.y[i], serie.x[i]);
                    } else {
                        serieNullValuesCount++; // count null values on each serie
                    }
                }
                nbNullValuesPerSerie.push(serieNullValuesCount);
            });
        }

        // Set minimum and maximum values in tile (in kW)
        minPowerTile.value = typeof min === 'number' ? min / 1000 : null;
        maxPowerTile.value = typeof max === 'number' ? max / 1000 : null;
        // Set dates associated with min & max values
        if (typeof minDate === 'string') {
            minPowerTile.info = `- ${minDate}`;
        }
        if (typeof maxDate === 'string') {
            maxPowerTile.info = `- ${maxDate}`;
        }

        /**
         * Set missing measures count and ratio in tile
         * Missing data can be caused by :
         *  - null values
         *  - missing time indexes in between two periods of data
         * Total missing measures is the sum of both
         **/

        // Compute the overall number of missing measures (from all series) and the overall number of measures
        const { nbMissingMeasures, nbExpectedMeasures } = this.loadCurveService.computeMissingMeasures(
            visibleSeries,
            nbNullValuesPerSerie
        );
        // Set values in tile
        missingMeasuresTile.valueHTML = this.setMissingMeasuresCountHTML(nbMissingMeasures || null, nbExpectedMeasures);
    }

    /**
     * Handle plotlines.
     * Min and max disabled while we can't find a way to display all points with good performances without grouping (false min/max displayed)
     * @param {number} min
     * @param {number} max
     */
    private handlePlotlines(min: number, max: number): void {
        const yAxis: AreasplineAxis = this.chart.get('yA0') as AreasplineAxis;
        yAxis.removePlotLine('min');
        yAxis.removePlotLine('max');
        if (typeof min === 'number') {
            yAxis.addPlotLine({
                id: 'min',
                label: {
                    text: `Min : ${min}W`,
                },
                color: 'green',
                width: 2,
                dashStyle: 'ShortDash',
                value: min,
                zIndex: 5,
            });
        }
        // Disable plotlines while couldn't find a way to display all points with good performances
        if (typeof max === 'number') {
            yAxis.addPlotLine({
                id: 'max',
                label: {
                    text: `Max : ${max}W`,
                },
                color: 'red',
                width: 2,
                dashStyle: 'ShortDash',
                value: max,
                zIndex: 5,
            });
        }
    }

    //#endregion

    //#region dropdown multilevel
    /***********************
     * DROPDOWN MULTILEVEL INIT
     **********************/

    private async initData(): Promise<void> {
        /**
         * Handle URL params
         */
        // moment.locale('fr'); // not working, moment still reads dates in english format
        this.route.queryParams.subscribe({
            next: async queryParams => {
                // Update component company id and get if changed
                // At first load, will always return true as companyId not set
                const hasChanged = this.updateCompanyId(queryParams);
                // If company id changed, then reload routing references dropdown
                if (hasChanged) {
                    /**
                     * Initialise the routing references list
                     */
                    await this.setDropdownRoutingReferencesData();
                }
                this.updateFiltersFromParams(queryParams);
            },
        });
    }

    /**
     * Update `companyId` from params. If not set yet, set it.
     * @param {Params} params
     * @return {boolean} true if company changed, false otherwise
     */
    private updateCompanyId(params: Params): boolean {
        let companyId = this.filterService.getParamAliasValue(params, 'company');
        // If companyId not set in params, use session company
        if (!companyId) {
            companyId = this.sessionService.getCompanyId();
        }
        // If companyId defined and equal component's companyId, then do nothing
        if (companyId && companyId === this.companyId) {
            return false;
        }
        // If component's companyId not set or different from companyId, then set to new companyId
        if (!this.companyId || this.companyId !== companyId) {
            this.companyId = companyId;
            return true;
        }
        return false;
    }

    /**
     * List of routing references for the dropdown multilevel
     * @returns {Promise<void>}
     */
    private async setDropdownRoutingReferencesData(): Promise<void> {
        const routingReferences = await this.routingReferencesService.getRoutingReferencesAssociated(
            {
                energies: 'elec',
                companyId: this.companyId,
                communicatingProviders: ['ga', 'enedis'],
            },
            { communicating: true }
        );
        this.routingReferencesList = routingReferences;

        // Disable the routing reference in the dropdown list if it has an inactive status
        this.routingReferencesList.forEach((routingReference: RoutingReferenceDropdownItem) => {
            if (routingReference.status.active === false) {
                routingReference.disabled = true;
            }
            routingReference.type = 'routingreference';
        });
    }
    //#endregion

    //#region chart
    /***********************
     * CHART
     **********************/

    /**
     * Method triggered when search of data is needed.
     * @param {SearchFilter} filters - filter to ask the API for
     * @returns {Promise<void>}
     */
    private async search(filters: SearchFilter): Promise<void> {
        try {
            // Complete search can't be waited as it has a timeout.
            // So we need to split chart loading activation and deactivation.
            // Only indicates first loading, not retrieval of complete data
            this.isChartLoading = true;
            await this.completeSearch(filters, null);
        } catch (e) {}
    }

    /**
     * Check if the load curve data is complete or not.
     * An empty data table corresponds to no load curve
     * therefore considered as complete data.
     * Load curves having pensing data aren't considered as complete
     * @param {LoadCurveData[]} data
     * @returns {boolean}
     */
    private isComplete(data: LoadCurveData[]): boolean {
        if (!data) {
            return false;
        }

        if (!data.length) {
            return true;
        }

        return data.every(serie => typeof serie.hasPending !== 'boolean' || serie.hasPending !== true);
    }

    /**
     * Launch the complete data search process
     * Keep processing every defined timeout interval while the data is incomplete
     *
     * @param {SearchFilter} filters - filter to ask the API for
     * @param {LoadCurveData[]} previousData - data from the previous completeSearch calling
     */
    private async completeSearch(filters: SearchFilter, previousData: LoadCurveData[]): Promise<void> {
        const isCompleteData = this.isComplete(previousData);
        this.isCompleteChartLoading = previousData && !isCompleteData;
        this.destroyTimeout();
        if (!isCompleteData) {
            this.setTimeoutSearchId = setTimeout(
                async () => {
                    try {
                        const data: LoadCurveData[] = await this.loadCurveService.getLoadCurveData(filters);

                        this.loadCurveData = {
                            default: data,
                            adjusted: null,
                        };
                        this.messageDisplayService.setErrors(data);

                        this.isAdjustedData = this.hasDifferentInterval(data);
                        if (this.isAdjustedData) {
                            this.loadCurveData.adjusted = this.adjustData(_.cloneDeep(data));
                        }

                        // update chart with new values
                        this.handleChart(data);
                        await this.completeSearch(filters, data);
                    } catch (e) {}

                    this.isChartLoading = false;
                },
                previousData ? this.timeoutInterval : 0
            );
        }
    }

    /**
     * Format data from API to data expected from the chart
     * Apply config chart reletive to the series data (weekends, gradient colors)
     * @param {LoadCurveData[]} data - data from the API
     * @returns {void}
     */
    private handleChart(data: LoadCurveData[]): void {
        this.loadCurveSeries = data.map((serie, index) => {
            const interval = serie.interval || this.loadCurveService.getDataInterval(serie.measures);
            const s: LoadCurveSerie = {
                name: serie.name || 'Source NC',
                data: serie.measures.map(x => [new Date(x.timestamp).getTime(), x.value]),
                pointInterval: interval, // keep interval to compute consumption
                visible: true,
                type: this.loadCurveProperties.chart.type as 'spline' | 'areaspline',
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [
                            0,
                            Highcharts.color(Highcharts.getOptions().colors[index])
                                .setOpacity(0.7)
                                .get('rgba')
                                .toString(),
                        ],
                        [
                            1,
                            Highcharts.color(Highcharts.getOptions().colors[index])
                                .setOpacity(0)
                                .get('rgba')
                                .toString(),
                        ],
                    ],
                },
            };
            return s;
        });

        this.plotWeekEnds(data);

        this.updateChart();
    }

    /**
     * Update the chart series
     */
    private updateChart(): void {
        if (this.chart) {
            // Navigator is the first serie, so we need to remove it last
            for (let i = this.chart.series.length - 1; i > -1; i--) {
                this.chart.series[i].remove(false);
            }
            this.loadCurveSeries.forEach(s => this.chart.addSeries(s, false));
            if (this.chart.xAxis && this.chart.xAxis[0]) {
                this.chart.xAxis[0].setExtremes(null, null, false);
            }
            this.chart.redraw(true);
        }
    }

    /**
     * PLot blue bands on weekend days
     * @param {LoadCurveData[]} data - data displayed in the chart
     */
    private plotWeekEnds(data: LoadCurveData[]): void {
        const xAxis: AreasplineAxis = this.chart.get('xA0') as AreasplineAxis;

        // remove old plotbands
        this.loadCurveProperties.xAxis[0].plotBands.forEach(plotband => {
            xAxis.removePlotBand(plotband.id);
        });

        // Compute week days. Get only one day per week as we'll set correct days when creating bands
        const uniqueWeekDays: moment.Moment[] = [];
        // First get starts and ends of series to get min/max
        const { starts, ends } = data.reduce(
            (memo: { starts: moment.Moment[]; ends: moment.Moment[] }, serie) => {
                if (serie.measures.length) {
                    // Not using UTC because we want dates in local of user
                    memo.starts.push(moment(serie.measures[0].timestamp));
                    memo.ends.push(moment(serie.measures[serie.measures.length - 1].timestamp));
                }
                return memo;
            },
            { starts: [], ends: [] }
        );
        // Iterate for every week between min start and max end.
        const minStart = moment.min(starts);
        const maxEnd = moment.max(ends);
        while (minStart.isBefore(maxEnd)) {
            uniqueWeekDays.push(minStart.clone().startOf('day'));
            minStart.add(1, 'week');
        }

        uniqueWeekDays.forEach(day => {
            xAxis.addPlotBand({
                id: 'weekend' + day.valueOf(),
                color: Highcharts.color(Highcharts.getOptions().colors[0])
                    .setOpacity(0.2)
                    .get('rgba')
                    .toString(),
                from: moment(day)
                    .isoWeekday(6)
                    .valueOf(),
                to: moment(day)
                    .isoWeekday(6)
                    .add(2, 'day')
                    .valueOf(),
            });
        });
    }

    /**
     * Switch from line to area stacked and reverse
     * @param {MatSlideToggleChange} value - from the meterial slider. Boolean property "checked" indicates the final state.
     * @returns {void}
     */
    public toggleStack(value: MatSlideToggleChange): void {
        const type = value.checked ? 'areaspline' : 'spline';
        this.loadCurveProperties.chart.type = type;
        this.loadCurveSeries.forEach(s => {
            s.type = type;
        });
        const changes = {
            chart: this.loadCurveProperties.chart,
            series: this.loadCurveSeries,
        };
        if (this.chart) {
            this.chart.update(changes);
            this.computeMinMaxMissing();
        }
    }

    /**
     * Returns true if some load curve data have different intervals otherwise returns false
     *
     * @param {LoadCurveData} data
     * @returns {boolean}
     */
    private hasDifferentInterval(data: LoadCurveData[]): boolean {
        if (!data || !data.length) {
            return false;
        }
        const intervals = this.getDataIntervals(data);
        return Boolean(intervals.length > 1);
    }

    /**
     * returns true if the data has interval otherwise returns false
     *
     * @param {LoadCurveData} data
     * @returns {boolean}
     */
    private hasValidInterval(data: LoadCurveData): boolean {
        return Boolean(data && typeof data.interval === 'number' && data.interval > 0);
    }

    /**
     * Get unique intervals of given load curve data
     *
     * @param {LoadCurveData[]} data
     * @returns {number[]}
     */
    private getDataIntervals(data: LoadCurveData[]): number[] {
        if (!data || !data.length) {
            return [];
        }

        const intervals = data.reduce((memo, d) => {
            if (this.hasValidInterval(d)) {
                memo.push(d.interval);
            }
            return memo;
        }, []);

        return _.uniq(intervals);
    }

    /**
     * Get null measures between start date and end date with defined interval
     *
     * @param {moment.Moment} start - start date
     * @param {moment.Moment} end - end date
     * @param {number} interval - interval (in milliseconds)
     * @returns {LoadCurveMeasure[]}
     */
    private getMissingMeasures(start: moment.Moment, end: moment.Moment, interval: number): LoadCurveMeasure[] {
        const startStep = start.clone();
        const measures = [];
        while (startStep.isBefore(end)) {
            startStep.add(interval, 'ms');
            measures.push({
                timestamp: startStep.toISOString(),
                value: null,
            });
        }
        return measures;
    }

    /**
     * Adjust load curve data by max interval
     *
     * @param {LoadCurveData[]} data - load curve data from API
     * @returns {LoadCurveData[]}
     */
    private adjustData(data: LoadCurveData[]): LoadCurveData[] {
        const intervals: number[] = [];

        const dateRange: {
            min: moment.Moment;
            max: moment.Moment;
        } = {
            min: null,
            max: null,
        };

        // Get all interval values
        // Get global min date and max date
        data.forEach(d => {
            // Consider only data with no empty measures (interval > 0)
            if (this.hasValidInterval(d)) {
                intervals.push(d.interval);

                if (dateRange.min === null || moment.utc(d.start).isBefore(dateRange.min)) {
                    dateRange.min = moment.utc(d.start);
                }

                if (dateRange.max === null || moment.utc(d.end).isAfter(dateRange.max)) {
                    dateRange.max = moment.utc(d.end);
                }
            }
        });

        // retrieve the max interval value
        const maxInterval = _.max(intervals);

        data.map((d: LoadCurveData) => {
            if (!this.hasValidInterval(d)) {
                return d;
            }

            const interval = d.interval;

            // get null measures between global min date and data start date
            const startMeasures = this.getMissingMeasures(dateRange.min.clone(), moment.utc(d.start), interval);

            // get null measures between data end date and global max date
            const endMeasures = this.getMissingMeasures(moment.utc(d.end), dateRange.max.clone(), interval);

            // fill current data measures with computed start measures and end measures
            d.measures = [...startMeasures, ...d.measures, ...endMeasures];
            d.start = dateRange.min.toISOString();
            d.end = dateRange.max.toISOString();

            // then only adjust data series which don't have the max interval
            if (interval !== maxInterval && d.measures.length) {
                const adjustedMeasures: LoadCurveMeasure[] = [];
                const measures: LoadCurveMeasure[] = _.cloneDeep(d.measures);
                let measure: LoadCurveMeasure = null;
                const endInterval: moment.Moment = dateRange.min.add(maxInterval, 'ms');
                // Values since last measure timestamp matching max interval
                let values: number[] = [];
                // collect measure values and compute these values every max interval
                while (measures.length) {
                    measure = measures.shift();

                    if (typeof measure.value === 'number') {
                        values.push(measure.value);
                    }

                    // At the end of each max interval, we compute the average of measures since the last measure timestamp
                    if (!measures.length || moment.utc(measure.timestamp).isSame(endInterval)) {
                        const avgValue = values.length ? Math.round(_.sum(values) / values.length) : null;
                        adjustedMeasures.push({
                            value: avgValue,
                            timestamp: endInterval.toISOString(),
                        });
                        endInterval.add(maxInterval, 'ms');
                        values = [];
                    }
                }
                d.measures = adjustedMeasures;
            }
            return d;
        });

        return data;
    }

    /**
     * Update the chart data when changing chart type and only if exists adjusted load curve data
     * then returns true ohterwise returns false
     *
     * @returns {boolean}
     */
    private updateChartData(): boolean {
        const prevChartType = this.selectedChartType;
        this.selectedChartType = this.loadCurveProperties.chart.type as 'spline' | 'areaspline';
        if (this.isAdjustedData && prevChartType && this.selectedChartType !== prevChartType) {
            if (this.selectedChartType === 'areaspline') {
                this.handleChart(this.loadCurveData.adjusted);
            } else {
                this.handleChart(this.loadCurveData.default);
            }
            return true;
        }
        return false;
    }

    /**
     * Reset the load curve data
     */
    private resetLoadCurveData() {
        this.loadCurveData = {
            default: null,
            adjusted: null,
        };
    }

    /**
     * Reset chart
     */
    private resetChart() {
        this.handleChart([]);
        this.resetLoadCurveData();
        this.destroyTimeout();
        this.isCompleteChartLoading = false;
        this.isAdjustedData = false;
    }

    /**
     * @returns {boolean} true if the chart has data to display
     */
    get hasChartData(): boolean {
        return Boolean(
            this.loadCurveSeries && this.loadCurveSeries.length && this.loadCurveSeries.some(serie => serie.data.length)
        );
    }

    /**
     * @returns {boolean} true if the chart has complete data to display
     */
    get hasCompleteChartData(): boolean {
        return Boolean(this.hasChartData && !this.isCompleteChartLoading);
    }

    /**
     * Return the appropriate message when no data
     * @returns {string}
     */
    get noDataMessage(): string {
        return this.isCompleteChartLoading
            ? 'Nous récupérons actuellement vos données auprès d’Enedis, l’opération peut prendre quelques minutes.'
            : null;
    }

    public getExcelExportNames(): string[] | null {
        if (this.isChartLoading || this.isCompleteChartLoading || this.loadCurveSeries.length === 0) {
            return null;
        }
        const start = moment(this.dateRange.fromDate.date).startOf('day');
        const end = moment(this.dateRange.toDate.date).startOf('day');

        return this.loadCurveData.default
            .filter(x => !x.hasPending)
            .map(x =>
                `MesuresCourbeDeCharge-${start.format('DDMMYYYY')}-${end.format('DDMMYYYY')}-${x.name}`.replace(
                    '/',
                    '_'
                )
            );
    }

    public getExcelExportURLs(): string[] | null {
        if (this.isChartLoading || this.isCompleteChartLoading || this.loadCurveSeries.length === 0) {
            return null;
        }
        const start = moment(this.dateRange.fromDate.date).startOf('day');
        const end = moment(this.dateRange.toDate.date)
            .startOf('day')
            .add(1, 'day');
        return this.loadCurveData.default
            .filter(x => !x.hasPending)
            .map(
                x =>
                    '/api/export/excel/load-curve/' +
                    x.entityType +
                    '?dateStart=' +
                    start.toISOString() +
                    '&dateEnd=' +
                    end.toISOString() +
                    '&' +
                    x.entityType +
                    '=' +
                    x.entityId +
                    '&provider=' +
                    x.provider
            );
    }

    // #endregion

    //#region filters
    /*************************
     * FILTERS
     ************************/

    /**
     * Update the filters from url parameters
     * Info: Dates from params have priority on the dates from filters.
     *       When any url param change is detected, dates take the params values.
     * Info: Expected format of date is MM-DD-YYYY
     * @param {Params} params
     * @param {string?} params.r - routing reference id
     * @param {string?} params.s - site id
     * @param {string?} params.ds - date - start of the period
     * @param {string?} params.de - date - end of the period
     */
    private async updateFiltersFromParams(params: Params): Promise<void> {
        /**
         * Set the period from params first
         * If the site has changed, the period query params might be updated in the entity change process, to adpat on the entity's available period
         */

        /**
         * Set dates
         */
        const [start, end] = [params.ds, params.de].map(d =>
            d && this.filterService.isDateValid(moment(d, this.urlDateFormat)) ? moment(d, this.urlDateFormat) : null
        );
        const hasDates: boolean = [start, end].some(d => d !== null);
        const hasCurrentStart = this.isDatePropertySet(this.dateRange.fromDate);
        const hasCurrentEnd = this.isDatePropertySet(this.dateRange.toDate);
        if (hasDates) {
            if (start) {
                if (!hasCurrentStart || start.toDate().getTime() !== this.dateRange.fromDate.date.getTime()) {
                    this.setDate(start.toDate(), this.dateRange.fromDate);
                }
            }
            if (end) {
                if (!hasCurrentEnd || end.toDate().getTime() !== this.dateRange.toDate.date.getTime()) {
                    this.setDate(end.toDate(), this.dateRange.toDate);
                }
            }
            this.dateRange = { ...this.dateRange };
        } else if (!hasDates && (hasCurrentStart || hasCurrentEnd)) {
            // if dates have been erased, reset date pickers
            this.resetDates();
        }

        /**
         * Set routing reference or site
         */
        const routingReference = params.r;
        const site = params.s;
        if (routingReference || site) {
            // select the desired entity
            const type: 'routingreference' | 'site' = routingReference ? 'routingreference' : 'site';
            const entity: Entity = {
                type,
                _id: routingReference ? routingReference : site,
                reference: null,
            };
            if (!this.selectedItem || entity._id !== this.selectedItem._id) {
                await this.setEntity(entity);
            }
        }

        /**
         * Search for load curve if the entire filter is correct.
         * If params have date, set them before launching the search
         */
        if (this.isFilterComplete) {
            this.emitSearch();
        }
    }

    /**
     * Handle a new selection from the entity selector
     * Set entity, load its periods of data and update query params
     * @param {RoutingReferenceDropdownItem} item - The item selected in the multiselect dropdown
     */
    public async onEntitySelected(item: RoutingReferenceDropdownItem): Promise<void> {
        // mapping for the queryParams to set
        const paramForType = {
            site: 's',
            routingreference: 'r',
        };

        const queryParams = {};

        // deselec the dropdown -> remove the url param
        if (!item) {
            const type = paramForType[this.selectedItem.type];
            queryParams[type] = null;
            this.selectedItem = null;
        } else {
            const type = paramForType[item.type];
            queryParams[type] = item._id;
            const typeToReset = Object.values(paramForType).find(t => t !== type); // reset value for other entity type
            queryParams[typeToReset] = null;
        }

        // update the query params of the entity
        this.filterService.updateRouteParams(queryParams);

        this.handleInputsChart('entity');
    }

    /**
     * Used to clear the load curve chart when handling inputs (entity and daterange)
     *
     * @param {('entity'|'daterange')?} handledInput - handled input
     */
    private handleInputsChart(handledInput?: 'entity' | 'daterange') {
        if (
            this.isFilterAllIncomplete ||
            (handledInput === 'entity' && !this.isDateSet) ||
            (handledInput === 'daterange' && !this.selectedItem)
        ) {
            this.resetChart();
        }
    }

    /**
     * Update the selected entity with its reference and site if needed
     * @param {Entity} entity
     * @returns {Entity} entity updated with references
     */
    private setSelectedEntity(entity: Entity): Entity {
        if (entity.type === 'site') {
            const rref = this.routingReferencesList.find(x => x.site._id === entity._id);
            entity.reference = rref.site.complement;
        } else {
            const rref = this.routingReferencesList.find(x => x._id === entity._id);
            entity.reference = rref.reference;
            entity.site = rref.site.complement;
        }
        return entity;
    }

    /**
     * Set the selected entity (a site or a routing reference)
     * Set the period available for the selected entity.
     * @param {Entity} entity
     */
    private async setEntity(entity: Entity): Promise<void> {
        try {
            this.isEntityLoading = true;

            // retrieve the site or the routing reference from the entities list and set the references
            this.selectedItem = this.setSelectedEntity(entity);

            // retrieve dates
            const response = await this.loadCurveService.getProjectPeriod(entity.type, entity._id);
            const dateStart = moment.utc(response.dateStart).toDate();
            // dateEnd must be < today
            const dateEnd = moment
                .min(
                    moment.utc(response.dateEnd),
                    moment()
                        .subtract(1, 'day')
                        .endOf('day')
                )
                .toDate();

            // set limit dates in datepickers
            this.dateRangeLimit.minDate = dateStart;
            this.dateRangeLimit.maxDate = dateEnd;

            // update dateRange to match the available range
            this.updateRange();

            this.isEntityLoading = false;
        } catch (e) {
            this.isEntityLoading = false;
            this.loadCurveSeries = [];
            this.updateChart();
        }
    }

    /**
     * Update the current period from the entity available period.
     */
    private updateRange() {
        // use moment dates to compare
        const entityPeriod = {
            start: moment(this.dateRangeLimit.minDate),
            end: moment(this.dateRangeLimit.maxDate),
        };
        const currentPeriod = {
            start: moment(this.dateRange.fromDate.date),
            end: moment(this.dateRange.toDate.date),
        };

        const hasEntityPeriod = entityPeriod.start.isValid() && entityPeriod.end.isValid();

        // if the current period or the entity period isn't valid, don't set the perdio
        if (!this.isDateSet || !hasEntityPeriod) {
            return;
        }

        // if the entity period is outside the current period (finishes before and start after), reset
        const isEntityOutsideCurrent =
            entityPeriod.end.isSameOrBefore(currentPeriod.start) || entityPeriod.start.isSameOrAfter(currentPeriod.end);
        if (isEntityOutsideCurrent) {
            this.filterService.updateRouteParams({ ds: null, de: null });
            return;
        }

        const params = {};
        // period end takes the end of the entity's period
        if (entityPeriod.end.isBefore(currentPeriod.end)) {
            params['de'] = entityPeriod.end.format(this.urlDateFormat);
        }

        // period start takes the beginning of the entity's period
        if (entityPeriod.start.isAfter(currentPeriod.start)) {
            params['ds'] = entityPeriod.start.format(this.urlDateFormat);
        }
        this.filterService.updateRouteParams(params);
    }

    /**
     * Reset start and end of the period selector
     */
    private resetDates(): void {
        this.dateRange = {
            fromDate: { date: null, utcDate: null },
            toDate: { date: null, utcDate: null },
        };
    }

    /**
     * Set the selected date
     * Search for load curve if the filter is complete
     * @param {DateRange} dateRange - date range selected
     */
    onDateRangeChange(dateRange: DateRange) {
        if (!dateRange) {
            return;
        }

        // update the query params of the date
        const queryParams = {
            ds: dateRange.fromDate.date ? moment(dateRange.fromDate.date).format(this.urlDateFormat) : null,
            de: dateRange.toDate.date ? moment(dateRange.toDate.date).format(this.urlDateFormat) : null,
        };
        this.filterService.updateRouteParams(queryParams);

        this.handleInputsChart('daterange');
    }

    /**
     * Set the  selected number of days
     */
    onDaysRangeChange(nbDays: number) {
        this.dateRangeDaysCount = nbDays;
    }

    /**
     * Set the selected date in side the datePicker
     * @param {Date} date - date to set
     * @param {isDatePropertySet} datePicker - datePicker to set the value to
     */
    private setDate(date: Date, datePicker: DatePicker): void {
        datePicker.date = date;
        /**
         * Ngx-bootstrap has a bug : it doesn't care about the timezones.
         * So we need to extract only year, month and day from date, not considering hours, minutes, ...
         */
        datePicker.utcDate = date
            ? moment.utc({ y: date.getFullYear(), M: date.getMonth(), d: date.getDate() }).toDate()
            : null;
    }

    /**
     * Search for load curve data to display, matching the given filter.
     */
    private emitSearch(): void {
        const start = moment(this.dateRange.fromDate.date).startOf('day');
        const end = moment(this.dateRange.toDate.date)
            .startOf('day')
            .add(1, 'day');
        const filters: SearchFilter = {
            entityType: this.selectedItem.type,
            entityId: this.selectedItem._id,
            start: start.toISOString(),
            end: end.toISOString(),
        };
        // Check that filters changed and search if so
        const currentHash = JSON.stringify(filters);
        const hasChanged = currentHash !== this.filtersHash;
        if (hasChanged) {
            this.filtersHash = currentHash;
            this.search(filters);
        }
    }

    /**
     * Return the appropriate key for plural or singular days
     * @returns {string} key for day/days
     */
    get dateRangeDaysKey(): string {
        return this.dateRangeDaysCount > 1 ? 'days' : 'day';
    }

    /**
     * Get the name of the site to display when selecting a site or a routing reference
     * @returns {string} selected site's name
     */
    get selectedSiteName(): string {
        if (!this.selectedItem) {
            return '';
        }
        return this.selectedItem.site || this.selectedItem.reference;
    }

    /**
     * Get the site name of the current displayed chart
     */
    get displayedSiteName(): string {
        if (!this.hasChartData) {
            this._displayedSiteName = '';
        } else {
            this._displayedSiteName = this.selectedSiteName || this._displayedSiteName;
        }

        return this._displayedSiteName;
    }

    //#endregion

    //#region utils
    /*************************
     * UTILS
     ************************/

    /**
     * Returns true if the current date object has value for date
     * @param {DatePicker} datePicker
     * @returns {boolean}
     */
    private isDatePropertySet(datePicker: DatePicker): boolean {
        return Boolean(datePicker.date && datePicker.utcDate);
    }

    /**
     * Returns true if values for from date and time are all set
     * @returns {boolean}
     */
    get isFromDateSet(): boolean {
        return this.isDatePropertySet(this.dateRange.fromDate);
    }

    /**
     * Returns true if values for to date and time are all set
     * @returns {boolean}
     */
    get isToDateSet(): boolean {
        return this.isDatePropertySet(this.dateRange.toDate);
    }

    /**
     * Returns true if both from and to dates & times are set
     * @return {boolean}
     */
    get isDateSet(): boolean {
        return Boolean(this.isFromDateSet && this.isToDateSet);
    }

    /**
     * Returns true if all data of filter are filled
     * @returns {boolean}
     */
    get isFilterComplete(): boolean {
        return Boolean(this.selectedItem && this.isDateSet);
    }

    /**
     * Returns true if all data of filter are incomplete
     * @returns {boolean}
     */
    get isFilterAllIncomplete(): boolean {
        return Boolean(!this.selectedItem && !this.isDateSet);
    }

    /**
     * Returns true if entity selected and no period (= no external data)
     * @returns {boolean}
     */
    get hasEntityNoPeriod(): boolean {
        return (
            Boolean(this.selectedItem) &&
            !Boolean(this.dateRangeLimit.minDate) &&
            !Boolean(this.dateRangeLimit.maxDate) &&
            !this.isEntityLoading
        );
    }

    /**
     * Returns true if entity selected is loaded and has data.
     * @returns {boolean}
     */
    get hasEntityPeriod(): boolean {
        return (
            Boolean(this.selectedItem) &&
            Boolean(this.dateRangeLimit.minDate) &&
            Boolean(this.dateRangeLimit.maxDate) &&
            !this.isEntityLoading
        );
    }

    /**
     * Returns entity type full text
     * @returns {string}
     */
    get entityTypeFullText(): string {
        return this.selectedItem.type === 'site' ? 'site' : 'PDL';
    }

    /**
     * Destroy timer
     */
    private destroyTimeout() {
        clearTimeout(this.setTimeoutSearchId);
        this.setTimeoutSearchId = null;
    }

    //#endregion
}
