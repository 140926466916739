import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from '../../../../models/question.interface';

let nextId = 0;

@Component({
    selector: 'ga-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
})
/**
 * Question component that all others question will extends.
 * Files are managed here as multiple components can upload files.
 */
export class QuestionComponent implements Question {
    @Input() data: any[];
    @Input() config: any;
    @Input() siteId: string;
    @Input() zoneId: string;
    @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() uploadFile: EventEmitter<any> = new EventEmitter<any>();

    // Unique identifier for the component at creation. So that each component's tag can have a unique id.
    // Created because of groups, without it, tags id were used multiple times.
    public uniqueId: number;

    // File object of the question. Set at init or after upload.
    public fileLoaded: { _id: string; originalname: string } = null;
    // Files uploaded. Only one accepted for now.
    private files: File[] = [];
    // Key of the file data dor DB.
    private fileKey: string = null;

    constructor() {
        this.uniqueId = nextId++;
    }

    /**
     * Get the column size for the questions label
     * @returns {string} width of 3/12 (md)
     */
    getQuestionColumnSize(): string {
        return 'col-md-3';
    }

    /**
     * Get the columns size for the answers.
     * @returns {string} width of 9/12 (md)
     */
    getAnswerColumnSize(): string {
        return 'col-md-9';
    }

    /**
     * Emit an event for the LegacyDataComponent to upload a file.
     * @param files: files to upload
     */
    fileUpload(files) {
        this.files = files;
        const uploadNotification = new EventEmitter<any>();
        uploadNotification.subscribe(f => {
            this.fileLoaded = f;
        });
        this.uploadFile.emit({
            key: this.fileKey,
            files: this.files,
            notif: uploadNotification,
        });
    }

    /**
     * Set the fileKey property.
     * @param fileKey: key of the file in DB.
     */
    protected setFileKey(fileKey) {
        this.fileKey = fileKey;
    }
}
