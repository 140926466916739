import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import Swal from 'sweetalert2';

import { UserPopulated } from 'app/shared/models/users.interface';
import { UsersService } from 'app/shared/services/users/users.service';
import { TextService } from 'app/shared/services/utils/text.service';
import { UserTypes } from '../../../constants/user-types-list.constants';
@Component({
    selector: 'ga-admin-users-list',
    templateUrl: './admin-users-list.component.html',
    styleUrls: ['./admin-users-list.component.scss'],
})
export class AdminUsersListComponent implements OnInit {
    private _usersList: UserPopulated[] = [];
    @Input()
    set usersList(list) {
        // Select or deselect all users according to the value of the checkbox "all"
        if (this.userSession && list) {
            this._usersList = list;
        }
    }

    get usersList() {
        return this._usersList;
    }

    private usersSelectedList: UserPopulated[] = [];

    @Input() userSession: any = null;
    @Input() redirection: string = null;
    @Input() isLoading = true;

    /** Default profile picture if user has no donwloaded picture */
    private defaultPictureUrl = 'assets/img/backgrounds/default-avatar.jpg';

    /** ID of the user for which the password is being reset */
    private resetUserId: string = null;

    /** SweetAlert options */
    private resetSwalOptions: any = {
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: "Oui, envoyer l'email",
        cancelButtonText: 'Annuler',
    };

    private userHovered: UserPopulated = null;

    @Output() searchInputChanged = new EventEmitter();
    public searchControl = new FormControl(); // needed to add a debounce time

    constructor(private usersService: UsersService, private textService: TextService) {}

    ngOnInit(): void {
        this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe({ next: newSearch => this.search() });
        this.searchControl.setValue('');
    }

    /**
     * Text for table rows
     * @param {boolean} isActive
     * @returns {string}
     */
    getStatusText(isActive: boolean): string {
        return isActive ? 'Actif' : 'Inactif';
    }

    /**
     * @param {string} firstname
     * @param {string} name
     * @returns {string}
     */
    getUserName(firstname: string, name: string): string {
        return name.toUpperCase() + ' ' + this.textService.toTitleCase(firstname);
    }

    /**
     * @param {UserPopulated} user
     * @returns {string}
     */
    getCompaniesText(user: UserPopulated): string {
        return !user.companies || !user.companies.length
            ? ''
            : this.hasMultipleCompanies(user)
            ? 'Multiple'
            : user.companies[0];
    }

    /**
     * Get the right text for the tooltip whether the user is active or not
     * @param {UserPopulated} user
     * @returns {string}
     */
    getTooltipText(user: UserPopulated): string {
        return user.active ? 'send_forgot_password_mail' : 'send_activate_mail';
    }

    /**
     * @param {UserPopulated} user
     * @returns {boolean}
     */
    hasMultipleCompanies(user: UserPopulated): boolean {
        return user.companies && user.companies.length > 1;
    }

    /**
     * @param {UserPopulated} user
     * @returns {string}
     */
    getPicture(user: UserPopulated): string {
        return user.logoUrl || this.defaultPictureUrl;
    }

    /**
     * Selection of the user
     * @param {string} userId
     * @returns {string}
     */
    getUserProfileLink(userId: string): string {
        return '/admin/utilisateur/' + userId + '/profil';
    }

    /**
     * @param {UserPopulated} user
     * @returns {void}
     */
    hoverUser(user: UserPopulated): void {
        this.userHovered = user;
    }

    /**
     * Returns appropriate placeholder depending on the redirection (`utilisateur` or `entreprise`)
     */
    get placeHolder() {
        if (this.redirection === 'utilisateur') {
            return 'Rechercher par prénom, nom, poste, e-mail, téléphone';
        } else if (this.redirection === 'entreprise') {
            return 'Rechercher par Nom, Adresse';
        }
    }

    /**
     * Reset password action
     * @param {UserPopulated} user
     * @returns {void}
     * */
    resetAction(user: UserPopulated): void {
        this.setSwalOptions(user);
        Swal.fire(this.resetSwalOptions).then(result => {
            if (result.value) {
                this.resetUserId = user._id;
                this.sendMail(user);
            }
        });
    }

    /**
     * Set SWAL options whether the user is active or not
     * @param {UserPopulated} user
     */
    setSwalOptions(user: UserPopulated) {
        if (user.active) {
            this.resetSwalOptions.title = 'Réinitialiser le mot de passe';
            this.resetSwalOptions.text =
                "Vous êtes sur le point d'envoyer un email de réinitialisation du mot de passe, êtes-vous certain(e) de vouloir effectuer cette action ?";
        } else {
            this.resetSwalOptions.title = 'Activation du compte';
            this.resetSwalOptions.text =
                "Vous êtes sur le point d'envoyer un email d'activation de compte, êtes-vous certain(e) de vouloir effectuer cette action ?";
        }
    }

    /**
     * Send activation mail or forgot password mail depend on the user status
     * @param {UserPopulated} user
     * @returns {Promise<void>}
     */
    async sendMail(user: UserPopulated): Promise<void> {
        try {
            if (!user.active) {
                await this.usersService.sendEmailActivation(user);
            } else {
                const email = user.contact.mail;
                await this.usersService.forgotPassword(email);
            }
            this.resetUserId = null;
        } catch (err) {
            this.resetUserId = null;
            this.sweetAlert(err.errorCode);
        }
    }

    /**
     * Display the icon only if the user is hovered + no user is currently selected.
     * Display the icon if mail is being sent to that user. Spin the icon when its being sent.
     * @param {UserPopulated} user
     * @return {string} classes
     */
    getResetIconClasses(user: UserPopulated): string {
        let classes = '';

        // Display if a mail is being sent to that user
        if (user._id === this.resetUserId) {
            classes += 'display table__column-spin';
        } else if (
            this.userHovered &&
            user._id === this.userHovered._id &&
            !this.hasUserSelected() &&
            this.canEdit(user)
        ) {
            // display only if it's hovered && no users are being selected && user has the right to do so
            classes += 'display ';
        } else {
            classes += 'display-none ';
        }

        return classes;
    }

    /**
     * Add the user to the selected users list if he's not there. Remove him from it otherwise.
     * @param {UserPopulated} user
     * @returns {void}
     */
    switchUserSelection(user: UserPopulated): void {
        const indexAlreadySelected = this.usersSelectedList.findIndex(u => u._id.toString() === user._id.toString());
        // if user was selected --> unselect him
        if (indexAlreadySelected !== -1) {
            this.usersSelectedList.splice(indexAlreadySelected, 1);
        } else {
            this.usersSelectedList.push(user);
        }
    }

    /**
     * Add to the selected users list all the users currently displayed in the table
     * (if there are not already in the list & if the user has the right to do so)
     * @returns {void}
     */
    selectAllUsers(): void {
        this.usersList.forEach(user => {
            if (this.canRemove(user) && !this.isAlreadySelected(user._id)) {
                this.usersSelectedList.push(user);
            }
        });
    }

    /**
     * Remove from the selected users all the users currently displayed in the list
     * @returns {void}
     */
    deselectAllUsers(): void {
        this.usersList.forEach(user => {
            if (this.canRemove(user) && this.isAlreadySelected(user._id)) {
                const indexAlreadySelected = this.usersSelectedList.findIndex(
                    u => u._id.toString() === user._id.toString()
                );
                this.usersSelectedList.splice(indexAlreadySelected, 1);
            }
        });
    }

    /**
     * Returns true if the user's id sent is already in the selected users list.
     * @param userIdToCompare
     * @returns {boolean}
     */
    isAlreadySelected(userIdToCompare): boolean {
        return this.usersSelectedList.some(u => u._id.toString() === userIdToCompare.toString());
    }

    /**
     * Returns true if all the users (which can be selected) are selected.
     * @return {boolean}
     */
    isEveryoneSelected(): boolean {
        return Boolean(
            this.usersList.length &&
                this.usersList.every(user => this.isAlreadySelected(user._id) || !this.canRemove(user))
        );
    }

    /**
     * Called when the selectbox "all" is checked or not
     * @returns {void}
     */
    onClickSelectAll() {
        if (!this.isEveryoneSelected()) {
            this.selectAllUsers();
        } else {
            this.deselectAllUsers();
        }
    }

    /**
     * Returns true if at least one user is selected
     * @returns {boolean}
     */
    hasUserSelected() {
        return this.usersSelectedList && this.usersSelectedList.length;
    }

    /**
     * Returns the number of users selected
     * @returns {string}
     */
    displayNbUsersSelected(): string {
        if (this.hasUserSelected()) {
            const count = this.usersSelectedList.length;
            return count.toString() + (count > 1 ? ' utilisateurs sélectionnés' : ' utilisateur sélectionné');
        }
    }

    /**
     * Delete all the users selected
     * @returns {void}
     */
    deleteUsers(): void {
        if (this.usersSelectedList && this.usersSelectedList.length) {
            Swal.fire({
                title: 'Êtes-vous sûr(e) ?',
                text: `Supprimer un utilisateur est une action irréversible.`,
                icon: 'warning',
                showCancelButton: true,
            }).then(result => {
                if (result.value) {
                    // delete all the users selected & refresh the list
                    this.usersService
                        .deleteUsers(this.usersSelectedList.map(u => u._id))
                        .then(res => {
                            if (res && res.code === 200) {
                                this.sweetAlert('users_deleted');

                                this.updateSelectedListAfterRemove(res.data);
                            } else {
                                this.sweetAlert('error_delete_users');
                            }
                            this.search();
                        })
                        .catch(err => {
                            // err.errorCode is set to error_delete_users
                            this.sweetAlert(err.errorCode);
                            // err.message = [{id: string, success: true/false}] --> true if was successfully removed, false if something wrong happened
                            // keep all the users that weren't deleted
                            this.search();
                        });
                }
            });
        }
    }

    /**
     * Called once the DELETE function has returned.
     * Update the list of the selected users according to the results.
     * For each user id, a boolean "success" indicates if the removing was successful
     * @param Array<{string} _id, {boolean} success }> results
     * @returns {void}
     */
    updateSelectedListAfterRemove(results: [{ _id: string; success: boolean }]): void {
        results.forEach(userRemoved => {
            if (userRemoved.success) {
                const indexAlreadySelected = this.usersSelectedList.findIndex(
                    u => u._id.toString() === userRemoved._id.toString()
                );
                this.usersSelectedList.splice(indexAlreadySelected, 1);
            }
        });
    }

    /**
     * Emit search from the input research field
     * @returns {void}
     */
    search(): void {
        if (!this.isLoading) {
            this.searchInputChanged.emit(this.searchControl.value);
        }
    }

    /**
     * @returns {boolean}
     */
    canRemoveAtLeastOne(): boolean {
        return this.usersList.some(user => this.canRemove(user));
    }

    /**
     * @param {user} user
     * @returns {boolean}
     */
    canRemove(user: UserPopulated): boolean {
        return this.canEdit(user) && this.userSession._id !== user._id;
    }

    /**
     * Set the rules to accept or not the editing action: reset pwd & delete
     * if it's the line of the user logged in -> can edit
     * if user = admin -> can edit
     * if user = energy manager -> can edit if he is one of his clients
     * if user = useradmin -> can edit if he is one of his standard users
     * if user = standard user -> can not edit
     * @param {UserPopulated} user
     * @return {boolean}
     */
    canEdit(user: UserPopulated): boolean {
        // if it's the line of the user logged in -> can edit
        if (this.userSession._id === user._id) {
            return true;
        } else if (this.userSession.type === UserTypes.ADMIN) {
            // if user = admin -> can edit
            return true;
        } else if (
            (this.userSession.type === UserTypes.ENERGY_MANAGER &&
                isRelated(user, this.userSession) &&
                [UserTypes.USER, UserTypes.USER_ADMIN].includes(user.type)) ||
            (this.userSession.type === UserTypes.USER_ADMIN && user.type === UserTypes.USER)
        ) {
            // if user = energy manager -> can edit if he is one of his clients
            // if user = useradmin -> can edit if he is one of his standard users
            return true;
        } else {
            return false;
        }

        function isRelated(userToCompare: UserPopulated, userSession: UserPopulated): boolean {
            // Companies are set after objects creation so they can be undefined
            if (!userToCompare.companies || !userSession.companies) {
                return false;
            }
            return userToCompare.companies.some(userCompany => userSession.companies.includes(userCompany));
        }
    }

    /**
     * Alerts for errors
     * @param {string} errorCode
     **/
    sweetAlert(errorCode: string) {
        const messages = {
            no_email_provided: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'envoi du mail de réinitialisation.",
                'error',
            ],
            user_not_found: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'envoi d'activation du compte.",
                'error',
            ],
            users_deleted: ['Supprimés', 'Tous les utilisateurs ont bien été supprimés', 'success'],
            error_delete_users: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la suppression des utilisateurs.',
                'error',
            ],
            invalid_token: [
                'Toutes nos excuses',
                "Il semblerait que le token de validité de l'utilisateur soit invalide ou ait expiré.",
                'error',
            ],
            internal_error: [
                'Toutes nos excuses',
                'Une erreur est survenue. Veuillez réessayer ultérieurement.',
                'error',
            ],
        };
        if (errorCode) {
            Swal.fire(messages[errorCode][0], messages[errorCode][1], messages[errorCode][2]);
        }
    }

    /**
     * @returns {string}
     */
    getIconCursorClass(): string {
        return this.hasUserSelected() ? 'cursor-pointer' : 'cursor-default';
    }

    /**
     * Check if the users list has data
     * @returns {boolean}
     */
    hasData(): boolean {
        return this.usersList.length > 0;
    }
}
