import { Injectable } from '@angular/core';

import { QueryFilter } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { RoutingRefGlobalCompleteness } from 'app/shared/models/bills.interface';

import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

/** Order of completeness properties to display */
export const propertiesOrder: string[] = ['furniture', 'cspe', 'cta', 'turpe', 'ticgn', 'global'];

@Injectable()
export class CompletenessService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get bills completeness per routing reference per date
     * @param {QueryFilter} filters
     * @returns {Promise<RoutingRefGlobalCompleteness>}
     */
    public async getRoutingReferencesCompleteness(filters: QueryFilter): Promise<RoutingRefGlobalCompleteness> {
        try {
            const result = await this.post('/api/completeness/billsCompleteness', filters);
            return result.data;
        } catch (e) {
            e.error_code = 'error_getBillsCompleteness';
            throw e;
        }
    }
}
