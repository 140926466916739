import { Component, OnInit, ViewChild } from '@angular/core';
import { ScenarioFilter } from 'app/shared/components/alerts/management-filter/alerts-mgmt-filter.component';
import { Scenario, ScenarioDisplay } from 'app/shared/models/scenario.model';
import { AlertsService } from 'app/shared/services/alerts/alerts.service';

import { NotificationsService } from 'app/shared/services/notifications/notifications.service';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Component({
    selector: 'ga-alerts-mananagement',
    templateUrl: './alerts-management.component.html',
    styleUrls: ['./alerts-management.component.scss'],
    providers: [],
})
export class AlertsManagementComponent implements OnInit {
    /**
     * List of all scenarios for the user and company
     */
    scenarios: ScenarioDisplay[] = [];

    /**
     * List of scenarios to display
     */
    scenariosDisplay: ScenarioDisplay[] = [];

    /**
     * Scenarios filters from filter component
     */
    scenarioFilter: ScenarioFilter = {
        scenario: null,
        status: null,
    };

    /**
     * Scenario object for modal
     */
    scenarioEdit: ScenarioDisplay;

    /**
     * Is scenario list loading
     */
    isLoading = false;

    /**
     * Does the scenario edition modal need to be in readonly
     */
    isScenarioReadOnly = false;

    /**
     * Creation/Edit modal
     */
    @ViewChild('modalEl', { static: true }) modal: ModalDirective;

    constructor(private alertsService: AlertsService, private notificationsService: NotificationsService) {}

    ngOnInit() {
        this.init();
    }

    private async init() {
        try {
            this.isLoading = true;
            const scenarios = await this.alertsService.getScenarios();
            this.scenarios = scenarios;
            this.filterList();
        } catch (err) {
            this.handleErrorMessage(err.errorCode);
        }
        this.isLoading = false;
    }

    /**
     * Filter list by filter event
     * @param {ScenarioFilter} filter
     */
    filterChange(filter: ScenarioFilter) {
        if (filter) {
            this.scenarioFilter = filter;
            this.filterList();
        }
    }

    /**
     * Filter scenario display list
     */
    private filterList() {
        this.scenariosDisplay = this.scenarios.filter(scenario => {
            if (this.scenarioFilter.scenario !== null) {
                return Boolean(this.scenarioFilter.scenario._id === scenario._id);
            }

            if (this.scenarioFilter.status !== null) {
                return Boolean(this.scenarioFilter.status === !scenario.flagMute);
            }

            return true;
        });
    }

    /** Error message management */
    /**
     * Open a swal with the error explained
     * @param {string} errorCode - error code
     */
    handleErrorMessage(errorCode: string) {
        let msg: [string, string, SweetAlertIcon];
        const mapping: { [key: string]: [string, string, SweetAlertIcon] } = {
            test: ['test', 'test', 'error'],
        };
        msg = mapping[errorCode];
        if (!msg) {
            msg = ['Une erreur est survenue', 'Merci de réessayer ultérieurement', 'error'];
        }
        Swal.fire(msg[0], msg[1], msg[2]);
    }

    /** Modal actions management */

    openCreate() {
        this.scenarioEdit = null;
        this.isScenarioReadOnly = false;
        this.modal.show();
    }

    openView(scenario: ScenarioDisplay) {
        this.scenarioEdit = _.cloneDeep(scenario);
        this.isScenarioReadOnly = true;
        this.modal.show();
    }

    openEdit(scenario: ScenarioDisplay) {
        this.scenarioEdit = _.cloneDeep(scenario);
        this.isScenarioReadOnly = false;
        this.modal.show();
    }

    onEditionDone(isSaved: boolean) {
        if (isSaved) {
            this.init();
        }
        this.modal.hide();
    }

    /**
     * Archive a list of scenarios
     * @param {string[]} scenarioIds - ids of the scenarios to archive
     */
    async archive(scenarioIds: string[]) {
        try {
            /**
             * Ask for confirmation
             */
            const result = await Swal.fire({
                title: 'Êtes-vous sûr ?',
                text: `Vous êtes sur le point de supprimer ${scenarioIds.length} alerte(s), et toutes les notifications associées.`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Annuler',
                confirmButtonText: 'Supprimer',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    await Promise.all(
                        scenarioIds.map(id => {
                            return this.alertsService.archiveScenario(id);
                        })
                    );
                },
                allowOutsideClick: () => !Swal.isLoading(),
            });
            if (!result.value) {
                return;
            }
            this.notificationsService.refresh();
            this.init();
        } catch (e) {
            this.handleErrorMessage(e.errorCode);
        }
    }
}
