import { CommunicatingSummary } from 'app/shared/models/routing-reference.interface';

export enum COMMUNICATING_COLOR_LABEL {
    COMMUNICATING = 'rref_communicating_communicating',
    NOT_COMMUNICATING = 'rref_communicating_not_communicating',
    CAN_COMMUNICATE = 'rref_communicating_can_communicate',
    INVALID_COMMUNICATING = 'rref_communicating_invalid_communicating',
}

type ConditionFunction = (communicatingSummary: CommunicatingSummary) => boolean;
export interface ConditionFunctions {
    inWarrant: ConditionFunction;
    canSync: ConditionFunction;
    synchronized: ConditionFunction;
    communicating: ConditionFunction;
    communicatingAvailable: ConditionFunction;
    communicatingUndetermined: ConditionFunction;
}

export interface StatusConfig {
    conditions: Array<{ fct: ConditionFunction; expected: boolean }>;
    colorLabel: COMMUNICATING_COLOR_LABEL;
    text: string;
    getTooltip: (communicatingSummary: CommunicatingSummary) => string;
    getAccessToLoadCurve: (communicatingSummary: CommunicatingSummary) => boolean;
}

export interface RoutingReferenceCommunicatingStatus {
    text: string;
    color: string;
    tooltip: string;
    accessToLoadcurve: boolean;
}
