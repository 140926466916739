import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../../shared/shared.module';
import { CotisationComponent } from './cotisation.component';

const COTISATION_ROUTE = [{ path: '', component: CotisationComponent }];

@NgModule({
    declarations: [CotisationComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(COTISATION_ROUTE)],
})
export class CotisationModule {}
