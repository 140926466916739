import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { RulesComponent } from './rules.component';

const RULES_ROUTE = [{ path: '', component: RulesComponent }];

@NgModule({
    declarations: [RulesComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(RULES_ROUTE)],
})
export class RulesModule {}
