import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-pdl-bloc-contract',
    templateUrl: './bloc-contract.component.html',
    styleUrls: ['./bloc-contract.component.scss'],
})
export class PdlBlocContractComponent implements OnInit {
    @Input() contract: any = {};
    @Input() routingReference: any = {};
    constructor() {}
    ngOnInit() {}
}
