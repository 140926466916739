import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

import { ColorService } from 'app/shared/services/color/color.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { ExternalDataService } from 'app/shared/services/external-data/external-data.service';
import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

import {
    ExternalAssociationsLocalType,
    ExternalAssociationsType,
} from 'app/shared/models/external-associations.interface';

import { Entity, ExternDataInfo, ProviderItem, SwitchFilterEntity } from './external-data-assignations.interface';

@Component({
    selector: 'ga-company-loading-curves-tab',
    templateUrl: './company-loading-curves.component.html',
    styleUrls: ['./company-loading-curves.component.scss'],
    providers: [ExternalDataService],
})
/**
 * @todo refacto entity part as it's not for all providers (only ga for now)
 */
export class CompanyLoadingCurvesComponent implements OnInit {
    @Input() company: any;

    // List of available providers
    providersList: ProviderItem[] = [
        {
            name: 'Green Analyzer',
            value: 'ga',
            selected: false,
            onlyCompany: false,
        },
        {
            name: 'Enedis',
            value: 'enedis',
            selected: true,
            onlyCompany: true,
        },
    ];

    // List of entity types
    entityTypes: SwitchFilterEntity[] = [
        {
            displayName: 'Sites',
            value: 'site',
            items: [],
            propertiesToCheck: ['complement', '_id', 'streetName', 'city', 'zipcode'],
        },
        {
            displayName: 'Zones',
            value: 'zone',
            items: [],
            propertiesToCheck: ['name', '_id', 'streetName', 'city', 'zipcode', 'siteName'],
        },
        {
            displayName: 'PDL',
            value: 'routingreference',
            items: [],
            propertiesToCheck: ['reference', '_id', 'address.streetName', 'address.city', 'address.zipcode'],
        },
    ];
    entityType: SwitchFilterEntity = this.entityTypes[0];

    // List of fluids available for the company
    fluidList: Array<{ name: string; value: string }> = [];

    // Id of the site whhich the PDL is over while dragging
    dragOverEntity: string = null;

    // Site information properties
    /** Selected entity by the user by click or drop of a external data */
    private _selectedEntity: Entity;
    public set selectedEntity(entity: Entity) {
        this.externalDataList = [];
        if (this.entityType) {
            this._selectedEntity = entity;
            this.externalDataService
                .getEntityExternalAssociations(entity._id, this.entityType.value, 'ga')
                .then(associations => {
                    if (!associations) {
                        return [];
                    }
                    const promises = associations.map(async association => {
                        const dataInfo = {
                            title: 'NC',
                            subtitle: '',
                            startDate: null,
                            endDate: null,
                            type: association.type,
                            sourceData: association.sourceData,
                            _id: association._id,
                            loaded: false,
                        };
                        this.externalDataList.push(dataInfo);
                        return this.externalDataService.getExternalDataInformation(dataInfo);
                    });
                    return Promise.all(promises);
                })
                .catch(() => {
                    this.swalError('du chargement de la donnée externe');
                });
        }
    }
    public get selectedEntity(): Entity {
        return this._selectedEntity;
    }

    // External data associated to the selected entity
    externalDataList: ExternDataInfo[] = [];

    // Sites's routing references information to display (colors, count).
    // Each site _id is a key
    entityDisplayInfo: any = {};
    // Limits for the display of sites and routing references
    limits = {
        entities: 10,
    };
    // List of sites to display
    entitiesToDisplay: Entity[] = [];

    // Search filters on routing reference and site columns
    search = {
        entity: '',
    };

    // Boolean indicating if a saving action in running (link)
    isSaving = false;

    // Loading informations for display.
    loading = {
        pdls: true,
        sites: true,
        fluids: true,
    };

    constructor(
        private energyService: EnergyService,
        private colorService: ColorService,
        private sitesService: SitesService,
        private routingReferencesService: RoutingReferencesService,
        private externalDataService: ExternalDataService
    ) {}

    ngOnInit() {
        this.getEntitiesList();
    }

    async getEntitiesList() {
        this.loading.sites = true;
        this.loading.pdls = true;

        await this.getSetFluids();

        this.sitesService
            .getSites({ companyId: this.company._id })
            .then(response => {
                const sitesList = this.entityTypes.find(x => x.value === 'site');
                const zonesList = this.entityTypes.find(x => x.value === 'zone');
                if (!response.data || !sitesList || !zonesList) {
                    return Promise.reject({ errorCode: 'no_sites_loaded' });
                }
                sitesList.items = [];
                zonesList.items = [];

                const sitesPopulated = response.data;
                sitesPopulated.forEach(site => {
                    const type: ExternalAssociationsLocalType = 'site';
                    const entity: Entity = Object.assign(site, { type });
                    sitesList.items.push(entity);
                    if (site.info.diviser) {
                        site.info.batiments.forEach(zone => {
                            const pdls = zone.routingReferences
                                .map(rR => {
                                    return site.routingReferences.find(x => x._id === rR);
                                })
                                .filter(x => x);
                            zonesList.items.push(
                                Object.assign({}, zone, {
                                    routingReferences: pdls,
                                    streetNumber: site.streetNumber,
                                    streetName: site.streetName,
                                    zipcode: site.zipcode,
                                    city: site.city,
                                    siteName: site.complement || site.name,
                                    siteId: site._id,
                                    type: 'zone',
                                })
                            );
                        });
                    }
                });
                this.selectEntityType('site');
                this.loading.sites = false;
            })
            .catch(() => {
                const sitesList = this.entityTypes.find(x => x.value === 'site');
                if (sitesList) {
                    sitesList.items = [];
                }
                const zonesList = this.entityTypes.find(x => x.value === 'zone');
                if (zonesList) {
                    zonesList.items = [];
                }
                this.swalError('du chargement des sites et zones');
                this.loading.sites = false;
            });

        this.routingReferencesService
            .getRoutingReferencesPopulated({ companyId: this.company._id })
            .then(response => {
                const pdlsList = this.entityTypes.find(x => x.value === 'routingreference');
                if (!response || !pdlsList) {
                    return Promise.reject({ errorCode: 'no_routingreferences_loaded' });
                }
                pdlsList.items = response.map(pdl => {
                    const type: ExternalAssociationsLocalType = 'routingreference';
                    const entity: Entity = Object.assign(pdl, { type });
                    return entity;
                });
                this.loading.pdls = false;
            })
            .catch(() => {
                const pdlsList = this.entityTypes.find(x => x.value === 'routingreference');
                if (pdlsList) {
                    pdlsList.items = [];
                }
                this.swalError('du chargement des PDLs');
                this.loading.pdls = false;
            });
    }

    /*****************
     * FILTERS COLUMN (PDL & SITES)
     *****************/

    /**
     * Get and set fluids available for a customer
     * @returns {Promise<string[]>}
     */
    private async getSetFluids(): Promise<string[]> {
        try {
            const fluids = await this.energyService.getFluidsAvailable(this.company._id);
            if (fluids && fluids.length) {
                this.fluidList = fluids.map(fluid => {
                    return {
                        name: this.energyService.energyFullText(fluid),
                        value: fluid,
                    };
                });
                this.loading.fluids = false;
            }
            return fluids;
        } catch (e) {
            this.swalError('du chargement des fluides disponibles');
            this.fluidList = [];
            this.loading.fluids = false;
        }
    }

    /**
     * Filter entities per value
     * @param {string} value - search criteria
     */
    searchEntity(value: string) {
        this.search.entity = value;
        this.filterEntities();
    }

    /**
     * Apply all filters to the entity list
     * @param {boolean} isReset reset the entity list (for example in research true, display more false)
     */
    private filterEntities(isReset: boolean = true) {
        // Reset the entity list
        if (isReset) {
            this.entitiesToDisplay = [];
        }
        // Split the search in multiple words to check each one instead of all of them
        const values = this.search.entity.toLocaleLowerCase().split(' ');
        // Properties of the entities to check in research
        const propertiesToCheck = this.entityType.propertiesToCheck;
        // Function to access embed properties
        const getProp = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj);
        // Add the right number of entities to the display list
        // Each one is the next one matching filters
        for (let i = this.entitiesToDisplay.length; i < this.limits.entities; i++) {
            const entity = this.entityType.items.find(x => {
                // Check if not already in the list
                if (this.entitiesToDisplay.some(y => x._id === y._id)) {
                    return false;
                }

                // Check from search input
                return propertiesToCheck.some(property => {
                    const val = getProp(x, property);
                    return val && this.checkMatch(val, values);
                });
            });
            if (entity) {
                this.entitiesToDisplay.push(entity);
            }
        }
    }
    /**
     * Check is a list of values matches a string
     * @param {string} property to check check the values from
     * @param {string[]} values list of values to test
     * @returns {boolean} true if any values matches, false otherwise
     */
    private checkMatch(property: string = '', values: string[] = []): boolean {
        for (const v of values) {
            if (property.toLocaleLowerCase().includes(v)) {
                return true;
            }
        }
        return false;
    }

    /**
     * Display more entities in the list.
     * @param {number} number of entities to display more. Default : 10
     */
    displayMoreEntities(number: number = 10) {
        this.limits.entities += number;
        this.filterEntities(false);
    }

    /**
     * Get entity bloc class.
     * @param {Entity} entity
     * @returns {string} 'drag-over' when hover while dragging, 'selected-site' when selected, empty otherwise
     */
    getEntityClass(entity: Entity): string {
        if (this.dragOverEntity === entity._id) {
            return 'drag-over';
        }
        if (this.selectedEntity && this.selectedEntity._id === entity._id) {
            return 'selected-entity';
        }
        return '';
    }

    /**
     * Set selected entity if not the same entity already selected
     * @param {Entity} entity to select
     */
    selectEntity(entity: Entity) {
        if (!this.selectedEntity || (this.selectedEntity && this.selectedEntity._id !== entity._id)) {
            this.selectedEntity = entity;
        }
    }

    /*****************
     *(DRAG &) DROP management
     *****************/

    /**
     * On drop of an external data to a entity, associate the external data to the entity
     * @param {DragEvent} event - data transferef must be at format : { externalId: any, type: string } (eg. {externalId: '123456789g1g4d5s6', type: 'ga'})
     * @param {Entity} entity
     */
    onDrop(event: DragEvent, entity: Entity) {
        event.preventDefault();
        const dataString = event.dataTransfer.getData('text/plain');
        const data = JSON.parse(dataString);

        this.linkExternalData(data, entity);
    }

    /**
     * Set the entity dragged over.
     * @param {DragEvent} event
     * @param {Entity} entity object of the entity dragged over
     */
    onDragEnter(event: DragEvent, entity: Entity) {
        event.preventDefault();

        this.dragOverEntity = entity._id;
    }

    /**
     * Unset the entity dragged over when leaving.
     * @param {DragEvent} event
     * @param {Entity} entity left. Unset only if the site is the one stored.
     */
    onDrageLeave(event: DragEvent, entity: Entity) {
        event.preventDefault();

        const id = entity._id;

        if (this.dragOverEntity === id) {
            this.dragOverEntity = null;
        }
    }

    /**
     * DO NOT REMOVE. ENABLE THE DROP.
     * @param {DragEvent} event
     */
    onDragOver(event: DragEvent) {
        event.preventDefault();
    }

    /*****************
     * COMPUTE ENTITIES DISPLAY INFO
     *****************/

    /**
     * Select an entity type to be associated to
     * @param {"sites"|"zones"|"routingreferences"} value - value of entity type selected
     */
    selectEntityType(value: 'site' | 'zone' | 'routingreference') {
        // TODO Load selected entity list of not already loaded
        this.entityType = this.entityTypes.find(x => x.value === value);
        this.computeEntitiesPdlInfo();
        this.filterEntities();
    }

    /**
     * Get entity search message for the filter
     */
    get searchEntityMessage() {
        let message = 'Rechercher';
        if (!this.entityType) {
            return message;
        }
        switch (this.entityType.value) {
            case 'site':
                message += ' un site';
                break;
            case 'zone':
                message += ' une zone';
                break;
            case 'routingreference':
                message += ' un PDL';
                break;
            default:
                break;
        }
        return message;
    }

    /**
     * Get title for the entity. Site name for sites, zone name for zones & reference for pdls
     * @param {Entity} entity - entity to get the title from
     * @returns {string} name of entity
     */
    getEntityTitle(entity: Entity): string {
        switch (entity.type) {
            default:
            case 'site':
                return entity.complement;
            case 'zone':
                return entity.siteName;
            case 'routingreference':
                return entity.reference;
        }
    }

    /**
     * Get subtitle for the entity. Nothing for sites and pdls, zone name for zones
     * @param {Entity} entity - entity to get the subtitle from
     * @returns {string} subtitle of entity
     */
    getEntitySubtitle(entity: Entity): string {
        switch (entity.type) {
            default:
            case 'site':
            case 'routingreference':
                return;
            case 'zone':
                return '> ' + entity.name;
        }
    }

    /**
     * Get entity address object root level.
     * @param {Entity} entity - entity to get the address root level from
     * @returns {*} address root object of entity
     */
    getEntityAddressRoot(entity: Entity): any {
        switch (entity.type) {
            default:
            case 'site':
            case 'zone':
                return entity;
            case 'routingreference':
                return entity.address;
        }
    }

    /**
     * Get the rgb color of a fluid. Return the color if the site has the fluid, default otherwise.
     * @param {string} fluid - fluid get the color from
     * @param {number} count - number of pdl in an entity
     * @returns {string} get rgb color string
     */
    private getFluidColor(fluid: string, count: number): string {
        const hasFluid = count > 0;
        if (hasFluid) {
            return this.colorService.getSecondRgbColor(fluid);
        }
        return;
    }
    /**
     * Get the number of pdls of a site for a given fluid.
     * @param {string} fluid to look for
     * @param {Entity} entity to get the count of pdls for the fluid
     * @returns {number}
     */
    private getEntityPdlCount(fluid: string, entity: Entity): number {
        if (this.entityType.value !== 'routingreference') {
            return this.sitesService.countRoutingReferencesOfFluid(entity, fluid);
        }
        return entity.energyType === fluid ? 1 : 0;
    }

    /**
     * Compute the PDLs information for a given entity.
     * As there is lot of entities displayed, it's calculated when user takes action
     * and not on the angular cycles
     * Objects of type: {
     *   color: string,
     *   count: number
     * }
     * @param {Entity} entity to compute the information of
     */
    private computeEntityPdlInfo(entity: Entity) {
        this.entityDisplayInfo[entity._id] = {};
        this.fluidList.forEach(fluid => {
            const count = this.getEntityPdlCount(fluid.value, entity);
            this.entityDisplayInfo[entity._id][fluid.value] = {
                color: this.getFluidColor(fluid.value, count),
                count,
            };
        });
    }

    /**
     * Compute the PDLS information for all entities for entity type selected.
     */
    private computeEntitiesPdlInfo() {
        this.entityType.items.forEach(entity => {
            this.computeEntityPdlInfo(entity);
        });
    }

    /**
     * @returns true if there is more entities to display, false otherwise
     */
    get hasMoreEntityToDisplay(): boolean {
        if (!this.entityType) {
            return false;
        }
        return this.entitiesToDisplay.length < this.entityType.items.length;
    }

    /**
     * @returns true is the selected entity list is loading, false otherwise.
     */
    get isEntityListLoading(): boolean {
        if (!this.entityType) {
            return true;
        }
        return this.entityType.value === 'routingreference' ? this.loading.pdls : this.loading.sites;
    }

    /*****************
     * ENTITY INFORMATION COLUMN
     *****************/

    /**
     * Get selected entity property
     * @param {string} property - property relative to generic entity
     * @returns {string} value fot he property
     */
    getEntitySelectedProperty(property: string): string {
        if (!this.selectedEntity) {
            return null;
        }

        if (property === 'title') {
            switch (this.selectedEntity.type) {
                default:
                case 'site':
                    return this.selectedEntity['complement'];
                case 'zone':
                    return this.selectedEntity['siteName'];
                case 'routingreference':
                    return this.selectedEntity['reference'];
            }
        }

        if (property === 'subtitle') {
            switch (this.selectedEntity.type) {
                default:
                case 'site':
                case 'routingreference':
                    return null;
                case 'zone':
                    return this.selectedEntity['name'];
            }
        }

        return this.selectedEntity[property];
    }

    /**
     * Get selected entity property
     * @param {string} property
     */
    getEntitySelectedAddressProperty(property: string) {
        if (!this.selectedEntity) {
            return null;
        }

        switch (this.selectedEntity.type) {
            default:
            case 'site':
            case 'zone':
                return this.selectedEntity[property];
            case 'routingreference':
                return this.selectedEntity.address[property];
        }
    }

    /**
     * Get the error message for the address.
     */
    get errorMessage() {
        return null;
    }

    /*****************
     * ENTITY INFORMATION EXTERNAL DATA LIST
     *****************/

    /**
     * Get the externalData value
     * @param {ExternDataInfo} externalData - external data to get the type of
     * @returns {string} external data value (eg. 'ga')
     */
    getExternalDataType(externalData: ExternDataInfo) {
        switch (externalData.type) {
            default:
            case 'ga':
                return 'ga-icon';
            /*case 'linky' :
                return "linky-icon";*/
            case 'enedis':
                return 'enedis-icon';
        }
    }

    /*****************
     * ASSOCIATE / DISSOCIATE EXTERNAL DATA
     *****************/

    /**
     * Link external data to an entity and update it when done.
     * @param {Object} data - external data
     * @param {Entity} entity - entity to link the external data to
     */
    async linkExternalData(data: any, entity: Entity) {
        try {
            this.isSaving = true;

            const entityType = entity.type;

            this.prepareDataEntity(entityType, data, entity);

            this.dragOverEntity = null;

            await this.externalDataService.associateExternalData(entityType, data);

            /**
             * Company can't be a selected entity (has its own assignation component)
             */
            if (entity.type !== 'company') {
                this.selectedEntity = entity;
            }
        } catch (e) {
            this.swalError("de l'association de la donnée");
        }

        this.isSaving = false;
    }

    /**
     * Remove an external data to the associated entity.
     * @param externalData
     */
    async unlinkExternalData(externalData: ExternDataInfo) {
        try {
            if (externalData && externalData.sourceData && externalData.type) {
                const entity = this.selectedEntity;
                const entityType = entity.type;
                const data: any = {
                    externalId: externalData.sourceData,
                    type: externalData.type,
                };

                this.prepareDataEntity(entityType, data, entity);

                await this.externalDataService.dissociateExternalData(entity.type, data, externalData._id);

                this.selectedEntity = entity;
            }
        } catch (e) {
            this.swalError('de la dissociation de la donnée');
        }
    }

    /**
     * Prepare data for request by filling with the correct id(s) depending on the entity type
     * @param {ExternalAssociationsLocalType} entityType - type of entity
     * @param {Object} data - data object to set the id(s)
     * @param {Entity} entity - entity object which
     */
    private prepareDataEntity(entityType: ExternalAssociationsLocalType, data: any, entity: Entity) {
        switch (entityType) {
            case 'site':
                data.site = entity._id;
                break;
            case 'zone':
                data.zone = entity._id;
                data.site = entity.siteId;
                break;
            case 'routingreference':
                data.routingreference = entity._id;
                break;
            case 'company':
                data.company = entity._id;
                break;
        }
    }

    /*****************
     * PROVIDERS LIST
     *****************/

    /**
     * Get provider class depending if it's selected or not.
     * @param {ProviderItem} provider - data provider to get the class of
     */
    getProviderClass(provider: ProviderItem): string[] {
        return provider.selected ? ['citron-background', 'provider__bloc--active'] : ['provider__bloc--inactive'];
    }

    /**
     * Actions when a provider is selected
     * @param {ProviderItem} provider - data provider selected
     */
    selectProvider(provider: ProviderItem) {
        this.providersList.forEach(p => {
            p.selected = p.value === provider.value;
        });
    }

    /**
     * Indicates if the provider passed is selected
     * @param {ExternalAssociationsType} value - data provider value to check selection
     * @returns {boolean} true if provider selected, false otherwise
     */
    isProviderSelected(value: ExternalAssociationsType): boolean {
        return this.providersList.some(p => p.selected && p.value === value);
    }

    /**
     * Does provider can only be associated to company and not entities
     * @returns {boolean} true if provider for company only, false otherwise
     */
    isSelectedProviderForCompanyOnly(): boolean {
        return this.providersList.some(p => p.selected && p.onlyCompany);
    }

    /*****************
     * UTILS
     *****************/

    swalError(message = 'du chargement') {
        Swal.fire(
            'Une erreur est survenue',
            'Une erreur est survenue lors ' + message + '.<br>Merci de réessayer ultérieurement.',
            'error'
        );
    }
}
