import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { MeteoStation } from 'app/shared/models/meteo-station.interface';
import { Site } from 'app/shared/models/site.interface';
import { ExportService } from 'app/shared/services/export/export.service';
import { MapService } from 'app/shared/services/map/map.service';
import { MeteoService } from 'app/shared/services/meteo/meteo.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';

import {
    BRExclusibleUses,
    DjuType,
    ExclusionTypes,
    HeatingPeriod,
    Monitoring,
} from '../operating-monitoring.interface';
import { OperatingMonitoringService } from '../operating-monitoring.service';
import { HeatingPeriodUpdate, MonitoringUpdate } from '../operating-monitoring.update.interface';

@Component({
    selector: 'ga-monitoring-header',
    templateUrl: './monitoring-header.component.html',
    styleUrls: ['./monitoring-header.component.scss'],
})
export class MonitoringHeaderComponent implements OnInit, OnDestroy {
    /** The monitoring actually selected */
    public monitoring: Monitoring;
    /** The monitoring's site info */
    public site: Site;
    /** The monitoring's meteo station info */
    public meteoStation: MeteoStation;
    /** The monitoring's period filters */
    public selectedPeriod: HeatingPeriod;
    /** The selected period new values */
    public editedPeriod: HeatingPeriodUpdate = {
        periodStart: null,
        periodEnd: null,
    };
    /** Period dates selection form */
    public editedPeriodForm: FormGroup;
    /** Period format */
    private readonly periodFormat = 'DD/MM/YYYY';
    /** Subscription to events */
    private subscription: Subscription;
    /** Get the start of first period to end of last one */
    get globalDates(): { periodStart: string; periodEnd?: string } {
        return {
            periodStart: this.monitoring.periods[this.monitoring.periods.length - 1].periodStart,
            periodEnd: this.monitoring.periods[0].periodEnd,
        };
    }
    /** Select the modal to show */
    public displayModalKey: 'add' | 'edit' | 'list' | null = null;

    /** Error messages to display if an heating period is not valid */
    public messagesInvalidPeriod: string[] = [];

    /** Track if user is exporting a monitoring */
    public isExporting = false;

    public djuSiteDisplayValue: number;

    constructor(
        private translateService: TranslateService,
        private mapService: MapService,
        private meteoService: MeteoService,
        private operatingMonitoringService: OperatingMonitoringService,
        private sitesService: SitesService,
        private exportService: ExportService
    ) {}

    ngOnInit() {
        this.editedPeriodForm = new FormGroup({
            start: new FormControl(''),
            end: new FormControl(''),
        });
        this.subscription = this.operatingMonitoringService.selectedMonitoring$.subscribe({
            next: value => this.setMonitoring(value),
        });
        this.subscription.add(
            this.operatingMonitoringService.selectedPeriod$.subscribe({
                next: value => (this.selectedPeriod = value),
            })
        );
        this.subscription.add(
            this.editedPeriodForm.valueChanges.subscribe({
                next: values => {
                    this.setEditedPeriod(values);
                    this.messagesInvalidPeriod = this.validatePeriod(this.editedPeriod);
                },
            })
        );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public getSitePictureAsBackground() {
        return this.mapService.getSitePictureAsBackground(this.site ? this.site : {});
    }

    public setSelectedPeriod(period: HeatingPeriod) {
        // Need to copy object to avoid changes somewhere else
        this.operatingMonitoringService.setSelectedPeriod(period ? { ...period } : null);
        this.displayModalKey = null;
    }

    public updatePeriodDates() {
        if (!this.invalidEditedPeriod) {
            const updateMonitoring: MonitoringUpdate = this.monitoring;
            this.displayModalKey = null;

            if (this.editedPeriod._id) {
                updateMonitoring.periods = updateMonitoring.periods.map(period => {
                    if (period._id === this.editedPeriod._id) {
                        return this.editedPeriod;
                    }
                    return period;
                });
            } else {
                updateMonitoring.periods.push(this.editedPeriod);
            }

            this.operatingMonitoringService.updateMonitoring(updateMonitoring);
        }
    }

    public addPeriod() {
        this.displayModalKey = this.displayModalKey === 'add' ? null : 'add';
        this.editedPeriod = { periodStart: null, periodEnd: null }; // reset last edited period before creating a new one
        this.setEditedPeriodForm(this.editedPeriod);
    }

    public editPeriod() {
        if (this.selectedPeriod) {
            this.displayModalKey = this.displayModalKey === 'edit' ? null : 'edit';
            this.editedPeriod = { ...this.selectedPeriod };
            this.setEditedPeriodForm(this.editedPeriod);
        }
    }

    private setEditedPeriodForm(editedPeriod: HeatingPeriodUpdate) {
        this.editedPeriodForm.patchValue({
            start: editedPeriod.periodStart ? moment.utc(editedPeriod.periodStart).format('YYYY-MM-DD') : '',
            end: editedPeriod.periodEnd ? moment.utc(editedPeriod.periodEnd).format('YYYY-MM-DD') : '',
        });
    }

    /**
     * Set edited period start and end.
     * Input values must be at format YYYY-MM-DD
     */
    private setEditedPeriod({ start, end }: { start: string; end: string }) {
        this.editedPeriod.periodStart = this.operatingMonitoringService.getPeriodDateISOString(start, 'start');
        this.editedPeriod.periodEnd = this.operatingMonitoringService.getPeriodDateISOString(end, 'end');
    }

    /**
     * Call monitoring service to validate the given period.
     * @param period Period to validate
     */
    public validatePeriod(period: HeatingPeriodUpdate): string[] {
        const messages: string[] = [];

        if (!this.operatingMonitoringService.isPeriodValid(period)) {
            messages.push(this.translateService._('monitoring_invalid_period'));
        }

        if (!this.operatingMonitoringService.isPeriodInContract(period, this.monitoring)) {
            const start = moment.utc(this.monitoring.contractStart).format(this.periodFormat);
            const end = moment.utc(this.monitoring.contractEnd).format(this.periodFormat);

            const messagePeriodOutsideContract = `${this.translateService._(
                'monitoring_period_must_be_inside_contract'
            )} : ${start} - ${end}`;

            messages.push(messagePeriodOutsideContract);
        }

        return messages;
    }

    /**
     * Returns true if the edited period has any validation error message
     */
    public get invalidEditedPeriod(): boolean {
        return Boolean(this.messagesInvalidPeriod.length);
    }

    private async setMonitoring(monitoring: Monitoring) {
        if (monitoring) {
            /** Period from the monitoring object to display */
            let monitoringPeriod: HeatingPeriod = null;

            // Fetch monitoring info for the first time or if a new one is loaded
            if (
                !this.monitoring ||
                this.monitoring._id !== monitoring._id ||
                this.monitoring.site._id !== monitoring.site._id
            ) {
                this.site = await this.sitesService.getSite(monitoring.site._id);
                this.meteoStation = await this.meteoService.getMeteoStation(this.site.meteoStation);
            }

            // Set new monitoring as the current one
            this.monitoring = monitoring;
            this.setDjuSiteValue(this.monitoring);
            // Select edited period from the monitoring object
            if (this.editedPeriod && this.editedPeriod.periodStart) {
                if (this.editedPeriod._id) {
                    monitoringPeriod = this.monitoring.periods.find(period => period._id === this.editedPeriod._id);
                } else {
                    const editedPeriodStart = new Date(this.editedPeriod.periodStart).toISOString();
                    // periodEnd can be null
                    const editedPeriodEnd = this.editedPeriod.periodEnd
                        ? new Date(this.editedPeriod.periodEnd).toISOString()
                        : null;

                    monitoringPeriod = this.monitoring.periods.find(
                        period => period.periodStart === editedPeriodStart && period.periodEnd === editedPeriodEnd
                    );
                }
                this.setSelectedPeriod(monitoringPeriod);
            }

            // Reset edited period to prevent it selecting a non existant period when loading a new monitoring
            this.editedPeriod = { periodStart: null, periodEnd: null };

            // Sort current monitoring periods in case order has changed
            this.monitoring.periods.sort((a, b) => b.periodStart.localeCompare(a.periodStart));
        } else if (this.operatingMonitoringService.isLastActionDelete) {
            this.monitoring = null;
            this.site = null;
            this.selectedPeriod = null;
        }
    }

    public openReadings() {
        this.operatingMonitoringService.toggleEditReadingsModal(true);
    }

    public editMonitoring() {
        this.operatingMonitoringService.editedMonitoring$.next(this.monitoring);
        this.operatingMonitoringService.toggleEditionMonitoringModal(true);
    }

    public async deleteMonitoring() {
        const confirm = await Swal.fire({
            title: this.translateService._('monitoring_deletion') + this.monitoring.name,
            text: this.translateService._('monitoring_delete_monitoring'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translateService._('yes_delete'),
            cancelButtonText: this.translateService._('cancel'),
        });

        if (confirm.value) {
            this.operatingMonitoringService.deleteMonitoring(this.monitoring);
        }
    }

    /** Indicates if reading btn must be displayed or not */
    public get displayReadingBtn(): boolean {
        const hotWaterUse = this.monitoring.uses.find(x => x.use === BRExclusibleUses.HOT_WATER);
        if (!hotWaterUse) {
            return false;
        }
        return hotWaterUse.exclusionType === ExclusionTypes.INDEX_READING;
    }

    /************************
     * Excel export methods *
     ************************/

    get exportIconClass() {
        return this.isExporting ? 'citron-icon rotating' : 'excel-icon';
    }

    /**
     * Get and save export file of given monitoring.
     * @param monitoring Monitoring to export
     */
    public getExportFile(monitoring: Monitoring): void {
        const exportFilename = this.getExportFilename(monitoring.name);
        const exportUrl = this.getExportUrl(monitoring._id);

        this.isExporting = true;

        this.exportService.getFile(exportUrl).subscribe({
            next: file => {
                saveAs(file, exportFilename);
                this.isExporting = false;
            },
            error: err => {
                this.isExporting = false;
                Swal.fire({
                    title: this.translateService._('error_we_are_sorry'),
                    text: this.translateService._('error_monitoring_while_exporting'),
                    icon: 'error',
                });
            },
        });
    }

    /**
     * Get export URL with monitoring ID.
     * @param monitoringId ID of the monitoring to export/
     */
    private getExportUrl(monitoringId: string): string {
        return `/api/operating-monitoring/export/excel/${monitoringId}`;
    }

    /**
     * Get export filename with monitoring name and today date.
     * @param monitoringName Name of the monitoring to export
     */
    private getExportFilename(monitoringName: string): string {
        const date = moment()
            .format('DDMMYYYY')
            .toString();

        return `suivi d'exploitation - ${monitoringName} - ${date}.xlsx`;
    }

    private setDjuSiteValue(monitoring): void {
        this.djuSiteDisplayValue =
            monitoring.djuType === DjuType.COLD
                ? this.site.dju.temperatureReferenceCold
                : this.site.dju.temperatureReference;
    }
}
