import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'data-tile-5',
    templateUrl: './data-tile-5.component.html',
    styleUrls: ['./data-tile-5.component.scss'],
})
export class DataTile5Component implements OnInit {
    @Input() label: string;
    @Input() unit: string;
    @Input() value: number;

    /**
     * subUnit is a label for subValue property.
     */
    @Input() subUnit?: string;

    /**
     * subValue is a property for secondary stats, next to the initial value-unit pair. It appear as less important stat number on the front.
     */
    @Input() subValue?: number;
    @Input() color: string;
    @Input() type: string;
    @Input() digits = 0;

    /**
     *  selected indicates if the given Tile has been selected as a filter
     */
    @Input() selected = false;

    constructor() {}
    ngOnInit() {}

    showSubValue(): boolean {
        return typeof this.subValue === 'number';
    }

    /**
     * Returns dynamically assign Tile classes and/or selected Tile classes depending on input type
     * @param {string} type Type of element to get classes for
     * @returns {string[]} array of css classes
     */
    public getTileClasses(type: string): string[] {
        const classes: string[] = [];

        if (type === 'icon') {
            classes.push('svg-icon');
            classes.push(this.type + '-icon');

            if (this.selected) {
                classes.push('icon-selected');
            }
        } else if (type === 'container' && this.selected) {
            classes.push(this.type + '-background-80p');
            classes.push('tile-selected');
        }

        return classes;
    }
}
