import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { modalConfigDefaults } from 'ngx-bootstrap/modal/modal-options.class';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-input-modal',
    templateUrl: './input-modal.component.html',
    styleUrls: ['./input-modal.component.scss'],
})
export class InputModalComponent implements OnInit {
    inputModel: string;
    swalOptions: any = {
        text: 'will be replaced with content in swal component', // THIS IS IMPORTANT KEEP IT AS IT OR CONTENT WONT BE SHOWN
        showCancelButton: true,
    };

    @Input() value: any;
    @Input() error: string;
    @Input() title: string;
    @Input() text: string;
    @Input() inputType = 'text';
    @Input() placeholder: string;
    @Input() cancelBtnText = 'Annuler';
    @Input() confirmBtnText = 'Enregistrer';

    @Output() confirm: EventEmitter<any> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() open: EventEmitter<any> = new EventEmitter();

    @ViewChild('modal', { static: true }) private modal: SwalComponent;
    @ViewChild('inputTextGroupName', { static: false }) el: ElementRef;

    constructor(public readonly swalTargets: SwalPortalTargets) {}

    ngOnInit() {}

    onKeyPressed(event) {
        // If key pressed is "Enter", confirm
        if (event && event.keyCode === 13) {
            this.sendValue();
            Swal.close();
            this.emitCloseEvent(event);
        }
    }

    show() {
        this.modal.fire();
    }

    emitOpenEvent(event) {
        this.el.nativeElement.focus();
        this.open.emit(event);
    }

    emitCloseEvent(event) {
        this.close.emit(event);
    }

    sendValue() {
        this.confirm.emit(this.inputModel);
        this.inputModel = '';
    }
}
