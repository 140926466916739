import { Component, Input, OnInit } from '@angular/core';
import { Level, VerificationConfig, VerificationItem } from 'app/shared/models/bill-verifications.interface';
import { BillVerification } from 'app/shared/models/bills.interface';
import { BillsVerificationsService } from 'app/shared/services/bills/bills-verifications.service';

@Component({
    selector: 'ga-bill-verification-pill',
    templateUrl: './bill-verification-pill.component.html',
    styleUrls: ['./bill-verification-pill.component.scss', '../../bill-verification.scss'],
    providers: [],
})
export class BillVerificationPillComponent implements OnInit {
    @Input() verifications: BillVerification[] = [];
    @Input() isMultiHeader = false;
    @Input() isPopoverVisible = true;

    /**
     * Level of verification to display
     * 0: grey -> no info
     * 1: green -> good
     * 2: orange -> medium
     * 3: red -> bad
     */
    verificationLevel: Level = 0;

    verificationsAnalyzed: VerificationItem[] = [];

    constructor(private billsVerificationService: BillsVerificationsService) {}

    ngOnInit() {
        this.computeGeneralLevel();
    }

    /**
     * Compute the general level for the verification.
     * The general level is the one with the highest priority.
     * If two have the same priority level, then it will take the one with the highest level (= verification with the worst result).
     */
    private computeGeneralLevel() {
        if (!this.verifications) {
            return;
        }
        // Find all levels { key: string, level: number, priority: number }[]
        const levels: VerificationItem[] = this.verifications
            .map(verification => {
                // Find definition in map table
                const definition = this.billsVerificationService.getConfigByKey(verification.key);

                if (!definition) {
                    return null;
                }

                if (typeof verification.ratio === 'number') {
                    verification.ratio = Math.round(verification.ratio * 1e1) / 1e1;
                }
                // Get corresponding entry
                const results: VerificationItem[] = definition.items
                    .reduce((memo: VerificationItem[], e) => {
                        const res = e.validate(verification);
                        // Only keep matching results
                        if (res === true) {
                            memo.push({
                                key: verification.key,
                                level: e.level,
                                priority: e.priority,
                                message: definition.message,
                                value: definition.valueMsg(verification),
                                elements: definition.elements(verification),
                            });
                        }
                        return memo;
                        // Sort by priority if multiple, should not happen.
                    }, [])
                    .sort((a, b) => b.priority - a.priority);
                // If nothing match, set a not verified option.
                if (!results.length) {
                    const noInfoItem: VerificationItem = {
                        key: verification.key,
                        level: 0,
                        priority: 0,
                        message: definition.message,
                        value: definition.valueMsg(verification),
                        elements: definition.elements(verification),
                    };
                    return noInfoItem;
                }
                return results[0];
            })
            .filter(x => x);
        // Sort by max priorities and worst first
        levels.sort((a, b) => {
            if (a.priority !== b.priority) {
                return b.priority - a.priority;
            }
            return b.level - a.level;
        });
        // Get item with max priority and worst.
        // Set as the global verification level
        if (levels.length) {
            const worstVerification = levels[0];
            this.verificationLevel = worstVerification.level;
            this.verificationsAnalyzed = levels;
        } else {
            this.verificationLevel = 0;
            this.verificationsAnalyzed = [];
        }
    }

    get pillColorClass(): string {
        return 'verification-' + this.verificationLevel;
    }
}
