import { Injectable } from '@angular/core';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { ApiService } from '../api/api.service';
import { CompaniesService } from '../companies/companies.service';
import { PageService } from '../page/page.service';
import { SessionService } from '../session/session.service';
import { PagesList } from './rights.interface';

@Injectable()
export class RightsService extends PageService {
    company: any;
    actions: any = {
        removePdl: [UserTypes.ADMIN, UserTypes.ENERGY_MANAGER], // For example
        uploadAndExtract: [UserTypes.ENERGY_MANAGER, UserTypes.USER, UserTypes.USER_ADMIN], // For example
    };

    constructor(
        public apiService: ApiService,
        private sessionService: SessionService,
        private companyService: CompaniesService
    ) {
        super(apiService);
    }

    isInge() {
        return this.sessionService.getUser().type === UserTypes.ENERGY_MANAGER;
    }

    isUser() {
        return this.sessionService.getUser().type === UserTypes.USER;
    }

    isAdmin() {
        return this.sessionService.getUser().type === UserTypes.ADMIN;
    }

    isUserAdmin() {
        return this.sessionService.getUser().type === UserTypes.USER_ADMIN;
    }

    hasRightAs(type) {
        let response = false;

        switch (type) {
            case UserTypes.USER:
                response = this.isUser();
                break;

            case UserTypes.ENERGY_MANAGER:
                response = this.isInge();
                break;

            case UserTypes.ADMIN:
                response = this.isAdmin();
                break;

            case UserTypes.USER_ADMIN:
                response = this.isUserAdmin();
                break;

            default:
                break;
        }

        return response;
    }

    hasRightOn(action) {
        return this.actions[action].includes(this.sessionService.getUser().type);
    }

    /**
     * Get list of all the pages and their URL aggregated for the rights
     * @returns {Promise<PagesList>}
     */
    async getPagesListURLs(): Promise<PagesList> {
        try {
            const response = await this.get('/api/rights/pages');
            return response.data;
        } catch (e) {
            e.error_code = 'error_getListPages';
            throw e;
        }
    }

    /**
     * Save user's right for a company. If not in company yet, add it to the company.
     * @param {string} companyId - company's ID
     * @param {string} userId - user's ID
     * @param {*} rights - rights object
     * @param {string} list - name of list to add the user to. Currently support 'viewer'. Otherwise will add it to the main user list.
     */
    async saveRights(companyId: string, userId: string, rights = null, list?: string): Promise<any> {
        try {
            const data: any = {
                rights,
            };
            if (typeof list === 'string' && list === 'viewers') {
                data.viewer = true;
            }
            const route = '/api/rights/company/' + companyId + '/user/' + userId;
            const res = await this.post(route, data);
            return res.data;
        } catch (err) {
            err.error_code = 'error_saveRights';
            return Promise.reject(err);
        }
    }

    // all the users' rights of the company
    async getRightsListByCompany(companyId: string): Promise<any> {
        try {
            const response = await this.get('/api/rights/company/' + companyId);
            return response.data;
        } catch (err) {
            err.error_code = 'error_getRightsByCompany';
            return Promise.reject(err);
        }
    }

    // get the users' rights for the specific company
    async getRightsListByCompanyAndUser(companyId: string, userId: string): Promise<any> {
        try {
            const response = await this.get('/api/rights/company/' + companyId + '/user/' + userId);
            return response.data;
        } catch (err) {
            err.error_code = 'error_getRightsByCompanyAndUser';
            return Promise.reject(err);
        }
    }

    // call to rights CRUD POST
    async createUserRights(userRights): Promise<any> {
        try {
            const route = '/api/rights/crud';
            const res = await this.post(route, userRights);
            return res.data;
        } catch (err) {
            err.error_code = 'error_createNewRights';
            return Promise.reject(err);
        }
    }

    // call to rights CRUD PUT
    async updateUserRightsById(userRights): Promise<any> {
        try {
            const route = '/api/rights/crud/' + userRights._id;
            const response = await this.put(route, userRights, null, true);
            if (response.data.matchedCount) {
                return;
            }
            const err = {
                error_code: 'error_updateNewRights',
            };
            return Promise.reject(err);
        } catch (err) {
            err.error_code = 'error_updateNewRights';
            return Promise.reject(err);
        }
    }

    // call to rights CRUD DELETE
    async deleteUserRightsById(userRights): Promise<any> {
        try {
            const route = '/api/rights/crud/' + userRights._id;
            const res = await this.delete(route);
            return res.data;
        } catch (err) {
            err.error_code = 'error_deleteRights';
            return Promise.reject(err);
        }
    }
}
