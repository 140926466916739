import { Component, ElementRef, Input, OnChanges, OnInit, QueryList, SimpleChange, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { PaginationService } from 'app/shared/components/pagination/front/pagination-front.service';
import { PaginationIndexes } from 'app/shared/components/pagination/pagination.interface';
import { PatternService } from 'app/shared/services/pattern/pattern.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';

/**
 * Pattern property to display in table
 * Can be a chunk or a simple file data
 */
interface PatternProperty {
    filename: string;
    pattern: {
        name: string;
        id: string;
    };
    properties: Array<{
        name: string;
        found: number;
    }>;
    hasPropertiesNotFound: boolean;
    uploadId: string;
    chunkId: string;
}

@Component({
    selector: 'ga-pattern-properties-table',
    templateUrl: './patterns-properties-table.component.html',
    styleUrls: ['./patterns-properties-table.component.scss'],
    providers: [PaginationService],
})
export class PatternsPropertiesTableComponent implements OnInit, OnChanges {
    @Input() data: PatternProperty[];
    @Input() groupId: string;
    @Input() indexSelectedGroupType: number;
    @Input() energyType: string;
    @Input() provider: { extracted: string; name: string; regex: string };
    @Input() contractType: { extracted: string; name: string; regex: string };
    @Input() disableActions = false;

    @ViewChildren('editPropertyTooltip') propertiesTooltip: QueryList<PopoverDirective>;

    /**
     * All unique properties found for for all data
     */
    allProperties: string[] = [];

    /**
     * Filtering
     */
    currentSearch = '';
    searchControl = new FormControl(); // needed to add a debounce time

    /**
     * Different patterns found
     * Not used for the moment
     */
    patterns: { [key: string]: number } = {};
    currentPatternCounter = 0;

    /**
     * Property to display in popover
     */
    propertyToDisplay = '';

    /**
     * Filtered list of files/chunks before pagination
     */
    public items: PatternProperty[] = [];

    /**
     * Filtered list of files/chunks after pagination
     */
    public itemsSorted: PatternProperty[] = [];

    constructor(private patternService: PatternService, private paginationService: PaginationService) {}

    ngOnInit() {
        this.searchControl.valueChanges
            .pipe(debounceTime(300))
            .subscribe({ next: newSearch => this.onSearch(newSearch) });
    }

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        // Reload component if input data has changes
        for (const propName in changes) {
            if (propName === 'data') {
                this.reinitComponent();
                this.updateProperties();
                this.updateElementsToDisplay();
            }
        }
    }

    reinitComponent() {
        this.allProperties = [];
        this.currentPatternCounter = 0;
    }

    updateProperties() {
        this.createAllProperties();
        this.modifyDataWithAllProperties();
    }

    updateElementsToDisplay() {
        this.items = [];
        this.data.forEach(element => {
            if (this.canBeDisplayed(element)) {
                this.items.push(element);
            }
        });
    }

    /**
     * Generate allProperties by searching all patterns properties found in data.
     */
    createAllProperties() {
        const uniqueProps = new Set<string>();
        this.data.forEach(element => {
            element.properties.forEach(p => {
                uniqueProps.add(p.name);
            });
        });
        this.allProperties = Array.from(uniqueProps);
    }

    getPatternDisplayName(pattern) {
        if (!this.patterns[pattern.id]) {
            this.patterns[pattern.id] = ++this.currentPatternCounter;
        }

        // return "Pattern " + this.patterns[pattern.id];
        return pattern.id;
    }

    /**
     * Update data by computing properties not found.
     */
    modifyDataWithAllProperties() {
        this.data = this.data.map(element => {
            if (element.properties.length !== this.allProperties.length) {
                const foundProperties = element.properties.filter(p => p.found).map(p => p.name);
                const notFoundProperties = element.properties.filter(p => !p.found).map(p => p.name);

                element.properties = this.allProperties.map(prop => {
                    let found = -1;

                    if (foundProperties.includes(prop)) {
                        found = 1;
                    } else if (notFoundProperties.includes(prop)) {
                        found = 0;
                    }

                    return {
                        name: prop,
                        found,
                    };
                });
            }

            return element;
        });
    }

    isInSearch(element: PatternProperty): boolean {
        if (this.currentSearch !== '') {
            return element.filename.includes(this.currentSearch);
        }

        return true;
    }

    isStateDisplayable(element: PatternProperty): boolean {
        return true;
    }

    /**
     * For the given element, define it can be displayed or not.
     * @param {PatternProperty} element - element to define if must be displayed or not.
     * @returns {boolean} true if element matches filters, false otherwise
     */
    canBeDisplayed(element: PatternProperty): boolean {
        return this.isStateDisplayable(element) && this.isInSearch(element);
    }

    getPropertyClass(found: number): string {
        let className = '';

        if (found === 1) {
            className = 'property-found';
        } else if (found === 0) {
            className = 'property-not-found';
        } else if (found === -1) {
            className = 'property-non-existent';
        }

        return className;
    }

    getFixUploadLink(element: PatternProperty): string[] {
        if (element.chunkId) {
            return ['/collecte/regex/upload/' + element.uploadId + '/' + element.chunkId];
        } else {
            return ['/collecte/regex/upload/' + element.uploadId];
        }
    }

    get fixUploadQueryParams() {
        return { index: this.indexSelectedGroupType };
    }

    /**
     * Get url for the redirection on the pattern edition page.
     * @param {string} property
     */
    getUrlProperty(property: string) {
        return (
            `/collecte/regex/propriete/${this.groupId}/${property}/` +
            `${encodeURIComponent(this.energyType)}/${encodeURIComponent(this.provider.name)}/${encodeURIComponent(
                this.contractType.name
            )}`
        );
    }

    /**
     * Get query params for redirection on the pattern edition page.
     * Param is the index of the selected contract.
     * On the pattern page, clicking on “Return“ enables the contract to be pre-selected.
     */
    getUrlQueryParams() {
        return { index: this.indexSelectedGroupType };
    }

    removeStringSpaces(str: string) {
        return str.replace(/\s/g, '_');
    }

    getPropertyNameFromSlug(property) {
        return this.patternService.getPropertyNameFromSlug(property, this.energyType);
    }

    displayProperty(event, property) {
        this.propertyToDisplay = property;
    }

    onSearch(search: string) {
        this.currentSearch = search;
        this.updateElementsToDisplay();
    }

    public updateItemsSorted(indexes: PaginationIndexes) {
        this.itemsSorted = this.items.slice(indexes.indexStart, indexes.indexEnd + 1);
    }

    /**
     * Hide all the tooltips.
     */
    public onScroll(): void {
        this.propertiesTooltip.forEach(x => x.hide());
    }
}
