import { animate, state, style, transition, trigger } from '@angular/animations';

export class Animations {
    public static slideInOut = trigger('slideInOut', [
        state(
            'true',
            style({
                height: '*',
            })
        ),
        state(
            'false',
            style({
                height: '0px',
            })
        ),
        transition('1 => 0', [animate('200ms linear')]),
        transition('0 => 1', [animate('200ms linear')]),
    ]);

    public static slideInOutHorizontal = trigger('slideInOutHorizontal', [
        state(
            'true',
            style({
                // width: '*'
            })
        ),
        state(
            'false',
            style({
                display: 'none',
            })
        ),
        transition('1 => 0', [animate('0s linear')]),
        transition('0 => 1', [animate('0s linear')]),
    ]);
}
