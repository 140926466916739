import { Injectable } from '@angular/core';

// services
import { ApiService } from 'app/shared/services/api/api.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { PageService } from 'app/shared/services/page/page.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { DefaultTile, TilesService } from 'app/shared/services/tiles/tiles.service';

// interfaces
import { FilterQuery } from 'app/shared/components/common/filter/filter.component';
import { KeyFigures } from 'app/shared/models/key-figure.interface';

export interface HomeMonthlyConsumptionData {
    data: {
        chart: HomeChartData[][];
        prices: Array<{ [label: string]: number }>;
    };
    labels: string[];
}

export interface HomeChartData {
    data: number[];
    label: string;
    unit: string;
}

export interface HomeDonutData {
    data: {
        ht: number[];
        ttc: number[];
    };
    labels: string[];
}

@Injectable()
export class HomeService extends PageService {
    constructor(
        public apiService: ApiService,
        public tilesService: TilesService,
        private sessionService: SessionService,
        private energyService: EnergyService,
        private chartService: ChartService
    ) {
        super(apiService);
    }

    /***** API *****/

    getMonthlyGlobalEnergyRepartition(filters): Promise<any> {
        return this.post('/api/common/global/monthly', filters);
    }

    getYearlyGlobalEnergyRepartition(filters): Promise<any> {
        return this.post('/api/common/global/yearly', filters);
    }

    /**
     * Get keyfigures for the session company
     * @returns {Promise<KeyFigures>} keyfigures for the session company
     */
    async getKeyFigures(): Promise<KeyFigures> {
        const companyId = this.sessionService.getCompanyId();
        try {
            const response = await this.get(`/api/keyfigures/company/${companyId}`);
            return response.data;
        } catch (err) {
            return Promise.reject(err);
        }
    }
    /**
     * Get sites filtered from filters
     * @param {FilterQuery} filters
     * @returns {Promise<*>}
     */
    getSites(filters: FilterQuery = {}): Promise<any> {
        const companyId: string = filters['companyId'] || this.sessionService.getCompany()._id;
        return this.post('/api/companies/' + companyId + '/sites', filters);
    }

    /***** FORMATE *****/

    /**
     * Format month data to monthly consumption to be displayed in the home page
     * @param {Array<*>} data - month data grouped by month and fluid
     * @param {Array<string>} monthToTheEnd - month labels over the period
     * @returns {{data: {chart: Array<*>, prices: Array<*>}, labels: Array<string>}} chart data (values and labels)
     */
    formateToMonthlyConsumption(data: any[], monthToTheEnd: string[]): HomeMonthlyConsumptionData {
        /**
         * Define default fluid.
         * Used to fill empty month with value 0, and not take a random fluid (will appear in legend)
         */
        const fluidsSet = new Set([]);
        data.forEach(md => {
            if (md.items) {
                md.items.forEach(item => {
                    fluidsSet.add(item.energyType);
                });
            }
        });
        const fluidsInData = fluidsSet.values();
        let defaultFluid = fluidsInData.next().value;
        if (!defaultFluid) {
            defaultFluid = 'water';
        }

        /**
         * We use a temporary object as it's easy to work with keys (for charts TTC/HT) then indexes
         */
        const res = {
            data: {
                chart: {
                    ttc: [],
                    ht: [],
                },
                prices: {
                    ttc: {},
                    ht: {},
                },
            },
            labels: [],
        };

        if (data && !data.length) {
            return {
                data: {
                    chart: [res.data.chart.ht, res.data.chart.ttc],
                    prices: [res.data.prices.ht, res.data.prices.ttc],
                },
                labels: [],
            };
        }

        const energies = {
            ttc: {},
            ht: {},
        };

        let count = 0;

        monthToTheEnd.forEach(month => {
            // month = "01/2018"
            const currentMonth = Number(month.substr(0, 2)) - 1;
            const currentYear = Number(month.substr(3, 4));

            // Add empty month/year with 0 amount to push the real data forward
            if (data.filter(x => x.year === currentYear && x.month === currentMonth).length < 1) {
                data.splice(count, 0, {
                    year: currentYear,
                    month: currentMonth,
                    items: [
                        {
                            energyType: defaultFluid,
                            totalHT: {
                                amount: 0,
                            },
                            totalTTC: {
                                amount: 0,
                            },
                            year: currentYear,
                            month: currentMonth,
                        },
                    ],
                });
            }
            count = count + 1;
        });

        data.sort((a, b) => {
            if (a.year !== b.year) {
                return a.year - b.year;
            }
            return a.month - b.month;
        });
        data.forEach((databyMonth, indexMonth) => {
            databyMonth.month = parseInt(databyMonth.month, 10);

            const monthLabel =
                this.chartService.getMonthNumber(databyMonth.month + 1) + '/' + databyMonth.items[0].year.toString();

            // We add the current month as new chart label
            res.labels.push(monthLabel);

            if (typeof res.data.prices.ttc[monthLabel] === 'undefined') {
                res.data.prices.ttc[monthLabel] = 0;
            }

            if (typeof res.data.prices.ht[monthLabel] === 'undefined') {
                res.data.prices.ht[monthLabel] = 0;
            }

            databyMonth.items.forEach(energyData => {
                for (const priceType in energies) {
                    if (!energies.hasOwnProperty(priceType)) {
                        continue;
                    }
                    if (typeof energies[priceType][energyData.energyType] === 'undefined') {
                        // need to initialize the array with 12 zeros and then replace the energies[priceType][energyType][month] with the correct values
                        energies[priceType][energyData.energyType] = Array.apply(null, Array(data.length)).map(
                            Number.prototype.valueOf,
                            0
                        );
                    }

                    // We get the price as we want HT or TTC
                    const price = priceType === 'ht' ? energyData.totalHT.amount : energyData.totalTTC.amount;

                    // We add the price to total price
                    res.data.prices[priceType][monthLabel] += price;

                    // We add the price to energy values, if more than a year index becomes greater than 11
                    energies[priceType][energyData.energyType][indexMonth] = price;
                }
            });
        });

        // We have all the values HT and TTC by energies. We create the chart data from it
        for (const priceType in energies) {
            if (energies.hasOwnProperty(priceType)) {
                for (const i in energies[priceType]) {
                    if (energies[priceType].hasOwnProperty(i)) {
                        res.data.chart[priceType].push({
                            data: energies[priceType][i],
                            label: i,
                        });
                    }
                }
            }
        }

        return {
            data: {
                chart: [res.data.chart.ht, res.data.chart.ttc],
                prices: [res.data.prices.ht, res.data.prices.ttc],
            },
            labels: [],
        };
    }

    getFromMapReduce(data: any[]): number[] {
        return data.map((element, index) => {
            return element.data.reduce((memo, currentItem) => {
                return memo + currentItem;
            }, 0);
        });
    }

    /**
     * Format monthly data for donut display
     * @param {HomeMonthlyConsumptionData} data - monthly data
     * @returns {HomeDonutData}
     */
    refactoDataForDonut(data: HomeMonthlyConsumptionData): HomeDonutData {
        return {
            // Example of the following function call : [{data: [1100, 15, 55, 80]}, {data: [98, 82, 48, 52]}] => [1250, 280]
            data: {
                ht: this.getFromMapReduce(data.data.chart[0]),
                ttc: this.getFromMapReduce(data.data.chart[1]),
            },
            labels: data.data.chart[0].map(element => element.label),
        };
    }

    /**
     * Format data for home tiles
     * @param {Array<*>} data
     * @returns {Array<DefaultTile>}
     */
    refactoDataForTiles(allData: any[] = []): DefaultTile[] {
        const response: DefaultTile[] = [];

        const totals: { [key: string]: number } = {};

        this.energyService
            .getAllFluids()
            .concat(['co2', 'ht', 'ttc'])
            .forEach(item => {
                totals[item] = 0;
            });

        allData.forEach(monthDatas => {
            monthDatas.items.forEach(singleData => {
                if (singleData && singleData.consumption) {
                    const energyType = singleData.energyType;

                    if (totals.hasOwnProperty(energyType)) {
                        totals[energyType] += singleData.consumption.quantity;
                    }

                    if (singleData.co2) {
                        totals.co2 += singleData.co2;
                    }
                    totals.ht += singleData.totalHT.amount;
                    totals.ttc += singleData.totalTTC.amount;
                }
            });
        });

        // create cost tile first we TTC and HT values - '€ HTVA - TTC'
        if (totals['ttc'] && totals['ht']) {
            const tile = this.tilesService.generateTile('ttc_ht');
            tile.value = totals['ht'];
            tile.value2 = totals['ttc'];

            delete totals['ht'];
            delete totals['ttc'];
            response.push(tile); // push the costs in first position
        }

        Object.keys(totals).forEach(key => {
            const total = totals[key];

            if (total !== 0) {
                const tile = this.tilesService.generateTile(key);
                tile.value = total;
                tile.value2 = null;

                response.unshift(tile); // insert in first position so that the cost tile is at the end
            }
        });

        return response;
    }
}
