import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-highchart-multilevel-pie-toggle',
    templateUrl: './highchart-multilevel-pie-toggle.component.html',
    styleUrls: ['./highchart-multilevel-pie-toggle.component.scss'],
    providers: [],
})
export class HighchartMultilevelPieToggleComponent implements OnInit {
    @Input() optionsSets: any[];
    @Input() legend: any = [];
    @Input() toggleNames = [];
    @Input() downloadFileNames = [];

    @Output() emitChartClick = new EventEmitter();

    public activeDataset = 0;
    public options: any[] = [];

    constructor() {}

    chartClicked(event) {
        this.emitChartClick.emit(event);
    }

    setActivePieChart(index: number) {
        this.activeDataset = index;
        this.options = this.optionsSets[index];
    }

    ngOnInit() {
        this.selectToggleWithData();
    }

    /**
     * Select the toggle which has data
     */
    selectToggleWithData() {
        const indexWithData = this.optionsSets.findIndex(optionSet =>
            optionSet.series[0].data.some(data => data.y > 0)
        );
        const indexSelected = indexWithData !== -1 ? indexWithData : 0;
        this.setActivePieChart(indexSelected);
    }
}
