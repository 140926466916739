import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { MeteoStation } from '../../../../models/meteo-station.interface';
import { Site } from '../../../../models/site.interface';
import { MeteoService } from '../../../../services/meteo/meteo.service';
import {
    BRExclusibleUses,
    DataSource,
    DjuType,
    ExclusionTypes,
    Monitoring,
} from '../../operating-monitoring.interface';
import { OperatingMonitoringService } from '../../operating-monitoring.service';
import { DataSourceFieldKey, FieldConfig } from '../monitoring-edition.interface';
import { MonitoringEditionService } from '../monitoring-edition.service';

/**
 * Monitoring creation data source selection
 */
@Component({
    selector: 'ga-monitoring-edition-source',
    templateUrl: './monitoring-edition-source.component.html',
    styleUrls: ['../monitoring-edition.component.scss', './monitoring-edition-source.component.scss'],
})
export class MonitoringEditionSourceComponent implements OnInit, OnDestroy {
    /** Subscription */
    private subscription: Subscription;

    public djuSiteDisplayValue: number;

    /**
     * Fields config
     */
    private configs: Array<FieldConfig<DataSourceFieldKey>> = [
        {
            key: 'datasource',
            displayKey: 'monitoring_datasource',
            deps: [],
            defaultValue: DataSource.BILLING,
            options: [{ value: DataSource.BILLING, key: `monitoring_datasource_${DataSource.BILLING}` }],
            disabled: true,
            required: true,
        },
        {
            key: 'djuType',
            displayKey: 'unified_degree_days',
            deps: [],
            defaultValue: DjuType.HOT,
            options: [
                { key: 'unified_degree_days_' + DjuType.HOT, value: DjuType.HOT },
                { key: 'unified_degree_days_' + DjuType.COLD, value: DjuType.COLD },
            ],
            required: true,
        },
        {
            key: 'heatingUse',
            displayKey: 'monitoring_hvc',
            deps: [],
            defaultValue: true,
            disabled: true,
        },
        {
            key: 'ecsUse',
            displayKey: 'monitoring_ecs_other',
            deps: [],
            defaultValue: false,
        },
        {
            key: 'linearModeling',
            displayKey: 'monitoring_linear_modeling',
            deps: [{ key: 'ecsUse', value: true }],
            defaultValue: false,
            options: [{ value: true, key: 'yes' }, { value: false, key: 'no' }],
        },
        {
            key: 'subtractECS',
            displayKey: 'monitoring_substract_ecs_other',
            deps: [{ key: 'ecsUse', value: true }],
            defaultValue: true,
            options: [{ value: true, key: 'yes' }, { value: false, key: 'no' }],
        },
        {
            key: 'subtractMethod',
            displayKey: 'monitoring_substract_method',
            deps: [
                { key: 'ecsUse', value: true },
                { key: 'linearModeling', value: false },
                { key: 'subtractECS', value: true },
            ],
            defaultValue: ExclusionTypes.INDEX_READING,
            options: [
                { value: ExclusionTypes.INDEX_READING, key: `monitoring_datasource_${ExclusionTypes.INDEX_READING}` },
                {
                    value: ExclusionTypes.HOUSING_THEORETICAL,
                    key: `monitoring_datasource_${ExclusionTypes.HOUSING_THEORETICAL}`,
                },
            ],
        },
    ];

    /** The monitoring's site info */
    public site: Site;
    /** The monitoring's meteo station info */
    public meteoStation: MeteoStation;

    /** Fields form group. Initiated dynamically */
    get form() {
        return this.monitoringEditionService.form;
    }

    constructor(
        private operatingMonitoringService: OperatingMonitoringService,
        private monitoringEditionService: MonitoringEditionService,
        private meteoService: MeteoService
    ) {}

    ngOnInit() {
        this.monitoringEditionService.addToControlGroup(this.configs);
        // Throw an event to init data
        this.form.updateValueAndValidity();

        this.subscription = this.operatingMonitoringService.editedMonitoring$.subscribe({
            next: value => this.prefillForm(value),
        });

        this.subscription.add(
            this.form.get('monitoringSite').valueChanges.subscribe({
                next: value => this.onSiteChange(value),
            })
        );
        this.subscription.add(
            this.form.get('djuType').valueChanges.subscribe({
                next: value => this.setDjuSiteValue(this.site, value),
            })
        );
    }
    /** Get data source field config */
    public get dataSourceField(): FieldConfig<DataSourceFieldKey> {
        return this.configs.find(x => x.key === 'datasource');
    }

    /** Get DJU radio buttons fields in the correct order */
    public get djuTypeFields(): Array<FieldConfig<DataSourceFieldKey>> {
        return ['djuType'].map(x => this.configs.find(y => y.key === x));
    }

    /** Get uses config fields */
    public get usesFields(): Array<FieldConfig<DataSourceFieldKey>> {
        return ['heatingUse', 'ecsUse'].map(x => this.configs.find(y => y.key === x));
    }
    /** Get calculatioon config radio buttons fields in the correct order */
    public get calculationConfigFields(): Array<FieldConfig<DataSourceFieldKey>> {
        return ['linearModeling', 'subtractECS', 'subtractMethod'].map(x => this.configs.find(y => y.key === x));
    }

    /**
     * Display or not field
     * @param item
     */
    public displayField(item: FieldConfig<DataSourceFieldKey>): boolean {
        if (!item.deps || !item.deps.length) {
            return true;
        }
        return item.deps.every(dep => this.form.get(dep.key).value === dep.value);
    }

    private prefillForm(monitoring: Monitoring) {
        if (!monitoring) {
            return;
        }
        const uses = monitoring.uses;
        const hotWaterUse = uses.find(x => x.use === BRExclusibleUses.HOT_WATER);
        const exclusionType = hotWaterUse ? hotWaterUse.exclusionType : null;
        const useLinearModeling = Boolean(hotWaterUse && hotWaterUse.perfFormula);
        this.form.get('djuType').setValue(monitoring.djuType);
        this.form.get('ecsUse').setValue(Boolean(hotWaterUse));
        this.form.get('linearModeling').setValue(useLinearModeling);
        this.form.get('subtractECS').setValue(!hotWaterUse || exclusionType !== null);
        this.form
            .get('subtractMethod')
            .setValue(!useLinearModeling && exclusionType ? exclusionType : ExclusionTypes.INDEX_READING);
    }

    private async onSiteChange(newSite: Site) {
        if (this.site !== newSite) {
            this.site = null;
            this.meteoStation = null;
        }
        this.site = newSite;
        if (newSite && (!this.site || !this.meteoStation)) {
            this.meteoStation = await this.meteoService.getMeteoStation(this.site.meteoStation);
        }
        this.setDjuSiteValue(this.site, this.form.controls.djuType.value);
    }

    private setDjuSiteValue(site: Site, djuType: DjuType): void {
        if (site) {
            this.djuSiteDisplayValue =
                djuType === DjuType.COLD ? site.dju.temperatureReferenceCold : site.dju.temperatureReference;
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
