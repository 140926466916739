import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IdentificationPattern } from 'app/shared/models/identification-pattern.interface';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

export interface ContractType {
    name: string;
    options: Array<{
        name: string;
        extracted: string;
    }>;
}

@Injectable()
export class IdentificationPatternService extends PageService {
    constructor(public apiService: ApiService, private sanitizer: DomSanitizer) {
        super(apiService);
    }

    async createIdentificationPattern(identificationPattern, uploadId, chunkId): Promise<any> {
        try {
            const route = '/api/identification-patterns/add-and-update/';
            const res = await this.post(route, {
                identificationPattern,
                uploadId,
                chunkId,
            });
            return res.data;
        } catch (err) {
            err.error_code = 'error_createNewIdentificationPattern';
            return Promise.reject(err);
        }
    }

    async deleteIdentificationPattern(identificationPatternId: string): Promise<any> {
        try {
            const route = '/api/identification-patterns/crud/' + identificationPatternId;
            await this.delete(route);
            return;
        } catch (err) {
            err.error_code = 'error_deleteNewIdentificationPattern';
            return Promise.reject(err);
        }
    }

    async getEnergies(isMultiChunk): Promise<any> {
        try {
            const route = '/api/identification-patterns/aggregate/energy';
            const res = await this.get(route, null, {
                isMultiChunk,
            });
            return res.data;
        } catch (err) {
            err.error_code = 'error_aggregateIdentificationPattern';
            return Promise.reject(err);
        }
    }

    async getAvailableProviders(): Promise<string[]> {
        try {
            const route = '/api/identification-patterns/available/provider';
            const res = await this.get(route);
            return res.data;
        } catch (err) {
            err.error_code = 'error_aggregateIdentificationPattern';
            return Promise.reject(err);
        }
    }

    /**
     * @returns {Promise<Array<{ name: string; options: Array<{ name: string; extracted: string }> }>>} contract types with options associated found in identifications patterns
     */
    async getAvailableContractTypes(): Promise<
        Array<{ name: string; options: Array<{ name: string; extracted: string }> }>
    > {
        try {
            const route = '/api/identification-patterns/available/contract-types';
            const res = await this.get(route);
            return res.data;
        } catch (err) {
            err.error_code = 'error_aggregateIdentificationPattern';
            return Promise.reject(err);
        }
    }

    async getAvailableOptions(): Promise<string[]> {
        try {
            const route = '/api/identification-patterns/available/options';
            const res = await this.get(route);
            return res.data;
        } catch (err) {
            err.error_code = 'error_aggregateIdentificationPattern';
            return Promise.reject(err);
        }
    }

    async getProviders(isMultiChunk, energyType): Promise<string[]> {
        try {
            const route = '/api/identification-patterns/aggregate/provider';
            const res = await this.get(route, null, {
                isMultiChunk,
                energyType,
            });
            return res.data;
        } catch (err) {
            err.error_code = 'error_aggregateIdentificationPattern';
            return Promise.reject(err);
        }
    }

    async getContractTypes(isMultiChunk, energyType, provider): Promise<ContractType[]> {
        try {
            const route = '/api/identification-patterns/aggregate/contract-types';
            const res = await this.get(route, null, {
                isMultiChunk,
                energyType,
                provider,
            });
            return res.data;
        } catch (err) {
            err.error_code = 'error_aggregateIdentificationPattern';
            return Promise.reject(err);
        }
    }

    async getIdentificationPatterns(
        isMultiChunk,
        energyType,
        provider,
        contractType
    ): Promise<IdentificationPattern[]> {
        try {
            const route = '/api/identification-patterns/contract-types';
            const res = await this.get(route, null, {
                isMultiChunk,
                energyType,
                provider,
                contractType,
            });
            return res.data;
        } catch (err) {
            err.error_code = 'error_aggregateIdentificationPattern';
            return Promise.reject(err);
        }
    }

    async getIdentificationPattern(identificationPatternId): Promise<any> {
        try {
            const route = '/api/identification-patterns/crud/' + identificationPatternId;
            return this.get(route);
        } catch (err) {
            err.error_code = 'error_getIdentificationPattern';
            return Promise.reject(err);
        }
    }
}
