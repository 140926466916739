import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

import { UploadService } from 'app/shared/services/files/upload.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';

import { FixRegexUploadInterface } from './fix-regex-upload.interface';
import { ExtractDataArray, ExtractDataTableProperty, FixRegexUploadService } from './fix-regex-upload.service';

@Component({
    selector: 'ga-fix-regex-upload',
    templateUrl: './fix-regex-upload.component.html',
    styleUrls: ['./fix-regex-upload.component.scss'],
    providers: [FixRegexUploadService],
})
export class FixRegexUploadComponent implements OnInit, FixRegexUploadInterface {
    uploadFile: any;
    energyType = '';
    identificationPatternId = '';
    textContent: any;
    indexCollectSelectedGroup: any;
    isPatternFromScratch = false;

    dataArray: ExtractDataArray = {
        dataTable: [],
        closestPattern: null,
        otherProperties: [],
    };

    initialDataArray: ExtractDataArray = {
        dataTable: [],
        closestPattern: null,
        otherProperties: [],
    };
    public get isPatternEnabled(): boolean {
        if (
            this.dataArray &&
            this.dataArray.closestPattern &&
            typeof this.dataArray.closestPattern.enabled === 'boolean'
        ) {
            return this.dataArray.closestPattern.enabled;
        }

        return true;
    }

    /** Selectable properties of the treeview */
    public selectableProperties: Array<{ displayName: string; value: string }>;
    /** Selected properties of the treeview */
    public selectedProperties: string[] = [];

    constructor(
        private regexService: FixRegexUploadService,
        private route: ActivatedRoute,
        private uploadService: UploadService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(queryParams => {
            if (queryParams.hasOwnProperty('index')) {
                this.indexCollectSelectedGroup = Number(queryParams.index);
            }
        });

        this.route.params.subscribe(params => {
            this.fetchPropertiesFromUpload(params.uploadId);
        });
    }

    /**
     * Fetch upload data and retrieves all its corresponding properties.
     * @param uploadId
     */
    private async fetchPropertiesFromUpload(uploadId: string): Promise<void> {
        try {
            const response = await this.regexService.getUploadFiles(uploadId);

            if (response.code === 200) {
                this.uploadFile = response.data;

                this.energyType = this.uploadFile.identificationPattern.energyType;
                this.identificationPatternId = this.uploadFile.identificationPattern._id;
                this.textContent = this.uploadFile.textContent.data.toString();

                // If upload has never had been extracted, there is no clothestPattern
                if (this.uploadFile.clothestPattern) {
                    this.dataArray.closestPattern = this.uploadFile.clothestPattern[this.energyType];
                    this.isPatternFromScratch = false;
                } else {
                    this.isPatternFromScratch = true;
                    this.dataArray.closestPattern = {
                        requiredProperties: [],
                    };
                }

                // the array 'properties' is filled with all the other properties existing for the fluid patterns which aren't in the required properties
                const allProperties = await this.regexService.getPatternProperties(this.energyType);

                this.regexService.fillDataTable(
                    allProperties,
                    this.dataArray,
                    this.textContent,
                    this.energyType,
                    this.identificationPatternId
                );

                this.dataArray.otherProperties.sort((a, b) => a.localeCompare(b));

                this.selectableProperties = this.regexService.formatPropertiesForTreeview(
                    this.dataArray.otherProperties
                );

                this.regexService.setInitialDataArray(this.initialDataArray, this.dataArray);
            }
        } catch (error) {
            Swal.fire({
                title: this.translateService._('error_we_are_sorry'),
                text: this.translateService._('error_collect_while_fetching_extracted_properties'),
                icon: 'error',
            });
        }
    }

    /**
     * Add each selected treeview item to table
     * @param selectedTreeItems Selected items in treeview
     */
    public addItemsToTable(selectedTreeItems: string[]): void {
        for (let index = 0, len = selectedTreeItems.length; index < len; index++) {
            this.addPropertyToTable(selectedTreeItems[index]);
        }

        this.selectableProperties = this.regexService.formatPropertiesForTreeview(this.dataArray.otherProperties);
    }

    // delete from the dataTable array
    deletePropertyFromTable(property: ExtractDataTableProperty) {
        this.regexService.deletePropertyFromTable(property, this.dataArray);
        this.selectableProperties = this.regexService.formatPropertiesForTreeview(this.dataArray.otherProperties);
    }

    // update the closest pattern / create a pattern with the values in the dataTable
    onSavePattern(action: string) {
        if (
            this.regexService.hasPatternChanged(this.dataArray, this.initialDataArray) &&
            !this.regexService.hasWrongRegex(this.dataArray)
        ) {
            Swal.fire({
                title: 'Etes-vous sûr ?',
                text: this.regexService.getSwalTitleOnSave(action),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#009688',
                cancelButtonColor: '#d33',
                confirmButtonText: this.regexService.getSwalConfirmButtonTextOnSave(action),
                cancelButtonText: 'Annuler',
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    if (this.regexService.hasEmptyRegex(this.dataArray)) {
                        Swal.fire('Annulé', 'Un regex est vide', 'error');
                    } else {
                        this.regexService.createClosestPattern(
                            action,
                            this.dataArray,
                            this.energyType,
                            this.identificationPatternId,
                            this.textContent
                        );

                        // save pattern in db
                        this.savePattern(action).then(
                            res => {
                                Swal.fire('OK', this.regexService.getSwalSuccessOnSave(action), 'success').then(() => {
                                    return this.backToCollect();
                                });
                            },
                            err => {
                                if (err.stack === 'error_updatePattern') {
                                    Swal.fire(
                                        'Toutes nos excuses',
                                        this.regexService.getSwalFailOnSave(action),
                                        'error'
                                    );
                                }
                            }
                        );
                    }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire('Annulé', '', 'error');
                }
            });
        } else {
            if (!this.regexService.hasPatternChanged(this.dataArray, this.initialDataArray)) {
                Swal.fire('Impossible', "Aucun regex n'a été modifié", 'error');
            }
            if (this.regexService.hasWrongRegex(this.dataArray)) {
                Swal.fire('Impossible', 'Un regex est en erreur ou en cours de recherche.', 'error');
            }
        }
    }

    // put in database the new pattern
    savePattern(action: string): Promise<any> {
        switch (action) {
            case 'update':
                return this.regexService.updatePattern(
                    this.energyType,
                    this.dataArray,
                    this.identificationPatternId,
                    this.textContent,
                    this.initialDataArray
                );

            case 'new':
                return this.regexService.createNewPattern(
                    this.energyType,
                    this.dataArray,
                    this.identificationPatternId
                );
        }
    }

    // add the new property to the table
    addPropertyToTable(property: string) {
        this.regexService.addPropertyToTable(
            property,
            this.initialDataArray,
            this.dataArray,
            this.energyType,
            this.identificationPatternId,
            this.textContent
        );
    }

    // when regex in change (typed in or selected in the selectbox)
    executeRegex(property: ExtractDataTableProperty) {
        this.regexService.executeRegex(property, this.textContent);
    }

    // reset value with initial value
    resetRegex(property: ExtractDataTableProperty) {
        this.regexService.resetRegex(property, this.textContent);
    }

    backToCollect() {
        this.regexService.backToCollect(this.uploadFile.group, this.indexCollectSelectedGroup);
    }

    getBackToCollectLink() {
        if (this.uploadFile) {
            return this.regexService.getBackToCollectLink(this.uploadFile.group, this.indexCollectSelectedGroup);
        } else {
            return this.regexService.getBackToCollectLink(this.uploadFile, this.indexCollectSelectedGroup);
        }
    }

    copyToClipboard() {
        const copied = this.regexService.copyTextToClipboard(this.textContent);
        if (!copied) {
            Swal.fire(
                'Toutes nos excuses',
                "Une erreur s'est produite pendant la copie du contenu du fichier",
                'error'
            );
        }
    }

    async openFileInTab() {
        try {
            await this.uploadService.openFileInTab(this.uploadFile._id);
        } catch (e) {
            Swal.fire(
                'Toutes nos excuses',
                'Le fichier est inaccessible pour le moment. Veuillez réessayer ultérieurement.',
                'warning'
            );
        }
    }
}
