import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { Notification } from 'app/shared/models/notification.interface';
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { FileStorageService } from 'app/shared/services/files/file-storage.service';
import { NotificationsService } from 'app/shared/services/notifications/notifications.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { UserSessionService } from 'app/shared/services/session/user-session.service';
import { TitleService } from 'app/shared/services/title/title.service';
import { UsersService } from 'app/shared/services/users/users.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

import * as moment from 'moment';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-navbar-top',
    templateUrl: './navbar-top.component.html',
    styleUrls: ['./navbar-top.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    providers: [],
})
export class NavbarTopComponent implements OnInit {
    private defaultAvatarUrl = 'assets/img/backgrounds/default-avatar.jpg';

    notifications: Notification[] = [];
    notificationsDisplay: Notification[] = [];
    notificationsLimit = 10;

    adminPanel: any[] = [];

    urlUserPicture: SafeUrl = '';
    title: string;
    subtitle: string;
    user: any = {};
    company: any = {};
    userCompanies: any[];

    /*
     *Companies displayed after user input in the search field
     */
    public companiesDisplayed: any[] = [];

    /** Control to handle search input */
    public searchControl: FormControl = new FormControl('');

    constructor(
        private utils: UtilsService,
        private router: Router,
        private sessionService: SessionService,
        private titleService: TitleService,
        private fileStorageService: FileStorageService,
        private sanitizer: DomSanitizer,
        private companyService: CompaniesService,
        private usersService: UsersService,
        private notificationsService: NotificationsService,
        private userSessionService: UserSessionService
    ) {}

    ngOnInit() {
        this.setUser();
        this.setCompany();
        this.initUserSubscription();
        this.setCompaniesList();
        this.setAdminPanels();
        this.initNotifications();
        this.searchControl.valueChanges.subscribe({
            next: value => {
                this.onSearch(value);
            },
        });
    }

    public displaySearchCompany(): boolean {
        return this.userCompanies.length >= 5;
    }

    public onSearch(filter: string) {
        this.refreshDisplayedCompanies(filter);
    }

    public refreshDisplayedCompanies(filter: string) {
        this.companiesDisplayed = this.userCompanies.filter(company => this.filterCompany(company, filter));
    }

    private filterCompany(company: any, filter: string): boolean {
        if (!filter) {
            return true;
        }
        const stringToMatch = this.utils.removeAccentAndLowerCase(filter);
        const companyName = this.utils.removeAccentAndLowerCase(company.name);

        return companyName.includes(stringToMatch);
    }

    setAdminPanels() {
        if ([UserTypes.ENERGY_MANAGER, UserTypes.ADMIN].includes(this.user.type)) {
            this.adminPanel = this.getAdminPanelLinks();
        } else if (this.user.type === UserTypes.USER_ADMIN) {
            this.adminPanel = this.getUserAdminPanelLinks();
        } else {
            this.adminPanel = this.getUserPanelLinks();
        }

        this.adminPanel.push(
            {
                display: 'Gestion des contrats',
                url: '/contrats',
                available: true,
                iconClass: 'icon-contracts',
            },
            {
                display: 'Gestion des factures',
                url: '/factures',
                available: true,
                iconClass: 'icon-bills',
            },
            {
                display: 'Paramétrage des alertes',
                url: '/parametrage-alertes',
                available: true,
                iconClass: 'icon-alert',
            },
            {
                display: 'Contrôle des factures',
                url: '/controle/factures',
                available: true,
                iconClass: 'icon-bills',
            }
        );
    }

    getAdminPanelLinks() {
        return [
            {
                display: 'Mon compte',
                url: '/admin/utilisateur/' + this.user._id + '/profil',
                available: true,
                iconClass: 'icon-myaccount',
            },
            {
                display: 'Nos clients',
                url: '/admin/entreprises',
                available: true,
                iconClass: 'icon-companies',
            },
            {
                display: 'Nos utilisateurs',
                url: '/admin/utilisateurs',
                available: true,
                iconClass: 'icon-em',
            },
            {
                display: 'Collecte automatique',
                url: '/collecte',
                available: true,
                iconClass: 'icon-collect',
            },
            {
                display: 'Base de DJU',
                url: '/dju',
                available: true,
                iconClass: 'icon-dju',
            },
        ];
    }

    getUserAdminPanelLinks() {
        return [
            {
                display: 'Mon compte',
                url: '/admin/utilisateur/' + this.user._id + '/profil',
                available: true,
                iconClass: 'icon-myaccount',
            },
            {
                display: 'Nos entreprises',
                url: '/admin/entreprises',
                available: true,
                iconClass: 'icon-companies',
            },
            {
                display: 'Nos utilisateurs',
                url: '/admin/utilisateurs',
                available: true,
                iconClass: 'icon-em',
            },
            {
                display: 'Collecte automatique',
                url: '/collecte',
                available: true,
                iconClass: 'icon-collect',
            },
        ];
    }

    getUserPanelLinks() {
        return [
            {
                display: 'Mon compte',
                url: '/admin/utilisateur/' + this.user._id + '/profil',
                available: true,
                iconClass: 'icon-myaccount',
            },
            {
                display: 'Mes Energy-managers',
                url: '/admin/utilisateurs',
                available: true,
                iconClass: 'icon-em',
            },
        ];
    }

    initNotifications() {
        this.notificationsService.getNotifications().subscribe({
            next: notifications => {
                this.notifications = notifications;
                this.notificationsDisplay = [];
                const limit = Math.min(this.notifications.length, this.notificationsLimit);
                for (let i = 0; i < limit; i++) {
                    this.notificationsDisplay.push(this.notifications[i]);
                }
            },
        });
        this.notificationsService.refresh();
    }

    setUser() {
        this.user = this.sessionService.getUser();
    }

    getUser() {
        return this.sessionService.getUser();
    }

    setCompany() {
        this.company = this.sessionService.getCompany();
    }

    getCompany() {
        return this.sessionService.getCompany();
    }

    async changeCompanySelected(companySelected: { [property: string]: any }) {
        this.sessionService.setCompany(companySelected);
        this.company = companySelected;

        // if the user is already on Home page --> specifies to reload all the page's components
        const url = this.router.url.substring(0, this.router.url.indexOf('?'));
        const queryParams = url === '/accueil' ? { refresh: true } : {};
        await this.redirectTo('/accueil', queryParams);
        this.notificationsService.refresh();
    }

    async redirectTo(url, queryParams) {
        return this.router.navigate([url], { queryParams });
    }

    async setUserImage() {
        if (this.user.img) {
            try {
                const url = await this.fileStorageService.getUrl(this.user.img);
                this.urlUserPicture = this.sanitizer.bypassSecurityTrustUrl(url);
            } catch (e) {
                this.urlUserPicture = this.defaultAvatarUrl;
            }
        } else {
            this.urlUserPicture = this.defaultAvatarUrl;
        }
    }

    private initUserSubscription() {
        this.userSessionService.getUserSession().subscribe({
            next: user => {
                if (user) {
                    this.user = user;
                    this.setUserImage();
                }
            },
        });
    }

    async logout() {
        try {
            // If an error occurs, we still want cache to be cleared and navigate to authentification
            await this.userSessionService.logout();
        } catch (e) {}
        this.sessionService.clearLocalStorage();
        await this.router.navigateByUrl('/authentification');
        // After logout, reload app to be sure components are reloaded
        window.location.reload();
    }

    getTitle() {
        this.title = this.titleService.getTitle(this.router.url);
        return this.title;
    }

    getSubtitle() {
        if (this.titleService.getSubtitle(this.router.url)) {
            this.subtitle = this.titleService.getSubtitle(this.router.url);
            // TODO subtitle with id
            this.subtitle = this.subtitle.replace(/\//g, '');
        } else {
            this.subtitle = null;
        }
        return this.subtitle;
    }

    getTitleColor(): string {
        return this.titleService.getTitleColor(this.router.url);
    }

    setCompaniesList() {
        this.companyService.getUserCompanies().subscribe({
            next: companies => {
                this.userCompanies = companies;
                this.refreshDisplayedCompanies(this.searchControl.value);
            },
        });
        this.companyService.refreshUserCompaniesList();
    }

    sortListAlphabetically(companyA, companyB) {
        const a = companyA.name;
        const b = companyB.name;
        return a > b ? 1 : a < b ? -1 : 0;
    }

    isFavouriteCompany(company) {
        return this.user.favouriteCompany && this.user.favouriteCompany === company._id;
    }

    setClassStar(company) {
        return this.user.favouriteCompany && this.user.favouriteCompany === company._id
            ? 'icon zmdi zmdi-star'
            : 'icon zmdi zmdi-star-outline';
    }

    // click on the star
    setFavouriteCompany(event, company) {
        // Disable event propagation
        event.stopPropagation();

        // doesn't have one yet or another one
        if (!this.user.favouriteCompany || this.user.favouriteCompany !== company._id) {
            const oldFavouriteCompany = this.user.favouriteCompany;
            this.user.favouriteCompany = company._id;
            this.usersService.updateUser(this.user).then(
                user => {
                    if (!user) {
                        this.user.favouriteCompany = oldFavouriteCompany;
                    }
                    return Promise.resolve();
                },
                err => {
                    this.user.favouriteCompany = oldFavouriteCompany;
                    // err.error_code is set to error_updateUser
                    return Promise.reject(err);
                }
            );
        }
    }

    setCompanyHoverClass(event, company) {
        if (event.type === 'mouseover') {
            event.srcElement.className = 'icon zmdi zmdi-star m-0';
        } else if (event.type === 'mouseleave' && !this.isFavouriteCompany(company)) {
            event.srcElement.className = 'icon zmdi zmdi-star-outline m-0';
        }
    }

    setAdminActiveClass(isAvailable) {
        return isAvailable ? ' active' : ' inactive';
    }

    setIconClass(item) {
        return 'icon ' + item.iconClass;
    }

    get userProfileLink() {
        return '/admin/utilisateur/' + this.user._id + '/profil';
    }

    get notificationsNotReadCount() {
        return this.notifications.filter(x => !x.read).length;
    }

    async notificationClick(dropdown: BsDropdownDirective, notification: Notification) {
        try {
            dropdown.hide();
            if (!notification.read && notification._id) {
                await this.notificationsService.setNotificationState(notification._id, true);
                notification.read = true;
            }
        } catch (e) {
            Swal.fire(`Impossible de changer l'état de la notification`, 'Merci de réessayer ultérieurement', 'error');
        }
    }

    /**
     * Toggle notification state (read/ not read)
     * @param {Notification} notification - notification to toggle read state from
     */
    async toggleNotificationState(notification: Notification) {
        try {
            const nextState = !notification.read;
            await this.notificationsService.setNotificationState(notification._id, nextState);
            notification.read = nextState;
            this.notificationsService.refresh();
        } catch (e) {
            Swal.fire(`Impossible de changer l'état de la notification`, 'Merci de réessayer ultérieurement', 'error');
        }
    }

    /**
     * Get notfication read button title key
     * @param {Notification} notification
     * @returns {string} translate key for title
     */
    getNotificationReadTitle(notification: Notification): string {
        return notification.read ? 'mark_as_not_read_f' : 'mark_as_read_f';
    }

    /**
     * Archive notification
     * @param {Notification} notification - notification to archive
     */
    async archiveNotification(notification: Notification) {
        try {
            if (!notification || !notification._id) {
                return;
            }
            await this.notificationsService.archiveNotification(notification._id);
            this.notificationsService.refresh();
        } catch (e) {
            Swal.fire(`Impossible d'archiver la notification`, 'Merci de réessayer ultérieurement', 'error');
        }
    }

    /**
     * Get notification display date from now
     * @param {Notification} notification
     * @returns {string} time formatted from now (eg. '1 hour ago')
     */
    getNotificationDate(notification: Notification): string {
        return moment(notification.notifiedAt).fromNow();
    }

    /**
     * Display more notifications, step of 10
     */
    displayMoreNotifications() {
        if (!this.canDisplayMore) {
            return;
        }
        const step = 10;

        const startIndex = this.notificationsLimit;
        const limit = Math.min(this.notifications.length, this.notificationsLimit + step);
        for (let i = startIndex; i < limit; i++) {
            this.notificationsDisplay.push(this.notifications[i]);
        }
        this.notificationsLimit = limit;
    }

    get canDisplayMore(): boolean {
        return this.notificationsDisplay.length < this.notifications.length;
    }
}
