import { Injectable } from '@angular/core';
import { UserSession } from 'app/shared/services/session/session.interface';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../shared/services/api/api.service';
import { PageService } from '../../shared/services/page/page.service';

interface ErrorInterface {
    message: string;
    errorCode: string;
    status: number;
}
interface SSOSuccessResponse {
    accessToken: string;
    user: UserSession;
    expiresIn: number;
}
@Injectable()
export class SigninService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    auth(data): Promise<APICitronResponse<{ user: UserSession; token: string }>> {
        const form = {
            email: data.email,
            password: data.password,
        };

        return this.post('/api/auth/login', form);
    }

    authSSO(token: string): Promise<any> {
        const data = {
            token,
        };
        return this.post('/api/auth/sso', data);
    }

    authSSOProvider(provider: Partial<{ name: string }>) {
        window.location.replace(`${environment.apiUrl}/auth/${provider.name}`);
    }

    authSSOSuccessHandler(base64Data: string): { token: string; user: UserSession; expiresIn: number } {
        const data: SSOSuccessResponse = JSON.parse(atob(base64Data));
        return { token: data.accessToken, user: data.user, expiresIn: data.expiresIn };
    }

    authSSOErrorHandler(base64Error: string): string {
        const error: ErrorInterface = JSON.parse(atob(base64Error));
        return error.message;
    }
    fakeData(route) {
        let response;

        switch (route) {
            default:
                response = {};
                break;
        }

        return response;
    }
}
