import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule) },
    { path: 'authentification', loadChildren: () => import('./pages/signin/signin.module').then(m => m.SigninModule) },
    {
        path: 'reset-password',
        loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        data: { activation: false },
    },
    {
        path: 'activation',
        loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        data: { activation: true },
    },
    { path: '**', redirectTo: '404' },
];
/**
 * Router configuration object to define navigation behaviour.
 * scrollPositionRestoration set to 'enabled' creates a scroll history for backward navigation,
 * and ensures forward navigation scrolls to a provided anchor, or top of the view if not anchor is provided.
 */
const routerOptions: ExtraOptions = { scrollPositionRestoration: 'enabled' };

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
