import { Component, Input } from '@angular/core';

@Component({
    selector: 'ga-highchart-doughnut',
    templateUrl: './highchart-doughnut.component.html',
    styleUrls: ['./highchart-doughnut.component.scss'],
    providers: [],
})
export class HighchartDoughnutComponent {
    @Input() options: any;
    @Input() legend: any;

    @Input() downloadFileName = 'chart-doughnut.png';
    public chart: any;

    saveInstance(chartInstance) {
        // DONT REMOVE IT: fix to avoid "Expression has changed after it was checked" error
        setTimeout(() => {
            this.chart = chartInstance;
        });
    }
}
