/**
 * Information about an external provider and its activation for the entity
 */
export interface ExternalProvider {
    /** Data provider name. Can be enedis or greenAlanyzer */
    dataProvider: SOURCE_TYPE;
    /** Client gave an authorization warrant */
    warrantAuthorization?: boolean;
    /** Users and admins can see load curve */
    loadCurveVisualization?: boolean;
    /** Contractual data can be retrieved */
    contractualData?: boolean;
    /** SIRET associated */
    siret?: string;
}

export enum SOURCE_TYPE {
    GA = 'ga',
    ENEDIS = 'enedis',
}

export enum ENTITY_TYPE {
    SITE = 'site',
    ROUTING_REFERENCE = 'routingreference',
    ZONE = 'zone',
}
