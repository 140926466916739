import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { Site, SitePopulated } from 'app/shared/models/site.interface';
import { ApiService } from '../../../shared/services/api/api.service';
import { PageService } from '../../../shared/services/page/page.service';
import { SessionService } from '../session/session.service';

@Injectable()
export class RoutingReferencesService extends PageService {
    constructor(private sessionService: SessionService, public apiService: ApiService) {
        super(apiService);
    }

    async getRoutingReferences(filters: any = {}, populate = false): Promise<RoutingReference[]> {
        const companyId: string = filters.companyId || this.sessionService.getCompany()._id;
        const url = '/api/routing-references/company/' + companyId + (populate ? '?contracts=1' : '');
        const res = await this.get(url);
        return res.data;
    }

    getRoutingReferencesPopulated(filters: any = {}): Promise<RoutingReference[]> {
        return this.getRoutingReferences(filters, true);
    }

    /**
     * Get routing references associate to sites.
     * Routing references can also be filtered via filters on their site.
     * @param {Param} filters - filters to be applyed on sites and routing references
     * @param {{communicating : boolean}} options - options to pass in queryParams
     */
    async getRoutingReferencesAssociated(filters: Params, options: { communicating?: boolean } = {}): Promise<any[]> {
        const queryParams = Object.keys(options).map(key => `${key}=${options[key]}&`);

        const res = await this.post(`/api/routing-references/sites?${queryParams}`, filters);
        return res.data;
    }

    /**
     * Associate a routing reference to a site
     * @param {string} routingReferenceId - id of routing reference to associate
     * @param {string} siteId - id of site to associate the routing reference to
     * @returns {Promise<Site>} site with routing references not populated
     */
    associateRoutingReferenceToSite(routingReferenceId: string, siteId: string): Promise<Site> {
        return this.put(`/api/routing-references/${routingReferenceId}/move-to/${siteId}`, {});
    }

    /**
     * Create a new site from a routing reference id
     * @param {string} routingReferenceId - id of routing reference to create site from
     * @param {string} companyId - id of company to create site on
     * @returns {Promise<SitePopulated>} site created and populated with the routing reference
     */
    async createSiteFromRoutingReference(routingReferenceId: string, companyId: string): Promise<SitePopulated> {
        const res = await this.put(`/api/routing-references/${routingReferenceId}/create-site`, { companyId });
        return res.data;
    }

    updateStatusRoutingReference(routingReferenceId, newStatus, revertHistoryId = null) {
        return this.put(`/api/routing-references/${routingReferenceId}/status`, { newStatus, revertHistoryId });
    }

    /**
     * Get min and max bills dates of a references.
     * Routing references can also be filtered via filters on their site.
     * @param {string} routingReferenceId - routing reference id
     * @param {{companyId?: string, contract?: string}} options - params to be apply on routing reference bills
     * @returns {Promise<{{start: string, end: string}}>} - start and end are iso date format
     */
    async getRoutingReferenceBillsDates(
        routingReferenceId: string,
        options: {
            companyId?: string;
            contract?: string;
        } = {}
    ): Promise<{ start: string; end: string }> {
        if (!options.companyId) {
            options.companyId = this.sessionService.getCompany()._id;
        }
        const res = await this.get(`/api/routing-references/${routingReferenceId}/bills-dates`, null, options);
        return res.data;
    }

    /**
     * Update reference and alias of a routing reference
     * @param {_id: string; alias: string[]; reference: string;} update - values to update
     */
    public async updateRRefAlias(update: {
        routingReferenceId: string;
        alias: string[];
        reference: string;
    }): Promise<RoutingReference> {
        const response = await this.patch(`/api/routing-references/rename`, { ...update });
        return response.data;
    }
}
