import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReadingsTab } from './monitoring-readings.interface';
import { OperatingReadingsService } from './monitoring-readings.service';

@Component({
    selector: 'ga-monitoring-readings',
    templateUrl: './monitoring-readings.component.html',
    styleUrls: ['../../../styles/navigation.scss', './monitoring-readings.component.scss'],
    providers: [OperatingReadingsService],
})
export class MonitoringReadingsComponent implements OnInit, OnDestroy {
    /** Tabs list */
    public tabs: ReadingsTab[] = [
        {
            key: 'measuringPoints',
            displayKey: 'monitoring_measuring_points',
        },
        {
            key: 'indexReadings',
            displayKey: 'monitoring_reading',
        },
    ];
    /** Selected tab */
    public selectedTab: ReadingsTab = null;

    ngOnInit(): void {
        this.selectedTab = this.tabs[0];
    }
    ngOnDestroy(): void {}

    isTabActive(key: string): boolean {
        return this.selectedTab.key === key;
    }

    getTabClasses(key: string): string[] {
        if (this.isTabActive(key)) {
            return ['citron-border-bottom', 'citron-color', 'tab-active'];
        }
        return [];
    }

    get hasMultipleTabsAllowed(): boolean {
        return this.tabs.filter(x => !x.hidden).length > 1;
    }
}
