import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from 'app/shared/services/translate/translate.service';

@Component({
    selector: 'ga-nodata',
    templateUrl: './no-data.component.html',
    styleUrls: ['./no-data.component.scss'],
    providers: [],
})
export class NoDataComponent implements OnInit {
    /** The data on which we want to check if it exists. */
    @Input() data: any;
    /** No data message to display */
    @Input() message: string = null;

    /**
     * Returns the given no data message or a default one
     * @returns {string}
     */
    get noDataMessage(): string {
        return this.message || this.translateService._('no_data');
    }

    constructor(private translateService: TranslateService) {}
    ngOnInit() {}
}
