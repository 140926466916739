import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

// services
import { MeteoStation } from 'app/shared/models/meteo-station.interface';
import { DjuService } from 'app/shared/services/dju/dju.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { LoadCurveService } from 'app/shared/services/load-curve/load-curve.service';
import { MapService } from 'app/shared/services/map/map.service';
import { ProfilesService } from 'app/shared/services/profiles/profiles.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { LegacyService } from 'app/shared/services/sites/legacy.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

// components
import { SiteDistributionKeyComponent } from 'app/shared/components/sites/distribution-key/distribution-key.component';
import { LegacyDataComponent } from 'app/shared/components/sites/legacy-data/legacy-data.component';

import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import * as moment from 'moment';
import Swal from 'sweetalert2';

// interfaces
import { RoutingReferencePopulated } from 'app/shared/models/routing-reference.interface';
import { SitePopulatedContracts } from 'app/shared/models/site.interface';
import { MeteoService } from 'app/shared/services/meteo/meteo.service';

@Component({
    selector: 'ga-profiles',
    templateUrl: './profiles.component.html',
    styleUrls: ['./profiles.component.scss'],
    providers: [ProfilesService],
})
export class ProfilesComponent implements OnInit {
    routingReferences: RoutingReferencePopulated[] = [];
    properties: any;
    site: SitePopulatedContracts = null;
    loading = true;
    zoneShown: number = null;
    fluidCategory = 'energy';
    fluidCategoryUrl = 'energie';

    deleteZoneSwalOptions: any = {
        title: "Suppression d'une zone",
        text: 'Vous êtes sur le point de supprimer une zone, êtes vous certain de vouloir effectuer cette action ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, la supprimer',
        cancelButtonText: 'Annuler',
    };

    /** Legacy Data **/
    hasLegacyData = false;
    hasLegacyTemplate = false;
    isLegacyLoading = false;
    templateComingSoon = false;
    legacyData: any = [];
    configLegacyData: any = {};
    elementNature: any = null;
    legacyMultizone: boolean;
    @ViewChild(LegacyDataComponent, { static: false })
    private legacyDataComponent: LegacyDataComponent;
    legacyDataCompletion = 0;

    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    @ViewChild('statusToggle', { static: false }) private statusToggle: MatSlideToggle;
    @ViewChild(SiteDistributionKeyComponent, { static: false })
    private siteDistributionKeyComponent: SiteDistributionKeyComponent;

    /** Picture update **/
    allowedTypes: string[] = ['.png', '.jpg', '.jpeg'];
    isPictureUploading = false;

    /** Meteo Information update **/
    availableTemperatures: number[] = [];
    availableTemperaturesCold: number[] = [];
    availableMethods: string[] = [];
    availableMeteoStations: MeteoStation[] = [];
    selectedTemperature: number = null;
    selectedTemperatureCold: number = null;
    selectedMethod: string = null;
    selectedMeteoStation: string = null;

    siteLoaded: Promise<boolean> = null;

    constructor(
        private route: ActivatedRoute,
        private profilesService: ProfilesService,
        private mapService: MapService,
        private sessionService: SessionService,
        private legacyService: LegacyService,
        private snackBar: MatSnackBar,
        private sitesService: SitesService,
        private energyService: EnergyService,
        private loadCurveService: LoadCurveService,
        private filterService: FilterService,
        private djuService: DjuService,
        private meteoService: MeteoService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(async params => {
            /** Initializes data for meteo information dropdowns **/
            await this.initMeteoInformation();
            this.getSiteWithProperties(params['id']);
            this.fluidCategoryUrl = params['fluidCategory'];
            this.fluidCategory = this.energyService.normalizeFluidCategory(this.fluidCategoryUrl);
        });
    }

    /**
     * Init the meteo station informations
     */
    private async initMeteoInformation(): Promise<void> {
        this.availableTemperatures = await this.djuService.getReferenceTemperaturesHot();
        this.availableTemperaturesCold = await this.djuService.getReferenceTemperaturesCold();
        this.availableMethods = await this.djuService.getDjusComputeMethods();
        this.availableMeteoStations = await this.meteoService.getMeteoStations();
    }

    public async onChangeReferenceTemperatureCold(temperature: number): Promise<void> {
        this.selectedTemperatureCold = temperature;

        await this.updateSiteMeteoInformation({
            temperatureReferenceCold: temperature,
        });
    }

    public async onChangeReferenceTemperatureHot(temperature: number): Promise<void> {
        this.selectedTemperature = temperature;

        await this.updateSiteMeteoInformation({
            temperatureReference: temperature,
        });
    }

    /**
     * Handle selection of new dju method
     */
    public async onMethodChange(method: string) {
        this.selectedMethod = method;

        await this.updateSiteMeteoInformation({
            method,
        });
    }

    /**
     * Handle selection of new meteo station
     */
    public async onMeteoStationChange(meteoStation: string) {
        this.selectedMeteoStation = meteoStation;
        await this.updateSiteMeteoInformation({
            meteoStationId: meteoStation,
        });
    }

    async saveProfile(updateLegacyData: boolean = true) {
        // Check for heavy modifications to open a swal in case of legacy data reset.
        const isMultizonedChanged = this.isMultizoneChangedNotSaved;
        const natureChanged = this.isNatureChangedNotSaved;

        if (isMultizonedChanged || (natureChanged && this.elementNature)) {
            const strType = this.legacyMultizone ? 'de la zone' : 'du site';
            const strZone = isMultizonedChanged ? 'le zonage' : '';
            const strNature = natureChanged ? 'la nature' : '';
            const strLiaison = isMultizonedChanged && natureChanged ? 'et' : '';
            const result = await Swal.fire({
                title: 'Êtes-vous sûr ?',
                text: `Vous avez changé ${strZone} ${strLiaison} ${strNature}, ceci entrainera une suppression des données patrimoniales ${strType}.`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui, sauvegarder',
                cancelButtonText: 'Annuler',
            });
            if (result.value) {
                return this.updateSite(isMultizonedChanged, natureChanged, updateLegacyData);
            }
            return;
        }
        return this.updateSite(isMultizonedChanged, natureChanged, updateLegacyData);
    }

    private async updateSite(
        isMultizonedChanged: boolean,
        natureChanged: boolean,
        updateLegacyData: boolean = true,
        notify: boolean = true
    ) {
        try {
            const response = await this.profilesService.updateSite(this.site._id, this.site);
            this.site = this.formatSite(response.data);
            this.routingReferences = this.site.routingReferences;
            if (!updateLegacyData) {
                return response;
            }
            if (isMultizonedChanged || natureChanged) {
                // Reset the legacy data if there have been changes in site nature or simple/multi-zone.
                let zoneId = null;
                if (!isMultizonedChanged && natureChanged) {
                    zoneId = this.site.info.batiments[this.zoneShown]
                        ? this.site.info.batiments[this.zoneShown]._id
                        : null;
                }
                this.isLegacyLoading = true;
                // Before reseting, we need to set the new nature and diviser to load the new templates.
                this.legacyMultizone = this.site.info.diviser;

                // Then we set the nature
                if (this.legacyMultizone) {
                    if (this.site.info.batiments[this.zoneShown]) {
                        this.elementNature = this.site.info.batiments[this.zoneShown].nature || null;
                    } else {
                        this.elementNature = null;
                    }
                } else {
                    this.elementNature = this.site.info.nature;
                }
                if (this.elementNature || isMultizonedChanged) {
                    this.initLegacyLoading();
                    // We delete legacy data from the site/zone
                    await this.resetLegacyData(zoneId, isMultizonedChanged);
                }
            } else {
                if (this.legacyDataComponent) {
                    await this.legacyDataComponent.saveData();

                    // Load current legacy data
                    const zoneId = this.site.info.batiments[this.zoneShown]
                        ? this.site.info.batiments[this.zoneShown]._id
                        : null;
                    await this.getSiteLegacyData(zoneId);
                    if (this.siteDistributionKeyComponent) {
                        await this.siteDistributionKeyComponent.update();
                    }
                }
            }
            if (notify) {
                Swal.fire({
                    icon: 'success',
                    title: 'Site sauvegardé !',
                    showConfirmButton: false,
                    timer: 1200,
                });
            }
            return response;
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erreur',
                text: 'Une erreur est survenue, veuillez réessayer plus tard.',
                showConfirmButton: false,
                timer: 2500,
            });
        }
    }

    async addZone() {
        const newbatiment = {
            name: `Zone ${this.site.info.batiments.length + 1}`,
            routingReferences: [],
        };

        this.site.info.batiments.push(newbatiment);
        const hasBeenUpdated: boolean = await this.checkZoneModificationAndSave(false);
        if (!hasBeenUpdated) {
            // nature changed and multizone changed has been managed by checkZoneModificationAndSave
            await this.updateSite(false, false, false, false);
        }
        Swal.fire({
            icon: 'success',
            title: 'Zone créée avec succès',
            text: 'Site mis à jour.',
            showConfirmButton: false,
            timer: 2500,
        });
    }

    zoneIsShown(i: number): boolean {
        return i === this.zoneShown;
    }

    private async loadTab(i, zone) {
        this.initLegacyLoading();

        this.zoneShown = i;
        this.elementNature = zone.nature;
        if (zone.nature) {
            await this.getSiteLegacyData(zone._id);
        }
    }

    async showZone(i: number) {
        if (this.zoneShown !== i) {
            const zone = this.site.info.batiments[i];
            if (zone) {
                try {
                    await this.checkZoneModificationAndSave(true);
                    await this.loadTab(i, zone);
                } catch (err) {
                    if (err && err.errorCode !== 'error_updating_site') {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: 'Une erreur est survenue, veuillez réessayer plus tard.',
                            showConfirmButton: false,
                            timer: 2500,
                        });
                    }
                }
            }
        }
    }

    /**
     * Check if current zone has been modified and needs update.
     * If so, prompt user then save.
     * @param {boolean} notify - notify that site has been updated if it needs update.
     * @returns {Promise<boolean>} true if site update, false otherwise
     */
    private async checkZoneModificationAndSave(notify: boolean = false): Promise<boolean> {
        const zoneToSave = this.site.info.batiments[this.zoneShown];
        const isMultizonedChanged = this.isMultizoneChangedNotSaved;
        const natureChanged = this.isNatureChangedNotSaved;

        let isSiteUpdated = false;

        const hasZoneChanged =
            zoneToSave &&
            ((zoneToSave.profilCompletion && zoneToSave.profilCompletion.changeDetected) ||
                isMultizonedChanged ||
                natureChanged);
        if (hasZoneChanged) {
            // Set that zone has no modifications to be done as it's going to be saved.
            if (zoneToSave.profilCompletion) {
                zoneToSave.profilCompletion.changeDetected = false;
            }

            const result = await Swal.fire({
                title: 'Modifications détectées',
                text: `Voulez-vous sauvegarder les modifications existantes ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui, sauvegarder',
                cancelButtonText: 'Non',
            });
            if (result.value) {
                try {
                    await this.updateSite(isMultizonedChanged, natureChanged, true, notify);
                    if (this.siteDistributionKeyComponent) {
                        this.siteDistributionKeyComponent.update();
                    }
                    isSiteUpdated = true;
                } catch (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erreur',
                        text: "Une erreur est survenue lors de l'enregistrement du site, veuillez réessayer plus tard.",
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    throw { errorCode: 'error_updating_site' };
                }
            } else if (zoneToSave.profilCompletion) {
                // If site not saved, reset legacy data completion %
                zoneToSave.profilCompletion.legacyData = zoneToSave.profilCompletion.oldlegacyDataCalculated;
            }
        }
        // Set old legacy data as null, to have it set after next modifications.
        if (zoneToSave && zoneToSave.profilCompletion) {
            zoneToSave.profilCompletion.oldlegacyDataCalculated = null;
        }
        return isSiteUpdated;
    }

    getActiveClass(i: number): string {
        return this.zoneShown === i ? 'tab-active' : '';
    }

    addRoutingReferenceInZone(zone, routingReference) {
        if (!zone.routingReferences.some(rr => rr._id === routingReference._id)) {
            zone.routingReferences.push(routingReference);
            this.generateSharedRoutingReferences();
        }
    }

    removeRoutingReferenceInZone(zone, routingReference) {
        // delete routing references
        zone.routingReferences = zone.routingReferences.filter(rr => rr._id !== routingReference._id);
        this.generateSharedRoutingReferences();
    }

    /*
     * Set in storage the current site in last visited sites for dashboard display
     */
    setLastVisitedSites(site) {
        this.sessionService.setLastVisitedSites(site);
    }

    async removeZone(i: number) {
        const zone = this.site.info.batiments[i];
        if (zone) {
            try {
                // Delete zone only if _id exists. If doesn't exist, means that the zone has not been saved.
                // So it would delete all the site legacy data.
                if (zone._id) {
                    await this.resetLegacyData(zone._id, true, false);
                }
                this.site.info.batiments.splice(i, 1);
                await this.showZone(0);
                await this.saveProfile(false);
            } catch (err) {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Une erreur est survenue, veuillez réessayer plus tard.',
                    showConfirmButton: false,
                    timer: 2500,
                });
            }
        }
    }

    getSitePictureAsBackground() {
        return this.mapService.getSitePictureAsBackground(this.site);
    }

    removePdl(pdl) {
        this.profilesService
            .deleteRoutingReference(this.site._id, pdl._id)
            .then(site => {
                if (site.data && site.data.routingReferences) {
                    this.site = this.formatSite(site.data);
                    this.routingReferences = site.data.routingReferences;
                }
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Une erreur est survenue, veuillez réessayer plus tard.',
                    showConfirmButton: false,
                    timer: 2500,
                });
            });
    }

    private formatSite(site: any): any {
        if (!site.info.organization) {
            site.info.organization = this.properties.organization.id;
        }

        site.info.codification = site.info.codification || 0;
        site.info.surface = site.info.surface || 0;
        site.info.numberAscenseur = site.info.numberAscenseur || 0;
        site.info.numberOfPeople = site.info.numberOfPeople || 0;
        site.info.buildDate = site.info.buildDate || '';
        site.info.diviser = site.info.diviser || false;

        if (site.info.profilCompletion && site.info.profilCompletion.legacyData) {
            this.legacyDataCompletion = site.info.profilCompletion.legacyData;
        } else {
            this.legacyDataCompletion = 0;
        }

        site.dju.method = site.dju.method || 'costic';
        site.dju.temperatureReference = site.dju.temperatureReference || 19;
        site.dju.temperatureReferenceCold = site.dju.temperatureReferenceCold || 18;
        site.meteoStation = site.meteoStation || null;

        return site;
    }

    private generateSharedRoutingReferences() {
        const sharedRoutingReferences = this.site.info.sharedRoutingReferences || [];

        // list all routing references and associated zone. With at least 2 zones associated
        const routingReferences = this.createRoutingReferenceListWithBatiments();

        const sharedPdlsArray = routingReferences.map(sharedRRef => {
            // search if routing reference were already shared
            const searchRf = sharedRoutingReferences.find(x => x.routingReference === sharedRRef.routingReference);

            // If not found, it means it's a newly shared pdl
            if (!searchRf) {
                const ratio = 1 / sharedRRef.zones.length;
                return {
                    routingReference: sharedRRef.routingReference,
                    type: 'percent_auto', // By default percent repartition
                    zones: sharedRRef.zones.map(zone => {
                        return { zone, ratio };
                    }),
                };
            }
            // If found, we just have to update the zones contained in the shared routing reference
            const zones = [];
            sharedRRef.zones.forEach(zone => {
                const searchZone = searchRf.zones.find(x => x.zone === zone);
                if (searchZone) {
                    zones.push(searchZone);
                } else {
                    zones.push({
                        zone,
                        ratio: 0,
                    });
                }
            });
            searchRf.zones = zones;
            return searchRf;
        });
        this.site.info.sharedRoutingReferences = sharedPdlsArray;
        if (this.siteDistributionKeyComponent) {
            this.siteDistributionKeyComponent.update();
        }
    }

    /**
     * @returns list of zones associated to each routing reference, if there is more than one zone associated.
     */
    private createRoutingReferenceListWithBatiments(): Array<{ routingReference: string; zones: string[] }> {
        const routingReferences = [];

        this.site.routingReferences.forEach(routingReference => {
            const zones = [];
            this.site.info.batiments.forEach(zone => {
                const hasPdl = zone.routingReferences.some(x => x._id === routingReference._id);
                if (hasPdl) {
                    zones.push(zone._id);
                }
            });
            if (zones.length > 1) {
                routingReferences.push({
                    routingReference: routingReference._id,
                    zones,
                });
            }
        });

        return routingReferences;
    }

    /**
     * Update routing reference zone ratio when user change it in the view
     */
    public async updateSharedRoutingReference(sharedRRefs: any[]): Promise<void> {
        this.site.info.sharedRoutingReferences = sharedRRefs;
    }

    private async getSiteWithProperties(id: string) {
        try {
            this.properties = await this.profilesService.getSiteProperties();
            const response = await this.profilesService.getSiteProfile(id);
            this.site = this.formatSite(response.data);
            this.selectedMeteoStation = this.site.meteoStation;
            this.selectedMethod = this.site.dju.method;
            this.selectedTemperature = this.site.dju.temperatureReference;
            this.selectedTemperatureCold = this.site.dju.temperatureReferenceCold;
            this.routingReferences = this.site.routingReferences;
            this.loading = false;

            this.setLastVisitedSites(this.site);

            this.legacyMultizone = this.site.info.diviser;
            // Au chargement, si le site n'est pas multizone
            if (!this.site.info.diviser) {
                this.elementNature = this.site.info.nature;
                // Charger les données si une nature est sélectionnée
                if (this.site.info.nature) {
                    this.getSiteLegacyData();
                }
                // Si le site est multi-zone
            } else {
                // Charger les données de la première zone si pas de zone en cours.
                if (this.site.info.batiments && this.site.info.batiments.length && this.zoneShown === null) {
                    this.loadTab(0, this.site.info.batiments[0]);
                }
            }
        } catch (e) {
            this.properties = [];
            this.site = null;
            this.routingReferences = [];
            this.loading = false;
        }
    }

    private async getSiteLegacyData(zoneId?: string) {
        this.isLegacyLoading = true;

        try {
            const dataTemplate = await this.legacyService.getConfigTemplate(this.elementNature);

            this.configLegacyData = dataTemplate;
            this.hasLegacyTemplate = true;

            try {
                const dataLegacy = await this.legacyService.getLegacyData(this.site._id, zoneId);
                this.legacyData = [];
                dataLegacy.data.forEach(val => {
                    const obj = {
                        key: val.key,
                        value: val.values[0] ? val.values[0].value : null,
                    };
                    this.legacyData.push(obj);
                });
                this.hasLegacyData = true;
                this.isLegacyLoading = false;
            } catch (error) {
                this.legacyData = [];
                this.isLegacyLoading = false;
            }
        } catch (err) {
            this.isLegacyLoading = false;
            if (err.errorCode === 'no_template') {
                this.templateComingSoon = true;
            }
        }
        return this.legacyData;
    }

    /**
     * Reset legacy data for a site/zone
     * @param {string} zoneId - id of the zone
     * @param {boolean} fullDelete - fully delete legacy data or not. If false, don't delete common data (like surface, construction date, ...)
     * @param {boolean} load - load the site/zone legacy data after reseting to be sure to display the lastest legacy data
     * (partial delete and check that legacy data correctly deleted).
     */
    private async resetLegacyData(zoneId?, fullDelete = false, load = true) {
        const result = await this.legacyService.resetLegacyData(this.site._id, zoneId, fullDelete);
        if (load) {
            return this.getSiteLegacyData(zoneId);
        }
        return result;
    }

    private initLegacyLoading() {
        this.hasLegacyData = false;
        this.hasLegacyTemplate = false;
        this.isLegacyLoading = true;
        this.legacyData = [];
        this.configLegacyData = {};
        this.templateComingSoon = false;
    }

    toggleMultiZone() {
        // this.elementNature = this.site.info.diviser ? this.site.info.batiments[this.zoneShown].nature : this.site.info.nature;
        this.zoneIsShown = null;
    }

    getAddressToDisplay(site) {
        const streetNumber = site.streetNumber || '';
        const zipcode = site.zipcode || '';
        return site.city ? `${streetNumber} ${site.streetName} ${zipcode} ${site.city}` : site.name;
    }

    get followUpLink() {
        return `/${this.fluidCategoryUrl}/suivi`;
    }
    get followuUpParams(): Params {
        const params: Params = {
            [this.filterService.getParamAlias('sites')]: this.site._id,
        };
        if (this.sessionService.getCompanyId() !== this.site.company) {
            params[this.filterService.getParamAlias('companies')] = this.site.company;
        }
        return params;
    }
    get loadCurveLink(): string {
        return this.loadCurveService.loadCurveLink;
    }

    /**
     * Load curve params : site id and period over the last month
     */
    get loadCurveParams(): Params {
        const { ds, de } = this.loadCurveService.formatDatesParams(
            moment().subtract(1, 'month'),
            moment().subtract(1, 'd')
        );
        const params: Params = {
            s: this.site._id,
            ds,
            de,
        };
        if (this.sessionService.getCompanyId() !== this.site.company) {
            params[this.filterService.getParamAlias('company')] = this.site.company;
        }
        return params;
    }

    /**
     * Display load curve redirection button only if the selected site is communicating
     * @returns {boolean} true if
     */
    get displayLoadCurveBtn(): boolean {
        return this.site.communicating;
    }

    getMergeSiteLink() {
        return `/${this.fluidCategoryUrl}/merge/${this.site._id}`;
    }

    get displayLegacy() {
        const isNatureChanged = this.isNatureChangedNotSaved;
        const isMultizoneChanged = this.isMultizoneChangedNotSaved;
        return (
            !isNatureChanged &&
            this.elementNature &&
            !isMultizoneChanged &&
            !this.isLegacyLoading &&
            this.hasLegacyData &&
            this.hasLegacyTemplate
        );
    }

    get isMultizoneChangedNotSaved() {
        return this.legacyMultizone !== this.site.info.diviser;
    }

    get isNatureChangedNotSaved() {
        if (this.site.info.diviser) {
            if (this.site.info.batiments && this.site.info.batiments[this.zoneShown]) {
                return this.elementNature !== this.site.info.batiments[this.zoneShown].nature;
            } else {
                return false;
            }
        } else {
            return this.elementNature !== this.site.info.nature;
        }
    }

    get legacyWarningMessage() {
        let message = '';
        if (!this.elementNature) {
            message =
                'Aucune nature sélectionnée. Veuillez selectionner un nature puis Sauvegarder pour éditer les données patrimoniales.';
        }
        if (this.isMultizoneChangedNotSaved) {
            message =
                'Vous avez changé le zonage du site, veuillez Sauvegarder vos modifications pour éditer les données patrimoniales.';
        }
        if (this.isNatureChangedNotSaved) {
            message =
                'Vous avez changé la nature de votre zone, veuillez Sauvegarder vos modifications pour éditer les données patrimoniales. ';
        }
        if (message && this.elementNature) {
            message += ' Ces données étant relatives à une nature de bâtiment, elles seront écrasées.';
        }
        if (this.templateComingSoon && !message) {
            return 'Les données patrimoniales pour cette nature de site arrivent bientôt';
        }
        return message;
    }

    async toggleStatus(value) {
        try {
            const response = await this.sitesService.updateStatusSite(this.site._id, value.checked);
            if (this.site) {
                this.updateSitePDLStatus(response.data);
                // Message to revert action if not wanted
                const message = value.checked ? 'activé' : 'désactivé';
                const snack = this.snackBar.open(`Vous avez ${message} le site`, 'Annuler', {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                // If cancel pressed, call for revert then re-update the front display history list
                snack.onAction().subscribe(async () => {
                    try {
                        // Get last history
                        const lastHistory = this.site.status.history[this.site.status.history.length - 1];
                        const res = await this.sitesService.updateStatusSite(
                            this.site._id,
                            value.checked,
                            lastHistory._id
                        );
                        this.updateSitePDLStatus(res.data);
                    } catch (e) {
                        this.handleStatusApiError(false, e);
                    }
                });
            }
        } catch (error) {
            this.handleStatusApiError(true, error);
        }
    }

    get siteStatus() {
        if (this.site && this.site.status) {
            return this.site.status.active;
        }
        return false;
    }

    get routingReferenceStatusText() {
        return this.siteStatus ? 'Actif' : 'Inactif';
    }

    private sortHistoryDateAsc() {
        if (this.site && this.site.status && this.site.status.history) {
            this.site.status.history.sort((a, b) => (a.date < b.date ? -1 : 1));
        }
    }

    /**
     * Handle error on status changed.
     * @param {boolean} resetToggle - reset the toggle back to its original state
     * @param {ApiCitronError} err - errorCode is used to display the correct message
     * @return {(error) => any}
     */
    private handleStatusApiError(resetToggle: boolean, err: ApiCitronError) {
        if (resetToggle) {
            // Timeout so that it goes back to the initial place not instantaneously.
            setTimeout(() => {
                this.statusToggle.toggle();
            }, 400);
        }

        const msg = {
            statusInconsistent_deactivationDate_too_close:
                "Il doit s'être écoulé 3 mois sans données pour pouvoir désactiver un PDL",
            default: 'Une erreur est survenue, merci de réessayer ultérieurement.',
        };
        const display: string = msg[err.errorCode] ? msg[err.errorCode] : msg.default;

        this.snackBar.open(display, 'Masquer', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }

    private updateSitePDLStatus(site) {
        this.site.status = site.status;
        this.sortHistoryDateAsc();
        if (site.routingReferences && site.routingReferences.length) {
            this.routingReferences.forEach(pdl => {
                const pdlSearch = site.routingReferences.find(x => x._id === pdl._id);
                if (pdlSearch) {
                    pdl.status = pdlSearch.status;
                }
            });
        }
    }

    get siteProfileCompletion() {
        if (this.site && this.site.info && this.site.info.profilCompletion && this.site.info.profilCompletion.general) {
            return this.site.info.profilCompletion.general;
        }
        return 0;
    }

    get siteLegacyDataCompletion() {
        return this.legacyDataCompletion;
    }

    computeProfileCompletionLegacyData(prog) {
        if (this.site) {
            if (!this.site.info.profilCompletion) {
                this.site.info.profilCompletion = {};
            }
            if (this.site.info.diviser) {
                const zone = this.site.info.batiments[this.zoneShown];
                if (!zone.profilCompletion) {
                    zone.profilCompletion = {};
                }

                if (
                    zone.profilCompletion.oldlegacyDataCalculated !== null &&
                    zone.profilCompletion.oldlegacyDataCalculated !== undefined &&
                    prog.changed
                ) {
                    zone.profilCompletion.changeDetected = true;
                }
                if (!prog.changed) {
                    zone.profilCompletion.changeDetected = false;
                }

                if (!zone.profilCompletion.oldlegacyDataCalculated) {
                    zone.profilCompletion.oldlegacyDataCalculated = prog.value;
                }

                zone.profilCompletion.legacyData = prog.value;
            } else {
                this.site.info.profilCompletion.legacyData = prog.value;
            }
        }
    }

    /**
     * @returns true if the site has shared routing references, false otherwise
     */
    get hasSharedRoutingReferences(): boolean {
        if (!this.site) {
            return false;
        }
        return (
            this.site.info.diviser &&
            this.site.info.sharedRoutingReferences &&
            this.site.info.sharedRoutingReferences.length
        );
    }

    /**
     * @returns {boolean} Returns true if fluid category is either energy or water, false if vehicles
     */
    get isCategoryRoutingReference(): boolean {
        return this.energyService.isCategoryForRoutingReference(this.fluidCategory);
    }

    /**
     * Get entities list title
     * @returns {string}
     */
    get entitiesListTitle(): string {
        if (this.isCategoryRoutingReference) {
            return 'Points de livraison du site';
        }
        return 'Véhicules du site';
    }

    /**
     * Custom picture part
     */
    /**
     * Upload picture and set it to site on selected file.
     * @param {Event} e - event from file input
     */
    onSelectFile(e): void {
        const files = Array.from(e.target.files);
        if (files.length) {
            const file: any = files[0];
            this.isPictureUploading = true;
            this.profilesService.updateSitePicture(file, this.site._id).subscribe(
                event => {
                    if (event.type === HttpEventType.Response) {
                        const data = event.body['data'];
                        this.site.info.img = data.info.img;
                        this.isPictureUploading = false;
                    }
                },
                err => {
                    const error = err.error;
                    this.isPictureUploading = false;
                    this.openSwalFromMessage(error.errorCode);
                }
            );
            e.target.value = '';
        }
    }

    /**
     * Open remove picture confirmation, and if confirmed, remove picture
     */
    async openRemovePicture(): Promise<void> {
        const result = await Swal.fire({
            title: 'Supprimer la photo ?',
            text: 'Cette action est irreversible.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Annuler',
        });

        if (result.value) {
            try {
                this.isPictureUploading = true;
                const res = await this.profilesService.deleteSitePicture(this.site._id);
                this.site.info.img = res.data.info.img;
            } catch (err) {
                this.openSwalFromMessage(err.errorCode);
            }
            this.isPictureUploading = false;
        }
    }

    /**
     * @returns true if site has a custom picture, false otherwise
     */
    get hasSiteCustomPicture(): boolean {
        return Boolean(this.site && this.site.info && this.site.info.img);
    }

    openSwalFromMessage(msg: string) {
        const map = {
            file_type_not_allowed: [
                'Type de fichier non correct',
                `Le format de fichier n'est pas accepté. Les types de fichiers autorisés sont les suivants : ${this.allowedTypes.join(
                    ', '
                )}`,
                'error',
            ],
            updating_site_picture_failed: [
                "Erreur lors de l'upload",
                "Impossible de changer l'image de ce site. Merci de réessayer ultérieurement.",
                'error',
            ],
            file_size_max: ['Fichier trop volumineux', 'La taille maximale autorisée est de 5Mo.', 'error'],
            delete_site_picture_failed: [
                'Erreur lors de la suppression',
                "Impossible de supprimer l'image de ce site. Merci de réessayer ultérieurement.",
                'error',
            ],
        };
        let values = map[msg];
        if (!values) {
            values = ['Une erreur est survenue', 'Merci de réessayer ultérieurement', 'error'];
        }
        Swal.fire(values[0], values[1], values[2]);
    }

    /**
     * Updates the site meteo information
     */
    async updateSiteMeteoInformation(data: {
        meteoStationId?: string;
        temperatureReference?: number;
        temperatureReferenceCold?: number;
        method?: string;
    }): Promise<{
        temperatureReference: number;
        temperatureReferenceCold: number;
        meteoStation: string;
        method: string;
    }> {
        const siteId = this.site._id;
        const { dju, meteoStation } = await this.profilesService.updateSiteMeteoInformation(siteId, data);
        this.site.dju = dju;
        this.site.meteoStation = meteoStation;
        return {
            temperatureReference: dju.temperatureReference,
            temperatureReferenceCold: dju.temperatureReferenceCold,
            method: dju.method,
            meteoStation,
        };
    }
}
