import { Injectable } from '@angular/core';

import { ExternDataInfo } from 'app/shared/components/admin/company/loading-curves/external-data-assignations.interface';
import {
    ExternalAssociation,
    ExternalAssociationsLocalType,
    ExternalAssociationsType,
} from 'app/shared/models/external-associations.interface';

import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

@Injectable()
export class ExternalDataService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Associate an external data to a entity
     * @param {ExternalAssociationsLocalType} entityType - entity type
     * @param {{externalId, site?, zone?, routingreference?}} data - to set the external data
     * @returns {Promise<ExternalAssociation>}
     */
    async associateExternalData(entityType: ExternalAssociationsLocalType, data: any): Promise<ExternalAssociation> {
        const res = await this.post(`/api/external-provider/association/${entityType}`, data);
        return res.data;
    }

    /**
     * Remove link of an external data of an entity
     * @param {ExternalAssociationsLocalType} entityType - entity type
     * @param {*} data - data to find the external data (site, zone, routing reference, externalId)
     * @param {string} removeId - internal id of the external data subdocument.
     * @returns {Promise<ExternalAssociation>}
     */
    async dissociateExternalData(
        entityType: ExternalAssociationsLocalType,
        data: any,
        removeId: string
    ): Promise<ExternalAssociation> {
        const res = await this.post(`/api/external-provider/association/${entityType}?remove=${removeId}`, data);
        return res.data;
    }

    /**
     * Get external data basic information for simple display. Contains a title, subtitle, a start date and an end date
     * @param {any} data - external data to get the information of.
     * @return {Promise<ExternDataInfo>}
     */
    async getExternalDataInformation(data: ExternDataInfo): Promise<ExternDataInfo> {
        switch (data.type) {
            case 'ga':
                const resGa = await this.getGAProject(data.sourceData);
                if (resGa && resGa.data) {
                    const d = resGa.data;
                    data.title = `${d.client} > ${d.site} > ${d.name}`;
                    data.startDate = d.startDate;
                    data.endDate = d.endDate;
                }
                break;
            case 'enedis': {
                data.title = 'Enedis Not Implemented Yet';
            }
        }
        data.loaded = true;
        return data;
    }

    /**
     * Get entity's external associations
     * @param {string} entityId - id of the entity
     * @param {ExternalAssociationsLocalType} entityType - type of entity
     * @param {ExternalAssociationsType} type - provider type (eg. 'ga')
     * @returns {Promise<ExternalAssociation[]>}
     */
    async getEntityExternalAssociations(
        entityId: string,
        entityType: ExternalAssociationsLocalType,
        type?: ExternalAssociationsType
    ): Promise<ExternalAssociation[]> {
        const options = {
            [entityType]: entityId,
            entityType,
        };
        if (type) {
            options.provider = type;
        }
        const res = await this.get('/api/external-datas/entity', null, options);
        return res.data;
    }

    async getGAClients() {
        return this.get('/api/external-datas/ga/clients');
    }

    async getGASites(clientId: string) {
        return this.get('/api/external-datas/ga/sites?client=' + clientId);
    }

    async getGAProjects(siteId: string) {
        return this.get('/api/external-datas/ga/projects?site=' + siteId);
    }

    async getGAProject(projectId: string) {
        return this.get('/api/external-datas/ga/project?project=' + projectId);
    }

    /** Returns the social denomination found for given company, or null otherwise */
    public async getSocialDenomination(companyId: string): Promise<string | null> {
        const associations = await this.getEntityExternalAssociations(companyId, 'company', 'enedis');

        if (associations && associations.length) {
            // If multiple association (should not happens), take the last one as it's the most recent one.
            const association = associations[associations.length - 1];

            if (association.sourceData && association.sourceData.socialDenomination) {
                return association.sourceData.socialDenomination;
            }
        }

        return null;
    }
}
