import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { PageService } from '../../../shared/services/page/page.service';

@Injectable()
export class AdminUserService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }
}
