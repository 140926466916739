import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-rules',
    templateUrl: './rules.component.html',
    styleUrls: ['./rules.component.scss'],
    providers: [],
})
export class RulesComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
