import { Injectable } from '@angular/core';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { TreeviewI18n, TreeviewSelection } from 'ngx-treeview';

@Injectable()
export class GATreeviewI18n extends TreeviewI18n {
    constructor(protected translateService: TranslateService) {
        super();
    }

    /**
     * Return the nb of selected entities to display
     * @param {TreeviewSelection} selection
     * @returns {string}
     */
    getText(selection: TreeviewSelection): string {
        if (selection.uncheckedItems.length === 0) {
            return this.translateService._('all_fp');
        }

        switch (selection.checkedItems.length) {
            case 0:
            case 1:
                return `${selection.checkedItems.length} ${this.translateService._('entity')}`;
            default:
                return `${selection.checkedItems.length} ${this.translateService._('entities')}`;
        }
    }

    /**
     * Text displayed when all checkboxes are selected
     * @returns {string}
     */
    getAllCheckboxText(): string {
        return this.translateService._('all_fp');
    }

    /**
     * Text displayed as the placeholder
     * @returns {string}
     */
    getFilterPlaceholder(): string {
        return this.translateService._('entity_name');
    }

    /**
     * Text displayed when there isn't any item found matching the given input
     * @returns {string}
     */
    getFilterNoItemsFoundText(): string {
        return this.translateService._('no_correspondance');
    }

    /**
     * Text displayed as the tooltip to expand or collapse the treeview
     * @param {boolean} isCollapse
     * @returns {string}
     */
    getTooltipCollapseExpandText(isCollapse: boolean): string {
        return isCollapse ? this.translateService._('expand') : this.translateService._('collapse');
    }
}
