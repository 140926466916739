import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginationService } from 'app/shared/components/pagination/front/pagination-front.service';
import * as _ from 'lodash';
import {
    CompletenessTableProperties,
    RoutingRefDateCompleteness,
    RRefClicked,
} from '../completeness-data-tab/completeness-data-tab.interface';

@Component({
    selector: 'bills-table-completeness',
    templateUrl: './bills-table-completeness.component.html',
    styleUrls: ['./bills-table-completeness.component.scss'],
    providers: [PaginationService],
})
export class BillsTableCompletenessComponent implements OnInit {
    constructor(private paginationService: PaginationService) {}

    /**
     * Number of columns in the table
     */
    public get nbColumns(): number {
        return this.tableProperties.completenessComponents.length + 2;
    }

    /**
     * Return the header message key to display
     */
    public get tableHeaderMessage(): string {
        return this.tableProperties.data.length > 1 ? 'routingReferences' : 'routingReference';
    }

    /**
     * Returns the elements to display matching the current pagination
     */
    get routingReferencesSorted(): RoutingRefDateCompleteness[] {
        return this.tableProperties.data.slice(
            this.paginationService.pagination.indexStart,
            this.paginationService.pagination.indexEnd + 1
        );
    }

    @Input() tableProperties: CompletenessTableProperties = {
        data: [],
        isLoading: false,
        completenessComponents: [],
        dateSelected: null,
    };

    /**
     * Routing reference view details clicked
     */
    @Output() detailsClicked: EventEmitter<RRefClicked> = new EventEmitter<RRefClicked>();

    ngOnInit() {}

    /**
     * Check if the table has data
     * @returns {boolean}
     */
    public hasData(): boolean {
        return this.tableProperties.data.length > 0;
    }

    /**
     * Return the percentage width of a component cell (each width is eaqual)
     * @returns {string} percentage
     */
    public getCellWidthPercentage(): string {
        /**
         * Routing reference number takes 10% - so 90% left to distribute equally between each comopnent
         */
        return `${90 / this.tableProperties.completenessComponents.length}%`;
    }

    /***************************
     **** Pagination & sort ****
     ***************************
     */

    /**
     * Update the sort caret class depending on the user action.
     * @param {string} header
     * @returns {string[]} css classes
     */
    public getClassesSort(header: string): string[] {
        return this.paginationService.getClassesSort(header);
    }

    /**
     * Handle sort click to update the routing references list
     * @param {string} header column header selected for sort
     */
    public onSort(header: string): void {
        /**
         * Update the pagination elements
         */
        this.paginationService.updateSort(header);

        /**
         * Display elements matching new sort & set first page
         */
        this.reinitTable();
    }

    /**
     * Sort table data with the current pagination & set first page.
     */
    public reinitTable(): void {
        this.paginationService.setFirstPage();
        this.sortTable();
    }

    /**
     * Sort the table data with the current pagination values
     */
    private sortTable() {
        const order = this.paginationService.sort.asc ? 'asc' : 'desc';
        const header = this.paginationService.sort.field;
        const field = this.tableProperties.completenessComponents.includes(header)
            ? `percentages.${this.paginationService.sort.field}`
            : header;
        this.tableProperties.data = _.orderBy(this.tableProperties.data, [field], [order]);
    }

    /*********************************************
     * routing reference completeness details button to open a modal
     *********************************************/

    /**
     * on routing reference completeness details button clicked
     * @param {RoutingRefDateCompleteness} routingRef
     */
    public detailsClick(routingRef: RoutingRefDateCompleteness): void {
        this.detailsClicked.emit({
            _id: routingRef.routingReferenceId,
            reference: routingRef.routingReference,
            company: routingRef.companyId,
            energyType: routingRef.fluid,
        });
    }
}
