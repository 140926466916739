import { Component, Input, OnInit } from '@angular/core';
import { GenericTableBill } from 'app/shared/models/fluid-tables.interface';

interface Row {
    name: string;
    billProperty: string;
    subLabels: Array<{ key: string; name: string; isTotal?: boolean }>;
    hasBreak: boolean;
}

interface FluidRows {
    hasTitle: boolean;
    rows: Row[];
}

interface FluidTypeRows {
    year: { [fluid: string]: FluidRows };
    month: { [fluid: string]: FluidRows };
    ratio: { [fluid: string]: FluidRows };
}

@Component({
    selector: 'pdl-table',
    templateUrl: './pdl-table.component.html',
    styleUrls: ['./pdl-table.component.scss'],
})
export class PdlTableComponent implements OnInit {
    @Input() bills: GenericTableBill[];
    @Input() fluid: string;
    @Input() type: string;

    fluidRows: FluidRows;

    rows: FluidTypeRows = {
        year: {
            elec: {
                rows: [
                    {
                        name: 'Montant de la facture',
                        billProperty: 'amount',
                        subLabels: [{ key: 'total', name: null }],
                        hasBreak: false,
                    },
                    {
                        name: 'Fourniture',
                        billProperty: 'consumption',
                        subLabels: [
                            { key: 'global', name: 'Coût de consommation (€)' },
                            { key: 'globalRatio', name: 'Coût de consommation (c€/kWh)' },
                            { key: 'renewable', name: 'Energies Renouvelables (€)' },
                            { key: 'renewableRatio', name: 'Energies Renouvelables (c€/kWh)' },
                            { key: 'capacity', name: 'Mécanisme de capacité (€)' },
                            { key: 'capacityRatio', name: 'Mécanisme de capacité (c€/kWh)' },
                            { key: 'cee', name: 'CEE (€)' },
                            { key: 'ceeRatio', name: 'CEE (c€/kWh)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                            { key: 'totalRatio', name: 'Sous-total (c€/kWh)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Abonnement (fournisseur et distributeur)',
                        billProperty: 'subscription',
                        subLabels: [
                            { key: 'subscriptionFixed', name: 'Abonnement fournisseur (c€/kWh)' },
                            { key: 'fixedRacking', name: 'Composante de Soutirage Fixe (c€/kWh)' },
                            { key: 'counting', name: 'Composante de Comptage (c€/kWh)' },
                            { key: 'management', name: 'Composante de Gestion (c€/kWh)' },
                            { key: 'total', name: 'Sous-total (c€/kWh)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Services réseau',
                        billProperty: 'networkService',
                        subLabels: [
                            { key: 'meterRent', name: 'Location de compteur (c€/kWh)' },
                            { key: 'miscServices', name: 'Services divers (c€/kWh)' },
                            { key: 'penalities', name: 'Pénalités diverses (c€/kWh)' },
                            { key: 'racking', name: 'Composante de soutirage variable (c€/kWh)' },
                            { key: 'overtaking', name: 'Dépassements (c€/kWh)' },
                            { key: 'reactive', name: 'Energie réactive (c€/kWh)' },
                            { key: 'total', name: 'Sous-total (c€/kWh)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Taxes',
                        billProperty: 'taxes',
                        subLabels: [
                            { key: 'cta', name: 'CTA (c€/kWh)' },
                            { key: 'cspe', name: 'CSPE (c€/kWh)' },
                            { key: 'tdcfe', name: 'TDCFE (c€/kWh)' },
                            { key: 'tccfe', name: 'TCCFE (c€/kWh)' },
                            { key: 'tcfe', name: 'TCFE (c€/kWh)' },
                            { key: 'total', name: 'Sous-total (c€/kWh)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Total HTVA (c€/kWh)',
                        billProperty: 'totalHTVA',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                    {
                        name: 'Total TTC (c€/kWh)',
                        billProperty: 'totalTTC',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                ],
                hasTitle: true,
            },
        },
        month: {
            elec: {
                rows: [
                    {
                        name: 'Fourniture',
                        billProperty: 'consumption',
                        subLabels: [
                            { key: 'globalQuantity', name: 'Consommation (kWh)' },
                            { key: 'globalCost', name: 'Coût de consommation (€)' },
                            { key: 'renewable', name: 'Energies Renouvelables (€)' },
                            { key: 'capacity', name: 'Mécanisme de capacité (€)' },
                            { key: 'cee', name: 'CEE (€)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Abonnement (fournisseur et distributeur)',
                        billProperty: 'subscription',
                        subLabels: [
                            { key: 'subscriptionFixed', name: 'Abonnement fournisseur (€)' },
                            { key: 'fixedRacking', name: 'Composante de Soutirage Fixe (€)' },
                            { key: 'counting', name: 'Composante de Comptage (€)' },
                            { key: 'management', name: 'Composante de Gestion (€)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Services réseau',
                        billProperty: 'networkService',
                        subLabels: [
                            { key: 'meterRent', name: 'Location de compteur (€)' },
                            { key: 'miscServices', name: 'Services divers (€)' },
                            { key: 'penalities', name: 'Pénalités diverses (€)' },
                            { key: 'racking', name: 'Composante de soutirage variable (€)' },
                            { key: 'overtaking', name: 'Dépassements (€)' },
                            { key: 'reactive', name: 'Energie réactive (€)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Taxes',
                        billProperty: 'taxes',
                        subLabels: [
                            { key: 'ctaCost', name: 'CTA (€)' },
                            { key: 'cspeQuantity', name: 'CSPE (kWh)' },
                            { key: 'cspeCost', name: 'CSPE (€)' },
                            { key: 'tdcfeQuantity', name: 'TDCFE (kWh)' },
                            { key: 'tdcfeCost', name: 'TDCFE (€)' },
                            { key: 'tccfeQuantity', name: 'TCCFE (kWh)' },
                            { key: 'tccfeCost', name: 'TCCFE (€)' },
                            { key: 'tcfeQuantity', name: 'TCFE (kWh)' },
                            { key: 'tcfeCost', name: 'TCFE (€)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Montant HTVA (€)',
                        billProperty: 'totalHTVA',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                    {
                        name: 'Montant TTC (€)',
                        billProperty: 'totalTTC',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                ],
                hasTitle: true,
            },
            gaz: {
                rows: [
                    {
                        name: 'Consommation',
                        billProperty: 'consumption',
                        subLabels: [
                            { key: 'globalQty', name: 'Quantité (MWh)' },
                            { key: 'globalVolume', name: 'Volume (m3)' },
                            { key: 'globalAmount', name: 'Coût de consommation (€)' },
                            { key: 'renewableAmount', name: 'Energies Renouvelables (€)' },
                            { key: 'ceeAmount', name: 'CEE (€)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Abonnement',
                        billProperty: 'subscription',
                        subLabels: [
                            { key: 'globalAmount', name: 'Abonnement (€)' },
                            { key: 'distribFixedAmount', name: 'Distribution part Fixe (ATRD fixe) (€)' },
                            { key: 'transpFixedAmount', name: 'Transport part Fixe (ATRT fixe) (€)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Services Réseau',
                        billProperty: 'networkServices',
                        subLabels: [
                            { key: 'distributionVariableQty', name: 'Distribution part Variable (ATRD) (MWh)' },
                            { key: 'distributionVariableAmount', name: 'Distribution part Variable (ATRD) (€)' },
                            { key: 'transportVariableQty', name: 'Transport part Variable (ATRT) (MWh)' },
                            { key: 'transportVariableAmount', name: 'Transport part Variable (ATRT) (€)' },
                            { key: 'storageQty', name: 'Stockage (MWh)' },
                            { key: 'storageAmount', name: 'Stockage (€)' },
                            { key: 'capacityAmount', name: 'Capacité (€)' },
                            { key: 'meterRentAmount', name: 'Location compteur (€)' },
                            { key: 'miscServicesAmount', name: 'Services divers (€)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Taxes',
                        billProperty: 'taxes',
                        subLabels: [
                            { key: 'CTAGlobal', name: 'CTA Globale (€)' },
                            { key: 'CTADistrib', name: 'CTA Distribution (€)' },
                            { key: 'CTATransp', name: 'CTA Transport (€)' },
                            { key: 'TICGNQty', name: 'TICGN (MWh)' },
                            { key: 'TICGNAmount', name: 'TICGN (€)' },
                            { key: 'CBMQty', name: 'CBM (MWh)' },
                            { key: 'CBMAmount', name: 'CBM (€)' },
                            { key: 'CTSSGQty', name: 'CTSSG (MWh)' },
                            { key: 'CTSSGAmount', name: 'CTSSG (€)' },
                            { key: 'CSPGQty', name: 'CSPG (MWh)' },
                            { key: 'CSPGAmount', name: 'CSPG (€)' },
                            { key: 'total', name: 'Sous-total (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Montant HTVA (€)',
                        billProperty: 'totalHT',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                    {
                        name: 'Montant TTC (€)',
                        billProperty: 'totalTTC',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                ],
                hasTitle: true,
            },
            heating: {
                rows: [
                    {
                        name: 'Consommation (R1)',
                        billProperty: 'consumption',
                        subLabels: [
                            { key: 'heatingQty', name: 'Consommation chauffage (kWh)' },
                            { key: 'ecsQuantity', name: 'Consommation ECS (kWh)' },
                            { key: 'totalQuantity', name: 'Consommation totale (kWh)', isTotal: true },
                            { key: 'heatingAmount', name: 'Coût consommation chauffage (€)' },
                            { key: 'ecsAmount', name: 'Coût consommation ECS (€)' },
                            { key: 'totalAmount', name: 'Total Coût consommation (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Abonnement (R2)',
                        billProperty: 'subscription',
                        subLabels: [
                            { key: 'heating', name: 'Abonnement chauffage (€)' },
                            { key: 'ecs', name: 'Abonnement ECS (€)' },
                            { key: 'total', name: 'Total Abonnement (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Services divers',
                        billProperty: 'miscellaneous',
                        subLabels: [
                            { key: 'telegestion', name: 'Télégestion (€)' },
                            { key: 'gestion', name: "Gestion de l'energie (€)" },
                            { key: 'total', name: 'Total Services divers (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Montant HTVA (€)',
                        billProperty: 'totalHT',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                    {
                        name: 'Montant TTC (€)',
                        billProperty: 'totalTTC',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                ],
                hasTitle: true,
            },
            cooling: {
                rows: [
                    {
                        name: 'Consommation (R1)',
                        billProperty: 'consumption',
                        subLabels: [
                            { key: 'energyQty', name: 'Consommation Energie (kWh)' },
                            { key: 'volumeQty', name: 'Consommation Volume (m3)' },
                            { key: 'energyAmount', name: 'Coût Consommation Energie (€)' },
                            { key: 'volumeAmount', name: 'Coût Consommation Volume (€)' },
                            { key: 'totalAmount', name: 'Total Coût consommation (€)', isTotal: true },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Abonnement (R2)',
                        billProperty: 'subscription',
                        subLabels: [
                            { key: 'r2', name: 'Abonnement (€)' },
                            { key: 'power', name: 'Puissance souscrite max (kW)' },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Services divers',
                        billProperty: 'miscellaneous',
                        subLabels: [{ key: 'deltaT', name: 'Delta T (°C)' }],
                        hasBreak: true,
                    },
                    {
                        name: 'Montant HTVA (€)',
                        billProperty: 'totalHT',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                    {
                        name: 'Montant TTC (€)',
                        billProperty: 'totalTTC',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                ],
                hasTitle: true,
            },
            water: {
                rows: [
                    {
                        name: 'Production et distribution',
                        billProperty: 'consumption',
                        subLabels: [
                            { key: 'conso', name: 'Coût Consommation (€)' },
                            { key: 'quantity', name: 'Volume (m³)' },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Collecte et traitement des eaux usées (€)',
                        billProperty: 'wasteWaterTreatment',
                        subLabels: [{ key: 'value', name: null }],
                        hasBreak: false,
                    },
                    {
                        name: 'Organismes publics (€)',
                        billProperty: 'publicOrganismAndVAT',
                        subLabels: [{ key: 'value', name: null }],
                        hasBreak: false,
                    },
                    {
                        name: 'Abonnement (€)',
                        billProperty: 'subscription',
                        subLabels: [{ key: 'value', name: null }],
                        hasBreak: false,
                    },
                    {
                        name: 'Montant HTVA (€)',
                        billProperty: 'totalHT',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                    {
                        name: 'Montant TTC (€)',
                        billProperty: 'totalTTC',
                        subLabels: [{ key: 'total', name: null, isTotal: true }],
                        hasBreak: false,
                    },
                ],
                hasTitle: true,
            },
        },
        ratio: {
            gaz: {
                rows: [
                    {
                        name: null,
                        billProperty: 'ratios',
                        subLabels: [
                            { key: 'consumption', name: 'Consommation (€/MWh)' },
                            { key: 'subscription', name: 'Abonnement (€/MWh)' },
                            { key: 'networkServices', name: 'Services réseau (€/MWh)' },
                            { key: 'taxes', name: 'Taxes (€/MWh)' },
                        ],
                        hasBreak: false,
                    },
                ],
                hasTitle: false,
            },
            heating: {
                rows: [
                    {
                        name: null,
                        billProperty: 'ratios',
                        subLabels: [
                            { key: 'consumption', name: 'Consommation R1 (c€ / kWh)' },
                            { key: 'subscription', name: "Coût d'abonnement R2 (c€ / kWh)" },
                            { key: 'miscellaneous', name: 'Services Divers (c€ / kWh)' },
                            { key: 'totalHTVA', name: 'Montant HTVA (c€ / kWh)' },
                            { key: 'totalTTC', name: 'Montant TTC (c€ / kWh)' },
                        ],
                        hasBreak: false,
                    },
                ],
                hasTitle: false,
            },
            cooling: {
                rows: [
                    {
                        name: null,
                        billProperty: 'ratios',
                        subLabels: [
                            { key: 'consumption', name: 'Consommation R1 (c€ / kWh)' },
                            { key: 'subscription', name: "Coût d'abonnement R2 (c€ / kWh)" },
                            { key: 'totalHTVA', name: 'Montant HTVA (c€ / kWh)' },
                            { key: 'totalTTC', name: 'Montant TTC (c€ / kWh)' },
                        ],
                        hasBreak: false,
                    },
                ],
                hasTitle: false,
            },
            water: {
                rows: [
                    {
                        name: 'Production et distribution',
                        billProperty: 'productionAndDistribution',
                        subLabels: [
                            { key: 'subscription', name: "Coût d'abonnement (c€ / m³)" },
                            { key: 'consumption', name: 'Consommation (c€ / m³)' },
                        ],
                        hasBreak: true,
                    },
                    {
                        name: 'Collecte et traitement des eaux usées (c€ / m³)',
                        billProperty: 'wasteWaterTreatment',
                        subLabels: [{ key: 'value', name: null }],
                        hasBreak: false,
                    },
                    {
                        name: 'Organismes publics (c€ / m³)',
                        billProperty: 'publicOrganismAndVAT',
                        subLabels: [{ key: 'value', name: null }],
                        hasBreak: false,
                    },
                    {
                        name: 'Montant HTVA (c€ / m³)',
                        billProperty: 'totalHT',
                        subLabels: [{ key: 'value', name: null }],
                        hasBreak: false,
                    },
                    {
                        name: 'Montant TTC (c€ / m³)',
                        billProperty: 'totalTTC',
                        subLabels: [{ key: 'value', name: null }],
                        hasBreak: false,
                    },
                ],
                hasTitle: true,
            },
        },
    };

    constructor() {}

    ngOnInit() {
        this.fluidRows = this.rows[this.type][this.fluid];
    }
}
