import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CollectService } from 'app/pages/collect/collect.service';
import { PaginationService } from 'app/shared/components/pagination/front/pagination-front.service';
import { PaginationIndexes } from 'app/shared/components/pagination/pagination.interface';
import { UploadStateList } from 'app/shared/constants/upload-state-list.constants';
import { Upload, UploadFile } from 'app/shared/models/upload-file.interface';
import { UploadService } from 'app/shared/services/files/upload.service';
import { PageService } from 'app/shared/services/page/page.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-upload-list',
    templateUrl: './upload-list.component.html',
    styleUrls: ['./upload-list.component.scss'],
    providers: [PageService, PaginationService],
})
export class UploadListComponent implements OnInit {
    @Input() files: Upload[] = [];

    @Output() removeFileClick: EventEmitter<UploadFile> = new EventEmitter();
    @Output() reextractFileClick: EventEmitter<UploadFile> = new EventEmitter();
    @Output() forceExtractClick: EventEmitter<Upload> = new EventEmitter();
    @Input() currentLineSelected = -1;
    @Output() selectElement = new EventEmitter();
    @Input() disableActions = false;

    /** Files to display including pagination */
    public filesSorted: Upload[] = [];

    constructor(
        private collectService: CollectService,
        private uploadService: UploadService,
        private paginationService: PaginationService
    ) {}

    ngOnInit() {}

    async openFileInTab(file: Upload) {
        try {
            await this.uploadService.openFileInTab(file.id);
        } catch (e) {
            Swal.fire(
                'Toutes nos excuses',
                'Le fichier est inaccessible pour le moment. Veuillez réessayer ultérieurement.',
                'warning'
            );
        }
    }

    getIconClass(state: string): string {
        return this.collectService.infos[this.collectService.stateInfo[state].type].icon;
    }

    getMessage(file: Upload): string {
        const message = this.collectService.stateInfo[file.state].message;
        return this.isFileMultiChunk(file.identificationPattern)
            ? message + ' (niv. ' + file.identificationPattern.cutters.length + ')'
            : message;
    }

    getColor(state: string): string {
        return this.collectService.infos[this.collectService.stateInfo[state].type].color;
    }

    getPercent(file: Upload): number {
        const stateInfo = this.collectService.stateInfo[file.state] || { percent: null };
        return stateInfo.percent || file.percent;
    }

    async reextract(file: Upload) {
        const result = await Swal.fire({
            title: 'Êtes-vous sur ?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ré-extraire',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        });

        if (result.value) {
            this.reextractFileClick.emit(file);
        }
    }

    async removeFile(file: Upload) {
        const result = await Swal.fire({
            title: 'Êtes-vous sur ?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        });

        if (result.value) {
            this.removeFileClick.emit(file);
        }
    }

    forceExtract(file: Upload) {
        this.forceExtractClick.emit(file);
    }

    canBeRemoved(state: string): boolean {
        return (
            state === UploadStateList.MULTI_UNCOMPLETED ||
            state === UploadStateList.PATTERN_MISSING ||
            state === UploadStateList.PROPERTY_MISSING ||
            state === UploadStateList.UNTOUCHED ||
            state === UploadStateList.ERROR ||
            state === UploadStateList.NO_PATTERNS ||
            state === UploadStateList.JOBS_DONE ||
            state === UploadStateList.HAS_CHUNKS_NO_PATTERNS ||
            state === UploadStateList.QUALITY_CHECK_FAILED
        );
    }

    public canForceQuality(state: string): boolean {
        return state === UploadStateList.QUALITY_CHECK_FAILED;
    }

    canGeolocForce(state: string): boolean {
        return state === UploadStateList.MULTI_UNCOMPLETED || state === UploadStateList.ERROR;
    }

    getLineActiveClass(index) {
        return index === this.currentLineSelected ? 'line-active' : '';
    }

    selectFile(index) {
        this.currentLineSelected = index;
        this.selectElement.emit(index);
    }

    isFileMultiChunk(identificationPattern) {
        return identificationPattern && identificationPattern.cutters && identificationPattern.cutters.length;
    }

    public updateFileSorted(indexes: PaginationIndexes) {
        this.filesSorted = this.files.slice(indexes.indexStart, indexes.indexEnd + 1);
    }
}
