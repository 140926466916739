import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { NotFoundComponent } from './not-found.component';

const NOTFOUND_ROUTE = [
    {
        path: '',
        component: NotFoundComponent,
        data: {
            title: '404',
        },
    },
];

@NgModule({
    declarations: [NotFoundComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(NOTFOUND_ROUTE)],
})
export class NotFoundModule {}
