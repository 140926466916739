import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { Warrant } from 'app/shared/models/warrant.interface';

import { PageService } from '../page/page.service';
import { EditWarrantParams, GetLatestWarrantParams, GetWarrantParams } from './interfaces';

@Injectable()
export class WarrantService extends PageService {
    /** Retrieve a specific warrant matching with given params */
    public async getWarrant(params: GetWarrantParams): Promise<Warrant | null> {
        if (params.corporateName === '') {
            return null;
        }

        const res = await this.get('/api/warrants', null, params);

        // Trick because if no warrant found, res.data is an empty object
        const warrant = Object.keys(res.data).length ? res.data : null;

        return warrant;
    }

    /** Retrieve latest warrant matching with given params */
    public async getLatestWarrant(params: GetLatestWarrantParams): Promise<Warrant | null> {
        if (params.corporateName === '') {
            return null;
        }

        const res = await this.get('/api/warrants/latest', null, params);

        // Trick because if no warrant found, res.data is an empty object
        const warrant = Object.keys(res.data).length ? res.data : null;

        return warrant;
    }

    /** Update existing warrant, or create a new one otherwise */
    public async editWarrant(params: EditWarrantParams): Promise<Warrant> {
        const res = await this.post('/api/warrants', params);

        return res.data;
    }

    /** Returns true if given warrant is valid for today's date  */
    public isValidWarrant(warrant: Warrant): boolean {
        if (!warrant) {
            return false;
        }

        const validityStart = moment.utc(warrant.validityStart);
        const validityEnd = moment.utc(warrant.validityEnd);

        return moment.utc().isBetween(validityStart, validityEnd, 'date', '()');
    }
}
