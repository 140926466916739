import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginationNavigation, PaginationNavigationLight } from 'app/shared/models/pagination.interface';
import { Page } from '../pagination.interface';
import { PaginationBackService } from './pagination-back.service';

@Component({
    selector: 'ga-pagination-back',
    templateUrl: './pagination-back.component.html',
    styleUrls: ['./../pagination.component.scss'],
})
export class PaginationBackComponent implements OnInit {
    /**
     * Set a new value inside the root pagination value in pagination's service.
     * @param value
     */
    @Input()
    set pagination(value: PaginationNavigation) {
        this.paginationService.pagination = value;
    }

    /**
     * Navigation properties set a page level
     */
    @Input() navigation: PaginationNavigationLight = {
        nbItemsPerPage: 10,
        canDisplayAll: false,
    };

    /**
     * Array of available pages.
     */
    arrayPagesDisplayed: Page[];

    @Output() paginationChanged: EventEmitter<{ resetLastItem: boolean }> = new EventEmitter<{
        resetLastItem: boolean;
    }>();

    /**
     * Selected value for the number of items displayed per page.
     * @todo: remove and use only the value inside the pagination
     */
    nbItemsPerPageSelected: number;

    constructor(private paginationService: PaginationBackService) {}

    ngOnInit(): void {
        this.initPagination();
        this.initPaginationSubscription();
        this.nbItemsPerPageSelected = this.paginationService.pagination.nbItemsPerPage;
        this.initArrayPagination();
    }

    /**
     * Initialize the pagination value.
     */
    private initPagination() {
        this.pagination = {
            currentPageIndex: 0,
            selectedPageIndex: 0,
            nbItemsPerPage: this.navigation.nbItemsPerPage,
            canDisplayAll: this.navigation.canDisplayAll,
            nbItems: 1,
            nbPages: 1,
        };
    }

    /**
     * Subscribe to the modification of the number of items.
     */
    private initPaginationSubscription() {
        this.paginationService.pagination$.subscribe({
            next: () => {
                this.onNbItemsChanged();
            },
        });
    }

    /**
     * Elements have changed. The array of available pages needs to be reset.
     */
    onNbItemsChanged() {
        this.initArrayPagination();
        this.changePaginationColor();
    }

    /**
     * Initialise the array of available pages (arrayPagination).
     */
    initArrayPagination() {
        this.arrayPagesDisplayed = [];

        for (let i = 0; i < this.paginationService.pagination.nbPages; i++) {
            this.arrayPagesDisplayed.push({
                page: i,
                usage: false,
                class: 'unused-button citron-color',
                display: false,
                displaySeparator: false,
            });
        }
    }

    /**
     * Update the pagination color of the selected page.
     * Set to white all the others.
     */
    changePaginationColor() {
        this.arrayPagesDisplayed.forEach(x => {
            const { selectedPageIndex, nbPages } = this.paginationService.pagination;
            const differenceWithCurrentIndex = Math.abs(x.page - selectedPageIndex);

            // We display the first and last item, plus the ones beside the active one
            x.display = x.page === 0 || x.page === nbPages - 1 || differenceWithCurrentIndex <= 1;

            // We display "..." beside the visible buttons
            x.displaySeparator = differenceWithCurrentIndex <= 2;

            // Set all other pages to unused
            if (x.page !== selectedPageIndex) {
                x.usage = false;
                x.class = 'unused-button citron-color';
            } else {
                x.usage = true;
                x.class = 'used-button citron-color citron-background-50';
            }
        });
    }

    /**
     * Called when a new range of items to display is selected (10 || 20 || Tous)
     * Select the first page.
     * Update the pagination array (compute new nb of pages available) & its colors
     * Emit an event to update the table.
     * @param {number|'Tous'} e - index of the range selected or "Tous"
     */
    onRangeSelected(e) {
        // update the range of items to display
        if (e.target.value === 'Tous') {
            this.paginationService.pagination.nbItemsPerPage = 0; // no limit
        } else {
            this.paginationService.pagination.nbItemsPerPage = Number(e.target.value);
        }

        // select the first page
        this.paginationService.pagination.selectedPageIndex = 0;
        this.paginationService.pagination.currentPageIndex = 0;

        // update the pagination array (compute new nb of pages available) & its colors
        this.initArrayPagination();
        this.changePaginationColor();

        this.paginationChanged.emit({ resetLastItem: true });
    }

    /**
     * Called when a new page is selected.
     * Update the selected page index + update the pagination array's colors.
     * Emit an event to update the table.
     * @param {number} index - index of the page selected
     */
    onIndexSelected(index: number) {
        if (this.paginationService.pagination.currentPageIndex !== index) {
            this.paginationService.pagination.selectedPageIndex = index;
            this.changePaginationColor();
            this.paginationChanged.emit();
        }
    }

    /**
     * @returns {boolean} true when "All" option is available
     */
    canDisplayAll() {
        return this.paginationService.pagination.canDisplayAll;
    }
}
