import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import * as clone from 'clone';
import { LegacyService } from '../../../services/sites/legacy.service';

@Component({
    selector: 'ga-site-distribution-key',
    templateUrl: './distribution-key.component.html',
    styleUrls: ['./distribution-key.component.scss'],
    providers: [],
})
export class SiteDistributionKeyComponent implements OnInit {
    // Site object to get the shared routing references, routing references and zones.
    @Input() site: any;
    @Output() updatedRoutingReferences: EventEmitter<any[]> = new EventEmitter<any[]>();

    /**
     * Shared routing references array, populated with the routing references and zones.
     * Each object must have the format :
     * {
     *   routingReference: {Routing reference obj},
     *   type: enum ['percent', 'buidlingsurface', 'nbpeopleonsite']
     *   zones: [{
     *     zone: {Zone obj},
     *     ratio: number (0 <= ratio <= 1)
     *   }]
     * }
     */
    sharedRoutingReferences: any[] = [];
    /** Initial values of shared routing references, used to restore if needed */
    initialSharedRRef: Record<string, any> = {};

    /**
     * Ponderation types buttons choices.
     */
    ponderationTypes = [
        {
            displayName: '% Automatique',
            value: 'percent_auto',
        },
        {
            displayName: '% Manuel',
            value: 'percent',
        },
        {
            displayName: 'Surface',
            value: 'buildingsurface',
        },
        {
            displayName: 'Effectif',
            value: 'nbpeopleonsite',
        },
    ];

    /**
     * Object with zone id as key. Each zone legacy data has "nbpeopleonsite" and "buildingsurface" property
     * Ie : {
     *   "a1b2c3d4e5f6g7h8i9j0kl" : {
     *     "buildingsurface": 125,
     *     "nbpeopleonsite": 12
     *   }, ...
     * }
     */
    zonesLegacyData: any = {};

    constructor(private legacyService: LegacyService) {}

    ngOnInit() {
        if (!this.site) {
            throw new Error('site is mandatory');
        }
        // Init shared routing references array by getting site's shared routing references and populating them.
        this.computeSharedRoutingReferences();
        // Init zones legacy data array by grabbing wanted data for each zone
        this.updateLegacyData();
    }

    /**
     * Update distribution key table data. Can be called when site changed or legacy data updated for example.
     * Every not saved changes will be erased.
     */
    public async update() {
        this.computeSharedRoutingReferences();
        await this.updateLegacyData();
    }

    /**
     * Compute sharedRoutingReferences member array.
     */
    private computeSharedRoutingReferences() {
        // Populate sharedRoutingReferences array with the routing references and zones (instead of ids)
        if (this.site.info.sharedRoutingReferences && this.site.info.sharedRoutingReferences.length) {
            // Clone site so that we don't update the parent site object
            const site = clone(this.site);
            this.sharedRoutingReferences = site.info.sharedRoutingReferences.map(sharedRoutingReference => {
                // Get the populated routing reference from the site's routing references list.
                const searchRf = site.routingReferences.find(x => x._id === sharedRoutingReference.routingReference);
                if (searchRf) {
                    sharedRoutingReference.routingReference = searchRf;
                } else {
                    return null;
                }
                // Get populated zones from the site's zones list.
                sharedRoutingReference.zones = sharedRoutingReference.zones.map(zone => {
                    const searchZone = site.info.batiments.find(x => x._id === zone.zone);
                    if (searchZone) {
                        zone.zone = searchZone;
                    }
                    return zone;
                });
                // Save initial value
                this.initialSharedRRef[sharedRoutingReference.routingReference._id] = clone(sharedRoutingReference);
                // Compute shared RRef zones ratio (for example, if a RRef has been added to list)
                this.computeZonesRatio(sharedRoutingReference);
                return sharedRoutingReference;
            });
        }
    }

    /**
     * Update zones legacy data by getting values
     */
    private async updateLegacyData() {
        // Get legacy data for each zone. Just get the legacy data we're interested in.
        if (this.site.info.diviser) {
            const promiseArray = this.site.info.batiments.map(zone => {
                this.zonesLegacyData[zone._id] = {};
                return this.getLegacyData(zone, this.site);
            });
            return Promise.all(promiseArray);
        }
    }

    /**
     * Emit shared routing reference ratio data change.
     * Data emitted is the clean, non populated, sharedRoutingReferences object.
     */
    private emitChange() {
        const valueEmit = [];
        this.sharedRoutingReferences.forEach(sharedPdl => {
            // Compute ratios before emiting
            this.computeZonesRatio(sharedPdl);
            const item = {
                routingReference: sharedPdl.routingReference._id,
                type: sharedPdl.type,
                zones: sharedPdl.zones.map(zone => ({
                    zone: zone.zone._id,
                    ratio: zone.ratio,
                })),
            };

            valueEmit.push(item);
        });
        this.updatedRoutingReferences.emit(valueEmit);
    }

    /**
     * Get shared routing reference energy icon class
     * @param {*} sharedPdl - shared routing reference populated object (see this.sharedRoutingReferences)
     * @returns {string} return fluid icon class or nothing if no data for the pdl. Class is at format {fluid}-icon (ie. "elec-icon")
     */
    getSharedPdlIcon(sharedPdl) {
        if (sharedPdl.routingReference && sharedPdl.routingReference.energyType) {
            return sharedPdl.routingReference.energyType + '-icon';
        }
        return '';
    }

    /**
     * Get shared routing reference status class.
     * @param {*} sharedPdl - shared routing reference populated object (see this.sharedRoutingReferences)
     * @returns {string} "status__icon-enabled" if pdl is active, "status__icon-disabled" otherwise
     */
    getPdlStatusClass(sharedPdl) {
        if (
            sharedPdl &&
            sharedPdl.routingReference &&
            sharedPdl.routingReference.status &&
            sharedPdl.routingReference.status.active
        ) {
            return 'status__icon-enabled';
        }
        return 'status__icon-disabled';
    }

    /**
     * Get ponderation value for a zone and a shared routing reference, depending on the shared routing reference ponderation type.
     * @param {*} zone - zone object to get the value of
     * @param {*} sharedPdl - shared routing reference populated object (see this.sharedRoutingReferences)
     * @returns {string|number} ponderation value
     */
    getValue(zone, sharedPdl) {
        switch (sharedPdl.type) {
            default:
            case 'percent':
            case 'percent_auto':
                return zone.ratio * 100;
            case 'buildingsurface':
                if (this.zonesLegacyData[zone.zone._id] && this.zonesLegacyData[zone.zone._id].buildingsurface) {
                    return this.zonesLegacyData[zone.zone._id].buildingsurface + ' m2';
                }
                return 'Non renseigné';
            case 'nbpeopleonsite':
                if (this.zonesLegacyData[zone.zone._id] && this.zonesLegacyData[zone.zone._id].nbpeopleonsite) {
                    return this.zonesLegacyData[zone.zone._id].nbpeopleonsite + ' personne(s)';
                }
                return 'Non renseigné';
        }
    }

    /**
     * Get legacy data for a zone (buildingsurface and nbpeopleonsite) and set it to the global member this.zonesLegacyData.
     * @param {*} zone - zone to get the lgacy data of
     * @param {*} site - site containing the zone
     */
    private async getLegacyData(zone, site) {
        try {
            const response = await this.legacyService.getLegacyData(site._id, zone._id, [
                'buildingsurface',
                'nbpeopleonsite',
            ]);
            if (response.data) {
                response.data.forEach(legacyData => {
                    this.zonesLegacyData[zone._id][legacyData.key] =
                        legacyData.values[legacyData.values.length - 1].value;
                });
            }
        } catch (error) {
            this.zonesLegacyData[zone._id] = {
                buildingsurface: null,
                nbpeopleonsite: null,
            };
        }
    }

    /**
     * Set selected ponderation for a shared routing reference
     * @param sharedPdl - shared routing reference populated object (see this.sharedRoutingReferences)
     * @param ponderation - ponderation type item (see this.ponderationTypes)
     */
    selectPonderation(sharedPdl, ponderation: string) {
        sharedPdl.type = ponderation;
        this.emitChange();
    }

    /**
     * Update shared pdl's zone ratio percent.
     * @param {number} value - percentage value to set (0 <= value <= 100).
     * @param {*} zone - zone to updated the ratio of
     */
    updatePercentRatio(value, zone) {
        if (value >= 0 && value <= 100) {
            zone.ratio = value / 100;
            this.emitChange();
        }
    }

    /**
     * Compute zones ratios.
     * Currently only recompute for 'percent_auto'.
     */
    private computeZonesRatio(sharedRRef: {
        routingReference: { _id: string };
        type: string;
        zones: Array<{ ratio: number }>;
    }) {
        // Only for display (backend recompute).
        // Legacy data doesn't use this ratio for display.
        // When going back to initial type, restore saved zones
        // Manual percent can't be computed.
        const initial = this.initialSharedRRef[sharedRRef.routingReference._id];
        if (initial && initial.type === sharedRRef.type) {
            sharedRRef.zones = clone(initial.zones);
            return;
        }

        if (sharedRRef.type === 'percent_auto') {
            const ratio = 1 / sharedRRef.zones.length;
            sharedRRef.zones.forEach(zone => {
                zone.ratio = ratio;
            });
        }
    }
}
