import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';
import { TranslateService } from '../translate/translate.service';
import { FluidsAvailableParam } from './energy.interface';

/**
 * This service handles the operations closely relatives to energies
 * For exemple the conversion to other units
 * But it should also handles energies traductions maybe
 */
@Injectable()
export class EnergyService extends PageService {
    constructor(public apiService: ApiService, private translateService: TranslateService) {
        super(apiService);
    }

    convertKwhefToKwhep(elecKwh) {
        return elecKwh * 2.3;
    }

    // This function exists only if the ratio changes one day. But unless it does, yes, this function is useless.
    convertKwhPcsToKwhep(gazKwh) {
        return gazKwh * 1;
    }

    convertEnergyKwhToKwhep(energy, value) {
        let response = 0;

        switch (energy.toLowerCase()) {
            case 'gaz':
            case 'gas':
            case 'heating':
            case 'rcu':
            case 'cooling':
            case 'rfu':
                response = this.convertKwhPcsToKwhep(value);
                break;

            case 'elec':
            case 'électricité':
                response = this.convertKwhefToKwhep(value);
                break;

            default:
                response = value;
                break;
        }

        return response;
    }

    /**
     * Get fluid full text from fluid key (ie. 'elec')
     * @param {string} fluid - fluid key
     * @returns {string} fluid full text
     */
    energyFullText(fluid: string): string {
        if (this.getAllFluids().includes(fluid)) {
            return this.translateService._(fluid);
        }
        return 'NC';
    }

    /**
     * Get fuel product full text from fuel product key (ie. '')
     * @param {string} fuelProduct - fuel product key
     * @returns {string} fuel product full text
     */
    fuelProductFullText(product: string): string {
        if (this.getAllFuelTypes().includes(product)) {
            return this.translateService._(product);
        }
        return 'NC';
    }

    /**
     * Get fluids availanle for companies
     * @param {string[]} companies: Array<string> array of companies id
     * @return {Promise<string[]>}
     */
    async getFluidsAvailableForCompanies(companies: string[]): Promise<string[]> {
        const result = await this.post('/api/contracts/energies', { companies });
        if (result.data) {
            return result.data;
        } else {
            throw { errorCode: 'error_getFluidsForCompanies' };
        }
    }

    /**
     * Get list of all energies (no liquids)
     * @returns {string[]}
     */
    getAllEnergies(): string[] {
        return ['elec', 'gaz', 'heating', 'cooling'];
    }

    /**
     * Get all liquids (only water for now)
     * @returns {string[]}
     */
    getAllLiquids(): string[] {
        return ['water'];
    }

    /**
     * Get all vehicle energies (only fuel for now)
     * @returns {string[]}
     */
    getAllVehicleFluids(): string[] {
        return ['fuel'];
    }

    /**
     * Get all routing references energies
     * @returns {string[]}
     */
    getAllRoutingReferenceFluids(): string[] {
        return [].concat(this.getAllEnergies(), this.getAllLiquids());
    }

    /**
     * Returns true if the given fluid is in the list of all energies
     * @param {string} fluid
     * @returns {boolean}
     */
    isFluidEnergy(fluid: string): boolean {
        return this.getAllEnergies().includes(fluid);
    }

    /**
     * Returns true if the given fluid is in the list of all liquids
     * @param {string} fluid
     * @returns {boolean}
     */
    isFluidLiquid(fluid: string): boolean {
        return this.getAllLiquids().includes(fluid);
    }

    /**
     * Returns true if the given fluid is in the list of all vehicle fluids
     * @param {string} fluid
     * @returns {boolean}
     */
    isFluidFromVehicle(fluid: string): boolean {
        return this.getAllVehicleFluids().includes(fluid);
    }

    /**
     * Returns the name of the fluid source: routing reference or vehicle
     * @param {string} fluid
     * @returns {"routingReference"|"vehicle"|null}
     */
    getFluidSource(fluid: string): string {
        if (this.isFluidEnergy(fluid) || this.isFluidLiquid(fluid)) {
            return 'routingReference';
        }
        if (this.isFluidFromVehicle(fluid)) {
            return 'vehicle';
        }
        return null;
    }

    /**
     * Get all fluids (energies, fuel, and liquids)
     * @returns {string[]}
     */
    getAllFluids(): string[] {
        return ['water', 'elec', 'gaz', 'heating', 'cooling', 'fuel'];
    }

    /**
     * Get all fluids available for the specific company and its branches. If no company is specified, use the session company
     * @param {string|null} company - company id of the company
     * @param {FluidsAvailableParam?} param - param to apply to the request
     * @returns {Promise<string[]>} list of fluids
     */
    async getFluidsAvailable(company: string = null, param?: FluidsAvailableParam): Promise<string[]> {
        const companyId = company ? company : this.apiService.sessionService.getCompanyId();
        const data = param ? param : {};
        const res = await this.get('/api/contracts/energies/' + companyId, null, data);
        return res.data;
    }

    /**
     * Get fluids by category (energy or water)
     * @param {string} category  - category to get fluids of
     * @returns {string[]} list of fluids
     */
    getFluidsByCategory(category: string): string[] {
        if (category === 'water') {
            return ['water'];
        }
        if (category === 'vehicle') {
            return ['fuel'];
        }
        // Default : category === energy
        return this.getAllEnergies();
    }

    /**
     * Get 'Données du PDL' base url for fluid
     * @param {string} fluid - fluid to get the base URL from
     */
    getFluidPdlDataUrl(fluid) {
        switch (fluid) {
            case 'water':
                return '/eau/pdl';
            case 'heating':
                return '/energie/pdl/rcu';
            case 'cooling':
                return '/energie/pdl/rfu';
            default:
                return `/energie/pdl/${fluid}`;
        }
    }

    /**
     * Get fluid category in french (eg. for classes or urls)
     * Not using the translate service because mainly used for classes and urls (no capital letter and accent)
     * @param {string} category - fluid category
     */
    getFluidCategoryFrench(category: string): string {
        switch (category) {
            case 'water':
                return 'eau';
            case 'vehicle':
                return 'vehicules';
            case 'energy':
            default:
                return 'energie';
        }
    }

    /**
     * Get all fluids categories
     * @returns {string[]} fluid categories available
     */
    getAllFluidCategories(): string[] {
        return ['energy', 'water', 'vehicle'];
    }

    /**
     * Get all fuel products available
     * @returns {string[]}
     */
    getAllFuelTypes(): string[] {
        return ['gasoline', 'diesel'];
    }

    /**
     * Returns true if fluid category is either energy or water, false if vehicles
     * @param {string} fluidCategory
     * @returns {boolean}
     */
    isCategoryForRoutingReference(fluidCategory: string): boolean {
        return ['energy', 'water'].includes(this.normalizeFluidCategory(fluidCategory));
    }

    /**
     * Normalize fluidCategory from any type/name to valid ones.
     * Default is 'energy'
     * @param {string} category - category to get normalized fluid category
     * @returns {string} normalized fluid category.
     */
    normalizeFluidCategory(category: string): string {
        switch (category) {
            case 'eau':
            case 'water':
                return 'water';
            case 'vehicules':
            case 'vehicle':
                return 'vehicle';
            case 'energie':
            case 'energy':
            default:
                return 'energy';
        }
    }

    /**
     * Get pricing rates available for the given fluid.
     * Rate is conversion to apply to a value to get the base rate.
     * @param {string} fluid
     * @returns {{ label: string; rate: number; }[]}
     */
    public getFluidPricingRates(fluid: string): Array<{ label: string; rate: number }> {
        switch (fluid) {
            case 'elec':
            case 'gaz':
                return [
                    {
                        label: 'c€/kWh',
                        rate: 1,
                    },
                    {
                        label: '€/MWh',
                        rate: 0.1,
                    },
                ];
            case 'water':
            case 'heating':
            case 'cooling':
            case 'fuel':
            default:
                return [
                    {
                        label: '--',
                        rate: 1,
                    },
                ];
        }
    }
}
