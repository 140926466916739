import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'ga-chart-doughnut',
    templateUrl: './chart-doughnut.component.html',
    styleUrls: ['./chart-doughnut.component.scss'],
})

// not used in the app
export class ChartDoughnutComponent {
    public chartInstance: any = {};

    downloadFileName = 'chart-doughnut.png';

    @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;

    @Input() data: any[];
    @Input() dataset: any[];
    @Input() labels: any[];
    @Input() colors: any[];
    @Input() legend: any = [];
    @Input() width = 900;
    @Input() height = 340;
    @Input() options: any = {};

    @Output() emitChartClick = new EventEmitter();

    setChartInstance(chart) {
        this.chartInstance = chart;
    }

    chartClicked(event) {
        this.emitChartClick.emit(event);
    }
}
