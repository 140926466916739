import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-simple-table',
    templateUrl: './simple-table.component.html',
    styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent implements OnInit {
    @Input() data: any[] = [];
    @Input() headers: any[] = [];

    constructor() {}
    ngOnInit() {}
}
