import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from 'app/shared/components/common/modal/modal.component';
import { PeriodFilter } from 'app/shared/models/bills-timeline.interface';
import { ExportService } from 'app/shared/services/export/export.service';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

// Interfaces
import {
    CompletenessRange,
    CompletenessTableProperties,
    DateLabel,
    RoutingRefDateCompleteness,
    RRefClicked,
} from 'app/shared/components/bills/bills-control/completeness/completeness-data-tab/completeness-data-tab.interface';
import {
    QueryFilterRoutingRef,
    ScopeFilterConfig,
} from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { RoutingRefGlobalCompleteness } from 'app/shared/models/bills.interface';
import { HeatmapProperties } from 'app/shared/models/charts/chart-properties.interface';
import { HeatmapPoint } from 'app/shared/models/charts/chart-serie.interface';

// Services
import { RoutingReferenceLight } from 'app/shared/models/routing-reference.interface';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { ColorService } from 'app/shared/services/color/color.service';
import { CompletenessService, propertiesOrder } from 'app/shared/services/completeness/completeness.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { BillsTableCompletenessComponent } from '../bills-table-completeness/bills-table-completeness.component';

import { CompletenessRangesService } from './completeness-ranges.service';

@Component({
    selector: 'ga-completeness-data-tab',
    templateUrl: './completeness-data-tab.component.html',
    styleUrls: ['./completeness-data-tab.component.scss'],
    providers: [CompletenessRangesService],
})
export class CompletenessDataTabComponent implements OnInit {
    @ViewChild(BillsTableCompletenessComponent, { static: true })
    comptenessTableComponent: BillsTableCompletenessComponent;
    @ViewChild(ModalComponent, { static: true }) public completenessModal: ModalComponent;

    constructor(
        private chartService: ChartService,
        private completenessService: CompletenessService,
        private completenessRangesService: CompletenessRangesService,
        private utilService: UtilsService,
        private colorService: ColorService,
        private exportService: ExportService
    ) {}

    /**
     * Set configuration for the filters
     */
    public filtersConfig: ScopeFilterConfig = {
        toggleButtonSelection: {
            enabled: true,
            selectionType: 'single',
        },
    };

    /**
     * Properties used by the heatmap component
     */
    public heatmapProperties: HeatmapProperties = {
        options: null,
        data: null,
        labelsX: [],
        labelsY: [],
        isLoading: true,
    };

    /**
     * Dates labels description. Contains each date from date start to date end of the filter.
     * Used to easily determine the x axis value corresponding to a percentage date (month / year).
     */
    private datesLabels: DateLabel[] = [];

    /**
     * Completeness per date per routing reference.
     * Data received by the Api.
     */
    private routingRefsCompletenessData: RoutingRefGlobalCompleteness = {
        data: [],
        average: {},
    };

    /**
     * Key of the selected completeness composant
     */
    public completenessKey: string = null;

    /**
     * Default key used when filters are initialized
     */
    private defaultCompletenessKey = 'furniture';

    /**
     * Filters selection
     */
    private filters: QueryFilterRoutingRef;

    /** Data for the rouging references table */
    public tableProperties: CompletenessTableProperties = {
        isLoading: false,
        data: [],
        completenessComponents: [],
        dateSelected: null,
    };

    /** The keys order for the completeness properties */
    private readonly order: string[] = propertiesOrder;

    /** Is currently exporting */
    public isExporting = false;

    /** Data for the completeness modal of a routing reference */
    public modalProperties: { routingReference: RoutingReferenceLight; periodFilter: PeriodFilter } = {
        /** Is used for detailed completeness modal */
        routingReference: null,
        /** Used to filter on completeness period of a routing reference */
        periodFilter: {
            beginDate: null,
            endDate: null,
        },
    };

    ngOnInit() {}

    /**
     * Start the research with new filters selection.
     * Compute new data, format values and set the chart options accordingly.
     * @param {QueryFilter} filters selected filters
     */
    public async search(filters: QueryFilterRoutingRef) {
        this.heatmapProperties.isLoading = true;

        this.filters = filters;

        /**
         * Collect data for selected filters and format it
         */
        await this.computeHeatmapData();

        /**
         * Set heatmap options with new data & associated configuration
         */
        this.setHeatmapOptions();

        this.heatmapProperties.isLoading = false;

        /**
         * Empty table and updated components keys in header
         */
        this.resetTable();
    }

    /**
     * Get data for heatmap from current filters selection and set heatmap series points well formated
     */
    private async computeHeatmapData(): Promise<void> {
        try {
            // get data for heatmap matching the filters selection
            this.routingRefsCompletenessData = await this.completenessService.getRoutingReferencesCompleteness(
                this.filters
            );

            // set selected key: use default completeness key or first key found
            this.completenessKey = this.routingRefsCompletenessData.average.hasOwnProperty(this.defaultCompletenessKey)
                ? this.defaultCompletenessKey
                : Object.keys(this.routingRefsCompletenessData.average)[0];

            // compute labels matching new data
            this.setHeatmapLabels();

            // format data for chart
            this.setHeatmapData();
        } catch (e) {
            this.heatmapProperties.data = [];
        }
    }

    /**
     * Group routing references per completeness range and date to create the cells (x:y) values.
     * @returns {HeatmapPoint[]}
     */
    private setHeatmapData(): void {
        const rangeValues: HeatmapPoint[] = [];

        /**
         * Each routing reference data has percentages associated to dates (month year)
         * For each (routing ref ; date ; percentage) find or create its cell (date; range)
         * Store in the cell the routing references matching its axes values.
         */
        this.routingRefsCompletenessData.data.forEach(routingRefData => {
            // for each date's percentages
            routingRefData.dates.forEach(dateCompleteness => {
                const currentValue = dateCompleteness.percentages[this.completenessKey];
                if (currentValue === null) {
                    return;
                }

                // get the percentage range of the current date's value (index on the y axis)
                const rangeIndex = this.completenessRangesService.getCompletenessRangeIndex(currentValue);

                // get the month of the current date (index on the x axis)
                const dateIndex: number = this.getDateIndex(dateCompleteness.year, dateCompleteness.month);

                // get the cell (date; range) if it already exists
                let rangeValue: HeatmapPoint = rangeValues.find(r => r.x === dateIndex && r.y === rangeIndex);
                if (!rangeValue) {
                    // create the cell's value if not existing yet
                    rangeValue = {
                        x: dateIndex,
                        y: rangeIndex,
                        value: 0,
                        data: [],
                    };
                    rangeValues.push(rangeValue);
                }
                // increment the number of routing references found for this cell (date; range)
                rangeValue.value++;

                // store the id of the current routing reference inside the cell's data
                rangeValue.data.push(routingRefData.routingReferenceId);
            });
        });

        this.heatmapProperties.data = _.sortBy(rangeValues, ['year', 'month']);
    }

    /**
     * Set heatmap labels (x: dates labels; y: completeness ticks)
     */
    private setHeatmapLabels(): void {
        /**
         * Init axis values : completeness ranges and dates labels
         */
        this.datesLabels = this.getDatesLabels();

        /**
         * Init heatmap axis labels
         */
        this.heatmapProperties.labelsX = this.getHeatmapDatesLabels();
        this.heatmapProperties.labelsY = this.getHeatmapPercentLabels();
    }

    /**
     * Set heatmap options used by the chart component. Contains tooltips, axes labels, series data.
     */
    private setHeatmapOptions(): void {
        // global options
        this.heatmapProperties.options = this.chartService.getConfig('heatmap');

        // size options
        this.setHeatmapSizeOptions();

        // color options
        this.setHeatmapColorOptions();

        // tooltips options
        this.setHeatmapTooltipsOptions();

        // axis labels options
        this.setHeatmapLabelsOptions();

        // fill data
        this.heatmapProperties.options.series = [{ type: 'heatmap', data: this.heatmapProperties.data }];
    }

    /**
     * Compute the cells size by computing the chart's overall size
     */
    private setHeatmapSizeOptions(): void {
        // Space taken under the x axis and on the left size of the y axis
        const offset = {
            x: 116.5, // axis title with padding (40 + 13.5) + largest y label (53px) + chart padding (10px)
            y: 130, // legend with margin (67px + 30px) + x labels (33px)
        };

        // Number of columns and rows
        const sortedDataY: HeatmapPoint[] = _.sortBy(this.heatmapProperties.data, 'y'); // sort percentage ranges to get the lowest and higest
        const highestRange = this.hasDataForHeatmap() ? _.last(sortedDataY).y : 0;
        const lowestRange = this.hasDataForHeatmap() ? sortedDataY[0].y : 0;
        const axesTicksCounts = {
            x: _.uniqBy(this.heatmapProperties.data, 'x').length, // number of dates displayed
            y: highestRange - lowestRange + 1, // number of y indexes displayed: difference between highest and lowest y label index
        };

        // Over twelve months, display labels vertically and by semester. Adjust the offset to keep the cell ratio.
        if (axesTicksCounts.x > 12) {
            this.heatmapProperties.options.xAxis = {
                labels: { rotation: -45 },
                tickInterval: 4, // display trimestrial dates
            };
            offset.y = 185; // labels are displayed vertically and take more space (increase offset so that cells keep their size)
        }

        /**
         * Height
         * Setting the chart's heigth enable to compute the heigth of cells
         */
        this.heatmapProperties.options.chart.height = offset.y + axesTicksCounts.y * 50; // height is around 50px

        /**
         * Width
         * Set the chart's min width from which the chart becomes scrollable
         */
        this.heatmapProperties.options.chart.scrollablePlotArea.minWidth =
            axesTicksCounts.x <= 12 ? offset.x + axesTicksCounts.x * 90 : 1000; // min cell width is minimum 90px under 12 months, under 90px it starts scrolling
    }

    /**
     * Set color axis and handle hover behaviour on a cell (brightness).
     */
    private setHeatmapColorOptions(): void {
        /**
         * From 0 to n/2 (n = the maximum number of routing references on a cell), set light grey to grey
         * From n/2 to the n, set from light green to dark green
         */
        this.heatmapProperties.options.colorAxis = {
            stops: [[0, '#F0F0F0'], [0.49, '#BFBFBF'], [0.5, '#C2DAF0'], [1, '#4281b8']],
        };

        /**
         * Reduce the brightness intensity on hover
         */
        this.heatmapProperties.options.plotOptions = {
            heatmap: {
                states: {
                    hover: {
                        brightness: 0.05, // default was 0.25 and made grey going white
                    },
                },
            },
        };
    }

    /**
     * Set custom tooltips to display: the number of routing references, the period and completeness range
     */
    private setHeatmapTooltipsOptions(): void {
        const defaultTooltips = this.chartService.getTooltipHTMLConfig('heatmap');

        const labelsDates = this.heatmapProperties.labelsX;
        const labelsPercentages = this.heatmapProperties.labelsY;

        const colorService = this.colorService;

        const that = this;

        this.heatmapProperties.options.tooltip = Object.assign(defaultTooltips, {
            pointFormatter() {
                // date & completeness range label
                const date = labelsDates[this.x];
                const range = labelsPercentages[this.y];

                const borderColor = colorService.getHexPageColor('BORDER_GREY');

                const valueStyle = `style="
                                        font-weight: bold;
                                        text-align: center;
                                        padding-bottom: 0.25rem;
                                        border-bottom:1px solid ${borderColor};"`;

                const valueLabel = this.value > 1 ? 'PDLs' : 'PDL';
                const dateValuesCount = that.countRoutingRefsOnMonth(this.x);

                const body = `
                        <div ${valueStyle}> ${this.value}/${dateValuesCount} ${valueLabel} </div>
                        <table class="mt-1">
                                <tr>
                                    <td> Période : </td>
                                    <td class="pl-1">${date}</td>
                                </tr>
                                <tr>
                                    <td> Complétude : </td>
                                    <td class="pl-1">${range}</td>
                                </tr>
                        </table>`;
                return body;
            },
        });
    }

    /**
     * Set axis labels.
     * Specific for axis X: the number of routing references under each month.
     */
    private setHeatmapLabelsOptions(): void {
        this.heatmapProperties.options.yAxis['categories'] = this.heatmapProperties.labelsY;

        const that = this;

        this.heatmapProperties.options.xAxis = Object.assign(this.heatmapProperties.options.xAxis, {
            categories: this.heatmapProperties.labelsX,
            labels: {
                useHTML: true,
                formatter() {
                    const count = that.countRoutingRefsOnMonth(this.pos);
                    return `<div class="d-flex flex-column align-items-center custom-highcharts-labels">
                        <div>  ${this.value} </div>
                        <div>  ${count} PDLs </div>
                    </div>`;
                },
            },
        });
    }

    /**
     * @returns {boolean} returns true if heatmap has data to display
     */
    public hasDataForHeatmap(): boolean {
        return Boolean(this.heatmapProperties.data && this.heatmapProperties.data.length);
    }

    /**
     * @returns {boolean} returns true if table has data to display
     */
    public hasDataForTable(): boolean {
        return Boolean(this.tableProperties.data && this.tableProperties.data.length);
    }

    /**
     * Return the index of the given date
     * @returns {number} index of the given date
     */
    private getDateIndex(year: number, month: number): number {
        return this.datesLabels.findIndex(x => x.month === month && x.year === year);
    }

    /**
     * Compute and return dates labels from the filtered period : each month in between the beginning and the end of the selected period.
     * @returns {DateLabel[]} date labels for each month between start and end
     */
    private getDatesLabels(): DateLabel[] {
        const dateStart = moment.utc(this.filters.dateStart, 'YYYY-MM', true).startOf('day');
        const dateEnd = moment.utc(this.filters.dateEnd, 'YYYY-MM', true).endOf('day');

        const nbMonths = dateEnd.diff(dateStart, 'months') + 1;

        const datesLabels = [];
        for (let i = 0; i < nbMonths; i++) {
            const dateLabel: DateLabel = {
                month: dateStart.month(),
                year: dateStart.year(),
                label: dateStart.format('MMMM YYYY'),
            };
            datesLabels.push(dateLabel);
            dateStart.add(1, 'M');
        }
        return datesLabels;
    }

    /**
     * Return the labels for the months axis (x).
     */
    private getHeatmapDatesLabels(): string[] {
        return this.datesLabels.map(d => d.label);
    }

    /**
     * Return the labels for the percentages ranges axis (y);
     */
    private getHeatmapPercentLabels(): string[] {
        return this.completenessRangesService.completenessRanges.map(c => c.label);
    }

    /**
     * Handle heatmap cell selection. Update the table data with selected data and sort.
     * @param {HeatmapPoint} heatmapCell selection
     */
    public onSelectedCell(heatmapCell: HeatmapPoint): void {
        const cellDateLabel: DateLabel = this.datesLabels[heatmapCell.x];
        this.computeTableData(heatmapCell.data, cellDateLabel);
        this.comptenessTableComponent.reinitTable();
    }

    /**
     * Count the number of routing references present on the given month
     * @param {number} monthIndex
     * @returns {number} routing references count
     */
    private countRoutingRefsOnMonth(monthIndex: number): number {
        return _.sumBy(this.heatmapProperties.data.filter(data => data.x === monthIndex), 'value');
    }

    /**
     * --------------------------------
     * --- ROUTING REFERENCES TABLE ---
     * --------------------------------
     */

    /**
     * Compute and set data for the routing references table.
     * For each given routing reference, get their percentage values at the given date.
     * @param {string[]} routingReferencesIds
     * @param {DateLabel} dateSelected
     */
    private computeTableData(routingReferencesIds: string[], dateSelected: DateLabel): void {
        this.tableProperties.isLoading = true;

        /** Find the routing references in the selected ids and keep only the percentage values for the selected date */
        this.tableProperties.data = this.routingRefsCompletenessData.data.reduce((tmpTableData, routingRefData) => {
            // find the selected routing references
            if (routingReferencesIds.includes(routingRefData.routingReferenceId)) {
                // find the percentage values for the selected date
                const datePercentages = routingRefData.dates.find(
                    d => d.month === dateSelected.month && d.year === dateSelected.year
                );
                // add the routing reference's values to table
                const row: RoutingRefDateCompleteness = Object.assign(
                    {
                        routingReferenceId: routingRefData.routingReferenceId,
                        routingReference: routingRefData.routingReference,
                        companyId: routingRefData.companyId,
                        fluid: routingRefData.fluid,
                    },
                    datePercentages
                );
                tmpTableData.push(row);
            }
            return tmpTableData;
        }, []);

        this.tableProperties.dateSelected = dateSelected.label;
        this.tableProperties.isLoading = false;
    }

    /**
     * Initialise routing references table
     */
    resetTable() {
        this.tableProperties.isLoading = true;
        this.tableProperties.data = [];
        this.tableProperties.completenessComponents = this.completenessKeys;
        this.tableProperties.isLoading = false;

        /** Change the order of the properties keys */
        this.tableProperties.completenessComponents.sort((a, b) => {
            return this.order.indexOf(a) - this.order.indexOf(b);
        });
    }

    /**
     * --------------------------
     * Completeness key selection
     * --------------------------
     */

    /**
     * Returns the available completeness keys (will depend on the selected fluid)
     * @returns {string[]} keys
     */
    public get completenessKeys(): string[] {
        return Object.keys(this.routingRefsCompletenessData.average);
    }

    /**
     * Returns the average completeness value to display for the selected key
     * @returns {string} average value
     */
    public get averageValueDisplayed(): string {
        return this.utilService.getNumberAndUnitToDisplay(
            this.routingRefsCompletenessData.average[this.completenessKey],
            '%'
        );
    }

    /**
     * Handle the selection of a new completeness key. Update the heatmap data & reset the table.
     * @param {string} key
     */
    public onSelectedCompletenessKey(): void {
        this.heatmapProperties.isLoading = true;

        /**
         * Update data for the new key selected and update heatmap
         */
        this.setHeatmapData();
        this.setHeatmapOptions();

        /**
         * Reset table
         */
        this.tableProperties.data = [];

        this.heatmapProperties.isLoading = false;
    }

    /**
     * handle click on download heatmap
     * @param {string} excelName - excel filename
     */
    public onDownloadHeatmap(excelName: string): void {
        this.isExporting = true;
        excelName = excelName || 'completude des donnees';
        const route = '/api/completeness/billsCompleteness/export';
        const params = { ...this.filters };
        this.exportService.getFile(route, params).subscribe(
            file => {
                saveAs(file, excelName + '.xlsx');
                this.isExporting = false;
            },
            err => {
                Swal.fire(
                    'Toutes nos excuses',
                    "Une erreur s'est produite lors de l'export de données. Merci de réessayer.",
                    'error'
                );
                this.isExporting = false;
            }
        );
    }

    /**
     * used to display the right icon when exporting excel file or not
     * @returns {string} - classname
     */
    get exportClass(): string {
        return this.isExporting ? 'citron-icon rotating' : 'excel-icon';
    }

    /**********************
     * completeness modal
     **********************/

    /**
     * open the bill completeness modal for the given routing reference
     * @param {RRefClicked} routingReference
     * @returns {void}
     */
    public openCompletenessModal(routingReference: RRefClicked): void {
        this.modalProperties.routingReference = {
            ...routingReference,
            energyType: routingReference.energyType,
        };

        this.setPeriodFilterModal();
        this.completenessModal.show();
    }

    /**
     * @returns {boolean} true is the completeness modal shown, false otherwise
     */
    get isModalShown(): boolean {
        return this.completenessModal.isShown;
    }

    /**
     * set the dates filter for the billing completeness modal
     * @returns {void}
     */
    private setPeriodFilterModal(): void {
        if (this.filters.dateStart && this.filters.dateEnd) {
            const dateStart: moment.Moment = moment.utc(`${this.filters.dateStart}`, 'YYYY-MM').startOf('month');
            const dateEnd: moment.Moment = moment.utc(`${this.filters.dateEnd}`, 'YYYY-MM').endOf('month');
            this.modalProperties.periodFilter = {
                beginDate: {
                    year: dateStart.year(),
                    month: dateStart.month() + 1,
                    day: dateStart.date(),
                },
                endDate: {
                    year: dateEnd.year(),
                    month: dateEnd.month() + 1,
                    day: dateEnd.date(),
                },
            };
        }
    }
}
