import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { OptionValue } from 'app/shared/models/filter-config.interface';
import _ = require('lodash');

import { FilterService } from 'app/shared/services/filter/filter.service';
import { OptionValueGroup } from './scope-filter.interface';

/**
 * This service is used for any scope filter objects.
 */
@Injectable()
export class ScopeFilterService {
    constructor(public filterService: FilterService) {}

    /**
     * Remove any wrong perimeter. If there's several perimeter setted, take the first one.
     * @param {Params} params
     * @returns {Params}
     */
    public removeUnacceptedPerimeterInParams(params: Params, perimeterList: OptionValueGroup[]): Params {
        // For information, sitesStatus is the field name for perimeter in the front
        if (!this.filterService.isAvailableAndSetPropertyInParams(params, 'sitesStatus')) {
            return params;
        }

        const perimeter = (this.filterService.getParamAliasValue(params, 'sitesStatus') || '')
            .split(',')
            .filter((x: string) => _.findIndex(perimeterList, p => p.value === x) >= 0);

        if (perimeter.length) {
            this.filterService.setParamPropertyValue(params, 'sitesStatus', perimeter[0]);
        } else {
            this.filterService.deleteParamPropertyByAlias(params, 'sitesStatus');
        }

        return params;
    }

    /**
     * Remove the compagnies ids if it's the same as the parent company or not corresponding to any company
     * @param {Params} params
     * @param {OptionValue[]} companiesList
     * @returns {Params} params
     */
    public removeInvalidCompaniesIdsInParams(params: Params, companiesList: OptionValue[]): Params {
        if (!this.filterService.isAvailableAndSetPropertyInParams(params, 'companies')) {
            this.filterService.deleteParamPropertyByAlias(params, 'companies');
        }

        // Remove filter if some of the companies ids don't exist in the list of available companies
        // or if it matches the session company's id

        /** Array of companiesIds from query params */
        const companiesIdsInParams: string = this.filterService.getParamAliasValue(params, 'companies');
        /** Array of companiesIds from params */
        let companiesIdsArray: string[] = [];

        if (companiesIdsInParams) {
            companiesIdsArray = companiesIdsInParams.split(',');
        }
        // Companies array has only 1 element matching the session company's id
        // One of the companies in the array doesn't match the available companies
        if (
            companiesIdsArray.some(cId => cId === this.filterService.sessionCompany._id) ||
            companiesIdsArray.some(cId => !this.isInCompanyList(companiesList, cId))
        ) {
            this.filterService.deleteParamPropertyByAlias(params, 'companies');
        }

        return params;
    }

    /**
     * Verify if the companyId corresponds to any company id in list
     * @param {OptionValue[]} companiesList
     * @param {string} companyId
     * @returns {boolean}
     */
    public isInCompanyList(companiesList: OptionValue[], companyId: string): boolean {
        return _.findIndex(companiesList, c => c.value === companyId) < 0;
    }
}
