import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import {
    ContractType,
    IdentificationPatternService,
} from 'app/shared/services/identification-pattern/identification-pattern.service';
import { RegexService } from 'app/shared/services/regex/regex.service';
import { IdentificationPattern } from '../../../models/identification-pattern.interface';

interface IdentificationPatternResult extends IdentificationPattern {
    tests: {
        provider: string;
        option: string;
        contractType: string;
    };
}
@Component({
    selector: 'ga-identification-pattern-search',
    templateUrl: './identification-pattern-search.component.html',
    styleUrls: ['./identification-pattern-search.component.scss'],
})
export class IdentificationPatternSearchComponent implements OnInit {
    isMultiChunk = false;
    currentFluid: string = null;
    currentProvider: string = null;
    currentContractType: string = null;
    fluids: string[] = [];
    providers: string[] = [];
    contractTypes: ContractType[] = [];
    identificationPatterns: IdentificationPatternResult[] = [];
    identificationPatternId: string;

    @Output() search = new EventEmitter();
    @Input() textContent: any = null;

    constructor(
        private identificationPatternService: IdentificationPatternService,
        private regexService: RegexService,
        private energyService: EnergyService
    ) {
        this.loadFluidsAvailable(false);
    }

    ngOnInit() {}

    resetData(arrayPropertiesToReset, propertiesToReset) {
        arrayPropertiesToReset.forEach(property => {
            this[property] = [];
        });
        propertiesToReset.forEach(property => {
            this[property] = null;
        });
    }

    testRegex(regex) {
        return this.regexService.testRegex(this.textContent, regex);
    }

    /**
     * Load fluids available and reset related data (providers, ...)
     * @param {boolean} isMultiChunk - true if upload is multichunk
     */
    loadFluidsAvailable(isMultiChunk: boolean) {
        this.isMultiChunk = isMultiChunk;
        this.resetData(
            ['energies', 'providers', 'contractTypes', 'identificationPatterns'],
            ['currentEnergyType', 'currentProvider', 'currentContractType', 'identificationPatternId']
        );
        this.fluids = this.energyService.getAllFluids();
    }

    /**
     * Load proviers avaible for a given fluid
     * @param {string} fluid
     */
    async loadProvidersAvailable(fluid: string) {
        try {
            this.currentFluid = fluid;
            const providers = await this.identificationPatternService.getProviders(
                this.isMultiChunk,
                this.currentFluid
            );
            this.resetData(
                ['providers', 'contractTypes', 'identificationPatterns'],
                ['currentProvider', 'currentContractType', 'identificationPatternId']
            );
            this.providers = providers;
        } catch (e) {
            this.providers = [];
        }
    }

    loadContractOrIdentification(provider) {
        this.currentProvider = provider;
        if (this.isMultiChunk) {
            this.loadIdentificationPatternsAvailable();
        } else {
            this.loadContractTypesAvailable();
        }
    }

    /**
     * Load contract types avaible for the current provider and fluid.
     */
    async loadContractTypesAvailable() {
        try {
            const contractTypes = await this.identificationPatternService.getContractTypes(
                this.isMultiChunk,
                this.currentFluid,
                this.currentProvider
            );
            this.resetData(
                ['contractTypes', 'identificationPatterns'],
                ['currentContractType', 'identificationPatternId']
            );
            this.contractTypes = contractTypes;
        } catch (e) {
            this.contractTypes = [];
        }
    }

    /**
     * Load identification patterns available and compute tests on regex.
     * Get identification patterns with current fluid, provider and contract type.
     * @param {string} contractType
     */
    async loadIdentificationPatternsAvailable(contractType?: string) {
        try {
            this.currentContractType = contractType;
            const patterns = await this.identificationPatternService.getIdentificationPatterns(
                this.isMultiChunk,
                this.currentFluid,
                this.currentProvider,
                this.currentContractType
            );
            this.resetData(['identificationPatterns'], ['identificationPatternId']);
            this.prepareIdentificationPatternList(patterns);
        } catch (e) {
            this.prepareIdentificationPatternList([]);
        }
    }

    /**
     * Prepare and set identification pattern result list
     * @param {IdentificationPattern[]} identificationPatterns
     */
    async prepareIdentificationPatternList(identificationPatterns: IdentificationPattern[]) {
        const identificationPatternsResults = await Promise.all(
            identificationPatterns.map(item => {
                return this.preparePatternResult(item, this.isMultiChunk);
            })
        );
        this.identificationPatterns = identificationPatternsResults;
    }

    /**
     * Prepare identification pattern result
     * @param {IdentificationPattern} identificationPattern
     * @param {boolean} isMulti
     * @returns {Promise<IdentificationPatternResult>}
     */
    private async preparePatternResult(
        item: IdentificationPattern,
        isMulti: boolean
    ): Promise<IdentificationPatternResult> {
        try {
            const promises = [this.testRegex(item.provider.regex), this.testRegex(item.optionRegex)];
            if (isMulti) {
                promises.push(this.testRegex(item.contractType.regex));
            }
            const [providers, options, contracts] = await Promise.all(promises);
            const result = Object.assign(
                {
                    tests: {
                        provider: providers && providers.length ? providers[0] : 'pas de match',
                        option: options && options.length ? options[0] : 'pas de match',
                        contractType: isMulti && contracts && contracts.length ? contracts[0] : 'pas de match',
                    },
                },
                item
            );
            return result;
        } catch (e) {
            const result = Object.assign(
                {
                    tests: {
                        provider: null,
                        option: null,
                        contractType: null,
                    },
                },
                item
            );
            return result;
        }
    }

    emitSearch() {
        this.identificationPatternService
            .getIdentificationPattern(this.identificationPatternId)
            .then(identificationPattern => {
                this.search.emit(identificationPattern);
            });
    }
}
