import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { ExchangeComponent } from './exchange.component';

const EXCHANGE_ROUTE = [{ path: '', component: ExchangeComponent }];

@NgModule({
    declarations: [ExchangeComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(EXCHANGE_ROUTE)],
})
export class ExchangeModule {}
