import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { CompaniesService } from '../../../shared/services/companies/companies.service';
import { PageService } from '../../../shared/services/page/page.service';

@Injectable()
export class CrossDataService extends PageService {
    constructor(public apiService: ApiService, private companiesService: CompaniesService) {
        super(apiService);
    }

    /**
     * Return the company's available properties.
     */
    async getSitesProperties(): Promise<any> {
        const res = await this.get('/api/sites/company/available/properties');
        return res.data;
    }

    getCompanyUsers(companyId, userTypesAllowed = null, companyRolesAllowed = null) {
        return this.companiesService.getCompanyUsers(companyId, userTypesAllowed, companyRolesAllowed);
    }
}
