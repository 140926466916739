import * as moment from 'moment';
import { Moment } from 'moment';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';

// interfaces
import { OptionValue, TypeFilter } from 'app/shared/models/filter-config.interface';
import { Region } from 'app/shared/models/localisations.interface';
import { AvailableYears, ContractProvidersParam, FilterAlias, QueryExport } from './filter.interface';

// services
import { CompanyLight } from 'app/shared/models/company.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { PageService } from 'app/shared/services/page/page.service';
import { RightsService } from 'app/shared/services/rights/rights.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { PropertiesService } from 'app/shared/services/sites/properties.service';

/**
 * This service is hugely used in the application. It mainly handles the queries to get values provided to the filters.
 * For example, it gets categories, regions, and any kind of filters related data.
 * It also modifies some responses to match better with the components/controllers
 */
@Injectable()
export class FilterService extends PageService {
    userId = null;
    /** Company from the current user's session */
    public sessionCompany: CompanyLight = null;

    /**
     * Use for months moment date format for the url params
     */
    public readonly urlDateFormat = 'MM-YYYY';

    /**
     * Used only for the inputs that require this format to function
     */
    public readonly inputDateFormat = 'YYYY-MM';

    /**
     * Use for year moment date format
     */
    public readonly yearDateFormat = 'YYYY';

    /**
     * URL parameters aliases
     */
    private paramAliases = {
        categories: 'c',
        customFilter: 'cf',
        energies: 'e',
        entities: 'r',
        regions: 'a',
        routingreferences: 'r',
        sites: 's',
        sitesStatus: 'p', // perimeter
        fuelCards: 'fc',
        fuelTypes: 'f',
        vehicleCategories: 'c',
        vehicles: 'v',
        company: 'le', // legal entity
        companies: 'les', // legal entities
        routingReferencesItems: 'e',
        vehiclesItems: 'f',
        year: 'year',
        dateStart: 'ds',
        dateEnd: 'de',
    };

    constructor(
        public apiService: ApiService,
        private sessionService: SessionService,
        private rightsService: RightsService,
        private companiesService: CompaniesService,
        private energyService: EnergyService,
        private propertiesService: PropertiesService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        super(apiService);
        this.setCompanyUserSession();
    }

    /**
     * Set company and user id from session
     */
    private setCompanyUserSession() {
        const user = this.sessionService.getUser();
        this.sessionCompany = this.sessionService.getCompany();
        this.userId = user ? user._id : '';
    }

    /**
     * Get api data config
     *
     * @param {string} displayName
     * @param {string} propertyName
     * @param {Promise<*>} apiServiceName
     * @param {*} defaultValue
     * @param {string} type
     * @param {boolean} multiSelect
     * @returns {Promise<*>}
     */
    private getApiDataConfig(
        displayName: string,
        propertyName: string,
        apiServiceName: Promise<any>,
        defaultValue: any = null,
        type: string = 'select',
        multiSelect: boolean = false
    ): Promise<any> {
        // Here call the API category service
        return apiServiceName.then(datas => {
            return {
                type: TypeFilter[type],
                displayName,
                propertyName,
                selectedValue: null,
                defaultValue,
                data: datas,
                multiSelect,
                multiSelectedValues: null,
            };
        });
    }

    /**
     * Get data config
     *
     * @param {string} displayName
     * @param {string} propertyName
     * @param {*[]} data
     * @param {*} defaultValue
     * @param {string} type
     * @param {boolean} multiSelect
     * @returns {Promise<*>}
     */
    private getDataConfig(
        displayName: string,
        propertyName: string,
        data: any[] = [],
        defaultValue: any = null,
        type: string = 'select',
        multiSelect: boolean = false
    ): Promise<any> {
        // Here call the API category service

        return new Promise(resolve => {
            return resolve({
                type: TypeFilter[type],
                displayName,
                propertyName,
                selectedValue: null,
                defaultValue,
                data,
                multiSelect,
                multiSelectedValues: null,
            });
        });
    }

    private getYearsSingleConfig(onlyValuesFromCompany: boolean): Promise<any> {
        return Promise.all([
            this.getApiDataConfig('Date', 'dateYear', this.getYearsWithData(onlyValuesFromCompany), '2016'),
        ]);
    }

    /**
     * Format sites list for display
     * @param {*[]} data - list of sites
     * @returns {{id: string; text: string}[]} text is formatted sites addresses
     */
    private formatSitesList(data: any[]): Array<{ id: string; text: string }> {
        const flattenSiteArray = [];

        data.forEach(site => {
            flattenSiteArray.push({
                id: site._id,
                text: this.formatCompleteAddress(
                    site.streetName,
                    site.streetNumber,
                    site.zipcode,
                    site.city,
                    site.name
                ),
            });
        });

        return flattenSiteArray;
    }

    private formatCompleteAddress(
        streetName: string,
        streetNumber: string,
        zipCode: string,
        city: string,
        name: string
    ) {
        if (!streetNumber && !streetName && !city && !zipCode) {
            return name;
        }
        const streetnb = streetNumber || '';
        const zipcode = zipCode || '';
        return `${streetnb} ${streetName} ${city} ${zipcode}`;
    }

    private flattenCategorieJson(data): Array<{ id: string; text: string }> {
        const flattenCatArray: Array<{ id: string; text: string }> = [];

        data.forEach(category => {
            flattenCatArray.push(this.getFlatObject(category));

            category.subcategories.forEach(subcategory => {
                flattenCatArray.push(this.getFlatObject(subcategory));

                if (subcategory.complements.length) {
                    subcategory.complements.forEach(complement => {
                        flattenCatArray.push(this.getFlatObject(complement));
                    });
                }
            });
        });

        return flattenCatArray;
    }

    private flattenCategorieJsonNewFilter(data): OptionValue[] {
        const flattenCatArray: OptionValue[] = [];

        data.forEach(category => {
            flattenCatArray.push(this.getFlatObjectNewFilter(category));

            category.subcategories.forEach(subcategory => {
                flattenCatArray.push(this.getFlatObjectNewFilter(subcategory));

                if (subcategory.complements.length) {
                    subcategory.complements.forEach(complement => {
                        flattenCatArray.push(this.getFlatObjectNewFilter(complement));
                    });
                }
            });
        });

        return flattenCatArray;
    }

    public getQueryResultByProvider(provider) {
        let functionToCall = '';

        switch (provider) {
            case 'energies':
                functionToCall = 'getEnergiesWithoutWater';
                break;

            case 'water':
                functionToCall = 'getWater';
                break;

            case 'sites':
                functionToCall = 'getSites';
                break;

            case 'regions':
                functionToCall = 'getRegionsForAutoCompletion';
                break;

            case 'categories':
                functionToCall = 'getCategoriesForAutoCompletionNewFilter';
                break;

            case 'companies':
                functionToCall = 'getCompanyAndAllBranchesForFilter';
                break;

            default:
                break;
        }

        if (functionToCall) {
            return this[functionToCall]();
        }

        return Promise.resolve([]);
    }

    /**
     * Transform an Object hodling regions into a array of regions formated as option values:
     *  - the region's key as value
     *  - the region's name as displayed name
     * @param {{[key: number]: Region}} regions
     * @retuns {OptionValue[]}
     */
    public flattenRegionsJson(regions: { [key: number]: Region }): OptionValue[] {
        const flattenRegionArray: OptionValue[] = [];

        Object.keys(regions).forEach(key => {
            flattenRegionArray.push({
                value: key.toString(),
                displayName: regions[key].name,
            });
        });

        return flattenRegionArray;
    }

    /**
     * Transform an Object hodling regions into a array of unwinded departments formated as options values:
     *  - the department's key as value
     *  - the department's code as displayed name
     * @param {{[key: number]: Region}} regions
     * @retuns {OptionValue[]}
     */
    public flattenDepartmentsJson(regions: { [key: number]: Region }): OptionValue[] {
        const flattenDepartmentsArray = [];

        Object.keys(regions).forEach(key => {
            regions[key].departments.forEach(department => {
                department.codes.forEach(code => {
                    flattenDepartmentsArray.push({
                        value: code,
                        displayName: code,
                    });
                });
            });
        });
        return flattenDepartmentsArray;
    }

    /**
     * Return a new object having the id and the id concatenated to the name as text.
     * @param {id: string, name: string} obj
     * @returns {id: string, text}
     */
    private getFlatObject(obj: { id: string; name: string }): { id: string; text: string } {
        return {
            id: obj.id,
            text: obj.id + ' - ' + obj.name,
        };
    }

    /**
     * Return an object value the id as value, and id concatenated to name as displayed name.
     * @param {id: string, name: string} obj
     * @returns {OptionValue}
     */
    private getFlatObjectNewFilter(obj: { id: string; name: string }): OptionValue {
        return {
            value: obj.id,
            displayName: obj.id + ' - ' + obj.name,
        };
    }

    private getDatePickerConfig(displayName: string, propertyName: string, defaultValue: any = null): Promise<any> {
        if (!displayName || !propertyName) {
            throw new Error('[getDatePickerConfig] - Argument missing');
        }

        return Promise.resolve({
            type: TypeFilter.date,
            displayName,
            propertyName,
            selectedValue: null,
            defaultValue,
            data: [],
        });
    }

    private getCategoriesConfig(onlyValuesFromCompany: boolean): Promise<any> {
        return Promise.all([
            this.getApiDataConfig(
                'Catégories',
                'categories',
                this.getCategoriesForAutoCompletion(onlyValuesFromCompany),
                null,
                'selectAutoComplete'
            ),
        ]);
    }

    private getMultiCategoriesConfig(onlyValuesFromCompany: boolean): Promise<any> {
        return Promise.all([
            this.getApiDataConfig(
                'Catégories',
                'categories',
                this.getCategoriesForAutoCompletion(onlyValuesFromCompany),
                null,
                'selectAutoComplete',
                true
            ),
        ]);
    }

    private getMultiSitesConfig(): Promise<any> {
        return Promise.all([
            this.getApiDataConfig('Sites', 'sites', this.getSitesForAutoCompletion(), null, 'selectAutoComplete', true),
        ]);
    }

    private getYearsConfig(): Promise<any> {
        const dateEnd = moment().year() + '-' + moment().month();
        const dateStart = moment().year() - 1 + '-' + moment().month();

        return Promise.all([
            this.getDatePickerConfig('Début', 'dateStart', dateStart),
            this.getDatePickerConfig('Fin', 'dateEnd', dateEnd),
        ]);
    }

    private getRegionsConfig(onlyValuesFromCompany: boolean): Promise<any> {
        return Promise.all([
            this.getApiDataConfig(
                'Régions',
                'regions',
                this.getRegionsForAutoCompletion(onlyValuesFromCompany),
                null,
                'selectAutoComplete'
            ),
        ]);
    }

    private getMultiRegionsConfig(onlyValuesFromCompany: boolean): Promise<any> {
        return Promise.all([
            this.getApiDataConfig(
                'Régions',
                'regions',
                this.getRegionsForAutoCompletion(onlyValuesFromCompany),
                null,
                'selectAutoComplete',
                true
            ),
        ]);
    }

    /**
     * Get the filter config for the main page
     * @returns {Promise<*>}
     */
    private getMainPageConfig(): Promise<any> {
        const dateEnd = moment().year() + '-' + moment().format('MM');
        const dateStart = moment().year() - 1 + '-' + moment().format('MM');

        return this.getCompanyAndAllBranchesForFilter()
            .then(branches => {
                // because the config depends on the number of branches, the config is built in two phases with a concat
                // --> we need an array of asynchronous functions that will be called at the same time
                let configFunctions =
                    branches.length > 1
                        ? [
                              this.getDataConfigFunction(
                                  'Entités juridiques',
                                  'companies',
                                  branches,
                                  [this.sessionCompany._id],
                                  'treeSelect',
                                  true
                              ).bind(this),
                          ]
                        : [];

                configFunctions = configFunctions.concat([
                    this.getDatePickerConfigFunction('Début', 'dateStart', dateStart.toString()).bind(this),
                    this.getDatePickerConfigFunction('Fin', 'dateEnd', dateEnd.toString()).bind(this),
                    this.getApiDataConfigFunction('Lieu', 'regions', this.getAllRegions()).bind(this),
                    this.getApiDataConfigFunction(
                        'Catégories',
                        'categories',
                        this.getCategoriesForAutoCompletion(true),
                        null,
                        'selectAutoComplete'
                    ).bind(this),
                    this.getApiDataConfigFunction('Énergie', 'energies', this.getEnergiesForFilter(true)).bind(this), // aggregates the energies of the company + its branches
                ]);

                // Orders mather
                return Promise.resolve(configFunctions);
            })
            .then(configFunctions => {
                return Promise.all(
                    configFunctions.map(func => {
                        return func();
                    })
                );
            });
    }

    /**
     * Get the filter config for the cartography page
     * @returns {Promise<*>}
     */
    private getCartographyConfig(): Promise<any> {
        return this.getCompanyAndAllBranchesForFilter()
            .then(branches => {
                // because the config depends on the number of branches, the config is built in two phases with a concat
                // --> we need an array of asynchronous functions that will be called at the same time
                let configFunctions =
                    branches.length > 1
                        ? [
                              this.getDataConfigFunction(
                                  'Entités juridiques',
                                  'companies',
                                  branches,
                                  [this.sessionCompany._id],
                                  'treeSelect',
                                  true
                              ).bind(this),
                          ]
                        : [];

                configFunctions = configFunctions.concat([
                    this.getApiDataConfigFunction('Lieu', 'regions', this.getAllRegions()).bind(this),
                    // this.getApiDataConfig('Catégorie', 'category',  this.getCategories()),
                    this.getApiDataConfigFunction('Énergie', 'energies', this.getEnergiesForFilter(true)).bind(this), // aggregates the energies of the company + its branches
                ]);

                return Promise.resolve(configFunctions);
            })
            .then(configFunctions => {
                return Promise.all(
                    configFunctions.map(func => {
                        return func();
                    })
                );
            });
    }

    /**
     * Get data config function
     *
     * @param {string} displayName
     * @param {string} propertyName
     * @param {*[]} data
     * @param {*} defaultValue
     * @param {string} type
     * @param {boolean} multiSelect
     * @returns {function}
     */
    private getDataConfigFunction(
        displayName: string,
        propertyName: string,
        data: any[] = [],
        defaultValue: any = null,
        type: string = 'select',
        multiSelect: boolean = false
    ) {
        return function() {
            return this.getDataConfig(displayName, propertyName, data, defaultValue, type, multiSelect);
        };
    }

    /**
     * Get api data config function
     *
     * @param {string} displayName
     * @param {string} propertyName
     * @param {Promise<*>} apiServiceName
     * @param {*} defaultValue
     * @param {string} type
     * @param {boolean} multiSelect
     * @returns {function}
     */
    private getApiDataConfigFunction(
        displayName: string,
        propertyName: string,
        apiServiceName: Promise<any>,
        defaultValue: any = null,
        type: string = 'select',
        multiSelect: boolean = false
    ) {
        return function() {
            return this.getApiDataConfig(displayName, propertyName, apiServiceName, defaultValue, type, multiSelect);
        };
    }

    private getDatePickerConfigFunction(displayName: string, propertyName: string, defaultValue: any = null) {
        return function() {
            return this.getDatePickerConfig(displayName, propertyName, defaultValue);
        };
    }

    private getEnergyDocumentsConfig(): Promise<any> {
        return Promise.all([
            this.getDatePickerConfig('Début', 'dateStart'),
            this.getDatePickerConfig('Fin', 'dateEnd'),
            this.getApiDataConfig('Lieu', 'place', this.getAllRegions()),
            // this.getApiDataConfig('Entité juridique', 'entity', this.getCompanyAndAllBranchesForFilter()),
            // this.getApiDataConfig('Catégorie', 'category', this.getCategories()),
            this.getApiDataConfig('Énergie', 'energy', this.getEnergiesWithoutWater(), 'elec'),
            // this.getApiDataConfig('Site', 'site', this.getSiteNames()),
            // this.getApiDataConfig('Document', 'document', this.getDocuments())
        ]);
    }

    private getWaterDocumentsConfig(): Promise<any> {
        return Promise.all([
            this.getDatePickerConfig('Début', 'dateStart'),
            this.getDatePickerConfig('Fin', 'dateEnd'),
            this.getApiDataConfig('Lieu', 'place', this.getAllRegions()),
            // this.getApiDataConfig('Entité juridique', 'entity', this.getCompanyAndAllBranchesForFilter()),
            this.getApiDataConfig('Énergie', 'energy', this.getWater(), 'water'),
            // this.getApiDataConfig('Site', 'site', this.getSiteNames()),
            // this.getApiDataConfig('Document', 'document', this.getDocuments())
        ]);
    }

    private getBeginEndDateConfig(): Promise<any> {
        // Orders mather
        return Promise.all([
            this.getDatePickerConfig('Début', 'dateStart', '2016-01'),
            this.getDatePickerConfig('Fin', 'dateEnd', '2017-01'),
        ]);
    }

    private getMultiEnergiesConfig(): Promise<any> {
        return Promise.all([
            this.getApiDataConfig(
                'Énergie',
                'energies',
                this.getEnergiesWithoutWaterForAutoCompletion(),
                'elec',
                'selectAutoComplete',
                true
            ),
        ]);
    }

    /**
     * pas de valeur par défaut, appelée une seule fois
     *
     * @param {string} pageName
     * @param {boolean} onlyValuesFromCompany
     * @returns {*}
     */
    public getFilterConfig(pageName: string, onlyValuesFromCompany: boolean): any {
        // reinit the company from the session variables, as it might have changed from the navbar top
        this.setCompanyUserSession();

        switch (pageName) {
            case 'main':
                return this.getMainPageConfig();
            case 'cartographie':
                return this.getCartographyConfig();
            case 'beginEndDate':
                return this.getBeginEndDateConfig();
            case 'energyDocuments':
                return this.getEnergyDocumentsConfig();
            case 'waterDocuments':
                return this.getWaterDocumentsConfig();
            case 'categories':
                return this.getCategoriesConfig(onlyValuesFromCompany);
            case 'multi-categories':
                return this.getMultiCategoriesConfig(onlyValuesFromCompany);
            case 'years':
                return this.getYearsConfig();
            case 'multi-sites':
                return this.getMultiSitesConfig();
            case 'regions':
                return this.getRegionsConfig(onlyValuesFromCompany);
            case 'multi-regions':
                return this.getMultiRegionsConfig(onlyValuesFromCompany);
            case 'multi-energies':
                return this.getMultiEnergiesConfig();
            case 'year-single':
                return this.getYearsSingleConfig(onlyValuesFromCompany);
            default:
                return this.getMainPageConfig();
        }
    }

    /**
     * Get company and all branches for filter
     * @returns {Promise<OptionValue[]}
     */
    async getCompanyAndAllBranchesForFilter(): Promise<OptionValue[]> {
        const branchesList: CompanyLight[] = await this.getAllVisibleBranches().catch(() => []);

        const responseDisplayed: OptionValue[] = [
            {
                displayName: this.sessionCompany.name,
                value: this.sessionCompany._id,
            },
        ];

        branchesList.forEach(branch => {
            responseDisplayed.push({
                displayName: branch.name,
                value: branch._id,
            });
        });

        return responseDisplayed;
    }

    /**
     * Get all visible branches for current company (branches and sub-branches, ...)
     * @param {boolean} isNameIndentRequired - if true, sort branches by name alphabetically and indent names with character "-" (one per level)
     * @returns {Promise<CompanyLight[]>} list of branches
     */
    async getAllVisibleBranches(isNameIndentRequired: boolean = true): Promise<CompanyLight[]> {
        const res = await this.companiesService.getBranches(this.sessionCompany._id, true);
        const branches = res.data;
        if (!isNameIndentRequired) {
            return branches;
        }
        /**
         * Currently, we group then flatten.
         * A better exists without grouping then flattening (working with an index array),
         * but the grouping function might be interesting in the futur.
         * Performances are still pretty good over 12 branches (3 levels), less than 1ms.
         */
        const groups = this.groupBranches(this.sessionCompany._id, [], branches);
        return this.flattenGroupedBranchList(groups);
    }

    /**
     * Group branches by holding from a flat list. Sort alphabetically each level of branches.
     * Recursive function.
     * @param {string} holding - holding id of the current branch level. Ex. For branch of level 3, holding will be the _id of the level 2 company.
     * @param {{item: any, children: any[]}[]} branchesGrouped - current level branch group. Children property is the same type as branchesGrouped.
     * @param {any[]} branchesFlat - list of all branches in a single array.
     * @returns {{item: any, children: any[]}[]} branches groupes by holding
     */
    groupBranches(
        holding: string,
        branchesGrouped: Array<{ item: any; children: any[] }>,
        branchesFlat: any[]
    ): Array<{ item: any; children: any[] }> {
        const children = branchesFlat.filter(x => x.holding === holding);
        // Remove element from orginal array, to avoid cycles
        children.forEach(child => {
            branchesFlat.splice(branchesFlat.findIndex(e => e._id === child._id), 1);
        });
        children.forEach(child => {
            const group = { item: child, children: this.groupBranches(child._id, [], branchesFlat) };
            branchesGrouped.push(group);
        });
        branchesGrouped.sort((a, b) => a.item.name.localeCompare(b.item.name));
        return branchesGrouped;
    }

    /**
     * Flatten group branch list and add text identation (one "-" per level) for branches.
     * Recursive function.
     * @param {{item: any, children: any[]}[]} branchesList - list of branches grouped by holding
     * @param {any[]} flatList - list of branches, with names indented, in a single array.
     * @param {number} index - index of current level in company tree (ex. first level branch index 1, second level index 2, ...)
     * @returns {any[]} list of branches
     */
    flattenGroupedBranchList(
        branchesList: Array<{ item: any; children: any[] }>,
        flatList: any[] = [],
        index: number = 1
    ): any[] {
        branchesList.forEach(branch => {
            branch.item.name = '-'.repeat(index) + ' ' + branch.item.name;
            flatList.push(branch.item);
            this.flattenGroupedBranchList(branch.children, flatList, index + 1);
        });
        return flatList;
    }

    /**
     * Get categories
     * @param {boolean} onlyValuesFromCompany
     * @param {Params} params
     * @returns {Promise<*>}
     */
    public async getCategories(onlyValuesFromCompany: boolean, params: Params = {}): Promise<any> {
        return this.propertiesService.getProperties(onlyValuesFromCompany, params);
    }

    /**
     * Get categories list for autocomplete (new version with data object having the list)
     * @param {boolean} onlyValuesFromCompany - if true return only for the chosen company, if false for all companies
     * @param {Params} params - params to add in request
     * @returns {Promise<{data: {value: string, displayName: string}[]}>} list of categories
     */
    public async getCategoriesForAutoCompletionNewFilter(
        onlyValuesFromCompany: boolean = true,
        params: Params = {}
    ): Promise<{ data: OptionValue[] }> {
        const data = await this.getCategories(onlyValuesFromCompany, params);
        return {
            data: this.flattenCategorieJsonNewFilter(data.categories),
        };
    }

    /**
     * Get categories list for autocomplete (old version with directly the list)
     * @param {boolean} onlyValuesFromCompany - if true return only for the chosen company, if false for all companies
     * @param {Params} params - params to add in request
     * @returns {Promise<{id: string, text: string}[]>} list of categories
     */
    public async getCategoriesForAutoCompletion(
        onlyValuesFromCompany: boolean,
        params: Params = {}
    ): Promise<Array<{ id: string; text: string }>> {
        const data = await this.getCategories(onlyValuesFromCompany, params);
        return this.flattenCategorieJson(data.categories);
    }

    /**
     * Get regions list for autocomplete
     * @param {boolean} onlyValuesFromCompanies - if true return only for the chosen companies, if false for all companies
     * @param {Params} params - params to add in request
     * @returns {Promise<{data: OptionValue[]}>} list of categories
     */
    public async getRegionsForAutoCompletion(
        onlyValuesFromCompanies: boolean = true,
        params: Params = {}
    ): Promise<{ data: OptionValue[] }> {
        const data = await this.getRegions(onlyValuesFromCompanies, params);
        return {
            data: this.flattenRegionsJson(data),
        };
    }

    private getEnergiesWithoutWaterForAutoCompletion(): any {
        return this.getEnergiesWithoutWater().then(data => {
            return this.formateEnergiesForAutoComp(data);
        });
    }

    /**
     * Get the current company's fluids.
     * Associate the English (values) with the French displayed name.
     * Aggregate the fluids of the company's branches as well if required.
     * Add in first position the option "all" iwth value null.
     * @param {boolean} loadBranchesEnergies - if true, look for the energies of the branches as well.
     * @returns {Promise<Array<{value: string|null, displayName: string}>>}
     */
    private async getEnergiesForFilter(loadBranchesEnergies: boolean = false): Promise<any> {
        const data = await this.getEnergies(loadBranchesEnergies);
        const energies = data.data;
        energies.unshift({
            value: null,
            displayName: 'Toutes',
        });
        return energies;
    }

    private formateEnergiesForAutoComp(data: any) {
        const formatedArray = [];

        data.forEach(energy => {
            formatedArray.push({
                id: energy.value,
                text: energy.displayName,
            });
        });

        return formatedArray;
    }

    public formatQueryToUrl(queryExport: QueryExport) {
        let url = `?start=${queryExport.dateStart}&end=${queryExport.dateEnd}`;
        url += queryExport.energies ? `&energies=${queryExport.energies}` : '';
        url += queryExport.sites ? `&sites=${queryExport.sites}` : '';
        url += queryExport.categories ? `&categories=${queryExport.categories}` : '';
        url += queryExport.regions ? `&regions=${queryExport.regions}` : '';
        url += queryExport.routingreferences ? `&routingreferences=${queryExport.routingreferences}` : '';
        url += queryExport.sitesStatus ? `&sitesStatus=${queryExport.sitesStatus}` : '';
        url += queryExport.routingreferencesStatus
            ? `&routingreferencesStatus=${queryExport.routingreferencesStatus}`
            : '';
        url += queryExport.customFilter ? `&customFilter=${queryExport.customFilter}` : '';
        url += queryExport.dju ? `&dju=true` : '';
        url += queryExport.companyId ? `&companyId=${queryExport.companyId}` : '';
        url += queryExport.companies ? `&companies=${queryExport.companies}` : '';
        url += queryExport.vehicles ? `&vehicles=${queryExport.vehicles}` : '';
        url += queryExport.fuelCards ? `&fuelCards=${queryExport.fuelCards}` : '';
        url += queryExport.vehicleCategories ? `&vehicleCategories=${queryExport.vehicleCategories}` : '';
        url += queryExport.fuelTypes ? `&fuelTypes=${queryExport.fuelTypes}` : '';

        return url;
    }

    /**
     * Get regions list
     * @param {boolean} onlyValuesFromCompany - if true return only for the chosen company, if false for all companies
     * @param {Params} params - params to add in request
     * @returns {Promise<Region[]>} list of regions
     */
    public async getRegions(onlyValuesFromCompany: boolean, params: Params = {}): Promise<Region[]> {
        const url = onlyValuesFromCompany ? `/api/sites/company/available/regions` : `/api/sites/all/available/regions`;

        try {
            const res = await this.get(url, null, params);
            if (!res.data) {
                throw new Error('Regions : no_data');
            }
            return res.data;
        } catch (err) {
            err.error_code = 'error_getRegions';
            return Promise.reject(err);
        }
    }

    /**
     * Get sites
     * @param {*} filters
     * @returns {Promise<APICitronResponse<any[]>>}
     */
    public getSites(filters: any = null): Promise<APICitronResponse<any[]>> {
        const companyId: string = filters && filters.companyId ? filters.companyId : this.sessionCompany._id;
        return this.post('/api/companies/' + companyId + '/sites', filters);
    }

    /**
     * Get sites populated
     * @param {*} filters
     * @returns {Promise<APICitronResponse<any[]>>}
     */
    public getSitesPopulated(filters: any): Promise<APICitronResponse<any[]>> {
        const companyId: string = filters.companyId || this.sessionService.getCompany()._id;
        return this.post('/api/companies/' + companyId + '/sites', filters);
    }

    /**
     * Get sites for auto-completion
     * @returns {Promise<{id: string; text: string;}[]}
     */
    private async getSitesForAutoCompletion(): Promise<Array<{ id: string; text: string }>> {
        const data = await this.getSites({});
        const d = this.formatSitesList(data.data);
        return d;
    }

    private getAllRegions(): any {
        return this.fakeData('/sites/places');
    }

    /**
     * Get the selected companies' fluids.
     * Associate the English (values) with the French displayed name.
     * Aggregate the fluids of the company's branches as well if required.
     * @param {boolean} loadBranchesEnergies - if true, look for the energies of the branches as well.
     * @param {string[]} companies - companies to get energies from
     * @returns {Promise<{data: Array<{value: string|null, displayName: string}>, code: number, success: boolean}>}
     */
    public async getEnergies(loadBranchesEnergies: boolean = false, companies?: string[]): Promise<any> {
        const company = this.sessionCompany._id;

        const results: APICitronResponse = await this.get(
            `/api/contracts/energies/${company}?loadBranches=${loadBranchesEnergies}`,
            null,
            { companies: companies ? companies.join(',') : [company] }
        );
        const fluidsCompany: string[] = results.data;

        results.data = fluidsCompany.map(fluid => {
            const fluidDisplayName = this.energyService.energyFullText(fluid);
            const fluidObject = {
                value: fluid,
                displayName: fluidDisplayName,
            };
            return fluidObject;
        });

        return results;
    }

    /**
     * Get the given companies fuel products
     * Associate the English (values) with the French displayed name.
     * Aggregate the fuel products of the given companies' branches as well if required.
     * @param {boolean} loadBranchesEnergies
     * @param {string[]} companies - coppanies to get fuel types from
     * @returns {{value: string, displayName: string}[]} list of fuel products and their French name to display
     */
    public async getFuelTypesFromCompanies(
        loadBranchesEnergies: boolean = false,
        companies?: string[]
    ): Promise<Array<{ value: string; displayName: string }>> {
        const company = this.sessionCompany._id;
        const results: APICitronResponse = await this.get(
            `/api/vehicles/products/${company}?loadBranches=${loadBranchesEnergies}`,
            null,
            { companies: companies.join(',') }
        );

        const fuelTypesCompanies: string[] = results.data;

        return fuelTypesCompanies.map(product => {
            const productDisplayName: string = this.energyService.fuelProductFullText(product);
            return {
                value: product,
                displayName: productDisplayName,
            };
        });
    }

    /**
     * Get company fluids excluding water.
     * @returns {Promise<{value: string, displayName: string}>}
     */
    public async getEnergiesWithoutWater(): Promise<any> {
        const result = await this.getEnergies();
        result.data = result.data.filter(energy => {
            return energy.value !== 'water';
        });
        return result;
    }

    /**
     * Get water fluid object
     * @returns {Promise<{data: Array<{value: string|null, displayName: string}>, code: number, success: boolean}>}
     */
    private getWater(): any {
        return new Promise(resolve => {
            resolve({
                data: [
                    {
                        value: 'water',
                        displayName: this.energyService.energyFullText('water'),
                    },
                ],
            });
        });
    }

    /**
     * Get years with data available for companies
     * @param {boolean} onlyValuesFromCompanies - if true return only for the chosen companies, if false for all companies
     * @param {Params} params - params to add in request (eg. companies)
     * @returns {Promise<APICitronResponse<number[]>>}
     */
    private getYearsAvailable(
        onlyValuesFromCompanies: boolean,
        params: Params = {}
    ): Promise<APICitronResponse<number[]>> {
        const company = params && params.company ? params.company : this.sessionCompany._id;
        const url = onlyValuesFromCompanies ? `/api/contracts/years/${company}` : `/api/contracts/years/`;
        return this.get(url, null, params);
    }

    /**
     * Get years with data available for given companies and for a fluid.
     * @param {string} fluids - fluids to get dates for
     * @param {Params} params - params for request (eg. companies)
     * @returns {Promise<APICitronResponse<number[]>>}
     */
    private getYearsAvailableForFluids(fluids: string[], params: Params = {}): Promise<APICitronResponse<number[]>> {
        const company = params && params.company ? params.company : this.sessionCompany._id;
        return this.get(`/api/contracts/years-by-energy/${company}`, null, {
            fluids: fluids.join(','),
            companies: params.companies,
        });
    }

    /**
     * Get the list of years during which the companies have bills data
     * @param {boolean} onlyValuesFromCompanies - if true return only for the chosen companies, if false for all companies
     * @param {Params} params
     * @param {string} params.companies - companies ids to get the bills dates from
     * @returns {Promise<{value: string, displayName: string}[]>} - available years to display
     * @TODO check if API needs changes to handle multiple companies
     */
    public async getYearsWithData(
        onlyValuesFromCompanies: boolean = true,
        params: Params = {}
    ): Promise<Array<{ value: string; displayName: string }>> {
        let data: APICitronResponse<number[]>;
        if (params && params.fluids) {
            data = await this.getYearsAvailableForFluids(params.fluids, params);
        } else {
            data = await this.getYearsAvailable(onlyValuesFromCompanies, params);
        }
        const arrayFormated: Array<{ value: string; displayName: string }> = [];

        data.data.forEach(year => {
            if (year > 1970) {
                arrayFormated.push({
                    displayName: year.toString(),
                    value: year.toString(),
                });
            }
        });

        return arrayFormated;
    }

    /**
     * Returns the list of providers available inside the companie's contrats
     * @param {string} companyId
     * @param {ContractProvidersParam?} param - params to apply to the request
     * @returns {Promise<{data: string[]}>}
     */
    public getContractProviders(companyId: string, param?: ContractProvidersParam): Promise<{ data: string[] }> {
        const data = param ? param : {};
        return this.get('/api/contracts/' + companyId + '/providers', null, data);
    }

    fakeData(route) {
        let response;

        switch (route) {
            case '/sites/legal-entities':
                response = [
                    {
                        displayName: 'LegalEntity1',
                        value: 'LEGALENTITY1',
                    },
                    {
                        displayName: 'LegalEntity2',
                        value: 'LEGALENTITY2',
                    },
                    {
                        displayName: 'LegalEntity3',
                        value: 'LEGALENTITY3',
                    },
                    {
                        displayName: 'Tous',
                        value: null,
                    },
                ];

                break;

            case '/sites/categories':
                response = [
                    {
                        displayName: 'Administratif',
                        value: 'ADMINISTRATIF',
                    },
                    {
                        displayName: 'Association',
                        value: 'ASSOCIATION',
                    },
                    {
                        displayName: 'CAS',
                        value: 'CAS',
                    },
                    {
                        displayName: 'CCAS',
                        value: 'CCAS',
                    },
                    {
                        displayName: 'Commerce et artisanat',
                        value: 'COMMERCE ET ARTISANAT',
                    },
                    {
                        displayName: 'Culture',
                        value: 'CULTURE',
                    },
                    {
                        displayName: 'Education',
                        value: 'EDUCATION',
                    },
                    {
                        displayName: 'Espaces verts',
                        value: 'ESPACES VERTS',
                    },
                    {
                        displayName: 'Etat civil',
                        value: 'ETAT CIVIL',
                    },
                    {
                        displayName: 'Jeunesse',
                        value: 'JEUNESSE',
                    },
                    {
                        displayName: 'Patrimoine',
                        value: 'PATRIMOINE',
                    },
                    {
                        displayName: 'Petite enfance',
                        value: 'PETITE ENFANCE',
                    },
                    {
                        displayName: 'Politique de la ville',
                        value: 'POLITIQUE DE LA VILLE',
                    },
                    {
                        displayName: 'Privé',
                        value: 'PRIVE',
                    },
                    {
                        displayName: 'Santé',
                        value: 'SANTE',
                    },
                    {
                        displayName: 'Sécurité',
                        value: 'SECURITE',
                    },
                    {
                        displayName: 'Sport',
                        value: 'SPORT',
                    },
                    {
                        displayName: 'Tous',
                        value: null,
                    },
                ];
                break;

            case '/sites/places':
                response = [
                    {
                        value: '11',
                        displayName: 'Île-de-France',
                    },
                    {
                        value: '24',
                        displayName: 'Centre-Val de Loire',
                    },
                    {
                        value: '27',
                        displayName: 'Bourgogne-Franche-Comté',
                    },
                    {
                        value: '28',
                        displayName: 'Normandie',
                    },
                    {
                        value: '32',
                        displayName: 'Nord-Pas-de-Calais-Picardie',
                    },
                    {
                        value: '44',
                        displayName: 'Alsace-Champagne-Ardenne-Lorraine',
                    },
                    {
                        value: '52',
                        displayName: 'Pays de la Loire',
                    },
                    {
                        value: '53',
                        displayName: 'Bretagne',
                    },
                    {
                        value: '75',
                        displayName: 'Aquitaine-Limousin-Poitou-Charentes',
                    },
                    {
                        value: '76',
                        displayName: 'Languedoc-Roussillon-Midi-Pyrénées',
                    },
                    {
                        value: '84',
                        displayName: 'Auvergne-Rhône-Alpes',
                    },
                    {
                        value: '93',
                        displayName: "Provence-Alpes-Côte d'Azur",
                    },
                    {
                        value: '94',
                        displayName: 'Corse',
                    },
                    {
                        value: null,
                        displayName: 'Tous',
                    },
                ];
                break;

            case '/energies':
                response = [
                    {
                        displayName: 'Electricité',
                        value: 'elec',
                    },
                    {
                        displayName: 'Eau',
                        value: 'water',
                    },
                    {
                        displayName: 'Gaz',
                        value: 'gaz',
                    },
                    {
                        displayName: 'RCU',
                        value: 'heating',
                    },
                    {
                        displayName: 'Tous',
                        value: null,
                    },
                ];
                break;

            case '/energies/without/water':
                response = [
                    {
                        displayName: 'Electricité',
                        value: 'elec',
                    },
                    {
                        displayName: 'Gaz',
                        value: 'gaz',
                    },
                    {
                        displayName: 'RCU',
                        value: 'heating',
                    },
                ];
                break;

            case '/sites/years':
                response = [
                    {
                        displayName: '2017',
                        value: 'YEAR1',
                    },
                    {
                        displayName: '2016',
                        value: 'YEAR2',
                    },
                    {
                        displayName: '2015',
                        value: 'YEAR3',
                    },
                ];
                break;

            case '/sites/names':
                response = [
                    {
                        displayName: 'Lyreco Bernay',
                        value: 'NAME1',
                    },
                    {
                        displayName: 'Lyreco Caen',
                        value: 'NAME2',
                    },
                    {
                        displayName: 'Lyreco Cabourg',
                        value: 'NAME3',
                    },
                ];
                break;

            case '/energy/profile/info/energies':
                response = ['FIO', 'GAZ', 'ELE', 'CPC', 'NONE'];
                break;

            case '/energy/profile/info/emission/types':
                response = [
                    'VENTILO_CONVECTEUR',
                    'CONVECTEUR_ELECTRIQUE',
                    'CASSETTE_QUATRE_TUBE',
                    'CASSETTE_DEUX_TUBE',
                    'POUTRE_FROIDE',
                    'RADIAN',
                    'RADIATEUR_EAU',
                ];
                break;

            case '/documents':
                response = [
                    {
                        displayName: 'Factures type 1',
                        value: 'TYPE1',
                    },
                    {
                        displayName: 'Factures type 2',
                        value: 'TYPE2',
                    },
                    {
                        displayName: 'Classeurs',
                        value: 'TYPE3',
                    },
                ];
                break;

            default:
                response = {};
                break;
        }

        return Promise.resolve(response);
    }

    public getDisabledErrorMessage(filterSelected: string) {
        if (filterSelected) {
            switch (filterSelected) {
                case 'sites':
                    return 'Vous devez sélectionner au moins un site';
                case 'regions':
                    return 'Vous devez sélectionner au moins une région';
                case 'categories':
                    return 'Vous devez sélectionner au moins une catégorie';
                case 'companies':
                    return 'Vous devez sélectionner au moins une entreprise';
                case 'routingReferences':
                    return 'Vous devez sélectionner au moins un PDL';
                case 'custom-filters':
                    return 'Vous devez sélectionner un filtre personnalisé';
                case 'vehicleCategories':
                    return 'Vous devez sélectionner un segment';
                case 'fuelCards':
                    return 'Vous devez sélectionner une carte carburant';
                case 'vehicles':
                    return 'Vous devez sélectionner un véhicule';
            }
        }

        return '';
    }

    public getMaxElementsErrorMessage(filterSelected, nbMax) {
        if (filterSelected) {
            switch (filterSelected) {
                case 'sites':
                    return `Vous ne pouvez pas sélectionner plus de ${nbMax} sites`;
                case 'regions':
                    return `Vous ne pouvez pas sélectionner plus de ${nbMax} régions`;
                case 'categories':
                    return `Vous ne pouvez pas sélectionner plus de ${nbMax} catégories`;
            }
        }

        return '';
    }

    /**
     * Returns an array of all the regions' name in which the commpanies' sites are
     * ex: ["Île-de-France", "Alsace-Champagne-Ardenne-Lorraine"]
     * @param companies
     * @return {Promise<any>}
     */
    getRegionsFromCompanies(companies): Promise<any> {
        const route = '/api/regions/companies';
        return this.post(route, { companies }).then(res => {
            if (res.code === 200 && res.data) {
                return Promise.resolve(res.data);
            } else {
                const err = { errorCode: 'error_getRegionsFromCompanies' };
                return Promise.reject(err);
            }
        });
    }

    /**
     * Returns an array of all the departments number in which the commpanies' sites are
     * ex: ["75", "92"]
     * @param companies
     * @return {Promise<any>}
     */
    getDepartmentsFromCompanies(companies): Promise<any> {
        const route = '/api/regions/departments/companies';
        return this.post(route, { companies }).then(res => {
            if (res.code === 200 && res.data) {
                return Promise.resolve(res.data);
            } else {
                const err = { errorCode: 'error_getDepartmentsFromCompanies' };
                return Promise.reject(err);
            }
        });
    }

    /**
     * Returns an array of all the cities names in which the commpanies' sites are
     * @param {string[]} companies
     * @return {Promise<string[]>}
     */
    async getCitiesFromCompanies(companies: string[]): Promise<string[]> {
        const route = '/api/sites/cities';
        const params = { companies: companies.join(',') };
        const response = await this.get(route, null, params);
        if (response.code === 200 && response.data) {
            return response.data;
        } else {
            const err = { errorCode: 'error_getCitiesList' };
            throw err;
        }
    }

    /**
     * Get user custom filters list
     * @param {Params} params - params to add to the request (ex. companiesIds)
     */
    getUserCustomFilters(params: Params = {}) {
        return this.get('/api/custom-filters/list', null, params);
    }

    /**
     * Get a specific custom filter by id
     * @param {string} id - custom filter object id
     */
    async getCustomFilter(id: string) {
        return this.get('/api/custom-filters/item/' + id);
    }

    /**
     * Delete a specific custom filter by id
     * @param {string} id - custom filter object id
     */
    async deleteCustomFilter(id: string) {
        return this.delete('/api/custom-filters/crud/' + id);
    }

    /**
     * Save custom filter.
     * @param {*} customFilter - customFilter object to save
     * @param {Params} params - params to add to the request (ex. companiesIds)
     * @returns {Promise<*>} promise containing updated custom filter or new one if creation.
     */
    saveCustomFilter(customFilter: any, params: Params = {}): Promise<any> {
        const data = Object.assign({}, customFilter, params);
        return this.post('/api/custom-filters', data);
    }

    /**
     * Get scope filters display values for each parameter.
     * @param {ScopeFilter} scope - parameter filter to get the display values from.
     */
    async getScopeFilterDisplayValues(scope) {
        return this.post('/api/filters/display', scope);
        /*
        return {
            success: true,
            code: 200,
            data: {
                dateStart: '2018-01-01T00:00:00.000Z',
                dateEnd: '2018-12-31T22:59:59.999Z',
                energies: ['Électricité', 'Gaz'],
                sites: ['Maternelle RPC Gilbert'],
                regions: ['Ile de France'],
                categories: ['Service généraux des administrations', 'Enseignement du premier degré'],
                routingreferences: ['ABDCEF123'],
                sitesStatus: 'Tous',
                routingreferencesStatus: 'Tous',
                customFilter: 'Bâtiments tertiaires de Rhône Alpes'
            }
        }
        */
    }

    /**
     * Navigate to the same view with update parameters to take into account.
     * @param {object} params
     * @param {string?} params.r - routing reference id
     * @param {string?} params.s - site id
     * @param {string?} params.ds - date - start of the period
     * @param {string?} params.de - date - end of the period
     * @param {string?} params.year
     * @returns {Promise<boolean>}
     */
    public async updateRouteParams(params: Params, paramHandling: QueryParamsHandling = 'merge'): Promise<boolean> {
        return this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: paramHandling,
        });
    }

    /**
     * Check if valid date
     *
     * @param {Moment} date
     * @returns {boolean} true if the given date is valid
     */
    public isDateValid(date: Moment): boolean {
        return Boolean(date && date.isValid());
    }

    /**
     * Remove invalid params, depending on wich followup filter it is (energy or vehicles)
     * @param {Params} params url query params from the route service
     * @param {string[]} permittedParams - list of permitted params
     * @returns {Params}
     */
    public removeInvalidParams(params: Params, permittedParams: string[]): Params {
        for (const [key, value] of Object.entries(params)) {
            if (value === '' || !permittedParams.includes(key)) {
                delete params[key];
            }
        }

        return params;
    }

    /**
     * Remove not valid dates in params object
     * @param {Params} params
     * @param {AvailableYears} availablePeriod
     * @returns {Params}
     */
    public removeInvalidDatesInParams(params: Params, availablePeriod: AvailableYears): Params {
        const permittedParams = ['year', 'de', 'ds'];
        for (const key of permittedParams) {
            if (
                params.hasOwnProperty(key) &&
                !moment(params[key], key === 'year' ? this.yearDateFormat : this.urlDateFormat).isValid()
            ) {
                delete params[key];
            }
        }

        // If the year is not correctly set or not included in the period limits, set the most recent year by default
        if (params.hasOwnProperty('year') && availablePeriod.years.findIndex(x => x.value === params.year) < 0) {
            delete params.year;
        }

        return params;
    }

    /**
     * Replace the missing values in the params, depending on which date is concerned.
     * Also clear the year if the params have the three parameter.
     * @param {Params} params
     * @param {AvailableYears} availablePeriod
     * @returns {Params}
     */
    public replaceMissingDatesInParams(params: Params, availablePeriod: AvailableYears): Params {
        const paramsHasDE = params.hasOwnProperty('de');
        const paramsHasDS = params.hasOwnProperty('ds');
        const paramsHasYear = params.hasOwnProperty('year');

        // If none dates params are setted
        if (!paramsHasDE && !paramsHasDS && !paramsHasYear) {
            params.year = moment(availablePeriod.dateEnd, this.inputDateFormat)
                .year()
                .toString();
        }
        // If the end date is not setted, it's recalculate depending on the max dateRange.
        else if (paramsHasDS && !paramsHasDE) {
            params.de = moment(availablePeriod.dateEnd, this.inputDateFormat).format(this.urlDateFormat);
        }
        // If the start date is not setted in the route params
        else if (!paramsHasDS && paramsHasDE) {
            params.ds = moment(availablePeriod.dateStart, this.inputDateFormat).format(this.urlDateFormat);
        }
        // If there's the three params (start date, end date and year), remove the year param
        if (paramsHasDE && paramsHasDS && paramsHasYear) {
            delete params.year;
        }

        return params;
    }

    /**
     * Return a corrected params if the dates are no setted correctly
     * @param {Params} params
     * @param {AvailableYears} availablePeriod
     * @returns {Params}
     */
    public fixDatesCoherence(params: Params, availablePeriod: AvailableYears): Params {
        const paramsDS = this.getParamAliasValue(params, 'dateStart');
        const paramsDE = this.getParamAliasValue(params, 'dateEnd');
        const minDate = moment(availablePeriod.dateStart, this.inputDateFormat);
        const maxDate = moment(availablePeriod.dateEnd, this.inputDateFormat);

        if (!paramsDS || !paramsDE) {
            return params;
        }
        const dateStart = moment(params.ds, this.urlDateFormat);
        const dateEnd = moment(params.de, this.urlDateFormat);
        // Verify if start date is before minimal input value and end date is after maximal input value
        // Otherwise set min and max input values as start and end
        if (dateStart.isBefore(minDate) && dateEnd.isAfter(maxDate)) {
            this.setParamPropertyValue(params, 'dateStart', minDate.format(this.urlDateFormat));
            this.setParamPropertyValue(params, 'dateEnd', maxDate.format(this.urlDateFormat));
        }

        // Verify if end date is before minimal input value or after the maximal input value
        if (dateEnd.isBefore(minDate) || dateEnd.isAfter(maxDate)) {
            this.setParamPropertyValue(params, 'dateEnd', maxDate.format(this.urlDateFormat));
        }

        // Verify if the start date is before minimal input value
        if (dateStart.isBefore(minDate)) {
            this.setParamPropertyValue(params, 'dateStart', minDate.format(this.urlDateFormat));
        }

        // Verify if the start date is not after the end date.
        // If it's the case, it replace the date start equal to end date subtract 1 year
        if (dateStart.isAfter(dateEnd)) {
            this.setParamPropertyValue(
                params,
                'dateStart',
                moment.max([minDate, dateEnd.clone().subtract(1, 'y')]).format(this.urlDateFormat)
            );
        }

        return params;
    }

    /**
     * Transform date in url format (MM-YYYY) into input date format (YYYY-MM)
     * @param {string} date
     * @returns {string}
     */
    public getMonthUrlIntoInputDateFormat(date: string): string {
        return moment(date, this.urlDateFormat).format(this.inputDateFormat);
    }

    /**
     * Transform date in input format (YYYY-MM) into input date format (MM-YYYY)
     * @param {string} date
     * @returns {string}
     */
    public getMonthUrlDateFormat(date: string): string {
        return moment(date).format(this.urlDateFormat);
    }

    /**
     * Verify if the property is available in the params aliases dictionnary and verify it existence in the query params.
     * @param {Params} params
     * @param {FilterAlias} property
     * @returns {boolean}
     */
    public isAvailableAndSetPropertyInParams(params: Params, property: FilterAlias): boolean {
        if (!this.paramAliases.hasOwnProperty(property) || !params.hasOwnProperty(this.getParamAlias(property))) {
            return false;
        }
        const val = this.getParamAliasValue(params, property);
        // False if val null, '' or false
        return Boolean(val);
    }

    /**
     * Return the shortened string of a property name.
     * @param {FilterAlias} property
     * @returns {string | null} Return the property short name
     */
    public getParamAlias(property: FilterAlias): string | null {
        return this.paramAliases.hasOwnProperty(property) ? this.paramAliases[property] : null;
    }

    /**
     * Return the property value in the params
     * @param {Params} params
     * @param {FilterAlias} property
     * @returns {string | null} Return the property value
     */
    public getParamAliasValue(params: Params, property: FilterAlias): string | null {
        const shortCode = this.getParamAlias(property);
        if (!shortCode) {
            return null;
        }
        if (typeof params[shortCode] === 'string') {
            return params[shortCode];
        }
        // If multiple time the same param, creates an array with values.
        // Eg. ?t=a,b&t=c => { t= ['a,b', 'c'] }
        if (Array.isArray(params[shortCode])) {
            return params[shortCode].join(',');
        }
        return null;
    }

    /**
     * Get values (as an array) from params for given property
     * @param {Param} params - params
     * @param {FilterAlias} property - property alias
     * @param {string[]} defaultValue - default value if param not found or empty
     * @returns {string[]}
     */
    public getParamAliasValues(params: Params, property: FilterAlias, defaultValue: string[] = []): string[] {
        const valueString = this.getParamAliasValue(params, property);
        const items = valueString ? valueString.split(',') : defaultValue;
        return items;
    }

    /**
     * Set the property value in the params
     * @param {Params} params
     * @param {FilterAlias} property
     * @param {string} value
     * @returns {Params}
     */
    public setParamPropertyValue(params: Params, property: FilterAlias, value: string): Params {
        if (this.isAvailableAndSetPropertyInParams(params, property)) {
            const shortCode = this.getParamAlias(property);
            params[shortCode] = value;
        }

        return params;
    }

    /**
     * Delete the property in the params
     * @param {Params} params
     * @param {FilterAlias} property
     * @returns {Params}
     */
    public deleteParamPropertyByAlias(params: Params, property: FilterAlias): Params {
        if (this.isAvailableAndSetPropertyInParams(params, property)) {
            delete params[this.getParamAlias(property)];
        }
        return params;
    }
}
