import { Component, OnInit, ViewChild } from '@angular/core';
import { CampaignsNavComponent } from 'app/shared/components/campaigns/campaigns-nav/campaigns-nav.component';
import { Animations } from 'app/shared/models/animations';
import { Campaign } from 'app/shared/models/campaign.interface';
import { CampaignsService } from 'app/shared/services/campaigns/campaigns.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-campaigns',
    templateUrl: './campaigns.component.html',
    styleUrls: ['./campaigns.component.scss'],
    providers: [CampaignsService],
    animations: [Animations.slideInOutHorizontal],
})
export class CampaignsComponent implements OnInit {
    currentAction: 'creation' | 'visualization' = null;

    campaign: Campaign;

    @ViewChild(CampaignsNavComponent, { static: true }) campaignsNavComponent: CampaignsNavComponent;

    isNavMenuExpanded = true;

    constructor(private campaignsService: CampaignsService) {}

    ngOnInit() {}

    createCampaign() {
        this.currentAction = 'creation';
    }

    async openCampaign(id) {
        try {
            const result = await this.campaignsService.getCampaign(id);
            this.campaign = result.data;
            this.currentAction = 'visualization';
        } catch (error) {
            this.currentAction = null;
            if (!error.errorCode) {
                error.errorCode = 'error_get_campaign';
            }
            this.handleErrorMessage(error);
        }
    }

    get isActionCreate(): boolean {
        return this.currentAction === 'creation';
    }

    get isActionVisualization(): boolean {
        return this.currentAction === 'visualization';
    }

    campaignCreated(campaign) {
        if (this.campaignsNavComponent) {
            this.campaignsNavComponent.refreshList();
            this.openCampaign(campaign._id);
        }
    }

    get navCol() {
        return this.isNavMenuExpanded ? 'col-lg-4 col-xl-3 ' : 'col-md-1';
    }

    get visuCol() {
        return this.isNavMenuExpanded ? 'col-lg-8 col-xl-9' : 'col-md-11';
    }

    updateNavMenuExpand(isExpanded: boolean) {
        this.isNavMenuExpanded = isExpanded;
    }

    private handleErrorMessage(error) {
        const messages = {
            error_get_campaign: {
                title: 'Impossible de récupérer les données de la campagne',
                message:
                    'Une erreur inconnue est survenue pendant la récupération de la campagne. Merci de réessayer ultérieurement.',
            },
            fail_delete_campaign: {
                title: 'Une erreur est survenue',
                message: 'Impossible de supprimer la campagne.',
            },
            error_delete_campaign: {
                title: 'Impossible de supprimer la campagne',
                message: `Soit elle n'existe pas, soit vous n'avez pas les droits pour la supprimer.`,
            },
            not_allowed: {
                title: 'Non autorisé',
                message: "Vous n'avez pas les droits pour effectuer cette action.",
            },
        };
        if (error && error.errorCode && messages[error.errorCode]) {
            Swal.fire(messages[error.errorCode].title, messages[error.errorCode].message, 'error');
        } else {
            Swal.fire(
                'Erreur inconnue',
                'Une erreur inconnue est survenue. Merci de réessayer. Si le problème persiste, veuillez contacter votre Energy Manager',
                'error'
            );
        }
    }

    /**
     * Delete campaign by id. Id must match the currently selected campaign.
     * @param {string} id - campaign id
     */
    async deleteCampaign(id: string) {
        if (id && this.campaign && id === this.campaign._id) {
            try {
                await this.campaignsService.deleteCampaign(id);
                this.campaignsNavComponent.refreshList();
                this.currentAction = null;
                this.campaign = null;
                Swal.fire('Campagne supprimée', '', 'success');
            } catch (err) {
                this.handleErrorMessage(err.errorCode || 'fail_delete_campaign');
            }
        }
    }
}
