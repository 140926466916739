import { Injectable } from '@angular/core';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import * as moment from 'moment';

// services
import { ApiService } from 'app/shared/services/api/api.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { PageService } from 'app/shared/services/page/page.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

// interfaces
import { CommonEnergyRepartitionMonthly } from 'app/shared/models/energy-data-bills.interface';

@Injectable()
export class FollowupService extends PageService {
    // TODO get from API
    regionsMap = {
        '11': 'Île-de-France',
        '24': 'Centre-Val de Loire',
        '27': 'Bourgogne-Franche-Comté',
        '28': 'Normandie',
        '32': 'Nord-Pas-de-Calais-Picardie',
        '44': 'Alsace-Champagne-Ardenne-Lorraine',
        '52': 'Pays de la Loire',
        '53': 'Bretagne',
        '75': 'Aquitaine-Limousin-Poitou-Charentes',
        '76': 'Languedoc-Roussillon-Midi-Pyrénées',
        '84': 'Auvergne-Rhône-Alpes',
        '93': "Provence-Alpes-Côte d'Azur",
        '94': 'Corse',
        null: 'Tous',
    };

    constructor(
        public apiService: ApiService,
        public filterService: FilterService,
        public energyService: EnergyService,
        private sitesService: SitesService,
        private chartService: ChartService
    ) {
        super(apiService);
        this.fake = false;
    }

    getSites(filters): any {
        return this.sitesService.getSites(filters);
    }

    getSitesFilteredByCategories(categories, energies) {
        return this.sitesService.getSites({ categories, energies });
    }

    getSitesFilteredByRegions(regions, energies) {
        return this.sitesService.getSites({ regions, energies });
    }

    getSitesProperties() {
        return this.get('/api/sites/available/properties');
    }

    private isPrimaryEnergy(energiesSelected: string): boolean {
        return energiesSelected.split(',').length > 1 ? true : false;
    }

    /**
     * Format data from monthly consumptions for charts datasets
     * @todo: typing & improve visibility.
     * @param {CommonEnergyRepartitionMonthly[]} data
     * @param {number} stackNb
     * @param filters
     * @param monthDjus
     * @param {boolean} isDjuActivated
     */
    formateToMonthlyConsumption(
        data: CommonEnergyRepartitionMonthly[],
        stackNb: number,
        filters,
        monthDjus,
        isDjuActivated: boolean
    ): any {
        const response: any = {
            data: {
                chart: [],
                totalPrices: [],
                totalConsumptions: [],
            },
            labels: [],
            stack: stackNb,
        };

        const chart = {
            kWhep: [],
            ht: [],
            ttc: [],
        };

        const totalPrices = {
            kWhep: [],
            ht: [],
            ttc: [],
        };

        const dataPerEnergy = {
            ttc: {},
            ht: {},
            kWhep: {},
        };

        const totalConsumptions = {};

        const start = moment.utc(filters.dateStart).startOf('month');
        const end = moment.utc(filters.dateEnd).startOf('month');

        const indexMonthYears = this.chartService.getIndexMonthYears(start, end);
        const nbMonths = indexMonthYears[end.year()][end.month()] + 1; // +1 cause index starts with 0

        response.labels = this.chartService.getPeriodMonthlyLabels(start, end);

        data.forEach(databyMonth => {
            const labelMonth =
                this.chartService.getMonthNumber(databyMonth.month + 1) + '/' + databyMonth.items[0].year.toString();

            if (typeof totalPrices.ttc[labelMonth] === 'undefined') {
                totalPrices.ttc[labelMonth] = 0;
            }

            if (typeof totalPrices.ht[labelMonth] === 'undefined') {
                totalPrices.ht[labelMonth] = 0;
            }
            if (typeof totalConsumptions[labelMonth] === 'undefined') {
                totalConsumptions[labelMonth] = 0;
            }

            databyMonth.items.forEach(energy => {
                for (const priceTypeOrConso in dataPerEnergy) {
                    if (!dataPerEnergy.hasOwnProperty(priceTypeOrConso)) {
                        continue;
                    }
                    if (typeof dataPerEnergy[priceTypeOrConso][energy.energyType] === 'undefined') {
                        dataPerEnergy[priceTypeOrConso][energy.energyType] = Array.apply(null, Array(nbMonths)).map(
                            Number.prototype.valueOf,
                            0
                        );
                    }

                    // We get the price as we want HT or TTC
                    let priceOrConso = 0;

                    switch (priceTypeOrConso) {
                        case 'ht':
                            priceOrConso = energy.totalHT.amount;
                            totalPrices[priceTypeOrConso][labelMonth] += priceOrConso; // We add the price to total price
                            break;

                        case 'ttc':
                            priceOrConso = energy.totalTTC.amount;
                            totalPrices[priceTypeOrConso][labelMonth] += priceOrConso;
                            break;

                        case 'kWhep':
                            // if we compare multiple fluids -> compare in Primary Energy kWhep
                            if (this.isPrimaryEnergy(filters.energies)) {
                                priceOrConso = this.energyService.convertEnergyKwhToKwhep(
                                    energy.energyType,
                                    energy.consumption.quantity
                                );
                            } else {
                                priceOrConso = energy.consumption.quantity;
                            }

                            // we add the consumption to total consumption
                            totalConsumptions[labelMonth] += priceOrConso;
                            break;
                    }

                    // We add the price to energy values
                    dataPerEnergy[priceTypeOrConso][energy.energyType][
                        indexMonthYears[databyMonth.year][databyMonth.month]
                    ] = priceOrConso;
                }
            });
        });

        // We have all the values HT and TTC by energies. We create the chart data from it
        for (const priceTypeOrConso in dataPerEnergy) {
            if (dataPerEnergy.hasOwnProperty(priceTypeOrConso)) {
                let idStackFilter = 0; // needed for the legend of the stack bar
                // let idStackFilter = stackNb - 1; // needed for the legend of the stack bar
                for (const energyName in dataPerEnergy[priceTypeOrConso]) {
                    if (dataPerEnergy[priceTypeOrConso].hasOwnProperty(energyName)) {
                        chart[priceTypeOrConso].push({
                            data: dataPerEnergy[priceTypeOrConso][energyName],
                            label: energyName,
                            stack: stackNb, // = site n° / categorie n° / region n°
                            idStackFilter,
                            yAxisID: 'y-axis-0',
                            type: 'bar',
                        });
                        idStackFilter++;
                    } else {
                        // TODO: Check Needed (Else to keep old behavior)
                        idStackFilter++;
                    }
                }

                // Here add dju years
                if (isDjuActivated) {
                    let hotDjus = [];
                    if (monthDjus && monthDjus.length) {
                        hotDjus = monthDjus.map(year => {
                            return year.hot;
                        });
                    }
                    chart[priceTypeOrConso].unshift({
                        data: hotDjus,
                        label: 'djus',
                        stack: stackNb, // = site n° / categorie n° / place n°
                        idStackFilter,
                        yAxisID: 'y-axis-1',
                        type: 'line',
                    });
                }
            }
        }

        response.data.chart = [chart.kWhep, chart.ht, chart.ttc];
        response.data.totalPrices = [totalPrices.ht, totalPrices.ttc];
        response.data.totalConsumptions = [totalConsumptions];

        return response;
    }

    handleAvailableYearsAndDates(data, availableYears) {
        if (data && data.length) {
            availableYears.years = data;
            availableYears.years.sort((a, b) => {
                if (parseInt(a.value, 10) && parseInt(b.value, 10)) {
                    return parseInt(b.value, 10) - parseInt(a.value, 10);
                }
                return a.value < b.value;
            });
            const years = data.map(year => year.value);

            // Set datePicker default dates
            const maxYear = years[0];
            const minYear = years[years.length - 1];
            availableYears.dateStart = minYear.concat('-01');
            availableYears.dateEnd = maxYear.concat('-12');
        }
    }

    /*
     *   return the displayed name of the elements selected in the filter
     *   ex args:
     *   filters = [ {displayName : "0 - Services généraux des administrations publiques locales", value : 0},
     *                 {displayName : "1 - Sécurité et salubrité publiques", value : 1}]
     *
     *   filterSelected = "categories"
     *   returns [ "0 - Services généraux des administrations publiques locales",
     *          "1 - Sécurité et salubrité publiques"]
     * */
    getBarGroupNames(filters, filterSelected) {
        return filters.map(filter => {
            return this.chartService.getChartLegendContentByFilter(filter, filterSelected);
        });
    }
}
