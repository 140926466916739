import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'ga-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.scss'],
})
export class UploadFilesComponent implements OnInit {
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

    @Input() accept = '';
    @Input() multiple = true;
    @Output() filesSelected: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() dragOver: EventEmitter<void> = new EventEmitter();
    @Output() dragLeave: EventEmitter<void> = new EventEmitter();
    @Output() fileRejected: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor() {}
    ngOnInit() {}

    fileChange(e) {
        const files = Array.from(e.target.files);

        if (files.length) {
            this.filesSelected.emit(files);
        }
    }

    onDragEnter(e) {
        this.disableDefault(e);
    }

    addDragStyle(e) {
        this.disableDefault(e);
        this.dragOver.emit();
    }

    removeDragStyle(e) {
        this.disableDefault(e);
        this.dragLeave.emit();
    }

    onDrop(e) {
        this.disableDefault(e);
        this.dragLeave.emit();

        const files: any[] = Array.from(e.dataTransfer.files);
        const acceptedTypes = this.accept.split(',');
        const rejectedFiles: any[] = [];
        const acceptedFiles: any[] = files.filter(file => {
            const accepted = acceptedTypes.some(type => {
                if (!type || !file.name) {
                    return false;
                }
                return Boolean(file.name.toLocaleLowerCase().endsWith(type.toLowerCase()));
            });
            if (!accepted) {
                rejectedFiles.push(file);
            }
            return accepted;
        });

        if (acceptedFiles.length) {
            // change default property 'name' to 'filename' to make things easier during object comparison from the model
            acceptedFiles.forEach(file => {
                file.filename = file.name;
                delete file.name;
            });

            this.filesSelected.emit(acceptedFiles);
        } else {
            this.filesSelected.emit(null);
        }
        if (rejectedFiles.length) {
            this.fileRejected.emit(rejectedFiles);
        }
    }

    // used in parent to click on input file
    clickOnInput() {
        this.fileInput.nativeElement.click();
    }

    private disableDefault(e) {
        e.stopPropagation();
        e.preventDefault();
    }
}
