import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-site-properties',
    templateUrl: './site-properties.component.html',
    styleUrls: ['./site-properties.component.scss'],
})
export class SitePropertiesComponent implements OnInit {
    @Input() element: any; // can be site or zone
    @Input() properties: any;

    @Input() colWidth: number | string = 12;

    constructor() {}
    ngOnInit() {}

    elementHasComplements(): boolean {
        if (!this.elementHasSubCategory()) {
            return false;
        }

        const subCategories = this.properties.categories[this.element.category].subcategories;
        const subCategory = subCategories.find(subCat => subCat.id === this.element.subcategory);

        return Boolean(subCategory && subCategory.complements.length);
    }

    elementHasSubCategory(): boolean {
        const category = this.properties.categories[this.element.category];

        return Boolean(category && category.subcategories.length);
    }

    get organizationIsCompany(): boolean {
        return this.properties && this.properties.organization && Number(this.properties.organization.id) === 1; // 1 is the id of "Entreprise" organization
    }

    get organizationHasCategories() {
        return Boolean(this.properties && this.properties.categories && this.properties.categories.length);
    }

    canShowSubCategories(): boolean {
        return Boolean(this.organizationHasCategories && this.elementHasSubCategory());
    }

    canShowComplements(): boolean {
        return Boolean(this.organizationHasCategories && this.elementHasComplements());
    }

    get organizationHasSubnatures(): boolean {
        return (
            this.properties && this.properties.natures && this.properties.natures.some(x => x.items && x.items.length)
        );
    }

    get showSubnatures(): boolean {
        return this.organizationHasSubnatures && this.element.nature;
    }

    get getElementSubnatures(): any[] {
        const nature = this.properties.natures.find(x => x.id === this.element.nature);
        return nature ? nature.items : [];
    }

    /******************/
    /*      GET       */
    /******************/

    getElementComplements(subCatId): any[] {
        const subCategories = this.properties.categories[this.element.category].subcategories;
        const subCategory = subCategories.find(subCat => subCat.id === subCatId);

        return subCategory ? subCategory.complements : [];
    }

    /******************/
    /*     UPDATE     */
    /******************/

    onCategoryChanged() {
        if (!this.element.category) {
            this.element.subcategory = null;
            this.element.complement = null;
        }
    }

    onSubCategoryChanged() {
        if (!this.element.subcategory) {
            this.element.complement = null;
        }
    }

    onNatureChanged() {
        this.element.subnature = null;
    }

    get getColClass() {
        return 'col-md-' + this.colWidth;
    }
}
