import { Component, Input, OnInit } from '@angular/core';
import { GoalItem } from 'app/shared/models/campaign.interface';
import { ColorService } from 'app/shared/services/color/color.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

@Component({
    selector: 'ga-campaign-goal-recap',
    templateUrl: './campaign-goal-recap.component.html',
    styleUrls: ['./campaign-goal-recap.component.scss'],
    providers: [],
})
export class CampaignGoalRecapComponent implements OnInit {
    private _goalItem: GoalItem;
    @Input()
    set goalItem(goalItem: GoalItem) {
        this._goalItem = goalItem;
        this.init();
    }
    get goalItem(): GoalItem {
        return this._goalItem;
    }

    @Input() isReached = false;

    private _multipleFluids = false;
    @Input()
    set multipleFluids(multipleFluids: boolean) {
        this._multipleFluids = multipleFluids;
        this.setUnit();
    }
    get multipleFluids(): boolean {
        return this._multipleFluids;
    }

    tiles = [
        {
            icon: 'chart-percent-icon',
            unit: '%',
            message: 'Réduction en %',
            value: '-',
        },
        {
            icon: 'chart-performance-icon',
            unit: '',
            message: 'Réduction en kWh',
            value: '-',
        },
        {
            icon: 'co2-icon',
            unit: '',
            message: 'Réduction en T CO2',
            value: '-',
        },
    ];

    backgroundColorIcon = '#909ea0';
    backgroundColorText = '#7b8a8a';

    constructor(private colorService: ColorService, private utilService: UtilsService) {}

    ngOnInit() {
        let property = 'ttc';

        if (this.isReached) {
            property = 'goal_reached';
        }

        this.backgroundColorIcon = this.colorService.getRgbColor(property);
        this.backgroundColorText = this.colorService.getSecondRgbColor(property);
    }

    private init() {
        this.tiles[0].value = this.utilService.getNumberAndUnitToDisplay(
            this.goalItem.consumptionPercentage,
            null,
            'percent'
        );
        this.tiles[1].value = this.utilService.getNumberAndUnitToDisplay(
            this.goalItem.consumptionReduction,
            null,
            'fluid'
        );
        this.tiles[2].value = this.utilService.getNumberAndUnitToDisplay(this.goalItem.co2Reduction, null, 'fluid');
    }

    private setUnit() {
        this.tiles[1].message = this.multipleFluids ? 'Réduction en kWhep' : 'Réduction en kWh';
    }
}
