import { Inject, Injectable, InjectionToken } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UserSession } from '../session/session.interface';
import { UserSessionService } from '../session/user-session.service';
import { AnalyticsTag } from './analytics-tag';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';
import { HotjarService } from './hotjar/hotjar.service';

export const ENVIRONMENT_NAME = new InjectionToken('Environment name');
export const ENVIRONMENT_VERSION = new InjectionToken('Environment version');

/**
 * Analytics service
 */
@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private readonly tags = new Subject<AnalyticsTag<Readonly<Record<string, unknown>>>>();

    /**
     * Class constructor
     *
     * @param userSessionService User session service
     * @param googleAnalyticsService Google Analytics service
     * @param hotjarService Hotjar service
     */
    constructor(
        userSessionService: UserSessionService,
        googleAnalyticsService: GoogleAnalyticsService,
        hotjarService: HotjarService,
        @Inject(ENVIRONMENT_NAME)
        environmentName: string,
        @Inject(ENVIRONMENT_VERSION)
        environmentVersion: string
    ) {
        googleAnalyticsService.setVariables({ env_name: environmentName, env_version: environmentVersion });

        const userSession$: Observable<UserSession | undefined> = userSessionService.getUserSession().asObservable();
        const sessionTagContext$ = userSession$.pipe(
            map(userSession => ({
                isCitron: userSession ? userSession.contact.mail.endsWith('@citron.io') : false,
                userType: userSession ? userSession.type : 'unknown',
                userId: userSession ? userSession._id : undefined,
            })),
            tap(session => {
                const { isCitron: is_citron, userType: user_type, userId } = session;

                googleAnalyticsService.setVariables({ is_citron, user_type, user_uid: userId });
                if (userId) {
                    hotjarService.setUserIdentity(userId, is_citron);
                }
            })
        );

        const tagsToBeUpdated$ = this.tags.asObservable().pipe(
            tap(analyticsTag => {
                if (!analyticsTag.properties) {
                    googleAnalyticsService.tag(['event', analyticsTag.type]);
                }
                googleAnalyticsService.setVariables(analyticsTag.properties);
                googleAnalyticsService.tag(['event', analyticsTag.type]);
                const propertiesReset = Object.keys(analyticsTag.properties).reduce((acc, key) => {
                    acc[key] = undefined;
                    return acc;
                }, {});
                googleAnalyticsService.setVariables(propertiesReset);
            })
        );

        merge(sessionTagContext$, tagsToBeUpdated$).subscribe();
    }

    /**
     * Records a tag
     *
     * @param analyticsTag Analytics tag
     */
    recordTag(analyticsTag: AnalyticsTag<Readonly<Record<string, unknown>>>): void {
        this.tags.next(analyticsTag);
    }
}
