import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScenarioDisplay } from 'app/shared/models/scenario.model';

interface StatusItem {
    value: string;
    displayKey: string;
    selected: boolean;
    status: boolean;
}

export interface ScenarioFilter {
    scenario: ScenarioDisplay;
    status: boolean;
}

@Component({
    selector: 'ga-alerts-mgmt-filter',
    templateUrl: './alerts-mgmt-filter.component.html',
    styleUrls: ['./alerts-mgmt-filter.component.scss'],
    providers: [],
})
export class AlertsManagementFilterComponent implements OnInit {
    /**
     * All scenarios for the user and company, used to be displayed in autocomplete
     */
    @Input() scenarios: ScenarioDisplay[] = [];

    /**
     * Event emitted when filtering changes
     * For status =>
     * All : null,
     * Active : true,
     * Inactive : false
     */
    @Output() filterChanged: EventEmitter<ScenarioFilter> = new EventEmitter<ScenarioFilter>();

    @Output() openCreate: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Scenario selected in the autocomplete
     */
    scenarioSelected: ScenarioDisplay = null;

    /**
     * Filters status element (status => flagMute, is the scenario active or not)
     */
    statusFilterElements: StatusItem[] = [
        {
            value: 'all',
            displayKey: 'all_fp',
            selected: true,
            status: null,
        },
        {
            value: 'active',
            displayKey: 'active_fp',
            selected: false,
            status: true,
        },
        {
            value: 'inactive',
            displayKey: 'inactive_fp',
            selected: false,
            status: false,
        },
    ];

    constructor() {}

    ngOnInit() {}

    /**
     * Set the scenario corresponding to given id
     * @param {string} id
     */
    selectScenario(id: string) {
        this.scenarioSelected = this.scenarios.find(x => x._id === id);
        const flagMute = this.scenarioSelected ? !this.scenarioSelected.flagMute : null;
        this.statusFilterElements.forEach(x => {
            x.selected = Boolean(x.status === flagMute);
        });
        this.filterChanged.emit({
            scenario: this.scenarioSelected,
            status: flagMute,
        });
    }

    /**
     * Set the selected scenario status
     * @param {StatusItem} status
     */
    selectStatus(status: StatusItem) {
        status.selected = true;
        this.statusFilterElements.forEach(x => {
            x.selected = Boolean(status.value === x.value);
        });
        this.scenarioSelected = null;
        this.filterChanged.emit({
            scenario: null,
            status: status.status,
        });
    }

    /**
     * Get status item class
     * @param {StatusItem} status
     * @returns {String[]} list of CSS classes to apply to the item status
     */
    getStatusClass(status: StatusItem): string[] {
        if (status.selected) {
            return ['citron-background', 'status__button-selected'];
        }
        return [];
    }

    /**
     * Open scneario creation model
     */
    openScenarioCreation() {
        this.openCreate.emit();
    }
}
