import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-optimization',
    templateUrl: './optimization.component.html',
    styleUrls: ['./optimization.component.scss'],
    providers: [],
})
export class OptimizationComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
