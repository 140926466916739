import { ModuleWithProviders, NgModule } from '@angular/core';

import { ServicesModule } from '../services.module';
import { ANALYTICS_CONFIGURATION, AnalyticsConfiguration } from './analytics-configuration';
import { AnalyticsService, ENVIRONMENT_NAME, ENVIRONMENT_VERSION } from './analytics.service';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';
import { HotjarService } from './hotjar/hotjar.service';

/**
 * Analytics module
 */
@NgModule({
    imports: [ServicesModule],
})
export class AnalyticsModule {
    /**
     * Creates an analytics module for the application root
     *
     * @param environmentName Environment name
     * @param environmentVersion Environment version
     * @param analyticsConfiguration Analytics configuration
     * @returns Analytics module
     */
    static forRoot(
        environmentName: string,
        environmentVersion: string,
        analyticsConfiguration: AnalyticsConfiguration
    ): ModuleWithProviders<AnalyticsModule> {
        return {
            ngModule: AnalyticsModule,
            providers: [
                AnalyticsService,
                GoogleAnalyticsService,
                HotjarService,
                {
                    provide: ANALYTICS_CONFIGURATION,
                    useValue: analyticsConfiguration,
                },
                {
                    provide: ENVIRONMENT_NAME,
                    useValue: environmentName,
                },
                {
                    provide: ENVIRONMENT_VERSION,
                    useValue: environmentVersion,
                },
            ],
        };
    }
}
