import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginationService } from 'app/shared/components/pagination/front/pagination-front.service';
import Swal from 'sweetalert2';
import { ApiService } from '../../shared/services/api/api.service';
import { PageService } from '../../shared/services/page/page.service';
import { PatternService } from '../../shared/services/pattern/pattern.service';
import { RegexService } from '../../shared/services/regex/regex.service';
import { FixRegexUploadService } from '../fix-regex-upload/fix-regex-upload.service';
import { FixRegexPropertyService } from './fix-regex-property.service';

@Component({
    selector: 'ga-fix-regex-property',
    templateUrl: './fix-regex-property.component.html',
    styleUrls: ['./fix-regex-property.component.scss'],
    providers: [FixRegexUploadService, FixRegexPropertyService, PageService, PaginationService],
})
export class FixRegexPropertyComponent implements OnInit {
    loading = false;
    indexCollectSelectedGroup: any;
    arrayData: any[] = [];
    groupId = '';
    energyType = '';
    property = '';
    provider = '';
    contractType = '';
    concatEnergyProviderContractType = '';

    allIdentificationPatternIds = [];

    data: any = {
        fileProperties: [],
        regexDictionaryPerIdentPattern: {},
    };

    constructor(
        private regexService: RegexService,
        private regexFixPatternService: FixRegexPropertyService,
        private regexFixUploadService: FixRegexUploadService,
        private route: ActivatedRoute,
        private apiService: ApiService,
        private pageService: PageService,
        private patternService: PatternService,
        private paginationService: PaginationService
    ) {}

    ngOnInit() {
        this.route.queryParams.subscribe(queryParams => {
            if (queryParams.hasOwnProperty('index')) {
                this.indexCollectSelectedGroup = Number(queryParams.index);
            }
        });

        this.route.params.subscribe(params => {
            this.groupId = params.groupId;
            this.property = params.property;

            this.energyType = decodeURIComponent(params.energy);
            this.provider = decodeURIComponent(params.provider);
            this.contractType = decodeURIComponent(params.contractType);

            switch (this.energyType) {
                case 'gas':
                    this.energyType = 'gaz';
                    break;
            }

            this.initData();
        });
        this.concatEnergyProviderContractType =
            this.removeStringSpaces(this.energyType) +
            this.removeStringSpaces(this.provider) +
            this.removeStringSpaces(this.contractType);
    }

    removeStringSpaces(str) {
        return str.replace(/\s/g, '_');
    }

    resetData() {
        this.allIdentificationPatternIds = [];

        this.data = {
            fileProperties: [],
            regexDictionaryPerIdentPattern: {},
        };
    }

    getConcatEnergyProviderContractType(identificationPattern): string {
        return (
            this.removeStringSpaces(identificationPattern.energyType) +
            this.removeStringSpaces(identificationPattern.provider.name) +
            this.removeStringSpaces(identificationPattern.contractType.name)
        );
    }

    startLoading() {
        setTimeout(() => {
            this.loading = true;
        });
    }

    stopLoading() {
        setTimeout(() => {
            this.loading = false;
        });
    }

    async associateTextContent(uploads) {
        const uploadsWithTextcontent = await Promise.all(
            uploads.map(async upload => {
                if (upload.chunks && upload.chunks.length) {
                    upload.chunks = await Promise.all(
                        upload.chunks.map(async chunk => {
                            const textContent = await new Promise((resolve, reject) => {
                                this.regexFixPatternService
                                    .getUploadChunkTextContentById(upload._id.toString(), chunk._id.toString())
                                    .then(textContentResult => {
                                        resolve(textContentResult);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    });
                            });

                            chunk.textContent = {
                                data: textContent,
                            };

                            return chunk;
                        })
                    );
                } else {
                    const textContent = await new Promise((resolve, reject) => {
                        this.regexFixPatternService
                            .getUploadTextContentById(upload._id.toString())
                            .then(textContentResult => {
                                resolve(textContentResult);
                            })
                            .catch(error => {
                                reject(error);
                            });
                    });

                    upload.textContent = {
                        data: textContent,
                    };
                }

                return upload;
            })
        );

        return uploadsWithTextcontent;
    }

    async onPaginationChanged() {
        let count = 0;

        this.resetData();
        this.startLoading();

        // Get upload/chunks from index start to index stop
        const response = await this.regexFixPatternService.searchGroupFiles(
            this.groupId,
            this.energyType,
            this.provider,
            this.contractType,
            this.paginationService.pagination.indexStart,
            this.paginationService.pagination.indexEnd
        );

        // Associate textContent with all upload/chunk
        response.data = await this.associateTextContent(response.data);

        this.stopLoading();

        this.getIdentificationPatternIds(response.data);

        this.getPropertyDictionaries().then(async () => {
            const regexToDo = [];
            response.data.forEach(file => {
                let regexToCheck = '';
                if (file.identificationPattern) {
                    if (file.identificationPattern.isMultiChunk && file.chunks) {
                        file.chunks.forEach(chunk => {
                            if (
                                chunk.identificationPattern &&
                                this.getConcatEnergyProviderContractType(chunk.identificationPattern) ===
                                    this.concatEnergyProviderContractType &&
                                chunk.clothestPattern &&
                                chunk.clothestPattern[this.energyType]
                            ) {
                                regexToCheck = this.fetchFromObject(
                                    chunk.clothestPattern[this.energyType],
                                    this.property
                                );

                                count = count + 1;
                                const fileProperty = {
                                    _id: file._id,
                                    name: file.originalname, // Put original name
                                    number: count,
                                    energyType: this.energyType,
                                    patternId: chunk.clothestPattern[this.energyType]._id,
                                    identificationPatternId: chunk.identificationPattern._id,
                                    regex: this.fetchFromObject(chunk.clothestPattern[this.energyType], this.property),
                                    valueExtract: '',
                                    potentialValueExtract: '',
                                    oldRegex: this.fetchFromObject(
                                        chunk.clothestPattern[this.energyType],
                                        this.property
                                    ),
                                    option: 'Selectionnez un regex',
                                    propertyToWatch: this.property,
                                    textContent: chunk.textContent.data,
                                    closestPattern: chunk.clothestPattern[this.energyType],
                                    loading: true,
                                    chunk: chunk._id,
                                };
                                regexToDo.push({
                                    content: chunk.textContent.data.toString(),
                                    regex: regexToCheck,
                                    fileProperty,
                                });
                                this.data.fileProperties.push(fileProperty);
                            }
                        });
                    } else {
                        if (
                            file.identificationPattern &&
                            this.getConcatEnergyProviderContractType(file.identificationPattern) ===
                                this.concatEnergyProviderContractType &&
                            file.clothestPattern &&
                            file.clothestPattern[this.energyType]
                        ) {
                            regexToCheck = this.fetchFromObject(file.clothestPattern[this.energyType], this.property);

                            count = count + 1;
                            const fileProperty = {
                                _id: file._id,
                                name: file.originalname,
                                number: count,
                                energyType: this.energyType,
                                patternId: file.clothestPattern[this.energyType]._id,
                                identificationPatternId: file.identificationPattern._id,
                                regex: this.fetchFromObject(file.clothestPattern[this.energyType], this.property),
                                valueExtract: '',
                                potentialValueExtract: '',
                                oldRegex: this.fetchFromObject(file.clothestPattern[this.energyType], this.property),
                                option: 'Selectionnez un regex',
                                propertyToWatch: this.property,
                                textContent: file.textContent.data,
                                closestPattern: file.clothestPattern[this.energyType],
                                loading: true,
                            };
                            regexToDo.push({
                                content: file.textContent.data.toString(),
                                regex: regexToCheck,
                                fileProperty,
                            });
                            this.data.fileProperties.push(fileProperty);
                        }
                    }
                }
            });
            for (const regexItem of regexToDo) {
                await this.testRegex(regexItem.content, regexItem.regex)
                    .then(resultFromMatch => {
                        regexItem.fileProperty.valueExtract = JSON.stringify(resultFromMatch);
                        regexItem.fileProperty.loading = false;
                    })
                    .catch(err => {
                        regexItem.fileProperty.valueExtract = 'Error : ' + err.errorCode;
                        regexItem.fileProperty.loading = false;
                    });
            }
        });
    }

    async initData() {
        this.resetData();

        const itemCount = await this.regexFixPatternService.searchGroupFilesCount(
            this.groupId,
            this.energyType,
            this.provider,
            this.contractType
        );
        this.arrayData = new Array(itemCount.data);
    }

    getIdentificationPatternIds(files) {
        // get the identificationPatternId, they all have the same so we get it form the first of the list
        files.forEach(file => {
            if (file.identificationPattern) {
                if (file.identificationPattern.isMultiChunk && file.chunks) {
                    file.chunks.forEach(chunk => {
                        if (chunk.identificationPattern && chunk.identificationPattern._id) {
                            if (this.allIdentificationPatternIds.indexOf(chunk.identificationPattern._id) === -1) {
                                this.allIdentificationPatternIds.push(chunk.identificationPattern._id);
                            }
                        }
                    });
                } else {
                    if (this.allIdentificationPatternIds.indexOf(file.identificationPattern._id) === -1) {
                        this.allIdentificationPatternIds.push(file.identificationPattern._id);
                    }
                }
            }
        });
    }

    getPropertyDictionaries() {
        // get the regex dictionary of the property given the identification pattern id
        this.data.regexDictionaryPerIdentPattern = {};

        return Promise.all(
            this.allIdentificationPatternIds.map(id => {
                return this.getDictionaryForProperty(id).then(listRegexResponse => {
                    this.data.regexDictionaryPerIdentPattern[id] = listRegexResponse;
                    return;
                });
            })
        ).then(responses => Promise.resolve(responses));
    }

    getDictionaryForProperty(identificationPatternId) {
        const route = `/api/patterns/${this.energyType}/search?identificationPatternId=${identificationPatternId}&property=${this.property}`;
        return this.pageService.get(route);
    }

    testRegex(text, regex) {
        return this.regexService.testRegex(text, regex);
    }

    fetchFromObject(obj, prop) {
        if (typeof obj === 'undefined') {
            return false;
        }

        const _index = prop.indexOf('.');
        if (_index > -1) {
            return this.fetchFromObject(obj[prop.substring(0, _index)], prop.substr(_index + 1));
        }

        return obj[prop];
    }

    // update the closest pattern / create a pattern with the values in the dataTable
    onSavePattern(action: string, fileProperty) {
        // if (this.regexService.hasPatternChanged(this.dataArray, this.initialDataArray)) {

        Swal.fire({
            title: 'Etes-vous sûr ?',
            text: this.regexFixUploadService.getSwalTitleOnSave(action),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: this.regexFixUploadService.getSwalConfirmButtonTextOnSave(action),
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
                /*if ( this.regexFixUploadService.hasEmptyRegex(this.dataArray) ) {
                        Swal.fire(
                            'Annulé',
                            'Un regex est vide',
                            'error'
                        )
                    } else {*/

                // save pattern in db
                this.savePattern(action, fileProperty).then(
                    res => {
                        Swal.fire('OK', this.regexFixUploadService.getSwalSuccessOnSave(action), 'success').then(() => {
                            this.onPaginationChanged();
                        });
                    },
                    err => {
                        if (err.error_code === 'error_updatePattern' || err.error_code === 'error_createNewPattern') {
                            Swal.fire(
                                'Toutes nos excuses',
                                this.regexFixUploadService.getSwalFailOnSave(action),
                                'error'
                            ).then(() => {
                                this.backToCollect();
                            });
                        }
                    }
                );
                // }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Annulé', '', 'error');
            }
        });
        /* } else {
            Swal.fire(
                'Impossible',
                'Aucun regex n\'a été modifié',
                'error'
            )
        } */
    }

    // put in database the new pattern
    savePattern(action, fileProperty) {
        switch (action) {
            case 'update':
                return this.updatePattern(fileProperty);

            case 'new':
                return this.createNewPattern(fileProperty);
        }
    }

    async updatePattern(fileProperty) {
        try {
            const route = '/api/patterns/' + fileProperty.energyType + '/crud/' + fileProperty.closestPattern._id;
            const response = await this.pageService.put(route, fileProperty.closestPattern, null, true);

            // now that the pattern has been successfully updated
            if (response.data.modifiedCount === 1) {
                return;
            } else {
                throw { error_code: 'error_updatePattern' };
            }
        } catch (err) {
            err.error_code = 'error_updatePattern';
            return Promise.reject(err);
        }
    }

    async createNewPattern(fileProperty) {
        try {
            delete fileProperty.closestPattern['_id'];

            const route = '/api/patterns/' + fileProperty.energyType + '/crud';
            const res: APICitronResponse = await this.pageService.post(route, fileProperty.closestPattern);

            fileProperty.closestPattern = res.data;

            // add it in db to the identificationPattern patterns list for the specific energy
            await this.addPatternToIndentificationPattern(
                fileProperty.identificationPatternId,
                fileProperty.closestPattern._id
            );
            return;
        } catch (err) {
            err.error_code = 'error_createNewPattern';
            return Promise.reject(err);
        }
    }

    addPatternToIndentificationPattern(id, idPattern) {
        const route = '/api/identification-patterns/update-patterns';

        const body = {
            id,
            idPattern,
        };
        return this.pageService.post(route, body);
    }

    removePatternFromPatternFix(e) {
        const route = '/api/patterns/' + e.energyType + '/crud/' + e.closestPattern._id;
        this.apiService.delete(route).toPromise();
    }

    backToCollect() {
        this.regexFixUploadService.backToCollect(this.groupId, this.indexCollectSelectedGroup);
    }

    getBackToCollectLink() {
        return this.regexFixUploadService.getBackToCollectLink(this.groupId, this.indexCollectSelectedGroup);
    }

    getTitle() {
        return 'Amélioration des regex de la propriété :  ' + this.getPropertyTraduction();
    }

    getPropertyTraduction() {
        return this.patternService.getPropertyNameFromSlug(this.property, this.energyType);
    }
}
