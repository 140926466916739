import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { File } from 'app/shared/models/upload-file.interface';
import { UploadFilesComponent } from '../upload-files/upload-files.component';
@Component({
    selector: 'ga-upload-files-tile',
    templateUrl: './upload-files-tile.component.html',
    styleUrls: ['./upload-files-tile.component.scss'],
})
export class UploadFilesTileComponent implements OnInit {
    dragIsOver = false;

    @ViewChild('upload', { static: true }) private uploadComponent: UploadFilesComponent;

    @Input() data: any = {};
    @Input() accept = '';
    @Input() page = '';
    @Input() disabled = false;
    @Output() filesSelected: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {}

    onfilesSelected(files: File[]) {
        // if the drag&drop zone is disabled, prevent the files from being added to the list
        if (!this.disabled) {
            this.filesSelected.emit(files);
        }
    }

    addDragStyle() {
        this.dragIsOver = true;
    }

    removeDragStyle() {
        this.dragIsOver = false;
    }

    clickOnInput() {
        this.uploadComponent.clickOnInput();
    }

    getImgUrlStyle(url) {
        return this.sanitizer.bypassSecurityTrustStyle(`-webkit-mask-image: url(${url})`);
    }

    isUploadCollect() {
        return this.data.imgUrl && this.page === 'collect';
    }

    isUploadAdmin() {
        return this.data.imgUrl && (this.page === 'company_logo' || this.page === 'user_logo');
    }
}
