import { Injectable } from '@angular/core';
import { CampaignInputs } from 'app/shared/components/campaigns/campaign-create/campaign.interface';
import { Campaign } from 'app/shared/models/campaign.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

@Injectable()
export class CampaignsService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    async getCompaniesCampaignsList() {
        return this.get('/api/campaigns/list/' + this.apiService.getCompanyId());
    }

    async getCampaign(id): Promise<{ success: boolean; code: number; data: Campaign }> {
        return this.get(`/api/campaigns/data/${id}`);
    }

    private getValues(energyType: string, quantity: number, unit: string, ttc: number, htva: number) {
        return { energyType, quantity, unit, ttc, htva };
    }

    /**
     * Save new campaign.
     * @param {CampaignInputs} campaign - campaign object to save.
     * @returns Promise<{ success: boolean; code: number; data: Campaign }> campaign object created.
     */
    async saveCampaign(campaign: CampaignInputs) {
        if (!campaign.company) {
            const companyId = this.apiService.getCompanyId();
            campaign.company = companyId;
        }
        return this.post('/api/campaigns/', { campaign });
    }

    /**
     * Delete campaign
     * @param {string} id - campaign id
     * @returns {Promise<*>}
     */
    async deleteCampaign(id: string) {
        return this.delete(`/api/campaigns/${id}`);
    }
}
