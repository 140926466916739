import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate/translate.service';

@Pipe({
    name: 'translate',
})
export class TranslatePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(key: string): string {
        return this.translate._(key) || key;
    }
}
