import { Component, Input, OnInit } from '@angular/core';
import { TextService } from 'app/shared/services/utils/text.service';
import Swal from 'sweetalert2';
import { AdminCompanyService } from '../../../../../pages/admin/company/company.service';
import { ApiService } from '../../../../services/api/api.service';

@Component({
    selector: 'ga-company-contacts-tab',
    templateUrl: './company-contacts.component.html',
    styleUrls: ['./company-contacts.component.scss'],
    providers: [],
})
export class CompanyContactsComponent implements OnInit {
    @Input() company = null;
    @Input() canEdit = false;
    @Input() userSession: any;

    contactsListSelectBox = [];
    contactsOfCompany = [];
    contactSelected: any;
    formContact: any;
    isLoading = false;

    constructor(private companyService: AdminCompanyService, private textService: TextService) {}

    ngOnInit() {
        this.initData();
    }

    initData() {
        this.isLoading = true;
        this.initContactForm();
        this.initContactsLists();
        this.setContactsLists();
    }

    initContactsLists() {
        this.contactsListSelectBox = [];
        this.contactsOfCompany = [];
        this.contactSelected = null;
    }

    initContactForm() {
        this.formContact = {
            name: '',
            mail: '',
            position: '',
            phone: '',
        };
    }

    // get all the contacts from the DB and divide them in two lists: the contacts of the company and the others
    setContactsLists() {
        const contacts = this.userSession.contacts || [];

        this.contactsOfCompany = [];
        this.contactsListSelectBox = [];

        if (contacts.length) {
            // contactsListSelectBox & contactsOfCompany: push all the users belonging to the company
            this.contactsListSelectBox = contacts.filter(
                c => !this.company.contacts.find(contactId => c._id.toString() === contactId)
            );
            this.contactSelected = this.contactsListSelectBox[0];
            this.company.contacts.forEach(contactId =>
                this.contactsOfCompany.push(contacts.find(c => c._id === contactId))
            );

            // sort alphabetically
            this.contactsListSelectBox = this.contactsListSelectBox.sort(this.sortListAlphabetically);
            this.contactsOfCompany = this.contactsOfCompany.sort(this.sortListAlphabetically);
        }
        this.isLoading = false;
    }

    sortListAlphabetically(contactA, contactB) {
        const a = contactA.name.toUpperCase();
        const b = contactB.name.toUpperCase();
        return a > b ? 1 : a < b ? -1 : 0;
    }

    hasContacts() {
        return this.contactsOfCompany.length;
    }

    async addContact(action: string) {
        // contact selected from selectbox
        if (action === 'selectbox') {
            this.company.contacts.push(this.contactSelected._id);

            await this.updateCompany('contact_added');
            return;
        } else {
            // contact created in the form

            // check empty fields and unicity (name + mail)
            if (this.isContactValid()) {
                try {
                    const contactSaved = await this.createContact();
                    this.company.contacts.push(contactSaved._id);
                    await this.updateCompany('contact_added');
                    return;
                } catch (err) {
                    // err.errorCode is set to error_createNewContact
                    this.getSwalMsg(err.errorCode);
                }
            }
        }
    }

    deleteContactPermanently(contactToDelete) {
        this.companyService.deleteContactPermanently(contactToDelete).then(
            response => {
                if (!response.success) {
                    this.initData();
                    this.getSwalMsg('error_delete_permantently');
                } else {
                    this.initData();
                    this.getSwalMsg('contact_deleted');
                }
            },
            err => {
                this.initData();
                this.getSwalMsg('error_delete_permantently');
            }
        );
    }

    // check if the form is valid before saving
    isContactValid(): boolean {
        // the fields can't be empty
        if (this.hasEmptyFields()) {
            this.getSwalMsg('form_missing_field');
            return false;
        } else if (this.alreadyInList()) {
            // if we create a company, its name needs to be unique
            this.getSwalMsg('alreadyInList');
            return false;
        }

        return true;
    }

    // check if some fields are empty before saving
    hasEmptyFields() {
        return (
            !this.formContact.firstname ||
            this.formContact.firstname === '' ||
            !this.formContact.name ||
            this.formContact.name === '' ||
            !this.formContact.mail ||
            this.formContact.mail === '' ||
            !this.formContact.phone ||
            this.formContact.phone === '' ||
            !this.formContact.position ||
            this.formContact.position === ''
        );
    }

    // check if the ocntact name + mail matches a contact already DB
    alreadyInList() {
        const isAlreadyInSelectBox = this.contactsListSelectBox.some(c => {
            return c.name === this.formContact.name && c.mail === this.formContact.mail;
        });
        const isAlreadyInCompany = this.contactsOfCompany.some(c => {
            return c.name === this.formContact.name && c.mail === this.formContact.mail;
        });
        return isAlreadyInSelectBox || isAlreadyInCompany;
    }

    // create the contact in db
    async createContact(): Promise<any> {
        const contact = await this.companyService.createContact(this.formContact);
        return contact;
    }

    isDisabled() {
        return this.contactsListSelectBox.length ? '' : 'disabled';
    }

    // sweet alert
    getSwalMsg(msg_code) {
        const messages = {
            error_getListContact: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des contacts.',
                'error',
            ],
            error_updateCompany: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la mise à jour des contacts.',
                'error',
            ],
            contact_deleted: ['Supprimé', 'Le contact a bien été supprimé.', 'success'],
            contact_added: ['Ajouté', 'Le contact a bien été ajouté.', 'success'],
            error_createNewContact: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la création du contact.',
                'error',
            ],
            form_missing_field: ['Attention', "L'un des champs n'est pas renseigné.", 'warning'],
            alreadyInList: ['Attention', 'Un autre contact a déjà ce nom et cet email.', 'warning'],
            error_delete_permantently: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la suppression définitive du contact.',
                'error',
            ],
        };

        Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
    }

    getSwalDeleteOptions(contactToDelete) {
        Swal.fire({
            title: '',
            text: 'Supprimer le contact...',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: "de l'entreprise ?",
            cancelButtonText: 'définitivement',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        }).then(async result => {
            this.company.contacts = this.company.contacts.filter(contact => contact !== contactToDelete._id.toString());

            // delete from that company only"
            if (result.value) {
                await this.updateCompany('contact_deleted');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // delete from all companies and delete the contact itself
                this.deleteContactPermanently(contactToDelete);
            }
        });
    }

    async updateCompany(swalMsg) {
        try {
            await this.companyService.updateCompany(this.company);
            // update the two components data
            this.initData();
            this.getSwalMsg(swalMsg);
            return;
        } catch (err) {
            this.initData();
            this.getSwalMsg(err.errorCode); // err.errorCode is set to error_updateCompany
        }
    }

    getContactName(contact) {
        return contact.firstname
            ? this.textService.toTitleCase(contact.firstname) + ' ' + contact.name.toUpperCase()
            : contact.name.toUpperCase();
    }
}
