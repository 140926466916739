/**
 * Builds the hotjar.js URL
 *
 * @param hotjarId Hotjar identifier
 * @param hotjarJsVersion Hotjar JS version
 * @returns hotjar.js URL
 */
export function buildHotjarJsUrl(hotjarId: number, hotjarJsVersion: number): string {
    return `https://static.hotjar.com/c/hotjar-${hotjarId}.js?sv=${hotjarJsVersion}`;
}
