import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FilterService } from '../../../services/filter/filter.service';

@Component({
    selector: 'ga-multiple-autocomplete-filter',
    templateUrl: './multiple-autocomplete-filter.component.html',
    styleUrls: ['./multiple-autocomplete-filter.component.scss'],
    providers: [],
})
export class MultipleAutocompleteFilterComponent implements OnInit {
    /**
     * MANDATORY PROPERTIES
     */

    // Input ('sites', 'categories', 'regions'...)
    @Input() provider: string = null;

    // Input : will be the array data-binded containing the elements the parent wants to use
    @Input() currentValuesSelected: any[] = [];

    /**
     * OTHER INPUTS
     */

    /*
     * If you want the component to have default values like preselected elements coming from parent
     * Different from currentValuesSelected because it takes an array of values like
     * ['gaz', 'elec'] or ['59eafdfd65g4fd8'] instead of [{value: '', displayName: ''}] and [_id: '', name: '' ...]
     */
    @Input() selectedDefaultValues: any[] = [];

    // If you want to provide the component its values yourself from parent (for example the list of sites or categories available)
    @Input() values: any[] = [];

    // Handles case were ofter the 'name' to display is 'displayName'. But sometimes for sites for example, its 'complement'.
    @Input() private propertiesToDisplay = ['displayName'];
    @Input() private propertyToCompare = 'value';

    // If we want to display more than one property of the values. CSS auto handled
    @Input() subPropertyToDisplay: string = null;

    // If we want to match with the search more than the main or sub property (value, _id...)
    @Input() propertiesToMatch: string[] = [];

    @Input() disabled = false;

    @Input() singleSelect = false;

    @Input() placeholder = '';

    @Input() hideName = false;

    // if hasHighlightedItems is true, sort the options into two list from their boolean isHighlighted
    @Input() hasHighlightedItems = false;

    // Event emitting the item selected (not the whole selection)
    @Output() itemSelected: EventEmitter<any> = new EventEmitter();

    autocompleteBaseClass = 'autocompleteMultiInput';

    showOptionsList = false;
    tempSearch: any = [];

    constructor(private filterService: FilterService) {}

    ngOnInit() {
        this.autocompleteBaseClass += this.provider;

        /**
         * Here is how it works. The mandatory provider element is used to get the values in FilterService
         * ONLY IF the component hasn't been provided values from the parent page. You can either specify values
         * or just provider and the values will come from FilterService
         */
        this.checkProvider(() => {
            this.sortValues();

            if (this.selectedDefaultValues.length) {
                this.handleSelectedDefaultValues();
            }
        });
    }

    /**
     * Empty currentValuesSelected to show them again later
     */
    public resetCurrentValuesSelected() {
        this.currentValuesSelected = [];
    }

    sortValues() {
        if (this.values.length > 1) {
            this.values = this.values.sort((optionA, optionB) => {
                let a = this.getItemDisplayName(optionA);
                let b = this.getItemDisplayName(optionB);

                // Make comparaison better without accents and majs if the two aren't null
                // We can sometimes have number here btw
                if (typeof a === 'string' && typeof b === 'string') {
                    a = this.removeAccentFromString(a).toLowerCase();
                    b = this.removeAccentFromString(b).toLowerCase();
                }

                if (a < b) {
                    return -1;
                } else if (a > b) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
    }

    checkProvider(callback) {
        if (this.provider && !this.values.length) {
            this.getProvidedValues()
                .then(response => {
                    this.values = response.data;
                    return callback();
                })
                .catch(error => {
                    this.values = [];
                    return callback();
                });
        } else {
            return callback();
        }
    }

    getProvidedValues() {
        return this.filterService.getQueryResultByProvider(this.provider);
    }

    handleSelectedDefaultValues() {
        const currentValuesSelectedLength = Boolean(this.currentValuesSelected.length);

        this.values.forEach(element => {
            /* Quite complicated. Do not make duplicate between selectedDefaultValues and currentValuesSelected in case
            currentValuesSelected already has elements */
            if (
                (this.selectedDefaultValues.includes(element[this.propertyToCompare]) &&
                    currentValuesSelectedLength &&
                    this.selectedDefaultValues[0] !== this.currentValuesSelected[0][this.propertyToCompare]) ||
                (this.selectedDefaultValues.includes(element[this.propertyToCompare]) && !currentValuesSelectedLength)
            ) {
                this.currentValuesSelected.push(element);
            }
        });
    }

    public onInputSelected(search: string) {
        if (!this.disabled) {
            this.showOptionsList = true;
            this.tempSearch = [];

            if (search === '') {
                this.tempSearch = this.values.slice();
                this.filterTempSearchByCurrentValuesSelected();
            } else {
                this.updateTempSearch(search);
            }
        }
    }

    private getPropertiesToMatch() {
        // First obviously we want the property displayed like "displayName or complement..."
        let propertiesToMatch = Array.from(this.propertiesToDisplay);

        // Then if there exists a subProperty displayed, we want to add it to the matching function
        if (this.subPropertyToDisplay) {
            propertiesToMatch.push(this.subPropertyToDisplay);
        }

        // Finally it there are more properties to match provided, we add them to the matching function
        if (this.propertiesToMatch && this.propertiesToMatch.length) {
            propertiesToMatch = propertiesToMatch.concat(this.propertiesToMatch);
        }

        return propertiesToMatch;
    }

    removeAccentFromString(str) {
        if (typeof str === 'string') {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }

        return str;
    }

    private valueMatchesProperties(search, option, propertiesToMatch) {
        let exists = false;

        search = this.removeAccentFromString(search.toLowerCase());

        propertiesToMatch.forEach(property => {
            if (option[property]) {
                const value = this.removeAccentFromString(option[property].toString().toLowerCase());

                if (!exists && value.includes(search)) {
                    exists = true;
                }
            }
        });

        return exists;
    }

    updateTempSearch(search: string) {
        const propertiesToMatch = this.getPropertiesToMatch();

        this.tempSearch = this.values.filter(option => {
            return this.valueMatchesProperties(search, option, propertiesToMatch);
        });

        this.filterTempSearchByCurrentValuesSelected();
    }

    filterTempSearchByCurrentValuesSelected() {
        // propertyToCompare = '_id' for example
        // tagsSelected is an array filled with all the selected items' _id
        const tagsSelected = [];
        this.currentValuesSelected.forEach(element => {
            tagsSelected.push(element[this.propertyToCompare]);
            if (element.items && element.items.length) {
                element.items.forEach(item => {
                    if (typeof item === 'string') {
                        tagsSelected.push(item);
                    } else if (typeof item === 'object' && item[this.propertyToCompare]) {
                        tagsSelected.push(item[this.propertyToCompare]);
                    }
                });
            }
        });

        // Keep in the temp search all the elements that are not selected
        this.tempSearch = this.tempSearch.filter(searchElement => {
            // to remove an element from the list, check its property 'propertyToCompare', ex: '_id'
            // return true if the element isn't selected yet --> the element stays in the search items
            const value = searchElement[this.propertyToCompare];
            return !tagsSelected.includes(value);
        });
    }

    public onOptionClicked(optionSelected: any) {
        this.showOptionsList = false;
        if (this.singleSelect) {
            this.currentValuesSelected.splice(0, this.currentValuesSelected.length);
            this.currentValuesSelected.push(optionSelected);
        } else {
            this.currentValuesSelected.push(optionSelected);
        }
        this.itemSelected.emit(optionSelected);
    }

    public getItemDisplayName(item: any): string {
        return this.propertiesToDisplay
            .map(property => item[property])
            .filter(value => !!value)
            .join(' - ');
    }

    getItemSubDisplayName(item) {
        return item[this.subPropertyToDisplay];
    }

    getNameFromProvider() {
        let name = '';

        switch (this.provider) {
            case 'energies':
                name = 'Énergies';
                break;

            case 'water':
                name = 'Eau';
                break;

            case 'sites':
                name = 'Sites';
                break;

            case 'categories':
                name = 'Catégories';
                break;

            case 'regions':
                name = 'Régions';
                break;

            case 'companies':
                name = 'Entreprises';
                break;

            case 'contracts':
                name = 'Contrats';
                break;

            default:
                break;
        }

        return name;
    }

    getLighterClass(searchItem) {
        return !this.hasHighlightedItems ? ' ' : searchItem.isHighlighted ? ' ' : 'lighter';
    }
}
