import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-managers',
    templateUrl: './managers.component.html',
    styleUrls: ['./managers.component.scss'],
    providers: [],
})
export class ManagersComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
