import { Component, DoCheck, EventEmitter, Input, IterableDiffer, IterableDiffers, Output } from '@angular/core';
import Swal from 'sweetalert2';

import {
    ExtractDataArray,
    ExtractDataTableProperty,
    SelectBoxValue,
} from 'app/pages/fix-regex-upload/fix-regex-upload.service';
import { PatternService } from 'app/shared/services/pattern/pattern.service';
import { RegexService } from 'app/shared/services/regex/regex.service';

interface PatternData {
    energyType: string;
    identificationPatternId: string;
    textContent: string;
}
@Component({
    selector: 'ga-upload-fix-table',
    templateUrl: './upload-fix-table.component.html',
    styleUrls: ['./upload-fix-table.component.scss'],
})
export class UploadFixTableComponent implements DoCheck {
    @Input() dataArray: ExtractDataArray;
    /**
     * Contains energyType, identificationPatternId and textContent.
     * Used to get dictionary of properties
     */
    @Input() patternData: PatternData;
    public search = '';
    public isSearch = false;
    private iterableDiffer: IterableDiffer<any>;
    @Output() propertySaved: EventEmitter<ExtractDataTableProperty> = new EventEmitter();
    @Output() propertyDeleted: EventEmitter<ExtractDataTableProperty> = new EventEmitter();
    @Output() regexChanged: EventEmitter<ExtractDataTableProperty> = new EventEmitter();
    @Output() regexReset: EventEmitter<ExtractDataTableProperty> = new EventEmitter();
    @Input() isPatternEnabled: boolean;

    constructor(
        private iterableDiffers: IterableDiffers,
        private patternService: PatternService,
        private regexService: RegexService
    ) {
        // https://stackoverflow.com/a/42962723 Here to detect changes on a inputArray, used when ExtractDataTableProperty are adeed to dataArray.dataTable from other component.
        this.iterableDiffer = iterableDiffers.find([]).create(null);
    }

    ngDoCheck() {
        const changes = this.iterableDiffer.diff(this.dataArray.dataTable);
        if (changes) {
            this.filterDataTable({
                target: {
                    value: this.search,
                },
            });
        }
    }

    removeRegex(property: ExtractDataTableProperty) {
        Swal.fire({
            title: 'Etes-vous sûr ?',
            text: 'Voulez-vous supprimer la propriété ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
                this.propertyDeleted.emit(property);
                Swal.fire('Supprimé', 'Le regex a été supprimé.', 'success');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire('Annulé', "Le regex n'a pas été supprimé", 'error');
            }
        });
    }

    resetRegex(property: ExtractDataTableProperty) {
        Swal.fire({
            title: 'Etes-vous sûr ?',
            text: 'Voulez-vous réinitialiser le regex initial ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Réinitialiser',
            cancelButtonText: 'Annuler',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
                this.regexReset.emit(property);
            }
        });
    }

    isRegexChangedNewOrWrong(property: ExtractDataTableProperty) {
        // wrong ?
        if ((!property.valueExtract || !property.countExtract) && !property.loading) {
            return 'red';
        } else if (!property.initialRegex) {
            // new property ?
            return 'blue';
        } else if (property.initialRegex !== property.regex) {
            // regex has changed and value is extracted
            return 'blue';
        }

        return;
    }

    editMode(property: ExtractDataTableProperty) {
        const data = this.dataArray.dataTable.find(prop => prop.name === property.name);
        data.editing = true;
        data.selectBoxData.selectedValue = data.selectBoxData.listOptions[0];
    }

    executeRegex(property: ExtractDataTableProperty) {
        this.regexChanged.emit(property);
    }

    updatePropertyRegex(property: ExtractDataTableProperty, optionSelected) {
        property.selectBoxData.selectedValue = optionSelected;
        property.regex = optionSelected.regex;
        property.editing = false;
        this.regexChanged.emit(property);
    }

    // If the regex belongs to the initial pattern and has been changed we can display the "Revert" button
    hasRegexChanged(property: ExtractDataTableProperty) {
        return property.initialRegex && property.initialRegex !== property.regex ? true : false;
    }

    displayDictionaryOption(regex, extractedValue) {
        // if the extracted valueis null -> shoudn't display 'null'
        const valueToDisplay = !extractedValue ? '' : extractedValue + ' --> ';

        // the first option is an empty regex, shouldn't display anything
        return valueToDisplay + regex;
    }

    getValueExtract(property: ExtractDataTableProperty) {
        if (property.loading) {
            return 'Recherche...';
        }
        return property.valueExtract;
    }

    public openOrCloseSearch() {
        this.isSearch = !this.isSearch;
        this.filterDataTable({
            target: {
                value: '',
            },
        });
    }

    /**
     * Filter the table of property according to search input
     */
    public filterDataTable(event: { target: { value: string } }): ExtractDataArray {
        this.search = event.target.value;
        this.dataArray.dataTable.forEach(property => (property.hide = !this.matchResearch(property)));

        return this.dataArray;
    }

    /**
     * True if search is contained in name or regex or value of property
     */
    private matchResearch(property: ExtractDataTableProperty): boolean {
        return (
            property.name.toLowerCase().includes(this.search.toLowerCase()) ||
            property.regex.toLowerCase().includes(this.search.toLowerCase()) ||
            property.valueExtract.toLowerCase().includes(this.search.toLowerCase())
        );
    }

    /**
     * Fill listOptions select box  of given property
     */
    public getListOptions(property: ExtractDataTableProperty): void {
        if (!property.selectBoxData.listOptions.length) {
            this.setDictionaryOfProperty(this.patternData, property);
        }
    }

    /**
     * Set listOption of property with dictionnary and set selectedBoxData.selectedValue of property.
     */
    private async setDictionaryOfProperty(
        { energyType, identificationPatternId, textContent }: PatternData,
        property: ExtractDataTableProperty
    ): Promise<void> {
        const listRegex = await this.patternService.getDictionaryForProperty(
            energyType,
            identificationPatternId,
            property.name
        );

        if (listRegex && listRegex.length) {
            const val = await this.getValuesFromListRegex(listRegex, textContent);
            property.selectBoxData.listOptions = property.selectBoxData.listOptions.concat(val);
            // if the regex belongs to the pattern, set preselected value with that regex
            // otherwise if it's a new property the regex is empty and no value is selected
            property.selectBoxData.selectedValue = property.selectBoxData.listOptions.find(
                option => option.regex === property.regex
            );
        }
    }

    /**
     * Find values in text content for a list of regex.
     */
    private async getValuesFromListRegex(listRegex: string[], textContent: string): Promise<SelectBoxValue[]> {
        const listRegexValue: SelectBoxValue[] = [];

        for (let i = 0; i < listRegex.length; i++) {
            const regex = listRegex[i];
            const valueExtracted = await this.getListValuesExtracted(textContent, regex);
            listRegexValue.push({
                id: i + 1,
                regex,
                valueExtracted,
            });
        }
        return listRegexValue;
    }

    /**
     * Get list of values extracted
     */
    private async getListValuesExtracted(textContent: string, regexToCheck: string): Promise<string> {
        try {
            const resultsFromMatch = await this.regexService.computeRegexInPool(textContent, regexToCheck);
            return this.displayValuesExtracted(resultsFromMatch);
        } catch (err) {
            if (err && err.errorCode) {
                return `Error : ${err.errorCode}`;
            }
            return 'Error : Unknown error';
        }
    }

    /**
     * Get display string for extracted values
     */
    private displayValuesExtracted(valuesArray: string[]): string {
        return valuesArray.map(value => `[${value}]`).join(' ');
    }
}
