import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdminCompanyService } from 'app/pages/admin/company/company.service';
import { CompanyService } from 'app/shared/components/admin/company/company.service';
import { ManagerTypes } from 'app/shared/constants/manager-types-list.constants';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { FileStorageService } from 'app/shared/services/files/file-storage.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { UsersService } from 'app/shared/services/users/users.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { UploadFilesTileComponent } from '../../../upload/upload-files-tile/upload-files-tile.component';

import { Location } from '@angular/common';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';
import { Company } from 'app/shared/models/company.interface';
import { File } from 'app/shared/models/upload-file.interface';
import { User } from 'app/shared/models/users.interface';
import * as clone from 'clone';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

const logoStates = {
    NO_LOGO: 'noLogo',
    HAS_LOGO: 'hasLogo',
};

interface Logo {
    _id?: string;
    url: string;
}

@Component({
    selector: 'ga-company-profile-tab',
    templateUrl: './company-profile.component.html',
    styleUrls: ['./company-profile.component.scss'],
    providers: [],
})
export class CompanyProfileComponent implements OnInit {
    @Input() company: any = null;
    private _canEdit = false;
    @Input()
    set canEdit(canEdit: boolean) {
        this._canEdit = canEdit;
    }
    get canEdit(): boolean {
        return this._canEdit && !this._isDeleting;
    }
    @Input() canDelete = false;

    isCreating = true;
    currentStateLogo: string = logoStates.NO_LOGO;
    user: any;
    isAffiliated = false;

    organizationsList: any = [];
    companiesList: any = [];
    companiesListFiltered: any = [];

    affiliationType: string;
    affiliation: any;
    branchesList: any = [];
    initialBranchesList: any = []; // need to store the initial branches, in case they are removed, we need to remove their holding property in db

    uploadTilesData = {
        [logoStates.NO_LOGO]: {
            label: 'Glissez et déposez votre logo',
            subLabel: "On s'occupe de tout !",
            imgUrl: 'assets/img/icons/005-hotel-with-three-floors.png',
        },
        [logoStates.HAS_LOGO]: {
            label: '',
            subLabel: '',
            imgUrl: 'assets/img/backgrounds/default-avatar.jpg',
        },
    };

    /**
     * Is company being deleted
     */
    private _isDeleting = false;
    set isDeleting(isDeleting: boolean) {
        this._isDeleting = isDeleting;
        this.deletingChange.emit(this._isDeleting);
    }
    get isDeleting(): boolean {
        return this._isDeleting;
    }

    /**
     * UPDATE MANAGERS and COMPLEXITY
     */

    /** Complexity value list */
    public complexityList: number[] = [null, 1, 2, 3, 4, 5, 6, 7];
    /** Energy manager list */
    public energyManagerList: User[] = [];
    /** data manager list */
    public dataManagerList: User[] = [];
    /** Selected energy manager (user _id) */
    public energyManager: string;
    /** Selected data manager (user _id) */
    public dataManager: string;

    /**
     * Emit event when deleting action is started and finished
     */
    @Output() deletingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() save: EventEmitter<any> = new EventEmitter();

    @ViewChild('upload', { static: false }) private uploadComponent: UploadFilesTileComponent;

    constructor(
        private adminCompanyService: AdminCompanyService,
        private companiesService: CompaniesService,
        private router: Router,
        private location: Location,
        private sessionService: SessionService,
        private usersService: UsersService,
        private utilsService: UtilsService,
        private companyService: CompanyService,
        private fileStorageService: FileStorageService
    ) {}

    ngOnInit() {
        this.initData();
    }

    /**
     * Returns TRUE if the current user is super admin
     *
     * @returns {boolean}
     */
    get displayManagersAssignation(): boolean {
        return this.usersService.isAdmin(this.sessionService.getUser());
    }

    /**
     * Get the full name of the specified user
     *
     * @param {User} user
     * @returns {string}
     */
    public getUserFullName(user: User): string {
        if (!user) {
            return '';
        }
        const lastname = typeof user.name === 'string' ? user.name : '';
        const firstname = typeof user.firstname === 'string' ? user.firstname : '';
        return `${lastname} ${firstname}`;
    }

    /**
     * Set energy manager and data manager to the company
     */
    private setCompanyManagers() {
        this.company.managers = [];

        if (this.energyManager) {
            this.company.managers.push({
                user: this.energyManager,
                type: ManagerTypes.ENERGY_MANAGER,
            });
        }

        if (this.dataManager) {
            this.company.managers.push({
                user: this.dataManager,
                type: ManagerTypes.DATA_MANAGER,
            });
        }
    }

    /**
     * Get logo column class by user type
     *
     * @returns {string} - class
     */
    get colLogo(): string {
        return this.displayManagersAssignation ? 'col-md-4' : 'col-md-6';
    }

    /**
     * Get profile column class by user type
     *
     * @returns {string} - class
     */
    get colProfile(): string {
        return this.displayManagersAssignation ? 'col-md-8' : 'col-md-6';
    }

    /**
     * Get profile sub columns class by user type
     *
     * @returns {string} - class
     */
    get colForm(): string {
        return this.displayManagersAssignation ? 'col-md-6' : 'col-md-12';
    }

    /**
     * Set energy manager list
     * Set data manager list
     *
     * @returns {Promise<void>}
     */
    private async initManagersData(): Promise<void> {
        const allUsers = await this.usersService.getAllUsersList(this.company._id, ['users', 'viewers']);
        const userTypes = [UserTypes.ADMIN, UserTypes.ENERGY_MANAGER];

        // Set energy managers and data managers selection list
        let energyManagers = this.companyService.getCompanyUsers(this.company.users, allUsers, userTypes);
        energyManagers = this.utilsService.sortAlphabetically(energyManagers, 'name');

        const companyViewers = this.companyService.getCompanyUsers(this.company.viewers, allUsers, userTypes);
        let dataManagers = energyManagers.concat(companyViewers);
        dataManagers = this.utilsService.sortAlphabetically(dataManagers, 'name');

        this.energyManagerList = [null].concat(energyManagers);
        this.dataManagerList = [null].concat(dataManagers);

        // Set selected energy manager
        this.energyManager = this.companyService.getCompanyManager(this.company.managers, [
            ManagerTypes.ENERGY_MANAGER,
        ]);

        // Set selected data manager
        this.dataManager = this.companyService.getCompanyManager(this.company.managers, [ManagerTypes.DATA_MANAGER]);
    }

    async initData() {
        try {
            this.affiliationType = '1'; // 1: branches, 2: holding - select branches by default

            const results = await Promise.all([
                this.adminCompanyService.getCompanyProperties(),
                this.companiesService.getVisibleCompaniesForUser(),
            ]);

            this.organizationsList = results[0].data.organizations;
            this.companiesList = results[1];
            this.companiesListFiltered = results[1];

            if (this.company) {
                this.isCreating = false;
                this.setAffiliations();
                if (this.displayManagersAssignation) {
                    await this.initManagersData();
                }
            } else {
                this.location.go('/admin/entreprises/creer');

                this.company = {
                    name: '',
                    address: '',
                    organization: this.organizationsList[0].id,
                };
            }
            this.setCompanyLogo();
        } catch (err) {
            // err.errorCode is set to error_getListCompanies
            this.getSwalMsg(err.errorCode);
            return this.router.navigateByUrl('/admin/entreprises');
        }
    }

    /**
     * Load the affiliations from the company being edited
     */
    setAffiliations() {
        // fill branchesList selectbox with the companies whose holding is the current company
        // add also the branches which aren't editable by the user
        this.company.branches.forEach(branch => {
            const branchEditable = this.companiesList.find(c => c._id === branch._id);

            if (branchEditable) {
                this.branchesList.push(branchEditable);
            } else {
                this.branchesList.push(branch);
            }
        });

        this.branchesList = this.utilsService.sortAlphabetically(this.branchesList, 'name');

        // need to record the inital branches in case we have to remove them in db
        this.initialBranchesList = this.branchesList.slice();

        // if the company is a holding or has a holding, the 'holding & branches' block is visible
        if (this.company.holding || this.branchesList.length) {
            this.isAffiliated = true;
        }

        // holding & branches select box : update it by removing the branches and holding of the current company
        this.filterCompaniesListWithAffiliations();
    }

    /**
     * Holding & branches select box : update it by removing the branches and holding of the current company
     */
    filterCompaniesListWithAffiliations() {
        // remove the company's branches
        this.companiesListFiltered = this.companiesList.filter(
            comp => !this.branchesList.find(c => c._id === comp._id)
        );

        // remove the company's holding
        if (this.company.holding) {
            this.companiesListFiltered = this.companiesListFiltered.filter(
                comp => comp._id.toString() !== this.company.holding._id
            );
        }

        // remove the current company from the selectbox list
        this.companiesListFiltered = this.companiesListFiltered.filter(
            comp => comp._id.toString() !== this.company._id
        );
        this.companiesListFiltered = this.utilsService.sortAlphabetically(this.companiesListFiltered, 'name');

        // select the first company in the select box
        this.affiliation = this.companiesListFiltered.length ? this.companiesListFiltered[0] : [];
    }

    /**
     * add either a branch or a holding
     */
    addAffiliation() {
        // selection is on 'branches'
        if (this.affiliationType === '1') {
            this.addBranch();
        } else {
            // selection is on 'holding'
            this.addHolding();
        }

        // update the list in the companies selectbox
        this.filterCompaniesListWithAffiliations();

        // select the new first company in the selectbox
        this.affiliation = this.companiesListFiltered[0];
    }

    /**
     * Remove the given branch from the branchList
     *
     * @param {{ _id: string; name: string }} branchToRemove
     */
    removeBranch(branchToRemove: { _id: string; name: string }) {
        this.branchesList = this.branchesList.filter(branch => branch._id !== branchToRemove._id);

        // update the list in the companies selectbox
        this.filterCompaniesListWithAffiliations();
    }

    /**
     * The user can remove the holding if he can edit the page, and can see the holding
     *
     * @returns {boolean}
     */
    canRemoveHolding(): boolean {
        return this.canEdit && this.companiesList.find(c => c._id === this.company.holding._id);
    }

    /**
     * Check if the given branch can be removed
     *
     * @param {string} branchId
     * @returns {boolean}
     */
    canRemoveBranch(branchId: string): boolean {
        return this.canEdit && this.companiesList.find(c => c._id === branchId);
    }

    /**
     * Remove the holding from the company
     */
    removeHolding() {
        if (this.company.holding) {
            this.company.holding = null;

            // update the list in the companies selectbox
            this.filterCompaniesListWithAffiliations();
        }
    }

    /**
     * Used to disable or not the button to add company holding/branch
     *
     * @returns {string}
     */
    isDisabled(): string {
        return this.companiesListFiltered.length ? '' : 'disabled';
    }

    /**
     * Add the affiliation to the branch list ordered alphabetically
     */
    addBranch() {
        this.branchesList.push(this.affiliation);
        this.branchesList = this.utilsService.sortAlphabetically(this.branchesList, 'name');
    }

    /**
     * Set the company holding
     */
    addHolding() {
        this.company.holding = { _id: this.affiliation._id, name: this.affiliation.name };
    }

    /**
     * Delete all affiliations when the 'holding & branches' selectbox is unchecked
     */
    deleteAffiliation() {
        this.isAffiliated = !this.isAffiliated;

        // if uncheck the box, delete affiliations
        if (!this.isAffiliated) {
            // delete holding
            if (this.company.holding) {
                delete this.company.holding;
            }

            // delete branches
            this.branchesList = [];
            this.affiliation = null;
        }

        // update the 'holding & branches' companies selectbox
        this.filterCompaniesListWithAffiliations();
    }

    //  ******* SAVE COMPANY *****

    /**
     * on click on 'Create' or 'Update'
     *
     * @returns {Promise<void>}
     */
    async saveCompanyProfile(): Promise<void> {
        if (this.displayManagersAssignation) {
            this.setCompanyManagers();
        }
        if (this.isCompanyValid()) {
            try {
                const companyToSave = this.getCompanyFormated();
                await this.saveCompany(companyToSave);

                // update the branches company in db: add their new holding or delete their old one
                await this.handleBranchesToRemove();
                await this.handleBranchesToAdd();
                this.getSwalMsg('company_saved');
                this.router.navigateByUrl('/admin/entreprise/' + this.company._id);
                this.company.branches = this.branchesList.map(b => ({ _id: b._id, name: b.name }));
                this.save.emit(this.company);
            } catch (err) {
                // err.errorCode : error_createNewCompany || error_add_branch || error_delete_branch
                this.getSwalMsg(err.errorCode);
            }
        }
    }

    /**
     * check if the form info are valid before saving
     *
     * @returns {boolean}
     */
    isCompanyValid(): boolean {
        // the fields can't be empty
        if (this.hasEmptyFields()) {
            this.getSwalMsg('empty_field');
            return false;
        } else if (this.isCreating) {
            // if we create a company, its name needs to be unique
            if (this.alreadyInList()) {
                this.getSwalMsg('company_already_exists');
                return false;
            }
        }

        return true;
    }

    /**
     * Get formated Company
     *
     * @returns {Company}
     */
    getCompanyFormated(): Company {
        const companyFormated = clone(this.company);
        if (companyFormated.holding) {
            companyFormated.holding = companyFormated.holding._id;
        }
        return companyFormated;
    }

    /**
     * check if some fields are empty before saving
     *
     * @returns {boolean}
     */
    hasEmptyFields(): boolean {
        return (
            !this.company.name ||
            this.company.name === '' ||
            !this.company.address ||
            this.company.address === '' ||
            !this.company.organization
        );
    }

    /**
     * Check if the company name already exists
     *
     * @returns {boolean}
     */
    alreadyInList(): boolean {
        return this.companiesList.some(c => {
            return c.name === this.company.name;
        });
    }

    /**
     * Create or update the company
     *
     * @param {Company} companyToSave
     * @returns {Promise<any>}
     */
    async saveCompany(companyToSave: Company): Promise<any> {
        if (!this.isCreating) {
            await this.adminCompanyService.updateCompany(companyToSave);
            return;
        } else {
            const res = await this.adminCompanyService.createCompany(companyToSave);
            this.company = res;
            return;
        }
    }

    /**
     * if some of the company's branches were removed, their 'holding' property needs to be removed in db
     *
     * @returns {Promise<any>}
     */
    async handleBranchesToRemove(): Promise<any> {
        try {
            const branchesToRemove = this.initialBranchesList.filter(
                oldBranch => !this.branchesList.includes(oldBranch)
            );
            await Promise.all(
                branchesToRemove.map(async branch => {
                    branch.holding = null;
                    await this.adminCompanyService.updateCompany(branch);
                    return;
                })
            );
            return;
        } catch (err) {
            err.errorCode = 'error_delete_branch';
            throw err;
        }
    }

    /**
     * if the company has some new holdings, their 'holding' property has to be updated with the company's id
     *
     * @returns {Promise<any>}
     */
    async handleBranchesToAdd(): Promise<any> {
        try {
            const branchesToAdd = this.branchesList.filter(oldBranch => !this.initialBranchesList.includes(oldBranch));
            await Promise.all(
                branchesToAdd.map(async branch => {
                    branch.holding = this.company._id;
                    await this.adminCompanyService.updateCompany(branch);
                    return;
                })
            );
            return;
        } catch (err) {
            err.errorCode = 'error_add_branch';
            throw err;
        }
    }

    /**
     * Delete company
     *
     * @returns {Promise<void>}
     */
    async deleteCompany(): Promise<void> {
        const doDelete: boolean = await this.confirmDeleteCompany();
        if (doDelete) {
            try {
                this.isDeleting = true;
                await this.companiesService.deleteCompany(this.company._id);
                this.isDeleting = false;
                Swal.fire(
                    this.company.name + ' supprimé',
                    'Toutes les données du client ' + this.company.name + ' ont bien été supprimées',
                    'success'
                );
            } catch (err) {
                this.isDeleting = false;
                this.getSwalMsg('error_delete_company');
                return;
            }
            await this.redirectAfterDelete();
        }
    }

    /**
     * Display two confirmation modals to delete company
     *
     * @returns {Promise<boolean>} returns true if user confirm that they want to delete company, false otherwise.
     */
    private async confirmDeleteCompany(): Promise<boolean> {
        const result1 = await Swal.fire({
            title: 'Supprimer ' + this.company.name + ' ?',
            text:
                'Êtes-vous sûr de vouloir supprimer le compte client ' +
                this.company.name +
                " ?\
                Cette opération est irréversible, toutes les données du client vont être supprimées (factures, sites, PDLs, ...).\
                Si vous avez un doute, n'hésitez à contacter Green Alternative.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer le compte ' + this.company.name,
            cancelButtonText: 'Annuler',
        });
        if (result1 && result1.value) {
            const result2 = await Swal.fire({
                title: 'Vraiment sûr ?',
                text:
                    'Dernière étape avant la suppression du client ' +
                    this.company.name +
                    '.\
                    Vous ne pourrez plus annuler cette suppression.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Supprimer ' + this.company.name,
                cancelButtonText: 'Annuler',
                input: 'checkbox',
                inputPlaceholder: '  Je confirme vouloir supprimer ' + this.company.name,
                inputValidator: value => {
                    return !value && 'Veuillez confirmer vouloir supprimer le client';
                },
            });
            if (result2 && result2.value) {
                return true;
            }
        }
        return false;
    }

    /**
     * Redirect user after the company is deleted
     *
     * @returns {Promise<void>}
     */
    private async redirectAfterDelete(): Promise<void> {
        try {
            const connectedCompany = this.sessionService.getCompany();
            const companies = await this.companiesService.refreshUserCompaniesList();
            if (connectedCompany && connectedCompany._id !== this.company._id) {
                this.router.navigateByUrl('/admin/entreprises');
                return;
            }
            // Go to favorite or first one
            // If the user isn't linked to any company, logout user
            const user = this.sessionService.getUser();
            if (user.favouriteCompany && companies.find(x => x._id === user.favouriteCompany)) {
                this.sessionService.setCompany(companies.find(x => x._id === user.favouriteCompany));
                this.router.navigate(['/accueil'], { queryParams: { refresh: true } });
            } else if (companies.length) {
                this.sessionService.setCompany(companies[0]);
                this.router.navigate(['/accueil'], { queryParams: { refresh: true } });
            } else {
                this.sessionService.clearLocalStorage();
                this.router.navigate(['/authentification']);
            }
            return;
        } catch (error) {
            this.router.navigate(['/accueil'], { queryParams: { refresh: true } });
        }
    }

    // **** LOGO UPLOAD AND LOAD ****

    /**
     * Click on add a logo
     */
    clickOnFileInput() {
        this.uploadComponent.clickOnInput();
    }

    /**
     * Get upload logo path and labels to display
     *
     * @returns {{ label: string; subLabel: string; imgUrl: string }}
     */
    getUploadLogoLabels(): { label: string; subLabel: string; imgUrl: string } {
        return this.uploadTilesData[this.currentStateLogo];
    }

    /**
     * Check if there is current state logo
     *
     * @returns {boolean}
     */
    hasCurrentState(): boolean {
        return Boolean(this.currentStateLogo);
    }

    /**
     * Check if there is a logo
     *
     * @returns {boolean}
     */
    hasLogo(): boolean {
        return this.currentStateLogo === logoStates.HAS_LOGO;
    }

    /**
     * files coming from drag & drop - upload them in db + link their upload id to the company's logo property
     *
     * @param {File[]} files
     * @returns {void}
     */
    addFiles(files: File[]) {
        if (!this.canEdit) {
            return;
        }
        if (files) {
            // the company must be saved first before uploading a file - need to link the upload to the company
            if (!this.isCreating) {
                // if multiple files were uploaded - take the first one
                const file = files[0];

                const formatedFile = {
                    filename: file.name,
                    fileObject: file, // file Object containing raw data
                };

                // save logo in db + link its id to the company's logo property
                return this.adminCompanyService.uploadPicture(formatedFile, this.company._id, 'company').subscribe(
                    event => {
                        if (event.type === HttpEventType.Response) {
                            this.company.logo = event.body['data'].logo;

                            // set the new logo path
                            this.setLogoState(this.company.logo);
                            this.getSwalMsg('picture_saved');
                        }
                    },
                    (err: HttpErrorResponse) => {
                        this.getSwalMsg(err.error.errorCode || 'error_savePicture');
                    }
                );
            } else {
                this.getSwalMsg('need_company_saved_before_logo');
            }
        } else {
            this.getSwalMsg('file_type_not_allowed');
        }
    }

    /**
     * set the new logo path
     *
     * @param {Logo} logo
     * @returns {void}
     */
    async setLogoState(logo: Logo): Promise<void> {
        if (logo && logo.url) {
            this.fileStorageService
                .getUrl(logo._id)
                .then(res => {
                    this.uploadTilesData[logoStates.HAS_LOGO].imgUrl = res;
                    this.currentStateLogo = logoStates.HAS_LOGO;
                })
                .catch(() => {
                    this.currentStateLogo = logoStates.NO_LOGO;
                });
        } else {
            this.currentStateLogo = logoStates.NO_LOGO;
        }
    }

    /**
     * set the logo info
     */
    setCompanyLogo() {
        // if we create a new company, set the default empty logo in background
        if (this.isCreating || !this.company.logo) {
            this.currentStateLogo = logoStates.NO_LOGO;
        } else {
            this.setLogoState(this.company.logo);
        }
    }

    /**
     * Get the Save profile button label
     *
     * @returns {string}
     */
    getSaveBtnLabel() {
        return !this.isCreating ? 'Mettre à jour' : 'Créer';
    }

    /**
     * Trigger the sweet alert
     * The alert message depends of the given error code
     *
     * @param {string} msg_code
     * @returns {void}
     */
    getSwalMsg(msg_code: string): void {
        const messages = {
            empty_field: ['Attention', "L'un des champs n'est pas renseigné.", 'warning'],
            error_getListUsers: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des autres utilisateurs.',
                'error',
            ],
            error_updateCompany: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement du profil.",
                'error',
            ],
            error_createCompany: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement du profil.",
                'error',
            ],
            company_already_exists: [
                'Nom déjà utilisé',
                'Chaque client doit avoir un nom unique. Veuillez choisir un autre nom.',
                'error',
            ],
            company_code_not_unique: [
                'Code déjà utilisé',
                "Chaque client au sein d'un même groupe doit avoir un code unique. Veuillez choisir un autre code.",
                'error',
            ],
            company_saved: ['Sauvegardé', "L'entreprise a bien été sauvegardée", 'success'],
            need_company_saved_before_logo: [
                'Attention',
                "Le client doit être créée avant d'enregistrer le logo.",
                'warning',
            ],
            logo_need_png_extension: ['Attention', "Le fichier uploadé doit avoir l'extension '.png'", 'warning'],
            file_type_not_allowed: [
                'Attention',
                `Le fichier uploadé doit avoir l'extension '.png', '.jpeg' ou '.jpg'`,
                'warning',
            ],
            file_size_max: ['Attention', `Le fichier uploadé doit faire moins de 5 Mo`, 'warning'],
            error_getListCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des filiales.',
                'error',
            ],
            error_savePicture: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement de la photo.",
                'error',
            ],
            error_deletePicture: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la suppression de la photo.',
                'error',
            ],
            error_delete_branch: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant la suppression d'une filiale.",
                'error',
            ],
            error_add_branch: ['Toutes nos excuses', "Une erreur est survenue pendant l'ajout d'une filiale.", 'error'],
            company_holding_branch_looping: [
                'Impossible',
                'Il est impossible de créer des boucles entre maisons mères et filiales (ou sous filiales, etc...)',
                'error',
            ],
            error_delete_company: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la suppression du client.',
                'error',
            ],
            picture_saved: ['Sauvegardé', 'La photo a bien été enregistrée', 'success'],
            default_error: ['Toutes nos excuses', 'Une erreur est survenue.', 'error'],
        };

        if (!messages[msg_code]) {
            msg_code = 'default_error';
        }

        Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
    }

    /**
     * Delete the company logo
     */
    async deleteLogoPicture() {
        try {
            const company = await this.adminCompanyService.deletePictureCompany(this.company._id);
            this.company = company;
            this.setLogoState(this.company.logo);
        } catch (err) {
            // err.error_code is set to error_deletePicture
            this.getSwalMsg(err.error_code);
        }
    }
}
