export const ExtractErrorList = {
    UPLOAD_ALREADY_EXTRACTED: 'upload_already_extracted',
    CHUNKS_ALREADY_EXTRACTED: 'chunks_already_extracted',
    NEED_FORCE_GEOLOC: 'need_geolocationForced',
    WRONG_UPLOADID: 'upload_not_found',
    MISSING_IDENTIFICATION_PATTERNS: 'identificationPatterns_not_found',
    WRONG_USERID: 'user_not_found',
    WRONG_COMPANYID: 'company_not_found',
    UPLOAD_CANT_FORCE_GEOLOC: 'upload_forceGeoloc_validAddress',
    CHUNKS_CANT_FORCE_GEOLOC: 'chunks_forceGeoloc_validAddress',
    CHUNKS_DIFFERENT_UPLOAD: 'wrong_chunksid_selected',
};
