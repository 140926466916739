import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils/utils.service';

@Pipe({
    name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
    constructor(private utilsService: UtilsService) {}

    transform(value: number, defaultIfFinite: any = '', digits: number = 0): string {
        return this.utilsService.formateNumber(value, defaultIfFinite, digits);
    }
}
