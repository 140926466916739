import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { OperatingMonitoringService } from '../operating-monitoring.service';

import { IndexReading } from '../operating-monitoring.interface';
import { IndexReadingUpdate } from '../operating-monitoring.update.interface';

@Injectable()
export class OperatingReadingsService {
    monitoringReadings: IndexReading[];

    private readonly dateFormat = 'DD/MM/YYYY';

    constructor(private operatingMonitoringService: OperatingMonitoringService) {
        // Fetch readings of the selected monitoring
        this.operatingMonitoringService.selectedMonitoring$.subscribe(
            monitoring => (this.monitoringReadings = monitoring.indexReadings)
        );
    }

    /**
     * Validate if index reading inputs are valid
     * @returns List containing error messages (list is empty if all inputs are valid)
     */
    public validateReading(reading: IndexReadingUpdate): string[] {
        const messages: string[] = [];

        if (reading.date === null || reading.measuringPointId === null || reading.volume === null) {
            messages.push("Veuillez remplir l'ensemble des champs du relevé d'index.");
        }

        // Only apply this validation part if reading has a measuring point
        if (reading.measuringPointId && reading.date) {
            const readings = this.getFilteredReadings(this.monitoringReadings.concat(), reading.measuringPointId);

            const readingWithSameDate = readings.find(
                filteredReading =>
                    moment.utc(filteredReading.date).isSame(moment.utc(reading.date), 'day') &&
                    ((!reading._id && filteredReading._id) || reading._id !== filteredReading._id)
            );

            if (readingWithSameDate) {
                const dateString = moment.utc(readingWithSameDate.date).format(this.dateFormat);
                messages.push(`Un relevé existe déjà à la date ${dateString}.`);
                return messages;
            }

            const { previousReading, nextReading } = this.fetchAdjacentReadings(reading, readings);

            // Validate reading new inputs
            if (previousReading && nextReading) {
                // Check if new volume is in between previous and next volume
                if (reading.volume < previousReading.volume || reading.volume > nextReading.volume) {
                    messages.push(
                        `Le volume doit être compris entre ${previousReading.volume} et ${nextReading.volume}.`
                    );
                }
            } else if (previousReading) {
                // Check if new volume is superior to previous volume
                if (reading.volume < previousReading.volume) {
                    messages.push(`Le volume doit être supérieur ou égal à ${previousReading.volume}`);
                }
            } else if (nextReading) {
                // Check if new volume is inferior to previous volume
                if (reading.volume > nextReading.volume) {
                    messages.push(`Le volume doit être inférieur ou égal à ${nextReading.volume}`);
                }
            } else {
                // We have to check if volume is not inferior to 0
                if (reading.volume < 0) {
                    messages.push('Le volume doit être supérieur à 0');
                }
            }
        }

        return messages;
    }

    /**
     * Filter readings on given measure point and sort by descending date
     * @returns Filtered and sorted list
     */
    private getFilteredReadings(readings: IndexReading[], measuringPointId: string): IndexReading[] {
        return readings
            .concat()
            .filter(indexReading => indexReading.measuringPointId === measuringPointId)
            .sort((a, b) => b.date.localeCompare(a.date));
    }

    /**
     * Find adjacent readings of given reading in filtered list of readings
     * @returns Previous reading or null if not found
     */
    private fetchAdjacentReadings(
        reading: IndexReadingUpdate,
        filteredReadings: IndexReading[]
    ): { nextReading: IndexReading; previousReading: IndexReading } {
        const adjacentReadings: {
            previousReading: IndexReading;
            nextReading: IndexReading;
        } = {
            previousReading: null,
            nextReading: null,
        };

        const indexOfReading = filteredReadings.findIndex(r => r._id === reading._id);

        if (filteredReadings[indexOfReading + 1]) {
            adjacentReadings.previousReading = filteredReadings[indexOfReading + 1];
        }
        if (filteredReadings[indexOfReading - 1]) {
            adjacentReadings.nextReading = filteredReadings[indexOfReading - 1];
        }

        return adjacentReadings;
    }
}
