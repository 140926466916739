import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
    selector: 'ga-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    providers: [],
})
export class ModalComponent implements OnInit {
    @Input() title: string;

    /**
     * If true, prevent modal from closing by clicking outside or using ESC key.
     */
    @Input() set preventBackdrop(value: boolean) {
        const doPrevent = Boolean(value);

        if (this.modal) {
            const config: ModalOptions = {
                ignoreBackdropClick: doPrevent,
                keyboard: !doPrevent,
            };
            this.modal.config = config;
        }
    }

    /** Size of modal. Can be 'sm', 'md' or 'lg' */
    @Input() size: 'sm' | 'md' | 'lg' = 'lg';

    /**
     * Creation/Edit modal
     */
    @ViewChild('customModal', { static: true }) modal: ModalDirective;

    constructor() {}

    ngOnInit() {}

    public show() {
        this.modal.show();
    }

    public close() {
        this.modal.hide();
    }

    /**
     * @returns {boolean} true if modal is shown, false otherwise
     */
    public get isShown(): boolean {
        return this.modal.isShown;
    }
}
