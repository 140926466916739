import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Attribute } from 'app/shared/models/attribute.interface';
import { CO2eEmission } from 'app/shared/models/co2emissions.interface';
import { SitePopulated } from 'app/shared/models/site.interface';
import { Vehicle } from 'app/shared/models/vehicle.interface';
import { ApiService } from '../../../shared/services/api/api.service';
import { PageService } from '../../../shared/services/page/page.service';

@Injectable()
export class VehiclesService extends PageService {
    /**
     * All vehicle categories available
     */
    private categories: Attribute[];

    /**
     * All vehicle makes available
     */
    private makes: Attribute[];

    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get vehicles for a given company.
     * If no property `companyId` in filters, will use session company.
     * @param {*} filters - filters to apply for routing references.
     * @returns {Promise<Vehicle[]>} list of vehicles matching filters
     */
    async getVehicles(filters: object = {}): Promise<Vehicle[]> {
        const res = await this.get('/api/vehicles/', null, filters);
        return res.data;
    }

    /**
     * Update vehicle data
     * @param {string} id - vehicle id
     * @param {object} data - vehicle properties to update
     * @returns {Promise<Vehicle>} vehicle updated
     */
    async update(id: string, data: object): Promise<Vehicle> {
        const res = await this.put(`/api/vehicles/${id}`, data);
        return res.data;
    }

    /**
     * Link given vehicle to given site
     * @param {string} vehicleId - id of vehicle to link
     * @param {string} siteId - id of site to associate vehicle to
     * @returns {Promise<Vehicle>} vehicle associated
     */
    async linkVehicleToSite(vehicleId: string, siteId: string): Promise<Vehicle> {
        const data = {
            vehicle: vehicleId,
            site: siteId,
        };
        const res = await this.patch(`/api/vehicles/associate-site`, data);
        return res.data;
    }

    /**
     * Create site from the given vehicle
     * @param {string} vehicleId - id of vehicle to create site from
     * @returns {Promise<SitePopulated>} site created
     */
    async createSiteFromVehicle(vehicleId: string): Promise<SitePopulated> {
        const data = {
            vehicle: vehicleId,
        };
        const res = await this.post('/api/vehicles/create-site', data);
        return res.data;
    }

    /**
     * Unlink given vehicle from site
     * @param {string} vehicleId - id of vehicle to unlink
     * @returns {Promise<Vehicle>} vehicle unlinked
     */
    async unlinkVehicleFromSite(vehicleId: string): Promise<Vehicle> {
        const res = await this.delete(`/api/vehicles/associate-site?vehicle=${vehicleId}`);
        return res.data;
    }

    /**
     * Get all vehicles fluids types available
     * @returns {Promise<string[]>}
     */
    async getFuelTypes(): Promise<string[]> {
        return ['diesel', 'gasoline'];
    }

    /**
     * Get all vehicles categories
     * @returns {Promise<Attribute[]>}
     */
    async getCategories(): Promise<Attribute[]> {
        if (!this.categories) {
            const param = { p: 'categories' };
            const res = await this.get('/api/vehicles/properties', null, param);
            this.categories = res.data;
        }
        return this.categories;
    }

    /**
     * Get all vehicles makes
     * @returns {Promise<Attribute[]>}
     */
    async getMakes(): Promise<Attribute[]> {
        if (!this.makes) {
            const param = { p: 'makes' };
            const res = await this.get('/api/vehicles/properties', null, param);
            this.makes = res.data;
        }
        return this.makes;
    }

    /**
     * Get all vehicles models for a given make
     * @param {string} makeId - id of make to get model from
     * @returns {Promise<Attribute[]>}
     */
    async getModels(makeId: string): Promise<Attribute[]> {
        const param = { p: 'models', parent: makeId };
        const res = await this.get('/api/vehicles/properties', null, param);
        return res.data;
    }

    /**
     * Get vehicle CO2 equivalent emissions per month with vehicles in, incomings and outgoings.
     * @param {string} companyId - company id to get data from
     * @param {*} filters - filter data to get
     * @returns {Promise<CO2eEmission[]>}
     */
    async getVehiclesCO2e(companyId: string, filters: any): Promise<CO2eEmission[]> {
        const data: Params = Object.assign({}, filters);
        if (companyId) {
            data.companyId = companyId;
        }
        const res = await this.get('/api/vehicles/emissions', null, data);
        return res.data;
    }

    /**
     * Get the list of attributes found for a specific property among given companies' vehicles.
     * @param {string[]} companies - List of companies ids
     * @param {string} property - name of the attribute to look for
     * @returns {Promise<Attribute[]}> list of attributes found for the property & companies
     */
    async getAttributesFromCompanies(companies: string[], property: string): Promise<Attribute[]> {
        const params = { companyIds: companies, p: property };
        const companyId = this.apiService.sessionService.getCompanyId();
        const res = await this.get(`/api/vehicles/properties/${companyId}`, null, params);
        return res.data;
    }
}
