import { Component, Input, OnInit } from '@angular/core';
import { TileSize, TilesService } from 'app/shared/services/tiles/tiles.service';

@Component({
    selector: 'data-tile-1',
    templateUrl: './data-tile-1.component.html',
    styleUrls: ['./data-tile-1.component.scss'],
    providers: [],
})
export class DataTile1Component implements OnInit {
    @Input() label: string;
    @Input() unit: string;
    @Input() value: number;
    @Input() value2: number;
    @Input() valueHTML: string;
    @Input() type: number;
    @Input() mainColor: string;
    @Input() secondaryColor: string;
    @Input() info: string;

    /**
     * Define a size for the tile in order to define a height
     */
    @Input() size: TileSize = 'large';

    constructor(private tilesService: TilesService) {}
    ngOnInit() {}

    /**
     * Get formated value to display in tile
     * @param {number} value
     * @returns {string}
     */
    getFormatedValue(value: number): string {
        return this.tilesService.getNumberToDisplay(value, this.unit);
    }
}
