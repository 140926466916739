import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureToggleService } from 'app/shared/services/feature-toggle/feature-toggle.service';
import { TitleService } from 'app/shared/services/title/title.service';
import { LinkItem, SubItem } from './left-navbar.interface';

@Component({
    selector: 'app-left-navbar',
    templateUrl: './left-navbar.component.html',
    styleUrls: ['./left-navbar.component.scss'],
})
export class LeftNavbarComponent implements OnInit {
    /**
     * Dropdown active name
     */
    private dropdownActive: string = null;

    /**
     * Links to display in navbar
     */
    public links: LinkItem[] = [
        {
            name: 'Accueil',
            href: '/accueil',
            dropdown: '',
            dropdownLi: [],
            color: 'accueil',
            available: true,
        },
        {
            name: 'Cartographie des sites',
            href: '/cartographie',
            dropdown: '',
            dropdownLi: [],
            color: 'cartographie',
            available: true,
        },
        {
            name: "Gestion de l'énergie",
            href: '/energie',
            color: 'energie',
            dropdown: 'dropdown-li',
            available: true,
            dropdownLi: [
                {
                    name: 'Données des sites',
                    href: '/pdl',
                    parent: 'energie',
                    available: true,
                },
                {
                    name: 'Suivi temporel',
                    href: '/suivi',
                    parent: 'energie',
                    available: true,
                },
                {
                    name: 'Répartition des flux',
                    href: '/repartition',
                    parent: 'energie',
                    available: true,
                },
                {
                    name: 'Classement des sites',
                    href: '/classement',
                    parent: 'energie',
                    available: true,
                },
                {
                    name: 'Courbes de charge',
                    href: '/courbes-de-charge',
                    parent: 'energie',
                    available: true,
                },
            ],
        },
        {
            name: "Gestion de l'eau",
            href: '/eau',
            color: 'eau',
            dropdown: 'dropdown-li',
            available: true,
            dropdownLi: [
                {
                    name: 'Données des sites',
                    href: '/pdl',
                    parent: 'eau',
                    available: true,
                },
                {
                    name: 'Suivi temporel',
                    href: '/suivi',
                    parent: 'eau',
                    available: true,
                },
                {
                    name: 'Classement des sites',
                    href: '/classement',
                    parent: 'eau',
                    available: true,
                },
            ],
        },
        {
            name: 'Gestion des véhicules',
            href: '/vehicules',
            color: 'vehicules',
            dropdown: 'dropdown-li',
            available: true,
            dropdownLi: [
                {
                    name: 'Données des véhicules',
                    href: '/flotte',
                    parent: 'vehicules',
                    available: true,
                },
                {
                    name: 'Suivi des consommations',
                    href: '/suivi',
                    parent: 'vehicules',
                    available: true,
                },
                {
                    name: 'Suivi du parc',
                    href: '/parc',
                    parent: 'vehicules',
                    available: true,
                },
            ],
        },
        {
            name: 'Analyse des données',
            href: '/analyse',
            dropdown: 'dropdown-li',
            available: true,
            dropdownLi: [
                {
                    name: 'Croisement des données',
                    href: '/croisement',
                    parent: 'analyse',
                    available: true,
                },
                {
                    name: 'Comparaison temporelle',
                    href: '/comparaison',
                    parent: 'analyse',
                    available: true,
                },
            ],
            color: 'analyse',
        },
        {
            name: 'Cibles',
            href: '/cibles',
            dropdown: 'dropdown-li',
            dropdownLi: [
                {
                    name: 'Campagnes',
                    href: '/campagnes',
                    parent: 'cibles',
                    available: true,
                },
                {
                    name: "Suivis d'exploitation",
                    href: '/suivis-exploitation',
                    parent: 'cibles',
                    available: true,
                    featureKey: 'operating-monitoring.feature',
                },
            ],
            available: true,
            color: 'cibles',
        },
        {
            name: 'Gestion des documents',
            href: '/documents',
            dropdown: '',
            dropdownLi: [],
            available: true,
            color: 'documents',
        },
    ];

    constructor(
        private router: Router,
        private titleService: TitleService,
        private featureToggleService: FeatureToggleService
    ) {}

    ngOnInit() {
        // Check and set feature for each item of the navbar
        this.links.forEach(link => {
            this.checkAndSetFeature(link);
            link.dropdownLi.forEach(subItem => {
                this.checkAndSetFeature(subItem);
            });
        });
    }

    /**
     * Check and set availability based on feature
     * @param link - link item or subitem
     */
    private checkAndSetFeature(link: LinkItem | SubItem) {
        if (link.featureKey && link.available) {
            link.available = this.featureToggleService.isEnabled(link.featureKey);
        }
    }

    setDropdown(element: string) {
        this.dropdownActive = this.dropdownActive === element ? null : element;
    }

    getDropdown(element: string): boolean {
        return this.dropdownActive !== element;
    }

    ifDropdownOpen(element: string): string {
        if (this.dropdownActive !== element) {
            return '';
        }

        return 'open-dropdown';
    }

    getLinkClasses(link: LinkItem): string[] {
        return [
            link.color,
            link.dropdown,
            this.getActive(link.name),
            this.ifDropdownOpen(link.name),
            this.getAvailableClass(link),
        ];
    }

    getAvailableClass(link: LinkItem): string {
        if (!link.available) {
            return 'li-inactive';
        }

        return '';
    }

    getActive(element: string): string {
        if (this.titleService.getTitle(this.router.url) === element) {
            return 'active';
        }

        return '';
    }

    linkClicked(element: string) {
        // For now, when a link is clicked, just close active dropdown.
        // For sub menus, pass the parent dropdown name, to be sure it's expanded.
        if (element) {
            this.dropdownActive = element;
        } else {
            this.dropdownActive = null;
        }
    }
}
