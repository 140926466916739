import { Component, Input, OnInit } from '@angular/core';
import { materialTypes } from '../../../constants/material-types.constants';

@Component({
    selector: 'ga-sites-add-material',
    templateUrl: './add-material.component.html',
    styleUrls: ['./add-material.component.scss'],
})
export class SitesAddMaterialComponent implements OnInit {
    systemCategories = [
        {
            text: 'Il existe une production de chauffage',
            value: materialTypes.HEAT,
        },
        {
            text: 'Il existe une production de froid',
            value: materialTypes.COLD,
        },
        {
            text: 'Emission',
            value: materialTypes.EMISSION,
        },
    ];

    materialForms: any = {
        [materialTypes.HEAT]: {},
        [materialTypes.COLD]: {},
        [materialTypes.EMISSION]: {},
    };

    @Input() element: any = {};
    @Input() energies: any[] = [];
    @Input() emissionTypes: any[] = [];

    constructor() {}
    ngOnInit() {}

    addMaterial(type) {
        // add material in site info
        this.element[type].info.push(this.materialForms[type]);
        // reset form
        this.materialForms[type] = {};
    }

    removeMaterial(type, index) {
        this.element[type].info.splice(index, 1);
    }

    IsEmission(type): boolean {
        return type === materialTypes.EMISSION;
    }
}
