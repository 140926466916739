import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminUserService } from 'app/pages/admin/user/user.service';
import { EngineerService } from 'app/shared/services/users/engineer.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-user-engineer-tab',
    templateUrl: './user-engineer.component.html',
    styleUrls: ['./user-engineer.component.scss'],
    providers: [AdminUserService, Location],
})
export class UserEngineerComponent implements OnInit {
    @Input() user: any = null;
    @Input() canEdit = false;
    @Input() createdFromCompanyId: string = null;

    engineer: any = null;

    isEditing = false;

    constructor(private router: Router, private engineerService: EngineerService) {}

    ngOnInit() {
        this.initData();
    }

    initData() {
        this.setEngineer().then(
            engineer => {
                // engineer = {} if it's new and not saved yet
                if (engineer && engineer.hasOwnProperty('_id')) {
                    this.engineer = engineer;
                    this.isEditing = true;
                } else {
                    this.engineer = {
                        specialty: '',
                        status: '',
                        localisation: '',
                        experience: '',
                        technicalCompetences: [''],
                        certifications: [''],
                        citation: '',
                    };
                }
            },
            err => {
                // err.error_code is set to error_loadEngineer
                this.getSwalMsg(err.error_code);
                return this.router.navigateByUrl('/admin/utilisateur/' + this.user._id);
            }
        );
    }

    // ******** INIT ENGINEER *****
    setEngineer(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.engineerService.getEngineerByUserId(this.user._id).then(
                engineer => {
                    return resolve(engineer);
                },
                err => {
                    // err.error_code is set to error_loadEngineer
                    return reject(err);
                }
            );
        });
    }

    //  ******* SAVE ENGINEER *****

    // on click on 'Save'
    saveEngineerProfile() {
        if (this.isEngineerValid()) {
            this.saveEngineer().then(
                () => {
                    this.getSwalMsg('engineer_saved');
                },
                err => {
                    // err.error_code is set to error_createNewEngineer or error_updateEngineer
                    this.getSwalMsg(err.error_code);
                    this.router.navigateByUrl('/admin/utilisateur/' + this.user._id);
                }
            );
        }
    }

    // check if the form info are valid before saving
    isEngineerValid(): boolean {
        // the fields can't be empty
        if (this.hasEmptyFields()) {
            this.getSwalMsg('error_empty_field');
            return false;
        } else if (!this.checkFieldsTypes()) {
            this.getSwalMsg('error_type_experience');
        }
        return true;
    }

    // check if some fields are empty before saving
    hasEmptyFields() {
        return (
            this.engineer.specialty === '' ||
            this.engineer.status === '' ||
            this.engineer.localisation === '' ||
            this.engineer.experience === '' ||
            this.engineer.technicalCompetences.includes('') ||
            this.engineer.certifications.includes('') ||
            !this.engineer.citation
        );
    }

    checkFieldsTypes() {
        // return (typeof this.engineer.experience) === 'Number';
        return this.engineer.experience instanceof Number;
    }

    // sweet alert
    getSwalMsg(msg_code) {
        const messages = {
            error_empty_field: ['Attention', "L'un des champs n'est pas renseigné.", 'warning'],
            engineer_saved: ['Sauvegardé', "L'utilisateur a bien été sauvegardé", 'success'],
            error_createNewEngineer: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement du profil ingénieur",
                'error',
            ],
            error_updateNewEngineer: [
                'Toutes nos excuses',
                "Une erreur est survenue pendant l'enregistrement du profil ingénieur",
                'error',
            ],
            error_type_experience: ['Attention', 'Le champs experience doit être un chiffre.', 'warning'],
            error_loadEngineer: [
                'Toutes nos exuses',
                "Une erreur s'est produite lors du chargement de l'utilisateur",
                'error',
            ],
        };

        Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
    }

    // create or update the new user in db
    saveEngineer(): Promise<any> {
        if (this.isEditing) {
            return this.engineerService.updateEngineer(this.engineer).then(
                () => {
                    return Promise.resolve();
                },
                err => {
                    // err.error_code is set to error_updateEngineer
                    return Promise.reject(err);
                }
            );
        } else {
            this.engineer.user = this.user._id;
            return this.engineerService.createEngineer(this.engineer).then(
                () => {
                    this.isEditing = true;
                    return Promise.resolve();
                },
                err => {
                    // err.error_code is set to error_createNewEngineer
                    return Promise.reject(err);
                }
            );
        }
    }

    // Save profile button label
    getSaveBtnLabel() {
        return this.isEditing ? 'Mettre à jour' : 'Créer';
    }

    addTechnicalCompetence() {
        this.engineer.technicalCompetences.push('');
    }
    addCertification() {
        this.engineer.certifications.push('');
    }

    removeTechnicalCompetence(index) {
        this.engineer.technicalCompetences.splice(index, 1);
    }
    removeCertification(index) {
        this.engineer.certifications.splice(index, 1);
    }

    trackListElement(index: number, obj: any): any {
        return index;
    }

    navigateToCompanyTabUsers() {
        const route = '/admin/entreprise/' + this.createdFromCompanyId + '/energy-managers';
        this.router.navigateByUrl(route);
    }
}
