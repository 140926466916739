import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import Swal from 'sweetalert2';

// Models/interfaces
import { DatePeriod, WaterMonthlyBill, WaterYearlyBill } from 'app/shared/models/fluid-bills.interface';
import { WaterMonthlyCostTableBill } from 'app/shared/models/fluid-tables.interface';

// Services
import { ElecPdlViewService } from 'app/pages/energy/pdl-view/elec/elec.service';
import { SitesListService } from 'app/pages/energy/sites-list/sites-list.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { ColorService } from 'app/shared/services/color/color.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

interface Loading {
    chartLine: boolean;
    secondChartLine: boolean;
    chartDonut: boolean;
    dataPerMonth: boolean;
    ratioTable: boolean;
    site: boolean;
}

@Component({
    selector: 'ga-water-pdl-view',
    templateUrl: './water.component.html',
    styleUrls: ['./water.component.scss'],
    providers: [ElecPdlViewService, SitesListService],
})
export class WaterPdlViewComponent implements OnInit {
    totalTTC = 0;
    totalHT = 0;

    totalConsoTilesProperties: any = {
        mainColor: '',
        secondaryColor: '',
        value: '',
        label: 'Consommation totale',
        unit: 'm³',
        type: 'water',
    };

    totalCostTilesProperties: any = {
        mainColor: '',
        secondaryColor: '',
        value: '',
        label: 'Dépense totale',
        unit: '',
        type: 'cost',
    };

    loading: Loading = {
        chartLine: false,
        secondChartLine: false,
        chartDonut: false,
        dataPerMonth: false,
        ratioTable: false,
        site: true,
    };

    /** Default config for conso line chart */
    private get defaultConfigConsoLineChart(): any {
        return {
            data: [],
            dataset: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: {},
            title: 'Consommation du PDL',
        };
    }
    /** Conso line chart properties */
    public lineChartProperties: any = this.defaultConfigConsoLineChart;

    /** Default config for costs line chart */
    private get defaultConfigCostsLineChart(): any {
        return {
            data: [],
            dataset: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: {},
            title: 'Coût de la consommation du PDL',
        };
    }
    /** Costs line chart properties */
    public secondLineChartProperties: any = this.defaultConfigCostsLineChart;

    // doughnut chart
    doughnutCostsProperties: any = {
        data: [],
        legend: [],
        title: 'Répartition des coûts',
    };

    montDataTable: WaterMonthlyCostTableBill[] = [];
    years: any[];
    contract = '';
    routingRef = '';
    site: any;
    data: any;
    selectedContract: any;
    consoData: any;
    costData: any;
    ratioTable = [];
    energyType = 'water';

    periodSelected: DatePeriod = { dateStart: null, dateEnd: null };

    constructor(
        private chartService: ChartService,
        private sessionService: SessionService,
        private energyPdlViewService: ElecPdlViewService,
        private siteService: SitesService,
        private route: ActivatedRoute,
        private colorService: ColorService,
        private utilsService: UtilsService,
        private tilesService: TilesService,
        private pdlService: SitesListService
    ) {}

    ngOnInit() {
        this.totalConsoTilesProperties.mainColor = this.chartService.getBarChartColorsTransparent([
            'total_conso_water',
        ])[0].backgroundColor;
        this.totalConsoTilesProperties.secondaryColor = this.colorService.getHexColor('BLUE');
        this.totalCostTilesProperties.mainColor = this.chartService.getBarChartColorsTransparent([
            'total_conso_water',
        ])[0].backgroundColor;
        this.totalCostTilesProperties.secondaryColor = this.colorService.getHexColor('BLUE');
        this.getSiteFromParams();
    }

    /**
     * Set all charts/tables loading
     * @param {boolean} loading - true to set as loading, false to set as not loading
     */
    private setAllLoadings(loading: boolean = true) {
        this.loading.chartLine = loading;
        this.loading.secondChartLine = loading;
        this.loading.chartDonut = loading;
        this.loading.dataPerMonth = loading;
        this.loading.ratioTable = loading;
    }

    resetAll() {
        this.lineChartProperties = this.defaultConfigConsoLineChart;
        this.secondLineChartProperties = this.defaultConfigCostsLineChart;

        this.doughnutCostsProperties = {
            data: [],
            legend: [],
            title: 'Répartition des coûts',
        };

        this.montDataTable = [];

        this.ratioTable = [];
    }

    /**
     * Called when the date has changed.
     * @param {DatePeriod} period
     */
    async onPeriodChanged(period: DatePeriod) {
        if (
            (this.periodSelected && period && period.dateStart !== this.periodSelected.dateStart) ||
            period.dateEnd !== this.periodSelected.dateEnd
        ) {
            this.periodSelected = period;
            await this.grabDataAndDisplay();
        }
    }

    /**
     * Grab data, format it, and set it for every component.
     */
    async grabDataAndDisplay(): Promise<void> {
        this.resetAll();

        const [monthlyConsumption, yearlyConsumptionForTable, globalConsumptionForDonut]: [
            WaterMonthlyBill[],
            WaterYearlyBill[],
            WaterYearlyBill[]
        ] = await Promise.all([
            this.getRfMonthlyConsumption(),
            this.getRfYearlyConsumptionWithHistoric(),
            this.getRfGlobalConsumptionForPeriod(),
        ]);

        this.handleMonthData(monthlyConsumption); // both charts & table of evolution per month
        this.handleRatioTable(yearlyConsumptionForTable);
        this.handleCostsDoughnut(globalConsumptionForDonut);
    }

    getSiteFromParams(): void {
        if (this.route.params) {
            this.route.params.subscribe(async params => {
                try {
                    this.setAllLoadings(true);
                    this.contract = params.contractId;
                    this.routingRef = params.rfId;

                    const years = await this.energyPdlViewService.getYears(
                        this.contract,
                        this.routingRef,
                        this.energyType
                    );

                    if (years && years.data && years.data.length) {
                        this.years = years.data;
                        this.years.sort((a, b) => b - a);
                    }

                    const routingReference = await this.energyPdlViewService.getRoutingReferenceById(this.routingRef);

                    this.data = routingReference.data;
                    this.selectedContract = this.data.contracts.find(contract => contract._id === this.contract);

                    const site = await this.siteService.getSiteFromRoutingReference(this.data._id, this.data.company);

                    this.site = site && site._id ? site : null;
                    this.loading.site = false;
                    return;
                } catch (error) {
                    this.years = this.years ? this.years : [];
                    this.periodSelected = this.periodSelected
                        ? this.periodSelected
                        : { dateStart: null, dateEnd: null };
                    this.data = this.data ? this.data : null;
                    this.selectedContract = this.selectedContract ? this.selectedContract : null;
                    this.site = null;
                    this.loading.site = false;
                    const msg = this.data ? 'du site' : 'de votre PDL';
                    Swal.fire('Toutes nos excuses', "Une erreur s'est produite lors du chargement " + msg, 'error');
                }
            });
        }
    }

    /**
     * Populate the ratio table from yearly bills data
     * @param {WaterYearlyBill[]} dataPerYear
     */
    setRatioTable(dataPerYear: WaterYearlyBill[]) {
        this.ratioTable = [];
        dataPerYear.forEach(yd => {
            const quantity = yd.productionAndDistribution.quantity;

            this.ratioTable.push({
                periodLabel: yd.year,
                productionAndDistribution: {
                    subscription: this.formatNumberDefaultToNC((yd.subscription.amount / quantity) * 100, 'money'),
                    consumption: this.formatNumberDefaultToNC(
                        (yd.productionAndDistribution.amount / quantity) * 100,
                        'money'
                    ),
                },
                wasteWaterTreatment: {
                    value: this.formatNumberDefaultToNC((yd.wasteWaterTreatment.amount / quantity) * 100, 'money'),
                },
                publicOrganismAndVAT: {
                    value: this.formatNumberDefaultToNC((yd.publicOrganismAndVAT.amount / quantity) * 100, 'money'),
                },
                totalHT: { value: this.formatNumberDefaultToNC((yd.totalHT.amount / quantity) * 100, 'money') },
                totalTTC: { value: this.formatNumberDefaultToNC((yd.totalTTC.amount / quantity) * 100, 'money') },
            });
        });
    }

    getTotalConso() {
        return Number(this.consoData[0].data.reduce((a, b) => Number(a) + Number(b)));
    }

    getTotalCost() {
        const totalHT = this.tilesService.getNumberToDisplay(this.totalHT, '€');
        const totalTTC = this.tilesService.getNumberToDisplay(this.totalTTC, '€');
        return `<b>${totalHT}</b> € HT - <b>${totalTTC}</b> € TTC`;
    }

    /**
     * Set in storage the current site in last visited sites for dashboard display
     */
    setLastVisitedSites(site) {
        this.sessionService.setLastVisitedSites(site);
    }

    /**
     * Returns the months data matching the selected period, aggregated per month.
     *
     * @return {Promise<WaterMonthlyBill[]> }
     * [{_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-01-31T23:59:59.999Z", month: 0, year: 2018, …},
     * {_id: {…}, dateStart: "2018-02-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 1, year: 2018, …},
     * {_id: {…}, dateStart: "2018-03-01T00:00:00.000Z", dateEnd: "2018-03-31T23:59:59.999Z", month: 2, year: 2018, …},
     * {_id: {…}, dateStart: "2018-04-01T00:00:00.000Z", dateEnd: "2018-04-05T00:00:00.000Z", month: 3, year: 2018, …}]
     */
    async getRfMonthlyConsumption(): Promise<WaterMonthlyBill[]> {
        try {
            this.loading.chartLine = true;
            this.loading.secondChartLine = true;
            this.loading.dataPerMonth = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                'water',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                false
            );

            return response.data;
        } catch (error) {
            this.loading.chartLine = false;
            this.loading.secondChartLine = false;
            this.loading.dataPerMonth = false;
            return [];
        }
    }

    /**
     * Returns the months data matching the selected period, aggregated per year. Asks for 2 years historic to populate the ratio table.
     *
     * @return {Promise<WaterYearlyBill[]>}
     * [{_id: {…}, dateStart: "2016-01-01T00:00:00.000Z", dateEnd: "2016-12-31T23:59:59.999Z", month: 11, year: 2016, …},
     *  {_id: {…}, dateStart: "2017-01-01T00:00:00.000Z", dateEnd: "2017-12-31T23:59:59.999Z", month: 6, year: 2017, …},
     *  {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-04-05T00:00:00.000Z", month: 2, year: 2018, …}]
     */
    async getRfYearlyConsumptionWithHistoric(): Promise<WaterYearlyBill[]> {
        try {
            this.loading.chartDonut = true;
            this.loading.ratioTable = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('year')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('year')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                'water',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                true,
                2
            );
            return response.data;
        } catch (error) {
            this.loading.chartDonut = false;
            this.loading.ratioTable = false;
            return [];
        }
    }

    /**
     * Returns the months data matching the selected period, aggregated per year.
     *
     * @return {Promise<WaterYearlyBill[]>}
     * [{_id: {…}, dateStart: "2017-06-20T00:00:00.000Z", dateEnd: "2017-12-31T23:59:59.999Z", month: 6, year: 2017, …},
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 0, year: 2018, …}]
     */
    async getRfGlobalConsumptionForPeriod(): Promise<WaterYearlyBill[]> {
        try {
            this.loading.chartDonut = true;
            this.loading.ratioTable = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                'water',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                true
            );
            return response.data;
        } catch (error) {
            this.loading.chartDonut = false;
            this.loading.ratioTable = false;
            return [];
        }
    }

    reinitTotals() {
        this.totalHT = 0;
        this.totalTTC = 0;
    }

    /**
     * Populate the tile of costs with the sum of totalHT totalTTC
     *
     * @param {WaterMonthlyBill[]}
     * monthsData : [
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-01-31T23:59:59.999Z", month: 0, year: 2018, …},
     * {_id: {…}, dateStart: "2018-02-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 1, year: 2018, …},
     * {_id: {…}, dateStart: "2018-03-01T00:00:00.000Z", dateEnd: "2018-03-31T23:59:59.999Z", month: 2, year: 2018, …},
     * {_id: {…}, dateStart: "2018-04-01T00:00:00.000Z", dateEnd: "2018-04-05T00:00:00.000Z", month: 3, year: 2018, …}]
     */
    setCostsTotalsFromMonthData(monthsData: WaterMonthlyBill[]): void {
        this.reinitTotals();

        monthsData.forEach(monthData => {
            this.totalHT += monthData.totalHT.amount;
            this.totalTTC += monthData.totalTTC.amount;
        });
    }

    /**
     * Use months data to populate the tiles total costs, both line charts &  the cost evolution table
     * @param {WaterMonthlyBill[]} monthData: [
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-01-31T23:59:59.999Z", month: 0, year: 2018, …},
     * {_id: {…}, dateStart: "2018-02-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 1, year: 2018, …},
     * {_id: {…}, dateStart: "2018-03-01T00:00:00.000Z", dateEnd: "2018-03-31T23:59:59.999Z", month: 2, year: 2018, …},
     * {_id: {…}, dateStart: "2018-04-01T00:00:00.000Z", dateEnd: "2018-04-05T00:00:00.000Z", month: 3, year: 2018, …}]
     */
    handleMonthData(monthData: WaterMonthlyBill[]): void {
        if (monthData.length) {
            this.setCostsTotalsFromMonthData(monthData); // populate the costs tile  TTC & HTVA
            this.setConsoLineChartData(monthData);
            this.setCostsLineChartData(monthData);
            this.setMonthDataTable(monthData);
        }

        this.loading.chartLine = false;
        this.loading.secondChartLine = false;
        this.loading.dataPerMonth = false;
    }

    /**
     * Use the month data to populate the ratio table & the donut
     * @param {WaterYearlyBill[]} monthData
     */
    handleCostsDoughnut(monthsData: WaterYearlyBill[]): void {
        if (monthsData.length) {
            this.setCostsDoughnut(monthsData);
        }
        this.loading.chartDonut = false;
    }

    /**
     * Populate the ratio table.
     * @param {WaterYearlyBill[]} dataPerYear
     * [{_id: {…}, dateStart: "2017-06-20T00:00:00.000Z", dateEnd: "2017-12-31T23:59:59.999Z", month: 6, year: 2017, …},
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 0, year: 2018, …}]
     */
    handleRatioTable(dataPerYear: WaterYearlyBill[]): void {
        if (dataPerYear.length) {
            this.setRatioTable(dataPerYear);
        }
        this.loading.ratioTable = false;
    }

    /**
     * Populate donut data and properties from monthsdata aggregated per year.
     * @param {WaterYearlyBill[]} dataPerYear
     * [{_id: {…}, dateStart: "2017-06-20T00:00:00.000Z", dateEnd: "2017-12-31T23:59:59.999Z", month: 6, year: 2017, …},
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 0, year: 2018, …}]
     */
    setCostsDoughnut(dataPerYear: WaterYearlyBill[]): void {
        let publicOrganismAndVAT = 0;
        let wasteWaterTreatment = 0;
        let productionAndDistribution = 0;
        let subscription = 0;
        if (dataPerYear && dataPerYear.length) {
            dataPerYear.forEach(data => {
                publicOrganismAndVAT += Number(data.publicOrganismAndVAT.amount);
                wasteWaterTreatment += Number(data.wasteWaterTreatment.amount);
                productionAndDistribution += Number(data.productionAndDistribution.amount);
                subscription += Number(data.subscription.amount);
            });
        }

        this.doughnutCostsProperties = {
            data: [],
            legend: [],
            title: 'Répartition des coûts',
        };
        this.doughnutCostsProperties.legend = this.chartService.getChartLegends([
            'water_subscription',
            'water_productionAndDistribution',
            'water_wasteWaterTreatment',
            'water_publicOrganismAndVAT',
        ]);

        const dataForChart = [
            Math.round(subscription * 100) / 100,
            Math.round(productionAndDistribution * 100) / 100,
            Math.round(wasteWaterTreatment * 100) / 100,
            Math.round(publicOrganismAndVAT * 100) / 100,
        ];
        this.doughnutCostsProperties.data = this.chartService.getHighchartDonutConfig(
            dataForChart,
            [
                'water_subscription',
                'water_productionAndDistribution',
                'water_wasteWaterTreatment',
                'water_publicOrganismAndVAT',
            ],
            '€'
        );
    }

    /**
     * Use month data to populate conso line chart.
     * @param {WaterMonthlyBill[]} data: [
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-01-31T23:59:59.999Z", month: 0, year: 2018, …},
     * {_id: {…}, dateStart: "2018-02-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 1, year: 2018, …},
     * {_id: {…}, dateStart: "2018-03-01T00:00:00.000Z", dateEnd: "2018-03-31T23:59:59.999Z", month: 2, year: 2018, …},
     * {_id: {…}, dateStart: "2018-04-01T00:00:00.000Z", dateEnd: "2018-04-05T00:00:00.000Z", month: 3, year: 2018, …}]
     */
    setConsoLineChartData(data: WaterMonthlyBill[]): void {
        const arrayData = [];
        const monthLabels = [];

        data.forEach(x => {
            arrayData.push(x.productionAndDistribution.quantity);
            monthLabels.push(
                moment()
                    .month(x.month)
                    .year(x.year)
                    .format('MM/YYYY')
            );
        });

        this.consoData = [
            {
                label: 'Consommation totale du PDL',
                data: arrayData,
            },
        ];

        this.lineChartProperties.data = this.consoData;
        this.lineChartProperties.dataset = this.consoData;
        this.lineChartProperties.labels = monthLabels;
        this.lineChartProperties.options = this.chartService.getConfig('bar', {
            tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line', 'm³'),
        });
        this.lineChartProperties.legend = this.chartService.getChartLegends(['total_conso_water']);
        this.lineChartProperties.colors = this.chartService.getBarChartColorsTransparent(['total_conso_water']);
    }

    /**
     * Use month data to populate costs line chart.
     * @param {WaterMonthlyBill[]} data: [
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-01-31T23:59:59.999Z", month: 0, year: 2018, …},
     * {_id: {…}, dateStart: "2018-02-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 1, year: 2018, …},
     * {_id: {…}, dateStart: "2018-03-01T00:00:00.000Z", dateEnd: "2018-03-31T23:59:59.999Z", month: 2, year: 2018, …},
     * {_id: {…}, dateStart: "2018-04-01T00:00:00.000Z", dateEnd: "2018-04-05T00:00:00.000Z", month: 3, year: 2018, …}]
     */
    setCostsLineChartData(data: WaterMonthlyBill[]): void {
        const arrayData = [];
        const monthLabels = [];

        data.forEach(x => {
            arrayData.push(x.productionAndDistribution.amount);
            monthLabels.push(
                moment()
                    .month(x.month)
                    .year(x.year)
                    .format('MM/YYYY')
            );
        });

        this.costData = [
            {
                label: 'Total des coûts',
                data: arrayData,
            },
        ];

        this.secondLineChartProperties.data = this.costData;
        this.secondLineChartProperties.dataset = this.costData;
        this.secondLineChartProperties.labels = monthLabels;
        this.secondLineChartProperties.options = this.chartService.getConfig('bar', {
            tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line', '€'),
        });
        this.secondLineChartProperties.legend = this.chartService.getChartLegends(['total_cost_water']);
        this.secondLineChartProperties.colors = this.chartService.getBarChartColorsTransparent(['total_cost_water']);
    }

    /**
     * Use month data to populate the table with the evolution of costs per month.
     * @param {WaterMonthlyBill[]} data: [
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-01-31T23:59:59.999Z", month: 0, year: 2018, …},
     * {_id: {…}, dateStart: "2018-02-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 1, year: 2018, …},
     * {_id: {…}, dateStart: "2018-03-01T00:00:00.000Z", dateEnd: "2018-03-31T23:59:59.999Z", month: 2, year: 2018, …},
     * {_id: {…}, dateStart: "2018-04-01T00:00:00.000Z", dateEnd: "2018-04-05T00:00:00.000Z", month: 3, year: 2018, …}]
     */
    setMonthDataTable(dataPerMonth: WaterMonthlyBill[]): void {
        this.montDataTable = [];

        dataPerMonth.forEach(md => {
            this.montDataTable.push({
                periodLabel: moment()
                    .month(md.month)
                    .year(md.year)
                    .format('MM/YYYY'),
                consumption: {
                    conso: this.formatNumberDefaultToNC(md.productionAndDistribution.amount, 'money'),
                    quantity: this.formatNumberDefaultToNC(md.productionAndDistribution.quantity),
                },
                wasteWaterTreatment: { value: this.formatNumberDefaultToNC(md.wasteWaterTreatment.amount, 'money') },
                publicOrganismAndVAT: { value: this.formatNumberDefaultToNC(md.publicOrganismAndVAT.amount, 'money') },
                subscription: { value: this.formatNumberDefaultToNC(md.subscription.amount, 'money') },
                totalHT: { total: this.formatNumberDefaultToNC(md.totalHT.amount, 'money') },
                totalTTC: { total: this.formatNumberDefaultToNC(md.totalTTC.amount, 'money') },
            });
        });
    }

    formatNumberDefaultToNC(value, type = 'fluid') {
        return this.utilsService.formateNumberType(value, 'NC', type);
    }

    siteLoaded() {
        return this.data && this.data._id && !this.loading.site;
    }

    canShowSecondLineChart(): boolean {
        return Boolean(!this.loading.secondChartLine && this.secondLineChartProperties.dataset.length);
    }

    canShowLineChart(): boolean {
        return Boolean(!this.loading.chartLine && this.lineChartProperties.dataset.length);
    }

    canShowDataPerMonth(): boolean {
        return Boolean(!this.loading.dataPerMonth && this.montDataTable);
    }

    canShowRatioTable(): boolean {
        return Boolean(!this.loading.ratioTable && this.ratioTable);
    }

    public hasDataForCostDoughnut(): boolean {
        try {
            const chartOptions = this.doughnutCostsProperties.data;
            return Boolean(
                (chartOptions &&
                    chartOptions.series[0].data &&
                    chartOptions.series[0].data[0] &&
                    chartOptions.series[0].data[1] &&
                    chartOptions.series[0].data[2] &&
                    (chartOptions.series[0].data[0].y > 0 ||
                        chartOptions.series[0].data[1].y > 0 ||
                        chartOptions.series[0].data[2].y > 0)) ||
                    (chartOptions.series[0].data[3].y > 0 || chartOptions.series[0].data[4].y > 0)
            );
        } catch (err) {
            return false;
        }
    }

    getExportExcelUrl(type) {
        return this.pdlService.getExportExcelUrl(
            type,
            this.periodSelected,
            this.routingRef,
            this.contract,
            false,
            'water'
        );
    }

    getExportName(txt) {
        return this.pdlService.getExportName(txt, this.periodSelected, this.data, this.selectedContract);
    }
}
