import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import Swal from 'sweetalert2';

// Routes
import { ActivatedRoute } from '@angular/router';

// Components
import { ChartBarToggleComponent } from 'app/shared/components/charts/chart-bar-toggle/chart-bar-toggle.component';

// Models/interfaces
import { FluidViewToggleChartProperties } from 'app/pages/energy/pdl-view/pdl-view.interface';
import { DonutProperties, LineChartPropertiesLight } from 'app/shared/models/charts/charts.interface';
import { Dju } from 'app/shared/models/dju.interface';
import { DatePeriod, GazMonthlyBill, GazYearlyBill } from 'app/shared/models/fluid-bills.interface';
import { GazMonthlyCostTableBill, YearlyRatioTableBill } from 'app/shared/models/fluid-tables.interface';

// Services
import { ElecPdlViewService } from 'app/pages/energy/pdl-view/elec/elec.service';
import { SitesListService } from 'app/pages/energy/sites-list/sites-list.service';
import { TileInterface } from 'app/shared/models/tile.interface';
import { BillsService } from 'app/shared/services/bills/bills.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { ColorService } from 'app/shared/services/color/color.service';
import { DjuService } from 'app/shared/services/dju/dju.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';

interface Loading {
    chartLine: boolean;
    secondChartLine: boolean;
    chartDonut: boolean;
    monthlyCostTable: boolean;
    ratioTable: boolean;
    site: boolean;
}

@Component({
    selector: 'ga-gas-pdl-view',
    templateUrl: './gas.component.html',
    styleUrls: ['./gas.component.scss'],
    providers: [ElecPdlViewService, SitesListService],
})
export class GasPdlViewComponent implements OnInit {
    totalTTC = 0;
    totalHT = 0;
    monthData: GazMonthlyBill[] = [];
    djus: any = [];

    consoGraph: any = {
        isDjuActivated: true,
    };

    loading: Loading = {
        chartLine: false,
        secondChartLine: false,
        chartDonut: false,
        monthlyCostTable: false,
        ratioTable: false,
        site: true,
    };

    /** Default config for conso bar chart */
    private get defaultConfigConsoBarChart(): FluidViewToggleChartProperties {
        return {
            title: 'Consommation du PDL',
            datasets: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: [],
            toggleTitles: [],
            downloadFileNames: ['Consommation du PDL'],
        };
    }
    /** Consumption bar chart properties */
    public consoBarChartProperties: FluidViewToggleChartProperties = this.defaultConfigConsoBarChart;

    @ViewChild('chartBarConso', { static: false }) chartBarConso: ChartBarToggleComponent;

    /** Default config for costs line chart */
    private get defaultConfigCostsLineChart(): LineChartPropertiesLight {
        return {
            title: 'Coût de la consommation du PDL',
            dataset: [],
            labels: [],
            colors: [],
            legend: [],
            width: 900,
            minHeight: null, // 'none'
            options: {},
        };
    }
    /** Costs line chart properties */
    public costsLineChartProperties: LineChartPropertiesLight = this.defaultConfigCostsLineChart;

    doughnutCostsProperties: DonutProperties = {
        options: [],
        legend: [],
        title: 'Répartition des coûts',
    };

    // monthly costs table
    monthlyCostTable: GazMonthlyCostTableBill[] = [];
    years: number[];
    contract = '';
    routingRef = '';
    site: any = null;
    data: any;
    selectedContract: any;
    consoData: any;
    costData: any;
    ratioTable: YearlyRatioTableBill[] = [];
    energyType = 'gaz';

    totalConsoTile: TileInterface = {
        mainColor: '',
        secondaryColor: '',
        label: 'Consommation totale',
        unit: 'kWh pcs',
        type: 'gaz',
    };

    totalCostTile: TileInterface = {
        mainColor: '',
        secondaryColor: '',
        label: 'Dépense totale',
        unit: '',
        type: 'cost',
    };

    periodSelected: DatePeriod = { dateStart: null, dateEnd: null };

    constructor(
        private chartService: ChartService,
        private sessionService: SessionService,
        private energyPdlViewService: ElecPdlViewService,
        private siteService: SitesService,
        private route: ActivatedRoute,
        private colorService: ColorService,
        private utilsService: UtilsService,
        private tilesService: TilesService,
        private djuService: DjuService,
        private pdlService: SitesListService,
        private billService: BillsService
    ) {}

    ngOnInit() {
        this.totalConsoTile.mainColor = this.colorService.getHexColor('SECOND_TURQUOISE');
        this.totalCostTile.mainColor = this.colorService.getHexColor('SECOND_TURQUOISE');
        this.totalConsoTile.secondaryColor = this.colorService.getHexColor('TURQUOISE');
        this.totalCostTile.secondaryColor = this.colorService.getHexColor('TURQUOISE');
        this.getSiteFromParams();
    }

    /**
     * Set all charts/tables loading
     * @param {boolean} loading - true to set as loading, false to set as not loading
     */
    private setAllLoadings(loading: boolean = true) {
        this.loading.chartDonut = loading;
        this.loading.chartLine = loading;
        this.loading.monthlyCostTable = loading;
        this.loading.ratioTable = loading;
        this.loading.secondChartLine = loading;
    }

    resetAll(): void {
        this.initConsoBarChartProperties();

        this.costsLineChartProperties = this.defaultConfigCostsLineChart;

        this.doughnutCostsProperties = {
            options: [],
            legend: [],
            title: 'Répartition des coûts',
        };

        this.monthlyCostTable = [];

        this.ratioTable = [];
    }

    /**
     * Called when period is changed from top site profile component
     * @param {Perdio} period
     */
    async onPeriodChanged(period: DatePeriod): Promise<void> {
        if (
            (this.periodSelected && period && period.dateStart !== this.periodSelected.dateStart) ||
            period.dateEnd !== this.periodSelected.dateEnd
        ) {
            this.periodSelected = period;
            await this.grabDataAndDisplay();
        }
    }

    /**
     * Aggregate bills values per month and per year and populate charts and tables.
     * @return {Promise<void>}
     */
    async grabDataAndDisplay(): Promise<void> {
        if (!this.periodSelected || !this.periodSelected.dateStart || !this.periodSelected.dateEnd) {
            return;
        }

        this.resetAll();

        const promiseArray: [Promise<GazMonthlyBill[]>, Promise<GazYearlyBill[]>, Promise<GazYearlyBill[]>] = [
            this.getRoutingReferenceMonthlyConsumption(),
            this.getRfYearlyConsumptionWithHistoric(),
            this.getRfYearlyConsumptionForPeriod(),
        ];

        const [monthlyConsumption, yearlyConsumption, globalConsumptionForDonut] = await Promise.all(promiseArray);

        this.handleMonthData(monthlyConsumption);
        this.handleRatioTable(yearlyConsumption);
        this.handleDonut(globalConsumptionForDonut);

        const djus: Dju[] | null = this.site ? await this.getDjusOnPeriod() : null;

        this.generateConsoGraph(this.consoGraph.isDjuActivated, djus);
    }

    /**
     * Get Dju array for the selected period from the nearest station from the current site.
     * @return {Promise<Dju[]>}
     */
    async getDjusOnPeriod(): Promise<Dju[]> {
        try {
            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            return this.djuService.getSiteDJUs(dateStart, dateEnd, this.site).catch(e => {
                if (e && e.errorCode && e.errorCode === 'not_geolocalised') {
                    return [];
                }
                return e;
            });
        } catch (error) {
            this.loading.chartLine = false;
            this.loading.secondChartLine = false;
            this.loading.monthlyCostTable = false;
            Swal.fire('Toutes nos excuses', "Une erreur s'est produite lors du chargement des DJUs", 'error');
        }
    }

    getSiteFromParams() {
        if (this.route.params) {
            this.route.params.subscribe(async params => {
                try {
                    this.setAllLoadings(true);
                    this.contract = params.contractId;
                    this.routingRef = params.rfId;

                    // #1 get available years
                    const years = await this.energyPdlViewService.getYears(
                        this.contract,
                        this.routingRef,
                        this.energyType
                    );

                    if (years && years.data && years.data.length) {
                        this.years = years.data;
                        this.years.sort((a, b) => b - a);
                    }

                    // #2 get routing reference datas
                    const routingReference = await this.energyPdlViewService.getRoutingReferenceById(this.routingRef);

                    this.data = routingReference.data;
                    this.selectedContract = this.data.contracts.find(contract => contract._id === this.contract);

                    // #3 get site data
                    const site = await this.siteService.getSiteFromRoutingReference(this.data._id, this.data.company);

                    if (!_.isEmpty(site)) {
                        this.site = site;
                    }
                    this.loading.site = false;
                } catch (error) {
                    this.years = this.years ? this.years : [];
                    this.periodSelected = this.periodSelected
                        ? this.periodSelected
                        : { dateStart: null, dateEnd: null };
                    this.data = this.data ? this.data : null;
                    this.selectedContract = this.selectedContract ? this.selectedContract : null;
                    this.site = null;
                    this.loading.site = false;
                    const msg = this.data ? 'du site' : 'de votre PDL';
                    Swal.fire('Toutes nos excuses', "Une erreur s'est produite lors du chargement " + msg, 'error');
                }
            });
        }
    }

    initConsoBarChartProperties() {
        this.consoBarChartProperties = this.defaultConfigConsoBarChart;
    }

    generateConsoGraph(activateDjus, djus: Dju[] | null = null): void {
        if (djus) {
            this.djus = djus;
        }

        this.loading.chartLine = true;
        this.consoGraph.isDjuActivated = activateDjus;

        this.initConsoBarChartProperties();

        this.handleDJUData(this.djus, this.monthData);
        this.djuActivateChanged(this.consoGraph.isDjuActivated);

        this.loading.chartLine = false;
    }

    handleDJUData(djuData: Dju[], monthData: GazMonthlyBill[]): void {
        const labels = ['total_conso_gas'];
        if (djuData) {
            labels.push('djus');
        }
        const configType = djuData ? 'mixed' : 'bar';
        this.consoBarChartProperties.datasets[0] = this.djuService.getDJUMonthData(
            djuData,
            monthData,
            'consumption.global.quantity'
        );
        this.consoBarChartProperties.labels = this.chartService.getMonthsLabel(monthData);
        this.consoBarChartProperties.options[0] = this.chartService.getSeriesAxesOptions(
            this.consoBarChartProperties.datasets[0],
            this.chartService.getConfig(configType, {
                tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line'),
            })
        );
        this.consoBarChartProperties.legend[0] = this.chartService.getChartLegends(labels);
        this.consoBarChartProperties.colors[0] = this.chartService.getBarChartColorsTransparent(labels);
        this.consoBarChartProperties.toggleTitles = [{ name: 'Total', display: true }];

        if (djuData) {
            this.consoBarChartProperties.toggleTitles.push({ name: 'kWh/dju', display: true });
            const djuDataset = this.billService.getMonthDataDjuRatio(
                djuData,
                monthData,
                [
                    {
                        name: 'Gaz conso',
                        path: 'consumption.global.quantity',
                    },
                ],
                labels
            );
            this.consoBarChartProperties.datasets[1] = djuDataset;

            this.consoBarChartProperties.options[1] = this.chartService.getSeriesAxesOptions(
                djuDataset,
                this.chartService.getConfig('bar', {
                    tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line'),
                })
            );
            this.consoBarChartProperties.legend[1] = this.chartService.getChartLegends(['total_conso_gas_dju_ratio']);
            this.consoBarChartProperties.colors[1] = this.chartService.getBarChartColorsTransparent([
                'total_conso_gas_dju_ratio',
            ]);
        }
    }

    /**
     * On DJU activation change, hide/show djus datasets and y-axe
     */
    djuActivateChanged(isDjuActivated: boolean): void {
        isDjuActivated = isDjuActivated && this.djus && this.djus.length;
        this.consoGraph.isDjuActivated = isDjuActivated;

        /**
         * First, we need to update data (so that it's saved depending on other actions)
         * Then, we need to update the chart display.
         */
        const djuLabel = 'DJU';
        const kwhDjuButton = 'kWh/dju';
        const labelsLegends = [['total_conso_gas'], ['total_conso_gas_dju_ratio']];
        if (isDjuActivated) {
            labelsLegends[0].push('djus');
        }

        const legends = labelsLegends.map(x => this.chartService.getChartLegends(x));

        this.djuService.toggleDjuInDatasetAndChart(
            isDjuActivated,
            this.consoBarChartProperties,
            djuLabel,
            legends,
            kwhDjuButton,
            this.chartBarConso
        );
    }

    /**
     * Create ratio table from the bills data aggregated per year
     * @param {GazYearlyBill[]} dataPerYear
     */
    setRatio(dataPerYear: GazYearlyBill[]): void {
        this.ratioTable = [];

        dataPerYear.forEach(yd => {
            const billTotals = this.getGazBillTotals(yd);

            const consumptionQty = yd.consumption.global.quantity / 1000;

            this.ratioTable.push({
                periodLabel: yd.year,
                ratios: {
                    consumption: this.formatForTable(billTotals.consumptionAmount / consumptionQty, 'money'),
                    subscription: this.formatForTable(billTotals.subscriptionAmount / consumptionQty, 'money'),
                    networkServices: this.formatForTable(billTotals.networkServicesAmount / consumptionQty, 'money'),
                    taxes: this.formatForTable(billTotals.taxesAmount / consumptionQty, 'money'),
                },
            });
        });
    }

    getTotalConso() {
        return Number(this.consoData[0].data.reduce((a, b) => Number(a) + Number(b)));
    }

    getTotalCost() {
        const totalHT = this.tilesService.getNumberToDisplay(this.totalHT, '€');
        const totalTTC = this.tilesService.getNumberToDisplay(this.totalTTC, '€');
        return `<b>${totalHT}</b> € HT - <b>${totalTTC}</b> € TTC`;
    }

    /*
     * Set in storage the current site in last visited sites for dashboard display
     */
    setLastVisitedSites(site) {
        this.sessionService.setLastVisitedSites(site);
    }

    /**
     * Get consumption per month on the selected period.
     * @return {Promise<GazMonthlyBill[]>}
     */
    async getRoutingReferenceMonthlyConsumption(): Promise<GazMonthlyBill[]> {
        try {
            // this.loading.chartLine = true;
            this.loading.secondChartLine = true;
            this.loading.monthlyCostTable = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            const reponse = await this.pdlService.getByMonthOrYear(
                'gaz',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd
            );

            return reponse.data;
        } catch (error) {
            // this.loading.chartLine = false;
            this.loading.secondChartLine = false;
            this.loading.monthlyCostTable = false;
            return [];
        }
    }

    /**
     * Returns the months data matching the selected period, aggregated per year. Asks for 2 years historic to populate the ratio table.
     *
     * [{_id: {…}, dateStart: "2016-01-01T00:00:00.000Z", dateEnd: "2016-12-31T23:59:59.999Z", month: 11, year: 2016, …},
     *  {_id: {…}, dateStart: "2017-01-01T00:00:00.000Z", dateEnd: "2017-12-31T23:59:59.999Z", month: 6, year: 2017, …},
     *  {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-04-05T00:00:00.000Z", month: 2, year: 2018, …}]
     *
     * @return {Promise<GazYearlyBill[]>}
     */
    async getRfYearlyConsumptionWithHistoric(): Promise<GazYearlyBill[]> {
        try {
            this.loading.chartDonut = true;
            this.loading.ratioTable = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('year')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('year')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                'gaz',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                true,
                2
            );

            return response.data;
        } catch (error) {
            this.loading.chartDonut = false;
            this.loading.ratioTable = false;
            return [];
        }
    }

    /**
     * Returns the months data matching the selected period, aggregated per year.
     *
     * [{_id: {…}, dateStart: "2017-06-20T00:00:00.000Z", dateEnd: "2017-12-31T23:59:59.999Z", month: 6, year: 2017, …},
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 0, year: 2018, …}]
     *
     * @return {Promise<GazYearlyBill[]>}
     */
    async getRfYearlyConsumptionForPeriod(): Promise<GazYearlyBill[]> {
        try {
            this.loading.chartDonut = true;
            this.loading.ratioTable = true;

            const dateStart = moment(this.periodSelected.dateStart, 'YYYY-MM')
                .startOf('month')
                .format('DD/MM/YYYY');
            const dateEnd = moment(this.periodSelected.dateEnd, 'YYYY-MM')
                .subtract(1, 'months')
                .endOf('month')
                .format('DD/MM/YYYY');

            const response = await this.pdlService.getByMonthOrYear(
                'gaz',
                this.contract,
                this.routingRef,
                dateStart,
                dateEnd,
                true
            );
            return response.data;
        } catch (error) {
            this.loading.chartDonut = false;
            this.loading.ratioTable = false;
            return [];
        }
    }

    reinitTotals() {
        this.totalHT = 0;
        this.totalTTC = 0;
    }

    setTotalsFromMonthData() {
        this.reinitTotals();

        this.monthData.forEach(monthdata => {
            this.totalHT += monthdata.bill.billAmount.HTVA;
            this.totalTTC += monthdata.bill.billAmount.TTC;
        });
    }

    /**
     * From month data, set totals for tiles, both charts and monthly cost table
     * @param {GazMonthlyBill[]} monthDatas
     */
    handleMonthData(monthDatas: GazMonthlyBill[]): void {
        this.monthData = monthDatas;
        this.setTotalsFromMonthData();

        if (monthDatas.length) {
            this.setConsoBarChartData();
            this.setCostChartData();
            this.setMonthlyCostTable();
        }

        // this.loading.chartLine = false;
        this.loading.secondChartLine = false;
        this.loading.monthlyCostTable = false;
    }

    /**
     * Populate the ratio table.
     * @param {GazYearlyBill[]} dataPerYear
     * [{_id: {…}, dateStart: "2017-06-20T00:00:00.000Z", dateEnd: "2017-12-31T23:59:59.999Z", month: 6, year: 2017, …},
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 0, year: 2018, …}]
     */
    handleRatioTable(dataPerYear: GazYearlyBill[]): void {
        if (dataPerYear.length) {
            this.setRatio(dataPerYear);
        }
        this.loading.ratioTable = false;
    }
    /**
     * Populate the donut.
     * @param {GazYearlyBill[]} dataPerYear
     * [{_id: {…}, dateStart: "2017-06-20T00:00:00.000Z", dateEnd: "2017-12-31T23:59:59.999Z", month: 6, year: 2017, …},
     * {_id: {…}, dateStart: "2018-01-01T00:00:00.000Z", dateEnd: "2018-02-28T23:59:59.999Z", month: 0, year: 2018, …}]
     */
    handleDonut(dataPerYear: GazYearlyBill[]): void {
        if (dataPerYear.length) {
            this.setCostsDoughnut(dataPerYear);
        }
        this.loading.chartDonut = false;
    }

    /**
     * Set donut config with computed data
     * @param {GazYearlyBill[]} dataPerYear
     */
    setCostsDoughnut(dataPerYear: GazYearlyBill[]): void {
        this.doughnutCostsProperties = {
            options: [],
            legend: [],
            title: 'Répartition des coûts',
        };

        let networkServices = 0;
        let consumption = 0;
        let taxes = 0;
        let subscription = 0;

        dataPerYear.forEach(yearData => {
            const billTotals = this.getGazBillTotals(yearData);

            consumption += billTotals.consumptionAmount;
            subscription += billTotals.subscriptionAmount;
            networkServices += billTotals.networkServicesAmount;
            taxes += billTotals.taxesAmount;
        });

        const labels = ['gaz_conso', 'gaz_networkServices', 'gaz_taxes', 'gaz_subscription'];
        this.doughnutCostsProperties.legend = this.chartService.getChartLegends(labels);

        const dataForChart = [
            Math.round(consumption * 100) / 100,
            Math.round(networkServices * 100) / 100,
            Math.round(taxes * 100) / 100,
            Math.round(subscription * 100) / 100,
        ];
        this.doughnutCostsProperties.options = this.chartService.getHighchartDonutConfig(dataForChart, labels, '€');
    }

    public hasDataForCostDoughnut(): boolean {
        try {
            const chartOptions = this.doughnutCostsProperties.options;
            return Boolean(
                chartOptions &&
                    chartOptions.series[0].data &&
                    chartOptions.series[0].data[0] &&
                    chartOptions.series[0].data[1] &&
                    chartOptions.series[0].data[2] &&
                    (chartOptions.series[0].data[0].y > 0 ||
                        chartOptions.series[0].data[1].y > 0 ||
                        chartOptions.series[0].data[2].y > 0)
            );
        } catch (err) {
            return false;
        }
    }

    /**
     * Set consoData for the bar chart
     */
    setConsoBarChartData(): void {
        const arrayData = [];
        const monthLabels = [];

        if (this.monthData) {
            this.monthData.forEach(x => {
                arrayData.push(x.consumption.global.quantity);
                monthLabels.push(
                    moment()
                        .month(x.month)
                        .year(x.year)
                        .format('MM/YYYY')
                );
            });
        }

        this.consoData = [
            {
                label: 'Consommation totale du site',
                data: arrayData,
            },
        ];
    }

    /**
     * Set costData for the cost line chart
     */
    private setCostChartData(): void {
        const arrayData = [];
        const monthLabels = [];

        this.monthData.forEach(x => {
            arrayData.push(x.consumption.global.amount);
            monthLabels.push(
                moment()
                    .month(x.month)
                    .year(x.year)
                    .format('MM/YYYY')
            );
        });

        this.costData = [
            {
                label: 'Total des coûts',
                data: arrayData,
            },
        ];

        this.costsLineChartProperties.dataset = this.costData;
        this.costsLineChartProperties.labels = monthLabels;
        this.costsLineChartProperties.options = this.chartService.getConfig('bar', {
            tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line', '€'),
        });
        this.costsLineChartProperties.legend = this.chartService.getChartLegends(['total_cost_gas']);
        this.costsLineChartProperties.colors = this.chartService.getBarChartColorsTransparent(['total_cost_gas']);
    }

    /**
     * Create monthly cost table from bills' data aggregated per month.
     */
    setMonthlyCostTable(): void {
        this.monthlyCostTable = [];

        this.monthData.forEach(md => {
            const billTotals = this.getGazBillTotals(md);

            this.monthlyCostTable.push({
                periodLabel: moment()
                    .month(md.month)
                    .year(md.year)
                    .format('MM/YYYY'),
                consumption: {
                    globalQty: this.formatForTable(md.consumption.global.quantity / 1000),
                    globalVolume: this.formatForTable(md.consumption.global.volume, 'volume'),
                    globalAmount: this.formatForTable(md.consumption.global.amount, 'money'),
                    renewableAmount: this.formatForTable(md.consumption.renewable.amount, 'money'),
                    ceeAmount: this.formatForTable(md.consumption.CEE.amount, 'money'),
                    total: this.formatForTable(billTotals.consumptionAmount, 'money'),
                },
                subscription: {
                    globalAmount: this.formatForTable(md.networkServices.global.amount, 'money'),
                    distribFixedAmount: this.formatForTable(md.networkServices.distributionFixed.amount, 'money'),
                    transpFixedAmount: this.formatForTable(md.networkServices.transportationFixed.amount, 'money'),
                    total: this.formatForTable(billTotals.subscriptionAmount, 'money'),
                },
                networkServices: {
                    distributionVariableQty: this.formatForTable(
                        md.networkServices.distributionVariable.quantity / 1000
                    ),
                    distributionVariableAmount: this.formatForTable(
                        md.networkServices.distributionVariable.amount,
                        'money'
                    ),
                    transportVariableQty: this.formatForTable(
                        md.networkServices.transportationVariable.quantity / 1000
                    ),
                    transportVariableAmount: this.formatForTable(
                        md.networkServices.transportationVariable.amount,
                        'money'
                    ),
                    storageQty: this.formatForTable(md.networkServices.storage.quantity / 1000),
                    storageAmount: this.formatForTable(md.networkServices.storage.amount, 'money'),
                    capacityAmount: this.formatForTable(md.networkServices.capacity.amount, 'money'),
                    meterRentAmount: this.formatForTable(md.networkServices.meterRent.amount, 'money'),
                    miscServicesAmount: this.formatForTable(md.networkServices.miscServices.amount, 'money'),
                    total: this.formatForTable(billTotals.networkServicesAmount, 'money'),
                },
                taxes: {
                    CTAGlobal: this.formatForTable(md.tax.CTA.global.amount, 'money'),
                    CTADistrib: this.formatForTable(md.tax.CTA.distribution.amount, 'money'),
                    CTATransp: this.formatForTable(md.tax.CTA.transportation.amount, 'money'),
                    TICGNQty: this.formatForTable(md.tax.TICGN.quantity / 1000),
                    TICGNAmount: this.formatForTable(md.tax.TICGN.amount, 'money'),
                    CBMQty: this.formatForTable(md.tax.CBM.quantity / 1000),
                    CBMAmount: this.formatForTable(md.tax.CBM.amount, 'money'),
                    CTSSGQty: this.formatForTable(md.tax.CTSSG.quantity / 1000),
                    CTSSGAmount: this.formatForTable(md.tax.CTSSG.amount, 'money'),
                    CSPGQty: this.formatForTable(md.tax.CSPG.quantity / 1000),
                    CSPGAmount: this.formatForTable(md.tax.CSPG.amount, 'money'),
                    total: this.formatForTable(billTotals.taxesAmount, 'money'),
                },
                totalHT: {
                    total: this.formatForTable(
                        billTotals.consumptionAmount +
                            billTotals.subscriptionAmount +
                            billTotals.networkServicesAmount +
                            billTotals.taxesAmount,
                        'money'
                    ),
                },
                totalTTC: { total: this.formatForTable(md.bill.billAmount.TTC, 'money') },
            });
        });
    }

    /**
     * Format number to be displayed intable. If null, set 'NC'.
     * @param {number} value
     * @param {string} [type='fluid']
     * @return {string} value formated as a string
     */
    formatForTable(value: number, type: string = 'fluid'): string {
        return this.utilsService.formateNumberType(value, 'NC', type);
    }

    siteLoaded() {
        return this.data && this.data._id && !this.loading.site;
    }

    canShowSecondLineChart(): boolean {
        return Boolean(!this.loading.secondChartLine && this.costsLineChartProperties.dataset.length);
    }

    canShowConsoBarChart(): boolean {
        return Boolean(!this.loading.chartLine && this.consoBarChartProperties.datasets.length);
    }

    hasDataForConsoBarChart(): boolean {
        return Boolean(this.consoBarChartProperties.datasets && this.consoBarChartProperties.datasets.length);
    }

    canShowMonthlyCostTable(): boolean {
        return Boolean(!this.loading.monthlyCostTable && this.monthlyCostTable);
    }

    canShowRatioTable(): boolean {
        return Boolean(!this.loading.ratioTable && this.ratioTable.length);
    }

    getExportExcelUrl(type) {
        return this.pdlService.getExportExcelUrl(
            type,
            this.periodSelected,
            this.routingRef,
            this.contract,
            this.consoGraph.isDjuActivated,
            'gaz'
        );
    }

    getExportName(txt) {
        return this.pdlService.getExportName(txt, this.periodSelected, this.data, this.selectedContract);
    }

    private getGazBillTotals(bill: GazMonthlyBill | GazYearlyBill) {
        const { consumption, networkServices, tax } = bill;

        const consumptionAmount = consumption.global.amount + consumption.renewable.amount + consumption.CEE.amount;

        const subscriptionAmount =
            networkServices.global.amount +
            networkServices.distributionFixed.amount +
            networkServices.transportationFixed.amount;

        const networkServicesAmount =
            networkServices.distributionVariable.amount +
            networkServices.transportationVariable.amount +
            networkServices.storage.amount +
            networkServices.capacity.amount +
            networkServices.meterRent.amount +
            networkServices.miscServices.amount;

        const taxesAmount =
            tax.CTA.global.amount +
            tax.CTA.transportation.amount +
            tax.CTA.distribution.amount +
            tax.TICGN.amount +
            tax.CBM.amount +
            tax.CTSSG.amount +
            tax.CSPG.amount;

        return {
            consumptionAmount,
            subscriptionAmount,
            networkServicesAmount,
            taxesAmount,
        };
    }
}
