import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CompanyLight } from 'app/shared/models/company.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { Site } from 'app/shared/models/site.interface';
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { SortService } from 'app/shared/services/sort/sort.service';

import { Monitoring, Types } from '../../operating-monitoring.interface';
import { OperatingMonitoringService } from '../../operating-monitoring.service';
import { CommonDataFieldKey, FieldConfig } from '../monitoring-edition.interface';
import { MonitoringEditionService } from '../monitoring-edition.service';

/**
 * Scope of monitoring creation
 */
@Component({
    selector: 'ga-monitoring-edition-scope',
    templateUrl: './monitoring-edition-scope.component.html',
    styleUrls: ['../monitoring-edition.component.scss', './monitoring-edition-scope.component.scss'],
})
export class MonitoringEditionScopeComponent implements OnInit, OnDestroy {
    private prefillMonitoring: Monitoring;

    private configs: Array<FieldConfig<CommonDataFieldKey>> = [
        {
            key: 'monitoringType',
            displayKey: 'monitoring_type',
            deps: [],
            defaultValue: Types.BOILER_ROOM,
            options: Object.values(Types).map(type => ({ value: type, key: `monitoring_type_${type}` })),
            disabled: true,
            required: true,
        },
        {
            key: 'monitoringName',
            displayKey: 'monitoring_name',
            deps: [],
            defaultValue: null,
            required: true,
        },
        {
            key: 'monitoringCompany',
            displayKey: 'entity',
            deps: [],
            defaultValue: null,
            required: true,
        },
        {
            key: 'monitoringSite',
            displayKey: 'site',
            deps: [],
            defaultValue: null,
            options: [],
            disabled: true,
            required: true,
        },
        {
            key: 'monitoringRref',
            displayKey: 'routingReference',
            deps: [],
            defaultValue: null,
            options: [],
            disabled: true,
            required: true,
        },
    ];
    /** Fields form group. Initiated dynamically */
    get form() {
        return this.monitoringEditionService.form;
    }
    /** Subscription */
    private subscription: Subscription;
    /** List of companies IDs in the filter */
    private companies: string[];
    /** Show the companies select if it does have branches */
    public displaySelectCompany = true;

    get monitoringTypeField() {
        return this.configs.find(c => c.key === 'monitoringType');
    }
    get monitoringNameField() {
        return this.configs.find(c => c.key === 'monitoringName');
    }
    get monitoringCompanyField() {
        return this.configs.find(c => c.key === 'monitoringCompany');
    }
    get monitoringSiteField() {
        return this.configs.find(c => c.key === 'monitoringSite');
    }
    get monitoringRRefField() {
        return this.configs.find(c => c.key === 'monitoringRref');
    }

    /** List of sites for the current company */
    private sites: Site[] = [];
    /** For spinner to wait end of site loading */
    public siteIsLoading = false;
    /** List of routing references for the current site */
    private routingReferences: RoutingReference[] = [];
    get monitoringSite(): { value: any; key: string } {
        const site = this.form.get(this.monitoringSiteField.key).value;
        if (site) {
            return this.monitoringSiteField.options.find(x => x.value === site._id);
        }
        return null;
    }
    /** For site autocomplete to fill up the form */
    set monitoringSiteValue(value: string) {
        this.form.get(this.monitoringSiteField.key).setValue(this.getSite(value));
    }
    /** For routing reference autocomplete to fill up the form */
    get monitoringRRef(): { value: any; key: string } {
        const value = this.form.get(this.monitoringRRefField.key).value;
        if (value) {
            return this.monitoringRRefField.options.find(x => x.value === value._id);
        }
        return null;
    }
    set monitoringRRefValue(value: string) {
        this.form.get(this.monitoringRRefField.key).setValue(this.getRoutingReference(value));
    }

    constructor(
        private companiesService: CompaniesService,
        private monitoringEditionService: MonitoringEditionService,
        private operatingMonitoringService: OperatingMonitoringService,
        private sessionService: SessionService,
        private siteService: SitesService,
        private sortService: SortService
    ) {}

    ngOnInit() {
        this.monitoringEditionService.addToControlGroup(this.configs);
        this.subscription = this.operatingMonitoringService.filters$.subscribe({
            next: value => this.getFilteredCompanies(value.companies),
        });
        this.subscription.add(
            this.form.get(this.monitoringCompanyField.key).valueChanges.subscribe({
                next: value => this.getCompanySites(value),
            })
        );
        this.subscription.add(
            this.form.get(this.monitoringSiteField.key).valueChanges.subscribe({
                next: value => this.getSiteRoutingReferences(value),
            })
        );
        this.subscription.add(
            this.operatingMonitoringService.editedMonitoring$.subscribe({
                next: value => {
                    this.prefillMonitoring = value;
                    if (this.prefillMonitoring) {
                        this.form.get(this.monitoringTypeField.key).setValue(this.prefillMonitoring.monitoringType);
                        this.form.get(this.monitoringNameField.key).setValue(this.prefillMonitoring.name);
                        this.getCompanySites(this.prefillMonitoring.companyId);
                    }
                },
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    /**
     * Check if the given companies ids list is different from the previous one
     *
     * @param {string[]} companiesIds
     */
    private differentToCompanies(companiesIds: string[]): boolean {
        if (!this.companies || this.companies.length !== companiesIds.length) {
            return true;
        }
        for (const companyId of companiesIds) {
            if (!this.companies.includes(companyId)) {
                return true;
            }
        }

        return false;
    }

    private async getFilteredCompanies(filterCompaniesIds: string[]) {
        if (this.differentToCompanies(filterCompaniesIds)) {
            const monitoringCompanyControl = this.form.get(this.monitoringCompanyField.key);
            const company = this.sessionService.getCompany();
            const companiesLight: CompanyLight[] = [{ _id: company._id, name: company.name }].concat(
                (await this.companiesService.getBranches(company._id, true)).data
            );
            // If company has no branches
            if (companiesLight.length === 1) {
                this.displaySelectCompany = false;
            }
            const companies = companiesLight
                .concat()
                .filter(c => filterCompaniesIds.includes(c._id))
                .sort((compA, compB) => this.sortService.compareStrings(compA.name, compB.name));

            this.monitoringCompanyField.options = companies.map(c => ({ value: c._id, key: c.name }));
            this.monitoringCompanyField.defaultValue = this.prefillMonitoring
                ? this.prefillMonitoring.companyId
                : companies.length
                ? companies[0]._id
                : null;
            monitoringCompanyControl.setValue(this.monitoringCompanyField.defaultValue);
            this.companies = filterCompaniesIds;
        }
    }

    private async getCompanySites(companyId: string) {
        const monitoringSiteControl = this.form.get(this.monitoringSiteField.key);
        // Reset the field
        this.monitoringSiteField.disabled = true;
        monitoringSiteControl.disable();
        monitoringSiteControl.setValue(null);
        this.siteIsLoading = true;
        this.sites = [];

        // Retrieve infos if company is provided
        if (companyId) {
            this.sites = (await this.siteService.getSites({ companies: companyId })).data;
            this.monitoringSiteField.disabled = false;
            monitoringSiteControl.enable();
        }

        this.monitoringSiteField.options = []
            .concat(this.sites)
            .map(s => ({
                value: s._id,
                key: s.code ? `${s.code} - ${s.complement || 'NC'}` : s.complement || 'NC',
            }))
            .sort((siteA, siteB) => this.sortService.compareStrings(siteA.key, siteB.key));

        if (this.prefillMonitoring && this.prefillMonitoring.companyId === companyId) {
            this.monitoringSiteField.defaultValue = this.prefillMonitoring.site;
            monitoringSiteControl.setValue(this.monitoringSiteField.defaultValue);
        }
        this.siteIsLoading = false;
    }

    private getSiteRoutingReferences(site: Site) {
        const monitoringRRefControl = this.form.get(this.monitoringRRefField.key);
        // Reset the field
        this.monitoringRRefField.disabled = true;
        monitoringRRefControl.disable();
        monitoringRRefControl.setValue(null);

        // Retrieve routing references if site is provided
        if (site && site._id) {
            this.routingReferences = this.getSite(site._id).routingReferences as RoutingReference[];
            this.monitoringRRefField.disabled = false;
            monitoringRRefControl.enable();
        }

        this.monitoringRRefField.options = this.routingReferences
            .concat()
            .sort((refA, refB) => this.sortService.compareStrings(refA.reference, refB.reference))
            .map(r => ({ value: r._id, key: r.reference }));

        this.monitoringRRefField.defaultValue = null;

        // Preselect routing reference :
        // - if site is already prefilled and user did not select a new one yet
        // - else if site has only one routing reference
        if (site && this.prefillMonitoring && this.prefillMonitoring.site._id === site._id) {
            this.monitoringRRefField.defaultValue = this.getRoutingReference(this.prefillMonitoring.scope.rRefId);
        } else if (this.monitoringRRefField.options.length === 1) {
            this.monitoringRRefField.defaultValue = this.monitoringRRefField.options[0].value;
        }

        monitoringRRefControl.setValue(this.monitoringRRefField.defaultValue);
    }

    /** Return the Site object of the selected site */
    private getSite(siteId: string): Site {
        return this.sites.find(s => s._id === siteId);
    }

    /** Return the Routing reference object of the selected site */
    private getRoutingReference(rRefId: string): RoutingReference {
        return this.routingReferences.find(r => r._id === rRefId);
    }
}
