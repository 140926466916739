import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils/utils.service';

interface Options {
    unit?: string;
    type?: string;
    replaceZero?: boolean;
    replaceNull?: boolean;
    excludedValues?: number[];
    replaceStringKey?: string;
}

@Pipe({
    name: 'formatNumberUnit',
})
export class FormatNumberUnitPipe implements PipeTransform {
    constructor(private utilsService: UtilsService) {}

    /**
     * Return the formated value to display
     * @param {number|null} value
     * @param {Option?} customOptions - options to get the required format
     * @param {string?} options.unit - also used to get the corred nb of decimal values
     * @param {string?} options.type - used to get the correct nb of decimal values when there is no unit
     * @param {number[]?} excludedValues - replace specific values with required string
     * @param {string?} replaceStringKey - to get the string replacing wrong value
     * @returns {string} value to display
     */
    transform(value: number | null, customOptions: Options = {}): string {
        const options = {
            unit: '',
            type: null,
            excludedValues: [],
            replaceStringKey: 'dash',
        };

        Object.assign(options, customOptions);

        const replaceString = {
            dashUnit: '-' + options.unit,
            dash: '-',
        };

        const isExcluded = options.excludedValues.includes(value);

        if (isExcluded) {
            return replaceString[options.replaceStringKey];
        }

        return this.utilsService.getNumberAndUnitToDisplay(value, options.unit, options.type);
    }

    /**
     * Returns true if the value is not a number and is undefined
     * @param {*} value - to test
     * @returns {boolean}
     */
    isUndefined(value: any): boolean {
        return typeof value !== 'number' && !value;
    }
}
