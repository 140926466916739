import { Injectable } from '@angular/core';

import { SOURCE_TYPE } from 'app/shared/models/external-providers.interface';
import { CommunicatingSummary, RoutingReference } from 'app/shared/models/routing-reference.interface';
import { ColorService } from 'app/shared/services/color/color.service';
import { LoadCurveService } from 'app/shared/services/load-curve/load-curve.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';

import {
    COMMUNICATING_COLOR_LABEL,
    ConditionFunctions,
    RoutingReferenceCommunicatingStatus,
    StatusConfig,
} from './pdl-communicating.interface';

@Injectable()
export class PDLCommunicatingService {
    /** Functions to use in configs conditions */
    private conditionFonctions: ConditionFunctions = {
        inWarrant: summary =>
            this.getPropertyFromExternalProviders<boolean>(summary, SOURCE_TYPE.ENEDIS, 'warrantAuthorization', false),
        canSync: summary =>
            this.getPropertyFromExternalProviders<boolean>(summary, SOURCE_TYPE.ENEDIS, 'contractualData', false),
        synchronized: summary => Boolean(summary.lastSynchronization),
        communicating: summary => summary.isCommunicating,
        communicatingAvailable: summary => summary.canCommunicate,
        communicatingUndetermined: summary => summary.isUndeterminedCommunicating,
    };

    /** List of labels, colors and tooltip to use, given the connected state match conditions */
    public statusConfigs: StatusConfig[] = [
        {
            conditions: [
                { fct: this.conditionFonctions.inWarrant, expected: true },
                { fct: this.conditionFonctions.canSync, expected: true },
                { fct: this.conditionFonctions.synchronized, expected: true },
                { fct: this.conditionFonctions.communicatingUndetermined, expected: true },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.CAN_COMMUNICATE,
            text: this.translate._('routing_reference_communicating_cant_be_determined'),
            getTooltip: summary => this.getDateTooltip(summary),
            getAccessToLoadCurve: summary => summary.isVisibleToUser,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.inWarrant, expected: true },
                { fct: this.conditionFonctions.canSync, expected: true },
                { fct: this.conditionFonctions.synchronized, expected: true },
                { fct: this.conditionFonctions.communicating, expected: true },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.COMMUNICATING,
            text: this.translate._('routing_reference_communicating'),
            getTooltip: summary => this.getDateTooltip(summary),
            getAccessToLoadCurve: summary => summary.isVisibleToUser,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.inWarrant, expected: true },
                { fct: this.conditionFonctions.canSync, expected: false },
                { fct: this.conditionFonctions.synchronized, expected: true },
                { fct: this.conditionFonctions.communicating, expected: true },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.COMMUNICATING,
            text: this.translate._('routing_reference_communicating'),
            getTooltip: summary => this.getDateTooltip(summary),
            getAccessToLoadCurve: summary => summary.isVisibleToUser,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.inWarrant, expected: false },
                { fct: this.conditionFonctions.canSync, expected: false },
                { fct: this.conditionFonctions.synchronized, expected: true },
                { fct: this.conditionFonctions.communicating, expected: true },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.COMMUNICATING,
            text: this.translate._('routing_reference_communicating'),
            getTooltip: summary => this.getDateTooltip(summary),
            getAccessToLoadCurve: summary => false,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.synchronized, expected: true },
                { fct: this.conditionFonctions.communicating, expected: false },
                { fct: this.conditionFonctions.communicatingAvailable, expected: false },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.NOT_COMMUNICATING,
            text: this.translate._('routing_reference_not_communicating'),
            getTooltip: communicatingSummary => this.getDateTooltip(communicatingSummary),
            getAccessToLoadCurve: summary => false,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.synchronized, expected: true },
                { fct: this.conditionFonctions.communicating, expected: false },
                { fct: this.conditionFonctions.communicatingAvailable, expected: true },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.CAN_COMMUNICATE,
            text: this.translate._('routing_reference_available_communicating'),
            getTooltip: communicatingSummary => this.getDateTooltip(communicatingSummary),
            getAccessToLoadCurve: summary => false,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.inWarrant, expected: true },
                { fct: this.conditionFonctions.canSync, expected: true },
                { fct: this.conditionFonctions.synchronized, expected: false },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.INVALID_COMMUNICATING,
            text: this.translate._('routing_reference_waiting_communicating'),
            getTooltip: () => this.translate._('no_available_data'),
            getAccessToLoadCurve: summary => false,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.inWarrant, expected: true },
                { fct: this.conditionFonctions.canSync, expected: false },
                { fct: this.conditionFonctions.synchronized, expected: false },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.INVALID_COMMUNICATING,
            text: this.translate._('routing_reference_need_sync_communicating'),
            getTooltip: () => this.translate._('no_available_data'),
            getAccessToLoadCurve: summary => false,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.inWarrant, expected: false },
                { fct: this.conditionFonctions.canSync, expected: false },
                { fct: this.conditionFonctions.synchronized, expected: false },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.INVALID_COMMUNICATING,
            text: this.translate._('routing_reference_out_of_scope_communicating'),
            getTooltip: () => this.translate._('no_available_data'),
            getAccessToLoadCurve: summary => false,
        },
        {
            conditions: [
                { fct: this.conditionFonctions.synchronized, expected: true },
                { fct: this.conditionFonctions.communicating, expected: null },
                { fct: this.conditionFonctions.communicatingAvailable, expected: null },
            ],
            colorLabel: COMMUNICATING_COLOR_LABEL.INVALID_COMMUNICATING,
            text: this.translate._('routing_reference_out_of_scope_communicating'),
            getTooltip: () => this.translate._('no_available_data'),
            getAccessToLoadCurve: summary => false,
        },
    ];

    /** The connnected state of the routing reference */
    private communicatingSummary: CommunicatingSummary;

    constructor(
        private translate: TranslateService,
        private colorService: ColorService,
        private loadcurveService: LoadCurveService
    ) {}

    /** Retrieve the connected state of the routing reference */
    public async retrieveCommunicatingStatus(
        routingReference: RoutingReference
    ): Promise<RoutingReferenceCommunicatingStatus | null> {
        this.communicatingSummary = await this.loadcurveService.getRoutingReferenceCommunicatingSummary(
            routingReference._id
        );

        return this.calcStatus();
    }

    /** Find correct config, calc status and emit the new one */
    private calcStatus(): RoutingReferenceCommunicatingStatus {
        const currentConfig = this.statusConfigs.find(config =>
            config.conditions.every(cond => cond.fct(this.communicatingSummary) === cond.expected)
        );

        return this.getRoutingReferenceStatus(currentConfig);
    }

    /** Calc the communicating status of the routing reference for the current config */
    private getRoutingReferenceStatus(config: StatusConfig): RoutingReferenceCommunicatingStatus {
        return {
            text: config.text,
            color: this.colorService.getRgbColor(config.colorLabel),
            tooltip: config.getTooltip(this.communicatingSummary),
            accessToLoadcurve: config.getAccessToLoadCurve(this.communicatingSummary),
        };
    }

    /** Return the tooltip text to display the last synchronization date */
    private getDateTooltip(communicatingSummary: CommunicatingSummary): string {
        if (!communicatingSummary) {
            return null;
        }

        const lastDate = new Date(communicatingSummary.lastSynchronization);
        return `${lastDate.toLocaleDateString()} ${lastDate.toLocaleTimeString()}`;
    }

    /** Return the value of a property for a given external provider */
    private getPropertyFromExternalProviders<T>(
        summary: CommunicatingSummary,
        provider: SOURCE_TYPE,
        property: string,
        defaultValue: T
    ): T {
        const externalProvider = summary.externalProviders.find(ep => ep.dataProvider === provider);
        if (!externalProvider) {
            return defaultValue;
        }

        const value = externalProvider[property];
        return value || defaultValue;
    }
}
