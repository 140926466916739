import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { IdentificationPatternComponent } from './identification-pattern.component';

import { AccessGuard } from '../../../shared/services/guards/access-guard.service';

const IDENTIFICATION_PATTERN_ROUTE = [
    {
        path: '',
        component: IdentificationPatternComponent,
        canActivate: [AccessGuard],
        data: { page: 'admin' },
    },
];

@NgModule({
    declarations: [IdentificationPatternComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(IDENTIFICATION_PATTERN_ROUTE)],
})
export class IdentificationPatternModule {}
