import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import swal from 'sweetalert2';

// Services
import { PaginationBackService } from 'app/shared/components/pagination/back/pagination-back.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { UsersService } from 'app/shared/services/users/users.service';
import Swal from 'sweetalert2';
import { AdminCompanyService } from '../company/company.service';

// Interfaces
import { PaginationUsers, QueryPaginationUser } from 'app/shared/models/pagination.interface';
import { UserPopulated } from 'app/shared/models/users.interface';

@Component({
    selector: 'ga-admin-users',
    templateUrl: './users-home.component.html',
    styleUrls: ['./users-home.component.scss'],
    providers: [PaginationBackService],
})
export class AdminUsersComponent implements OnInit {
    public listUsers: UserPopulated[] = [];
    public userSession: UserPopulated;
    public pagination: PaginationUsers = {
        navigation: {
            canDisplayAll: false,
            nbItemsPerPage: 10,
        },
        lastUser: {
            name: null,
            index: 0,
        },
    };
    private searchInput = '';
    public isLoading = false;

    constructor(
        private companyService: AdminCompanyService,
        private sessionService: SessionService,
        private usersService: UsersService,
        public paginationService: PaginationBackService
    ) {}

    ngOnInit() {
        this.userSession = Object.assign(this.sessionService.getUser(), {
            companies: [],
            contacts: [],
            position: '',
            process: null,
            relations: [],
            sitesRules: null,
            logoUrl: null,
        });
    }

    /**
     * Init the users list to display.
     * Init the pagination.
     */
    async setListAndPagination() {
        this.isLoading = true;

        try {
            const [, newNbUsers] = await Promise.all([this.setUsersList(), this.countNbUsersTotal()]);

            // update pagination with the new last user, and new current page, and number of pages
            this.paginationService.updateAfterSearch(newNbUsers);

            this.isLoading = false;
        } catch (err) {
            this.getSwalMsg(err.errorCode); // err.errorCode is set to error_getListUsers
        }
    }

    /**
     * Set the users list to display in the table.
     * @return {Promise<UserPopulated[]>}
     */
    async setUsersList(): Promise<UserPopulated[]> {
        const queryPagination: QueryPaginationUser = this.paginationService.getPaginationQueryUser(
            this.pagination.lastUser
        );
        this.listUsers = await this.usersService.getVisibleUsers(
            this.userSession._id,
            queryPagination,
            this.searchInput
        );

        /**
         * Populate the other fields we need:
         *  - picture
         *  - companies
         */
        const listWithImgAndCompanies = _.clone(this.listUsers); // need to clone so that the child component (user table) receives the change
        const results = await Promise.all([
            this.companyService.getPictures(this.listUsers, 'img'),
            this.getUsersCompanies(),
        ]);
        const picturesResult = results[0]; // Array<{_id: string, imgUrl: string}>
        const companiesResult = results[1]; // Array<{_id: string, companies: Array<{_id: string, name: string}>}>

        // 1. set pictures
        picturesResult.forEach(picResult => {
            const user = listWithImgAndCompanies.find(u => u._id.toString() === picResult['_id'].toString());
            user.logoUrl = picResult['imgUrl'];
        });

        // 2. set companies in user session + users list
        this.userSession.companies = companiesResult
            .find(result => result._id.toString() === this.userSession._id.toString())
            .companies.map(c => c.name);
        listWithImgAndCompanies.forEach(user => {
            const res = companiesResult.find(result => result._id.toString() === user._id.toString());
            user.companies = res.companies.map(c => c.name);
        });

        this.listUsers = listWithImgAndCompanies; // fire the event to the child component

        this.paginationService.updateLastUser(this.pagination.lastUser, this.listUsers);

        return this.listUsers;
    }

    /**
     * Returns an array with the companies (id + name) of each user
     * @return {Promise<{_id: string, companies: {_id: string, name:string}[]}[]>}
     */
    getUsersCompanies() {
        return this.usersService
            .getUsersCompanies(this.listUsers.concat(this.userSession).map(u => u._id))
            .catch(err => {
                this.getSwalMsg(err.errorCode);
                return Promise.resolve(err.data);
            });
    }

    /**
     * Init pagination object.
     * Load the entire list of users available to count users and compute the number of pages.
     * @return {Promise<number>} the total number of users
     */
    async countNbUsersTotal() {
        const queryWithNoLimit: QueryPaginationUser = {
            lastUserName: null,
            lastUserIndex: 0,
            currentPageIndex: 0,
            selectedPageIndex: 0,
            nbItemsPerPage: 0, // when limit is 0 -> no limit applied
        };
        const allUsers = await this.usersService.getVisibleUsers(
            this.userSession._id,
            queryWithNoLimit,
            this.searchInput
        );
        return allUsers.length;
    }

    /**
     * Called when pagination is updated.
     * Load the updated sites.
     */
    async onPaginationChanged(event = { resetLastItem: false }) {
        if (event.resetLastItem) {
            this.paginationService.updateLastUser(this.pagination.lastUser, []);
        }
        await this.setListAndPagination();
    }

    /**
     * When a new input comes in from the search input.
     * Reload the list of users and pagination.
     * @param {string} input
     * @returns {void}
     */
    onSearchChanged(input: string): void {
        // If we're not still waiting for the previous input
        if (!this.isLoading) {
            this.searchInput = input;

            // reset pagination to first page
            this.paginationService.setFirstPageUser(this.pagination.lastUser);

            // get users to display with the new research value
            this.setListAndPagination();
        }
    }

    /**
     * Sweet alert when something went wrong.
     * @param {string} msg_code
     * @returns {void}
     */
    getSwalMsg(msg_code: string): void {
        const messages = {
            error_getListUsers: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des utlisateurs.',
                'error',
            ],
            error_getListCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des entreprises.',
                'error',
            ],
        };

        if (messages[msg_code]) {
            Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
        } else {
            Swal.fire('Toutes nos excuses', 'Une erreur est survenue pendant le chargement de la page.', 'error');
        }
    }
}
