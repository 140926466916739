import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-highchart-sankey-editable',
    templateUrl: './highchart-sankey-editable.component.html',
    styleUrls: ['./highchart-sankey-editable.component.scss'],
})
export class HighchartSankeyEditableComponent implements OnInit {
    @Input() options: any;
    @Input() legend: any;
    @Input() toggles: any[];
    @Input() columnsUser: any[];
    @Input() sankeyHighchartsData: any[];

    @Output() activeDatasetChange = new EventEmitter();

    columnsAvailable: any[] = [
        {
            label: 'Energies',
            name: 'energyType',
            available: true,
        },
        {
            label: 'Catégories',
            name: 'category',
            available: true,
        },
        {
            label: 'Sous-Catégorie',
            name: 'subcategory',
            available: true,
        },
        {
            label: 'Complément',
            name: 'complement',
            available: true,
        },
        {
            label: 'Natures',
            name: 'nature',
            available: true,
        },
        {
            label: 'Sites',
            name: 'name',
            available: true,
        },
        {
            label: 'Zones',
            name: 'zone',
            available: true,
        },
        {
            label: 'Régions',
            name: 'zipcode',
            available: true,
        },
    ];

    columnsNumberChoices = [
        {
            name: 2,
            display: true,
        },
        {
            name: 3,
            display: false,
        },
    ];
    columnNumber: number;

    downloadFileName = 'chart-sankey.png';

    constructor() {}
    ngOnInit() {
        this.columnsUser.forEach(col => {
            const item = this.columnsAvailable.find(elem => elem.name === col.name);
            item.available = false;
        });
        this.columnNumber = this.columnsUser.length;
        if (this.columnsNumberChoices.find(item => item.name === this.columnNumber)) {
            this.columnsNumberChoices.forEach(col => {
                if (col.name === this.columnNumber) {
                    col.display = true;
                } else {
                    col.display = false;
                }
            });
        }
    }

    private refresh() {
        this.activeDatasetChange.emit();
    }

    getRgbColor(color) {
        return `rgb(${color})`;
    }

    /**
     * Trigger when the user choose a number of columns to display.
     * @param value Number selected
     */
    public setColumnNumber(value) {
        // If the user doesn't select the selected value
        if (!value.display) {
            // Set not displayed for all other buttons
            this.columnsNumberChoices.forEach(col => {
                if (col.display) {
                    col.display = !col.display;
                }
            });
            // Set displayed the selected number
            value.display = !value.display;
            // Get the number
            this.columnNumber = value.name;
            // If reducing number of columns to display
            if (this.columnNumber <= this.columnsUser.length) {
                const size = this.columnsUser.length;
                // Set available for remaining columns all choices removed
                for (let i = this.columnNumber; i < size; i++) {
                    const item = this.columnsAvailable.find(elem => elem.name === this.columnsUser[i].name);
                    item.available = true;
                }
                // Remove columns
                this.columnsUser.splice(this.columnNumber);
            } else {
                // Add the next available choice
                const toAdd = this.columnNumber - this.columnsUser.length;
                for (let i = 0; i < toAdd; i++) {
                    const item = this.columnsAvailable.find(elem => elem.available);
                    this.columnsUser.push({ name: item.name, display: true });
                    item.available = false;
                }
            }
            this.refresh();
        }
    }

    /**
     * Change the data type
     * @param value chose by the user
     */
    public setActiveToggle(value) {
        const others = this.toggles.filter(i => i.name !== value.name);
        value.display = true;
        others.forEach(item => {
            item.display = false;
        });
        this.activeDatasetChange.emit();
    }

    public getClass(value) {
        return value.display ? 'btn-primary' : 'btn-white';
    }

    /**
     * When a column is updated, update all the available choices.
     */
    public updateColumns() {
        this.columnsAvailable.forEach(item => {
            const temp = this.columnsUser.find(col => col.name === item.name);
            if (temp === undefined) {
                item.available = true;
            } else {
                item.available = false;
            }
        });
        this.refresh();
    }

    /**
     * Get the column label from the type
     * @param name type name of the columns
     * @returns {any} Label of the columns
     */
    public getLabel(name) {
        const res = this.columnsAvailable.find(item => item.name === name);
        return res === undefined ? name : res.label;
    }

    /**
     * Get the class for columns selects. Depend on the number of columns to display.
     * @returns {string}
     */
    public getClassSelects() {
        return 'col-md-' + 12 / this.columnNumber;
    }

    downloadChart(event) {
        const anchor = event.target;
        anchor.href = event.target.parentElement.parentElement.parentElement
            .getElementsByTagName('canvas')[0]
            .toDataURL();
        anchor.download = 'graphique.png';
    }
}
