import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChange,
    ViewChild,
} from '@angular/core';
import { FilterComponent } from '../filter/filter.component';

@Component({
    selector: 'ga-full-filter',
    templateUrl: './full-filter.component.html',
    styleUrls: ['./full-filter.component.scss'],
})
export class FullFilterComponent implements OnInit, OnChanges {
    @ViewChild(FilterComponent, { static: true }) SubFilter;

    @Input() filterName: string;
    @Input() subtitle: string;
    @Input() refresh = false;

    /**
     * Class name on filters html objects. Used mainly for columns management.
     * Applied to every filter item.
     */
    @Input() filterClassName: string;

    @Output() querySearch: EventEmitter<any> = new EventEmitter();
    public searchBtnClassName = 'searchBtn';

    public error = '';

    constructor() {}
    ngOnInit() {}

    ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        // Relaunch the filters with the current session values -> reload page by sending event
        for (const propName in changes) {
            if (propName === 'refresh' && this.refresh) {
                // refresh is set when the company logged in has changed --> need to reload the filters accordingly
                this.sendInitEvent();
            }
        }
    }

    public sendSearchEvent() {
        this.SubFilter.search();
    }

    public sendInitEvent() {
        this.SubFilter.ngOnInit();
    }

    public search(query) {
        this.querySearch.emit(query);
    }

    public updateError(text) {
        this.error = text;
    }
}
