import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

// interfaces
import { Legend, LegendConfig } from 'app/shared/components/legends/legend.interface';

// services
import { PaginationBackService } from 'app/shared/components/pagination/back/pagination-back.service';
import { BillsControlService } from 'app/shared/services/bills/bills-control.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { UploadService } from 'app/shared/services/files/upload.service';
import { SessionService } from 'app/shared/services/session/session.service';

// components
import { ModalComponent } from 'app/shared/components/common/modal/modal.component';
import { BillChunkDisplay, BillDisplay } from 'app/shared/models/bills-controls.interface';

@Component({
    selector: 'ga-bills-table-control',
    templateUrl: './bills-table-control.component.html',
    styleUrls: ['./bills-table-control.component.scss', './../../../bill-verification.scss'],
})
export class BillsTableControlComponent implements OnInit {
    private _billsList: BillDisplay[] = [];

    /**
     * Creation/Edit modal
     */
    @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;

    @Input() billsLoading: boolean;

    @Input() legends: Legend[];
    @Output() sortChanged: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    set billsList(list) {
        this.initCollapseMulti(list);
        this.setBillPriorityDifferenceRatio(list);
        this._billsList = list;
    }

    get billsList() {
        return this._billsList;
    }

    @Input() filtersQueryString: string;

    billsSelectedList: BillDisplay[] = [];

    _bill: BillDisplay;
    _chunk: BillChunkDisplay;
    _modalTitle: string;

    public exportUrl = '/api/export/excel/bills-control';

    /**
     * Set config attributes for the legend component
     */
    public legendConfig: LegendConfig = {
        alignement: {
            enabled: true,
            value: 'right',
        },
    };

    constructor(
        private billsControlService: BillsControlService,
        private energyService: EnergyService,
        private sessionService: SessionService,
        private uploadService: UploadService,
        private paginationService: PaginationBackService
    ) {}

    ngOnInit() {
        // pagination default direction is descendant: sort with highest values first
        this.paginationService.defaultSortAsc = false;
    }

    /**
     * ************ Init data after search ************
     */

    /**
     * Initialise the collapse of each bill in the given list:
     * Set the collapse of each bill from its state in the current list (if present in the current list).
     * @param {BillDisplay[]} bills
     */
    initCollapseMulti(bills: BillDisplay[]): void {
        bills.forEach(bill => {
            if (bill.multi) {
                const billAlreadyPresent = this.billsList.find(b => b.upload === bill.upload);
                bill.isExpanded = billAlreadyPresent ? billAlreadyPresent.isExpanded : false;
            } else {
                bill.isExpanded = false;
            }
        });
    }

    /**
     * Set the priority of each bill and chunk.
     * Also compute and set the value of each bill's global difference and ratio.
     * @param {BillDisplay[]} bills
     */
    setBillPriorityDifferenceRatio(bills: BillDisplay[]): void {
        bills.forEach(bill => {
            if (bill.chunks.length) {
                bill.chunks.forEach(chunk => {
                    // filter verifications and keep only those from the config
                    chunk.verifications = this.billsControlService.filterBillVerifications(
                        chunk.fluid,
                        chunk.verifications
                    );
                    // compute priority from the chunk's verification
                    chunk.priority = this.billsControlService.getChunkPriorityFromRecap(chunk.controlsRecap);
                });

                // set the bill's priority for non multi bills
                if (!bill.multi) {
                    bill.priority = this.billsControlService.getChunkPriorityFromRecap(bill.chunks[0].controlsRecap);
                }
            }
        });
    }

    /**
     * ******************************************************
     * ******************** Select bills ********************
     * ******************************************************
     */

    /**
     * Returns true if all the bills displayed are selected.
     * @return {boolean}
     */
    isEveryBillSelected(): boolean {
        return Boolean(
            this.billsSelectedList.length && this.billsList.every(bill => this.isAlreadySelected(bill.upload))
        );
    }

    /**
     * Returns true if the upload ID sent is already in the selected bills list */
    isAlreadySelected(billIdToCompare): boolean {
        return this.billsSelectedList.some(b => b.upload.toString() === billIdToCompare.toString());
    }

    /** Called when the selectbox "all" is checked or not */
    onClickSelectAll(): void {
        if (!this.isEveryBillSelected()) {
            this.selectAllBills();
        } else {
            this.deselectAllBills();
        }
    }

    /** Add to the selected bills list all the bills currently displayed in the table */
    selectAllBills(): void {
        this.billsList.forEach(bill => {
            if (!this.isAlreadySelected(bill.upload)) {
                this.billsSelectedList.push(bill);
            }
        });
    }

    /** Remove from the selected bills all the bills currently displayed in the list */
    deselectAllBills(): void {
        this.billsList.forEach(bill => {
            if (this.isAlreadySelected(bill.upload)) {
                const indexAlreadySelected = this.billsSelectedList.findIndex(
                    b => b.upload.toString() === bill.upload.toString()
                );
                this.billsSelectedList.splice(indexAlreadySelected, 1);
            }
        });
    }

    /** Cursor pointer of some bills are selected */
    getIconCursorClass(): string {
        return this.hasBillSelected() ? 'cursor-pointer' : '';
    }

    /** Returns the number of bills selected */
    displayNbBillsSelected(): string {
        if (this.hasBillSelected()) {
            const count = this.billsSelectedList.length;
            return count.toString() + (count > 1 ? ' factures sélectionnées' : ' facture sélectionnée');
        }
    }

    /** Returns true if at least one bill is selected */
    hasBillSelected(): boolean {
        return Boolean(this.billsSelectedList && this.billsSelectedList.length);
    }

    /**
     * ******************************************************
     * ********************** Download **********************
     * ******************************************************
     */

    /**
     * Download the selected bills.
     * For those which failed, create a report in a modal with their filenames formated.
     */
    async onDownloadMultiSelection(): Promise<void> {
        this.uploadService.downloadAndReportMulti(this.billsSelectedList);
    }

    /**
     * Download the given bill.
     * @param {BillDisplay} bill
     */
    async onSingleDownload(bill: BillDisplay): Promise<void> {
        try {
            this.uploadService.downloadBill(bill);
        } catch (e) {
            const filename = this.uploadService.getFilenameFromBill(bill);
            Swal.fire(
                'Toutes nos excuses',
                `Le fichier ${filename} est inaccessible pour le moment. Veuillez réessayer ultérieurement.`,
                'warning'
            );
        }
    }

    /**
     * *****************************************************
     * *********************** Bills ***********************
     * *****************************************************
     */

    /**
     * Add the bill to the selected bills list if it's not there remove the bill from the list otherwise
     * @param {BillDisplay} bill
     */
    switchBillSelection(bill: BillDisplay): void {
        const indexAlreadySelected = this.billsSelectedList.findIndex(
            b => b.upload.toString() === bill.upload.toString()
        );
        // If the bill was selected --> unselected it
        if (indexAlreadySelected !== -1) {
            this.billsSelectedList.splice(indexAlreadySelected, 1);
        } else {
            this.billsSelectedList.push(bill);
        }
    }

    /**
     * Collapse if bill is multi to display all the bills
     * @param {BillDisplay} bill
     */
    toggleCollapse(bill: BillDisplay): void {
        bill.isExpanded = !bill.isExpanded;
    }

    /**
     * Returns MULTI or SIMPLE depending on the bill's type
     * @param {BillDisplay} bill
     * @returns {string}
     */
    getType(bill: BillDisplay): string {
        return bill.multi ? 'multi' : 'simple';
    }

    /**
     * Return a class depending on the bill's type
     * @param {BillDisplay} bill
     * @returns {string}
     */
    getTypeClass(bill: BillDisplay): string {
        return bill.multi ? 'table__col-type-multi cursor-pointer' : 'table__col-type-simple';
    }

    /**
     * Get the bill reference
     *
     * @param {BillDisplay} bill
     * @param {BillChunkDisplay} chunk
     * @returns {string}
     */
    getBillNumber(bill: BillDisplay, chunk: BillChunkDisplay = null): string {
        return !bill.multi
            ? bill.chunks[0] && bill.chunks[0].bill.reference
            : bill.isExpanded && chunk
            ? chunk.bill.reference
            : bill.chunks[0].bill.reference;
    }

    /**
     * Get the contract reference associated to the bill
     *
     * @param {BillDisplay} bill
     * @param {BillChunkDisplay} chunk
     * @returns {string}
     */
    getContractNumber(bill: BillDisplay, chunk: BillChunkDisplay = null): string {
        return !bill.multi
            ? bill.chunks[0] && bill.chunks[0].contract.reference
            : bill.isExpanded && chunk
            ? chunk.contract.reference
            : bill.chunks[0].contract.reference;
    }

    /**
     * Returns the routing reference number / vehicle immat of the bill / the chunk / nothing
     * The bill must have passed validation. Required fields include: "chunks.routingReference"|"chunks.vehicle"
     * @param {BillDisplay} bill
     * @param {BillChunkDisplay|null} chunk
     * @returns {string} routing reference number / vehicle immat of the bill/the chunk / nothing
     * */
    getSourceReference(bill: BillDisplay, chunk: BillChunkDisplay = null): string {
        if (!chunk) {
            if (!bill.multi && bill.chunks[0]) {
                chunk = bill.chunks[0];
            } else {
                return '';
            }
        }

        const field = this.energyService.getFluidSource(chunk.fluid);

        if (!field) {
            return '';
        }

        return !bill.multi
            ? bill.chunks[0] && bill.chunks[0][field].reference
            : bill.isExpanded && chunk
            ? chunk[field].reference
            : '';
    }

    /**
     * Check if the multi bill is collapsed to change the arrow icon rotation
     * @param {BillDisplay} bill
     * @returns {string}
     */
    getCollapseClass(bill: BillDisplay): string {
        return bill.isExpanded ? 'fa-rotate-90' : '';
    }

    /**
     * Display chunks if bill is multi
     * @param {BillDisplay} bill
     * @returns {boolean}
     */
    isDisplayingChunks(bill: BillDisplay): boolean {
        return bill.multi && bill.isExpanded;
    }

    /**
     * Get the bill energy type (gaz, elec...)
     * @param {BillDisplay} bill
     * @returns {string}
     */
    getEnergyType(bill: BillDisplay): string {
        if (!bill.multi) {
            return bill.chunks[0].fluid;
        }
    }

    /**
     * *****************************************************
     * ******************* Bills control *******************
     * *****************************************************
     */

    /**
     * Open the modal that display the bill controls
     *
     * @param {BillDisplay} bill
     * @param {BillChunkDisplay} chunk
     */
    openModal(bill: BillDisplay, chunk: BillChunkDisplay = null): void {
        this._bill = bill;
        this._chunk = chunk;
        this._modalTitle = `Facture n°${bill.chunks[0].bill.reference}`;

        this.modal.show();
    }

    /**
     * Close the modal
     */
    public closeModal(): void {
        this.modal.close();
    }

    get isModalShown(): boolean {
        return this.modal.isShown;
    }

    /**
     * get the rgb color of the legend by given value
     * @param {*} val
     * @returns {string}
     */
    getRgbColorByValue(val: any): string {
        const legend = this.legends.find(item => item.values && item.values.includes(val));
        return legend ? `rgb(${legend.color})` : null;
    }

    /**
     * Call the service to return the icon based on the priority number
     *
     * @param {number} priority
     * @returns {string}
     */
    getPriorityIcon(priority: number): string {
        return this.billsControlService.getPriorityIcon(priority);
    }

    /**
     * *****************************************************
     * ******************* Table sort *******************
     * *****************************************************
     */

    /**
     * Update the sort caret class according to the user action.
     * @param {string} header
     * @returns {string[]} css classes
     */
    getClass(header: string): string[] {
        return this.paginationService.getClassesSort(header);
    }

    /**
     * Handle sort click to update the contracts list accordingly.
     * @param {string} header column header selected for sort
     */
    onSort(header: string): void {
        this.paginationService.onSort(header);
        this.sortChanged.emit();
    }

    /**
     * Check if the bills list has data
     *
     * @returns {boolean}
     */
    hasData() {
        return this.billsList.length > 0;
    }
}
