import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/guards/auth-guard.service';
import { CanDeactivateGuard } from '../shared/services/guards/can-deactivate-guard.service';
import { LayoutComponent } from './layout.component';

const LAYOUT_ROUTES: Routes = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard],
        children: [
            {
                path: '',
                redirectTo: 'accueil',
                pathMatch: 'full',
            },
            {
                path: 'accueil',
                loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule),
            },
            {
                path: 'cartographie',
                loadChildren: () => import('../pages/cartography/cartography.module').then(m => m.CartographyModule),
            },

            /* ANALYSE DES DONNEES */
            {
                path: 'analyse/croisement',
                loadChildren: () =>
                    import('../pages/analyse/cross-data/cross-data.module').then(m => m.CrossDataModule),
            },
            {
                path: 'analyse/comparaison',
                loadChildren: () => import('../pages/analyse/compare/compare.module').then(m => m.CompareModule),
            },

            /* COLLECTE */
            {
                path: 'collecte',
                loadChildren: () => import('../pages/collect/collect.module').then(m => m.CollectModule),
            },
            {
                path: 'collecte/:groupId',
                loadChildren: () => import('../pages/collect/collect.module').then(m => m.CollectModule),
            },
            {
                path: 'collecte/regex/upload/:uploadId',
                loadChildren: () =>
                    import('../pages/fix-regex-upload/fix-regex-upload.module').then(m => m.FixRegexUploadModule),
            },
            {
                path: 'collecte/regex/upload/:uploadId/:chunkId',
                loadChildren: () =>
                    import('../pages/fix-regex-upload/chunk/fix-regex-upload-chunk.module').then(
                        m => m.FixRegexUploadChunkModule
                    ),
            },
            {
                path: 'collecte/regex/propriete/:groupId/:property/:energy/:provider/:contractType',
                loadChildren: () =>
                    import('../pages/fix-regex-property/fix-regex-property.module').then(m => m.FixRegexPropertyModule),
            },

            /* ENERGY */
            {
                path: 'energie',
                redirectTo: 'energie/pdl',
            },
            {
                path: 'energie/pdl',
                loadChildren: () => import('../pages/energy/sites-list/sites-list.module').then(m => m.SitesListModule),
                data: { fluidCategory: 'energy', page: 'energy' },
            },
            {
                path: 'energie/pdl/elec/:rfId',
                loadChildren: () => import('../pages/energy/pdl-view/elec/elec.module').then(m => m.ElecPdlViewModule),
            },
            {
                path: 'energie/pdl/gaz/:rfId',
                loadChildren: () => import('../pages/energy/pdl-view/gas/gas.module').then(m => m.GasPdlViewModule),
            },
            {
                path: 'energie/pdl/rcu/:rfId',
                loadChildren: () =>
                    import('../pages/energy/pdl-view/heating/heating.module').then(m => m.HeatingPdlViewModule),
            },
            {
                path: 'energie/pdl/rfu/:rfId',
                loadChildren: () =>
                    import('../pages/energy/pdl-view/cooling/cooling.module').then(m => m.CoolingPdlViewModule),
            },
            {
                path: 'energie/pdl/elec/:rfId/:contractId',
                loadChildren: () => import('../pages/energy/pdl-view/elec/elec.module').then(m => m.ElecPdlViewModule),
            },
            {
                path: 'energie/pdl/gaz/:rfId/:contractId',
                loadChildren: () => import('../pages/energy/pdl-view/gas/gas.module').then(m => m.GasPdlViewModule),
            },
            {
                path: 'energie/pdl/rcu/:rfId/:contractId',
                loadChildren: () =>
                    import('../pages/energy/pdl-view/heating/heating.module').then(m => m.HeatingPdlViewModule),
            },
            {
                path: 'energie/pdl/rfu/:rfId/:contractId',
                loadChildren: () =>
                    import('../pages/energy/pdl-view/cooling/cooling.module').then(m => m.CoolingPdlViewModule),
            },
            {
                path: 'energie/suivi',
                loadChildren: () => import('../pages/energy/followup/followup.module').then(m => m.FollowupModule),
                data: { fluidCategory: 'energy', page: 'energy' },
            },
            {
                path: ':fluidCategory/profil/:id',
                loadChildren: () => import('../pages/energy/profiles/profiles.module').then(m => m.ProfilesModule),
            },
            {
                path: 'energie/classement',
                loadChildren: () => import('../pages/energy/ranking/ranking.module').then(m => m.RankingModule),
                data: { fluidCategory: 'energy', page: 'energy' },
            },
            {
                path: 'energie/optimisation',
                loadChildren: () =>
                    import('../pages/energy/optimization/optimization.module').then(m => m.OptimizationModule),
            },
            {
                path: ':fluidCategory/merge/:siteId',
                loadChildren: () => import('../pages/energy/merge/merge.module').then(m => m.MergeModule),
            },
            {
                path: ':fluidCategory/merge/rf/:routingReferenceId',
                loadChildren: () => import('../pages/energy/merge/merge.module').then(m => m.MergeModule),
            },
            {
                path: 'energie/repartition',
                loadChildren: () =>
                    import('../pages/energy/repartition/repartition.module').then(m => m.RepartitionModule),
            },
            {
                path: 'energie/courbes-de-charge',
                loadChildren: () => import('../pages/energy/load-curve/load-curve.module').then(m => m.LoadCurveModule),
            },

            /* WATER */
            {
                path: 'eau',
                redirectTo: 'water/pdl',
            },
            {
                path: 'eau/pdl',
                loadChildren: () => import('../pages/energy/sites-list/sites-list.module').then(m => m.SitesListModule),
                data: { fluidCategory: 'water', page: 'water' },
            },
            {
                path: 'eau/pdl/:rfId',
                loadChildren: () =>
                    import('../pages/energy/pdl-view/water/water.module').then(m => m.WaterPdlViewModule),
            },
            {
                path: 'eau/pdl/:rfId/:contractId',
                loadChildren: () =>
                    import('../pages/energy/pdl-view/water/water.module').then(m => m.WaterPdlViewModule),
            },
            {
                path: 'eau/classement',
                loadChildren: () => import('../pages/energy/ranking/ranking.module').then(m => m.RankingModule),
                data: { fluidCategory: 'water', page: 'water' },
            },
            {
                path: 'eau/suivi',
                loadChildren: () => import('../pages/energy/followup/followup.module').then(m => m.FollowupModule),
                data: { fluidCategory: 'water', page: 'water' },
            },

            /* VEHICLES */
            {
                path: 'vehicules/flotte',
                loadChildren: () => import('../pages/energy/sites-list/sites-list.module').then(m => m.SitesListModule),
                data: { fluidCategory: 'vehicle', page: 'vehicles' },
            },
            {
                // TODO: see if we can emrge with energy FollowupModule
                path: 'vehicules/suivi',
                loadChildren: () => import('../pages/vehicles/followup/followup.module').then(m => m.FollowUpModule),
            },
            {
                path: 'vehicules/parc',
                loadChildren: () =>
                    import('../pages/vehicles/park-monitoring/park-monitoring.module').then(
                        m => m.ParkMonitoringModule
                    ),
            },

            /* CONTRATS */
            {
                path: 'contrats',
                loadChildren: () => import('../pages/contracts/contracts.module').then(m => m.ContractsModule),
            },

            /* RSE */
            {
                path: 'rse',
                loadChildren: () => import('../pages/rse/rse.module').then(m => m.RseModule),
            },

            /* RULES */

            {
                path: 'veille',
                loadChildren: () => import('../pages/rules/rules.module').then(m => m.RulesModule),
            },

            /* MANAGERS */
            {
                path: 'managers',
                loadChildren: () => import('../pages/managers/managers.module').then(m => m.ManagersModule),
            },
            {
                path: 'managers/echange',
                loadChildren: () => import('../pages/managers/exchange/exchange.module').then(m => m.ExchangeModule),
            },
            {
                path: 'managers/devis',
                loadChildren: () => import('../pages/managers/devis/devis.module').then(m => m.DevisModule),
            },

            /* PROFILE */
            {
                path: 'profil',
                loadChildren: () => import('../pages/profile/profile.module').then(m => m.ProfileModule),
            },
            {
                path: 'profil/compte',
                loadChildren: () => import('../pages/profile/account/account.module').then(m => m.AccountModule),
            },
            {
                path: 'profil/em',
                loadChildren: () => import('../pages/profile/em/em.module').then(m => m.EmModule),
            },
            {
                path: 'profil/utilisateurs',
                loadChildren: () => import('../pages/profile/users/users.module').then(m => m.UsersModule),
            },
            {
                path: '404',
                loadChildren: () => import('../pages/404/not-found.module').then(m => m.NotFoundModule),
            },

            /* ADMIN */

            /*  -- ENTREPRISES */
            {
                path: 'admin/entreprises',
                loadChildren: () =>
                    import('../pages/admin/companies-home/companies-home.module').then(m => m.AdminCompaniesModule),
            },
            {
                path: 'admin/entreprise/:companyId',
                redirectTo: 'admin/entreprise/:companyId/profil',
                loadChildren: () => import('../pages/admin/company/company.module').then(m => m.CompanyModule),
            },
            {
                path: 'admin/entreprise/:id/uploads/:idUpload',
                loadChildren: () =>
                    import('../pages/admin/identification-pattern/identification-pattern.module').then(
                        m => m.IdentificationPatternModule
                    ),
            },
            {
                path: 'admin/entreprise/:id/uploads/:idUpload/chunks/:idChunk',
                loadChildren: () =>
                    import('../pages/admin/identification-pattern/identification-pattern.module').then(
                        m => m.IdentificationPatternModule
                    ),
            },
            {
                path: 'admin/entreprises/creer',
                loadChildren: () => import('../pages/admin/company/company.module').then(m => m.CompanyModule),
            },
            {
                path: 'admin/entreprise/:companyId/:tab',
                loadChildren: () => import('../pages/admin/company/company.module').then(m => m.CompanyModule),
            },

            /*  -- UTILISATEURS */
            {
                path: 'admin/utilisateurs',
                loadChildren: () => import('../pages/admin/users-home/users-home.module').then(m => m.AdminUsersModule),
            },
            {
                path: 'admin/utilisateur/:userId',
                loadChildren: () => import('../pages/admin/user/user.module').then(m => m.UserModule),
            },
            {
                path: 'admin/utilisateurs/creer',
                loadChildren: () => import('../pages/admin/user/user.module').then(m => m.UserModule),
            },
            {
                path: 'admin/utilisateurs/creer/:companyId',
                loadChildren: () => import('../pages/admin/user/user.module').then(m => m.UserModule),
            },
            {
                path: 'admin/utilisateur/:userId/:tab',
                loadChildren: () => import('../pages/admin/user/user.module').then(m => m.UserModule),
            },

            /* SPECIFIC CLIENT */
            /* RESAH */
            {
                path: 'specific/resah/cotisation',
                loadChildren: () =>
                    import('../pages/specific/resah/cotisation/cotisation.module').then(m => m.CotisationModule),
            },

            /* DOCUMENTS */
            {
                path: 'documents',
                loadChildren: () => import('../pages/documents/documents.module').then(m => m.DocumentsModule),
            },

            /* CAMPAIGNS */
            {
                path: 'cibles/campagnes',
                loadChildren: () => import('../pages/targets/campaigns/campaigns.module').then(m => m.CampaignsModule),
            },
            {
                path: 'cibles/suivis-exploitation',
                loadChildren: () =>
                    import('../pages/targets/operating-monitoring/operating-monitoring.module').then(
                        m => m.OperatingMonitoringModule
                    ),
            },

            /* BILLS */
            {
                path: 'factures',
                loadChildren: () => import('../pages/bills/bills.module').then(m => m.BillsModule),
            },

            /* BILLS CONTROL */
            {
                path: 'controle/:tab',
                loadChildren: () =>
                    import('../pages/bills-control/bills-control.module').then(m => m.BillsControlModule),
            },

            /* DJU */
            {
                path: 'dju',
                loadChildren: () => import('../pages/dju/dju.module').then(m => m.DjuModule),
            },

            /* ALERTS MANAGEMENT */
            {
                path: 'parametrage-alertes',
                loadChildren: () =>
                    import('../pages/alerts-management/alerts-management.module').then(m => m.AlertsManagementModule),
            },
        ],
    },
];

// Keep export const instead of export default. Not supported for router to work it seems...
export const LayoutRoutes = RouterModule.forChild(LAYOUT_ROUTES);
