import { Injectable } from '@angular/core';
import { ApiService } from '../../../../shared/services/api/api.service';
import { PageService } from '../../../../shared/services/page/page.service';

@Injectable()
export class CotisationService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    getCotisationData() {
        return this.get('/api/specific/resah/procedures');
    }

    getSiteConsumption(site, dates) {
        const options = {
            sites: site,
            dateStart: dates.startDate.substr(0, 7),
            dateEnd: dates.endDate.substr(0, 7),
            energy: 'elec,gaz',
        };
        return this.post('/api/specific/resah/cotisation', options);
    }

    getAvailableYears() {
        const companyId = this.apiService.sessionService.getCompanyId();
        return this.get('/api/contracts/dates/' + companyId);
    }

    convertConsumption(value, unitConso, unitDestination) {
        let factor = 1;
        let conso = 0;

        if (unitDestination === 'GWh') {
            factor = factor / 1000000;
        } else if (unitDestination === 'MWh') {
            factor = factor / 1000;
        }

        if (unitConso === 'kWh') {
            conso = value * factor;
        } else if (unitConso === 'MWh') {
            conso = value * factor * 1000;
        } else if (unitConso === 'GWh') {
            conso = value * factor * 1000000;
        } else {
            conso = value;
        }
        return conso;
    }
}
