import { HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Site } from '../../models/site.interface';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

@Injectable()
export class ProfilesService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    getSiteProfile(siteId): Promise<any> {
        return this.get('/api/sites/profile/' + siteId);
    }

    /**
     * Update site data. Remove status property from data before sending.
     * @param siteId - site id to save
     * @param data - site data to save
     * @returns {Promise<*>} new site data
     */
    async updateSite(siteId: string, data: any): Promise<any> {
        delete data.status;
        await this.put(`/api/sites/crud/${siteId}`, data);
        return this.getSiteProfile(siteId);
    }

    async getSiteProperties(): Promise<any> {
        const response = await this.get('/api/sites/available/properties');
        return response.data;
    }

    async deleteRoutingReference(siteId, routingReferenceId): Promise<any> {
        await this.delete(`/api/sites/${siteId}/reference/${routingReferenceId}`);
        return this.getSiteProfile(siteId);
    }

    fakeData(route) {
        let response;

        switch (route) {
            default:
                response = {};
                break;
        }

        return response;
    }

    /**
     * Upload new site's picture to API.
     * @param {File} file - file to set as the new site's picture
     * @param {string} siteId - site id to upload the new picture to
     * @returns {Observable<HttpEvent<*>>} Observable with HttpEvent
     */
    updateSitePicture(file: File, siteId: string): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const options = {
            reportProgress: true,
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: 'Basic ' + this.apiService.getToken(),
            }),
        };
        const url = `/api/sites/${siteId}/picture`;
        return this.apiService.upload(url, formData, options);
    }

    /**
     * Delete site's picture
     * @param siteId - site id to delete the picture from
     * @returns {Promise<*>} promise containing the site updated in property 'data'
     */
    deleteSitePicture(siteId: string): Promise<any> {
        const route = `/api/sites/${siteId}/picture`;
        return this.delete(route);
    }

    /**
     * Update site meteo information
     */
    async updateSiteMeteoInformation(
        siteId: string,
        data: {
            meteoStationId?: string;
            temperatureReference?: number;
            temperatureReferenceCold?: number;
            method?: string;
        }
    ): Promise<Site> {
        const resp = await this.patch(`/api/sites/${siteId}/meteo-information`, data);
        return resp.data;
    }
}
