import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { PageService } from '../../../shared/services/page/page.service';

@Injectable()
export class MergeService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    getRoutingReference(routingReferenceId): Promise<any> {
        return this.get('/api/routing-references/crud/' + routingReferenceId);
    }

    searchLocation(search): Promise<any> {
        return this.post('/api/sites/search', { search });
    }

    saveLocation(entity, newAddress): Promise<any> {
        let url = `/api/sites/${entity.id}/location`;
        if (!entity.isSite) {
            url = `/api/routing-references/${entity.id}/location`;
        }
        return this.put(url, newAddress);
    }

    fakeData(route) {
        let response;

        switch (route) {
            default:
                response = {};
                break;
        }

        return response;
    }
}
