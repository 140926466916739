import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyAdmin } from 'app/shared/models/company.interface';
import { KeyFigures } from 'app/shared/models/key-figure.interface';
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { UsersService } from 'app/shared/services/users/users.service';
import Swal from 'sweetalert2';
import { AdminCompanyService } from '../company/company.service';

@Component({
    selector: 'ga-admin-companies',
    templateUrl: './companies-home.component.html',
    styleUrls: ['./companies-home.component.scss'],
    providers: [],
})
export class AdminCompaniesComponent implements OnInit {
    listCompanies: CompanyAdmin[] = [];
    listKeyFigures: KeyFigures[] = [];
    listSubTexts: Array<{
        label: string;
        property: string;
    }> = [];
    userSession: any;

    constructor(
        private adminCompanyService: AdminCompanyService,
        private companiesService: CompaniesService,
        private router: Router,
        private sessionService: SessionService,
        private usersService: UsersService
    ) {}

    ngOnInit() {
        this.userSession = this.sessionService.getUser();
        this.checkAccessToPage();

        this.initializeData();

        this.listSubTexts = [
            {
                label: 'Nombre de pdls',
                property: 'nbPdl',
            },
        ];
    }

    /**
     * Standard users don't have access to this page. Double check in addition to pages rights.
     */
    checkAccessToPage() {
        if (this.usersService.isUser(this.userSession)) {
            this.router.navigateByUrl('accueil');
        }
    }

    /**
     * Initialize data to display companies and keyfigures
     * @returns {Promise<void>}
     */
    async initializeData(): Promise<void> {
        // if user is super citron or energy manager -> don't filter, display all the companies
        // if user is type standard user or useradmin --> filter and display only the companies they belong to
        try {
            // Get companies
            const allCompanies = await this.companiesService.getVisibleCompaniesForUser();
            this.listCompanies = allCompanies ? allCompanies : [];
            this.listCompanies = this.listCompanies.sort(this.sortListAlphabetically);

            // Get keyfigures
            const allKeyFigures = await this.adminCompanyService.getAllKeyFigures();
            this.listKeyFigures = allKeyFigures ? allKeyFigures : [];

            this.initializeCompaniesList();
        } catch (err) {
            // er.error_code is set to error_getListCompanies
            this.getSwalMsg(err.errorCode);
        }
    }

    sortListAlphabetically(companyA, companyB) {
        const a = companyA.name;
        const b = companyB.name;
        return a > b ? 1 : a < b ? -1 : 0;
    }

    /**
     * Set companies number of PDLs and and logo
     */
    initializeCompaniesList() {
        this.listCompanies.forEach(company => {
            const keyFigure = this.listKeyFigures.find(companyKeyFig => companyKeyFig.companyId === company._id);
            company.nbPdl = keyFigure ? keyFigure.pdlActive : 0;
        });
    }

    // sweet alert
    getSwalMsg(msg_code) {
        const messages = {
            error_getListCompanies: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des enterprises.',
                'error',
            ],
            error_getKeyFigures: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement du nombre de PDLs.',
                'error',
            ],
            default: ['Toutes nos excuses', 'Une erreur est survenue. Veuillez réessayer ultérieurement.', 'error'],
        };
        if (!msg_code || messages[msg_code]) {
            msg_code = 'default';
        }

        Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
    }
}
