import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { AccessGuard } from '../../../shared/services/guards/access-guard.service';
import { FixRegexUploadChunkComponent } from './fix-regex-upload-chunk.component';

const REGEX_ROUTE = [
    {
        path: '',
        component: FixRegexUploadChunkComponent,
        canActivate: [AccessGuard],
        data: { redirectionForbiddenUrl: '/collecte', page: 'collect' },
    },
];

@NgModule({
    declarations: [FixRegexUploadChunkComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(REGEX_ROUTE)],
})
export class FixRegexUploadChunkModule {}
