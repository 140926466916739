import { Component } from '@angular/core';
import { SitePopulated } from 'app/shared/models/site.interface';
import { Vehicle } from 'app/shared/models/vehicle.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { VehiclesService } from 'app/shared/services/vehicles/vehicles.service';
import { EntitiesAttributionComponent } from '../entity-attribution.component';

@Component({
    selector: 'ga-company-vehicles-tab',
    templateUrl: '../entity-attribution.component.html',
    styleUrls: ['../entity-attribution.component.scss'],
    providers: [],
})
export class CompanyVehiclesComponent extends EntitiesAttributionComponent<Vehicle> {
    constructor(
        public energyService: EnergyService,
        public sitesService: SitesService,
        private vehiclesService: VehiclesService
    ) {
        super(energyService, sitesService);
    }

    async getEntities(): Promise<Vehicle[]> {
        return this.vehiclesService.getVehicles({ companyId: this.company._id });
    }

    getSiteEntities(site: SitePopulated): Vehicle[] {
        return site && site.vehicles ? site.vehicles : [];
    }

    setSiteEntities(site: SitePopulated, entities: Vehicle[]): void {
        site.vehicles = entities;
    }

    get searchProperties(): string[] {
        return ['registrationNumber', 'name', '_id', 'fuelType'];
    }

    isEntityFiltered(entity: Vehicle, filters: { entities: string; fluid: string }) {
        // Don't apply specific filters on entities name or fluid
        return true;
    }

    getEntityTitle(entity: Vehicle): string {
        return entity.registrationNumber;
    }

    getEntityIcon(entity: Vehicle): string {
        return 'fuel-icon';
    }

    getEntitySubtitle1(entity: Vehicle): string {
        return entity.name;
    }

    getEntitySubtitle2(entity: Vehicle): string {
        return '';
    }

    get entityKeyPlural(): string {
        return 'vehicles';
    }

    get searchPlaceholder(): string {
        return 'Immatriculation';
    }

    get entityType(): 'vehicles' {
        return 'vehicles';
    }

    async getFluids() {
        return this.energyService.getAllVehicleFluids();
    }
}
