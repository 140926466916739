import { Component, Input, OnInit } from '@angular/core';
import { KeyFigures } from '../../../models/key-figure.interface';
import { KeyFigureModel } from './key-figure.model';
@Component({
    selector: 'ga-grid-key-figures',
    templateUrl: './grid-key-figures.component.html',
    styleUrls: ['./grid-key-figures.component.scss'],
    providers: [],
})
export class GridKeyFiguresComponent implements OnInit {
    @Input() keyFigures: KeyFigures;
    color = '#5BAF4D';
    mode: string;
    value: number;
    bufferValue: number;
    list_keyfigures: Array<{ left: KeyFigureModel; right: KeyFigureModel }>;

    constructor() {}

    ngOnInit(): void {
        this.setKeyValues();
    }

    private setKeyValues() {
        const pdlsActive = new KeyFigureModel(
            'picto_pdl',
            this.keyFigures.pdlActive,
            'Point de livraison (PDL)',
            'Points de livraison (PDL)',
            this.keyFigures.pdl - this.keyFigures.pdlActive
        );
        const sitesActive = new KeyFigureModel(
            'picto_pin',
            this.keyFigures.sitesActive,
            'Site',
            'Sites',
            this.keyFigures.sites - this.keyFigures.sitesActive
        );

        const surface = new KeyFigureModel(
            'picto_ruler',
            this.keyFigures.surface,
            'Surface totale (m²)',
            'Surface totale (m²)'
        );
        const bat_tertiaires = new KeyFigureModel(
            'picto_building_2',
            this.keyFigures.bat_tertiaires,
            'Bâtiment tertiaire',
            'Bâtiments tertiaires'
        );

        const bat_industriels = new KeyFigureModel(
            'picto_industry',
            this.keyFigures.bat_industriels,
            'Bâtiment industriel',
            'Bâtiments industriels'
        );
        const bat_residentiels = new KeyFigureModel(
            'picto_home',
            this.keyFigures.bat_residentiels,
            'Bâtiment résidentiel',
            'Bâtiments résidentiels'
        );

        const veh_fonction = new KeyFigureModel(
            'picto_car',
            this.keyFigures.veh_fonction,
            'Véhicule de fonction',
            'Véhicules de fonction'
        );
        const veh_utilitaires = new KeyFigureModel(
            'picto_truck',
            this.keyFigures.veh_utilitaires,
            'Véhicule utilitaire',
            'Véhicules utilitaires'
        );

        const veh_electriques = new KeyFigureModel(
            'picto_electriccar',
            this.keyFigures.veh_electriques,
            'Véhicule électrique',
            'Véhicules écletriques'
        );
        const espaces_verts = new KeyFigureModel(
            'picto_park',
            this.keyFigures.espaces_verts,
            'Espace vert',
            'Espaces verts'
        );

        const feux_tricolores = new KeyFigureModel(
            'picto_trafficlight',
            this.keyFigures.feux_tricolores,
            'Feu tricolore',
            'Feux tricolores'
        );
        const eclairages_urbains = new KeyFigureModel(
            'picto_streetlight',
            this.keyFigures.eclairages_urbains,
            'Éclairage urbain',
            'Eclairages urbains'
        );

        this.list_keyfigures = [
            {
                left: pdlsActive,
                right: sitesActive,
            },
            {
                left: surface,
                right: bat_tertiaires,
            },
            {
                left: bat_industriels,
                right: bat_residentiels,
            },
            {
                left: veh_fonction,
                right: veh_utilitaires,
            },
            {
                left: veh_electriques,
                right: espaces_verts,
            },
            {
                left: feux_tricolores,
                right: eclairages_urbains,
            },
        ];

        this.value = 40;
        this.bufferValue = 0;
        this.mode = 'determinate';
    }
}
