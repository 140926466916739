import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { TranslateService } from 'app/shared/services/translate/translate.service';
import { WarrantService } from 'app/shared/services/warrants/warrant.service';

import { EnedisWarrantService } from './enedis-warrant.service';

@Component({
    selector: 'ga-loadcurve-assignation-enedis',
    templateUrl: './assignation-enedis.component.html',
    styleUrls: ['./assignation-enedis.component.scss'],
    providers: [EnedisWarrantService],
})
export class LoadCurveAssignationEnedisComponent implements OnInit, OnDestroy {
    @Input() company: any;

    /** Boolean to show or hide routing reference selection tab */
    public showRrefSelection = false;

    /** Component's subscription */
    private subscription: Subscription;

    constructor(
        private readonly translateService: TranslateService,
        private readonly warrantService: WarrantService,
        private readonly enedisWarrantService: EnedisWarrantService
    ) {}

    async ngOnInit(): Promise<void> {
        // Whenever a warrant is successfully saved, set routing reference selection tab visibility
        this.subscription = this.enedisWarrantService.loadedWarrant$.subscribe(warrant => {
            // Show routing reference selection tab only if current warrant is valid for today date
            this.showRrefSelection = this.warrantService.isValidWarrant(warrant);
        });

        // Load page data
        try {
            await this.enedisWarrantService.loadLatestWarrant(this.company._id);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: this.translateService._('error_we_are_sorry'),
                text: this.translateService._('warrant_error_loading'),
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
