import { Location } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

// services
import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { UsersService } from 'app/shared/services/users/users.service';
import { AdminCompanyService } from './company.service';

// interfaces
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { AdminTab } from 'app/shared/models/admin-tab.interface';

@Component({
    selector: 'ga-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss'],
    providers: [],
})
export class CompanyComponent implements OnInit {
    company: any = null;
    selectedTab: AdminTab = null;
    listTabs: AdminTab[] = [];

    isLoading = true;

    /**
     * Is company being deleted
     */
    isDeleting = false;

    userSession: any = null;

    constructor(
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        private adminCompanyService: AdminCompanyService,
        private sessionService: SessionService,
        private usersService: UsersService,
        private companyService: CompaniesService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.initData(params);
        });
    }

    async initData(params: object) {
        this.userSession = await this.sessionService.getUser();
        this.checkAccessToPage();

        let selectedTabKey = 'profil';

        if (params && params['companyId']) {
            await this.setCompany(params['companyId']);
            selectedTabKey = params['tab'];
        } else if (!this.usersService.hasRightToCreateCompany(this.userSession)) {
            // standard users can't access the creation page
            this.router.navigateByUrl('/admin/entreprises');
        }
        this.userSession.belongsToCompany = this.company
            ? this.company.users.includes(this.userSession._id.toString())
            : false;
        this.initTabs();
        this.selectTab(selectedTabKey);
        this.isLoading = false;
    }

    /**
     * Standard users don't have access to this page. Double check in addition to pages rights.
     */
    checkAccessToPage() {
        if (this.usersService.isUser(this.userSession)) {
            this.router.navigateByUrl('accueil');
        }
    }

    /**
     * Initialize the tabs with an array of AdminTabs.
     * Visibility and editing rights depend on the user's type and whether he belongs to the company or not.
     */
    initTabs() {
        /** Is session user ADMIN or belongs to the company as a USER_ADMIN or EM */
        const isAdminOrBelongs = this.usersService.isAdmin(this.userSession) || this.isEmOrUserAdminInCharge();
        /** Is session user ADMIN or belongs to the company as an EM */
        const isAdminOrEmBelonging = this.usersService.isAdmin(this.userSession) || this.isEmInCharge();
        /** Is session user from Citron */
        const isFromCitron = this.usersService.isFromCitron(this.userSession);

        this.listTabs = [
            { key: 'profil', name: 'profil', visible: true, canEdit: isAdminOrBelongs },
            { key: 'utilisateurs', name: 'utilisateurs', visible: true, canEdit: isAdminOrBelongs },
            { key: 'energy-managers', name: 'energy-managers', visible: true, canEdit: isAdminOrEmBelonging },
            { key: 'energy-viewers', name: 'energy-viewers', visible: isFromCitron, canEdit: isAdminOrEmBelonging },
            // { key: 'contacts', name: 'contacts', visible: isGa, canEdit: isAdminOrEmBelonging},
            { key: 'uploads', name: 'uploads', visible: isAdminOrEmBelonging, canEdit: true },
            { key: 'filiales', name: 'gestion des filiales', visible: isAdminOrBelongs, canEdit: true },
            { key: 'prestations', name: 'prestations', visible: false, canEdit: false },
            { key: 'pdls', name: 'attribution des PDLs', visible: isAdminOrBelongs, canEdit: true },
            { key: 'vehicules', name: 'attribution des véhicules', visible: isAdminOrBelongs, canEdit: true },
            {
                key: 'courbes-de-charge',
                name: 'attribution des courbes de charge',
                visible: isAdminOrEmBelonging,
                canEdit: true,
            },
            {
                key: 'fusion-des-pdls',
                name: 'Fusion des PDLs',
                visible: isAdminOrEmBelonging,
                canEdit: true,
            },
        ];
    }

    /**
     * Returns true if the user is an EM in charge of the company (associated to the company)
     * @return {boolean}
     */
    isEmInCharge(): boolean {
        return this.usersService.isEM(this.userSession) && this.userSession.belongsToCompany;
    }

    /**
     * Returns true if the user belongs to the company and is either an EM or USER_ADMIN
     * @return {boolean}
     */
    isEmOrUserAdminInCharge(): boolean {
        return (
            [UserTypes.USER_ADMIN, UserTypes.ENERGY_MANAGER].includes(this.userSession.type) &&
            this.userSession.belongsToCompany
        );
    }

    /**
     * Load the company info from its id in the URL params
     * @param {string} id - company's id to load
     * @return {Promise<void>}
     */
    async setCompany(id: string) {
        try {
            const results = await Promise.all([
                this.adminCompanyService.getCompanyById(id),
                this.companyService.getBranches(id),
            ]);

            this.company = results[0].data;
            this.company.branches = results[1].data;
        } catch (err) {
            this.isLoading = false;
            // err.errorCode is set to error_loadCompany or error_loadBranches
            this.getSwalMsg(err.errorCode);
            await this.router.navigateByUrl('/admin/entreprises');
        }
    }

    /**
     * Set the correct tab based on URL
     * @param {string} tabKey - name in the url after the company's id, match a key in the tabs list
     */
    selectTab(tabKey: string) {
        this.selectedTab = this.listTabs.find(t => t.key === tabKey);

        // if the url is wrong, display profile + update url
        if (!this.selectedTab && this.company) {
            this.selectedTab = this.listTabs[0];
            this.location.go('/admin/entreprise/' + this.company._id + '/' + this.selectedTab.key);
        }
    }

    /**
     * Display a sweet alert based on the code sent
     * @param msg_code
     */
    getSwalMsg(msg_code) {
        const messages = {
            error_loadCompany: [
                'Toutes nos exuses',
                "Une erreur s'est produite lors du chargement de l'entreprise",
                'error',
            ],
            error_loadBranches: [
                'Toutes nos exuses',
                "Une erreur s'est produite lors du chargement des filiales",
                'error',
            ],
            error_default: ['Toutes nos exuses', "Une erreur s'est produite lors du chargement de la page.", 'error'],
        };

        if (!messages[msg_code]) {
            msg_code = 'error_default';
        }

        Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
    }

    getCompanyName(): string {
        return !this.isLoading ? this.companyDisplayName : '';
    }

    get companyDisplayName(): string {
        return this.company && this.company.name ? this.company.name : '';
    }

    /**
     * Check if user close page or change page
     * HostListener is for the browser window (close)
     * CanDesactivate is mandatory for the canDeactivateGuard
     */
    @HostListener('window:beforeunload')
    canDeactivate(): boolean {
        return !this.isDeleting;
    }

    /**
     * Set if company is beign deleted
     * @param {boolean} isDeleting
     */
    setDeleting(isDeleting: boolean) {
        this.isDeleting = isDeleting;
    }
}
