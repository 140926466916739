import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { from, Subject } from 'rxjs';
import Swal from 'sweetalert2';

import { InputModalComponent } from 'app/shared/components/modals/input-modal/input-modal.component';
import { FormatFileSizePipe } from 'app/shared/pipes/format-file-size.pipe';
import { FilesTypeService } from 'app/shared/services/files/files-type.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

import { FileStorageService } from 'app/shared/services/files/file-storage.service';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { DocumentsService } from './documents.service';
import { UploadSnackbarComponent } from './upload-snackbar.component';
@Component({
    selector: 'ga-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    providers: [DocumentsService],
})
export class DocumentsComponent implements OnInit {
    // Item selected by the user (mouse click)
    selectedItem: any = null;
    // All files items of the current directory
    files: any[] = [];
    // Current directory id
    currentDirectory: any = null;
    // List of parents directory. stack LIFO
    parentDirectory: any[] = [];
    // Types allowed for file upload
    allowedTypes: string[] = [];

    // is the information panel open
    isInformationOpened = false;
    // Tag list
    tagsAll: any[] = [];
    // Sites List
    sitesAll: any[] = [];
    // Contracts List
    contractsAll: any[] = [];

    // Drag/Drop
    isDragging = false;

    // Create Directory Modal
    @ViewChild('groupModal', { static: true }) private directoryModal: InputModalComponent;
    modalDirectoryError = '';
    modalDirectoryValue = '';

    // Search text
    searchQuery = '';

    loading = {
        allowedTypes: true,
        tags: true,
        sites: true,
        contracts: true,
    };

    loadingSelectedItem = true;

    // Notification positioning
    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    // is uploading or removing action in progress
    isUploadingOrRemoving = false;

    constructor(
        private documentsService: DocumentsService,
        private formatFileSize: FormatFileSizePipe,
        private fileTypeService: FilesTypeService,
        private sitesService: SitesService,
        private fileStorageService: FileStorageService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.initFiles();
        this.documentsService.getAllowedTypes().then(response => {
            if (response.data) {
                this.allowedTypes = response.data.map(x => '.' + x);
                this.loading.allowedTypes = false;
            }
        });
        this.documentsService.getTagList().then(response => {
            if (response.data) {
                this.tagsAll = response.data;
                this.loading.tags = false;
            }
        });
        this.documentsService.getSitesList().then(response => {
            if (response.data) {
                this.sitesAll = response.data;
                this.sitesService.createGoogleAddressToDisplay(this.sitesAll);
                this.loading.sites = false;
            }
        });
        this.documentsService.getContractsList().then(response => {
            if (response.data) {
                this.contractsAll = response.data;
                this.loading.contracts = false;
            }
        });
    }

    /**
     * Check if user close page or change page
     * HostListener is for the brower window (close)
     * CanDesactivate is mandatory for the canDeactivateGuard
     */
    @HostListener('window:beforeunload')
    canDeactivate(): boolean {
        return !this.isUploadingOrRemoving;
    }

    initFiles() {
        this.currentDirectory = null;
        this.parentDirectory = [];
        this.searchQuery = '';
        this.documentsService.getFiles(this.currentDirectory).then(response => {
            if (response.data) {
                this.files = response.data;
            }
        });
    }

    selectItem(item) {
        this.loadingSelectedItem = true;

        this.selectedItem = item;
        if (!this.selectedItem.tags) {
            this.selectedItem.tags = [];
        }
        if (!this.selectedItem.relations) {
            this.selectedItem.relations = {};
        }
        this.setSelectedItemSites();
        if (!this.selectedItem.relations.contracts) {
            this.selectedItem.relations.contracts = [];
        }

        this.loadingSelectedItem = false;
    }

    setSelectedItemSites() {
        if (!this.selectedItem.relations.sites) {
            this.selectedItem.relations.sites = [];
        } else {
            this.selectedItem.relations.sites.forEach(site => {
                site.googleAddress = this.sitesAll.find(s => s._id === site._id).googleAddress;
            });
        }
    }

    getFileLineClass(item) {
        return item === this.selectedItem ? 'table-info' : '';
    }

    getIconClass(item) {
        return item.isDirectory ? 'fa fa-folder' : 'fa fa-file icon-color';
    }

    getFileType(item) {
        if (item.isDirectory) {
            return 'Dossier';
        }
        return this.fileTypeService.getTypeNameFromMIME(item.mimetype);
    }

    /**
     * Get username from file or '-' if not found.
     * @param item File
     * @returns Username
     */
    public getFileUsername(item): string {
        if (item.user) {
            if (item.user.firstname && item.user.name) {
                return item.user.firstname + ' ' + item.user.name;
            } else if (item.user.name) {
                return item.user.name;
            } else if (item.user.firstname) {
                return item.user.firstname;
            }
        }

        return '-';
    }

    getFileSize(item) {
        if (item.size) {
            return this.formatFileSize.transform(item.size);
        }
        return '-';
    }

    getUpdatedDate(item) {
        if (item.updatedAt) {
            return moment(item.updatedAt).format('DD/MM/YYYY HH:mm');
        }
        return 'NC';
    }

    getClassBack() {
        return this.parentDirectory.length ? '' : 'disabled';
    }

    getClassDelete() {
        return this.selectedItem ? '' : 'disabled';
    }

    getClassDownload() {
        return this.selectedItem && !this.selectedItem.isDirectory ? '' : 'disabled';
    }

    addDragStyle() {
        this.isDragging = true;
    }

    removeDragStyle() {
        this.isDragging = false;
    }

    getDragClass() {
        return this.isDragging ? 'dragging' : 'no-dragging';
    }

    onSelectFile(e) {
        const files = Array.from(e.target.files);
        this.uploadFile(files);
        e.target.value = '';
    }

    onDropFile(files) {
        this.uploadFile(files);
    }

    getClassNavigation() {
        return this.isInformationOpened ? 'col-md-10' : 'col-md-12';
    }

    uploadFile(files) {
        if (files && files.length) {
            this.isUploadingOrRemoving = true;
            const progressObservable = new Subject<number>();
            progressObservable.next(0);
            const snack = this.snackBar.openFromComponent(UploadSnackbarComponent, {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                data: progressObservable,
            });
            const totalFileSize = files.reduce((memo, x) => memo + x.size, 0);
            let uploadFileSize = 0;
            const id = this.currentDirectory ? this.currentDirectory._id : null;
            const subscription = from(files)
                .pipe(
                    mergeMap(event => this.documentsService.uploadFile(event, id)),
                    filter(event => event.type === HttpEventType.Response),
                    tap((event: HttpResponse<unknown>) => {
                        const data = event.body['data'];
                        if (data) {
                            this.files.push(data);
                            uploadFileSize += data.size;
                            progressObservable.next(Math.round((uploadFileSize / totalFileSize) * 100));
                        }
                    })
                )
                .subscribe({
                    error: err => {
                        snack.dismiss();
                        const error: ApiCitronError = err.error;
                        this.openError(
                            "Erreur lors de l'upload",
                            "Une erreur est survenue lors de l'upload de fichier(s). Veuillez réessayer.<br>" +
                                '<br><br>' +
                                'Formats autorisés : ' +
                                this.allowedTypes.join(', ') +
                                '<br>' +
                                'Taille maximale autorisée : 50 Mo' +
                                '<br><br>' +
                                'ErrorCode: ' +
                                error.errorCode
                        );
                        this.isUploadingOrRemoving = false;
                    },
                    complete: () => {
                        snack.dismiss();
                        this.snackBar.open('Upload effectué', 'Masquer', {
                            horizontalPosition: this.horizontalPosition,
                            verticalPosition: this.verticalPosition,
                            duration: 5000,
                        });
                        this.isUploadingOrRemoving = false;
                        subscription.unsubscribe();
                    },
                });
        }
    }

    createDirectory(name) {
        const id = this.currentDirectory ? this.currentDirectory._id : null;
        this.documentsService
            .createDirectory(name, id)
            .then(response => {
                if (response.data) {
                    this.files.push(response.data);
                }
            })
            .catch(err => {
                this.openError(
                    'Erreur lors de la création',
                    'Une erreur est survenue lors de la création du dossier. Veuillez réessayer.<br><br>ErrorCode: ' +
                        err.errorCode
                );
            });
    }

    openDirectoryModal({ error = '', value = '' } = {}) {
        this.modalDirectoryError = error;
        this.modalDirectoryValue = value;
        // Reset the value selected before
        this.directoryModal.inputModel = '';

        this.directoryModal.show();
    }

    openDirectory(item) {
        if (item === null || item.isDirectory) {
            if (!this.searchQuery) {
                const index = this.parentDirectory.indexOf(item);
                if (index > -1) {
                    this.parentDirectory.splice(index, this.parentDirectory.length - index);
                } else {
                    this.parentDirectory.push(this.currentDirectory);
                }
            } else {
                this.searchQuery = '';
                this.parentDirectory = [];
            }
            this.currentDirectory = item;
            const id = this.currentDirectory ? this.currentDirectory._id : null;
            this.documentsService.getFiles(id).then(response => {
                this.files = response.data;
            });
            this.selectedItem = null;
        }
    }

    goBack() {
        if (this.parentDirectory.length) {
            this.selectedItem = null;
            this.currentDirectory = this.parentDirectory.pop();
            const id = this.currentDirectory ? this.currentDirectory._id : null;
            this.documentsService.getFiles(id).then(response => {
                this.files = response.data;
            });
        }
    }

    toggleInformation() {
        this.isInformationOpened = !this.isInformationOpened;
    }

    onChangeInformationItem(item) {
        this.documentsService.saveFile(this.selectedItem);
    }

    get allowedFilesJoined() {
        return this.allowedTypes.join(',');
    }

    deleteSelectedItem() {
        const item = this.selectedItem;
        if (item) {
            Swal.fire({
                title: 'Êtes vous sûr de vouloir supprimer ce fichier ?',
                text: 'Ce fichier sera supprimé définitivement!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Oui, supprimer',
                cancelButtonText: 'Non, je veux le garder',
            }).then(result => {
                if (result.value) {
                    this.isUploadingOrRemoving = true;
                    const snack = this.snackBar.open('Suppression en cours', null, {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                    });
                    this.documentsService
                        .deleteFile(item._id)
                        .then(response => {
                            snack.dismiss();
                            if (response.data) {
                                this.snackBar.open('Suppression réussie', 'Masquer', {
                                    horizontalPosition: this.horizontalPosition,
                                    verticalPosition: this.verticalPosition,
                                    duration: 5000,
                                });
                                this.selectedItem = null;
                                const index = this.files.indexOf(item);
                                if (index > -1) {
                                    this.files.splice(index, 1);
                                }
                            }
                            this.isUploadingOrRemoving = false;
                        })
                        .catch(err => {
                            this.openError(
                                'Erreur lors de la suppression',
                                "Une erreur est survenue lors de la suppression de l'élément. Veuillez réessayer.<br><br>ErrorCode: " +
                                    err.errorCode
                            );
                            this.isUploadingOrRemoving = false;
                        });
                }
            });
        }
    }

    downloadSelectedItem() {
        if (this.selectedItem && !this.selectedItem.isDirectory) {
            const item = this.selectedItem;
            this.fileStorageService.downloadFile(item.file, item.originalname);
        }
    }

    search(value) {
        if (this.searchQuery) {
            const searchQuery = this.searchQuery;
            if (searchQuery) {
                this.documentsService.searchFile(searchQuery).then(response => {
                    if (response.data) {
                        this.files = response.data;
                    }
                });
            }
        } else {
            this.initFiles();
        }
    }

    clearSearch() {
        this.initFiles();
    }

    private openError(errorTitle: string, errorMessage: string) {
        return Swal.fire(errorTitle, errorMessage, 'error');
    }

    tableNavigate(key) {
        let index = -1;
        switch (key.code) {
            case 'ArrowUp':
                index = this.files.indexOf(this.selectedItem);
                if (index > 0) {
                    const previousItem = this.files[index - 1];
                    this.selectItem(previousItem);
                } else if (!this.selectedItem && this.files.length) {
                    this.selectItem(this.files[0]);
                }
                break;
            case 'ArrowDown':
                index = this.files.indexOf(this.selectedItem);
                if (index > -1 && index < this.files.length - 1) {
                    const nextItem = this.files[index + 1];
                    this.selectItem(nextItem);
                } else if (!this.selectedItem && this.files.length) {
                    this.selectItem(this.files[0]);
                }
                break;
            case 'Enter':
                if (this.selectedItem && this.selectedItem.isDirectory) {
                    this.openDirectory(this.selectedItem);
                }
                break;
            case 'ArrowLeft':
                this.goBack();
                break;
            case 'Delete':
                this.deleteSelectedItem();
                break;
        }
    }

    onFileRejected(files) {
        let fileNames = '';
        files.forEach(file => {
            fileNames += file.name + '<br>';
        });
        const title = files.length > 1 ? 'Fichier(s) non accepté(s)' : 'Fichier non accepté';
        const body =
            files.length > 1
                ? 'Les fichiers suivants ont été rejetés car leurs format ne sont pas valides :'
                : "Le fichier suivant a été rejeté car son format n'est pas valide :";
        this.openError(
            title,
            body + '<br><br>' + fileNames + '<br>Formats autorisés : ' + this.allowedTypes.join(', ')
        );
    }
}
