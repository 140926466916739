import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Company } from 'app/shared/models/company.interface';
import { TranslateService } from 'app/shared/services/translate/translate.service';

import { RRefItem } from './rrefs-selection.interface';
import { RRefSelectionService } from './rrefs-selection.service';

@Component({
    selector: 'ga-enedis-rrefs-selection',
    templateUrl: './rrefs-selection.component.html',
    styleUrls: ['./rrefs-selection.component.scss'],
    providers: [RRefSelectionService],
})
export class EnedisRRefsSelectionComponent implements OnInit, OnDestroy {
    /** Company */
    @Input() company: Company;

    /** Form control responsible of handling display filters for rrefs list */
    public filtersAssignedRrefsControl = new FormControl(null);

    /** Selectable filters to apply to routing references list */
    public filtersAssignedRrefs = [
        {
            value: null,
            label: 'routingreferences_all',
            count: 0,
        },
        {
            value: true,
            label: 'routing_reference_associated_p',
            count: 0,
        },
        {
            value: false,
            label: 'routing_reference_not_associated_p',
            count: 0,
        },
    ];

    /** Search form control */
    public searchControl: FormControl = new FormControl('');

    /** First sticky row */
    @ViewChild('stickyRow', { static: false }) private stickyRowEl: ElementRef;

    /** Filtered list of routing references to display */
    public rRefListFiltered: RRefItem[] = [];

    /** Select all form group */
    public selectAllGroup: FormGroup;

    /** Day of month on which we should update the contractual data */
    public updateDay: FormControl = new FormControl(null);

    /** Is updateDay beeing modified */
    public updateDayModified = false;

    /** List of days for html select */
    public selectDayValues: Array<{ value: number; text: string }> = [];

    /** Saving state off Rref selection button */
    public savingSelection = false;

    /** Subscription */
    private subscription: Subscription;

    /** Message to explain why some synchronisations can not be done */
    public get debounceTimeTooltip() {
        return this.translateService._('loadcurve_config_debounce_expl');
    }

    /** Is loading of routing references still ongoing */
    public isLoading = false;

    constructor(
        private service: RRefSelectionService,
        private translateService: TranslateService,
        private snackBar: MatSnackBar
    ) {}

    ngOnInit() {
        this.init();
        for (let i = 1; i <= 28; i++) {
            if (i === 1) {
                this.selectDayValues.push({ value: i, text: '1er' });
            } else {
                this.selectDayValues.push({ value: i, text: i.toString() });
            }
        }
        this.getDataUpdateDay();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    /**
     * First sticky row height for second top offset
     */
    public get stickyRowHeight(): string {
        const height = this.stickyRowEl ? this.stickyRowEl.nativeElement.offsetHeight : 0;
        return `${height}px`;
    }

    public get nbSelected(): string {
        return `${this.service.getSelectedRrefCount(this.filtersAssignedRrefsControl.value)}`;
    }

    public get nbUpdated(): string {
        const nb = this.service.nbUpdated;
        if (!nb) {
            return '';
        }
        if (nb === 1) {
            return `, ${nb} ${this.translateService._('updated')}`;
        }
        return `, ${nb} ${this.translateService._('updated_p')}`;
    }

    public async save() {
        try {
            this.savingSelection = true;
            await this.service.saveUpdatedItems();
            Swal.fire(
                this.translateService._('loadcurve_config_perimeter'),
                `${this.translateService._('loadcurve_config_saved')}<br/><br/>
                ${this.translateService._('loadcurve_config_saved_next')}`,
                'success'
            );
        } catch (e) {
            Swal.fire('Erreur', `Une erreur s'est produite lors de l'enregistrement<br />(${e.message})`, 'error');
        } finally {
            this.savingSelection = false;
        }
    }

    /**
     * Save the update day for this company
     */
    public async saveUpdateDay() {
        try {
            await this.service.saveDataUpdateDay(this.company, this.updateDay);
            this.updateDayModified = false;
            this.snackBar.open(this.translateService._('loadcurve_config_auto_update_saved'), '', {
                duration: 5000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        } catch (e) {
            Swal.fire('Erreur', `Une erreur s'est produite lors de l'enregistrement de la périodicité`, 'error');
        }
    }

    /**
     * Synchronize the contractual data for a given list of routing references
     * First filters on those that can be synchronized
     */
    public async synchronize(routingReferences: RRefItem[] = this.rRefListFiltered) {
        const rRefToSync = this.service.filterRRefToSync(routingReferences);
        const nbUpdated = rRefToSync.length;
        const nbIgnored = routingReferences.length - rRefToSync.length;

        try {
            await this.service.synchronizeContractualData(rRefToSync);
            Swal.fire(
                this.translateService._('loadcurve_config_synchronization'),
                `${this.translateService._('loadcurve_config_synchronization_running')} <br/><br/>
                ${nbUpdated} ${this.translateService._(
                    nbUpdated === 1 ? 'loadcurve_config_pdl_sync' : 'loadcurve_config_pdls_sync'
                )} <br/>
                ${nbIgnored} ${this.translateService._(
                    nbIgnored === 1 ? 'loadcurve_config_pdl_ignored' : 'loadcurve_config_pdls_ignored'
                )}`,
                'success'
            );
        } catch (e) {
            Swal.fire('Erreur', this.translateService._('error_loadcurve_config_on_sync'), 'error');
        }
    }

    /**
     * Returns the tooltip displayed on the synchronize icon of a routing reference
     */
    public syncTooltipText(rRef: RRefItem): string {
        if (!rRef.lastSync) {
            return this.translateService._('loadcurve_config_never_sync');
        } else {
            return `${this.translateService._(
                'loadcurve_config_last_sync'
            )} : ${rRef.lastSync.toLocaleDateString()} ${rRef.lastSync.toLocaleTimeString()}`;
        }
    }

    private async init() {
        this.isLoading = true;
        const rRefList = await this.service.initRRefList(this.company._id);

        // Init filters items counts
        const [filterAll, filterAssigned, filterUnassigned] = this.filtersAssignedRrefs;
        filterAll.count = this.service.totalRrefsCount;
        filterAssigned.count = this.service.assignedRrefsCount;
        filterUnassigned.count = filterAll.count - filterAssigned.count;

        this.filterRRefList();
        this.subscription = this.searchControl.valueChanges.subscribe({
            next: value => {
                this.filterRRefList(value);
            },
        });

        // Triggers whenever a new display filter options is selected
        this.subscription.add(
            this.filtersAssignedRrefsControl.valueChanges.subscribe({
                next: value => {
                    this.service.resetUpdatedItems();

                    this.rRefListFiltered = this.service.filterRrefItemsByAssignation(value);

                    this.updateSelectAllStates();
                },
            })
        );

        rRefList.forEach(rRefItem => {
            this.service.ensureFieldsCoherence(rRefItem.formGroup);
            this.subscription.add(
                rRefItem.formGroup.valueChanges
                    .pipe(
                        startWith(rRefItem.formGroup.value),
                        pairwise()
                    )
                    .subscribe({
                        next: ([prev, next]) => {
                            // If warrant authorization is updated and true, then also enable contractual data
                            if (prev.warrantAuthorization !== next.warrantAuthorization && next.warrantAuthorization) {
                                rRefItem.formGroup.controls.contractualData.patchValue(true, { emitEvent: false });
                            }
                            this.service.ensureFieldsCoherence(rRefItem.formGroup);
                            this.service.updateItem(rRefItem);
                            this.updateSelectAllStates();
                        },
                    })
            );
        });
        this.initSelectAll();
        this.isLoading = false;
    }

    private initSelectAll() {
        this.selectAllGroup = new FormGroup({
            warrantAuthorization: new FormControl(false),
            loadCurveVisualization: new FormControl(false),
            contractualData: new FormControl(false),
            siret: new FormControl(''),
        });
        Object.keys(this.selectAllGroup.controls).forEach(field => {
            this.subscription.add(
                this.selectAllGroup.controls[field].valueChanges.subscribe({
                    next: v => {
                        this.selectAllField(v, field);
                    },
                })
            );
        });
        this.updateSelectAllStates();
    }

    private selectAllField(value: boolean, field: string) {
        // Set all rrefs `field` to true
        this.rRefListFiltered.forEach(item => {
            const controls = item.formGroup.controls;
            if (controls[field].enabled && controls[field].value !== value) {
                controls[field].patchValue(value);
            }
        });
    }

    private updateSelectAllStates() {
        if (!this.selectAllGroup) {
            return;
        }
        Object.entries(this.selectAllGroup.controls).forEach(([key, control]) => {
            const allSelected = this.rRefListFiltered.every(
                x => x.formGroup.controls[key] && x.formGroup.controls[key].value === true
            );
            if (control.value !== allSelected) {
                control.patchValue(allSelected, { emitEvent: false });
            }
        });
    }

    private filterRRefList(search: string = '') {
        const newList = this.service.getRRefItems(search);
        if (newList) {
            this.rRefListFiltered = newList;
        }
        this.updateSelectAllStates();
    }

    /**
     * Retrieve the update day of routing references contractual data for the current company
     */
    private async getDataUpdateDay() {
        try {
            const day = await this.service.getDataUpdateDay(this.company);
            this.updateDay.setValue(day);
        } catch (e) {
            Swal.fire('Erreur', this.translateService._('error_loadcurve_config_on_date_retrive'), 'error');
        }
    }
}
