import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AdminCompanyService } from 'app/pages/admin/company/company.service';
import { AutoCompleteItem } from 'app/shared/components/common/ultra-simple-autocomplete/ultra-simple-autocomplete.interface';
import { MultipleAutocompleteFilterComponent } from 'app/shared/components/filters/multiple-autocomplete-filter/multiple-autocomplete-filter.component';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { PagesList } from 'app/shared/services/rights/rights.interface';
import { RightsService } from 'app/shared/services/rights/rights.service';
import { UsersService } from 'app/shared/services/users/users.service';
import { TextService } from 'app/shared/services/utils/text.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-company-users-tab',
    templateUrl: './company-users.component.html',
    styleUrls: ['./company-users.component.scss'],
    providers: [],
})
export class CompanyUsersComponent implements OnInit {
    @Input() company: any = null;
    @Input() userSession: any = null;
    @Input() canEdit = false;

    @Input() userTypesAvailable: string[] = [UserTypes.USER, UserTypes.USER_ADMIN];
    userRolesExisting = ['users', 'viewers']; // to remove from the selectbox options the users already stored as users / viewers

    // to associate readOnly or Editable values
    @Input() tableProperties: {
        companyRole: string; // "users" or "viewers": the name of the property inside the Company Model in which we find the users to display in the table
        rightsAvailable: Array<{ name: string; value: number }>;
    };

    @ViewChild(MultipleAutocompleteFilterComponent, { static: false })
    multipleAutocompleteFilterComponent: MultipleAutocompleteFilterComponent;

    usersSelectBox: any[] = [];
    companyUsers: any[] = []; // includes the whole User Object

    usersCollapsed: any = {};
    pagesName: PagesList = {};
    rightsListPerUser: any = [];

    constructor(
        private companyService: AdminCompanyService,
        private router: Router,
        private usersService: UsersService,
        private rightsService: RightsService,
        private textService: TextService
    ) {}

    ngOnInit() {
        this.setUsersList().catch(err => {
            // err.error_code is set to error_getListUsers / error_getListPages /error_getRightsByCompany
            this.getSwalMsg(err.error_code);
        });
    }

    /**
     * Add a user to the company
     */
    async addUser(user: any) {
        try {
            // add the user to the company before saving it
            this.company[this.tableProperties.companyRole].push(user._id);

            // empty the values in currentValuesSelected so they can be reselected
            this.multipleAutocompleteFilterComponent.resetCurrentValuesSelected();

            await this.companyService.updateCompany(this.company);

            // add the default rights to the new user in the rightsList
            this.addRightToUser(user);

            // save the rights
            await this.saveUserRights(user, 'create');

            await this.setUsersList();

            this.collapse(user);

            this.getSwalMsg('saveRights');
        } catch (error) {
            const code = error.error_code ? error.error_code : error.errorCode;
            this.getSwalMsg(code);
        }
    }

    getSwalMsg(msg_code) {
        const messages = {
            error_getListUsers: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des utilisateurs.',
                'error',
            ],
            error_getAllUsers: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des utilisateurs.',
                'error',
            ],
            error_getListPages: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des noms de pages.',
                'error',
            ],
            error_getRightsByCompany: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement des droits.',
                'error',
            ],
            error_updateCompany: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la mise à jour des utilisateurs.',
                'error',
            ],
            error_updateNewRights: [
                'Toutes nos excuses',
                "Une erreur est surevenue pendant la création des droits de l'utilisateur.",
                'error',
            ],
            error_createNewRights: [
                'Toutes nos excuses',
                "Une erreur est surevenue pendant la mise à jour des droits de l'utilisateur.",
                'error',
            ],
            saveRights: ['Sauvegardé', 'Les droits ont bien été sauvegardés', 'success'],
            company_saved: ['Supprimé', "L'utilisateur a bien été supprimé", 'success'],
            error_deleteRights: [
                'Toutes nos excuses',
                'Une erreur est survenue pendant la suppression des droits.',
                'error',
            ],
        };

        if (messages[msg_code]) {
            Swal.fire(messages[msg_code][0], messages[msg_code][1], messages[msg_code][2]);
        }
    }

    /**
     * Get visible users
     *
     * @returns {Promise<any>}
     */
    async getVisibleUsers(): Promise<any> {
        if ([UserTypes.ADMIN, UserTypes.ENERGY_MANAGER].includes(this.userSession.type)) {
            return this.usersService.getAllUsersByFilter({
                types: this.userTypesAvailable,
            });
        } else {
            return this.userSession.relations;
        }
    }

    async setUsersList(): Promise<void> {
        const allUsers = await this.getVisibleUsers();
        this.usersSelectBox = [];
        this.companyUsers = [];

        if (allUsers.length) {
            // usersSelectBox: push all the users NOT belonging to the company's users'/viewers + having the correct type

            this.usersSelectBox = allUsers.filter(
                user =>
                    // don't keep the users already added inside the company's users or viewers
                    !this.userRolesExisting.some(userRole => {
                        return this.company[userRole].find(userId => userId === user._id.toString());
                    }) && this.userTypesAvailable.includes(user.type)
            ); // keep the users having the correct type (user or viewer depending on the tab)

            // companyUsers: push all the users belonging to the company + having an available type
            this.companyUsers = allUsers.filter(
                user =>
                    this.company[this.tableProperties.companyRole].find(userId => userId === user._id.toString()) &&
                    this.userTypesAvailable.includes(user.type)
            );
            this.usersSelectBox.forEach(user => (user.displayName = this.getDisplayName(user)));
        }

        this.usersSelectBox.sort(this.sortListAlphabetically);
        this.companyUsers.sort(this.sortListAlphabetically);

        this.initCollapse();

        await this.setPagesName();
        await this.setRightsList();
        return;
    }

    sortListAlphabetically(userA, userB) {
        const a = userA.name + userA.firstname;
        const b = userB.name + userB.firstname;
        return a.localeCompare(b);
    }

    isDisabled() {
        return this.usersSelectBox.length ? '' : 'disabled';
    }

    getUserName(user): string {
        return user.firstname ? user.firstname + ' ' + user.name : user.name;
    }

    /*
     * Returns the name of a user as we want it to be displayed in the auto-complete list
     */
    getDisplayName(user: any): string {
        return user.firstname
            ? user.name.toUpperCase() + ' ' + this.textService.toTitleCase(user.firstname)
            : user.name.toUpperCase();
    }

    initCollapse() {
        this.companyUsers.forEach(user => {
            this.usersCollapsed[user._id] = true;
        });
    }

    collapse(user) {
        this.usersCollapsed[user._id] = !this.usersCollapsed[user._id];
    }

    isCollapsedClass(user) {
        return this.usersCollapsed[user._id] ? 'uncollapsed-chevron' : 'collapsed-chevron';
    }

    isCollapsed(user) {
        return this.usersCollapsed[user._id];
    }

    hasUsers() {
        return this.companyUsers.length;
    }

    async setPagesName(): Promise<any> {
        const pagesList = await this.rightsService.getPagesListURLs();
        this.pagesName = pagesList;
    }

    async setRightsList(): Promise<any> {
        this.rightsListPerUser = [];
        const rightsList = await this.rightsService.getRightsListByCompany(this.company._id);

        // rightList : rights per user per company already initialized
        this.companyUsers.forEach(user => {
            const userRights = rightsList.find(right => right.user === user._id && right.company === this.company._id);

            // if the user already has some rights stored, create and display them
            if (!userRights) {
                this.addRightToUser(user);
            } else {
                this.checkRightsConstistency(userRights);
                this.rightsListPerUser.push(userRights);
            }
        });
    }

    addRightToUser(user) {
        const rightPerUser = {
            user: user._id,
            company: this.company._id,
            rights: this.initializeUserRights(), // initialize all the user rights with "read only"
        };

        this.rightsListPerUser.push(rightPerUser);
    }

    getPageName(page) {
        return this.pagesName[page.page].name;
    }

    getSubPageName(page, subPage) {
        return this.pagesName[page.page].subPages[subPage.name].name;
    }

    // initialize all the user rights with "read only"
    initializeUserRights() {
        const rights = [];
        Object.keys(this.pagesName).forEach(page => {
            this.initializePageRights(page, rights); // initialize the whole page/subpages rights with "read only"
        });
        return rights;
    }

    // initialize the whole page/subpages rights with "read only"
    initializePageRights(page, rights) {
        const pageRights = {
            page,
            subPages: [],
            right: null,
            state: 'new',
        };

        if (this.pagesName[page].subPages) {
            pageRights.right = null;
            delete pageRights.state;
            Object.keys(this.pagesName[page].subPages).forEach(subPage => {
                pageRights.subPages.push({
                    name: subPage,
                    right: 1,
                    state: 'new',
                });
            });
        } else {
            pageRights.right = 1;
        }

        rights.push(pageRights);
    }

    // 1. if the user rights have extra pages -> delete them
    // 2. if the user rights have extra subpages -> delete them
    // 3. if a page had subpages and is now a single one - delete the entire page
    // 4. if the user rights don't have all the pages/subpages -> add them
    checkRightsConstistency(userRights) {
        // delete the pages or subpages that don't exist anymore
        // 1. keep only the pages that are included in pagesName
        userRights.rights = userRights.rights.filter(pageRight => {
            return this.pagesName.hasOwnProperty(pageRight.page);
        });

        // 2. keep only the subPages that are included in pagesName
        userRights.rights.forEach(pageRight => {
            pageRight.subPages = pageRight.subPages.filter(subpageRight => {
                return (
                    this.pagesName[pageRight.page].subPages &&
                    this.pagesName[pageRight.page].subPages.hasOwnProperty(subpageRight.name)
                );
            });
        });

        // 3. if a page had subpages before and is now a single page -> its right is null and subPages just got deleted
        // we delete the whole page so that it appears blue and new
        userRights.rights = userRights.rights.filter(pageRight => {
            return !(pageRight.right === null && !pageRight.subPages.length);
        });

        // 4. add the missing pages or subPages
        // pagesName is the reference : it has all the pages for which a right should be filled in
        Object.keys(this.pagesName).forEach(page => {
            const currentPageRights = userRights.rights.find(right => right.page === page);

            // check if the page is missing
            if (!currentPageRights) {
                this.initializePageRights(page, userRights.rights); // initialize the whole page/subpages rights with "read only"
            } else if (this.pagesName[page].subPages) {
                // check if any subPage is missing
                // every official subpage must be included in the current subPages list
                Object.keys(this.pagesName[page].subPages).forEach(subPage => {
                    if (!currentPageRights.subPages.some(subP => subP.name === subPage)) {
                        currentPageRights.subPages.push({
                            name: subPage,
                            right: 1,
                            state: 'new',
                        });
                    }
                });
            }
        });
    }

    getClassForCheckbox(valueForChecked, page) {
        return page.right === valueForChecked ? 'fa-check-square' : 'fa-square-o';
    }

    isNewPage(page) {
        return page.state === 'new' ? 'new-page' : '';
    }

    hasSubPages(page) {
        return page.subPages.length;
    }

    async onSaveBtn(user) {
        try {
            await this.saveUserRights(user, 'update');

            await this.setRightsList();
            this.getSwalMsg('saveRights');
        } catch (error) {
            this.getSwalMsg(error.error_code);
        }
    }

    async saveUserRights(user, action): Promise<any> {
        const userRights = this.rightsListPerUser.find(rights => rights.user.toString() === user._id.toString());
        // the rights need to be created, the user doesn't have any rights for that company yet

        switch (action) {
            case 'update':
                await this.rightsService.updateUserRightsById(userRights);
                break;

            case 'create':
                await this.rightsService.createUserRights(userRights);
                break;

            case 'delete':
                await this.rightsService.deleteUserRightsById(userRights);
                break;
        }
    }

    isloadingTableRights() {
        return this.rightsListPerUser.length < this.companyUsers.length;
    }

    onCheckboxChange(value, page) {
        if (page.right === value) {
            page.right = 0;
        } else {
            page.right = value;
        }
    }

    async removeUserFromCompany(userToDelete) {
        try {
            this.company[this.tableProperties.companyRole] = this.company[this.tableProperties.companyRole].filter(
                user => user !== userToDelete._id.toString()
            );
            await this.companyService.updateCompany(this.company);

            await this.saveUserRights(userToDelete, 'delete');

            await this.setUsersList();

            this.getSwalMsg('company_saved');
        } catch (err) {
            const code = err.error_code ? err.error_code : err.errorCode;
            this.getSwalMsg(code);
        }
    }

    createUser() {
        this.router.navigateByUrl(
            `/admin/utilisateurs/creer/${this.company._id}?l=${this.tableProperties.companyRole}`
        );
    }
}
