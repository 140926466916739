import { Injectable } from '@angular/core';

import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

interface Recurrence {
    dayOfMonth: number;
}
interface Schedule {
    tag: string;
    action: string;
    recurrence: Recurrence;
    data: object;
}

export enum ACTION {
    SYNC_CONTRACTUAL_DATA = 'sync-contractual-data',
}

@Injectable()
export class SchedulesService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get schedules matching the given filters
     */
    public async getSchedules(filters: { tag?: string; action?: string } = {}): Promise<Schedule[]> {
        const route = '/api/schedules';
        const res = await this.get(route, null, filters);
        return res.data;
    }

    /**
     * Create or update a given schedule
     */
    public async createUpdateSchedule(tag: string, action: ACTION, recurrence: Recurrence, data: unknown = null) {
        const route = '/api/schedules';
        const body = { schedules: [{ tag, action, recurrence, data }] };
        await this.post(route, body);
    }
}
