import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-number-filter',
    templateUrl: './number-filter.component.html',
    styleUrls: ['./number-filter.component.scss'],
})
export class NumberFilterComponent implements OnInit {
    @Output() updateElement: EventEmitter<any> = new EventEmitter();
    @Input() currentValue = '';

    @Input() minValue = 0;
    @Input() maxValue: number = null;
    @Input() stepValue = 1;

    constructor() {}

    ngOnInit() {}

    updateCurrentValue(value) {
        this.updateElement.emit(value);
    }
}
