import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import swal, { SweetAlertIcon } from 'sweetalert2';

// interfaces
import { DoubleDonutProperties } from 'app/pages/energy/followup/followup.interface';
import { OptionValueGroup, QueryFilterVeh } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { DoubleDonutSerieIn, DoubleDonutSerieOut } from 'app/shared/models/charts/chart-serie.interface';
import { DoublePieOptionsSeries } from 'app/shared/models/charts/charts-config.interface';
import { ColorGA, ToggleDonutProperties } from 'app/shared/models/charts/charts.interface';
import { CommonEnergyRepartitionMonthly } from 'app/shared/models/energy-data-bills.interface';
import { MapOptions, Marker } from 'app/shared/models/marker.interface';
import { ChartSerieFollowup, GroupChartsDataFormated, ToggleBarPropertiesFollowUp } from './followup.interface';

// services
import { ChartService } from 'app/shared/services/chart/chart.service';
import { EnergyDataService } from 'app/shared/services/energy/energy-data.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { MapService } from 'app/shared/services/map/map.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { FollowupService } from './followup.service';

// components
import { FollowupFilterComponent } from 'app/shared/components/filters/followup-filter/followup-filter.component';
import { FilterService } from '../../../shared/services/filter/filter.service';

interface Loading {
    nbQueriesToDo: number;
    nbQueriesDone: number;
    data: boolean;

    charts: {
        consoDonut: boolean;
        costDonut: boolean;
        bar: boolean;
    };
}

@Component({
    selector: 'ga-followup',
    templateUrl: './followup.component.html',
    styleUrls: ['./followup.component.scss'],
    providers: [FollowupService],
})
export class FollowupComponent implements OnInit {
    fakeData = false;

    /**
     * Map
     */
    markers: Marker[] = [];
    mapOptions: MapOptions = {
        zoom: 8,
        lat: 49.894067,
        lng: 2.2957529999999906,
        height: 250,
    };

    /**
     * Filter
     */
    fuelTypesAvailable: string[]; // existing fuel types
    queryFilterSelected: OptionValueGroup; // selected filter properties

    /**
     * Donuts
     */
    donuts: {
        conso: DoubleDonutProperties;
        ttc: DoubleDonutProperties;
        htva: DoubleDonutProperties;
    };

    /**
     * Toggle costs donuts
     */
    donutToggle: ToggleDonutProperties = {
        title: 'Répartition des coûts énergétiques sur la période',
        downloadFileNames: [
            'Répartition des coûts énergétiques HTVA sur la période',
            'Répartition des coûts énergétiques TTC sur la période',
        ],
        toggleNames: ['HTVA', 'TTC'],
        legend: [],
        optionsSets: [],
    };

    dataDonutsInside: { [donutKey: string]: number[][] } = {
        conso: [],
        ttc: [],
        htva: [],
    };

    /**
     * Bar chart
     */
    barToggle: ToggleBarPropertiesFollowUp = {
        datasets: [],
        labels: [],
        colors: [],
        width: 1900,
        minHeight: 340,
        maxHeight: 340,
        options: [],
        legend: [],
        title: 'Répartition mensuelle sur la période',
        toggleTitles: [
            { name: 'CONSOMMATION (L)', display: true },
            { name: 'HTVA (€)', display: true },
            { name: 'TTC (€)', display: true },
        ],
        downloadFileNames: ['', 'Coût mensuel HTVA sur la période (€)', 'Coût mensuel TTC sur la période (€)'],
        units: ['L', '€', '€'],
    };

    fueltypesSelected: string[] = []; // ['diesel', 'gasoline']
    pieInnerColor: string;
    fuelTypesLabels: string[] = [];
    fuelTypesColors: ColorGA[] = [];

    @ViewChild(FollowupFilterComponent, { static: true }) filterComponent: FollowupFilterComponent;

    loading: Loading = {
        nbQueriesDone: 0,
        nbQueriesToDo: 1,
        data: true,
        charts: {
            consoDonut: true,
            costDonut: true,
            bar: true,
        },
    };

    /** Filter saved for export */
    exportFilters: QueryFilterVeh;

    constructor(
        private route: ActivatedRoute,
        private energyService: EnergyService,
        private energyDataService: EnergyDataService,
        private sitesService: SitesService,
        private mapService: MapService,
        private followupService: FollowupService,
        private translateService: TranslateService,
        private chartService: ChartService,
        private filterService: FilterService
    ) {}

    ngOnInit() {
        this.fuelTypesAvailable = this.energyService.getAllFuelTypes();

        /**
         * {Params} route.queryParams
         * {string} route.queryParams.vehicleId - vehicle id to preselect
         */

        if (this.route.queryParams) {
            // subscribe to change to launch initiliazation when url is modified
            this.route.queryParams.subscribe(() => {
                this.initComponentSetup();
            });
        }
    }

    /**
     * 	INITIALISATION
     */

    /**
     * Initialize components.
     */
    initComponentSetup() {
        this.setChartStateLoading(true); // make the charts loading
        this.resetComponents(); // empty components
    }

    /**
     * Set all charts loading or not.
     * @param {boolean} isLoading
     */
    setChartStateLoading(isLoading: boolean) {
        this.loading.charts = {
            costDonut: isLoading,
            consoDonut: isLoading,
            bar: isLoading,
        };
    }

    /**
     * Empty charts.
     * @param {QueryFilterVeh} filters
     */
    resetComponents() {
        // donuts
        this.donuts = {
            conso: {
                title: 'Répartition de la consommation énergétique sur la période',
                dataInside: [],
                dataOutside: [],
                options: {},
                legend: [],
                unit: 'L',
            },
            htva: {
                dataOutside: [],
                dataInside: [],
                options: {},
                unit: '€',
            },
            ttc: {
                dataOutside: [],
                dataInside: [],
                options: {},
                unit: '€',
            },
        };

        this.dataDonutsInside = {
            conso: [],
            ttc: [],
            htva: [],
        };

        this.donutToggle.optionsSets = [];
        this.barToggle.datasets = [[], [], []];

        this.loading.nbQueriesDone = 0;

        this.pieInnerColor = this.chartService.getColorRgbToHex('LIGHTGREY');
    }

    /**
     * Set a marker for each site matching the current filters
     * @param {QueryFilterVeh} filters
     */
    async initMarkers(filters: QueryFilterVeh) {
        try {
            const sites = await this.sitesService.getSites(filters);
            this.markers = this.mapService.getMarkersFromSites(sites.data, 'fuel');
        } catch (e) {
            this.markers = this.mapService.getMarkersFromSites([]);
            this.handleError('map_markers');
        }
    }

    /**
     * 	LOAD DATA
     */

    /**
     * Initialize components and load new data to display with the current filter values.
     * @param {QueryFilterVeh} filters
     */
    async search(filters: QueryFilterVeh) {
        this.queryFilterSelected = this.filterComponent.getMainFilterSelected();
        this.resetComponents();
        this.initComponents(filters);
    }

    /**
     * Init labels, colors, markers, and each component with the new filter values.
     * @param {QueryFilterVeh} filters
     */
    async initComponents(filters: QueryFilterVeh): Promise<void> {
        /**
         * Donuts
         */

        // labels
        this.fueltypesSelected = filters.fuelTypes.split(',');
        this.fuelTypesLabels = this.fueltypesSelected.map(fluid => this.energyService.fuelProductFullText(fluid));

        // colors
        const colorsDonut = this.chartService.getColorsDoughnutChart(this.fueltypesSelected);
        this.fuelTypesColors = colorsDonut.backgroundColor; // for donuts

        /**
         * Bar
         */

        // labels
        const start = moment.utc(filters.dateStart).startOf('month');
        const end = moment.utc(filters.dateEnd).endOf('month');
        this.barToggle.labels = this.chartService.getPeriodMonthlyLabels(start, end);

        /**
         * Markers & components
         */
        this.initMarkers(filters);
        await this.getConsumption(filters);
        return;
    }

    /**
     * Get consumption for each selected element inside the filter.
     * Filter groups (tags) are processed one after the other: data grabbed + datasets filled.
     * Once all the tags have been processed the charts configs are set and displayed.
     * @param {QueryFilterVeh} filters
     */
    async getConsumption(filters: QueryFilterVeh) {
        if (filters.fuelTypes && filters.dateStart && filters.dateEnd) {
            this.loading.data = true;
            this.exportFilters = filters;

            /**
             * Index of the filter group (tag) being processed.
             * Index must begin at 1 for the chart bar to process each group in the correct order.
             * Index must be incremented of 1 for each new filter group (tag) being processed.
             */
            let filterGroupIndex: number;

            /**
             * Get consumption for each selected filter option.
             * When the filter is different from 'all' and 'custom-filters', no need to format it.
             **/
            if (['all', 'custom-filters'].includes(this.queryFilterSelected.value)) {
                this.loading.nbQueriesToDo = 1;
                filterGroupIndex = 1; // only one group for the selection "all" or a specific custom filter
                await this.addFilterDataToCharts(filters, filterGroupIndex);
            } else {
                const tagsSelected: string[] = filters[this.queryFilterSelected.value].split(',');
                this.loading.nbQueriesToDo = tagsSelected.length;

                // create n queries, for each tag value selected
                for (let i = 0; i < tagsSelected.length; i++) {
                    const filterUniqueTag: QueryFilterVeh = Object.assign({}, filters, {
                        [this.queryFilterSelected.value]: tagsSelected[i],
                    });
                    filterGroupIndex = i + 1;
                    await this.addFilterDataToCharts(filterUniqueTag, filterGroupIndex);
                }
            }
        } else {
            /**
             * Zero fuel type found when the company is empty (no bill - no fuel type)
             * Same if no date start/end
             * Data for charts is already initialized with empty datasets
             */
            this.loading.data = false;
            this.setChartStateLoading(false);
        }
    }

    /**
     * Grab data for the current filter being processed.
     * Add data inside each chart at the right position.
     * @param {QueryFilterVeh} filters
     * @param {number} filterGroupIndex index of the current filter (tag) being processed
     */
    async addFilterDataToCharts(filters: QueryFilterVeh, filterGroupIndex: number) {
        try {
            // 1. get data and format it
            const conso: CommonEnergyRepartitionMonthly[] = await this.energyDataService.getMonthlyGlobalEnergyRepartition(
                filters,
                'fuelType_monthly'
            );
            const consoFormated: GroupChartsDataFormated = this.followupService.formatToMonthlyConsumption(
                conso,
                filterGroupIndex,
                filters
            );
            this.loading.nbQueriesDone++;

            // 2. populate charts
            this.setChartsData(consoFormated);
        } catch (e) {
            this.handleError('set_chart_data');
            return;
        }
    }

    /**
     * -------
     * DATA & CHARTS
     * -------
     */

    /**
     * Populate each chart, with the current tag data being processed.
     * @param {GroupChartsDataFormated} monthlyFormatedConsumption
     */
    setChartsData(monthlyFormatedConsumption: GroupChartsDataFormated): void {
        this.populateCharts(monthlyFormatedConsumption);

        // if it was the last query
        if (this.loading.nbQueriesDone === this.loading.nbQueriesToDo) {
            this.loading.data = false;

            this.handleLegends();
            this.handleDonuts();
            this.handleBar();

            this.setChartStateLoading(false);
        }
    }

    /**
     * Populate charts witt the formated data of the current tag being processed
     * @param {GroupChartsDataFormated} monthlyFormatedConsumption
     */
    populateCharts(monthlyFormatedConsumption: GroupChartsDataFormated): void {
        /**
         * Sum each fuel type data for the current filter group (tag)
         * eg: [ [diesel site 1], [diesel site 2, gasoline site 2] ]
         */
        const indexFilter = monthlyFormatedConsumption.stack - 1;

        this.dataDonutsInside.conso[indexFilter] = this.getSumConsoPerFuelType(monthlyFormatedConsumption, 'L');
        this.dataDonutsInside.htva[indexFilter] = this.getSumConsoPerFuelType(monthlyFormatedConsumption, 'ht');
        this.dataDonutsInside.ttc[indexFilter] = this.getSumConsoPerFuelType(monthlyFormatedConsumption, 'ttc');

        for (let indexFuelType = 0; indexFuelType < monthlyFormatedConsumption.data.chart.L.length; indexFuelType++) {
            _.values(monthlyFormatedConsumption.data.chart).forEach((fuelTypeData, indexToggle) => {
                this.barToggle.datasets[indexToggle].push(fuelTypeData[indexFuelType]);
            });
        }
    }

    /**
     * Sum the conso per fuel type of the given group filter.
     * @param {GroupChartsDataFormated} singleFilterData - data of the current filter being processed
     * @returns {number[]} sums per fuel type
     * @returns {"L"|"ttc"|"ht"} donut type to process
     */
    private getSumConsoPerFuelType(singleFilterData: GroupChartsDataFormated, chartType: 'L' | 'ttc' | 'ht'): number[] {
        const consoPerFuelType: number[] = []; // [ diesel sum, gasoline sum ]

        const seriesData: ChartSerieFollowup[] = singleFilterData.data.chart[chartType];

        seriesData.forEach(fuelTypeData => {
            const sumMonthsData: number = fuelTypeData.data.reduce((monthA, monthB) => monthA + monthB, 0);
            const indexFuelType = this.fueltypesSelected.findIndex(fuelType => fuelType === fuelTypeData.label);
            if (indexFuelType !== -1) {
                consoPerFuelType[indexFuelType] = sumMonthsData;
            }
        });

        return consoPerFuelType;
    }

    /**
     * COMMON
     */
    handleLegends() {
        const legend = this.getChartLegends(this.fueltypesSelected);
        this.donuts.conso.legend = legend;
        this.donutToggle.legend = [legend, legend];
        this.barToggle.legend = [legend, legend, legend];
    }

    /**
     * 	DONUTS
     */

    /**
     * Handle the three donuts, and the toggle for costs.
     * Set datasets, unit, and options config.
     */
    private handleDonuts(): void {
        /**
         * Fill donut datasets.
         * inner donut series = data per filter group (tag)
         * outer donut series = data per fuel type
         */
        _.keys(this.donuts).forEach(donutType => {
            this.setDonutDataForInside(donutType);
            this.setDonutDataForOutside(donutType);
        });

        // Create global configs for each double donut
        _.values(this.donuts).forEach(donut => {
            this.getDoubleDonutConfig(donut);
        });

        // Create toggle donut for costs htva & ttc
        this.donutToggle.optionsSets = [this.donuts.htva.options, this.donuts.ttc.options];
    }

    /**
     * Create donut series per filter for the inner circle.
     */
    private setDonutDataForInside(donutType: string): void {
        const filters = this.filterComponent.getFilters();

        // for each filter group, try to create a serie
        filters.forEach((filter, i) => {
            /**
             * Create a donut serie for the filters having data.
             * A serie contains each fuel type value (data), and the sum (y)
             **/

            const filterGroupData: number[] = this.dataDonutsInside[donutType][i]; // eg: this.dataDonutsInside.conso = [ [diesel site 1], [diesel site 2, gasoline site 2] ]
            if (filterGroupData) {
                const totalGroupConso = filterGroupData.reduce((a, b) => a + b, 0);
                if (totalGroupConso > 0) {
                    const serie: DoubleDonutSerieIn = {
                        name: this.chartService.getChartLegendContentByFilter(filter, this.queryFilterSelected.value),
                        y: totalGroupConso,
                        color: this.pieInnerColor,
                        data: filterGroupData,
                    };
                    this.donuts[donutType].dataInside.push(serie);
                }
            }
        });
    }

    /**
     * Create a donut series for the outer circle.
     */
    private setDonutDataForOutside(donutType: string): void {
        const filters = this.filterComponent.getFilters();

        // for each filter
        for (let indexFilter = 0; indexFilter < filters.length; indexFilter++) {
            // for each fuel type
            const nbFuelTypes = this.fueltypesSelected.length;
            for (let indexFuelType = 0; indexFuelType < nbFuelTypes; indexFuelType++) {
                // create a serie for each combinaison filter / fuel type
                const filterData = this.donuts[donutType].dataInside[indexFilter];
                if (filterData) {
                    const dataFilterFuelType = filterData.data[indexFuelType];
                    if (dataFilterFuelType && dataFilterFuelType > 0) {
                        const fluidData: DoubleDonutSerieOut = {
                            name: this.fuelTypesLabels[indexFuelType],
                            y: dataFilterFuelType,
                            color: this.fuelTypesColors[indexFuelType],
                        };
                        if (nbFuelTypes > 1) {
                            fluidData.percentageCat = (dataFilterFuelType * 100) / filterData.y;
                        }
                        this.donuts[donutType].dataOutside.push(fluidData);
                    }
                }
            }
        }
    }

    /**
     * Create config from the inner and outer circle series
     * @param {DoubleDonutProperties} pieChartProperties
     */
    private getDoubleDonutConfig(pieChartProperties: DoubleDonutProperties) {
        pieChartProperties.options = this.chartService.getHighchartDoublePieConfig(
            pieChartProperties.dataInside,
            pieChartProperties.dataOutside,
            pieChartProperties.unit
        );
    }

    /**
     * BAR
     */

    /**
     *
     */
    private handleBar(): void {
        // sort
        this.chartService.sortByStack(this.barToggle.datasets);

        // units in datasets series
        this.barToggle.datasets.forEach((dataset, unitIndex) => {
            dataset.forEach(serie => {
                serie.unit = this.barToggle.units[unitIndex];
            });
        });

        // colors
        this.barToggle.colors = this.chartService.getStackColors(this.barToggle);

        // config
        const filters = this.filterComponent.getFilters();
        const groupNamesTooltips = filters.map(filter => {
            return this.chartService.getChartLegendContentByFilter(filter, this.queryFilterSelected.value);
        });
        const options = this.chartService.getConfig('groupable-stacked-bar', {
            tooltips: this.chartService.getTooltipHTMLConfig(
                'groupable-stack-followup',
                null,
                groupNamesTooltips,
                this.queryFilterSelected.totalGroupName
            ),
        });
        this.barToggle.options = this.chartService.getDatasetsAxesOptions(this.barToggle.datasets, options);
    }

    /**
     * --------
     * CHARTS
     * --------
     */

    /**
     * Used to disable filter search.
     * @returns {boolean} true if at least one graph is loading.
     */
    isSomeChartLoading(): boolean {
        return _.values(this.loading.charts).some(elem => elem === true);
    }

    /**
     * @param {string} title - begining of the full filename
     * @returns {string} name of the export file correctly formatted
     */
    getExportName(title: string): string {
        const date = moment()
            .format('YYYYMMDD')
            .toString();
        const filterType = this.queryFilterSelected ? this.translateService._(this.queryFilterSelected.value) : '';
        return `${title}-${date}-${filterType}.xlsx`;
    }

    getExportExcelUrl(type: string) {
        if (type && this.exportFilters) {
            this.exportFilters.dju = false;
            let url = `/api/export/excel/followup/${type}`;
            url += this.filterService.formatQueryToUrl(this.exportFilters);

            return url;
        }
        return null;
    }

    getSiteConsumptionExportExcelUrl(type: string) {
        if (type && this.exportFilters) {
            this.exportFilters.dju = false;
            let url = `/api/export/excel/${type}`;
            url += this.filterService.formatQueryToUrl(this.exportFilters);

            return url;
        }
        return null;
    }

    public isLoadingData(): boolean {
        return Boolean(this.loading.data);
    }

    /**
     * @returns {boolean} true if chart has data for both circles (inside & outside)
     */
    public hasDataForConsoDonut(): boolean {
        try {
            const series: DoublePieOptionsSeries[] = this.donuts.conso.options.series;
            return Boolean(series.length && series.every(serie => Boolean(serie.data.length)));
        } catch (err) {
            return false;
        }
    }

    /**
     * @returns {boolean} true if one of the toggle has data for both circles (inside & outside)
     */
    public hasDataForToggleDonut(): boolean {
        try {
            const chartOptionSets = this.donutToggle.optionsSets;
            return Boolean(
                chartOptionSets.length === 2 &&
                    chartOptionSets.some(set => set.series.every(serie => serie.data.length))
            );
        } catch (err) {
            return false;
        }
    }

    /**
     * @returns {boolean} true if one of the toggle datasets has data to display
     */
    public hasDataForBar(): boolean {
        try {
            return this.barToggle.datasets.length && this.barToggle.datasets.some(dataSet => Boolean(dataSet.length));
        } catch (e) {
            return false;
        }
    }

    /**
     * @returns {boolean} true if both data and charts have loaded
     */
    public isReadyToDisplay(): boolean {
        return !this.isLoadingData() && !this.isSomeChartLoading();
    }

    getChartLegends(labels: any, options = {}) {
        return labels.map(label => this.chartService.getLegend(label, options));
    }

    /**
     * Display error message for the user.
     * @param {string} errorCode - error code
     */
    private handleError(errorCode: string = null) {
        const errors: { [code: string]: [string, string, SweetAlertIcon] } = {
            default: ['Une erreur inconnue est survenue', 'Merci de réessayer ultérieurement.', 'error'],
            map_markers: ["Impossible d'afficher les sites", 'Merci de réessayer ultérieurement.', 'error'],
            set_chart_data: [
                "Impossible d'afficher les données de consommation.",
                'Merci de réessayer ultérieurement.',
                'error',
            ],
        };
        let e = errors.default;
        if (errorCode && errors[errorCode]) {
            e = errors[errorCode];
        }
        swal.fire(e[0], e[1], e[2]);
    }
}
