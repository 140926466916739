import { Injectable } from '@angular/core';

/**
 * Rgb colors used in charts
 */
const colorsRgb: { [key: string]: string } = {
    YELLOW: '252, 168, 40',
    GREEN: '35, 157, 132',
    GREEN2: '0, 128, 0',
    SECOND_GREEN2: '11, 162, 11',
    GREEN_FLAT_NORMAL: '141, 190, 74',
    GREEN_FLAT_DARK: '35, 157, 132',
    BLUE: '66, 129, 184',
    BLUEGREY: '48,130,184',
    PURPLE: '117,97,194',
    HT_GREEN: '47,173,99',
    TURQUOISE: '68,157,132',
    RED: '166,36,36',
    RED2: '255,0,0',
    RED3: '219,0,0',
    RED_FLAT_NORMAL: '219, 75, 71',
    SECOND_GREEN: '40,186,155',
    SECOND_YELLOW: '253,204,45',
    GREY: '108, 117, 117',
    GREY2: '128, 128, 128',
    LIGHTGREY: '211, 211, 211',
    BROWN: '179, 103, 0',
    ORANGE: '223, 124, 58',
    SECOND_ORANGE: '255, 153, 0',
    ORANGE_FLAT_DARK: '223, 114, 58',
    ORANGE_FLAT: '251, 171, 131',
    LIGHT_ORANGE: '234, 168, 125',
    DARK_ORANGE: '177, 87, 29',
    SECOND_BLUE: '105, 158, 210',
    SECOND_PURPLE: '91, 75, 162',
    SECOND_GREY: '144, 158, 160',
    SECOND_HT_GREEN: '56, 202, 117',
    SECOND_TURQUOISE: '82, 184, 153',
    DARK_TURQUOISE: '57, 134, 113',
    DARK_BLUE1: '16, 78, 139',
    DARK_BLUE2: '66, 129, 183',
    LIGHT_BLUE: '172, 204, 236',
    LIGHT_BLUE2: '116, 171, 226',
    LIGHT_BLUE3: '92, 175, 253',
    LIGHT_BLUE4: '152, 206, 255',
    LIGHT_BLUE5: '173, 216, 230',
    BURGUNDY: '115, 30, 86',
    SECOND_BURGUNDY: '171, 47, 82',
    LIGHT_BURGUNDY: '191, 126, 144',
    PALE_GREEN: '163, 197, 87',
    SECOND_PALE_GREEN: '107, 163, 0',
    DIESEL_GREEN: '33, 88, 56',
    SECOND_RED: '217,48,48',
};

/**
 * Hex colors from app/shared/style/colors.scss
 */
const pageColorsHex: { [key: string]: string } = {
    TEXT_COLOR: '#607188',
    BORDER_GREY: '#d9d9d9',
};

/**
 * Hex colors used in charts
 */
const colorsHex: { [key: string]: string } = {
    WHITE: '#ffffff',
    TURQUOISE: '#449d84',
    SECOND_TURQUOISE: '#52b99a',
    DARK_TURQUOISE: '#398671',
    BLUE: '#4281b8',
    SECOND_BLUE: '#699ED2',
    DARK_BLUE1: '#104E8B',
    DARK_BLUE2: '#4281B7',
    LIGHT_BLUE: '#ACCCEC',
    LIGHT_BLUE2: '#74ABE2',
    LIGHT_BLUE3: '#5CAFFD',
    LIGHT_BLUE4: '#98CEFF',
    ADMIN: '#db4b47',
    BURGUNDY: '#731E56',
    SECOND_BURGUNDY: '#AB2F52',
    LIGHT_BURGUNDY: '#BF7E90',
    ORANGE: '#DF7C3A', // @todo: check #FF9900
    RED: '#A62424',
    SECOND_RED: '#D93030',
};

/**
 * This service contains all the colors used in JS/HTML (not SCSS) for the application.
 * The best would be to never have a color outside this class to avoid duplicates and same colors not changed everywhere
 */
@Injectable()
export class ColorService {
    public colors: {
        [key: string]: {
            color: string;
            secondColor?: string;
        };
    } = {
        gaz: {
            color: colorsRgb.GREEN,
            secondColor: colorsRgb.SECOND_GREEN,
        },
        elec: {
            color: colorsRgb.YELLOW,
            secondColor: colorsRgb.SECOND_YELLOW,
        },
        water: {
            color: colorsRgb.BLUE,
            secondColor: colorsRgb.SECOND_BLUE,
        },
        fuel: {
            color: colorsRgb.ORANGE,
            secondColor: colorsRgb.SECOND_ORANGE,
        },
        heating: {
            color: colorsRgb.BURGUNDY,
            secondColor: colorsRgb.SECOND_BURGUNDY,
        },
        cooling: {
            color: colorsRgb.LIGHT_BLUE3,
            secondColor: colorsRgb.LIGHT_BLUE4,
        },
        unknown: {
            color: colorsRgb.GREY,
            secondColor: colorsRgb.SECOND_GREY,
        },
        co2: {
            color: colorsRgb.SECOND_PURPLE,
            secondColor: colorsRgb.PURPLE,
        },
        ttc: {
            color: colorsRgb.GREY,
            secondColor: colorsRgb.SECOND_GREY,
        },
        ht: {
            color: colorsRgb.HT_GREEN,
            secondColor: colorsRgb.SECOND_HT_GREEN,
        },
        gaz_taxes: {
            color: colorsRgb.SECOND_TURQUOISE,
        },
        gaz_networkServices: {
            color: colorsRgb.TURQUOISE,
        },
        gaz_conso: {
            color: colorsRgb.DARK_TURQUOISE,
        },
        gaz_subscription: {
            color: colorsRgb.GREEN,
        },
        heating_subscription: {
            color: colorsRgb.SECOND_BURGUNDY,
        },
        heating_consumption: {
            color: colorsRgb.BURGUNDY,
        },
        heating_regulation: {
            color: colorsRgb.DARK_TURQUOISE,
        },
        heating_miscellaneous: {
            color: colorsRgb.LIGHT_BURGUNDY,
        },
        water_publicOrganismAndVAT: {
            color: colorsRgb.LIGHT_BLUE,
        },
        water_wasteWaterTreatment: {
            color: colorsRgb.SECOND_BLUE,
        },
        water_productionAndDistribution: {
            color: colorsRgb.DARK_BLUE2,
        },
        water_subscription: {
            color: colorsRgb.DARK_BLUE1,
        },
        goal_reached: {
            color: colorsRgb.PALE_GREEN,
            secondColor: colorsRgb.SECOND_PALE_GREEN,
        },
        powers_subscribe: {
            color: colorsRgb.ORANGE,
        },
        powers_reached: {
            color: colorsRgb.SECOND_YELLOW,
        },
        HC: {
            color: colorsRgb.LIGHT_ORANGE,
        },
        HP: {
            color: colorsRgb.ORANGE,
        },
        pointe: {
            color: colorsRgb.DARK_ORANGE,
        },
        total_conso: {
            color: colorsRgb.SECOND_YELLOW,
        },
        total_conso_elec_dju_ratio: {
            color: colorsRgb.SECOND_YELLOW,
        },
        total_conso_elec_dju_ratio_kwhep: {
            color: colorsRgb.SECOND_YELLOW,
        },
        total_cost: {
            color: colorsRgb.SECOND_YELLOW,
        },
        total_cost_water: {
            color: colorsRgb.SECOND_BLUE,
        },
        total_conso_water: {
            color: colorsRgb.LIGHT_BLUE2,
        },
        water_production_distribution: {
            color: colorsRgb.BLUE,
        },
        taxes: {
            color: colorsRgb.SECOND_BLUE,
        },
        total_conso_gas: {
            color: colorsRgb.TURQUOISE,
        },
        total_conso_gas_dju_ratio: {
            color: colorsRgb.TURQUOISE,
        },
        total_cost_gas: {
            color: colorsRgb.SECOND_TURQUOISE,
        },
        heating_heating: {
            color: colorsRgb.BURGUNDY,
        },
        heating_ecs: {
            color: colorsRgb.DARK_BLUE2,
        },
        total_conso_heating: {
            color: colorsRgb.LIGHT_BURGUNDY,
        },
        total_conso_heating_calculated: {
            color: colorsRgb.BURGUNDY,
        },
        total_conso_heating_dju_ratio: {
            color: colorsRgb.BURGUNDY,
        },
        total_cost_heating: {
            color: colorsRgb.LIGHT_BURGUNDY,
        },
        total_cost_heating_calculated: {
            color: colorsRgb.BURGUNDY,
        },
        djus: {
            color: colorsRgb.BLUEGREY,
        },
        campaignGoal: {
            color: colorsRgb.ORANGE,
        },
        cooling_consumption: {
            color: colorsRgb.LIGHT_BLUE3,
        },
        cooling_subscription: {
            color: colorsRgb.LIGHT_BLUE4,
        },
        cooling_energy: {
            color: colorsRgb.LIGHT_BLUE3,
        },
        cooling_volume: {
            color: colorsRgb.LIGHT_BLUE4,
        },
        cooling_total_cost: {
            color: colorsRgb.LIGHT_BLUE4,
        },
        vehicle_quantity: {
            color: colorsRgb.YELLOW,
            secondColor: colorsRgb.SECOND_YELLOW,
        },
        vehicle_incoming: {
            color: colorsRgb.GREEN,
            secondColor: colorsRgb.SECOND_GREEN,
        },
        vehicle_outgoing: {
            color: colorsRgb.RED,
            secondColor: colorsRgb.SECOND_RED,
        },
        vehicles_emissions: {
            color: colorsRgb.ORANGE,
        },
        diesel: {
            color: colorsRgb.SECOND_ORANGE,
            secondColor: colorsRgb.SECOND_ORANGE,
        },
        gasoline: {
            color: colorsRgb.DIESEL_GREEN,
            secondColor: colorsRgb.DIESEL_GREEN,
        },
        undercharged: {
            color: colorsRgb.SECOND_ORANGE,
        },
        overcharged: {
            color: colorsRgb.RED3,
        },
        valid: {
            color: colorsRgb.GREEN2,
        },
        not_controlled: {
            color: colorsRgb.GREY2,
        },
        missing_measures: {
            color: colorsRgb.GREY,
            secondColor: colorsRgb.SECOND_GREY,
        },
        min_elec_power: {
            color: colorsRgb.GREEN2,
            secondColor: colorsRgb.SECOND_GREEN2,
        },
        max_elec_power: {
            color: colorsRgb.RED,
            secondColor: colorsRgb.RED2,
        },
        monitoring_conso_noticed: {
            color: colorsRgb.GREEN_FLAT_DARK,
        },
        monitoring_conso_expected: {
            color: colorsRgb.ORANGE_FLAT_DARK,
        },
        monitoring_conso_remaining: {
            color: colorsRgb.ORANGE_FLAT,
        },
        monitoring_deviation_formula: {
            color: colorsRgb.GREEN_FLAT_NORMAL,
        },
        monitoring_deviation_positive: {
            color: colorsRgb.GREEN_FLAT_NORMAL,
        },
        monitoring_deviation_negative: {
            color: colorsRgb.RED_FLAT_NORMAL,
        },
        rref_communicating_communicating: {
            color: colorsRgb.GREEN_FLAT_DARK,
        },
        rref_communicating_not_communicating: {
            color: colorsRgb.RED_FLAT_NORMAL,
        },
        rref_communicating_can_communicate: {
            color: colorsRgb.YELLOW,
        },
        rref_communicating_invalid_communicating: {
            color: colorsRgb.GREY,
        },
    };

    getRgbColors() {
        return colorsRgb;
    }

    getColors() {
        return this.colors;
    }

    /**
     * Get element rgb color at format 'r,g,b'
     * @param {string} element - element key
     * @returns {string|null} rgb color string at format 'r,g,b' or null if element not found
     */
    getRgbColorValue(element: string) {
        if (this.colors[element]) {
            return this.colors[element].color;
        }
        return null;
    }

    /**
     * Get element rgb color at format 'rgb(r,g,b)'
     * @param {string} element - element key
     * @returns {string|null} rgb color string at format 'rgb(r,g,b)' or null if element not found
     */
    getRgbColor(element: string) {
        const rgbColor = this.getRgbColorValue(element);
        if (rgbColor) {
            return `rgb(${rgbColor})`;
        }

        return null;
    }

    /**
     * Get element second rgb color at format 'r,g,b'.
     * If there is no second color, returns first color.
     * @param {string} element - element key
     * @returns {string|null} rgb color string at format 'r,g,b' or null if element not found
     */
    getRgbSecondColorValue(element: string) {
        if (this.colors[element] && this.colors[element].secondColor) {
            return this.colors[element].secondColor;
        }
        return this.getRgbColorValue(element);
    }

    /**
     * Get element second rgb color at format 'rgb(r,g,b)'
     * @param {string} element - element key
     * @returns {string|null} rgb color string at format 'rgb(r,g,b)' or null if element not found
     */
    getSecondRgbColor(element: string) {
        const rgbColor = this.getRgbSecondColorValue(element);
        if (rgbColor) {
            return `rgb(${rgbColor})`;
        }

        return null;
    }

    /**
     * Return the hex color from the given name
     * @param {string} colorName
     */
    getHexColor(colorName: string) {
        return colorsHex[colorName];
    }

    /**
     * Return the hex page color from the given page name
     * @param {string} pageName
     */
    getHexPageColor(pageName: string): string {
        return pageColorsHex[pageName];
    }

    hexToRgba(hexColor, opacity = 1) {
        hexColor = hexColor.charAt(0) === '#' ? hexColor.substring(1, 7) : hexColor;

        return hexToR(hexColor) + ', ' + hexToG(hexColor) + ', ' + hexToB(hexColor) + ', ' + opacity;

        function hexToR(h) {
            return parseInt(h.substring(0, 2), 16);
        }
        function hexToG(h) {
            return parseInt(h.substring(2, 4), 16);
        }
        function hexToB(h) {
            return parseInt(h.substring(4, 6), 16);
        }
    }
}
