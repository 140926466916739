import { Injectable } from '@angular/core';
import { UserSession } from './session.interface';

@Injectable()
export class SessionService {
    constructor() {}

    setToken(token) {
        this.set('token', token);
    }

    getToken() {
        return this.get('token');
    }

    /**
     * Check if there is a token for current session
     * @returns {boolean} true if there is a token in local storage, false otherwise
     */
    public hasToken(): boolean {
        return Boolean(this.getToken());
    }

    removeToken() {
        this.remove('token');
    }

    setUser(user: UserSession) {
        this.set('user', user);
    }

    getUser(): UserSession {
        return this.get('user');
    }

    removeUser() {
        this.remove('user');
    }

    setCompany(company) {
        this.set('company', company);
    }

    getCompany() {
        return this.get('company');
    }

    /**
     * Get session company id
     * @returns {string} id of company currently logged to
     */
    getCompanyId(): string {
        const currentCompany = this.get('company');
        return currentCompany && currentCompany._id ? currentCompany._id : '';
    }

    setCompanies(companies) {
        this.set('companies', companies);
    }

    getCompanies() {
        return this.get('companies');
    }

    removeCompany() {
        this.remove('company');
    }

    setLastVisitedSites(site) {
        const userId = this.getUser()._id;

        if (site && site._id) {
            let sites = this.getLastVisitedSites();
            sites = sites.filter(element => site._id !== element.id);

            sites.push({
                id: site._id,
                userId,
                date: Date.now(),
            });

            this.set('lastVisitedSites', sites);
        }
    }

    getLastVisitedSites(): any[] {
        const sites = this.get('lastVisitedSites');

        if (sites === null) {
            return [];
        }

        const userId = this.getUser()._id;

        // We usually push the visited sites one after another. So to see the last in top, we reverse the array
        return sites
            .filter(site => site.id && site.userId && site.userId === userId)
            .reverse()
            .slice(0, 5);
    }

    removeLastVisitedSites() {
        return this.remove('lastVisitedSites');
    }

    private set(element: string, value: any) {
        localStorage.setItem(element, JSON.stringify(value));
    }

    private get(element: string): any {
        try {
            return JSON.parse(localStorage.getItem(element));
        } catch (e) {
            return null;
        }
    }

    remove(element) {
        localStorage.removeItem(element);
    }

    getVersion() {
        return this.get('version');
    }

    /**
     * Check user local storage angular version and clear the local storage if the user has an old one.
     * If updated true and user has an old version, update the version after clearing it.
     * @param {string}version - angular version to compare. (usually the most recent one)
     * @param {boolan} update - update or not the user local storage version if the local version is different.
     */
    checkVersion(version: string, update: boolean = false) {
        const userVersion = this.get('version');

        if (!userVersion || userVersion !== version) {
            // localStorage.clear();
            if (update) {
                this.set('version', version);
            }
            return false;
        }
        return true;
    }

    clearLocalStorage() {
        localStorage.clear();
    }
}
