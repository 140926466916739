import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { DevisComponent } from './devis.component';

const DEVIS_ROUTE = [{ path: '', component: DevisComponent }];

@NgModule({
    declarations: [DevisComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(DEVIS_ROUTE)],
})
export class DevisModule {}
