import { Component, SimpleChange } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordService } from './reset-password.service';

@Component({
    selector: 'ga-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    providers: [ResetPasswordService],
})
export class ResetPasswordComponent {
    isActivation = false;
    isSSOProviderActivation = false;
    providerName: string;
    passwordResetForm: FormGroup;
    apiError: string;
    visibility = 'hidden';
    isPasswordChanged = false;
    isPasswordChanging = false;
    token: string = null;
    /**  According to the strength of the password, this object contains :
     * - a table with the missing prerequisites
     * - a table with the validated prerequisites
     * */
    public prerequisites: { prerequisitesMissing: boolean[]; prerequisitesValidated: boolean[] } = {
        prerequisitesMissing: [],
        prerequisitesValidated: [],
    };

    /** Colors for strength bar of password*/
    private colors: string[] = ['#D8454A', '#F48C54', '#EBC44E', '#F9C975', '#7E9E7B'];
    constructor(
        fb: FormBuilder,
        private router: Router,
        private resetPasswordService: ResetPasswordService,
        private route: ActivatedRoute
    ) {
        this.isActivation = route.snapshot.data.activation || false;
        this.route.queryParams.subscribe({
            next: params => {
                this.token = params['token'];
                this.isSSOProviderActivation = params['provider'] || false;
                if (this.isSSOProviderActivation) {
                    this.providerName = params['provider'];
                }
            },
        });
        this.passwordResetForm = fb.group(
            {
                password: ['', [Validators.required, Validators.minLength(8)]],
                passwordCheck: ['', [Validators.required, Validators.minLength(8)]],
            },
            {
                validator: this.matchPassword.bind(this),
            }
        );
    }

    /**
     * Check password prerequisites conformity then check matching of both password inputs
     * @param {AbstractControl} control
     * @returns
     */
    private matchPassword(control: AbstractControl) {
        const password = control.get('password').value; // to get value in input tag
        const confirmPassword = control.get('passwordCheck').value; // to get value in input tag
        this.prerequisites = this.resetPasswordService.getPrerequisitesValuesOfPassword(password);
        if (password && this.prerequisites.prerequisitesMissing.length > 0) {
            control.get('password').setErrors({ Prerequisites: true });
        } else if (password !== confirmPassword) {
            control.get('passwordCheck').setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }

    public getErrorClass(control: FormControl): object {
        return { 'has-error': !control.valid && control.touched };
    }

    public inputHasError(control: FormControl, errorType): boolean {
        return control.errors && control.errors[errorType] && control.touched;
    }

    public submitForm(form: any): void {
        this.apiError = null;
        if (
            this.isSSOProviderActivation ||
            (this.passwordResetForm.valid && !this.isPasswordChanged && !this.isPasswordChanging)
        ) {
            this.isPasswordChanging = true;
            this.resetPasswordService
                .resetOrActivate(form, this.token, this.isActivation, this.isSSOProviderActivation)
                .then(response => {
                    this.isPasswordChanging = false;
                    this.isPasswordChanged = true;
                })
                .catch(err => {
                    this.isPasswordChanging = false;
                    if (err.code === 410) {
                        this.apiError = `Le lien n'est pas valide ou a expiré.`;
                    } else {
                        this.apiError = 'Une erreur est survenue, veuillez réessayer plus tard';
                    }
                });
        } else {
            this.touchedAllFields();
        }
    }

    private touchedAllFields() {
        Object.keys(this.passwordResetForm.controls).forEach(field => {
            const control = this.passwordResetForm.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }

    public goToLogin() {
        this.router.navigateByUrl('/authentification');
    }

    get passwordChangingText() {
        if (this.isActivation) {
            if (!this.isPasswordChanging) {
                return 'Activer mon compte';
            }
            return 'Activation en cours...';
        } else {
            if (!this.isPasswordChanging) {
                return 'Changer le mot de passe';
            }
            return 'Changement en cours...';
        }
    }

    get instructionText() {
        if (this.isSSOProviderActivation) {
            return `Activez votre compte et connectez vous avec votre adresse email "${this.providerName}".`;
        }
        if (this.isActivation) {
            return 'Pour activer votre compte, veuillez choisir un mot de passe et le confirmer. ';
        }
        return 'Veuillez entrer votre nouveau mot de passe et le confirmer.';
    }

    /**
     * return the description of prerequisite
     * @param {string} prerequisite the name of the prerequiste
     * @return {string} description of the prerequisite to show in the view
     */
    public getDescOfPrerequisite(prerequisite: string): string {
        let desc = '';
        if (prerequisite === 'lower') {
            desc = 'au moins une lettre minuscule';
        } else if (prerequisite === 'upper') {
            desc = 'au moins une lettre majuscule';
        } else if (prerequisite === 'digit') {
            desc = 'au moins un chiffre';
        } else if (prerequisite === 'special') {
            desc = 'au moins un caractère spécial';
        } else if (prerequisite === 'charCount') {
            desc = 'au moins 8 caractères';
        }
        return desc;
    }

    /**
     * get the color of the scale according to password strength
     * @return {string} color in hexadecimal RGB
     */
    get passwordStrengthColor() {
        return this.colors[this.prerequisites.prerequisitesValidated.length - 1];
    }
}
