import { FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class Validators {
    /**
     * Check if the given value is an integer
     *
     * @param {FormControl} control
     * @return {ValidationErrors | null}
     */
    static isInteger(control: FormControl): ValidationErrors | null {
        if (!Number.isInteger(control.value)) {
            return { notInteger: true };
        }

        return null;
    }

    /**
     * Return a validator function which check is the value has maximum a given amount of decimals
     *
     * @param {number} n Maximum amout of decimals wanted
     * @return {ValidatorFn}
     */
    static hasMaxNDecimals(n: number): ValidatorFn {
        return (control: FormControl): ValidationErrors | null => {
            if (control.value === null) {
                // Not required
                return null;
            }

            if (!Number.isFinite(control.value)) {
                return { notNumber: true };
            }
            const decimalPart = control.value.toString().split('.')[1];
            if (decimalPart && decimalPart.length > n) {
                return { [`moreThan${n}Decimals`]: true };
            }

            return null;
        };
    }
}
