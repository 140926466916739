import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import Swal, { SweetAlertIcon } from 'sweetalert2';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Condition, Scenario } from 'app/shared/models/scenario.model';
import { AlertsService } from 'app/shared/services/alerts/alerts.service';

@Component({
    selector: 'ga-alerts-edition',
    templateUrl: './alerts-edition.component.html',
    styleUrls: ['./alerts-edition.component.scss'],
    providers: [],
})
export class AlertsEditionComponent implements OnInit {
    /**
     * Scenario to edit. Leave empty or null to create a new one.
     * An object from type ScenarioDisplay can be sent, as it only have one more property (display)
     */
    @Input() scenario: Scenario = null;

    /**
     * Set read only view for the component
     */
    @Input() readonly = false;

    /**
     * Triggered when save done or cancel button pressed.
     * True passed when saved, false when cancel triggered
     */
    @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Last condition value from the condition builder component
     */
    newCondition: Condition;

    /**
     * True if the new condition is valid, false otherwise
     */
    validCondition: boolean;

    /**
     * Is scenario being saved
     */
    isSaving = false;

    constructor(private alertsService: AlertsService) {}

    ngOnInit() {
        if (!this.scenario) {
            this.scenario = this.alertsService.createScenarioObject();
        }
    }

    /**
     * Submit changes for the scenario.
     * Send save request to the API
     */
    async submitForm() {
        try {
            this.isSaving = true;
            // Verify if the scenario condition is valid
            const isValid = this.validCondition;

            if (!isValid) {
                throw { errorCode: 'invalid_scenario' };
            }

            this.scenario.condition = this.newCondition;
            // TODO change it with name when implemented
            if (!this.scenario.msgTitle) {
                this.scenario.msgTitle = 'Scénario ' + this.scenario.name + ' réalisé';
            }
            if (!this.scenario.msgBody) {
                this.scenario.msgBody =
                    'La condition du scénario ' +
                    this.scenario.name +
                    ' a été remplie. Vous recevez cette notification car vous êtes abonné(e) à ce scénario';
            }

            // If scenario has an _id, update it, otherwise create one.
            if (this.scenario._id && this.scenario._id !== null) {
                await this.alertsService.updateScenario(this.scenario);
            } else {
                await this.alertsService.insertScenario(this.scenario);
            }
        } catch (e) {
            this.handleErrorMessage(e.errorCode);
        }
        this.isSaving = false;

        this.done.emit(true);
    }

    /**
     * Triggered by the cancel button of the form.
     */
    cancel() {
        this.done.emit(false);
    }

    /**
     * Toggle flagMute value for scenario flagMute
     * @param {MatSlideToggleChange} change - slide toggle change new value
     */
    toggleMute(change: MatSlideToggleChange) {
        this.scenario.flagMute = !change.checked;
    }

    /**
     * @returns {boolean} true if the scenario is already save, false otherwise
     */
    get isCreating(): boolean {
        return Boolean(this.scenario && !this.scenario._id);
    }

    /**
     * Open a swal with the error explained
     * @param {string} errorCode - error code
     */
    handleErrorMessage(errorCode: string) {
        let msg: [string, string, SweetAlertIcon];
        const mapping: { [key: string]: [string, string, SweetAlertIcon] } = {
            invalid_scenario: [
                "Création de l'alerte impossible",
                "Vous devez sélectionner un PDL, une donnée source, un opérateur ainsi qu'une valeur de comparaison",
                'error',
            ],
            error_save_scenario: [
                "Création de l'alerte impossible",
                'Une erreur inconnue est survenue. Merci de réessayer ultérieurement',
                'error',
            ],
            scenarioValidation__rights_fail: [
                "Création de l'alerte impossible",
                "Vous n'avez pas les droits pour créer cette alerte",
                'error',
            ],
            scenarioValidation_condition_fail_inconsistent_threshold: [
                "Création de l'alerte impossible",
                "La condition de l'alerte ne peut être acceptée en raison de son seuil",
                'error',
            ],
            scenarioValidation_condition_fail_comparedProperty: [
                "Création de l'alerte impossible",
                "La condition de l'alerte ne peut être acceptée en raison de sa valeur de comparaison",
                'error',
            ],
            scenarioValidation_scope_fail_wrong_fluid: [
                "Création de l'alerte impossible",
                "Le fluide du PDL n'est pas encore supporté",
                'error',
            ],
            scenarioValidation_scope_fail_inconsistent_routingReference: [
                "Création de l'alerte impossible",
                'Vous devez choisir un PDL ou tous les PDLs ("Tout sélectionner")',
                'error',
            ],
            scenarioValidation_type_fail_inconsistent_type: [
                "Création de l'alerte impossible",
                "Type d'alerte inconnu",
                'error',
            ],
            not_allowed: [
                "Edition de l'alerte impossible",
                "Vous n'avez pas les droits pour éditer cette alerte",
                'error',
            ],
            internal_error: [
                "Edition de l'alerte impossible",
                'Une erreur inconnue est survenue. Merci de réessayer ultérieurement',
                'error',
            ],
        };
        msg = mapping[errorCode];
        if (!msg) {
            msg = mapping['internal_error'];
        }
        Swal.fire(msg[0], msg[1], msg[2]);
    }
}
