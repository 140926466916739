import { HttpEventType } from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    Type,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';
import Swal from 'sweetalert2';
import { Question } from '../../../models/question.interface';
import { QuestionService } from '../../../services/sites/question.service';
import { FileQuestionComponent } from '../questions/file-upload/file-upload.component';
import { GroupQuestionComponent } from '../questions/group-collapse/group-collapse.component';
import { InputTextMultipleComponent } from '../questions/input-text-multiple/input-text-multiple.component';
import { InputTextComponent } from '../questions/input-text/input-text.component';
import { ModalQuestionComponent } from '../questions/modal/modal.component';
import { SelectChoicesBlocComponent } from '../questions/select-choices-bloc/select-choices-bloc.component';
import { SelectChoicesInputComponent } from '../questions/select-choices-input/select-choices-input.component';
import { SelectChoicesComponent } from '../questions/select-choices/select-choices.component';
import { SelectDropdownComponent } from '../questions/select-dropdown/select-dropdown.component';

/**
 * Main component to handle the legacy data.
 * The schema below describe which component can create which component.
 * LegacyDataComponent ----------
 *  |   |                       |
 *  |   V                       |
 *  |  ModalQuestionComponent   |
 *  |   |                   |   |
 *  V   V                   |   |
 * GroupQuestionComponent   |   |
 *  |                       |   |
 *  V                       V   V
 *  --------------------------------------------------------------------
 * | FileQuestionComponent, InputTextComponent, SelectChoicesComponent, |
 * | SelectDropdownComponent, InputTextMultipleComponent,               |
 * | SelectChoicesInputComponent, SelectChoicesBlocComponent            |
 *  --------------------------------------------------------------------
 * All components (except LeagcyDataComponent) extend QuestionComponent and implement Question
 * @Input data: legacy data for on site/zone.
 * @Input config: configuration file to create the template.
 * @Input siteId: ID of the site related to the legacy data
 * @Input zone: ID of the zone related to the legacy data (if multi zone site)
 */
@Component({
    selector: 'ga-sites-legacy-data',
    templateUrl: './legacy-data.component.html',
    styleUrls: ['./legacy-data.component.scss'],
})
export class LegacyDataComponent implements OnInit, AfterViewInit {
    @Input() data: any[];
    @Input() config: any;
    @Input() siteId = '';
    @Input() zoneId = '';

    // Tab selected by the user
    currentTab = '';

    // Values entered by the user. Contains all values from every questions except for files
    private values: Array<{ key: string; value: any }> = [];

    // Get all children of ng-template to replace it by questions components.
    @ViewChildren('question', { read: ViewContainerRef }) questionItems: QueryList<ViewContainerRef>;

    // Progress for all questions between 0 and 100. (%)
    totalProgress: number;

    // Progress for each tab. Object dynamically created (~ associative array)
    tabsProgress = {};

    // Is the view initialized or not. Used to allow the updateProgress function to be run after initialization.
    private viewInitialized = false;

    private valuesChecksumLastSave: string = null;

    @Output() progressUpdated: EventEmitter<any> = new EventEmitter<any>();

    constructor(private questionService: QuestionService, private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.totalProgress = 0;
    }

    ngAfterViewInit(): void {
        let index = 0;
        // For each tab defined by the config file
        this.config.tabs.forEach(tab => {
            // For each question in the tab
            tab.infos.forEach(question => {
                // Get the corresponding ng-template element to create a question
                const vcr = this.questionItems.toArray()[index];
                this.addQuestion(question, vcr);
                index++;
            });
            // Create and init the tab progress for the tab
            this.tabsProgress[tab.key] = 0;
        });
        // Set the current tab to the first one
        if (this.config.tabs && this.config.tabs.length > 0) {
            this.currentTab = this.config.tabs[0].key;
        }
        // As we change values outside a change detection cycle, we force the detection
        // Known problem in Angular 4
        this.cdr.detectChanges();
        this.viewInitialized = true;
        this.valuesChecksumLastSave = JSON.stringify(this.values);
        this.updateProgress();
        this.cdr.detectChanges();
    }

    /**
     * Save data to DB when user click on the save button or when a file is uploaded
     */
    async saveData() {
        const options = {
            values: this.values,
            site: this.siteId,
            zone: this.zoneId,
        };
        const result = await this.questionService.saveLegacyData(options);
        this.valuesChecksumLastSave = JSON.stringify(options.values);
        return result;
    }

    /**
     * Change the tab displayed to the user on user click
     * @param tab: key of the tab
     */
    display(tab) {
        this.currentTab = tab;
    }

    /**
     * Return the class either the tab is active or not
     * @param tab: key of the tab
     * @returns {string | string} class to display tab or not
     */
    getTabClass(tab) {
        return this.currentTab === tab ? 'show active' : '';
    }

    /**
     * Return the class either the tab is active or not
     * @param tab: key of the tab
     * @returns {string | string} class to display tab or not
     */
    getTabLinkClass(tab) {
        return this.currentTab === tab ? 'show active citron-background' : '';
    }

    /**
     * Get the col width of questions. For modal, create smaller columns.
     * @param question: question config
     * @returns {string}: class for the column
     */
    getQuestionClass(question) {
        let qClass = 'mb-1 mt-1 question ';
        qClass += question.type === 'modal' ? 'col-md-3' : 'col-md-12';
        return qClass;
    }

    /******* COMPONENTS CREATION *******/

    /**
     * Create and add to the DOM the question element and return the observable for modifications
     * @param data: data from DB for the site's legacy data
     * @param config: config of the question
     * @param vcr: DOM element where the question component will be placed (vcr = ViewContainerRef).
     * @returns {any} object containing event emitters to have interactions between question and this.
     */
    private createQuestion(data, config, vcr) {
        let typeComponent: Type<Question> = null;
        switch (config.type) {
            case 'simple-choices':
                typeComponent = SelectChoicesComponent;
                break;
            case 'simple-choices-bloc':
                typeComponent = SelectChoicesBlocComponent;
                break;
            case 'input-text':
                typeComponent = InputTextComponent;
                break;
            case 'select-dropdown':
                typeComponent = SelectDropdownComponent;
                break;
            case 'input-text-multiple':
                typeComponent = InputTextMultipleComponent;
                break;
            case 'select-choices-input':
                typeComponent = SelectChoicesInputComponent;
                break;
            case 'file':
                typeComponent = FileQuestionComponent;
                break;
            case 'modal':
                typeComponent = ModalQuestionComponent;
                break;
            case 'group':
                typeComponent = GroupQuestionComponent;
                break;
        }
        if (typeComponent) {
            return this.questionService.addComponent(data, config, this.siteId, this.zoneId, typeComponent, vcr);
        } else {
            return null;
        }
    }

    /**
     * Create the question then listen to events of the questions.
     * @param config of the question
     * @param vcr reference of the container where the question is placed. (vcr = ViewContainerRef)
     */
    private addQuestion(config, vcr) {
        const obsAll = this.createQuestion(this.data, config, vcr);
        // When a value changes, update the values list.
        if (obsAll.change) {
            obsAll.change.subscribe(value => {
                if (Array.isArray(value)) {
                    value.forEach(item => {
                        this.addOrUpdate(item);
                    });
                } else {
                    this.addOrUpdate(value);
                }
                this.updateProgress();
            });
        }
        // When a file is selected, upload it and save the values with the reference to the file
        if (obsAll.fileUpload) {
            obsAll.fileUpload.subscribe(value => {
                let file = null;
                const fileValues = Object.assign(
                    {
                        siteId: this.siteId,
                        zoneId: this.zoneId,
                    },
                    value
                );
                this.questionService.uploadFile(value.files[0], fileValues).subscribe(
                    event => {
                        if (event.type === HttpEventType.Response) {
                            file = event.body.data;
                        }
                    },
                    error => {
                        Swal.fire(
                            'Oups',
                            "Une erreur est survenue pendant l'upload de votre fichier.<br>" +
                                'Seuls les fichiers aux formats PDF et PNG sont acceptés.<br>' +
                                "Merci d'essayer de nouveau, et si le problème persiste de nous contacter.",
                            'error'
                        );
                    },
                    () => {
                        if (file) {
                            // Notify the question that its file has been uploaded with success.
                            value.notif.emit(file);
                            // Save all data.
                            this.saveData();
                        }
                    }
                );
            });
        }
    }

    /**
     * Add or update data to the values list.
     * @param {key: string; value: any} item value from a question component
     */
    private addOrUpdate(item: { key: string; value: any }) {
        if (item) {
            const temp = this.values.find(x => x.key === item.key);
            if (!temp) {
                this.values.push(item);
            } else {
                temp.value = item.value;
            }
        }
    }

    /******* PROGRESS MANAGEMENT *******/

    /**
     * Update the progress for general and by tab.
     */
    private updateProgress() {
        if (this.viewInitialized) {
            const tmpProgress = {
                total: 0,
                filled: 0,
            };

            this.config.tabs.forEach(tab => {
                this.tabProgress(tab, tmpProgress);
            });
            if (tmpProgress.total > 0) {
                this.totalProgress = (tmpProgress.filled / tmpProgress.total) * 100;
            } else {
                this.totalProgress = 100;
            }
            const checksum = JSON.stringify(this.values);
            this.progressUpdated.emit({
                value: this.totalProgress,
                changed: checksum !== this.valuesChecksumLastSave,
            });
        }
    }

    /**
     * Get a tab progress between 0 and 100 (%)
     * @param tab: key of the tab
     * @returns {any} number between 0 and 100
     */
    public getTabProgress(tab) {
        return this.tabsProgress[tab.key];
    }

    /**
     * Compute a tab progress
     * @param tab: key of the tab
     * @param tmpProgress: progress follow-up up object ({total: number, filled: number}).
     */
    private tabProgress(tab, tmpProgress) {
        const tabProgress = {
            total: 0,
            filled: 0,
        };
        tab.infos.forEach(info => {
            if (info.type === 'modal') {
                info.properties.questions.forEach(q => {
                    this.groupProgress(tabProgress, q);
                });
            } else {
                this.groupProgress(tabProgress, info);
            }
        });
        tmpProgress.total += tabProgress.total;
        tmpProgress.filled += tabProgress.filled;
        this.tabsProgress[tab.key] = (tabProgress.filled / tabProgress.total) * 100;
    }

    /**
     * Compute the progress for groups (or not)
     * @param tmpProgress: progress follow-up up object ({total: number, filled: number}).
     * @param info: question config
     */
    private groupProgress(tmpProgress, info) {
        if (info.type === 'group') {
            // For a group, only one question needs to be filled to be considered as completed.
            const grpProgress = { total: 0, filled: 0 };
            info.properties.questions.forEach(q => {
                this.manageProgress(grpProgress, q, true);
            });
            tmpProgress.total += 1;
            tmpProgress.filled += grpProgress.filled > 0 ? 1 : 0;
        } else {
            this.manageProgress(tmpProgress, info);
        }
    }

    /**
     * Compute progress for each "classic" question type.
     * @param tmpProgress: progress follow-up up object ({total: number, filled: number}).
     * @param info: question config
     * @param {boolean} isGroup: is the question part of a group.
     */
    private manageProgress(tmpProgress, info, isGroup = false) {
        // These two types have a key at a different place than others.
        const multipleTypes = ['input-text-multiple', 'select-choices-input'];

        if (multipleTypes.find(x => x === info.type)) {
            if (info.properties && info.properties.values) {
                // Compute the progress for the question that have multiple answers.
                const mtProgress = { total: 0, filled: 0 };
                mtProgress.total += info.properties.values.length;
                info.properties.values.forEach(item => {
                    const val = this.values.find(y => y.key === item.key);
                    const filled = val ? (isGroup ? val.value[0] : val.value) : false;
                    mtProgress.filled += filled ? 1 : 0;
                });
                // For select-choices-input, at least one needs to be filled
                // to be considered as completed.
                if (info.type === 'select-choices-input') {
                    tmpProgress.total++;
                    tmpProgress.filled += mtProgress.filled > 0 ? 1 : 0;
                } else {
                    tmpProgress.total += mtProgress.total;
                    tmpProgress.filled += mtProgress.filled;
                }
            }
        } else {
            const val = this.values.find(y => y.key === info.properties.key);
            const filled = val ? (isGroup ? val.value[0] : val.value) : false;
            tmpProgress.filled += filled ? 1 : 0;
            tmpProgress.total++;
        }
    }

    public getTotalProgress() {
        return this.totalProgress;
    }
}
