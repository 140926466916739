import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Animations } from 'app/shared/models/animations';
import { CampaignsService } from 'app/shared/services/campaigns/campaigns.service';

interface CampaignLight {
    _id: string;
    name: string;
    state: number;
    campaignStart: string | Date;
    creator: { firstname: string; name: string };
    goal: number;
}

interface CampaignList {
    isDisplayed: boolean;
    isLoading: boolean;
    values: CampaignLight[];
    displayName: string;
}

@Component({
    selector: 'ga-campaigns-nav',
    templateUrl: './campaigns-nav.component.html',
    styleUrls: ['./campaigns-nav.component.scss'],
    providers: [],
    animations: [Animations.slideInOut, Animations.slideInOutHorizontal],
})
export class CampaignsNavComponent implements OnInit {
    /**
     * Emit event when create a campaign button is clicked.
     */
    @Output() createCompaign: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Emit event containing a campaign _id when a campaign is selected by the user.
     */
    @Output() campaignSelectedChanged: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Event triggered when menu toggle clicked
     */
    @Output() menuToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Campaigns still active or not started yet.
     */
    campaignsActive: CampaignList = {
        isDisplayed: true,
        isLoading: false,
        values: [],
        displayName: 'Campagnes en cours',
    };

    /**
     * Campaigns finished
     */
    campaignsFinished: CampaignList = {
        isDisplayed: false,
        isLoading: false,
        values: [],
        displayName: 'Campagnes terminées',
    };

    /**
     * Selected campain by the user.
     */
    selectedCampaign: null | string = null;

    displayMenu = true;

    constructor(private campaignsService: CampaignsService) {}

    ngOnInit() {
        this.initCampaignsLists();
    }

    /**
     * Init campaigns list.
     * Get campaigns for the company and set the member arrays of active (started or not) and finished campaigns.
     */
    async initCampaignsLists(): Promise<void> {
        try {
            this.campaignsActive.isLoading = true;
            this.campaignsFinished.isLoading = true;

            const result = await this.campaignsService.getCompaniesCampaignsList();

            const data = result.data;

            this.campaignsActive.values = [];
            this.campaignsFinished.values = [];

            data.forEach(campaign => {
                if (campaign.state === 0 || campaign.state === 1) {
                    this.campaignsActive.values.push(campaign);
                } else if (campaign.state === 2) {
                    this.campaignsFinished.values.push(campaign);
                }
            });
            this.sortCampaignsList(this.campaignsActive);
            this.sortCampaignsList(this.campaignsFinished);

            this.campaignsActive.isLoading = false;
            this.campaignsFinished.isLoading = false;
        } catch (error) {
            // TODO gestion de l'erreur
            this.campaignsActive.isLoading = false;
            this.campaignsFinished.isLoading = false;
        }
    }

    /** Campaigns List Menu Display */

    toggleDisplayMenu() {
        this.displayMenu = !this.displayMenu;
        this.menuToggle.emit(this.displayMenu);
    }

    displayCampaignsMenu() {
        return this.displayMenu;
    }

    get menuRowClass() {
        return this.displayMenu ? 'justify-content-end' : 'justify-content-center';
    }

    /** Campaigns Lists Display */

    /**
     * Get number of campaigns in a campaigns list.
     * @param {CampaignList} campaignsList - list of campaigns objects.
     * @returns {number} number of campaigns in the list if defined. 0 otherwise.
     */
    getCampaignsListCount(campaignsList: CampaignList): number {
        return campaignsList && campaignsList.values ? campaignsList.values.length : 0;
    }

    /**
     * Rreturn to display or not a campaigns list.
     * @param {CampaignList} campaignsList - campaign list object
     * @returns {boolean} true to display the campaigns list or not.
     */
    displayCampaignsList(campaignsList: CampaignList): boolean {
        return Boolean(campaignsList && campaignsList.isDisplayed && !campaignsList.isLoading);
    }

    /**
     * Toggle display of a campaigns list
     * @param {CampaignList} campaignsList - campaign list object
     */
    toggleCampaignsListDisplay(campaignsList: CampaignList): void {
        if (campaignsList && typeof campaignsList.isDisplayed === 'boolean') {
            campaignsList.isDisplayed = !campaignsList.isDisplayed;
        } else {
            campaignsList.isDisplayed = true;
        }
    }

    /**
     * Get campaigns list title icon class for rotation or not.
     * @param {CampaignList} campaignsList - campaign list object
     * @return {string} rotation class if displayed, empty string otherwise.
     */
    getIconClass(campaignsList: CampaignList): string {
        return campaignsList && campaignsList.isDisplayed ? 'fa-rotate-90' : '';
    }

    /**
     * Get class for campaign item in list
     * @param {CampaignLight} campaign - campaign to get the class from
     * @returns {string} class to set to the campaign item in list.
     */
    getCampaignClass(campaign: CampaignLight): string {
        return this.selectedCampaign && campaign && campaign._id === this.selectedCampaign ? 'citron-color' : '';
    }

    /**
     * Get campaigns list values (list of campaigns)
     * @param {CampaignList} campaignsList - campaign list object
     * @returns {CampaignLight[]} list of campaigns
     */
    getCampaignsListValues(campaignsList: CampaignList): CampaignLight[] {
        return campaignsList && campaignsList.values ? campaignsList.values : [];
    }

    /** User actions */

    /**
     * Emit create a new campaign action asked by the user.
     */
    openCampaignCreation(): void {
        this.selectedCampaign = null;
        this.createCompaign.emit();
    }

    /**
     * Emit that a campaign has been selected by the user.
     * @param {CampaignLight} value - campaign to set as selected
     */
    selectCampaign(value: CampaignLight): void {
        if (value && value._id && value._id !== this.selectedCampaign) {
            this.selectedCampaign = value._id;
            this.campaignSelectedChanged.emit(value._id);
        }
    }

    /** Utils */

    /**
     * Sort a campaigns list by name alphabetically. Sort in the array and return the array modified
     * @param {CampaignList} campaignsList - list of campaigns objects.
     * @returns {CampaignList} campaigns array modified.
     */
    private sortCampaignsList(campaignsList: CampaignList) {
        return campaignsList.values.sort((a, b) => a.name.localeCompare(b.name));
    }

    /** Used by hosts */

    public refreshList() {
        this.initCampaignsLists();
    }
}
