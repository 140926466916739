import { Component, Input, OnInit } from '@angular/core';
import { UserTypes } from '../../../constants/user-types-list.constants';

// subtext to display in each tile under the title: label followed by the element.property
interface SubText {
    label: string;
    property: string;
}

/**
 * Only Companies, but we keep a generic name
 */
interface ElementAdmin {
    _id: string;
    logo: {
        _id?: string;
        url: string;
    } | null;
}

@Component({
    selector: 'ga-admin-selection',
    templateUrl: './admin-selection.component.html',
    styleUrls: ['./admin-selection.component.scss'],
    providers: [],
})
export class AdminSelectionComponent implements OnInit {
    @Input() elementList: ElementAdmin[] = [];
    @Input() subTexts: SubText[] = [];
    @Input() redirection = '';
    @Input() userSession: any = null;

    defaultPictures = {
        entreprise:
            'https://maps.googleapis.com/maps/api/streetview?size=650x725&location=74%20' +
            'Rue%20du%20Révérend%20Père%20Christian%20Gilbert%2092600%20Asnières-sur-Seine&key=AIzaSyBhzQGzbrFE_V0Lot8-DicgBnxhglT0Jko',
        utilisateur: 'assets/img/backgrounds/default-avatar.jpg',
    };

    userFilter = '';

    ngOnInit() {}

    /**
     * Get company link
     * @param {string} action
     * @param {ElementAdmin} element
     * @returns {string} url to redirect to
     */
    getPageLink(action: string, element: ElementAdmin = null): string {
        switch (action) {
            case 'create':
                return `/admin/${this.redirection}s/creer`;
            case 'edit':
                return `/admin/${this.redirection}/${element._id}/profil`;
        }
    }

    hasLogo(element: ElementAdmin): string {
        // POL-206 disable pictures in view page due to perf issues
        return ' has-no-logo';
        return element.logo && element.logo.url ? ' has-logo' : ' has-no-logo';
    }

    getLogo(element: ElementAdmin): string {
        // POL-206 disable pictures in view page due to perf issues
        return this.defaultPictures[this.redirection];
        return element.logo && element.logo.url ? element.logo.url : this.defaultPictures[this.redirection];
    }

    hasSubTexts() {
        return this.subTexts.length ? '' : ' bottom-border';
    }

    // admin, EM, and useradmin can create new users
    // admin and EM can create new companies
    canCreateNewItem() {
        if (this.redirection === 'utilisateur') {
            return [UserTypes.ENERGY_MANAGER, UserTypes.ADMIN, UserTypes.USER_ADMIN].includes(this.userSession.type);
        } else if (this.redirection === 'entreprise') {
            return [UserTypes.ENERGY_MANAGER, UserTypes.ADMIN].includes(this.userSession.type);
        }
    }

    get placeHolder() {
        if (this.redirection === 'utilisateur') {
            return 'Rechercher par nom, prénom';
        } else if (this.redirection === 'entreprise') {
            return 'Rechercher par nom, adresse';
        }
    }
}
