import { UploadErrorList } from 'app/shared/constants/upload-error-list.constants';
import { FeatureToggleService } from 'app/shared/services/feature-toggle/feature-toggle.service';
import { Observable } from 'rxjs';

import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BillPopulated } from 'app/shared/models/bills.interface';
import { UploadStateList } from '../../shared/constants/upload-state-list.constants';
import { ForceType, Upload, UploadFile } from '../../shared/models/upload-file.interface';
import { UploadGroup } from '../../shared/models/upload-group.interface';
import { ApiService } from '../../shared/services/api/api.service';
import { PageService } from '../../shared/services/page/page.service';
import { SessionService } from '../../shared/services/session/session.service';
import { UploadSearchResult } from './collect.interface';

@Injectable()
export class CollectService extends PageService {
    /**
     * Info types
     */
    public infoType = {
        ERROR: 'error',
        WARNING: 'warning',
        SUCCESS: 'success',
        INFO: 'info',
    };

    /**
     * States info
     */
    public stateInfo: {
        [key: string]: {
            type: string;
            message: string;
            percent: number;
        };
    } = {
        [UploadStateList.UPLOADING]: this.createStateInfo(this.infoType.INFO, 'Envoi du document en cours'),
        UPLOADING: this.createStateInfo(this.infoType.INFO, 'Envoi du document en cours'),
        [UploadStateList.UNTOUCHED]: this.createStateInfo(this.infoType.SUCCESS, 'Document uploadé', 50),
        UNTOUCHED: this.createStateInfo(this.infoType.SUCCESS, 'Document uploadé', 50),
        [UploadStateList.UPLOAD_ERROR]: this.createStateInfo(
            this.infoType.ERROR,
            "Erreur lors de l'envoi du document",
            0
        ),
        UPLOAD_ERROR: this.createStateInfo(this.infoType.ERROR, "Erreur lors de l'envoi du document", 0),
        [UploadStateList.IN_PROGRESS]: this.createStateInfo(this.infoType.INFO, 'Extraction des données en cours', 50),
        IN_PROGRESS: this.createStateInfo(this.infoType.INFO, 'Extraction des données en cours', 50),
        [UploadStateList.PATTERN_MISSING]: this.createStateInfo(this.infoType.ERROR, 'Document inconnu', 50),
        PATTERN_MISSING: this.createStateInfo(this.infoType.ERROR, 'Document inconnu', 50),
        [UploadStateList.NO_PATTERNS]: this.createStateInfo(this.infoType.ERROR, 'Document inconnu', 50),
        NO_PATTERNS: this.createStateInfo(this.infoType.ERROR, 'Document inconnu', 50),
        [UploadStateList.ERROR]: this.createStateInfo(this.infoType.ERROR, "Erreur lors de l'extraction", 50),
        ERROR: this.createStateInfo(this.infoType.ERROR, "Erreur lors de l'extraction", 50),
        [UploadStateList.PROPERTY_MISSING]: this.createStateInfo(this.infoType.WARNING, 'Propriétés manquantes', 50),
        PROPERTY_MISSING: this.createStateInfo(this.infoType.WARNING, 'Propriétés manquantes', 50),
        [UploadStateList.MULTI_UNCOMPLETED]: this.createStateInfo(
            this.infoType.WARNING,
            'Propriétés manquantes - MULTI ',
            50
        ),
        MULTI_UNCOMPLETED: this.createStateInfo(this.infoType.WARNING, 'Propriétés manquantes - MULTI ', 50),
        [UploadStateList.JOBS_DONE]: this.createStateInfo(this.infoType.SUCCESS, 'Document ajouté', 100),
        JOBS_DONE: this.createStateInfo(this.infoType.SUCCESS, 'Document ajouté', 100),
        [UploadStateList.HAS_CHUNKS_NO_PATTERNS]: this.createStateInfo(
            this.infoType.ERROR,
            'Type de facture inconnu - MULTI ',
            50
        ),
        [UploadStateList.ARCHIVED]: this.createStateInfo(this.infoType.INFO, 'Document archivé', 100),
        ARCHIVED: this.createStateInfo(this.infoType.INFO, 'Document archivé', 100),

        [UploadErrorList.MISSING_GEOLOC]: this.createStateInfo(this.infoType.ERROR, "L'adresse est introuvable"),
        [UploadErrorList.MISSING_DATE]: this.createStateInfo(
            this.infoType.ERROR,
            'La date de début et/ou fin est introuvable'
        ),
        [UploadErrorList.SAVING_VALIDATION]: this.createStateInfo(
            this.infoType.ERROR,
            `La facture n'est pas valide: élément(s) manquant(s) ou incorrect(s)`
        ),
        [UploadErrorList.SAVING_UNKNOWN]: this.createStateInfo(
            this.infoType.ERROR,
            `Erreur inconnue lors de la sauvegarde de la facture`
        ),
        [UploadErrorList.PRODUCT_NOT_FOUND]: this.createStateInfo(
            this.infoType.ERROR,
            `Le(s) produit(s) extrait(s) ne peut(vent) pas être identifié(s)`
        ),
        QUALITY_CHECK_FAILED: this.createStateInfo(this.infoType.ERROR, `Extraction incomplète`, 50),
        [UploadErrorList.QUALITY_CHECK_FAILED]: this.createStateInfo(
            this.infoType.ERROR,
            `Echec du contrôle de l'extraction`,
            50
        ),
    };

    /**
     * Infos color and icon mapping
     */
    public infos = {
        [this.infoType.ERROR]: {
            color: '#be0712',
            icon: 'fa-exclamation-circle',
        },
        [this.infoType.WARNING]: {
            color: '#eb7d3c',
            icon: 'fa-exclamation-triangle',
        },
        [this.infoType.SUCCESS]: {
            color: '#5BAF4D',
            icon: 'fa-check-square',
        },
        [this.infoType.INFO]: {
            color: '#5790af',
            icon: 'fa-info-circle',
        },
    };

    /**
     * Mapping between force types and the states of uploads/chunks that can be forced for each one.
     */
    public forceTypeStates = {
        [ForceType.EXTRACT]: [UploadStateList.MULTI_UNCOMPLETED, UploadStateList.PROPERTY_MISSING],
        [ForceType.QUALITY]: [UploadStateList.QUALITY_CHECK_FAILED],
    };

    constructor(
        public apiService: ApiService,
        public sessionService: SessionService,
        private readonly featureToggleService: FeatureToggleService
    ) {
        super(apiService);
        this.fake = false;
    }

    createGroup(groupName) {
        const body = {
            name: groupName,
            company: this.sessionService.getCompanyId(),
        };

        const url = `/api/upload-groups/crud/`;

        return this.post(url, JSON.stringify(body));
    }

    /**
     * Upload a new name for a given group
     * @return {Promise<UploadGroup>}
     */
    async updateGroup(groupId: number, groupName: string): Promise<UploadGroup> {
        const body = {
            name: groupName,
            company: this.sessionService.getCompanyId(),
        };

        const url = `/api/upload-groups/crud/${groupId}`;
        const response = await this.put(url, body);

        return response.data;
    }

    deleteGroup(groupId) {
        const url = `/api/upload-groups/crud/${groupId}`;

        return this.delete(url);
    }

    /**
     * Get upload groups for the current connected company.
     * @returns {Promise<UploadGroup[]>}
     */
    async getGroupsByCompany(): Promise<UploadGroup[]> {
        const response = await this.get('/api/upload-groups/company/' + this.sessionService.getCompanyId());
        return response.data;
    }

    getUploadsByCompany() {
        return this.get('/api/uploads/company/' + this.sessionService.getCompanyId());
    }

    /**
     * Get uploads by group
     * @param groupId - id of group
     */
    async getUploadsByGroup(groupId: string): Promise<Upload[]> {
        const url = `/api/uploads/group/${groupId}`;
        const response = await this.post(url, {});
        const { uploads, identificationPatterns, clothestPatterns } = response.data;
        this.populateIdentificationAndClothestPattern(uploads, identificationPatterns, clothestPatterns);
        return uploads;
    }

    /**
     * Populated upload with identification pattern and clothest pattern
     */
    private populateIdentificationAndClothestPattern(
        uploads: Upload[],
        identificationPatterns: any[],
        clothestPatterns: any[]
    ) {
        if (!uploads || !uploads.length || !identificationPatterns || !identificationPatterns.length) {
            return;
        }
        const identificationPatternsById = this.transformArrayToObjectById(identificationPatterns, '_id');
        const clothestPatternsById = this.transformArrayToObjectById(clothestPatterns, '_id');

        for (let i = 0, iLen = uploads.length; i < iLen; i++) {
            const upload = uploads[i];
            if (upload.chunks && upload.chunks.length) {
                for (let j = 0, jLen = upload.chunks.length; j < jLen; j++) {
                    const chunk = upload.chunks[j];
                    this.setIdentificationAndClothestPattern(chunk, identificationPatternsById, clothestPatternsById);
                }
            }
            this.setIdentificationAndClothestPattern(upload, identificationPatternsById, clothestPatternsById);
        }
    }

    /**
     * Transform an array of object to an object with {object[key] : object,}
     */
    private transformArrayToObjectById(array: any[], key: string): any {
        const objectById = {};
        for (let i = 0, iLen = array.length; i < iLen; i++) {
            const element = array[i];
            objectById[element[key]] = element;
        }
        return objectById;
    }

    /**
     * Sets identification pattern and clothest pattern of upload or chunk from identificationPatternsById and clothestPatternsById
     */
    private setIdentificationAndClothestPattern(
        chunk: Upload | any,
        identificationPatternsById: any,
        clothestPatternsById: any
    ) {
        if (chunk.identificationPattern) {
            chunk.identificationPattern = identificationPatternsById[chunk.identificationPattern];
        }
        if (chunk.clothestPattern) {
            const fluids = Object.keys(chunk.clothestPattern);
            for (let i = 0, iLen = fluids.length; i < iLen; i++) {
                const fluid = fluids[i];
                chunk.clothestPattern[fluid] = clothestPatternsById[chunk.clothestPattern[fluid]];
            }
        }
    }

    isBill(file: UploadFile | BillPopulated): file is BillPopulated {
        return (file as BillPopulated).upload !== undefined;
    }

    removeFile(file: UploadFile | BillPopulated): Promise<any> {
        const fileId = this.isBill(file) ? file.upload : file.id;
        const url = `/api/uploads/crud/${fileId}`;
        return this.delete(url);
    }

    /**
     * Reset all uploads by group
     * @param {string} groupId - id of group
     */
    async resetGroup(
        groupId: string
    ): Promise<{ success: string[]; error: Array<{ uploadId: string; originalname: string; error: string }> }> {
        const urlBase = this.featureToggleService.isEnabled('upload-reset-by-group.newRoutes')
            ? '/api/upload-management/group/reset'
            : '/api/uploads/group/reset';
        const response = await this.patch(`${urlBase}/${groupId}`, null);
        return response.data;
    }

    async reextractFile(uploadId: string): Promise<Upload> {
        const urlBase = this.featureToggleService.isEnabled('upload-reExtraction.newRoutes')
            ? '/api/upload-management'
            : '/api/uploads';
        return this.patch(`${urlBase}/re-extract/${uploadId}`, null);
    }

    /**
     * Extract the upload (specific chunks if given). Force the extraction if required.
     * @param {string} uploadId
     * @param {string[]} chunksIdSelected
     * @param {ForceType} forceType
     */
    extractFile(uploadId: string, chunksIdSelected: string[] = [], forceType: ForceType = null): Promise<any> {
        const urlBase = this.featureToggleService.isEnabled('upload-extraction.newRoutes')
            ? '/api/upload-management/extract'
            : `/api/uploads/${forceType ? 'force-' : ''}extract`;

        const url = `${urlBase}/${uploadId}`;

        const body = {
            companyId: this.sessionService.getCompanyId(),
            chunksId: chunksIdSelected,
            forceType,
        };

        return this.post(url, body);
    }

    uploadFile(file, groupId): Observable<any> {
        const formData: FormData = new FormData();

        formData.append('file', file.fileObject, file.filename);
        formData.append('companyId', this.sessionService.getCompanyId());
        formData.append('groupId', groupId);

        const options = {
            reportProgress: true,
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: 'Basic ' + this.apiService.getToken(),
            }),
        };
        const url = this.featureToggleService.isEnabled('upload-import.newRoutes')
            ? '/api/upload-management/import'
            : '/api/uploads/upload';
        return this.apiService.upload(url, formData, options);
    }

    fakeData(route) {
        let response;

        switch (route) {
            default:
                response = {};
                break;
        }

        return response;
    }

    private createStateInfo(
        type: string,
        message: string,
        percent: number = null
    ): { type: string; message: string; percent: number } {
        return {
            type,
            message,
            percent,
        };
    }

    /**
     * Search upload
     * @param {string} search
     */
    public async searchUpload(search: string): Promise<UploadSearchResult[]> {
        const response = await this.get(`/api/uploads/search`, null, { search });
        return response.data;
    }

    /**
     * Get force type based on state
     * @param state
     * @param forceExtract
     */
    public getForceType(state: string, forceExtract: boolean): ForceType {
        let forceType: ForceType = null;
        if (forceExtract) {
            forceType = this.getForceTypeFromState(state, forceType);
            // If force type still null, it means that there is a bug and state is not forcable
            if (forceType === null) {
                throw new Error('state not matching force');
            }
        }
        return forceType;
    }

    /**
     * Get force type from state based on a given current force type.
     * It privileges force type earlier in workflow (eg. n quality and 1 extract => extract)
     * @param state - chunk or upload state
     * @param currentForce - current force type (for a list of uploads/chunks)
     */
    public getForceTypeFromState(state: string, currentForce: ForceType): ForceType {
        if (this.forceTypeStates[ForceType.EXTRACT].includes(state.toLowerCase())) {
            return ForceType.EXTRACT;
        }
        if (this.forceTypeStates[ForceType.QUALITY].includes(state.toLowerCase()) && currentForce === null) {
            return ForceType.QUALITY;
        }
        return currentForce;
    }

    /**
     * Get force types from states of elements having a state field.
     * @param elements
     * @param forceExtract
     */
    public getForceTypeFromStates(elements: Array<{ state: string }>, forceExtract: boolean): ForceType {
        let forceType = null;
        if (!forceExtract) {
            return forceType;
        }
        elements.forEach(elem => {
            // set force type
            forceType = this.getForceTypeFromState(elem.state, forceType);
        });
        return forceType;
    }

    /**
     * Check if upload/chunk state is matching force compatible states.
     * Returns true if not forcing
     * @param state
     * @param force
     */
    public isStateMatchingForce(state: string, force: ForceType): boolean {
        if (!force) {
            return true;
        }
        if (!this.forceTypeStates[force]) {
            return false;
        }
        return this.forceTypeStates[force].includes(state.toLowerCase());
    }
}
