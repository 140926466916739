import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    selector: 'ng2-charts-bar',
    templateUrl: './ng2-charts-bar.component.html',
})
export class Ng2BarChartDemoComponent implements OnInit {
    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

    @Input() barChartData: any[];
    @Input() barChartDataset: any[];
    @Input() barChartColors: any[];
    @Input() barChartLabels: string[];
    @Input() barChartStacked = false;
    @Input() barChartWidth = 900;
    @Input() barChartMaxHeight: number = null;
    @Input() barChartMinHeight: number = null;
    @Input() barChartLegend = false;
    @Input() barChartGrid = true;
    @Input() barChartOptions: any = {};

    @Output() chartInit: EventEmitter<BaseChartDirective> = new EventEmitter<BaseChartDirective>();
    @Output() emitChartClick = new EventEmitter();

    @Input() public barChartType = 'bar';

    public chartPlugins = [pluginAnnotations];

    constructor() {}
    ngOnInit() {
        this.chartInit.emit(this.chart);
    }

    // events
    public chartClicked(e: any): void {
        this.emitChartClick.emit(e);
    }

    public chartHovered(e: any): void {
        // console.log(e);
    }

    public setMinHeight() {
        return this.barChartMinHeight ? this.barChartMinHeight + 'px' : 'none';
    }

    public setMaxHeight() {
        return this.barChartMaxHeight ? this.barChartMaxHeight + 'px' : 'none';
    }
}
