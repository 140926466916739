import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
    @Input() value = 0;
    @Input() color = '#5BAF4D';
    @Input() height = 7;

    constructor() {}
    ngOnInit() {}

    getPercentageString() {
        return this.value + '%';
    }

    getHeight() {
        return this.height + 'px';
    }
}
