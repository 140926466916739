import { Pipe, PipeTransform } from '@angular/core';
import { TextService } from 'app/shared/services/utils/text.service';

// IMPORTANT : This pipe is the recent implementation of Angluar's title case pipe
// Orginal source code : https://github.com/angular/angular/blob/master/packages/common/src/pipes/case_conversion_pipes.ts
//
// Regex below matches any Unicode word and compatible with ES5. In ES2018 the same result
// can be achieved by using /\p{L}\S*/gu and also known as Unicode Property Escapes
// (http://2ality.com/2017/07/regexp-unicode-property-escapes.html). Since there is no
// transpilation of this functionality down to ES5 without external tool, the only solution is
// to use already transpiled form. Example can be found here -
// https://mothereff.in/regexpu#input=var+regex+%3D+/%5Cp%7BL%7D/u%3B&unicodePropertyEscape=1
//

/**
 * Transforms text to title case.
 * Capitalizes the first letter of each word, and transforms the
 * rest of the word to lower case.
 * Words are delimited by any whitespace character, such as a space, tab, or line-feed character.
 *
 * @see `LowerCasePipe`
 * @see `UpperCasePipe`
 *
 * @usageNotes
 * The following example shows the result of transforming various strings into title case.
 *
 * <code-example path="common/pipes/ts/titlecase_pipe.ts" region='TitleCasePipe'></code-example>
 *
 * @ngModule CommonModule
 * @publicApi
 */
@Pipe({ name: 'titlecaseGA' })
export class TitleCasePipe implements PipeTransform {
    constructor(private textService: TextService) {}
    /**
     * @param value The string to transform to title case.
     */
    transform(value: string): string {
        return this.textService.toTitleCase(value);
    }
}
