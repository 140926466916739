import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Company } from 'app/shared/models/company.interface';
import Swal from 'sweetalert2';
import { AdminCompanyService } from '../company/company.service';
import { AdminIdentificationPatternService } from '../identification-pattern/identification-pattern.service';

@Component({
    selector: 'ga-indentification-pattern',
    templateUrl: './identification-pattern.component.html',
    styleUrls: ['./identification-pattern.component.scss'],
    providers: [],
})
export class IdentificationPatternComponent implements OnInit {
    public company: Company = null;
    public identificationPattern: any = null;
    public textContent: any = null;
    public isloading = true;
    public uploadId: string = null;
    public chunkId: string = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private companyService: AdminCompanyService,
        private identificationPatternService: AdminIdentificationPatternService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.uploadId = params['idUpload'];
            this.chunkId = params['idChunk'];
            if (params['idChunk'] && params['idUpload']) {
                this.initChunk(params);
            } else {
                this.initUpload(params);
            }
        });
    }

    /**
     * Init upload
     *
     * @param {Params} params
     */
    private async initUpload(params: Params) {
        await Promise.all([
            this.getUpload(params['idUpload']).then(textContent => {
                this.textContent = textContent;
            }),
            this.setCompany(params['id']),
        ]);
    }

    /**
     * init chunk
     *
     * @param {Params} params
     */
    private async initChunk(params: Params) {
        await Promise.all([
            this.getChunk(params['idUpload'], params['idChunk']).then(textContent => {
                this.textContent = textContent;
            }),
            this.setCompany(params['id']),
        ]);
    }

    /**
     * Get the text content of the specified chunk of the upload
     *
     * @param {string} id - id of the upload
     * @param {string} idChunk - id of the chunk
     * @returns {Promise<*>}
     */
    private async getChunk(id: string, idChunk: string): Promise<any> {
        try {
            if (!id) {
                return null;
            }

            const upload = await this.identificationPatternService.getUploadChunkTextContentById(id, idChunk);
            return upload;
        } catch (err) {
            // err.errorCode is set to error_loadUpload
            this.getSwalMsg();
        }
    }

    /**
     * Get the text content of the specified upload
     *
     * @param {string} id - upload id
     * @returns {Promise<*>}
     */
    private async getUpload(id: string): Promise<any> {
        try {
            if (!id) {
                return null;
            }

            const upload = await this.identificationPatternService.getUploadTextContentById(id);
            return upload;
        } catch (err) {
            // err.errorCode is set to error_loadUpload
            this.getSwalMsg();
        }
    }

    /**
     * Load the company info from its id in the URL params
     *
     * @param {string} id - company id
     * @returns Promise<{void | boolean}>
     */
    private async setCompany(id: string): Promise<void | boolean> {
        try {
            if (id) {
                const result = await this.companyService.getCompanyById(id);
                this.company = result.data;
                this.isloading = false;
            }
        } catch (err) {
            // err.errorCode is set to error_loadCompany
            this.getSwalMsg();
            return this.router.navigateByUrl('/admin/entreprises/creer');
        }
    }

    /**
     * Set identification pattern
     *
     * @param {*} identificationPattern
     */
    public setIdentificationPattern(identificationPattern: any) {
        this.identificationPattern = identificationPattern;
    }

    /**
     * Fire sweet alert
     */
    private getSwalMsg() {
        Swal.fire(
            'Toutes nos exuses',
            "Une erreur s'est produite lors du chargement du pattern d'identification",
            'error'
        );
    }

    /**
     * Get title
     *
     * @returns {string}
     */
    public getTitle(): string {
        return 'Nouveau pattern d\'identification';
    }
}
