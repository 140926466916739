import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

import { FileStorage } from 'app/shared/models/file-storage.interface';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

@Injectable()
export class FileStorageService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /** Get a public url for the file */
    public async getUrl(id: string): Promise<string> {
        const response = await this.get(`/api/file-storage/url/${id}`);
        return response.data;
    }

    /** Open the file in a new tab */
    async downloadFile(fileStorageId: string, fileName: string): Promise<void> {
        const url = await this.getUrl(fileStorageId);
        saveAs(url, fileName);
    }

    /** Get info from file storage (including the url) */
    public async getInfo(id: string): Promise<FileStorage> {
        const response = await this.get(`/api/file-storage/info/${id}`);
        return response.data;
    }
}
