import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Warrant } from 'app/shared/models/warrant.interface';
import { ExternalDataService } from 'app/shared/services/external-data/external-data.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { WarrantService } from 'app/shared/services/warrants/warrant.service';

import { EnedisWarrantService } from '../enedis-warrant.service';

@Component({
    selector: 'ga-enedis-warrant-form',
    templateUrl: './warrant-form.component.html',
    styleUrls: ['./warrant-form.component.scss'],
    providers: [],
})
export class EnedisWarrantFormComponent implements OnInit, OnDestroy {
    @Input() companyId: string;

    /** Boolean indicating warrant form edition state */
    public editing = false;
    /** Boolean indicating warrant saving result state */
    public saved = false;
    /** Boolean indicating warrant saving state */
    public saving = false;

    /** Message to show under corporate name field */
    public searchBarMessage: string;

    /** Warrant form group */
    public warrantForm = new FormGroup({
        corporateName: new FormControl('', Validators.required),
        validityStart: new FormControl('', Validators.required),
        validityEnd: new FormControl('', Validators.required),
        confirmation: new FormControl(false, Validators.requiredTrue),
    });

    /** Component's subscriptions */
    private subscription: Subscription;

    /** Loaded warrant */
    private warrant: Warrant;

    constructor(
        private readonly translateService: TranslateService,
        private readonly warrantService: WarrantService,
        private readonly externalDataService: ExternalDataService,
        private readonly enedisWarrantService: EnedisWarrantService
    ) {}

    ngOnInit(): void {
        // Whenever we load a new warrant, store it and update form values
        this.subscription = this.enedisWarrantService.loadedWarrant$.subscribe(warrant => {
            this.warrant = warrant;

            if (this.warrant) {
                this.patchWarrantFormValues(this.warrant);
            }
        });

        // Whenever user enters a new corporate name, finds if any warrant is matching
        this.subscription.add(
            this.warrantForm
                .get('corporateName')
                .valueChanges.pipe(
                    debounceTime(500),
                    distinctUntilChanged()
                )
                .subscribe(corporateName => this.onSearchWarrant(corporateName))
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onClickEdit(): void {
        this.editing = true;
        this.warrantForm.get('confirmation').setValue(false);
        this.warrantForm.markAsUntouched();
    }

    public async onSubmitWarrant(): Promise<void> {
        this.warrantForm.markAllAsTouched();

        if (this.warrantForm.valid) {
            const formValues = this.getWarrantFormValues();

            try {
                // Save corporate name in external associations
                await this.externalDataService.associateExternalData('company', {
                    company: this.companyId,
                    type: 'enedis',
                    externalId: {
                        socialDenomination: formValues.corporateName,
                    },
                });

                await this.enedisWarrantService.editWarrant(this.warrant, formValues);

                this.searchBarMessage = null;
                this.editing = false;

                Swal.fire({
                    icon: 'success',
                    title: this.translateService._('success'),
                    text: this.translateService._('warrant_success_saving'),
                });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: this.translateService._('error_we_are_sorry'),
                    text: this.translateService._('warrant_error_saving'),
                });
            }
        }
    }

    /** Search for a warrant and update current warrant dates with the found ones */
    private async onSearchWarrant(corporateName: string): Promise<void> {
        if (!this.warrant || this.warrant.corporateName !== corporateName) {
            this.warrant = await this.warrantService.getLatestWarrant({
                corporateName,
                provider: 'enedis',
                providerFluid: 'elec',
            });

            if (this.warrant) {
                this.searchBarMessage = 'warrant_edition_existing_warrant_message';
                this.patchWarrantFormValues(this.warrant);
            } else {
                this.searchBarMessage = 'warrant_edition_no_warrant_message';
                this.resetWarrantFormValues();
            }
        }
    }

    /** Patch warrant form using given warrant */
    private patchWarrantFormValues(warrant: Warrant): void {
        const validityStart = moment.utc(warrant.validityStart).format('YYYY-MM-DD');
        const validityEnd = moment.utc(warrant.validityEnd).format('YYYY-MM-DD');

        this.warrantForm.get('corporateName').setValue(warrant.corporateName);
        this.warrantForm.get('validityStart').setValue(validityStart);
        this.warrantForm.get('validityEnd').setValue(validityEnd);
    }

    /** Reset dates of warrant form */
    private resetWarrantFormValues(): void {
        this.warrantForm.get('validityStart').setValue('');
        this.warrantForm.get('validityEnd').setValue('');
    }

    /** Returns formatted values from warrant form */
    private getWarrantFormValues(): { corporateName: string; validityStart: string; validityEnd: string } {
        return {
            corporateName: this.warrantForm.get('corporateName').value,
            validityStart: moment
                .utc(this.warrantForm.get('validityStart').value)
                .startOf('date')
                .toISOString(),
            validityEnd: moment
                .utc(this.warrantForm.get('validityEnd').value)
                .endOf('date')
                .toISOString(),
        };
    }
}
