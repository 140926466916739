import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Vehicle, VehiclePopulated } from 'app/shared/models/vehicle.interface';

import { Attribute, AttributeType } from 'app/shared/models/attribute.interface';
import { VehiclesService } from 'app/shared/services/vehicles/vehicles.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-vehicle-edit',
    templateUrl: './vehicle-edit.component.html',
    styleUrls: ['./vehicle-edit.component.scss'],
})
export class VehicleEditComponent implements OnInit {
    /**
     * Vehicle to display and edit
     */
    @Input() vehicle: VehiclePopulated;

    /**
     * Triggered when a vehicle is updated
     */
    @Output() vehicleUpdated: EventEmitter<Vehicle> = new EventEmitter<Vehicle>(true);

    /**
     * Vehicle reactive form
     */
    vehicleForm: FormGroup;

    /**
     * List of all types of fuel available
     */
    fuelTypes: string[] = [];

    /**
     * List of all available vehicle categories
     */
    categories: Attribute[] = [];

    /**
     * List of all available vehicle makes
     */
    makes: Attribute[] = [];

    /**
     * List of all available vehicle models for the vehicle's make
     */
    models: Attribute[] = [];

    /**
     * Is vehicle being currently saved
     */
    isSaving = false;

    constructor(private vehiclesService: VehiclesService) {}

    ngOnInit() {
        const dateIn = this.vehicle.dateIn ? moment.utc(this.vehicle.dateIn).format('YYYY-MM-DD') : null;
        const dateOut = this.vehicle.dateOut ? moment.utc(this.vehicle.dateOut).format('YYYY-MM-DD') : null;
        this.vehicleForm = new FormGroup({
            registrationNumber: new FormControl({ value: this.vehicle.registrationNumber, disabled: true }),
            name: new FormControl(this.vehicle.name, [Validators.required]),
            fuelType: new FormControl(this.vehicle.fuelType),
            isHybrid: new FormControl(this.vehicle.isHybrid),
            category: new FormControl(this.vehicle.category),
            make: new FormControl(this.vehicle.make),
            model: new FormControl(this.vehicle.model),
            dateIn: new FormControl(dateIn),
            dateOut: new FormControl(dateOut),
            consumptionCity: new FormControl(this.vehicle.consumption.city),
            consumptionCombined: new FormControl(this.vehicle.consumption.combined),
            consumptionHighway: new FormControl(this.vehicle.consumption.highway),
        });

        this.getSetCategories();
        this.getSetMakes();
        this.getSetFuelTypes();

        this.onChanges();
    }

    /**
     * Set reactive form changes behaviors
     */
    onChanges() {
        this.vehicleForm.get('make').valueChanges.subscribe(val => {
            this.vehicleForm.get('model').setValue(null);
            this.getSetModels(val);
        });
    }

    /**
     * Save vehicle then triggers save event
     */
    async saveVehicle() {
        try {
            this.isSaving = true;
            const values = Object.assign({}, this.vehicleForm.value);
            /**
             * Dates returned at format , we need to format them at ISO format.
             */
            if (values.dateIn) {
                values.dateIn = moment.utc(values.dateIn).toISOString();
            }
            if (values.dateOut) {
                values.dateOut = moment.utc(values.dateOut).toISOString();
            }

            values.consumption = {
                city: values.consumptionCity,
                combined: values.consumptionCombined,
                highway: values.consumptionHighway,
            };
            delete values.consumptionCity;
            delete values.consumptionCombined;
            delete values.consumptionHighway;

            // Save vehicle with vehicle service
            const vehicleSaved = await this.vehiclesService.update(this.vehicle._id, values);
            this.vehicleUpdated.emit(vehicleSaved);
        } catch (e) {
            Swal.fire('Erreur lors de la sauvegarde', `Merci d'essayer de nouveau ultérieurement`, 'error');
        }
        this.isSaving = false;
    }

    /**
     * Get and set all vehicles categories
     * @returns {Promise<void>}
     */
    async getSetCategories(): Promise<void> {
        try {
            const categories = await this.vehiclesService.getCategories();
            this.categories = categories;
        } catch (e) {
            this.categories = [this.createErrorAttribute('vehicleCategory')];
            Swal.fire(
                'Erreur de la récupération des segments automobiles',
                `Merci d'essayer de nouveau ultérieurement`,
                'error'
            );
        }
    }

    /**
     * Get and set all vehicles makes
     * @returns {Promise<void>}
     */
    async getSetMakes(): Promise<void> {
        try {
            const makes = await this.vehiclesService.getMakes();
            this.makes = makes;
        } catch (e) {
            this.makes = [this.createErrorAttribute('vehicleMake')];
            Swal.fire(
                'Erreur lors de la récupération des constructeurs automobiles',
                `Merci d'essayer de nouveau ultérieurement`,
                'error'
            );
        }
    }

    /**
     * Get all vehicles models for a given make
     * @param {string} makeId - id of make to get model from
     * @returns {Promise<void>}
     */
    async getSetModels(makeId: string): Promise<void> {
        try {
            const models = await this.vehiclesService.getModels(makeId);
            this.models = models;
        } catch (e) {
            this.models = [this.createErrorAttribute('vehicleModel')];
            Swal.fire(
                'Erreur lors de la récupération des modèles automobiles',
                `Merci d'essayer de nouveau ultérieurement`,
                'error'
            );
        }
    }

    /**
     * Get all vehicles fluids types available
     * @returns {Promise<void>}
     */
    async getSetFuelTypes(): Promise<void> {
        try {
            const fuelTypes = await this.vehiclesService.getFuelTypes();
            this.fuelTypes = fuelTypes;
        } catch (e) {
            Swal.fire(
                'Erreur lors de la récupération des différents types de carburant',
                `Merci d'essayer de nouveau ultérieurement`,
                'error'
            );
        }
    }

    /**
     * Create an error attribute tobe displayed in list
     * @param {AttributeType} type - type of attribute
     * @returns {Attribute}
     */
    private createErrorAttribute(type: AttributeType): Attribute {
        return {
            _id: 'error',
            type,
            name: 'Erreur',
            key: null,
            parent: null,
            organization: null,
            createdAt: null,
            updatedAt: null,
        };
    }
}
