import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'app/shared/services/session/session.service';
import { SigninService } from './signin.service';

import { CompaniesService } from 'app/shared/services/companies/companies.service';
import { AccessGuard } from 'app/shared/services/guards/access-guard.service';
import { UserSession } from 'app/shared/services/session/session.interface';
import { UsersService } from 'app/shared/services/users/users.service';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss'],
    providers: [SigninService],
})
export class SigninComponent implements OnInit {
    signinForm: FormGroup;
    passwordForgotForm: FormGroup;
    apiError: string;
    isAuthenticated = false;
    companySelected = null;
    companiesList = [];
    visibility = 'hidden';
    isPasswordForgot = false;
    isEmailSent = false;
    isMailSending = false;
    isSSO = false;
    isSSOProcessing = false;

    isSSOProviderLogin = false;
    providers = [
        {
            name: 'saint-gobain',
            domain: 'saint-gobain.com',
        },
    ];
    currentProvider = null;

    constructor(
        fb: FormBuilder,
        private router: Router,
        private signInService: SigninService,
        private sessionService: SessionService,
        private companiesService: CompaniesService,
        private route: ActivatedRoute,
        private guardService: AccessGuard,
        private usersService: UsersService
    ) {
        this.signinForm = fb.group({
            email: ['', Validators.email],
            password: ['', Validators.required],
        });
        this.passwordForgotForm = fb.group({
            email: ['', Validators.email],
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params['sso']) {
                this.isSSO = true;
                this.loginSSO(params['sso']);
            }
            if (params['error']) {
                this.isSSOProviderLogin = true;
                this.handleSSOError(params['error']);
            } else if (params['data']) {
                this.isSSOProviderLogin = true;
                this.handleSSOSuccess(params['data']);
            }
        });
        this.signinForm.get('email').valueChanges.subscribe((email: string) => {
            const result = this.providers.filter(p => email.includes(p.domain));
            if (result.length) {
                this.isSSOProviderLogin = true;
                this.currentProvider = result[0];
            } else {
                this.isSSOProviderLogin = false;
                this.currentProvider = null;
            }
        });
    }
    handleSSOSuccess(base64Data: string) {
        const successData = this.signInService.authSSOSuccessHandler(base64Data);
        if (!successData.token || !successData.user || !successData.expiresIn) {
            this.apiError = `Une erreur s'est produite. Veuillez contacter le support.`;
            return;
        }
        this.connectUser(successData);
    }
    handleSSOError(base64Error: string) {
        const errorMessage = this.signInService.authSSOErrorHandler(base64Error);
        this.apiError = errorMessage;
    }

    private async loginSSO(token: string) {
        this.isSSOProcessing = true;

        try {
            const res = await this.signInService.authSSO(token);
            this.connectUser(res.data);
        } catch (err) {
            switch (err.errorCode) {
                case 'invalid_sso_token':
                    this.apiError = 'Token SSO invalide, veuillez réessayer.';
                    break;
                case 'user_sso_not_found':
                    this.apiError = `Utilisateur Citron non trouvé. Veuillez contacter l'équipe GreenAlternative`;
                    break;
                default:
                    this.apiError = 'Une erreur est survenue lors de la connexion via le SSO, veuillez réessayer.';
                    break;
            }
        }
        this.isSSOProcessing = false;
    }

    public getErrorClass(control: FormControl): object {
        return { 'has-error': !control.valid && control.touched };
    }

    public inputHasError(control: FormControl, errorType): boolean {
        return control.errors && control.errors[errorType] && control.touched;
    }

    public submitForm(form: any): void {
        this.apiError = null;
        if (this.isSSOProviderLogin) {
            try {
                this.signInService.authSSOProvider(this.currentProvider);
            } catch (err) {
                if (err && err.errorCode === 'login_failed') {
                    this.apiError = `Le mot de passe ou l'adresse email renseignée est incorrecte`;
                } else if (err && err.errorCode === 'login_account_inactive') {
                    this.apiError = `Le compte est inactif, veuillez activer votre compte`;
                } else {
                    this.apiError = 'Une erreur est survenue, veuillez réessayer plus tard';
                }
            }
            return;
        }
        if (this.signinForm.valid) {
            this.signInService
                .auth(form)
                .then(response => {
                    if (response.data && response.data.token) {
                        this.connectUser(response.data);
                    }
                })
                .catch(err => {
                    if (err && err.errorCode === 'login_failed') {
                        this.apiError = `Le mot de passe ou l'adresse email renseignée est incorrecte`;
                    } else if (err && err.errorCode === 'login_account_inactive') {
                        this.apiError = `Le compte est inactif, veuillez activer votre compte`;
                    } else {
                        this.apiError = 'Une erreur est survenue, veuillez réessayer plus tard';
                    }
                });
        } else {
            this.touchedAllFields();
        }
    }

    private async connectUser(data: { token: string; user: UserSession; expiresIn?: number }) {
        this.sessionService.setToken(data.token);

        this.sessionService.setUser(data.user);

        await this.setCompaniesList();

        await this.guardService.load();

        this.isAuthenticated = true;
        this.visibility = 'shown';
    }

    setCompaniesList(): Promise<any> {
        return this.companiesService.getCompaniesListByUserId(this.sessionService.getUser()._id).then(userCompanies => {
            // all the user's companies in the selectbox
            this.companiesList = userCompanies.sort(this.sortListAlphabetically);
            this.sessionService.setCompanies(userCompanies);

            // if there is a favourite company, put it in default selection
            if (this.sessionService.getUser().favouriteCompany) {
                this.companySelected = userCompanies.find(
                    comp => comp._id === this.sessionService.getUser().favouriteCompany
                );
            } else {
                this.companySelected = userCompanies[0];
            }

            return Promise.resolve(userCompanies);
        });
    }

    sortListAlphabetically(companyA, companyB) {
        const a = companyA.name;
        const b = companyB.name;
        return a > b ? 1 : a < b ? -1 : 0;
    }

    selectCompany() {
        this.sessionService.setCompany(this.companySelected);
        this.router.navigateByUrl('/accueil');
    }

    private touchedAllFields() {
        Object.keys(this.signinForm.controls).forEach(field => {
            const control = this.signinForm.get(field);
            control.markAsTouched({ onlySelf: true });
        });
    }

    passwordForgot() {
        this.isPasswordForgot = true;
        this.apiError = null;
    }

    public submitPasswordForgotForm(form: any) {
        this.apiError = null;

        if (this.passwordForgotForm.valid && !this.isEmailSent && !this.isMailSending) {
            this.isMailSending = true;
            this.usersService
                .forgotPassword(form.email)
                .then(res => {
                    this.isEmailSent = true;
                    this.isMailSending = false;
                })
                .catch(err => {
                    this.isMailSending = false;
                    this.apiError = 'Une erreur est survenue, veuillez réessayer plus tard';
                });
        }
    }
    public goToLogin() {
        this.isEmailSent = false;
        this.isPasswordForgot = false;
    }

    get passwordSendText() {
        if (!this.isMailSending) {
            return 'Envoyer le mail';
        }
        return 'Envoi en cours...';
    }
}
