import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

import { TranslateService } from 'app/shared/services/translate/translate.service';

import { BRExclusibleUses, VolumeUnits } from '../../operating-monitoring.interface';
import { OperatingMonitoringService } from '../../operating-monitoring.service';
import { MeasuringPointUpdate, MonitoringUpdate } from '../../operating-monitoring.update.interface';

@Component({
    selector: 'ga-monitoring-add-meter',
    templateUrl: './monitoring-add-meter.component.html',
    styleUrls: ['./monitoring-add-meter.component.scss'],
})
export class MonitoringAddMeterComponent implements OnInit, OnDestroy {
    /** Subscriptions */
    private subscription: Subscription;

    /** The monitoring actually selected */
    public monitoring: MonitoringUpdate;
    /** Copy of the original value of the currently edited reading point */
    public originalMeasuringPoint: MeasuringPointUpdate;

    constructor(
        private translateService: TranslateService,
        private operatingMonitoringService: OperatingMonitoringService
    ) {}

    ngOnInit() {
        this.subscription = this.operatingMonitoringService.selectedMonitoring$.subscribe({
            next: value => (this.monitoring = value),
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        if (this.originalMeasuringPoint) {
            this.cancelEdit();
        }
    }

    public startEditingMeasuringPoint(measuringPoint: MeasuringPointUpdate) {
        if (!measuringPoint) {
            // Create a new measuring point
            measuringPoint = {
                name: null,
                uses: BRExclusibleUses.HOT_WATER,
                volumeUnit: VolumeUnits.M3,
            };
            this.monitoring.measuringPoints.unshift(measuringPoint);
        }

        this.originalMeasuringPoint = { ...measuringPoint };
    }

    public cancelEdit() {
        if (!this.originalMeasuringPoint._id) {
            const itemIndex = this.monitoring.measuringPoints.findIndex(measuring => !measuring._id);
            this.monitoring.measuringPoints.splice(itemIndex, 1);
        } else {
            this.monitoring.measuringPoints = this.monitoring.measuringPoints.map(measuring =>
                measuring._id === this.originalMeasuringPoint._id ? this.originalMeasuringPoint : measuring
            );
        }
        this.originalMeasuringPoint = null;
    }

    public updateMeasuringPoints(measuringPoint: MeasuringPointUpdate) {
        if (measuringPoint && !this.isValid(measuringPoint)) {
            return;
        }

        this.operatingMonitoringService.updateMonitoring(this.monitoring);
        this.originalMeasuringPoint = null;
    }

    public isValid(measuringPoint: MeasuringPointUpdate) {
        if (!measuringPoint.name || !measuringPoint.volumeUnit || !measuringPoint.uses) {
            return false;
        }

        return true;
    }

    public isEditingEnabled(measuringPoint: MeasuringPointUpdate) {
        return this.originalMeasuringPoint && this.originalMeasuringPoint._id === measuringPoint._id;
    }

    public async deleteMeasuringPoint(measuringPoint: MeasuringPointUpdate) {
        const confirm = await Swal.fire({
            title: this.translateService._('deletion_of') + measuringPoint.name,
            text: this.translateService._('monitoring_delete_measuring_message'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.translateService._('yes_delete'),
            cancelButtonText: this.translateService._('cancel'),
        });

        if (confirm.value) {
            this.monitoring.measuringPoints = this.monitoring.measuringPoints.filter(
                measuring => measuring._id !== measuringPoint._id
            );
            this.monitoring.indexReadings = this.monitoring.indexReadings.filter(
                reading => reading.measuringPointId !== measuringPoint._id
            );
            this.updateMeasuringPoints(null);
        }
    }
}
