import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { DjuComponent } from './dju.component';

const DJU_ROUTE = [
    {
        path: '',
        component: DjuComponent,
        canActivate: [],
    },
];

@NgModule({
    declarations: [DjuComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(DJU_ROUTE)],
})
export class DjuModule {}
