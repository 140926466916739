import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import _ = require('lodash');

// interfaces
import { QueryFilter, QueryFilterRoutingRef, QueryFilterVeh } from '../scope-filter/scope-filter.interface';

// services
import { FilterAlias } from 'app/shared/services/filter/filter.interface';
import { FilterService } from 'app/shared/services/filter/filter.service';
import moment = require('moment');

/**
 * This service is used for any scope filter objects.
 */
@Injectable()
export class FollowupFilterService {
    constructor(private filterService: FilterService) {}

    /************************
     * Query into url params convertion functions
     ************************/

    /**
     * Convert the filter params into url params. It will convert filter fields names into shortened ones and remove unnecessary variables
     * @param {QueryFilterVeh | QueryFilterRoutingRef | QueryFilter} filter
     * @returns {Params}
     */
    public convertFilterIntoQueryParams(filter: QueryFilterVeh | QueryFilterRoutingRef | QueryFilter): Params {
        const params: Params = {};

        // else : convert with the aliases in a for loop
        this.convertFilterValuesIntoQueryParams(params, filter);

        // Dates if date start month = jan and date end = date start + 11 months = YEAR, or personalized
        this.convertFilterDatesIntoQueryParams(params, filter);

        // Remove the legal entity params if the id is equal to the session's company id
        if (params.hasOwnProperty('le') && params.le === this.filterService.sessionCompany._id) {
            delete params.le;
        }

        if (filter.hasOwnProperty('fuelTypes')) {
            delete params.e;
        }

        return params;
    }

    /**
     * Format the dates params. If the period correspond in an entire year (January to Decembre to the same year),
     * then it will consider it's a year params.
     * In other case, it will simply return the initial dates into params.
     * @param {Params} params
     * @param {QueryFilterVeh | QueryFilterRoutingRef | QueryFilter} filter
     * @returns {Params}
     */
    public convertFilterDatesIntoQueryParams(
        params: Params,
        filter: QueryFilterVeh | QueryFilterRoutingRef | QueryFilter
    ): Params {
        if (filter.dateStart === '' || filter.dateEnd === '') {
            return params;
        }

        const ds = moment(filter.dateStart);
        const de = moment(filter.dateEnd);

        // If it's an entire year, from january to december, it will use the year params instead of both dateEnd and dateStart.
        if (ds.month() === 0 && de.month() === 11 && ds.year() === de.year()) {
            params.year = ds.year();
            delete params.ds;
            delete params.de;
        } else {
            params.ds = ds.format(this.filterService.urlDateFormat);
            params.de = de.format(this.filterService.urlDateFormat);
        }

        return params;
    }

    /**
     * Convert the params in the filter into params for the url params with shortened key names.
     * It don't take any values that don't fit the authorized key list and empty values
     * @param {Params} params
     * @param {QueryFilterVeh | QueryFilterRoutingRef | QueryFilter} filter
     * @returns {Params}
     */
    public convertFilterValuesIntoQueryParams(
        params: Params,
        filter: QueryFilterVeh | QueryFilterRoutingRef | QueryFilter
    ): Params {
        for (const key in filter) {
            if (filter[key] !== '') {
                const alias = key as FilterAlias;
                if (this.filterService.getParamAlias(alias)) {
                    params[this.filterService.getParamAlias(alias)] = filter[alias];
                }
            }
        }

        // Exception for the perimeter. If it's set at 'all', it will consider if as useless
        if (params.hasOwnProperty('p') && params.p === 'all') {
            delete params.p;
        }

        return params;
    }
}
