import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { PartialObserver } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'ga-date-filter',
    templateUrl: './date-filter.component.html',
    styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
    @Output() updateDate: EventEmitter<any> = new EventEmitter();

    @Input() set currentValue(value: string) {
        if (this.dateControl && value !== this.dateControl.value) {
            this.dateControl.setValue(value);
        }
    }

    @Input() minDate: string;
    @Input() maxDate: string;

    dateControl = new FormControl();
    value: any;

    constructor() {}
    ngOnInit() {
        const observer: PartialObserver<string> = {
            next: value => {
                this.validateMinMaxFromKeys(value);
                this.updateDate.emit(value);
            },
        };
        this.dateControl.valueChanges.pipe(debounceTime(300)).subscribe(observer);
    }

    private validateMinMaxFromKeys(value: string) {
        if (moment(value).isAfter(moment(this.maxDate))) {
            this.currentValue = this.maxDate;
        }
        if (moment(value).isBefore(moment(this.minDate))) {
            this.currentValue = this.minDate;
        }
    }
}
