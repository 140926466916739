import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'data-tile-3',
    templateUrl: './data-tile-3.component.html',
    styleUrls: ['./data-tile-3.component.scss'],
})
export class DataTile3Component implements OnInit {
    private MODIFIERS = {
        SQUARE: 'square',
        FULL: 'full',
    };

    @Input() label: string;
    @Input() value: number;
    @Input() color: string;
    @Input() modifier: string;
    @Input() unit: string;
    @Input() type: string;
    @Input() digits = 0;

    constructor() {}
    ngOnInit() {
        this.setModifier();
    }

    setModifier() {
        this.modifier = this.modifier || this.MODIFIERS.SQUARE;
    }

    getModifierClass(): string {
        return `dt3-modifier-${this.modifier}`;
    }
}
