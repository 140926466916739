import { Injectable } from '@angular/core';
declare var $: any;

@Injectable()
export class SpinnerService {
    private _selector = 'loader-wrapper';
    private _element: HTMLElement;

    constructor() {
        this._element = document.getElementById(this._selector);
    }

    public show({ transparent } = { transparent: false }): void {
        if (transparent) {
            this._element.classList.add('loader-transparent');
        }
        this._element.style['display'] = 'block';
    }

    public hide(delay: number = 300): void {
        setTimeout(() => {
            this._element.style['display'] = 'none';
        }, delay);
    }
}
