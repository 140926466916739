import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UserSessionService } from 'app/shared/services/session/user-session.service';
import { UserTypes } from '../../../constants/user-types-list.constants';
import { AdminTab } from '../../../models/admin-tab.interface';

@Component({
    selector: 'ga-user-table',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
    @Input() user: any = null;
    @Input() userSession: any = null;

    @Input() engineer: any = null;
    @Input() tabs: AdminTab[] = [];

    @Input() selectedTab: AdminTab = null;

    createdFromCompanyId: string = null;

    get UserTypes() {
        return UserTypes;
    }

    constructor(private location: Location, private userSessionService: UserSessionService) {}

    ngOnInit() {
        this.selectTab(this.selectedTab);
    }

    selectTab(tab: AdminTab) {
        const isTabSame = this.selectedTab && tab && this.selectedTab.key === tab.key;

        if (this.user && !this.isTabDisabled(tab.key) && !isTabSame) {
            // change the URL name as well but without reloading the page
            this.location.go('/admin/utilisateur/' + this.user._id + '/' + tab.key);
            this.selectedTab = tab; // they might be different if selectTab is called by the page being refresh (Ctrl R)
        }
    }

    getTabClasses(key: string): string {
        let classes = '';
        classes += this.isTabActive(key) ? 'tab-active ' : '';
        classes += this.isTabDisabled(key) ? 'cursor-not-allowed' : '';
        return classes;
    }

    isTabActive(key: string): boolean {
        return this.selectedTab.key === key;
    }

    getNameCapitalized(name: string): string {
        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    onUserChanged(user) {
        this.user = user;
        /**
         * If connected user is on its own profile,
         * then refresh user session (will refresh the picture and name)
         */
        if (this.user._id === this.userSession._id) {
            this.userSessionService.refreshUserSession();
        }
    }

    keepCompanyOrigin(companyId) {
        this.createdFromCompanyId = companyId;
    }

    /**
     * Return true if tab should be disabled.
     * @param {string} key
     * @return {boolean}
     */
    isTabDisabled(key: string): boolean {
        // if the new user hasn't been saved, allow only the user's profile tab
        if (!this.user && key !== 'profil') {
            return true;
        }

        return false;
    }

    /**
     * Returns whether the tab is editable or not
     * @param key - key of the tab
     * @return {boolean}
     */
    canEditTab(key: string): boolean {
        const tab = this.tabs.find(t => t.key === key);
        return tab ? tab.canEdit : false;
    }
}
