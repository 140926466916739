import { Injectable } from '@angular/core';
import { ChartSerieLight } from 'app/shared/models/charts/chart-serie.interface';
import { Dju } from 'app/shared/models/dju.interface';
import { CoolingMonthlyBill, ElecMonthlyBill, HeatingMonthlyBill } from 'app/shared/models/fluid-bills.interface';
import * as _ from 'lodash';

@Injectable()
export class BillsService {
    constructor() {}

    /**
     * Find element inside object from the given path.
     * Return undefined if the path isn't found.
     * @param {object} obj
     * @param {string} path
     * @returns {any} element found with the path, undefined if path is incorrect
     */
    private deepFind(obj: object, path: string): any {
        const paths = path.split('.');
        let current = obj;
        for (const p of paths) {
            if (current[p] === undefined) {
                return undefined;
            }
            current = current[p];
        }
        return current;
    }

    /**
     * Add a value inside one of the series of the memo array.
     * A serie has a label and an array of values. If it doesn't exist it is created.
     * The value to add is found from the given path / object.
     *
     * @param {{label: string, data: any[]}[]} memo array containing the diffferent series
     * @param currentItem object holding the value to insert
     * @param serieName serie inside which the value needs to be added.
     * @param propertyPath path to deep into to get the value
     */
    private addValueToMemo(
        memo: Array<{ label: string; data: any[] }>,
        currentItem: object,
        serieName: string,
        propertyPath: string
    ): void {
        // Handle full hours
        const existingSerie = memo.find(item => item.label === serieName);

        const value = this.deepFind(currentItem, propertyPath);

        if (existingSerie) {
            // Push new data in existing dataset
            existingSerie.data.push(value);
        } else {
            // Push new serie in dataset
            const newSerie = {
                label: serieName,
                data: [value],
            };

            memo.push(newSerie);
        }
    }

    /**
     * Add value inside one of the series of the dataset.
     * If the serie doesn't exist it is created.
     * @param {ChartSerieLight[]} memo serie of a dataset for bar chart
     * @param {string} serieName name of the serie to update/create
     * @param {number} value value to insert inside the serie
     * @param {string} yAxis name of the serie's axis inside the chart bar
     * @param {string} type type of chart (line, bar, etc)
     * @param {boolean} filled serie's field, determine if the chart serie is filled with color
     * @param {string} unit serie's field, used for the chart tooltips
     */
    private addValueToDataset(
        memo: ChartSerieLight[],
        serieName: string,
        value: number,
        yAxis: string,
        type: string,
        filled: boolean,
        unit: string
    ): void {
        // Handle full hours
        const existingSerie = memo.find(item => item.label === serieName);

        if (existingSerie) {
            // Push new data in existing dataset
            existingSerie.data.push(value);
        } else {
            // Push new serie in dataset
            const newSerie: ChartSerieLight = {
                label: serieName,
                data: [value],
                yAxisID: yAxis,
                filled,
                type,
                unit,
            };

            memo.push(newSerie);
        }
    }

    /**
     * Create and return a dataset of bar chart series.
     * Each serie's data array has the length of the monthData array.
     * A serie for djus is created if djuData isn't null nor empty. The value 0 is set on the month for which dju value isn't found in the array.
     *
     * @param {ElecMonthlyBill[]} monthDatas monthly data from elec bills
     * @param {{name: string, isOptional: boolean}[]} series names of the series to create and fill with data. When optional, series with only zeros are removed.
     * @param {string[]} seriesPathProperties path of the values to find inside the month data ojects
     * @param {Dju[]} djuData data to create the dju serie. If empty or null, the dju serie isn't created
     * @returns {ChartSerieLight[]} dataset of bar chart series created
     */
    public prepareDatasetWithDju(
        monthDatas: ElecMonthlyBill[],
        series: Array<{ name: string; isOptional: boolean }>,
        seriesPathProperties: string[],
        djuData: Dju[] = null
    ): ChartSerieLight[] {
        let dataSet = monthDatas.reduce((memo, currentItem) => {
            // create or update the dju serie
            if (djuData && djuData.length) {
                const djuOnCurrentMonth = djuData.find(dju => {
                    return dju.year === currentItem.year && dju.month === currentItem.month + 1;
                });

                // Add the current month dju value, 0 if not found for this month.
                // TODO: check if null or 0
                const djuValue = djuOnCurrentMonth ? djuOnCurrentMonth.hot : 0;
                this.addValueToDataset(
                    memo,
                    'Degrés jours unifiés (par mois)',
                    djuValue,
                    'y-axis-1',
                    'line',
                    false,
                    'DJU'
                );
            }

            // create or update the cost/conso  series
            series.forEach((currentSerie, index) => {
                const value = this.deepFind(currentItem, seriesPathProperties[index]);
                this.addValueToDataset(memo, currentSerie.name, value, 'y-axis-0', 'bar', true, 'kWh');
            });

            return memo;
        }, []);

        // remove the optional series filled with zeros
        dataSet = dataSet.filter(serie => {
            const isOptional = series.find(s => s.name === serie.label && s.isOptional);
            if (isOptional) {
                const hasData = serie.data.some(value => Boolean(value));
                return hasData; // if serie is optional and doesn't have data, the serie is removed
            }
            return true;
        });

        return dataSet;
    }

    /**
     * Create a dataset with HP, HC, Pointe consumptions, and djus if requested.
     * If the dju data array is empty or null, the dju serie isn't created.
     * If the pointe isn't found, the pointe serie isn't created.
     * @param {ElecMonthlyBill[]} monthData monthly bills data to fill the dataset with
     * @param {Dju[]} djuData monthly dju data, if not null nor empty a serie is created with these values
     * @returns {ChartSerieLight[]} dataset of bar chart series created
     */
    public getHPHCConsoMonthData(monthData: ElecMonthlyBill[], djuData: Dju[]): ChartSerieLight[] {
        const series = [
            { name: 'Pointe', isOptional: true },
            { name: 'Heures Pleines', isOptional: false },
            { name: 'Heures Creuses', isOptional: false },
        ];
        const properties = [
            'consumption.pointe.quantity',
            'consumption.globalHP.quantity',
            'consumption.globalHC.quantity',
        ];

        return this.prepareDatasetWithDju(monthData, series, properties, djuData);
    }

    /**
     * Create a dataset with global consumption and djus if requested.
     * If the dju data array is empty or null, the dju serie isn't created.
     * @param {ElecMonthlyBill[]} monthData monthly bills data to fill the dataset with
     * @param {Dju[]} djuData monthly dju data, if not null nor empty a serie is created with these values
     * @returns {ChartSerieLight[]} dataset of bar chart series created
     */
    public getTotalConsoMonthData(monthData: ElecMonthlyBill[], djuData: Dju[]): ChartSerieLight[] {
        const series = [{ name: 'Total des consommations', isOptional: false }];
        const properties = ['consumption.global.quantity'];
        return this.prepareDatasetWithDju(monthData, series, properties, djuData);
    }

    /**
     * Create a dataset with HP, HC, Pointe costs.
     * @param {ElecMonthlyBill[]} monthData monthly bills data to fill the dataset with
     * @returns {ChartSerieLight[]} dataset of bar chart series created
     */
    public getHPHCCostMonthData(monthData: ElecMonthlyBill[]) {
        const series = [
            { name: 'Pointe', isOptional: true },
            { name: 'Heures Pleines', isOptional: false },
            { name: 'Heures Creuses', isOptional: false },
        ];
        const properties = ['consumption.pointe.amount', 'consumption.globalHP.amount', 'consumption.globalHC.amount'];

        return this.prepareDatasetWithDju(monthData, series, properties);
    }

    public getHeatingMonthData(djuData: Dju[], monthData: HeatingMonthlyBill[], isCost: boolean = false) {
        const series = ['Chauffage', 'ECS'];
        const property = isCost ? 'amount' : 'quantity';
        return monthData.reduce((memo, currentItem) => {
            if (djuData) {
                const djuOnCurrentMonth = djuData.find(dju => {
                    return dju.year === currentItem.year && dju.month === currentItem.month + 1;
                });

                // If data of dju on current month : add it
                if (djuOnCurrentMonth) {
                    this.addValueToDataset(
                        memo,
                        'Degrés jours unifiés (par mois)',
                        djuOnCurrentMonth.hot,
                        'y-axis-1',
                        'line',
                        false,
                        'DJU'
                    );
                } else {
                    // TODO: check if null or 0
                    this.addValueToDataset(
                        memo,
                        'Degrés jours unifiés (par mois)',
                        0,
                        'y-axis-1',
                        'line',
                        false,
                        'DJU'
                    );
                }
            }

            const val = {
                heating: currentItem.consumption.heating[property] + currentItem.regulation.heating[property],
                ecs: currentItem.consumption.ecs[property] + currentItem.regulation.ecs[property],
            };

            this.addValueToDataset(memo, series[0], val.heating, 'y-axis-0', 'bar', true, isCost ? '€' : 'kWh');
            this.addValueToDataset(memo, series[1], val.ecs, 'y-axis-0', 'bar', true, isCost ? '€' : 'kWh');

            return memo;
        }, []);
    }

    public getDjuPonderationByEnergy(datasets) {
        const djuDatas = datasets.find(item => item.label === 'djus');
        const dataDjuPonderation = [];

        if (!djuDatas) {
            return;
        }

        for (let i = 1, len = datasets.length; i < len; i++) {
            const currentData = datasets[i].data;
            const djuPonderation = currentData.map((item, index) => {
                return item / djuDatas.data[index];
            });

            const newPonderationDataset = {
                data: djuPonderation,
                label: datasets[i].label,
                type: 'bar',
                stack: 0,
            };

            dataDjuPonderation.push(newPonderationDataset);
        }

        // Fill with another dataset
        const array = new Array(datasets[0].data.length);
        array.fill(null);

        const emptyPonderationDataset = {
            data: array,
            label: 'dju',
            type: 'bar',
        };

        dataDjuPonderation.push(emptyPonderationDataset);

        return dataDjuPonderation;
    }

    public getMonthDataDjuRatio(djuData: Dju[], monthData: any[], properties: any[], labels: string[]) {
        // Each property should have a name for the serie and either a path to get in monthDataItem or function to process monthDataItem
        const series = properties.map(property => {
            return property.name + ' (kWh/dju)';
        });

        return monthData.reduce((memo: ChartSerieLight[], currentItem) => {
            if (djuData) {
                const djuOnCurrentMonth = djuData.find(dju => {
                    return dju.year === currentItem.year && dju.month === currentItem.month + 1;
                });

                properties.forEach((property, index) => {
                    let propertyValue = null;

                    if (property.path) {
                        propertyValue = _.get(currentItem, property.path);
                    } else if (property.processFunction) {
                        propertyValue = property.processFunction(currentItem);
                    } else {
                        throw new Error('Property should contain either a property path or a processFunction');
                    }

                    // If data of dju on current month : add it
                    if (djuOnCurrentMonth && propertyValue && djuOnCurrentMonth.hot) {
                        this.addValueToDataset(
                            memo,
                            series[index],
                            propertyValue / djuOnCurrentMonth.hot,
                            'y-axis-0',
                            'bar',
                            true,
                            'kWh/DJU'
                        );
                    } else {
                        this.addValueToDataset(memo, series[index], 0, 'y-axis-0', 'bar', true, 'kWh/DJU');
                    }
                });

                // Here put as much dataset as labels to be sure chart update won't crash
                if (labels.length > properties.length) {
                    for (let i = properties.length, len = labels.length; i < len; i++) {
                        this.addValueToDataset(memo, i.toString(), null, 'y-axis-0', 'line', true, 'kWh/DJU');
                    }
                }
            }

            return memo;
        }, []);
    }

    public getHeatingMonthDataDjuRatio(djuData: Dju[], monthData: HeatingMonthlyBill[]) {
        const series = ['Chauffage kWh/DJU', 'ECS kWh/DJU', 'Total extrait kWh/DJU'];
        const property = 'quantity';

        const axis = 'y-axis-0';
        const chartType = 'bar';
        const unit = 'kWh/DJU';

        return monthData.reduce((memo, currentItem) => {
            if (djuData) {
                const djuOnCurrentMonth = djuData.find(dju => {
                    return dju.year === currentItem.year && dju.month === currentItem.month + 1;
                });

                const val = {
                    heating: currentItem.consumption.heating[property] + currentItem.regulation.heating[property],
                    ecs: currentItem.consumption.ecs[property] + currentItem.regulation.ecs[property],
                    total: currentItem.consumption.total[property] + currentItem.regulation.total[property],
                };

                // If data of dju on current month : add it
                if (djuOnCurrentMonth && djuOnCurrentMonth.hot) {
                    this.addValueToDataset(
                        memo,
                        series[0],
                        val.heating / djuOnCurrentMonth.hot,
                        axis,
                        chartType,
                        true,
                        unit
                    );
                    this.addValueToDataset(
                        memo,
                        series[1],
                        val.ecs / djuOnCurrentMonth.hot,
                        axis,
                        chartType,
                        true,
                        unit
                    );
                    this.addValueToDataset(
                        memo,
                        series[2],
                        val.total / djuOnCurrentMonth.hot,
                        axis,
                        chartType,
                        true,
                        unit
                    );
                } else {
                    // TODO: check if null or 0
                    this.addValueToDataset(memo, series[0], 0, axis, chartType, true, unit);
                    this.addValueToDataset(memo, series[1], 0, axis, chartType, true, unit);
                    this.addValueToDataset(memo, series[2], 0, axis, chartType, true, unit);
                }
            }

            return memo;
        }, []);
    }

    public getHeatingTotalMonthData(djuData: Dju[], monthData: HeatingMonthlyBill[], isCost: boolean = false) {
        const series = ['Total des consommations extrait', 'Total des consommations calculé'];
        const property = isCost ? 'amount' : 'quantity';
        return monthData.reduce((memo, currentItem) => {
            if (djuData) {
                const djuOnCurrentMonth = djuData.find(dju => {
                    return dju.year === currentItem.year && dju.month === currentItem.month + 1;
                });

                // If data of dju on current month : add it
                if (djuOnCurrentMonth) {
                    this.addValueToDataset(
                        memo,
                        'Degrés jours unifiés (par mois)',
                        djuOnCurrentMonth.hot,
                        'y-axis-1',
                        'line',
                        false,
                        'DJU'
                    );
                } else {
                    // TODO: check if null or 0
                    this.addValueToDataset(
                        memo,
                        'Degrés jours unifiés (par mois)',
                        0,
                        'y-axis-1',
                        'line',
                        false,
                        'DJU'
                    );
                }
            }

            const val1 = {
                tot: 0,
            };
            if (
                currentItem.consumption.hasOwnProperty('total') &&
                currentItem.consumption.total.hasOwnProperty(property)
            ) {
                val1.tot += currentItem.consumption.total[property];
            }
            if (
                currentItem.regulation.hasOwnProperty('total') &&
                currentItem.regulation.total.hasOwnProperty(property)
            ) {
                val1.tot += currentItem.regulation.total[property];
            }
            this.addValueToDataset(memo, series[0], val1.tot, 'y-axis-0', 'bar', true, isCost ? '€' : 'kWh');
            const val = {
                tot: 0,
            };
            if (
                currentItem.consumption.hasOwnProperty('heating') &&
                currentItem.consumption.heating.hasOwnProperty(property)
            ) {
                val.tot += currentItem.consumption.heating[property];
            }
            if (currentItem.consumption.hasOwnProperty('ecs') && currentItem.consumption.ecs.hasOwnProperty(property)) {
                val.tot += currentItem.consumption.ecs[property];
            }
            if (
                currentItem.regulation.hasOwnProperty('heating') &&
                currentItem.regulation.heating.hasOwnProperty(property)
            ) {
                val.tot += currentItem.regulation.heating[property];
            }
            if (currentItem.regulation.hasOwnProperty('ecs') && currentItem.regulation.ecs.hasOwnProperty(property)) {
                val.tot += currentItem.regulation.ecs[property];
            }
            this.addValueToDataset(memo, series[1], val.tot, 'y-axis-0', 'bar', true, isCost ? '€' : 'kWh');

            return memo;
        }, []);
    }

    public getCoolingMonthData(
        djuData: Dju[],
        monthData: CoolingMonthlyBill[],
        isCost: boolean = false,
        isTotal: boolean = false
    ) {
        const series = ['Energie', 'Volume'];
        const consoProperties = [{ p: 'energy', u: 'kWh' }, { p: 'volume', u: 'm3' }];
        const property = isCost ? 'amount' : 'quantity';
        const djuProperty = 'cold';
        return monthData.reduce((memo, currentItem) => {
            if (djuData) {
                const djuOnCurrentMonth = djuData.find(dju => {
                    return dju.year === currentItem.year && dju.month === currentItem.month + 1;
                });
                const djuValue = djuOnCurrentMonth ? djuOnCurrentMonth[djuProperty] : 0;
                // If data of dju on current month : add it
                this.addValueToDataset(
                    memo,
                    'Degrés jours unifiés (par mois)',
                    djuValue,
                    'y-axis-2',
                    'line',
                    false,
                    'DJU'
                );
            }
            if (!isTotal) {
                consoProperties.forEach((prop, index) => {
                    if (
                        currentItem.consumption.hasOwnProperty(prop.p) &&
                        currentItem.consumption[prop.p].hasOwnProperty(property)
                    ) {
                        const value = currentItem.consumption[prop.p][property];
                        this.addValueToDataset(
                            memo,
                            series[index],
                            value,
                            'y-axis-' + index,
                            'bar',
                            true,
                            isCost ? '€' : prop.u
                        );
                    }
                });
            } else {
                let value = 0;
                consoProperties.forEach((prop, index) => {
                    if (
                        currentItem.consumption.hasOwnProperty(prop.p) &&
                        currentItem.consumption[prop.p].hasOwnProperty(property)
                    ) {
                        value += currentItem.consumption[prop.p][property];
                    }
                });
                this.addValueToDataset(memo, 'Total', value, 'y-axis-0', 'bar', true, isCost ? '€' : 'kWh|m3');
            }

            return memo;
        }, []);
    }

    public getCoolingMonthDataDjuRatio(djuData: Dju[], monthData: CoolingMonthlyBill[]) {
        const series = ['Energie kWh/DJU'];
        const property = 'quantity';
        const consoProperty = 'energy';
        const djuProperty = 'cold';

        const axis = 'y-axis-0';
        const chartType = 'bar';
        const unit = 'kWh/DJU';

        return monthData.reduce((memo, currentItem) => {
            if (djuData) {
                const djuOnCurrentMonth = djuData.find(dju => {
                    return dju.year === currentItem.year && dju.month === currentItem.month + 1;
                });

                const val = currentItem.consumption[consoProperty][property];
                const djuValue =
                    djuOnCurrentMonth && djuOnCurrentMonth[djuProperty] ? val / djuOnCurrentMonth[djuProperty] : 0;
                // If data of dju on current month : add it
                this.addValueToDataset(memo, series[0], djuValue, axis, chartType, true, unit);
            }

            return memo;
        }, []);
    }

    public getTotalCostMonthData(monthData: any[]) {
        // public lineChartData:Array<any> = [
        //         {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A'},
        //         {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B'},
        //         {data: [18, 48, 77, 9, 100, 27, 40], label: 'Series C'}
        //     ];

        const series = ['Total des consommations'];

        const dataset = monthData.reduce((memo, currentItem) => {
            this.addValueToMemo(memo, currentItem, series[0], 'consumption.global.amount');
            return memo;
        }, []);

        return dataset;
    }

    public getWaterMonthData(month) {
        return {
            dateStart: '2015-07-01T00:00:00.000Z',
            dateEnd: '2015-07-31T23:59:59.999Z',
            month,
            year: 2015,
            days: 31,
            totalTTC: 24.382022471910112,
            totalHT: 23.71325842696629,
            totalTVA: 1.3026966292134832,
            productionAndDistribution: {
                amount: 18.857752808988764,
                quantity: 11.842696629213483,
            },
            wasteWaterTreatment: {
                amount: 0,
            },
            publicOrganismAndVAT: {
                amount: 4.855505617977528,
            },
            VAT: null,
        };
    }
}
