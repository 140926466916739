export const UploadStateList = {
    UPLOADING: 'uploading',
    UNTOUCHED: 'untouched',
    UPLOAD_ERROR: 'upload_error',
    EXTRACTING: 'extracting',
    PATTERN_MISSING: 'pattern_missing',
    NO_PATTERNS: 'no_patterns',
    ERROR: 'error',
    PROPERTY_MISSING: 'property_missing',
    MULTI_UNCOMPLETED: 'multi_uncompleted',
    JOBS_DONE: 'jobs_done',
    NOTHING: 'nothing',
    HAS_CHUNKS_NO_PATTERNS: 'has_chunks_no_patterns',
    ARCHIVED: 'archived',
    IN_PROGRESS: 'in_progress',
    QUALITY_CHECK_FAILED: 'quality_check_failed',
};
