// tslint:disable:no-access-missing-member
import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    OnInit,
    QueryList,
    TemplateRef,
    Type,
    ViewChildren,
    ViewContainerRef,
} from '@angular/core';

import { Question } from '../../../../models/question.interface';
import { QuestionService } from '../../../../services/sites/question.service';
import { FileQuestionComponent } from '../file-upload/file-upload.component';
import { InputTextMultipleComponent } from '../input-text-multiple/input-text-multiple.component';
import { InputTextComponent } from '../input-text/input-text.component';
import { QuestionComponent } from '../question/question.component';
import { SelectChoicesBlocComponent } from '../select-choices-bloc/select-choices-bloc.component';
import { SelectChoicesInputComponent } from '../select-choices-input/select-choices-input.component';
import { SelectChoicesComponent } from '../select-choices/select-choices.component';
import { SelectDropdownComponent } from '../select-dropdown/select-dropdown.component';

@Component({
    selector: 'ga-question-group',
    templateUrl: './group-collapse.component.html',
    styleUrls: ['./group-collapse.component.scss'],
})
/**
 * Component to create a group of questions. A group is composed of multiple questions that can have multiple answers.
 * @Input data : User data at the format [{key: string, value: string}]
 * @Input config : Question from the config file with pro perties attribute at the format {questions: [... any question objects]}
 * @Ouput valueChange : event that gives the data each time it's changed by the user at the format [{key: string, value: string}]
 */
export class GroupQuestionComponent extends QuestionComponent implements OnInit, AfterViewChecked {
    // Container to set the questions component in the ng-template places
    rootViewContainer: ViewContainerRef;

    // Values of the questions components
    private values: Array<{
        key: string;
        value: any[];
    }> = [];

    // Get all the questions components from the ng-template.s
    @ViewChildren('questionGroup', { read: ViewContainerRef }) questionItems: QueryList<ViewContainerRef>;

    public groups: Array<{
        rendered: boolean; // Is the group created in the DOM (render => create the DOM component)
        display: boolean;
        values: Array<{ key: string; value: string }>;
        vcr: ViewContainerRef;
    }> = [];

    constructor(
        private factoryResolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef,
        private questionService: QuestionService
    ) {
        super();
    }

    ngOnInit() {
        let nbGroups = 1;
        let key = '';
        // Define the number of groups that needs to be initialized
        if (this.config.properties.questions) {
            const type = this.config.properties.questions[0].type;
            if (
                type === 'simple-choices' ||
                type === 'simple-choices-bloc' ||
                type === 'input-text' ||
                type === 'select-dropdown' ||
                type === 'file'
            ) {
                key = this.config.properties.questions[0].properties.key;
            } else if (type === 'input-text-multiple' || type === 'select-choices-input') {
                if (this.config.properties.questions[0].properties.values) {
                    key = this.config.properties.questions[0].properties.values[0].key;
                }
            }
            const tmpData = this.data.find(x => x.key === key);
            if (tmpData && Array.isArray(tmpData.value)) {
                nbGroups = tmpData.value.length;
            }
        }
        for (let i = 0; i < nbGroups; i++) {
            this.add();
        }
    }

    /**
     * After the view check, check if each group is already created. Create those which aren't.
     * The property rendered indicates if it has been created/rendered.
     */
    ngAfterViewChecked() {
        this.groups.forEach(group => {
            if (!group.rendered) {
                group.vcr = this.questionItems.toArray()[this.groups.indexOf(group)];
                this.manageGroups(group);
                group.rendered = true;
            }
        });
    }

    /**
     * Add groups at init
     * @param group to create
     */
    private manageGroups(group) {
        this.config.properties.questions.forEach(question => {
            this.addQuestion(question, group);
        });
        this.cdr.detectChanges();
    }

    toggleCollapse(group) {
        if (!group.display) {
            group.display = !group.display;
            this.groups.forEach(g => {
                g.display = false;
            });
        }
        group.display = !group.display;
    }

    /**
     * Delete a collapse group when clicking on the delete button
     * @param group to delete.
     */
    deleteCollapseGroup(group) {
        const index = this.groups.indexOf(group);
        const keys = [];
        // Get the files keys of the group's file to delete them.
        this.config.properties.questions.forEach(q => {
            if (q.type === 'file' || (q.type === 'simple-choices' && q.fileKey)) {
                if (q.properties.key) {
                    keys.push(q.properties.key);
                }
            }
        });
        // If there is some files
        if (keys.length > 0) {
            // Delete the files
            const valueToDelete = {
                site: this.siteId,
                zone: this.zoneId,
                keys,
                index,
                groupDelete: true,
            };

            this.questionService.deleteFiles(valueToDelete).then(res => {
                this.groups = this.groups.filter(x => x !== group);
                this.updateValues();
            });
        } else {
            this.groups = this.groups.filter(x => x !== group);
            this.updateValues();
        }
    }

    /**
     * Add a group to the group list.
     * @param {Array<{key: string; value: string}>} values
     */
    add(values: Array<{ key: string; value: string }> = []) {
        this.groups.forEach(g => {
            g.display = false;
        });
        this.groups.push({
            rendered: false,
            display: true,
            values,
            vcr: null,
        });
    }

    /**
     * Create, add a question at the place, then subscribe to any changes of the question value
     * @param config of the question in the group.
     * @param group where the question is added.
     */
    private addQuestion(config, group) {
        this.rootViewContainer = group.vcr;
        const obsAll = this.createQuestion(this.getDataForGroup(group), config);
        // Watch for value change to update it.
        if (obsAll.change) {
            obsAll.change.subscribe(value => {
                if (Array.isArray(value)) {
                    value.forEach(item => {
                        this.addOrUpdate(item, group);
                    });
                } else {
                    this.addOrUpdate(value, group);
                }
                this.updateValues();
            });
        }
        // Watch for file upload instruction
        if (obsAll.fileUpload) {
            obsAll.fileUpload.subscribe(value => {
                const index = this.groups.indexOf(group);

                if (index > -1) {
                    const res = Object.assign(
                        {
                            index,
                        },
                        value
                    );

                    this.uploadFile.emit(res);
                }
            });
        }
    }

    /**
     * Lean the data for a group with the good value
     * (ex. for 2nd group : ["coucou", "test"] => "test"
     * @param group
     * @returns {any[]}
     */
    private getDataForGroup(group) {
        const index = this.groups.indexOf(group);
        const data = [];
        this.data.forEach(x => {
            data.push({
                key: x.key,
                value: Array.isArray(x.value) ? x.value[index] : x.value,
            });
        });
        return data;
    }

    /**
     * Create the question element and return the observable for modifications
     * @param data from db
     * @param config: question config
     * @returns {any} objects containing EventEmitters
     */
    createQuestion(data, config) {
        let typeComponent: Type<Question> = null;
        switch (config.type) {
            case 'simple-choices':
                typeComponent = SelectChoicesComponent;
                break;
            case 'simple-choices-bloc':
                typeComponent = SelectChoicesBlocComponent;
                break;
            case 'input-text':
                typeComponent = InputTextComponent;
                break;
            case 'select-dropdown':
                typeComponent = SelectDropdownComponent;
                break;
            case 'input-text-multiple':
                typeComponent = InputTextMultipleComponent;
                break;
            case 'select-choices-input':
                typeComponent = SelectChoicesInputComponent;
                break;
            case 'file':
                typeComponent = FileQuestionComponent;
                break;
        }
        if (typeComponent) {
            return this.questionService.addComponent(
                data,
                config,
                this.siteId,
                this.zoneId,
                typeComponent,
                this.rootViewContainer
            );
        } else {
            return null;
        }
    }

    /**
     * Update the group value by adding it to the list if not existing. Otherwise, update it.
     * @param {{key: string; value: string}} item
     * @param group
     */
    private addOrUpdate(item: { key: string; value: string }, group) {
        const temp = group.values.find(x => x.key === item.key);
        if (!temp) {
            group.values.push(item);
        } else {
            temp.value = item.value;
        }
    }

    /**
     * Update values and emit it to parent.
     */
    private updateValues() {
        this.values = [];
        this.groups.forEach(grp => {
            grp.values.forEach(val => {
                const tmpQuestion = this.values.find(x => x.key === val.key);
                if (tmpQuestion) {
                    tmpQuestion.value[this.groups.indexOf(grp)] = val.value;
                } else {
                    this.values.push({
                        key: val.key,
                        value: [val.value],
                    });
                }
            });
        });
        this.valueChange.emit(this.values);
    }
}
