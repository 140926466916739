import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'user-tile',
    templateUrl: './user-tile.component.html',
    styleUrls: ['./user-tile.component.scss'],
})
export class UserTileComponent implements OnInit {
    @Input() energyManager: any;
    @Input() color: string;
    defaultPicture = 'assets/img/backgrounds/default-avatar.jpg';

    constructor() {}

    ngOnInit() {}

    getImg() {
        return this.energyManager.logoUrl ? this.energyManager.logoUrl : this.defaultPicture;
    }
}
