import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartBarComponent } from '../chart-bar/chart-bar.component';

@Component({
    selector: 'ga-chart-bar-toggle',
    templateUrl: './chart-bar-toggle.component.html',
    styleUrls: ['./chart-bar-toggle.component.scss'],
    providers: [],
})
export class ChartBarToggleComponent implements OnInit {
    _toggleOptions: Array<{ name: string; display: boolean }>;

    @Input() datasets: any[];
    @Input() colors: object[];
    @Input() labels: string[] | string[][];
    @Input() width = 900;
    @Input() minHeight = 340;
    @Input() maxHeight = 340;
    @Input() showGrid = true;
    @Input() legend: any[] = [];
    @Input() options: any[];
    @Input() downloadFileNames: any[];

    @Input('toggleOptions')
    set toggleOptions(values: Array<string | { name: string; display: boolean }>) {
        this._toggleOptions = [];
        values.forEach(value => {
            if (typeof value === 'string') {
                this._toggleOptions.push({
                    name: value,
                    display: true,
                });
            } else if (typeof value === 'object') {
                this._toggleOptions.push(value);
            }
        });
    }
    get toggleOptions(): Array<string | { name: string; display: boolean }> {
        return this._toggleOptions;
    }
    @Input() barChartType = 'bar';

    @ViewChild('chart', { static: false }) chart: ChartBarComponent;

    @Output() emitChartClick = new EventEmitter();

    public activeDataset = 0;

    constructor(private el: ElementRef) {}

    ngOnInit() {
        this.selectToggleWithData();
    }

    chartClicked(event) {
        this.emitChartClick.emit(event);
    }

    setActiveBarChart(index: number) {
        if (this.chart && this.activeDataset !== index) {
            const datasets = this.datasets[index];
            if (datasets) {
                const colors = this.colors[index];
                for (let i = 0; i < datasets.length; i++) {
                    if (colors[i]) {
                        Object.assign(datasets[i], colors[i]);
                    }
                }
                this.chart.updateOptions(this.options[index]);
                this.chart.replaceDatasets(this.datasets[index]);
                this.activeDataset = index;
                this.chart.replaceLabels(this.currentLabels);
            }
        }
    }

    /**
     * Returns false if one of the toggles has data to display
     * @return {boolean}
     */
    isCurrentChartEmpty() {
        // returns false as soon as it finds a dataset with values for one fluid
        return this.findToggleIndexWithData() === -1 ? true : false;
    }

    /**
     * Returns the first toggle index with data
     * @return {number}
     */
    findToggleIndexWithData(): number {
        return this.datasets.findIndex(toggleDataset => {
            // returns true as soon as it finds a fluid dataset with values
            return toggleDataset.some(fluidDataSet => {
                return fluidDataSet.data.some(value => value > 0 || value < 0);
            });
        });
    }

    /**
     * Select the toggle which has data
     */
    selectToggleWithData() {
        const indexWithData = this.findToggleIndexWithData();
        this.setActiveBarChart(indexWithData);
    }

    getButtonClass(i) {
        return this.activeDataset === i ? 'btn-primary' : 'btn-white';
    }

    get currentDataSet() {
        return this.datasets[this.activeDataset];
    }

    get currentColors() {
        return this.colors[this.activeDataset];
    }

    get currentLabels(): string[] {
        if (this.barChartType === 'horizontalBar') {
            return this.labels[this.activeDataset] as string[];
        }
        return this.labels as string[];
    }

    get currentLegends() {
        return this.legend[this.activeDataset];
    }

    get currentDownloadFileName() {
        return this.downloadFileNames[this.activeDataset];
    }

    get currentOptions() {
        return this.options[this.activeDataset];
    }

    getHeight() {
        let height = 0;
        if (this.el.nativeElement.children && this.el.nativeElement.children.length) {
            for (const el of this.el.nativeElement.children) {
                height += el.offsetHeight;
            }
        }
        return height;
    }

    public addDataset(dataset) {
        if (this.chart) {
            this.chart.addDataset(dataset);
        }
    }

    public removeDataset(label = '') {
        if (this.chart) {
            this.chart.removeDataset(label);
        }
    }

    public toggleDataset(label = '', hide: true | false | null = null) {
        if (this.chart) {
            this.chart.toggleDataset(label, hide);
        }
    }

    public toggleScale(axis: 'x' | 'y' = 'y', id: string = null, hide: true | false | null = null) {
        if (this.chart) {
            this.chart.toggleScale(axis, id, hide);
        }
    }
}
