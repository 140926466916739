import { Injectable } from '@angular/core';
import { MeteoStation } from 'app/shared/models/meteo-station.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

export interface MeteoStationSearch {
    dates: {
        start: string;
        end: string;
    };
    location: Location;
    station: MeteoStation;
}

export interface Location {
    name: string;
    streetNumber?: string;
    streetName: string;
    zipcode?: string;
    city: string;
    longitude: number;
    latitude: number;
}

@Injectable()
export class DjuService extends PageService {
    constructor(public apiService: ApiService) {
        super(apiService);
        this.fake = false;
    }

    async searchStation(query: string): Promise<MeteoStationSearch> {
        const res = await this.get(`/api/meteo-stations/search?s=${query}`);
        return res.data;
    }
}
