import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable()
export class SortService {
    constructor() {}

    public compareStrings(a: string, b: string): number {
        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b, 'fr');
        }

        if (typeof a === 'string') {
            return -1;
        }

        if (typeof b === 'string') {
            return 1;
        }

        return 0;
    }

    public compareNumbers(a: number, b: number): number {
        if (typeof a === 'number' && typeof b === 'number') {
            return a - b;
        }

        if (typeof a === 'number') {
            return -1;
        }

        if (typeof b === 'number') {
            return 1;
        }

        return 0;
    }

    public compareBooleans(a: boolean, b: boolean): number {
        const numberifyA = a ? 1 : 0;
        const numberifyB = b ? 1 : 0;

        if (typeof numberifyA === 'number' && typeof numberifyB === 'number') {
            return numberifyA - numberifyB;
        }

        if (typeof numberifyA === 'number') {
            return -1;
        }

        if (typeof numberifyB === 'number') {
            return 1;
        }

        return 0;
    }

    /** Compare two date strings (in format DD/MM/YYYY) */
    public compareDateStrings(a: string, b: string): number {
        return moment(a, 'DD/MM/YYYY').diff(moment(b, 'DD/MM/YYYY'));
    }
}
