import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Legend, LegendConfig } from './legend.interface';

@Component({
    selector: 'ga-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
    providers: [],
})
export class LegendComponent implements OnInit {
    /**
     * legend items defined by name & color (ex rgb: '255,255,255')
     */
    @Input() legends: Legend[];

    /**
     * Get the styles of the legend container defined in the children component
     */
    @Input() legendConfig: LegendConfig;

    public containerClasses: string[] = [];

    constructor() {}

    ngOnInit() {
        this.initConfig();
    }

    /**
     * Get the rgb color from by given string (ex '255,255,255')
     * @param {string} color
     */
    getRgbColor(color: string): string {
        return color ? `rgb(${color})` : null;
    }

    initConfig() {
        if (_.get(this.legendConfig, 'alignement.enabled')) {
            const value = this.legendConfig.alignement.value;
            this.containerClasses.push(`float-${value}`);
        }
    }
}
