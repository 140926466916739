import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MergeService } from './merge.service';

import { Marker } from 'app/shared/models/marker.interface';
import { MapService } from 'app/shared/services/map/map.service';
import { ProfilesService } from 'app/shared/services/profiles/profiles.service';

import Swal, { SweetAlertOptions } from 'sweetalert2';

interface Address {
    streetNumber?: string;
    streetName?: string;
    zipcode?: string;
    city?: string;
    name: string;
    longitude?: number;
    latitude?: number;
}

@Component({
    selector: 'ga-merge',
    templateUrl: './merge.component.html',
    styleUrls: ['./merge.component.scss'],
    providers: [MergeService],
})
export class MergeComponent implements OnInit {
    oldAddress: Address = { name: null };
    newAddress: Address = { name: null };
    markers: Marker[] = [];
    entity: {
        id: string;
        isSite: boolean;
    } = { id: null, isSite: true };

    private _search: string;

    set search(search) {
        this.resetTexts();
        this._search = search;
    }
    get search() {
        return this._search;
    }

    texts: { warning: string; error: string } = {
        warning: '',
        error: '',
    };

    mapOptions: {
        zoom: number;
        lat: number;
        lng: number;
        height: number;
    } = {
        zoom: 8,
        lat: 49.894067,
        lng: 2.2957529999999906,
        height: 300,
    };

    fluidCategory = 'energie';

    private equalityCheckFields: string[] = ['latitude', 'longitude', 'city', 'zipcode', 'streetName', 'streetNumber'];

    constructor(
        private mergeService: MergeService,
        private route: ActivatedRoute,
        private mapService: MapService,
        private profilesService: ProfilesService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(params => {
            if (params.siteId) {
                this.getSiteInformations(params.siteId);
            } else if (params.routingReferenceId) {
                this.entity.isSite = false;
                this.getRoutingReferenceInformation(params.routingReferenceId);
            }

            this.fluidCategory = params['fluidCategory'];
        });
    }

    async searchLocation(): Promise<void> {
        try {
            const response = await this.mergeService.searchLocation(this.search);
            this.newAddress = this.copyAddress(response.data);
            if (response.data && !response.data.streetNumber) {
                delete this.newAddress.streetNumber;
            }

            this.setSiteMarker(this.newAddress);
        } catch (err) {
            if (err.status === 404) {
                this.texts.error = "L'adresse renseignée est introuvable";
            } else if (err.status === 400) {
                this.texts.error = 'Une erreur est survenue, veuillez réessayer plus tard';
            }
        }
    }

    async saveLocation(): Promise<void> {
        this.resetTexts();
        try {
            const response = await this.mergeService.saveLocation(this.entity, this.newAddress);
            const dataAddress = this.entity.isSite ? response.data : response.data.address;
            this.newAddress = this.copyAddress(dataAddress);
            this.oldAddress = this.copyAddress(dataAddress);
            const entityDisplay = this.entity.isSite ? 'site' : 'PDL';
            Swal.fire({
                icon: 'success',
                title: 'La nouvelle adresse du ' + entityDisplay + ' a été sauvegardée !',
                showConfirmButton: false,
                timer: 2000,
            });
        } catch (err) {
            const swalProperties: SweetAlertOptions = {
                icon: 'error',
                title: 'Une erreur est survenue',
                text: 'Merci de réessayer ultérieurement.',
                showConfirmButton: false,
                timer: 2000,
            };
            if (err && err.errorCode && err.errorCode === 'address_already_used') {
                swalProperties.title = 'Addresse déjà utilisée';
                swalProperties.text = 'Un site est déjà localisé à cette adresse, veuillez utiliser une autre adresse';
                swalProperties.timer = 3000;
            }
            Swal.fire(swalProperties);
        }
    }

    canBeSaved(): boolean {
        return this.newAddresshasLatLng() && this.siteAndnewAddressAreDifferent();
    }

    canSearch(): boolean {
        return this.newAddress.name !== this.search;
    }

    siteAndnewAddressAreDifferent(): boolean {
        return this.equalityCheckFields.some(key => this.newAddress[key] !== this.oldAddress[key]);
    }

    newAddresshasLatLng(): boolean {
        return Boolean(this.newAddress.latitude && this.newAddress.longitude);
    }

    private async getSiteInformations(id: string): Promise<void> {
        const response = await this.profilesService.getSiteProfile(id);

        this.entity = { id: response.data._id, isSite: true };
        this.newAddress = this.copyAddress(response.data);
        this.oldAddress = this.copyAddress(response.data);
        this.search = this.newAddress.name;
        this.setSiteMarker(response.data);
    }

    private setSiteMarker(site): void {
        this.markers = [
            {
                lat: site.latitude,
                lng: site.longitude,
                config: 'site',
                data: this.mapService.getSiteDataForMarkersAddress(site),
            },
        ];
    }

    private async getRoutingReferenceInformation(id: string): Promise<void> {
        const response = await this.mergeService.getRoutingReference(id);

        this.entity = { id: response.data._id, isSite: false };
        this.newAddress = this.copyAddress(response.data.address);
        this.oldAddress = this.copyAddress(response.data.address);
        this.search = this.getRoutingReferenceSearch(this.newAddress);
        this.setRoutingReferenceMarker(response.data);
    }

    private setRoutingReferenceMarker(routingReference): void {
        this.markers = [
            {
                lat: routingReference.address.latitude,
                lng: routingReference.address.longitude,
                config: 'routing-reference',
                data: this.mapService.getRoutingReferenceDataForMarkersAddress(routingReference),
            },
        ];
    }

    private getRoutingReferenceSearch(address: Address): string {
        if (!address.streetName && !address.zipcode && !address.city) {
            return address.name;
        }
        let query = address.streetNumber ? `${address.streetNumber} ` : '';
        query += `${address.streetName} `;
        query += address.zipcode ? address.zipcode : address.city;
        return query;
    }

    private resetTexts(): void {
        this.texts.warning = '';
        this.texts.error = '';
    }

    private copyAddress(obj: Address): Address {
        const address = { name: null };
        const properties = ['name'].concat(this.equalityCheckFields);
        properties.forEach(property => {
            if (obj.hasOwnProperty(property)) {
                address[property] = obj[property];
            }
        });
        return address;
    }

    get entityTypeDisplay(): string {
        return this.entity.isSite ? 'Site' : 'PDL';
    }
}
