/**
 * Interface used inside the collect tables components
 */
export interface UploadFile {
    id: string;
    uploadId?: string;
    filename: string;
    size: number;
    state: string;
    percent: number;
    group: string;
    fileObject?: File; // file Object containing raw data
    stack: string;
}

/**
 * Interface of the file expected from a drag&drop zone
 */
export interface File {
    filename: string;
    lastModified: number;
    lastModifiedDate: any;
    name: string;
    size: number;
    type: string;
}

/**
 * Interface of the Upload objects expected from the API
 * Extra attributes can be added in order to be used inside the collect tables components
 */
export interface Upload extends UploadFile {
    _id?: string;
    originalname?: string;
    identificationPattern: any;
    clothestPattern?: any;
    chunks?: any[];
    [key: string]: any;
    file: string;
}

/**
 * Enum having different type of upload forcing
 */
export enum ForceType {
    EXTRACT = 'extract',
    QUALITY = 'quality',
}

/**
 * Contract group information
 */
export interface ContractUploadsGroup {
    groupContractProviderEnergy: string;
    energy: string;
    energyType: string;
    provider: { name: string };
    contractType: { name: string };
    count: number;
    error: boolean;
    data: ContractFileChunk[];
    forceType: ForceType;
}

/**
 * Chunk or upload inside the contract group data
 */
export interface ContractFileChunk {
    id: string;
    filename: string;
    size: number;
    identificationPattern: any;
    state: string;
    stack: string;
    _id?: string;
    uploadId?: string;
    chunkId?: string;
    missingProperties?: string[];
    requiredProperties?: string[];
    patternId?: string;
    error?: any;
    uploadState?: string;
}

/**
 * Recap of upload files
 */
export interface UploadChunkRecap {
    filename: string;
    missingProperties: string[];
    size: number;
    state: string;
    nbProperties: string[];
    checked: boolean;
    stack: string;
    uploadId: string;
    chunkId: string;
    uploadState: string;
    error: any;
}
