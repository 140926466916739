import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    providers: [],
})
export class NotFoundComponent implements OnInit {
    constructor() {}
    ngOnInit() {}

    getBlocCss() {
        return {
            'text-align': 'center',
            padding: '20px',
        };
    }
}
