import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
    ChartColorGA,
    ChartDataSetsGA,
    ChartGA,
    ChartLegendGA,
    ChartOptionsGA,
    ChartTypeGA,
} from 'app/shared/models/charts/charts.interface';
import * as _ from 'lodash';

@Component({
    selector: 'ga-chart-bar',
    templateUrl: './chart-bar.component.html',
    styleUrls: ['./chart-bar.component.scss'],
})
export class ChartBarComponent {
    public chartInstance: ChartGA;

    @Input()
    downloadFileName = 'histogramme';

    @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;

    @Input() data: any[];
    @Input() dataset: ChartDataSetsGA[];
    @Input() colors: ChartColorGA[];
    @Input() labels: string[];
    @Input() stacked = false;
    @Input() width = 900;
    @Input() minHeight: number = null;
    @Input() maxHeight: number = null;
    @Input() showGrid = true;
    @Input() options: ChartOptionsGA = {};
    @Input() legend: ChartLegendGA[] = [];
    @Input() secondLegend: ChartLegendGA[] = [];
    @Input() barChartType: ChartTypeGA = 'bar';

    @Output() emitChartClick = new EventEmitter();

    setChartInstance(chart) {
        this.chartInstance = chart;
    }

    chartClicked(event) {
        this.emitChartClick.emit(event);
    }

    updateChart() {
        this.chartInstance.chart.update();
    }

    addDataset(dataset: ChartDataSetsGA) {
        this.chartInstance.chart.data.datasets.push(dataset);
        this.chartInstance.chart.update();
    }

    removeDataset(label = '') {
        if (label) {
            const index = this.chartInstance.chart.data.datasets.findIndex(x => x.label === label);
            if (index > -1) {
                this.chartInstance.chart.data.datasets.splice(index, 1);
                this.chartInstance.chart.update();
            }
        }
    }

    toggleDataset(label = '', hide: true | false | null = null) {
        if (label) {
            const dataset = this.chartInstance.chart.data.datasets.find(x => x.label === label);
            if (dataset) {
                if (hide !== null) {
                    dataset.hidden = hide;
                } else {
                    if (dataset.hasOwnProperty('hidden')) {
                        dataset.hidden = !dataset.hidden;
                    } else {
                        dataset.hidden = true;
                    }
                }
                this.chartInstance.chart.update();
            }
        }
    }

    toggleScale(axis: 'x' | 'y' = 'y', id: string = null, hide: true | false | null = null) {
        if (!['x', 'y'].includes(axis) || !id) {
            return;
        }
        const scaleAxe = this.chartInstance.chart.config.options.scales[`${axis}Axes`];
        if (scaleAxe && scaleAxe.length) {
            const searchAxis = scaleAxe.find(x => x.id === id);
            if (searchAxis) {
                if (hide !== null) {
                    searchAxis.display = !hide;
                } else {
                    searchAxis.display = !searchAxis.display;
                }
                this.chartInstance.chart.update();
            }
        }
    }

    replaceDatasets(datasets: ChartDataSetsGA[]) {
        this.chartInstance.chart.data.datasets = [];
        this.chartInstance.chart.data.datasets.push(...datasets);
        this.chartInstance.chart.update();
    }

    /**
     * Replace labels and update
     * @param {string[]} labels - labels to replace
     */
    replaceLabels(labels: string[]) {
        this.chartInstance.chart.data.labels = [];
        this.chartInstance.chart.data.labels.push(...labels);
        this.chartInstance.chart.update();
    }

    updateOptions(options: ChartOptionsGA) {
        if (this.chartInstance.chart.config.options && options) {
            // Deep Object.assign, to override properties changed.
            _.merge(this.chartInstance.chart.config.options, options);
            // For axes, we change axes data if needed.
            this.chartInstance.chart.config.options.scales.xAxes.forEach(element => {
                const s = options.scales.xAxes.find(x => x.id === element.id);
                if (s) {
                    _.merge(element, s);
                }
            });
            this.chartInstance.chart.config.options.scales.yAxes.forEach((oldAxe, index) => {
                delete oldAxe.ticks.min;
                delete oldAxe.ticks.max;

                const newAxe = options.scales.yAxes.find(axe => axe.id === oldAxe.id);
                if (newAxe) {
                    _.merge(oldAxe, newAxe);
                } else {
                    // If new config has less y axes, hide the overflow
                    if (index + 1 > options.scales.yAxes.length) {
                        oldAxe.display = false;
                    }
                }
            });
        }
        this.chartInstance.chart.update();
    }
}
