import { Component, Input } from '@angular/core';

@Component({
    selector: 'ga-pdl-communicating-icon',
    templateUrl: './pdl-communicating-icon.component.html',
    styleUrls: ['./pdl-communicating-icon.component.scss'],
    providers: [],
})
export class PDLCommunicatingIconComponent {
    @Input() color: string;
    @Input() tooltip: string;
}
