import { Inject, Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

import { ANALYTICS_CONFIGURATION, AnalyticsConfiguration } from '../analytics-configuration';

import { loadHotjar } from './load-hotjar';

/**
 * Hotjar service
 */
@Injectable({
    providedIn: 'root',
})
export class HotjarService {
    private readonly hotjar$ =
        this.configuration.hotjarId !== undefined
            ? loadHotjar(document, this.configuration.hotjarId).pipe(
                  catchError(_e => {
                      return EMPTY;
                  }),
                  shareReplay()
              )
            : EMPTY;

    /**
     * Class constructor
     *
     * @param configuration Analytics configuration
     */
    constructor(@Inject(ANALYTICS_CONFIGURATION) private readonly configuration: AnalyticsConfiguration) {
        this.hotjar$.subscribe();
    }

    /**
     * Sets the user data for Hotjar to track
     *
     * @param userId User identifier
     * @param isCitron Whether the user is a Citron staff user
     */
    setUserIdentity(userId: string, isCitron: boolean): void {
        this.hotjar$.subscribe(hotjar =>
            hotjar('identify', userId, {
                is_citron: isCitron,
            })
        );
    }
}
