import { Component, OnInit } from '@angular/core';
import { ExternalDataService } from '../../../../../../services/external-data/external-data.service';

interface ListItem {
    name: string;
    value: string;
}

@Component({
    selector: 'ga-loadcurve-assignation-ga',
    templateUrl: './assignation-greenanalyzer.component.html',
    styleUrls: ['./assignation-greenanalyzer.component.scss'],
    providers: [],
})
export class LoadCurveAssignationGAComponent implements OnInit {
    clients: ListItem[] = [];
    sites: ListItem[] = [];
    projects: ListItem[] = [];

    projectsGA: any = [];

    draggedData: any = null;

    loadingProjects = false;

    constructor(private externalDataService: ExternalDataService) {}

    ngOnInit() {
        this.externalDataService
            .getGAClients()
            .then(response => {
                const clients = response.data;
                this.clients = this.listMapSort(clients);
            })
            .catch(() => {
                this.projectsGA = [];
                this.projects = [];
                this.sites = [];
                this.clients = [];
            });
    }

    /**
     * Set selected client
     * @param {string} value - client id from GA
     */
    selectClient(value: string) {
        this.projectsGA = [];
        this.projects = [];
        this.sites = [];

        if (!value) {
            return;
        }

        this.externalDataService
            .getGASites(value)
            .then(response => {
                const sites = response.data;
                this.sites = this.listMapSort(sites);
            })
            .catch(() => {
                this.projectsGA = [];
                this.projects = [];
                this.sites = [];
            });
    }

    /**
     * Set selected site
     * @param {string} value - site id from GA
     */
    selectSite(value: string) {
        this.projectsGA = [];
        this.projects = [];

        if (!value) {
            return;
        }
        this.externalDataService
            .getGAProjects(value)
            .then(response => {
                const projects = response.data;
                this.projects = this.listMapSort(projects);
            })
            .catch(() => {
                this.projectsGA = [];
                this.projects = [];
            });
    }

    /**
     * Set selected project
     * @param {string} value - project id from GA
     */
    selectProject(value: string) {
        this.projectsGA = [];
        if (!value) {
            return;
        }
        this.loadingProjects = true;
        this.externalDataService
            .getGAProject(value)
            .then(response => {
                this.projectsGA.push(response.data);
                this.loadingProjects = false;
            })
            .catch(() => {
                this.projectsGA = [];
                this.loadingProjects = false;
            });
    }

    /**
     * Handle lists from GA, as they're all with the same format ([{name, _id}]).
     * Create objects for a list and sort it by name alphabetically
     * @param list - List of items
     * @returns {Array<ListItem>} list of simple items ready to be used by a selector
     */
    private listMapSort(list: any[]): ListItem[] {
        return list
            .map(item => {
                return {
                    name: item.name,
                    value: item._id,
                };
            })
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
    }

    /*********
     * DRAG AND DROP
     */

    onDragStart(event: DragEvent, data) {
        // TODO Set transfer to an external data
        const externalData = {
            externalId: data._id,
            type: 'ga',
        };
        const toTransfer = JSON.stringify(externalData);
        event.dataTransfer.setData('text/plain', toTransfer);
        event.dataTransfer.effectAllowed = 'copy';
    }
}
