// tslint:disable:no-access-missing-member
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuestionComponent } from '../question/question.component';

@Component({
    selector: 'ga-question-select-dropdown',
    templateUrl: './select-dropdown.component.html',
    styleUrls: ['./select-dropdown.component.scss'],
})
/**
 * Component to create an select question with single or multiple choice.
 * @Input data : User data at the format [{key: string, value: string}]
 * @Input config : Question from the config file with properties attribute at the format {key: string, multiple: boolean, values: [{label: string, value: string}}
 * @Ouput valueChange : event that gives the data each time it's changed by the user at the format {key: string, value: string}
 */
export class SelectDropdownComponent extends QuestionComponent implements OnInit {
    questionForm: FormGroup;

    // Value from the database. Will needed to be set in ngOnInit with data
    value: string[] = [];

    constructor() {
        super();
    }

    ngOnInit() {
        // Get value from db
        const dbValue = this.data.find(x => x.key === this.config.properties.key);

        this.value = dbValue && dbValue.value ? dbValue.value.split(',') : [];
        // If only one value allowed, just keep the first one.
        if (this.value.length > 1 && !this.multiple) {
            this.value.splice(1);
        }
        this.questionForm = new FormGroup({
            question: new FormControl(this.noValue ? null : this.value),
        });
        this.onChanges();
        this.updateValues();
    }

    onChanges() {
        this.questionForm.valueChanges.subscribe(val => {
            if (Array.isArray(val.question)) {
                this.value = val.question;
            } else {
                this.value = [];
                this.value.push(val.question);
            }
            this.updateValues();
        });
    }

    private updateValues() {
        const empty = this.value.length === 0;
        this.valueChange.emit({
            key: this.config.properties.key,
            value: !empty ? this.value.join(',') : null,
        });
    }

    get multiple() {
        return this.config.properties.multiple;
    }

    get noValue() {
        return this.value.length === 0;
    }
}
