import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ga-pdl-data-table-site',
    templateUrl: './pdl-data-table-site.component.html',
    styleUrls: ['./pdl-data-table-site.component.scss'],
    providers: [],
})
export class PdlDataTableSiteComponent implements OnInit {
    @Input() pdls: object;
    @Input() type: string;
    @Input() currentSelection: any;
    @Input() site: any;
    @Input() filter = '';

    constructor() {}
    ngOnInit() {}

    getPdlRouterLink(pdl): string {
        switch (pdl.contract.energyType) {
            case 'elec':
                return `/${this.type}/pdl/${this.site._id}/${pdl.contract._id}/${pdl.routingReference}`;
            case 'gaz':
                return `/${this.type}/pdl/gaz/${this.site._id}/${pdl.contract._id}/${pdl.routingReference}`;
            case 'water':
                return `/${this.type}/pdl/${this.site._id}/${pdl.contract._id}/${pdl.routingReference}`;
        }
    }

    filterEnergyType(pdl): boolean {
        if (this.filter === 'water') {
            return pdl.contract.energyType === 'water';
        }

        return pdl.contract.energyType !== 'water';
    }
}
