import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ContractDisplay } from 'app/pages/contracts/contracts.interface';
import { ModalComponent } from 'app/shared/components/common/modal/modal.component';
import { PaginationBackService } from 'app/shared/components/pagination/back/pagination-back.service';
import { ContractLight } from 'app/shared/models/contract.interface';
import { FormatDatePipe } from 'app/shared/pipes/format-date.pipe';

@Component({
    selector: 'ga-contracts-table',
    templateUrl: './contracts-table.component.html',
    styleUrls: ['./contracts-table.component.scss'],
    providers: [],
})
export class ContractsTableComponent implements OnInit {
    @Input() contracts: ContractDisplay[] = [];

    /**
     * Event emitted when the table is sorted
     */
    @Output() sortChanged: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Event emitted when the contract has been successfully edited
     */
    @Output() contractEdited: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Creation/Edit modal
     */
    @ViewChild(ModalComponent, { static: true }) private modal: ModalComponent;

    /**
     * Contract being edited
     */
    contractEditing: ContractLight = null;

    /**
     * Set a dynamic header when sorting on the period column :
     * ASC : display contracts by ascending dateStart (infos.dateStart)
     * DESC : display contracts by descending dateEnd (infos.dateEnd)
     */
    public periodHeader = 'infos.dateStart';

    constructor(private paginationService: PaginationBackService, private datePipe: FormatDatePipe) {}

    ngOnInit(): void {}

    openModal(contract: ContractDisplay) {
        this.contractEditing = contract;
        this.modal.show();
    }

    get isModalShown() {
        return this.modal.isShown;
    }

    /**
     * Update the sort caret class according to the user action.
     * @param {string} header
     * @returns {string[]} css classes
     */
    getClass(header: string): string[] {
        return this.paginationService.getClassesSort(header);
    }

    /**
     * Handle sort click to update the contracts list accordingly.
     * @param {string} header column header selected for sort
     */
    onSort(header: string): void {
        this.paginationService.onSort(header);
        if (header === this.periodHeader) {
            // Handle click on the period column and set the header depending on the paginationService.sort.asc
            this.paginationService.sort.field = this.paginationService.sort.asc ? 'infos.dateStart' : 'infos.dateEnd';
            this.periodHeader = this.paginationService.sort.field;
        }
        this.sortChanged.emit();
    }

    /**
     * Get contract start date or return 'NC' if no date
     * @param {ContractDisplay} contract
     * @returns {string}
     */
    getContractStartDate(contract: ContractDisplay): string {
        return contract.infos.dateStart ? this.datePipe.transform(contract.infos.dateStart) : 'NC';
    }

    /**
     * Get contract end date or return 'NC' if no date
     * @param {ContractDisplay} contract
     * @returns {string}
     */
    getContractEndDate(contract: ContractDisplay): string {
        return contract.infos.dateEnd ? this.datePipe.transform(contract.infos.dateEnd) : 'NC';
    }

    public onContractSaved() {
        this.modal.close();
        this.contractEdited.emit();
    }

    public closeModal() {
        this.modal.close();
    }
}
