import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorService } from '../../../services/color/color.service';

@Component({
    selector: 'ga-highchart-sankey',
    templateUrl: './highchart-sankey.component.html',
    styleUrls: ['./highchart-sankey.component.scss'],
})
export class HighchartSankeyComponent implements OnInit {
    @Input() options: any;
    @Input() sankeyHighchartsData: any[];

    labels: Array<Array<{ id: string; value: number }>> = [[], []];
    totals = [];
    heights: number[] = [1400, 1400];

    colors = [];

    constructor(private colorService: ColorService) {}

    ngOnInit() {
        this.initLabels();
        this.colors = [
            this.colorService.getHexColor('DARK_BLUE1'),
            this.colorService.getHexColor('LIGHT_BLUE2'),
            this.colorService.getHexColor('TURQUOISE'),
            this.colorService.getHexColor('DARK_BLUE2'),
        ];
    }

    public initLabels() {
        $(() => {
            this.labels = [[], []];
            this.totals = [0, 0];
            this.sankeyHighchartsData[0].forEach(item => {
                const val = this.labels[0].find(x => x.id === item[0]);
                if (!val) {
                    this.labels[0].push({ id: item[0], value: item[2] });
                } else {
                    val.value += item[2];
                }
                this.totals[0] += item[2];
            });
            this.sankeyHighchartsData[this.sankeyHighchartsData.length - 1].forEach(item => {
                const val = this.labels[1].find(x => x.id === item[1]);
                if (!val) {
                    this.labels[1].push({ id: item[1], value: item[2] });
                } else {
                    val.value += item[2];
                }
                this.totals[1] += item[2];
            });
            // Compute height of the left column
            const col1 = $("[fill='#104E8B']");
            if (col1.length > 0) {
                this.heights[0] = (col1.length - 1) * 15;
                for (const c of col1 as any) {
                    this.heights[0] += Number($(c).attr('height'));
                }
            }

            // Compute height of the right column
            const color = this.colors[this.sankeyHighchartsData.length];
            const query = "[fill='" + color + "']";

            const col2 = $(query);
            if (col2.length > 0) {
                this.heights[1] = (col2.length - 1) * 15;
                for (const c of col2 as any) {
                    this.heights[1] += Number($(c).attr('height'));
                }
            }
        });
    }

    public getLabels(column: number) {
        return this.labels[column];
    }

    public getHeight(item, column) {
        if (item.value > 0) {
            const rest = this.heights[column] - this.getLabels(column).length * 15;
            return rest * (item.value / this.totals[column]);
        } else {
            return 0;
        }
    }

    public getShort(text) {
        const res = text.length > 20 ? '...' : '';
        return text.slice(0, 20) + res;
    }

    public hasData() {
        if (this.sankeyHighchartsData && this.sankeyHighchartsData.length > 0) {
            return this.sankeyHighchartsData[0].length > 0;
        } else {
            return false;
        }
    }
}
