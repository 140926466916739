import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'pdlfilter',
    pure: false,
})
export class PdlFilterPipe implements PipeTransform {
    transform(items: any[], searchText?: any): any {
        if (!items) {
            return [];
        }

        if (!searchText) {
            return items;
        }

        return items.filter(item => {
            return (
                item.routingReference.reference.toLowerCase().includes(searchText.toLowerCase()) ||
                (item.routingReference.address.name
                    ? item.routingReference.address.name.toLowerCase().includes(searchText.toLowerCase())
                    : null) ||
                (item.routingReference.address.streetName
                    ? item.routingReference.address.streetName.toLowerCase().includes(searchText.toLowerCase())
                    : null)
            );
        });
    }
}
