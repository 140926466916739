export const UploadErrorList = {
    MISSING_GEOLOC: 'missing_geoloc',
    MISSING_DATE: 'missing_dates',
    ALREADY_UPLOADED: 'file_already_uploaded',
    MISSING_UPLOAD_GROUP: 'missing_upload_group',
    PDF_TEXT_EXTRACTION_IMPOSSIBLE: 'pdf_text_extraction_failed',
    SAVING_VALIDATION: 'saving_validation_error',
    SAVING_UNKNOWN: 'saving_unknown_error',
    PRODUCT_NOT_FOUND: 'product_not_found',
    ALREADY_EXTRACTING: 'upload_already_extracting',
    QUALITY_CHECK_FAILED: 'quality_check_failed',
    EMPTY_FILE: 'empty_file',
    REGEX_TIMEOUT: 'regex_timeout',
};
