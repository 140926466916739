import { Component, OnInit } from '@angular/core';
import { ContractPricing, ContractPricingItem } from 'app/shared/models/contract-pricing.interface';
import {
    ContractPopulated,
    PricingSelected,
    PricingSelectedValue,
    RoutingRefContract,
} from 'app/shared/models/contract.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import * as _ from 'lodash';
import { PricingOption, PricingSection } from '../contract-edition.interface';
import { ContractEditionService } from '../contract-edition.service';

@Component({
    selector: 'contract-edition-pdl-pricings',
    templateUrl: './pdl-pricings.component.html',
    styleUrls: ['./pdl-pricings.component.scss'],
})
export class PdlPricingsComponent implements OnInit {
    /**
     * Contract to edit
     */
    public get contract(): ContractPopulated {
        return this.contractEditionService.contract;
    }

    /**
     * Available pricings description for the contract
     */
    public get pricings(): ContractPricing[] {
        return this.contractEditionService.pricings;
    }

    /**
     * Pricing items related to the pricings
     */
    public get pricingItems(): ContractPricingItem[] {
        return this.contractEditionService.pricingItems;
    }

    /**
     * Get each pricing selected in the pricing section
     */
    public get pricingOptions(): PricingOption[] {
        return this.contractEditionService.selectedPricingOptions;
    }

    public pricingList: PricingSection[] = [];
    public expandedIndex: number;
    public selectedRoutingReferences: Array<RoutingRefContract<RoutingReference>> = [];
    public searchText: string;

    public items: ContractPricing[] = [];

    public routingReferenceList: Array<RoutingRefContract<RoutingReference>> = [];

    constructor(private contractEditionService: ContractEditionService) {}

    ngOnInit() {
        this.expandedIndex = -1;

        this.pricings.forEach(pricing => {
            this.flattenDeep(pricing);
        });

        this.setAvailableRoutingReferencesList();

        /**
         * Subscribe to pricing options change
         */
        this.contractEditionService.pricingOptionsSubject.subscribe(pricingOptions => {
            this.updatePricingList(pricingOptions);
        });
    }

    /**
     * Update pricing list with new pricing options
     *
     * @param {PricingOption[]} pricingOptions
     */
    private updatePricingList(pricingOptions: PricingOption[]) {
        this.pricingList = pricingOptions.map(pricingOption => {
            const pricingItems = this.getContractPricingItems(pricingOption);
            return {
                label: pricingOption.label,
                routingReferences: [],
                pricingItems,
            };
        });

        this.assignSelectedRoutingReferences();
    }

    /**
     * Collapse pricing section
     *
     * @param {number} index
     */
    collapse(index: number) {
        this.expandedIndex = index === this.expandedIndex ? -1 : index;
    }

    /**
     * Construct the list of all available PDL
     */
    setAvailableRoutingReferencesList() {
        for (const rf of this.contract.routingReferences) {
            const routingReference = rf;
            this.computedRoutingReferencePrices(routingReference);
            this.routingReferenceList.push(routingReference);
        }
    }

    /**
     * compute all pricing values of a routingReference with the contract unitRate
     * @param {RoutingRefContract<RoutingReference>} routingReference
     */
    computedRoutingReferencePrices(routingReference: RoutingRefContract<RoutingReference>) {
        const unitRate: number = _.get(this.contract, 'infos.unitRate');
        const pricingValues: PricingSelectedValue[] = _.get(routingReference, 'pricing.values', []);
        if (pricingValues) {
            pricingValues.forEach(pv => {
                if (unitRate) {
                    pv.value = this.contractEditionService.getPricingItemValue(pv, 1 / unitRate);
                }
            });
        }
    }

    /**
     * Select one or many PDL and push it in an array
     *
     * @param {RoutingRefContract<RoutingReference>} routingReference
     * @param {boolean} isChecked
     */
    onSelectRoutingReference(routingReference: RoutingRefContract<RoutingReference>, isChecked: boolean) {
        if (isChecked) {
            // Add each selected PDL in a selected PDL array
            this.selectedRoutingReferences.push(routingReference);
        } else {
            // Remove each PDL from the selected array
            const index = this.selectedRoutingReferences.indexOf(routingReference);
            this.selectedRoutingReferences.splice(index, 1);
        }
    }

    /**
     * Assign one or many PDL in the pricing section
     *
     * @param {PricingSection} pricingSection
     */
    addRoutingReferences(pricingSection: PricingSection) {
        // Add all the checked PDL into the routingReferences array of the pricing section
        this.selectedRoutingReferences.forEach((routingReference: RoutingRefContract<RoutingReference>) => {
            // Before adding check if the selected PDL is not yet added
            if (
                pricingSection.routingReferences.findIndex(
                    rRef =>
                        rRef.routingReference &&
                        routingReference.routingReference &&
                        rRef.routingReference._id === routingReference.routingReference._id
                ) === -1
            ) {
                pricingSection.routingReferences.push(routingReference);

                // Remove the selected PDL from the routing references list
                const index = this.routingReferenceList.findIndex(
                    rRef =>
                        rRef.routingReference &&
                        routingReference.routingReference &&
                        rRef.routingReference._id === routingReference.routingReference._id
                );
                this.routingReferenceList.splice(index, 1);

                // Update the pathContractPricing property for the routing reference of the contract
                this.contractEditionService.setRoutingReferencePricing(routingReference, pricingSection);

                this.onRoutingReferencePricingsChange();
            }
        });

        // Remove everything in the selected array after adding the PDl in the pricing section
        this.selectedRoutingReferences = [];
    }

    /**
     * Remove the assignation of a PDL for a pricing section
     *
     * @param {RoutingRefContract<RoutingReference>} routingReference
     * @param {PricingSection} pricingSection
     */
    unSelectRoutingReference(routingReference: RoutingRefContract<RoutingReference>, pricingSection: PricingSection) {
        // Remove the PDL from the pricing section
        const index = pricingSection.routingReferences.indexOf(routingReference);

        if (index !== -1) {
            pricingSection.routingReferences.splice(index, 1);

            // Add the PDl in the routing references array
            this.routingReferenceList.unshift(routingReference);

            // Find the right routing reference in the contract and set its pricing at {}
            for (const contractRoutingReference of this.contract.routingReferences) {
                if (
                    contractRoutingReference.routingReference.reference === routingReference.routingReference.reference
                ) {
                    contractRoutingReference.pricing = null;
                    this.onRoutingReferencePricingsChange();
                }
            }
        }
    }

    /**
     * Recursively flatten contract pricing to create pricing option.
     * Objective is to have all possible options.
     *
     * @param {ContractPricing} pricing - contract pricing at any depth
     */
    private flattenDeep(pricing: ContractPricing) {
        if (pricing.contractData && pricing.contractData.custom && pricing.contractData.custom.length) {
            this.items.push(pricing);
        }

        if (pricing.items && pricing.items.length) {
            pricing.items.forEach(item => {
                this.flattenDeep(item);
            });
        }
    }

    /**
     * Add each existing routing reference of the current contract to the right pricing section
     */
    private assignSelectedRoutingReferences() {
        for (const contractRoutingReference of this.contract.routingReferences) {
            const pathContractPricing: string[] = _.get(contractRoutingReference, 'pricing.pathContractPricing');
            if (pathContractPricing) {
                let isAssignedRrefPricing = false;

                for (const pricingOption of this.pricingOptions) {
                    // For each added pricing sections, get the string ID path in the form of an array
                    const pricingOptionPathIDs = pricingOption.id.split('-');

                    // Compare each pricing section ID path with each routing reference pricing path
                    if (_.isEqual(pricingOptionPathIDs, pathContractPricing)) {
                        // If the ID paths matches, get the right section and push the routing reference in it
                        const section = _.find(this.pricingList, list => {
                            return list.label === pricingOption.label;
                        });
                        section.routingReferences.push(contractRoutingReference);

                        isAssignedRrefPricing = true;
                    }
                }

                const routingReferencesList = this.routingReferenceList;
                const index = _.findIndex(routingReferencesList, r => {
                    return r.routingReference.reference === contractRoutingReference.routingReference.reference;
                });

                // When the rRef is not assigned to a contract pricing option, it must be in the rRef select list
                if (!isAssignedRrefPricing && index === -1) {
                    routingReferencesList.unshift(contractRoutingReference);
                } else if (isAssignedRrefPricing && index !== -1) {
                    // Then we remove these routing references from the list of all routing references
                    routingReferencesList.splice(index, 1);
                }
            }
        }
    }

    /**
     * Get the value of each routing reference pricing
     *
     * @param {RoutingRefContract<RoutingReference>} routingReference
     * @param {ContractPricingItem} item
     *
     * @returns {string}
     */
    getRoutingReferencePricingValue(
        routingReference: RoutingRefContract<RoutingReference>,
        item: ContractPricingItem
    ): string {
        if (routingReference.pricing && routingReference.pricing.values) {
            const search = routingReference.pricing.values.find(rf => rf.id === item._id);

            if (search && typeof search.value !== 'undefined') {
                return search.value;
            }
            return null;
        } else {
            return null;
        }
    }

    /**
     * Set the changes for each pricing input value
     *
     * @param {RoutingRefContract<RoutingReference>} routingReference
     * @param {ContractPricingItem} item
     * @param {string} value
     */
    setRoutingReferencePricingValue(
        routingReference: RoutingRefContract<RoutingReference>,
        item: ContractPricingItem,
        value: string
    ) {
        // Check if the pricing item exist in the the routing reference pricing.values array
        const pricingValue = _.find(routingReference.pricing.values, v => {
            return v.id === item._id;
        });
        if (value === '') {
            value = null;
        }
        if (pricingValue) {
            // If the value exists, update it
            pricingValue.value = value;
        } else {
            // If the value doesn't exist, update it
            routingReference.pricing.values.push({ id: item._id, value });
        }

        this.onRoutingReferencePricingsChange();
    }

    onRoutingReferencePricingsChange() {
        this.contractEditionService.updateRoutingReferences(this.contract.routingReferences);
    }

    /**
     * Get contract pricing items for a given pricing option
     * @param {pricingOption} pricingOption
     * @returns {ContractPricingItem[]}
     */
    private getContractPricingItems(pricingOption: PricingOption): ContractPricingItem[] {
        const pricingItemIds: string[] = [];
        // Get path ids
        const pathIds = pricingOption.id.split('-');
        // Get root pricing
        const rootId = pathIds.shift();
        let item = this.pricings.find(x => x._id === rootId);
        // Get root custom pricing items
        const customItems: string[] = _.get(item, 'contractData.custom', []);
        pricingItemIds.push(...customItems);
        // Iterate over child paths to get each level's custom pricing items
        pathIds.forEach(pathId => {
            item = item.items.find(x => x._id === pathId);
            const childCustomItems: string[] = _.get(item, 'contractData.custom', []);
            pricingItemIds.push(...childCustomItems);
        });
        return pricingItemIds.map(x => this.pricingItems.find(y => y._id === x));
    }
}
