import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/services/api/api.service';
import { EnergyService } from '../../../shared/services/energy/energy.service';
import { PageService } from '../../../shared/services/page/page.service';

@Injectable()
export class RankingService extends PageService {
    constructor(public apiService: ApiService, private energyService: EnergyService) {
        super(apiService);
        this.fake = false;
    }

    getConsumption(filters) {
        return this.post('/api/common/consumption', filters);
    }

    /**
     * Format consumption data (PDL/Site) to consumption data per site.
     * @param {Array<any>} data - consumption data. ID is the pair routing reference / site
     * @param {Array<string>} fluids - list of fluids to accept
     * @returns {Array<any>} list of sites with consumption object with one property per fluid with properties totalHT, totalTTC & quantity
     */
    formatConsumptionDataPerSite(data: any[], fluids: string[]): any[] {
        // Aggregate site data
        // Not using an array as find is slow on big lists
        // Instead we use an object where each key is a site id.
        const sites = {};
        data.forEach(sitePdl => {
            let site = sites[sitePdl._id.siteId];
            // If site not in list, init simple site object with conso and name
            if (!site) {
                const initConso = {};
                fluids.forEach(fluid => {
                    initConso[fluid] = {};
                    initConso[fluid].totalHT = 0;
                    initConso[fluid].totalTTC = 0;
                    initConso[fluid].quantity = 0;
                });
                sites[sitePdl._id.siteId] = {
                    _id: sitePdl._id.siteId,
                    name: sitePdl.name,
                    conso: initConso,
                };
                site = sites[sitePdl._id.siteId];
            }
            // Get site conso
            // If site is multi zone, check the conso of each zone and add it to the global of the site
            if (sitePdl.info.diviser) {
                sitePdl.batiments.forEach(zone => {
                    if (zone.routingReferencesConso) {
                        zone.routingReferencesConso.forEach(routingReferenceConso => {
                            const conso = routingReferenceConso.conso;
                            if (conso) {
                                fluids.forEach(fluid => {
                                    if (site.conso[fluid] && conso[fluid]) {
                                        site.conso[fluid].totalHT += conso[fluid].totalHT;
                                        site.conso[fluid].totalTTC += conso[fluid].totalTTC;
                                        site.conso[fluid].quantity += conso[fluid].quantity;
                                    }
                                });
                            }
                        });
                    }
                });
            } else {
                const conso = sitePdl.conso;
                if (conso) {
                    fluids.forEach(fluid => {
                        if (site.conso[fluid] && conso[fluid]) {
                            site.conso[fluid].totalHT += conso[fluid].totalHT;
                            site.conso[fluid].totalTTC += conso[fluid].totalTTC;
                            site.conso[fluid].quantity += conso[fluid].quantity;
                        }
                    });
                }
            }
        });
        // Tranform object into array, create a total property and convert fluids if needed.
        const sitesArray = Object.keys(sites).map(key => {
            const site = sites[key];
            const total = {
                totalHT: 0,
                totalTTC: 0,
                quantity: 0,
            };
            Object.keys(site.conso).forEach(fluid => {
                if (fluids.length > 1) {
                    site.conso[fluid].quantity = this.energyService.convertEnergyKwhToKwhep(
                        fluid,
                        site.conso[fluid].quantity
                    );
                }
                total.quantity += site.conso[fluid].quantity;
                total.totalHT += site.conso[fluid].totalHT;
                total.totalTTC += site.conso[fluid].totalTTC;
            });
            site.conso.total = total;
            return site;
        });
        return sitesArray;
    }

    formatSitesConsumptionPerDJU(sites, sitesDJUs) {
        return sites.map(site => {
            const dju = sitesDJUs.find(x => x.site === site._id);
            let djuValue = null;
            if (dju && dju.hot) {
                djuValue = dju.hot;
            }
            for (const item in site.conso) {
                if (site.conso[item] && djuValue) {
                    site.conso[item].quantityDju = site.conso[item].quantity / djuValue;
                } else {
                    site.conso[item].quantityDju = null;
                }
            }
            return site;
        });
    }

    fakeData(route) {
        let response;

        switch (route) {
            default:
                response = [];
                break;
        }

        return response;
    }
}
