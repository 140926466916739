import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { Campaign } from 'app/shared/models/campaign.interface';
import { UsersService } from 'app/shared/services/users/users.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-campaign-overview',
    templateUrl: './campaign-overview.component.html',
    styleUrls: ['./campaign-overview.component.scss'],
    providers: [],
})
export class CampaignOverviewComponent implements OnInit {
    @Input() campaign: Campaign;

    /**
     * Event emitter to ask for campaign delete.
     * Value passed is the campaign id
     */
    @Output() deleteCampaign: EventEmitter<string> = new EventEmitter<string>();

    constructor(private usersService: UsersService) {}

    ngOnInit() {}

    get campaignDates(): string {
        if (!this.campaign) {
            return 'NC';
        }
        const start = moment(this.campaign.campaignStart);
        const end = moment(this.campaign.campaignEnd);
        return `De ${start.format('MMMM YYYY')} à ${end.format('MMMM YYYY')}`;
    }

    get campaignReferenceDates(): string {
        if (!this.campaign) {
            return 'NC';
        }
        const start = moment(this.campaign.referencePeriod.dateStart);
        const end = moment(this.campaign.referencePeriod.dateEnd);
        return `De ${start.format('MMMM YYYY')} à ${end.format('MMMM YYYY')}`;
    }

    get campaignHasMultipleFluids(): boolean {
        if (!this.campaign || !this.campaign.scope || !this.campaign.scope.energyTypes) {
            return false;
        }
        return this.campaign.scope.energyTypes.length > 1;
    }

    /**
     * Open confirmation modal to delete campaign.
     * If confirmed, emit delete event with campaign id.
     */
    async confirmCampaignDelete(): Promise<void> {
        const result = await Swal.fire({
            title: 'Êtes-vous sûr(e) ?',
            text: 'Une fois supprimée, la campagne ne peut plus être récupérée!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Annuler',
        });
        if (result.value) {
            this.deleteCampaign.emit(this.campaign._id);
        }
    }

    get displayDeleteButton(): boolean {
        return this.usersService.isSessionUserAtLeast(UserTypes.USER_ADMIN);
    }
}
