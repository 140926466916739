import { Injectable } from '@angular/core';
import {
    ItemVerification,
    VerificationConfig,
    VerificationElement,
} from 'app/shared/models/bill-verifications.interface';
import { BillVerification } from 'app/shared/models/bills.interface';
import { UtilsService } from '../utils/utils.service';

@Injectable()
export class BillsVerificationsService {
    constructor(public utilsService: UtilsService) {}

    /**
     * Configuration for each bill verification key : levels indicators definition.
     *
     * key is the verification key
     * level is the corresponding error level (bad, medium, good)
     * validate is a function return true if the indicator matches the verification
     * priority is the importance of the criteria. The higher the more important a criteria is.
     */
    private readonly config: {
        [key: string]: VerificationConfig;
    } = {
        elec_quantity_cspe: {
            message: "Contrôle de l'extraction des consommations",
            items: this.qtyCspeItems,
            elements: (v: BillVerification) => this.getQtyExtractionElements(v, 'CSPE'),
            valueMsg: (v: BillVerification): string => this.getQtyExtractionValue(v, 'CSPE'),
            key: 'elec_quantity_cspe',
        },
        elec_amount_htva: {
            message: "Contrôle de la complétude d'extraction",
            elements: () => [],
            items: this.amountHtvaItems,
            valueMsg: (v: BillVerification): string => this.getAmountHtvaValue(v),
            key: 'elec_amount_htva',
        },
        gaz_ticgn_quantity: {
            message: "Contrôle de l'extraction des consommations",
            items: this.qtyCspeItems,
            elements: (v: BillVerification) => this.getQtyExtractionElements(v, 'TICGN'),
            valueMsg: (v: BillVerification): string => this.getQtyExtractionValue(v, 'TICGN'),
            key: 'gaz_ticgn_quantity',
        },
        gaz_htva_amount: {
            message: "Contrôle de la complétude d'extraction",
            elements: () => [],
            items: this.amountHtvaItems,
            valueMsg: (v: BillVerification): string => this.getAmountHtvaValue(v),
            key: 'gaz_htva_amount',
        },
    };

    /**
     * An array of extraction verification keys grouped by category
     *
     * group is the category name related to each key
     */
    private readonly groupedVerificationConfigKeys: { [group: string]: string[] } = {
        elec: ['elec_quantity_cspe', 'elec_amount_htva'],
        gaz: ['gaz_ticgn_quantity', 'gaz_htva_amount'],
    };

    /**
     * Returns the configuration for the given verification
     * @param {string} key - verification key
     * @returns {VerificationConfig} verification configuration
     */
    public getConfigByKey(key: string): VerificationConfig {
        return this.config[key];
    }

    /**
     * Returns the verifications configs grouped by category
     * @returns {{ [group: string]: VerificationConfig[] }} arrays of verification configs grouped by category
     */
    public getGroupedVerificationConfigs(): { [group: string]: VerificationConfig[] } {
        const groupedVerificationConfigs = {};

        Object.keys(this.groupedVerificationConfigKeys).forEach(group => {
            groupedVerificationConfigs[group] = this.groupedVerificationConfigKeys[group].map(configKey => {
                return this.getConfigByKey(configKey);
            });
        });

        return groupedVerificationConfigs;
    }

    /**
     * ---- Verification of the CPSE quantity ----
     */

    /**
     * Returns the evaluation process to identify the priority / level of a bill
     */
    private get qtyCspeItems(): ItemVerification[] {
        return [
            // Level 0 with priority 0 is the default one, for any reason, if no one match, display the verification as grey.
            // Otherwise, it won't display verification at all
            { level: 0, validate: (v: BillVerification): boolean => true, priority: 0 },
            {
                level: 1,
                validate: (v: BillVerification): boolean =>
                    v.isValid === true && !(typeof v.expectedValue !== 'number' && typeof v.noticedValue !== 'number'), // set grey for valid but with all values null
                priority: 1,
            },
            { level: 3, validate: (v: BillVerification): boolean => v.isValid === false, priority: 2 },
        ];
    }

    /**
     * Returns elements explaining the quantity extraction verification value
     * @param {BillVerification} v
     * @param {string} indicator
     * @returns {VerificationElement[]}
     */
    private getQtyExtractionElements(v: BillVerification, indicator: string): VerificationElement[] {
        return [
            { key: 'Consommation', value: typeof v.noticedValue === 'number' ? v.noticedValue : 'NC' },
            { key: indicator, value: typeof v.expectedValue === 'number' ? v.expectedValue : 'NC' },
        ];
    }

    /**
     * Returns the result to display depending on the quantity extraction verification ratio & values
     * @param {BillVerification} v
     * @param {string} indicatorName - display text of extraction comparison indicator (eg. 'CSPE', 'TICGN', ...)
     * @returns {string} value to display
     */
    private getQtyExtractionValue(v: BillVerification, indicatorName: string): string {
        const indicatorValue = v.expectedValue;
        const conso = v.noticedValue;
        const isConsoExtracted = typeof conso === 'number';
        const isIndicatorExtracted = typeof indicatorValue === 'number';

        /**
         * display 100%
         * indicator = conso (not null)
         * cspe null & indicator ≠ 0
         * conso null & indicator ≠ 0
         */
        if (v.isValid && (isConsoExtracted || isIndicatorExtracted)) {
            v.ratio = 100;
        }

        // when indicator = conso => display 100% in green
        if (v.ratio === 0) {
            v.ratio = 100;
        }

        // when indicator ≠ 0 & conso null => display 0%
        if (isIndicatorExtracted && indicatorValue !== 0 && !isConsoExtracted) {
            v.ratio = 0;
        }

        if (typeof v.ratio === 'number') {
            return `${v.ratio} %`;
        }

        // when indicator = 0 & conso ≠ 0 => display error
        if (indicatorValue === 0 && isConsoExtracted && conso !== 0) {
            return `Consommation extraite pour une ${indicatorName} de 0 (incohérence)`;
        }

        return 'Vérification impossible';
    }

    /**
     *  ---- Verification of the HTVA Amount ----
     */

    /**
     * Returns the evaluation process to identify the priority / level of a bill
     */
    private get amountHtvaItems(): ItemVerification[] {
        return [
            { level: 0, validate: (v: BillVerification): boolean => true, priority: 0 },
            {
                level: 1,
                validate: (v: BillVerification): boolean =>
                    v.isValid === true || (typeof v.ratio === 'number' && v.ratio === 0),
                priority: 1,
            },
            {
                level: 2,
                validate: (v: BillVerification): boolean =>
                    v.isValid === false && typeof v.ratio === 'number' && v.ratio > 0 && v.ratio <= 80,
                priority: 1,
            },
            {
                level: 3,
                validate: (v: BillVerification): boolean =>
                    v.isValid === false && typeof v.ratio === 'number' && (v.ratio < 0 || v.ratio > 80),
                priority: 1,
            },
            {
                level: 3,
                validate: (v: BillVerification): boolean => v.isValid === false && typeof v.ratio !== 'number',
                priority: 1,
            },
        ];
    }

    /**
     * Returns the verification ratio to display
     * @param {BillVerification} v
     * @returns {string} value to display
     */
    private getAmountHtvaValue(v: BillVerification): string {
        return typeof v.ratio === 'number' ? this.utilsService.getNumberAndUnitToDisplay(v.ratio, '%') : '';
    }
}
