import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { ContractPricing, ContractPricingItem } from 'app/shared/models/contract-pricing.interface';
import { ContractPopulated, PricingSelected, PricingSelectedValue } from 'app/shared/models/contract.interface';
import { PricingOption } from '../contract-edition.interface';
import { ContractEditionService } from '../contract-edition.service';

@Component({
    selector: 'contract-edition-capacity-factor',
    templateUrl: './capacity-factor.component.html',
    styleUrls: ['./capacity-factor.component.scss'],
})
export class CapacityFactorComponent implements OnInit {
    /**
     * Pricing items related to the pricings
     */
    public pricingItems: ContractPricingItem[] = [];

    /**
     * Get each pricing selected in the pricing section
     */
    public get pricingOptions(): PricingOption[] {
        return this.contractEditionService.selectedPricingOptions;
    }

    public items: ContractPricing[] = [];

    constructor(private contractEditionService: ContractEditionService) {}

    ngOnInit() {
        this.pricingItems = this.contractEditionService.pricingItems;
        // Find pricing items with coefficient capacity group only
        this.pricingItems = _.filter(this.pricingItems, { group: 'coefficient_capacity' });
        this.pricingItems = _.sortBy(this.pricingItems, ['label']);
    }

    /**
     * @param {PricingOption} pricing
     * @param {ContractPricingItem} item
     * @returns {boolean} true if pricing option is concerned by the pricing item.
     */
    public hasContractPricingItem(pricing: PricingOption, item: ContractPricingItem): boolean {
        return pricing.items.some(x => x.id === item._id);
    }

    /**
     * Get the factor of a pricing item of a contract pricing
     * @param {PricingOption} pricing
     * @param {ContractPricingItem} item
     * @returns {string|number}
     */
    /**
     * Get the value of a pricing item of a contract pricing.
     * @param {PricingOption} pricing
     * @param {ContractPricingItem} item
     * @returns {string|number}
     */
    public getContractPricingValue(pricing: PricingOption, item: ContractPricingItem): string | number {
        const search = pricing.items.find(x => x.id === item._id);
        if (search && typeof search.value !== 'undefined') {
            return search.value;
        }
        return null;
    }

    /**
     * Set the value of a pricing item of a contract pricing.
     * @param {PricingOption} pricing
     * @param {ContractPricingItem} item
     * @param {string} value
     */
    public setContractPricingValue(pricing: PricingOption, item: ContractPricingItem, value: string) {
        const search = pricing.items.find(x => x.id === item._id);
        if (search && value) {
            search.value = value;
            this.onContractPricingsChange();
        }
    }

    /**
     * Create most recent version of contract pricings selected and emit to parent component.
     * No save done in this component.
     */
    private onContractPricingsChange() {
        const pricings: PricingSelected[] = this.pricingOptions.map(pricing => {
            /** Get values converted with rate choosen to set base unit for fluids (eg. for elec convert all to c€/kWh) */
            const values: PricingSelectedValue[] = pricing.items.map(item => {
                return {
                    id: item.id,
                    value: item.value,
                };
            });
            const obj: PricingSelected = {
                pathContractPricing: pricing.path,
                values,
            };
            return obj;
        });

        this.contractEditionService.updateCommonPricing(pricings);
    }
}
