import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[appOffClick]',
})
export class OffClickDirective {
    @Output() offClick = new EventEmitter();

    @HostBinding('class.open') isOpen: boolean;

    constructor(private elemRef: ElementRef) {}

    @HostListener('document:click', ['$event'])
    @HostListener('document:touchstart', ['$event'])
    toggle(event) {
        if (!this.elemRef.nativeElement.contains(event.target)) {
            this.offClick.emit(true);
        }
    }
}
