import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

// services & pipes
import { BillsService } from 'app/shared/services/bills/bills.service';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { DjuService } from 'app/shared/services/dju/dju.service';
import { EnergyDataService } from 'app/shared/services/energy/energy-data.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { MapService } from 'app/shared/services/map/map.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TilesService } from 'app/shared/services/tiles/tiles.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { FollowupService } from './followup.service';

// interfaces
import {
    OptionValueGroup,
    QueryFilterRoutingRef,
} from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { GroupStackBarChartSerie } from 'app/shared/models/charts/chart-serie.interface';
import { ColorDonut, ColorGA, ToggleDonutProperties } from 'app/shared/models/charts/charts.interface';
import { CommonEnergyRepartitionMonthly } from 'app/shared/models/energy-data-bills.interface';
import { MapOptions, Marker } from 'app/shared/models/marker.interface';
import { BarChartProperties, DoubleDonutProperties } from './followup.interface';

// components
import { ChartBarToggleComponent } from 'app/shared/components/charts/chart-bar-toggle/chart-bar-toggle.component';
import { FollowupFilterComponent } from 'app/shared/components/filters/followup-filter/followup-filter.component';

export interface LoadingFollowUp {
    isLoading: boolean;
    nbQueriesDone: number;
    nbQueriesToDo: number;
}

@Component({
    selector: 'ga-followup',
    templateUrl: './followup.component.html',
    styleUrls: ['./followup.component.scss'],
    providers: [FollowupService],
})
export class FollowupComponent implements OnInit {
    markers: Marker[] = [];

    mapOptions: MapOptions = {
        zoom: 8,
        lat: 49.894067,
        lng: 2.2957529999999906,
        height: 250,
    };

    totalConso = 0;
    totalHT = 0;
    totalTTC = 0;

    // consumptions pie
    pieConsumptionsProperties: DoubleDonutProperties = {
        title: 'Répartition de la consommation énergétique sur la période',
        dataInside: [],
        dataOutside: [],
        options: {},
        legend: [],
        unit: '',
    };

    // costs TTC pie
    pieTTCProperties: DoubleDonutProperties = {
        dataOutside: [],
        dataInside: [],
        options: {},
        unit: '€',
    };

    // costs HTVA pie
    pieHTVAProperties: DoubleDonutProperties = {
        dataOutside: [],
        dataInside: [],
        options: {},
        unit: '€',
    };

    // bar chart year
    barProperties: BarChartProperties = {
        isLoading: false,
        isDjuActivated: true,
        hasDju: true,
        filters: null,
        datasets: [],
        labels: [],
        colors: [],
        width: 1900,
        minHeight: 340,
        maxHeight: 340,
        options: [],
        legend: [],
        title: 'Répartition mensuelle sur la période',
        titleUnit: 'kWhep',
        toggleTitles: [
            { name: 'CONSOMMATION (kWhep)', display: true },
            { name: 'HTVA (€)', display: true },
            { name: 'TTC (€)', display: true },
        ],
        downloadFileNames: ['', 'Coût mensuel HTVA sur la période (€)', 'Coût mensuel TTC sur la période (€)'],
        units: ['Kwhep', '€', '€'],
        monthData: [],
        monthDjus: [],
    };

    pieToggleGenProperties: ToggleDonutProperties = {
        title: 'Répartition des coûts énergétiques sur la période',
        downloadFileNames: [
            'Répartition des coûts énergétiques HTVA sur la période',
            'Répartition des coûts énergétiques TTC sur la période',
        ],
        toggleNames: ['HTVA', 'TTC'],
        legend: [],
        optionsSets: [],
    };

    fluidColors: ColorGA[] = [];
    frenchFluidLabels = [];
    pieInnerColor: any;

    // pies data
    dataConsumptionsPerFilter = [];
    dataCostsTTCPerFilter = [];
    dataCostsHTVAPerFilter = [];

    fluidsSelectedArray = []; // ['elec', 'gaz']
    nbCurrentFluidSelected = 0;

    loadingChartData: LoadingFollowUp = {
        isLoading: false,
        nbQueriesDone: 0,
        nbQueriesToDo: 1, // minimum is one query for the default site
    };

    queryExport: any;
    queryFilterSelected: OptionValueGroup;

    fluidCategory: string;
    fluidsAccepted: string[] = [];

    @ViewChild(FollowupFilterComponent, { static: false }) filterComponent: FollowupFilterComponent;
    @ViewChild('repartitionMonthlyChart', { static: false }) repartitionMonthlyChart: ChartBarToggleComponent;

    constructor(
        private followupService: FollowupService,
        private route: ActivatedRoute,
        private sitesService: SitesService,
        private tilesService: TilesService,
        private mapService: MapService,
        private djuService: DjuService,
        private energyService: EnergyService,
        private chartService: ChartService,
        private billService: BillsService,
        private filterService: FilterService,
        private energyDataService: EnergyDataService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        /**
         * {Params} route.queryParams
         * {string} route.queryParams.siteId - site id to preselect
         * {Object} route.data
         * {string} route.data.fluidCategory
         * {string} route.data.page
         */

        if (this.route.queryParams && this.route.data) {
            // queryParams can contain 'siteId'
            this.route.queryParams.subscribe(() => {
                this.route.data.subscribe(params => {
                    this.initComponentQueries(params);
                });
            });
        }
    }

    initComponentQueries(params) {
        this.fluidCategory = 'energy';
        if (params && params['fluidCategory'] && params['fluidCategory'] === 'water') {
            this.fluidCategory = 'water';
        }
        this.fluidsAccepted = this.energyService.getFluidsByCategory(this.fluidCategory);

        this.initComponentSetup();
    }

    initComponentSetup() {
        this.loadingChartData.isLoading = true;
        // ----- elements shared by all charts

        // ----- pies chart : color of the inner part
        this.pieInnerColor = this.chartService.getColorRgbToHex('LIGHTGREY');
    }

    resetComponents(filters) {
        this.loadingChartData.nbQueriesDone = 0;

        this.dataCostsHTVAPerFilter = [];
        this.dataCostsTTCPerFilter = [];
        this.dataConsumptionsPerFilter = [];

        this.barProperties.datasets = [];

        this.pieConsumptionsProperties.options = {};
        this.pieConsumptionsProperties.dataInside = [];
        this.pieConsumptionsProperties.dataOutside = [];

        this.pieTTCProperties.options = {};
        this.pieTTCProperties.dataInside = [];
        this.pieTTCProperties.dataOutside = [];

        this.pieHTVAProperties.options = {};
        this.pieHTVAProperties.dataInside = [];
        this.pieHTVAProperties.dataOutside = [];

        this.pieToggleGenProperties.optionsSets = [];

        this.totalConso = 0;
        this.totalHT = 0;
        this.totalTTC = 0;

        const fluidsSelected = filters.energies.split(',');
        this.fluidsSelectedArray = fluidsSelected;

        // ['gaz', 'elec', 'water'] -> ["Gaz", "électricité", "Eau"]
        this.frenchFluidLabels = fluidsSelected.map(fluid => this.energyService.energyFullText(fluid));

        // ['gaz', 'elec', 'water'] -> ["rgba(40,186,155, 1)", "rgba(253,204,45, 1)", "rgba(59,152,217, 1)"]
        const colors: ColorDonut = this.chartService.getColorsDoughnutChart(fluidsSelected);
        this.fluidColors = colors.backgroundColor;

        const start = moment.utc(filters.dateStart).startOf('month');
        const end = moment.utc(filters.dateEnd).startOf('month');
        this.barProperties.labels = this.chartService.getPeriodMonthlyLabels(start, end);

        this.loadingChartData.isLoading = true;
    }

    /**
     * Used to disable filter search.
     * @returns {boolean} true if at least one graph is loading.
     */
    public someGraphIsLoading(): boolean {
        return this.loadingChartData && this.loadingChartData.isLoading;
    }

    /**
     * Set markers for each site matching the current filters
     * @param {QueryFilterRoutingRef} filters
     */
    async setMarkers(filters: QueryFilterRoutingRef) {
        try {
            const sitesForMarkers = await this.sitesService.getSites(filters);
            const markerType = this.energyService.isFluidLiquid(this.fluidCategory) ? 'water' : 'energy';
            this.markers = this.mapService.getMarkersFromSites(sitesForMarkers.data, markerType);
        } catch (e) {
            this.markers = this.mapService.getMarkersFromSites([]);
        }
    }

    /**
     * Get consumption for each selected element inside the filter.
     * @param {QueryFilterRoutingRef} filters
     */
    public async search(filters: QueryFilterRoutingRef): Promise<void> {
        this.queryExport = filters;

        this.queryFilterSelected = this.filterComponent.getMainFilterSelected();
        this.resetComponents(filters);

        let stackId = 0;

        // <!> Don't wait for it to finish. The following parts don't need the sites. <!>
        this.setMarkers(filters);

        // We need separate results for each one of the filter chosen: site by site or category per category instead of all together
        switch (this.queryFilterSelected.value) {
            case 'regions':
                const regions = filters.regions.split(',');
                this.loadingChartData.nbQueriesToDo = regions.length;
                for (const r of regions) {
                    const regionFilter = Object.assign({}, filters, { regions: r });
                    stackId += 1;
                    this.getGlobalMonthlyConsumption(regionFilter, stackId);
                }
                break;

            case 'sites':
                const sites = filters.sites.split(',');
                this.loadingChartData.nbQueriesToDo = sites.length;

                for (const s of sites) {
                    const siteFilter = Object.assign({}, filters, { sites: s });
                    stackId += 1;
                    this.getGlobalMonthlyConsumption(siteFilter, stackId);
                }
                break;

            case 'categories':
                const categories = filters.categories.split(',');
                this.loadingChartData.nbQueriesToDo = categories.length;
                for (const c of categories) {
                    const categoryFilter = Object.assign({}, filters, { categories: c });
                    stackId += 1;
                    this.getGlobalMonthlyConsumption(categoryFilter, stackId);
                }
                break;

            case 'routingReferences':
                const routingReferences = filters.routingreferences.split(',');
                this.loadingChartData.nbQueriesToDo = routingReferences.length;
                for (const rr of routingReferences) {
                    const routingReferencesFilter = Object.assign({}, filters, {
                        routingreferences: rr,
                    });
                    stackId += 1;
                    this.getGlobalMonthlyConsumption(routingReferencesFilter, stackId);
                }
                break;

            case 'custom-filters':
            case 'all':
            default:
                this.loadingChartData.nbQueriesToDo = 1;
                stackId = 1;
                this.getGlobalMonthlyConsumption(filters, stackId);
                break;
        }

        this.nbCurrentFluidSelected = this.fluidsSelectedArray.length;
        this.setBarChartToggleUnit();
        this.setBarChartDownloadTitles();
    }

    /**
     * Get global monthly consumption
     *
     * @param {QueryFilterRoutingRef} filters
     * @param {number} stackId
     * @returns {Promise<void>}
     */
    async getGlobalMonthlyConsumption(filters: QueryFilterRoutingRef, stackId: number): Promise<void> {
        if (filters.energies && filters.dateStart && filters.dateEnd) {
            const nbQueriesToDo = this.loadingChartData.nbQueriesToDo;
            const computeDJU = Boolean(nbQueriesToDo <= 1); // if only one filter selected, get DJU as well

            let consoResult;
            let djuResult;

            try {
                if (!computeDJU) {
                    this.barProperties.hasDju = false;
                    this.barProperties.monthDjus = [];
                    this.barProperties.filters = null;
                    this.barProperties.monthData = null;
                    consoResult = await this.energyDataService.getMonthlyGlobalEnergyRepartition(filters);
                } else {
                    [consoResult, djuResult] = await Promise.all([
                        this.energyDataService.getMonthlyGlobalEnergyRepartition(filters),
                        this.djuService.getDJUs(filters),
                    ]);
                    this.barProperties.hasDju = Boolean(djuResult && djuResult.length);
                    this.barProperties.monthDjus = djuResult;
                    this.barProperties.monthData = consoResult;
                    this.barProperties.filters = filters;
                }

                this.setChartsData(consoResult, filters, stackId, this.barProperties.monthDjus, computeDJU);
            } catch (error) {
                this.setChartsData([], filters, stackId);
            }
        } else {
            /**
             * filters.energies is empty if the company is empty (no contract - no energy)
             * data for charts is already initialized with empty datasets
             */
            this.loadingChartData.isLoading = false;
        }
    }

    /**
     * Populate charts
     *
     * @param {*} monthlyFormatedConsumption
     * @param {boolean} isDjuActivated
     * @returns {*}
     */
    populateCharts(monthlyFormatedConsumption, isDjuActivated): any {
        this.generateTotalsForTiles(monthlyFormatedConsumption);
        this.populateBarDataset(monthlyFormatedConsumption, isDjuActivated); // data for chartBart [ data filter 1, data filtre 2, ... ]

        this.populateConsPieDataset(monthlyFormatedConsumption); // data for consumptions pie chart
        this.populateTTCPieDataset(monthlyFormatedConsumption); // data for TTC pie chart
        this.populateHTVAPieDataset(monthlyFormatedConsumption); // data for HTVA pie chart

        // Essentially for tests. Does not impact any code here.
        return monthlyFormatedConsumption;
    }

    /**
     * Format data and populate charts with input consumption.
     * @param {CommonEnergyRepartitionMonthly[]} conso
     * @param {QueryFilterRoutingRef} filters
     * @param {number} stackId
     * @param {*[]} monthDjus
     * @param {boolean} isDjuActivated
     */
    setChartsData(
        conso: CommonEnergyRepartitionMonthly[],
        filters: QueryFilterRoutingRef,
        stackId: number,
        monthDjus?: any[],
        isDjuActivated?: boolean
    ) {
        const monthlyFormatedConsumption = this.followupService.formateToMonthlyConsumption(
            conso,
            stackId,
            filters,
            monthDjus,
            isDjuActivated
        );

        this.populateCharts(monthlyFormatedConsumption, isDjuActivated);

        this.loadingChartData.nbQueriesDone++;

        // if it was the last query
        if (this.loadingChartData.nbQueriesDone === this.loadingChartData.nbQueriesToDo) {
            this.loadingChartData.isLoading = false;

            // if API had data for this filter
            this.handleBarChart(isDjuActivated);
            this.onBarChartDjuChange(isDjuActivated);
            this.handlePieConsumptionData();
            this.handlePieCostsData();
        }
    }

    /**
     * Get unit for conso
     *
     * @param {string}
     */
    public getUnitForConso(): string {
        if (this.fluidCategory === 'water') {
            return 'm³';
        }
        return this.nbCurrentFluidSelected > 1 ? 'kWhep' : 'kWh';
    }

    /**
     * Generate totals for tiles
     *
     * @param {*} monthlyFormatedConsumption
     */
    generateTotalsForTiles(monthlyFormatedConsumption: any) {
        const totalComsumptions = monthlyFormatedConsumption.data.totalConsumptions[0];

        this.totalConso += Object.keys(totalComsumptions).reduce((memo, index) => {
            memo += totalComsumptions[index];
            return memo;
        }, 0);

        const totalPricesHT = monthlyFormatedConsumption.data.totalPrices[0];
        const totalPricesTTC = monthlyFormatedConsumption.data.totalPrices[1];

        this.totalHT += Object.keys(totalPricesHT).reduce((memo, index) => {
            memo += totalPricesHT[index];
            return memo;
        }, 0);

        this.totalTTC += Object.keys(totalPricesTTC).reduce((memo, index) => {
            memo += totalPricesTTC[index];
            return memo;
        }, 0);
    }

    /**
     * On bar chart dju change
     *
     * @param {boolean} isDjuActivated
     */
    public onBarChartDjuChange(isDjuActivated: boolean) {
        if (this.hasDataForBar()) {
            isDjuActivated = isDjuActivated && this.barProperties.hasDju;
            this.barProperties.isDjuActivated = isDjuActivated;

            const djuLabel = 'djus';
            const kwhDjuButton = 'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )';
            const legends = this.barProperties.datasets.map(dataset => {
                const labels = dataset.reduce((memo, item) => {
                    // to get unique labels
                    if (item.label !== djuLabel && !memo.includes(item.label)) {
                        memo.push(item.label);
                    }
                    return memo;
                }, []);
                if (isDjuActivated) {
                    labels.unshift(djuLabel);
                }
                return this.getChartLegends(labels);
            });

            this.djuService.toggleDjuInDatasetAndChart(
                isDjuActivated,
                this.barProperties,
                djuLabel,
                legends,
                kwhDjuButton,
                this.repartitionMonthlyChart
            );
        }
    }

    /**
     * return true if the bar chart is loading otherwise return false
     *
     * @returns {boolean}
     */
    isBarChartLoading(): boolean {
        return this.barProperties.isLoading;
    }

    // ----   CONSUMPTIONS pie functions

    /**
     * handle pic consumption data
     */
    private handlePieConsumptionData() {
        // ['gaz', 'elec', 'water'] -> [ {name: "Gaz", color: "40,186,155"}, {name: "électricité", color: "253,204,45"} ]
        this.pieConsumptionsProperties.legend = this.getChartLegends(this.fluidsSelectedArray);
        // unit : KWhep if we compare two different fluids, KWh otherwise
        this.pieConsumptionsProperties.unit = this.getUnitForConso();

        // data
        this.setConsumptionsPieDataPerFilter(); // fill inner data = filter data
        this.setConsumptionsPieDataPerFluid(); // fill outer data = fluid data

        // options
        this.pieConsumptionsProperties.options = this.getDoublePieConfig(this.pieConsumptionsProperties);
    }

    /**
     * populate consumption pie dataset
     *
     * @param {*} dataOfOneFilter
     */
    private populateConsPieDataset(dataOfOneFilter: any) {
        const filterConsPerFluid = [];

        // chart[0] = kWhep  chart[1] = HTVA chart[2] = TTC
        // get consumptions of each fluid [ { data: [... x12], label: "elec", stack: 1, idStackFilter: 1]

        const indexFilter = dataOfOneFilter.stack - 1;

        dataOfOneFilter.data.chart[0].forEach(fluidData => {
            const sumMonthsData = fluidData.data.reduce((monthA, monthB) => monthA + monthB, 0);
            const indexFluid = this.fluidsSelectedArray.findIndex(fluid => fluid === fluidData.label);
            filterConsPerFluid[indexFluid] = sumMonthsData;
        });

        // at the end should look like [ [gaz site 1], [gaz site 2, elec site 2] , [undefined, elec site 3] ]
        this.dataConsumptionsPerFilter[indexFilter] = filterConsPerFluid;
    }

    /**
     * Set consumption pie data per filter
     */
    private setConsumptionsPieDataPerFilter() {
        const filters = this.filterComponent.getFilters();

        for (let i = 0; i < filters.length; i++) {
            if (this.dataConsumptionsPerFilter[i]) {
                const valueFilter = this.getSumFluidValue(this.dataConsumptionsPerFilter[i]);
                if (valueFilter > 0) {
                    this.pieConsumptionsProperties.dataInside.push({
                        name: this.chartService.getChartLegendContentByFilter(
                            filters[i],
                            this.queryFilterSelected.value
                        ),
                        y: valueFilter,
                        color: this.pieInnerColor,
                        data: this.dataConsumptionsPerFilter[i],
                    });
                }
            }
        }
    }

    /**
     * Set consumption pie data per fluid
     */
    private setConsumptionsPieDataPerFluid() {
        const filters = this.filterComponent.getFilters();
        for (let indexFilter = 0; indexFilter < filters.length; indexFilter++) {
            // for each Fluid
            for (let indexFluid = 0; indexFluid < this.fluidsSelectedArray.length; indexFluid++) {
                // fill filter data if the filter contains this fluid
                if (this.pieConsumptionsProperties.dataInside[indexFilter]) {
                    const dataFilterFluid = this.pieConsumptionsProperties.dataInside[indexFilter].data[indexFluid];

                    if (dataFilterFluid && dataFilterFluid > 0) {
                        const fluidData: any = {
                            name: this.frenchFluidLabels[indexFluid],
                            y: dataFilterFluid,
                            color: this.fluidColors[indexFluid],
                        };
                        if (this.fluidsSelectedArray.length > 1) {
                            fluidData.percentageCat =
                                (dataFilterFluid * 100) / this.pieConsumptionsProperties.dataInside[indexFilter].y;
                        }
                        this.pieConsumptionsProperties.dataOutside.push(fluidData);
                    }
                }
            }
        }
    }

    /**
     * Get chart legends
     *
     * @param {string[]} labels
     * @param {object} options
     * @returns {*}
     */
    getChartLegends(labels: string[], options: object = {}): any {
        return labels.map(label => this.chartService.getLegend(label, options));
    }

    // ------- COSTS PIE functions

    /**
     * Populate TTC pie dataset
     *
     * @param {*} dataOfOneFilter
     */
    private populateTTCPieDataset(dataOfOneFilter: any) {
        const filterTTCPerFluid = [];

        // chart[0] = kWhep  chart[1] = HTVA chart[2] = TTC
        // get consumptions of each fluid [ { data: [... x12], label: "elec", stack: 1, idStackFilter: 1]
        // if(indexFilter < 0) indexFilter = 0;
        const indexFilter = dataOfOneFilter.stack - 1;
        dataOfOneFilter.data.chart[2].forEach(fluidData => {
            const sumMonthsData = fluidData.data.reduce((monthA, monthB) => monthA + monthB, 0);
            const indexFluid = this.fluidsSelectedArray.findIndex(fluid => fluid === fluidData.label);
            filterTTCPerFluid[indexFluid] = sumMonthsData;
        });

        // at the end should look like [ [gaz site 1], [gaz site 2, elec site 2] , [undefined, elec site 3] ]
        this.dataCostsTTCPerFilter[indexFilter] = filterTTCPerFluid;
    }

    /**
     * populate HTVA pie dataset
     *
     * @param {*} dataOfOneFilter
     */
    private populateHTVAPieDataset(dataOfOneFilter: any) {
        const filterHTVAPerFluid = [];

        // chart[0] = kWhep  chart[1] = HTVA chart[2] = TTC
        // get consumptions of each fluid [ { data: [... x12], label: "elec", stack: 1, idStackFilter: 1]
        const indexFilter = dataOfOneFilter.stack - 1;
        dataOfOneFilter.data.chart[1].forEach(fluidData => {
            const sumMonthsData = fluidData.data.reduce((monthA, monthB) => monthA + monthB, 0);
            const indexFluid = this.fluidsSelectedArray.findIndex(fluid => fluid === fluidData.label);
            filterHTVAPerFluid[indexFluid] = sumMonthsData;
        });

        // at the end should look like [ [gaz site 1], [gaz site 2, elec site 2] , [undefined, elec site 3] ]
        this.dataCostsHTVAPerFilter[indexFilter] = filterHTVAPerFluid;
    }

    /**
     * Handle pie costs data
     */
    private handlePieCostsData() {
        // legend
        const legend = this.getChartLegends(this.fluidsSelectedArray);
        this.pieToggleGenProperties.legend = [legend, legend];

        // data
        this.setCostsPieDataPerFilter(); // fill inner data = filter data
        this.setCostsPieDataPerFluid(); // fill outer data = fluid data

        // options
        this.pieTTCProperties.options = this.getDoublePieConfig(this.pieTTCProperties);
        this.pieHTVAProperties.options = this.getDoublePieConfig(this.pieHTVAProperties);
        this.pieToggleGenProperties.optionsSets = [this.pieHTVAProperties.options, this.pieTTCProperties.options];
    }

    /**
     * Set costs pie data per fitler
     */
    private setCostsPieDataPerFilter() {
        const filters = this.filterComponent.getFilters();

        // HTVA
        for (let i = 0; i < filters.length; i += 1) {
            if (this.dataCostsHTVAPerFilter[i]) {
                const valueFilter = this.getSumFluidValue(this.dataCostsHTVAPerFilter[i]);
                if (valueFilter > 0) {
                    this.pieHTVAProperties.dataInside.push({
                        name: this.chartService.getChartLegendContentByFilter(
                            filters[i],
                            this.queryFilterSelected.value
                        ),
                        y: valueFilter,
                        color: this.pieInnerColor,
                        data: this.dataCostsHTVAPerFilter[i],
                    });
                }
            }
        }

        // TTC
        for (let i = 0; i < filters.length; i += 1) {
            if (this.dataCostsTTCPerFilter[i]) {
                const valueFilter = this.getSumFluidValue(this.dataCostsTTCPerFilter[i]);
                if (valueFilter > 0) {
                    this.pieTTCProperties.dataInside.push({
                        name: this.chartService.getChartLegendContentByFilter(
                            filters[i],
                            this.queryFilterSelected.value
                        ),
                        y: valueFilter,
                        color: this.pieInnerColor,
                        data: this.dataCostsTTCPerFilter[i],
                    });
                }
            }
        }
    }

    /**
     * Set costs pie data per fluid
     */
    private setCostsPieDataPerFluid() {
        const filters = this.filterComponent.getFilters();

        for (let indexFilter = 0; indexFilter < filters.length; indexFilter += 1) {
            // for each fluid
            for (let indexFluid = 0; indexFluid < this.fluidsSelectedArray.length; indexFluid += 1) {
                // fill filter data if the filter contains this fluid
                if (this.pieTTCProperties.dataInside[indexFilter]) {
                    const dataTTCFilterFluid = this.pieTTCProperties.dataInside[indexFilter].data[indexFluid];

                    if (dataTTCFilterFluid && dataTTCFilterFluid > 0) {
                        const fluidData: any = {
                            name: this.frenchFluidLabels[indexFluid],
                            y: dataTTCFilterFluid,
                            color: this.fluidColors[indexFluid],
                        };
                        if (this.fluidsSelectedArray.length > 1) {
                            fluidData.percentageCat =
                                (dataTTCFilterFluid * 100) / this.pieTTCProperties.dataInside[indexFilter].y;
                        }
                        this.pieTTCProperties.dataOutside.push(fluidData);
                    }
                }

                // fill filter data if the filter contains this fluid
                if (this.pieHTVAProperties.dataInside[indexFilter]) {
                    const dataHTVAFilterFluid = this.pieHTVAProperties.dataInside[indexFilter].data[indexFluid];

                    if (dataHTVAFilterFluid && dataHTVAFilterFluid > 0) {
                        const fluidData: any = {
                            name: this.frenchFluidLabels[indexFluid],
                            y: dataHTVAFilterFluid,
                            color: this.fluidColors[indexFluid],
                        };
                        if (this.fluidsSelectedArray.length > 1) {
                            fluidData.percentageCat =
                                (dataHTVAFilterFluid * 100) / this.pieHTVAProperties.dataInside[indexFilter].y;
                        }
                        this.pieHTVAProperties.dataOutside.push(fluidData);
                    }
                }
            }
        }
    }

    /**
     * Create and return config from the inner and outer circle series
     * @param {DoubleDonutProperties} pieChartProperties
     * @returns {*}
     */
    private getDoublePieConfig(pieChartProperties: DoubleDonutProperties): any {
        return this.chartService.getHighchartDoublePieConfig(
            pieChartProperties.dataInside,
            pieChartProperties.dataOutside,
            pieChartProperties.unit
        );
    }

    // ------- BAR CHARTS functions
    /**
     * Populate bar dataset
     *
     * @param {*} dataOfOneFilter
     * @param {boolean?} isDjuActivated
     */
    populateBarDataset(dataOfOneFilter: any, isDjuActivated?: boolean) {
        if (!this.barProperties.datasets) {
            this.barProperties.datasets = [];
        }
        for (let i = 0; i < 3; i++) {
            if (!this.barProperties.datasets[i]) {
                this.barProperties.datasets[i] = [];
            }
        }

        // push the data object of each fluid
        for (let indexFluid = 0; indexFluid < dataOfOneFilter.data.chart[0].length; indexFluid++) {
            this.barProperties.datasets[0].push(dataOfOneFilter.data.chart[0][indexFluid]); // Consommation kWhep
            this.barProperties.datasets[1].push(dataOfOneFilter.data.chart[1][indexFluid]); // HTVA
            this.barProperties.datasets[2].push(dataOfOneFilter.data.chart[2][indexFluid]); // TTC
        }

        if (isDjuActivated) {
            const dataDjuPonderation = this.billService.getDjuPonderationByEnergy(this.barProperties.datasets[0]);
            this.barProperties.datasets.splice(1, 0, dataDjuPonderation);
        }

        // Remove empty datasets
        this.barProperties.datasets = this.barProperties.datasets.map(datasets => {
            const r = datasets.filter(x => {
                const isOk = x.data.some(d => d !== 0 && d !== null);
                return isOk;
            });
            return r;
        });
    }

    /**
     * Handle bar chart
     *
     * @param {boolean} isDjuActivated
     */
    private handleBarChart(isDjuActivated: boolean) {
        this.chartService.sortByStack(this.barProperties.datasets);

        // Each dataset has its legend and colors (can be different size)
        this.barProperties.legend = this.barProperties.datasets.map(dataset => {
            this.getChartLegends(
                dataset.reduce((memo, item) => {
                    // to get unique labels
                    if (!memo.includes(item.label)) {
                        memo.push(item.label);
                    }
                    return memo;
                }, [])
            );
        });
        this.barProperties.colors = this.chartService.getStackColors(this.barProperties);

        // Set unit for each tab of toggles
        this.barProperties.units = [this.getCurrentUnit(), '€', '€'];

        const filters = this.filterComponent.getFilters();

        const options = this.chartService.getConfig(
            isDjuActivated ? 'mixed-groupable-stacked-bar' : 'groupable-stacked-bar',
            {
                tooltips: this.chartService.getTooltipHTMLConfig(
                    'groupable-stack-followup',
                    null,
                    this.followupService.getBarGroupNames(filters, this.queryFilterSelected.value),
                    this.queryFilterSelected.totalGroupName
                ),
            }
        );

        this.barProperties.options = this.chartService.getDatasetsAxesOptions(this.barProperties.datasets, options);
        this.barProperties.toggleTitles = [
            { name: 'CONSOMMATION (' + this.getCurrentUnit() + ')', display: true },
            { name: 'HTVA (€)', display: true },
            { name: 'TTC (€)', display: true },
        ];
        this.barProperties.downloadFileNames = [
            `Consommation sur la période (${this.getCurrentUnit()})`,
            'Coût mensuel HTVA sur la période (€)',
            'Coût mensuel TTC sur la période (€)',
        ];

        if (isDjuActivated) {
            this.barProperties.units.splice(1, 0, this.getCurrentUnit() + '/DJU');
            this.barProperties.options.splice(
                1,
                1,
                this.chartService.getConfig('bar', {
                    tooltips: this.chartService.getTooltipHTMLConfig(
                        'ng-chart-bar-line',
                        this.getCurrentUnit() + '/DJU'
                    ),
                })
            );
            this.barProperties.toggleTitles.splice(1, 0, {
                name: 'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )',
                display: true,
            });
            this.barProperties.downloadFileNames.splice(
                1,
                0,
                'PONDERATION PAR DJU (' + this.getCurrentUnit() + '/DJU )'
            );
        }

        this.setUnit(this.barProperties.datasets, this.barProperties.units);
    }

    /**
     * Get sum of fluid values
     *
     * @param {number[]} filterValues
     * @returns {number}
     */
    private getSumFluidValue(filterValues: number[]): number {
        return filterValues.reduce((a, b) => a + b, 0);
    }

    /**
     * Populate the barProperties.data with units in each dataset of each toggle.
     * For the bars: put the unit from the toggle
     * For the line: put "dju"
     * @param {GroupStackBarChartSerie[][]} toggleDatasets - all tab in the view [ tab conso, tab htva, tab ttc]
     *        each tab contain datasets
     * @param {string[]} unitsForBars - array of the barUnits for each toggle ["kwh", "€", "€"]
     * @returns {GroupStackBarChartSerie[][]}
     */
    private setUnit(toggleDatasets: GroupStackBarChartSerie[][], unitsForBars: string[]): GroupStackBarChartSerie[][] {
        return toggleDatasets.map((toggleDataset, index) => {
            return toggleDataset.map(dataset => {
                dataset.unit = dataset.type === 'bar' ? unitsForBars[index] : 'DJU';
                return dataset;
            });
        });
    }

    /**
     * Get the current fluid unit
     *
     * @returns {string}
     */
    public getCurrentUnit(): string {
        if (this.fluidCategory === 'water') {
            return 'm³';
        }
        return this.filterComponent.getFluidsCount() > 1 ? 'kWhep' : 'kWh';
    }

    /**
     * Getter totalConso
     *
     * @returns {number}
     */
    getTotalConso(): number {
        return this.totalConso;
    }

    /**
     * Return the formatted html total cost
     *
     * @returns {string}
     */
    getTotalCost(): string {
        const totalHT = this.tilesService.getNumberToDisplay(this.totalHT, '€');
        const totalTTC = this.tilesService.getNumberToDisplay(this.totalTTC, '€');
        return `<b>${totalHT}</b> € HTVA - <b>${totalTTC}</b> € TTC`;
    }

    /**
     * Return true if the chart data is loading
     *
     * @returns {boolean}
     */
    public isLoadingData(): boolean {
        return Boolean(this.loadingChartData.isLoading);
    }

    /**
     * Return true if there are data for consumption pie chart otherwise return false
     *
     * @returns {boolean}
     */
    public hasDataForConsPie(): boolean {
        try {
            const chartOptions = this.pieConsumptionsProperties.options;
            return Boolean(
                chartOptions &&
                    chartOptions.series &&
                    (chartOptions.series[0].data.length && chartOptions.series[1].data.length)
            );
        } catch (err) {
            return false;
        }
    }

    /**
     * Return true if there are data for HTVA / TTC pie chart otherwise return false
     *
     * @returns {boolean}
     */
    public hasDataForHTVAOrTTCPie(): boolean {
        try {
            const chartOptionSets = this.pieToggleGenProperties.optionsSets;
            return Boolean(
                (chartOptionSets &&
                    chartOptionSets.length === 2 &&
                    (chartOptionSets[0].series[0].data.length && chartOptionSets[0].series[1].data.length)) ||
                    (chartOptionSets[1].series[0].data.length && chartOptionSets[1].series[1].data.length)
            );
        } catch (err) {
            return false;
        }
    }

    /**
     * Return true if there are dataset for chart bar otherwise return false
     *
     * @returns {boolean}
     */
    public hasDataForBar(): boolean {
        return Boolean(
            this.barProperties.datasets && this.barProperties.datasets.length && this.barProperties.datasets[0].length
        );
    }

    /*
     ** Filters
     */

    /**
     * Return true if the selected multiple fluids otherwise return false
     *
     * @returns {boolean}
     */
    hasMultipleFluidsSelected(): boolean {
        return this.nbCurrentFluidSelected > 1 ? true : false;
    }

    /**
     * Set the bar chart toggle unit
     */
    setBarChartToggleUnit() {
        this.barProperties.units[0] = this.getUnitForConso();
        this.barProperties.toggleTitles[0] = {
            name: 'CONSOMMATION (' + this.barProperties.units[0] + ')',
            display: true,
        };
    }

    /**
     * Set the bar chart download titles
     */
    setBarChartDownloadTitles() {
        this.barProperties.units[0] = this.getUnitForConso();
        this.barProperties.downloadFileNames[0] =
            'Consommation mensuelle sur la période ' + '(' + this.barProperties.units[0] + ')';
    }

    /**
     * Set the the unit for chart bar title by specified unit index
     *
     * @param {string} index
     */
    setUnitForChartBarTitle(index: string) {
        this.barProperties.titleUnit = this.barProperties.units[index];
    }

    /**
     * Get followup excel export url
     *
     * @param {string} type - specified export type
     * @returns {string}
     */
    getExportExcelUrl(type: string): string {
        if (type && this.queryExport) {
            this.queryExport.dju = this.barProperties.isDjuActivated && type === 'repartition-monthly';
            let url = `/api/export/excel/followup/${type}`;
            url += this.filterService.formatQueryToUrl(this.queryExport);

            return url;
        }
        return null;
    }

    /**
     * Get consumption excel export url
     *
     * @param {string} type - specified export type
     * @returns {string}
     */
    getSiteConsumptionExportExcelUrl(type: string): string {
        if (type && this.queryExport) {
            this.queryExport.dju = false;
            let url = `/api/export/excel/${type}`;
            url += this.filterService.formatQueryToUrl(this.queryExport);

            return url;
        }
        return null;
    }

    /**
     * Get consumption per site and zone excel export url
     *
     * @returns {string}
     */
    getSiteZoneConsumptionExportExcelUrl(): string {
        if (this.queryExport) {
            this.queryExport.dju = false;
            let url = `/api/export/excel/consumptions/sites-zones`;
            url += this.filterService.formatQueryToUrl(this.queryExport);

            return url;
        }
        return null;
    }

    /**
     * Get export file name by specified title
     *
     * @param {string} title - begining of the full filename
     * @returns {string} name of the export file correctly formatted
     */
    getExportName(title: string): string {
        const date = moment()
            .format('YYYYMMDD')
            .toString();
        const filterType = this.queryFilterSelected ? this.translateService._(this.queryFilterSelected.value) : '';
        return `${title}-${date}-${filterType}.xlsx`;
    }

    /**
     * Get tiles color
     *
     * @param {string} type - specified tile type
     * @returns {string}
     */
    getTilesColor(type: string): string {
        if (this.fluidCategory === 'water') {
            return '#4381b7';
        }
        if (type === 'conso') {
            return '#f3cc2e';
        }
        return '#df7c3b';
    }
}
