import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChartLegendGA } from 'app/shared/models/charts/charts.interface';

@Component({
    selector: 'ga-graph-bottom',
    templateUrl: './graph-bottom.component.html',
    styleUrls: ['./graph-bottom.component.scss'],
})
export class GraphBottomComponent {
    @Input() legend: ChartLegendGA[];
    @Input() secondLegend: ChartLegendGA[];
    @Input() chartInstance: any;
    @Input() downloadFileName: string;
    @Input() graphType: string;

    @ViewChild('downloadLink', { static: true }) downloadLink: ElementRef;

    public downloadChart() {
        if (this.chartInstance && this.graphType === 'highchart') {
            this.chartInstance.exportChart({ filename: this.downloadFileName });
        } else {
            this.downloadLink.nativeElement.href = this.chartInstance.chart.toBase64Image();
            this.downloadLink.nativeElement.click();
        }
    }

    public getRgbColor(color: string): string {
        return `rgba(${color})`;
    }
}
