// Required angular modules
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// App bootstrap
import { AppComponent } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './app.state';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './rollbar';

// Import all services defined in services module
import { ServicesModule } from './shared/services/services.module';

// Import all modules shared by multiple components of application
import { SharedModule } from './shared/shared.module';

// Import main routing module defining layout component as default route
import { AppRoutingModule } from './app.routing';

import { PipesModule } from './shared/pipes/pipes.module';
import { ApiService } from './shared/services/api/api.service';

// Import Guards
import { AccessGuard } from './shared/services/guards/access-guard.service';
import { AuthGuard } from './shared/services/guards/auth-guard.service';
import { CanDeactivateGuard } from './shared/services/guards/can-deactivate-guard.service';

// Import ErrorInterceptor
import { ErrorInterceptor } from './shared/services/api/error.interceptor';

// Import question components for dynamic creation
import { FileQuestionComponent } from './shared/components/sites/questions/file-upload/file-upload.component';
import { GroupQuestionComponent } from './shared/components/sites/questions/group-collapse/group-collapse.component';
import { InputTextMultipleComponent } from './shared/components/sites/questions/input-text-multiple/input-text-multiple.component';
import { InputTextComponent } from './shared/components/sites/questions/input-text/input-text.component';
import { ModalQuestionComponent } from './shared/components/sites/questions/modal/modal.component';
import { SelectChoicesBlocComponent } from './shared/components/sites/questions/select-choices-bloc/select-choices-bloc.component';
import { SelectChoicesInputComponent } from './shared/components/sites/questions/select-choices-input/select-choices-input.component';
import { SelectChoicesComponent } from './shared/components/sites/questions/select-choices/select-choices.component';
import { SelectDropdownComponent } from './shared/components/sites/questions/select-dropdown/select-dropdown.component';
import { AnalyticsModule } from './shared/services/analytics/analytics.module';

import { environment } from '../environments/environment';
import { AnalyticsService } from './shared/services/analytics/analytics.service';
import { HotjarService } from './shared/services/analytics/hotjar/hotjar.service';

// Application wide providers
const APP_PROVIDERS = [
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    AppState,
    GlobalState,
    Title,
    ApiService,
    AuthGuard,
    AccessGuard,
    {
        provide: LocationStrategy,
        useClass: HashLocationStrategy,
    },
    CanDeactivateGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
];

export interface StoreType {
    state: InternalStateType;
    restoreInputValues: () => void;
    disposeOldHosts: () => void;
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ServicesModule,
        SharedModule.forRoot(),
        AppRoutingModule,
        PipesModule.forRoot(),
        SweetAlert2Module.forRoot(),
        AnalyticsModule.forRoot(environment.APP_ENV, environment.VERSION, {
            ...(location.host === 'energie.citron.io' ? { hotjarId: 3298685 } : {}),
            gaId: 'GTM-KKZ3GWT',
        }),
    ],
    providers: [APP_PROVIDERS],
    bootstrap: [AppComponent],
    entryComponents: [
        SelectChoicesComponent,
        SelectChoicesBlocComponent,
        InputTextComponent,
        SelectDropdownComponent,
        InputTextMultipleComponent,
        SelectChoicesInputComponent,
        ModalQuestionComponent,
        GroupQuestionComponent,
        FileQuestionComponent,
    ],
})
export class AppModule {
    constructor(
        public appState: AppState,
        public analyticsService: AnalyticsService,
        public hotjarService: HotjarService
    ) {}
}
