import { Injectable } from '@angular/core';
import { ChartBarToggleComponent } from 'app/shared/components/charts/chart-bar-toggle/chart-bar-toggle.component';
import { QueryFilter } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { ChartSerieLight } from 'app/shared/models/charts/chart-serie.interface';
import { ToggleChartPropertiesLight } from 'app/shared/models/charts/charts.interface';
import { Dju } from 'app/shared/models/dju.interface';
import { Site } from 'app/shared/models/site.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { PageService } from 'app/shared/services/page/page.service';

@Injectable()
export class DjuService extends PageService {
    /** List of possible methods to compute djus */
    private djusComputeMethods: string[] = null;
    private referenceTemperaturesHot: number[] = null;
    private referenceTemperaturesCold: number[] = null;

    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get available dju methods
     */
    async getDjusComputeMethods(): Promise<string[]> {
        if (!this.djusComputeMethods) {
            const resp = await this.get(`/api/dju/methods`);
            this.djusComputeMethods = resp.data;
        }
        return this.djusComputeMethods;
    }

    async getReferenceTemperaturesCold(): Promise<number[]> {
        if (this.referenceTemperaturesCold) {
            return this.referenceTemperaturesCold;
        }

        const resp = await this.get('/api/dju/reference-temperatures-cold');

        return resp.data;
    }

    async getReferenceTemperaturesHot(): Promise<number[]> {
        if (this.referenceTemperaturesHot) {
            return this.referenceTemperaturesHot;
        }

        const resp = await this.get('/api/dju/reference-temperatures-hot');

        return resp.data;
    }

    /**
     * Get DJUs filtered and grouped. By default, grouped by month.
     * @param filters - filter obejct to filter DJUs
     * @param group - DJU per frenquency ("yearly", "monthly" or "site"). Default : null => monthly
     */
    async getDJUs(filters: QueryFilter, group: string = null): Promise<Dju[]> {
        let url = '/api/dju/filters';
        if (group) {
            url += '?group=' + group;
        }
        const res = await this.post(url, filters);
        return res.data;
    }

    /**
     * Get Dju array from the nearest station of the given site for the selected period.
     * @param {string} dateStart - at ISO string
     * @param {string} dateEnd - at ISO string
     * @param {Site} site
     * @return {Promise<Dju[]>}
     */
    async getSiteDJUs(dateStart: string, dateEnd: string, site: Site): Promise<Dju[]> {
        const url = `/api/dju/site/${site._id}?start=${dateStart}&end=${dateEnd}`;
        const response = await this.get(url);
        const djus: Dju[] = response.data;
        djus.forEach(dju => {
            dju.hot = dju[`${site.dju.method}_hot_${site.dju.temperatureReference}`];
            dju.cold = dju[`${site.dju.method}_cold_${site.dju.temperatureReferenceCold}`];
        });
        return djus;
    }

    /**
     * Find element inside object from the given path.
     * Return undefined if the path isn't found.
     * @param {object} obj
     * @param {string} path
     * @returns {any} element found with the path, undefined if path is incorrect
     */
    private deepFind(obj: object, path: string): any {
        const paths = path.split('.');
        let current = obj;
        for (const p of paths) {
            if (current[p] === undefined) {
                return undefined;
            }
            current = current[p];
        }
        return current;
    }

    /**
     * Add object value to dataset
     * @param {ChartSerieLight[]} memo
     * @param {string} serieName
     * @param {any} currentItem
     * @param {string} property
     * @param {string} yAxis
     * @param {string} type
     * @param {boolean} filled
     * @param {string} unit
     */
    private addObjectValueToDataset(
        memo: ChartSerieLight[],
        serieName: string,
        currentItem: object,
        property: string,
        yAxis: string,
        type: string,
        filled: boolean,
        unit: string
    ) {
        const value = this.deepFind(currentItem, property);

        this.addValueToDataset(memo, serieName, value, yAxis, type, filled, unit);
    }

    /**
     * Add value to dataset
     * @param {ChartSerieLight[]} memo
     * @param {string} serieName
     * @param {any} value
     * @param {string} yAxis
     * @param {string} type
     * @param {boolean} filled
     * @param {string} unit
     */
    private addValueToDataset(
        memo: ChartSerieLight[],
        serieName: string,
        value: any,
        yAxis: string,
        type: string,
        filled: boolean,
        unit: string
    ) {
        // Handle full hours
        const existingSerie = memo.find(item => item.label === serieName);

        if (existingSerie) {
            // Push new data in existing dataset
            existingSerie.data.push(value);
        } else {
            // Push new serie in dataset
            const newSerie = {
                label: serieName,
                data: [value],
                yAxisID: yAxis,
                type,
                filled,
                unit,
            };

            memo.push(newSerie);
        }
    }

    /**
     * Group and sum all datas by year
     * @param monthDjuDatas
     */
    getYearDju(monthDjuDatas: Dju[]): Dju[] {
        return monthDjuDatas.reduce((memo, monthDju) => {
            const itemFind = memo.find(item => {
                return item.year === monthDju.year;
            });

            if (!itemFind) {
                memo.push(JSON.parse(JSON.stringify(monthDju)));
            } else {
                itemFind.hot += monthDju.hot;
                itemFind.cold += monthDju.cold;
            }
            return memo;
        }, []);
    }

    /**
     * Get DJU and month data formatted data for charts.
     * @param {Dju[]} djuData
     * @param {any[]} monthdatas
     * @param {string} property
     * @returns {ChartSerieLight[]}
     */
    getDJUMonthData(djuData: Dju[], monthdatas: any[], property: string): ChartSerieLight[] {
        const series = ['Consommation totale'];
        const yAxis = ['y-axis-0'];

        if (djuData) {
            series.push('DJU');
            yAxis.push('y-axis-1');
        }

        const dataset: ChartSerieLight[] = [];

        // Generate 12 months of data
        monthdatas.forEach(monthData => {
            // If data of month on current month : add it
            if (monthData) {
                this.addObjectValueToDataset(dataset, series[0], monthData, property, yAxis[0], 'bar', true, 'kWh');
            } else {
                this.addValueToDataset(dataset, series[0], 0, yAxis[0], 'bar', true, 'kWh');
            }

            if (djuData) {
                const djuOnCurrentMonth = djuData.find(dju => {
                    return dju.year === monthData.year && dju.month === monthData.month + 1;
                });

                // If data of dju on current month : add it
                if (djuOnCurrentMonth) {
                    this.addValueToDataset(dataset, series[1], djuOnCurrentMonth.hot, yAxis[1], 'line', false, 'DJU');
                } else {
                    this.addValueToDataset(dataset, series[1], 0, yAxis[1], 'line', false, 'DJU');
                }
            }
        });

        return dataset;
    }

    /**
     * Toggle DJUs in datasets and chart instance. Works for toggle chart bar toggles.
     * When DJUs are disabled, it hides the datasets and axes related to the DJUs.
     * DJU axes is defined as the one on the right. If for any reason it's not, then the right axes will be hidden anyway.
     * It also show/hide legends and buttons related to DJUs.
     *
     * @param {boolean} isDjuActivated - true to activate DJU, false otherwise.
     * @param {ToggleChartPropertiesLight} chartProperties - chart bar properties. Required properties : datasets, options, legend, buttons.
     * @param {string} djuLabel - dju dataset label in datasets
     * @param {string[][]} labelsLegends - new legends to set.
     * @param {string} kwhDjuToggleName - label of the button for kWh/DJU to be shown/hidden in toggle
     * @param {ChartBarToggleComponent} chartBarToggle - Chart bar instance
     * @param {string} toggleYAxis - y axis to toggle
     */
    toggleDjuInDatasetAndChart(
        isDjuActivated: boolean,
        chartProperties: ToggleChartPropertiesLight,
        djuLabel: string,
        labelsLegends: any[][],
        kwhDjuToggleName: string,
        chartBarToggle: ChartBarToggleComponent,
        toggleYAxis: string = 'y-axis-1'
    ) {
        let djuChanged = false;
        let axis = null;

        /**
         * Hide/Show DJU dataset for each toggle datasets
         */
        chartProperties.datasets.forEach(toggleDatasets => {
            const dataset = toggleDatasets.find(x => x.label === djuLabel);
            if (dataset) {
                dataset.hidden = !isDjuActivated;
                djuChanged = true;
            }
        });

        /**
         * Hide/Show DJU yAxe (right one)
         */
        chartProperties.options.forEach(toggleOption => {
            const djuAxe = toggleOption.scales.yAxes.find(x => x.id === toggleYAxis);
            if (djuAxe) {
                axis = djuAxe.id === 'y-axis-2' ? null : toggleYAxis;
                djuAxe.display = isDjuActivated && djuAxe.id !== 'y-axis-2';
                djuChanged = true;
            }
        });

        /**
         * Hide/Show DJU Legends
         */
        chartProperties.legend = chartProperties.legend.map((legend, index) => {
            if (labelsLegends[index]) {
                return labelsLegends[index];
            }
            return legend;
        });

        /**
         * Deal with kwh/dju button, remove/hide it if dju disactivated, add/show it if activated
         */
        const btnIndex = chartProperties.toggleTitles.findIndex(x => x.name === kwhDjuToggleName);
        if (btnIndex > -1) {
            if (!isDjuActivated) {
                chartProperties.toggleTitles[btnIndex].display = false;
                if (chartBarToggle && chartBarToggle.activeDataset === btnIndex) {
                    chartBarToggle.selectToggleWithData();
                }
            } else {
                chartProperties.toggleTitles[btnIndex].display = true;
            }
        } else if (btnIndex < 0 && isDjuActivated) {
            chartProperties.toggleTitles.push({ name: kwhDjuToggleName, display: true });
        }

        /**
         * If changes, toggle display of dju and scale
         */
        if (djuChanged && chartBarToggle) {
            chartBarToggle.toggleOptions = chartProperties.toggleTitles;
            chartBarToggle.toggleDataset(djuLabel, !isDjuActivated);
            chartBarToggle.toggleScale('y', axis, !isDjuActivated);
        }
    }
}
