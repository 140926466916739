import { Injectable } from '@angular/core';
import { SiteLightEntity, SitePopulated } from 'app/shared/models/site.interface';
import { Site } from 'app/shared/models/site.interface';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';
import { SessionService } from '../session/session.service';
import { UtilsService } from '../utils/utils.service';

@Injectable()
export class SitesService extends PageService {
    constructor(private sessionService: SessionService, public apiService: ApiService, private utils: UtilsService) {
        super(apiService);
    }

    setSiteProperties(site, properties) {
        if (site && !site.info.diviser) {
            const cat = properties.categories.find(cate => cate.id === site.category);
            if (cat) {
                site.category = cat.name;
                const subcat = cat.subcategories.find(sub => sub.id === site.subcategory);
                if (subcat) {
                    site.subcategory = subcat.name;
                    const comp = subcat.complements.find(co => co.id === site.complement);
                    site.complement = comp ? comp.name : 'Complement N/A';
                } else {
                    site.subcategory = 'Sous-Catégorie N/A';
                    site.complement = 'Complement N/A';
                }
            } else {
                site.category = 'Catégorie N/A';
                site.subcategory = 'Sous-Catégorie N/A';
                site.complement = 'Complement N/A';
            }

            const nat = properties.natures.find(natu => natu.id === site.info.nature);
            site.nature = nat ? nat.name : 'Nature N/A';

            if (!site.name) {
                site.name = 'Site N/A';
            }
        } else if (site) {
            site.batiments.forEach(zone => {
                const cat = properties.categories.find(cate => cate.id === zone.category);
                if (cat) {
                    zone.category = cat.name;
                    const subcat = cat.subcategories.find(sub => sub.id === zone.subcategory);
                    if (subcat) {
                        zone.subcategory = subcat.name;
                        const comp = subcat.complements.find(co => co.id === zone.complement);
                        zone.complement = comp ? comp.name : 'Complement N/A';
                    } else {
                        zone.subcategory = 'Sous-Catégorie N/A';
                        zone.complement = 'Complement N/A';
                    }
                } else {
                    zone.category = 'Catégorie N/A';
                    zone.subcategory = 'Sous-Catégorie N/A';
                    zone.complement = 'Complement N/A';
                }

                const nat = properties.natures.find(natu => natu.id === zone.nature);
                zone.nature = nat ? nat.name : 'Nature N/A';
            });
        }
    }

    createGoogleAddressToDisplay(allSites) {
        allSites.forEach(site => {
            site.googleAddress = this.getAddressToDisplay(site);
        });
    }

    getAddressToDisplay(site) {
        const streetNumber = site.streetNumber || '';
        const zipcode = site.zipcode || '';
        return site.city ? `${streetNumber} ${site.streetName} ${zipcode} ${site.city}` : site.name;
    }

    /**
     * Get sites matching the filters.
     * Each site is populated with its routing references and vehicles.
     * Routing references are populated with their contracts.
     * Vehicles are populated with their fuel cards and contracts.
     * @param {{[key: string]: string}} filters
     * @returns {Promise<APICitronResponse<SitePopulated[]>>}
     */
    getSites(filters: { companies?: string; [filter: string]: any }): Promise<APICitronResponse<SitePopulated[]>> {
        const companyId: string = filters && filters.companyId ? filters.companyId : this.sessionService.getCompanyId();
        return this.post(`/api/companies/${companyId}/sites`, filters);
    }

    /**
     * Return the site from a routing reference
     * @param {string} routingReferenceId
     * @param {string} company
     * @returns {Promise<Site>}
     */
    async getSiteFromRoutingReference(routingReferenceId: string, company: string = null): Promise<Site> {
        const companyFromSession = this.sessionService.getCompany();
        const companyId: string = company ? company : companyFromSession.id;
        const res = await this.get(`/api/routing-references/${routingReferenceId}/site`, null, { companyId });
        return res.data;
    }

    /**
     * Returns the company's sites.
     * @param {string} companyId
     * @return {Promise<SiteLightEntity[]>}
     */
    async getSitesLight(companyId: string): Promise<SiteLightEntity[]> {
        const res = await this.get('/api/companies/' + companyId + '/sites');
        return res.data;
    }

    /**
     * Returns the sites matching the rule
     * @param rule
     * @return {Promise<any>}
     */
    getSitesFromRule(rule): Promise<any> {
        const route = '/api/sites/rules';
        return this.post(route, { rule })
            .then(res => {
                if (res.code === 200 && res.data) {
                    return Promise.resolve(res.data);
                } else {
                    const err = { errorCode: 'error_getSitesFromRule' };
                    return Promise.reject(err);
                }
            })
            .catch(err => {
                // err.errorCode is set to 'error_getSitesFromRule';
                return Promise.reject(err);
            });
    }

    getSitesFromCustomFilter(customFilter): Promise<any> {
        return this.post('/api/custom-filters/preview', customFilter);
    }

    /**
     * Update site
     * @param {string} siteId - id of site to update
     * @param {*} data - data to update to site
     * @returns {Promise<*>} site updated
     */
    updateSite(siteId: string, data: any): Promise<APICitronResponse> {
        return this.put('/api/sites/crud/' + siteId, data);
    }

    removeRoutingReferenceFromSite(routingReferenceId, siteId) {
        return this.delete(`/api/sites/${siteId}/reference/${routingReferenceId}`);
    }

    /**
     * Search site address
     * @param {string} search - search query
     * @param {boolean} checkExisting - if true, check if an other site is located at the same address
     * @param {string} siteIdToExclude - id of site to exclude from existing check
     * @param {string} companyId
     * @returns {Promise<*>} address found
     */
    searchAddress(
        search: string,
        checkExisting: boolean = false,
        siteIdToExclude: string = null,
        companyId: string = null
    ): Promise<APICitronResponse> {
        const url = '/api/sites/search' + (checkExisting ? '?check=1' : '');
        return this.post(url, { search, site: siteIdToExclude, companyId });
    }

    /**
     * Save site new location
     * @param {string} siteId - id of site to save location of
     * @param {*} location - site location
     * @param {string} companyId
     * @returns {Promise<*>} site updated
     */
    saveLocation(siteId: string, location: any, companyId: string = null): Promise<APICitronResponse> {
        const data = Object.assign({ companyId }, location);
        return this.put(`/api/sites/${siteId}/location`, data);
    }

    /**
     * Check if the site has a fluid
     * @param site - site populated with routing references and contracts.
     * @param fluid - fluid to check
     * @returns {boolean} true if the site has the fluid, false otherwise
     */
    siteHasFluid(site, fluid: string) {
        if (!site.routingReferences || !site.routingReferences.length || !fluid) {
            return false;
        }
        return site.routingReferences.some(pdl => {
            return pdl.energyType === fluid;
        });
    }

    /**
     * Count routing references of a given fluid in a given site
     * @param {*} site - site populated with routing references and contracts.
     * @param {string} fluid - fluid to count
     * @returns {number} number of routing references having the fluid
     */
    countRoutingReferencesOfFluid(site: any, fluid: string): number {
        if (!site.routingReferences || !site.routingReferences.length || !fluid) {
            return 0;
        }
        const count = site.routingReferences.filter(pdl => {
            return pdl.energyType === fluid;
        }).length;
        return count;
    }

    deleteSite(siteId) {
        return this.delete('/api/sites/crud/' + siteId);
    }

    updateStatusSite(siteId, newStatus, revertHistoryId = null) {
        return this.put(`/api/sites/${siteId}/status`, { newStatus, revertHistoryId });
    }

    /**
     * Display load curve redirection button only if the selected site has an elec routing reference
     * @param {object} site
     * @param {string[]} fluids - fluids to look for inside site's routing references
     * @returns {boolean} true if
     */
    hasFluidRoutingReference(site: any, fluids: string[]): boolean {
        return Boolean(
            site && site.routingReferences && site.routingReferences.some(r => r && fluids.includes(r.energyType))
        );
    }

    /**
     * Sort sites populate by name (property `complement` then `name`).
     * /!\ Mutate original array
     * @param {SitePopulated[]} sites
     * @returns {SitePopulated[]} sites sorted by name
     */
    public sortSitesPopulatedByName(sites: SitePopulated[]): SitePopulated[] {
        sites.sort((a, b) => {
            if (a.complement && b.complement) {
                return a.complement.localeCompare(b.complement);
            }
            if (a.complement) {
                return -1;
            }
            if (b.complement) {
                return 1;
            }
            return 0;
        });
        return sites;
    }

    /**
     * Filter site on search
     * @param {SitePopulated} site - site to check if matching search
     * @param {string} search - search to match on site properties
     * @returns {boolean}
     */
    public filterSiteOnSearch(site: SitePopulated, search: string): boolean {
        if (search === '') {
            return true;
        }

        const stringToMatch = this.utils.removeAccentAndLowerCase(search);
        let exists = false;

        // search in code (complement)
        if (site.code) {
            exists = this.utils.removeAccentAndLowerCase(site.code).includes(stringToMatch);
        }

        // search in name (complement)
        if (!exists && site.complement) {
            exists = this.utils.removeAccentAndLowerCase(site.complement).includes(stringToMatch);
        }

        // search in address
        if (!exists && site.streetName && site.zipcode && site.city) {
            const address = `${site.streetNumber || ''} ${site.streetName} ${site.zipcode} ${site.city}`;
            const baseString = this.utils.removeAccentAndLowerCase(address);

            exists = baseString.includes(stringToMatch);
        }

        // search in pdls
        if (!exists) {
            exists = site.routingReferences.some(rr => {
                return rr.reference && rr.reference.toLowerCase().includes(stringToMatch);
            });
        }

        // search in contracts
        if (!exists) {
            exists = site.routingReferences.some(rr => {
                return (
                    rr.contracts &&
                    rr.contracts.some(c => c.reference && c.reference.toLowerCase().includes(stringToMatch))
                );
            });
        }

        // search in vehicles
        if (!exists) {
            exists = site.vehicles.some(veh => {
                return veh.registrationNumber && veh.registrationNumber.toLowerCase().includes(stringToMatch);
            });
        }

        return exists;
    }

    /**
     * Retrieve info of a given site
     */
    public async getSite(siteId: string): Promise<Site> {
        const route = `/api/sites/profile/${siteId}`;
        return this.get(route).then(response => response.data);
    }
}
