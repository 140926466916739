import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccessGuard } from '../../../shared/services/guards/access-guard.service';
import { SharedModule } from '../../../shared/shared.module';
import { CrossDataComponent } from './cross-data.component';

const MONITORING_ROUTE = [
    {
        path: '',
        component: CrossDataComponent,
        canActivate: [AccessGuard],
        data: { page: 'analyse' },
    },
];

@NgModule({
    declarations: [CrossDataComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(MONITORING_ROUTE)],
})
export class CrossDataModule {}
