import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { AccessGuard } from '../../shared/services/guards/access-guard.service';
import { CanDeactivateGuard } from '../../shared/services/guards/can-deactivate-guard.service';
import { DocumentsComponent } from './documents.component';
import { UploadSnackbarComponent } from './upload-snackbar.component';

const REGEX_ROUTE = [
    {
        path: '',
        component: DocumentsComponent,
        canActivate: [AccessGuard],
        canDeactivate: [CanDeactivateGuard],
        data: { redirectionForbiddenUrl: '/accueil' },
    },
];

@NgModule({
    declarations: [DocumentsComponent, UploadSnackbarComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(REGEX_ROUTE)],
    entryComponents: [UploadSnackbarComponent],
})
export class DocumentsModule {}
