import { Injectable } from '@angular/core';
import Highcharts = require('highcharts');
import * as _ from 'lodash';
import * as moment from 'moment';

// interfaces
import { AreasplineOptions, ChartOptionsHC, HeatmapOptions } from 'app/shared/models/charts/chart-properties.interface';
import {
    ChartSerieLight,
    DoubleDonutSerieIn,
    DoubleDonutSerieOut,
    GroupStackBarChartSerie,
} from 'app/shared/models/charts/chart-serie.interface';
import {
    ChartAxisGA,
    ChartDataSetsGA,
    ChartLegendGA,
    ChartLegendOptionsGA,
    ChartOptionsGA,
    ColorDonut,
    ColorGA,
    ToggleChartPropertiesLight,
} from 'app/shared/models/charts/charts.interface';

import {
    ChartTooltipOptionsGA,
    HighChartsDonutTooltip,
    HighchartsTooltipOptionsGA,
    TooltipConfigType,
    TooltipModelGA,
} from 'app/shared/models/charts/tooltips.interface';
import { ChartColor } from 'app/shared/models/colors.interface';

// services
import { ColorService } from '../color/color.service';
import { EnergyService } from '../energy/energy.service';
import { TranslateService } from '../translate/translate.service';
import { UtilsService } from '../utils/utils.service';

interface TooltipsOptionsStack {
    displayPercentage?: boolean;
    isHorizontal?: boolean;
    displayTotal?: boolean;
}

/**
 * This class handles all charts main operations like getting config, getting legends traduced...
 * It also handles your charts colors given your labels in partnership with ColorService
 */
@Injectable()
export class ChartService {
    private colors = this.colorService.getRgbColors();

    /**
     * Default ChartJs chart configuration
     */
    defaultChartConfig: ChartOptionsGA = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        layout: {
            padding: {
                left: 14,
                right: 14,
                top: 24,
                bottom: 24,
            },
        },
    };

    /**
     * Default Highcharts chart configuration
     */
    defaultHighChartConfig: ChartOptionsHC = {
        title: { text: '' },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
    };

    /**
     * Default Highcharts tooltips configuration
     */
    defaultHighChartsTooltip: HighchartsTooltipOptionsGA = {
        useHTML: true,
        backgroundColor: 'rgb(255,255,255)',
        borderColor: 'rgb(240,240,240)',
        borderRadius: 5,
        shadow: false,
        headerFormat: '',
    };

    /**
     * Mapping for chart name > chart configuration
     */
    private configs: {
        [key: string]: ChartOptionsGA | HeatmapOptions | AreasplineOptions;
    } = {
        stacked: Object.assign({}, this.defaultChartConfig, {
            dataset: {
                barThickness: 25,
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            display: true,
                        },
                        stacked: true,
                        gridLines: {
                            display: false,
                            zeroLineWidth: 0,
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            callback: label => Intl.NumberFormat('fr-FR').format(label),
                        },
                        stacked: true,
                        gridLines: {
                            display: true,
                            borderDashOffset: 2,
                            borderDash: [2],
                            zeroLineBorderDash: [2],
                            zeroLineBorderDashOffset: 2,
                        },
                    },
                ],
            },
            annotation: { annotations: [] },
            tooltips: {
                enabled: true,
                mode: 'index',
            },
            layout: {
                padding: {
                    left: 24,
                    right: 24,
                    top: 24,
                    bottom: 24,
                },
            },
        }),
        bar: Object.assign({}, this.defaultChartConfig, {
            scales: {
                yAxes: [
                    {
                        display: true,
                        gridLines: {
                            display: true,
                            borderDashOffset: 2,
                            borderDash: [2],
                            drawBorder: false,
                            drawTicks: false,
                            zeroLineBorderDash: [2],
                            zeroLineBorderDashOffset: 2,
                        },
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                            callback: value => {
                                return new Intl.NumberFormat('fr-FR', {
                                    maximumFractionDigits: 2,
                                }).format(value);
                            },
                            beginAtZero: true,
                        },
                    },
                ],
                xAxes: [
                    {
                        display: true,
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                        },
                        gridLines: {
                            display: false,
                            zeroLineWidth: 0,
                        },
                    },
                ],
            },
            annotation: { annotations: [] },
        }),
        horizontalBar: Object.assign({}, this.defaultChartConfig, {
            maintainAspectRatio: false,
            scales: {
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                            callback: value => {
                                return value.length > 20 ? `${value.substr(0, 20)}...` : value;
                            },
                        },
                        stacked: true,
                    },
                ],
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                            callback: value => {
                                return value.toLocaleString();
                            },
                        },
                        gridLines: {
                            display: true,
                        },
                    },
                ],
            },
            annotation: { annotations: [] },
            tooltips: {
                mode: 'point',
            },
        }),
        mixed: Object.assign({}, this.defaultChartConfig, {
            elements: {
                line: {
                    fill: false,
                },
            },
            scales: {
                yAxes: [
                    {
                        display: true,
                        position: 'left',
                        id: 'y-axis-0',
                        stacked: false,
                        scaleLabel: {
                            display: false,
                        },
                        afterTickToLabelConversion: this.removeMinAndMax,
                        gridLines: {
                            display: true,
                            borderDashOffset: 2,
                            borderDash: [2],
                            drawBorder: false,
                            drawTicks: false,
                            zeroLineBorderDash: [2],
                            zeroLineBorderDashOffset: 2,
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 10,
                            callback: label => Intl.NumberFormat('fr-FR').format(label),
                        },
                    },
                    {
                        display: true,
                        position: 'right',
                        id: 'y-axis-1',
                        stacked: true,
                        afterTickToLabelConversion: this.removeMinAndMax,
                        ticks: {
                            beginAtZero: true,
                            padding: 10,
                            callback: label => {
                                return Intl.NumberFormat('fr-FR').format(label);
                            },
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false,
                            drawTicks: false,
                            zeroLineWidth: 0,
                        },
                    },
                    {
                        display: false,
                        id: 'y-axis-2',
                        stacked: false,
                        ticks: {},
                    },
                ],
                xAxes: [
                    {
                        display: true,
                        id: 'x-axis-0',
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                            callback: value => {
                                return value.toLocaleString();
                            },
                        },
                        gridLines: {
                            display: false,
                            zeroLineWidth: 0,
                        },
                    },
                ],
            },
            annotation: { annotations: [] },
        }),
        'groupable-stacked-bar': Object.assign({}, this.defaultChartConfig, {
            dataset: {
                barPercentage: 0.8,
                categoryPercentage: 0.7,
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                        },
                        stacked: true,
                        gridLines: {
                            display: false,
                            zeroLineWidth: 0,
                        },
                    },
                ],
                yAxes: [
                    {
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                            callback: value => {
                                return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
                                    Math.round(value)
                                );
                            },
                        },
                        scaleLabel: {
                            fontSize: 15,
                            display: true,
                        },
                        stacked: true,
                        gridLines: {
                            display: true,
                            borderDashOffset: 2,
                            borderDash: [2],
                            drawBorder: false,
                            drawTicks: false,
                            zeroLineBorderDash: [2],
                            zeroLineBorderDashOffset: 2,
                        },
                    },
                ],
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 24,
                    bottom: 24,
                },
            },
            annotation: { annotations: [] },
        }),
        'mixed-groupable-stacked-bar': Object.assign({}, this.defaultChartConfig, {
            dataset: {
                barPercentage: 0.8,
                categoryPercentage: 0.7,
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                        },
                        stacked: true,
                        gridLines: {
                            display: false,
                            zeroLineWidth: 0,
                        },
                    },
                ],
                yAxes: [
                    {
                        position: 'left',
                        id: 'y-axis-0',
                        stacked: true,
                        ticks: {
                            padding: 10,
                            fontSize: 13,
                            beginAtZero: true,
                            callback: value => {
                                return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
                                    Math.round(value)
                                );
                            },
                        },
                        afterTickToLabelConversion: this.removeMinAndMax,
                        scaleLabel: {
                            fontSize: 15,
                            display: true,
                        },
                        gridLines: {
                            display: true,
                            borderDashOffset: 2,
                            borderDash: [2],
                            drawBorder: false,
                            drawTicks: false,
                            zeroLineBorderDash: [2],
                            zeroLineBorderDashOffset: 2,
                        },
                    },
                    {
                        position: 'right',
                        id: 'y-axis-1',
                        stacked: true,
                        afterTickToLabelConversion: this.removeMinAndMax,
                        ticks: {
                            beginAtZero: true,
                            padding: 10,
                            fontSize: 13,
                            callback: value => {
                                return new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(
                                    Math.round(value)
                                );
                            },
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false,
                            drawTicks: false,
                            zeroLineWidth: 0,
                        },
                    },
                ],
            },
            annotation: { annotations: [] },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 24,
                    bottom: 24,
                },
            },
            elements: {
                line: {
                    fill: false,
                },
            },
        }),
        heatmap: Object.assign({}, this.defaultHighChartConfig, {
            chart: {
                type: 'heatmap',
                spacing: [20, 0, 0, 0],
                scrollablePlotArea: {
                    scrollPositionX: 0,
                },
            },
            xAxis: {
                tickInverval: 1,
            },
            yAxis: {
                gridLineWidth: 0,
                title: {
                    enabled: true,
                    text: '% de jours couverts',
                    margin: 40,
                },
            },
            series: [
                {
                    allowPointSelect: true,
                    borderColor: 'white',
                    borderWidth: 0.8,
                },
            ],
            legend: {
                display: true,
                title: { text: 'Nombre de PDLs', style: null },
                width: '100%',
                layout: 'horizontal',
                margin: 30,
                x: 55,
            },
        }),
        areaspline: {
            chart: {
                type: 'areaspline',
                zoomType: 'x',
            },
            xAxis: [
                {
                    id: 'xA0',
                    type: 'datetime',
                    gridLineDashStyle: 'LongDash',
                    labels: {
                        formatter() {
                            return Highcharts.dateFormat('%a %d/%m/%Y %H:%M', this.value);
                        },
                        align: 'right',
                        rotation: -15,
                        staggerLines: 1,
                        style: {
                            fontSize: '10px',
                        },
                    },
                    dateTimeLabelFormats: {
                        second: { main: '%Y-%m-%d<br/>%H:%M:%S' },
                        minute: { main: '%Y-%m-%d<br/>%H:%M' },
                        hour: { main: '%Y-%m-%d<br/>%H:%M' },
                        day: { main: '%Y<br/>%m-%d' },
                        week: { main: '%Y<br/>%m-%d' },
                        month: { main: '%Y-%m' },
                        year: { main: '%Y' },
                    },
                    plotBands: [],
                },
            ],
            yAxis: [
                {
                    id: 'yA0',
                    title: {
                        text: 'W',
                        align: 'high',
                        offset: 0,
                        rotation: 0,
                        y: -10,
                    },
                    gridLineDashStyle: 'LongDash',
                    min: 0,
                },
            ],
            tooltip: {
                backgroundColor: 'white',
                shadow: false,
                formatter() {
                    let s = `${Highcharts.dateFormat('%a %d/%m/%Y %H:%M', this.x)}`;
                    for (let i = 0, len = this.points.length; i < len; i++) {
                        s += `<br/>${this.points[i].series.name} : <b>${Math.round(this.points[i].y)} W</b>`;
                    }
                    return s;
                },
                shared: true,
            },
            rangeSelector: {
                selected: 2,
                inputEnabled: false,
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '24h',
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1sem',
                    },
                    {
                        type: 'all',
                        text: 'Tout',
                    },
                ],
            },
            series: [],
            credits: {
                enabled: false,
            },
            title: {
                text: '',
            },
            plotOptions: {
                areaspline: {
                    stacking: 'normal',
                },
                series: {
                    dataGrouping: {
                        enabled: true,
                    },
                },
            },
            boost: {
                allowForce: true,
                enabled: true,
            },
            legend: {
                enabled: true,
                verticalAlign: 'top',
            },
            exporting: {
                enabled: false,
            },
        },
    };

    constructor(
        private colorService: ColorService,
        private utilsService: UtilsService,
        private energyService: EnergyService,
        private translateService: TranslateService
    ) {}

    removeMinAndMax(scaleInstance) {
        // set the first and last tick to null so it does not display
        // note, ticks[0] is the last tick and ticks[length - 1] is the first
        if (scaleInstance.ticks[0] !== 0) {
            scaleInstance.ticks[0] = null;
        }
        if (scaleInstance.ticks[scaleInstance.ticks.length - 1] !== '0') {
            scaleInstance.ticks[scaleInstance.ticks.length - 1] = null;
        }

        // need to do the same thing for this similiar array which is used internally
        if (scaleInstance.ticksAsNumbers[0] !== 0) {
            scaleInstance.ticksAsNumbers[0] = null;
        }
        if (scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] !== 0) {
            scaleInstance.ticksAsNumbers[scaleInstance.ticksAsNumbers.length - 1] = null;
        }
    }

    /**
     * Get tooltips HTML config
     * @param {'highcharts-doughnut'|TooltipConfigType} type - type of HTML config to get
     * @param {*} args - arguments depending on type. First one is always unit (string)(leave null for automatic).
     * Second one is years (array) or horizontal (boolean)
     */
    getTooltipHTMLConfig(type: 'highcharts-doughnut', ...args): HighChartsDonutTooltip;
    getTooltipHTMLConfig(type: 'heatmap', ...args): HighchartsTooltipOptionsGA;
    getTooltipHTMLConfig(type: TooltipConfigType, ...args): ChartTooltipOptionsGA;
    getTooltipHTMLConfig(type: string, ...args) {
        const types = [
            'stack',
            'groupable-stack-followup',
            'monthly',
            'yearly',
            'yearOverYear',
            'ng-chart-bar-line',
            'horizontal-bar',
            'highcharts-doughnut',
            'heatmap',
            'default',
        ];

        if (!types.includes(type)) {
            throw Error('Please provide good type');
        }
        const unit = args[0];
        const that = this;

        switch (type) {
            /*
             * args[0] : not used
             * args[1] : groupNames, ex : ['Tous les sites'] or ['Ile-de-France', 'Alsace', ...]
             * args[2] : string to display inside the tooltip for the group's total, ex "Total de la région"
             * */
            case 'groupable-stack-followup': {
                const groupNames = args[1];
                const totalGroupName = args[2];
                const hasMultipleGroups = Boolean(groupNames && groupNames.length > 1);

                const tGroupable: ChartTooltipOptionsGA = {
                    backgroundColor: 'rgb(255,255,255)',
                    titleFontColor: 'rgb(0,0,0)',
                    bodyFontColor: 'rgb(0,0,0)',
                    enabled: false,

                    custom(tooltipModel: TooltipModelGA) {
                        // no tooltip when there is no bar for that month
                        if (!tooltipModel.dataPoints || !tooltipModel.dataPoints[0].yLabel) {
                            tooltipModel.opacity = 0;
                        }

                        // generate the tootltip HTML base
                        that.displayTooltip(tooltipModel, this._chart, () => {
                            const data = this._chart.config.data;

                            const point_datasetIndex: number = tooltipModel.dataPoints[0].datasetIndex;

                            const point_groupIndex: number = data.datasets[point_datasetIndex].stack; // index of the group's bar, ex: 1 for 'Alsace'
                            const point_groupName = groupNames[point_groupIndex - 1];
                            const point_value = tooltipModel.dataPoints[0].yLabel;

                            // unit
                            const point_unit = that.getUnit(tooltipModel.dataPoints, data);
                            const point_energyLabel = data.datasets[point_datasetIndex].label;
                            const point_energyFrench = that.translateService._(point_energyLabel);

                            // set the HTML
                            let innerHtml = '<thead>';

                            // 1. group name if there is one, ex : 'Alsace'
                            innerHtml += '<tr><th colspan="2">' + point_groupName + '</th></tr>';

                            // 2. energy name : value
                            const displayed_pointValue = that.getNumberToDisplay(point_value, point_unit);

                            // if tooltip for line chart
                            if (data.datasets[point_datasetIndex].type !== 'line') {
                                innerHtml +=
                                    '<tr><th>' +
                                    point_energyFrench +
                                    ' :   </th><th> ' +
                                    displayed_pointValue +
                                    '</th></tr></thead>';

                                const point_monthIndex = tooltipModel.dataPoints[0].index;
                                let group_nbFluids = 0; // count the nb of fluids for that month inside that group
                                let energy_sumGroups = 0; // energy_sumGroups = the sum of every group's value for this fluid & that month
                                let group_sumFluids = 0; // group_sumFluids = the sum of every fluids for this group & that month
                                this._chart.config.data.datasets.forEach(dataset => {
                                    // sum the bar chart values
                                    if (dataset.type === 'bar') {
                                        // same fluid
                                        if (dataset.label === point_energyLabel) {
                                            energy_sumGroups += dataset.data[point_monthIndex];
                                        }
                                        // same group
                                        if (dataset.stack === point_groupIndex) {
                                            const value = dataset.data[point_monthIndex];
                                            group_nbFluids += value ? 1 : 0;
                                            group_sumFluids += value;
                                        }
                                    }
                                });
                                // percentage of the point among its own group
                                const percentage_overFluids: number = (100 * (point_value as number)) / group_sumFluids;
                                // percentage of the point among the other groups for the same energy
                                const percentage_overGroups: number =
                                    (100 * (point_value as number)) / energy_sumGroups;

                                innerHtml += '<tbody>';
                                // ---------------
                                // 3. Total for the group + percentage
                                // if 1 group display "Total :"
                                // if more than 1 group "Total + groupname : ", ex "Total Alsace : "
                                if (group_nbFluids > 1) {
                                    const displayed_sumFluids = that.getNumberToDisplay(group_sumFluids, point_unit);
                                    const displayed_percentageOverFluids = that.getNumberToDisplay(
                                        percentage_overFluids,
                                        '%'
                                    );

                                    const title = hasMultipleGroups ? totalGroupName : 'Total';
                                    innerHtml +=
                                        '<tr><td> --------------- </td></tr>' +
                                        '<tr><td>' +
                                        title +
                                        ' : </td><td> ' +
                                        displayed_sumFluids +
                                        '</td></tr>' +
                                        '<tr><td> Part :   </td><td> ' +
                                        displayed_percentageOverFluids +
                                        '</td></tr>';
                                }

                                // ---------------
                                // 4. Total for the month + percentage
                                if (hasMultipleGroups) {
                                    const displayed_sumGroups = that.getNumberToDisplay(energy_sumGroups, point_unit);

                                    const displayed_percentageOverGroups = that.getNumberToDisplay(
                                        percentage_overGroups,
                                        '%'
                                    );

                                    innerHtml +=
                                        '<tr><td> --------------- </td></tr>' +
                                        '<tr><td> Total du mois :   </td><td> ' +
                                        displayed_sumGroups +
                                        '</td></tr>' +
                                        '<tr><td> Part :   </td><td> ' +
                                        displayed_percentageOverGroups +
                                        '</td></tr>';
                                }

                                innerHtml += '</tbody>';
                            } else {
                                innerHtml += '<tr><td>' + displayed_pointValue + '</td></tr></thead>';
                            }

                            return innerHtml;
                        });
                    },
                };
                return tGroupable;
            }
            case 'stack': {
                const options: TooltipsOptionsStack = {
                    displayPercentage: true,
                    isHorizontal: false,
                    displayTotal: true,
                };
                const customOptions = args[1] || {};

                Object.assign(options, customOptions);

                const tStack: ChartTooltipOptionsGA = {
                    backgroundColor: 'rgb(255,255,255)',
                    titleFontColor: 'rgb(0,0,0)',
                    bodyFontColor: 'rgb(0,0,0)',
                    enabled: false,
                    mode: 'point',
                    custom(tooltipModel: TooltipModelGA) {
                        // no tooltip when there is no point (stack bar) for that month
                        if (!tooltipModel.dataPoints || !tooltipModel.dataPoints[0].yLabel) {
                            tooltipModel.opacity = 0;
                        }

                        // generate the tootltip HTML base
                        that.displayTooltip(tooltipModel, this._chart, () => {
                            const datasets = this._chart.config.data.datasets;

                            const point_datasetIndex = tooltipModel.dataPoints[0].datasetIndex;
                            const point_energyLabel = datasets[point_datasetIndex].label;
                            const point_monthIndex = tooltipModel.dataPoints[0].index;
                            const point_datasetType = datasets[point_datasetIndex].type;

                            let point_titleFrench = that.translateService._(point_energyLabel);
                            if (!point_titleFrench) {
                                point_titleFrench = that.energyService.energyFullText(point_energyLabel);
                            }

                            let point_value = tooltipModel.dataPoints[0].yLabel;
                            let point_label = tooltipModel.dataPoints[0].xLabel;

                            // Here args[1] is for horizontal => We switch x axis and y axis
                            if (options.isHorizontal === true) {
                                point_value = tooltipModel.dataPoints[0].xLabel;

                                // When changing the label for shortened with triple points, the tooltip need to retrieve the original label instead of the shortened one
                                point_label = this._chart.config.data.labels[point_monthIndex];
                            }

                            let group_nbStackedElements = 0; // count the nb of fluids for that month inside that group
                            let group_sumFluids = 0; // group_sumFluids = the sum of every fluids for this group & that month
                            datasets.forEach(dataset => {
                                if (dataset.type === point_datasetType) {
                                    const value = dataset.data[point_monthIndex];
                                    group_nbStackedElements += value ? 1 : 0;
                                    group_sumFluids += value;
                                }
                            });

                            // percentage of the fluid among the other fluids
                            const percentage_overFluids: number = (100 * (point_value as number)) / group_sumFluids;

                            // unit
                            const point_unit = datasets[point_datasetIndex].unit;

                            // 1. month label - energy
                            let innerHtml = '<thead><tr><th colspan="2">' + point_label + '</th></tr></thead>';

                            let displayed_pointValue = that.getNumberToDisplay(point_value, point_unit);

                            if (group_nbStackedElements > 1) {
                                if (options.displayPercentage) {
                                    displayed_pointValue += ' - ' + that.getNumberToDisplay(percentage_overFluids, '%');
                                }

                                if (options.displayTotal) {
                                    // 2. title and value of the point
                                    innerHtml +=
                                        '<tbody>' +
                                        '<tr><th>' +
                                        point_titleFrench +
                                        ' : </th><th>' +
                                        displayed_pointValue +
                                        '</th></tr>';
                                    const displayed_sumPoints = that.getNumberToDisplay(group_sumFluids, point_unit);
                                    // 3. Total for the group + percentage
                                    innerHtml +=
                                        '<tr><td> Total :   </td><td> ' + displayed_sumPoints + '</td></tr></tbody>';
                                } else {
                                    // 2. title and value of the point
                                    innerHtml +=
                                        '<tbody>' +
                                        '<tr><td>' +
                                        point_titleFrench +
                                        ' : </th><td>' +
                                        displayed_pointValue +
                                        '</th></tr></tbody>';
                                }
                            } else {
                                // 2. value without percentage
                                innerHtml +=
                                    '<tbody><tr><th>' +
                                    point_titleFrench +
                                    ' : </th><th>' +
                                    displayed_pointValue +
                                    '</th></tr></tbody>';
                            }

                            return innerHtml;
                        });
                    },
                };
                return tStack;
            }
            case 'monthly': {
                const years = args[1];

                const tMonthly: ChartTooltipOptionsGA = {
                    backgroundColor: 'rgb(255,255,255)',
                    titleFontColor: 'rgb(0,0,0)',
                    bodyFontColor: 'rgb(0,0,0)',
                    enabled: false,
                    custom(tooltipModel: TooltipModelGA) {
                        that.displayTooltip(tooltipModel, this._chart, () => {
                            const datasets = this._chart.config.data.datasets;
                            const titleLines = ['PERIMETRE DU FILTRE'];
                            const bodyLines = [];
                            bodyLines.push('Consommations');

                            const consumptionsByYearOnCurrentMonth = that.getConsumptionMonthly(
                                tooltipModel.dataPoints[0],
                                datasets,
                                years
                            );
                            consumptionsByYearOnCurrentMonth.forEach(item => {
                                const itemUnit = that.getUnit(tooltipModel.dataPoints, this._chart.config.data);
                                bodyLines.push(item.year + ' : ' + that.getNumberToDisplay(item.consumption, itemUnit));
                            });

                            if (consumptionsByYearOnCurrentMonth && consumptionsByYearOnCurrentMonth.length > 1) {
                                // get the comparison in % between the current year and year-1 & year-2 (for the same month)
                                const currentYear = consumptionsByYearOnCurrentMonth[0].year;
                                const comparisons = that.getComparisons(consumptionsByYearOnCurrentMonth);

                                // if there is no data for the current month/year, don't display any comparison
                                if (comparisons.some(item => item.variation)) {
                                    bodyLines.push('---------------');
                                    bodyLines.push('Comparaison');

                                    comparisons.forEach(item => {
                                        if (item.variation) {
                                            bodyLines.push(
                                                currentYear +
                                                    ' vs ' +
                                                    item.year +
                                                    ' : ' +
                                                    that.getNumberToDisplay(item.variation, '%')
                                            );
                                        }
                                    });
                                }
                            }

                            let innerHtml = '<thead>';

                            titleLines.forEach(title => {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(body => {
                                innerHtml += '<tr><td>' + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            return innerHtml;
                        });
                    },
                };
                return tMonthly;
            }
            case 'yearly': {
                const years = args[1];

                const tYearly: ChartTooltipOptionsGA = {
                    backgroundColor: 'rgb(255,255,255)',
                    titleFontColor: 'rgb(0,0,0)',
                    bodyFontColor: 'rgb(0,0,0)',
                    enabled: false,
                    custom(tooltipModel: TooltipModelGA) {
                        that.displayTooltip(tooltipModel, this._chart, () => {
                            const datasets = this._chart.config.data.datasets;
                            const bodyLines = [];
                            const titleLines = [];

                            const currentDatasetIndex = tooltipModel.dataPoints[0].datasetIndex;

                            // handle consumption tooltip
                            if (datasets[currentDatasetIndex].type === 'bar') {
                                titleLines.push('ANNÉE CALENDAIRE');
                                bodyLines.push('Consommations');

                                const consoDatasets = datasets.filter(dataset => dataset.type === 'bar'); // don't sum up the dju
                                const consumptionsByYear = that.getConsumptionYearly(
                                    tooltipModel.dataPoints[0],
                                    consoDatasets,
                                    years
                                );

                                consumptionsByYear.forEach(item => {
                                    const itemUnit = that.getUnit(tooltipModel.dataPoints, this._chart.config.data);
                                    bodyLines.push(
                                        item.year + ' : ' + that.getNumberToDisplay(item.consumption, itemUnit)
                                    );
                                });

                                if (consumptionsByYear && consumptionsByYear.length > 1) {
                                    // get the comparison in % between the current year pointed the years before
                                    const currentYear = consumptionsByYear[0].year;

                                    const comparisons = that.getComparisons(consumptionsByYear);

                                    // if there is no data for the current year, all variations are null, don't display any comparison
                                    if (comparisons.some(item => item.variation)) {
                                        bodyLines.push('---------------');
                                        bodyLines.push('Comparaison');

                                        comparisons.forEach(item => {
                                            if (item.variation) {
                                                bodyLines.push(
                                                    currentYear +
                                                        ' vs ' +
                                                        item.year +
                                                        ' : ' +
                                                        that.getNumberToDisplay(item.variation, '%')
                                                );
                                            }
                                        });
                                    }
                                }
                            } else {
                                // handle DJU tooltip
                                titleLines.push(tooltipModel.dataPoints[0].xLabel);
                                const pointValue = tooltipModel.dataPoints[0].yLabel;
                                const pointUnit = that.getUnit(tooltipModel.dataPoints, this._chart.config.data);
                                bodyLines.push(that.getNumberToDisplay(pointValue, pointUnit));
                            }

                            // Build html
                            let innerHtml = '<thead>';

                            titleLines.forEach(title => {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(body => {
                                innerHtml += '<tr><td>' + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            return innerHtml;
                        });
                    },
                };
                return tYearly;
            }
            case 'yearOverYear': {
                const years = args[1];

                const tYearOverYear: ChartTooltipOptionsGA = {
                    backgroundColor: 'rgb(255,255,255)',
                    titleFontColor: 'rgb(0,0,0)',
                    bodyFontColor: 'rgb(0,0,0)',
                    enabled: false,
                    custom(tooltipModel: TooltipModelGA) {
                        that.displayTooltip(tooltipModel, this._chart, () => {
                            const datasets = this._chart.config.data.datasets;
                            const currentDatasetIndex = tooltipModel.dataPoints[0].datasetIndex;

                            const bodyLines = [];
                            const titleLines = [];

                            // handle consumption tooltip
                            if (datasets[currentDatasetIndex].type === 'bar') {
                                titleLines.push('ANNÉE GLISSANTE');
                                bodyLines.push('Consommations');
                                const consumptionsByYearOnCurrentMonth = that.getConsumptionMonthly(
                                    tooltipModel.dataPoints[0],
                                    datasets,
                                    years
                                );
                                consumptionsByYearOnCurrentMonth.forEach(item => {
                                    const itemUnit = that.getUnit(tooltipModel.dataPoints, this._chart.config.data);
                                    bodyLines.push(that.getNumberToDisplay(item.consumption, itemUnit));
                                });
                            } else {
                                // handle DJU tooltip
                                titleLines.push(tooltipModel.dataPoints[0].xLabel);
                                const pointValue = tooltipModel.dataPoints[0].yLabel;
                                const pointUnit = that.getUnit(tooltipModel.dataPoints, this._chart.config.data);
                                bodyLines.push(that.getNumberToDisplay(pointValue, pointUnit));
                            }

                            let innerHtml = '<thead>';

                            titleLines.forEach(title => {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(body => {
                                innerHtml += '<tr><td>' + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            return innerHtml;
                        });
                    },
                };
                return tYearOverYear;
            }
            case 'ng-chart-bar-line': {
                const tBarLine: ChartTooltipOptionsGA = {
                    backgroundColor: 'rgb(255,255,255)',
                    titleFontColor: 'rgb(0,0,0)',
                    bodyFontColor: 'rgb(0,0,0)',
                    enabled: false,

                    custom(tooltipModel: TooltipModelGA) {
                        that.displayTooltip(tooltipModel, this._chart, () => {
                            let innerHtml = '<thead>';

                            innerHtml += '<tr><th>' + tooltipModel.dataPoints[0].xLabel + '</th></tr>';

                            innerHtml += '</thead><tbody>';

                            const body = that.getNumberToDisplay(
                                tooltipModel.dataPoints[0].yLabel,
                                that.getUnit(tooltipModel.dataPoints, this._chart.config.data, unit)
                            );

                            innerHtml += '<tr><td>' + body + '</td></tr>';

                            innerHtml += '</tbody>';

                            return innerHtml;
                        });
                    },
                };
                return tBarLine;
            }

            case 'horizontal-bar': {
                const hBar: ChartTooltipOptionsGA = {
                    backgroundColor: 'rgb(255,255,255)',
                    titleFontColor: 'rgb(0,0,0)',
                    bodyFontColor: 'rgb(0,0,0)',
                    mode: 'point',
                    enabled: false,
                    custom(tooltipModel: TooltipModelGA) {
                        that.displayTooltip(tooltipModel, this._chart, () => {
                            let innerHtml = '';
                            const indexes: number[] = [];
                            let labelIndex: number = null;
                            let dataSetIndex: number = null;
                            for (let i = 0, iLen = tooltipModel.dataPoints.length; i < iLen; i++) {
                                labelIndex = tooltipModel.dataPoints[i].index;
                                dataSetIndex = tooltipModel.dataPoints[i].datasetIndex;
                            }
                            // show all the tooltip for a stack
                            for (let i = 0, iLen = this._chart.tooltip._data.datasets.length; i < iLen; i++) {
                                if (
                                    this._chart.tooltip._data.datasets[dataSetIndex].stack ===
                                    this._chart.tooltip._data.datasets[i].stack
                                ) {
                                    indexes.push(i);
                                }
                            }

                            if (labelIndex !== null) {
                                innerHtml += '<thead>';
                                innerHtml += '<tr><th>';
                                innerHtml += this._chart.tooltip._data.labels[labelIndex];
                                innerHtml += ' - ' + this._chart.tooltip._data.datasets[dataSetIndex].customData[0];
                                innerHtml += ' à ' + this._chart.tooltip._data.datasets[dataSetIndex].customData[1];
                                innerHtml += '</th></tr>';
                                innerHtml += '</thead><tbody>';

                                for (const index of indexes) {
                                    let value: number;

                                    try {
                                        const values: number[] = this._chart.tooltip._data.datasets[index].data[
                                            labelIndex
                                        ];

                                        if (values[0] === 0) {
                                            value = values[1];
                                        } else {
                                            value = (values[0] + values[1]) / 2;
                                        }
                                    } catch (e) {}
                                    if (value !== undefined) {
                                        const body = that.getNumberToDisplay(
                                            value,
                                            that.getUnit(tooltipModel.dataPoints, this._chart.config.data, unit)
                                        );

                                        innerHtml +=
                                            '<tr><td>' +
                                            this._chart.tooltip._data.datasets[index].label +
                                            ' : ' +
                                            body +
                                            '</td></tr>';
                                        innerHtml += '</tbody>';
                                    }
                                }
                            }
                            return innerHtml;
                        });
                    },
                };
                return hBar;
            }
            case 'highcharts-doughnut': {
                const tDonut: HighChartsDonutTooltip = Object.assign({}, this.defaultHighChartsTooltip, {
                    outside: true,
                    hideDelay: 0,
                    valueDecimals: this.getNbDecimalsForUnit(unit),
                    pointFormatter() {
                        const label = that.getLabelForTooltip(this.name);
                        const styleLabel = `style="
                        padding-bottom: 6px;
                        font-size: 12px;
                        color:#607188;
                        font-family: 'Montserrat', sans-serif;
                        overflow-wrap: break-word;
                        font-weight: bold;"`;

                        const style = `style="
                        font-size: 12px;
                        color:#607188;
                        font-family: 'Montserrat', sans-serif;
                        overflow-wrap: break-word;"`;

                        let body = `<div ${styleLabel}> ${label} </div>
                            <div ${style}> ${that.getNumberToDisplay(this.y, unit)}</div>`;

                        if (this.percentageCat) {
                            body += `<div ${style}> ${that.getNumberToDisplay(this.percentageCat, '%')}</div>`;
                        } else if (this.percentage < 100) {
                            body += `<div ${style}> ${that.getNumberToDisplay(this.percentage, '%')}</div>`;
                        }

                        return body;
                    },
                });
                return tDonut;
            }
            case 'heatmap': {
                return this.defaultHighChartsTooltip;
            }
            default:
            case 'default': {
                /*
                 * args[0] {string[]|string} : units of datasets. If a string, same for all
                 */
                const tDefault: ChartTooltipOptionsGA = {
                    backgroundColor: 'rgb(255,255,255)',
                    titleFontColor: 'rgb(0,0,0)',
                    bodyFontColor: 'rgb(0,0,0)',
                    enabled: false,

                    custom(tooltipModel: TooltipModelGA) {
                        that.displayTooltip(tooltipModel, this._chart, () => {
                            let innerHtml = '<thead>';

                            innerHtml += '<tr><th>' + tooltipModel.dataPoints[0].xLabel + '</th></tr>';

                            innerHtml += '</thead><tbody>';
                            const itemUnit = Array.isArray(unit) ? unit[tooltipModel.dataPoints[0].datasetIndex] : unit;
                            const body = that.getNumberToDisplay(tooltipModel.dataPoints[0].yLabel, itemUnit);

                            innerHtml += '<tr><td>' + body + '</td></tr>';

                            innerHtml += '</tbody>';

                            return innerHtml;
                        });
                    },
                };
                return tDefault;
            }
        }
    }

    /**
     * Set French options on Highcharts
     */
    setFrenchOptions() {
        Highcharts.setOptions({
            time: {
                timezone: 'Europe/Paris',
            },
            lang: {
                months: [
                    'Janvier',
                    'Février',
                    'Mars',
                    'Avril',
                    'Mai',
                    'Juin',
                    'Juillet',
                    'Août',
                    'Septembre',
                    'Octobre',
                    'Novembre',
                    'Décembre',
                ],
                weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            },
        });
    }

    /**
     * Display chartjs tooltip in page
     * @param {TooltipModelGA} tooltipModel - tooltip data for display
     * @param {Chart} chart - chart object
     * @param {Function} buildBodyFunction - function that returns a string with the HTML body of the tooltip
     */
    displayTooltip(tooltipModel: TooltipModelGA, chart: Chart, buildBodyFunction: () => string) {
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
        }

        // Remove if no tooltip
        if (tooltipModel.opacity === 0) {
            tooltipEl.remove();
            return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');

        if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
            tooltipEl.classList.add('no-transform');
        }

        // Set Text
        if (tooltipModel.body) {
            const innerHtml = buildBodyFunction();

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
        }

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';

        tooltipEl.style.border = 'solid 1px rgb(240,240,240)';
        tooltipEl.style.borderRadius = '5px';
        tooltipEl.style.backgroundColor = tooltipModel.backgroundColor.toString();

        tooltipEl.style.fontFamily = "'Montserrat', sans-serif";
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
        tooltipEl.style['z-index'] = 2;

        // `chartPosition` will be the overall chart
        const chartPosition = chart.canvas.getBoundingClientRect();

        /** position X of the tooltip **/
        const positionBarLeft = chartPosition.left + window.scrollX + tooltipModel.caretX;

        // when the tooltip goes outside the chart's right border
        // display tooltip where there is the biggest space: on the left or the right side of the bar
        const spaceFromChartRightBorderToBar = chartPosition.width - tooltipModel.caretX; // space between the chart right border and the bar
        const spaceFromChartLeftBorderToBar = tooltipModel.caretX; // space between the chart left border and the bar

        const windowScreenWidth = document.getElementById('app_wrapper').getBoundingClientRect().width; // width of the screen
        const spaceFromWindowToChartRightBorder = windowScreenWidth - chartPosition.right; // space between the window right border and the chart right border

        // bigger space on the left side --> display on the left
        if (spaceFromChartLeftBorderToBar > spaceFromChartRightBorderToBar) {
            tooltipEl.style.right = spaceFromWindowToChartRightBorder + spaceFromChartRightBorderToBar - 15 + 'px';
            // if tooltip goes outside the chart's right border --> fix the right position of the tooltip on the chart's right border
            if (tooltipEl.getBoundingClientRect().width + 15 > spaceFromChartLeftBorderToBar) {
                tooltipEl.style.left = (chartPosition as DOMRect).x + 20 + 'px';
            }
        } else {
            // bigger space on the right side --> display on the right
            tooltipEl.style.left = positionBarLeft + 15 + 'px';
            // tooltipEl.style.right = spaceFromWindowToChartRightBorder + 'px';
        }

        /** position Y of the tooltip **/
        // position Y on the top of the bar, unless the bottom part is outside the screen window
        const positionBarTop = chartPosition.top + window.scrollY + tooltipModel.caretY;
        const tooltipHeight = tooltipEl.getBoundingClientRect().height;
        const screenBottom = document.getElementById('app_wrapper').getBoundingClientRect().height;
        const spaceYFromScreenWindow = positionBarTop + tooltipHeight - screenBottom;
        if (spaceYFromScreenWindow >= 0) {
            tooltipEl.style.top = positionBarTop - spaceYFromScreenWindow - 15 + 'px';
        } else {
            tooltipEl.style.top = positionBarTop + 'px';
        }
    }

    getComparisons(consumptionsByYearOnCurrentMonth) {
        // The order is from higher to smaller year | 2018, 2017, 2016
        consumptionsByYearOnCurrentMonth.sort((a, b) => {
            return b.year - a.year;
        });

        // Keep the lastest year
        const currentYear = consumptionsByYearOnCurrentMonth.shift();

        // Get calculation with the consumption
        return consumptionsByYearOnCurrentMonth.reduce((memo, currentItem) => {
            let variationFromLastestYear = null;

            if (currentYear.consumption && currentItem.consumption) {
                const increase = currentYear.consumption - currentItem.consumption;
                const increasePourcentage = (increase / currentItem.consumption) * 100;
                variationFromLastestYear = increasePourcentage.toString();
            }

            memo.push({
                year: currentItem.year,
                variation: variationFromLastestYear,
            });

            return memo;
        }, []);
    }

    getConsumptionByMonth(currentMonthIndex, currentYearIndex, datasets) {
        // Get all months data for the same year (same stack)
        const monthData = datasets.filter(item => item.stack === currentYearIndex);

        // Sum all months data
        return monthData.reduce((memo, currentItem) => {
            if (currentItem.type === 'bar' && currentItem.data && currentItem.data[currentMonthIndex]) {
                memo += currentItem.data[currentMonthIndex];
            }
            return memo;
        }, 0);
    }

    getConsumptionByYear(currentYearIndex, datasets) {
        // Sum all months data
        return datasets.reduce((memo, currentItem) => {
            if (currentItem.data && currentItem.data[currentYearIndex]) {
                memo += currentItem.data[currentYearIndex];
            }
            return memo;
        }, 0);
    }

    getConsumptionYearly(tooltipItem, datasets, years) {
        const currentYearIndex = tooltipItem.index;
        const consumptionYear = [];

        for (let k = currentYearIndex, len = 0; k >= len; k--) {
            const yearConsumption = this.getConsumptionByYear(k, datasets);
            const currentYearInLoop = years[k];
            consumptionYear.push({
                consumption: yearConsumption,
                year: currentYearInLoop,
            });
        }

        return consumptionYear;
    }

    getConsumptionMonthly(tooltipItem, datasets, years) {
        const currentMonthIndex = tooltipItem.index;
        const currentMonth = tooltipItem.xLabel;

        const currentYearIndex = datasets[tooltipItem.datasetIndex].stack;
        const consumptionYear = [];
        for (let k = currentYearIndex, len = 0; k >= len; k--) {
            const monthConsumption = this.getConsumptionByMonth(currentMonthIndex, k, datasets);
            const currentYear = years[k];

            consumptionYear.push({
                consumption: monthConsumption,
                year: currentYear,
                month: currentMonth,
            });
        }

        return consumptionYear;
    }

    /**
     * Get configuration from the given label name and apply specific options to the default config.
     * @todo: type a specific interface for each label, instead of the generic ChartOptionsGA
     * @param {string} label
     * @param {*[]} args
     * @returns {ChartOptionsGA | HeatmapOptions | AreasplineOptions}
     */
    getConfig(
        label: 'stacked' | 'bar' | 'horizontalBar' | 'mixed' | 'groupable-stacked-bar' | 'mixed-groupable-stacked-bar',
        ...args
    ): ChartOptionsGA;
    getConfig(type: 'heatmap', ...args): HeatmapOptions;
    getConfig(type: 'areaspline', ...args): AreasplineOptions;
    public getConfig(label: string, options: object = {}): ChartOptionsGA | HeatmapOptions | AreasplineOptions {
        const config = this.configs[label] || {};
        return Object.assign({}, config, options);
    }

    public getUnit(tooltipItem, data, unit?: string): string {
        let datasetUnit = null;
        // If no unit is given, check into dataset
        if (!unit) {
            const datasetIndex = tooltipItem[0].datasetIndex;
            datasetUnit = data.datasets[datasetIndex].unit;
        }
        if (!unit && !datasetUnit) {
            throw new Error('Please provide unit');
        }

        return unit ? unit : datasetUnit;
    }

    /**
     * Get color for chart
     * @param {string} label - label of color to get
     * @param {object} options - options to add in color
     * @returns {ColorGA} all properties at string format
     */
    public getColor(label: string, options: object = {}): ColorGA {
        const rgb: string = this.getRgbColorValue(label);

        const color: ColorGA = {
            backgroundColor: 'rgba(' + rgb + ', 1)',
            borderColor: 'rgba(' + rgb + ', 1)',
            pointBackgroundColor: 'rgba(' + rgb + ', 1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(' + rgb + ', 0.8)',
        };

        return Object.assign({}, color, options);
    }

    /**
     * Get color for chart with opacity
     * @param {string} label - label of color to get
     * @param {number} opacity - opacity of the backgroundColor
     * @param {object} options - options to add in color
     * @returns {{backgroundColor, borderColor, pointBackgroundColor, pointBorderColor, pointHoverBackgroundColor, pointHoverBorderColor}} all properties at string format
     */
    public getColorWithOpacity(label: string, opacity: number, options: object = {}) {
        const rgb = this.getRgbColorValue(label);

        const color = {
            backgroundColor: `rgba(${rgb},${opacity} )`,
            borderColor: 'rgba(' + rgb + ', 1)',
            pointBackgroundColor: 'rgba(' + rgb + ',1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(' + rgb + ', 0.8)',
        };

        return Object.assign({}, color, options);
    }

    /**
     * Get transparent color for chart
     * @param {string} label - label of color to get
     * @param {object} options - options to add in color
     * @returns {{backgroundColor, borderColor, pointBackgroundColor, pointBorderColor, pointHoverBackgroundColor, pointHoverBorderColor}} all properties at string format
     */
    public getColorTransparent(label: string, options: object = {}) {
        const rgb = this.getRgbColorValue(label);

        const color = {
            backgroundColor: 'rgba(' + rgb + ', 0.4)',
            borderColor: 'rgba(' + rgb + ', 0.4)',
            pointBackgroundColor: 'rgba(' + rgb + ', 0.4)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(' + rgb + ', 0.2)',
        };

        return Object.assign({}, color, options);
    }

    /**
     * Get legend for chart
     * @param label - legend label
     * @param options
     * @returns legend for the given label
     */
    public getLegend(label: string, options: ChartLegendOptionsGA = {}): ChartLegendGA {
        const name = this.translateService._(label);
        const legend: ChartLegendGA = {
            name: name || 'NC',
            color: this.getRgbColorValue(label),
        };
        if (options.colors) {
            legend.colors = options.colors.map(x => this.getRgbColorValue(x));
        }
        return legend;
    }

    /**
     * Return of month labels from the month data
     * @param {{month: number, year: number, [key: string]: any}[]} monthData
     * @return {string[]}
     */
    public getMonthsLabel(monthData: Array<{ month: number; year: number; [key: string]: any }>): string[] {
        const labels = [];
        moment.locale('fr');
        monthData.map(x => {
            labels.push(
                moment()
                    .month(x.month)
                    .year(x.year)
                    .format('MM/YYYY')
            );
        });

        return labels;
    }

    public getChartLegends(labels: string[], options = {}) {
        return labels.map(label => this.getLegend(label, options));
    }

    public getColorsDoughnutChart(labels: string[]): ColorDonut {
        const colorsDoughnut = { backgroundColor: [], hoverBackgroundColor: [] };

        labels.forEach(label => {
            const color: ColorGA = this.getColor(label);
            colorsDoughnut.backgroundColor.push(color.backgroundColor);
            colorsDoughnut.hoverBackgroundColor.push(color.backgroundColor);
        });

        return colorsDoughnut;
    }

    /**
     * Get rgb color for charts at format 'r,g,b'
     * @param {string} label - label of color to get
     * @returns {string} rgb string color at format 'r,g,b'
     */
    private getRgbColorValue(label: string): string {
        return this.colorService.getRgbSecondColorValue(label) || '0,0,0';
    }

    /**
     * Get colors for bar charts
     * @param {string[]} labels
     * @param options
     * @returns {ChartColor[]}
     */
    public getBarChartColors(labels: string[], options = {}): ChartColor[] {
        return labels.map(label => this.getColor(label, options));
    }

    /**
     * Get colors for bar charts with opcaity
     * @param {string[]} labels
     * @param {number} opacity
     * @param options
     * @returns {ChartColor[]}
     */
    public getBarChartColorsWithOpacity(labels: string[], opacity: number, options = {}): ChartColor[] {
        return labels.map(label => this.getColorWithOpacity(label, opacity, options));
    }

    /**
     * Get trasparent colors for bar charts
     * @param {string[]} labels
     * @param options
     * @returns {ChartColor[]}
     */
    public getBarChartColorsTransparent(labels: string[], options = {}): ChartColor[] {
        return labels.map(label => this.getColorTransparent(label, options));
    }

    /*
     * data: [147463.3576574074, 573948.0802875435, 104684.81969207081, 121493.06999999999]
     * labels: ["gaz", "elec", "water", "heating"]
     * unit '€'
     * */
    public getHighchartDonutConfig(data: any[], labels: any[], unit: string) {
        const series = [];

        for (let i = 0; i < data.length; ++i) {
            series.push({
                name: labels[i],
                y: data[i],
                color: this.getColor(labels[i]).backgroundColor,
            });
        }

        const that = this;

        const response = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: '',
            },
            tooltip: that.getTooltipHTMLConfig('highcharts-doughnut', unit),
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    size: '100%',
                    dataLabels: {
                        distance: -40,
                        formatter() {
                            return this.percentage > 5 ? that.getNumberToDisplay(this.percentage, '%') : null;
                        },
                        style: {
                            fontSize: '16px',
                            textOutline: '0px',
                            color: '#fff',
                            fontWeight: 'normal',
                            fontFamily: "'Montserrat', sans-serif",
                        },
                    },
                },
            },
            series: [
                {
                    name: '',
                    innerSize: '50%',
                    data: series,
                },
            ],
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
        };

        return response;
    }

    /*
     * returns the tooltip label
     * each point has a name which is used to get the French label to display
     * either it's a commonly used abreviation, ex: elec -> look for translate -> "Electricité"
     * or it comes from a filter, ex: "020 - Administration générale de la collectivité" -> already the correct label to display
     * */
    private getLabelForTooltip(pointName: string): string {
        const label = this.translateService._(pointName);
        return label ? label : pointName;
    }

    /**
     * Get highchart double pie config
     *
     * @param {DoubleDonutSerieIn[]} dataInside - [{name: "0 - Services généraux des administrations publiques locales", y: 739320.5287266553, color: "rgba(211,211,211, 1)", data: Array(2)},
     *   {name: "026 - Cimetières et pompes funèbres", y: 14912.4, color: "rgba(211,211,211, 1)", data: Array(1)}]
     *
     * @param {DoubleDonutSerieOut[]} dataOutside - [{name: "Electricité", y: 325389.46421052626, color: "rgba(253,204,45, 1)", percentageCat: 44.01196119509223},
     *   {name: "Gaz", y: 413931.06451612903, color: "rgba(40,186,155, 1)", percentageCat: 55.98803880490777},
     *   {name: "Electricité", y: 14912.4, color: "rgba(253,204,45, 1)", percentageCat: 100}]
     *
     * @param {string} unit - unit : kWhep
     * @return {*}
     */
    public getHighchartDoublePieConfig(
        dataInside: DoubleDonutSerieIn[],
        dataOutside: DoubleDonutSerieOut[],
        unit: string
    ): any {
        const that = this;

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            tooltip: that.getTooltipHTMLConfig('highcharts-doughnut', unit),
            title: {
                text: '',
            },
            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                },
            },
            series: [
                {
                    name: '',
                    data: dataInside,
                    size: '60%',
                    dataLabels: {
                        connectorWidth: 0,
                        formatter() {
                            return this.series.data.length > 1
                                ? this.point.name + ': ' + that.getNumberToDisplay(this.point.percentage, '%')
                                : this.point.name;
                        },
                        distance: 80,
                        style: {
                            fontSize: '12px',
                            fontFamily: "'Montserrat', sans-serif",
                            textOutline: '0px',
                            fontWeight: 'normal',
                            color: '#607188',
                            textOverflow: 'ellipsis',
                        },
                        useHTML: true,
                    },
                    id: 'filter',
                },
                {
                    name: '',
                    data: dataOutside,
                    size: '100%',
                    innerSize: '60%',
                    dataLabels: {
                        formatter() {
                            return this.point.percentageCat > 10
                                ? that.getNumberToDisplay(this.point.percentageCat, '%')
                                : null;
                        },
                        distance: -35,
                        color: '#fff',
                        style: {
                            fontSize: '15px',
                            textOutline: '0px',
                            color: '#fff',
                            fontWeight: 'normal',
                            fontFamily: "'Montserrat', sans-serif",
                        },
                    },
                    id: 'fluid',
                },
            ],
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
        };
    }

    public getColorRgbToHex(colorName: string): string {
        const rgb = this.colors[colorName];
        return `rgba(${rgb}, 1)`;
    }

    /**
     * Create a config for Sankey diagrams
     **/
    public getHightchartSankeyConfig(data: any) {
        const series = data.data;

        const total = data.total;

        const unit = data.unit;

        const response = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'sankey',
            },
            title: {
                text: '',
            },
            tooltip: {
                headerFormat: '',
                formatter() {
                    if (this.point.id && typeof this.point.sum === 'number') {
                        return (
                            this.point.id +
                            ': <b> ' +
                            unit +
                            ' ' +
                            this.point.sum.toLocaleString(undefined, { maximumFractionDigits: 2 }) +
                            '</b> (' +
                            ((this.point.sum / total) * 100).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
                            '%)<br/>'
                        );
                    } else {
                        return (
                            this.point.fromNode.id +
                            ' → ' +
                            this.point.toNode.id +
                            ': <b>' +
                            unit +
                            ' ' +
                            this.point.weight.toLocaleString(undefined, { maximumFractionDigits: 2 }) +
                            '</b> (' +
                            ((this.point.weight / total) * 100).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                            }) +
                            '%)<br/>'
                        );
                    }
                },
            },
            plotOptions: {
                sankey: {
                    dataLabels: {
                        style: {
                            fontSize: '12px',
                            textOutline: '0px',
                            color: '#212121',
                            fontWeight: 'normal',
                            fontFamily: 'Montserrat',
                        },
                    },
                },
            },
            series: [],
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
        };

        const colors = [
            this.colorService.getHexColor('DARK_BLUE1'),
            this.colorService.getHexColor('LIGHT_BLUE2'),
            this.colorService.getHexColor('TURQUOISE'),
            this.colorService.getHexColor('DARK_BLUE2'),
        ];

        let seriesFormatted = [];
        const nodesFormatted = [];

        for (let i = 0; i < series.length; i++) {
            series[i].forEach(item => {
                nodesFormatted.push({
                    id: item[0],
                    color: colors[i],
                    name: i === 0 ? ' ' : item[0],
                });
            });
            if (i === series.length - 1) {
                series[i].forEach(item => {
                    nodesFormatted.push({
                        id: item[1],
                        color: colors[i + 1],
                        name: ' ',
                    });
                });
            }
            seriesFormatted = seriesFormatted.concat(series[i]);
        }
        response.series.push({
            name: '',
            keys: ['from', 'to', 'weight'],
            data: seriesFormatted,
            nodePadding: 15,
            dataLabels: {
                enabled: true,
            },
            nodes: nodesFormatted,
        });

        return response;
    }

    /**
     * Create a config for Scatter plot diagram
     **/
    public getHighchartScatterPlotConfig() {
        return {
            responsive: true,
            chart: {
                type: 'scatter',
                spacingTop: 40,
                spacingBottom: 40,
                zoomType: 'xy',
                panning: true,
                panKey: 'shift',
                animation: {
                    duration: 1000,
                },
            },
            title: {
                text: '',
            },
            legend: {
                enabled: false,
            },
            yAxis: {
                gridLineDashStyle: 'longdash',
                gridLineWidth: 1,
                title: {
                    align: 'high',
                    text: 'kWh',
                    rotation: 0,
                    y: -20,
                    offset: 15,
                },
                scrollbar: {
                    enabled: true,
                },
            },
            xAxis: {
                gridLineDashStyle: 'longdash',
                gridLineWidth: 1,
                title: {
                    align: 'high',
                    offset: 7,
                    text: '€',
                    rotation: 0,
                    y: 20,
                },
                startOnTick: true,
                endOnTick: true,
                scrollbar: {
                    enabled: true,
                    margin: 35,
                },
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                            },
                        },
                        symbol: 'circle',
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false,
                            },
                        },
                    },
                    stickyTracking: false, // Only show tooltip if the mouse is over a graph marker
                    tooltip: {
                        headerFormat: '',
                        pointFormat: '',
                        useHTML: true,
                        style: {
                            pointerEvents: 'auto',
                        },
                    },
                },
            },
            tooltip: {
                useHTML: true,
                borderColor: '#f0f0f0',
                backgroundColor: '#ffffff',
                borderRadius: 5,
                opacity: 1,
                shadow: false,
                style: {
                    pointerEvents: 'auto',
                },
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
            },
        };
    }

    public getNbDecimalsForUnit(unit): number {
        return this.utilsService.getNbMaxDecimalsForUnitOrType(unit);
    }

    // return the value formated with correct decimals number + its unit as a string
    getNumberToDisplay(value, unit: string, type = null): string {
        return this.utilsService.getNumberAndUnitToDisplay(value, unit, type);
    }

    /**
     * Set on the options the min and max ticks when required, for each dataset
     * @param {ChartSerieLight[][]} datasets
     * @param {ChartOptionsGA} defaultOptions
     * @returns {ChartOptionsGA[]} options for each dataset, with the min and max set if needed
     */
    public getDatasetsAxesOptions(datasets: ChartSerieLight[][], defaultOptions: ChartOptionsGA): ChartOptionsGA[] {
        const options = datasets.map(datasetSeries => {
            return this.getSeriesAxesOptions(datasetSeries, defaultOptions);
        });
        return options;
    }

    /**
     * Set min and max ticks when required by the dataset series
     * @param {ChartSerieLight[]} series
     * @param {ChartOptionsGA} defaultOptions
     * @returns {ChartOptionsGA} options with the min and max set if needed
     */
    public getSeriesAxesOptions(
        series: ChartSerieLight[] | ChartDataSetsGA[],
        defaultOptions: ChartOptionsGA
    ): ChartOptionsGA {
        const options = _.cloneDeep(defaultOptions);
        this.setTicksOptions(series, options);
        return options;
    }

    /**
     * Set min and max ticks when required by the dataset series
     * Handle cases :
     *  - multi y axis - same zero
     *  - all values near zero - adapt scale
     * @param {ChartSerieLight[]} series
     * @param {ChartOptionsGA} options - to update with correct min max
     */
    private setTicksOptions(series: ChartSerieLight[] | ChartDataSetsGA[], options: ChartOptionsGA): void {
        // Set in each serie's axis, the overall max/min values
        series.forEach(serie => {
            const datasetValues: number[] = serie.data
                .concat()
                .map(data => {
                    if (typeof data === 'object' && data !== null && data !== undefined) {
                        return data.y;
                    }
                    return data;
                })
                .filter(data => data);

            // Find axis option
            const datasetAxis = options.scales.yAxes.find(axis => axis.id === serie.yAxisID) || options.scales.yAxes[0];

            // Set min_value and max_value on the axis
            this.setAxisMinMaxValue(datasetValues, datasetAxis);
        });

        // Handle multi y axes series - set same zero
        this.scaleAxesToUnifyZeroes(series, options);

        // Handle series with all values < 0.01
        // <!> need to be after the unified zeros <!>
        this.scaleAxesOnZeroValuesOnly(options.scales.yAxes);
    }

    /**
     * On multi series graph, set the y axis zero at the same level for all series
     * @param {ChartSerieLight[]} series
     * @param {ChartOptionsGA} options - to update with correct min max
     */
    scaleAxesToUnifyZeroes(series: ChartSerieLight[] | ChartDataSetsGA[], options: ChartOptionsGA): void {
        const axes = options.scales.yAxes.filter(yAxis => series.some(s => s.yAxisID === yAxis.id));
        if (axes && axes.length > 1) {
            // Which gives the overall range of each axis
            axes.forEach(axis => {
                // Range must not be 0. If min and max equal then set range to 1 ratio computation
                axis.range = axis.max_value - axis.min_value || 1;
                // Express the min / max values as a fraction of the overall range
                axis.min_ratio = axis.min_value / axis.range;
                axis.max_ratio = axis.max_value / axis.range;
            });
            // Find the largest of these ratios
            const largest = axes.reduce(
                (memo, axis) => {
                    if (typeof memo.min_ratio === 'number') {
                        memo.min_ratio = Math.min(memo.min_ratio, axis.min_ratio);
                    } else {
                        memo.min_ratio = axis.min_ratio;
                    }
                    if (typeof memo.max_ratio === 'number') {
                        memo.max_ratio = Math.max(memo.max_ratio, axis.max_ratio);
                    } else {
                        memo.max_ratio = axis.max_ratio;
                    }
                    return memo;
                },
                { min_ratio: null, max_ratio: null }
            );

            // Then scale each axis accordingly
            axes.forEach(axis => {
                axis.ticks = axis.ticks || {};

                axis.ticks.min = largest.min_ratio * axis.range;
                axis.ticks.max = largest.max_ratio * axis.range;
            });
        }
    }

    /**
     * Set on the axis, the overall min_value from its dataset
     * Handle stack axis
     * @param {number[]} datasetValues
     * @param {ChartAxisGA} axis
     */
    private setAxisMinMaxValue(datasetValues: number[], axis: ChartAxisGA): void {
        // if axis (index-y-0) is stacked, should sum all line with same axis
        if (axis.stacked) {
            // If axis has previous value, calculate the next value with the previous
            if (axis.min_value) {
                const sumedLineData = datasetValues.map(item => {
                    if (axis.min_value < 0 && item > 0) {
                        return axis.min_value;
                    }
                    return item + axis.min_value;
                });
                axis.min_value = Math.min(...sumedLineData, axis.min_value, 0);
            } else {
                axis.min_value = Math.min(...datasetValues, 0);
            }

            if (axis.max_value) {
                const sumedLineData = datasetValues.map((item, index) => {
                    if (axis.max_value > 0 && item < 0) {
                        return axis.max_value;
                    }

                    return item + axis.max_value;
                });
                axis.max_value = Math.max(...sumedLineData, axis.max_value, 0);
            } else {
                axis.max_value = Math.max(...datasetValues, 0);
            }
        } else {
            axis.min_value = Math.min(...datasetValues, axis.min_value || 0);
            axis.max_value = Math.max(...datasetValues, axis.max_value || 0);
        }
    }

    /**
     * Set a maximum tick value when all values are closed to zero.
     * Necessary to avoid multiple line with value zero
     * @param {ChartOptionsGA} options
     */
    scaleAxesOnZeroValuesOnly(yAxesOptions: ChartAxisGA[]): void {
        yAxesOptions.forEach(axis => {
            // chart axes display 2 decimal digits, so 0.01 should be the maximum value displayed
            if (axis.max_value && axis.max_value < 0.01) {
                axis.ticks.max = 0.01;
            }
        });
    }

    /**
     * Populate the barProperties.data with units in each dataset of each toggle.
     * Example:
     * For the bars: put the unit from the toggle
     * For the line: put "dju"
     * @param {Array<any>} toggleDatasets all tab in the view [ tab conso, tab htva, tab ttc]
     *        each tab contain datasets
     * @param unitsForBars: array of the barUnits for each type of chart & each toggle {bar : ["kwh", "€", "€"], line: ["dju", "dju", "dju"]}
     */
    public populateBarLineDatasetsWithUnits(toggleDatasets, unitsForBars) {
        return toggleDatasets.map((toggleDataset, index) => {
            return toggleDataset.map(dataset => {
                dataset.unit = unitsForBars[dataset.type][index];
                return dataset;
            });
        });
    }

    public getHighchartLang(locale: string = 'fr') {
        switch (locale) {
            case 'fr':
            default: {
                return {
                    months: [
                        'janvier',
                        'février',
                        'mars',
                        'avril',
                        'mai',
                        'juin',
                        'juillet',
                        'août',
                        'septembre',
                        'octobre',
                        'novembre',
                        'décembre',
                    ],
                    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
                    shortMonths: [
                        'Jan',
                        'Fev',
                        'Mar',
                        'Avr',
                        'Mai',
                        'Juin',
                        'Juil',
                        'Aout',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Déc',
                    ],
                    decimalPoint: ',',
                    thousandsSep: ' ',
                    downloadPNG: 'Télécharger en image PNG',
                    downloadJPEG: 'Télécharger en image JPEG',
                    downloadPDF: 'Télécharger en document PDF',
                    downloadSVG: 'Télécharger en document Vectoriel',
                    exportButtonTitle: 'Export du graphique',
                    loading: 'Chargement en cours...',
                    printButtonTitle: 'Imprimer le graphique',
                    resetZoom: 'Réinitialiser le zoom',
                    resetZoomTitle: 'Réinitialiser le zoom au niveau 1:1',
                };
            }
        }
    }

    /**
     * Format month number to string labels: with a zero before the number from 01 to 09
     * @param {number} month
     * @returns {string} month label formated
     */
    getMonthNumber(month: number): string {
        if (month < 10) {
            return '0' + month.toString();
        }

        return month.toString();
    }

    /**
     * Computes the index corresponding to each month/year between two dates.
     * ex:
     *   {
     *       2016 : {
     *           9 : 0,
     *           10 : 1,
     *           11 : 2,
     *           12 : 3
     *       },
     *       2017 : {
     *           0 : 4,
     *           1 : 5,
     *           2 : 6
     *       }
     *   }
     *
     *   @param {moment.Moment} start
     *   @param {moment.Moment} end
     *   @returns {[year: number]: {[month: number]: number}}
     */

    public getIndexMonthYears(
        start: moment.Moment,
        end: moment.Moment
    ): { [year: number]: { [month: number]: number } } {
        const indexMonthYears = {};
        let index = 0;

        for (const startDate = start.clone(); startDate.isSameOrBefore(end); startDate.add(1, 'months')) {
            if (!indexMonthYears[startDate.year()]) {
                indexMonthYears[startDate.year()] = {};
            }

            indexMonthYears[startDate.year()][startDate.month()] = index;
            index++;
        }

        return indexMonthYears;
    }

    /**
     * Compute an array with all the charts labels included between two dates.
     * Format is MM/YYYY.
     * @param {moment.Moment} start
     * @param {moment.Moment} end
     * @returns {string[]} dates MM/YYYY between start and end.
     */
    getPeriodMonthlyLabels(start: moment.Moment, end: moment.Moment): string[] {
        const monthLabels: string[] = [];

        for (const startDate = start.clone(); startDate.isSameOrBefore(end); startDate.add(1, 'months')) {
            const labelMonth: string = this.getMonthNumber(startDate.month() + 1) + '/' + startDate.year().toString(); // ex: "02/2017"
            monthLabels.push(labelMonth);
        }

        return monthLabels;
    }

    getChartLegendContentByFilter(filter, filterSelected) {
        let name = '';

        switch (filterSelected) {
            case 'all':
            case 'categories':
            case 'regions':
            case 'custom-filters':
            case 'vehicleCategories':
                name = filter.displayName;
                break;

            case 'sites':
                name = filter.complement ? filter.complement : filter.googleAddress;
                break;

            case 'routingReferences':
                name = filter.zoneId ? filter.displayName : filter.reference;
                break;

            case 'vehicles':
                name = filter.registrationNumber;
                break;

            case 'fuelCards':
                name = filter.reference;
                break;

            default:
                break;
        }

        return name;
    }

    /**
     * Order datasets by stack number from 1 to n (= number of groups).
     * The goal is to keep the bars from group n°1 on the left of group n°2, etc
     */
    sortByStack(datasets: GroupStackBarChartSerie[][]) {
        for (const ds of datasets) {
            ds.sort((a, b) => a.stack - b.stack);
        }
    }

    /**
     * Get stack colors for properties datasets labels
     * Order respects the datasets order
     * @param {ToggleChartPropertiesLight} properties
     * @returns {ChartColor[][]}
     */
    getStackColors(properties: ToggleChartPropertiesLight): ChartColor[][] {
        return properties.datasets.map(dataset => {
            const labelsSortedByStack: string[] = [];
            for (const d of dataset) {
                labelsSortedByStack.push(d.label);
            }
            return this.getBarChartColors(labelsSortedByStack);
        });
    }
}
