import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleService } from 'app/shared/services/feature-toggle/feature-toggle.service';

@Directive({
    selector: '[featureToggle]',
})
export class FeatureToggleDirective implements OnInit {
    @Input() featureToggle: string;

    constructor(
        private featureToggleService: FeatureToggleService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {}

    ngOnInit() {
        if (this.featureToggleService.isEnabled(this.featureToggle)) {
            // If feature enabled, create targeted element
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            // Otherwise don't display element
            this.viewContainer.clear();
        }
    }
}
