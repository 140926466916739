import { InjectionToken } from '@angular/core';

/**
 * Analytics configuration
 */
export interface AnalyticsConfiguration {
    /**
     * Google Analytics identifier
     */
    readonly gaId?: string;
    /**
     * Hotjar identifier
     */
    readonly hotjarId?: number;
}

/**
 * Analytics configuration injection token
 */
export const ANALYTICS_CONFIGURATION = new InjectionToken('ANALYTICS_CONFIGURATION');
