import { Injectable } from '@angular/core';

import * as fr from '../../models/i18n/fr.json';

enum CitronLang {
    'fr',
}

@Injectable()
export class TranslateService {
    private currentLang = fr;

    private langMap = {
        fr,
    };

    constructor() {}

    /**
     * Change language in use for translate
     * @param {CitronLang} lang - lang to use (ie. 'fr')
     */
    public use(lang: CitronLang): void {
        if (this.langMap[lang]) {
            this.currentLang = this.langMap[lang];
        }
    }

    /**
     * Get translation for key
     * @param {string} key - key to get corresponding translation.
     * @returns {string} text from key
     */
    public _(key: string): string {
        if (this.currentLang[key]) {
            return this.currentLang[key];
        }
        return '';
    }
}
