import { Component, Input, OnInit } from '@angular/core';
import { SiteDisplay } from 'app/shared/components/cartography/data-search-list/data-search-list.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { UtilsService } from 'app/shared/services/utils/utils.service';
import { PaginationService } from '../../pagination/front/pagination-front.service';

@Component({
    selector: 'ga-data-search-list',
    templateUrl: './data-search-list.component.html',
    styleUrls: ['./data-search-list.component.scss'],
    providers: [PaginationService],
})
export class DataSearchListComponent implements OnInit {
    @Input() sitesOriginal: SiteDisplay[] = [];
    sitesDisplayed: SiteDisplay[] = [];
    filter = '';

    // pagination
    @Input() paginationRange = 10;

    energyFluids: string[] = [];
    liquidsFluids: string[] = [];

    @Input() company: string = null;

    constructor(
        private utils: UtilsService,
        private energyService: EnergyService,
        private paginationService: PaginationService
    ) {}

    ngOnInit() {
        this.onSearch('');
        this.energyFluids = this.energyService.getAllEnergies();
        this.liquidsFluids = this.energyService.getAllLiquids();
    }

    public ifNc(address) {
        if (address === 'NC') {
            return true;
        } else if (address === '') {
            return true;
        } else {
            return false;
        }
    }

    public displayVehicles() {
        return this.sitesDisplayed.some(site => this.hasVehicles(site));
    }

    public displayWater() {
        return this.sitesDisplayed.some(site => this.hasWater(site));
    }

    public displayEnergy() {
        return this.sitesDisplayed.some(site => this.hasEnergy(site));
    }

    public hasVehicles(site) {
        return Boolean(site.vehicles.length);
    }

    public hasWater(site) {
        return this.liquidsFluids.some(x => site.infoFluids[x] !== 0);
    }

    public hasEnergy(site) {
        return this.energyFluids.some(x => site.infoFluids[x] !== 0);
    }

    public getQueryParam(site) {
        const queryParams: {
            siteId: string;
            company?: string;
        } = {
            siteId: site.id,
        };
        if (this.company) {
            queryParams.company = this.company;
        }
        return queryParams;
    }

    private filterSite(site: SiteDisplay): boolean {
        if (this.filter === '') {
            return true;
        }

        let exists = false;

        const stringToMatch = this.utils.removeAccentAndLowerCase(this.filter);

        // search in pdls
        if (!exists) {
            exists = site.routingReferences.some(routingReference => {
                return routingReference.reference && routingReference.reference.toLowerCase().includes(stringToMatch);
            });
        }

        // search in contracts
        if (!exists) {
            exists = site.routingReferences.some(routingReference => {
                return routingReference.contracts.some(contract => {
                    return contract && contract.reference && contract.reference.toLowerCase().includes(stringToMatch);
                });
            });
        }
        const siteName = this.utils.removeAccentAndLowerCase(site.complement || site.name);
        const siteAddress = this.utils.removeAccentAndLowerCase(site.address);
        const siteCode = this.utils.removeAccentAndLowerCase(site.code || '');

        return (
            exists ||
            siteName.includes(stringToMatch) ||
            siteAddress.includes(stringToMatch) ||
            siteCode.includes(stringToMatch)
        );
    }

    public refreshList(sites: SiteDisplay[]): void {
        this.sitesDisplayed = sites.filter(site => this.filterSite(site));
    }

    /********** Pagination **********/

    /**
     * Returns the elements to display matching the current pagination
     */
    get sitesSorted(): SiteDisplay[] {
        return this.sitesDisplayed.slice(
            this.paginationService.pagination.indexStart,
            this.paginationService.pagination.indexEnd + 1
        );
    }

    /********** Recherche ***********/

    onSearch(value: string) {
        this.filter = value;
        this.sitesDisplayed = this.sitesOriginal.filter(site => this.filterSite(site));
    }
}
