import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';

import { AlertsManagementComponent } from './alerts-management.component';

const ALERTS_MANAGEMENT_ROUTE = [
    {
        path: '',
        component: AlertsManagementComponent,
        canActivate: [],
        data: {},
    },
];

@NgModule({
    declarations: [AlertsManagementComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(ALERTS_MANAGEMENT_ROUTE)],
})
export class AlertsManagementModule {}
