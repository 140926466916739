import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UploadErrorList } from '../../../constants/upload-error-list.constants';
import { UploadStateList } from '../../../constants/upload-state-list.constants';

@Component({
    selector: 'ga-upload-report',
    templateUrl: './upload-report.component.html',
    styleUrls: ['./upload-report.component.scss'],
})
export class UploadReportComponent implements OnInit {
    @Input() files: any[];
    @Input() disableActions = false;

    constructor() {}
    ngOnInit() {}

    getTotalNbFilesMsg() {
        if (this.files && this.files.length > 1) {
            return this.files.length + ' fichiers déposés';
        } else {
            return this.files.length + ' fichier déposé';
        }
    }

    getTotalSuccessMsg() {
        const nb = this.getNumberOfSuccessFiles();
        if (nb > 1) {
            return nb + ' fichiers ajoutés avec succès à la bibliothèque';
        } else {
            return nb + ' fichier ajouté avec succès à la bibliothèque';
        }
    }

    getNumberOfSuccessFiles(): number {
        return this.files.reduce((memo, file) => {
            return file.state === UploadStateList.JOBS_DONE ? ++memo : memo;
        }, 0);
    }

    getTotalErrorsMsg() {
        const nb = this.getNumberOfErrorFiles();
        if (nb > 1) {
            return nb + ' fichiers présentant des erreurs';
        } else {
            return nb + ' fichier présentant des erreurs';
        }
    }

    getNumberOfErrorFiles(): number {
        return this.files.reduce((memo, file) => {
            return this.isErrorFile(file.state) ? ++memo : memo;
        }, 0);
    }

    private isErrorFile(state: string): boolean {
        return (
            state === UploadStateList.MULTI_UNCOMPLETED ||
            state === UploadStateList.PROPERTY_MISSING ||
            state === UploadStateList.ERROR ||
            state === UploadStateList.PATTERN_MISSING ||
            state === UploadStateList.QUALITY_CHECK_FAILED ||
            state === UploadStateList.NO_PATTERNS ||
            state === UploadStateList.HAS_CHUNKS_NO_PATTERNS
        );
    }

    getTotalPendingMsg() {
        const nb = this.getNumberOfPendingFiles();
        if (nb > 1) {
            return nb + ' fichiers en attente d’extraction';
        } else {
            return nb + ' fichier en attente d’extraction';
        }
    }

    getNumberOfPendingFiles(): number {
        return this.files.reduce((memo, file) => {
            return this.isPendingFile(file.state) ? ++memo : memo;
        }, 0);
    }

    private isPendingFile(state: string): boolean {
        return state === UploadStateList.UNTOUCHED;
    }

    private isErrorGeolocFile(state: string, stack: string): boolean {
        return state === UploadStateList.ERROR && stack === UploadErrorList.MISSING_GEOLOC;
    }

    private isErrorDatesFile(state: string, stack: string): boolean {
        return state === UploadStateList.ERROR && stack === UploadErrorList.MISSING_DATE;
    }

    getErrorGeolocMsg() {
        const nb = this.getNbErrorGeoloc();
        if (nb > 1) {
            return 'dont ' + nb + ' ayant leur adresse invalide';
        } else {
            return 'dont ' + nb + ' ayant son adresse invalide';
        }
    }

    getNbErrorGeoloc(): number {
        return this.files.reduce((memo, file) => {
            return this.isErrorGeolocFile(file.state, file.stack) ? ++memo : memo;
        }, 0);
    }

    getErrorDatesMsg() {
        const nb = this.getNbErrorDates();
        if (nb > 1) {
            return 'dont ' + nb + ' ayant leurs dates invalides';
        } else {
            return 'dont ' + nb + ' ayant ses dates invalides';
        }
    }

    getNbErrorDates(): number {
        return this.files.reduce((memo, file) => {
            return this.isErrorDatesFile(file.state, file.stack) ? ++memo : memo;
        }, 0);
    }

    getErrorUploadMsg() {
        const nb = this.getNbErrorUpload();
        if (nb > 1) {
            return nb + " fichiers n'ont pas été uploadés";
        } else {
            return nb + " fichier n'a pas été uploadé";
        }
    }

    getNbErrorUpload(): number {
        return this.files.reduce((memo, file) => {
            return this.isErrorUploadFile(file.state) ? ++memo : memo;
        }, 0);
    }

    // pdf text content couldn't be extracted - document not uploaded - disappears on refresh
    private isErrorUploadFile(state: string): boolean {
        return state === UploadStateList.UPLOAD_ERROR;
    }
}
