import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

// interfaces
import { SwitchFilterElement } from 'app/shared/components/filters/switch-filter/switch-filter-element.interface';
import { PeriodFilter } from 'app/shared/models/bills-timeline.interface';
import { AvailableYears, SelectedDatePeriod } from 'app/shared/services/filter/filter.interface';

// services
import { ExportService } from 'app/shared/services/export/export.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { LoadCurveService } from 'app/shared/services/load-curve/load-curve.service';
import { MapService } from 'app/shared/services/map/map.service';
import { RoutingReferencesService } from 'app/shared/services/routing-references/routing-references.service';

// components
import { SessionService } from 'app/shared/services/session/session.service';
import { ModalComponent } from '../../common/modal/modal.component';
import { RoutingReferenceCommunicatingStatus } from './pdl-communicating.interface';
import { PDLCommunicatingService } from './pdl-communicating.service';

@Component({
    selector: 'ga-pdl-data-site-top',
    templateUrl: './pdl-data-site-top.component.html',
    styleUrls: ['./pdl-data-site-top.component.scss'],
    providers: [PDLCommunicatingService],
})
export class PdlDataSiteTopComponent implements OnInit, OnDestroy {
    private _data: any;
    @Input()
    set data(value: any) {
        this._data = value;
        this.sortHistoryDateAsc();
    }
    get data() {
        return this._data;
    }
    @Input() site: any;
    @Input() contract: any;
    @Input() fluidCategory = 'energie';

    @Input() exportExcelUrl: string;
    @Input() exportExcelName: string;

    get pdlReference() {
        return _.get(this.data, 'reference') || '';
    }

    get contractLight() {
        if (!this.contract) {
            return null;
        }
        return {
            _id: this.contract._id,
            reference: this.contract.reference,
            provider: this.contract.provider,
            startDate: _.get(this.contract, 'infos.dateStart'),
            endDate: _.get(this.contract, 'infos.dateEnd'),
        };
    }

    /**
     * Period selection variables
     */

    @Output() yearChanged: EventEmitter<any> = new EventEmitter(); // output {dateStart:.., dateEnd: ..}

    @Input() dateMethodSelected = 'yearly';

    public switchDateFilterElements: SwitchFilterElement[] = [
        {
            displayName: 'Par année',
            value: 'yearly',
        },
        {
            displayName: 'Personnalisé',
            value: 'custom',
        },
    ];

    @Input() years: any;

    public billCompletenessDates: PeriodFilter;

    /**
     * Period being currently selected in the datepickers
     */
    public selectedDate: SelectedDatePeriod = {
        dateStart: '',
        dateEnd: '',
        year: '',
    };

    /**
     * Period requested to update the params
     */
    public requestedDate: SelectedDatePeriod = {
        dateStart: '',
        dateEnd: '',
        year: '',
    };

    public availablePeriod: AvailableYears = {
        dateStart: '',
        dateEnd: '',
        years: [],
    };

    /** The calculated communicating status of the routing reference */
    public communicatingStatus: RoutingReferenceCommunicatingStatus;

    /**
     * Subscriptions
     */
    private subscription: Subscription;

    /**
     * Last used and valid params
     */
    private params: Params;

    /** Check if the routing reference can have load curve (some fluids won't) */
    get hasLoadcurve(): boolean {
        return this.loadCurveService.compatibleFluids.includes(this.data.energyType);
    }

    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    @ViewChild('statusToggle', { static: true }) private statusToggle: MatSlideToggle;
    @ViewChild(ModalComponent, { static: true }) public billingModal: ModalComponent;

    constructor(
        private mapService: MapService,
        private exportService: ExportService,
        private routingReferencesService: RoutingReferencesService,
        private filterService: FilterService,
        private snackBar: MatSnackBar,
        private loadCurveService: LoadCurveService,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private communicatingService: PDLCommunicatingService
    ) {}

    async ngOnInit() {
        this.initMinMaxRange();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = this.route.queryParams.subscribe(queryParams => {
            this.updateDatesFromRouteParams(queryParams);
        });

        if (this.hasLoadcurve) {
            try {
                this.communicatingStatus = await this.communicatingService.retrieveCommunicatingStatus(this.data);
            } catch (err) {
                Swal.fire(
                    'Une erreur est survenue',
                    'Impossible de récupérer les informations de connectivité',
                    'error'
                );
            }
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ifNc(value) {
        if (value === 'NC' || value === '' || !value) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Initialize the maximum range available for dateStart and dateEnd.
     * minDateRange is January of the oldest year available.
     * maxDateRange is January of the earliest year available + 1.
     * Initialise the available years for the options of the input "Per year". Format is {displayName: .., value: ..}
     */
    private async initMinMaxRange() {
        if (this.years && this.years.length) {
            this.availablePeriod.dateEnd = moment({
                y: Number(this.years[0] + 1),
                M: 0,
            }).format(this.filterService.inputDateFormat);
            this.availablePeriod.dateStart = moment({
                y: Number(this.years[this.years.length - 1]),
                M: 0,
            }).format(this.filterService.inputDateFormat);
            this.availablePeriod.years = this.years.map(year => ({
                displayName: year.toString(),
                value: year.toString(),
            }));
        }
    }

    /**
     * Set the dates inputs depending on the params of the route or init with last year if not set.
     * Method preselected by default is set to "per year".
     * Year selected by default is the most recent year available.
     * Specific period selected is January of the most recent year available to January of the newt year. Format is "YYYY-MM".
     * @param {Params} params
     */
    private setSelectedDates(params: Params) {
        if (this.years && this.years.length) {
            // set default param on the last available year if none given
            if (!(params.hasOwnProperty('ds') || params.hasOwnProperty('de') || params.hasOwnProperty('year'))) {
                this.onUpdateSelectedYear(this.years[0]);
                return;
            }

            // If both start date and end date are setted in the route params, it initialise  the dates inputs with them
            if (params.hasOwnProperty('ds') && params.hasOwnProperty('de')) {
                this.dateMethodSelected = 'custom';
                this.selectedDate = {
                    year: moment(params.de, this.filterService.urlDateFormat).format(this.filterService.yearDateFormat),
                    dateStart: this.filterService.getMonthUrlIntoInputDateFormat(params.ds),
                    dateEnd: this.filterService.getMonthUrlIntoInputDateFormat(params.de),
                };
            }

            // It initialize the dates inputs depending on the year route params or a default year (the most recent year possible)
            else {
                const selectedYear = moment(
                    params.hasOwnProperty('year') ? params.year : this.years[0],
                    this.filterService.yearDateFormat
                );
                this.dateMethodSelected = 'yearly';

                this.selectedDate = {
                    year: selectedYear.format(this.filterService.yearDateFormat),
                    dateStart: selectedYear.startOf('year').format(this.filterService.inputDateFormat),
                    dateEnd: selectedYear.add(1, 'y').format(this.filterService.inputDateFormat),
                };
            }

            this.requestedDate = { ...this.selectedDate };
        }
    }

    /**
     * Called every time the date selected is changed.
     * Update if needed in order: the max date available for dateEnd input, the selected dateEnd.
     * Max date available for dateEnd input: one year after dateStart if possible, otherwise we take maxDateRange.
     * Selected dateEnd changes if selected dateStart was set after dateEnd: one year after if possible, otherwise we take maxDateRange.
     * @param {Params} params
     */
    private async updateDatesFromRouteParams(params: Params): Promise<void> {
        // Update selected dates if the current and new params are different
        if (_.isEqual(params, this.params)) {
            return;
        }

        const oldSelectedDate = { ...this.selectedDate };
        if (this.years && this.years.length) {
            if (Object.keys(params).length) {
                const tmpParams: Params = { ...params };
                const permittedParams = ['year', 'de', 'ds'];

                // Verify the params integrity, and deleting not valid params
                this.filterService.removeInvalidParams(tmpParams, permittedParams);

                // Verify the dates integrity by parsing them and removing not valid dates
                this.filterService.removeInvalidDatesInParams(tmpParams, this.availablePeriod);

                // Replace missing params if needed
                this.filterService.replaceMissingDatesInParams(tmpParams, this.availablePeriod);

                // Replace dates if they are out of limits
                this.filterService.fixDatesCoherence(tmpParams, this.availablePeriod);

                this.setSelectedDates(tmpParams);

                this.params = tmpParams;

                // Emit to the parent only if there's different between the new and current selectedDates
                if (!_.isEqual(oldSelectedDate, this.selectedDate)) {
                    this.updateParent();
                }
            } else {
                this.setSelectedDates(params);
            }
        }
    }

    /**
     * On click on the switch "Per year" / "Specific period".
     * Preselect the year of the dateStart of the specific period.
     * @param {string} method: "yearly" or "custom"
     */
    public updateDateSearchMethod(method: string) {
        this.dateMethodSelected = method;
        if (this.dateMethodSelected === 'yearly') {
            this.requestedDate.year = this.requestedDate.dateStart.substring(0, 4);
        }
        this.updateRouteParams();
    }

    /**
     * Update the route params, depending on the selected date method ('yearly' or 'custom')
     */
    public async updateRouteParams() {
        // TODO handle errors

        const params =
            this.dateMethodSelected === 'yearly'
                ? {
                      year: moment(this.requestedDate.year, this.filterService.yearDateFormat).format(
                          this.filterService.yearDateFormat
                      ),
                  }
                : {
                      ds: this.filterService.getMonthUrlDateFormat(this.requestedDate.dateStart),
                      de: this.filterService.getMonthUrlDateFormat(this.requestedDate.dateEnd),
                  };

        await this.filterService.updateRouteParams(params, '');
    }

    /**
     * set the dates filter for the billing completeness modal
     */
    setBillCompletenessDates() {
        if (this.selectedDate.dateStart && this.selectedDate.dateEnd) {
            const dateStart: string[] = this.selectedDate.dateStart.split('-');
            const dateEnd: moment.Moment = moment
                .utc(`${this.selectedDate.dateEnd}-01`, 'YYYY-MM-DD')
                .subtract(1, 'days')
                .endOf('day');

            this.billCompletenessDates = {
                beginDate: {
                    year: +dateStart[0],
                    month: +dateStart[1],
                    day: 1,
                },
                endDate: {
                    year: dateEnd.year(),
                    month: dateEnd.month() + 1,
                    day: dateEnd.date(),
                },
            };
        }
    }

    /**
     * On click on a new year option.
     * Set the dates from January to December of the selected year.
     * Update the parent component.
     * @param year
     */
    onUpdateSelectedYear(year) {
        this.requestedDate.year = year;
        this.updateRouteParams();
    }

    /**
     * On click on a new dateStart.
     * Update the parent component.
     * @param date
     */
    updateSelectedDateStart(date) {
        this.requestedDate.dateStart = date;
        this.updateRouteParams();
    }
    /**
     * On click on a new dateEnd.
     * Update the parent component.
     * @param date
     */
    updateSelectedDateEnd(date) {
        this.requestedDate.dateEnd = date;
        this.updateRouteParams();
    }

    /**
     * Emit the change to the parent of the component.
     * The expected format of the event is {dateStart: .., dateEnd: ..}
     */
    updateParent() {
        this.setBillCompletenessDates();
        this.yearChanged.emit({ dateStart: this.selectedDate.dateStart, dateEnd: this.selectedDate.dateEnd });
    }
    get siteLink() {
        return `/${this.fluidCategory}/profil/${this.site._id}`;
    }

    /**
     * Display load curve redirection button only if the current routing reference is elec
     * @returns {boolean} true if
     */
    get displayLoadCurveBtn(): boolean {
        return this.hasLoadcurve && this.communicatingStatus && this.communicatingStatus.accessToLoadcurve;
    }

    get loadCurveLink(): string {
        return this.loadCurveService.loadCurveLink;
    }

    /**
     * Load curve params : site id and date is a week ago to yesterday
     */
    get loadCurveParams(): Params {
        const { ds, de } = this.loadCurveService.formatDatesParams(
            this.selectedDate.dateStart,
            this.selectedDate.dateEnd
        );
        const params: Params = {
            ds,
            de,
        };

        if (this.site) {
            params.s = this.site._id;

            if (this.sessionService.getCompanyId() !== this.site.company) {
                params[this.filterService.getParamAlias('company')] = this.site.company;
            }
        }

        return params;
    }

    getSitePictureAsBackground() {
        if (!this.data.address) {
            return null;
        }
        return this.mapService.getSitePictureAsBackground(this.data.address);
    }

    getPdlContractDates(pdl, contract) {
        const search = contract.routingReferences.find(x => x.routingReference === pdl._id);
        return {
            dateStart: search.dateStart,
            dateEnd: search.dateEnd,
        };
    }

    exportToExcel() {
        if (this.exportExcelUrl && this.exportExcelName) {
            this.exportService.getFile(this.exportExcelUrl).subscribe(file => {
                saveAs(file, this.exportExcelName);
            });
        }
    }

    get address() {
        if (
            !this.data.address ||
            !(this.data.address.streetName && this.data.address.zipcode && this.data.address.city)
        ) {
            return 'PDL non localisé';
        }

        const streetNumber = this.data.address.streetNumber ? `${this.data.address.streetNumber}, ` : '';
        const zipcode = this.data.address.zipcode ? this.data.address.zipcode : '';
        return `${streetNumber} ${this.data.address.streetName} ${zipcode} ${this.data.address.city}`;
    }

    async toggleStatus(value) {
        try {
            const response = await this.routingReferencesService.updateStatusRoutingReference(
                this.data._id,
                value.checked
            );
            if (this.data) {
                this.data.status = response.data.status;
                this.sortHistoryDateAsc();
                // Message to revert action if not wanted
                const message = value.checked ? 'activé' : 'désactivé';
                const snack = this.snackBar.open(`Vous avez ${message} le PDL`, 'Annuler', {
                    duration: 5000,
                    horizontalPosition: this.horizontalPosition,
                    verticalPosition: this.verticalPosition,
                });
                // If cancel pressed, call for revert then re-update the front display history list
                snack.onAction().subscribe(async () => {
                    try {
                        // Get last history
                        const lastHistory = this.data.status.history[this.data.status.history.length - 1];
                        const res = await this.routingReferencesService.updateStatusRoutingReference(
                            this.data._id,
                            value.checked,
                            lastHistory._id
                        );
                        this.data.status = res.data.status;
                        this.sortHistoryDateAsc();
                    } catch (e) {
                        this.handleApiError(false);
                    }
                });
            }
        } catch (e) {
            this.handleApiError(true);
        }
    }

    get routingReferenceStatus() {
        if (this.data && this.data.status) {
            return this.data.status.active;
        }
        return false;
    }

    get routingReferenceStatusText() {
        return this.routingReferenceStatus ? 'Actif' : 'Inactif';
    }

    private sortHistoryDateAsc() {
        if (this._data && this._data.stats && this._data.status && this._data.status.history) {
            this._data.status.history.sort((a, b) => (a.date < b.date ? -1 : 1));
        }
    }

    private handleApiError(resetToggle: boolean) {
        return error => {
            if (resetToggle) {
                // Timeout so that it go back to the inital place not instantaneously.
                setTimeout(() => {
                    this.statusToggle.toggle();
                }, 700);
            }
            this.snackBar.open(`Une erreur est survenue, merci de réessayer utlérieurement.`, 'Masquer', {
                duration: 5000,
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
            });
        };
    }

    openBillingModal() {
        this.billingModal.show();
        return;
    }

    /**
     * @returns {boolean} true is the modal shown, false otherwise
     */
    get isModalShown(): boolean {
        return this.billingModal.isShown;
    }
}
