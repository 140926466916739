import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { IdentificationPatternService } from '../../../services/identification-pattern/identification-pattern.service';
import { RegexService } from '../../../services/regex/regex.service';
import { AutoCompleteItem } from '../../common/ultra-simple-autocomplete/ultra-simple-autocomplete.interface';

@Component({
    selector: 'ga-identification-pattern',
    templateUrl: './identification-pattern.component.html',
    styleUrls: ['./identification-pattern.component.scss'],
})
export class IdentificationPatternComponent implements OnInit {
    @Input() company: any = null;
    @Input('identificationPattern')
    set model(identificationPattern: any) {
        if (identificationPattern) {
            this.identificationPattern = identificationPattern;
        }
    }
    @Input() textContent: any = null;
    @Input() uploadId: any = null;
    @Input() chunkId: any = null;

    identificationPattern: any = {
        isMultiChunk: false,
        option: [],
    };
    newOptionExtracted = null;
    newOptionName = null;
    cutter: any = {};
    energies: any = [
        {
            name: 'Electricité',
            value: 'elec',
        },
        {
            name: 'Eau',
            value: 'water',
        },
        {
            name: 'Gaz',
            value: 'gaz',
        },
        {
            name: 'RCU',
            value: 'heating',
        },
    ];

    regexTestValues: any = {};

    availableProviders: AutoCompleteItem[] = [];
    availableContractTypes: AutoCompleteItem[] = [];
    availableOptions: AutoCompleteItem[] = [];

    constructor(
        private regexService: RegexService,
        private identificationPatternService: IdentificationPatternService,
        private router: Router,
        private energyService: EnergyService
    ) {}

    ngOnInit() {
        this.reset();
        this.getAndFormatAllFluids();
        this.getAvailableProviders();
        this.getAvailableContractTypes();
        this.getAvailableOptions();
    }

    updateIdentificationPattern() {
        this.identificationPatternService
            .createIdentificationPattern(this.identificationPattern, this.uploadId, this.chunkId)
            .then(
                identificationPattern => {
                    Swal.fire('Ajouté', "Le pattern d'identification a été sauvegardé.", 'success').then(() => {
                        if (this.uploadId && this.chunkId) {
                            this.router.navigateByUrl('/collecte/regex/upload/' + this.uploadId + '/' + this.chunkId);
                        } else if (this.uploadId) {
                            this.router.navigateByUrl('/collecte/regex/upload/' + this.uploadId);
                        } else {
                            this.router.navigateByUrl('/admin/entreprises');
                        }
                    });
                },
                error => {
                    Swal.fire(
                        'Toutes nos excuses',
                        "Une erreur s'est produite pendant la sauvegarde du pattern d'identification",
                        'error'
                    );
                }
            );
    }

    updateProvider(providerName: string) {
        this.identificationPattern.provider.name = providerName;
    }

    updateContractType(contractTypeName: string) {
        this.identificationPattern.contractType.name = contractTypeName;
    }

    updateNewOption(newOptionName: string) {
        this.newOptionName = newOptionName;
    }

    async getAvailableProviders() {
        const providers = await this.identificationPatternService.getAvailableProviders();
        this.availableProviders = providers
            .filter(x => x)
            .map(x => ({
                value: x,
                label: x,
            }));
    }

    async getAvailableContractTypes() {
        const contractTypes = await this.identificationPatternService.getAvailableContractTypes();
        this.availableContractTypes = contractTypes.map(contractType => ({
            value: contractType.name,
            label: contractType.name,
        }));
    }

    async getAvailableOptions() {
        const options = await this.identificationPatternService.getAvailableOptions();
        this.availableOptions = options.map(x => ({ value: x, label: x }));
    }

    async deleteIdentificationPattern() {
        const result = await Swal.fire({
            title: 'Êtes-vous sûr ?',
            text: 'Cette action est irreversible!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Annuler',
        });
        if (!result.value) {
            return;
        }
        try {
            await this.identificationPatternService.deleteIdentificationPattern(this.identificationPattern._id);
            Swal.fire('Supprimé', "Le pattern d'identification a été supprimé.", 'success');
            this.reset();
        } catch (e) {
            Swal.fire(
                'Toutes nos excuses',
                "Une erreur s'est produite pendant la suppression du pattern d'identification",
                'error'
            );
        }
    }

    saveIdentificationPattern() {
        if (!this.identificationPattern.energyType) {
            Swal.fire('Impossible', 'Une energie est nécessaire', 'error');
        } else if (
            !this.identificationPattern.provider.regex ||
            !this.identificationPattern.provider.extracted ||
            !this.identificationPattern.provider.name
        ) {
            Swal.fire('Impossible', 'Le fournisseur doit avoir un regex, être testé et avoir un nom', 'error');
        } else if (
            this.identificationPattern.isMultiChunk &&
            this.identificationPattern.cutters &&
            !this.identificationPattern.cutters.length
        ) {
            Swal.fire('Impossible', 'En multifacture un cutter minimum est attendu', 'error');
        } else if (
            !this.identificationPattern.isMultiChunk &&
            (!this.identificationPattern.contractType.regex ||
                !this.identificationPattern.contractType.extracted ||
                !this.identificationPattern.contractType.name)
        ) {
            Swal.fire(
                'Impossible',
                'En identification de pattern simple, le type de contrat doit y avoir un regex, être testé et avoir un nom',
                'error'
            );
        } else if (
            this.identificationPattern.optionRegex &&
            this.newOptionExtracted &&
            this.newOptionName &&
            this.identificationPattern.options &&
            !this.identificationPattern.options.length
        ) {
            Swal.fire('Impossible', 'Une option est nécessaire dans la liste des options', 'error');
        } else {
            if (this.identificationPattern._id) {
                delete this.identificationPattern._id;
            }
            this.updateIdentificationPattern();
        }
    }

    copyToClipboard() {
        const copied = this.regexService.copyTextToClipboard(this.textContent);
        if (!copied) {
            Swal.fire(
                'Toutes nos excuses',
                "Une erreur s'est produite pendant la copie du contenu du fichier",
                'error'
            );
        } else {
            Swal.fire('Ajouté', "Le contenu de l'upload a été copié dans le presse papier", 'success');
        }
    }

    /**
     * Reset identification pattern data
     */
    reset() {
        this.newOptionName = '';
        this.newOptionExtracted = '';
        this.identificationPattern = {
            isMultiChunk: false,
            options: [],
            provider: {},
            contractType: {},
        };
    }

    resetMulti() {
        this.identificationPattern = {
            isMultiChunk: true,
            provider: {},
            cutters: [],
        };
    }

    addOption() {
        if (
            this.identificationPattern.options.find(item => {
                return item.extracted === this.newOptionExtracted || item.extracted === this.newOptionName;
            })
        ) {
            Swal.fire('Impossible', "L'option existe déja", 'error');
        } else if (!this.newOptionExtracted) {
            Swal.fire('Impossible', 'Veuillez tester le regex des options', 'error');
        } else if (!this.newOptionName) {
            Swal.fire('Impossible', "Veuillez remplir le nom de l'option", 'error');
        } else {
            this.identificationPattern.options.push({
                extracted: this.newOptionExtracted,
                name: this.newOptionName,
            });
        }
    }

    removeOption(index) {
        if (index > -1) {
            this.identificationPattern.options.splice(index, 1);
        } else {
            Swal.fire('Impossible', '', 'error');
        }
    }

    switchIdentificationPatternMulti(event) {
        event.preventDefault();
        if (this.identificationPattern.isMultiChunk) {
            this.reset();
        } else {
            this.resetMulti();
        }

        this.regexTestValues = {};
    }

    testPattern(propertyRegex, propertyResult, isCustom = false) {
        this.regexService.testRegex(this.textContent, _.get(this.identificationPattern, propertyRegex)).then(result => {
            if (!isCustom) {
                _.set(this.identificationPattern, propertyResult, result && result.length ? result[0] : null);
            } else {
                _.set(this, propertyResult, result && result.length ? result[0] : null);
            }
        });
    }

    addCutter() {
        this.identificationPattern.cutters.push({
            chunkIdentifiersPattern: this.cutter.chunkIdentifiersPattern,
            chunkCutterPattern: this.cutter.chunkCutterPattern,
        });
        this.cutter = {};
    }

    deleteCutter(index: number) {
        if (index > -1 && index < this.identificationPattern.cutters.length) {
            this.identificationPattern.cutters.splice(index, 1);
        }
    }

    /**
     * Get all fluids, format it with display name and set member property energies
     */
    getAndFormatAllFluids() {
        const fluids = this.energyService.getAllFluids();
        this.energies = fluids.map(fluid => {
            return {
                name: this.energyService.energyFullText(fluid),
                value: fluid,
            };
        });
    }
}
