import { Component, OnInit } from '@angular/core';
import { Monitoring, MonitoringFilters, Status } from '../operating-monitoring.interface';
import { OperatingMonitoringService } from '../operating-monitoring.service';

@Component({
    selector: 'ga-monitoring-list',
    templateUrl: './monitoring-list.component.html',
    styleUrls: ['./monitoring-list.component.scss'],
})
export class MonitoringListComponent implements OnInit {
    /**
     * The monitoring filters actually applied
     */
    public filters: MonitoringFilters;
    /**
     * The list of all the monitorings
     */
    public monitorings: Monitoring[];
    /**
     * The currently selected monitoring
     */
    public selectedMonitoring: Monitoring;
    /**
     * Return existings status for HTML
     */
    get STATUS() {
        return Status;
    }

    constructor(private operatingMonitoringService: OperatingMonitoringService) {}

    ngOnInit() {
        this.operatingMonitoringService.filters$.subscribe({
            next: value => (this.filters = value),
        });
        this.operatingMonitoringService.monitoringsFiltered$.subscribe({
            next: value => (this.monitorings = value),
        });
        this.operatingMonitoringService.selectedMonitoring$.subscribe({
            next: value => (this.selectedMonitoring = value),
        });
    }

    public getMessageNoMonitorings(): string {
        const hasSelectedStatusFilter = this.filters.status && Object.values(Status).includes(this.filters.status);

        return hasSelectedStatusFilter
            ? 'monitoring_no_corresponding_monitorings'
            : 'monitoring_no_existing_monitorings';
    }

    public showEditionMonitoringModal() {
        this.operatingMonitoringService.editedMonitoring$.next(null);
        this.operatingMonitoringService.toggleEditionMonitoringModal(true);
    }

    public onStatusChange(value: Status) {
        const newFilters: MonitoringFilters = { ...this.filters, status: value };
        this.operatingMonitoringService.setFilters(newFilters);
    }

    public onClickMonitoring(monitoring: Monitoring) {
        // Throw only if value have changed
        if (!this.selectedMonitoring || this.selectedMonitoring._id !== monitoring._id) {
            this.operatingMonitoringService.selectedMonitoring$.next(monitoring);
        }
    }
}
