import { Component, Input, OnInit } from '@angular/core';
import { TilesService } from 'app/shared/services/tiles/tiles.service';

@Component({
    selector: 'data-tile-2',
    templateUrl: './data-tile-2.component.html',
    styleUrls: ['./data-tile-2.component.scss'],
    providers: [],
})
export class DataTile2Component implements OnInit {
    @Input() label: string;
    @Input() subLabel: string;
    @Input() unit: string;
    @Input() value: number;
    @Input() valueHTML: string;
    @Input() mainColor: string;
    @Input() secondaryColor: string;
    @Input() type: string;

    constructor(private tilesService: TilesService) {}
    ngOnInit() {}

    getClass(): string {
        return this.subLabel ? 'dt2-with-sublabel' : '';
    }

    /**
     * Return the formated value to display
     * @param {number} value - value to format
     * @returns {string} formated value
     */
    getFormatedValue(value: number): string {
        return this.tilesService.getNumberToDisplay(value, this.unit);
    }
}
