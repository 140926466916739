import { Component, Input } from '@angular/core';

@Component({
    selector: 'ga-highchart-multilevel-pie',
    templateUrl: './highchart-multilevel-pie.component.html',
    styleUrls: ['./highchart-multilevel-pie.component.scss'],
    providers: [],
})
export class HighchartMultilevelPieComponent {
    @Input() options: object;
    @Input() legend: any = [];
    chart: any;
    @Input() downloadFileName = 'chart-doughnut.png';

    saveInstance(chartInstance) {
        // DONT REMOVE IT: fix to avoid "Expression has changed after it was checked" error
        setTimeout(() => {
            this.chart = chartInstance;
        });
    }
}
