import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../api/api.service';
import { PageService } from '../page/page.service';

import { Notification } from '../../models/notification.interface';

@Injectable()
export class NotificationsService extends PageService {
    private notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);

    constructor(public apiService: ApiService) {
        super(apiService);
    }

    /**
     * Get notifications for the current user and company
     */
    private async getNotificationsCurrentCompany(): Promise<any> {
        return this.get('/api/notifications/' + this.apiService.getCompanyId());
    }

    /**
     * Get the notification BehaviorSubject.
     * @returns {BehaviorSubject} behavior subject of the notifications to subscribe
     */
    public getNotifications(): BehaviorSubject<Notification[]> {
        return this.notifications;
    }

    /**
     * Refresh notifications and send event to all notifications subscribers
     */
    public async refresh(): Promise<void> {
        try {
            const res = await this.getNotificationsCurrentCompany();

            const notifs: Notification[] = res.data;

            this.notifications.next(notifs);
        } catch (err) {
            const errorNotif: Notification = {
                _id: null,
                message: 'Erreur lors du chargement des notifications.',
                redirect: null,
                read: false,
                notifiedAt: Date.now(),
            };
            this.notifications.next([errorNotif]);
        }
    }

    /**
     * Set notification read state.
     * @param {string} id - notification id
     * @param {boolean} read - read state of notification
     * @returns {Promise<Notification>} notification with latest values
     */
    public async setNotificationState(id: string, read: boolean): Promise<Notification> {
        const res = await this.patch(`/api/notifications/read/${id}`, { read });
        return res.data;
    }

    /**
     * Archive notification
     * @param {string} id - notification id
     * @returns {Promise<Notification>} notification archived
     */
    public async archiveNotification(id: string): Promise<Notification> {
        const res = await this.delete(`/api/notifications/${id}`);
        return res.data;
    }
}
