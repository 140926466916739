import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeFileExtension',
})
export class RemoveFileExtensionPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return;
        }
        return value.replace(/\.[^/.]+$/, '');
    }
}
