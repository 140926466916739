export const environment = {
    apiUrl: 'https://api-energie.citron.io',
    isProdMode: true,
    isProduction: true,
    APP_ENV: 'production',
    VERSION: '1.46.0',
    REVISION_SHA1: 'aba2eec8785cee877387c35db0b6ff9eef7b1e2e',
    ROLLBAR_TOKEN: 'bdabc70daca74da6b44c24562f90ca75',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDAPQEDx82drT0_5_83lFt1uIuae92a1jA',
    features: {"bills-controls":{"navigation":true,"controls":true,"completeness":true},"operating-monitoring":{"feature":true},"upload-extraction":{"newRoutes":true},"upload-import":{"newRoutes":true},"upload-reExtraction":{"newRoutes":true},"upload-reset-by-group":{"newRoutes":true},"re-extraction-on-history":{"newButton":true}},
};
