import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';

import { ApiService } from 'app/shared/services/api/api.service';
import { Validators as GAValidators } from 'app/shared/services/forms/validators';
import { PageService } from 'app/shared/services/page/page.service';

import { BRExclusibleUses, DjuType, ExclusionTypes, Status } from '../operating-monitoring.interface';
import { MonitoringUpdate } from '../operating-monitoring.update.interface';
import { FieldConfig, MonitoringFormData } from './monitoring-edition.interface';

@Injectable()
export class MonitoringEditionService extends PageService {
    /** New monitoring subject with values */
    public monitoring$: BehaviorSubject<MonitoringUpdate> = new BehaviorSubject<MonitoringUpdate>(null);
    /** The form used to create or update a new monitoring */
    public form = new FormGroup({});
    /** Subscription */
    public subscription: Subscription;

    constructor(public apiService: ApiService) {
        super(apiService);
        // Init monitoring with empty values
        this.monitoring$.next({
            companyId: null,
            monitoringType: null,
            name: '',
            scope: null,
            modulatorBase: null,
            valueCommitted: null,
            contractStart: null,
            contractEnd: null,
            deltaTolerancePercentage: null,
            profitSharingPercentage: null,
            penaltiesPercentage: null,
            dataSourceType: null,
            includedCosts: [],
            uses: [],
            djuType: DjuType.HOT,
        });

        this.subscription = this.form.valueChanges.subscribe({
            next: value => this.updateMonitoring(value),
        });
    }

    public addToControlGroup(configs: Array<FieldConfig<any>>) {
        // Build control group based on config
        for (const item of configs) {
            const controlFormState = {
                value: item.defaultValue,
                disabled: false,
            };
            const validators = [];

            // Set up disabled state for form control
            if (item.disabled) {
                controlFormState.disabled = true;
            }
            const control = new FormControl(controlFormState);

            // Set up validators for form control
            if (item.required) {
                validators.push(Validators.required);
            }
            if (typeof item.min === 'number') {
                validators.push(Validators.min(item.min));
            }
            if (typeof item.max === 'number') {
                validators.push(Validators.max(item.max));
            }
            if (typeof item.minLength === 'number') {
                validators.push(Validators.minLength(item.minLength));
            }
            control.setValidators(validators);

            this.form.addControl(item.key, control);
        }
    }

    private updateMonitoring(value: MonitoringFormData) {
        const monitoring = this.monitoring$.getValue();
        // Raw value includes disabled fields (while value doesn't)
        const rawValue: MonitoringFormData = this.form.getRawValue();

        // Format contract dates
        const utcContractStart = moment
            .utc(value.contractStart)
            .startOf('day')
            .toDate();

        const utcContractEnd = moment
            .utc(value.contractEnd)
            .endOf('day')
            .toDate();

        const newMonitoring = {
            ...monitoring,
            monitoringType: rawValue.monitoringType,
            name: value.monitoringName,
            companyId: rawValue.monitoringCompany,
            site: value.monitoringSite,
            scope: { rRefId: value.monitoringRref && value.monitoringRref._id },
            dataSourceType: rawValue.datasource,
            uses: [],
            contractStart: utcContractStart,
            contractEnd: utcContractEnd,
            modulatorBase: value.monitoringDJUs,
            valueCommitted: value.monitoringNB,
            deltaTolerancePercentage: value.deltaTolerancePercentage,
            profitSharingPercentage: value.profitSharingPercentage,
            penaltiesPercentage: value.penaltiesPercentage,
            includedCosts: value.includedCosts,
            status: Status.IDLE,
            djuType: rawValue.djuType,
        };
        let subtractMethod = null;
        if (value.subtractECS) {
            subtractMethod = value.linearModeling ? ExclusionTypes.PERF_FORMULA : value.subtractMethod;
        }
        const useIndexReading = subtractMethod === ExclusionTypes.INDEX_READING && !value.linearModeling;
        const useTheoretical = subtractMethod === ExclusionTypes.HOUSING_THEORETICAL && !value.linearModeling;
        const areQValuesNeeded = useIndexReading || useTheoretical;

        // Create ECS use
        if (value.ecsUse) {
            newMonitoring.uses.push({
                use: BRExclusibleUses.HOT_WATER,
                exclusionType: subtractMethod,
                perfFormula: value.linearModeling
                    ? { gradient: value.perfFormulaGradient, yIntercept: value.perfFormulaYIntercept }
                    : null,
                q: areQValuesNeeded ? { highSeason: value.qHighSeason, lowSeason: value.qLowSeason } : null,
                nHousing: useTheoretical ? rawValue.nHousing : null,
                avgVolume: useTheoretical ? rawValue.avgVolume : null,
            });
        }
        // Enable or disable validators for performance formula
        const perfFormulaGradientControl = this.form.get('perfFormulaGradient');
        const perfFormulaYInterceptControl = this.form.get('perfFormulaYIntercept');
        if (perfFormulaGradientControl && this.form.get('perfFormulaYIntercept')) {
            if (value.ecsUse && value.linearModeling) {
                perfFormulaGradientControl.setValidators([Validators.required, Validators.min(0)]);
                perfFormulaYInterceptControl.setValidators([Validators.required, Validators.min(0)]);
            } else {
                perfFormulaGradientControl.clearValidators();
                perfFormulaYInterceptControl.clearValidators();
            }
            perfFormulaGradientControl.updateValueAndValidity({ emitEvent: false });
            perfFormulaYInterceptControl.updateValueAndValidity({ emitEvent: false });
        }

        // Enable or disable validators for q values
        const qHighSeasonControl = this.form.get('qHighSeason');
        const qLowSeasonControl = this.form.get('qLowSeason');
        if (qHighSeasonControl && qLowSeasonControl) {
            if (value.ecsUse && areQValuesNeeded) {
                qHighSeasonControl.setValidators([Validators.required, GAValidators.hasMaxNDecimals(1)]);
                qLowSeasonControl.setValidators([GAValidators.hasMaxNDecimals(1)]);
            } else {
                qHighSeasonControl.clearValidators();
                qLowSeasonControl.clearValidators();
            }
            qHighSeasonControl.updateValueAndValidity({ emitEvent: false });
            qLowSeasonControl.updateValueAndValidity({ emitEvent: false });
        }

        // Enable or disable validators for theoretical water use
        const nHousingControl = this.form.get('nHousing');
        const avgVolumeControl = this.form.get('avgVolume');
        if (nHousingControl && avgVolumeControl) {
            if (value.ecsUse && useTheoretical) {
                nHousingControl.setValidators([Validators.required, GAValidators.isInteger]);
                avgVolumeControl.setValidators([Validators.required, GAValidators.hasMaxNDecimals(1)]);
            } else {
                nHousingControl.clearValidators();
                avgVolumeControl.clearValidators();
            }
            nHousingControl.updateValueAndValidity({ emitEvent: false });
            avgVolumeControl.updateValueAndValidity({ emitEvent: false });
        }

        // Enable or disable validators for DJU/NB contract
        const monitoringDJUsControl = this.form.get('monitoringDJUs');
        const monitoringNBControl = this.form.get('monitoringNB');
        if (monitoringDJUsControl && monitoringNBControl) {
            if (!value.ecsUse || !value.linearModeling) {
                monitoringDJUsControl.setValidators([Validators.required, Validators.min(0)]);
                monitoringNBControl.setValidators([Validators.required, Validators.min(0)]);
            } else {
                monitoringDJUsControl.clearValidators();
                monitoringNBControl.clearValidators();
            }
            monitoringDJUsControl.updateValueAndValidity({ emitEvent: false });
            monitoringNBControl.updateValueAndValidity({ emitEvent: false });
        }
        this.monitoring$.next(newMonitoring);
    }
}
