import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../environments/environment';

const rollbarConfig: Rollbar.Configuration = {
    enabled: environment.APP_ENV === 'production' || environment.APP_ENV === 'staging',
    accessToken: environment.ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    nodeSourceMaps: false,
    inspectAnonymousErrors: true,
    ignoreDuplicateErrors: true,
    wrapGlobalEventHandlers: false,
    scrubRequestBody: true,
    exitOnUncaughtException: false,
    payload: {
        environment: environment.APP_ENV,
        client: {
            javascript: {
                code_version: environment.REVISION_SHA1,
            },
        },
    },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
    constructor(@Inject(RollbarService) private rollbar: Rollbar) {}
    handleError(err: any): void {
        // tslint:disable-next-line: no-console
        console.error(err.originalError || err);
        const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
        if (!isIEOrEdge) {
            this.rollbar.error(err.originalError || err);
        }
    }
}

export function rollbarFactory(): Rollbar {
    return new Rollbar(rollbarConfig);
}

export default Rollbar;
