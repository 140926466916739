import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';

import { AccessGuard } from 'app/shared/services/guards/access-guard.service';
import { FollowupComponent } from './followup.component';

const FOLLOWUP_ROUTE = [
    {
        path: '',
        component: FollowupComponent,
        canActivate: [AccessGuard],
        data: { page: 'vehicles' },
    },
];

@NgModule({
    declarations: [FollowupComponent],
    imports: [CommonModule, SharedModule, RouterModule.forChild(FOLLOWUP_ROUTE)],
})
export class FollowUpModule {}
