import { Injectable } from '@angular/core';

@Injectable()
export class ThemesService {
    styleTag: any;
    defaultTheme = 'A';

    constructor() {
        this.createStyle();
        this.setTheme(this.defaultTheme);
    }

    private createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
    }

    setTheme(name) {
        switch (name) {
            case 'A':
                this.injectStylesheet(require('../../styles/themes/theme-a.scss'));
                break;
            case 'B':
                this.injectStylesheet(require('../../styles/themes/theme-b.scss'));
                break;
            case 'C':
                this.injectStylesheet(require('../../styles/themes/theme-c.scss'));
                break;
            case 'D':
                this.injectStylesheet(require('../../styles/themes/theme-d.scss'));
                break;
            case 'E':
                this.injectStylesheet(require('../../styles/themes/theme-e.scss'));
                break;
            case 'F':
                this.injectStylesheet(require('../../styles/themes/theme-f.scss'));
                break;
            case 'G':
                this.injectStylesheet(require('../../styles/themes/theme-g.scss'));
                break;
            case 'H':
                this.injectStylesheet(require('../../styles/themes/theme-h.scss'));
                break;
        }
    }

    injectStylesheet(css) {
        this.styleTag.innerHTML = css;
    }

    getDefaultTheme() {
        return this.defaultTheme;
    }
}
