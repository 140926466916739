import { Component, OnInit } from '@angular/core';
import { ContractPopulated, InfoContract, Periodicity } from 'app/shared/models/contract.interface';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ContractEditionService } from '../contract-edition.service';

interface Item<T = string> {
    value: T;
    label: string;
}

interface DateInput {
    key: string;
    required: boolean;
    property: string;
    value: string;
    isValid: boolean;
    validator: (inputs: DateInput[]) => boolean;
    errorMessage: string;
}

@Component({
    selector: 'contract-edition-dates-periodicity',
    templateUrl: './dates-periodicity.component.html',
    styleUrls: ['./dates-periodicity.component.scss'],
})
export class ContractDatesPeriodicityComponent implements OnInit {
    /**
     * Contract to display
     */
    private get contract(): ContractPopulated {
        return this.contractEditionService.contract;
    }

    /**
     * Periodicity object with value and available items.
     */
    public periodicity: {
        value: Periodicity;
        items: Array<Item<Periodicity>>;
    } = {
        value: null,
        items: [
            {
                value: null,
                label: '-',
            },
            {
                value: 'monthly',
                label: 'monthly',
            },
            {
                value: 'quarterly',
                label: 'quarterly',
            },
        ],
    };

    /**
     * Periodicity day of month when bills arrive.
     */
    public periodicityDay: {
        value: number;
        min: number;
        max: number;
        isValid: () => boolean;
    } = {
        value: null,
        min: 1,
        max: 31,
        isValid() {
            if (typeof this.value !== 'number') {
                return true;
            }
            if (this.value < this.min || this.value > this.max) {
                return false;
            }
            return true;
        },
    };

    /**
     * Periodicity reminder object with value and available items.
     * Items list initialized in ngOnInit
     */
    public periodicityReminders: {
        value: number;
        items: Array<Item<number>>;
    } = {
        value: null,
        items: [],
    };

    /**
     * Date inputs with validation functions
     */
    public dateInputs: DateInput[] = [
        {
            key: 'contract_start',
            required: true,
            property: 'infos.dateStart',
            value: null,
            isValid: true,
            validator(dateInputs) {
                const end = dateInputs.find(x => x.key === 'contract_end');
                if (end && end.value && this.value) {
                    return moment(this.value).isBefore(end.value);
                }
                return true;
            },
            errorMessage: 'La date de début doit être inférieure à la date de fin',
        },
        {
            key: 'contract_on_notice',
            required: false,
            property: 'infos.dateNotice',
            value: null,
            isValid: true,
            validator(dateInputs) {
                if (!this.value) {
                    return true;
                }
                const start = dateInputs.find(x => x.key === 'contract_start');
                if (start && start.value && !moment(start.value).isBefore(this.value)) {
                    return false;
                }
                const end = dateInputs.find(x => x.key === 'contract_end');
                if (end && end.value && !moment(end.value).isAfter(this.value)) {
                    return false;
                }
                return true;
            },
            errorMessage: 'La date de préavis doit être entre les dates de début et de fin',
        },
        {
            key: 'contract_end',
            required: true,
            property: 'infos.dateEnd',
            value: null,
            isValid: true,
            validator(dateInputs) {
                const start = dateInputs.find(x => x.key === 'contract_start');
                if (start && start.value && this.value) {
                    return moment(this.value).isAfter(start.value);
                }
                return true;
            },
            errorMessage: 'La date de fin doit être supérieure à la date de début',
        },
    ];

    constructor(private contractEditionService: ContractEditionService) {}

    ngOnInit(): void {
        this.initPeriodicityReminders();
        this.initDateInputs();
        this.initPeriodicities();
    }

    /**
     * Initiate list of available values for periodicity reminders
     */
    private initPeriodicityReminders() {
        this.periodicityReminders.items = [
            {
                value: null,
                label: '-',
            },
        ];
        for (let i = 1; i <= 12; i++) {
            this.periodicityReminders.items.push({
                value: i,
                label: i.toString(),
            });
        }
    }

    /**
     * Initiate date inputs by setting contract values and computing validity
     */
    private initDateInputs() {
        this.dateInputs.forEach(dateInput => {
            const value = _.get(this.contract, dateInput.property);
            if (value) {
                dateInput.value = moment(value).format('YYYY-MM-DD');
            }
            dateInput.isValid = dateInput.validator(this.dateInputs);
        });
    }

    /**
     * Initiate periodicity input fields
     */
    private initPeriodicities() {
        /** Set periodicity */
        const billPeriodicity = _.get(this.contract, 'infos.billPeriodicity');
        if (billPeriodicity) {
            this.periodicity.value = billPeriodicity;
        }

        /** Set periodicity day of the month */
        const billDateNumber = _.get(this.contract, 'infos.billDateNumber');
        if (billDateNumber) {
            this.periodicityDay.value = billDateNumber;
        }

        /** Set periodicity reminder */
        const billNotification = _.get(this.contract, 'infos.billNotification');
        if (billNotification) {
            this.periodicityReminders.value = billNotification;
        }
    }

    /**
     * On date change, set if date is valid then triggers component change
     */
    public onDateChange() {
        // Recompute all validity
        this.dateInputs.forEach(dateInput => {
            dateInput.isValid = dateInput.validator(this.dateInputs);
        });
        this.handleChange();
    }

    /**
     * On input change, compute new info contracts and emit it if valid.
     */
    public handleChange() {
        /** Check all and emit most recent values */
        let valid = true;

        this.dateInputs.forEach(dateInput => {
            if (dateInput.isValid === false) {
                valid = false;
            }
        });

        if (!this.periodicityDay.isValid()) {
            valid = false;
        }

        const infoContract: InfoContract = {
            dateStart: this.dateInputs.find(x => x.key === 'contract_start').value || null,
            dateNotice: this.dateInputs.find(x => x.key === 'contract_on_notice').value || null,
            dateEnd: this.dateInputs.find(x => x.key === 'contract_end').value || null,
            billPeriodicity: this.periodicity.value,
            billDateNumber: this.periodicityDay.value,
            billNotification: this.periodicityReminders.value,
        };
        this.contractEditionService.updateInfos({ data: infoContract, isValid: valid });
    }
}
