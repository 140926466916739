import { Injectable } from '@angular/core';
import moment = require('moment');
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';

import { Company } from 'app/shared/models/company.interface';
import { RoutingReference } from 'app/shared/models/routing-reference.interface';
import { SitePopulated } from 'app/shared/models/site.interface';
import { ApiService } from 'app/shared/services/api/api.service';
import { KeyDataService } from 'app/shared/services/key-data/key-data.service';
import { PageService } from 'app/shared/services/page/page.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

import { RoutingReferenceKeyfigures } from './rref-merge.interface';

@Injectable()
export class RrefMergeService extends PageService {
    /** Event fired with sites list is updated */
    public sites$: BehaviorSubject<SitePopulated[]> = new BehaviorSubject<SitePopulated[]>(null);

    /** Event fired with destination routing reference keyfigures */
    public rRefDestination$: BehaviorSubject<RoutingReferenceKeyfigures> = new BehaviorSubject<
        RoutingReferenceKeyfigures
    >(null);

    /** Event fired with origin routing reference keyfigures */
    public rRefOrigin$: BehaviorSubject<RoutingReferenceKeyfigures> = new BehaviorSubject<RoutingReferenceKeyfigures>(
        null
    );

    /** Keyfigures display */
    public rRefPreview$: BehaviorSubject<RoutingReferenceKeyfigures> = new BehaviorSubject<RoutingReferenceKeyfigures>(
        null
    );

    /** Company related to service */
    private company: Company;

    constructor(
        public apiService: ApiService,
        private sitesService: SitesService,
        private keyDataService: KeyDataService
    ) {
        super(apiService);
    }

    /**
     * Init service data
     * @param {Company} company - company to init in component
     */
    public async init(company: Company) {
        this.company = company;
        await this.refreshSites();
        combineLatest([this.rRefDestination$, this.rRefOrigin$])
            .pipe(
                map(([destination, origin]) => ({
                    destination,
                    origin,
                }))
            )
            .subscribe({
                next: v => {
                    if (v.destination && v.origin) {
                        // Get rref preview
                        this.getPreviewKeyFigures(v.destination, v.origin);
                    } else {
                        this.rRefPreview$.next(null);
                    }
                },
            });
        // Subscribe to site refresh to refresh keyfigures
        this.sites$.subscribe({
            next: async sites => {
                await this.refreshKeyFigures(sites);
            },
        });
    }

    /**
     * Refresh sites and trigger site event
     */
    public async refreshSites() {
        const sites = await this.sitesService.getSites({ companies: this.company._id });
        this.sitesService.sortSitesPopulatedByName(sites.data);
        this.sites$.next(sites.data);
    }

    /**
     * Get keyfigures combined related to 1..n routing references
     * @param {string[]} ids
     */
    public async getKeyfigures(
        rRef: { _id: string; reference: string; energyType: string; alias: string[]; address: any },
        ids: string[]
    ): Promise<RoutingReferenceKeyfigures> {
        const rRefKeyData = await this.keyDataService.getRRefsKeyData(ids);
        if (!rRefKeyData) {
            throw new Error('Empty routing reference key data');
        }
        const rRefKeyFigures: RoutingReferenceKeyfigures = {
            _id: rRef._id,
            reference: rRef.reference,
            energyType: rRef.energyType,
            alias: rRef.alias.map(x => ({ reference: x })),
            address: rRef.address,
            contracts: rRefKeyData.contracts.map(x => ({
                contractId: x.contractId,
                reference: x.reference,
                dateStart: moment(x.dateStart).format('DD/MM/YYYY'),
                dateEnd: moment(x.dateEnd).format('DD/MM/YYYY'),
            })),
            keyfigures: {
                contracts: rRefKeyData.totalContracts,
                bills: rRefKeyData.totalBills,
            },
        };
        return rRefKeyFigures;
    }

    /**
     * Get keyfigures for given routing reference and set it as destination (emit related subject)
     * @param {RoutingReference} rRef
     */
    public async grabDestinationKeyFigures(rRef: RoutingReference): Promise<void> {
        const keyfigures = await this.getKeyfigures(rRef, [rRef._id]);
        this.rRefDestination$.next(keyfigures);
    }

    /**
     * Refresh selected key figures
     */
    public async refreshKeyFigures(sites: SitePopulated[]) {
        const destinationId = this.rRefDestination$.value ? this.rRefDestination$.value._id : null;
        const originId = this.rRefOrigin$.value ? this.rRefOrigin$.value._id : null;
        // Get routing references ids related to destination and origin
        const rRefsToGet: string[] = [];
        if (destinationId) {
            rRefsToGet.push(destinationId);
        }
        if (originId) {
            rRefsToGet.push(originId);
        }
        if (!rRefsToGet.length) {
            return;
        }
        // Get routing references related to destination and origin
        const rRefs: RoutingReference[] = [];
        for (let i = 0, len = sites.length; i < len; i++) {
            for (let j = 0, len2 = sites[i].routingReferences.length; j < len2; j++) {
                if (rRefsToGet.includes(sites[i].routingReferences[j]._id)) {
                    rRefs.push(sites[i].routingReferences[j]);
                }
            }
        }
        // Grab keyfigures realted to routing references ids related to destination and origin
        if (destinationId) {
            const rRef = rRefs.find(x => x._id === destinationId);
            if (rRef) {
                await this.grabDestinationKeyFigures(rRef);
            }
        }
        if (originId) {
            const rRef = rRefs.find(x => x._id === originId);
            if (rRef) {
                await this.grabOriginKeyFigures(rRef);
            }
        }
    }

    /**
     * Clear destination routing reference
     */
    public clearDestination(): null {
        this.rRefDestination$.next(null);
        return null;
    }

    /**
     * Get keyfigures for given routing reference and set it as origin (emit related subject)
     * @param {RoutingReference} rRef
     */
    public async grabOriginKeyFigures(rRef: RoutingReference): Promise<void> {
        const keyfigures = await this.getKeyfigures(rRef, [rRef._id]);
        this.rRefOrigin$.next(keyfigures);
    }

    /**
     * Clear origin routing reference
     */
    public clearOrigin(): null {
        this.rRefOrigin$.next(null);
        return null;
    }

    /**
     * Get keyfigures for preview and set it as preview (emit related subject)
     * @param {RoutingReferenceKeyfigures} destination
     * @param {RoutingReferenceKeyfigures} origin
     */
    public async getPreviewKeyFigures(
        destination: RoutingReferenceKeyfigures,
        origin: RoutingReferenceKeyfigures
    ): Promise<RoutingReferenceKeyfigures> {
        // Get merged list of alias of destination and origin
        const aliasMerged: string[] = destination.alias.concat(origin.alias).reduce((memo, x) => {
            if (!memo.includes(x.reference)) {
                memo.push(x.reference);
            }
            return memo;
        }, []);
        const rRefData = {
            _id: destination._id,
            reference: destination.reference,
            energyType: destination.energyType,
            alias: aliasMerged,
            address: destination.address,
        };
        const keyfigures = await this.getKeyfigures(rRefData, [destination._id, origin._id]);
        this.setPreviewHighlighted(keyfigures, destination);
        this.rRefPreview$.next(keyfigures);
        return keyfigures;
    }

    /**
     * Compare destination and preview values and set highlighted valued in preview
     * @param {RoutingReferenceKeyfigures} preview
     * @param {RoutingReferenceKeyfigures} destination
     */
    private setPreviewHighlighted(preview: RoutingReferenceKeyfigures, destination: RoutingReferenceKeyfigures) {
        if (!destination || !preview) {
            return;
        }
        // Set new alias as highlighted
        preview.alias.forEach(alias => {
            const destAlias = destination.alias.find(x => x.reference === alias.reference);
            if (!destAlias) {
                alias.highlighted = true;
            } else {
                destAlias.highlighted = alias.reference !== destAlias.reference;
            }
        });
        // Set new contracts as highlights
        // If a contract already exists, check if dates changed and set as highlighted if so
        preview.contracts.forEach(contract => {
            const destContract = destination.contracts.find(x => x.contractId === contract.contractId);
            if (!destContract) {
                contract.dateStartHighlighted = true;
                contract.dateEndHighlighted = true;
                contract.referenceHighlighted = true;
            } else {
                contract.dateStartHighlighted = contract.dateStart !== destContract.dateStart;
                contract.dateEndHighlighted = contract.dateEnd !== destContract.dateEnd;
            }
        });
        // Set number of contracts as highlighted if changed
        preview.keyfigures.contractsHighlighted = destination.keyfigures.contracts !== preview.keyfigures.contracts;
        // Set number of bills as highlighted if changed
        preview.keyfigures.billsHighlighted = destination.keyfigures.bills !== preview.keyfigures.bills;
    }

    /**
     * Call API to merge routing references. Destination is kept, origin will be deleted and included in destination
     * @param {string} destinationId - destination routing reference id
     * @param {string} originId - origin routing reference id
     * @returns {Promise<void>} nothing returned. An error is thrown if something fails.
     */
    public async mergeRoutingReferences(destinationId: string, originId: string): Promise<void> {
        if (!destinationId || !originId) {
            throw new Error('Destination and origin must be set');
        }
        const body = {
            destinationRoutingReferenceId: destinationId,
            originRoutingReferenceId: originId,
        };
        const response = await this.post('/api/routing-references-merge', body);
        return response.data;
    }

    /**
     * Handle actions after routing references merge
     */
    public async afterMerge() {
        // Reset destination and origin
        this.clearDestination();
        this.clearOrigin();
        // Refresh sites list
        await this.refreshSites();
    }

    /**
     * Display error
     * @param {string} errorCode - error code. Leave empty for default unknown error message
     */
    public displayError(errorCode: string) {
        const dict = {
            default: errorCode ? errorCode : 'Une erreur inconnue est survenue',
        };
        if (!dict[errorCode]) {
            errorCode = 'default';
        }
        Swal.fire('Erreur', dict[errorCode], 'error');
    }
}
