import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-devis',
    templateUrl: './devis.component.html',
    styleUrls: ['./devis.component.scss'],
    providers: [],
})
export class DevisComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
