import { Component, Input, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import * as moment from 'moment';

import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { LoadCurveService } from 'app/shared/services/load-curve/load-curve.service';
import { MapService } from 'app/shared/services/map/map.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';

@Component({
    selector: 'ga-site-informations',
    templateUrl: './site-informations.component.html',
    styleUrls: ['./site-informations.component.scss'],
    providers: [],
})
export class SiteInformationsComponent implements OnInit {
    @Input() tiles: any[] = [];
    @Input() currentSelection: any = {};
    @Input() fluidCategory = 'energy';

    fluidCategoryFrench: string;

    constructor(
        private mapService: MapService,
        private energyService: EnergyService,
        private siteService: SitesService,
        private loadCurveService: LoadCurveService,
        private sessionService: SessionService,
        private filterService: FilterService
    ) {}
    ngOnInit() {
        this.fluidCategoryFrench = this.energyService.getFluidCategoryFrench(this.fluidCategory);
    }

    getClassFromTilesNumber() {
        const max = 12;
        const size = max / this.tiles.length;
        const prefix = 'col-md-' + size;

        return {
            [prefix]: true,
        };
    }

    getProfileSiteLink() {
        return `/${this.fluidCategoryFrench}/profil/${this.currentSelection._id}`;
    }

    isDisabled() {
        return !this.currentSelection._id;
    }

    getSiteBackgroundImage() {
        return this.mapService.getSitePictureAsBackground(this.currentSelection);
    }

    get loadCurveLink(): string {
        return this.loadCurveService.loadCurveLink;
    }

    /**
     * Load curve params : site id and period over the last month
     */
    get loadCurveParams(): Params {
        const { ds, de } = this.loadCurveService.formatDatesParams(
            moment().subtract(1, 'month'),
            moment().subtract(1, 'd')
        );
        const params: Params = {
            s: this.currentSelection._id,
            ds,
            de,
        };
        if (this.sessionService.getCompanyId() !== this.currentSelection.company) {
            params[this.filterService.getParamAlias('company')] = this.currentSelection.company;
        }
        return params;
    }

    /**
     * Display load curve redirection button only if the selected site is communicating
     * @returns {boolean} true if
     */
    get displayLoadCurveBtn(): boolean {
        return this.currentSelection.communicating;
    }
}
