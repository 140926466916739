import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ga-exchange',
    templateUrl: './exchange.component.html',
    styleUrls: ['./exchange.component.scss'],
    providers: [],
})
export class ExchangeComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
