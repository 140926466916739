import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NumberOptions } from './utils.interface';

/**
 * Number of min/max decimal digits & units for a value type
 */
export interface ValueGroupType {
    type: string;
    nbMaxDecimal: number;
    nbMinDecimal: number;
    units: string[];
}

@Injectable()
export class UtilsService {
    /**
     * Decimal digits per type of value
     */
    private FLUID_DECIMALS_MAX = 0;
    private CO2_DECIMALS_MAX = 2;
    private PERCENT_DECIMALS_MAX = 1;
    private MONEY_DECIMALS_MAX = 2;
    private MONEY_DECIMALS_MIN = 2;
    private LEGACYDATA_DECIMALS_MAX = 2;

    /**
     * Match a type of value with the number of decimal (max & min) and the possible units
     */
    private nbDecimals: ValueGroupType[] = [
        {
            type: 'fluid',
            nbMaxDecimal: this.FLUID_DECIMALS_MAX,
            nbMinDecimal: 0,
            units: ['kWh', 'kWhep', 'KVA', 'm³', 'kWh PCI', 'kWh ef', 'kWh pcs', 'T', 'W', 'kW'],
        },
        {
            type: 'money',
            nbMaxDecimal: this.MONEY_DECIMALS_MAX,
            nbMinDecimal: this.MONEY_DECIMALS_MIN,
            units: ['€', '€ HTVA - TTC'],
        },
        {
            type: 'percent',
            nbMaxDecimal: this.PERCENT_DECIMALS_MAX,
            nbMinDecimal: 0,
            units: ['%'],
        },
        {
            type: 'ratio',
            nbMaxDecimal: this.MONEY_DECIMALS_MAX,
            nbMinDecimal: 0,
            units: ['kWh/dju', 'kWhep/dju'],
        },
        {
            type: 'legacydata',
            nbMaxDecimal: this.LEGACYDATA_DECIMALS_MAX,
            nbMinDecimal: 0,
            units: [],
        },
        {
            type: 'co2',
            nbMaxDecimal: this.CO2_DECIMALS_MAX,
            nbMinDecimal: 0,
            units: ['tco2e', 'tco2e/véhicule'],
        },
    ];

    /**
     * Format the value with the given maximum decimal digits.
     * Use default value if not finite.
     * @param {number} value
     * @param {string} defaultNotFinite
     * @param {number} maxDigits
     * @returns {string} value formated
     */
    formateNumber(value: number, defaultNotFinite: string = 'NC', maxDigits: number = 2): string {
        value = Number(value);

        return isFinite(value) ? this.getFormatedValue(value, maxDigits) : defaultNotFinite;
    }

    /**
     * Format the value with its unit from the given type.
     * Use default value if not finite.
     * @param {number} value
     * @param {string} defaultNotFinite
     * @param {string} type
     * @returns {string}
     */
    formateNumberType(value: number, defaultNotFinite: string = 'NC', type: string = null): string {
        const isNull = value === null;
        value = Number(value);

        return isFinite(value) && !isNull ? this.getNumberAndUnitToDisplay(value, null, type) : defaultNotFinite;
    }

    /**
     * Format the value with the given maximum decimal digits
     * @param {number} value
     * @param {number} maxDigits
     * @returns {string} number formated
     */
    getFormatedValue(number: number, maxDigits: number = 2): string {
        return Intl.NumberFormat('fr-FR', { maximumFractionDigits: maxDigits }).format(number);
    }

    /**
     * Returns the string without accent
     * @param {string} str
     * @returns {string} same string without accent
     */
    removeAccentFromString(str: string): string {
        if (typeof str === 'string') {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }

        return str;
    }

    /**
     * Returns the string without accent and lower cased
     * @param {string} str
     * @returns {string} same string without accent and lower cased
     */
    removeAccentAndLowerCase(str: string): string {
        if (typeof str === 'string' && str) {
            return this.removeAccentFromString(str.toLowerCase());
        }
        return str;
    }

    /**
     * Returns a group type for a given unit or type
     * @param {string} unit
     * @param {string} type
     * @returns {ValueGroupType} group type
     */
    getValueGroup(unit: string, type: string): ValueGroupType {
        return this.nbDecimals.find(
            x => x.type === type || (unit && x.units.some(u => u && u.toLowerCase() === unit.toLowerCase()))
        );
    }

    /**
     * Return the number of maximum decimal digits for the given unit or type.
     * Default value is the fluid's type maximum decimal digits
     * @param {string} unit
     * @param {string} type
     * @returns {number} maximum number of decimal digits
     */
    getNbMaxDecimalsForUnitOrType(unit: string, type: string = null): number {
        if (unit || type) {
            const search = this.getValueGroup(unit, type);
            if (search) {
                return search.nbMaxDecimal;
            }
        }
        return this.FLUID_DECIMALS_MAX;
    }

    /**
     * Return the number of minimum decimal digits for the given unit or type.
     * Default value is 0
     * @param {string} unit
     * @param {string} type
     * @returns {number} minim number of decimal digits
     */
    getNbMinDecimalsForUnitOrType(unit: string, type: string = null): number {
        if (unit || type) {
            const search = this.getValueGroup(unit, type);
            if (search) {
                return search.nbMinDecimal;
            }
        }
        return 0;
    }

    /**
     * Format value with the correct decimals digits and its unit
     * @param {number} value
     * @param {string} unit
     * @param {string} type
     * @returns {string} value formated with its unit
     */
    getNumberAndUnitToDisplay(value: number, unit: string = null, type: string = null): string {
        return (
            Intl.NumberFormat('fr-FR', {
                maximumFractionDigits: this.getNbMaxDecimalsForUnitOrType(unit, type),
                minimumFractionDigits: this.getNbMinDecimalsForUnitOrType(unit, type),
            }).format(value) + (unit ? ' ' + unit : '')
        );
    }

    /**
     * Get the value prettified display with its unit.
     * Use default value if not finite.
     * @param {number} value
     * @param {NumberOptions} options
     * @returns {string}
     */
    getNumberPretty(value: number, unit: string = null, options: NumberOptions): string {
        const opt: NumberOptions = {
            defaultNotFinite: 'NC',
            defaultDisplayUnit: false,
            type: null,
        };
        Object.assign(opt, options);
        const isNull = value === null;
        value = Number(value);
        if (isFinite(value) && !isNull) {
            return this.getNumberAndUnitToDisplay(value, unit, opt.type);
        }
        return opt.defaultNotFinite + (opt.defaultDisplayUnit && unit ? ` ${unit}` : '');
    }

    /**
     * Returns an object with its field name changed and other properties untouched
     * @param {string} oldField
     * @param {string} newField
     * @param {{[key: string]: any}} object - object on which we operate the field name change
     */
    renameField(oldField: string, newField: string, { [oldField]: fieldValue, ...others }: any) {
        return { [newField]: fieldValue, ...others };
    }

    /**
     * Round number with precision.
     * Example with a precision 1e3: 3.00445 => 3.004
     * @param {number} val
     * @param {number} precision
     * @returns {number}
     */
    roundNumber(val: number, precision: number = 1e3): number {
        return Math.round(val * precision) / precision;
    }

    /**
     * humanize duration (ex: '1 j 10 h 23 min')
     * @param {number} duration - duration in milliseconds
     * @returns {string}
     */
    formatDuration(duration: number): string {
        let durationFormatted = '';
        const durationMoment = moment.duration(duration);

        if (durationMoment.years()) {
            durationFormatted += `${durationMoment.years()} a`;
        }
        if (durationMoment.months()) {
            durationFormatted += ` ${durationMoment.months()} m`;
        }
        if (durationMoment.days()) {
            durationFormatted += ` ${durationMoment.days()} j`;
        }
        if (durationMoment.hours()) {
            durationFormatted += ` ${durationMoment.hours()} h`;
        }
        if (durationMoment.minutes()) {
            durationFormatted += ` ${durationMoment.minutes()} min`;
        }
        if (durationMoment.seconds()) {
            durationFormatted += ` ${durationMoment.seconds()} sec`;
        }

        return durationFormatted;
    }

    /**
     * Sort a list of object alphabetically
     *
     * @param {*[]} - list of object
     * @param {string} property - property to sort
     */
    sortAlphabetically(objects: any[], property: string) {
        return objects.sort((objA, objB) =>
            _.get(objA, property).localeCompare(_.get(objB, property), 'fr', {
                sensitivity: 'base',
            })
        );
    }

    /**
     * Escape string for regular expression use
     * @param {string} value - value to escape
     * @returns {string} value
     */
    public escapeRegExp(value: string): string {
        if (typeof value !== 'string') {
            return value;
        }
        return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }
}
