import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'ga-chart-doughnut-toggle',
    templateUrl: './chart-doughnut-toggle.component.html',
    styleUrls: ['./chart-doughnut-toggle.component.scss'],
    providers: [],
})

// not used in the app
export class ChartDoughnutToggleComponent implements OnInit {
    @Input() titles: string[] = ['Total', 'HP/HC'];
    @Input() datasets: any[];
    @Input() colors: object[];
    @Input() labels: object[];
    @Input() width = 300;
    @Input() height = 300;
    @Input() showGrid = true;
    @Input() legend: any = false;
    @Input() options: object = {};

    @Output() emitChartClick = new EventEmitter();

    public activeDataset = 0;
    public dataset: any[] = [];

    constructor() {}

    chartClicked(event: any) {
        const newEvent: any = event;
        newEvent.activeDataset = this.activeDataset;

        this.emitChartClick.emit(newEvent);
    }

    setActiveBarChart(index: number) {
        this.activeDataset = index;
        this.dataset = this.datasets[index];
    }

    ngOnInit() {
        this.setActiveBarChart(0);
    }
}
