import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartLineComponent } from '../chart-line/chart-line.component';

@Component({
    selector: 'ga-chart-line-toggle',
    templateUrl: './chart-line-toggle.component.html',
    styleUrls: ['./chart-line-toggle.component.scss'],
    providers: [],
})
export class ChartLineToggleComponent implements OnInit {
    @Input() datasets: any[];
    @Input() colors: object[];
    @Input() labels: object[];
    @Input() width = 900;
    @Input() minHeight = 340;
    @Input() showGrid = true;
    @Input() legend = false;
    @Input() options: any[];
    @Input() downloadFileNames: any[];
    @Input() toggleOptions: string[];

    @ViewChild('chart', { static: false }) chart: ChartLineComponent;

    @Output() emitChartClick = new EventEmitter();

    public activeDataset = 0;

    constructor() {}

    ngOnInit() {
        this.setActiveLineChart(0);
    }

    chartClicked(event) {
        this.emitChartClick.emit(event);
    }

    setActiveLineChart(index: number) {
        if (this.chart && this.activeDataset !== index) {
            this.chart.updateChart();
        }
        this.activeDataset = index;
    }

    DatasetHasData(datasetId) {
        if (this.datasets[this.activeDataset][datasetId]) {
            return this.datasets[this.activeDataset][datasetId].data.reduce((memo, item) => memo + item, 0) !== 0;
        }

        return false;
    }

    DatasetHPHasData() {
        return this.DatasetHasData(0);
    }

    DatasetHCHasData() {
        return this.DatasetHasData(1);
    }

    currentChartHasData() {
        if (this.activeDataset === 0) {
            return this.datasets.length && this.datasets[this.activeDataset].length;
        }

        return (
            this.datasets.length &&
            this.datasets[this.activeDataset].length &&
            (this.DatasetHPHasData() || this.DatasetHCHasData())
        );
    }

    getButtonClass(i) {
        return this.activeDataset === i ? 'btn-primary' : 'btn-white';
    }

    get currentDataSet() {
        return this.datasets[this.activeDataset];
    }

    get currentColors() {
        return this.colors[this.activeDataset];
    }

    get currentLabels() {
        return this.labels;
    }

    get currentLegends() {
        return this.legend[this.activeDataset];
    }

    get currentDownloadFileName() {
        return this.downloadFileNames[this.activeDataset];
    }

    get currentOptions() {
        return this.options[this.activeDataset];
    }
}
