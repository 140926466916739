export enum CONTRACTUAL_DATA_SOURCE {
    ENEDIS = 'enedis_cdtc_v1',
}

/** Represents the identifiers of a contractual data item. */
export interface Identifiers extends Record<string, string> {}

/** Represents the values of a property of a contractual data item.  */
export interface DataProperty {
    key: string;
    values: Array<{
        _id?: string;
        value: unknown;
        startDate?: Date;
        endDate?: Date;
    }>;
}

/** Represents the synchronizations history for each source of data for one contractual data item.  */
export interface SourceSynchronizations {
    source: string;
    lastSync: {
        syncId: string;
        requestedAt: string;
        completedAt?: string;
    };
}

/** Represents a contractual data item. */
export interface ContractualData {
    identifiers: Identifiers;
    data?: DataProperty[];
    synchronizations?: SourceSynchronizations[];
}
