import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DropdownSettings } from 'app/shared/components/common/dropdown-multilevel/dropdown-multilevel.interface';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import {
    Filter,
    FiltersAvailable,
    FilterSelectGrp,
    GrpOption,
    Option,
    QueryFilter,
} from 'app/shared/models/bills-contracts-filter.interface';
import { NoticeState } from 'app/shared/models/contract.interface';
import { BillsListService } from 'app/shared/services/bills-list/bills-list.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { UsersService } from 'app/shared/services/users/users.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'ga-bills-contracts-filter',
    templateUrl: './bills-contracts-filter.component.html',
    styleUrls: ['./bills-contracts-filter.component.scss'],
    providers: [],
})
export class BillsContractsFilterComponent implements OnInit, OnChanges {
    isLoading = true;

    @Input() filtersAvailable: FiltersAvailable;

    @Output()
    filterChanged = new EventEmitter<QueryFilter>();

    /**
     * Form controls to add a debounce time to fields
     */
    form = {
        search: new FormControl(),
        start: new FormControl(),
        end: new FormControl(),
    };

    filters: Filter;

    toggleFilters = false;

    // Configuration for the dropdown list
    public dropdownSettings: DropdownSettings = {
        multiple: true,
        bindLabel: 'name',
        groupBy: 'group',
        selectableGroup: true,
        selectableGroupAsModel: true,
        notFoundText: 'Aucun type trouvé',
        dropdownPosition: 'bottom',
        customSearch: (term: string, item: GrpOption) => {
            term = term.toLowerCase();
            return [item.name, item.value, item.group].some(x => x && x.toLowerCase().indexOf(term) > -1);
        },
    };

    constructor(
        private energyService: EnergyService,
        private filterService: FilterService,
        private route: ActivatedRoute,
        private router: Router,
        private usersService: UsersService,
        private billsListService: BillsListService,
        private translateService: TranslateService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.initFilter();

        this.form.search.valueChanges
            .pipe(debounceTime(300))
            .subscribe({ next: newSearch => this.onInputSearchChanged(newSearch) });

        this.route.queryParams.subscribe({
            next: queryParams => {
                this.updateFiltersFromParams(queryParams);
            },
        });
    }

    async ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
        for (const propName in changes) {
            if (propName === 'filtersAvailable' && !changes.filtersAvailable.firstChange) {
                await this.setAvailableFilters();
                this.isLoading = false;
            }
        }
    }

    /**
     * ------
     * Initilisaiton
     * ------
     */

    /**
     * Initialize the filter with data sent from parent.
     */
    async initFilter() {
        this.filters = {
            searchValue: '',
            hasBranches: false,
            includeBranches: false,
            errorMsg: null,
        };

        await this.setAvailableFilters();

        this.isLoading = false;
    }

    /**
     * Returns array for selectbox with name in French and value in short English term
     * @param {string[]} fluidsArray: array of short English fluids names. ex: ["elec", "gaz"]
     * @returns {Option[]} fluids array formated as options
     */
    private formatFluidSelectbox(fluidsArray: string[]): Option[] {
        return fluidsArray.map(fluid => {
            return {
                name: this.energyService.energyFullText(fluid),
                value: fluid,
            };
        });
    }

    /**
     * Returns the array of providers formated as options
     * @param {string[]} providersArray
     * @returns {Option[]} providers formated as options
     */
    private formatProvidersSelectBox(providersArray: string[]): Option[] {
        return providersArray.map(provider => {
            return {
                value: provider,
                name: provider,
            };
        });
    }

    /**
     *
     * @param {string[]} status
     * @returns {Option[]}
     */
    private formatStatusSelectBox(status: string[]): Option[] {
        return status.map(value => {
            return {
                value,
                name: this.billsListService.getBillStatusFullText(value),
            };
        });
    }

    /**
     * Returns the array of failed verifications possibilities formated as options
     * @param {{ [fluid: string]: Option[] }} fluidsOptions
     * @returns {GrpOption[]} failed verifications formated as options
     */
    private formatFailedVerificationsSelectBox(fluidsOptions: { [fluid: string]: Option[] }): GrpOption[] {
        return Object.keys(fluidsOptions).reduce((memo: GrpOption[], fluid) => {
            const items = fluidsOptions[fluid];
            const fluidLabel = this.translateService._(fluid);
            const fluidOptions = items.map(x => Object.assign({ group: fluidLabel }, x));
            return memo.concat(fluidOptions);
        }, []);
    }

    /**
     * Returns the array of the contract states formated as options
     * @param {NoticeState[]} contractStatesArray
     * @returns {Option[]}
     */
    private formatContractStatesSelectBox(contractStatesArray: NoticeState[]): Option[] {
        return contractStatesArray.map(contractState => {
            return {
                value: contractState.name,
                name: contractState.msg,
            };
        });
    }

    /**
     * Update filters from params object. If some changes valid, an event to update the search.
     * Toggle the advanced filter when one of its fields is updated by the params.
     * @param {Params} params - object containing params to set
     * @param {string} params.e - error param, must be 0 or 1
     * @param {string} params.q - search query (bill number for example)
     * @param {string} start - period date start (format YYYY-MM)
     * @param {string} end - period date end (format YYYY-MM)
     */
    private updateFiltersFromParams(params: Params): void {
        const onlyErrorString = params['e'];

        if (_.get(this.filtersAvailable, 'onlyErrorSelected.enabled') && ['1', '0'].includes(onlyErrorString)) {
            this.updateOnlyError(Boolean(onlyErrorString === '1'));
            this.toggleFilters = true;
        }

        if (
            _.get(this.filtersAvailable, 'searchValue.enabled') &&
            params['q'] &&
            params['q'] !== this.filters.searchValue
        ) {
            this.form.search.setValue(params['q']);
        }

        if (_.get(this.filtersAvailable, 'years.enabled')) {
            if (params['date']) {
                const date = moment(params['date']);
                if (date.isValid()) {
                    const formattedDate = date.format('YYYY-MM');
                    if (formattedDate !== this.filters.years.selectedStart) {
                        this.form.start.setValue(formattedDate);
                        this.toggleFilters = true;
                    }
                    if (formattedDate !== this.filters.years.selectedEnd) {
                        this.form.end.setValue(formattedDate);
                        this.toggleFilters = true;
                    }
                }
            }
            if (params['start']) {
                const date = moment(params['start']);
                if (date.isValid()) {
                    const formattedDate = date.format('YYYY-MM');
                    if (formattedDate !== this.filters.years.selectedStart) {
                        this.form.start.setValue(formattedDate);
                        this.toggleFilters = true;
                    }
                }
            }
            if (params['end']) {
                const date = moment(params['end']);
                if (date.isValid()) {
                    const formattedDate = date.format('YYYY-MM');
                    if (formattedDate !== this.filters.years.selectedEnd) {
                        this.form.end.setValue(formattedDate);
                        this.toggleFilters = true;
                    }
                }
            }
        }

        /**
         * Emit an event to launch the search with the correct filters
         */
        this.emitFilterChanged();
    }

    /**
     * --------
     * User interactions
     * --------
     */

    /**
     * Handle search from user keyboard in the research part
     * @param {string} searchInput
     */
    onInputSearchChanged(searchInput: string) {
        this.filters.searchValue = searchInput;
        this.emitFilterChanged();
    }

    /**
     * Handle a change in dates selection
     *
     * @param {string} value - filled date input value
     * @property {'selectedStart' | 'selectedEnd'} property - date input target (start date or end date)
     * @returns {void}
     */
    onDatesChanged(value: string, property: 'selectedStart' | 'selectedEnd'): void {
        this.filters.years[property] = value && moment(value, 'YYYY-MM').isValid() ? value : null;

        if (!this.filters.years.selectedStart || !this.filters.years.selectedEnd) {
            this.filters.errorMsg = null;
            // If allowMissngBound option is enabled : emit on changed start date or changed end date
            if (this.filtersAvailable.years.allowMissingBound === true) {
                this.emitFilterChanged();
            }
            return;
        }

        const isDateStartBeforeEnd = moment(this.filters.years.selectedStart)
            .startOf('month')
            .isBefore(moment(this.filters.years.selectedEnd).endOf('month'));
        if (isDateStartBeforeEnd) {
            this.filters.errorMsg = null;
            this.emitFilterChanged();
        } else {
            this.filters.errorMsg = 'Votre date de début ne peut pas être supérieure à votre date de fin.';
        }
    }

    /**
     * Manage click error checkbox. Navigate by updating query parameter e (= error, only error).
     * @param {Event} e - event from checkbox
     */
    onClickOnlyError(e) {
        this.updateOnlyError(e.target.checked);
        // changes the route without moving from the current view or
        // triggering a navigation event
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { e: this.filters.onlyErrorSelected ? '1' : '0' },
            // preserve the existing query params in the route
            queryParamsHandling: 'merge',
            skipLocationChange: false,
        });
    }

    /**
     * Manage click on checkbox to include branches. Set value selected in filters.
     * @param {Event} e - event from checkbox
     */
    onClickIncludeBranches(e) {
        this.isLoading = true;
        this.filters.includeBranches = e.target.checked;
        this.emitFilterChanged();
    }

    /**
     * Update filter value for onlyErrorSelected
     * @param {boolean} value - true to display only error, false otherwise
     */
    private updateOnlyError(value: boolean) {
        this.filters.onlyErrorSelected = value;
    }

    /**
     * Compute and emit the new filters query to the parent component
     */
    public emitFilterChanged() {
        this.filterChanged.emit(this.computeFilterQuery());
    }

    /**
     * Set selected values to filter. Handle filter using groups changes.
     * If group selected, add all items of the group to filter selected items.
     * @param {GrpOption[]} selected - group options selected
     * @param {FilterSelectGrp} filterSelect - related filter
     */
    public groupFilterChanged(selected: GrpOption[], filterSelect: FilterSelectGrp) {
        if (selected) {
            filterSelect.selected = selected.reduce((memo: GrpOption[], item) => {
                // If not a group, has a value, so add it.
                if (item.value) {
                    memo.push(item);
                    return memo;
                }
                // Otherwise, it's a group, so we need to add all items of the group.
                const groupItems = filterSelect.available.filter(x => x.group === item.group);
                memo = memo.concat(groupItems);
                return memo;
            }, []);
        }
        this.emitFilterChanged();
    }

    /**
     * Select/unselect fluid in filter
     * @param {{ name: string; value: string }} fluidOption
     */
    selectFluid(fluidOption: { name: string; value: string }) {
        const index = _.findIndex(this.filters.fluids.selected, { value: fluidOption.value });

        if (index === -1) {
            this.filters.fluids.selected.push(fluidOption);
        } else {
            this.filters.fluids.selected.splice(index, 1);
        }

        this.emitFilterChanged();
    }

    /**
     * Get fluid selection class
     * @param {{ name: string; value: string }} fluidOption
     * @returns {string}
     */
    getFluidSelectionClass(fluidOption: { name: string; value: string }): string {
        const index = _.findIndex(this.filters.fluids.selected, { value: fluidOption.value });

        if (index === -1) {
            return '';
        }
        return 'selected';
    }

    /**
     * Compute the query from the filters selection
     * @return {QueryFilter}
     */
    computeFilterQuery(): QueryFilter {
        const queryFilters: QueryFilter = {};

        if (_.get(this.filtersAvailable, 'searchValue.enabled')) {
            queryFilters.query = this.filters.searchValue;
        }

        if (_.get(this.filtersAvailable, 'branches.enabled')) {
            queryFilters.includeBranches = this.filters.includeBranches;
        }

        if (
            _.get(this.filtersAvailable, 'onlyErrorSelected.enabled') &&
            typeof this.filters.onlyErrorSelected === 'boolean'
        ) {
            queryFilters.onlyError = this.filters.onlyErrorSelected;
        }

        if (_.get(this.filtersAvailable, 'years.enabled') && this.filters.years) {
            if (!this.filtersAvailable.years.allowMissingBound) {
                this.filters.years.selectedStart = this.filters.years.selectedStart || this.filters.years.minDate;
                this.filters.years.selectedEnd = this.filters.years.selectedEnd || this.filters.years.maxDate;
            }

            if (this.filters.years.selectedStart) {
                queryFilters.start = moment
                    .utc(this.filters.years.selectedStart)
                    .startOf('month')
                    .toISOString();
            }
            if (this.filters.years.selectedEnd) {
                queryFilters.end = moment
                    .utc(this.filters.years.selectedEnd)
                    .endOf('month')
                    .toISOString();
            }
        }

        if (_.get(this.filtersAvailable, 'fluids.enabled') && this.filters.fluids.selected.length) {
            queryFilters.fluids = this.filters.fluids.selected.map(f => f.value);
        }

        if (_.get(this.filtersAvailable, 'providers.enabled') && this.filters.providers.selected.length) {
            queryFilters.providers = this.filters.providers.selected.map(f => f.value);
        }

        if (_.get(this.filtersAvailable, 'contractStates.enabled') && this.filters.contractStates.selected.length) {
            queryFilters.states = this.filters.contractStates.selected.map(f => f.value);
        }

        if (_.get(this.filtersAvailable, 'status.enabled') && this.filters.status.selected.length) {
            queryFilters.status = this.filters.status.selected.map(f => f.value);
        }

        if (
            _.get(this.filtersAvailable, 'failedVerifications.enabled') &&
            this.filters.failedVerifications.selected.length
        ) {
            queryFilters.failedVerifications = this.filters.failedVerifications.selected.map(f => f.value);
        }

        return queryFilters;
    }

    /**
     * Get the fluid type (water, elec...)
     * @param {Option} fluid
     */
    getFluidType(fluid: Option): string {
        return fluid.value;
    }

    /**
     * Set the filter with data sent from the parent
     */
    async setAvailableFilters() {
        if (_.get(this.filtersAvailable, 'years.enabled')) {
            let start = '';
            let min = null;
            if (this.filtersAvailable.years.selectedStart) {
                start = this.filtersAvailable.years.selectedStart;
            }
            if (this.filtersAvailable.years.yearsMin) {
                min = this.filtersAvailable.years.yearsMin.concat('-01');
                start = start || min;
            }

            let end = '';
            let max = null;
            if (this.filtersAvailable.years.selectedEnd) {
                end = this.filtersAvailable.years.selectedEnd;
            }
            if (this.filtersAvailable.years.yearsMin) {
                max = this.filtersAvailable.years.yearsMax.concat('-12');
                end = end || max;
            }

            const selectedStart = _.get(this.filters, 'years.selectedStart', '');
            const selectedEnd = _.get(this.filters, 'years.selectedEnd', '');

            this.filters.years = {
                selectedStart: this.filtersAvailable.years.setDefault ? start : selectedStart,
                selectedEnd: this.filtersAvailable.years.setDefault ? end : selectedEnd,
                minDate: min,
                maxDate: max,
            };

            this.form.start.setValue(this.filters.years.selectedStart);
            this.form.end.setValue(this.filters.years.selectedEnd);

            this.form.start.valueChanges
                .pipe(debounceTime(500))
                .subscribe({ next: value => this.onDatesChanged(value, 'selectedStart') });
            this.form.end.valueChanges
                .pipe(debounceTime(500))
                .subscribe({ next: value => this.onDatesChanged(value, 'selectedEnd') });
        }

        if (_.get(this.filtersAvailable, 'fluids.enabled')) {
            const availableFluids = _.get(this.filtersAvailable, 'fluids.values', []);
            const selectedFluids = _.get(this.filters, 'fluids.selected', []).filter(f => {
                return availableFluids.includes(f.value);
            });

            this.filters.fluids = {
                available: this.formatFluidSelectbox(this.filtersAvailable.fluids.values),
                selected: selectedFluids,
            };
        }

        if (_.get(this.filtersAvailable, 'providers.enabled')) {
            const availableProviders = _.get(this.filtersAvailable, 'providers.values', []);
            const selectedProviders = _.get(this.filters, 'providers.selected', []).filter(p => {
                return availableProviders.includes(p.value);
            });

            this.filters.providers = {
                available: this.formatProvidersSelectBox(this.filtersAvailable.providers.values),
                selected: selectedProviders,
            };
        }

        if (_.get(this.filtersAvailable, 'contractStates.enabled')) {
            this.filters.contractStates = {
                available: this.formatContractStatesSelectBox(this.filtersAvailable.contractStates.values),
                selected: [],
            };
        }

        if (_.get(this.filtersAvailable, 'status.enabled')) {
            this.filters.status = {
                available: this.formatStatusSelectBox(this.filtersAvailable.status.values),
                selected: [],
            };
        }

        if (_.get(this.filtersAvailable, 'onlyErrorSelected.enabled')) {
            this.filters.onlyErrorSelected = this.filtersAvailable.onlyErrorSelected.value || false;
        }

        if (_.get(this.filtersAvailable, 'failedVerifications.enabled')) {
            this.filters.failedVerifications = {
                available: this.formatFailedVerificationsSelectBox(this.filtersAvailable.failedVerifications.values),
                selected: [],
                placeholder: this.filtersAvailable.failedVerifications.placeholder,
            };
        }

        // display the checkbox to include branches only when useful
        if (_.get(this.filtersAvailable, 'branches.enabled')) {
            const branches = await this.filterService.getAllVisibleBranches();
            this.filters.hasBranches = Boolean(branches.length);
        }
    }
}
