import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';

@Component({
    selector: 'ga-yes-no-modal',
    templateUrl: './yes-no-modal.component.html',
    styleUrls: ['./yes-no-modal.component.scss'],
})
export class YesNoModalComponent implements OnInit {
    swalOptions: any = {
        text: 'will be replaced with content in swal component', // THIS IS IMPORTANT KEEP IT AS IT OR CONTENT WONT BE SHOWN
        showCancelButton: true,
    };

    @Input() value: any;
    @Input() error: string;
    @Input() title: string;
    @Input() text: string;
    @Input() cancelBtnText = 'Annuler';
    @Input() confirmBtnText = 'Enregistrer';

    @Output() confirm: EventEmitter<any> = new EventEmitter();
    @Output() close: EventEmitter<any> = new EventEmitter();
    @Output() open: EventEmitter<any> = new EventEmitter();

    @ViewChild('modal', { static: true }) private modal: SwalComponent;

    constructor(public readonly swalTargets: SwalPortalTargets) {}

    ngOnInit() {}

    show() {
        this.modal.fire();
    }

    dismiss() {
        Swal.close();
    }

    emitOpenEvent(event) {
        this.open.emit(event);
    }

    emitCloseEvent(event) {
        this.close.emit(event);
    }

    send() {
        this.confirm.emit();
    }
}
