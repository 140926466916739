import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterConfig, TypeFilter } from '../../../models/filter-config.interface';
import { FilterService } from '../../../services/filter/filter.service';

enum ParamsType {
    String = 'string',
    Array = 'array',
}

/**
 * Filter query values
 * Non exhaustive list of all possibilities.
 * But listing some fields can help with the type
 */
export interface FilterQuery {
    dateStart?: string;
    dateEnd?: string;
    [key: string]: string | any[] | number;
}

@Component({
    selector: 'ga-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
    public readonly ALL_VALUE_FEM = 'Toutes';
    public readonly ALL_VALUE_MASC = 'Tous';
    public filters: FilterConfig[] = [];
    public TypeFilter = TypeFilter;
    public minYear: string = null;
    public maxYear: string = null;

    // ** autocomplete select dropdowns
    // array of all the categories/regions/sites
    public initialSearch = {
        sites: [] = [],
        categories: [] = [],
        regions: [] = [],
        energies: [] = [],
    };

    // array of the categories/regions/sites displayed
    public tempSearch = {
        sites: [] = [],
        categories: [] = [],
        regions: [] = [],
        energies: [] = [],
    };

    // text input from the user
    public autoCompleteValue = {
        sites: null,
        categories: null,
        regions: null,
        energies: null,
    };

    // last value displayed in the input when a category is clicked
    public lastSelectedValue = {
        sites: null,
        categories: null,
        regions: null,
        energies: null,
    };

    private clickInsideFiter = {
        sites: false,
        categories: false,
        regions: false,
        energies: false,
    };

    public placeHolders = {
        sites: this.ALL_VALUE_MASC,
        categories: this.ALL_VALUE_FEM,
        regions: this.ALL_VALUE_FEM,
        energies: this.ALL_VALUE_FEM,
    };

    public placeHoldersMultiSelect = {
        sites: 'Sélectionne un site',
        categories: 'Sélectionnez une catégorie',
        regions: 'Sélectionnez une région',
        energies: 'Sélectionnez un fluide',
    };

    public optionClass = 'listOption';

    private filterIndex = {
        sites: 0,
        categories: 0,
        regions: 0,
        energies: 0,
    };

    @Input() public tags = {
        sites: [] = [],
        categories: [] = [],
        regions: [] = [],
        energies: [] = [],
    };
    // to open / close the list of the dropdowns
    public showOptionsList = {
        sites: false,
        categories: false,
        regions: false,
        energies: false,
    };

    /**
     * Map between filter query and queryParams names add types
     */
    private paramsQueryMap: Array<{
        param: string;
        query: string;
        type: ParamsType;
    }> = [
        { param: 'loc', query: 'regions', type: ParamsType.String },
        { param: 'cat', query: 'categories', type: ParamsType.String },
        { param: 'en', query: 'energies', type: ParamsType.String },
        { param: 'c', query: 'companyId', type: ParamsType.String },
        { param: 'c_s', query: 'companies', type: ParamsType.Array },
        { param: 'start', query: 'dateStart', type: ParamsType.String },
        { param: 'end', query: 'dateEnd', type: ParamsType.String },
    ];

    private routeSubscription: Subscription;

    @Input() filterName: string;

    @Output() sendError: EventEmitter<string> = new EventEmitter();
    @Output() querySearch: EventEmitter<FilterQuery> = new EventEmitter();
    @Input() hasLabel = true;
    @Input() companyId: string = null;
    @Input() searchBtnClass: string;

    /**
     * Class name on filters html objects. Used mainly for columns management.
     * Applied to every filter item.
     */
    @Input() filterClassName = '';

    // ** Listen to the user's clicks to determine whether the user clicked on a category from the list or outside the list to exit it
    @HostListener('click', ['$event']) isClickOnSearchList(event) {
        // clicked inside the filter component
    }

    @HostListener('document:click', ['$event']) IsClickOutsideList(event) {
        // if clicks on Search -> the input is reset & list of options hidden
        if (!event.target['classList'].contains(this.searchBtnClass)) {
            // has an id only if clicked on the input, none if clicked outside
            // if clicks completely outside the filter anywhere on the page --> wants to close the dropdown
            if (event.target.id !== 'onInputMulti' && event.target.id !== 'onInputSingle') {
                this.showOptionsList.categories = false;
                this.showOptionsList.regions = false;
                this.showOptionsList.sites = false;
                this.showOptionsList.energies = false;
            }
        }
    }

    constructor(private filterService: FilterService, private route: ActivatedRoute, private router: Router) {}

    ngOnInit() {
        this.initFilter().then(async () => {
            /**
             * Only one subscription of the route must exists for the filter
             * (Prevent multiple subscrition after company change on home page)
             */
            if (this.routeSubscription) {
                this.routeSubscription.unsubscribe();
            }
            // We only launch the event for the page queries when filters are loaded
            // We don't want to override existing params, but set them if they don't exist.
            await this.search(false);

            this.routeSubscription = this.route.queryParams.subscribe({
                next: params => {
                    // On refresh (= session company change), we unsubscribe to simulate a "fresh start".
                    if (params && params.refresh === 'true') {
                        if (this.routeSubscription) {
                            this.routeSubscription.unsubscribe();
                        }
                    } else {
                        const query = this.paramsToQuery(params);
                        this.setQueryValueToFilters(query);
                        this.querySearch.emit(query);
                    }
                },
            });
        });
    }

    ngOnDestroy() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    /**
     * Set query values to filters as selected value,
     * or resets filters' default value if no query param is provided
     * Used when loading page with filters already in URL.
     * If value set is the same, won't change filter.
     * @param {FilterQuery} query - query to set values of.
     */
    private setQueryValueToFilters(query: FilterQuery) {
        this.filters.forEach(filter => {
            const value = query[filter.propertyName];
            if (typeof value !== 'undefined') {
                if (filter.multiSelect) {
                    filter.selectedValue = value.toString().split(',');
                } else {
                    filter.selectedValue = value;
                }
            } else {
                filter.selectedValue = filter.defaultValue;
            }
        });
    }

    /**
     * Get values from filters and set it into a query then transform it to URL parameters.
     * Finally, update URL's parameters. This change will be caught by query parameters subscribers to emit search.
     * @param {boolean} overrideParams - if true, override URL params, if false, set only if params weren't present
     */
    async search(overrideParams: boolean = true): Promise<void> {
        const query: FilterQuery = {};
        for (const filter of this.filters) {
            if (filter.type === TypeFilter.selectAutoComplete) {
                this.showOptionsList[filter.propertyName] = false;
                if (filter.multiSelect) {
                    this.autoCompleteValue[filter.propertyName] = '';
                    query[filter.propertyName] = filter.multiSelectedValues;
                } else {
                    this.autoCompleteValue[filter.propertyName] = this.lastSelectedValue[filter.propertyName];
                    query[filter.propertyName] = filter.selectedValue;
                }
            } else {
                query[filter.propertyName] = filter.selectedValue;
            }
        }

        /**
         * Update route's queryParams with current search
         * If not trying to override the params, will merge them.
         * Keep the old version of a param, if it was existing. Create it otherwise
         */
        const params = this.queryToParams(query);
        if (!overrideParams) {
            Object.assign(params, this.route.snapshot.queryParams);
        }
        // changes the route without moving from the current view or
        // triggering a navigation event
        await this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: '',
            skipLocationChange: false,
        });
    }

    /**
     * Called on autocomplete input change
     * @param {string} filterName - name of the input
     * @param {string} search - search value
     */
    public onInputSelected(filterName: string, search: string) {
        this.showOptionsList[filterName] = true;
        this.clickInsideFiter[filterName] = true;
        this.tempSearch[filterName] = [];

        if (
            search === '' ||
            search === this.placeHolders[filterName] ||
            search === this.placeHoldersMultiSelect[filterName]
        ) {
            this.tempSearch[filterName] = this.initialSearch[filterName].slice();
        } else {
            this.updateTempSearch(filterName, search);
        }
    }

    /**
     * Update the autocomplete input results by specified search value
     * @param {string} filterName - filter name of the handled input
     * @param {string} search - search value
     */
    private updateTempSearch(filterName: string, search: string) {
        const lowerSearch = search.toLowerCase();

        this.tempSearch[filterName] = this.initialSearch[filterName].filter(option => {
            return option.text.toLowerCase().includes(lowerSearch);
        });
    }

    /**
     * On option clicked:
     * - if clicked on input different than "Tous" - will be removed later cause "Tous" isn't possible
     * - if option "Tous" is selected, display nothing but the placeholder "Tous"
     * @param {string} filterName
     * @param {*} optionSelected
     */
    public onOptionClicked(filterName: string, optionSelected: any) {
        // if clicked on input different than "Tous" - will be removed later cause "Tous" isn't possible
        if (optionSelected) {
            this.autoCompleteValue[filterName] = optionSelected.text; // for the text displayed in the input
            this.lastSelectedValue[filterName] = optionSelected.text; // if next input is wrong - display the previous one
            this.filters[this.filterIndex[filterName]].selectedValue = optionSelected.id; // for the query
        } else {
            // if option "Tous" is selected, display nothing but the placeholder "Tous"
            this.autoCompleteValue[filterName] = this.placeHolders[filterName];
            this.lastSelectedValue[filterName] = this.placeHolders[filterName];
            this.filters[this.filterIndex[filterName]].selectedValue = null;
        }
        this.showOptionsList[filterName] = false;
        this.clickInsideFiter[filterName] = true;
    }

    /**
     * Add the selected item in the tag list (5 items max)
     * @param {*} itemSelected
     * @param {string} filterName
     */
    private addTag(itemSelected: any, filterName: string) {
        if (this.tags[filterName].length < 5) {
            this.tags[filterName].push(itemSelected);
            this.showOptionsList[filterName] = false;
            this.filters[this.filterIndex[filterName]].multiSelectedValues.push(itemSelected.id); // for the query
        }
    }

    /**
     * Remove the selected tag
     * @param {string} filterName
     * @param {number} indexTag
     * @param {number} indexFilter
     */
    public removeTag(filterName: string, indexTag: number, indexFilter: number) {
        // put the option back in the dropdown list
        this.tempSearch[filterName].push(this.tags[filterName][indexTag]);
        this.initialSearch[filterName].push(this.tags[filterName][indexTag]);
        this.tempSearch[filterName].sort(this.sortAsc);
        this.initialSearch[filterName].sort(this.sortAsc);

        // remove from the tags and selected values
        this.tags[filterName].splice(indexTag, 1);
        this.filters[indexFilter].multiSelectedValues.splice(indexTag, 1);
    }

    /**
     * Check selected start date and end date then emit error
     */
    public checkDateValues() {
        const dateStart = this.filters.find(element => element.propertyName === 'dateStart');
        const dateEnd = this.filters.find(element => element.propertyName === 'dateEnd');
        const isDateStartBeforeEnd = moment(dateStart.selectedValue).isBefore(dateEnd.selectedValue);

        if (isDateStartBeforeEnd) {
            this.sendError.emit('');
        } else {
            this.sendError.emit('Votre date de début ne peut pas être supérieure à votre date de fin');
        }
    }

    /**
     * Init filter
     * @return {Promise<void>}
     */
    public async initFilter(): Promise<void> {
        const onlyValuesFromCompany = this.companyId ? true : false;

        const filtersConfig = await this.filterService.getFilterConfig(this.filterName, onlyValuesFromCompany);
        this.filters = filtersConfig.map((filterConfig, index) => {
            if (filterConfig.defaultValue) {
                filterConfig.selectedValue = filterConfig.defaultValue;
            }

            // fill options of auto increment dropdowns
            if (
                filterConfig.propertyName === 'categories' ||
                filterConfig.propertyName === 'sites' ||
                filterConfig.propertyName === 'regions' ||
                filterConfig.propertyName === 'energies'
            ) {
                this.filterIndex[filterConfig.propertyName] = index;
                this.initialSearch[filterConfig.propertyName] = filterConfig.data.slice();
                this.initialSearch[filterConfig.propertyName].sort(this.sortAsc);
                this.tempSearch[filterConfig.propertyName] = filterConfig.data.slice();
                this.tempSearch[filterConfig.propertyName].sort(this.sortAsc);
                this.tags[filterConfig.propertyName] = [];
                filterConfig.multiSelectedValues = [];
            }

            // put the min max on date select
            if (filterConfig.propertyName === 'dateYear') {
                this.minYear = filterConfig.data[0].value.concat('-01');
                this.maxYear = filterConfig.data[filterConfig.data.length - 1].value.concat('-12');
            }

            return filterConfig;
        });
    }

    /**
     * Helper to sort asc
     * Return -1 if the text A is before the text B
     * Return 1 if the text A is after the text B
     * Otherwise return 0
     * @param {*} optionA
     * @param {*} optionB
     * @returns {number}
     */
    private sortAsc(optionA: any, optionB: any): number {
        if (optionA.text < optionB.text) {
            return -1;
        } else if (optionA.text > optionB.text) {
            return 1;
        } else {
            return 0;
        }
    }

    /**
     * On option autocompleted clicked
     * @param {boolean} isMultiselect
     * @param {*} itemSelected
     * @param {string} filterName
     */
    public onOptionAutoCompleteClicked(isMultiselect: boolean, itemSelected: any, filterName: string) {
        if (isMultiselect) {
            // add to the tags list
            this.addTag(itemSelected, filterName);

            // remove from the dropdown options list
            this.tempSearch[filterName] = this.tempSearch[filterName].filter(item => {
                return item !== itemSelected;
            });
            this.initialSearch[filterName] = this.initialSearch[filterName].filter(item => {
                return item !== itemSelected;
            });
        } else {
            this.onOptionClicked(filterName, itemSelected);
        }
    }

    /**
     * Get the placeholder identified by the type and the name of the select input
     * @param {boolean} isMultiselect
     * @param {string} filterName
     */
    public getPlaceholder(isMultiselect: boolean, filterName: string) {
        if (isMultiselect) {
            return this.placeHoldersMultiSelect[filterName];
        } else {
            return this.placeHolders[filterName];
        }
    }

    /**
     * Transform URL query params into a query for filters
     * @param {Params} params - params from URL
     * @returns {FilterQuery} query formatted object
     */
    private paramsToQuery(params: Params): FilterQuery {
        const query: FilterQuery = {};
        Object.keys(params).forEach(key => {
            const s = this.paramsQueryMap.find(x => x.param === key);
            if (s) {
                switch (s.type) {
                    default:
                    case ParamsType.String: {
                        query[s.query] = params[key];
                        break;
                    }
                    case ParamsType.Array: {
                        // query[s.query] = params[key].split(',');
                        query[s.query] = params[key];
                        break;
                    }
                }
            }
        });
        return query;
    }

    /**
     * Transform query from filters into URL query params
     * @param {Object} query - query from filters
     * @returns {Parms} query params for URL
     */
    private queryToParams(query: FilterQuery): Params {
        const params: Params = {};
        Object.keys(query).forEach(key => {
            const s = this.paramsQueryMap.find(x => x.query === key);
            if (s) {
                switch (s.type) {
                    default:
                    case ParamsType.String: {
                        params[s.param] = query[key];
                        break;
                    }
                    case ParamsType.Array: {
                        if (query[key] && Array.isArray(query[key])) {
                            params[s.param] = (query[key] as any[]).join(',');
                        }
                        break;
                    }
                }
            }
        });
        return params;
    }
}
