import { Component, Input, OnInit } from '@angular/core';
import { VerificationItem } from 'app/shared/models/bill-verifications.interface';

@Component({
    selector: 'ga-bill-verification-popover',
    templateUrl: './bill-verification-popover.component.html',
    styleUrls: ['./bill-verification-popover.component.scss', '../../bill-verification.scss'],
})
export class BillVerificationPopoverComponent implements OnInit {
    @Input() verifications: VerificationItem[] = [];
    @Input() isMultiHeader = false;

    constructor() {}

    ngOnInit() {}

    /**
     * Get color class for the verification item pill
     * @param {VerificationItem} v - verification item to get the pill color from
     */
    getPillColorClass(v: VerificationItem) {
        return 'verification-' + v.level;
    }

    /**
     * Return the message to display when no verification is available.
     * @return {string}
     */
    displayErrorText(): string {
        if (this.isMultiHeader) {
            return 'Il s\'agit d\'une facture multisite. Veuillez cliquer sur "MULTI" pour accéder aux vérifications des factures individuelles.';
        } else {
            return 'Pas de vérification disponible pour cette facture.';
        }
    }
}
