import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import Swal, { SweetAlertIcon } from 'sweetalert2';

// services
import { ChartService } from 'app/shared/services/chart/chart.service';
import { FilterService } from 'app/shared/services/filter/filter.service';
import { MapService } from 'app/shared/services/map/map.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import { VehiclesService } from 'app/shared/services/vehicles/vehicles.service';
import { ParkMonitoringService } from './park-monitoring.service';

// interfaces
import { QueryFilterVeh } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { BarChartProperties } from 'app/shared/models/charts/charts.interface';
import { CO2eEmission } from 'app/shared/models/co2emissions.interface';
import { MapOptions, Marker } from 'app/shared/models/marker.interface';

interface Loading {
    evolution: boolean;
    emissionAvg: boolean;
    emissionTotal: boolean;
}

@Component({
    selector: 'ga-park-monitoring',
    templateUrl: './park-monitoring.component.html',
    styleUrls: ['./park-monitoring.component.scss'],
    providers: [ParkMonitoringService],
})
export class ParkMonitoringComponent implements OnInit {
    /**
     * Charts
     */
    evolutionChartProperties: BarChartProperties = {
        datasets: [],
        colors: [],
        labels: [],
        options: {},
        width: 9000,
        maxHeight: 350,
        legends: [],
    };

    emissionAvgChartProperties: BarChartProperties = {
        datasets: [],
        colors: [],
        labels: [],
        options: {},
        width: 9000,
        maxHeight: 350,
        legends: [],
    };

    emissionTotalChartProperties: BarChartProperties = {
        datasets: [],
        colors: [],
        labels: [],
        options: {},
        width: 9000,
        maxHeight: 350,
        legends: [],
    };

    loading: Loading = {
        evolution: true,
        emissionAvg: true,
        emissionTotal: true,
    };

    /**
     * Map
     */
    markers: Marker[] = [];
    mapOptions: MapOptions = {
        zoom: 8,
        lat: 49.894067,
        lng: 2.2957529999999906,
        height: 250,
    };

    /** Filter saved for export */
    exportFilters: QueryFilterVeh;

    constructor(
        private route: ActivatedRoute,
        private parkMonitoringService: ParkMonitoringService,
        private chartService: ChartService,
        private vehicleService: VehiclesService,
        private translate: TranslateService,
        private filterService: FilterService,
        private sitesService: SitesService,
        private mapService: MapService
    ) {}

    ngOnInit() {
        /**
         * {Params} route.queryParams
         * {string} route.queryParams.siteId - site id to preselect
         */

        if (this.route.queryParams) {
            // subscribe to change to launch initiliazation when url is modified
            this.route.queryParams.subscribe(() => {
                this.initComponentSetup();
            });
        }
    }

    /**
     * Initialize components.
     */
    initComponentSetup() {
        // make the charts loading
        this.setChartStateLoading();

        // @todo: empty charts
    }

    /**
     * Set all charts as loading.
     */
    setChartStateLoading() {
        this.loading.evolution = true;
        this.loading.emissionAvg = true;
        this.loading.emissionTotal = true;
    }

    /**
     * 	LOAD DATA
     */

    /**
     * Triggers search with filters.
     * @param {QueryFilterVeh} filters
     */
    public async search(filters: QueryFilterVeh) {
        this.exportFilters = filters;
        this.setChartStateLoading();
        this.initMarkers(filters); // dont need to wait, the following operations don't need the sites

        /**
         * Get markers of the sites matching the filters
         * Get emissions data
         **/
        const emissions = await this.vehicleService.getVehiclesCO2e(null, filters);

        /**
         * Format emissions data
         * Fill charts with data and display
         */
        this.parkMonitoringService.formatCo2eEmissions(emissions);
        this.handleEvolution(emissions);
        this.handleEmissions(emissions);
    }

    /**
     * Set a marker for each site matching the current filters
     * @param {QueryFilterVeh} filters
     */
    async initMarkers(filters: QueryFilterVeh) {
        try {
            const sites = await this.sitesService.getSites(filters);
            this.markers = this.mapService.getMarkersFromSites(sites.data, 'fuel');
        } catch (e) {
            this.handleError('map_markers');
            this.markers = [];
        }
    }

    /**
     * Used to disable filter search.
     * @returns {boolean} true if at least one graph is loading.
     */
    isSomeGraphLoading(): boolean {
        return Object.keys(this.loading).some(key => this.loading[key]);
    }

    /**************
     * EVOLUTION MANAGEMENT
     **************/

    /**
     * Handle evolution chart data and chart properties
     * @param {*} filters - filters data on vehicles
     * @param {Moment[]} dates - dates to be display in x axis in charts
     */
    private async handleEvolution(emissions: CO2eEmission[]) {
        try {
            this.loading.evolution = true;

            /**
             * Create datasets and set chart properties.
             */
            this.setEvolutionChartProperties(emissions);
        } catch (e) {
            this.handleError('evolution_chart');
        }
        this.loading.evolution = false;
    }

    /**
     * Set evolution properties
     * @param {EvolutionDate[]} data - evolution data for status by date.
     */
    private setEvolutionChartProperties(data: CO2eEmission[]) {
        /**
         * For each month, push value to dataset
         */
        const commonProperties = { type: 'bar', stack: 'stack-0', unit: '' };
        const inDataset = Object.assign({ data: [], label: 'vehicles' }, commonProperties);
        const incomingDataset = Object.assign({ data: [], label: 'vehicles_incoming' }, commonProperties);
        const outgingDataset = Object.assign({ data: [], label: 'vehicles_outgoing' }, commonProperties);
        const labels: string[] = [];
        const keys = ['vehicle_quantity', 'vehicle_incoming', 'vehicle_outgoing'];

        data.forEach(d => {
            inDataset.data.push(d.in.length - d.incoming.length);
            incomingDataset.data.push(d.incoming.length);
            outgingDataset.data.push(d.outgoing.length * -1);
            labels.push(d.date);
        });

        /**
         * Chart properties filling
         */
        this.evolutionChartProperties.datasets = [inDataset, incomingDataset, outgingDataset];
        this.evolutionChartProperties.labels = labels;
        this.evolutionChartProperties.colors = this.chartService.getBarChartColors(keys);
        this.evolutionChartProperties.options = this.chartService.getConfig('stacked', {
            tooltips: this.chartService.getTooltipHTMLConfig('stack', null, {
                displayPercentage: false,
                displayTotal: false,
            }),
        });
        this.evolutionChartProperties.legends = keys.map((label, i) => {
            const legend = this.chartService.getLegend(label);
            legend.name = this.translate._(this.evolutionChartProperties.datasets[i].label);
            return legend;
        });
    }

    /**
     * Handle emissions charts data and charts
     * @param {*} filters - filters to get emissions
     * @param {Moment[]} dates - dates to be display in x axis in charts
     */
    private async handleEmissions(emissions: CO2eEmission[]) {
        try {
            this.loading.emissionAvg = true;
            this.loading.emissionTotal = true;

            this.setEmissionAvgChartProperties(emissions);
            this.setEmissionTotalChartProperties(emissions);
        } catch (e) {
            this.handleError('emission_charts');
        }
        this.loading.emissionAvg = false;
        this.loading.emissionTotal = false;
    }

    /**
     * Set vehicle average emission chart properties
     * @param {EmissionData[]} data
     */
    private setEmissionAvgChartProperties(data: CO2eEmission[]) {
        /**
         * For each month, push value to dataset
         */
        const dataset = { data: [], label: this.translate._('vehicles_emissions_avg') };
        const labels: string[] = [];
        const keys = ['vehicles_emissions'];

        data.forEach(d => {
            dataset.data.push(d.co2eAvg);
            labels.push(d.date);
        });

        /**
         * Chart properties filling
         */
        this.emissionAvgChartProperties.datasets = [dataset];
        this.emissionAvgChartProperties.labels = labels;
        this.emissionAvgChartProperties.colors = this.chartService.getBarChartColors(keys);
        this.emissionAvgChartProperties.options = this.chartService.getConfig('bar', {
            tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line', 'TCO2e/véhicule'),
        });
        this.emissionAvgChartProperties.legends = keys.map((label, i) => {
            const legend = this.chartService.getLegend(label);
            legend.name = this.emissionAvgChartProperties.datasets[i].label;
            return legend;
        });
    }

    /**
     * Set total vehicle emissions chart properties
     * @param {EmissionData[]} data
     */
    private setEmissionTotalChartProperties(data: CO2eEmission[]) {
        /**
         * For each month, push value to dataset
         */
        const dataset = { data: [], label: this.translate._('vehicles_emissions_total') };
        const labels: string[] = [];
        const keys = ['vehicles_emissions'];

        data.forEach(d => {
            dataset.data.push(d.co2e);
            labels.push(d.date);
        });

        /**
         * Chart properties filling
         */
        this.emissionTotalChartProperties.datasets = [dataset];
        this.emissionTotalChartProperties.labels = labels;
        this.emissionTotalChartProperties.colors = this.chartService.getBarChartColors(keys);
        this.emissionTotalChartProperties.options = this.chartService.getConfig('bar', {
            tooltips: this.chartService.getTooltipHTMLConfig('ng-chart-bar-line', 'TCO2e'),
        });
        this.emissionTotalChartProperties.legends = keys.map((label, i) => {
            const legend = this.chartService.getLegend(label);
            legend.name = this.emissionTotalChartProperties.datasets[i].label;
            return legend;
        });
    }

    get hasEvolutionData(): boolean {
        return this.evolutionChartProperties.datasets.some(x => (x.data as number[]).some(y => y !== 0));
    }

    get hasEmissionData(): boolean {
        return this.emissionTotalChartProperties.datasets.some(x => (x.data as number[]).some(y => y !== 0));
    }

    /**
     * Display error message for the user.
     * @param {string} errorCode - error code
     */
    private handleError(errorCode: string = null) {
        const errors: { [code: string]: [string, string, SweetAlertIcon] } = {
            default: ['Une erreur inconnue est survenue', 'Merci de réessayer ultérieurement.', 'error'],
            map_markers: ["Impossible d'afficher les sites", 'Merci de réessayer ultérieurement.', 'error'],
            evolution_chart: ["Impossible d'afficher l'évolution.", 'Merci de réessayer ultérieurement.', 'error'],
            emission_charts: ["Impossible d'afficher les émissions.", 'Merci de réessayer ultérieurement.', 'error'],
        };
        let e = errors.default;
        if (errorCode && errors[errorCode]) {
            e = errors[errorCode];
        }
        Swal.fire(e[0], e[1], e[2]);
    }

    /**
     * Export management
     */
    /**
     * @param {string} title - begining of the full filename
     * @returns {string} name of the export file correctly formatted
     */
    getExportName(title: string): string {
        const date = moment()
            .format('YYYYMMDD')
            .toString();
        return `${title}-${date}.xlsx`;
    }

    getExportExcelUrl(type: string) {
        if (type && this.exportFilters) {
            this.exportFilters.dju = false;
            let url = `/api/export/excel/vehicles-park/${type}`;
            url += this.filterService.formatQueryToUrl(this.exportFilters);

            return url;
        }
        return null;
    }
}
