import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/services/api/api.service';
import { PageService } from '../../shared/services/page/page.service';
import { SessionService } from '../../shared/services/session/session.service';

@Injectable()
export class DocumentsService extends PageService {
    constructor(public apiService: ApiService, public router: Router, private sessionService: SessionService) {
        super(apiService);
    }

    getFiles(folder: string = null) {
        return this.get('/api/files/list/' + (folder ? folder : ''));
    }

    createDirectory(name: string, parent: string) {
        return this.post('/api/files/directory', { name, parent });
    }

    uploadFile(file, parent: string) {
        const formData: FormData = new FormData();
        formData.append('companyId', this.sessionService.getCompanyId());
        formData.append('parent', parent);
        formData.append('file', file, file.name);
        const options = {
            reportProgress: true,
            headers: new HttpHeaders({
                Accept: 'application/json',
                Authorization: 'Basic ' + this.apiService.getToken(),
            }),
        };
        const url = `/api/files/upload?companyId=${this.sessionService.getCompanyId()}&parent=${parent}`;
        return this.apiService.upload(url, formData, options);
    }

    getAllowedTypes() {
        return this.get('/api/files/allowedtypes');
    }

    getTagList() {
        return this.get('/api/tags/crud/');
    }

    getSitesList() {
        return this.get('/api/companies/' + this.apiService.sessionService.getCompanyId() + '/sites');
    }

    getContractsList() {
        return this.get('/api/contracts/');
    }

    saveFile(file) {
        return this.post('/api/files/save', { file });
    }

    deleteFile(fileId) {
        return this.delete('/api/files/' + fileId);
    }

    searchFile(searchQuery) {
        return this.get('/api/files/search?s=' + searchQuery);
    }
}
