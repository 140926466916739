import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionValueGroup } from '../scope-filter/scope-filter.interface';

@Component({
    selector: 'ga-switch-bloc-filter',
    templateUrl: './switch-bloc-filter.component.html',
    styleUrls: ['./switch-bloc-filter.component.scss'],
})
export class SwitchBlocFilterComponent implements OnInit {
    /** Selected filter element inside the main filter */
    @Input() method = 'all';

    /** Selected set of elements for the top filter */
    @Input() elements: OptionValueGroup[] = [];

    @Input() columnsWidth = 0;

    /** Emits a new value each time the selected filter element changes */
    @Output() updateFilter: EventEmitter<string> = new EventEmitter();

    /** Name if the filter element being hovered - to highlight it */
    mouseover = '';

    constructor() {}
    ngOnInit() {}

    onRadioFilterChange(value: string) {
        this.method = value;
        this.updateFilter.emit(value);
    }

    getColumn() {
        return this.columnsWidth > 0 ? 'col-md-' + this.columnsWidth : 'col';
    }

    getClass(elem: OptionValueGroup) {
        return elem.value !== this.method ? ' inactive' : ' active';
    }

    getIcon(elem: OptionValueGroup) {
        const color = elem.value === this.method || elem.value === this.mouseover ? ' active' : '';
        let classIcon = '';
        switch (elem.value) {
            case 'all':
                classIcon = 'icon--default';
                break;
            case 'site':
            case 'sites':
                classIcon = 'icon--site';
                break;
            case 'categories':
                classIcon = 'icon--category';
                break;
            case 'regions':
                classIcon = 'icon--region';
                break;
            case 'routingreference':
            case 'routingreferences':
                classIcon = 'icon--routingreference';
                break;
            case 'custom-filters':
            case 'customfilters':
                classIcon = 'icon--custom-filters';
                break;
            case 'vehicles':
                classIcon = 'icon--vehicles';
                break;
            case 'fuelCards':
                classIcon = 'icon--fuelcards';
                break;
            case 'vehicleCategories':
                classIcon = 'icon--vehiclecategories';
                break;
            default:
                classIcon = 'icon--default';
                break;
        }

        return classIcon + color;
    }

    onMouseOver(value: string) {
        this.mouseover = value;
    }

    onMouseOut(value: string) {
        if (this.mouseover === value) {
            this.mouseover = '';
        }
    }
}
