// tslint:disable:no-access-missing-member
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { QuestionService } from 'app/shared/services/sites/question.service';
import { UploadFilesComponent } from '../../../upload/upload-files/upload-files.component';
import { QuestionComponent } from '../question/question.component';

@Component({
    selector: 'ga-question-select-choices',
    templateUrl: './select-choices.component.html',
    styleUrls: ['./select-choices.component.scss'],
    providers: [FormBuilder],
})
/**
 * Component to create a question with pre-determined answers (radios or checkboxes). Can be one or multiple choices. Also contains file upload possibility.
 * @Input data : User data at the format [{key: string, value: string}]
 * @Input config : Question from the config file with properties attribute at the format {key: string, multiple: boolean, values: [{label: string, value: string}]}
 * @Ouput valueChange : event that gives the data each time it's changed by the user at the format {key: string, value: string}
 */
export class SelectChoicesComponent extends QuestionComponent implements OnInit {
    // Multiple answers allowed (radio/checkbox)
    multiple = false;

    questionForm: FormGroup;

    // Values entered by the user
    values: string[] = [];

    // Upload component
    @ViewChild('upload', { static: false }) private uploadComponent: UploadFilesComponent;

    // Is file uploaded allowed from config file for this question
    fileAccepted = false;

    // Checkboxes items for reactive form binding.
    checkboxes: Array<{ label: string; value: string; allowFile: boolean; selected: boolean }> = [];

    constructor(private fb: FormBuilder, private questionService: QuestionService) {
        super();
    }

    ngOnInit(): void {
        // Set the fileKey property if the fileKey defined.
        if (this.config.properties.fileKey) {
            super.setFileKey(this.config.properties.fileKey);
        }
        // Get values from db values
        const questionValues = this.data.find(x => x.key === this.config.properties.key);

        // Set values from db values
        if (questionValues && questionValues.value) {
            if (!Array.isArray(questionValues.value)) {
                this.values = questionValues.value.split(',');
            }
        }

        // Get initial values in case of not mulitple for radio button
        const initVal = this.multiple ? this.values : this.values[0] ? this.values[0] : null;

        // Set the checkboxes properties if multiple allowed
        if (this.config.properties.multiple) {
            this.multiple = true;
            this.config.properties.values.forEach(val => {
                this.checkboxes.push({
                    label: val.label,
                    value: val.value,
                    allowFile: val.allowFile ? val.allowFile : false,
                    selected: Boolean(this.values.find(x => x === val.value)),
                });
            });
        }

        this.questionForm = this.fb.group({
            radio: this.fb.control(initVal),
            checkbox: this.buildQuestionsCB(),
        });
        this.onChanges();
        this.updateValues();

        // If file accepted, get the file information already uploaded by the user.
        if (this.fileAccepted) {
            const initFile = this.data.find(x => x.key === this.config.properties.fileKey);

            if (initFile && initFile.value) {
                this.questionService
                    .getUploadFile(initFile.value)
                    .then(fileRes => {
                        this.fileLoaded = fileRes;
                    })
                    .catch(err => {
                        this.fileLoaded = {
                            _id: null,
                            originalname: 'Erreur lors de la récupération votre fichier',
                        };
                    });
            }
        }
    }

    onChanges(): void {
        this.questionForm.valueChanges.subscribe(val => {
            if (!this.multiple) {
                this.values[0] = val.radio;
            } else {
                this.values = [];
                this.checkboxes.forEach((cb, i) => {
                    if (val.checkbox[i]) {
                        this.values.push(cb.value);
                    }
                });
            }
            this.updateValues();
        });
    }

    isFileAccepted() {
        return this.fileAccepted;
    }

    /**
     * Event when a user click to upload a file
     */
    clickOnInput() {
        if (this.uploadComponent) {
            this.uploadComponent.clickOnInput();
        }
    }

    /**
     * Update value when user input changes
     */
    private updateValues() {
        let tmpFileAccepted = false;
        this.values.forEach(item => {
            if (this.config.properties.values.find(x => x.value === item && x.allowFile)) {
                tmpFileAccepted = true;
            }
        });
        this.fileAccepted = tmpFileAccepted;

        const empty = this.values.length === 0;
        const res = [
            {
                key: this.config.properties.key,
                value: !empty ? this.values.join(',') : null,
            },
        ];
        this.valueChange.emit(res);
    }

    /**
     * Initiate the checkboxes control
     * @returns {FormArray}
     */
    buildQuestionsCB() {
        const arr = this.checkboxes.map(q => {
            return this.fb.control(q.selected);
        });
        return this.fb.array(arr);
    }

    get checkbox(): FormArray {
        return this.questionForm.get('checkbox') as FormArray;
    }

    /**
     * Delete the file
     */
    deleteFile() {
        const key = this.config.properties.fileKey;
        const valueToDelete = {
            site: this.siteId,
            zone: this.zoneId,
            keys: [key],
            fileId: this.fileLoaded._id,
        };

        this.questionService.deleteFiles(valueToDelete).then(res => {
            this.fileLoaded = null;
        });
    }
}
