import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    IterableDiffer,
    IterableDiffers,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { OptionValue } from 'app/shared/models/filter-config.interface';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import _ = require('lodash');
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { TreeItem, TreeviewComponent, TreeviewConfig, TreeviewI18n, TreeviewItem } from 'ngx-treeview';
import { GATreeviewI18n } from './ga-treeview-i18n';

@Component({
    selector: 'ga-tree-view-select',
    templateUrl: './tree-view-select.component.html',
    styleUrls: ['./tree-view-select.component.scss'],
    providers: [{ provide: TreeviewI18n, useClass: GATreeviewI18n }],
})

/**
 * Component used to create select with tree view
 * For constructing the tree, we count the number of dashes at the beginning of the value string and determine the depth of the data
 */
export class TreeViewSelectComponent implements OnInit, OnChanges, DoCheck {
    /**
     * List of the selected values inside the tree
     */
    @Input() values: string[] = [];
    /**
     * Differ to detect changes in `values`
     */
    private valuesDiffer: IterableDiffer<string>;
    /**
     * List of data containing an array of options. We will use the dashes as basis for calculating depth of the treeview
     */
    @Input() data: OptionValue[] = [];

    /**
     * If false, prevent the user from unchecking every option & reset the first option by default. (Default: false)
     */
    @Input() public canUncheckAll = false;

    /**
     * If true, when selecting a parent it selects all its children. (Default: true)
     */
    @Input() public decoupleChildren = true;

    /**
     * If true, add a submit button with an onclick event emitting selected values.
     * Otherwise, emits selected values on selection changes. (Default: false)
     */
    @Input() public hasSubmitButton = false;

    /**
     * If true, the field cannot be selected and changed
     */
    @Input() public disabled = false;

    /**
     * Name of the field containing the value
     */
    @Input() public valueField = 'value';

    /**
     * Name of the field containing the display name
     */
    @Input() public displayNameField = 'displayName';

    /**
     * Emit the checked values in the tree view
     */
    @Output() selectionChanged: EventEmitter<string[]> = new EventEmitter();

    /**
     * Dropdown object in the treeview select.
     */
    @ViewChild('dropdownList', { static: false }) private readonly dropdownList: BsDropdownDirective;

    /**
     * Treeview component.
     */
    @ViewChild(TreeviewComponent, { static: false }) private treeviewComponent!: TreeviewComponent;

    /**
     * Limits of the string in the input
     */
    private strLimit = 15;

    /**
     * Basic Config for treeView select
     */
    public treeViewConfig: TreeviewConfig = {
        hasAllCheckBox: false,
        hasFilter: true,
        hasCollapseExpand: false,
        decoupleChildFromParent: true,
        maxHeight: 500,
        hasDivider: false,
    };

    /**
     * List containing the main tree object, and its children elements
     */
    public treeItems: TreeviewItem[] = [];

    /**
     * List of the checked options in the tree view items
     * So far only used to display the number of selected options
     */
    public checkedOptions: string[] = [];
    /**
     * Differ to detect changes in `checkedOptions` and prevent excessive emit of selection changed
     */
    private checkedOptionsDiffer: IterableDiffer<string>;

    /**
     * Regex matching string indicating if element is a child element. String only containing `-`
     */
    private readonly childRegex = /^\-+$/g;

    /** True if all options in the treeview are checked. */
    public allOptionsChecked: boolean;

    constructor(protected translateService: TranslateService, private iterable: IterableDiffers) {
        this.valuesDiffer = iterable.find([]).create(null);
        this.checkedOptionsDiffer = iterable.find([]).create(null);
    }

    /**
     * Close the dropdown list if the user click outsite
     */
    public closeDropdownList() {
        this.dropdownList.isOpen = false;
    }

    private initTreeview() {
        const result: TreeItem = this.formateRawDataIntoTreeView();
        if (result) {
            this.treeItems.push(new TreeviewItem(result));
        }
        this.setCheckedOptions(this.treeItems);
        this.allOptionsChecked = this.areAllOptionsChecked(this.treeItems);
        this.checkedOptions = this.getCheckedOptions(this.treeItems);
        this.valuesDiffer.diff(this.values);
        this.checkedOptionsDiffer.diff(this.checkedOptions);
    }

    ngOnInit(): void {
        this.treeViewConfig.decoupleChildFromParent = this.decoupleChildren;
        this.initTreeview();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Recreate treeview each time treeview data is modified
        if (changes.data && !changes.data.firstChange) {
            // Reset treeview items
            this.treeItems = [];
            // Update by initializing with new data
            this.initTreeview();
        }
    }

    ngDoCheck(): void {
        const changes = this.valuesDiffer.diff(this.values);

        // if the selected options have changed
        if (changes) {
            this.setCheckedOptions(this.treeItems);
            this.checkedOptions = this.getCheckedOptions(this.treeItems);
        }
    }

    /**
     * Format the initial data into a tree.
     * Use carets to know the item's depth. Ex: "- Ville de Anglet" & "-- Office de tourisme d'Anglet"
     * @returns {TreeItem} tree created
     */
    private formateRawDataIntoTreeView(): TreeItem {
        let treeObject: TreeItem;

        for (const item of this.data) {
            // Look for cartes indicating the depth. Ex: "-- Office de tourisme d'Anglet" -> length = 3
            const depthString = item[this.displayNameField].split(' ')[0];
            const isChild = depthString.match(this.childRegex);
            const depth = isChild ? depthString.length : 0;
            // Insert the value into the right parent.
            const newTreeItem = this.createTreeItem(item[this.displayNameField], item[this.valueField]);

            treeObject = this.insertChildrenTreeItem(treeObject, treeObject, newTreeItem, depth);
        }

        return treeObject;
    }

    /**
     * Insert children branch of legal entity recursirvely.
     * @param {TreeItem} treeObject
     * @param {TreeItem} child
     * @param {TreeItem} treeItem
     * @param {number} depth
     *
     * @returns {TreeItem} updated tree object
     */
    private insertChildrenTreeItem(treeObject: TreeItem, child: TreeItem, treeItem: TreeItem, depth: number): TreeItem {
        switch (depth) {
            case 0:
                treeObject = treeItem;
                break;
            case 1:
                child.children.push(treeItem);
                break;
            default:
                this.insertChildrenTreeItem(treeObject, child.children[child.children.length - 1], treeItem, depth - 1);
                break;
        }

        return treeObject;
    }

    /**
     * Create new tree view item and remove any dashes at the beginning of the name
     * @param {string} name
     * @param {string} value
     * @returns {TreeItem}
     */
    private createTreeItem(name: string, value: string): TreeItem {
        // Remove the dashes at the beginning of the name string
        const newName = name.split(' ');
        if (newName[0].match(this.childRegex)) {
            newName.shift();
        }

        return {
            text: newName.join(' '),
            value,
            children: [],
        };
    }

    /**
     * Sets all the options in the treeview to the given value.
     * If all options arent already checked, selects them all. Otherwise, unselects them all.
     */
    public onSelectAllOptions(): void {
        this.setAllOptions(this.treeItems, this.allOptionsChecked ? false : true);
        this.treeviewComponent.raiseSelectedChange();
    }

    /**
     * Compute and emit the list of checked options to the parent component.
     */
    public emitCheckedOptions() {
        this.checkedOptions = this.getCheckedOptions(this.treeItems);

        const diff = this.checkedOptionsDiffer.diff(this.checkedOptions);

        if (diff) {
            this.selectionChanged.emit(this.checkedOptions);
        }
    }

    /**
     * On change, emit checked options if there is no submit button
     */
    public onTreeSelectedChange(): void {
        this.allOptionsChecked = this.areAllOptionsChecked(this.treeItems);
        this.checkedOptions = this.getCheckedOptions(this.treeItems);

        // handle the case where all options are unchecked
        if (!this.checkedOptions.length && !this.canUncheckAll) {
            // set the first option by default when all options are unchecked and should not
            this.values = [this.treeItems[0].value];
            this.setCheckedOptions(this.treeItems);
        }

        if (!this.hasSubmitButton) {
            this.emitCheckedOptions();
        }
    }

    /**
     * Return list of checked values on tree view
     * @param {TreeviewItems} treeItems - list of tree items to go through
     * @returns {string[]}
     */
    private getCheckedOptions(treeItems: TreeviewItem[]): string[] {
        let checked = [];
        for (const item of treeItems) {
            // Add checked items which are not parents (parents are represented by empty string)
            if (item.checked && item[this.valueField] !== '') {
                checked.push(item[this.valueField]);
            }
            if ('children' in item && typeof item.children !== 'undefined' && item.children.length) {
                checked = checked.concat(this.getCheckedOptions(item.children));
            }
        }
        return checked;
    }

    /**
     * Set checked options depending of the send values of the parent component
     * @param {TreeviewItems} treeItems - list of tree items
     * @param {boolean} forcedChecked - true to check all items regardeless of the values
     */
    private setCheckedOptions(treeItems: TreeviewItem[], forcedChecked: boolean = false): void {
        for (const item of treeItems) {
            item.checked = forcedChecked ? true : this.values.includes(item[this.valueField]);
            if ('children' in item && typeof item.children !== 'undefined' && item.children.length) {
                this.setCheckedOptions(item.children);
            }
        }
    }

    /**
     * Sets all the options in the list of treeview items to the given checked value.
     */
    public setAllOptions(items: TreeviewItem[], checked: boolean): void {
        for (const item of items) {
            item.checked = checked;
            if (Array.isArray(item.children) && item.children.length > 0) {
                this.setAllOptions(item.children, checked);
            }
        }
    }

    /**
     * Returns true if all the treeview items in the list (and their children) are checked.
     */
    private areAllOptionsChecked(items: TreeviewItem[]): boolean {
        for (const item of items) {
            if (!item.checked) {
                return false;
            }
            if (Array.isArray(item.children) && item.children.length > 0) {
                if (!this.areAllOptionsChecked(item.children)) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Return the text to display in the input, cut with "..." if too long
     * @returns {string} text to display
     */
    public getText(): string {
        if (this.checkedOptions.length === 0) {
            return this.translateService._('none_f');
        }

        const firstCheckedName = this.data
            .find(d => this.checkedOptions[0] === d[this.valueField])
            [this.displayNameField].replace(/^[^a-zA-Z]+/, '')
            .substring(0, this.strLimit);

        return `${firstCheckedName}${firstCheckedName.length >= this.strLimit ? '...' : ''}`;
    }
}
