import { Component, OnInit, ViewChild } from '@angular/core';
import { Column, Option } from 'app/shared/components/analyse/axis-selection/axis-selection.component';
import {
    PropertyDescription,
    StatisticsComponent,
} from 'app/shared/components/analyse/statistics/statistics.component';
import { HighchartScatterPlotComponent } from 'app/shared/components/charts/highchart-scatter-plot/highchart-scatter-plot.component';
import { QueryFilterRoutingRef } from 'app/shared/components/filters/scope-filter/scope-filter.interface';
import { CompanyRoles } from 'app/shared/constants/company-roles-list.constants';
import { UserTypes } from 'app/shared/constants/user-types-list.constants';
import { ScatterPlotPoint, ScatterPlotSerie } from 'app/shared/models/charts/chart-serie.interface';
import { ScatterPlotProperties } from 'app/shared/models/charts/charts.interface';
import { SiteRoutingRefContractConso } from 'app/shared/models/site.interface';
import { ChartService } from 'app/shared/services/chart/chart.service';
import { EnergyDataService } from 'app/shared/services/energy/energy-data.service';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { SessionService } from 'app/shared/services/session/session.service';
import { SitesService } from 'app/shared/services/sites/sites.service';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { AdminCompanyService } from '../../admin/company/company.service';
import { CrossDataService } from './cross-data.service';

interface AxisOption extends Option {
    unit: string;
    unitType: string;
}

interface QueryFilter extends QueryFilterRoutingRef {
    legacyData: string;
}

@Component({
    selector: 'ga-cross-data',
    templateUrl: './cross-data.component.html',
    styleUrls: ['./cross-data.component.scss'],
    providers: [CrossDataService],
})
export class CrossDataComponent implements OnInit {
    // sites data (triple key object): site / contract / n°PDL
    dataSitesRfcContract: SiteRoutingRefContractConso[] = [];
    sitesProperties: any = {};
    company = null;
    specificCriteria = ['buildingsurface', 'nbpeopleonsite'];
    displayZones = false;

    // chart
    scatterPlotProperties: ScatterPlotProperties;
    chartSeries: ScatterPlotSerie[] = []; // data set inside options.series
    chartVisiblePoints: ScatterPlotPoint[] = []; // points filtered from the zoom action on chart
    @ViewChild('chart', { static: false }) chart: HighchartScatterPlotComponent;

    isLoadingData = false;
    isLoadingAxes = false;

    // statistics
    isShowingStatistics = true;
    @ViewChild(StatisticsComponent, { static: false }) statisticsComponent: StatisticsComponent;

    // axes selection
    axesOptions: Array<Column<AxisOption>>;

    // sites filter
    filters: QueryFilter;
    isFilterCollapsed = false;

    // colors filter
    colorfilterKey: string = null; // default is none
    filterColorsOptions: Array<{ label: string; value: string }>;

    constructor(
        private crossDataService: CrossDataService,
        private chartService: ChartService,
        private energieService: EnergyService,
        private sitesService: SitesService,
        private sessionService: SessionService,
        private companyService: AdminCompanyService,
        private energyDataService: EnergyDataService
    ) {}

    ngOnInit() {
        this.isLoadingData = true;
        this.isLoadingAxes = true;

        this.initChartProperties();
        this.company = this.sessionService.getCompany();
        this.setAxesOptions();
        this.setFormOptions();

        if (this.company) {
            this.crossDataService
                .getCompanyUsers(this.company._id, UserTypes.ENERGY_MANAGER, CompanyRoles.USERS)
                .then(res => {
                    this.company.users = res;
                    this.companyService.setPictures(this.company.users, 'img');
                })
                .catch(error => {
                    this.company.users = [];
                });
        } else {
            Swal.fire(
                'Toutes nos excuses',
                'Une erreur est survenue pendant le chargement de votre entreprise',
                'error'
            );
        }
    }

    initChartProperties() {
        this.scatterPlotProperties = {
            title: 'Croisement des données',
            legend: [],
            options: {},
        };
    }

    /**
     * Set the available options for the axis.
     */
    setAxesOptions() {
        this.isLoadingAxes = true;

        // get columns already selected if axis not undefined
        const selectedX =
            this.axesOptions &&
            this.axesOptions.find(column => column.key === 'x').options.find(opt => opt.selected).key;
        const selectedY =
            this.axesOptions &&
            this.axesOptions.find(column => column.key === 'y').options.find(opt => opt.selected).key;

        const x: Column<AxisOption> = { key: 'x', name: 'Abscisses', options: [] };
        const y: Column<AxisOption> = { key: 'y', name: 'Ordonnées', options: [] };
        this.axesOptions = [y, x];

        this.setDefaultAxesOptions();
        this.setSpecificAxesOptions();
        this.preselectAxes({ selectedX, selectedY });

        this.isLoadingAxes = false;
    }

    /**
     * Set the default axis options common for both x and y:
     *  - cost TTC
     *  - cost HT
     *  - quantity
     *  - cost TTC/kwh
     *  - cost HT/kWh
     */
    setDefaultAxesOptions(): void {
        // kWhep if multiple energies selected
        const energies = this.filters && this.filters.energies ? this.filters.energies.split(',') : [];
        const consoUnit = energies.length > 1 ? 'kWhep' : 'kWh';

        this.axesOptions.forEach(axe => {
            axe.options.push(
                {
                    key: 'totalTTC',
                    unit: '€',
                    legend: '€ TTC',
                    selected: false,
                    unitType: 'money',
                },
                {
                    key: 'totalHT',
                    unit: '€',
                    legend: '€ HT',
                    selected: false,
                    unitType: 'money',
                },
                {
                    key: 'quantity',
                    unit: consoUnit,
                    legend: consoUnit,
                    selected: false,
                    unitType: 'fluid',
                },
                {
                    key: 'ratio_totalTTC_quantity',
                    unit: 'c€/' + consoUnit,
                    legend: 'c€ TTC/' + consoUnit,
                    selected: false,
                    unitType: 'ratio',
                },
                {
                    key: 'ratio_totalHT_quantity',
                    unit: 'c€/' + consoUnit,
                    legend: 'c€ HT/' + consoUnit,
                    selected: false,
                    unitType: 'ratio',
                }
            );
        });
    }

    /**
     * Preselect options in axes.
     * Quantity for x, ratio € / qty for y
     */
    preselectAxes({ selectedX = 'quantity', selectedY = 'ratio_totalTTC_quantity' }): void {
        const axeX = this.axesOptions.find(column => column.key === 'x');
        const axeY = this.axesOptions.find(column => column.key === 'y');

        axeX.options.find(opt => opt.key === selectedX).selected = true;
        axeY.options.find(opt => opt.key === selectedY).selected = true;
    }

    /**
     * Set specific options for the legacy data (surface, number of people on site).
     * Each legacy data has 3 options:
     *  - legacy data
     *  - ratio conso / legacy data
     *  - ratio cost / legacy data
     */
    setSpecificAxesOptions(): void {
        const energies = this.filters && this.filters.energies ? this.filters.energies.split(',') : [];
        const consoUnit = energies.length > 1 ? 'kWhep' : 'kWh';

        // this array will be set by the user preferences in the Legacy Data part
        const specificOptions = [
            {
                key: 'buildingsurface',
                unit: 'm²',
                ratiounit: 'm²',
                legend: 'm²',
            },
            {
                key: 'nbpeopleonsite',
                unit: 'personnes',
                ratiounit: 'personne',
                legend: 'Effectif',
            },
        ];

        // for each legacy data, add it as a button choice, and add 3 ratio : kWh c€ HT c€ TTC
        this.axesOptions.forEach(axis => {
            specificOptions.forEach(option => {
                const value_option: AxisOption = {
                    key: option.key,
                    unit: option.unit,
                    legend: option.legend,
                    selected: false,
                    unitType: 'legacydata',
                };

                const ratio_qty_option: AxisOption = {
                    key: 'ratio_quantity_' + option.key,
                    unit: consoUnit + '/' + option.ratiounit,
                    legend: consoUnit + '/' + option.ratiounit,
                    selected: false,
                    unitType: 'ratio',
                };

                const ratio_TTC_option: AxisOption = {
                    key: 'ratio_totalTTC_' + option.key,
                    unit: '€/' + option.ratiounit,
                    legend: '€ TTC/' + option.ratiounit,
                    selected: false,
                    unitType: 'ratio',
                };

                const ratio_HT_option: AxisOption = {
                    key: 'ratio_totalHT_' + option.key,
                    unit: '€/' + option.ratiounit,
                    legend: '€ HT/' + option.ratiounit,
                    selected: false,
                    unitType: 'ratio',
                };

                axis.options.push(value_option, ratio_qty_option, ratio_TTC_option, ratio_HT_option);
            });
        });
    }

    /**
     * Get the properties description needed by the Statistics component to compute values from points.
     * @returns {PropertyDescription[]}
     */
    getStatisticsProperties(): PropertyDescription[] {
        const selection = this.getAxesSelection();
        return [
            { key: 'y', unit: selection.y.unit, unitType: selection.y.unitType },
            { key: 'x', unit: selection.x.unit, unitType: selection.x.unitType },
        ];
    }

    /**
     * Return the selected option from axis x and y
     * @returns {x: AxisOption, y: AxisOption} selected option from both axes
     */
    getAxesSelection(): { x: AxisOption; y: AxisOption } {
        return {
            x: this.axesOptions.find(axe => axe.key === 'x').options.find(opt => opt.selected),
            y: this.axesOptions.find(axe => axe.key === 'y').options.find(opt => opt.selected),
        };
    }

    /**
     * Set the available form options.
     * Default is none selected.
     * Form options depend on the company's organization (entreprise or other).
     */
    setFormOptions(): void {
        // Default is none, appears at first position
        this.filterColorsOptions = [
            {
                label: 'Aucun',
                value: null,
            },
        ];

        // Entreprise
        if (this.company.organization === '1') {
            this.filterColorsOptions.push(
                {
                    label: 'Régions',
                    value: 'region',
                },
                {
                    label: 'Natures',
                    value: 'nature',
                }
            );
        } else {
            // Santé / Commune / Collectivité / Syndic de copropriété / Association
            this.filterColorsOptions.push(
                {
                    label: 'Régions',
                    value: 'region',
                },
                {
                    label: 'Natures',
                    value: 'nature',
                },
                {
                    label: 'Catégories',
                    value: 'category',
                },
                {
                    label: 'Sous-catégories',
                    value: 'subcategory',
                },
                {
                    label: 'Complément',
                    value: 'complement',
                }
            );
        }
    }

    /**
     * @returns {boolean} true if the chart has some data to display
     */
    public hasDataForChart(): boolean {
        return this.chartSeries && this.chartSeries.some(dataSet => Boolean(dataSet.data && dataSet.data.length));
    }

    /**
     * Start the research with new criterias
     * @param {QueryFilter} filters from the filter component
     */
    public async search(filters): Promise<void> {
        this.isLoadingData = true;
        this.filters = filters;
        this.displayZones = this.filters.sites || this.filters.categories ? true : false;

        this.filters.legacyData = this.specificCriteria.toString();

        // need to reset the unit in axe options in case filter.energy has changed (kWh -> kWhep)
        // keep the old selection if there was one
        this.setAxesOptions();

        try {
            this.sitesProperties = await this.crossDataService.getSitesProperties();
            this.dataSitesRfcContract = await this.energyDataService.getCommonConsumptionPerSite(this.filters);

            this.setPropertiesNames();

            this.scatterPlotProperties.options = this.chartService.getHighchartScatterPlotConfig();

            this.handleScatterPlotChart();
            this.computeStatistics(this.chartSeries);
        } catch (err) {
            this.isLoadingData = false;
            Swal.fire(
                'Toutes nos excuses',
                "Une erreur s'est produite lors du chargement des données de vos sites",
                'error'
            );
        }
    }

    /**
     * Flatten the points inside the given series.
     * Changing the points will automatically launch the statistics computation inside the statistics components.
     * @param {ScatterPlotSerie[]} series holding the points from which statitics are computed.
     */
    computeStatistics(series: ScatterPlotSerie[]) {
        this.chartVisiblePoints = _.flatten(series.map(serie => serie.data));
    }

    /**
     * Set categories, subcategories and complements names inside sites and zones.
     */
    setPropertiesNames(): void {
        this.dataSitesRfcContract.forEach(site => {
            this.sitesService.setSiteProperties(site, this.sitesProperties);
        });
    }

    /**
     * Handle scatter plot chart
     * 1. gather the data of every contract & rfc for each site
     * 2. gather the sites per color filter selected (default = 'region')
     * 3. formate data for the scatter chart
     * 4. compute statitistics
     * 5. use data and statitics to compute tooltips
     */
    handleScatterPlotChart() {
        const dataPerSite = this.aggregatePerSite();

        this.isShowingStatistics = this.hasMultiplePoints(dataPerSite);

        const sitesFilteredForColor = this.aggregatePerFilterColor();

        this.formateDataForSerie(dataPerSite, sitesFilteredForColor);

        this.createChartTooltips();
        this.createChartClickEvent();

        this.isLoadingData = false;
    }

    /**
     * Group the sites ids per form filter (color).
     * Ex: {
            Ile-de-France: [
                "5a7f351d71468150e94de227",
                "59bba6cf5099a902a3ba82b2",
                "59bba71c5099a902a3baffd0",
              ],
             Bretagne : [
                "5a7f351d71468456494de224",
                "59bba6cf5099a902a3ba82b8",
                "59bba71c5099a902a3baffd4",
              ],
              ...
        }
     * @returns { [key: string]: string[] }
     */
    aggregatePerFilterColor() {
        const sitesFilteredForColor = {};

        if (this.colorfilterKey) {
            this.dataSitesRfcContract.forEach(site => {
                const filter = site[this.colorfilterKey] ? site[this.colorfilterKey] : 'NC';

                if (!sitesFilteredForColor.hasOwnProperty(filter)) {
                    sitesFilteredForColor[filter] = [];
                }

                if (!sitesFilteredForColor[filter].includes(site._id.siteId)) {
                    sitesFilteredForColor[filter].push(site._id.siteId);
                }
            });
        }

        return sitesFilteredForColor;
    }

    /*
        {
            5a7f351d71468150e94de227: {
                name: 'Gymnase Saint-Charles'
                quantity: 436943,
                totalHT: 242,
                totalTTC: 1144
              },
              ...
        }
    */
    /**
     * Aggregate data per site from the triple key consumption array: site / contract / routing ref
     * @return {name: string, quantity: number,totalHT: number, totalTTC: number}
     */
    aggregatePerSite() {
        const dataPerSite = {};

        this.dataSitesRfcContract.forEach(site => {
            if (!site.info.diviser) {
                if (site.conso && Object.keys(site.conso).length !== 0) {
                    for (const key in site.conso) {
                        if (site.conso.hasOwnProperty(key)) {
                            this.addDataToSite(dataPerSite, key, site.conso[key], site);
                        }
                    }
                }
            } else if (site.info.diviser && site.conso && Object.keys(site.conso).length !== 0) {
                // site is divided in zones but that PDL doesn't belong to any zone
                for (const key in site.conso) {
                    if (site.conso.hasOwnProperty(key)) {
                        this.addDataToSite(dataPerSite, key, site.conso[key], site);
                    }
                }
            } else if (site.info.diviser && site.batiments.length) {
                site.batiments.forEach(zone => {
                    if (zone.routingReferencesConso) {
                        zone.routingReferencesConso.forEach(rfc => {
                            if (rfc.conso && Object.keys(rfc.conso).length !== 0) {
                                for (const key in rfc.conso) {
                                    if (rfc.conso.hasOwnProperty(key)) {
                                        this.addDataToSite(dataPerSite, key, rfc.conso[key], site, zone);
                                    }
                                }
                            }
                        });
                    }
                });
            }
        });
        return dataPerSite;
    }

    addDataToSite(dataPerSite, key, conso, site, zone = null) {
        const siteId = site._id.siteId;
        const siteName = site.name;
        const siteFilter = site[this.colorfilterKey];

        if (dataPerSite.hasOwnProperty(siteId)) {
            this.setSiteOrZoneConso(key, conso, dataPerSite[siteId]);
            if (zone) {
                this.setSiteOrZoneConso(key, conso, dataPerSite[siteId].batiments[zone._id]);
            }
        } else {
            dataPerSite[siteId] = this.initializeSiteOrZoneData(siteName, siteFilter);
            // for each axe option
            this.specificCriteria.forEach(legacyData => {
                this.initializeLegacyData(dataPerSite[siteId], legacyData, site);
            });

            if (site.info.diviser && site.batiments.length) {
                dataPerSite[siteId].batiments = {};
                site.batiments.forEach(bat => {
                    dataPerSite[siteId].batiments[bat._id] = this.initializeSiteOrZoneData(
                        bat.name,
                        bat[this.colorfilterKey]
                    );
                    // for each axe option
                    this.specificCriteria.forEach(legacyData => {
                        this.initializeLegacyData(dataPerSite[siteId].batiments[bat._id], legacyData, bat); // add the zone's legacy data to the zone info
                        dataPerSite[siteId][legacyData] += dataPerSite[siteId].batiments[bat._id][legacyData]; // add the zone's legacy data to the site's legacy data
                    });
                });
            }

            this.addDataToSite(dataPerSite, key, conso, site, zone);
        }
    }

    setSiteOrZoneConso(key, conso, dataPerSiteOrZone) {
        const energiesAccepted = this.energieService.getAllEnergies();
        // quantity & ratios
        if (energiesAccepted.includes(key)) {
            let quantity = 0;
            if (this.filters.energies && this.filters.energies.split(',').length > 1) {
                quantity = this.energieService.convertEnergyKwhToKwhep(key, conso.quantity);
            } else {
                quantity = conso.quantity;
            }

            // add quantity
            dataPerSiteOrZone.quantity += quantity;

            // add total HT
            dataPerSiteOrZone.totalHT += conso.totalHT;

            // add total TTC
            dataPerSiteOrZone.totalTTC += conso.totalTTC;

            // update c€/kwh
            // id quantity is 0, ratio remains 0 - will start being updated once the first quantity is found
            if (dataPerSiteOrZone.quantity) {
                dataPerSiteOrZone.ratio_totalTTC_quantity =
                    (dataPerSiteOrZone.totalTTC / dataPerSiteOrZone.quantity) * 100;
                dataPerSiteOrZone.ratio_totalHT_quantity =
                    (dataPerSiteOrZone.totalHT / dataPerSiteOrZone.quantity) * 100;
            }

            // update ratio for each specific legacy data (axe option) with new totalTTC totalHT quantity
            this.specificCriteria.forEach(legacyData => {
                if (dataPerSiteOrZone[legacyData]) {
                    // ratio /kwh
                    const ratioNameQty = 'ratio_quantity_' + legacyData;
                    dataPerSiteOrZone[ratioNameQty] = dataPerSiteOrZone.quantity / dataPerSiteOrZone[legacyData];

                    // ratio /c€ TTC
                    const ratioNameTTC = 'ratio_totalTTC_' + legacyData;
                    dataPerSiteOrZone[ratioNameTTC] = dataPerSiteOrZone.totalTTC / dataPerSiteOrZone[legacyData];

                    // ratio /c€ HT
                    const ratioNameHT = 'ratio_totalHT_' + legacyData;
                    dataPerSiteOrZone[ratioNameHT] = dataPerSiteOrZone.totalHT / dataPerSiteOrZone[legacyData];
                }
            });
        }
    }

    initializeSiteOrZoneData(name, filterName) {
        return {
            name,
            quantity: 0,
            totalHT: 0,
            totalTTC: 0,
            ratio_totalTTC_quantity: 0,
            ratio_totalHT_quantity: 0,
            filter: filterName || 'NC',
        };
    }

    initializeLegacyData(dataPerSiteOrZone, legacyData, siteOrZone) {
        const ratioNames = [];
        ratioNames.push('ratio_quantity_' + legacyData, 'ratio_totalTTC_' + legacyData, 'ratio_totalHT_' + legacyData);

        // initialize the site's legacy data : this data won't change, every rfc has the site's value
        dataPerSiteOrZone[legacyData] = siteOrZone[legacyData] ? Number(siteOrZone[legacyData][0].value) : 0;

        // ratio initialized with 0, will be updated when the site's total conso and cost are updated (kWh/m2)
        ratioNames.forEach(ratioName => {
            dataPerSiteOrZone[ratioName] = 0;
        });
    }

    // returns true when there are more than one site, or if there is only one site with multiple zones
    hasMultiplePoints(dataPerSite) {
        // only one point when there is only one site without any zone
        return Boolean(
            Object.keys(dataPerSite).length &&
                (Object.keys(dataPerSite).length > 1 || dataPerSite[Object.keys(dataPerSite)[0]].batiments)
        );
    }

    formateDataForSerie(dataPerSite, sitesFilteredForColor) {
        const regionColors = [
            '253, 204, 45', // SECOND_YELLOW
            '252, 168, 40, 0.8', // yellow
            '82, 184, 153', // SECOND_TURQUOISE
            '35, 157, 132, 0.5', // green
            '223, 124, 58', // ORANGE
            '219, 75, 71', // red admin
            '172, 204, 236', // LIGHT_BLUE
            '105, 158, 210', // SECOND_BLUE
            '16, 78, 139', // DARK_BLUE1
            '117, 97, 194, 0.5', // PURPLE
            '91, 75, 162', // SECOND_PURPLE
            '123, 138, 138', // GREY
        ];

        const selection = this.getAxesSelection();

        const unitX = selection.x.unit;
        const unitY = selection.y.unit;

        const unitTypeX = selection.x.unitType;
        const unitTypeY = selection.y.unitType;

        const x = selection.x.key;
        const y = selection.y.key;

        const legendX = selection.x.legend;
        const legendY = selection.y.legend;

        this.scatterPlotProperties.options.yAxis.title.text = legendY;
        this.scatterPlotProperties.options.xAxis.title.text = legendX;

        const optionsSeries = [];
        this.scatterPlotProperties.legend = [];

        if (this.colorfilterKey) {
            let colorIndex = 0;
            Object.keys(sitesFilteredForColor).forEach(filter => {
                optionsSeries.push(
                    this.createSerie(
                        sitesFilteredForColor[filter],
                        dataPerSite,
                        x,
                        y,
                        unitX,
                        unitY,
                        filter,
                        regionColors[colorIndex],
                        unitTypeX,
                        unitTypeY
                    )
                );
                colorIndex = colorIndex === regionColors.length - 1 ? 0 : colorIndex + 1;
            });
        } else {
            optionsSeries.push(
                this.createSerie(
                    Object.keys(dataPerSite),
                    dataPerSite,
                    x,
                    y,
                    unitX,
                    unitY,
                    null,
                    regionColors[0],
                    unitTypeX,
                    unitTypeY
                )
            );
        }

        this.chartSeries = optionsSeries;
    }

    createSerie(sitesId, dataPerSite, x, y, unitX, unitY, filterName, filterColor, unitTypeX, unitTypeY) {
        const serieData = [];

        // add sites data
        sitesId.forEach(siteId => {
            // sites without any consumption weren't added to sitesFilteredForColor
            if (dataPerSite[siteId]) {
                // displayZones = true when each zone has its own point on the chart
                if (this.displayZones && dataPerSite[siteId].batiments) {
                    Object.keys(dataPerSite[siteId].batiments).forEach(zoneId => {
                        serieData.push({
                            name: dataPerSite[siteId].name,
                            zone: 'Zone : ' + dataPerSite[siteId].batiments[zoneId].name,
                            x: dataPerSite[siteId].batiments[zoneId][x],
                            y: dataPerSite[siteId].batiments[zoneId][y],
                            id: siteId, // site id for the redirection to the site profile when click on the graph point
                        });
                    });
                } else {
                    // the site has 0 zero zone or the zones are merged on the site's point
                    const serie = {
                        name: dataPerSite[siteId].name,
                        x: dataPerSite[siteId][x],
                        y: dataPerSite[siteId][y],
                        id: siteId,
                    };

                    // if the site has zones, their name and consumption will be displayed inside the tooltip
                    if (dataPerSite[siteId].batiments) {
                        serie['batiments'] = [];
                        Object.keys(dataPerSite[siteId].batiments).forEach(zoneId => {
                            serie['batiments'].push({
                                name: dataPerSite[siteId].batiments[zoneId].name,
                                x: dataPerSite[siteId].batiments[zoneId][x],
                                y: dataPerSite[siteId].batiments[zoneId][y],
                            });
                        });
                    }

                    serieData.push(serie);
                }
            }
        });

        // create legend
        this.scatterPlotProperties.legend.push({
            name: filterName || 'Tous les sites',
            color: filterColor,
        });

        // the scatter plot object expected from highchart for each serie
        return {
            data: serieData,
            color: `rgba(${filterColor})`,
            unitX,
            unitY,
            unitTypeX,
            unitTypeY,
            name: filterName || 'Tous les sites',
        };
    }

    /**
     * Handle a change in the axis selection.
     * Grab new data for all sites.
     * Reset zoom and launch statistics computation.
     */
    onAxeChanged() {
        if (this.chart) {
            this.isLoadingData = true;

            this.handleScatterPlotChart();

            // reset zoom if needed to display all points + reset visible points for statistics
            if (_.get(this.chart, 'chart.resetZoomButton')) {
                this.chart.chart.resetZoomButton.element.onclick();
            }

            this.computeStatistics(this.chartSeries);
        }
    }

    isCollapsed() {
        return this.isFilterCollapsed;
    }

    addHiddenClass() {
        return this.isFilterCollapsed ? 'hidden' : '';
    }

    toggleCollapse() {
        this.isFilterCollapsed = !this.isFilterCollapsed;
        // if the chart doesn't have data, don't try to redraw it, it doesn't exist yet
        if (this.chart) {
            this.chart.redrawChart();
        }
    }

    getChartContainerClass() {
        return this.isCollapsed() ? 'col-md-12 graph-full' : 'col-md-10 graph-half';
    }

    getChartColumnSize() {
        // if the chart has data : col-md-10 for chart & col-md-2 for statistics
        // if no data: col-md-12 for chart & no statistics
        return !this.isLoadingData && this.hasDataForChart() ? 'col-md-10' : 'col-md-12';
    }

    hasEnergyManager() {
        return !this.isLoadingData && this.company && this.company.users && this.company.users.length;
    }

    /**
     * Create a tooltip for each site
     * Specific cases:
     * - if the site has zones, display their own names and conso
     * - if multiple sites share the same point (x, y), aggregate their name
     * - if multiple sites share the same point (x, y), but come from different series (ex: regions), display the region's name before the site's names list
     */
    createChartTooltips(): void {
        const that = this;
        this.scatterPlotProperties.options.plotOptions.scatter.tooltip.pointFormatter = function() {
            const series = [];

            // if there are multiple series with a site having the same (x,y)
            // -> display the serie's name and the sites' name below

            // check if any other point in any serie has the same (x, y)
            this.series.chart.xAxis[0].series.forEach(serie => {
                const points = [];

                serie.data.forEach(point => {
                    if (point.x === this.x && point.y === this.y) {
                        points.push({
                            name: point.name,
                            zone: point.options.zone, // exists if the point is the zone's point (don't know why 'zone' is in 'options' whereas 'batiments' is not
                            batiments: point.batiments, // exists if the site has some zones but they are merged on the site's point
                        });
                    }
                });

                /**
                 * if other points were found in this serie with the same (x, y), note the serie's name and sites' names
                 * $series for the point (x: 0, y: 10) can look like
                 * [ {
                 *       name: 'Ile-de-France',
                 *       points: [
                 *           {
                 *               name : "Crèche de Belleville",
                 *               batiments: null
                 *           },
                 *            {
                 *               name : "Ecole primaire Saint Jean",
                 *               batiments: [...]
                 *           }
                 *        ]
                 *    }
                 *   ]
                 */
                if (points.length) {
                    series.push({
                        name: serie.name,
                        points,
                    });
                }
            });

            const unitX = this.series.userOptions.unitX;
            const unitY = this.series.userOptions.unitY;

            const unitTypeX = this.series.userOptions.unitTypeX;
            const unitTypeY = this.series.userOptions.unitTypeY;

            const mean = that.statisticsComponent.statistics.mean;

            let markup = `<div class="tooltip__container">`;

            series.forEach(serie => {
                serie.points = serie.points.sort(sortListAlphabetically);

                // if different series had sites for the same (x,y)  (ex: Ile-de-France, Auvergne, Corse)
                // --> display the serie's names (Ile-de-France, Auvergne, Corse) because the point color on the graph only refers to the first serie (ex: Ile-de-France)
                if (series.length > 1) {
                    markup += `<hr> <div class="tooltip__series-name">${serie.name}</div> <hr>`;
                }

                serie.points.forEach(site => {
                    markup += `<div><b>${site.name}</b></div>`;
                    markup += site.zone ? `<div><b>${site.zone}</b></div>` : '';

                    // if the site has zones, add them to the tooltip in a table, between the site's name and the site's global conso
                    if (site.batiments) {
                        markup += `<div class="tooltip__values">
                        <table>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                         `;
                        site.batiments.forEach(zone => {
                            // if mean is 0, the point's value is 0 and its difference with the mean is 0%
                            const ratioZoneMeanX = mean.x ? ((zone.x - mean.x) / mean.x) * 100 : 0;
                            const ratioZoneMeanY = mean.y ? ((zone.y - mean.y) / mean.y) * 100 : 0;

                            if (that.isShowingStatistics) {
                                markup += `<tr class="tooltip__table">
                                    <td class="tooltip__zone-name">${zone.name}</td>
                                    <td class="tooltip__zone-name">
                                        ${that.chartService.getNumberToDisplay(zone.y, unitY, unitTypeY)} 
                                        (${that.chartService.getNumberToDisplay(ratioZoneMeanY, '%')})
                                    </td>              
                                    <td class="tooltip__zone-name">
                                        ${that.chartService.getNumberToDisplay(zone.x, unitX, unitTypeX)} 
                                        (${that.chartService.getNumberToDisplay(ratioZoneMeanX, '%')}) 
                                    </td>
                                </tr>            
                                `;
                            } else {
                                markup += `<tr class="tooltip__table">
                                    <td class="tooltip__zone-name">${zone.name}</td>         
                                    <td class="tooltip__zone-name">${that.chartService.getNumberToDisplay(
                                        zone.y,
                                        unitY,
                                        unitTypeY
                                    )}</td>              
                                    <td class="tooltip__zone-name">${that.chartService.getNumberToDisplay(
                                        zone.x,
                                        unitX,
                                        unitTypeX
                                    )}</td>
                                </tr>            
                                `;
                            }
                        });

                        markup += `</table> </div>`;
                    }
                });
            });

            function sortListAlphabetically(siteA, siteB) {
                return siteA.name > siteB.name ? 1 : siteA.name < siteB.name ? -1 : 0;
            }

            const ratioMeanX = mean.x ? ((this.x - mean.x) / mean.x) * 100 : 0;
            const ratioMeanY = mean.y ? ((this.y - mean.y) / mean.y) * 100 : 0;

            if (that.isShowingStatistics) {
                return (
                    markup +
                    `
                     <div class="tooltip__values">
                         <div>
                            ${that.chartService.getNumberToDisplay(this.y, unitY, unitTypeY)} 
                            (${that.chartService.getNumberToDisplay(ratioMeanY, '%')})*
                         </div>
                         <div>
                            ${that.chartService.getNumberToDisplay(this.x, unitX, unitTypeX)} 
                            (${that.chartService.getNumberToDisplay(ratioMeanX, '%')})*
                         </div>
                    </div>

                    <div class="tooltip__values"> * écart par rapport à la moyenne en % </div>
                `
                );
            } else {
                return (
                    markup +
                    `
                        <div class="tooltip__values">
                            <div>${that.chartService.getNumberToDisplay(this.y, unitY, unitTypeY)}</div>
                            <div>${that.chartService.getNumberToDisplay(this.x, unitX, unitTypeX)}</div>
                        </div>
                    `
                );
            }
        };
    }

    // redirection on the site's profile when clicking on a graph point
    createChartClickEvent() {
        this.scatterPlotProperties.options.plotOptions.series = {
            turboThreshold: 0,
            cursor: 'pointer',
            point: {
                events: {
                    click() {
                        location.href = '#/energie/profil/' + this.options.id;
                    },
                },
            },
        };
    }

    /**
     * Handle a change in the format selection
     */
    onFormatChanged() {
        if (this.hasDataForChart()) {
            this.handleScatterPlotChart();
        }
    }
}
