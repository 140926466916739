import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';

// services
import { EnergyService } from 'app/shared/services/energy/energy.service';

// interfaces
import { ContractLight } from 'app/shared/models/contract.interface';
import { RoutingReferencePopulated } from 'app/shared/models/routing-reference.interface';
import { SitePopulatedContracts } from 'app/shared/models/site.interface';
import { Vehicle, VehiclePopulated } from 'app/shared/models/vehicle.interface';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
    selector: 'ga-sites-pdl-list',
    templateUrl: './pdl-list.component.html',
    styleUrls: ['./pdl-list.component.scss'],
})
export class SitesPdlListComponent implements OnInit {
    /**
     * Items to display
     * @deprecated Not used anymore. Use it if site not provided ?
     */
    @Input() items: RoutingReferencePopulated[] | VehiclePopulated[] = [];

    /**
     * Site to display entities from
     */
    @Input() site: SitePopulatedContracts;

    /**
     * Fluid category for display entities. Can be energy, water or vehicles
     */
    @Input() fluidCategory: string = null;

    /**
     * If true, display button to relocate routing references / edit vehicles
     */
    @Input() canEditItems = false;

    /**
     * If true, display button to unlink entities from site
     */
    @Input() canDeleteItems = false;

    /**
     * Triggered when use click on the unlink button and confirmed the modal
     */
    @Output() removeClick: EventEmitter<any> = new EventEmitter();

    /**
     * Vehicle selected for editing modal
     */
    vehicleSelected: VehiclePopulated = null;

    /**
     * Vehicle edit modal
     */
    @ViewChild('modal', { static: true }) modal: ModalDirective;

    /**
     * Swal options for the routing reference unlink confirmation modal
     */
    public swalOptions: SweetAlertOptions = {
        title: "Dissociation d'un PDL",
        text:
            'Vous êtes sur le point de dissocier un PDL de ce site, êtes vous certain de vouloir effectuer cette action ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, le dissocier',
        cancelButtonText: 'Annuler',
    };

    constructor(private energyService: EnergyService) {}

    ngOnInit() {}

    /***************
     * Generic
     ***************/

    /**
     * Get columns headers
     * @returns {string[]}
     */
    get itemColumns(): string[] {
        const columns = this.isSourceRoutingReference ? this.routingReferenceColumns : this.vehicleColumns;

        columns.push('Données'); // common
        return columns;
    }

    /**
     * Returns true if fluid category is either energy or water, false if vehicles
     * @returns {boolean}
     */
    get isSourceRoutingReference(): boolean {
        return this.energyService.isCategoryForRoutingReference(this.fluidCategory);
    }

    /**
     * Returns the element to remove
     * @param {RoutingReferencePopulated|VehiclePopulated} item
     */
    emitDeleteEvent(item: RoutingReferencePopulated | VehiclePopulated) {
        this.removeClick.emit(item);
    }

    /**
     * Returns the url for the routing reference/contract view or the vehicle's followup
     * @param {RoutingReferencePopulated|VehiclePopulated} item
     * @param {ContractLight} contract
     * @returns {url: string[], queryParams: any} url link for routerLink with optional query params
     */
    getItemRedirection(
        item: RoutingReferencePopulated | VehiclePopulated,
        contract?: ContractLight
    ): { url: string[]; params?: any } {
        if (this.isSourceRoutingReference) {
            return { url: [`${this.getItemUrlFromContract(contract.energyType)}/${item._id}/${contract._id}`] };
        }
        return { url: [`/vehicules/suivi`], params: { vehicleId: item._id } };
    }

    /**
     * Find the contract matching the routing reference's id / fuelCard's id and return its date.
     * @param {ContractLight} contract
     * @param {string} itemId - routing reference's id / fuel card's id
     * @returns {{dateStart: string|null, dateEnd: string|null}}
     */
    getItemContractDates(contract: ContractLight, itemId: string): { dateStart: string; dateEnd: string } {
        if (this.isSourceRoutingReference) {
            return this.getRoutingReferenceContractDates(contract, itemId);
        }
        return this.getVehiculeContractDates(contract, itemId);
    }

    /**
     * Return the css class for item validaty.
     * Typing is not possible beacause routing reference and vehcile don't share the field on which the validity is based on.
     * @param {*} item
     * @returns {string} class name
     */
    getItemStatusClass(item: any): string {
        const active = this.isSourceRoutingReference ? this.isRoutingReferenceActive(item) : this.isVehActive(item);
        return active ? 'status__icon-green' : 'status__icon-red';
    }

    /**
     * Get item status title attribute to display
     * @param {*} item
     * @returns {string}
     */
    getItemStatusTitle(item: any): string {
        if (this.isSourceRoutingReference) {
            return this.getRoutingReferenceTitle(item);
        }
        return this.getVehicleTitle(item);
    }

    /***************
     * Routing references
     ***************/

    get routingReferences(): RoutingReferencePopulated[] {
        return this.site && this.site.routingReferences ? this.site.routingReferences : [];
    }

    /**
     * Returns true to fitler items by their energy type, depending on the fluid category (energy, water, vehicle)
     * Water pdls should not be visible in energy page, and vice-versa.
     * (Vehicles are not filtered, we need to diaply all of them)
     * @param {RoutingReferencePopulated} routingReference
     * @returns {boolean}
     */
    isItemFilteredByEnergyType(routingReference: RoutingReferencePopulated): boolean {
        // no need to filter items per energy (it's the case on vehicle page)
        if (!this.isSourceRoutingReference) {
            return true;
        }

        if (routingReference.energyType) {
            if (this.fluidCategory === 'water') {
                return routingReference.energyType === 'water';
            }
            return routingReference.energyType !== 'water';
        }
        return false;
    }

    /**
     * Get URL to relocate routing reference page
     * @param {RoutingReferencePopulated}
     */
    getRelocateRoutingRefUrl(routingRef: RoutingReferencePopulated): string[] {
        const urlCategory = this.energyService.getFluidCategoryFrench(this.fluidCategory);
        return ['/', urlCategory, 'merge', 'rf', routingRef._id];
    }

    /**
     * Returns the url for the routing reference's contract view
     * @param {RoutingReferencePopulated} item - routing reference
     * @param {string} energyType
     * @returns {string[]} url link for routerLink
     */
    getRoutingRefUrl(item: RoutingReferencePopulated, energyType: string): string[] {
        const url = `${this.getItemUrlFromContract(energyType)}/${item._id}`;
        return [url];
    }

    /**
     * Get base url for the routing reference view, for the contract's specific fluid
     * @param {string} energyType
     */
    private getItemUrlFromContract(energyType: string): string {
        return this.energyService.getFluidPdlDataUrl(energyType);
    }

    /**
     * Get common zones for a routing reference
     * @param {RoutingReferencePopulated} routingReference - routing reference object
     * @returns {string} names of the zones sharing the routing reference
     */
    getCommonZones(routingReference: RoutingReferencePopulated): string {
        // Get shared routing reference object if shared
        const sharedRoutingReference = this.site.info.sharedRoutingReferences.find(item => {
            return item.routingReference === routingReference._id;
        });
        // If found, return zones names joined
        if (sharedRoutingReference) {
            const zonesName = sharedRoutingReference.zones.map(zoneItem => {
                const z = this.site.info.batiments.find(x => x._id === zoneItem.zone);
                return z ? z.name : 'Zone NC (Erreur)';
            });
            return zonesName.join(' - ');
        }
        // If not, found, means that it's not shared, so return empty string.
        return '';
    }

    /**
     * Returns true if the routing reference has more than 1 contract
     * @param {RoutingReferencePopulated} routingReference
     * @returns {boolean}
     */
    hasSeveralContracts(routingReference: RoutingReferencePopulated): boolean {
        return routingReference.contracts && routingReference.contracts.length > 1;
    }

    get routingReferenceColumns(): string[] {
        const columns = ['PDL', '', 'N° de contrat', 'Période', 'Type', 'Fournisseur'];
        if (this.hasSharedRoutingReferences) {
            columns.push('Commun');
        }
        return columns;
    }

    /**
     * Returns true if the routing reference is active
     * @param {RoutingReferencePopulated} routingReference
     * @returns {boolean}
     */
    isRoutingReferenceActive(routingReference: RoutingReferencePopulated): boolean {
        return Boolean(routingReference && routingReference.status && routingReference.status.active);
    }

    /**
     * Returns true if the site has some routing references shared by mutiple zones
     * @returns {boolean}
     */
    get hasSharedRoutingReferences(): boolean {
        return (
            this.site &&
            this.site.info &&
            this.site.info.sharedRoutingReferences &&
            this.site.info.sharedRoutingReferences.length
        );
    }

    /**
     * Get routing reference dates for the given contract
     * @param {ContractLight} contract
     * @param {string} id
     * @returns {{dateStart: string, dateEnd: string}}
     */
    getRoutingReferenceContractDates(contract: ContractLight, id: string): { dateStart: string; dateEnd: string } {
        const contractItem = contract.routingReferences.find(x => x.routingReference === id);

        return {
            dateStart: contractItem ? contractItem.dateStart : null,
            dateEnd: contractItem ? contractItem.dateEnd : null,
        };
    }

    /**
     * Get routing reference title attribute to display
     * @param {RoutingReferencePopulated} routingReference
     */
    getRoutingReferenceTitle(routingReference: RoutingReferencePopulated): string {
        return '';
    }

    /***************
     * Vehicles
     ***************/

    /**
     * Get vehicles display columns
     */
    get vehicleColumns(): string[] {
        return ['Immatriculation', 'N° de contrat', 'N° de carte', 'Période', 'Type', 'Fournisseur'];
    }

    get vehicles(): VehiclePopulated[] {
        return this.site && this.site.vehicles ? this.site.vehicles : [];
    }

    /**
     * Returns true if the vehicle is active, meaning its contract hasn't ended yet
     * @param {VehiclePopulated} vehicle
     * @returns {boolean}
     */
    isVehActive(vehicle: VehiclePopulated): boolean {
        /**
         * Vehicle is active if dateOut defined and is after current time.
         * Or if dateOut not defined and dateIn defined and is before current time
         */
        const hasStarted = Boolean(vehicle.dateIn && moment.utc(vehicle.dateIn).isBefore());
        const isStillActual = Boolean(vehicle.dateOut && moment.utc(vehicle.dateOut).isAfter());
        return isStillActual || (!vehicle.dateOut && hasStarted);
    }

    /**
     * Get row span for the vehicle's registration name & its status icon
     * @returns {number} number of rows for this vehicle: 1 row per fuelCard per contract
     */
    getVehicleRowSpan(contracts: ContractLight[]): number {
        return contracts.reduce((count, c) => {
            count += c.fuelCards.length;
            return count;
        }, 0);
    }

    /**
     * Get vehicle dates for the given contract
     * @param {ContractLight} contract
     * @param {string} id
     * @returns {{dateStart: string, dateEnd: string}}
     */
    getVehiculeContractDates(contract: ContractLight, id: string): { dateStart: string; dateEnd: string } {
        const contractItem = contract.fuelCards.find(x => x.fuelCard === id);

        return {
            dateStart: contractItem ? contractItem.dateStart : null,
            dateEnd: contractItem ? contractItem.dateEnd : null,
        };
    }

    /**
     * Update vehicle data
     * @param {Vehicle} vehicle - vehicle with recent data
     */
    updateVehicle(vehicle: Vehicle) {
        /**
         * Vehicle provided is not populated with contracts so we can't just assign.
         */
        this.vehicleSelected.fuelType = vehicle.fuelType;
        this.vehicleSelected.dateIn = vehicle.dateIn;
        this.vehicleSelected.dateOut = vehicle.dateOut;
        this.vehicleSelected.category = vehicle.category;
        this.vehicleSelected.make = vehicle.make;
        this.vehicleSelected.model = vehicle.model;
        this.vehicleSelected.consumption = vehicle.consumption;
        this.vehicleSelected.isHybrid = vehicle.isHybrid;
        if (this.modal) {
            this.modal.hide();
        }
    }

    /**
     * Get vehicle status title attribute
     * @param {VehiclePopulated} vehicle
     * @returns {string}
     */
    getVehicleTitle(vehicle: VehiclePopulated): string {
        if (this.isVehActive(vehicle)) {
            return '';
        }
        if (vehicle.dateOut) {
            return 'La date de sortie du véhicule est dépassée';
        }
        return "La date de sortie du véhicule n'est pas définie";
    }

    /**
     * Return the css class for item validaty.
     * @param {VehiclePopulated} item
     * @returns {string} class name
     */
    getVehicleStatusClass(item: VehiclePopulated): string {
        if (this.isVehActive(item)) {
            return 'status__icon-green';
        }
        if (item.dateOut) {
            return 'status__icon-red';
        }
        return 'status__icon-grey';
    }
}
