import { Injectable } from '@angular/core';
import { ColorService } from '../color/color.service';
import { EnergyService } from '../energy/energy.service';
import { UtilsService } from '../utils/utils.service';

export interface DefaultTile {
    mainColor: string;
    secondaryColor: string;
    unit: string;
    label: string;
    type: string;
    value?: number;
    value2?: number;
    valueHTML?: string;
    size?: TileSize;
    info?: string;
}

/**
 * Used to set a height for the tile
 */
export type TileSize = 'small' | 'medium' | 'large';

export interface CartographyTile {
    label: string;
    value: number | null;
    unit: string;
    subValue?: number;
    subUnit?: string;
    color: string;
    selected: boolean;
    type: string;
}

@Injectable()
export class TilesService {
    private cartographyTiles: { [fluid: string]: CartographyTile } = {
        elec: {
            label: '',
            value: null,
            unit: 'routingReference',
            subValue: null,
            subUnit: 'active_m',
            color: this.colorService.getRgbColor('elec'),
            type: 'elec',
            selected: false,
        },
        fuel: {
            label: '',
            value: null,
            unit: 'vehicle',
            subValue: null,
            subUnit: 'active_m',
            color: this.colorService.getRgbColor('fuel'),
            type: 'fuel',
            selected: false,
        },
        gaz: {
            label: '',
            value: null,
            unit: 'routingReference',
            subValue: null,
            subUnit: 'active_m',
            color: this.colorService.getRgbColor('gaz'),
            type: 'gaz',
            selected: false,
        },
        water: {
            label: '',
            value: null,
            unit: 'routingReference',
            subValue: null,
            subUnit: 'active_m',
            color: this.colorService.getRgbColor('water'),
            type: 'water',
            selected: false,
        },
        heating: {
            label: '',
            value: null,
            unit: 'routingReference',
            subValue: null,
            subUnit: 'active_m',
            color: this.colorService.getRgbColor('heating'),
            type: 'heating',
            selected: false,
        },
        cooling: {
            label: '',
            value: null,
            unit: 'routingReference',
            subValue: null,
            subUnit: 'active_m',
            color: this.colorService.getRgbColor('cooling'),
            type: 'cooling',
            selected: false,
        },
    };

    private defaultTiles: { [fluid: string]: DefaultTile } = {
        elec: {
            mainColor: this.colorService.getRgbColor('elec'),
            secondaryColor: this.colorService.getSecondRgbColor('elec'),
            unit: 'kWh ef',
            label: 'Consommation électrique',
            type: 'elec',
        },
        water: {
            label: 'Consommation eau',
            unit: 'm³',
            mainColor: this.colorService.getRgbColor('water'),
            secondaryColor: this.colorService.getSecondRgbColor('water'),
            type: 'water',
        },
        gaz: {
            label: 'Consommation gaz',
            unit: 'kWh pcs',
            mainColor: this.colorService.getRgbColor('gaz'),
            secondaryColor: this.colorService.getSecondRgbColor('gaz'),
            type: 'gas',
        },
        heating: {
            label: 'Consommation RCU',
            unit: 'kWh',
            mainColor: this.colorService.getRgbColor('heating'),
            secondaryColor: this.colorService.getSecondRgbColor('heating'),
            type: 'heating',
        },
        cooling: {
            label: 'Consommation RFU',
            unit: 'kWh',
            mainColor: this.colorService.getRgbColor('cooling'),
            secondaryColor: this.colorService.getSecondRgbColor('cooling'),
            type: 'cooling',
        },
        fuel: {
            label: 'Consommation fuel',
            unit: 'L',
            mainColor: this.colorService.getRgbColor('fuel'),
            secondaryColor: this.colorService.getSecondRgbColor('fuel'),
            type: 'fuel',
        },
        co2: {
            label: 'Émission CO2',
            unit: 'T',
            mainColor: this.colorService.getRgbColor('co2'),
            secondaryColor: this.colorService.getSecondRgbColor('co2'),
            type: 'co2',
        },
        ttc: {
            label: 'Coût énergétique',
            unit: '€ TTC',
            mainColor: this.colorService.getRgbColor('ttc'),
            secondaryColor: this.colorService.getSecondRgbColor('ttc'),
            type: 'coins',
        },
        ht: {
            label: 'Coût énergétique',
            unit: '€ HT',
            mainColor: this.colorService.getRgbColor('ht'),
            secondaryColor: this.colorService.getSecondRgbColor('ht'),
            type: 'coins',
        },
        ttc_ht: {
            label: 'Coût énergétique',
            unit: '€ HTVA - TTC',
            mainColor: this.colorService.getRgbColor('ttc'),
            secondaryColor: this.colorService.getSecondRgbColor('ttc'),
            type: 'coins',
        },
        missing_measures: {
            label: 'Mesures manquantes',
            unit: '',
            mainColor: this.colorService.getRgbColor('missing_measures'),
            secondaryColor: this.colorService.getSecondRgbColor('missing_measures'),
            type: 'unknown',
        },
        min_elec_power: {
            mainColor: this.colorService.getRgbColor('min_elec_power'),
            secondaryColor: this.colorService.getSecondRgbColor('min_elec_power'),
            unit: 'kW',
            label: 'Puissance min. atteinte',
            type: 'elec',
        },
        max_elec_power: {
            mainColor: this.colorService.getRgbColor('max_elec_power'),
            secondaryColor: this.colorService.getSecondRgbColor('max_elec_power'),
            unit: 'kW',
            label: 'Puissance max. atteinte',
            type: 'elec',
        },
    };

    constructor(
        private colorService: ColorService,
        private utilsService: UtilsService,
        private energyService: EnergyService
    ) {}

    /**
     * Return default cartography tile for the given fluid and value
     * @param {string} fluid
     * @param {number} total
     * @param {number} subValue
     * @param {string | any[] | number} selectedFluid optional fluid type that is selected
     * @returns {CartographyTile} tile for fluid with value updated
     */
    public generateCartoTile(
        fluid: string,
        total: number,
        subValue: number,
        selectedFluid?: string | any[] | number
    ): CartographyTile {
        const tile: CartographyTile = Object.assign({}, this.cartographyTiles[fluid]);
        tile.value = total;
        tile.subValue = subValue;
        tile.label = this.energyService.energyFullText(tile.type);

        tile.unit += tile.value < 2 ? '' : 's';
        if (typeof tile.subValue === 'number') {
            tile.subUnit += tile.subValue < 2 ? '' : 'p';
        }

        // Marking the filtered energy Tile as selected
        if (selectedFluid && selectedFluid === tile.type) {
            tile.selected = true;
        }

        return tile;
    }

    /**
     * Return tile for the given key
     * @param {string} key
     * @returns {DefaultTile} tile for key with value updated
     */
    public generateTile(key: string): DefaultTile {
        return Object.assign({}, this.defaultTiles[key]);
    }

    /**
     * Get fluid information to get object.
     * @param {string} type - fluid type
     * @param {*} value - value to assign to tile
     * @param {string} unit - value unit
     * @returns {{label: string, value: *, unit: string, color: string, type: string}} - data for tile
     */
    public getTile(type: string, value: any, unit: string) {
        let tile = {};
        const fluids = this.energyService.getAllFluids();
        if (fluids.includes(type)) {
            tile = {
                label: this.energyService.energyFullText(type),
                value,
                unit,
                color: this.colorService.getRgbColor(type),
                type,
            };
        }

        return tile;
    }

    public getSimpleTiles(data: any[]): any[] {
        const response = [];

        data.forEach(element => {
            const tile = this.getTile(element.type, element.value, element.unit);
            response.push(tile);
        });

        return response;
    }

    /**
     * Return the value formated with its correct number of minimum and maximum number of decimal digits
     * If value not a number, returns '-'.
     * Sepcific case for fluid value being between 0 and 0.5
     * @param {number} value
     * @param {string} unit
     * @param {string} type
     * @returns {string} value formated
     */
    getNumberToDisplay(value: number, unit: string, type: string = null): string {
        if (typeof value !== 'number') {
            return '-';
        }
        /**
         * Specific case: a fluid value being between 0 and 0.5
         * Simply return '< 1'
         */
        if (value > 0 && value < 0.5) {
            const valueType = this.utilsService.getValueGroup(unit, type);
            const isFluidType = type === 'fluid' || (valueType && valueType.type === 'fluid');
            if (isFluidType) {
                return '< 1';
            }
        }
        return Intl.NumberFormat('fr-FR', {
            maximumFractionDigits: this.utilsService.getNbMinDecimalsForUnitOrType(unit, type),
            minimumFractionDigits: this.utilsService.getNbMinDecimalsForUnitOrType(unit, type),
        }).format(value);
    }
}
