import { AutoCompleteItem } from 'app/shared/components/common/ultra-simple-autocomplete/ultra-simple-autocomplete.interface';
import { InputModalComponent } from 'app/shared/components/modals/input-modal/input-modal.component';
import { UnknownFile } from 'app/shared/components/regex/not-find-table/not-find-table.interface';
import { UploadsMapping } from 'app/shared/components/regex/recap-files/recap-files.interface';
import { UploadFilesTileComponent } from 'app/shared/components/upload/upload-files-tile/upload-files-tile.component';
import { ExtractErrorList } from 'app/shared/constants/extract-error-list.constants';
import { UploadErrorList } from 'app/shared/constants/upload-error-list.constants';
import { UploadStateList } from 'app/shared/constants/upload-state-list.constants';
import { ErrorUploadObject } from 'app/shared/models/error-upload-object.interface';
import {
    ContractUploadsGroup,
    File,
    ForceType,
    Upload,
    UploadChunkRecap,
    UploadFile,
} from 'app/shared/models/upload-file.interface';
import { UploadGroup } from 'app/shared/models/upload-group.interface';
import { EnergyService } from 'app/shared/services/energy/energy.service';
import { NotificationsService } from 'app/shared/services/notifications/notifications.service';
import { TranslateService } from 'app/shared/services/translate/translate.service';
import * as BlueBird from 'bluebird';
import * as _ from 'lodash';
import Swal from 'sweetalert2';

import { Location } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { selectedFilesToExtractFileParams as getSelectedFiles } from './collect.helper';
import { ExtractFileParams, Loading, StateTile } from './collect.interface';
import { CollectService } from './collect.service';

const globalStates = {
    NOTHING: 'nothing',
    DOING: 'doing',
    DONE: 'done',
};

@Component({
    selector: 'ga-collect',
    templateUrl: './collect.component.html',
    styleUrls: ['./collect.component.scss'],
    providers: [CollectService],
})
export class CollectComponent implements OnInit {
    @ViewChild('upload', { static: true }) private uploadComponent: UploadFilesTileComponent;
    @ViewChild('groupModal', { static: true }) private groupModal: InputModalComponent;
    @ViewChild('groupEditModal', { static: true }) private groupEditModal: InputModalComponent;
    @ViewChild('groupDeleteModal', { static: true }) private groupDeleteModal: InputModalComponent;
    @ViewChild('groupReExtractModal', { static: true }) private groupReExtractModal: InputModalComponent;

    files: Upload[] = [];
    group: any;
    dataArray: ContractUploadsGroup[] = [];
    unknownFiles: UnknownFile[] = [];
    groupList: UploadGroup[] = [];
    /**
     * List of items matching search (for upload search to set group)
     */
    public searchUploadList: AutoCompleteItem[] = [];
    actualState = '';
    isPageDisabled = false;
    isDragAndDropDisabled = false;
    isExtractSelectionDisabled = true;
    temporaryFiles: File[] = [];
    selectedGroupType: any = {};
    indexSelectedGroupType = 0;
    indexSelectedFile = -1;

    patternsPropertiesData: any[] = [];
    recapFilesData: UploadChunkRecap[][] = [];
    energyType = '';

    // modal values
    modalError = '';
    modalValue = '';
    modalEditError = '';
    modalDeleteError = '';
    modalReExtractError = '';
    filterType = 'all';
    filteredFiles = [];
    filteredRecapFilesData = [];
    filteredPatternsPropertiesData = [];
    filteredDataArray = [];

    uploadTilesData: {
        [state: string]: StateTile;
    } = {
        [globalStates.NOTHING]: {
            label: 'Glissez et déposez vos fichiers',
            subLabel: "On s'occupe de tout !",
            imgUrl: '/assets/img/pictos-svg/picto_citron.svg',
        },
        [globalStates.DOING]: {
            label: 'Extraction des données en cours',
            subLabel: "et si vous profitiez d'une pause pendant ce temps ?",
            imgUrl: '/assets/img/pictos-svg/picto_juicer_1.svg',
        },
        [globalStates.DONE]: {
            label: "C'est déja terminé !",
            subLabel: "Vous pouvez voir l'ensemble de vos données sur les autres pages de la plateforme",
            imgUrl: '/assets/img/pictos-svg/picto_lemonade_1.svg',
        },
    };

    tableState: any = {
        property: {
            isOpen: true,
        },
        recap: {
            isOpen: true,
        },
    };

    loading: Loading = {
        tableProperties: false,
        tableRecap: false,
        group: false,
    };
    /** default identification pattern that will be set to chunk if it doesn't have any */
    private readonly jsonIdentificationPatternDefault = {
        _id: 'NC',
        provider: {
            name: 'NC',
        },
        contractType: {
            name: 'NC',
        },
        energyType: 'unknown',
    };

    @HostListener('window:beforeunload', ['$event']) beforeUnloadHandler(event) {
        // TODO : nice alert
        return confirm('Confirm refresh');
    }

    constructor(
        private collectService: CollectService,
        private energyService: EnergyService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private notificationService: NotificationsService,
        private translateService: TranslateService
    ) {}
    ngOnInit() {
        this.loading.tableProperties = true;
        // collecte/groupId?index=2
        this.route.queryParams.subscribe(queryParams => {
            if (!queryParams.hasOwnProperty('index')) {
                this.indexSelectedGroupType = 0;
            } else if (queryParams.index !== this.indexSelectedGroupType) {
                this.indexSelectedGroupType = Number(queryParams.index);
            }
        });

        // handles the collect page data when the URL is changed
        this.route.params.subscribe(params => {
            // change of groupId in URL or no groupId in URL
            if (!this.group || this.group._id !== params.groupId) {
                this.reinitData();
                this.lookIntoGroup(params.groupId);
            } else {
                // select data related to the provider group and refresh the blocks data
                this.selectGroupType(this.indexSelectedGroupType);
            }
        });
    }

    /**
     * Search group Id and load its data
     * @param {string} groupId - group id to load
     * @returns {Promise<void>}
     */
    async lookIntoGroup(groupId: string): Promise<void> {
        this.loading.group = true;
        try {
            const hasGroupInList = this.groupList.some(group => group._id === groupId);

            if (!hasGroupInList) {
                // the group has just been created -> add the new group to this.groupList
                await this.resetGroupList();
            }
            if (groupId) {
                await this.setGroup(groupId);
            }
            this.loading.group = false;
        } catch (error) {
            this.loading.group = false;
            Swal.fire('Une erreur est survenue', 'Impossible de récupérer le groupe.', 'error');
        }
    }

    /**
     * Load and set selected group data
     * @todo check use of launch extract parameter, seems to be useless
     * @param {string} groupId - group id
     * @param {boolean} launchExtract - launch extract
     * @returns {Promise<*>}
     */
    async setGroup(groupId: string, launchExtract: boolean = false): Promise<any> {
        // this.group = undefined if we come from the collect page without any group selected #/collecte
        // groupId !== this.group._id if we come from another group #/collecte/123456
        if (!this.group || groupId !== this.group._id) {
            this.group = this.groupList.find(group => group._id === groupId);

            if (this.group) {
                // indexSelectedGroupType comes from the optional URL parameter or is null
                // launchExtract is false if the group has just been created
                return this.getUploadsByGroup(launchExtract);
            }
        } else {
            // indexSelectedGroupType = the provider group type index
            this.selectGroupType(this.indexSelectedGroupType);
        }
        return;
    }

    /**
     * Navigate to the group selection page or a specific group page.
     * @param {string?} groupId - id of the group to navigate to
     */
    changeRouteUrl(groupId: string = null) {
        if (groupId) {
            this.router.navigate(['/collecte/' + groupId]);
        } else {
            this.router.navigate(['/collecte']);
        }
    }

    /**
     * Search upload and update list
     * @param {string} search
     */
    public async searchUpload(search: string) {
        try {
            // Search for uploads matching
            const uploads = await this.collectService.searchUpload(search);
            // Set group id as item id as when an item is selected, group will be set
            this.searchUploadList = uploads.map(x => ({ value: x.group, label: x.originalname }));
            // If none found, display no upload found message
            if (this.searchUploadList.length === 0) {
                this.searchUploadList = [{ value: null, label: this.translateService._('no_upload_found') }];
            }
        } catch (e) {
            // Display that an error occured
            this.searchUploadList = [{ value: null, label: this.translateService._('error_loading') }];
        }
    }

    /**
     * Handle upload selection
     * @param {AutoCompleteItem} item
     */
    public selectSearchUpload(item: AutoCompleteItem) {
        if (item && item.value) {
            // Set selected group
            this.changeRouteUrl(item.value);
        }
    }

    /**
     * Empty block components
     */
    reinitData() {
        this.dataArray = [];
        this.selectedGroupType = {};
        this.files = [];
        this.recapFilesData = [];
        this.patternsPropertiesData = [];
        this.unknownFiles = [];
        this.filteredFiles = [];
        this.filteredPatternsPropertiesData = [];
        this.filteredRecapFilesData = [];
        this.filteredDataArray = [];
    }

    // called from the modal to create a new upload group
    createGroupUpload(name): Promise<any> {
        return this.collectService
            .createGroup(name)
            .then(response => {
                this.group = response.data;
                this.groupList = this.groupList.concat(this.group);
                this.reinitData();
                this.indexSelectedGroupType = 0;

                this.addFiles(this.temporaryFiles).then(() => {
                    this.changeRouteUrl(this.group._id);
                });
            })
            .catch(err => {
                const openModalData: any = {
                    value: name,
                };

                if (err.code === 422) {
                    openModalData.error = 'Un groupe avec ce nom existe déjà';
                } else {
                    openModalData.error = "Une erreur est survenue, le groupe n'a pas pu être enregistré";
                }

                this.openGroupModal(openModalData);
            });
    }

    /**
     * Update the current group name
     * @return {Promise<void>}
     */
    async editGroupUpload(name): Promise<void> {
        try {
            this.group = await this.collectService.updateGroup(this.group._id, name);
            this.groupList = this.groupList
                .map(item => {
                    if (item._id === this.group._id) {
                        return this.group;
                    }

                    return item;
                })
                .sort(this.groupListSorter);
        } catch (err) {
            const openModalData: { value: string; error: string } = {
                value: name,
                error: "Une erreur est survenue, le groupe n'a pas pu être enregistré",
            };

            if (err.code === 422) {
                openModalData.error = 'Un groupe avec ce nom existe déjà';
            }

            this.openGroupEditModal(openModalData);
        }
    }

    /**
     * Delete the current upload group with all the uploads' data.
     * @return {Promise<void>}
     */
    async deleteGroupUpload(): Promise<void> {
        try {
            this.isPageDisabled = true;
            const response = await this.collectService.deleteGroup(this.group._id);

            this.group = null;
            this.groupList = this.groupList.filter(item => {
                return item._id === response.data._id;
            });

            // Réinit data status
            this.reinitData();
            this.isPageDisabled = false;

            // Refresh notifications > number of uploads in error
            this.notificationService.refresh();

            // Go on the main collect url
            this.changeRouteUrl();
        } catch (err) {
            this.isPageDisabled = false;
            this.openGroupDeleteModal({ error: "Une erreur est survenue, le groupe n'a pas pu être supprimé" });
        }
    }

    /**
     * Re-extract all uploads of a upload groups
     */
    async reExtractGroupUpload(): Promise<void> {
        try {
            this.isPageDisabled = true;
            // reset all uploads to be UNTOUCHED
            const result = await this.collectService.resetGroup(this.group._id);
            // TODO: Implement error handling as result sends 2 tables : success and error.

            // Refesh the data on the page after reset
            this.reinitData();
            await this.getUploadsByGroup();

            for (let i = 0, iLen = result.success.length; i < iLen; i++) {
                const uploadId: string = result.success[i];
                try {
                    // extract upload
                    const res = await this.collectService.extractFile(uploadId);
                    this.updateExtractionReportData(res.data);
                } catch (error) {}
            }
            this.isPageDisabled = false;
            // Refresh the data on the page after re-extract
            this.reinitData();
            await this.getUploadsByGroup();
        } catch (err) {
            this.isPageDisabled = false;
            this.openGroupReExtractModal({ error: 'Une erreur est survenue.' });
        }
    }

    /**
     * Reset upload groups list. Sort groups alphabetically.
     */
    async resetGroupList(): Promise<void> {
        try {
            const groups = await this.collectService.getGroupsByCompany();
            this.groupList = groups.sort(this.groupListSorter);
        } catch (e) {
            this.groupList = [];
            Swal.fire('Toutes nos excuses', 'Une erreur est survenue lors de la récupération des groupes', 'error');
        }
    }

    /**
     * Sort the elements by alphabetical order on name
     */
    groupListSorter(a: UploadGroup, b: UploadGroup): number {
        if (a && a.name && b && b.name) {
            return a.name.localeCompare(b.name);
        }
        if (a && a.name) {
            return -1;
        }
        return 1;
    }

    createExtractInitFunction(uploadObject: Upload, launchExtract, extractSelectedGroupType) {
        return function() {
            return new Promise(resolve => {
                // if file has a state then has never been extracted (UNTOUCHED) --> need to do it again
                // if no provider group type was specified in the URL parameter (extractSelectedGroupType === null)
                if (
                    launchExtract &&
                    extractSelectedGroupType === null &&
                    UploadStateList[uploadObject.state] === UploadStateList.UNTOUCHED
                ) {
                    const file = this.files.find(elem => elem.id === uploadObject._id);
                    file.uploadId = file.id;
                    this.extractFile(file).then(result => {
                        resolve(result);
                    });
                } else {
                    resolve(null);
                }
            });
        };
    }

    updateValuesByFilterState() {
        if (this.filterType === 'errored') {
            this.filteredFiles = this.getErrorFiles();
            this.filteredRecapFilesData = this.getErroredRecapFilesData();
            this.filteredPatternsPropertiesData = this.getErroredPatternsPropertiesData();
            this.filteredDataArray = this.getErroredDataArray();
        } else {
            this.filteredFiles = this.files;
            this.filteredRecapFilesData = this.recapFilesData;
            this.filteredPatternsPropertiesData = this.patternsPropertiesData;
            this.filteredDataArray = this.dataArray.slice();
        }
    }
    getErrorFiles() {
        return this.files.filter(file => file.state !== 'jobs_done');
    }

    getErroredRecapFilesData() {
        return this.recapFilesData.filter(file => {
            return file.find(chunk => {
                return chunk.state !== 'JOBS_DONE';
            });
        });
    }

    getErroredPatternsPropertiesData() {
        return this.patternsPropertiesData.filter(pattern => pattern.hasPropertiesNotFound);
    }

    getErroredDataArray() {
        const result = this.dataArray.map(contractUploadGroup => {
            const clone = { ...contractUploadGroup };
            clone.data = contractUploadGroup.data.filter(chunk => chunk.error);
            return clone;
        });
        return result;
    }

    /**
     * Get uploads by group
     * @param launchExtract - true to launch extract
     * @param extractSelectedGroupType
     */
    async getUploadsByGroup(launchExtract: boolean = false, extractSelectedGroupType = null): Promise<void> {
        try {
            const uploads = await this.collectService.getUploadsByGroup(this.group._id);

            if (uploads.length) {
                // Refresh component extract report and Recap File
                uploads.forEach((uploadObject: Upload) => {
                    /** If an upload is being extracted, disable all extraction on page */
                    if (UploadStateList[uploadObject.state] === UploadStateList.IN_PROGRESS) {
                        this.isPageDisabled = true;
                    }

                    // update this.files for blocks "Etat de l'extraction" & "Rapport d'extraction"
                    this.updateExtractionReportData(uploadObject);

                    // update unknown files for Bloc 1 : Unknown files
                    // update dataArray for Bloc 2 : Contracts types
                    this.updateUnknownFilesAndProvidersTableData(uploadObject);
                });
                // Update dataArray forceType
                this.updateDataArrayForceType();

                const serialFunctions = BlueBird.resolve(
                    uploads.map(uploadObject => {
                        return this.createExtractInitFunction(
                            uploadObject,
                            launchExtract,
                            extractSelectedGroupType
                        ).bind(this);
                    })
                );

                await serialFunctions.mapSeries(this.iterator);
                if (this.dataArray.length) {
                    // indexSelectedGroupType = the provider group type index
                    this.selectGroupType(this.indexSelectedGroupType);
                }
            }
        } catch (err) {
            Swal.fire('Toutes nos excuses', 'Une erreur est survenue lors de la récupération des fichiers', 'error');
        }
    }

    /**
     * Lauchn extract on selected group type
     * @param {boolean} forceExtract
     */
    async launchExtractOnSelectedGroupType(forceExtract: boolean = false) {
        const dataSelected = this.dataArray[this.indexSelectedGroupType];

        const result = await Swal.fire({
            title: '',
            text:
                'Etes-vous sûr(e) de réextraire tous les fichiers du contrat ' +
                dataSelected.contractType.name +
                ' de ' +
                dataSelected.provider.name +
                ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#009688',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
            },
            buttonsStyling: false,
        });
        if (result.value) {
            const filesToExtract = this.formatContractFilesToExtract(dataSelected.forceType);
            await this.extractSelectedFiles(filesToExtract, forceExtract);
        }
    }

    extractGroupType(index: number, forceExtract = false) {
        if (this.indexSelectedGroupType !== index) {
            this.changeGroupTypeSelected(index);
        }

        this.launchExtractOnSelectedGroupType(forceExtract);
    }

    handleUnkwownFile(upload: Upload) {
        if (!this.unknownFiles.find(x => x.id === upload._id)) {
            this.unknownFiles.push({
                id: upload._id,
                filename: upload.originalname,
                size: upload.size,
                uploadState: upload.state,
            });
        }
    }

    /**
     * upload with chunks : add data to blocks "Unkwown files" + "Contract types"
     */
    handleUploadWithChunks(upload: Upload) {
        // add the chunk data to the contract type data
        upload.chunks.forEach(chunk => {
            // Set a default identification patern to chunk if it doesn't have one
            if (!chunk.identificationPattern) {
                chunk.identificationPattern = this.jsonIdentificationPatternDefault;
            }
            // check if the contract type has already been found
            const concatEnergyProviderContractType = this.getContractTypeName(chunk);

            if (
                this.dataArray.findIndex(
                    elem => elem.groupContractProviderEnergy === concatEnergyProviderContractType
                ) === -1
            ) {
                // add the contract type if it's a new one
                this.addContractType(chunk, concatEnergyProviderContractType);
            }

            this.addChunkDataToContractType(chunk, upload, concatEnergyProviderContractType);
        });
    }

    /**
     * Update `dataArray` force type for each element
     */
    private updateDataArrayForceType() {
        this.dataArray.forEach(elem => {
            elem.forceType = this.collectService.getForceTypeFromStates(elem.data, true);
        });
    }

    /**
     * Add contract type for bloc 'Données extraites de x fichier'.
     * Also used for all blocs after (when a contract is selected, change the rest)
     * @param {Upload} upload - upload to add the contract from
     * @param {string} concatEnergyProviderContractType - concatenation of strings fluid provider and contract type to create a unique identifier
     */
    addContractType(upload: Upload, concatEnergyProviderContractType: string) {
        this.dataArray.push({
            groupContractProviderEnergy: concatEnergyProviderContractType,
            energy: this.energyService.energyFullText(upload.identificationPattern.energyType),
            energyType: upload.identificationPattern.energyType,
            provider: upload.identificationPattern.provider,
            contractType: upload.identificationPattern.contractType,
            count: 0,
            error: false,
            data: [],
            forceType: null,
        });
        this.updateValuesByFilterState();
    }

    private addChunkDataToContractType(chunk: any, upload: Upload, concatEnergyProviderContractType: string) {
        const contractType = this.dataArray.find(
            elem => elem.groupContractProviderEnergy === concatEnergyProviderContractType
        );

        // the chunk/upload might be extracted a second time, in which case we remove the old one
        const indexToRemove = contractType.data.findIndex(elem => elem.id === chunk.checksum);
        if (indexToRemove !== -1) {
            contractType.data.splice(indexToRemove, 1);
            contractType.count -= 1;
        }

        const energyType: string = this.getEnergyType(chunk);
        const isClosestPatternExisting = chunk.clothestPattern && chunk.clothestPattern[energyType];

        // add the chunk to the contract type data
        contractType.data.push({
            uploadId: upload._id,
            chunkId: chunk._id,
            id: chunk.checksum, // TODO: change the name to checksum but it's used for the extract so CAREFUL
            filename: upload.originalname,
            size: upload.size,
            identificationPattern: chunk.identificationPattern,
            state: chunk.state,
            missingProperties: chunk.missingProperties,
            requiredProperties: isClosestPatternExisting ? chunk.clothestPattern[energyType].requiredProperties : null,
            patternId: isClosestPatternExisting ? chunk.clothestPattern[energyType]._id : null,
            stack: chunk.stack,
            error: chunk.error,
            uploadState: upload.state,
        });

        contractType.count += 1;
    }

    /**
     * Return the group key of the given upload: fluid, provider and contract type name concatenated as a key id
     * @param {Upload} upload
     * @returns {string} concatenated keys
     */
    getContractTypeName(upload: Upload): string {
        if (!upload.identificationPattern || typeof upload.identificationPattern !== 'object') {
            return 'NCNCNC';
        }

        const energy = this.energyService.energyFullText(upload.identificationPattern.energyType);
        const provider = upload.identificationPattern.provider.name;
        const contractType = upload.identificationPattern.contractType.name;

        return energy + provider + contractType;
    }

    /**
     * Handle uploads without chunks to update `dataArray` for Bloc 2 : Contracts types
     */
    private handleUploadWithoutChunk(upload: Upload) {
        if (!upload.identificationPattern) {
            upload.identificationPattern = this.jsonIdentificationPatternDefault;
        }

        // check if the contract type has already been found
        const concatEnergyProviderContractType = this.getContractTypeName(upload);

        if (
            this.dataArray.findIndex(elem => elem.groupContractProviderEnergy === concatEnergyProviderContractType) ===
            -1
        ) {
            // add the contract type if it's a new one
            this.addContractType(upload, concatEnergyProviderContractType);
        }

        // -- addChunkDataToContractType
        const contractType = this.dataArray.find(
            elem => elem.groupContractProviderEnergy === concatEnergyProviderContractType
        );

        // the upload might be extracted a second time, in which case we remove the old one
        const indexToRemove = contractType.data.findIndex(elem => elem._id === upload._id);
        if (indexToRemove !== -1) {
            contractType.data.splice(indexToRemove, 1);
            contractType.count -= 1;
        }

        // TODO refacto - récupérer que les éléments utiles de l'upload sous le même format que les chunks
        // redundant but makes things easier in subcomponenent to differenciate files with and without chunks
        /*{
                uploadId: upload._id,
                chunkId: fileChunk._id,
                id: fileChunk.checksum,
                filename: upload.filename,
                size: upload.size,
                identificationPattern: fileChunk.identificationPattern,
                state: fileChunk.state,
                missingProperties: fileChunk.missingProperties,
                requiredProperties: (fileChunk.clothestPattern) ? fileChunk.clothestPattern[fileChunk.identificationPattern.energyType].requiredProperties : null,
                patternId: (fileChunk.clothestPattern) ? fileChunk.clothestPattern[fileChunk.identificationPattern.energyType]._id : null,
                stack: fileChunk.state
         }*/
        upload.uploadId = upload._id;
        upload.chunkId = null;
        upload.filename = upload.originalname;

        const energyType: string = this.getEnergyType(upload);

        const isClosestPatternExisting = upload.clothestPattern && upload.clothestPattern[energyType];
        upload.requiredProperties = isClosestPatternExisting
            ? upload.clothestPattern[energyType].requiredProperties
            : null;
        upload.patternId = isClosestPatternExisting ? upload.clothestPattern[energyType]._id : null;
        upload.uploadState = upload.state;

        contractType.data.push(upload);
        contractType.count += 1;
    }

    /**
     * Returns energyType of an upload or a chunk according to it's identificationPattern.
     * If identificationPattern is null then find it on the clothestPattern.
     * @param {any} uploadOrChunk - either a chunk or an upload
     * @returns {string} energyType
     */
    private getEnergyType(uploadOrChunk: any): string {
        // handles if identifactionPatterns has been delete from database
        if (uploadOrChunk.identificationPattern.energyType === 'unknown') {
            if (uploadOrChunk.clothestPattern) {
                for (const param in uploadOrChunk.clothestPattern) {
                    if (param) {
                        return param;
                    }
                }
            }
        }
        return uploadOrChunk.identificationPattern.energyType;
    }

    /**
     * update unknown files for Bloc 1 : Unknown files
     * update dataArray for Bloc 2 : Contracts types
     */
    private updateUnknownFilesAndProvidersTableData(upload: Upload) {
        if (!upload.identificationPattern && upload.state === 'PATTERN_MISSING') {
            // identification pattern missing -> upload goes to unkwown files table
            this.handleUnkwownFile(upload);
        } else if (upload.identificationPattern && upload.identificationPattern.isMultiChunk) {
            // sort the chunk by contract type and add their data to the corresponding contract type data
            this.handleUploadWithChunks(upload);
        } else {
            // upload without chunk
            this.handleUploadWithoutChunk(upload);
        }
    }

    // update files for block "Extraction report"
    updateExtractionReportData(upload: Upload) {
        const file = this.files.find(elem => elem.id === upload._id);

        if (!file) {
            const formatedFile: Upload = {
                id: upload._id,
                filename: upload.originalname,
                identificationPattern: upload.identificationPattern,
                size: upload.size,
                group: upload.group,
                percent: 0,
                state: UploadStateList[upload.state],
                stack: upload.stack,
                file: upload.file,
            };

            this.files.push(formatedFile);
        } else {
            file.state = UploadStateList[upload.state];
            file.stack = upload.stack;
            file.identificationPattern = upload.identificationPattern;
        }
        this.updateValuesByFilterState();
    }

    changeGroupTypeSelected(index: number) {
        this.indexSelectedFile = null; // deselect file above if one was selected
        this.selectGroupType(index);
    }

    selectGroupType(index: number) {
        // if the index comes from url parameter but doesn't exist ( could be modified by hand in the URL and be wrong)
        this.indexSelectedGroupType = this.filteredDataArray[index] ? index : 0;
        this.selectedGroupType = this.filteredDataArray[this.indexSelectedGroupType];

        this.location.go('collecte/' + this.getGroupId() + '?index=' + this.indexSelectedGroupType); // change the URL without charging it

        // selectedGroupType is null if it's a brand new group
        if (this.selectedGroupType) {
            this.refreshDataOnGoupTypeSelected();
        }
    }

    /**
     * Refresh
     */
    refreshDataOnGoupTypeSelected() {
        this.energyType = this.selectedGroupType.energyType;

        this.makeRecapFilesData();
        this.makePatternsPropertiesData();
        this.updateValuesByFilterState();
    }

    selectFileExtractionStates(index) {
        this.indexSelectedFile = index;

        const contractGroupIndex = this.dataArray.findIndex(contractTypeGroup => {
            const hasUploadWithId = contractTypeGroup.data.find(f => f.uploadId === this.files[index].id.toString());
            return hasUploadWithId ? true : false;
        });

        // if selected a multi file & all the chunks are unknown OR selected an unknown file -> index =-1
        if (contractGroupIndex !== -1) {
            this.selectGroupType(contractGroupIndex);
        }
    }

    /**
     * Aggregate the contract chunks per upload filename
     */
    makeRecapFilesData(): void {
        const tmpFiles: { [filename: string]: UploadChunkRecap[] } = {};

        this.selectedGroupType.data.forEach((upload, i) => {
            const filename = upload.filename || 'Fichier ' + (i + 1);

            if (typeof tmpFiles[filename] === 'undefined') {
                tmpFiles[filename] = [];
            }

            tmpFiles[filename].push({
                filename,
                missingProperties: upload.missingProperties,
                size: upload.size,
                state: upload.state,
                nbProperties: this.getRequiredProperties(upload),
                checked: false,
                stack: upload.stack,
                uploadId: upload.uploadId,
                chunkId: upload.chunkId,
                uploadState: upload.uploadState,
                error: upload.error,
            });
        });

        this.recapFilesData = Object.keys(tmpFiles).map(index => {
            return tmpFiles[index];
        });
        this.updateValuesByFilterState();
    }

    getRequiredProperties(file): string[] {
        // chunkFile
        if (file.requiredProperties) {
            return file.requiredProperties;
        } else if (
            file.clothestPattern &&
            file.identificationPattern &&
            file.clothestPattern[file.identificationPattern.energyType] &&
            file.clothestPattern[file.identificationPattern.energyType].requiredProperties
        ) {
            return file.clothestPattern[file.identificationPattern.energyType].requiredProperties;
        }
        return [];
    }

    makePatternsPropertiesData() {
        const notDisplayedStates = ['ARCHIVED'];

        this.patternsPropertiesData = this.selectedGroupType.data.reduce((memo: any[], file, i: number) => {
            if (!notDisplayedStates.includes(file.state)) {
                const filename = file.filename || 'Fichier ' + (i + 1);

                let hasPropertiesNotFound = false;

                const fileProperties: Array<{ name: string; found: number }> = this.getRequiredProperties(file)
                    .map(property => {
                        let propertyFound = 1;

                        if (file.missingProperties && file.missingProperties.includes(property)) {
                            propertyFound = 0;
                            hasPropertiesNotFound = true;
                        }

                        return {
                            name: property,
                            found: propertyFound,
                        };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));

                const patternName = file.identificationPattern.contractTypePattern;

                memo.push({
                    filename,
                    pattern: {
                        name: patternName,
                        id: file.patternId,
                    },
                    properties: fileProperties,
                    hasPropertiesNotFound,
                    uploadId: file.uploadId,
                    chunkId: file.chunkId,
                });
            }
            return memo;
        }, []);

        this.loading.tableProperties = false;
    }

    // if the user forgot to create / select a group before drag@dropping files
    openGroupModal({ error = '', value = '' } = { error: '', value: '' }) {
        this.modalError = error;
        this.modalValue = value;

        // this calls the show method inside the InputModalComponent (methods that show the modal itself)
        this.groupModal.show();
    }

    openGroupEditModal({ error = '' } = { error: '' }) {
        this.modalEditError = error;

        // this calls the show method inside the InputModalComponent (methods that show the modal itself)
        this.groupEditModal.show();
    }

    openGroupDeleteModal({ error = '' } = {}) {
        this.modalDeleteError = error;

        // this calls the show method inside the InputModalComponent (methods that show the modal itself)
        this.groupDeleteModal.show();
    }

    openGroupReExtractModal({ error = '' } = {}) {
        this.modalReExtractError = error;

        this.groupReExtractModal.show();
    }
    /*
    update this.files for bloc "Extraction report"
	formatedFile = {
                id: '',
                filename: file.filename,
                size: file.size,
                state: '',
                percent: 0,
                group: this.group._id,
                // file Object containing raw data
                fileObject: file,
                stack: null
            };
	theses files comes from drag drop upload or input file
	*/

    async addFiles(files: File[]): Promise<void> {
        try {
            if (!files) {
                Swal.fire('Attention', 'Seuls les fichiers PDF sont acceptés.', 'warning');
                return Promise.resolve();
            }

            // if the user forgot to create / select a group before drag@adding files
            if (!this.group) {
                this.temporaryFiles = files;
                this.openGroupModal();
                return;
            }

            if (!files) {
                return;
            }

            this.temporaryFiles = [];

            this.isPageDisabled = true;
            this.isDragAndDropDisabled = true;

            const serialFunctions = BlueBird.resolve(
                files.map(f => this.createUploadAndExtractSerialFunction(f).bind(this))
            );
            const results = await serialFunctions.mapSeries(this.iterator);
            this.removeFilesNotUploaded();
            this.isPageDisabled = false;
            this.isDragAndDropDisabled = false;
            return this.generateReportExtractionError(results);
        } catch (e) {
            this.isPageDisabled = false;
            this.isDragAndDropDisabled = false;
            // TODO Display specific error message
            Swal.fire('Toutes nos excuses', 'Une erreur est survenue, veuillez réessayer plus tard', 'error');
        }
    }

    iterator(f) {
        return f();
    }

    removeFilesNotUploaded(): void {
        this.files = this.files.filter(upload => upload.state !== UploadStateList.UPLOAD_ERROR);
    }

    /**
     * Generate report in a swal to display the names of the files having their removal failed
     * @param {UnknownFile[]} files - files that failed to be removed
     */
    generateReportMultiRemoval(files: UnknownFile[]): void {
        if (!files.length) {
            return;
        }

        // Generate error report with file name
        let html = '';
        html += '<ul>';

        files.forEach(file => {
            html += '<li>' + file.filename + '</li>';
        });
        html += '</ul>';

        // Display error report
        Swal.fire({
            html: "<div>Certains fichiers n'ont pas pu être supprimés</div>" + html,
            icon: 'error',
        });
    }

    /**
     * Generate report in a swal to display the filenames grouped by type of error
     * @param {ErrorUploadObject[]} resultsExtract - extraction results - one per upload, failed or successful
     */
    generateReportExtractionError(resultsExtract: ErrorUploadObject[]): Promise<void> {
        // Generate html report
        if (resultsExtract && resultsExtract.length && resultsExtract.some(result => !result || !result.success)) {
            const errors = this.groupErrors(resultsExtract);
            // Generate error report with file name
            let html = '';
            Object.keys(errors).forEach(key => {
                html += '<h3>' + errors[key].name + '</h3>';
                html += '<ul>';
                if (errors[key].items) {
                    errors[key].items.forEach(item => {
                        html += '<li>' + item + '</li>';
                    });
                }
                html += '</ul>';
            });

            // Display error report
            Swal.fire({
                html: "<h1>Rapport d'erreurs</h1><br>" + html,
                icon: 'error',
            });
            return Promise.resolve();
        }
        return Promise.resolve();
    }

    /**
     * Group filenames per errorCode
     * @param {ErrorUploadObject[]} resultsExtract
     * @returns {name: string, items: string[]} filenames grouped per error msg
     */
    groupErrors(resultsExtract: ErrorUploadObject[]): { name: string; items: string[] } {
        const errors: any = {};
        for (let i = 0, len = resultsExtract.length; i < len; i++) {
            const result = resultsExtract[i];
            if (result && !result.success) {
                if (!errors[result.errorCode]) {
                    errors[result.errorCode] = {
                        name: this.getError(result.errorCode),
                        items: [],
                    };
                }

                if (resultsExtract[i].file) {
                    const name = resultsExtract[i].file.filename || resultsExtract[i].file.fileObject.name;
                    errors[result.errorCode].items.push(name);
                }
            }
        }
        return errors;
    }

    createUploadAndExtractSerialFunction(file: File) {
        return function() {
            return new Promise((resolve, reject) => {
                const uploadFile: UploadFile = {
                    id: '',
                    filename: file.filename,
                    size: file.size,
                    state: '',
                    percent: 0,
                    group: this.group._id,
                    fileObject: file, // file Object containing raw data
                    stack: null,
                };
                this.files.push(uploadFile);
                return this.uploadAndExtractFile(uploadFile).then(response => {
                    resolve(response);
                });
            });
        };
    }

    /**
     * Extract selected files
     * @param uploadsSelected - uploads selected array
     * @param forceExtract - true to force
     */
    async extractSelectedFiles(uploadsSelected: UploadsMapping[], forceExtract: boolean = false): Promise<void> {
        this.isPageDisabled = true;
        const results = [];
        for (let i = 0, len = uploadsSelected.length; i < len; i++) {
            const uploadObject = uploadsSelected[i];
            const result = await this.extractUploadOrChunk(uploadObject[Object.keys(uploadObject)[0]], forceExtract);
            results.push(result);
        }
        this.isPageDisabled = false;
        this.updateValuesByFilterState();
        return this.generateReportExtractionError(results);
    }

    /**
     * Extract an upload or a specific chunk
     * @param {UploadChunkRecap[]} upload - upload or chunk
     * @param {boolean} forceExtract
     * @returns {Promise<ErrorUploadObject>} function to extract the upload or chunk
     */
    async extractUploadOrChunk(upload: UploadChunkRecap[], forceExtract: boolean): Promise<ErrorUploadObject> {
        // An upload without chunk was selected
        if (!upload[0].chunkId) {
            const response = await this.extractFile((upload[0] as unknown) as Upload, forceExtract);
            return response;
        } else {
            const response = await this.extractChunks(upload[0].uploadId, upload, forceExtract);
            return response;
        }
    }

    /**
     * Format uploads for extraction (launch extract for an entire contract type's data)
     * Filter only uploads and chunks not being extracted yet.
     */
    private formatContractFilesToExtract(forceType: ForceType): Array<{ [uploadId: string]: UploadChunkRecap[] }> {
        const formatedArray: Array<{ [uploadId: string]: UploadChunkRecap[] }> = [];

        let upload: { [uploadId: string]: UploadChunkRecap[] } = {};
        /** Keep only the chunks matching force type and not already extracted */
        const uploadsFiltered = this.recapFilesData
            .map(uploadChunks =>
                uploadChunks.filter(
                    chunk =>
                        chunk.state !== 'JOBS_DONE' && this.collectService.isStateMatchingForce(chunk.state, forceType)
                )
            )
            .filter(x => x.length); // Filter to prevent extracting all chunks of an upload
        uploadsFiltered.forEach(uploadChunks => {
            uploadChunks.forEach(chunk => {
                upload = formatedArray.find(elem => elem.hasOwnProperty(chunk.uploadId));

                if (!upload) {
                    upload = {};
                    upload[chunk.uploadId] = [];
                    formatedArray.push(upload);
                }
                upload[chunk.uploadId].push(chunk);
            });
        });

        return formatedArray;
    }

    /**
     * Remove multiple files one after the other and update blocks tables accordingly
     * @param {UploadFile[]} files - files to remove
     */
    async removeMultipleFiles(files: UploadFile[]): Promise<void> {
        if (files.length === 1) {
            return this.removeSimpleFile(files[0]); // to have the correct report displayed
        }

        this.isPageDisabled = true;

        // clone is important as files are being removed from the array inside the for loop
        const filesToRemove = _.clone(files);
        const errorFiles = [];

        // remove files from db and from tables one by one
        for (const file of filesToRemove) {
            try {
                await this.removeFile(file);
            } catch (e) {
                errorFiles.push(file);
            }
        }

        if (errorFiles.length) {
            this.generateReportMultiRemoval(errorFiles);
        } else {
            Swal.fire('Supprimés', 'Tous les fichiers ont bien été supprimés.', 'success');
        }
        this.isPageDisabled = false;
        this.notificationService.refresh();
    }

    /**
     * Remove a file and update blocks tables accordingly
     * @param {UploadFile} fileToRemove - file to remove
     */
    async removeSimpleFile(fileToRemove: UploadFile): Promise<void> {
        this.isPageDisabled = true;
        try {
            await this.removeFile(fileToRemove);
            this.isPageDisabled = false;
            this.notificationService.refresh();
            Swal.fire('Supprimé', 'Le fichier a bien été supprimé.', 'success');
        } catch (err) {
            this.isPageDisabled = false;
            Swal.fire('Toutes nos excuses', 'Une erreur est survenue lors de la suppression du fichier', 'error');
        }
    }

    /**
     * Remove the required upload and its data.
     * @param {UploadFile} fileToRemove
     */
    async removeFile(fileToRemove: UploadFile): Promise<void> {
        const res = await this.collectService.removeFile(fileToRemove);
        if (res.code !== 200) {
            throw new Error('remove-file-failed');
        }

        // update blocks components
        this.removeFileFromBlocksData(fileToRemove);
    }

    /**
     * Remove a file and update the blocks 'Extraction report' & 'Extraction states'
     * @param fileToRemove
     */
    removeFileFromBlocksData(fileToRemove: UploadFile) {
        // Update blocks  'Extraction report' & 'Extraction states'
        this.files = this.files.filter(f => f.id !== fileToRemove.id.toString());
        this.indexSelectedFile = null; // deselect file in 'Extraction states' if one was selected

        // look inside Bloc 1 : Unknown files
        const unknownFileIndex = this.unknownFiles.findIndex(f => f.id === fileToRemove.id.toString());

        // if it's an unknown file
        if (unknownFileIndex !== -1) {
            this.unknownFiles.splice(unknownFileIndex, 1);
        } else {
            // if it's a file uploaded --> delete in cascade in other block components

            // Bloc 2 : Contracts types
            const contractGroup = this.dataArray.find(contractTypeGroup => {
                const hasUploadWithId = contractTypeGroup.data.find(f => f.uploadId === fileToRemove.id.toString());
                return hasUploadWithId ? true : false;
            });
            contractGroup.data = contractGroup.data.filter(f => f.uploadId !== fileToRemove.id);
            contractGroup.count = contractGroup.data.length;

            // if the file deleted is not the last one from the provider group type
            if (contractGroup.count !== 0) {
                // Bloc 3 : Files recap
                this.recapFilesData = this.recapFilesData.filter(f => f[0].filename !== fileToRemove.filename);

                // Bloc 4 : Properties & Patterns
                this.patternsPropertiesData = this.patternsPropertiesData.filter(
                    f => f.filename !== fileToRemove.filename
                );
            } else {
                // if the file deleted is the last one from the provider group type

                // Bloc 2 : Contracts types - delete the provider group
                this.dataArray = this.dataArray.filter(
                    group => group.groupContractProviderEnergy !== contractGroup.groupContractProviderEnergy
                );
                // if its provider group wasn't the only one in the upload group -> set selected index on another provider group and reload blocks
                if (this.files.length) {
                    this.selectGroupType(0);
                } else {
                    // if it was the only provider group, empty block components
                    this.reinitData();
                }
            }
        }
    }

    clickOnFileInput(e) {
        this.uploadComponent.clickOnInput();
    }

    /**
     * Get upload tile data to display (drag & drop area)
     */
    getUploadTilesData(): { label: string; subLabel: string; imgUrl: string } {
        let state: string;

        if (!this.files.length) {
            state = globalStates.NOTHING;
        } else {
            const isExtracting = this.files.some(file => {
                return file.state === UploadStateList.UPLOADING || file.state === UploadStateList.IN_PROGRESS;
            });

            if (isExtracting) {
                state = globalStates.DOING;
            } else {
                state = globalStates.DONE;
            }
        }

        this.actualState = state;
        return this.uploadTilesData[state];
    }

    private uploadAndExtractFile(upload: Upload): Promise<ErrorUploadObject> {
        upload.state = UploadStateList.UPLOADING;

        return new Promise(resolve => {
            return this.collectService.uploadFile(upload, this.group._id).subscribe(
                event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        upload.percent = Math.round((50 * event.loaded) / event.total);
                    } else if (event.type === HttpEventType.Response) {
                        upload.id = event.body['data']._id;
                        upload.state = UploadStateList.UNTOUCHED;

                        this.extractFile(upload).then((result: ErrorUploadObject) => {
                            resolve(result);
                        });
                        this.updateValuesByFilterState();
                    }
                },
                err => {
                    const error: ErrorUploadObject = err.error;
                    upload.state = UploadStateList.UPLOAD_ERROR;
                    Object.assign(error, { file: upload });
                    resolve(error);
                }
            );
        });
    }

    /**
     * Reextract an upload.
     * @param {Upload} upload
     */
    async reextractFile(upload: Upload): Promise<{ success: boolean; file: Upload }> {
        const uploadId = upload.id;
        const oldState = this.files.find(file => file.id === uploadId).state;

        // display file as "being extracted" inside blocks 'Recapitulatif fichiers' & 'Etat de l'extraction'
        this.refreshExtractionStates(uploadId, UploadStateList.IN_PROGRESS);
        this.refreshRecapFiles(uploadId, UploadStateList.IN_PROGRESS);

        // prevents the user from adding any new file or clicking on any button
        this.isPageDisabled = true;

        try {
            await this.collectService.reextractFile(uploadId);

            // If data is extracted, refresh page content
            await Promise.all([this.getUploadsByGroup(false), this.notificationService.refresh()]);
            this.isPageDisabled = false;

            return {
                success: true,
                file: upload,
            };
        } catch (err) {
            this.isPageDisabled = false;
            this.refreshExtractionStates(uploadId, oldState);
            this.refreshRecapFiles(uploadId, oldState);

            const error = Object.assign({}, err, { file: upload });
            await this.generateReportExtractionError([error]);
            return error;
        }
    }

    updateExtractSelectionButton(isEnabled: boolean) {
        this.isExtractSelectionDisabled = !isEnabled;
    }

    /**
     * Extract all selected files with paralel requests
     */
    extractSelection(forceSelection: boolean): void {
        const selection = getSelectedFiles(this.recapFilesData);
        if (selection.length === 0) {
            Swal.fire('', 'Aucune facture sélectionnée', 'error');
        } else {
            const extraction = selection
                .filter(
                    file =>
                        !forceSelection ||
                        file.state.toLowerCase() === UploadErrorList.QUALITY_CHECK_FAILED.toLowerCase()
                )
                .map(file => this.extractOneSelectedFile(file, forceSelection ? ForceType.QUALITY : null));

            Promise.all(extraction).then(data => this.generateReportExtractionError(data));
        }
    }

    /**
     * Extract files from multiselected files and refresh blocks ExtractionStates & RecapFiles
     * @param {ExtractFileParams} uploadParams
     * @return {Promise<ErrorUploadObject>}
     */
    async extractOneSelectedFile(
        uploadParams: ExtractFileParams,
        forceType: ForceType = null
    ): Promise<ErrorUploadObject> {
        const { uploadId, chunksIdSelected, fileName } = uploadParams;

        const oldState = this.files.find(file => file.id === uploadId).state;

        // display file as "being extracted" inside block Etat de l'extraction'
        this.refreshExtractionStates(uploadId, UploadStateList.IN_PROGRESS);

        // display chunk and file as "being extracted" inside block 'Recapitulatif fichiers'
        const chunkStates = {};
        chunksIdSelected.forEach(chunkId => {
            chunkStates[chunkId] = UploadStateList.IN_PROGRESS;
        });
        this.refreshRecapFiles(uploadId, UploadStateList.IN_PROGRESS);
        this.refreshChunksInRecapFiles(uploadId, chunkStates);

        this.isPageDisabled = true;

        try {
            const response = await this.collectService.extractFile(uploadId, chunksIdSelected, forceType);
            this.isPageDisabled = false;

            if (response.data) {
                // If data is extracted, refresh page content
                await Promise.all([this.getUploadsByGroup(false), this.notificationService.refresh()]);
                return {
                    success: true,
                    file: { filename: fileName },
                };
            }
        } catch (err) {
            this.isPageDisabled = false;

            this.refreshExtractionStates(uploadId, oldState);
            this.refreshRecapFiles(uploadId, oldState);

            const error = Object.assign({}, err, { file: { filename: fileName } });
            return error;
        }
    }

    /**
     * Extract files and refresh blocks ExtractionStates & RecapFiles
     * @param {Upload} upload
     * @param {boolean} forceExtract
     * @param {boolean} disableView - if true prevents the user from adding any new file or clicking on any button. Default to false.
     * @return {Promise<ErrorUploadObject>}
     */
    async extractFile(
        upload: Upload,
        forceExtract: boolean = false,
        disableView: boolean = false
    ): Promise<ErrorUploadObject> {
        const uploadId = upload.id || upload.uploadId; // depends if extract is launched after upload or from button 'Extract'

        const oldState = this.files.find(file => file.id === uploadId).state;

        // display file as "being extracted" inside blocks 'Recapitulatif fichiers' & 'Etat de l'extraction'
        this.refreshExtractionStates(uploadId, UploadStateList.IN_PROGRESS);
        this.refreshRecapFiles(uploadId, UploadStateList.IN_PROGRESS);

        if (disableView) {
            this.isPageDisabled = true;
        }
        try {
            const forceType = this.collectService.getForceType(oldState, forceExtract);
            const response = await this.collectService.extractFile(uploadId, [], forceType);
            if (disableView) {
                this.isPageDisabled = false;
            }
            if (response.data) {
                // If data is extracted, refresh page content
                await Promise.all([this.getUploadsByGroup(false), this.notificationService.refresh()]);
                return {
                    success: true,
                    file: upload,
                };
            }
        } catch (err) {
            if (disableView) {
                this.isPageDisabled = false;
            }

            this.refreshExtractionStates(uploadId, oldState);
            this.refreshRecapFiles(uploadId, oldState);

            const error = Object.assign({}, err, { file: upload });
            return error;
        }
    }

    /**
     * Returns the appropriate message from a given errorCode
     * @param {string} errorCode
     * @returns {string} msg
     */
    getError(errorCode: string): string {
        switch (errorCode) {
            case ExtractErrorList.UPLOAD_ALREADY_EXTRACTED:
                return "L'extract a déjà été effectué sur ce(s) fichier(s)";
            case ExtractErrorList.CHUNKS_ALREADY_EXTRACTED:
                return "L'extract a déjà été effectué sur le(s) fichier(s) sélectionné(s)";
            case ExtractErrorList.NEED_FORCE_GEOLOC:
                return 'Sur un fichier ayant une adresse introuvable, vous devez forcer la géolocalisation';
            case ExtractErrorList.UPLOAD_CANT_FORCE_GEOLOC:
                return 'Sur un fichier ayant une adresse valide, vous ne pouvez pas forcer la géolocalisation';
            case ExtractErrorList.CHUNKS_CANT_FORCE_GEOLOC:
                return 'Sur des factures ayant une adresse valide, vous ne pouvez pas forcer la géolocalisation';
            case ExtractErrorList.WRONG_UPLOADID:
                return 'Le(s) fichier(s) est(sont) introuvable(s) ou est(s) en erreur';
            case ExtractErrorList.MISSING_IDENTIFICATION_PATTERNS:
                return "Les patterns d'identification n'ont pas pu être chargés.";
            case ExtractErrorList.WRONG_COMPANYID:
                return "L'id de l'entreprise est introuvable.";
            case ExtractErrorList.WRONG_USERID:
                return "L'id de l'utilisateur est introuvable.";
            case ExtractErrorList.CHUNKS_DIFFERENT_UPLOAD:
                return "Certains chunks n'appartiennent pas à l'upload sélectionné";

            case UploadErrorList.ALREADY_UPLOADED:
                return 'Le(s) fichier(s) a(ont) déjà été uploadé(s)';
            case UploadErrorList.MISSING_UPLOAD_GROUP:
                return 'Le groupe de fichiers est introuvable';
            case UploadErrorList.MISSING_GEOLOC:
                return "L'adresse est introuvable";
            case UploadErrorList.MISSING_DATE:
                return 'La date de début et/ou fin est introuvable pour ce(s) fichier(s)';
            case UploadErrorList.PDF_TEXT_EXTRACTION_IMPOSSIBLE:
                return "Le contenu texte du fichier  n'a pas pu être extrait";
            case UploadErrorList.ALREADY_EXTRACTING:
                return "Fichier déjà en cours d'extraction";
            case UploadErrorList.EMPTY_FILE:
                return 'Le fichier est vide';
            case UploadErrorList.REGEX_TIMEOUT:
                return 'Une Regex a pris trop de temps';
            default:
                return 'Erreur inconnue';
        }
    }

    /**
     * Extract upload with a selection of chunks
     * @param uploadId - id of upload to extract
     * @param chunksSelected - list of chunks to extract
     * @param forceExtract - true to force, false otherwise
     */
    private async extractChunks(
        uploadId: string,
        chunksSelected: UploadChunkRecap[] = [],
        forceExtract = false
    ): Promise<ErrorUploadObject> {
        const oldChunksStates: { [chunkId: string]: string } = {};
        const newChunkStates: { [chunkId: string]: string } = {};
        const oldUploadState = this.files.find(file => file.id === uploadId).state;

        const chunkIds = [];
        // Get force type from chunks selected
        const forceType = this.collectService.getForceTypeFromStates(chunksSelected, forceExtract);
        chunksSelected.forEach(chunk => {
            if (this.collectService.isStateMatchingForce(chunk.state, forceType)) {
                chunkIds.push(chunk.chunkId);
                // Set states for display
                oldChunksStates[chunk.chunkId] = chunk.state;
                newChunkStates[chunk.chunkId] = UploadStateList.IN_PROGRESS;
            }
        });

        // refresh block 'Recapitulatif fichiers' &  'Etat de l'extraction'
        this.refreshExtractionStates(uploadId, UploadStateList.IN_PROGRESS);
        this.refreshChunksInRecapFiles(uploadId, newChunkStates);

        this.isPageDisabled = true;

        try {
            const response = await this.collectService.extractFile(uploadId, chunkIds, forceType);
            this.isPageDisabled = false;

            if (response.data) {
                await this.getUploadsByGroup(false);
                return {
                    success: true,
                    file: response.data,
                };
            } else {
                return {
                    success: false,
                    file: response.data,
                };
            }
        } catch (err) {
            this.isPageDisabled = false;

            if (err.code) {
                // refresh block 'Recapitulatif fichiers' &  'Etat de l'extraction'
                this.refreshChunksInRecapFiles(uploadId, oldChunksStates);
                this.refreshExtractionStates(uploadId, oldUploadState);

                Object.assign(err, { file: chunksSelected[0] });

                return err;
            }
            return err;
        }
    }

    /**
     * Set new state on the upload inside the extraction state block
     * @param {string} uploadId
     * @param {string} state
     */
    refreshExtractionStates(uploadId: string, state: string): void {
        const file_extractionStateBlock = this.files.find(file => file.id === uploadId);
        file_extractionStateBlock.state = state;
    }

    /**
     * Set new state on the upload /chunk inside the file recap block 'Recapitulatif Fichiers'
     * @param {string} uploadId
     * @param {string} state
     */
    refreshRecapFiles(uploadId, state) {
        // [[filename: .., uploadId: ..]] --> upload without chunk
        // [[filename: .., uploadId: .., chunkId], --> upload with 3 chunks
        //  [filename: .., uploadId: .., chunkId],
        //  [filename: .., uploadId: .., chunkId],
        // ]
        const files = this.recapFilesData.find(f => f.find(chunkOrUpload => chunkOrUpload.uploadId === uploadId));
        if (files) {
            files.forEach(chunkOrUpload => (chunkOrUpload.state = state));
            files.forEach(chunkOrUpload => (chunkOrUpload.uploadState = state));
        }
    }

    /**
     * Refresh chunks in recapFiles
     * @param uploadId
     * @param chunkStatesByIds
     */
    private refreshChunksInRecapFiles(uploadId: string, chunkStatesByIds: { [chunkId: string]: string }) {
        const files = this.recapFilesData.find(f => f.find(chunk => chunk.uploadId === uploadId));
        if (files) {
            // [{filename: .., uploadId: .., chunkId}, --> upload with 3 chunks
            //  {filename: .., uploadId: .., chunkId},
            //  {filename: .., uploadId: .., chunkId},
            // ]

            files.forEach(chunk => {
                if (chunkStatesByIds.hasOwnProperty(chunk.chunkId)) {
                    chunk.state = chunkStatesByIds[chunk.chunkId];
                    chunk.uploadState = chunkStatesByIds[chunk.chunkId];
                }
            });
        }
    }

    isPatternsPropertiesTableLoading() {
        return this.loading.tableProperties;
    }

    patternsPropertiesTableHasData() {
        return this.patternsPropertiesData && this.patternsPropertiesData.length;
    }

    isRecapTableLoading() {
        return this.loading.tableRecap;
    }

    recapTableHasData() {
        return this.recapFilesData && this.recapFilesData.length;
    }

    hasSelectedGroup() {
        return this.selectedGroupType && this.selectedGroupType.energy;
    }

    getRecapTableTitle() {
        const currentEnergyType = this.selectedGroupType.energy;
        const currentProvider = this.selectedGroupType.provider ? this.selectedGroupType.provider.name : 'NC';
        const currentContractType = this.selectedGroupType.contractType
            ? this.selectedGroupType.contractType.name
            : 'NC';

        return `Récapitulatif des fichiers : ${currentEnergyType} ${currentProvider} ${currentContractType}`;
    }

    getExtractDataBlocTitle() {
        const nbTotalFiles = this.dataArray.reduce((totalFiles, item) => {
            return totalFiles + item.data.reduce(files => ++files, 0);
        }, 0);

        return nbTotalFiles > 1
            ? `Données extraites de ${nbTotalFiles} fichiers`
            : `Données extraites de ${nbTotalFiles} fichier`;
    }

    getExtractDataBlockWidth() {
        return this.unknownFiles.length ? 'col-md-8' : 'col-md-12';
    }

    getGroupId() {
        if (this.group && this.group._id) {
            return this.group._id;
        } else {
            return null;
        }
    }

    getUnknwownFilesTitle() {
        return this.unknownFiles.length > 1
            ? this.unknownFiles.length + ' documents inconnus'
            : this.unknownFiles.length + ' document inconnu';
    }

    /**
     * Disable the drag&drop zone when files are being extracted, or if any action disabled the whole page
     * @return {boolean}
     */
    isDropZoneDisabled() {
        return this.isPageDisabled || this.isDragAndDropDisabled;
    }
}
