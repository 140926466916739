import { Component, OnDestroy, OnInit } from '@angular/core';
import moment = require('moment');
import { Subscription } from 'rxjs';

import { BarChartProperties, ChartProperties, ColorGA } from 'app/shared/models/charts/charts.interface';
import { ChartService } from 'app/shared/services/chart/chart.service';

import { HeatingPeriod, Monitoring, MonthlyAggregated } from '../operating-monitoring.interface';
import { OperatingMonitoringService } from '../operating-monitoring.service';
import { ChartConfig, ChartDatasetConfig } from './monitoring-graphs.interface';

@Component({
    selector: 'ga-monitoring-graphs',
    templateUrl: './monitoring-graphs.component.html',
    styleUrls: ['./monitoring-graphs.component.scss'],
})
export class MonitoringGraphsComponent implements OnInit, OnDestroy {
    private dateLabelFormat = 'MM/YYYY';

    /** Selected monitoring */
    public selectedMonitoring: Monitoring;

    /** Subcription */
    private subscription: Subscription;

    /** Consumption chart properties */
    public consumptionProperties: BarChartProperties = null;
    /** Consumption chart data config */
    private consumptionConfig: ChartConfig<MonthlyAggregated> = {
        getLabel: (month: MonthlyAggregated) => month.monthStart,
        datasets: [
            {
                data: [],
                label: 'djus',
                yAxisID: 'y-axis-1',
                stack: '0',
                type: 'line',
                getX: item => item.monthStart,
                getY: item => item.modulatorReal,
            },
            {
                data: [],
                label: 'monitoring_conso_noticed',
                yAxisID: 'y-axis-0',
                stack: '1',
                getX: item => item.monthStart,
                getY: item => item.consumptionHeating,
            },
            {
                data: [],
                label: 'monitoring_conso_expected',
                yAxisID: 'y-axis-0',
                stack: '2',
                getX: item => item.monthStart,
                getY: item => item.valueRevaluedOnBillsPeriod,
            },
            {
                data: [],
                label: 'monitoring_conso_remaining',
                yAxisID: 'y-axis-0',
                stack: '2',
                getX: item => item.monthStart,
                getY: item => item.valueRevalued - item.valueRevaluedOnBillsPeriod,
            },
        ],
    };

    /** Objective gap chart properties */
    public objectiveGapProperties: BarChartProperties = null;
    /** Objective gap chart data config */
    private objectiveGapConfig: ChartConfig<MonthlyAggregated> = {
        getLabel: item => item.monthStart,
        datasets: [
            {
                data: [],
                label: 'monitoring_deviation_formula',
                yAxisID: 'y-axis-0',
                stack: '1',
                getX: item => item.monthStart,
                getY: item => item.deviation,
                getColor: p =>
                    p.y >= 0
                        ? this.chartService.getColor('monitoring_deviation_positive')
                        : this.chartService.getColor('monitoring_deviation_negative'),
                legendOptions: {
                    colors: ['monitoring_deviation_positive', 'monitoring_deviation_negative'],
                },
            },
        ],
    };

    constructor(private operatingMonitoringService: OperatingMonitoringService, private chartService: ChartService) {}

    ngOnInit() {
        this.subscription = this.operatingMonitoringService.selectedPeriod$.subscribe({
            next: period => {
                this.selectedMonitoring = this.operatingMonitoringService.selectedMonitoring$.getValue();
                if (this.selectedMonitoring) {
                    this.resetChartsProperties();
                    this.setChartsData(this.selectedMonitoring, period);
                }
            },
        });
    }

    /**
     * Returns true if at least one dataset of the consumption graph has data.
     */
    public hasConsumptionGraphData(): boolean {
        if (
            this.consumptionProperties &&
            this.consumptionProperties.datasets &&
            this.consumptionProperties.datasets.length
        ) {
            return this.consumptionProperties.datasets.some(dataset => dataset.data.length);
        }
        return false;
    }

    /**
     * Returns true if the dataset of the objective gap graph has data.
     */
    public hasObjectiveGapGraphData(): boolean {
        if (
            this.objectiveGapProperties &&
            this.objectiveGapProperties.datasets &&
            this.objectiveGapProperties.datasets.length
        ) {
            const [dataset] = this.objectiveGapProperties.datasets;

            // Verify if dataset is not empty nor filled with null values on y axis
            return dataset.data.length && dataset.data.some(point => point.y !== null);
        }
        return false;
    }

    /**
     * Reset chart properties
     */
    private resetChartsProperties() {
        // Consumption chart
        this.consumptionProperties = {
            datasets: [],
            labels: [],
            colors: [],
            options: this.chartService.getConfig('mixed-groupable-stacked-bar', {
                tooltips: this.chartService.getTooltipHTMLConfig('default', ['DJU', 'kWh', 'kWh', 'kWh']),
            }),
            minHeight: 250,
            maxHeight: 300,
            width: 10000, // Set very large width to adapt large screens. Won't overflow
        };
        this.consumptionProperties.options.scales.yAxes[0].scaleLabel = {
            display: true,
            labelString: 'kWh',
        };
        this.consumptionProperties.options.scales.yAxes[1].scaleLabel = {
            display: true,
            labelString: 'DJU',
        };
        // Objective chart
        this.objectiveGapProperties = {
            datasets: [],
            labels: [],
            colors: [],
            options: this.chartService.getConfig('bar', {
                tooltips: this.chartService.getTooltipHTMLConfig('default', ['%']),
            }),
            minHeight: 250,
            maxHeight: 300,
            width: 10000, // Set very large width to adapt large screens. Won't overflow
        };
        this.objectiveGapProperties.options.scales.yAxes[0].scaleLabel = {
            display: true,
            labelString: '%',
        };
    }

    /**
     * Set chart data for given montoring and period
     * @param period - if `null` or `undefined`, consider that all periods to display
     */
    private setChartsData(monitoring: Monitoring, period: HeatingPeriod) {
        // Get months items related to period
        let months = [].concat(monitoring.monthlyAggregated);
        months.sort((a, b) => moment(a.monthStart).diff(b.monthStart));
        if (period) {
            months = months.filter(x => {
                if (period.periodEnd !== null) {
                    return moment.utc(x.monthStart).isBetween(period.periodStart, period.periodEnd, 'day', '[]');
                }
                return moment.utc(x.monthStart).isSameOrAfter(period.periodStart, 'day');
            });
        }
        // Compute chart data
        this.computeChartData<MonthlyAggregated>(this.consumptionConfig, months, this.consumptionProperties);
        this.consumptionProperties.options = this.chartService.getSeriesAxesOptions(
            this.consumptionProperties.datasets,
            this.consumptionProperties.options
        );

        this.computeChartData<MonthlyAggregated>(this.objectiveGapConfig, months, this.objectiveGapProperties);
        this.drawPeriodZones(period, monitoring, this.consumptionProperties);
        this.drawPeriodZones(period, monitoring, this.objectiveGapProperties);
    }

    private computeChartData<T>(config: ChartConfig<T>, arr: T[], chartProperties: ChartProperties) {
        // Reset config datasets data
        for (const c of config.datasets) {
            c.data = [];
        }
        // Compute datasets values for each array item
        const labels: string[] = [];
        for (const item of arr) {
            for (const c of config.datasets) {
                c.data.push({ x: c.getX(item), y: c.getY(item) });
            }
            labels.push(config.getLabel(item));
        }

        chartProperties.datasets = config.datasets.map(c => ({
            data: c.data,
            label: c.label,
            yAxisID: c.yAxisID,
            stack: c.stack,
            type: c.type,
            ...this.handleDatasetColor(c),
        }));

        // Compute labels (x axis)
        chartProperties.labels = labels.map(x => moment.utc(x).format(this.dateLabelFormat));
        // Compute legends
        chartProperties.legends = config.datasets.map(x => this.chartService.getLegend(x.label, x.legendOptions || {}));
    }

    /**
     * Handle dataset color
     * Same for all point (string) or specific for each point (string[])
     */
    private handleDatasetColor<T>(c: ChartDatasetConfig<T>) {
        const color = c.getColor ? c.data.map(x => c.getColor(x)) : this.chartService.getColor(c.label);
        const isArray = Array.isArray(color);
        const getProp: (prop: string) => string | string[] = (prop: string) =>
            isArray ? (color as ColorGA[]).map(x => x[prop]) : color[prop];
        return {
            backgroundColor: getProp('backgroundColor'),
            borderColor: getProp('borderColor'),
            hoverBackgroundColor: getProp('backgroundColor'), // Not in ColorGA so keep same color on hover
            hoverBorderColor: getProp('borderColor'), // Not in ColorGA so keep same color on hover
            pointBackgroundColor: getProp('pointBackgroundColor'),
            pointBorderColor: getProp('pointBorderColor'),
            pointHoverBackgroundColor: getProp('pointHoverBackgroundColor'),
            pointHoverBorderColor: getProp('pointHoverBorderColor'),
        };
    }

    private drawPeriodZones(period: HeatingPeriod, monitoring: Monitoring, chartProperties: ChartProperties) {
        // Clean previous zones
        chartProperties.options.annotation.annotations = [];

        // Draw only for all periods selected
        if (period) {
            return;
        }

        for (let i = 0, len = monitoring.periods.length; i < len; i++) {
            const p = monitoring.periods[i];
            if (i % 2 === 0) {
                const iStart = chartProperties.labels.indexOf(moment.utc(p.periodStart).format(this.dateLabelFormat));
                const iEnd = chartProperties.labels.indexOf(moment.utc(p.periodEnd).format(this.dateLabelFormat));
                chartProperties.options.annotation.annotations.push({
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    xScaleID: 'x-axis-0',
                    xMin: iStart > 0 ? iStart - 0.5 : null,
                    xMax: iEnd > 0 ? iEnd + 0.5 : null,
                    borderColor: '#00000010',
                    backgroundColor: '#00000010',
                });
            }
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
