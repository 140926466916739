import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[gaListPanel]',
})
/**
 * Directive specific to the List-Panel element
 * This element contains a list of cards
 * Clicking on one of those recenter it in the scrollbar
 */
export class ListPanelDirective {
    constructor() {}

    @HostListener('click', ['$event.target'])
    onClick(button) {
        button.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });
    }
}
